/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGlobalLang,
    useGridApi,
    useModal,
} from '@veeam-vspc/shared/components';
import {
    BackupServerApplicationSettingsVSS,
    BackupServerTransactionLogsSettings,
    BackupServerVmwareInventoryType,
} from '@veeam-vspc/models/rest';
import { deepCopy } from '@veeam-vspc/shared/core';
import { processDecoratorsForDisabledByPriority } from '@veeam-vspc/shared/helpers';

import type { BackupServerBackupJobApplicationSettings, BackupServerBackupJobVmwareObjectSize } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import { ProcessingSettingsDialog } from '../ProcessingSettings/ProcessingSettings';
import { ItemsModal } from '../ItemsModal/ItemsModal';
import { useJobWizardStore } from '../../../../../../stores';

export const ActionsToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<BackupServerBackupJobApplicationSettings, void, void>();
    const wizardStore = useJobWizardStore();

    const handleAdd = (items: BackupServerBackupJobVmwareObjectSize[]) => {
        wizardStore.handleUpdateAppAwareProcessingSettings([
            ...wizardStore.appAwareProcessingSettings,
            ...items.map(item => ({
                vmObject: {
                    hostName: wizardStore.selectedCompanyVC.hostName,
                    name: item.inventoryObject.name,
                    type: item.inventoryObject.type,
                    objectId: item.inventoryObject.objectId,
                },
                vss: BackupServerApplicationSettingsVSS.RequireSuccess,
                usePersistentGuestAgent: false,
                transactionLogs: BackupServerTransactionLogsSettings.Process,
                sql: null,
                oracle: null,
                exclusions: null,
                scripts: null,
            })),
        ]);

        gridApi.fetchData();
    };

    const handleRemove = (items: BackupServerBackupJobApplicationSettings[]) => {
        const ids = items.map(x => x.vmObject.objectId);

        wizardStore.handleUpdateAppAwareProcessingSettings(
            wizardStore.appAwareProcessingSettings.filter(x => !ids.includes(x.vmObject.objectId))
        );

        gridApi.fetchData();
    };

    const handleUpdate = (item: BackupServerBackupJobApplicationSettings) => {
        wizardStore.handleUpdateAppAwareProcessingSettings(
            wizardStore.appAwareProcessingSettings.map((x) => {
                if (x.vmObject.objectId === item.vmObject.objectId) {
                    const clonedItem = deepCopy(item) as BackupServerBackupJobApplicationSettings & { objectId?: string; };
                    delete clonedItem.objectId;

                    return clonedItem;
                }

                return x;
            })
        );

        gridApi.fetchData();
        gridApi.deselectAll();
    };

    const [itemDialog, itemDialogActions] = useModal({
        render: ({ deactivate }) => (
            <ItemsModal
                onApply={(newData: BackupServerBackupJobVmwareObjectSize[]) => {
                    handleAdd(newData);
                    deactivate();
                }}
                onCancel={deactivate}
            />
        ),
    });

    const [settingsDialog, settingsDialogActions] = useModal<BackupServerBackupJobApplicationSettings>({
        render: ({ deactivate, data }) => (
            <ProcessingSettingsDialog
                data={data}
                onConfirm={(newData) => {
                    handleUpdate(newData);
                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                itemDialogActions.activate();
            },
            decorators: [
                () => {
                    if (wizardStore.includedItems.every(x => x.inventoryObject.type === BackupServerVmwareInventoryType.VirtualMachine)) {
                        return ({ disabled: true });
                    }

                    return ({ disabled: false });
                },
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: () => {
                const selected = gridApi.selected[0];
                settingsDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: () => {
                const selected = gridApi.selected;

                handleRemove(selected);
            },
            decorators: [
                processDecoratorsForDisabledByPriority([
                    toolbarItemDecorators.disallowWithoutSingleSelection(),
                    (payload) => {
                        const isNotRemovable = (item: BackupServerBackupJobApplicationSettings) => wizardStore.isItemSelectedGeneric(
                            item.vmObject.objectId,
                            (item: BackupServerBackupJobVmwareObjectSize) => item.inventoryObject.objectId,
                            wizardStore.includedItems
                        );

                        if (payload.selectedItems.some(item => isNotRemovable(item as unknown as BackupServerBackupJobApplicationSettings))) {
                            return ({
                                disabled: true,
                            });
                        }

                        return ({
                            disabled: false,
                        });
                    },
                ]),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {itemDialog}
            {settingsDialog}
        </>
    );
};
