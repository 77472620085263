/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { JobSessionTaskStatusRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { getMarkedList, getStatusIconSrc } from 'core/utils';

export interface FileServerStatusColumnProps {
    status: JobSessionTaskStatusRepresentation;
    statusName: string;
    messages: string[];
}

export const ObjectStorageStatusColumn: React.FC<FileServerStatusColumnProps> = (props) => {
    const { status, statusName, messages } = props;
    const lang = useGlobalLang<VspcLang>();
    const iconSrc = getStatusIconSrc(statusName);
    const msg = getMarkedList(messages || []);
    const isLink = [JobSessionTaskStatusRepresentation.Failed, JobSessionTaskStatusRepresentation.Warning].includes(status);

    return (
        <ActionLinkColumn
            contentIconBefore={iconSrc}
            isLink={isLink}
            isExtMode={true}
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {statusName}
                </TextColumn>
            )}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.STATUS}
                    onRequestClose={deactivate}
                    actions={[{ text: lang.OK, onClick: deactivate }]}
                    iconSrc={iconSrc}
                >
                    {<ParsedText textOrConfig={msg} />}
                </MessageBox>
            )}
        />
    );
};
