/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface JobsOverviewFile {
    JobsOverview: ComponentType<any>;
}

export const JobsSummaryPage = () => (
    <LazyLoadedComponent<JobsOverviewFile>
        importPromise={() => (import('./components/JobsOverview') as Promise<JobsOverviewFile>)}
        pageName={'JobsOverview'}
    />
);
