/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BasicFilter, useGlobalLang } from '@veeam-vspc/shared/components';

import type { BasicFilterProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { getStatusIconSrc } from 'core/utils/status-helpers';

import type { ToggleFilterStatusItem } from './interfaces';

export interface StatusToggleFilterProps<ValueT> extends Omit<BasicFilterProps<ValueT>, 'label' | 'buttons'> {
    statuses: ToggleFilterStatusItem[];
    propName?: string;
    label?: string;
}

export const StatusToggleFilter = <ValueT,>({ label, propName = 'status', statuses, ...restProps }: StatusToggleFilterProps<ValueT>) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <BasicFilter
            {...restProps}
            name={propName}
            label={label ?? lang.STATUS}
            hasAllButton={true}
            buttons={statuses.map(({ title, value, iconSrc }) => ({
                title,
                value: value as any,
                icon: iconSrc || getStatusIconSrc(title),
            }))}
        />
    );
};
