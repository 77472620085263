/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    FormLayout,
    useGlobalLang,
    STACK_DIRECTION,
    FieldLayout,
    CheckboxKit,
    CONTROL_SIZE,
} from '@veeam-vspc/shared/components';
import { Month } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

interface MonthsModalProps {
    deactivate: () => void;
    onConfirm: (data: Array<Month>) => void;
    data: Array<Month>;
}

export const MonthsModal: React.FC<MonthsModalProps> = observer(({
    deactivate,
    onConfirm,
    data = [],
}) => {
    const lang = useGlobalLang<VspcLang>();

    const [values, setValues] = useState({
        [Month.Jan]: data.includes(Month.Jan),
        [Month.Feb]: data.includes(Month.Feb),
        [Month.Mar]: data.includes(Month.Mar),
        [Month.Apr]: data.includes(Month.Apr),
        [Month.May]: data.includes(Month.May),
        [Month.Jun]: data.includes(Month.Jun),
        [Month.Jul]: data.includes(Month.Jul),
        [Month.Aug]: data.includes(Month.Aug),
        [Month.Sep]: data.includes(Month.Sep),
        [Month.Oct]: data.includes(Month.Oct),
        [Month.Nov]: data.includes(Month.Nov),
        [Month.Dec]: data.includes(Month.Dec),
    });

    return (
        <Dialog
            header={lang.SELECT_MONTHS}
            actions={[
                {
                    disabled: Object.values(values).every(value => !value),
                    text: lang.OK,
                    onClick: () => onConfirm(
                        Array.from(Object.entries(values))
                            .filter(entry => entry[1] === true)
                            .map(entry => entry[0] as Month)
                    ),
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Jan]}
                        onChange={value => setValues({ ...values, [Month.Jan]: value })}
                    >
                        {lang.JANUARY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Apr]}
                        onChange={value => setValues({ ...values, [Month.Apr]: value })}
                    >
                        {lang.APRIL}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Jul]}
                        onChange={value => setValues({ ...values, [Month.Jul]: value })}
                    >
                        {lang.JULY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Oct]}
                        onChange={value => setValues({ ...values, [Month.Oct]: value })}
                    >
                        {lang.OCTOBER}
                    </CheckboxKit>
                </FieldLayout>
            </FormLayout>

            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Feb]}
                        onChange={value => setValues({ ...values, [Month.Feb]: value })}
                    >
                        {lang.FEBRUARY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.May]}
                        onChange={value => setValues({ ...values, [Month.May]: value })}
                    >
                        {lang.MAY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Aug]}
                        onChange={value => setValues({ ...values, [Month.Aug]: value })}
                    >
                        {lang.AUGUST}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Nov]}
                        onChange={value => setValues({ ...values, [Month.Nov]: value })}
                    >
                        {lang.NOVEMBER}
                    </CheckboxKit>
                </FieldLayout>
            </FormLayout>

            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Mar]}
                        onChange={value => setValues({ ...values, [Month.Mar]: value })}
                    >
                        {lang.MARCH}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Jun]}
                        onChange={value => setValues({ ...values, [Month.Jun]: value })}
                    >
                        {lang.JUNE}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Sep]}
                        onChange={value => setValues({ ...values, [Month.Sep]: value })}
                    >
                        {lang.SEPTEMBER}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[Month.Dec]}
                        onChange={value => setValues({ ...values, [Month.Dec]: value })}
                    >
                        {lang.DECEMBER}
                    </CheckboxKit>
                </FieldLayout>
            </FormLayout>
        </Dialog>
    );
});
