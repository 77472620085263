/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type {
    AgentTask,
    AgentTaskName,
    AgentTasksParam,
    CompanyParam,
    CompanyShortData,
} from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { TasksFilterToolbar, TasksStatusColumn } from './components';
import { useTransportRequest } from 'views/hooks';


interface TasksProps {
    stateId: string;
}

export const Tasks: React.FC<TasksProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.Tasks`;
    const { transportService } = useGlobalServices();
    const {
        loading: loadingCompaniesList,
        data: companiesList,
        forceRequest: getCompaniesList,
    } = useTransportRequest<CompanyParam, CompanyShortData[]>('/Company/GetCompanyList', {
        filter: {
            excludeResellers: false,
            excludeSystem: false,
        },
        sort: [{ property: 'name', direction: GridSortDirections.Asc }],
    });
    const {
        loading: loadingTaskNames,
        data: agentTaskNames,
        forceRequest: getAgentTaskNames,
    } = useTransportRequest<unknown, AgentTaskName[]>('/Agent/GetAgentTaskNames', {
        sort: [{ property: 'name', direction: GridSortDirections.Asc }],
    });

    const columns: GridColumnProps<AgentTask>[] = [
        {
            id: 'status',
            title: lang.STATUS,
            cell: rowData => (
                <TasksStatusColumn status={rowData.status} />
            ),
        },
        {
            id: 'taskId',
            title: lang.ID,
            cell: rowData => rowData.taskId,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'company',
            title: lang.COMPANY,
            cell: rowData => rowData.company,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'taskName',
            title: lang.TASK,
            cell: rowData => rowData.taskName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'agentName',
            title: capitalize(lang.AGENT),
            cell: rowData => rowData.agentName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'startTime',
            title: lang.TIME,
            cell: rowData => (
                <GridDateColumn value={rowData.startTime} />
            ),
        },
        {
            id: 'description',
            title: lang.DESCRIPTION,
            cell: rowData => rowData.description,
            defaultValue: lang.NO_INFO,
        },
    ];

    return !loadingCompaniesList && !loadingTaskNames
        ? (
            <ConfiguredGrid
                stateId={gridStateId}
                columns={columns}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: columns?.[1]?.id,
                    },
                }}
                defaultColumnMinWidth={80}
                data={(requestParams: AgentTasksParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<AgentTask[]>>('/Agent/GetAgentTasks', { ...requestParams })}
                toggleable={true}
                toolbars={[
                    () => (
                        <TasksFilterToolbar
                            companiesList={companiesList as CompanyShortData[] ?? []}
                            getCompaniesList={getCompaniesList}
                            agentTaskNames={agentTaskNames as AgentTaskName[] ?? []}
                            getAgentTaskNames={getAgentTaskNames}
                        />
                    ),
                ]}
                selection={{
                    field: 'taskId',
                    checkbox: false,
                    multiple: false,
                }}
            />
        ) : null;
};
