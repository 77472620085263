/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    FieldLayout,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextInput,
    useExternalFormApi,
    useGlobalAppData,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { FormErrors, WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { NoteBar } from 'components/layouts/NoteBar';
import { RolesAndUsersWizardType } from '../../../../enums';
import { RulesList } from './components/RulesList';

import type { SSORuleModel } from '../../interfaces/sso-rule-model';

export const conditionsStepValidate = (lang: VspcLang, data: SSORuleModel): FormErrors<SSORuleModel> => {
    const validator = new FormValidator(data);

    validator.validate('model.organizationMappingSourceClaimType')
        .string()
        .maxLength(2048)
        .check((value) => {
            if (value === undefined || value.trim() === '') {
                return lang.THE_FIELD_IS_REQUIRED;
            }
        });

    if (data.model.additionalMappings) {
        data.model.additionalMappings.forEach((item, i) => {
            validator.validate(`model.additionalMappings[${i}].claimType`)
                .string()
                .maxLength(2048)
                .check((value) => {
                    if (value === undefined || value.trim() === '') {
                        return lang.THE_FIELD_IS_REQUIRED;
                    }
                });
            validator.validate(`model.additionalMappings[${i}].value`)
                .string()
                .maxLength(2048)
                .check((value) => {
                    if (value === undefined || value.trim() === '') {
                        return lang.THE_FIELD_IS_REQUIRED;
                    }
                });
        });
    }

    return validator.result();
};

const Conditions: React.FC<WizardStepData<SSORuleModel>> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { data, validationState, onDataChange } = props;
    const formApi = useExternalFormApi<SSORuleModel>();
    const { portalUser } = useGlobalAppData();

    return (
        <Form
            value={data}
            validate={val => conditionsStepValidate(lang, val)}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
        >
            <StepLayout
                title={lang.CONDITIONS}
                description={lang.CONFIGURE_MAPPING_CONDITIONS_FOR_THE_SELECTED_ROLE}
            >
                <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.column}>
                    <TextInput
                        name='model.organizationMappingSourceClaimType'
                        label={data.wizard === RolesAndUsersWizardType.CompanySSORule
                            ? lang.SPECIFY_CLAIM_NAME_FROM
                            : `${lang.SPECIFY_CLAIM_NAME} «${portalUser.companyName}»`}
                        placeholder={lang.CLAIM_NAME}
                    />

                    <NoteBar>
                        {lang.CLAIM_IS_AN_ATTRIBUTE}
                        <br />
                        {lang.FOR_OKTA}
                        <br />
                        {lang.FOR_KEYCLOAK_ADFS}
                        <br />
                    </NoteBar>

                    <FieldLayout label={`${lang.CONFIGURE_ADDITIONAL_MAPPING_CONDITIONS}:`}>
                        <RulesList
                            data={data}
                            formApi={formApi}
                        />
                    </FieldLayout>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getConditionsStep = (lang: VspcLang, title: string): WizardStep<SSORuleModel> => ({
    title,
    validate: ({ data }) => isValid(val => conditionsStepValidate(lang, val), data),
    render: data => <Conditions {...data} />,
    id: 'conditions',
});


