/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, Text, StackView, STACK_ALIGN, STACK_DIRECTION, STACK_GAP, TextColumn } from '@veeam-vspc/shared/components';
import { Vb365ProtectedObjectType } from '@veeam-vspc/models/rest';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';

import group from 'images/filters/protected-data-type/group.svg';
import site from 'images/filters/protected-data-type/site.svg';
import teams from 'images/filters/protected-data-type/teams.svg';
import user from 'images/filters/protected-data-type/user.svg';

const typeIcon = {
    [Vb365ProtectedObjectType.User]: user,
    [Vb365ProtectedObjectType.Teams]: teams,
    [Vb365ProtectedObjectType.Group]: group,
    [Vb365ProtectedObjectType.Site]: site,
};

export const ProtectedDataTypeColumn = ({ rowData }: { rowData: Vb365ProtectedObject; }) => {
    if (!rowData.protectedDataType) {
        return (
            ColumnTexts.DashSymbol
        );
    }

    if (rowData.protectedDataType === 'Unknown') {
        return (
            rowData.protectedDataType
        );
    }

    return (
        <TextColumn>
            <StackView
                align={STACK_ALIGN.center}
                direction={STACK_DIRECTION.row}
                gap={STACK_GAP.s}
            >
                <Icon src={typeIcon[rowData.protectedDataType]} />
                <Text truncate style={{ color: 'inherit' }}>{rowData.protectedDataType}</Text>
            </StackView>
        </TextColumn>
    );
};
