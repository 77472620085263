/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/shared/components';

import type { FormErrors } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { DeploymentScheduleType } from '../../enums';

import type { VbrDeploySettingsWizardModel } from '../../entries';

export const stepValidate = (lang: VspcLang, data: VbrDeploySettingsWizardModel): FormErrors<VbrDeploySettingsWizardModel> => {
    const validator = new FormValidator(data);
    const now = new Date();

    if (data.deploymentSchedule === DeploymentScheduleType.ScheduleUpgrade) {
        validator.validate('scheduleDate')
            .date()
            .required()
            .min(now, lang.THE_SCHEDULE_DATE_CANNOT);
    }

    return validator.result();
};
