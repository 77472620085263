/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { REG_EXP } from 'core/const';

import type { VbrDeploySettingsWizardModel } from '../../entries';

export const formValidate = (lang: VspcLang) => (data: VbrDeploySettingsWizardModel) => {
    const validator = new FormValidator(data);

    const fileTypeValidator = (inputFile: FileList): string => {
        const fileName = inputFile[0].name;
        const regExp = REG_EXP.xmlFileType;

        return regExp.test(fileName) ? '' : lang.THE_SPECIFIED_FILE_IS_INVALID;
    };

    validator.validate('installationParametersFile')
        .object()
        .required()
        .check(fileTypeValidator);

    return validator.result();
};
