/**
 * Copyright © Veeam Software Group GmbH.
 */

import { AppBarNotification, useGlobalContext, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { canCancelInterval } from '@veeam-vspc/shared/core-modules';

import type { VspcLang } from 'configs/languages';

import { ConnectionStatuses } from '../../enums';
import { useAppBellStore } from '../../hooks';
import { NotificationBellMessage } from '../NotificationBellMessage';

export const Bell: React.FC = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { appState } = useGlobalContext();
    const bellStore = useAppBellStore();
    const disconnectAndStopIntervalTimer = () => {
        if (bellStore.connectionStatus !== ConnectionStatuses.None) {
            bellStore.clearRefreshInterval();
            bellStore.disconnect();
        }
    };

    useEffect(() => {
        bellStore.subscribeToEvents(); // subscribe only and unsubscribe will be on disconnect
        bellStore.setRefreshInterval();
        bellStore.connect();

        return () => {
            // Disconnect and clear interval on ComponentUnmount
            disconnectAndStopIntervalTimer();
        };
    }, []);

    useEffect(() => {
        if (canCancelInterval(appState)) {
            // Disconnect and clear interval on SessionExpired and Destroyed
            disconnectAndStopIntervalTimer();
        }
    }, [appState]);

    return (
        <AppBarNotification
            isAutoDisplay={false}
            noAutoDisplay={true}
            unread={bellStore.unread}
            messages={bellStore.messages.map(({ permanentNotificationPayload, asyncActionPayload, ...props }) => ({
                render: () => <NotificationBellMessage {...props} />,
            }))}
            texts={{
                empty: lang.NO_NOTIFICATIONS,
                clearAll: lang.CLEAR_ALL,
            }}
        />
    );
});
