/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    View,
    VIEW_BG_VIEW,
    VIEW_BORDER,
    useGlobalLang,
    ConfiguredGrid,
    X_AXIS,
    Y_AXIS,
    Button,
    BUTTON_VIEW,
    STACK_DISTRIBUTION,
    useGlobalServices,
    GridSortDirections,
    SortingMode,
} from '@veeam-vspc/shared/components';
import { Dropdown, SPACE_FILL, SPACING_M, StackView, STACK_DIRECTION, STACK_GAP } from '@veeam/components';
import { capitalize, formatStr } from '@veeam-vspc/shared/helpers';
import styled from 'styled-components';

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { DropdownControl } from '../DropdownControl/DropdownControl';

interface SitesFilterProps {
    data: CloudConnectServerWithDeployInfo[];
    selected: string[];
    disabled?: boolean;
    onSelectionChange: (agentGuids: string[]) => void;
}

const dropdownSizing = {
    width: '400px',
    minHeight: 180,
    maxHeight: 400,
    itemHeight: 31,
};

const GridContainer = styled(StackView)`
    padding: ${SPACING_M};
`;

export const SitesFilter: React.FC<SitesFilterProps> = ({
    data,
    selected = [],
    disabled,
    onSelectionChange,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const [selection, setSelection] = useState<string[]>(() => {
        if (selected.length === 0) {
            return data.map(filter => filter.agentGuid);
        }
        return [...selected];
    });
    const [skipSelectionAfterFilterChanging] = useState({ val: false });
    const [query, setQuery] = useState<string>(getResultText());
    const total = data.length;
    const dropdownHeight = Math.min(dropdownSizing.minHeight + total * dropdownSizing.itemHeight, dropdownSizing.maxHeight);

    useEffect(() => {
        setQuery(getResultText());
    }, [selected, total]);

    function getResultText() {
        const actualSelected = selected.filter(guid => data.find(x => x.agentGuid === guid));

        return actualSelected.length === total || actualSelected.length === 0 ?
            capitalize(RCOP.Lang.ALL_SITES) :
            formatStr(lang.PROGRESS_SHOWN, actualSelected.length, total);
    }

    const hasCustomSelection: boolean = selected && selected.length > 0;

    return (
        <div>
            <Dropdown
                popoverConfig={[{
                    xAxis: X_AXIS.outsideRight,
                    yAxis: Y_AXIS.insideTop,
                }]}
                renderControl={({ button, actions, isActive }) => (
                    <DropdownControl
                        disabled={disabled}
                        button={button}
                        actions={actions}
                        query={query}
                        readonly
                        label={lang.SITES}
                        hoverable={false}
                        selected={hasCustomSelection}
                        active={isActive}
                    />
                )}
                renderPopover={({ popover, content }) => (
                    <View
                        {...popover}
                        backgroundView={VIEW_BG_VIEW.normal}
                        borderAll={VIEW_BORDER.brand}
                        size={{
                            width: dropdownSizing.width,
                            height: `${dropdownHeight}px`,
                        }}
                    >
                        {content}
                    </View>
                )}
                renderContent={({ actions }) => (
                    <GridContainer
                        spaceFill={SPACE_FILL.all}
                        direction={STACK_DIRECTION.column}
                    >
                        <ConfiguredGrid
                            data={() => Promise.resolve({
                                data,
                                meta: {
                                    pagingInfo: {
                                        total: data.length,
                                    },
                                },
                            })}
                            columns={[{
                                id: 'siteName',
                                title: lang.SITES,
                                cell: rowData => rowData.siteName,
                            }]}
                            selection={{
                                field: 'agentGuid',
                                checkbox: true,
                                multiple: true,
                            }}
                            initialState={{
                                selected: selection.map(x => ({ agentGuid: x })),
                                sort: {
                                    key: 'siteName',
                                    direction: GridSortDirections.Asc,
                                },
                            }}
                            sortingMode={SortingMode.Local}
                            onSelectionChange={
                                (items) => {
                                    if (skipSelectionAfterFilterChanging.val) {
                                        skipSelectionAfterFilterChanging.val = false;
                                    } else {
                                        setSelection(items.map(item => item.agentGuid));
                                    }
                                }
                            }
                            toggleable={false}
                        />

                        <StackView
                            distribution={STACK_DISTRIBUTION.end}
                            gap={STACK_GAP.m}
                            style={{ paddingTop: SPACING_M }}
                        >
                            <Button
                                onClick={() => {
                                    if (selection.length) {
                                        const resultSelected = selection.length === data.length ? [] : selection;
                                        skipSelectionAfterFilterChanging.val = true;
                                        onSelectionChange([...resultSelected]);
                                        setQuery(getResultText());
                                        actions.hide();
                                    } else {
                                        actions.hide();
                                        notificationService.warning(
                                            lang.WARNING,
                                            lang.AT_LEAST_ONE_CONDITION_NEEDS
                                        );
                                        setSelection(
                                            selected.length === 0
                                                ? data.map(filter => filter.agentGuid)
                                                : [...selected]
                                        );
                                    }
                                }}
                            >
                                {lang.APPLY}
                            </Button>

                            <Button
                                ui={BUTTON_VIEW.secondary}
                                onClick={actions.hide}
                            >
                                {lang.CANCEL}

                            </Button>
                        </StackView>
                    </GridContainer>
                )}
            />
        </div>
    );
};

