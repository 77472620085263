/**
 * Copyright © Veeam Software Group GmbH.
 */

import worldMap from '@highcharts/map-collection/custom/world.geo.json';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { GeoJSON } from 'highcharts';

import { checkIfMapExist, getLocationsBy, getMapByMapId } from '../../../helpers';

import type { GeoLocationData } from '../../../interfaces';

export const useGridStore = (data: GeoLocationData) => {
    const [countryMap, setCountryMap] = useState<GeoJSON | null>(null);

    const [locationAdmin0Code, setLocationAdmin0Code] = useState<string>();
    const [locationAdmin1Code, setLocationAdmin1Code] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const countries = useMemo(() => getLocationsBy(worldMap), []);
    const regions = useMemo(() => getLocationsBy(countryMap, false), [countryMap]);

    const countryName: string = useMemo(
        () => getLocationsBy(worldMap).find(country => country.id === locationAdmin0Code)?.name, [locationAdmin0Code]);
    const regionName: string = useMemo(
        () => getLocationsBy(countryMap).find(region => region.id === locationAdmin1Code)?.name, [locationAdmin1Code, countryMap]);

    const downloadMap = useCallback((mapId: string) => {
        if (!checkIfMapExist(mapId)) return;
        setIsLoading(true);

        getMapByMapId(mapId)
            .then(setCountryMap)
            .finally(() => setIsLoading(false));
    }, []);

    const selectCountry = useCallback((countryId: string) => {
        setLocationAdmin0Code(countryId);
        resetRegions();

        if (countryId) {
            updateRegionsMap(countryId);
        }

    }, []);

    const updateRegionsMap = useCallback((countryId: string) => {
        downloadMap(countryId);
    }, []);

    const selectRegion = useCallback((regionId: string) => {
        setLocationAdmin1Code(regionId);
    }, []);

    const resetRegions = useCallback(() => {
        setLocationAdmin1Code('');
        setCountryMap(null);
    }, []);

    const initialize = useCallback(({ locationAdmin0Code, locationAdmin1Code }: { locationAdmin0Code: string; locationAdmin1Code: string; }) => {
        if (!locationAdmin0Code) return;

        selectCountry(locationAdmin0Code);
        selectRegion(locationAdmin1Code);
    }, []);

    useEffect(() => {
        initialize(data);
    }, []);

    return {
        countries,
        regions,
        locationAdmin0Code,
        locationAdmin1Code,
        selectCountry,
        selectRegion,
        countryName,
        regionName,
        initialize,
        isLoading,
    };
};
