/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { EditUserProfileStoreContext } from '../edit-user-profile-store-context';

import type { EditUserProfileStore } from '../edit-user-profile-store';

export const useEditUserProfileStore = (): EditUserProfileStore => useContext(EditUserProfileStoreContext);
