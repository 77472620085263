/**
 * Copyright © Veeam Software Group GmbH.
 */

export const MAX_NUMBER_OF_ROWS_OPTIONS = [
    { id: 5, text: '5' },
    { id: 10, text: '10' },
    { id: 15, text: '15' },
    { id: 20, text: '20' },
    { id: 25, text: '25' },
];
