/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { EmptyLayout } from '@veeam-vspc/shared/components';

import { ExtComponent } from '../../components/Ext/ExtComponent';

export interface BackupServerTerminalPageProps {
    currentPath: string;
}

export const BackupServerTerminalPage: React.FC<BackupServerTerminalPageProps> = ({ currentPath }) => (
    <EmptyLayout currentPath={currentPath}>
        <ExtComponent
            isCustomViewport
        />
    </EmptyLayout>
);
