/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    GridSortDirections,
    PortalSpinner,
    ActionLinkColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalAppData,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { GeneratedReportModel, ReportParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import { AllReportsActionToolbar } from './components/AllReportsActionToolbar';
import { ReportsFilterToolbar } from '../ReportsFilterToolbar';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';


interface AllReportsProps {
    stateId: string;
}

export const AllReports: React.FC<AllReportsProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { transportService, fileTransportService } = useGlobalServices();
    const [loading, setLoading] = useState(false);
    const gridStateId = `${stateId}.AllReports`;
    const isCompany = portalUser.isClientPortal();

    const columns: GridColumnProps<GeneratedReportModel>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
            hidden: isCompany,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            hidden: true,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'fileName',
            title: lang.REPORT,
            cell: rowData => (
                <ActionLinkColumn
                    content={rowData.fileName}
                    onClick={() => {
                        setLoading(true);
                        fileTransportService.downloadFile(`/Report/GetGeneratedReportPdf?uid=${rowData.statementID}`)
                            .finally(() => setLoading(false));
                    }}
                />
            ),
        },
        {
            id: 'created',
            title: lang.GENERATED_ON,
            cell: rowData => (
                <GridDateColumn value={rowData.created} />
            ),
        },
        {
            id: 'locations',
            title: lang.LOCATIONS,
            cell: rowData => rowData.locations,
            defaultValue: lang.NO_INFO,
            hidden: portalUser.isPortalOrSiteAdmin() || portalUser.isPortalOperator(),
        },
        {
            id: 'typeName',
            title: lang.TYPE,
            cell: rowData => rowData.typeName,
            defaultValue: lang.NO_INFO,
        },
    ];

    return (
        <>
            <ConfiguredGrid
                sectionId={ConfigSectionIds.ReportsAllReports}
                stateId={gridStateId}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: 'created',
                    },
                }}
                defaultColumnMinWidth={80}
                columns={columns}
                data={(requestParams: ReportParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<GeneratedReportModel[]>>('/Report/GetGeneratedReports', {
                        ...requestParams,
                    })}
                toggleable={true}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <ReportsFilterToolbar withDateFilter />,
                    () => <AllReportsActionToolbar />,
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
        </>

    );
};
