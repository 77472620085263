/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum SMTPCertStatus {
    NoCert = 'noCertificate',
    Trusted = 'trusted',
    Untrusted = 'untrusted',
    UntrustedAccepted = 'untrustedAccepted',
}
