/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    BASE_FONT_FAMILY,
    BASE_FONT_NORMAL_WEIGHT,
    colors as palette,
    FONT_LINE_HEIGHT_S,
    FONT_SIZE_S,
} from '@veeam-vspc/shared/components';

import type * as Highcharts from 'highcharts';

import type { CustomParams } from '../interfaces';

export const getBaseOptions = ({
    categories,
    colors,
    height,
    legend = true,
    series,
    yTitle = '',
}: CustomParams): Highcharts.Options => ({
    colors,
    series,
    title: { text: '' },

    chart: {
        backgroundColor: 'transparent',
        height,
        style: { fontFamily: BASE_FONT_FAMILY },
    },

    credits: {
        enabled: false,
    },

    legend: {
        enabled: legend,
        itemDistance: 32,
        itemMarginTop: 2,
        margin: 16,
        padding: 3,
        symbolPadding: 4,
        symbolHeight: 8,
        symbolWidth: 8,

        itemStyle: {
            fontSize: FONT_SIZE_S,
            fontWeight: BASE_FONT_NORMAL_WEIGHT,
            lineHeight: FONT_LINE_HEIGHT_S,
        },

    },

    plotOptions: {
        series: {
            stickyTracking: false,

            events: {
                legendItemClick: function() {
                    let visibleCount = 0;

                    this.chart.series.forEach(s => visibleCount += s.visible ? 1 : 0);

                    if (this.visible && visibleCount === 1) {
                        return false;
                    }

                    this.chart.legend.update({
                        itemStyle: {
                            cursor: this.visible && visibleCount === 2 ? 'default' : 'pointer',
                        },
                    });
                },
            },

            states: {
                hover: {
                    halo: undefined,
                    lineWidthPlus: 0,
                },
            },
        },
    },

    tooltip: {
        backgroundColor: palette.G000,
        borderColor: palette.G300,
        borderRadius: 0,
        hideDelay: 0,
        padding: 0,
        shape: 'square',
        shared: true,
        useHTML: true,

        shadow: {
            color: palette.G300,
            width: 5,
        },
    },

    xAxis: {
        categories,
        lineColor: palette.G350,

        labels: {
            autoRotation: [0],

            style: {
                color: palette.G600,
                fontSize: FONT_SIZE_S,
            },
        },
    },

    yAxis: {
        allowDecimals: false,
        lineColor: palette.G350,
        softMin: 0,
        tickAmount: 5,
        title: { text: yTitle },

        labels: {
            format: '{value}',

            style: {
                color: palette.G600,
                fontSize: FONT_SIZE_S,
            },
        },
    },
});
