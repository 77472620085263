/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ACTION_VIEW,
    MODAL_SIZE_MS,
    useGlobalAppStorage,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { PopupType } from '@veeam-vspc/models/web-controllers';
import dayjs from 'dayjs';
import { plural, TextDividerSymbols } from '@veeam-vspc/shared/helpers';
import { NotificationDialogsIcons, NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { ConfigStates, TimeUnitsInMs } from '@veeam-vspc/shared/core';
import { useEffect } from 'react';

import type { ParsedTextConfig } from '@veeam-vspc/shared/helpers';
import type { LicenseUsageReportingInfo, ProductUsageInfo } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { ConfigItemIds, ConfigSectionIds } from 'core/enums';

export const useMonthlyUsageReport = (showApp: boolean) => {
    const lang = useGlobalLang<VspcLang>();
    const appStorage = useGlobalAppStorage();
    const { portalEnums } = useGlobalAddons();
    const { transportService, notificationService, configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.MonthlyUsageReportDialog);
    const getDateMessage = (finalizeDate: string) => {
        const date1 = dayjs(new Date());
        const date2 = dayjs(new Date(Date.parse(finalizeDate)));
        const leftDay = date2.diff(date1, 'd') + 1;

        return leftDay === 0
            ? lang.TODAY.toLowerCase()
            : `${lang.WITHIN_THE_NEXT.toLowerCase()} ${plural(leftDay, lang.DAY)}.`;
    };
    const getUsageInfoConfigs = (usageInfo: ProductUsageInfo[]) => usageInfo.reduce((res, usageInfoItem) => {
        const { workloadUsages, usageType } = usageInfoItem;

        return res.concat([
            { text: `${portalEnums.getEnumDescription('ApprovalTarget', usageType)}`, props: { asTag: 'b' } },
            { type: TextDividerSymbols.Paragraph },
            ...(workloadUsages.reduce((result, workloadUsageItem) => {
                const { identifier, newCount, managedCount } = workloadUsageItem;

                return result.concat([
                    { text: `${identifier.name}: ` },
                    { text: managedCount, props: { asTag: 'b' } },
                    { text: newCount ? ` (${newCount} ${lang.NEW.toLowerCase()})` : '' },
                    { type: TextDividerSymbols.Paragraph },
                ]);
            }, [])),
            { type: TextDividerSymbols.BlankLine },
        ]);
    }, []);
    const checkMonthlyUsageReport = () => {
        const isRoleAllowed = sectionConfig
            ? sectionConfig.getItemState(ConfigItemIds.MonthlyUsageReport) === ConfigStates.Visible
            : false;

        if (isRoleAllowed) {
            transportService.request<unknown, RequestSuccessResponse<LicenseUsageReportingInfo>>('/License/GetMonthlyUsageInfo')
                .then(({ data }: RequestSuccessResponse<LicenseUsageReportingInfo>) => {
                    const {
                        showReviewPopup,
                        popupType,
                        periodUID,
                        finalizeDate,
                        totalCount,
                        totalInstances,
                        note,
                        usageInfo,
                    } = data;
                    const dayInMs = TimeUnitsInMs.Day;
                    const showMonthlyUsageTime = parseInt(appStorage.getItem('showMonthlyUsageTime'), 10);
                    const isMonthlyUsageShowed = Boolean(appStorage.getSessionItem('isMonthlyUsageShowed'));
                    const showUsageMsg = (!showMonthlyUsageTime || Number.isNaN(showMonthlyUsageTime) || Date.now() - dayInMs > showMonthlyUsageTime);
                    const isShowMsg = !isMonthlyUsageShowed && showReviewPopup && showUsageMsg;

                    if (!isShowMsg) {
                        return;
                    }

                    const isReview = popupType === PopupType.Review;
                    const dateMessage = getDateMessage(finalizeDate);
                    const usageInfoConfigs = usageInfo ? getUsageInfoConfigs(usageInfo) : [];
                    const reviewMessage: ParsedTextConfig[] = [
                        { text: lang.MONTHLY_USAGE_REPORT_HAS_BEEN_GENERATED },
                        { type: TextDividerSymbols.BlankLine },
                        { text: `${lang.TOTAL_NUMBER_OF_WORKLOADS_MANAGED_FOR} ` },
                        { text: String(totalCount), props: { asTag: 'b' } },
                        { text: ` (${totalInstances} ${lang.CONSUMED_POINTS.toLowerCase()}):` },
                        { type: TextDividerSymbols.BlankLine },
                        ...usageInfoConfigs,
                        ...(note ? [{ text: note || '' }, { type: TextDividerSymbols.BlankLine }] : []),
                        { text: `${lang.PLEASE_REVIEW_AND_SUBMIT_THE_REPORT} ${dateMessage}` },
                    ];
                    const message: ParsedTextConfig[] = [
                        { text: lang.FAILED_TO_AUTO_APPROVE_YOUR_MONTHLY },
                        ...(note ? [{ type: TextDividerSymbols.BlankLine }, { text: note || '' }] : []),
                    ];

                    return notificationService.custom(lang.MONTHLY_USAGE_REPORT, isReview ? reviewMessage : message, {
                        independent: true,
                        icon: isReview ? NotificationDialogsIcons.Info : NotificationDialogsIcons.Warning,
                        width: MODAL_SIZE_MS.width,
                        buttons: [
                            { type: NotificationDialogsTextKeys.Ok, text: lang.REVIEW },
                            {
                                type: NotificationDialogsTextKeys.Cancel,
                                view: ACTION_VIEW.secondary,
                                text: isReview ? lang.POSTPONE : lang.CLOSE,
                            },
                        ],
                    })
                        .then((buttonKey) => {
                            appStorage.setSessionItem('isMonthlyUsageShowed', 'true');

                            if (buttonKey !== NotificationDialogsTextKeys.Cancel) {
                                appStorage.setItem('showMonthlyUsageTime', String(Date.now()));
                            }

                            if (buttonKey === NotificationDialogsTextKeys.Ok) {
                                window.open(
                                    `${document.location.origin}/config/adjustreports?periodUid=${periodUID}`,
                                    '_blank',
                                    'noopener,noreferrer',
                                );
                            }
                        });
                });
        }
    };

    useEffect(() => {
        if (showApp) {
            checkMonthlyUsageReport();
        }
    }, [showApp]);
};
