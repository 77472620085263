/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum AsyncActionNames {
    RemoveVb365Organizations ='RemoveVb365Organizations',
    DeleteVb365Server ='DeleteVb365Server',
    ForceCollectVb365Server ='ForceCollectVb365Server',
    RemoveVb365Organization ='RemoveVb365Organization',
    CreateVb365Microsoft365Organization ='CreateVb365Microsoft365Organization',
    PatchVb365Microsoft365Organization ='PatchVb365Microsoft365Organization',
    RemoveVb365Jobs ='RemoveVb365Jobs',
    StartVb365Jobs ='StartVb365Jobs',
    StopVb365Jobs ='StopVb365Jobs',
    DisableVb365Jobs ='DisableVb365Jobs',
    EnableVb365Jobs ='EnableVb365Jobs',
    CreateVb365BackupJob ='CreateVb365BackupJob',
    PatchVb365BackupJob ='PatchVb365BackupJob',
    CreateVb365CopyJob ='CreateVb365CopyJob',
    PatchVb365CopyJob ='PatchVb365CopyJob',
}
