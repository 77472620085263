/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum MatchRule {
    Unknown = 'Unknown',
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    Contains = 'Contains',
    NotContains = 'NotContains',
    MatchRegex = 'MatchRegex',
}
