/**
 * Copyright © Veeam Software Group GmbH.
 */

import { BackupTaskTypes } from '@veeam-vspc/models/web-controllers';

import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';

import { transformBaseChartData } from 'views/pages/TrendsPage/helpers';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { TrendData } from 'views/pages/TrendsPage/interfaces';

const names = [
    { label: 'Ratio', type: BackupTaskTypes.BackupTask },
];

export class BackupTasksRatioService {

    constructor(
        private transportService: TransportService<RequestErrorResponse | Error>,
        private langService: VspcLang,
    ) {}

    fetchData({ reportingPeriodInfo }): Promise<ChartDataWithCategories> {
        return this.transportService
            .request('/Trends/SuccessfulBackup', { ...reportingPeriodInfo })
            .then((resp: RequestSuccessResponse<TrendData>) => {
                resp.data.trends.forEach(trend => trend.counters.forEach((c) => {
                    c.value = c.value === null ? null : Math.round(c.value);
                }));

                return transformBaseChartData(this.langService, resp.data.trends, reportingPeriodInfo, names);
            });
    }
}
