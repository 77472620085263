/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import { colors, useGlobalAppData, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';
import { useLocation } from 'react-router-dom';
import { ReportIntervalRepresentation } from '@veeam-vspc/models/web-controllers';

import type { PieChartModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { BillingWidget } from '../../../BillingWidget';
import { Entity } from 'views/components/HighchartsWrapper/enums';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { IntervalCombobox } from '../IntervalCombobox';
import { IntervalContext } from '../IntervalCombobox/contexts';
import { IntervalStore } from '../IntervalCombobox/stores';
import { getDonutOptions } from 'views/components/HighchartsWrapper/helpers';
import { transformDonutData } from 'views/components/ChartWidget/helpers';
import { useIntervalRequest, useLocalStorageValue } from 'views/hooks';
import { useIntervalStore } from '../IntervalCombobox/hooks';

import type { PieData } from 'views/components/HighchartsWrapper/interfaces';

const MonthlyRevenueStateWidget = observer(() => {
    const abortController = useRef(new AbortController());
    const didMount = useRef(false);
    const lang = useGlobalLang<VspcLang>();
    const store = useIntervalStore();
    const { interval } = store;
    const { portalUser } = useGlobalAppData();

    const request = useIntervalRequest<{}, PieChartModel>(
        '/Billing/GetBillingSummaryMonthlyIncomeState',
        { interval },
        {
            requestInit: {
                signal: abortController.current.signal,
            },
        },
        5 * TimeUnitsInMs.Minute,
    );

    const { empty, series } = request.data
        ? transformDonutData(request.data as PieChartModel, items => items.every(i => !i.capacity && !i.value))
        : {} as PieData;

    const href = portalUser.isPortalOrSiteAdmin() || portalUser.isPortalOperator() || portalUser.isResellerPortal()
        ? '/home/invoices/configurations'
        : null;

    const emptyDesc = {
        href,
        linkText: lang.GENERATE_INVOICE,
        text: lang.GENERATE_INVOICE_TO_VIEW_THE_DATA,
    };

    const params = {
        colors: [colors.B400, colors.G250],
        lang,
    };

    const options = getDonutOptions({
        categories: [],
        entity: Entity.Paid,
        height: 232,
        series,
        size: 200,
        ...params,
    });

    const title = {
        text: lang.MONTHLY_REVENUE_STATE,
        tools: <IntervalCombobox />,
    };

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();
            abortController.current = new AbortController();

            return request.forceRequest();
        }

        didMount.current = true;
    }, [interval]);

    return (
        <BillingWidget title={title}>
            <HighchartsWrapper
                empty={empty}
                emptyDesc={emptyDesc}
                loading={request.loading}
                options={options}
            />
        </BillingWidget>
    );
});

export const MonthlyRevenueState: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.MonthlyRevenueState`;
    const initialInterval = useLocalStorageValue<ReportIntervalRepresentation>(stateId, ReportIntervalRepresentation.LastMonth);

    const store = useMemo(() => new IntervalStore(initialInterval, stateId), [initialInterval, stateId]);

    return (
        <IntervalContext.Provider value={store}>
            <MonthlyRevenueStateWidget />
        </IntervalContext.Provider>
    );
};
