/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { SignPost, StackView, Text, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

export const StyledText = styled(Text)`
  width: max-content;
`;

interface SuffixProps {
    variables: string;
}

export const Suffix: React.FC<SuffixProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { variables } = props;

    return (
        <StackView>
            <SignPost
                width={'min-content'}
                children={(
                    <>
                        <StyledText>{`${lang.EMAIL_NOTIFICATION_SUBJECT_ALLOWS_USING}:`}</StyledText>
                        <Text>{variables}</Text>
                    </>
                )}
            />
        </StackView>
    );
};
