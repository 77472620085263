/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';

import { trimTextFields } from 'core/utils';

import type { ConfigurationFormData } from '../interfaces';

export const trimConfigurationFormData = (formData: ConfigurationFormData): ConfigurationFormData => {
    const newFormData = deepCopy(formData);

    trimTextFields<ConfigurationFormData>(newFormData, ['defaultFrom']);
    trimTextFields<ConfigurationFormData['billing']>(newFormData.billing, ['from', 'subject']);
    trimTextFields<ConfigurationFormData['alarms']>(newFormData.alarms, ['from', 'to', 'dailySubject']);
    trimTextFields<ConfigurationFormData['discovery']>(newFormData.discovery, ['from', 'to', 'subject']);

    return newFormData;
};
