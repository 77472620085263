/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants } from '@veeam-vspc/shared/components';

export const EmptyPlateStyled = styled.div`
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 100%;
    justify-content: center;
    justify-items: center;
    padding-bottom: ${constants.SPACING_XL};
    width: 100%;
`;
