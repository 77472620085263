/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SPACING_XL } from '@veeam-vspc/shared/components';

export const Separator = styled.hr`
  width: 100%;

  border: none;
  border-top: 1px solid #c0c0c0;
  margin: ${SPACING_XL} 0;
`;
