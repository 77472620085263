/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
    GridSortDirections,
    NOTEBAR_STATUS,
    Text,
    NoteBar,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useGlobalLang,
    useGlobalServices,
    createSearchableControl,
} from '@veeam-vspc/shared/components';
import { SoftwareStatusRepresentation } from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type { DiscoveredComputerModel, CompanyInfoShort } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { MAX_INTEGER_32 } from 'core/const';

export interface CompanyDialogProps {
    selected: DiscoveredComputerModel[];
    onCompanyChosen: (companyId: string) => void;
    deactivate: () => void;
}

interface CompanyForm {
    companyId: string;
}

const formValidate = (data: CompanyForm) => {
    const validator = new FormValidator(data);

    validator.validate('companyId')
        .number()
        .required();

    return validator.result();
};

export const CompanyDialog: React.FC<CompanyDialogProps> = ({ selected, deactivate, onCompanyChosen }) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService, transportService } = useGlobalServices();
    const [data] = useState<CompanyForm>({
        companyId: null,
    });
    const [companiesList, setCompaniesList] = useState<CompanyInfoShort[]>([]);
    const formApi = useExternalFormApi<CompanyForm>();
    const [validationState] = useState(new ValidationState());

    useEffect(() => {
        transportService.request('/Agent/GetCompaniesMoveTo', {
            limit: MAX_INTEGER_32,
            sort: [{ property: 'name', direction: GridSortDirections.Asc }],
            page: 1,
            start: 0,
        })
            .then((resp: RequestSuccessResponse<CompanyInfoShort[]>) => {
                setCompaniesList(resp.data);
            });
    }, []);

    const shouldWarn = selected
        .some(item => item.isVawInstalled === SoftwareStatusRepresentation.Installed);

    return (
        <Dialog
            header={lang.SET_COMPANY}
            description={lang.SET_COMPANY_FOR_THE_SELECTED_AGENTS}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        validationState.markAsHandledForce();
                        if (formApi.isValid()) {
                            const chosenCompany = companiesList.find(company => company.id === data.companyId);

                            if (shouldWarn && !chosenCompany.isSystem) {
                                notificationService
                                    .confirm(lang.SET_COMPANY, lang.THIS_WILL_SET_A_NEW_COMPANY)
                                    .then((btnKey: NotificationResponse) => {
                                        if (btnKey === NotificationDialogsTextKeys.Yes) {
                                            onCompanyChosen(data.companyId);
                                        }
                                    });
                            } else {
                                onCompanyChosen(data.companyId);
                            }
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
        >
            <Form <CompanyForm>
                value={data}
                validate={formValidate}
                validationState={validationState}
                externalFormApi={formApi}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <FormLayout inlineLabel>
                        <Combobox
                            name={'companyId'}
                            label={lang.COMPANY}
                            data={companiesList}
                            valueGetter={item => item.id}
                            textGetter={(item) => {
                                let textValue = item.name;
                                if (item.showSiteName) {
                                    textValue += ` (${item.siteName})`;
                                }

                                return textValue;
                            }}
                            controlRenderer={createSearchableControl()}
                            size={CONTROL_SIZE.full}
                        />
                    </FormLayout>

                    <NoteBar status={NOTEBAR_STATUS.info}>
                        <Text>{lang.THE_CHOSEN_COMPANY_WILL_BE_SET_FOR_THE_SELECTED_AGENTS}</Text>
                    </NoteBar>
                </StackView>
            </Form>
        </Dialog>
    );
};
