/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { Combobox, CONTROL_SIZE, FormLayout, LabelGroup, NumberInput, STACK_DIRECTION, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { RpoPeriod } from '../../../enums';

interface Props {
    disabled?: boolean;
    label?: string | React.ReactElement;
}

export const RpoInterval: React.FC<Props> = ({ disabled = false, label }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <LabelGroup label={label || `${lang.RPO}:`}>
            <FormLayout direction={STACK_DIRECTION.row} disabled={disabled}>
                <NumberInput
                    name='parameters.rpoInterval.number'
                    size={CONTROL_SIZE.xs}
                />

                <Combobox
                    name='parameters.rpoInterval.period'
                    data={[
                        {
                            id: RpoPeriod.Hour,
                            text: capitalize(lang.HOURS),
                        },
                        {
                            id: RpoPeriod.Day,
                            text: lang.DAYS,
                        },
                        {
                            id: RpoPeriod.Week,
                            text: lang.WEEKS,
                        },
                        {
                            id: RpoPeriod.Month,
                            text: lang.MONTHS,
                        },
                    ]}
                    valueGetter={item => item.id}
                    textGetter={item => item.text}
                    size={CONTROL_SIZE.xs}
                />
            </FormLayout>
        </LabelGroup>
    );
};
