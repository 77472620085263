/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum PluginsFilter {
    All,
    PublicCloud,
    Licensing,
    Billing,
    Storage,
    RMM,
    RESTAPI,
}
