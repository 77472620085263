/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { colors, useGlobalAddons } from '@veeam-vspc/shared/components';
import { reaction } from 'mobx';

import { BaseTrendChartContext } from './contexts';
import { BaseTrendChartStore } from './stores';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { ChartWidget } from 'views/components/ChartWidget';
import { getColumnOptions, getLineOptions } from 'views/components/HighchartsWrapper/helpers';
import { useBaseTrendChartStore } from './hooks';
import { useFilterStore } from '../../hooks';
import { TREND_WIDGET_HEIGHT } from './const';

import type { BaseChartProps, BaseTrendChartProps } from './interfaces';

const BaseChart = observer(({ objectsType, params, title }: BaseChartProps) => {
    const store = useBaseTrendChartStore();
    const { portalFilters } = useGlobalAddons();
    const { categories, dimension, empty, loading, series } = store;
    const { backupServicesType, reportingPeriodInfo } = useFilterStore();
    const [options, setOptions] = useState({});

    const fetchData = () => {
        store.fetchData({ backupServicesType, objectsType, reportingPeriodInfo });
    };

    useEffect(fetchData, [backupServicesType, objectsType, reportingPeriodInfo, store]);

    useEffect(() => {
        if (series?.length === 0) {
            return;
        }

        setOptions((params.type === 'column' ? getColumnOptions : getLineOptions)({
            categories,
            legend: series?.length > 1,
            height: 302,
            series,
            spacingBottom: series?.length === 1,
            ...Object.assign({}, params, {
                yTitle: dimension ? `${params.yTitle}, ${dimension}` : params.yTitle,
            }),
        }));
    }, [categories, dimension, params, series]);

    useEffect(() => {
        if (portalFilters) {
            return reaction(
                () => [portalFilters.locationFilter, portalFilters.siteFilter.join(','), portalFilters.organizationFilter],
                () => {
                    fetchData();
                },
            );
        }
    }, [portalFilters]);

    return (
        <ChartWidget
            style={{ backgroundColor: empty ? colors.G100 : colors.G000, height: TREND_WIDGET_HEIGHT }}
            title={title}
        >
            <HighchartsWrapper
                empty={empty}
                loading={loading}
                options={options}
            />
        </ChartWidget>
    );
});

export const BaseTrendChart = ({ service, ...rest }: BaseTrendChartProps) => {
    const baseTrendChartStore = useMemo(() => new BaseTrendChartStore(service), [service]);

    return (
        <BaseTrendChartContext.Provider value={baseTrendChartStore}>
            <BaseChart {...rest} />
        </BaseTrendChartContext.Provider>
    );
};
