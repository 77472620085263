/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { createDefaultDatetimeInputControl, Datetime, useGlobalAppData } from '@veeam-vspc/shared/components';
import { formatDate, normalizeOldDateFormat } from '@veeam-vspc/shared/helpers';

import type { DefaultDatetimeInputControlValueFormatterPayload, DatetimeProps } from '@veeam-vspc/shared/components';

export const DatetimeFormatted: React.FC<DatetimeProps> = (props) => {
    const { formats } = useGlobalAppData();

    return (
        <Datetime
            controlRenderer={createDefaultDatetimeInputControl({
                valueFormatter: ({ value }: DefaultDatetimeInputControlValueFormatterPayload): string =>
                    value ? formatDate(value, normalizeOldDateFormat(formats.netShortDate)) : '',
            })}
            {...props}
        />
    );
};
