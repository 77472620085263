/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { SPACING_M } from '@veeam-vspc/shared/components';

import { ChartWidget } from 'views/components/ChartWidget';

import type { TitleProps } from 'views/components/ChartWidget/interfaces';

interface BillingWidgetProps {
    children: React.ReactElement;
    title: TitleProps;
}

export const BillingWidget = ({ children, title }: BillingWidgetProps) => (
    <ChartWidget
        style={{
            float: 'left',
            height: 370,
            margin: `0 ${SPACING_M} ${SPACING_M} 0`,
            width: 500,
        }}
        title={title}
    >
        {children}
    </ChartWidget>
);
