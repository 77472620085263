/**
 * Copyright © Veeam Software Group GmbH.
 */

import { addOriginToUrl } from '@veeam-vspc/shared/core';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';

import type {
    FlrAvailability,
    FlrAvailabilityParams,
    FlrRedirectParams,
    FlrRedirectToken,
    OpenFlrParams,
} from '../interfaces';

export class ComputersService {
    constructor(
        private transportService: TransportService<RequestErrorResponse | Error>,
        private notificationService: NotificationService,
    ) {}

    async openFlr(lang: VspcLang, { agentId, tabUrl, userId, vspcVersion }: OpenFlrParams): Promise<void> {
        this.transportService.request<FlrAvailabilityParams, RequestSuccessResponse<FlrAvailability>>('/FileLevelRestore/IsFlrAvailable', { agentId })
            .then((response: RequestSuccessResponse<FlrAvailability>) => {
                const data = response.data as FlrAvailability;

                if (data.flrAvailable === false) {
                    this.notificationService.error(lang.ERROR, data.message);

                    return;
                }

                this.transportService
                    .request<FlrRedirectParams, RequestSuccessResponse<FlrRedirectToken>>('/FileLevelRestore/GetRedirectToken', { agentId })
                    .then((response: RequestSuccessResponse<FlrRedirectToken>) => {
                        const url = new URL(addOriginToUrl((response.data as FlrRedirectToken)?.redirectUrl));

                        url.searchParams.append('code', (response.data as FlrRedirectToken)?.authCode);
                        url.searchParams.append('agentId', agentId);
                        url.searchParams.append('userId', userId);
                        url.searchParams.append('vspcVersion', vspcVersion);

                        if (tabUrl && typeof tabUrl === 'string') {
                            url.hash = tabUrl;
                        }

                        window.open(url.href, '_blank', 'noopener,noreferrer');
                    });
            });
    }
}
