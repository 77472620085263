/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationSettingsLevel } from '@veeam-vspc/models/rest';

import type { NotificationSettings } from '@veeam-vspc/models/rest';

import { NotificationLevel } from '../enums';

export const getLevelForSlider = (serverValue: NotificationSettings['level']): NotificationLevel => {
    switch (serverValue) {
        case NotificationSettingsLevel.All:
            return NotificationLevel.EveryEvent;

        case NotificationSettingsLevel.Summary:
            return NotificationLevel.Summary;

        case NotificationSettingsLevel.Disabled:
        default:
            return NotificationLevel.NoEmails;
    }
};
