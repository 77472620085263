/**
 * Copyright © Veeam Software Group GmbH.
 */

import { isDate } from './is-date';

export function shortDate(date, formats) {
    if (!isDate(date)) {
        date = new Date(Date.parse(date));
    }

    if (!isDate(date) || Number.isNaN(date.getTime()) || date <= new Date(0)) {
        return '';
    }

    return Ext.Date.format(date, formats.shortDate);
}
