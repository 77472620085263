/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { Vb365JobLastStatus } from '@veeam-vspc/models/rest';
import { TextDividerSymbols } from '@veeam-vspc/shared/helpers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { Vb365Job } from '@veeam-vspc/models/rest';
import type { ParsedTextConfig } from '@veeam-vspc/shared/helpers';
import type { VspcLang } from 'configs/languages';

import { getMarkedList, getStatusIconSrc } from 'core/utils';

export const JobStatusColumn = ({ rowData }: { rowData: Vb365Job; }) => {
    const { lastStatus, lastStatusDetails, lastErrorLogRecords } = rowData;
    const lang = useGlobalLang<VspcLang>();

    const isLastStatusValid = lastStatus && lastStatus !== Vb365JobLastStatus.Unknown;

    const iconSrc = getStatusIconSrc(lastStatus);

    const message: ParsedTextConfig[] = [{
        text: lastStatusDetails,
    }];

    if (lastErrorLogRecords?.length) {
        message.push(
            { type: TextDividerSymbols.BlankLine },
            { text: `${lang.DETAILS}:` },
        );
        message.push(...getMarkedList(lastErrorLogRecords.map(x => x.message)));
    }

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {isLastStatusValid ? lastStatus : ColumnTexts.DashSymbol}
                </TextColumn>
            )}
            isLink={[Vb365JobLastStatus.Warning, Vb365JobLastStatus.Failed].includes(lastStatus)}
            contentIconBefore={isLastStatusValid ? iconSrc : ''}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.JOB_STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        { text: lang.OK, onClick: deactivate },
                    ]}
                    iconSrc={iconSrc}
                >
                    <ParsedText textOrConfig={message} />
                </MessageBox>
            )}
        />
    );
};
