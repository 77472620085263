/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileTagAttributesValues, XmlFileTagQueries } from '../../../enums';
import { getTagValueAttr, getXmlTagByQuery } from '../../../helpers';

export const checkReboot = (xml: XMLDocument): boolean => {
    const rebootTag = getXmlTagByQuery(xml, XmlFileTagQueries.RebootIfRequired);

    if (!rebootTag) {
        return true;
    }

    return [XmlFileTagAttributesValues.Decline, ''].includes(getTagValueAttr(rebootTag));
};
