/**
 * Copyright © Veeam Software Group GmbH.
 */
import { LINK_TARGET, ParsedText, SignPost, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

const HIGHCHARTS_LINK = 'https://www.highcharts.com/';
const NATURAL_EARTH_LINK = 'https://www.naturalearthdata.com/';

export const HighchartSourceText = (): React.ReactElement => {
    const lang = useGlobalLang<VspcLang>();
    return (
        <ParsedText
            textOrConfig={[
                {
                    text: `${lang.DATA_FOR_COUNTRIES_AND_REGIONS_IS_TAKEN_FROM} `,
                },
                {
                    text: 'Highcharts.com',
                    linkProps: {
                        href: HIGHCHARTS_LINK,
                        target: LINK_TARGET.blank,
                    },
                    link: true,
                },
                {
                    text: ' © ',
                },
                {
                    text: 'Natural Earth',
                    linkProps: {
                        href: NATURAL_EARTH_LINK,
                        target: LINK_TARGET.blank,
                    },
                    link: true,
                },
            ]}
        />
    );
};
export const HighchartSourceIndicator = (): React.ReactElement => (
    <SignPost>
        <HighchartSourceText />
    </SignPost>
);
