/**
 * Copyright © Veeam Software Group GmbH.
 */

export const addPercentageToMax = (maxValue: number) => {
    let percentage: number;

    if (maxValue > 1000) {
        percentage = 5;
    } else {
        percentage = 10;
    }

    return maxValue + (maxValue * percentage) / 100;
};
