/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable, observable } from 'mobx';
import { CommonLicenseStateRepresentation } from '@veeam-vspc/models/web-controllers';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { LicenseStatusService } from '../services';

const SORT_ORDER = [CommonLicenseStateRepresentation.Valid, CommonLicenseStateRepresentation.Warning, CommonLicenseStateRepresentation.Error];

export class LicenseStatusStore {
    @observable categories: string[];
    @observable empty: boolean;
    @observable loading: boolean;
    @observable series: [];

    constructor(protected transportService: TransportService<RequestErrorResponse | Error>) {
        makeObservable(this);
    }

    sortSeriesByStatus(series) {
        if (!series || series.length === 0) return;

        series[0].data.sort((a, b) => SORT_ORDER.indexOf(a.name.toLowerCase()) - SORT_ORDER.indexOf(b.name.toLowerCase()));
    }

    @action
    fetchData(params): void {
        this.setLoading(true);

        LicenseStatusService
            .fetchData(this.transportService, params)
            .then((data) => {
                this.setEmpty(data.empty);

                this.sortSeriesByStatus(data.series);
                this.setSeries(data.series);
                this.setLoading(false);
            })
            .catch(() => {
                this.setLoading(false);
            });
    }

    @action.bound
    setEmpty(empty: boolean): void {
        this.empty = empty;
    }

    @action.bound
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action.bound
    setSeries(series): void {
        this.series = series;
    }
}
