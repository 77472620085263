/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { ERdsBackupType } from '@veeam-vspc/models/web-controllers';

import type { PublicCloudRdsModel, PublicCloudRdsParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { CloudDatabasesActionToolbar, CloudDatabasesColumn, CloudDatabasesFilterToolbar } from './components';


export interface CloudDatabasesProps {
    stateId: string;
}

export const CloudDatabases: React.FC<CloudDatabasesProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const gridStateId = `${stateId}.CloudDatabases`;

    const columns: GridColumnProps<PublicCloudRdsModel>[] = [
        {
            id: 'vmName',
            title: lang.INSTANCE,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
            width: 150,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'policyName',
            title: lang.POLICY,
            cell: rowData => rowData.policyName,
        },
        {
            id: 'platformType',
            title: lang.PLATFORM,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platformType)}
                </TextColumn>
            ),
        },
        {
            id: 'backup.backupCount',
            title: lang.BACKUPS,
            cell: rowData => (
                <CloudDatabasesColumn
                    value={rowData.backup?.backupCount}
                    name={`${lang.BACKUPS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupType.Backup,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.'Backup`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'archive.backupCount',
            title: lang.ARCHIVES,
            cell: rowData => (
                <CloudDatabasesColumn
                    value={rowData.archive?.backupCount}
                    name={`${lang.ARCHIVES} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupType.Archive,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.Archive`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'snapshot.backupCount',
            title: lang.SNAPSHOTS,
            cell: rowData => (
                <CloudDatabasesColumn
                    value={rowData.snapshot?.backupCount}
                    name={`${lang.SNAPSHOTS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupType.Snapshot,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.Snapshot`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'remoteSnapshot.backupCount',
            title: lang.REPLICA_SNAPSHOTS,
            cell: rowData => (
                <CloudDatabasesColumn
                    value={rowData.remoteSnapshot?.backupCount}
                    name={`${lang.REPLICA_SNAPSHOTS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupType.ReplicaSnapshot,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.RemoteSnapshot`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            width: 150,
        },
        {
            id: 'engine',
            title: lang.ENGINE,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('ERdsEngineType', rowData.engine)}
                </TextColumn>
            ),
        },
        {
            id: 'totalSize',
            title: lang.TOTAL_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.totalSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'region',
            title: lang.REGION,
            cell: rowData => rowData.region,
        },
        {
            id: 'backup.lastBackup',
            title: lang.LAST_BACKUP,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backup?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'archive.lastBackup',
            title: lang.LAST_ARCHIVE,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.archive?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'snapshot.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.snapshot?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'remoteSnapshot.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.remoteSnapshot?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'policyState',
            title: lang.POLICY_STATE,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('JobStateRepresentation', rowData.policyState)}
                </TextColumn>
            ),
        },
        {
            id: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.resourceId,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataCloudDatabases}
            defaultColumnMinWidth={100}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            columns={columns}
            data={(requestParams: PublicCloudRdsParam) => transportService
                .request<PublicCloudRdsParam, RequestSuccessResponse<PublicCloudRdsModel[]>>('/PublicCloudRds/GetProtectedRds', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'uid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <CloudDatabasesFilterToolbar />,
                () => <CloudDatabasesActionToolbar idProperty={'uid'} selectedOnly />,
            ]}
        />
    );
};
