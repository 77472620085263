/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { StackView, STACK_DIRECTION, STACK_GAP, RadiobuttonKit, FieldLayout, constants } from '@veeam-vspc/shared/components';

import type { FIELD_LAYOUT_HELP_TEXT_VIEW, Styleable } from '@veeam-vspc/shared/components';

export interface LinkProps {
    disabled: boolean;
}

export interface RadioGroupButtons<T> {
    value: T;
    label?: string;
    disabled?: boolean;
    render?: () => React.ReactNode;
    helpText?: string | React.ReactElement;
    helpTextView?: FIELD_LAYOUT_HELP_TEXT_VIEW;
    link?: (props: LinkProps) => React.ReactNode;
    buttonStyle?: Styleable['style'];
}

interface RadioGroupProps<T> {
    value: T;
    onChange: (T) => void;
    buttons: RadioGroupButtons<T>[];
}

const LinkWrapper = styled.div`
    margin-left: ${constants.SIZE_L};
    padding-left: ${constants.SIZE_XS};
`;

export const RadioGroup = <T,>({ buttons, value, onChange }: RadioGroupProps<T>): React.ReactElement => (
    <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
        {buttons.map((button, index) => (
            <FieldLayout
                key={index}
                helpText={button.helpText}
                disabled={button.disabled}
                helpTextView={button.helpTextView}
            >
                <>
                    <RadiobuttonKit
                        checked={button.value === value}
                        disabled={button.disabled}
                        value={button.value}
                        onChange={value => onChange(value)}
                        style={button.buttonStyle}
                    >
                        {button.render ? button.render() : button.label}
                    </RadiobuttonKit>

                    {(button.link && button.value === value) && <LinkWrapper>{button.link({ disabled: button.disabled })}</LinkWrapper>}
                </>
            </FieldLayout>
        ))}
    </StackView>
);
