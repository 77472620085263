/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    INDENT,
    Notification,
    NOTIFICATION_STATUS,
    StackView,
    useFormApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import styled from 'styled-components';

import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../stores';

const StyledButton = styled(Button)`
    width: 80px;
`;

export const SaveBtnGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useFormApi();
    const { formStore: { formIsDirty, showSavedMsg }, saveSettings } = useConfigurationStore();

    const onSaveClick = (): void => {
        formApi.isValid() && saveSettings();
    };

    return (
        <StackView indentTop={INDENT.s}>
            <StackView indentRight={INDENT.s}>
                <StyledButton disabled={!formIsDirty} onClick={onSaveClick}>
                    {lang.SAVE}
                </StyledButton>
            </StackView>

            {formIsDirty && (
                <Notification>
                    {lang.YOUR_CHANGES_ARE_NOT_SAVED}
                </Notification>
            )}

            {showSavedMsg && (
                <Notification status={NOTIFICATION_STATUS.ok}>
                    {lang.YOUR_CHANGES_ARE_SAVED}
                </Notification>
            )}
        </StackView>
    );
});
