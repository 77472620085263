/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
    GridSortDirections,
    useGlobalLang,
    useGlobalServices,
    createSearchableControl,
} from '@veeam-vspc/shared/components';
import { formatStr } from '@veeam-vspc/shared/helpers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BaseLocationData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { MAX_INTEGER_32 } from 'core/const';

export interface LocationDialogProps {
    locationId: number;
    companyId: number;
    entityName: string;
    onLocationChosen: (locationId: number) => void;
    deactivate: () => void;
}

interface LocationForm {
    locationId: number;
}

const formValidate = (data: LocationForm) => {
    const validator = new FormValidator(data);

    validator.validate('locationId')
        .number()
        .required();

    return validator.result();
};

export const LocationDialog: React.FC<LocationDialogProps> = ({ locationId, companyId, entityName, deactivate, onLocationChosen }) => {
    const lang = useGlobalLang<VspcLang>();
    const [data] = useState<LocationForm>({
        locationId,
    });
    const [locationsList, setLocationsList] = useState<BaseLocationData[]>([]);
    const formApi = useExternalFormApi<LocationForm>();
    const [validationState] = useState(new ValidationState());
    const { transportService } = useGlobalServices();

    useEffect(() => {
        transportService.request('/Location/GetLocations', {
            companyIds: [companyId],
            limit: MAX_INTEGER_32,
            sort: [{ property: 'name', direction: GridSortDirections.Asc }],
            page: 1,
            start: 0,
        })
            .then((resp: RequestSuccessResponse<BaseLocationData[]>) => {
                setLocationsList(resp.data);
            });
    }, []);

    return (
        <Dialog
            header={lang.SET_LOCATION}
            description={formatStr(lang.SELECT_LOCATION_FOR_THE, entityName)}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        validationState.markAsHandledForce();
                        if (formApi.isValid()) {
                            onLocationChosen(data.locationId);
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
        >
            <Form <LocationForm>
                value={data}
                validate={formValidate}
                validationState={validationState}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <Combobox
                        name={'locationId'}
                        label={lang.LOCATIONS}
                        data={locationsList}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        size={CONTROL_SIZE.full}
                        controlRenderer={createSearchableControl()}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
