/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredAdvancedFilter,
    useGlobalLang,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';

import type { ConfiguredAdvancedFilterOptions, AdvancedFilterValue, ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import nasIconSrc from 'images/pages/protected-data/filters/backup-types/nas-backup.png';
import nasBackupCopyIconSrc from 'images/pages/protected-data/filters/backup-types/nas-backup-copy.png';
import { JobTypes } from 'core/enums';

export interface FileSharesLocalToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const FileSharesLocalToolbar = (props: FileSharesLocalToolbarProps) => {
    const { method = 'GetFileShares', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.BACKUP_TYPE,
                        items: [
                            {
                                label: capitalize(lang.FILE_SHARE_BACKUP, true),
                                value: {
                                    id: JobTypes.Nas,
                                    name: 'types',
                                },
                                iconSrc: nasIconSrc,
                            },
                            {
                                label: lang.FILE_SHARE_BACKUP_COPY,
                                value: {
                                    id: JobTypes.NasBackupCopy,
                                    name: 'types',
                                },
                                iconSrc: nasBackupCopyIconSrc,
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.SERVER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
