/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Text, TEXT_WEIGHT } from '@veeam-vspc/shared/components';

export interface LabelBoldProps {
    labelText: string;
}

export const LabelBold: React.FC<LabelBoldProps> = props => <Text weight={TEXT_WEIGHT.bold}>{`${props.labelText}:`}</Text>;
