/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';
import { useGlobalAppData, useGlobalAppStorage, useGlobalContext, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppStates } from '@veeam-vspc/shared/enums';
import { CompanyStatusRepresentation } from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { formatStr } from '@veeam-vspc/shared/helpers';

import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type { VspcLang } from 'configs/languages';
import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { Dispatch, SetStateAction } from 'react';

import { AppStorageKeys } from 'core/enums';

import type { LicenseKeyDlgProps } from '../../components/layouts/AppPortalLayout';

export const useAppPortalLaunch = (licenseKeyDlgActions: UseModalActionsProps<LicenseKeyDlgProps>): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const location = useLocation();
    const navigate = useNavigate();
    const lang = useGlobalLang<VspcLang>();
    const { getLoginPath, logout, appState } = useGlobalContext();
    const { notificationService } = useGlobalServices();
    const appDataStore = useGlobalAppData();
    const { licenseExpired, message, portalUser, version } = appDataStore;
    const appStorage = useGlobalAppStorage();
    const [showApp, setShowApp] = useState<boolean>(appDataStore.isAppFullyLoaded);

    useEffect(() => {
        if (appState === AppStates.Unauthorized) {
            navigate(`${getLoginPath(location.pathname + window.location.search)}`);
        } else if (appState === AppStates.LoggedIn) {
            Promise.resolve()
                .then(() => {
                    if (licenseExpired && portalUser?.isPortalAdministrator()) {
                        return new Promise((resolve, reject) => {
                            licenseKeyDlgActions.activate({ onClose: reject });
                        });
                    }
                })
                .then(() => {
                    if (portalUser?.companyStatus === CompanyStatusRepresentation.Disabled) {
                        return notificationService.confirm(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS, {
                            canClose: false,
                        })
                            .then((btnKey: NotificationResponse) => (
                                btnKey === NotificationDialogsTextKeys.Yes
                                    ? Promise.resolve()
                                    : Promise.reject()
                            ));
                    }
                })
                .then(() => {
                    if (message) {
                        return notificationService.warning(lang.LOGIN, message);
                    }
                })
                .then(() => {
                    const appStorageVersion = appStorage?.getItem(AppStorageKeys.Version) ?? null;

                    if (!appStorageVersion) {
                        appStorage?.setItem(AppStorageKeys.Version, version);
                    }

                    if (!appStorageVersion || (appStorageVersion && appStorageVersion === version)) {
                        setShowApp(true);
                    } else if (appStorage?.isLocalStorageSupported()) {
                        appStorage?.setItem(AppStorageKeys.Version, version);

                        return notificationService.info(lang.BACKUP_PORTAL, lang.WEBSITE_CONFIGURATION_HAS_BEEN_UPDATED, {
                            canClose: false,
                        })
                            .then(() => window.location.reload());
                    } else {
                        setShowApp(true);

                        notificationService.warning(
                            lang.VEEAM_SERVICE_PROVIDER_CONSOLE,
                            formatStr(lang.ENABLE_THE_LOCALSTORAGE, lang.BACKUP_PORTAL.toLowerCase())
                        );
                    }
                })
                .then(() => appDataStore.isAppFullyLoaded = true)
                .catch(() => logout());
        }
    }, [appState, message]);

    return [showApp, setShowApp];
};
