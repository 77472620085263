/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConfigActionIds {
    FilterToolbarAdvanced = 'FilterToolbar.Advanced',
    FilterToolbarSiteSearch = 'FilterToolbar.SiteSearch',
    FilterToolbarCompanySearch = 'FilterToolbar.CompanySearch',
    FilterToolbarRegionSearch = 'FilterToolbar.RegionSearch',
    FilterToolbarType = 'FilterToolbar.Type',
    FilterToolbarStatus = 'FilterToolbar.Status',
    FilterToolbarScheduleType = 'FilterToolbar.ScheduleType',
    FilterToolbarDatePeriod = 'FilterToolbar.DatePeriod',
    ActionToolbarViewReports = 'ActionToolbar.ViewReports',
    ActionToolbarAutoDeploy = 'ActionToolbar.AutoDeploy',
    ActionToolbarChangeSettings = 'ActionToolbar.ChangeSettings',
    ActionToolbarSetCompany = 'ActionToolbar.SetCompany',
    ActionToolbarDeployAgentGroup = 'ActionToolbar.DeployAgentGroup',
    ActionToolbarDeployAgentSingle = 'ActionToolbar.DeployAgentSingle',
    ActionToolbarManagementAgent = 'ActionToolbar.ManagementAgent',
    ActionToolbarAcceptConnection = 'ActionToolbar.AcceptConnection',
    ActionToolbarRejectConnection = 'ActionToolbar.RejectConnection',
    ActionToolbarAcceptConnectionSeparator = 'ActionToolbar.AcceptConnectionSeparator',
    ActionToolbarInstallVbr = 'ActionToolbar.InstallVbr',
    ActionToolbarDownloadAgentGroup = 'ActionToolbar.DownloadAgentGroup',
    ActionToolbarDownloadAgentSingle = 'ActionToolbar.DownloadAgentSingle',
    ActionToolbarInstallVbrSeparator = 'ActionToolbar.InstallVbrSeparator',
    ActionToolbarChangeSettingSeparator = 'ActionToolbar.ChangeSettingSeparator',
    ActionToolbarRemoveRule = 'ActionToolbar.RemoveRule',
    ActionToolbarRuleActions = 'ActionToolbar.RuleActions',
    ActionToolbarViewDiscoveredComputers = 'ActionToolbar.ViewDiscoveredComputers',
    New = 'New',
    Edit = 'Edit',
    Remove = 'Remove',
    Separator = 'Separator',
    Copy = 'Copy',
    ClearLogs = 'ClearLogs',
    DownloadLogs = 'DownloadLogs',
    CancelDeployment = 'CancelDeployment',
}
