/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useCallback } from 'react';
import { STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import {
    DownloadTraffic,
    FileBackups,
    ManagedBackupAgents,
    Microsoft365Groups,
    Microsoft365Users,
    MonitoredWorkloads,
    ProtectedCloudWorkloads,
    QuotaUsage,
    ReplicatedVms,
    StoredVMs,
    Traffic,
    TransferredData,
} from './components';
import { MaxNumberOfRows } from '../MaxNumberOfRows';
import { SelectOnPage } from '../../stores';
import { useRowsStore } from '../../hooks';

export const Companies = observer(() => {
    const rowsStore = useRowsStore();
    const { maxNumberRowsCompanies: maxNumberOfRows } = rowsStore;

    const handleChangeMaxNumberOfRows = useCallback((maxNumberValue: number): void => {
        rowsStore.setMaxNumberRows(maxNumberValue, SelectOnPage.Companies);
    }, []);

    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} style={{ width: '100%', overflow: 'hidden' }}>
            <StackView direction={STACK_DIRECTION.row}>
                <MaxNumberOfRows
                    maxNumberOfRows={maxNumberOfRows}
                    handleChangeMaxNumberOfRows={handleChangeMaxNumberOfRows}
                />
            </StackView>

            <StackView
                direction={STACK_DIRECTION.row}
                gap={STACK_GAP.m}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                }}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxl}>
                    <ManagedBackupAgents maxNumberOfRows={maxNumberOfRows} />
                    <StoredVMs maxNumberOfRows={maxNumberOfRows} />
                    <QuotaUsage maxNumberOfRows={maxNumberOfRows} />
                    <FileBackups maxNumberOfRows={maxNumberOfRows} />
                    <Microsoft365Users maxNumberOfRows={maxNumberOfRows} />
                    <ProtectedCloudWorkloads maxNumberOfRows={maxNumberOfRows} />
                </StackView>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxl}>
                    <Traffic maxNumberOfRows={maxNumberOfRows} />
                    <DownloadTraffic maxNumberOfRows={maxNumberOfRows} />
                    <TransferredData maxNumberOfRows={maxNumberOfRows} />
                    <ReplicatedVms maxNumberOfRows={maxNumberOfRows} />
                    <Microsoft365Groups maxNumberOfRows={maxNumberOfRows} />
                    <MonitoredWorkloads maxNumberOfRows={maxNumberOfRows} />
                </StackView>
            </StackView>
        </StackView>
    );
});
