/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    NumberInput,
    Form,
    FormLayout,
    FormValidator,
    immediatelyValidateFlag,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

interface DownloadLogsDialogProps {
    header?: string;
    deactivate: () => void;
    onConfirm: (count: number) => void;
}

export const DownloadLogsDialog: React.FC<DownloadLogsDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const [formValue] = useState({
        countDays: 7,
    });
    const [isFormValid, setIsFormValid] = useState(true);

    return (
        <Dialog
            data-tid={'download-logs-dialog'}
            header={props.header || lang.DOWNLOAD_DEPLOYMENT_LOGS}
            title={lang.SELECT_THE_TIME_PERIOD_FOR_COLLECTING_LOGS_TO_BE_DOWNLOADED}
            onRequestClose={props.deactivate}
            actions={[
                {
                    text: lang.START,
                    onClick: () => {
                        props.onConfirm(formValue.countDays);
                        props.deactivate();
                    },
                    disabled: !isFormValid,
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form
                value={formValue}
                validate={(data) => {
                    const validator = new FormValidator(data);
                    validator.validate('countDays')
                        .number()
                        .required(lang.THIS_FIELD_IS_REQUIRED)
                        .min(1, `${lang.MIN_VALUE_IS}${immediatelyValidateFlag}`)
                        .max(100, `${lang.MAX_VALUE_IS}${immediatelyValidateFlag}`);

                    const result = validator.result();

                    setIsFormValid(!result.countDays);

                    return result;
                }}
            >
                <FormLayout inlineLabel>
                    <NumberInput
                        name='countDays'
                        label={lang.DOWNLOAD_LOGS_FOR_THE_LAST}
                        suffix={`${lang.DAYS.toLowerCase()}`}
                        minValue={1}
                        maxValue={100}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
