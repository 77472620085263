/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Vb365BackupJobSchedulePolicyPeriodicallyEvery,
    Vb365BackupJobSchedulePolicySchedulePolicyType,
} from '@veeam-vspc/models/rest';

import type { Vb365BackupJobSchedulePolicy } from '@veeam-vspc/models/rest';

export const isPeriodicScheduleApplicable = (schedulePolicy: Vb365BackupJobSchedulePolicy) => [
    Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours1,
    Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours2,
    Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours4,
    Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours8,
].includes(schedulePolicy.periodicallyEvery) &&
    schedulePolicy.schedulePolicyType === Vb365BackupJobSchedulePolicySchedulePolicyType.Periodically &&
    schedulePolicy.scheduleEnabled;
