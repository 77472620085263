/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/shared/components';

export const UsedValueStyled = styled.div<{ error?: boolean; }>`
    color: ${props => props.error ? colors.R800 : colors.B400};
    display: inline-block;
    font-size: ${constants.FONT_SIZE_XL};
    position: relative;
`;
