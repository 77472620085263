/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ConfigurationStoreContext } from './configuration-store-context';

import type { ConfigurationStore } from '../configuration-store';

export const useConfigurationStore = (): ConfigurationStore => useContext(ConfigurationStoreContext);
