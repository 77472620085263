/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors } from '@veeam-vspc/shared/components';

export const RowStyled = styled.div`
    align-items: center;
    border-bottom: 1px solid ${colors.G350};
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
`;
