/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { BackupServicesType, TimeUnit } from '@veeam-vspc/models/web-controllers';
import { FieldLayout, STACK_DIRECTION, STACK_GAP, StackView, useGlobalLang } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { FilterContext } from '../../contexts';
import { FilterStore } from '../../stores';
import { ManagedBackupAgents } from './components/ManagedBackupAgents';
import { ManagedVms } from './components/ManagedVms';
import { MonitoredObjects } from './components/MonitoredObjects';
import { ProtectedAwsWorkloads } from './components/ProtectedAwsWorkloads';
import { ProtectedAzureWorkloads } from './components/ProtectedAzureWorkloads';
import { ProtectedFileShares } from './components/ProtectedFileShares';
import { ProtectedGcpWorkloads } from './components/ProtectedGcpWorkloads';
import { ProtectedMicrosoft365Objects } from './components/ProtectedMS365Objects';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { WidgetColumnsStyled, WidgetViewStyled, WidgetRowsStyled } from 'components/layouts/WidgetView';
import { useFilterStore } from '../../hooks';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

interface BackupServicesLayoutProps {
    periodStateId: string;
    typeStateId: string;
}

const BackupServicesLayout: React.FC<BackupServicesLayoutProps> = observer((props) => {
    const { periodStateId, typeStateId } = props;
    const lang = useGlobalLang<VspcLang>();
    const filterStore = useFilterStore();

    return (
        <WidgetViewStyled>
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xl}>
                <StatefulCombobox
                    controlRenderer={createLinkControl()}
                    label={`${lang.REPORTING_PERIOD}:`}
                    onChange={filterStore.setReportingPeriod}
                    stateId={periodStateId}
                    textGetter={(p: ComboboxItem) => p.text}
                    value={filterStore.reportingPeriod}
                    valueGetter={(p: ComboboxItem) => p.id}
                    data={[
                        { id: TimeUnit.Weeks, text: lang.WEEKLY },
                        { id: TimeUnit.Months, text: lang.MONTHLY },
                    ]}
                />

                <FieldLayout inlineLabel label={`${lang.TYPE}:`}>
                    <StatefulCombobox
                        controlRenderer={createLinkControl()}
                        onChange={filterStore.setBackupServicesType}
                        stateId={typeStateId}
                        textGetter={(p: ComboboxItem) => p.text}
                        value={filterStore.backupServicesType}
                        valueGetter={(p: ComboboxItem) => p.id}
                        data={[
                            { id: BackupServicesType.All, text: lang.ALL },
                            { id: BackupServicesType.Managed, text: lang.CLIENT },
                            { id: BackupServicesType.Hosted, text: lang.HOSTED },
                        ]}
                    />
                </FieldLayout>
            </StackView>

            <WidgetColumnsStyled>
                <WidgetRowsStyled>
                    <ManagedBackupAgents />
                    <ProtectedFileShares />
                    <ProtectedMicrosoft365Objects />
                    <ProtectedAzureWorkloads />
                </WidgetRowsStyled>

                <WidgetRowsStyled>
                    <ManagedVms />
                    <MonitoredObjects />
                    <ProtectedAwsWorkloads />
                    <ProtectedGcpWorkloads />
                </WidgetRowsStyled>
            </WidgetColumnsStyled>
        </WidgetViewStyled>
    );
});

export interface BackupServersProps {
    stateId: string;
}

export const BackupServices: React.FC<BackupServersProps> = () => {
    const { pathname } = useLocation();
    const periodStateId = `${pathname}.ReportingPeriod`;
    const typeStateId = `${pathname}.BackupServiceType`;

    const initialPeriod = useLocalStorageValue<TimeUnit>(periodStateId, TimeUnit.Months);
    const initialType = useLocalStorageValue<BackupServicesType>(typeStateId, BackupServicesType.All);

    const filterStore = useMemo(() => new FilterStore(initialPeriod, initialType), [initialPeriod, initialType]);

    return (
        <FilterContext.Provider value={filterStore}>
            <BackupServicesLayout periodStateId={periodStateId} typeStateId={typeStateId} />
        </FilterContext.Provider>
    );
};
