/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { CompanyWizardStoreContext } from '../stores';

import type { CompanyUserStore } from '../stores';

export const useCompanyWizardStore = (): CompanyUserStore => useContext(CompanyWizardStoreContext);
