/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { useGlobalAppData, useGlobalContext } from '@veeam-vspc/shared/components';
import { AppStates } from '@veeam-vspc/shared/enums';

import { LoginContext, LoginStore } from '../../stores';
import { LoginBackground } from '../LoginBackground';
import { LoginFactorsContainer } from '../LoginFactorsContainer';

export const Login: React.FC<{}> = () => {
    const { setAppState } = useGlobalContext();
    const { identityProviders, message: error } = useGlobalAppData();
    const loginStore = useMemo(
        () => new LoginStore({
            loadApp: () => {
                setAppState(AppStates.Loading);
            },
            loadLogin: () => {}, // @TODO unused
            identityProviders,
            error,
        }),
        [identityProviders, error]
    );

    return (
        <LoginContext.Provider value={loginStore}>
            <LoginBackground>
                <LoginFactorsContainer />
            </LoginBackground>
        </LoginContext.Provider>
    );
};
