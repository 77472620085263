/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CopyToClipboardButton,
    LinkButton,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { ETaskLogType } from '@veeam-vspc/models/web-controllers';
import { capitalize, copyTextToClipboard } from '@veeam-vspc/shared/helpers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { DeployLogRecordRepresentation } from '@veeam-vspc/models/web-controllers';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { RefObject } from 'react';
import type { VspcLang } from 'configs/languages';

import downloadLogsActionIconSrc from 'images/actions/download-logs.png';
import copyLogsActionIconSrc from 'images/actions/copy-to-clipboard.png';
import cancelDeploymentActionIconSrc from 'images/actions/cancel-deployment.svg';
import clearLogsActionIconSrc from 'images/actions/clear-logs.png';
import { ProgressBar } from 'views/components/ProgressBar';
import { DownloadLogsButton } from 'views/components/DownloadLogsButton';
import { calculateDate, calculateDuration } from 'core/utils';
import { ConfigActionIds, ConfigSectionIds } from 'core/enums';

export interface TaskDetailsTopBarProps {
    taskGuid: string;
    biosUuid: string;
    agentId: string;
    progress: number;
    api: RefObject<GridStore<DeployLogRecordRepresentation, unknown, {}>>;
    gridRef: RefObject<HTMLElement>;
    isClearBtnDisabled: boolean;
    isDownloadBtnDisabled: boolean;
    cancelDeploymentBtnIsDisabled: boolean;
    onCancelTaskHandler?: () => void;
    onClearLogsHandler?: () => void;
}

export const TaskDetailsTopBar: React.FC<TaskDetailsTopBarProps> = (props) => {
    const {
        taskGuid,
        biosUuid,
        agentId,
        progress,
        api,
        isDownloadBtnDisabled,
        isClearBtnDisabled,
        cancelDeploymentBtnIsDisabled,
        onCancelTaskHandler,
        onClearLogsHandler,
    } = props;
    const lang = useGlobalLang<VspcLang>();
    const { transportService, configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.DiscoveryTaskDetailsDialog);

    const clearLogsBtnIsHidden = sectionConfig
        ? sectionConfig.getActionState(ConfigActionIds.ClearLogs) === ConfigStates.Hidden
        : false;

    const cancelDeploymentBtnIsHidden = sectionConfig
        ? sectionConfig.getActionState(ConfigActionIds.CancelDeployment) === ConfigStates.Hidden
        : false;

    const downloadLogsBtnIsHidden = sectionConfig
        ? sectionConfig.getActionState(ConfigActionIds.DownloadLogs) === ConfigStates.Hidden
        : false;

    return (
        <StackView
            direction={STACK_DIRECTION.row}
            gap={STACK_GAP.xl}
        >
            <ProgressBar progress={progress} />

            {
                !downloadLogsBtnIsHidden
                && (
                    <DownloadLogsButton
                        isLinkButton
                        iconBefore={downloadLogsActionIconSrc}
                        buttonText={lang.DOWNLOAD_LOGS}
                        progressStartedMessage={lang.DOWNLOADING_DEPLOYMENT_LOGS}
                        downloadInitUrl={'/DiscoveryRule/DownloadBackupServerDeployLogs'}
                        showSelectPeriodDialog={true}
                        disabled={isDownloadBtnDisabled}
                        requestParams={{
                            biosUuid,
                        }}
                    />
                )
            }

            <CopyToClipboardButton
                iconSrc={copyLogsActionIconSrc}
                text={lang.COPY_ALL_TO_CLIPBOARD}
                onClick={async() => {
                    const headerText = api.current.columns.map(column => column.title).join('; ')
                        .concat('\r\n');
                    const rowText = api.current.pageData
                        .map(item =>
                            `${item.text}; ${calculateDate(item.startTime)}; ${calculateDate(item.endTime)}; ${calculateDuration(item.duration)}`)
                        .join('\r\n');

                    setTimeout(() => (document.activeElement as HTMLElement)?.blur(), 100);

                    await copyTextToClipboard(`${headerText}\r\n${rowText}`);

                    return lang.COPIED_TO_CLIPBOARD;
                }}
            />

            {!cancelDeploymentBtnIsHidden && (
                <LinkButton
                    onClick={
                        () => transportService.request('/Deployment/CancelDeploymentTask', { taskGuid })
                            .then(() => {
                                onCancelTaskHandler?.();
                            })
                    }
                    iconBefore={cancelDeploymentActionIconSrc}
                    disabled={cancelDeploymentBtnIsDisabled}
                >
                    {capitalize(lang.CANCEL_DEPLOYMENT)}
                </LinkButton>
            )}

            {!clearLogsBtnIsHidden && (
                <LinkButton
                    onClick={
                        () => transportService.request('/Deployment/ResetTaskLog', {
                            logTask: ETaskLogType.VspcAgent,
                            agentId,
                            biosUid: biosUuid,
                        })
                            .then(() => {
                                onClearLogsHandler?.();
                                (document.activeElement as HTMLElement)?.blur();
                            })
                    }
                    iconBefore={clearLogsActionIconSrc}
                    disabled={isClearBtnDisabled}
                >
                    {lang.CLEAR_LOGS}
                </LinkButton>
            )}
        </StackView>
    );
};
