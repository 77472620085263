/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { PortalSpinner, TOOLBAR_ITEM_TYPE, useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { FileInput, Toolbar } from '@veeam/components';
import { LicenseTypeRepresentation, LicenseUpdateState } from '@veeam-vspc/models/web-controllers';
import { observer } from 'mobx-react-lite';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { LicenseUpdateLicenseResponse, VacLicenseInfo } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import install from 'images/actions/install-license.png';
import update from 'images/actions/update.svg';
import { shortDate } from 'core/utils/date-helpers';

interface LicenseKeyToolbarProps {
    updateLicenseInfo?: (data: VacLicenseInfo) => void;
    confirmFn?: () => void;
    hideUpdate: boolean;
}

export const LicenseKeyToolbar = observer(({
    updateLicenseInfo,
    confirmFn,
    hideUpdate,
}: LicenseKeyToolbarProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, fileTransportService, notificationService } = useGlobalServices();
    const [loading, setLoading] = useState(false);
    const { formats } = useGlobalAppData();

    const installLicense = (files) => {
        const data = new FormData();

        data.append('0', files[0]);

        setLoading(true);

        return fileTransportService.uploadFormData<VacLicenseInfo>('/License/InstallLicense', data, {
            backendErrorTitle: lang.LICENSE_UPDATE_STATUS,
        })
            .then((resp: RequestSuccessResponse<VacLicenseInfo>) => {
                setLoading(false);

                const isAutoUpdateEnabled = resp.data.autoUpdate;
                const isRentalLicense = resp.data.licenseType === LicenseTypeRepresentation.Rental;
                const isSubscriptionLicense = resp.data.licenseType === LicenseTypeRepresentation.Subscription;

                if ((isRentalLicense || isSubscriptionLicense) && !isAutoUpdateEnabled) {
                    const confirmMsg = formatStr(
                        isRentalLicense ? lang.YOU_HAVE_INSTALLED_A_RENTAL_LICENSE : lang.YOU_HAVE_INSTALLED_A_SUBSCRIPTION_LICENSE,
                        shortDate(resp.data.expirationDate, formats)
                    );

                    return notificationService.confirm(lang.INSTALL_LICENSE_FILE, confirmMsg)
                        .then((btn) => {
                            if (btn === NotificationDialogsTextKeys.Yes) {
                                return transportService.request('/License/SetLicenseAutoUpdate', { value: true });
                            }
                        });
                }

                return notificationService.info(lang.LICENSE_UPDATE_STATUS, lang.LICENSE_HAS_BEEN_INSTALLED);
            })
            .then(() => transportService.request('/License/GetLicenseInfo'))
            .then((r: RequestSuccessResponse<VacLicenseInfo>) => updateLicenseInfo?.(r.data))
            .then(() => confirmFn?.())
            .catch(() => {
                setLoading(false);
                confirmFn?.();
            });
    };

    const updateLicense = () => {
        setLoading(true);

        return transportService.request('/License/UpdateLicense', null, {
            backendErrorTitle: lang.LICENSE_UPDATE_STATUS,
        })
            .then((r: LicenseUpdateLicenseResponse) => {
                const { message, state } = r.data;

                setLoading(false);

                return Promise.resolve()
                    .then(() => {
                        if (state !== LicenseUpdateState.Ok && state !== LicenseUpdateState.UpdateIsNotRequired) {
                            return notificationService.error(lang.LICENSE_UPDATE_STATUS, message);
                        }

                        return notificationService.info(lang.LICENSE_UPDATE_STATUS, message);
                    })
                    .then(() => confirmFn?.());
            })
            .catch(() => {
                setLoading(false);
                confirmFn?.();
            });
    };

    return (
        <>
            <Toolbar
                items={[
                    {
                        render: () => (
                            <FileInput
                                accept='.lic'
                                buttonText={`${lang.INSTALL_LICENSE}...`}
                                hideInput
                                iconSrc={install}
                                onChange={installLicense}
                            />
                        ),
                        type: TOOLBAR_ITEM_TYPE.customControl,
                    },
                    {
                        hidden: hideUpdate,
                        iconSrc: update,
                        onClick: updateLicense,
                        text: `${lang.UPDATE_NOW}...`,
                        type: TOOLBAR_ITEM_TYPE.button,
                    },
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
});
