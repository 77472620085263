/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { BackupServerJobProcessedObjects } from '@veeam-vspc/models/web-controllers';

import { TopDataUrls } from '../../../../enums';
import { useCommonSettings } from '../../../../hooks';
import { TopsGrid } from '../../../TopsGrid';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const JobsProcessedObjects: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings =
        useCommonSettings<BackupServerJobProcessedObjects>({
            maxNumberOfRows,
            getDataRequestUrl: TopDataUrls.JobsProcessedObjects,
        });
    const { lang } = settings;

    const columns: GridColumnProps<BackupServerJobProcessedObjects>[] = [
        {
            id: 'name',
            title: capitalize(lang.JOB_NAME),
            cell: rowData => rowData.name,
        },
        {
            id: 'type',
            title: lang.JOB_TYPE,
            cell: rowData => rowData.type,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'serverName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.serverName,
            width: 160,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'count',
            title: lang.PROCESSED_OBJECTS,
            cell: rowData => rowData.count,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 175,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_JOBS_BY_PROCESSED_OBJECTS}
            columns={columns}
            sectionId={ConfigSectionIds.TopsInfrastructure}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
