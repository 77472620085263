/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettingsKind, SmtpSettingsTlsMode } from '@veeam-vspc/models/rest';

import type { OAuth2Credential, SmtpSettings } from '@veeam-vspc/models/rest';

import { SmtpOAuthAddresses } from '../enums';

export const getOAuthSmtpSettings = (originalSmtpSettings: SmtpSettings, oAuthCreds: OAuth2Credential): SmtpSettings => {
    const isGoogleKind = oAuthCreds.clientSettings.kind === OAuth2ClientSettingsKind.Google;

    return {
        ...originalSmtpSettings,
        oAuth2Credential: oAuthCreds,
        passwordCredential: null,
        serverAddress: isGoogleKind ? SmtpOAuthAddresses.Google : SmtpOAuthAddresses.Azure,
        timeout: originalSmtpSettings?.timeout || '00:00:30',
        tlsMode: originalSmtpSettings?.tlsMode || SmtpSettingsTlsMode.Auto,
    };
};
