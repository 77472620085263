/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { FileSharesLocal } from './components';

export interface ProtectedDataFileSharesLocalPageProps {
    currentPath?: string;
}

export const ProtectedDataFileSharesLocalPage: React.FC<ProtectedDataFileSharesLocalPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <FileSharesLocal stateId={currentPath} />
    </TabBarBodyLayout>
);
