/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { ConfigSectionIds } from 'core/enums';
import { RouteIds, RoutePaths } from '../../enums';
import { allConfigsRoutes, allCustomRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from '../../../views/components/layouts/AppPortalBodyLayout';

export const unknownConfig: UserConfigData<typeof UserRoleRepresentation.Unknown, ConfigSectionIds> = {
    role: UserRoleRepresentation.Unknown,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Configs,
                    component: AppPortalBodyLayout,
                    items: [
                        allConfigsRoutes[RouteIds.Settings],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.EditUserProfileWizard,
            items: {
                loginInfoStep: ConfigStates.Hidden,
            },
        },
    ],
};
