/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConfigSectionIds {
    MonthlyUsageReportDialog = 'MonthlyUsageReportDialog',
    ActiveAlarmsAlarms = 'ActiveAlarms.Alarms',
    ActiveAlarmsAlarmsFilterToolbar = 'ActiveAlarms.Alarms.FilterToolbar',
    ActiveAlarmsAlarmsFilterToolbarAdvancedFilter = 'ActiveAlarms.Alarms.FilterToolbar.AdvancedFilter',
    BackupJobsComputersManagedByConsole = 'BackupJobs.Computers.ManagedByConsole',
    BackupJobsComputersManagedByBackupServer = 'BackupJobs.Computers.ManagedByBackupServer',
    BackupJobsVirtualMachinesVirtualInfrastructure = 'BackupJobs.VirtualMachines.VirtualInfrastructure',
    BackupJobsVirtualMachinesVirtualInfrastructureAdvancedFilter = 'BackupJobs.VirtualMachines.VirtualInfrastructure.AdvancedFilter',
    BackupJobsVirtualMachinesPublicClouds = 'BackupJobs.VirtualMachines.PublicClouds',
    BackupJobsFileSharesPublicClouds = 'BackupJobs.FileShares.PublicClouds',
    BackupJobsCloudDatabases = 'BackupJobs.CloudDatabases',
    BackupJobsFileSharesLocal = 'BackupJobs.FileShares.Local',
    BackupJobsDataBackupObjectStorage = 'BackupJobs.DataBackup.ObjectStorage',
    BackupJobsSelectVbDialogFilterToolbar = 'BackupJobs.SelectVbDialog.FilterToolbar',
    BillingSummaryCloudConnectResourcesCloudConnectReplica = 'BillingSummary.CloudConnectResources.CloudConnectReplica',
    BillingSummaryCloudConnectResourcesCloudConnectBackup = 'BillingSummary.CloudConnectResources.CloudConnectBackup',
    ProtectedDataComputers = 'ProtectedData.Computers',
    ProtectedDataFileSharesLocal = 'ProtectedData.FileShares.Local',
    ProtectedDataFileSharesPublicClouds = 'ProtectedData.FileShares.PublicClouds',
    ProtectedDataDataBackupObjectStorage = 'ProtectedData.DataBackup.ObjectStorage',
    ProtectedDataCloudDatabases = 'ProtectedData.CloudDatabases',
    ProtectedDataVirtualMachinesVirtualInfrastructure = 'ProtectedData.VirtualMachines.VirtualInfrastructure',
    ProtectedDataVirtualMachinesPublicClouds = 'ProtectedData.VirtualMachines.PublicClouds',
    ReportsConfigurationsWizard = 'Reports.Configurations.Wizard',
    ReportsAllReports = 'Reports.AllReports',
    ReportsConfiguration = 'Reports.Configuration',
    ReportsFilterToolbarAdvancedFilter = 'Reports.FilterToolbar.AdvancedFilter',
    DiscoveryComputersComputers = 'Discovery.Computers.Computers',
    DiscoveryComputersRules = 'Discovery.Computers.Rules',
    DiscoveryTaskDetailsDialog = 'Discovery.TaskDetails.Dialog',
    TopsCompanies = 'Tops.Companies',
    TopsInfrastructure = 'Tops.Infrastructure',
    InvoicesAllInvoices = 'Invoices.AllInvoices',
    InvoicesConfiguration = 'Invoices.Configuration',
    EditUserProfileWizard = 'EditUserProfileWizard',
    EditUserProfileWizardSummary = 'EditUserProfileWizard.Summary',
    SubscriptionPlans = 'Subscription.Plans',
    SubscriptionPlansActionToolbar = 'Subscription.Plans.ActionToolbar',
    SubscriptionPlansCompanies = 'Subscription.Plans.Companies',
    Companies = 'Companies',
    CompanyInfoProfile = 'CompanyInfo.Profile',
    FailoverPlansFailoverPlans = 'FailoverPlans.FailoverPlans',
    LicensingOverview = 'Licensing.Overview',
    CompaniesLocations = 'Companies.Locations',
}
