/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    ConfiguredGrid,
    GridColumnJustify,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams } from '@veeam-vspc/shared/components';
import type { DiscoveryRuleGridDataModel, DiscoveryRuleFilter } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import { RulesFilterToolbar } from './components/RulesFilterToolbar';
import { RuleStatusColumn } from './components/RuleStatusColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RulesActionToolbar } from './components/RulesActionToolbar';


export interface RulesProps {
    stateId?: string;
}

export const Rules: React.FC<RulesProps> = ({ stateId }) => {
    const gridStateId = `${stateId}.Rules`;
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.DiscoveryComputersRules}
            stateId={gridStateId}
            defaultColumnMinWidth={80}
            columns={[
                {
                    id: 'stateStr',
                    title: lang.STATUS,
                    cell: rowData => <RuleStatusColumn rowData={rowData} />,
                }, {
                    id: 'name',
                    title: lang.RULE,
                    cell: rowData => rowData.name,
                }, {
                    id: 'company',
                    title: lang.COMPANY,
                    cell: rowData => rowData.company,
                }, {
                    id: 'siteName',
                    title: lang.SITE,
                    cell: rowData => rowData.siteName,
                    hidden: true,
                }, {
                    id: 'locationName',
                    title: lang.LOCATION,
                    cell: rowData => rowData.locationName,
                    hidden: true,
                }, {
                    id: 'osType',
                    title: lang.GUEST_OS,
                    cell: (rowData) => {
                        const osText = portalEnums.getEnumDescription('NetworkDiscoveryRuleOsTypeRepresentation', rowData.osType);

                        return osText;
                    },
                }, {
                    id: 'totalComputers',
                    title: lang.TOTAL_COMPUTERS,
                    cell: rowData => (
                        <Link
                            onClick={() => {
                                RCOP.discoveryComputersRuleId = rowData.id;
                            }}
                            to='/home/discovery/discoveredComputers/computers'
                        >
                            {rowData.totalComputers}
                        </Link>
                    ),
                    justify: GridColumnJustify.Right,
                }, {
                    id: 'online',
                    title: lang.ONLINE,
                    cell: rowData => rowData.online,
                    justify: GridColumnJustify.Right,
                }, {
                    id: 'offline',
                    title: lang.INACCESSIBLE,
                    cell: rowData => rowData.offline,
                    justify: GridColumnJustify.Right,
                }, {
                    id: 'scheduleSettings.type',
                    title: lang.SCHEDULE,
                    cell: (rowData) => {
                        const scheduleText = portalEnums
                            .getEnumDescription('ScheduleTypeRepresentation', rowData.scheduleSettings.type);

                        return scheduleText;
                    },
                }, {
                    id: 'lastRun',
                    title: lang.LAST_RUN,
                    cell: rowData => (
                        <GridDateColumn value={rowData.lastRun} />
                    ),
                }, {
                    id: 'nextRun',
                    title: lang.NEXT_RUN,
                    cell: rowData => (
                        <GridDateColumn value={rowData.nextRun} />
                    ),
                },
            ]}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            data={(requestParams: RequestParams<DiscoveryRuleFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<DiscoveryRuleGridDataModel[]>>('/DiscoveryRule/Get', { ...requestParams })}
            toolbars={[
                () => <RulesFilterToolbar />,
                () => <RulesActionToolbar />,
            ]}
        />
    );
};
