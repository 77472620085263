/**
 * Copyright © Veeam Software Group GmbH.
 */

import { LogRecordStatusInteg } from '@veeam-vspc/models/web-controllers';
import {
    ConfiguredGrid,
    Dialog,
    DIALOG_SIZE,
    DurationColumn,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';
import React, { useRef, useState } from 'react';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { DeployLogRecordRepresentation, DiscoveryRuleGetTaskLogResponse, TaskDetailsParam, TaskLog } from '@veeam-vspc/models/web-controllers';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { StatusColumn } from 'views/components/columns/StatusColumn';
import { TaskDetailsTopBar } from './components';

export interface TaskDetailsDialogProps {
    deactivate: () => void;
    stateId?: string;
    hostName: string;
    agentId: string;
    biosUuid: string;
    url?: string;
    onCancelTaskHandler?: () => void;
    onClearLogsHandler?: () => void;
}

export const TaskDetailsDialog: React.FC<TaskDetailsDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const {
        deactivate,
        stateId,
        url = '/DiscoveryRule/GetTaskLog',
        hostName,
        agentId,
        biosUuid,
        onCancelTaskHandler,
        onClearLogsHandler,
    } = props;
    const [taskProgress, setTaskProgress] = useState<number>(0);
    const [taskGuid, setTaskGuid] = useState<string>('');
    const [isClearBtnDisabled, setIsClearBtnDisabled] = useState(true);
    const [isDownloadBtnDisabled, setIsDownloadBtnDisabled] = useState(true);
    const [cancelDeploymentBtnIsDisabled, setCancelDeploymentBtnIsDisabled] = useState(false);
    const gridWrapperRef = useRef(null);
    const api = useRef<GridStore<DeployLogRecordRepresentation, unknown, {}>>();
    const { transportService } = useGlobalServices();

    return (
        <Dialog
            header={`${lang.TASK_DETAILS}: ${hostName}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.m}
                ref={gridWrapperRef}
            >
                <TaskDetailsTopBar
                    taskGuid={taskGuid}
                    biosUuid={biosUuid}
                    agentId={agentId}
                    progress={taskProgress}
                    api={api}
                    gridRef={gridWrapperRef}
                    isClearBtnDisabled={isClearBtnDisabled}
                    isDownloadBtnDisabled={isDownloadBtnDisabled}
                    cancelDeploymentBtnIsDisabled={cancelDeploymentBtnIsDisabled}
                    onClearLogsHandler={onClearLogsHandler}
                    onCancelTaskHandler={onCancelTaskHandler}
                />

                <ConfiguredGrid
                    stateId={stateId ? `${stateId}.Dialog.TaskDetails` : undefined}
                    autoUpdateInterval={3 * TimeUnitsInMs.Second}
                    loadingDelayTime={300}
                    columns={[
                        {
                            id: 'status',
                            title: lang.ACTION,
                            isSortable: false,
                            cell: rowData => (
                                <StatusColumn
                                    status={rowData.status}
                                    priorityCellText={rowData.text}
                                />
                            ),
                        },
                        {
                            id: 'startTime',
                            title: lang.START_TIME,
                            isSortable: false,
                            width: 162,
                            cell: rowData => (
                                <GridDateColumn
                                    value={rowData.startTime}
                                />
                            ),
                        },
                        {
                            id: 'endTime',
                            title: lang.END_TIME,
                            isSortable: false,
                            width: 162,
                            cell: rowData => (
                                <GridDateColumn
                                    value={rowData.endTime}
                                />
                            ),
                        },
                        {
                            id: 'duration',
                            title: lang.DURATION,
                            isSortable: false,
                            width: 110,
                            cell: rowData => (
                                rowData.status === LogRecordStatusInteg.Running
                                    ? null
                                    : <DurationColumn value={rowData.duration} />
                            ),
                        },
                    ]}
                    api={api}
                    data={(requestParams: TaskDetailsParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<TaskLog>>(
                            url,
                            {
                                ...requestParams,
                                // we use a sort filter here instead of initialState because we don't want to see sort arrows
                                sort: [{ property: 'startTime', direction: GridSortDirections.Asc.toUpperCase() }],
                                hostName,
                                biosUuid,
                                agentId,
                            }
                        )
                        .then((resp: RequestSuccessResponse<TaskLog>) => {
                            const { progress, items, taskGuid, canDownloadLogs, taskFinished } = resp.data;
                            const isFailed = items.length > 0 && items[items.length - 1].status === LogRecordStatusInteg.Failed;
                            const currentProgress = isFailed ? null : progress;

                            if (currentProgress === 1) {
                                api.current?.unsubscribeFromUpdates();
                            }

                            setCancelDeploymentBtnIsDisabled(isFailed || currentProgress === 1 || taskFinished);

                            if (isFailed || currentProgress === 1) {
                                setIsClearBtnDisabled(false);
                            }

                            setIsDownloadBtnDisabled(!canDownloadLogs);
                            setTaskGuid(taskGuid);
                            setTaskProgress(currentProgress);

                            return resp;
                        })
                        .then((resp) => {
                            const { data } = resp as DiscoveryRuleGetTaskLogResponse;

                            return {
                                meta: {
                                    pagingInfo: {
                                        total: data.items.length,
                                        count: data.items.length,
                                        offset: 0,
                                    },
                                },
                                ...resp,
                                data: data.items.map((item, index) => ({
                                    ...item,
                                    id: `${index}-${item.timeStamp}`,
                                })),
                            };
                        })}
                    toggleable={false}
                    selection={{
                        field: 'id',
                        checkbox: false,
                        multiple: false,
                    }}
                />
            </StackView>
        </Dialog>
    );
};
