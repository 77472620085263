/**
 * Copyright © Veeam Software Group GmbH.
 */

import DOMPurify from 'dompurify';
import { splitTextByDividers, textDividers } from '@veeam-vspc/shared/helpers';

import type { ParsedTextConfig } from '@veeam-vspc/shared/helpers';

export const getMarkedList = (value: string | string[]): ParsedTextConfig[] => {
    const items: string[] = typeof value === 'string'
        ? splitTextByDividers(value)
        : value.flatMap(splitTextByDividers);

    const isSingleItem = items.length === 1;

    if (isSingleItem) {
        return [{ text: items[0] }];
    }

    return items.map((text, index) => {
        const divider = textDividers.find(divider => divider.uniqueSymbol === text) || null;

        if (divider && index > 0) {
            return { type: divider.symbol };
        }

        return { text, listItem: !text.endsWith(':') };
    });
};

export const trimTextFields = <T,>(data: T, keys: (keyof T)[]) => {
    keys.forEach((key) => {
        const value = data[key];
        if (typeof value === 'string') {
            (data[key] as unknown as string) = value.trim();
        }
    });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const sanitize = (str, cfg = { ADD_ATTR: [ 'target' ] }) => DOMPurify.sanitize(str, cfg);

export const removeDotFromEndOfText = (text: string): string => text.replace(/\.$/, '');

export const insertComponent = (str: string, ...args) => str.split(/{\d+}/g).reduce((acc, v, i) => {
    acc.push(v);
    args[i] && acc.push(args[i]);
    return acc;
}, []);
