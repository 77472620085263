/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { HighchartsStyles } from '../../components/HighchartsWrapper';
import { Overview } from './components';

export const LicensingOverviewPage: React.FC<{}> = () => (
    <TabBarBodyLayout>
        <HighchartsStyles />

        <div style={{ flex: 1, overflow: 'hidden' }}>
            <Overview />
        </div>
    </TabBarBodyLayout>
);
