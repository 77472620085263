/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { DataNode } from 'rc-tree/lib/interface';

export const filterTreeByQuery = (
    target: DataNode[],
    query: string,
): DataNode[] => {
    if (!query) {
        return target;
    }

    return target.reduce(
        (arr: DataNode[], node: DataNode) => {
            const hasIntersectionInNodeName = (node.title as string)
                .toLowerCase()
                .includes(query.toLowerCase());

            const children = filterTreeByQuery(node.children ?? [], query);

            if (hasIntersectionInNodeName || children.length) {
                const nodeCopy = {
                    ...node,
                    children,
                };

                arr.push(nodeCopy);
            }

            return arr;
        },
        []
    );
};