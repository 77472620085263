/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum LoginFactors {
    LoginStart = 'LoginStart',
    SelectServer = 'SelectServer',
    VerifyCode = 'VerifyCode',
    ChangePassword = 'ChangePassword',
    ResetPassword = 'ResetPassword',
    Sso = 'Sso',
}
