/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';
import { SmtpSettingsTlsMode } from '@veeam-vspc/models/rest';
import { useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { useState } from 'react';

import type { OAuth2ClientSettings, SmtpSettings, NotificationSettings, OAuth2SignInParameters } from '@veeam-vspc/models/rest';
import type { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { AuthTypes } from '../../../enums';
import { useConfigurationRequestsStore, useConfigurationStore } from '../../../../../stores';
import { useServerSettingsDialogFormStore } from '../../../stores';
import { getNewSmtpSettings, getOAuthSettings } from '../helpers';
import { getHostAndPortFromURL } from '../../../helpers';

import type { ServerSettingsDialogFormData } from '../../../interfaces';
import type { UntrustedCertDialogData } from '../interfaces';

export interface ControllerReturnedValues {
    onSaveActionClick: () => void;
    testedSmtpSettings: SmtpSettings;
}

export const useServerSettingsDialogController = (
    deactivateDialogCb: UseModalActionsProps['deactivate'],
    showUntrustedCertDialogCb: UseModalActionsProps<UntrustedCertDialogData>['activate']
): ControllerReturnedValues => {

    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const configurationPageStore = useConfigurationStore();
    const requestsStore = useConfigurationRequestsStore();
    const dialogFormStore = useServerSettingsDialogFormStore();
    const { portalUser } = useGlobalAppData();
    const { userRole } = portalUser ?? {};

    const {
        formApi: dialogFormApi,
        formData: dialogFormData,
        validationState,
        redirectUrl,
    } = dialogFormStore;

    const [testedSmtpSettings, setTestedSmtpSettings] = useState<SmtpSettings>(null);

    const onSaveActionClick = (): void => {
        const isBasicType = dialogFormData.authType === AuthTypes.Basic;
        const isDirty = dialogFormApi.isDataChanged();
        const formIsValid = dialogFormApi.validate();
        const clientSettings = dialogFormData.smtp.oAuth2Credential.clientSettings;

        // we should allow to press save action btn for modern auth type, even if nothing have been changed
        if (!isDirty && isBasicType) {
            deactivateDialogCb();
        } else if (formIsValid) {
            isBasicType ? basicSettingsHandler(dialogFormData) : modernSettingsHandler(userRole, redirectUrl, clientSettings);
        } else {
            validationState.markAsForce();
        }
    };

    const basicSettingsHandler = (formData: ServerSettingsDialogFormData): void => {
        dialogFormStore.setIsLoading(true);
        const newSmtpSettings = getNewSmtpSettings(formData);

        requestsStore.testSmtpSettings(newSmtpSettings, false)
            .then((smtpSettingsAfterTest: SmtpSettings): void => {
                setTestedSmtpSettings(smtpSettingsAfterTest);

                const useSSL = dialogFormData.smtp.tlsMode !== SmtpSettingsTlsMode.None;

                // in order to not lose changed form data from configurationPageStore, we use 'dataForSaving' and not 'initialData'
                const newNotificationSettings: NotificationSettings = {
                    ...configurationPageStore.formStore.dataForSaving,
                    smtp: newSmtpSettings,
                };

                if (useSSL && smtpSettingsAfterTest.serverCertificate) {
                    dialogFormStore.setIsLoading(false);
                    return validateCertificate(newNotificationSettings, smtpSettingsAfterTest);
                }

                return saveBasicSettings(newNotificationSettings);
            })
            .catch(() => dialogFormStore.setIsLoading(false));
    };

    const validateCertificate = (newSettings: NotificationSettings, smtpSettingsAfterTest: SmtpSettings): void => {
        const initialServerAddress = configurationPageStore.smtpStore.settings?.serverAddress;
        const { host: initialHost } = getHostAndPortFromURL(initialServerAddress);
        const initialCertHash = configurationPageStore.smtpStore.settings?.exclusivelyAcceptedCertificateHash;

        const { serverCertificate: testedCert } = smtpSettingsAfterTest;

        const isValidServerCert = testedCert.isValid;
        const hashesMatch = initialCertHash === testedCert.hash;

        const isHashesUntrustedCert = !isValidServerCert && !hashesMatch;
        const isHostsUntrustedCert = !isValidServerCert && hashesMatch && (initialHost !== dialogFormData.host);

        const isUntrustedCert = isHashesUntrustedCert || isHostsUntrustedCert;

        if (isUntrustedCert) {
            return untrustedCertHandler(newSettings, smtpSettingsAfterTest);
        }

        return saveBasicSettings(newSettings);
    };

    const untrustedCertHandler = (newSettings: NotificationSettings, smtpSettingsAfterTest: SmtpSettings): void => {
        const { exclusivelyAcceptedCertificateHash: testedCertHash } = smtpSettingsAfterTest;

        newSettings.smtp.exclusivelyAcceptedCertificateHash = testedCertHash;

        return showUntrustedCertDialogCb({
            onSaveCallback() {
                saveBasicSettings(newSettings);
            },
        });
    };

    const saveBasicSettings = (newSettings: NotificationSettings): void => {
        dialogFormStore.setIsLoading(true);
        const initialSettings = deepCopy(configurationPageStore.formStore.initialData);

        requestsStore.patchSettings(initialSettings, newSettings, false)
            .then(deactivateDialogCb)
            .then(() => dialogFormStore.setIsLoading(false))
            .then(configurationPageStore.initiateStoreData)
            .then(() => notificationService.success(lang.EMAIL_SERVER_SETTINGS, lang.EMAIL_SERVER_SETTINGS_HAVE_BEEN_SAVED))
            .catch(() => dialogFormStore.setIsLoading(false));
    };

    const modernSettingsHandler = (userRole: UserRoleRepresentation, redirectUrl: string, clientSettings: OAuth2ClientSettings): void => {
        dialogFormStore.setIsLoading(true);
        const signInBody = getOAuthSettings(userRole, redirectUrl, clientSettings);

        requestsStore.oAuthSignInRequest(signInBody)
            .then((signInParams: OAuth2SignInParameters): void => window.location.assign(signInParams.location))
            .catch(() => dialogFormStore.setIsLoading(false));
    };

    return { onSaveActionClick, testedSmtpSettings };
};
