/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { SobrUsageService } from './services/sobr-usage.service';

export const SobrUsage: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    const params = {
        colors: [palette.B400, palette.Grn200, palette.B250, palette.T500, '#834799'],
        yTitle: lang.USAGE,
    };
    return (
        <BaseTrendChart
            params={params}
            service={new SobrUsageService(transportService, lang)}
            title={{ text: capitalize(lang.STORAGE_USAGE) }}
        />
    );
};
