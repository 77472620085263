/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TOOLBAR_VIEW_TYPE, TOOLBAR_ITEM_TYPE } from '@veeam/components';
import { BasicFilter, Search, ConfiguredToolbar, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import group from 'images/filters/protected-data-type/group.svg';
import site from 'images/filters/protected-data-type/site.svg';
import teams from 'images/filters/protected-data-type/teams.svg';
import user from 'images/filters/protected-data-type/user.svg';

export const VBObjectsFilterToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            placeholder={lang.OBJECT}
                            name='objectNameFilter'
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <BasicFilter
                            label={lang.TYPE}
                            hasAllButton
                            name='objectTypeFilter'
                            buttons={[
                                {
                                    icon: user,
                                    value: 'User',
                                    title: lang.USER,
                                },
                                {
                                    icon: group,
                                    value: 'Group',
                                    title: lang.GROUP,
                                },
                                {
                                    icon: teams,
                                    value: 'Teams',
                                    title: lang.TEAMS,
                                },
                                {
                                    icon: site,
                                    value: 'Site',
                                    title: lang.SITE,
                                },
                            ]}
                        />
                    ),
                },
            ]}
        />
    );
};
