/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    DurationColumn,
    FieldsetBlocks,
    PortalSpinner,
    SizeColumn,
    constants,
    useGlobalLang,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { capitalize, DurationFormatTypes } from '@veeam-vspc/shared/helpers';
import styled from 'styled-components';

import type { BackupServerJobGetCdpJobStatisticsParam, CdpJobStatisticsModel } from '@veeam-vspc/models/web-controllers';
import type { FieldsetBlockProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusColumn } from 'views/components/columns/StatusColumn';
import { useTransportRequest } from 'views/hooks';
import { JobStatisticsLabelText as LabelText } from '../JobStatisticsLabelText/JobStatisticsLabelText';
import { JobStatisticsValueText as ValueText } from '../JobStatisticsValueText/JobStatisticsValueText';
import { isEmpty } from 'core/utils';

export interface CdpJobStatisticsDialogProps {
    deactivate: () => void;
    jobUid: string;
    title: string;
}

const DialogCustom = styled(Dialog)`
    min-width: ${constants.MODAL_SIZE_XL.width};
`;

export const CdpJobStatisticsDialog: React.FC<CdpJobStatisticsDialogProps> = ({ deactivate, jobUid, title }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { loading, data } = useTransportRequest<BackupServerJobGetCdpJobStatisticsParam, CdpJobStatisticsModel>(
        '/BackupServerJob/GetCdpJobStatistics',
        { jobUid }
    );
    const { lastPeriod, lastDay } = (data as CdpJobStatisticsModel) || {};

    const fieldsetBlocksData: FieldsetBlockProps[] = data
        ? [
            {
                title: lang.LAST_PERIOD,
                sections: [
                    {
                        title: <LabelText text={lang.RPO} isFirst isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.SLA} isFirst />,
                                value: <ValueText text={isEmpty(lastPeriod?.sla) ? null : `${lastPeriod?.sla}%`} />,
                            },
                            {
                                label: <LabelText text={lang.MAX_DELAY} isFirst />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={lastPeriod?.maxDelay}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={lang.BOTTLENECK} isFirst />,
                                value: (
                                    <ValueText
                                        text={
                                            lastPeriod?.bottleneck
                                                ? portalEnums.getEnumDescription('cdpBottleneckRepresentation', lastPeriod?.bottleneck)
                                                : null
                                        }
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.SYNC_SESSIONS} isBold />,
                        items: [
                            {
                                label: <LabelText text={<StatusColumn status={lang.SUCCESS} isCorrectStatus compact />} />,
                                value: <ValueText text={lastPeriod?.statusCounter?.successCount} />,
                            },
                            {
                                label: <LabelText text={<StatusColumn status={lang.WARNING} isCorrectStatus compact />} />,
                                value: <ValueText text={lastPeriod?.statusCounter?.warningCount} />,
                            },
                            {
                                label: <LabelText text={<StatusColumn status={lang.ERROR} isCorrectStatus compact />} />,
                                value: <ValueText text={lastPeriod?.statusCounter?.errorCount} />,
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.DURATION} isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.AVERAGE} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={lastPeriod?.duration?.avgSec}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={lang.MAXIMUM} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={lastPeriod?.duration?.maxSec}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={lang.SYNC_INTERVAL} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={lastPeriod?.duration?.syncIntervalSec}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.DATA} isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.AVERAGE} />,
                                value: <ValueText text={<SizeColumn value={lastPeriod?.transferredData?.avgDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.MAXIMUM} />,
                                value: <ValueText text={<SizeColumn value={lastPeriod?.transferredData?.maxDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.TOTAL} />,
                                value: <ValueText text={<SizeColumn value={lastPeriod?.transferredData?.totalDataKb} pointFixed={1} />} />,
                            },
                        ],
                    },
                ],
            },
            {
                title: lang.LAST_24_HOURS,
                sections: [
                    {
                        title: <LabelText text={lang.RPO} isFirst isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.SLA} isFirst />,
                                value: <ValueText text={isEmpty(lastDay?.sla) ? null : `${lastDay.sla}%`} />,
                            },
                            {
                                label: <LabelText text={lang.MAX_DELAY} isFirst />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={lastDay?.maxDelay}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={lang.BOTTLENECK} isFirst />,
                                value: (
                                    <ValueText
                                        text={
                                            lastDay?.bottleneck
                                                ? portalEnums.getEnumDescription('cdpBottleneckRepresentation', lastDay?.bottleneck)
                                                : null
                                        }
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.SYNC_SESSIONS} isBold />,
                        items: [
                            {
                                label: <LabelText text={<StatusColumn status={lang.SUCCESS} isCorrectStatus compact />} />,
                                value: <ValueText text={lastDay?.statusCounter?.successCount} />,
                            },
                            {
                                label: <LabelText text={<StatusColumn status={lang.WARNING} isCorrectStatus compact />} />,
                                value: <ValueText text={lastDay?.statusCounter?.warningCount} />,
                            },
                            {
                                label: <LabelText text={<StatusColumn status={lang.ERROR} isCorrectStatus compact />} />,
                                value: <ValueText text={lastDay?.statusCounter?.errorCount} />,
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.DATA} isBold />,
                        items: [
                            {
                                label: <LabelText text={capitalize(lang.TOTAL_SIZE, true)} />,
                                value: <ValueText text={<SizeColumn value={lastDay?.transferredData?.totalDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.READ} />,
                                value: <ValueText text={<SizeColumn value={lastDay?.transferredData?.readDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.TRANSFERRED} />,
                                value: <ValueText text={<SizeColumn value={lastDay?.transferredData?.transferredDataKb} pointFixed={1} />} />,
                            },
                        ],
                    },
                ],
            },
        ]
        : [];

    return (
        <DialogCustom
            header={title}
            size={{
                width: DIALOG_SIZE.auto,
                height: DIALOG_SIZE.l,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            {loading && <PortalSpinner whiteBg />}
            {!loading && <FieldsetBlocks items={fieldsetBlocksData} />}
        </DialogCustom>
    );
};
