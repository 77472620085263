/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { formatStr } from '@veeam-vspc/shared/helpers';
import {
    Form,
    FormLayout,
    FormValidator,
    isValid,
    NoLabelGroup,
    PasswordInput,
    TextInput,
    useExternalFormApi,
    useWizardStore,
} from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type {
    UserCheckCurrentPasswordParam,
    UserCheckCurrentPasswordResponse,
    UserData,
} from '@veeam-vspc/models/web-controllers';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { NotificationService } from '@veeam-vspc/shared/services';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';

import { useEditUserProfileStore } from '../../stores';
import { EditUserProfileStepIds } from '../../enums';

interface LoginInfoStepProps {
    lang: VspcLang;
    stepModel: WizardStepData<UserData>;
}

const maxLength = 255;

const formValidate = (lang: VspcLang, data: UserData) => {
    const validator = new FormValidator(data);

    validator
        .validate('oldPassword')
        .string()
        .maxLength(maxLength, formatStr(lang.THE_MAXIMUM_LENGTH_IS, maxLength))
        .check((v) => {
            if (v && !v.trim()) {
                return lang.PASSWORD_FIELD_CANNOT_ONLY;
            }
        });

    if (data['password'] || data['confirmPassword']) {
        validator
            .validate('oldPassword')
            .string()
            .required();

        validator
            .validate('password')
            .string()
            .maxLength(maxLength, formatStr(lang.THE_MAXIMUM_LENGTH_IS, maxLength))
            .check((v) => {
                if (v && !v.trim()) {
                    return lang.PASSWORD_FIELD_CANNOT_ONLY;
                }
            });

        validator
            .validate('confirmPassword')
            .string()
            .maxLength(maxLength, formatStr(lang.THE_MAXIMUM_LENGTH_IS, maxLength))
            .check(() => {
                if (data['password'] !== data['confirmPassword']) {
                    return lang.PASSWORDS_DO_NOT_MATCH;
                }
            });
    }

    return validator.result();
};

const stepValidate = (
    lang: VspcLang,
    { data: userData }: WizardStepData<UserData>,
    notificationService: NotificationService,
    transportService: TransportService<RequestErrorResponse | Error>
) => {
    if (isValid(value => formValidate(lang, value), userData)) {
        return transportService
            .request<UserCheckCurrentPasswordParam, RequestSuccessResponse<UserCheckCurrentPasswordResponse>>('/User/CheckCurrentPassword', {
                password: userData.password,
            })
            .then(({ data }: RequestSuccessResponse<UserCheckCurrentPasswordResponse>) => {
                if (!userData.oldPassword || !data.errors) {
                    return true;
                }

                return notificationService.error(lang.VERIFYING_SETTINGS, lang.SPECIFIED_VALUE_IS_INVALID)
                    .then(() => false);
            });
    }

    return false;
};

const LoginInfoStep: React.FC<LoginInfoStepProps> = observer(({ lang, stepModel }) => {
    const editUserProfileStore = useEditUserProfileStore();
    const { data, isVcd, disabledPassword, disabledNewPassword } = editUserProfileStore;
    const formApi = useExternalFormApi<UserData>();
    const store = useWizardStore();

    editUserProfileStore.shouldConfigureMfa && store.setStepId(EditUserProfileStepIds.MfaStep);

    return (
        <Form
            value={data}
            externalFormApi={formApi}
            validate={data => formValidate(lang, data)}
            validationState={stepModel.validationState}
        >
            <NoLabelGroup
                content={(
                    <FormLayout inlineLabel>
                        <TextInput label={lang.USERNAME} name={'login'} disabled />

                        <PasswordInput
                            label={isVcd ? lang.PASSWORD : lang.CURRENT_PASSWORD}
                            maskPlaceholder={null}
                            name={'oldPassword'}
                            disabled={disabledPassword}
                        />

                        <PasswordInput
                            label={lang.NEW_PASSWORD}
                            maskPlaceholder={null}
                            name={'password'}
                            disabled={disabledNewPassword}
                        />

                        <PasswordInput
                            label={lang.CONFIRM_PASSWORD}
                            maskPlaceholder={null}
                            name={'confirmPassword'}
                            disabled={disabledNewPassword}
                        />
                    </FormLayout>
                )}
            />
        </Form>
    );
});

export const getLoginInfoStep = (
    lang: VspcLang,
    notificationService: NotificationService,
    transportService: TransportService<RequestErrorResponse | Error>
): WizardStep<UserData> => ({
    title: lang.LOGIN_INFO,
    description: formatStr(lang.SPECIFY_USERNAME_FOR_THE_USER, ` ${lang.AND.toLowerCase()} ${lang.PASSWORD.toLowerCase()}`),
    render: stepModel => <LoginInfoStep lang={lang} stepModel={stepModel} />,
    validate: stepModel => stepValidate(lang, stepModel, notificationService, transportService),
});
