/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { CompanyVboRepositoryStorageType } from '../../enums';


export const getVb365RepositoryStorageTypes = (lang: VspcLang): { id: CompanyVboRepositoryStorageType; name: string; }[] => ([
    {
        id: CompanyVboRepositoryStorageType.ObjectStorage,
        name: capitalize(lang.OBJECT_STORAGE, true),
    },
    {
        id: CompanyVboRepositoryStorageType.ArchiveObjectStorage,
        name: lang.ARCHIVE_OBJECT_STORAGE,
    },
    {
        id: CompanyVboRepositoryStorageType.JetDatabase,
        name: lang.JET_DATABASE,
    },
]);
