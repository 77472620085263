/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { VbrDeployType } from '../../enums';
import { LicenseGrid } from './components/LicenseGrid';

import type { VbrDeploySettingsWizardModel } from '../../entries';

const PulseLicense = (stepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();

    useEffect(() => {
        if (stepData.validationState.isForce()) {
            stepData.validationState.markAsHandledForce();

            if (!stepData.data.licenseId) {
                notificationService.error(lang.ERROR, `${lang.SELECT_A_LICENSE_KEY_FROM}.`);
            }
        }
    });

    return (
        <StepLayout
            title={lang.VCSP_PULSE}
            description={`${lang.SPECIFY_LICENSE_FILE_TO_USE}.`}
        >
            <LicenseGrid {...stepData} />
        </StepLayout>
    );
};

export const getPulseLicenseStep = (title: string): WizardStep<VbrDeploySettingsWizardModel> => ({
    title,
    isHidden: ({ data }) => {
        if (data.vbrDeployType === VbrDeployType.Upgrade) {
            return true;
        }

        if (data.licenseSource !== VbrDeploymentLicenseSourceType.Pulse) {
            return true;
        }
    },
    validate: ({ data, stepData }) => {
        if (!data.licenseId) {
            return false;
        } else if (stepData.isLicenseAlreadyInstalled) {
            return stepData.isLicenseAlreadyInstalled();
        }

        return true;
    },
    render: data => <PulseLicense {...data} />,
});
