/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';

import type {
    PublicCloudProtectedVmBackupRestorePointModel,
    PublicCloudProtectedVmBackupRestorePointParam,
} from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { MAX_INTEGER_32 } from 'core/const';

const { Snapshot, RemoteSnapshot } = PublicCloudVmRestoreType;

export interface PublicCloudsRestorePointDialogProps {
    stateId: string;
    name: string;
    extraParams: Pick<PublicCloudProtectedVmBackupRestorePointParam, 'backupType' | 'backupUid' | 'backupServerUid' | 'vmUid' | 'platformType'>;
    deactivate: () => void;
}

export const PublicCloudsRestorePointDialog: React.FC<PublicCloudsRestorePointDialogProps> = ({ stateId, name, extraParams, deactivate }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { backupType } = extraParams;
    const isSnapshot = [Snapshot, RemoteSnapshot].includes(backupType);
    const columns: GridColumnProps<PublicCloudProtectedVmBackupRestorePointModel>[] = [
        {
            id: 'creationDate',
            title: lang.DATE,
            cell: rowData => <GridDateColumn value={rowData.creationDate} />,
        },
        !isSnapshot && {
            id: 'usedSourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.usedSourceSize} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        isSnapshot && {
            id: 'usedSourceSize',
            title: lang.VM_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.usedSourceSize} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
    ];

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} ${name ? `- ${name}` : ''}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: PublicCloudProtectedVmBackupRestorePointParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<PublicCloudProtectedVmBackupRestorePointModel[]>>(
                            '/PublicCloudVm/GetProtectedVmBackupRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                lang={lang}
                                idProperty={'instanceUid'}
                                method={'GetPublicCloudVmRestorePoint'} // backend mistake that "point" from small letter
                                extraParams={extraParams}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
