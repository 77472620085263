/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AgentTaskStatus } from '@veeam-vspc/models/web-controllers';
import { useGlobalAddons } from '@veeam-vspc/shared/components';

import { StatusColumn } from 'views/components/columns/StatusColumn';
import { StatusTypes } from 'core/utils/status-helpers/enums';
import { getStatusIconSrc } from 'core/utils';

interface TasksStatusColumnProps {
    status: AgentTaskStatus;
}

function getStatus(status: AgentTaskStatus) {
    switch (status) {
        case AgentTaskStatus.WaitToRun:
            return StatusTypes.Pending;
        case AgentTaskStatus.Faulted:
        case AgentTaskStatus.Error:
            return StatusTypes.Canceled;
        default:
            return status;
    }
}

export const TasksStatusColumn: React.FC<TasksStatusColumnProps> = ({ status }) => {
    const { portalEnums } = useGlobalAddons();

    return (
        <StatusColumn
            isCorrectStatus
            status={portalEnums.getEnumDescription('AgentTaskStatus', status)}
            statusIconSrc={getStatusIconSrc(getStatus(status))}
        />
    );
};
