/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PortalLayout, PortalSpinner, useModal } from '@veeam-vspc/shared/components';

import type { AppRouteItem } from '@veeam-vspc/shared/stores';

import { AppPortalHeader } from 'views/components/AppPortalHeader';
import { LicenseKeyDialog } from 'views/pages/LicensingPage/components/LicenseKeyDialog';
import { useAppPortalLaunch } from 'views/hooks';
import { useMonthlyUsageReport } from 'views/hooks/use-monthly-usage-report';
import { closeExtDialogsOnLogout } from 'core/utils';
import { NotificationBarStore, NotificationBarStoreContext } from '../../NotificationBar/store';

export interface AppPortalLayoutProps {
    currentPath?: string;
    items: AppRouteItem[];
}

export interface LicenseKeyDlgProps {
    onClose: () => void;
}

export const AppPortalLayout: React.FC<AppPortalLayoutProps> = observer(({ currentPath, items }) => {
    const [licenseKeyDlg, licenseKeyDlgActions] = useModal<LicenseKeyDlgProps>({
        render: ({ data }) => (
            <LicenseKeyDialog
                onClose={data.onClose}
            />
        ),
    });
    const [showApp] = useAppPortalLaunch(licenseKeyDlgActions); // here dialogs that should be shown before portal opens
    const store = useMemo(() => new NotificationBarStore(), []);

    useMonthlyUsageReport(showApp);

    useEffect(() => closeExtDialogsOnLogout, []);

    useEffect(() => () => store.hideAllAndFireCancelActions(), [store]);

    return (
        <>
            {!showApp && <PortalSpinner whiteBg />}

            {showApp && (
                <NotificationBarStoreContext.Provider value={store}>
                    <PortalLayout
                        currentPath={currentPath}
                        items={items}
                        header={<AppPortalHeader />}
                        customPortalBody={<Outlet key={currentPath} />}
                    />
                </NotificationBarStoreContext.Provider>
            )}

            {licenseKeyDlg}
        </>
    );
});
