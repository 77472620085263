/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { HardwarePlanCompanies } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { CpuColumnRender, SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const UsedHardwarePlansByCompanies: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings =
        useCommonSettings<HardwarePlanCompanies>({
            maxNumberOfRows,
            getDataRequestUrl: TopDataUrls.UsedHardwarePlansByCompanies,
        });
    const { lang } = settings;

    const columns: GridColumnProps<HardwarePlanCompanies>[] = [
        {
            id: 'name',
            title: lang.HARDWARE_PLAN,
            cell: rowData => rowData.name,
            width: 130,
        },
        {
            id: 'cpuUsageMhz',
            title: lang.CPU_USAGE,
            cell: rowData => <CpuColumnRender value={rowData.cpuUsageMhz} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'ramUsage',
            title: lang.MEMORY_USAGE,
            cell: rowData => <SizeColumnRender value={rowData.ramUsage} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 130,
        },
        {
            id: 'storageUsage',
            title: lang.STORAGE_USAGE,
            cell: rowData => <SizeColumnRender value={rowData.storageUsage} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 130,
        },
        {
            id: 'serverName',
            title: lang.CLOUD_CONNECT_SERVER,
            cell: rowData => rowData.serverName,
            width: 180,
        },
        {
            id: 'companiesCount',
            title: lang.COMPANIES,
            cell: rowData => rowData.companiesCount,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_USED_HARDWARE_PLANS_BY_COMPANIES}
            columns={columns}
            sectionId={ConfigSectionIds.TopsInfrastructure}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
