/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, ConfiguredToolbar, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

export const AvailableOrganizationsToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.default}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            maxLength={100}
                            placeholder={lang.ORGANIZATION}
                            name='name'
                        />
                    ),
                },
            ]}
        />
    );
};
