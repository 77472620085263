/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { ReportIntervalRepresentation } from '@veeam-vspc/models/web-controllers';

export class IntervalStore {

    @observable interval: ReportIntervalRepresentation;

    constructor(interval: ReportIntervalRepresentation, readonly localStorageStateId: string) {
        makeObservable(this);
        this.interval = interval;
    }

    @action.bound
    setInterval(interval: ReportIntervalRepresentation): void {
        this.interval = interval;
    }
}
