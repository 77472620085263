/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

export const getGfsPolicyHelpText = (data: BackupServerBackupJobConfiguration, lang: VspcLang) => {
    const values = [];

    if (data.storage.gfsPolicy?.weekly?.isEnabled) {
        values.push(`${data.storage.gfsPolicy.weekly.keepForNumberOfWeeks} ${lang.WEEKLY}`);
    }

    if (data.storage.gfsPolicy?.monthly?.isEnabled) {
        values.push(`${data.storage.gfsPolicy.monthly.keepForNumberOfMonths} ${lang.MONTHLY}`);
    }

    if (data.storage.gfsPolicy?.yearly?.isEnabled) {
        values.push(`${data.storage.gfsPolicy.yearly.keepForNumberOfYears} ${lang.YEARLY}`);
    }

    return values.length ? values.join(', ') : undefined;
};
