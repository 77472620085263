/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Icon,
    STACK_GAP,
    StackView,
    Text,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    ToolbarWithExport,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
    useGridApi,
    useModal,
    PortalSpinner,
} from '@veeam-vspc/shared/components';
import {
    DeployAgentStatusRepresentation,
    EActionResultStatus,
    EVacAgentStatusInteg,
    PulseProductType,
    SoftwareStatusRepresentation,
    SystemTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import {
    capitalize,
    formatStr,
    hideForFilterWithoutPropValue,
    hideForSelectionWithoutPropValue,
    TextDividerSymbols,
} from '@veeam-vspc/shared/helpers';
import { deepCopy, delay } from '@veeam-vspc/shared/core';
import { observer } from 'mobx-react-lite';

import type { NotificationDialogsMessage, NotificationResponse } from '@veeam-vspc/shared/services';
import type {
    AgentsToDelete,
    AutoDeploymentConfigRepresentation,
    DiscoveredComputerFilter,
    DiscoveredComputerModel,
    MultiActionResult,
    PulseProductModel,
    VbrDeployWizardParametersModel,
} from '@veeam-vspc/models/web-controllers';
import type { CustomToolbarPayload, DecoratorFunction, ToolbarItem } from '@veeam-vspc/shared/components';
import type { UseFullPageActions } from '@veeam-vspc/shared/hooks';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import installAgentIconSrc from 'images/actions/install-backup-agent.png';
import installVbrIconSrc from 'images/actions/install-vbr.svg';
import autoDeploymentIconSrc from 'images/actions/auto-deployment.png';
import restartIconSrc from 'images/actions/restart.png';
import rebootIconSrc from 'images/filters/discovered-computer-connection-status/rebooting.png';
import setLocationSrc from 'images/actions/set-location.png';
import resolveSrc from 'images/actions/resolve.svg';
import disableSrc from 'images/actions/disable.svg';
import removeSrc from 'images/actions/remove.svg';
import companySrc from 'images/company.svg';
import windowsSrc from 'images/filters/os/windows.svg';
import linuxSrc from 'images/filters/os/linux.svg';
import macSrc from 'images/filters/os/mac.svg';
import defaultsSrc from 'images/actions/defaults.png';
import downloadLogsIconSrc from 'images/actions/download-logs.png';
import managementAgentSrc from 'images/filters/managenemt-mode/vac-agent.png';
import downloadAgentSrc from 'images/actions/download-agent.svg';
import customNoteSrc from 'images/actions/custom-note.png';
import upgradeSrc from 'images/upgrade.png';
import { useAsyncAction, useDependentToolbarButton } from 'views/hooks';
import { isAssignTagAllowed } from 'views/components/columns/CustomNoteColumn/utils/assign-tag-helper';
import { ConfigActionIds, ConfigSectionIds, SystemTypeRepresentations } from 'core/enums';
import { LocationDialog } from './components/LocationDialog';
import { CompanyDialog } from './components/CompanyDialog';
import { SetCustomNoteDialog } from 'views/components/columns/CustomNoteColumn/components/SetCustomNoteDialog/SetCustomNoteDialog';
import { waitFor } from 'core/utils';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';
import type { Product } from '../../../../../InstallAndUpdateVBRWizard/interfaces';

interface LocationDialogProps {
    locationId: number;
    companyId: number;
}

interface ComputersActionToolbarProps {
    installVbrWizardAction: UseFullPageActions<{
        vbrParams: VbrDeployWizardParametersModel;
        hostName: string;
        products: Product[];
        onFinish: () => void;
    }>;
}

export const ComputersActionToolbar: React.FC<ComputersActionToolbarProps> = observer(({
    installVbrWizardAction,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<DiscoveredComputerModel, unknown, DiscoveredComputerFilter>();
    const { notificationService, transportService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [loading, setLoading] = useState<boolean>(false);

    const installAgentGroupId = ConfigActionIds.ActionToolbarDeployAgentGroup;
    const managementAgentGroupId = ConfigActionIds.ActionToolbarManagementAgent;
    const downloadAgentGroupId = ConfigActionIds.ActionToolbarDownloadAgentGroup;

    const [locationDialog, locationDialogActions] = useModal<LocationDialogProps>({
        render: ({ deactivate, data }) => (
            <LocationDialog
                {...data}
                onLocationChosen={(locationId) => {
                    doAsyncAction(
                        '/Agent/SaveLocation',
                        lang.SET_LOCATION,
                        {
                            ...gridApi.requestParamsForActions,
                            locationId,
                        }
                    ).then(() => {
                        gridApi.reloadGrid();
                        deactivate();
                    });
                }}
                entityName={lang.DISCOVERED_COMPUTER.toLowerCase()}
                deactivate={deactivate}
            />
        ),
    });

    const [companyDialog, companyDialogActions] = useModal<void>({
        render: ({ deactivate }) => (
            <CompanyDialog
                selected={gridApi.selected}
                onCompanyChosen={(companyUid) => {
                    doAsyncAction(
                        '/Agent/MoveToCompany',
                        lang.SET_COMPANY,
                        {
                            ...gridApi.requestParamsForActions,
                            companyUid,
                        }
                    ).then(() => {
                        gridApi.reloadGrid();
                        deactivate();
                    });
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [tagDialog, tagDialogActions] = useModal<string>({
        render: ({ deactivate, data }) => (
            <SetCustomNoteDialog
                value={data}
                onSubmit={(tag) => {
                    doAsyncAction(
                        '/Agent/SetCustomNote',
                        lang.ASSIGN_TAG,
                        {
                            ...gridApi.requestParamsForActions,
                            note: tag,
                        }
                    ).then(() => {
                        gridApi.reloadGrid();
                        deactivate();
                    });
                }}
                deactivate={deactivate}
            />
        ),
    });

    const groupInstallAgentDecorators = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const relevantItems = selectedItems.filter(item => item.allowInstallBackupAgentButton);

            return {
                disabled: relevantItems.length === 0,
            };
        };

    const installVbrDecorators = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            let disabled = selectedItems.length !== 1;

            if (!disabled) {
                const item: DiscoveredComputerModel = selectedItems[0];
                disabled = item.pendingList || item.agentStatus === EVacAgentStatusInteg.NotSet;
            }

            return {
                disabled,
            };
        };
    const restartDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => ({
            disabled: selectedItems.some(
                (item: DiscoveredComputerModel) => item.agentStatus === EVacAgentStatusInteg.Restarting || item.pendingList
            ),
        });

    const setLocationDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => ({
            disabled: selectedItems.some(
                (item: DiscoveredComputerModel) =>
                    item.agentStatus === EVacAgentStatusInteg.Restarting
                    || item.pendingList
                    || (portalUser.isPortalAdministrator() && item.companyId === portalUser.companyId)
                    || item.companyId !== selectedItems[0].companyId
            ),
        });

    const setCompanyDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const isPendingListRecords = selectedItems.every(item => item.pendingList);
            const isAdminCompany = portalUser.isPortalAdministrator()
                ? selectedItems.every(item => item.companyId === portalUser.companyId)
                : false;
            const isRestartingStatus = selectedItems
                .every(item => item.agentStatus === EVacAgentStatusInteg.Restarting);
            const isInstallingDeployStatus = selectedItems
                .every(item => item.deployStatus === DeployAgentStatusRepresentation.Installing);

            if (isPendingListRecords) {
                return {
                    disabled: false,
                };
            }

            return {
                disabled: isAdminCompany || isRestartingStatus || isInstallingDeployStatus,
            };
        };

    const setAsMasterDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveredComputerModel[];
            return {
                disabled: selected.length !== 1
                    || selected[0].agentStatus === EVacAgentStatusInteg.Restarting
                    || selected[0].isMasterAgent
                    || selected[0].pendingList,
            };
        };

    const acceptConnectionDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveredComputerModel[];
            return {
                disabled: !selected.length || selected.filter(
                    item =>
                        item.agentStatus === EVacAgentStatusInteg.Restarting
                        || item.isConnectionAccepted
                        || item.isVacInstalled !== SoftwareStatusRepresentation.Installed
                ).length > 0,
            };
        };

    const rejectConnectionDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveredComputerModel[];
            return {
                disabled: !selected.length || selected.filter(
                    item =>
                        item.agentStatus === EVacAgentStatusInteg.Restarting
                        || !item.isConnectionAccepted
                        || item.pendingList
                        || item.isVacInstalled !== SoftwareStatusRepresentation.Installed
                ).length > 0,
            };
        };

    const assignTagDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveredComputerModel[];

            return {
                disabled: selected.length !== 1
                    || !isAssignTagAllowed('', portalUser.userRole, selectedItems[0]).canEdit,
            };
        };

    const upgradeDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => ({
            disabled: !selectedItems.every(
                (item: DiscoveredComputerModel) => item.agentStatus !== EVacAgentStatusInteg.Restarting && !item.pendingList
            ),
        });

    const removeDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => ({
            disabled: !selectedItems.every(
                (item: DiscoveredComputerModel) => item.agentStatus !== EVacAgentStatusInteg.Restarting
            ),
        });

    const downloadLogDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => ({
            disabled: selectedItems.length > 10 || !selectedItems.every(
                (item: DiscoveredComputerModel) => item.agentStatus !== EVacAgentStatusInteg.Restarting && !item.pendingList
            ),
        });

    const waitForExt = () => {
        const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

        setLoading(true);

        return promise
            .then(() => delay(500))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const installAgentAction = async(os: SystemTypeRepresentation = null) => {
        const relevantItems = gridApi.selected.filter(item => item.allowInstallBackupAgentButton);
        const currentOs = os || relevantItems[0].systemType;
        const currentOsInt = portalEnums.getEnumIntValue('systemTypeRepresentation', currentOs);
        let ruleId = gridApi.filters.ruleId;

        if (relevantItems.length === 1) {
            ruleId = relevantItems[0].ruleId;
        }

        await waitForExt();

        const dlg = Ext.create(
            os === SystemTypeRepresentation.Linux
                ? 'RCOP.view.home.discovery.discoveredComputers.dialogs.installBackupAgent.linux.InstallBackupAgent'
                : 'RCOP.view.home.discovery.discoveredComputers.dialogs.installBackupAgent.windows.InstallBackupAgent',
            {
                records: gridApi.requestParamsForActions,
                ruleId,
                osType: currentOsInt,
            }
        );

        dlg.open((values) => {
            function deploy() {
                return doAsyncAction(
                    '/DiscoveryRule/Deploy',
                    lang.INSTALL_AGENT,
                    { data: gridApi.requestParamsForActions, config: dataForReact }
                ).then(() => dlg.close());
            }

            const dataForReact = deepCopy(values);
            portalEnums.restoreStringEnumsInData('DeploymentConfigurationRepresentation', dataForReact);
            dataForReact.systemType = currentOs;

            RCOP.view.home.discovery.discoveredComputers.dialogs.installBackupAgent.base.InstallBackupAgentController
                .checkBackupAgentQuotaUsage(deploy);
        });
    };

    const installMacAgentAction = () => {
        const needActivate = gridApi.selected.every(
            item => (
                item.systemType === SystemTypeRepresentation.Mac
                && item.isVawInstalled === SoftwareStatusRepresentation.Installed
                && item.isVacInstalled === SoftwareStatusRepresentation.Installed
            )
        );

        if (needActivate) {
            doAsyncAction(
                '/DiscoveryRule/Deploy',
                lang.INSTALL_AGENT,
                {
                    data: gridApi.requestParamsForActions,
                    config: {
                        systemType: SystemTypeRepresentation.Mac,
                    },
                },
            );
        } else {
            notificationService.info(
                lang.INSTALL_BACKUP_AGENT,
                lang.REMOTE_AGENT_INSTALLATION_IS_NOT_SUPPORTED_FOR_MACOS,
                {
                    internalLinkProps: {
                        onClick: async(event) => {
                            event.preventDefault();

                            notificationService.hide({ asResolved: true });

                            await showDownloadAgentDialog(SystemTypeRepresentation.Mac);
                        },
                    },
                }
            );
        }
    };

    const needSetAutoDeployment = portalUser.isPortalOrSiteAdmin() || portalUser.isServiceProviderGlobalOrUsualAdmin();
    const [autoDeployment, setAutoDeployment] = useState(needSetAutoDeployment ? true : false);
    const [autoDeploymentConfig, setAutoDeploymentConfig] = useState<AutoDeploymentConfigRepresentation>(null);
    const autoDeploymentText = `${lang.AUTO_DEPLOYMENT}: ${autoDeployment ? lang.ON : lang.OFF}`;

    useEffect(() => {
        if (needSetAutoDeployment) {
            transportService.request('/DiscoveryRule/GetGlobalAutoDeployConfiguration', { rcopAutoUpdate: true })
                .then(
                    (resp: RequestSuccessResponse<AutoDeploymentConfigRepresentation>) => {
                        setAutoDeployment(resp.data.enabled);
                        setAutoDeploymentConfig(resp.data);
                    }
                );
        }
    }, []);


    const installVbrHandler = () => {
        const { allowInstallVbrButton: allowInstallVbr, deployStatus, hostName } = gridApi.selected[0];
        const deployIsInProcess = (deployStatus === DeployAgentStatusRepresentation.Installing)
            || (deployStatus === DeployAgentStatusRepresentation.Upgrading);

        if (deployIsInProcess) {
            notificationService.info(lang.INSTALL_BACKUP_SERVER, lang.CANNOT_INSTALL_VEEAM_BACKUP);
            return;
        }

        if (!allowInstallVbr) {
            notificationService.info(
                lang.INSTALL_BACKUP_SERVER,
                `${lang.VBR_IS_ALREADY_INSTALLED}.`
            );
            return;
        }

        transportService.request(
            '/DiscoveryRule/ValidateVbrDeployParameters',
            gridApi.requestParamsForActions
        )
            .then(
                async(resp: RequestSuccessResponse<MultiActionResult>) => {
                    const { data } = resp;
                    const results = data && data.results;

                    if (
                        (results && results.length > 0 && results.every(res => !res.success))
                        || (data.status === EActionResultStatus.Error && data.message)
                    ) {
                        return Promise.reject(resp);
                    }

                    const { data: wizardProps } = await transportService.request<unknown, RequestSuccessResponse<VbrDeployWizardParametersModel>>(
                        '/DiscoveryRule/GetDeployVbrWizardParameters',
                        gridApi.requestParamsForActions
                    )
                        .then((resp: RequestSuccessResponse<VbrDeployWizardParametersModel>) => resp);

                    const products: Product[] = [];

                    if (wizardProps.pulseConfigured) {
                        const productsResp = await transportService
                            .request('/Pulse/GetLicenseKeysProductsForOrganization', {
                                organizationUid: wizardProps.organizationUid,
                                filter: {
                                    productTypes: [PulseProductType.BackupAndReplication],
                                },
                            })
                            .then((resp: RequestSuccessResponse<PulseProductModel[]>) => resp);

                        if (productsResp.data) {
                            productsResp.data.forEach(product => products.push({
                                value: product.versions[0].productUid,
                                text: product.productName,
                            }));
                        }
                    }

                    installVbrWizardAction.activate({
                        vbrParams: wizardProps,
                        hostName,
                        products,
                        onFinish: () => gridApi.reloadGrid(),
                    });
                }
            )
            .catch((resp: RequestSuccessResponse<MultiActionResult>) => {
                notificationService.multi(lang.INSTALL_BACKUP_SERVER, resp);
            });
    };

    const getNameList = (names: string[], maxItemsLength = 10) => {
        if (!Array.isArray(names)) {
            return '';
        }

        return `${names.slice(0, maxItemsLength).join(', ')}${names.length > maxItemsLength ? ', ...' : ''}`;
    };

    const getConfirmationCaseMessage = (items: AgentsToDelete = {}) => {
        const selection = gridApi.selected;
        const isSingleSelection = selection.length === 1;

        const {
            installedWithBackupAgents,
            notInstalledAgents,
            guestConnections,
        } = items;
        const notInstalledAgentNames = getNameList(notInstalledAgents);
        const installedWithBackupAgentsNames = getNameList(installedWithBackupAgents);
        const guestConnectionNames = getNameList(guestConnections);
        const existedItems = Object.keys(items).filter(key => (items[key] && (items[key].length > 0) && (key !== 'installedAgents')));

        const noAgentOnComputerText = formatStr(
            isSingleSelection
                ? lang.THE_REMOTE_COMPUTER_ARE_OFFLINE
                : lang.THE_REMOTE_COMPUTERS_ARE_OFFLINE,
            notInstalledAgentNames
        );

        const uninstallBackupAgentsText = isSingleSelection
            ? formatStr(lang.THE_BACKUP_AGENT_RUNNING_ON_THIS_COMPUTER, installedWithBackupAgentsNames)
            : formatStr(lang.ALL_BACKUP_AGENTS_RUNNING_ON_THESE_COMPUTERS_WILL_BE, installedWithBackupAgentsNames);

        const guestConnectionsText = formatStr(
            isSingleSelection
                ? lang.THE_CONNECTION_FROM_THE_MANAGEMENT_AGENT
                : lang.THE_CONNECTION_FROM_THE_MANAGEMENT_AGENTS,
            guestConnectionNames
        );

        const baseMessageText = isSingleSelection
            ? lang.THE_MANAGEMENT_AGENT_WILL_BE_UNINSTALLED
            : lang.THE_MANAGEMENT_AGENTS_WILL_BE_UNINSTALLED;

        const baseMessage: NotificationDialogsMessage = [{ text: `${baseMessageText}` }];

        if (existedItems.length >= 1) {
            baseMessage.push({ type: TextDividerSymbols.BlankLine });
        }

        if (existedItems.length > 1) {
            if (existedItems.includes('installedWithBackupAgents')) {
                baseMessage.push(
                    { text: uninstallBackupAgentsText },
                    { type: TextDividerSymbols.BlankLine }
                );
            }

            const existedItemsBaseText = isSingleSelection
                ? lang.REMOTE_MANAGEMENT_AGENT
                : lang.SOME_OF_THE_REMOTE_MANAGEMENT_AGENTS;

            baseMessage.push({ text: `${existedItemsBaseText}:` });

            existedItems.reduce((res, key) => {
                if (res.length > baseMessage.length) {
                    res.push({ type: TextDividerSymbols.Paragraph });
                }

                if (key === 'notInstalledAgents') {
                    res.push({ text: noAgentOnComputerText, listItem: true });
                } else if (key === 'guestConnections') {
                    res.push({ text: guestConnectionsText, listItem: true });
                }

                return res;
            }, baseMessage);
        } else if (installedWithBackupAgents && installedWithBackupAgents.length > 0) {
            baseMessage.push({ text: uninstallBackupAgentsText });
        } else if (notInstalledAgents && notInstalledAgents.length > 0) {
            baseMessage.push({ text: noAgentOnComputerText });
        } else if (guestConnections && guestConnections.length > 0) {
            baseMessage.push({ text: guestConnectionsText });
        }

        // Add question in the very end for all cases
        baseMessage.push(
            { type: TextDividerSymbols.BlankLine },
            { text: lang.DO_YOU_WANT_TO_PROCEED }
        );

        return baseMessage;
    };

    const showDownloadAgentDialog = async(os: SystemTypeRepresentation) => {
        const currentOsInt = portalEnums.getEnumIntValue('systemTypeRepresentation', os);

        await waitForExt();

        Ext.create('RCOP.view.home.discovery.discoveredComputers.dialogs.downloadAgent.DownloadAgent', {
            osType: currentOsInt,
        }).show();
    };

    const { group: installAgentGroup, items: installAgentItems } = useDependentToolbarButton<
        DiscoveredComputerModel,
        unknown,
        DiscoveredComputerFilter
    >(
        {
            id: installAgentGroupId,
            title: lang.INSTALL_BACKUP_AGENT,
            iconSrc: installAgentIconSrc,
        },
        [
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: installAgentGroupId,
                iconSrc: windowsSrc,
                text: lang.WINDOWS,
                onClick: () => installAgentAction(SystemTypeRepresentation.Windows),
                decorators: [
                    groupInstallAgentDecorators(),
                ],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Windows),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Windows),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: installAgentGroupId,
                iconSrc: linuxSrc,
                text: lang.LINUX,
                onClick: () => installAgentAction(SystemTypeRepresentation.Linux),
                decorators: [
                    groupInstallAgentDecorators(),
                ],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Linux),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Linux),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: installAgentGroupId,
                iconSrc: macSrc,
                text: lang.MAC,
                onClick: () => installMacAgentAction(),
                decorators: [
                    groupInstallAgentDecorators(),
                ],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Mac),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Mac),
                ],
            },
        ],
    );

    const { group: downloadAgentGroup, items: downloadAgentItems } = useDependentToolbarButton<
        DiscoveredComputerModel,
        unknown,
        DiscoveredComputerFilter
    >(
        {
            id: downloadAgentGroupId,
            title: capitalize(lang.DOWNLOAD_MANAGEMENT_AGENT),
            iconSrc: downloadAgentSrc,
        },
        [
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: downloadAgentGroupId,
                iconSrc: windowsSrc,
                text: lang.WINDOWS,
                onClick: () => showDownloadAgentDialog(SystemTypeRepresentation.Windows),
                decorators: [],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Windows),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Windows),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: downloadAgentGroupId,
                iconSrc: linuxSrc,
                text: lang.LINUX,
                onClick: () => showDownloadAgentDialog(SystemTypeRepresentation.Linux),
                decorators: [],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Linux),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Linux),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                groupId: downloadAgentGroupId,
                iconSrc: macSrc,
                text: lang.MAC,
                onClick: () => showDownloadAgentDialog(SystemTypeRepresentation.Mac),
                decorators: [],
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Mac),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Mac),
                ],
            },
        ],
    );

    const items: ToolbarItem[] = [
        ...installAgentItems,
        {
            id: ConfigActionIds.ActionToolbarAutoDeploy,
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <StackView gap={STACK_GAP.s}>
                    <Icon src={autoDeploymentIconSrc} />
                    <Text>{autoDeploymentText}</Text>
                </StackView>
            ),
        }, {
            id: ConfigActionIds.ActionToolbarChangeSettings,
            type: TOOLBAR_ITEM_TYPE.button,
            text: `${lang.CHANGE_SETTINGS}...`,
            removeFromContextMenu: true,
            onClick: async() => {
                const limitBandwidthSpeedUnit = autoDeploymentConfig?.backupAgentSettings?.limitBandwidthSpeedUnit;
                if (limitBandwidthSpeedUnit !== undefined) {
                    autoDeploymentConfig.backupAgentSettings.limitBandwidthSpeedUnit = portalEnums
                        .getEnumIntValue('backupAgentBandwidthSpeedUnitRepresentation', limitBandwidthSpeedUnit) as any;
                }

                await waitForExt();

                const win = Ext.create(
                    'RCOP.view.home.discovery.discoveredComputers.dialogs.autoDeploymentSettings.AutoDeploymentSettings',
                    { value: autoDeploymentConfig, isAutoDeployment: true }
                );
                win.getViewModel().set(autoDeploymentConfig);
                win.open(async(data) => {
                    if (limitBandwidthSpeedUnit !== undefined) {
                        data.backupAgentSettings.limitBandwidthSpeedUnit = portalEnums
                            .getEnumStringValue('backupAgentBandwidthSpeedUnitRepresentation', data.backupAgentSettings.limitBandwidthSpeedUnit);
                    }

                    await transportService.request('/DiscoveryRule/SaveGlobalAutoDeployConfiguration', data);
                    const autoDeploy = win.getViewModel().getData().enabled;
                    setAutoDeployment(autoDeploy);
                    setAutoDeploymentConfig(data);
                    win.close();
                });
            },
        }, {
            id: ConfigActionIds.ActionToolbarInstallVbrSeparator,
            type: TOOLBAR_ITEM_TYPE.separator,
        }, {
            id: ConfigActionIds.ActionToolbarInstallVbr,
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: installVbrIconSrc,
            text: lang.INSTALL_BACKUP_SERVER,
            onClick: installVbrHandler,
            decorators: [
                installVbrDecorators(),
            ],
        }, {
            id: ConfigActionIds.ActionToolbarChangeSettingSeparator,
            type: TOOLBAR_ITEM_TYPE.separator,
        }, {
            id: managementAgentGroupId,
            type: TOOLBAR_ITEM_TYPE.group,
            text: lang.MANAGEMENT_AGENT,
            iconSrc: managementAgentSrc,
            items: [
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: restartIconSrc,
                    text: lang.RESTART_AGENT_SERVICE,
                    onClick: () => {
                        doAsyncAction(
                            '/Agent/Restart',
                            lang.REJECT_AGENT_CONNECTION,
                            gridApi.requestParamsForActions,
                        );
                    },
                    decorators: [
                        restartDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: rebootIconSrc,
                    text: lang.REBOOT_REMOTE_COMPUTER,
                    onClick: () => {
                        notificationService
                            .confirm(lang.REBOOT_REMOTE_COMPUTER, lang.THIS_WILL_REBOOT_THE_SELECTED_COMPUTERS)
                            .then((btnKey: NotificationResponse) => {
                                if (btnKey === NotificationDialogsTextKeys.Yes) {
                                    doAsyncAction(
                                        '/Agent/Reboot',
                                        lang.REBOOT_REMOTE_COMPUTER,
                                        gridApi.requestParamsForActions,
                                    );
                                }
                            });
                    },
                    decorators: [
                        restartDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.separator,
                    disabled: true,
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: setLocationSrc,
                    text: lang.SET_LOCATION,
                    onClick: () => {
                        const companyId = gridApi.selected[0].companyId;
                        const locationId = gridApi.selected.length === 1 ? gridApi.selected[0].locationId : null;
                        locationDialogActions.activate({ locationId, companyId });
                    },
                    decorators: [
                        setLocationDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    id: ConfigActionIds.ActionToolbarSetCompany,
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: companySrc,
                    text: lang.SET_COMPANY,
                    onClick: () => companyDialogActions.activate(),
                    decorators: [
                        setCompanyDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: defaultsSrc,
                    text: lang.SET_AS_MASTER,
                    onClick: () => {
                        doAsyncAction(
                            '/Agent/SetAsMaster',
                            lang.SET_AS_MASTER,
                            {
                                ...gridApi.requestParamsForActions,
                            }
                        );
                    },
                    decorators: [
                        setAsMasterDecorator(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.separator,
                    disabled: true,
                }, {
                    id: ConfigActionIds.ActionToolbarAcceptConnection,
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: resolveSrc,
                    text: lang.ACCEPT_CONNECTION,
                    onClick: () => {
                        doAsyncAction(
                            '/Agent/SetRejectState',
                            lang.ACCEPT_CONNECTION,
                            {
                                ...gridApi.requestParamsForActions,
                                rejected: false,
                            }
                        );
                    },
                    decorators: [
                        acceptConnectionDecorator(),
                    ],
                }, {
                    id: ConfigActionIds.ActionToolbarRejectConnection,
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: disableSrc,
                    text: lang.REJECT_CONNECTION,
                    onClick: () => {
                        notificationService
                            .confirm(lang.REJECT_AGENT_CONNECTION, lang.THIS_ACTION_WILL_DROP_A_CONNECTION)
                            .then((btnKey: NotificationResponse) => {
                                if (btnKey === NotificationDialogsTextKeys.Yes) {
                                    doAsyncAction(
                                        '/Agent/SetRejectState',
                                        lang.REJECT_CONNECTION,
                                        {
                                            ...gridApi.requestParamsForActions,
                                            rejected: true,
                                        }
                                    );
                                }
                            });
                    },
                    decorators: [
                        rejectConnectionDecorator(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.separator,
                    id: ConfigActionIds.ActionToolbarAcceptConnectionSeparator,
                    disabled: true,
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: customNoteSrc,
                    text: lang.ASSIGN_TAG,
                    onClick: () => {
                        tagDialogActions.activate(gridApi.selected[0].agentCustomNote);
                    },
                    decorators: [
                        assignTagDecorator(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: upgradeSrc,
                    text: lang.UPGRADE,
                    onClick: () => {
                        doAsyncAction(
                            '/Agent/Upgrade',
                            lang.MANAGED_COMPUTER,
                            gridApi.requestParamsForActions,
                        );
                    },
                    decorators: [
                        upgradeDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: removeSrc,
                    text: lang.DELETE,
                    onClick: () => {
                        const title = gridApi.selected.length === 1 ?
                            capitalize(lang.DELETE_MANAGEMENT_AGENT) :
                            capitalize(lang.DELETE_MANAGEMENT_AGENTS);

                        const data = gridApi.requestParamsForActions;

                        transportService.request('/Agent/GetAgentsToDelete', data)
                            .then(
                                (resp: RequestSuccessResponse<AgentsToDelete>) => {
                                    const confirmationCaseMessage = getConfirmationCaseMessage(resp && resp.data);

                                    notificationService
                                        .confirm(title, confirmationCaseMessage)
                                        .then((btnKey: NotificationResponse) => {
                                            if (btnKey === NotificationDialogsTextKeys.Yes) {
                                                doAsyncAction(
                                                    '/Agent/Delete',
                                                    title,
                                                    data
                                                );
                                            }
                                        });
                                }
                            );
                    },
                    decorators: [
                        removeDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                }, {
                    type: TOOLBAR_ITEM_TYPE.separator,
                    disabled: true,
                }, {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: downloadLogsIconSrc,
                    text: lang.DOWNLOAD_LOGS,
                    onClick: async() => {
                        const { selected } = gridApi;

                        await waitForExt();

                        Ext.create('RCOP.utils.DownloadAgentLogsManager', {
                            title: lang.DOWNLOAD_LOGS,
                            url: '/Agent/DownloadLogs',
                            params: gridApi.requestParamsForActions,
                            systemTypeData: {
                                onlyThese: selected.map(item => item.systemType),
                            },
                        }).start();
                    },
                    decorators: [
                        downloadLogDecorator(),
                        toolbarItemDecorators.disallowWithoutSelection(),
                    ],
                },
            ],
        },
        ...downloadAgentItems,
    ];

    return (
        <>
            <ToolbarWithExport
                selectedOnly
                lang={lang}
                method={'GetDiscoveredComputers'}
                sectionId={ConfigSectionIds.DiscoveryComputersComputers}
                groups={[
                    installAgentGroup,
                    downloadAgentGroup,
                ]}
                items={items}
            />

            {locationDialog}
            {companyDialog}
            {tagDialog}
            {asyncActionLoader}
            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
});
