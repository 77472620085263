/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout, TabBarBodyLayoutModes } from '@veeam-vspc/shared/components';

import { CloudConnect } from './components';
import { RowsContext } from './contexts';
import { rowsStore } from './stores';

export const TopsCloudConnectPage: React.FC<{}> = () => (
    <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
        <RowsContext.Provider value={rowsStore}>
            <CloudConnect />
        </RowsContext.Provider>
    </TabBarBodyLayout>
);
