/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ConfiguredToolbar,
    Link,
    Text,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    toolbarItemDecorators,
    useGlobalLang,
    useGlobalServices,
    useGridApi,
    useModal,
} from '@veeam-vspc/shared/components';
import {
    capitalize,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/shared/helpers';
import { EActionResultStatus } from '@veeam-vspc/models/web-controllers';
import { useNavigate } from 'react-router-dom';

import type {
    InvoiceConfigurationFilter,
    InvoiceConfigurationModel,
    InvoiceOptionParam,
    MultiActionResult,
    QuotaUsageOptionsModel,
} from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { useAsyncAction } from 'views/hooks';
import scheduleSrc from 'images/actions/link.png';
import manageInvoiceSrc from 'images/actions/manage-invoice.png';
import generateInvoiceSrc from 'images/actions/generate-invoice.png';
import generateQuotaInvoiceSrc from 'images/actions/generate-quota-usage.png';
import { InvoiceScheduling } from '../dialogs/InvoiceScheduling';
import { InvoiceParameters } from '../dialogs/InvoiceParameters';
import { QuotaUsage } from '../dialogs/QuotaUsage';
import { ConfigSectionIds } from 'core/enums';
import { insertComponent } from 'core/utils';

import type { ReportSchedule } from '../../interfaces';

export const InvoiceConfigurationActionToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const { notificationService } = useGlobalServices();
    const gridApi = useGridApi<InvoiceConfigurationModel, unknown, InvoiceConfigurationFilter>();
    const [invoiceSchedulingDialog, invoiceSchedulingDialogActions] = useModal<ReportSchedule>({
        render: ({ deactivate, data }) => (
            <InvoiceScheduling
                data={data}
                onApplySchedule={(data: ReportSchedule) =>
                    doAsyncAction('/Report/SetReportScheduling', lang.SCHEDULE, {
                        ids: gridApi.selected.map(({ id }) => id),
                        schedule: data.schedule,
                        generateAfterLicenseReport: data.generateAfterLicenseReport,
                    })
                        .finally(deactivate)}
                deactivate={() => {
                    deactivate();
                    gridApi?.reloadGrid();
                }}
            />
        ),
    });
    const [invoiceParametersDialog, invoiceParametersDialogActions] = useModal<number[]>({
        render: ({ deactivate, data }) => (
            <InvoiceParameters
                ids={data}
                onApplyParameters={(data: InvoiceOptionParam) =>
                    doAsyncAction('/Invoice/SetInvoiceOptions', lang.INVOICE_PARAMETERS, data).finally(deactivate)}
                deactivate={() => {
                    deactivate();
                    gridApi?.reloadGrid();
                }}
            />
        ),
    });
    const [quotaUsageDialog, quotaUsageDialogActions] = useModal({
        render: ({ deactivate }) => (
            <QuotaUsage
                onRun={(options: QuotaUsageOptionsModel) =>
                    doAsyncAction(
                        '/Invoice/CreateQuotaUsageImmediately',
                        lang.QUOTA_USAGE_REPORT,
                        { ids: gridApi.selected.map(({ id }) => id), options }
                    )
                        .finally(deactivate)}
                deactivate={() => {
                    deactivate();
                    gridApi?.reloadGrid();
                }}
            />
        ),
    });

    const generateInvoice = () => {
        const ids = gridApi.selected.map(({ id }) => id);

        doAsyncAction('/Invoice/CreateInvoiceImmediately', capitalize(lang.GENERATE_INVOICE), { ids })
            .then((resp: RequestSuccessResponse<MultiActionResult>) => {
                if (resp.data.status === EActionResultStatus.Success) {
                    notificationService.info(
                        capitalize(lang.GENERATE_INVOICE),
                        <Text>
                            {insertComponent(
                                lang.NAVIGATE_TO_ALL_INVOICES,
                                <Link onClick={() => {
                                    navigate('home/invoices/allInvoices');
                                    notificationService.hide();
                                }}
                                >
                                    {lang.ALL_INVOICES}
                                </Link>
                            )}
                        </Text>
                    );
                }
            });
    };

    const actions = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.SCHEDULE,
            iconSrc: scheduleSrc,
            onClick: () => invoiceSchedulingDialogActions.activate({
                schedule: gridApi.selected[0].schedule,
                generateAfterLicenseReport: gridApi.selected[0].generateAfterLicenseReport,
            }),
            decorators: [
                processDecoratorsForDisabledByPriority([
                    disableForSelectionWithPropValues('subscriptionPlanName', [
                        null,
                    ]),
                    toolbarItemDecorators.disallowWithoutSelection(),
                ]),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.INVOICE_PARAMETERS,
            iconSrc: manageInvoiceSrc,
            onClick: () => invoiceParametersDialogActions.activate(gridApi.selected.map(({ id }) => id)),
            decorators: [
                processDecoratorsForDisabledByPriority([
                    disableForSelectionWithPropValues('subscriptionPlanName', [
                        null,
                    ]),
                    toolbarItemDecorators.disallowWithoutSelection(),
                ]),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.separator,
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: capitalize(lang.GENERATE_INVOICE),
            iconSrc: generateInvoiceSrc,
            onClick: generateInvoice,
            decorators: [
                processDecoratorsForDisabledByPriority([
                    disableForSelectionWithPropValues('subscriptionPlanName', [
                        null,
                    ]),
                    toolbarItemDecorators.disallowWithoutSelection(),
                ]),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.GENERATE_QUOTA_USAGE_REPORT,
            iconSrc: generateQuotaInvoiceSrc,
            onClick: () => quotaUsageDialogActions.activate(),
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
    ];

    return (
        <>
            <ConfiguredToolbar
                sectionId={ConfigSectionIds.InvoicesConfiguration}
                view={TOOLBAR_VIEW_TYPE.default}
                items={actions}
            />

            {asyncActionLoader}
            {quotaUsageDialog}
            {invoiceSchedulingDialog}
            {invoiceParametersDialog}
        </>
    );
};
