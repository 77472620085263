/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { Text, View, VIEW_BG_VIEW, colors } from '@veeam-vspc/shared/components';
import { Dropdown, TEXT_WEIGHT } from '@veeam/components';
import styled, { createGlobalStyle } from 'styled-components';
import Tree from 'rc-tree';

import type { OrganizationsFlatMap } from '@veeam-vspc/shared/addons';
import type { DataNode } from 'rc-tree/lib/interface';
import type { CompanyTree } from '@veeam-vspc/models/web-controllers';

import { DropdownControl } from '../DropdownControl/DropdownControl';
import { filterTreeByQuery } from './utils/filter-tree-by-query';
import { GlobalFiltersDefaults } from '../../enums';

interface FilterTreeProps {
    data: DataNode[];
    dataMap: OrganizationsFlatMap;
    selected: number;
    rootNode: number;
    disabled?: boolean;
    onSelectionChange: (data: CompanyTree) => void;
}

const TreeStyles = createGlobalStyle`
    .organizations-filter {
        .rc-tree {
            .rc-tree-list {
                .rc-tree-list-holder {
                    overflow-y: auto!important;
                    overflow-x: hidden;
                }
            }

            .rc-tree-treenode {
                line-height: 32px;
                height: 32px;
                display: flex;
                padding-left: 5px;

                .rc-tree-node-content-wrapper {
                    flex-grow: 1;
                    display: flex !important;
                    overflow: hidden;
                    padding-left: 2px !important;

                    .rc-tree-iconEle {
                        position: relative;
                        top: 7px;
                        flex-shrink: 0;
                    }

                    .rc-tree-title {
                        flex-grow: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-left: 4px;
                    }
                }

                .rc-tree-switcher {
                    background-image: unset;
                    display: flex;
                    top: 9px;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                }

                .rc-tree-switcher_open {
                    transform: rotate(90deg);
                }

                .rc-tree-switcher-noop {
                    visibility: hidden;
                }

            }

            .rc-tree-treenode-selected {
                background-color: ${colors.B100};

                .rc-tree-node-selected {
                    all: unset;
                }
            }

            .rc-tree-list-scrollbar {
                display: none!important;
            }
        }
    }
`;

const dropdownSizing = {
    width: '350px',
    maxHeight: '400px',
};

const TreeContainer = styled.div`
    width: ${dropdownSizing.width};
    overflow: hidden;

    & .rc-tree {
        border-color: ${({ theme }) => theme.colorAccent};
    }

    & .rc-tree .rc-tree-list .rc-tree-treenode:hover {
        background-color: ${({ theme }) => theme.colorHighlightLight};
    }

    & .rc-tree .rc-tree-list .rc-tree-treenode-selected {
        background-color: ${({ theme }) => theme.colorHighlightDark};
    }
`;

export const OrganizationsFilter: React.FC<FilterTreeProps> = ({
    data,
    dataMap,
    selected,
    rootNode,
    disabled,
    onSelectionChange,
}) => {
    const [query, setQuery] = useState<string>(getSelectedItemName());
    const [filteredData, setFilteredData] = useState<DataNode[]>(data);
    const [expandedKeys, setExpandedKeys] = useState<number[]>([rootNode]);
    const [keysToExpand, setKeysToExpand] = useState<number[]>([]);

    useEffect(() => {
        const keys = [];
        dataMap.forEach((value) => {
            if (value.hasChildren) {
                keys.push(value.id);
            }
        });

        setKeysToExpand(keys);
    }, []);

    useEffect(() => {
        setQuery(getSelectedItemName());
    }, [selected]);

    useEffect(() => {
        setFilteredData(filterTreeByQuery(data, query));
    }, [data]);

    const handleSelect = (selectedNodes: DataNode[]) => {
        const node = dataMap.get(selectedNodes[0]?.key as number ?? GlobalFiltersDefaults.OrganizationDefaultId);

        setQuery(node.name);
        onSelectionChange(node);
    };

    const isDirty = () => query !== getSelectedItemName();

    function getSelectedItemName(): string {
        if (selected === rootNode) {
            return dataMap.get(rootNode).name;
        }

        const node = dataMap.get(selected);

        return node.name;
    }

    const handleQueryChange = (value: string, activateFn: () => void) => {
        const v = value.trimStart();
        setFilteredData(filterTreeByQuery(data, v));
        setExpandedKeys(keysToExpand);
        setQuery(v);
        activateFn();
    };

    const onExpand = (expandedKeys: number[]) => {
        setExpandedKeys(expandedKeys);
    };

    const nodeRenderer = (node: DataNode) => {
        const value = node.title as string;

        if (isDirty() && query && query.toLowerCase() !== value.toLowerCase()) {
            const regex = new RegExp(`(${query
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                .toLowerCase()})`, 'gi');

            const parts = value.split(regex);

            return parts.map((part, i) => (
                <Text
                    key={i}
                    weight={part.toLowerCase() === query.toLowerCase() ? TEXT_WEIGHT.bold : TEXT_WEIGHT.normal}
                    style={{ display: 'inline' }}
                >
                    {part}
                </Text>
            ));
        }

        return value;
    };

    const handleHide = () => {
        setQuery(getSelectedItemName());
        setExpandedKeys([rootNode]);
    };

    const hasSelection: boolean = Number.isFinite(selected) && selected >= 0;

    return (
        <div>
            <TreeStyles />

            <Dropdown
                onDidHide={handleHide}
                renderControl={({ button, actions, isActive }) => (
                    <DropdownControl
                        disabled={disabled}
                        button={button}
                        actions={actions}
                        query={query}
                        onQueryChange={handleQueryChange}
                        active={isActive}
                        selected={hasSelection}
                    />
                )}
                renderPopover={({ popover, content }) => (
                    <View
                        {...popover}
                        backgroundView={VIEW_BG_VIEW.normal}
                        size={dropdownSizing}
                    >
                        {content}
                    </View>
                )}
                renderContent={({ actions }) => (
                    <TreeContainer className='organizations-filter'>
                        <Tree
                            key={query} // tree should re-render on treeData updated, so we control it outside
                            expandedKeys={expandedKeys}
                            treeData={isDirty() ? filteredData : data}
                            onSelect={(keys, { selectedNodes }) => {
                                handleSelect(selectedNodes);
                                actions.hide();
                            }}
                            onExpand={onExpand}
                            selectedKeys={[selected]}
                            titleRender={nodeRenderer}
                            virtual={true}
                            height={400}
                            itemHeight={32}
                            defaultExpandAll={isDirty()}
                            showLine={false}
                        />
                    </TreeContainer>
                )}
            />
        </div>
    );
};

