/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    FileInput,
    Form,
    INDENT,
    SelectButton,
    STACK_GAP,
    StackView,
    useExternalFormApi,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type {
    WizardStep,
    WizardStepData,
    ExternalFormApi,
} from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import hideHelpIcon from 'images/hide.png';
import showHelpIcon from 'images/view_help.png';
import downloadIcon from 'images/actions/download-logs.svg';
import { Help } from './components';
import { VbrDeployType } from '../../enums';
import { stepValidate } from './stepValidate';
import { formValidate } from './formValidate';

import type { VbrDeploySettingsWizardModel } from '../../entries';

const InstallationParameters = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { fileTransportService } = useGlobalServices();
    const { data, onDataChange, validationState, stepData } = wizardStepData;
    const addButton = useRef<HTMLButtonElement>();
    const [isHelpVisible, showHelp] = useState(false);
    const formApi = useExternalFormApi<VbrDeploySettingsWizardModel>();

    useEffect(() => {
        stepData.formApi = formApi as ExternalFormApi<VbrDeploySettingsWizardModel>;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    return (
        <Form
            value={data}
            validate={formValidate(lang)}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
        >
            <StepLayout
                title={lang.SETUP_OPTIONS}
                description={`${lang.PROVIDE_FILE_WITH_INSTALLATION_PARAMETERS}.`}
            >
                <FileInput
                    name='installationParametersFile'
                    label={lang.FILE}
                    accept='.xml'
                    inlineLabel
                />

                <StackView
                    spaceBottom={INDENT.m}
                    spaceTop={INDENT.m}
                    gap={STACK_GAP.m}
                >
                    <SelectButton
                        onClick={() => {
                            const url = data.vbrDeployType === VbrDeployType.Install ? '/BackupServer/DownloadAnswerFile' :
                                '/BackupServer/DownloadAnswerFile?isUpgrade=true';

                            fileTransportService.downloadFile(url)
                                .then(() => addButton.current.blur());
                        }}
                        iconAfter={null}
                        iconBefore={downloadIcon}
                        ref={addButton}
                    >
                        {lang.CONFIGURATION_FILE_SAMPLE}
                    </SelectButton>

                    <SelectButton
                        onClick={() => {
                            showHelp(isHelpVisible => !isHelpVisible);

                            addButton.current.blur();
                        }}
                        iconAfter={null}
                        iconBefore={isHelpVisible ? hideHelpIcon : showHelpIcon}
                        ref={addButton}
                    >
                        {isHelpVisible ? lang.HIDE_HELP : lang.VIEW_HELP}
                    </SelectButton>
                </StackView>

                <Help
                    install={data.vbrDeployType === VbrDeployType.Install}
                    isHelpVisible={isHelpVisible}
                />
            </StepLayout>
        </Form>
    );
};

export const getInstallationParametersStep = (
    lang: VspcLang,
    notificationService: NotificationService,
): WizardStep<VbrDeploySettingsWizardModel> => ({
    title: lang.SETUP_OPTIONS,
    validate: ({ data, stepData: { formApi } }) => stepValidate(lang, data, notificationService, formApi),
    render: data => <InstallationParameters {...data} />,
});
