/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    FormLayout,
    useGlobalLang,
    STACK_DIRECTION,
    FieldLayout,
    CONTROL_SIZE,
    CheckboxKit,
} from '@veeam-vspc/shared/components';
import { DaysOfWeekNullable } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

interface DaysModalProps {
    deactivate: () => void;
    onConfirm: (data: Array<DaysOfWeekNullable>) => void;
    data: Array<DaysOfWeekNullable>;
}

export const DaysModal: React.FC<DaysModalProps> = observer(({
    data = [],
    onConfirm,
    deactivate,
}) => {
    const lang = useGlobalLang<VspcLang>();

    const [values, setValues] = useState({
        [DaysOfWeekNullable.Monday]: data.includes(DaysOfWeekNullable.Monday),
        [DaysOfWeekNullable.Tuesday]: data.includes(DaysOfWeekNullable.Tuesday),
        [DaysOfWeekNullable.Wednesday]: data.includes(DaysOfWeekNullable.Wednesday),
        [DaysOfWeekNullable.Thursday]: data.includes(DaysOfWeekNullable.Thursday),
        [DaysOfWeekNullable.Friday]: data.includes(DaysOfWeekNullable.Friday),
        [DaysOfWeekNullable.Saturday]: data.includes(DaysOfWeekNullable.Saturday),
        [DaysOfWeekNullable.Sunday]: data.includes(DaysOfWeekNullable.Sunday),
    });

    return (
        <Dialog
            header={lang.SELECT_DAYS}
            actions={[
                {
                    disabled: Object.values(values).every(value => !value),
                    text: lang.OK,
                    onClick: () => onConfirm(
                        Array.from(Object.entries(values))
                            .filter(entry => entry[1] === true)
                            .map(entry => entry[0] as DaysOfWeekNullable)
                    ),
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <FormLayout direction={STACK_DIRECTION.row}>
                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Monday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Monday]: value })}
                    >
                        {lang.MONDAY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Wednesday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Wednesday]: value })}
                    >
                        {lang.WEDNESDAY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Friday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Friday]: value })}
                    >
                        {lang.FRIDAY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Sunday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Sunday]: value })}
                    >
                        {lang.SUNDAY}
                    </CheckboxKit>
                </FieldLayout>
            </FormLayout>

            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Tuesday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Tuesday]: value })}
                    >
                        {lang.TUESDAY}
                    </CheckboxKit>
                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Thursday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Thursday]: value })}
                    >
                        {lang.THURSDAY}
                    </CheckboxKit>

                </FieldLayout>

                <FieldLayout inlineLabel size={CONTROL_SIZE.xs}>
                    <CheckboxKit
                        checked={values[DaysOfWeekNullable.Saturday]}
                        onChange={value => setValues({ ...values, [DaysOfWeekNullable.Saturday]: value })}
                    >
                        {lang.SATURDAY}
                    </CheckboxKit>
                </FieldLayout>

            </FormLayout>
        </Dialog>
    );
});
