/**
 * Copyright © Veeam Software Group GmbH.
 */

export const buildFormData = (formData: FormData, data: Object | Date | Blob | string, parentKey?: string): void => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === null ? '' : (data as Blob | string);

        formData.append(parentKey, value);
    }
};

export const dataToFormData = (data: Object | Date | Blob | string): FormData => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
};
