/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

export const ROLES = {
    [UserRoleRepresentation.CompanyOwner]: 'Company Owner',
    [UserRoleRepresentation.CompanyAdministrator]: 'Company Administrator',
    [UserRoleRepresentation.CompanyLocationAdministrator]: 'Location Administrator',
    [UserRoleRepresentation.CompanyLocationUser]: 'Location User',
    [UserRoleRepresentation.Finance]: 'Company Invoice Auditor',
    [UserRoleRepresentation.Subtenant]: 'Subtenant',
    [UserRoleRepresentation.ResellerOwner]: 'Service Provider Global Administrator',
    [UserRoleRepresentation.ResellerAdministrator]: 'Service Provider Administrator',
    [UserRoleRepresentation.ResellerOperator]: 'Service Provider Operator',
    [UserRoleRepresentation.ResellerUser]: 'Service Provider User',
    [UserRoleRepresentation.ResellerFinance]: 'Service Provider Invoice Auditor',
    [UserRoleRepresentation.VacAdministrator]: 'Portal Administrator',
    [UserRoleRepresentation.VacOperator]: 'Portal Operator',
    [UserRoleRepresentation.VacReadonlyOperator]: 'Read-only User',
    [UserRoleRepresentation.CloudConnectAdministrator]: 'Site Administrator',
};

export * from './company-data-defaults';
export * from './common';
export * from './reg-exp';
