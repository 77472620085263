/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ReportGenerationStateRepresentation } from '@veeam-vspc/models/web-controllers';
import { useGlobalAddons, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { StatusColumn } from 'views/components/columns/StatusColumn';
import { StatusTypes } from 'core/utils/status-helpers/enums';

interface ConfigurationStatusColumnProps {
    status: ReportGenerationStateRepresentation;
    message: string;
}

function getConfigurationStatus(status: ReportGenerationStateRepresentation) {
    switch (status) {
        case ReportGenerationStateRepresentation.Processed:
            return StatusTypes.Pending;
        case ReportGenerationStateRepresentation.Successed:
            return StatusTypes.Success;
        case ReportGenerationStateRepresentation.Unknown:
            return '';
        default:
            return status;
    }
}

export const ConfigurationStatusColumn: React.FC<ConfigurationStatusColumnProps> = ({ status, message }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();

    return (
        <StatusColumn
            status={getConfigurationStatus(status)}
            isLink
            isTextHidden
            content={portalEnums.getEnumDescription('ReportGenerationStateRepresentation', status)}
            messageBoxTitle={lang.STATE}
            message={message}
        />
    );
};
