/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ManagedAgentType } from '@veeam-vspc/models/web-controllers';

import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';

import { transformBaseChartData } from 'views/pages/TrendsPage/helpers';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { TrendData } from 'views/pages/TrendsPage/interfaces';

const names = [
    { label: 'Workstations', type: ManagedAgentType.Workstation },
    { label: 'Servers', type: ManagedAgentType.Server },
];

export class AgentBackupsService {

    constructor(
        private transportService: TransportService<RequestErrorResponse | Error>,
        private langService: VspcLang,
    ) {}

    fetchData({ reportingPeriodInfo }): Promise<ChartDataWithCategories> {
        return this.transportService
            .request('/Trends/CloudAgentBackups', { ...reportingPeriodInfo })
            .then((resp: RequestSuccessResponse<TrendData>) =>
                transformBaseChartData(this.langService, resp.data.trends, reportingPeriodInfo, names));
    }
}
