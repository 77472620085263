/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const getMailServicesTypes = (lang: VspcLang) => [
    { id: OAuth2ClientSettingsKind.Google, name: lang.GOOGLE },
    { id: OAuth2ClientSettingsKind.Azure, name: lang.MICROSOFT },
];
