/**
 * Copyright © Veeam Software Group GmbH.
 */

export const getMaxSumOfSeries = (series: any[]): number => {
    const values: { data: number[]; }[] = series;
    const sumArray: number[] = [];

    const maxLength = Math.max(...values.map(obj => obj.data.length));

    for (let i = 0; i < maxLength; i++) {
        let sum = 0;
        for (const obj of values) {
            if (obj.data[i] !== undefined) sum += obj.data[i];
        }
        sumArray.push(sum);
    }
    return Math.max(...sumArray);
};
