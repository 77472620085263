/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { PortalSpinner, useGlobalAppData, useGlobalAddons } from '@veeam-vspc/shared/components';

import type { AppDataStore } from '@veeam-vspc/shared/stores';
// import type { VspcLang } from 'configs/languages';

import { closeExtDialogsOnLogout, waitFor } from 'core/utils';
import { ExtContainer } from './ExtContainer';

export interface ExtApplicationModel {
    extApplicationInitializationState: string;
    runApp: (data: AppDataStore) => Promise<unknown>;
    launched: boolean;
}

interface ExtViewContainerModel {
    setActiveItem: (x: ExtComponentModel) => true | Error;
}

export interface ExtComponentProps {
    xtype?: string;
    extProps?: Record<string, any>;
    isCustomViewport?: boolean;
}

export const ExtComponent: React.FC<ExtComponentProps> = ({ xtype, extProps, isCustomViewport }) => {
    const appDataStore = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    // const lang = useGlobalLang<VspcLang>();
    // const { notificationService } = useGlobalServices();
    const extView = useRef<ExtComponentModel>();
    const mainExtView = useRef<any>();
    const [extLoaded, setExtLoaded] = useState(false);
    const [rerunCounter, setRerunCounter] = useState<number>(0);

    function initExtComponent() {
        if (extView.current) {
            try {
                extView.current.destroy();

                if (xtype) {
                    RCOP.MainViewContainer.remove(extView.current, true);
                } else if (isCustomViewport) {
                    RCOP.CustomViewContainer.remove(extView.current, true);
                }
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }

            extView.current = null;
        }

        if (xtype) {
            try {
                if (RCOP.Configurator.store?.getCount() === 0) {
                    RCOP.Configurator.setUser(appDataStore.portalUser);
                    RCOP.Configurator.initViewControls();
                }

                extView.current = Ext.create({
                    xtype,
                    extProps,
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }
        }

        if (xtype) {
            return waitFor<ExtViewContainerModel>(RCOP, 'MainViewContainer')
                .promise
                .then((container) => {
                    const isContainerSetActive: true | Error = container.setActiveItem(extView.current);

                    if (isContainerSetActive !== true) {
                        return Promise.reject(isContainerSetActive);
                    }
                })
                .then(() => {
                    setExtLoaded(true);

                    // hack to force Ext.LoadMask rerender in the right position
                    if (extView.current['loadMask']) {
                        try {
                            extView.current['loadMask'].disable();
                            extView.current['loadMask'].enable();
                            extView.current['loadMask'].show();
                        } catch (err) {
                            if (process.env.NODE_ENV === 'development') {
                                err && console.error('[DEV_MODE_MESSAGE]', err);
                            }
                        }
                    }
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === 'development') {
                        err && console.error('[DEV_MODE_MESSAGE]', err);
                    }

                    return Promise.reject();
                });
        } else if (isCustomViewport) {
            return waitFor<ExtViewContainerModel>(RCOP, 'CustomViewContainer')
                .promise
                .then(() => setExtLoaded(true));
        } else {
            throw Error('No xtype and customViewport, there should be at least one');
        }
    }

    const setupExtPortalData = (portalData: AppDataStore) => {
        // portalData is for React, extPortalData is for Ext.js
        // deepCopy from 'clone-deep' library doesn't work properly because of the classes in portalData properties
        const extPortalData = JSON.parse(JSON.stringify(portalData));

        // converting and mutating extPortalData.portalUser to enums with numbers
        portalEnums.replaceEnumsInData('IsLoggedUserJson', extPortalData.portalUser);

        // portalData === extPortalData except 'portalUser' property
        return extPortalData;
    };

    const updateExtLayout = () => {
        if (xtype && RCOP.Viewport) {
            try {
                RCOP.Viewport.setHeight(document.getElementById('ext-container').clientHeight);
                RCOP.Viewport.setWidth(document.getElementById('ext-container').clientWidth);
                RCOP.Viewport.updateLayout();
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }
        }

        if (isCustomViewport && RCOP.CustomViewContainer) {
            try {
                RCOP.CustomViewContainer.setHeight(document.getElementById('ext-container').clientHeight);
                RCOP.CustomViewContainer.setWidth(document.getElementById('ext-container').clientWidth);
                RCOP.CustomViewContainer.updateLayout();
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }
        }
    };

    const cleanUpOnUnmount = () => {
        if (extView.current) {
            try {
                extView.current.getPlugin('autoUpdate')?.stop();
                extView.current.destroy();
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }
            extView.current = null;
        }

        if ((window as any).ExtApplication) {
            (window as any).ExtApplication.extApplicationInitializationState = 'idle';
        }

        if (mainExtView.current) {
            try {
                mainExtView.current.destroy();
            } catch (err) {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }
            }
            mainExtView.current = null;
        }

        if (extLoaded) {
            setExtLoaded(false);
        }
    };

    const forceCleanOnExtError = () => {
        cleanUpOnUnmount();
        try {
            (window as any).Ext.destroy();
            Object.values((window as any).Ext.Element.cache).forEach((el) => {
                if ((el as any).id.includes('ext-quicktips')) {
                    (el as any).destroy();
                }
            });
            (window as any).Ext.Element.cache = {};
            (window as any).Ext.Boot.scripts = {};
            (window as any).Ext.cache = {};
            (window as any).Ext.ComponentManager.destroy();
            (window as any).Ext.dom.GarbageCollector.collect();
            (window as any).Ext.manifest = (window as any).RCOP_MANIFEST_THEME;
            (window as any).Ext.Microloader.run();
            (window as any).ExtApplication = null;
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                err && console.error('[DEV_MODE_MESSAGE]', err);
            }
        }
    };

    useEffect(() => {
        let cancelExtContainer;
        let cancelExtApp;
        let extContainerRerunTimerId = null;
        const cancelRunAppObj = { cancelled: false };

        const initExt = async() => {
            const { promise: extContainerPromise, cancel: extContainerCancel } = waitFor(window, 'ext-container');

            cancelExtContainer = extContainerCancel;
            await extContainerPromise;

            const { promise, cancel } = waitFor<ExtApplicationModel>(window, 'ExtApplication');
            cancelExtApp = cancel;

            promise
                .then((app) => {
                    if (app.extApplicationInitializationState === 'idle') {
                        const extPortalData = setupExtPortalData(appDataStore);

                        return app.runApp.apply(app, [extPortalData, cancelRunAppObj])
                            .then((mainViewport) => {
                                if (cancelRunAppObj.cancelled) {
                                    return Promise.reject({ ...cancelRunAppObj, mainViewport });
                                }

                                mainExtView.current = mainViewport;
                            })
                            .catch((err) => {
                                if (err.cancelled) {
                                    try {
                                        err.mainViewport?.destroy();
                                    } catch (err) {
                                        if (process.env.NODE_ENV === 'development') {
                                            err && console.error('[DEV_MODE_MESSAGE]', err);
                                        }
                                    }
                                } else {
                                    return Promise.reject(err);
                                }
                            });
                    }
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === 'development') {
                        err && console.error('[DEV_MODE_MESSAGE]', err);
                    }

                    forceCleanOnExtError();
                    extContainerRerunTimerId = window.setTimeout(() => {
                        if (rerunCounter < 3) {
                            setRerunCounter(rerunCounter + 1);
                        }
                    }, 800);
                });
        };

        initExt();

        return () => {
            if (extContainerRerunTimerId !== null) {
                clearTimeout(extContainerRerunTimerId);
                extContainerRerunTimerId = null;
            }

            if (extView.current?.['loadMask']) {
                extView.current['loadMask'].hide();
            }

            cancelExtContainer?.();
            cancelExtApp?.();
            cancelRunAppObj.cancelled = true;
            cleanUpOnUnmount();
            closeExtDialogsOnLogout();
        };
    }, [rerunCounter]);

    useEffect(() => {
        let extContainerRerunTimerId = null;
        const { promise, cancel } = waitFor<ExtApplicationModel>(window, 'ExtApplication', app => app.extApplicationInitializationState === 'done');
        const runInitExtComponent = () => promise
            .then(() => initExtComponent())
            .catch((err) => {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error('[DEV_MODE_MESSAGE]', err);
                }

                forceCleanOnExtError();
                extContainerRerunTimerId = window.setTimeout(() => {
                    if (rerunCounter < 3) {
                        setRerunCounter(rerunCounter + 1);
                    }
                }, 800);
            });

        runInitExtComponent();

        return () => {
            if (extContainerRerunTimerId !== null) {
                clearTimeout(extContainerRerunTimerId);
                extContainerRerunTimerId = null;
            }

            cancel();
        };
    }, [rerunCounter]);

    useEffect(() => {
        window.addEventListener('resize', updateExtLayout, { passive: true });

        return () => window.removeEventListener('resize', updateExtLayout);
    }, []);

    useEffect(() => updateExtLayout(), [extLoaded]);

    useEffect(() => {
        let checkIntervalId: number | null = null;

        if (extLoaded) {
            checkIntervalId = window.setInterval(() => {
                const mainViewportEl = document.getElementById('mainViewport');
                const adjustViewportEl = document.getElementById('adjustViewport');
                const terminalViewportEl = document.getElementById('terminalViewport');

                if (
                    extLoaded
                    && (
                        (!mainViewportEl && !adjustViewportEl && !terminalViewportEl)
                        || (mainViewportEl && mainViewportEl.clientHeight < 10)
                        || (adjustViewportEl && adjustViewportEl.clientHeight < 10)
                        || (terminalViewportEl && terminalViewportEl.clientHeight < 10)
                    )
                ) {
                    clearInterval(checkIntervalId);
                    // notificationService.error(lang.ERROR, (lang as VspcLang).AN_ERROR_OCCURRED, { canClose: false })
                    //     .then(() => window.location.reload())
                    //     .catch(() => {
                    //         // for double error handling
                    //     });

                    forceCleanOnExtError();
                    if (rerunCounter < 3) {
                        setRerunCounter(rerunCounter + 1);
                    }
                }
            }, 2000);
        }

        return () => {
            if (checkIntervalId) {
                clearInterval(checkIntervalId);
            }
        };
    }, [extLoaded, rerunCounter]);

    return (
        <>
            {!extLoaded && <PortalSpinner whiteBg delayTime={300} />}
            <ExtContainer hide={!extLoaded} />
        </>
    );
};
