/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';
import { useGlobalServices } from '@veeam-vspc/shared/components';

import type { TransportRequestOptions } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import type { UseRequestsData } from './interfaces';

// Cache for problem with multiple request when use that hook in dialogs, because ReactTransition calls a few re-renders when do animations on show
const requestCache: Record<string, Promise<any>> = {};

export const useTransportRequest = <T, R extends {}>(
    url: string,
    requestParams?: T,
    options?: Partial<TransportRequestOptions> & { responsePropName?: string; },
): UseRequestsData<R> => {
    const { transportService } = useGlobalServices();
    const [isForcedRequest, setIsForcedRequest] = useState(false);
    const [requestData, setRequestData] = useState<Omit<UseRequestsData<R>, 'forceRequest'>>({
        loading: true,
        isError: false,
        data: null,
        error: null,
    });
    const { loading, data } = requestData;

    useEffect(() => {
        if ((loading && !data) || isForcedRequest) {
            const clearRequestFromCache = () => {
                delete requestCache[requestId];
            };

            if (isForcedRequest) {
                setIsForcedRequest(false);
                setRequestData({
                    error: null,
                    loading: true,
                    isError: false,
                    data,
                });
                clearRequestFromCache();
            }

            let requestPromise;
            const { responsePropName = 'data', ...restOptions } = options ?? {};
            const requestParamsStr = JSON.stringify(requestParams);
            const requestId = `${url}$$$${requestParamsStr}`;

            if (requestCache[requestId]) {
                requestPromise = requestCache[requestId];
            } else {
                requestPromise = transportService.request<T, RequestSuccessResponse<R>>(url, requestParams, restOptions);
                requestCache[requestId] = requestPromise;
            }

            requestPromise
                .then((resp: RequestSuccessResponse<R>) => {
                    clearRequestFromCache();
                    setRequestData({
                        error: null,
                        data: resp[responsePropName] as R,
                        loading: false,
                        isError: false,
                    });
                })
                .catch((errResp: RequestErrorResponse) => {
                    clearRequestFromCache();

                    setRequestData({
                        error: errResp,
                        data: null,
                        loading: false,
                        isError: !(errResp instanceof DOMException && errResp.code === DOMException.ABORT_ERR),
                    });
                });
        }
    }, [transportService, loading, data, isForcedRequest, url, requestParams, options]);

    return {
        ...requestData,
        forceRequest: () => setIsForcedRequest(true),
    };
};
