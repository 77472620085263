/**
 * Copyright © Veeam Software Group GmbH.
 */

import { createGlobalStyle } from 'styled-components';

export const HighchartsStyles = createGlobalStyle`
  .highcharts-container {
      .highcharts-legend-item{
          &.highcharts-legend-item-hidden {
              text {
                  cursor: pointer !important;
              }
          }
      }
  }
`;
