/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { BackupServerJobCredentialType } from '@veeam-vspc/models/web-controllers';

import type { BackupServerCredentialsType } from '@veeam-vspc/models/rest';
import type { BackupServerJobCredential } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import { CredentialsPanelToolbar } from './components/CredentialsPanelToolbar/CredentialsPanelToolbar';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';

interface CredentialsPanelProps {
    hidePanel: () => void;
}

export const CredentialsPanel: FC<CredentialsPanelProps > = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<BackupServerJobCredential, unknown, unknown>>();
    const { transportService } = useGlobalServices();

    const handleApply = () => {
        wizardStore.updateJobData({
            ...wizardStore.data,
            guestProcessing: {
                ...wizardStore.data.guestProcessing,
                guestCredentials: {
                    ...wizardStore.data.guestProcessing.guestCredentials,
                    credentialsId: api.current?.selected[0]?.instanceUid,
                    credentialsType: api.current?.selected[0]?.type as unknown as BackupServerCredentialsType,
                },
            },
        });

        hidePanel();
    };

    const handleCancel = () => {
        hidePanel();
    };

    return (
        <SidePanelForm
            title={lang.GUEST_OS_CREDENTIALS}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                stateId='backupJobs.VMs.virtualInfrastructure.jobWizard.guestProcessing.Credentials'
                api={api}
                columns={[
                    {
                        id: 'username',
                        title: lang.USERNAME,
                        cell: rowData => (
                            rowData.username
                        ),
                    },
                    {
                        id: 'type',
                        title: lang.GUEST_OS,
                        cell: rowData => rowData.type === BackupServerJobCredentialType.Standard
                            ? lang.WINDOWS
                            : lang.LINUX,
                    },
                    {
                        id: 'creationTime',
                        title: capitalize(lang.CREATION_DATE),
                        cell: rowData => (
                            <GridDateColumn
                                value={rowData.creationTime}
                            />
                        ),
                    },
                    {
                        id: 'description',
                        title: lang.DESCRIPTION,
                        cell: rowData => (
                            rowData.description
                        ),
                    },
                ]}
                data={requestParams => transportService.request<any, RequestSuccessResponse<BackupServerJobCredential[]>>(
                    '/backupserverjob/getbackupservercredentials',
                    {
                        ...requestParams,
                        backupServerUid: wizardStore.serverUid,
                        mappedOrganizationUid: wizardStore.selectedCompany.companyUid,
                        jobUid: wizardStore.data.instanceUid,
                    },
                )}
                disableAutoUpdate
                initialState={{
                    selected: wizardStore.data.guestProcessing.guestCredentials
                        ? [{ instanceUid: wizardStore.data.guestProcessing.guestCredentials.credentialsId } as BackupServerJobCredential]
                        : undefined,
                }}
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
                toolbars={[
                    () => <CredentialsPanelToolbar />,
                ]}
            />
        </SidePanelForm>
    );
});
