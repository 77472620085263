/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { PortalFiltersIds } from '@veeam-vspc/shared/addons';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { ConfigActionIds, ConfigAdvancedFilterItemsIds, ConfigItemIds, ConfigSectionIds } from 'core/enums';
import { RouteIds, RoutePaths } from '../../enums';
import { allConfigsRoutes, allCustomRoutes, allHomeRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

export const resellerOperatorConfig: UserConfigData<typeof UserRoleRepresentation.ResellerOperator, ConfigSectionIds | PortalFiltersIds> = {
    role: UserRoleRepresentation.ResellerOperator,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.Overview],
                        allHomeRoutes[RouteIds.JobsSummary],
                        allHomeRoutes[RouteIds.ActiveAlarms],
                        allHomeRoutes[RouteIds.ProtectedDataSummary],
                        allHomeRoutes[RouteIds.BackupJobs],
                        allHomeRoutes[RouteIds.FailoverPlans],
                        allHomeRoutes[RouteIds.ProtectedData],
                        allHomeRoutes[RouteIds.Companies],
                        allHomeRoutes[RouteIds.Rules],
                        allHomeRoutes[RouteIds.ManagedComputers],
                        allHomeRoutes[RouteIds.BillingSummary],
                        allHomeRoutes[RouteIds.Invoices],
                        allHomeRoutes[RouteIds.Reports],
                    ],
                },
                {
                    path: RoutePaths.Configs,
                    component: AppPortalBodyLayout,
                    items: [
                        allConfigsRoutes[RouteIds.RolesUsersReseller],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.BackupServerTerminal],
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            actions: {
                separatorBeforeDownloadRemoteConsole: ConfigStates.Hidden,
                downloadRemoteConsole: ConfigStates.Hidden,
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsDataBackupObjectStorage,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ReportsConfigurationsWizard,
            columns: {
                managedBy: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesConfiguration,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesAllInvoices,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
            columns: {
                companyName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarmsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.AlarmProductVspc]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmProductCloudConnect]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmScope]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersComputers,
            actions: {
                [ConfigActionIds.ActionToolbarAutoDeploy]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarChangeSettings]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarAcceptConnection]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarRejectConnection]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarAcceptConnectionSeparator]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersRules,
            columns: {
                company: ConfigStates.Hidden,
            },
        },
        {
            id: PortalFiltersIds.PortalFiltersSection,
            items: {
                [PortalFiltersIds.SitesPortalFilter]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.Companies,
            actions: {
                [ConfigActionIds.New]: ConfigStates.Hidden,
                [ConfigActionIds.Remove]: ConfigStates.Hidden,
            },
            items: {
                [ConfigItemIds.CompaniesWizardVBRServersActionToolbar]: ConfigStates.Hidden,
                [ConfigItemIds.CompaniesWizardVBRRepositoriesActionToolbar]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ReportsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.ReportsManagingCompany]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructureAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructureBackupServerType]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructurePlatformType]: ConfigStates.Hidden,
            },
        },
    ],
};
