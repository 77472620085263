/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { Vb365JobItemGroupGroupType } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const groupTypeTitles = (type: Vb365JobItemGroupGroupType, lang: VspcLang) => ({
    [Vb365JobItemGroupGroupType.Distribution]: lang.DISTRIBUTION_GROUP,
    [Vb365JobItemGroupGroupType.DynamicDistribution]: lang.DYNAMIC_DISTRIBUTION_GROUP,
    [Vb365JobItemGroupGroupType.Office365]: lang.MICROSOFT_365_GROUP,
    [Vb365JobItemGroupGroupType.Security]: lang.SECURITY_GROUP,
    [Vb365JobItemGroupGroupType.Unknown]: ColumnTexts.DashSymbol,
}[type]);
