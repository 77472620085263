/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { PortalUser } from '@veeam-vspc/shared/stores';

import type { Vb365OrganizationBaseFix } from 'core/interfaces';

export const isScheduleHidden = (portalUser: PortalUser, selectedOrganization: Vb365OrganizationBaseFix) => {
    if (!selectedOrganization || !selectedOrganization.isJobSchedulingEnabled) {
        return true;
    }

    if (portalUser.isResellerPortal() || portalUser.isPortalAdministrator()) {
        return false;
    }
};
