/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ProtectedFileRetentionType {
    Default = -1,
    Backup = 0,
    Archive = 1,
    Snapshot = 2,
}
