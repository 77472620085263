/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredAdvancedFilter,
    useGlobalLang,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { JobTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { ConfiguredAdvancedFilterOptions, AdvancedFilterValue, ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import dataBackupIcon from 'images/filters/job-types/object-storage-data-backup.svg';
import dataBackupCopyIcon from 'images/filters/job-types/object-storage-data-backup-copy.svg';

interface DataBackupObjectStorageFilterToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method: string;
}

const BACKUP_TYPE_FILTER_NAME = 'types';

export const DataBackupObjectStorageFilterToolbar = (props: DataBackupObjectStorageFilterToolbarProps) => {
    const { method, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.BACKUP_TYPE,
                        items: [
                            {
                                label: lang.OBJECT_STORAGE_DATA_BACKUP,
                                iconSrc: dataBackupIcon,
                                value: {
                                    id: JobTypeRepresentation.ObjectStorageBackup,
                                    name: BACKUP_TYPE_FILTER_NAME,
                                },
                            },
                            {
                                label: lang.OBJECT_STORAGE_DATA_BACKUP_COPY,
                                iconSrc: dataBackupCopyIcon,
                                value: {
                                    id: JobTypeRepresentation.ObjectStorageBackupCopy,
                                    name: BACKUP_TYPE_FILTER_NAME,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <Search name='jobName' placeholder={lang.JOB} />,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <Search name='name' placeholder={lang.OBJECT_STORAGE} />,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <ConfiguredAdvancedFilter label={lang.FILTER} options={advancedFilterOptions} />,
                },
            ]}
        />
    );
};
