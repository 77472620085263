/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum HREFS {
    Swagger = 'api/swagger',
    OpenApiSpecification = 'api/.well-known/specification-{0}-PortalAdministrator-full-complexQueryParameters-OpenApi_3_0.json',
    RestApiUserGuide = 'https://redirect.veeam.com/helpvac{0}topicid=501',
    RestApiVeeamForums = 'https://forums.veeam.com/',
}
