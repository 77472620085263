/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Button,
    ComboboxKit,
    PortalSpinner,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_WEIGHT,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import type { BrandingSetPortalColorThemeParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { SanitizedInnerHtml } from 'views/components/SanitizedInnerHtml';
import { getColorScheme } from '../../../../helpers';
import { getReactProductTheme, waitFor } from 'core/utils';
import { FormNames } from '../../enums';

const CurrentColorScheme = styled.div<{ color: string; }>`
    background-color: ${({ color }) => color};
    width: 16px;
    height: 16px;
`;

interface ColorSchemeProps {
    onIsDirtyChange: (formIsDirty: Partial<Record<FormNames, boolean>>) => void;
}

export const ColorScheme: React.FC<ColorSchemeProps> = ({ onIsDirtyChange }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, notificationService } = useGlobalServices();
    const appGlobalData = useGlobalAppData();
    const [themeColor, setThemeColor] = useState<string>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        transportService.request<unknown, RequestSuccessResponse<string>>('/Branding/GetPortalColorTheme')
            .then(({ data }: RequestSuccessResponse<string>) => setThemeColor(data));
    }, []);

    useEffect(() => {
        onIsDirtyChange({ [FormNames.ColorScheme]: isDirty });
    }, [isDirty]);

    const onApply = () => {
        setIsDirty(false);
        setIsLoading(true);
        transportService.request<BrandingSetPortalColorThemeParam, unknown>('/Branding/SetPortalColorTheme', { themeColor })
            .then(() => appGlobalData.theme = getReactProductTheme(themeColor as any))
            .then(() => waitFor<{ setColorTheme: (theme: string, version: string) => void; }>(window, 'ExtApplication').promise)
            .then(app => app.setColorTheme(themeColor, appGlobalData.version))
            .catch(err => notificationService.multi(lang.ERROR, err))
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <Text weight={TEXT_WEIGHT.bold}>{lang.BRANDING_COLOR_SCHEME_TITLE}</Text>
                <SanitizedInnerHtml html={lang.BRANDING_COLOR_SCHEME_DESCRIPTION} isPreSanitized />

                <ComboboxKit
                    data={getColorScheme()}
                    value={themeColor}
                    valueGetter={value => value.color}
                    textGetter={value => value.name}
                    onChange={(value) => {
                        setIsDirty(true);
                        setThemeColor(value);
                    }}
                    iconGetter={({ option }) => <CurrentColorScheme color={option.colorfill} />}
                />

                <Button disabled={!isDirty} onClick={onApply} style={{ width: 'max-content' }}>{lang.APPLY}</Button>
            </StackView>

            {isLoading && <PortalSpinner />}
        </>

    );
};
