/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { transformData } from '../helpers';

import type { LicenseUsageMonthlyStatistic, UsageReportsData } from '../interfaces';

export class UsageReportsService {
    static fetchData(transportService: TransportService<RequestErrorResponse | Error>, { productType }): Promise<UsageReportsData> {
        return transportService
            .request('/License/GetLicenseUsageMonthlyStatistics', { productType })
            .then((resp: RequestSuccessResponse<LicenseUsageMonthlyStatistic>) => transformData(resp.data.reportsStatistics));
    }
}
