/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

interface ContainerProps {
    isLoading?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  align-items: ${props => props.isLoading ? 'center' : 'flex-start'};
  justify-content: center;

  height: calc(100% - 48px);
  margin: 0 auto;
  max-width: 462px;
`;
