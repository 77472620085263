/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, FieldLayout } from '@veeam-vspc/shared/components';

import type { LinkButtonProps } from '@veeam-vspc/shared/components';

export interface LinkFieldProps extends LinkButtonProps {
    label?: string;
}

export const LinkField = ({ label, children, ...props }: LinkFieldProps) => (
    <FieldLayout label={`${label}:`} disabled={props.disabled}>
        <LinkButton {...props}>{children}</LinkButton>
    </FieldLayout>
);
