/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';

import type { EJobStatusInteg } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getStatusIconSrc } from 'core/utils';

import type { EJobStatuses } from '../../../../../../enums';

export interface SuccessfulJobsColumnProps {
    onClick: () => void;
    successJobsCount: number;
    totalJobsCount: number;
    jobsStatus: EJobStatuses | EJobStatusInteg;
}

export const SuccessfulJobsColumn: React.FC<SuccessfulJobsColumnProps> = ({ onClick, successJobsCount = 0, totalJobsCount = 0, jobsStatus }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <TextColumn>
            <LinkButton
                onClick={onClick}
                iconBefore={getStatusIconSrc(jobsStatus)}
            >
                {`${successJobsCount} ${lang.OF} ${totalJobsCount}`}
            </LinkButton>
        </TextColumn>
    );
};
