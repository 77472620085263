/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import {
    Icon,
    LinkButton,
    SPACING_XL,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextColumn,
    useGlobalAppData,
    useGlobalLang,
    transformTimeToAgoString,
} from '@veeam-vspc/shared/components';
import { ERestoreStatus, UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { FlrStatusInfo, SystemTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getStatusIconSrc } from 'core/utils';
import { StatusTypes } from 'core/utils/status-helpers/enums';

import type { ComputersService } from '../../services';

const StyledStackView = styled(StackView)`
    min-height: ${SPACING_XL};
`;

export interface FlrStatusColumnProps {
    computersService: ComputersService;
    agentId: string;
    name: string;
    systemType: SystemTypeRepresentation;
    flrStatusInfo: FlrStatusInfo;
    tabUrl?: string;
}

export const FlrStatusColumn: React.FC<FlrStatusColumnProps> = (props) => {
    const {
        agentId,
        flrStatusInfo,
        tabUrl = '#/restore-items-audit',
        computersService,
    } = props;
    const { status, lastRestore, restoredCount, totalCount } = flrStatusInfo || {};
    const lang = useGlobalLang<VspcLang>();
    let isLink = false;
    let text: string | null = null;
    let iconSrc = '';
    const { portalUser, version } = useGlobalAppData();
    const disallowLinkFor = [
        UserRoleRepresentation.VacReadonlyOperator,
        UserRoleRepresentation.ResellerUser,
        UserRoleRepresentation.CompanyLocationUser,
    ];

    if (status === ERestoreStatus.Restoring) {
        const count = `${restoredCount} ${lang.OF} ${totalCount} ${lang.ITEMS.toLowerCase()}`;

        text = `${lang.RESTORING} (${count})`;
        iconSrc = getStatusIconSrc(StatusTypes.Pending);
    } else if (status === ERestoreStatus.Error) {
        text = `${lang.FAILED} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Error);
        isLink = true;
    } else if (status === ERestoreStatus.Success) {
        text = `${lang.SUCCESS} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Success);
    } else if (status === ERestoreStatus.Warning) {
        text = `${lang.WARNING} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Warning);
        isLink = true;
    }

    if (isLink && !disallowLinkFor.includes(portalUser.userRole)) {
        return (
            <TextColumn>
                <LinkButton
                    onClick={() => computersService.openFlr(lang, {
                        agentId,
                        tabUrl,
                        userId: portalUser.instanceUid,
                        vspcVersion: version,
                    })}
                    iconBefore={iconSrc}
                >
                    {text}
                </LinkButton>
            </TextColumn>
        );
    }

    return (
        <TextColumn>
            {
                text && (
                    <StyledStackView
                        align={STACK_ALIGN.center}
                        direction={STACK_DIRECTION.row}
                        gap={STACK_GAP.s}
                    >
                        {iconSrc && <Icon src={iconSrc} />}
                        <Text truncate style={{ color: 'inherit' }}>{text}</Text>
                    </StyledStackView>
                )
            }
        </TextColumn>
    );
};
