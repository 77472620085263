/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

export interface RunningJobsColumnProps {
    onClick: () => void;
    runningJobsCount: number;
    totalJobsCount: number;
}

export const RunningJobsColumn: React.FC<RunningJobsColumnProps> = ({ onClick, runningJobsCount = 0, totalJobsCount = 0 }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <TextColumn>
            <LinkButton
                onClick={onClick}
            >
                {`${runningJobsCount} ${lang.OF} ${totalJobsCount}`}
            </LinkButton>
        </TextColumn>
    );
};
