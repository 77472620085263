/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useCallback } from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { CompanyDownloadTraffic } from '@veeam-vspc/models/web-controllers';
import type { GridColumnProps } from '@veeam-vspc/shared/components';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings, usePeriodsStore } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';
import { GridName } from '../../../../stores';

import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const DownloadTraffic: React.FC<TopsGridComponentWrapper> = observer(({ maxNumberOfRows }) => {
    const { setPeriod, periodTopCompaniesByDataTransferOut } = usePeriodsStore();

    const settings = useCommonSettings<CompanyDownloadTraffic>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.DownloadTraffic,
        shouldRenderPeriodSelect: true,
        period: periodTopCompaniesByDataTransferOut,
    });
    const { lang } = settings;

    const columns: GridColumnProps<CompanyDownloadTraffic>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'cloudDownloadTraffic',
            title: lang.DATA_TRANSFER_OUT,
            cell: rowData => <SizeColumnRender value={rowData.cloudDownloadTraffic} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    const handleChangePeriod = useCallback((periodValue: number): void => {
        setPeriod(periodValue, GridName.TopCompaniesByDataTransferOut);
    }, [setPeriod]);

    return (
        <TopsGrid
            title={lang.TOP_COMPANIES_BY_DATA_TRANSFER_OUT}
            columns={columns}
            sectionId={ConfigSectionIds.TopsCompanies}
            maxNumberOfRows={maxNumberOfRows}
            handleChangePeriod={handleChangePeriod}
            {...settings}
        />
    );
});
