/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    transformTimeToAgoString,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/shared/helpers';

import type { ProtectedComputerManagedByConsole, ProtectedComputersManagedByConsoleFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { CustomNoteColumn } from 'views/components/columns/CustomNoteColumn';
import { JobKinds } from 'views/pages/BackupJobsPage/enums';
import { BackupJobsColumn } from '../BackupJobsColumn';
import { FlrStatusColumn } from '../FlrStatusColumn';
import { ComputersActionToolbar, ComputersFilterToolbar } from './components';
import { ConfigSectionIds } from 'core/enums';
import { ComputersService } from '../../services';


export interface ManagedByConsoleProps {
    stateId: string;
}

export const ManagedByConsole: React.FC<ManagedByConsoleProps> = ({ stateId }) => {
    const { formats } = useGlobalAppData();
    const { netShortDate, netShortTime } = formats;
    const { transportService, notificationService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.ManagedByConsole`;
    const computersService = new ComputersService(transportService, notificationService);

    const columns: GridColumnProps<ProtectedComputerManagedByConsole>[] = [
        {
            id: 'name',
            title: lang.NAME,
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'agentCustomNote',
            title: lang.TAG,
            cell: rowData => (
                <CustomNoteColumn
                    value={rowData.agentCustomNote}
                    agentUid={rowData.agentId}
                />
            ),
        },
        {
            id: 'totalJobsCount',
            title: lang.BACKUPS,
            cell: rowData => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupJobs`}
                    value={rowData.totalJobsCount}
                    title={`${lang.BACKUPS} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.BackupJob}
                    hideMalwareState
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'totalCopyJobsCount',
            title: lang.BACKUP_COPIES,
            cell: rowData => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupCopyJobs`}
                    value={rowData.totalCopyJobsCount}
                    title={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.CopyJob}
                    hideMalwareState
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'guestOs',
            title: lang.GUEST_OS,
            cell: rowData => rowData.guestOs,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: rowData => transformTimeToAgoString(rowData.lastRestorePoint, lang),
            defaultValue: lang.NO_INFO,
            width: 180,
        },
        {
            id: 'lastCloudRestorePoint',
            title: lang.CLOUD_COPY,
            cell: (rowData) => {
                if (!rowData.cloudCopy) {
                    return lang.NO;
                } else if (!rowData.lastCloudRestorePoint) {
                    return lang.YES;
                }

                return (
                    <TextColumn>
                        {`${lang.YES} (${transformTimeToAgoString(rowData.lastCloudRestorePoint, lang)})`}
                    </TextColumn>
                );
            },
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'lastHeartbeat',
            title: lang.LAST_HEARTBEAT,
            cell: rowData => transformTimeToAgoString(rowData.lastHeartbeat, lang),
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'flrStatusInfo.lastRestore',
            title: lang.LAST_RESTORE,
            cell: rowData => renderShortDateAndTimeForColumn(rowData.flrStatusInfo?.lastRestore, netShortDate, netShortTime),
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'flrStatusInfo.status',
            title: lang.RESTORE_STATUS,
            cell: rowData => (
                <FlrStatusColumn
                    agentId={rowData.agentId}
                    name={rowData.name}
                    systemType={rowData.systemType}
                    flrStatusInfo={rowData.flrStatusInfo}
                    computersService={computersService}
                />
            ),
            width: 150,
        },
        {
            id: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: rowData => rowData.operationModeRendered,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            stateId={gridStateId}
            defaultColumnMinWidth={80}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<ProtectedComputersManagedByConsoleFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<ProtectedComputerManagedByConsole[]>>('/ProtectedWorkloads/GetComputers', {
                    ...requestParams,
                })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ComputersFilterToolbar />,
                () => <ComputersActionToolbar idProperty={'instanceUid'} computersService={computersService} />,
            ]}
        />
    );
};
