/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { HighchartsStyles } from 'views/components/HighchartsWrapper';
import { Replication } from './components/CloudConnectResources/Replication';

export interface ResourcesAndBillingCloudConnectReplicaPageProps {
    currentPath: string;
}

export const ResourcesAndBillingCloudConnectReplicaPage: React.FC<ResourcesAndBillingCloudConnectReplicaPageProps> = () => (
    <TabBarBodyLayout>
        <HighchartsStyles />
        <Replication />
    </TabBarBodyLayout>
);
