/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatDate, normalizeOldDateFormat } from '@veeam-vspc/shared/helpers';

import type { FormatsJson } from '@veeam-vspc/models/web-controllers';

import { isDate } from '../is-date';

export function normalizeDateWithTime(value: Date, formats: FormatsJson): string | null {
    if (!isDate(value) || value <= new Date(0)) {
        return null;
    }

    return formatDate(value, `${normalizeOldDateFormat(formats.netShortDate)} ${normalizeOldDateFormat(formats.netShortTime)}`);
}
