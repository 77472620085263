/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { constants, STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/shared/components';

import { EmptyPlateStyled } from '../EmptyPlate/EmptyPlate.styled';
import { PlateDescriptionStyled, PlateTextStyled } from '../PlateText';
import { UsedValueStyled } from '../../../HighchartsWrapper/components/DonutInfo/UsedValue';
import { calcScale } from 'core/utils/size-helpers';

export const UnlimitedPlate = ({ lang, options }) => {
    let used = options.series[0].data[0][1];
    const scale = calcScale(used);

    used = parseFloat((used / Math.pow(1024, scale.exp)).toFixed(1));

    return (
        <EmptyPlateStyled>
            <StackView
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.m}
                style={{
                    height: '100%',
                    padding: `0 ${constants.SPACING_XL}`,
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                <PlateTextStyled>{lang.UNLIMITED_QUOTA}</PlateTextStyled>

                <PlateDescriptionStyled>
                    <div style={{ paddingBottom: constants.SPACING_S }}>{`${lang.USED}, ${scale.unit}`}</div>
                    <UsedValueStyled>{used}</UsedValueStyled>
                </PlateDescriptionStyled>
            </StackView>
        </EmptyPlateStyled>
    );
};
