/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useState } from 'react';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { TestEmailOptions } from '@veeam-vspc/models/rest';
import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useConfigurationRequestsStore } from '../../../stores';

export interface ControllerReturnedValues {
    isLoading: boolean;
    sendTestNotification: (data: TestEmailOptions) => void;
}

export const useNotificationTestDialogController = (deactivateDialog: UseModalActionsProps['deactivate']): ControllerReturnedValues => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const requestsStore = useConfigurationRequestsStore();
    const [isLoading, setIsLoading] = useState(false);

    const sendTestNotification = (formData: TestEmailOptions): void => {
        setIsLoading(true);

        requestsStore.sendTestNotificationRequest(formData)
            .then(() => {
                setIsLoading(false);
                deactivateDialog();

                notificationService
                    .info(lang.EMAIL_NOTIFICATION, lang.TEST_EMAIL_NOTIFICATION_HAS_BEEN_SENT, { width: 'auto' });
            })
            .catch(() => setIsLoading(false));
    };

    return { isLoading, sendTestNotification };
};
