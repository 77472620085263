/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PublicCloudEfsModel, PublicCloudEfsParam } from '@veeam-vspc/models/web-controllers';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import {
    FileSharesPublicCloudsActionToolbar,
    FileSharesPublicCloudsFilterToolbar,
    FsPublicCloudsBackupColumn,
} from './components';
import { FlrPortalColumn } from '../../../FlrPortalColumn';
import { ConfigSectionIds } from 'core/enums';


export interface FileSharesPublicCloudsProps {
    stateId: string;
}

export const FileSharesPublicClouds: React.FC<FileSharesPublicCloudsProps> = ({ stateId }) => {
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.FileSharesPublicClouds`;

    const columns: GridColumnProps<PublicCloudEfsModel>[] = [
        {
            id: 'vmName',
            title: lang.NAME,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
            hidden: true,
        },
        {
            id: 'platformType',
            title: lang.PLATFORM,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platformType)}
                </TextColumn>
            ),
        },
        {
            id: 'restoreStatusModel.status',
            title: lang.FILE_LEVEL_RESTORE_PORTAL,
            width: 190,
            cell: rowData => (
                <FlrPortalColumn
                    instanceUid={rowData.uid}
                    vbServerId={rowData.vbServerId}
                    restoreType={EPublicCloudRestoreType.RestoreEfsFileLevel}
                    restoreStatusModel={rowData.restoreStatusModel}
                />
            ),
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'policyName',
            title: lang.POLICY,
            cell: rowData => rowData.policyName,
            hidden: true,
        },
        {
            id: 'backup.backupCount',
            title: lang.SNAPSHOT,
            cell: rowData => (
                <FsPublicCloudsBackupColumn
                    value={rowData.backup?.backupCount}
                    name={`${lang.SNAPSHOT} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: rowData.backup?.backupType,
                    }}
                    stateId={`${gridStateId}.Dialogs.FsPublicCloudsBackup.Backup`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backupCopy.backupCount',
            title: capitalize(lang.REPLICA_SNAPSHOT),
            width: 145,
            cell: rowData => (
                <FsPublicCloudsBackupColumn
                    value={rowData.backupCopy?.backupCount}
                    name={`${capitalize(lang.REPLICA_SNAPSHOT)} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: rowData.backupCopy?.backupType,
                    }}
                    stateId={`${gridStateId}.Dialogs.FsPublicCloudsBackup.BackupCopy`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'region',
            title: lang.REGION,
            cell: rowData => rowData.region,
            hidden: true,
        },
        {
            id: 'backup.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backup?.lastBackup}
                />
            ),
        },
        {
            id: 'backupCopy.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            width: 150,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backupCopy?.lastBackup}
                />
            ),
        },
        {
            id: 'policyState',
            title: lang.POLICY_STATE,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('JobStateRepresentation', rowData.policyState)}
                </TextColumn>
            ),
        },
        {
            id: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.resourceId,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataFileSharesPublicClouds}
            defaultColumnMinWidth={100}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudEfsParam['filter']>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<PublicCloudEfsModel[]>>('/PublicCloudEfs/GetProtectedEfs', {
                    ...requestParams,
                })}
            toggleable={true}
            selection={{
                field: 'uid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesPublicCloudsFilterToolbar />,
                () => <FileSharesPublicCloudsActionToolbar idProperty={'uid'} selectedOnly />,
            ]}
        />
    );
};
