/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

export const PercentColumnRender = ({ value }: { value: number; }) => {
    const lang = useGlobalLang<VspcLang>();

    if (value === null) return lang.DASH_SYMBOL;

    const percentData = value;
    const percent = percentData || 0;

    return (
        <TextColumn>
            {percentData === -1
                ? lang.UNLIMITED
                : `${Math.round(percent * 10) / 10}%`}
        </TextColumn>
    );
};
