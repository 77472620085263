/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

export const PlateTextStyled = styled.div<{ withoutDesc?: boolean; }>`
    align-content: ${props => props.withoutDesc ? 'center' : 'end' };
    display: flex;
    flex-wrap: wrap;
    height: ${props => props.withoutDesc ? '100%' : '120px'};
    justify-content: center;
    text-align: center;
    width: 100%;
`;
