/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    Search,
    ACTION_VIEW,
    ConfiguredGrid,
    SortingMode,
    ConfiguredToolbar,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { GridStore } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { Vb365JobItemSite } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';
import { backupModeService } from '../../services';
import { BackupOptionsTarget } from '../../../../enums';

import type { SitesModalProps } from '../../interfaces';

export const SitesModal: FC<SitesModalProps> = ({ onCancel, onApply, target }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemSite, unknown, unknown>>();

    const apply = () => {
        const selected = api.current.selected;

        onApply(selected);
    };

    const { vb365ServerUid, instanceUid } = wizardStore.selectedOrganization;

    const isRowDisabled = (rowData: Vb365JobItemSite) => {
        if (target === BackupOptionsTarget.ExcludedItems) {
            return wizardStore.isItemSelected(rowData.id, Vb365JobItemComposedItemType.Site);
        }

        return !rowData.isAvailable || wizardStore.isItemSelected(rowData.id, Vb365JobItemComposedItemType.Site);
    };

    return (
        <Dialog
            header={lang.ADD_SITES}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                api={api}
                columns={[
                    { id: 'title', title: lang.TITLE, cell: rowData => rowData.title },
                    { id: 'url', title: lang.URL, cell: rowData => rowData.url },
                ]}
                data={backupModeService.getSites(vb365ServerUid, instanceUid, api.current)}
                disableAutoUpdate
                sortingMode={SortingMode.Local}
                useCompactPaginationWithCurrentPage
                toolbars={[
                    () => (
                        <ConfiguredToolbar
                            view={TOOLBAR_VIEW_TYPE.spotted}
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='multifilter'
                                            placeholder={lang.TITLE}
                                            maxLength={256}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                isRowDisabled={isRowDisabled}
            />
        </Dialog>
    );
};
