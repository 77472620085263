/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Button,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_WEIGHT,
    TextInputKit,
    Tooltip,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useEffect, useState } from 'react';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { useAsyncAction } from 'views/hooks';
import { REG_EXP } from 'core/const';
import { FormNames } from '../../enums';

interface PortalNameProps {
    onIsDirtyChange: (formIsDirty: Partial<Record<FormNames, boolean>>) => void;
}

export const PortalName: React.FC<PortalNameProps> = ({ onIsDirtyChange }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [name, setName] = useState<string>();
    const [error, setError] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();

    useEffect(() => {
        transportService.request('/Branding/GetPortalName')
            .then(({ data }: RequestSuccessResponse<string>) => setName(data));
    }, []);

    useEffect(() => {
        onIsDirtyChange({ [FormNames.PortalName]: isDirty });
    }, [isDirty]);

    const onApply = () => {
        doAsyncAction('/Branding/SetPortalName', lang.ERROR, { name })
            .finally(() => setIsDirty(false));
    };

    return (
        <>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <Text weight={TEXT_WEIGHT.bold}>{lang.PORTAL_NAME}</Text>
                <Text>{lang.THIS_NAME_WILL_BE_DISPLAYED}</Text>

                <Tooltip
                    isActive={!!error}
                    text={error}
                    render={(control: any) => (
                        <TextInputKit
                            {...control}
                            value={name}
                            error={!!error}
                            onChange={(v) => {
                                const errorMsg = REG_EXP.defaultNameLight.test(name) ? '' : lang.INVALID_CHARACTER;
                                setError(errorMsg);
                                setIsDirty(!errorMsg);
                                setName(v);
                            }}
                        />
                    )}
                />

                <Button disabled={!isDirty} onClick={onApply} style={{ width: 'max-content' }}>{lang.APPLY}</Button>
            </StackView>

            {asyncActionLoader}
        </>
    );
};
