/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
    Icon,
    INDENT,
    SIZE_M,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_SIZE,
    TEXT_WEIGHT,
    TEXT_WHITE_SPACE,
    VIEW_BG_VIEW,
    VIEW_BORDER,
} from '@veeam-vspc/shared/components';

import closeIconSrc from 'images/close.svg';
import { SanitizedInnerHtml } from 'views/components/SanitizedInnerHtml';

import type { NotificationMessageProps } from '../../interfaces';

const CloseIcon = styled(Icon)`
    position: absolute;
    cursor: pointer;
    top: ${SIZE_M};
    right: ${SIZE_M};
`;

const Root = styled(StackView)`
    flex-shrink: 1;
    // TODO: remove properties below after fixing bug 451635
    min-width: 100%;
    max-width: 100%;
    width: max-content;
    white-space: pre-wrap;
`;

const MessageText = styled(Text)`
    line-height: 18px;
`;

export const NotificationBellMessage = forwardRef((
    {
        className,
        title,
        testId,
        titleIcon,
        text,
        textIcon,
        onCloseClick,
        hasBorder = false,
        read = false,
        creationTime,

        onClick,
        onMouseDown,
        onMouseUp,
        onMouseEnter,
        onMouseLeave,
        onKeyDown,
        onKeyUp,
        onKeyPress,
        style,

        ...rest
    }: NotificationMessageProps,
    ref: React.RefObject<HTMLElement>
) => (
    <Root
        {...rest}
        className={className}
        backgroundView={read ? VIEW_BG_VIEW.normal : VIEW_BG_VIEW.neutral}
        root
        direction={STACK_DIRECTION.column}
        spaceLeft={INDENT.m}
        spaceVertical={INDENT.m}
        spaceRight={INDENT.xxl}
        borderTop={hasBorder ? VIEW_BORDER.normal : VIEW_BORDER.none}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        ref={ref}
        style={style}
    >
        <StackView gap={STACK_GAP.xxs} direction={STACK_DIRECTION.column}>

            {creationTime && (
                <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>

                    <MessageText size={TEXT_SIZE.m} truncate>
                        {creationTime}
                    </MessageText>
                </StackView>
            )}

            {title && (
                <StackView gap={STACK_GAP.s} align={STACK_ALIGN.start}>
                    {titleIcon && <Icon src={titleIcon} />}

                    <MessageText size={TEXT_SIZE.m} weight={TEXT_WEIGHT.bold} whitespace={TEXT_WHITE_SPACE.normal}>
                        <SanitizedInnerHtml html={title} isPreSanitized withMarkdown />
                    </MessageText>
                </StackView>
            )}

            {text && (
                <StackView gap={STACK_GAP.s} align={STACK_ALIGN.start}>
                    {textIcon && <Icon src={textIcon} />}

                    <MessageText size={TEXT_SIZE.m} weight={TEXT_WEIGHT.normal}>
                        <SanitizedInnerHtml html={text} isPreSanitized withMarkdown />
                    </MessageText>
                </StackView>
            )}

        </StackView>

        {onCloseClick && (
            <CloseIcon
                data-selector-id='close-icon'
                onClick={onCloseClick}
                src={closeIconSrc}
            />
        )}
    </Root>
)
);
