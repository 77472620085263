/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SPACING_S } from '@veeam-vspc/shared/components';

export const ChartTitleStyled = styled.div`
    padding: ${SPACING_S} 0;
    text-align: center;
`;
