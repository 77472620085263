/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SizeUnits } from '../../enums';

export const calcScale = (value) => {
    const kbLimit = 1024;
    const mbLimit = kbLimit * 1024;
    const gbLimit = mbLimit * 1024;
    const tbLimit = gbLimit * 1024;
    const pbLimit = tbLimit * 1024;
    const ebLimit = pbLimit * 1024;

    let exp;

    if (value < kbLimit) {
        exp = 0;
    } else if (value < mbLimit) {
        exp = 1;
    } else if (value < gbLimit) {
        exp = 2;
    } else if (value < tbLimit) {
        exp = 3;
    } else if (value < pbLimit) {
        exp = 4;
    } else if (value < ebLimit) {
        exp = 5;
    } else {
        exp = 6;
    }

    return { exp, unit: SizeUnits[exp] };
};
