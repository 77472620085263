/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Text, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

import { ImageUpload } from '../ImageUpload';

export const Report: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ImageUpload
            fileExt={'.png'}
            imageTitle={lang.BRANDING_REPORT_TITLE}
            imageDescription={<Text>{lang.BRANDING_REPORT_DESCRIPTION}</Text>}
            imageInfoUrl={'/Branding/GetReportBranding'}
            getImageUrl={'/Branding/GetReportBrandImage'}
            uploadImageUrl={'/Branding/UploadReportBrandImage'}
            resetImageUrl={'/Branding/ResetReportBranding'}
            imageSize={{
                width: 200,
                height: 45,
            }}
            withThemeBgColor={false}
        />
    );
};
