/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SPACING_S, useGlobalAddons } from '@veeam-vspc/shared/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { OrganizationsFilter } from './components/OrganizationsFilter';
import { LocationsFilter } from './components/LocationsFilter';
import { SitesFilter } from './components/SitesFilter';
import { RoutePaths } from 'configs/enums';
import { prepareCompaniesTree } from './components/OrganizationsFilter/utils/prepare-companies-tree';

const GlobalFiltersStyled = styled.div`
    padding: ${SPACING_S};
`;

const GlobalFiltersItemStyled = styled.div`
    &:not(:last-child) {
        padding-bottom: ${SPACING_S};
    }
`;

export const GlobalFilters: React.FC = observer(() => {
    const { portalFilters, portalPreferences } = useGlobalAddons();
    const location = useLocation();
    const {
        isSitesFilterVisible,
        isOrganizationsFilterVisible,
        isLocationsFilterVisible,
        sites,
        organizations,
        organizationsMap,
        locations,
        organizationFilter,
    } = portalFilters;
    const organizationNodes = useMemo(() => (
        isOrganizationsFilterVisible ? [prepareCompaniesTree(organizations[0])] : []
    ), [isOrganizationsFilterVisible, organizations]);

    // @TODO: move to Route Enum
    const locationFilterDisabledOn = [
        '/billingSummary',
        '/invoices',
        '/tops',
        '/trends/infrastructure',
        '/companies',
        '/resellers',
    ];

    const locationFilterDisabled = () => {
        const path = location.pathname.split(RoutePaths.Home)[1];
        const ruleApplied = locationFilterDisabledOn.some(rule => path.startsWith(rule));
        const orgValueIsAllCompanies = isOrganizationsFilterVisible && organizationFilter === null;

        if (ruleApplied || orgValueIsAllCompanies) {
            return true;
        }

        return locations.length <= 1;
    };

    return (
        <GlobalFiltersStyled>
            {isSitesFilterVisible && sites.length > 1 && (
                <GlobalFiltersItemStyled>
                    <SitesFilter
                        data={sites}
                        selected={portalFilters.siteFilter}
                        onSelectionChange={(agentGuids) => {
                            portalFilters.setSiteFilter(agentGuids);
                            portalFilters.updatePortalFilters();
                        }}
                    />
                </GlobalFiltersItemStyled>
            )}

            {isOrganizationsFilterVisible && (
                <GlobalFiltersItemStyled>
                    <OrganizationsFilter
                        data={organizationNodes}
                        dataMap={organizationsMap}
                        selected={portalFilters.organizationFilter?.id ?? organizationNodes[0].key as number}
                        rootNode={organizationNodes[0].key as number}
                        onSelectionChange={(data) => {
                            portalFilters.setOrganizationFilter(data);
                            portalPreferences.forceReloadPreferences();
                        }}
                    />
                </GlobalFiltersItemStyled>
            )}

            {isLocationsFilterVisible && (
                <GlobalFiltersItemStyled>
                    <LocationsFilter
                        data={locations}
                        selected={portalFilters.locationFilter || locations[0].id}
                        disabled={locationFilterDisabled()}
                        onSelectionChange={id => portalFilters.setLocationFilter(id)}
                    />
                </GlobalFiltersItemStyled>
            )}
        </GlobalFiltersStyled>
    );
});
