/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CloudConnectRepositoryUsage } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { PercentColumnRender, SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const UsedCloudRepositories: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings = useCommonSettings<CloudConnectRepositoryUsage>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.UsedCloudRepositories,
    });
    const { lang } = settings;

    const columns: GridColumnProps<CloudConnectRepositoryUsage>[] = [
        {
            id: 'name',
            title: lang.REPOSITORY,
            cell: rowData => rowData.name,
        },
        {
            id: 'serverName',
            title: lang.CLOUD_CONNECT_SERVER,
            cell: rowData => rowData.serverName,
            width: 180,
        },
        {
            id: 'capacity',
            title: lang.CAPACITY,
            cell: rowData => <SizeColumnRender value={rowData.capacity} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'freeSpace',
            title: lang.FREE_SPACE,
            cell: rowData => <SizeColumnRender value={rowData.freeSpace} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'utilization',
            title: lang.USAGE,
            cell: rowData => <PercentColumnRender value={rowData.utilization} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'usedSpace',
            title: lang.USED_SPACE,
            cell: rowData => <SizeColumnRender value={rowData.usedSpace} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_CLOUD_REPOSITORIES_BY_USED_SPACE}
            columns={columns}
            sectionId={ConfigSectionIds.TopsInfrastructure}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
