/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ACTION_VIEW,
    CheckboxKit,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    FieldLayout,
    Form,
    FormLayout,
    FormValidator,
    LabelGroup,
    MultipleChoice,
    NoLabelGroup,
    RadiobuttonKit,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    useExternalFormApi,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useEffect, useMemo, useState } from 'react';
import {
    DayOfWeek,
    MonthsRepresentation,
    ScheduleTypeRepresentation,
    WeekInMonthRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { ScheduleMonthlySettingsRepresentation } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getDaysNumber, getDaysOfWeek, getMonth, getWeekInMonth } from './helpers';
import { TimeField } from 'components/controls/TimeField';
import { ReportStore } from 'views/pages/ReportPage/components/wizards/ReportWizard/stores';
import { DayInMonth } from 'views/pages/ReportPage/components/wizards/ReportWizard/enums';
import { isValidDayInMonth } from 'core/utils/date-helpers/is-valid-day-in-month';

import type { ReportSchedule } from '../../../interfaces';
import type { Timezone } from 'views/pages/ReportPage/components/wizards/ReportWizard/interfaces';

interface InvoiceSchedulingProps {
    deactivate: () => void;
    onApplySchedule: (data: ReportSchedule) => void;
    data: ReportSchedule;
}

const formValidate = (schedule: ReportSchedule['schedule'], lang: VspcLang) => {
    const validator = new FormValidator(schedule);

    validator
        .validate('monthly.dayNumber')
        .string()
        .check((value) => {
            if (parseInt(value) < 32 && !isValidDayInMonth(parseInt(value), schedule.monthly.months))
                return lang.THE_SPECIFIED_DAY_IS_NO_PRESENT;
        });

    validator
        .validate('monthly.months')
        .string()
        .check((val) => {
            if (!val || val.length === 0) {
                return lang.ERROR;
            }
        });

    return validator.result();
};

const defaultMonths: Array<MonthsRepresentation> = [
    MonthsRepresentation.January,
    MonthsRepresentation.February,
    MonthsRepresentation.March,
    MonthsRepresentation.April,
    MonthsRepresentation.May,
    MonthsRepresentation.June,
    MonthsRepresentation.July,
    MonthsRepresentation.August,
    MonthsRepresentation.September,
    MonthsRepresentation.October,
    MonthsRepresentation.November,
    MonthsRepresentation.December,
];
const defaultFormData: ScheduleMonthlySettingsRepresentation = {
    week: WeekInMonthRepresentation.Day,
    months: defaultMonths,
    day: DayOfWeek.Sunday,
    time: '10:00',
    dayNumber: 1,
};

type FormState = { generateAfterLicenseReport: boolean; } & ReportSchedule['schedule'];

export const InvoiceScheduling: React.FC<InvoiceSchedulingProps> = ({ deactivate, data, onApplySchedule }) => {
    const lang = useGlobalLang<VspcLang>();
    const [formState] = useState<FormState>(() => {
        const d = {
            ...data.schedule,
            monthly: defaultFormData,
        };

        if (data?.schedule?.monthly) {
            d.monthly = {
                ...data.schedule.monthly,
                months: data.schedule.monthly.months ? data.schedule.monthly.months : defaultMonths,
            };
        }

        return { ...d, generateAfterLicenseReport: data?.generateAfterLicenseReport } as FormState;
    });

    const [type, setType] = useState<ScheduleTypeRepresentation>(formState.type);
    const [week, setWeek] = useState(formState.monthly?.week);
    const [generateAfterLicenseReport, setGenerateAfterLicenseReport] = useState(Boolean(data?.generateAfterLicenseReport));
    const [disabled, setDisabled] = useState(false);
    const formApi = useExternalFormApi<ReportSchedule['schedule']>();
    const [timezones, setTimezones] = useState<Timezone[]>([]);
    const { portalUser } = useGlobalAppData();
    const { transportService } = useGlobalServices();
    const isNotScheduledType = type === ScheduleTypeRepresentation.NotScheduled;

    const reportStore = useMemo(() => new ReportStore(lang, portalUser, transportService), []);

    useEffect(() => {
        reportStore.timezoneListCache.load()
            .then(items => setTimezones(items));
    }, []);

    return (
        <Dialog
            onRequestClose={deactivate}
            header={lang.SCHEDULE}
            title={lang.SPECIFY_THE_INVOICE_SCHEDULING}
            size={DIALOG_SIZE.auto}
            actions={[
                {
                    text: lang.APPLY,
                    disabled,
                    onClick: () => {
                        const { generateAfterLicenseReport, ...schedule } = formApi.value as FormState;
                        onApplySchedule({
                            schedule,
                            generateAfterLicenseReport,
                        });
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: deactivate,
                    view: ACTION_VIEW.secondary,
                },
            ]}
        >
            <StackView
                gap={STACK_GAP.s}
                direction={STACK_DIRECTION.column}
                distribution={STACK_DISTRIBUTION.firstFill}
            >
                <Form
                    value={formState}
                    externalFormApi={formApi}
                    onChange={(value: FormState) => setDisabled(!value.generateAfterLicenseReport && !formApi.isValid())}
                    validate={value => formValidate(value, lang)}
                >
                    <FormLayout inlineLabel>
                        <NoLabelGroup
                            content={(
                                <CheckboxKit
                                    checked={type === ScheduleTypeRepresentation.Monthly || generateAfterLicenseReport === true}
                                    onChange={(value) => {
                                        const newValue = value
                                            ? ScheduleTypeRepresentation.Monthly
                                            : ScheduleTypeRepresentation.NotScheduled;
                                        setType(newValue);
                                        formApi.setValue('type', newValue);

                                        if (!value) {
                                            setGenerateAfterLicenseReport(false);
                                            formApi.setValue('generateAfterLicenseReport', false);
                                        }
                                    }}
                                >
                                    {lang.GENERATE_THIS_INVOICE_AUTOMATICALLY}
                                </CheckboxKit>
                            )}
                            subContent={(
                                <>
                                    <NoLabelGroup
                                        disabled={isNotScheduledType && generateAfterLicenseReport === false}
                                        content={(
                                            <FieldLayout>
                                                <RadiobuttonKit
                                                    checked={generateAfterLicenseReport === false}
                                                    onChange={() => {
                                                        setGenerateAfterLicenseReport(false);
                                                        formApi.setValue('generateAfterLicenseReport', false);
                                                        setType(ScheduleTypeRepresentation.Monthly);
                                                        formApi.setValue('type', ScheduleTypeRepresentation.Monthly);
                                                    }}
                                                >
                                                    {`${lang.MONTHLY_AT}:`}
                                                </RadiobuttonKit>
                                            </FieldLayout>
                                        )}
                                        subContent={(
                                            <FormLayout inlineLabel>
                                                <LabelGroup label={`${lang.TIME}:`} disabled={isNotScheduledType}>
                                                    <FormLayout direction={STACK_DIRECTION.row} inlineLabel>
                                                        <TimeField
                                                            name={'monthly.time'}
                                                            shortFormat
                                                            size={CONTROL_SIZE.xs}
                                                            disabled={isNotScheduledType}
                                                        />

                                                        <Combobox
                                                            data={getWeekInMonth(lang)}
                                                            valueGetter={item => item.id}
                                                            textGetter={item => item.text}
                                                            name={'monthly.week'}
                                                            onChange={(value: DayInMonth) => setWeek(value)}
                                                            size={CONTROL_SIZE.xs}
                                                            disabled={isNotScheduledType}
                                                        />

                                                        {week !== DayInMonth.Day && (
                                                            <Combobox
                                                                data={getDaysOfWeek(lang)}
                                                                valueGetter={item => item.id}
                                                                textGetter={item => item.name}
                                                                name={'monthly.day'}
                                                                size={CONTROL_SIZE.xs}
                                                                disabled={isNotScheduledType}
                                                            />
                                                        )}

                                                        {week === DayInMonth.Day && (
                                                            <Combobox
                                                                name={'monthly.dayNumber'}
                                                                data={getDaysNumber(lang)}
                                                                valueGetter={item => item.id}
                                                                textGetter={item => item.name}
                                                                size={CONTROL_SIZE.xs}
                                                                disabled={isNotScheduledType}
                                                            />
                                                        )}

                                                        <MultipleChoice
                                                            error={formState.monthly.months.length === 0}
                                                            value={formState.monthly.months}
                                                            data={getMonth(lang)}
                                                            titleFormatter={() => lang.MONTHS}
                                                            columnSize={6}
                                                            onChange={(values) => {
                                                                formApi.setValue('monthly.months', values);
                                                            }}
                                                            disabled={isNotScheduledType}
                                                        />
                                                    </FormLayout>
                                                </LabelGroup>

                                                <Combobox
                                                    label={lang.TIME_ZONE}
                                                    data={timezones}
                                                    valueGetter={item => item.id}
                                                    textGetter={item => item.description}
                                                    name={'timeZoneId'}
                                                    size={CONTROL_SIZE.full}
                                                    disabled={isNotScheduledType}
                                                />
                                            </FormLayout>
                                        )}
                                    />

                                    <NoLabelGroup
                                        content={(
                                            <FieldLayout
                                                disabled={isNotScheduledType && generateAfterLicenseReport === false}
                                            >
                                                <RadiobuttonKit
                                                    checked={generateAfterLicenseReport === true}
                                                    onChange={() => {
                                                        setType(ScheduleTypeRepresentation.NotScheduled);
                                                        setGenerateAfterLicenseReport(true);
                                                        formApi.setValue('generateAfterLicenseReport', true);
                                                        formApi.setValue('type', ScheduleTypeRepresentation.NotScheduled);
                                                    }}
                                                >
                                                    {lang.AFTER_MONTHLY_LICENSE_USAGE_REPORT}
                                                </RadiobuttonKit>
                                            </FieldLayout>
                                        )}
                                    />
                                </>
                            )}
                        />
                    </FormLayout>
                </Form>
            </StackView>
        </Dialog>
    );
};
