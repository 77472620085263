/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import {
    PageWizard as PageWizardFromComponents,
    ThemeProviderKit,
    themes,
    useGlobalAppData,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { useNavigationBlocker } from '@veeam-vspc/shared/hooks';

import type { PageWizardProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

export const PageWizard = <T extends {}>(
    props: Omit<PageWizardProps<T>, 'closeMessageText' | 'closeMessageCancelButtonText' | 'closeMessageOkButtonText'>
) => {
    const { theme } = useGlobalAppData();
    const lang = useGlobalLang<VspcLang>();

    const { cleanup } = useNavigationBlocker({
        isDirty: true,
        prompt: {
            title: props.title,
            message: lang.ALL_CHANGES_WILL_BE_LOST,
        },
        requestClose: () => {
            props.deactivate();
        },
    });

    useEffect(() => () => cleanup(), []);

    return (
        <ThemeProviderKit theme={themes[theme]}>
            <PageWizardFromComponents
                hasBack
                closeMessageText={lang.ALL_CHANGES_WILL_BE_LOST}
                closeMessageCancelButtonText={lang.CANCEL}
                closeMessageOkButtonText={lang.OK}
                {...props}
                onClose={() => {
                    if (props.onClose) {
                        props.onClose();
                    } else {
                        // For use in ExtJS only
                        document.dispatchEvent(new CustomEvent('RCOP/HIDE_PAGE_WIZARD'));
                    }
                }}
            />
        </ThemeProviderKit>
    );
};
