/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { Vb365JobItemComposedItemType, Vb365JobItemUserUserType } from '@veeam-vspc/models/rest';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { Vb365JobItemComposed } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { BackupOptionsTarget } from '../../../../enums';

import type { ConfigurableOption, OptionsCellProps } from '../../interfaces';

const optionsNames = (lang: VspcLang): Record<ConfigurableOption, string> => ({
    backupMailbox: lang.MAIL,
    backupArchiveMailbox: lang.ARCHIVE,
    backupOneDrive: lang.ONE_DRIVE,
    backupPersonalSite: lang.SITE,
    backupMembers: lang.MEMBERS,
    backupSites: lang.SITES,
    backupTeams: lang.TEAMS,
    backupMemberMailbox: lang.MAIL,
    backupMemberArchiveMailbox: lang.ARCHIVE,
    backupMemberOneDrive: lang.ONE_DRIVE,
    backupMemberSite: lang.SITE,
    backupGroupSite: lang.GROUP_SITE,
    backupTeamsChats: lang.TEAMS_CHATS,
});

export const getOptionName = (option: ConfigurableOption, itemType: Vb365JobItemComposedItemType, lang: VspcLang) => {
    if (itemType === Vb365JobItemComposedItemType.Group && option === 'backupMailbox') {
        return lang.GROUP_MAIL;
    }

    if (itemType === Vb365JobItemComposedItemType.Team && option === 'backupTeamsChats') {
        return lang.CHATS;
    }

    return optionsNames(lang)[option];
};

export const isItemEditable = (item: Vb365JobItemComposed, targetCollectionType: BackupOptionsTarget) => {
    const itemTypesThatCouldBeEdited = [
        Vb365JobItemComposedItemType.User,
        Vb365JobItemComposedItemType.Group,
        Vb365JobItemComposedItemType.PartialOrganization,
        Vb365JobItemComposedItemType.Team,
    ];

    if (!itemTypesThatCouldBeEdited.includes(item.itemType)) {
        return false;
    }

    if (item.user?.userType === Vb365JobItemUserUserType.Public) {
        return false;
    }


    if (
        targetCollectionType === BackupOptionsTarget.ExcludedItems &&
        item.itemType === Vb365JobItemComposedItemType.Team
    ) {
        return false;
    }

    return true;
};

const getOptions = (
    lang: VspcLang,
    resource: Vb365JobItemComposed,
    options: ConfigurableOption[]
): string => {
    if (options.every(option => resource[option] === true)) {
        return lang.ALL;
    }

    if (options.every(option => resource[option] === false) && resource.itemType !== Vb365JobItemComposedItemType.Team) {
        return ColumnTexts.DashSymbol;
    }

    const optionsNames = options
        .filter(option => resource[option] === true)
        .map(option => getOptionName(option, resource.itemType, lang));

    // Add fake Teams option
    if (resource.itemType === Vb365JobItemComposedItemType.Team) {
        optionsNames.push(lang.CHANNELS_TABS_FILES);
    }

    return optionsNames.join('/');
};

export const OptionsCell: FC<OptionsCellProps> = observer(({ rowData, store, collection, onClick }) => {
    const lang = useGlobalLang<VspcLang>();
    const item = store[collection].find(i => i.id === rowData.id);

    if (!item) {
        return;
    }

    const options = store.getProcessingOptions(item);

    const plaintext = getOptions(lang, rowData, options);

    if (options.length === 0) {
        return plaintext;
    }

    if (options.length === 1 && options[0] === 'backupMembers') {
        return plaintext;
    }

    if (!isItemEditable(rowData, collection)) {
        return plaintext;
    }

    return <LinkButton onClick={onClick}>{getOptions(lang, item, options)}</LinkButton>;
});
