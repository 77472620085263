/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BodyLayout, StackView } from '@veeam-vspc/shared/components';

const Wrapper = styled(StackView)<{ hide: boolean; }>`
    display: ${props => props.hide ? 'none' : 'flex'};
`;

const BodyLayoutStyled = styled(BodyLayout)<{ id: string; }>`
    & > :first-child {
        padding: 0;
    }
`;

interface ExtContainerProps {
    hide?: boolean;
}

const extContainerDomElement = document.createElement('div');
extContainerDomElement.id = 'ext-container';
extContainerDomElement.style.width = '100%';
extContainerDomElement.style.height = '100%';

export const ExtContainer: React.FC<ExtContainerProps> = ({ hide = false }) => {
    useEffect(() => {
        if (!document.querySelector('#react-ext-container').contains(extContainerDomElement)) {
            document.querySelector('#react-ext-container').appendChild(extContainerDomElement);
        }
    }, []);

    return (
        <Wrapper data-tid='ext-container-wrapper' hide={hide} size={{ width: '100%', height: '100%' }}>
            <BodyLayoutStyled id='react-ext-container' />
        </Wrapper>
    );
};

