/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import { useLoadImageDataUrl } from '../use-load-image-data-url';

export const usePortalFaviconImage = (noAuth = false) => {
    const loadImgDataUrl = useLoadImageDataUrl();
    const [icon, setIcon] = useState('');
    const loadPortalFaviconImage = () => {
        const prevLink = document.getElementById('dynamic-favicon');
        const prevLink1 = document.getElementById('dynamic-favicon1');

        if (prevLink) {
            prevLink.remove();
        }
        if (prevLink1) {
            prevLink1.remove();
        }
        const link = document.createElement('link');
        link.id = 'dynamic-favicon';
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';

        const link1 = document.createElement('link');
        link1.id = 'dynamic-favicon1';
        link1.rel = 'icon';
        link.type = 'image/x-icon';

        document.head.appendChild(link1);
        document.head.appendChild(link);

        loadImgDataUrl(`/Branding/GetPortalFavicon?_dc=${Date.now()}`, noAuth)
            .then((portalFaviconDataUrl) => {
                [link, link1].forEach((elem) => {
                    elem.href = portalFaviconDataUrl;
                });

                setIcon(portalFaviconDataUrl);
            });
    };

    useEffect(() => {
        if (!icon) {
            loadPortalFaviconImage();
        }
    }, []);
};
