/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { SmtpSettings, SmtpSettingsServerCertificate } from '@veeam-vspc/models/rest';

import { SMTPCertStatus } from '../enums';

export const getValidatedCertStatus = (
    certificate: SmtpSettingsServerCertificate,
    hash: SmtpSettings['exclusivelyAcceptedCertificateHash'] = ''
): SMTPCertStatus => {
    const { NoCert, Trusted, Untrusted, UntrustedAccepted } = SMTPCertStatus;

    if (certificate === null) {
        return NoCert;
    } else if (certificate.isValid) {
        return Trusted;
    } else if (certificate.hash.toLowerCase() === hash.toLowerCase()) {
        return UntrustedAccepted;
    }

    return Untrusted;
};
