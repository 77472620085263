/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGridApi } from '@veeam-vspc/shared/components';

import type { RequestGridSortDirections } from '@veeam-vspc/shared/components';

import type { GridExportParams, GridExportProps } from './interfaces';

export const useGridExportParams = <RowDataT, TotalRowDataT, FilterT>(props: GridExportProps = {}): GridExportParams => {
    const {
        selectedOnly = false,
        idProperty = 'id',
        extraParams = {},
        forceSelectedId = null,
        excludeFromExport = [],
    } = props;
    const gridApi = useGridApi<RowDataT, TotalRowDataT, FilterT> ();
    const { columns, selected, sortingConfig, requestParamsForActions } = gridApi;

    const selectedResult = forceSelectedId
        ? gridApi.pageData.filter(item => item[idProperty] === forceSelectedId)
        : selected;

    const exportParams: GridExportParams = {
        ...extraParams,
        columns: columns
            .filter(column => !excludeFromExport.includes(column.id))
            .map(column => `${column.title}:${column.id}`),
        sort: sortingConfig
            ? [{
                direction: sortingConfig.direction.toUpperCase() as RequestGridSortDirections,
                property: sortingConfig.key,
            }]
            : [],
        data: {
            filter: requestParamsForActions.filter,
        },
    };

    if (selectedOnly && selectedResult.length > 0) {
        const { onlyThese, exclude } = requestParamsForActions;

        if (onlyThese.length) {
            exportParams.data.onlyThese = onlyThese as string[];
        }

        if (exclude.length) {
            exportParams.data.exclude = exclude as string[];
        }
    }

    return exportParams;
};
