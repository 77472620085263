/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface GettingStartedFile {
    GettingStarted: ComponentType<any>;
}

export const GettingStartedPage = () => (
    <LazyLoadedComponent<GettingStartedFile>
        importPromise={() => (import('./components/GettingStarted') as Promise<GettingStartedFile>)}
        pageName={'GettingStarted'}
    />
);
