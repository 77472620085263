/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    ACTION_VIEW,
    ConfiguredGrid,
    ConfiguredToolbar,
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    Search,
    SortingMode,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    useGlobalAddons,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { deepCopy } from '@veeam-vspc/shared/core';

import type { GridStore } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type {
    BackupServerBackupJobIndexingSettings,
    BackupServerBackupJobVmwareObjectSize,
} from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../../../stores';
import { guestProcessingResourcesService } from '../../../../services';

interface ItemsModalProps {
    onApply: (selection: BackupServerBackupJobVmwareObjectSize[]) => void;
    onCancel: () => void;
}

export const ItemsModal: FC<ItemsModalProps> = ({ onCancel, onApply }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<BackupServerBackupJobVmwareObjectSize, unknown, unknown>>();
    const { portalEnums } = useGlobalAddons();

    const [selected, setSelected] = useState<BackupServerBackupJobVmwareObjectSize[]>([]);

    const getCleanedSelected = (selected: BackupServerBackupJobVmwareObjectSize[]): BackupServerBackupJobVmwareObjectSize[] => {
        const clonedSelected = deepCopy(selected) as (BackupServerBackupJobVmwareObjectSize & { objectId?: string; })[];

        return clonedSelected.map((item) => {
            delete item.objectId;
            return item;
        });
    };

    const apply = () => {
        onApply(getCleanedSelected(selected));
    };

    const isItemSelected = (rowData: BackupServerBackupJobVmwareObjectSize) =>
        wizardStore.isItemSelectedGeneric(
            rowData.inventoryObject.objectId,
            (item: BackupServerBackupJobIndexingSettings) => item.vmObject.objectId,
            wizardStore.guestFSIndexingSettings
        );

    return (
        <Dialog
            header={capitalize(lang.ADD_OBJECTS)}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                api={api}
                columns={[
                    {
                        id: 'name',
                        title: lang.NAME,
                        cell: rowData => rowData.inventoryObject.name,
                    },
                    {
                        id: 'type',
                        title: lang.TYPE,
                        cell: rowData => portalEnums.getEnumDescription('BackupServerVmwareInventoryType', rowData.inventoryObject.type),
                    },
                    {
                        id: 'size',
                        title: lang.SIZE,
                        cell: rowData => rowData.size,
                        isSortable: false,
                    },
                ]}
                data={guestProcessingResourcesService.getTransformedExpandedObjects(
                    wizardStore.serverUid,
                    wizardStore.selectedCompanyVC.hostUid,
                    wizardStore.includedItems,
                    api.current
                )}
                defaultColumnMinWidth={100}
                disableAutoUpdate
                sortingMode={SortingMode.Remote}
                toolbars={[
                    () => (
                        <ConfiguredToolbar
                            view={TOOLBAR_VIEW_TYPE.spotted}
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='name'
                                            placeholder={lang.NAME}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                selection={{
                    field: 'objectId',
                    checkbox: true,
                    multiple: true,
                }}
                disableAllPageSelection
                onSelectionChange={setSelected}
                isRowDisabled={rowData => isItemSelected(rowData)}
                initialState={{
                    selected,
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: 'name',
                    },
                }}
            />
        </Dialog>
    );
};
