/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AppBellContext } from '../stores';

import type { AppBellStore } from '../stores';

export const useAppBellStore = () => useContext<AppBellStore>(AppBellContext);
