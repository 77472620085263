/**
 * Copyright © Veeam Software Group GmbH.
 */

export const readAsText = (file: File): Promise<string> => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);

        reader.readAsText(file);
    })
);
