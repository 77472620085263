/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { IconType } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { EProductUpgradeStatus } from 'core/enums';
import successStatusIconSrc from 'images/statuses/success.svg';
import warningStatusIconSrc from 'images/statuses/warning.svg';
import updateVawStatusIconSrc from 'images/statuses/update-vaw.png';
import actionPatchStatusIconSrc from 'images/statuses/action-patch.png';

interface ColumnContentParams {
    iconSrc: IconType;
    columnText: string;
}

export const getColumnContentParams = (lang: VspcLang, value: EProductUpgradeStatus, upgradeVersion: string): ColumnContentParams => {
    switch (value) {
        case EProductUpgradeStatus.UpToDate:
            return {
                iconSrc: successStatusIconSrc,
                columnText: lang.UP_TO_DATE,
            };

        case EProductUpgradeStatus.UpgradeAvailable:
            return {
                iconSrc: updateVawStatusIconSrc,
                columnText: `${lang.UPGRADE_AVAILABLE} (${upgradeVersion})`,
            };

        case EProductUpgradeStatus.PatchAvailable:
            return {
                iconSrc: actionPatchStatusIconSrc,
                columnText: `${lang.PATCH_AVAILABLE} (${upgradeVersion})`,
            };

        case EProductUpgradeStatus.ManualUpgradeRequired:
            return {
                iconSrc: warningStatusIconSrc,
                columnText: `${lang.MANUAL_UPGRADE_REQUIRED} (${upgradeVersion})`,
            };

        default:
            return {
                iconSrc: '',
                columnText: lang.DASH_SYMBOL,
            };
    }
};
