/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

export const CpuColumnRender = ({ value }: { value: number; }) => {
    const lang = useGlobalLang<VspcLang>();

    const resultValue = value >= 1000 ? (value / 1000).toFixed(2) : value;
    const suffix = value >= 1000 ? lang.GHz : lang.MHz;
    return <TextColumn>{value === -1 ? lang.UNLIMITED : `${resultValue} ${suffix}`}</TextColumn>;
};
