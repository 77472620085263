/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    Form,
    MODAL_SIZE_MS,
    useGlobalLang,
    useModal,
} from '@veeam-vspc/shared/components';

import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { formValidate } from './helpers';
import { useServerSettingsDialogController } from './hooks';
import { useServerSettingsDialogFormStore } from '../../stores';
import { BasicSettingsFields, ModernSettingsFields, UntrustedCertDialog } from './components';
import { TestedSmtpSettingsContext } from './context';

import type { UntrustedCertDialogData } from './interfaces';

export interface ServerSettingsDialogProps {
    deactivateDialogCb: UseModalActionsProps['deactivate'];
}

export const ServerSettingsDialog: React.FC<ServerSettingsDialogProps> = observer((props) => {
    const { deactivateDialogCb } = props;
    const lang = useGlobalLang<VspcLang>();
    const formStore = useServerSettingsDialogFormStore();
    const { hasOAuth, formApi, formData, onFormChange, validationState } = formStore;

    const [untrustedCertDialog, untrustedCertDialogActions] = useModal<UntrustedCertDialogData>({
        render: ({ deactivate, data }) => <UntrustedCertDialog deactivateDialog={deactivate} {...data} />,
    });

    const { onSaveActionClick, testedSmtpSettings } = useServerSettingsDialogController(deactivateDialogCb, untrustedCertDialogActions.activate);

    return (
        <TestedSmtpSettingsContext.Provider value={testedSmtpSettings}>
            {untrustedCertDialog}

            <Dialog
                header={lang.EMAIL_SERVER_SETTINGS}
                onRequestClose={deactivateDialogCb}
                size={{ width: MODAL_SIZE_MS.width, height: MODAL_SIZE_MS.height }}
                actions={[
                    {
                        text: hasOAuth ? lang.AUTHORIZE : lang.SAVE,
                        onClick: onSaveActionClick,
                    },
                    {
                        text: lang.CANCEL,
                        onClick: deactivateDialogCb,
                        view: ACTION_VIEW.secondary,
                    },
                ]}
            >
                <Form
                    value={formData}
                    externalFormApi={formApi}
                    validationState={validationState}
                    validate={formData => formValidate(formData)}
                    onChange={onFormChange}
                >
                    {hasOAuth
                        ? <ModernSettingsFields />
                        : <BasicSettingsFields />}
                </Form>
            </Dialog>
        </TestedSmtpSettingsContext.Provider>
    );
});
