/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateColumn, useGlobalAppData } from '@veeam-vspc/shared/components';

import type { DateColumnProps } from '@veeam-vspc/shared/components';

export interface GridDateColumnProps extends Partial<DateColumnProps> {
    value: DateColumnProps['value'];
}

export const GridDateColumn = observer(({ value, ...restProps }: GridDateColumnProps) => {
    const { formats } = useGlobalAppData();
    const { netShortDate, netShortTime } = formats;

    return (
        <DateColumn
            {...restProps}
            value={value}
            shortDateFormat={netShortDate}
            shortTimeFormat={netShortTime}
        />
    );
});
