/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum XmlFileParams {
    ParsingType = 'text/xml',
}

export enum XmlFileTags {
    Property= 'property',
    Main = 'unattendedInstallationConfiguration',
}

export enum XmlFileTagAttributes {
    Name = 'name',
    Value = 'value',
    Hidden = 'hidden',
    Bundle = 'bundle',
    Mode = 'mode',
}

export enum XmlFileTagAttributesValues {
    Accept = '1',
    Decline = '0',
    Bundle = 'vbr',
    InstallMode = 'install',
    UpgradeMode = 'upgrade',
}

export enum XmlFileTagQueries {
    Eula = 'property[name=ACCEPT_EULA]',
    License = 'property[name=VBR_LICENSE_FILE]',
    RequiredSoftware = 'property[name=ACCEPT_REQUIRED_SOFTWARE]',
    LicensingPolicy = 'property[name=ACCEPT_LICENSING_POLICY]',
    ThirdPartyLicenses = 'property[name=ACCEPT_THIRDPARTY_LICENSES]',
    RebootIfRequired = 'property[name=REBOOT_IF_REQUIRED]',
}
