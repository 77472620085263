/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SPACE_FILL, STACK_DIRECTION, StackView } from '@veeam-vspc/shared/components';
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import type { PropsWithChildren } from 'react';

import { NotificationBarItem } from './components/NotificationBarItem';
import { useNotificationBar } from './hooks/use-notification-bar';

export const NotificationBar: React.FC<PropsWithChildren<{}>> = observer(({ children }) => {
    const notificationBarStore = useNotificationBar();
    const textHash = notificationBarStore.bars.map(bar => bar.text).join();

    const updateExtLayout = useCallback(() => {
        const extContainer = document.getElementById('ext-container');

        if (extContainer && RCOP.Viewport) {
            RCOP.Viewport.setHeight(extContainer.clientHeight);
            RCOP.Viewport.setWidth(extContainer.clientWidth);
            RCOP.Viewport.updateLayout();
        }

        if (extContainer && RCOP.CustomViewContainer) {
            RCOP.CustomViewContainer.setHeight(extContainer.clientHeight);
            RCOP.CustomViewContainer.setWidth(extContainer.clientWidth);
            RCOP.CustomViewContainer.updateLayout();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('unload', notificationBarStore.hideAll);
        return () => window.removeEventListener('unload', notificationBarStore.hideAll);
    }, []);

    useEffect(() => updateExtLayout(), [textHash, updateExtLayout]);

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            spaceFill={SPACE_FILL.all}
            style={{ overflow: 'hidden' }}
        >
            {notificationBarStore.bars.length > 0 && (
                notificationBarStore.bars.map(barProps => <NotificationBarItem {...barProps} key={String(barProps.id)} />)
            )}

            <StackView
                spaceFill={SPACE_FILL.all}
                style={{ overflow: 'hidden' }}
            >
                {children}
            </StackView>
        </StackView>
    );
});
