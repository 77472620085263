/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import {
    GridColumnJustify,
    TimeAgoColumn,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { ProtectedObjectStorage } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';

type ColumnsContentType = GridColumnProps<ProtectedObjectStorage>[];

export const useColumns = (gridStateId: string): ColumnsContentType => {
    const lang = useGlobalLang<VspcLang>();

    return useMemo((): ColumnsContentType => (
        [
            {
                id: 'name',
                title: lang.OBJECT_STORAGE,
                cell: rowData => rowData.name,
            },
            {
                id: 'bucketName',
                title: lang.BUCKET,
                cell: rowData => (
                    <SourceItemsColumn
                        value={rowData.bucketName}
                        stateId={`${gridStateId}.Dialogs.SourceItems`}
                        name={rowData.bucketName}
                        sourceItems={rowData.sourceItems}
                    />
                ),
            },
            {
                id: 'jobName',
                title: lang.JOB,
                cell: rowData => rowData.jobName,
            },
            {
                id: 'backupServerName',
                title: capitalize(lang.BACKUP_SERVER),
                cell: rowData => rowData.backupServerName,
            },
            {
                id: 'shortTermRestorePointsSize',
                title: lang.BACKUP_SIZE,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.shortTermRestorePointsSize} />,
            },
            {
                id: 'longTermRestorePointsSize',
                title: lang.ARCHIVE_SIZE,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.longTermRestorePointsSize} />,
            },
            {
                id: 'totalBackupSize',
                title: lang.TOTAL_BACKUP_SIZE,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.totalBackupSize} />,
            },
            {
                id: 'destination',
                title: capitalize(lang.BACKUP_REPOSITORY),
                hidden: true,
                cell: rowData => rowData.destination,
            },
            {
                id: 'shortTermRestorePointsCount',
                title: lang.RESTORE_POINTS,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => rowData.shortTermRestorePointsCount,
            },
            {
                id: 'longTermRestorePointsCount',
                title: lang.ARCHIVED_POINTS,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => rowData.longTermRestorePointsCount,
            },
            {
                id: 'lastRestorePoint',
                title: lang.LAST_RUN,
                cell: rowData => <TimeAgoColumn lang={lang} value={rowData.lastRestorePoint} />,
            },
            {
                id: 'lastSourceSize',
                title: lang.PROTECTED_FILES,
                cell: rowData => <RestorePointSizeColumn value={rowData.lastSourceSize} />,
            },
            {
                id: 'siteName',
                title: lang.SITE,
                hidden: true,
                cell: rowData => rowData.siteName,
            },
            {
                id: 'companyName',
                title: lang.COMPANY,
                cell: rowData => rowData.companyName,
            },
            {
                id: 'locationName',
                title: lang.LOCATION,
                hidden: true,
                cell: rowData => rowData.locationName,
            },
            {
                id: 'archiveDestination',
                title: lang.ARCHIVE_REPOSITORY,
                hidden: true,
                cell: rowData => rowData.archiveDestination,
            },
        ]
    ), [gridStateId, lang]);
};
