/**
 * Copyright © Veeam Software Group GmbH.
 */

import deepmerge from 'deepmerge';

export const mergeModels = <T,>(defaultModel: T, currentModel: T): T => deepmerge(
    defaultModel,
    currentModel,
    {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
    },
);
