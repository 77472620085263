/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';

import type { StreamFileData, FileTransportRequestOptions, FileTransportService } from '@veeam-vspc/shared/services';
import type { PortalEnumsAddon } from '@veeam-vspc/shared/addons';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { webControllersEnumsMap } from 'swagger/webControllers/webControllersEnumsMap';

import type { EnumMap } from 'swagger/webControllers/interfaces/enum-map';

export class ExtFileTransportService {

    protected fileTransportService: FileTransportService;
    protected portalEnums: PortalEnumsAddon;

    constructor(fileTransportService: FileTransportService, portalEnums: PortalEnumsAddon) {
        this.fileTransportService = fileTransportService;
        this.portalEnums = portalEnums;
    }

    uploadFormData(url: string, data: FormData, options: Partial<FileTransportRequestOptions> = {}) {
        return this.fileTransportService.uploadFormData(url, data, options);
    }

    uploadFilesByStream(
        url: string,
        files: StreamFileData[],
        options: Partial<FileTransportRequestOptions> = {},
    ): Promise<Array<RequestErrorResponse | Error | boolean>> {
        return this.fileTransportService.uploadFilesByStream(url, files, options);
    }

    downloadFile(fileUrl: string, options: Partial<FileTransportRequestOptions> = {}) {
        return this.fileTransportService.downloadFile(fileUrl, options);
    }

    downloadFileAjax<T>(fileUrl: string, data?: T, options: Partial<FileTransportRequestOptions> = {}) {
        return this.fileTransportService.downloadFileAjax(fileUrl, data, options);
    }

    downloadFileAjaxWithReplaceEnums<T>(
        fileUrl: string,
        data?: T,
        options?: FileTransportRequestOptions,
    ) {
        let clearUrl = fileUrl.replace(/\?.*$/, '').toLowerCase();

        const dataClone = deepCopy(data);

        if (clearUrl[0] !== '/') {
            clearUrl = `/${clearUrl}`;
        }

        if (webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].request) {
            const enumRequestMaps = webControllersEnumsMap[clearUrl].request;
            enumRequestMaps.forEach(property => this.replaceEnumsInRequestParams(dataClone, property, 0, fileUrl));
        }

        return this.fileTransportService.downloadFileAjax(fileUrl, dataClone, options);
    }

    protected replaceEnumsInRequestParams = (params: Object, enumMap: EnumMap, stepI = 0, url: string) => {
        const enumDescriptors = this.portalEnums.getEnumDescriptors();
        const isLastStep = stepI === enumMap.path.length - 1;
        const handleEnumValue = (obj: Object, propName: string|number) => {
            const enumValue = obj[propName] as number;
            if (typeof enumValue === 'number') {
                if (!enumDescriptors[enumMap.enumName]) {
                    console.error(`No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                } else {
                    const enumDescriptor = enumDescriptors[enumMap.enumName]
                        .find(enumDescriptorItem => enumDescriptorItem.intValue === enumValue);

                    if (enumDescriptor) {
                        obj[propName] = enumDescriptor.literalValue;
                    }
                }
            }
        };

        if (!params) {
            return;
        }

        if (isLastStep) {
            const pathValue = enumMap.path[stepI];
            if (pathValue === '$i') {
                (params as Array<string>).forEach((param, i) => handleEnumValue(params, i));
            } else {
                handleEnumValue(params, pathValue);
            }
        } else {
            const isArrayStep = enumMap.path[stepI] === '$i';
            if (isArrayStep) {
                const dataItemArray = params as Array<Object>;
                if (dataItemArray) {
                    dataItemArray.forEach(dataSubItem => this.replaceEnumsInRequestParams(dataSubItem, enumMap, stepI + 1, url));
                }
            } else {
                if (params) {
                    this.replaceEnumsInRequestParams(params[enumMap.path[stepI]], enumMap, stepI + 1, url);
                }
            }
        }
    };

}
