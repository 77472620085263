/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getParamValueFromUrl, safelyParseJson } from '@veeam-vspc/shared/core';

export const parseJsonFromQueryUrl = <T extends {}>(queryField: string): T => {
    const params = getParamValueFromUrl(queryField);

    if (!params) return null;

    return safelyParseJson(params);
};
