/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    GridSortDirections,
} from '@veeam-vspc/shared/components';

import type { BaseRequestData, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { MAX_INTEGER_32 } from 'core/const';
import { CloudTenantType } from 'core/enums';


import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

export interface CompanyRow {
    id: number;
    name: string;
    siteName: string;
    tenantType: CloudTenantType;
    cloudConnectServerUid: string;
}

export interface CompanyFilterModel {
    name: string;
}

export const CompanyGrid = ({ data, onDataChange }: WizardStepData<CompanyUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    return (
        <ConfiguredGrid
            columns={[
                {
                    id: 'name',
                    title: lang.COMPANY,
                    cell: rowData => rowData.name,
                },
                {
                    id: 'siteName',
                    title: lang.SITE,
                    cell: rowData => rowData.siteName,
                },
            ]}
            initialState={{
                sort: { key: 'name', direction: GridSortDirections.Asc },
                selected: data.companyID ? [{ id: data.companyID } as CompanyRow] : undefined,
                filters: {
                    name: data.companyFilterInitValue,
                },
            }}
            data={requestParams => transportService.request<typeof requestParams & BaseRequestData, RequestSuccessResponse<CompanyRow[]>>(
                '/Company/GetCompanies',
                { disableGlobalFilters: true, ...requestParams }
            )}
            toggleable={false}
            selection={{
                field: 'id',
                multiple: false,
                checkbox: false,
            }}
            onSelectionChange={(selected) => {
                const selectedItem = selected[0];
                data.companyID = selectedItem?.id;
                data.cloudAgentUid = selectedItem?.cloudConnectServerUid;

                if (selectedItem?.tenantType !== undefined) {
                    data.isVcdCompany = selectedItem?.tenantType === CloudTenantType.Vcd;
                }

                onDataChange(data);
            }}
            paginationLimit={MAX_INTEGER_32}
            toolbars={[
                () => (
                    <Toolbar
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='name'
                                        placeholder={lang.COMPANY_NAME}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
        />
    );
};
