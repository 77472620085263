/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    ConfiguredGrid,
    createLinkControl,
    SortingMode,
    TEXT_SIZE,
} from '@veeam-vspc/shared/components';
import { Combobox } from '@veeam/components';
import React from 'react';

import { PERIOD_OPTIONS } from './configs';
import { SpinnerWithLayout } from '../SpinnerWithLayout';
import { GridWrapper } from '../../styles';

import type { ComboboxItem } from '../MaxNumberOfRows/interfaces';
import type { TopsGridProps } from './interfaces';

export const TopsGrid = <T extends {}>(props: TopsGridProps<T>) => {
    const {
        api,
        columns,
        getGridData,
        height,
        isLoading,
        maxNumberOfRows,
        title,
        sectionId,
        shouldRenderPeriodSelect,
        period,
        handleChangePeriod,
    } = props;

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            gap={STACK_GAP.m}
        >
            <StackView
                direction={STACK_DIRECTION.row}
                style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '32px' }}
            >
                <Text size={TEXT_SIZE.l}>{title}</Text>

                {
                    shouldRenderPeriodSelect && (
                        <Combobox
                            controlRenderer={createLinkControl()}
                            value={period}
                            onChange={handleChangePeriod}
                            textGetter={(p: ComboboxItem) => p.text}
                            valueGetter={(p: ComboboxItem) => p.id}
                            data={PERIOD_OPTIONS}
                        />
                    )
                }
            </StackView>

            <GridWrapper height={height}>
                <SpinnerWithLayout isLoading={isLoading} />

                <ConfiguredGrid
                    api={api}
                    columns={columns}
                    data={getGridData}
                    defaultColumnMinWidth={80}
                    paginationLimit={maxNumberOfRows}
                    sectionId={sectionId}
                    sortingMode={SortingMode.Disabled}
                    toggleable={false}
                    isLoading={false}
                    style={{
                        minHeight: height,
                    }}
                />
            </GridWrapper>
        </StackView>
    );
};
