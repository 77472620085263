/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { colors, STACK_DIRECTION, StackView, useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { ChartTitleStyled } from 'views/components/ChartWidget/components/ChartTitle';
import { CurrentUsageContext } from './contexts';
import { CurrentUsageStore } from './stores';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { RoutePaths } from 'configs/enums';
import { getColumnOptions } from 'views/components/HighchartsWrapper/helpers';
import { useCurrentUsageStore } from './hooks';
import { useProductStore } from '../../../../hooks';
import { shouldDisableRedirectLink } from './helpers';

const CurrentUsageLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const store = useCurrentUsageStore();
    const { categories, empty, loading, series } = store;
    const { portalUser } = useGlobalAppData();
    const { type } = useProductStore();

    const pageRoutes = {
        [LicenseProductType.BackupServer]: 'servers',
        [LicenseProductType.CloudConnectServer]: 'cloudConnect',
        [LicenseProductType.Vbo]: 'vbo',
        [LicenseProductType.VOne]: 'veeamOne',
        [LicenseProductType.VspcEndpoint]: 'agents',
    };

    const params = {
        colors: [colors.Grn400, colors.Y450, colors.B250, colors.T500],
        multi: true,
        navigate,
        redirectUrl: shouldDisableRedirectLink(type, portalUser.userRole) ? null : `/${RoutePaths.Configs}/licensing/${pageRoutes[type]}`,
        stacked: true,
        stackedStyled: true,
        shouldCallOnDrawEvent: true,
    };

    const options = getColumnOptions({
        categories,
        height: 332,
        series,
        ...params,
    });

    useEffect(() => {
        store.fetchData({ productType: type, portalUser });
    }, [portalUser, store, type]);

    return (
        <StackView direction={STACK_DIRECTION.column} style={{ flex: 1 }}>
            <ChartTitleStyled>{lang.CURRENT_USAGE}</ChartTitleStyled>

            <HighchartsWrapper
                empty={empty}
                loading={loading}
                options={options}
            />
        </StackView>
    );
});

export const CurrentUsage = () => {
    const { transportService } = useGlobalServices();
    const store = useMemo(() => new CurrentUsageStore(transportService), []);

    return (
        <CurrentUsageContext.Provider value={store}>
            <CurrentUsageLayout />
        </CurrentUsageContext.Provider>
    );
};
