/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { SmtpSettings } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { CertVerificationStatus, HostIconTypeEnum } from '../enums';
import { getOAuthHostText } from './get-oauth-host-text';
import { getServerHostName } from './get-server-host-name';

import type { SmtpHostContent } from '../interfaces';

export const getSmtpHostContent = (
    smtpSettings: SmtpSettings,
    certVerificationStatus: CertVerificationStatus,
    lang: VspcLang
): SmtpHostContent => {
    const hasOAuth = Boolean(smtpSettings?.oAuth2Credential);
    const hasHost = Boolean(smtpSettings?.serverAddress);

    if (!hasHost) {
        return {
            iconType: HostIconTypeEnum.Warning,
            mainText: `${lang.NOT_SET_YET}...`,
            additionalText: '',
        };
    }

    if (hasOAuth) {
        return {
            iconType: HostIconTypeEnum.Success,
            mainText: getOAuthHostText(smtpSettings.oAuth2Credential, { google: lang.GOOGLE, azure: lang.AZURE }),
            additionalText: '',
        };
    }

    const hostName = getServerHostName(smtpSettings.serverAddress);

    switch (certVerificationStatus) {
        case CertVerificationStatus.NotVerified:
            return {
                iconType: HostIconTypeEnum.Error,
                mainText: hostName,
                additionalText: `(${lang.CANNOT_VERIFY_THE_REMOTE})`,
            };

        case CertVerificationStatus.NotVerifiedTrusted:
            return {
                iconType: HostIconTypeEnum.Warning,
                mainText: hostName,
                additionalText: `(${lang.THE_SECURITY_CERTIFICATE_IS_NOT})`,
            };

        case CertVerificationStatus.GettingInfo:
            return {
                iconType: HostIconTypeEnum.Loading,
                mainText: hostName,
                additionalText: `(${lang.GETTING_INFORMATION}...)`,
            };

        case CertVerificationStatus.Verified:
        default:
            return {
                iconType: HostIconTypeEnum.Success,
                mainText: hostName,
                additionalText: `(${lang.CONNECTION_HAS_BEEN_SUCCESSFULLY})`,
            };
    }
};
