/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { FIELD_LAYOUT_HELP_TEXT_VIEW, useGlobalLang } from '@veeam-vspc/shared/components';

import type { ExternalFormApi } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { DistributionType } from '../../../../enums';
import { RadioGroup } from 'components/controls/RadioGroup';

import type { VbrDeploySettingsWizardModel } from '../../../../entries';

export interface AutoLkRadioGroupProps {
    distributiveSize: VbrDeploySettingsWizardModel['distributiveSize'];
    distributionType: VbrDeploySettingsWizardModel['distributionType'];
    formApi: ExternalFormApi<VbrDeploySettingsWizardModel>;
}

export const AutoLkRadioGroup: React.FC<AutoLkRadioGroupProps> = ({ distributiveSize, distributionType, formApi }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <RadioGroup
            value={distributionType}
            onChange={(value: DistributionType) => {
                formApi.setValue('distributionType', value); // otherwise we don't see errors
            }}
            buttons={[
                {
                    label: `${formatStr(lang.DOWNLOAD_THE_BACKUP_SERVER_SETUP_FILE, distributiveSize)} (${lang.RECOMMENDED})`,
                    value: DistributionType.DownloadFromVeeamServers,
                    helpText: lang.THE_VEEAM_BACKUP_AND_REPLICATION_SETUP_FILE,
                    helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.labelDescription,
                },
                {
                    label: lang.USE_THE_BACKUP_SERVER_SETUP_FILE,
                    value: DistributionType.DownloadFromFileShare,
                    helpText: `${lang.SPECIFY_PATH_TO_THE_BACKUP_SERVER}:`,
                    helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.labelDescription,
                },
            ]}
        />
    );
};
