/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable } from 'mobx';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { delay } from '@veeam-vspc/shared/core';

import type { BeforeLeave } from '@veeam-vspc/shared/helpers';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';
import type { FileTransportService } from '@veeam-vspc/shared/services';

import { ServerErrorCodes } from 'core/enums';
import { NotificationBarsIds } from 'views/components/NotificationBar/enums/notification-bars-ids';

import type { NotificationBarStore } from 'views/components/NotificationBar/store';
import type { PatchUploadData } from '../interfaces';

export class DiscoveryBackupStore {
    protected readonly notificationBarId = NotificationBarsIds.PatchingVbr;

    lang: VspcLang;
    uploadedSessions: PatchUploadData[] = [];
    beforeLeave: BeforeLeave;
    patchUploadTarget: string;
    fileTransportService: FileTransportService;
    notificationBar: NotificationBarStore;

    constructor(
        lang: VspcLang,
        fileTransportService: FileTransportService,
        beforeLeave: BeforeLeave,
        notificationBar: NotificationBarStore,
    ) {
        makeObservable(this);
        this.lang = lang;
        this.beforeLeave = beforeLeave;
        this.lang = lang;
        this.patchUploadTarget = this.lang.REMOTE_COMPUTER.toLowerCase();
        this.fileTransportService = fileTransportService;
        this.notificationBar = notificationBar;
    }

    public uploadPatchBackupFiles(uploadUrl: string, uid: string, filesInfo) {
        const abortController = new AbortController();
        const fileUploaderOptions = {
            streamOptions: {
                progress: data => this.updatePatchUploadItems(uid, data.done),
                chunkSize: 1024 * 1024, // 1 mb in bytes
            },
            notShowDefaultError: true,
            requestInit: {
                signal: abortController.signal,
            },
        };
        const filesToUpload = filesInfo.map(fileInfo => (
            {
                blobFile: fileInfo.file,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ...(fileInfo.streamUid ? { customHeaders: { 'X-File-Uid': fileInfo.streamUid } } : {}),
            }
        ));

        this.addPatchUploadItem(uid, abortController);

        this.beforeLeave.show(this.notificationBarId);

        return this.fileTransportService.uploadFilesByStream(uploadUrl, filesToUpload, fileUploaderOptions)
            .then(() => this.updatePatchUploadItems(uid, 101)) // 101 means that download finished successfully
            .catch((err?: RequestErrorResponse | Error) => {
                this.updatePatchUploadItems(uid, null, err);

                return err ? Promise.reject(err) : Promise.resolve();
            });
    }

    @action.bound
    protected addPatchUploadItem(uid: string, abortController: AbortController) {
        this.uploadedSessions.push({ uid, progress: 0, abortController });
    }

    @action.bound
    protected updatePatchUploadItems(uid: string, progress: number, err?: RequestErrorResponse | Error): void {
        this.uploadedSessions.forEach((item) => {
            if (item.uid === uid && progress !== null) {
                item.progress = progress;
            } else if (item.uid === uid) {
                item.error = true;
                delete item.progress;
            }
        });

        this.refreshUploadingInfo(err);
    }

    @action.bound
    protected refreshUploadingInfo(err?: RequestErrorResponse | Error): void {
        const cancelAction = () => this.uploadedSessions.forEach(item => item.abortController?.abort());
        const hasError = this.uploadedSessions.some(item => item.error);
        const itemsWithProgress = this.uploadedSessions.filter(item => !item.error);
        const generalProgress = Math.floor(itemsWithProgress.reduce((res, item) => res + item.progress, 0) / (itemsWithProgress.length || 1));

        const isCancelled = (err as RequestErrorResponse)?.errors?.[0]?.code === ServerErrorCodes.Cancelled
            || (err as Error)?.name === 'AbortError';

        if (itemsWithProgress.length > 0 && generalProgress >= 0 && generalProgress <= 100) {
            // show uploading progress
            this.notificationBar.show({
                id: this.notificationBarId,
                text: formatStr(this.lang.UPLOADING_PATCH_FILES_TO_THE, this.patchUploadTarget, generalProgress),
                cancelAction,
            });
        } else if (generalProgress > 100 && hasError) {
            // show finish uploading with error
            this.notificationBar.show({
                id: this.notificationBarId,
                text: isCancelled
                    ? this.lang.THE_FILE_UPLOAD_OPERATION_HAS_BEEN
                    : formatStr(this.lang.FAILED_TO_UPLOAD_PATCH, this.patchUploadTarget),
                cancelAction,
            });

            this.hideUploadingInfo(2000);
        } else if (generalProgress > 100) {
            // show finish uploading
            this.notificationBar.show({
                id: this.notificationBarId,
                text: formatStr(this.lang.THE_SELECTED_PATCH_FILES_HAVE_BEEN, this.patchUploadTarget),
                cancelAction,
            });

            this.hideUploadingInfo(1000);
        } else if (itemsWithProgress.length === 0 && hasError) {
            // show uploading error
            this.notificationBar.show({
                id: this.notificationBarId,
                text: isCancelled
                    ? this.lang.THE_FILE_UPLOAD_OPERATION_HAS_BEEN
                    : formatStr(this.lang.FAILED_TO_UPLOAD_PATCH, this.patchUploadTarget),
                cancelAction,
            });

            this.hideUploadingInfo(2000);
        }
    }

    protected hideUploadingInfo(delayTime: number): void {
        this.beforeLeave.hide(this.notificationBarId);

        delay(delayTime)
            .then(() => {
                this.uploadedSessions = [];
                this.notificationBar.hide({
                    id: this.notificationBarId,
                });
            });
    }
}
