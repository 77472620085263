/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxKit, FieldLayout, Icon, STACK_GAP, StackView, Text } from '@veeam-vspc/shared/components';

import type { NotificationAlarmsSettingsDailyStatusFilter } from '@veeam-vspc/models/rest';
import type { IconType } from '@veeam-vspc/shared/components';

import { useAdvancedSettingsStore } from '../../stores';

export interface StatusCheckboxItem {
    checked: boolean;
    enumValue: NotificationAlarmsSettingsDailyStatusFilter;
    icon: IconType;
    label: string;
}

export interface StatusCheckboxesProps {
    items: StatusCheckboxItem[];
}

export const StatusCheckboxes: React.FC<StatusCheckboxesProps> = observer((props) => {
    const { items } = props;
    const advancedSettingsStore = useAdvancedSettingsStore();

    return (
        <>
            {items.map((item) => {
                const onChange = (isChecked: boolean): void => advancedSettingsStore.updateStatusFilter(item.enumValue, isChecked);

                return (
                    <FieldLayout key={item.enumValue}>
                        <CheckboxKit checked={item.checked} onChange={onChange}>
                            <StackView gap={STACK_GAP.s}>
                                <Icon src={item.icon} />
                                <Text>{item.label}</Text>
                            </StackView>
                        </CheckboxKit>
                    </FieldLayout>
                );
            }
            )}
        </>
    );
});
