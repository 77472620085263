/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';

import type { ActionLinkColumnProps, DialogAction, ParsedTextProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { getStatusIconSrc, getStatusTitle } from 'core/utils';

export interface StatusColumnProps extends ActionLinkColumnProps {
    status: string;
    statusIconSrc?: string;
    isTextHidden?: boolean;
    message?: ParsedTextProps['textOrConfig'];
    actions?: DialogAction[];
    isCorrectStatus?: boolean;
    messageBoxTitle?: string;
    priorityCellText?: string;
}

export const StatusColumn: React.FC<StatusColumnProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { status, statusIconSrc, isTextHidden, message, actions, isCorrectStatus = false, priorityCellText, messageBoxTitle, ...restProps } = props;
    const iconSrc = statusIconSrc ?? getStatusIconSrc(status);
    const title = isCorrectStatus ? status : getStatusTitle(lang, status);

    return (
        <ActionLinkColumn
            {...restProps}
            contentIconBefore={iconSrc}
            renderContent={() => (isTextHidden ? '' : <TextColumn style={{ color: 'inherit' }}>{priorityCellText || title}</TextColumn>)}
            isLink={message?.length > 0}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={messageBoxTitle || title}
                    onRequestClose={deactivate}
                    actions={actions ?? [
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                    iconSrc={iconSrc}
                >
                    <ParsedText textOrConfig={message} />
                </MessageBox>
            )}
        />
    );
};
