/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGridApi, SelectionMode } from '@veeam-vspc/shared/components';
import { processDecoratorsForHiddenByPriority } from '@veeam-vspc/shared/helpers';

import type { ToolbarGroupItem, ButtonTypeItem } from '@veeam-vspc/shared/components';

import type { UseDependentToolbarButtonData } from './interfaces';

export const useDependentToolbarButton = <RowDataT, TotalRowDataT, FilterT>(
    actionGroup: ToolbarGroupItem = null,
    actionItems: Array<ButtonTypeItem & { dependencies: ButtonTypeItem['decorators']; }> = [],
): UseDependentToolbarButtonData => {
    const { selected: selectedItems, filters, selectionMode } = useGridApi<RowDataT, TotalRowDataT, FilterT>();
    const items = actionItems.map(({ dependencies, ...restItem }) => ({
        ...restItem,
        ...processDecoratorsForHiddenByPriority(dependencies)({
            selectedItems: selectedItems as Record<string, unknown>[],
            filters,
            flags: {
                isAllPageMode: selectionMode === SelectionMode.AllPages,
            },
        }),
    }))
        .filter(item => !item.hidden);

    return {
        group: items.length > 1 ? actionGroup : null,
        items: items.length === 1
            ? items.map(({ groupId, text, iconSrc, ...restItem }) => ({
                ...restItem,
                id: groupId,
                text: actionGroup.title || text,
                iconSrc: actionGroup.iconSrc || iconSrc,
            }))
            : items.map(({ groupId, ...restItem }, index) => ({
                ...restItem,
                groupId: groupId,
                id: `${groupId}${index}`,
            })),
    };
};
