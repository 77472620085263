/**
 * Copyright © Veeam Software Group GmbH.
 */

import { QueryKeys } from '../../enums';

export const isSsoRedirect = (queryParams: URLSearchParams): boolean => {
    const ssoQueries = [QueryKeys.SsoBackendError, QueryKeys.SsoEmailCode, QueryKeys.SsoLoginState];

    return ssoQueries.some(ssoQuery => queryParams.get(ssoQuery));
};
