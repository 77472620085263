/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { ExtComponent } from '../../components/Ext/ExtComponent';

export const ClientRolesUsersPortalRulesPage: React.FC<{}> = () => {
    const xtype = 'clientPortal.rolesAndUsers.portalRules';

    return (
        <TabBarBodyLayout>
            <ExtComponent
                xtype={xtype}
            />
        </TabBarBodyLayout>
    );
};
