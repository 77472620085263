/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum FormFieldNames {
    Host = 'host',
    Port = 'port',
    UseAuth = 'useAuth',
    AuthType = 'authType',
    TlsMode = 'smtp.tlsMode',
    UserName = 'smtp.passwordCredential.userName',
    Password = 'smtp.passwordCredential.password',
    TenantId = 'smtp.oAuth2Credential.clientSettings.tenantId',
    ClientId = 'smtp.oAuth2Credential.clientSettings.clientId',
    MailServiceKind = 'smtp.oAuth2Credential.clientSettings.kind',
    ClientSecret = 'smtp.oAuth2Credential.clientSettings.clientSecret',
}
