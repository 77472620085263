/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors } from '@veeam-vspc/shared/components';

export const Separator = styled.div`
    background-color: ${colors.G300};
    width: 1px;
`;
