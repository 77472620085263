/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Text, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { ImageUpload } from '../ImageUpload';

export const Portal: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ImageUpload
            fileExt={'.png'}
            imageTitle={lang.BRANDING_PORTAL_TITLE}
            imageDescription={<Text>{lang.BRANDING_PORTAL_DESCRIPTION}</Text>}
            imageInfoUrl={'/Branding/GetPortalBranding'}
            getImageUrl={'/Branding/GetPortalBrandImage'}
            uploadImageUrl={'/Branding/UploadPortalBrandImage'}
            resetImageUrl={'/Branding/ResetPortalBranding'}
            imageSize={{
                width: 400,
                height: 32,
            }}
        />
    );
};
