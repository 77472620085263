/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum FlrStatuses {
    None = 0,
    Restoring = 1,
    Error = 2,
    Success = 3,
    Warning = 4,
}
