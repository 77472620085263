/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    ACTION_VIEW,
    useGlobalLang,
    TabBar,
    StackView,
    SPACE_FILL,
    INDENT,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VirtualServerTag, VirtualServerVirtualMachine } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { ContainersGrid } from '../ContainersGrid/ContainersGrid';
import { VMsGrid } from '../VMsGrid/VMsGrid';

interface ItemsModalProps {
    onApply: (containers: VirtualServerTag[], vms: VirtualServerVirtualMachine[]) => void;
    onCancel: () => void;
    availablePages: ('containers' | 'vms')[];
}

export const ItemsModal: FC<ItemsModalProps> = ({
    onCancel,
    onApply,
    availablePages,
}) => {
    const lang = useGlobalLang<VspcLang>();

    const [containers, setContainers] = useState<VirtualServerTag[]>([]);
    const [vms, setVms] = useState<VirtualServerVirtualMachine[]>([]);

    const apply = () => {
        onApply(
            containers,
            vms,
        );
    };

    const pages = {
        containers: lang.CONTAINER,
        vms: lang.VM,
    };

    const [activePage, selectPage] = useState(pages[availablePages[0]]);

    return (
        <Dialog
            header={capitalize(lang.ADD_OBJECTS)}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <TabBar
                items={
                    availablePages.map(p => ({
                        title: pages[p],
                        onClick: () => selectPage(pages[p]),
                        active: pages[p] === activePage,
                    }))
                }
                style={{ flexGrow: 0 }}
            />

            <StackView spaceFill={SPACE_FILL.all} spaceTop={INDENT.m}>
                { activePage === pages.containers && (
                    <ContainersGrid
                        onSelectionChange={containers => setContainers(containers)}
                        selected={containers}
                    />
                )}

                { activePage === pages.vms && (
                    <VMsGrid
                        onSelectionChange={vms => setVms(vms)}
                        selected={vms}
                    />
                )}
            </StackView>
        </Dialog>
    );
};
