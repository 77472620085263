/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CloudConnectConsumedPoints } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const CloudConnectServersByPoints: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings =
        useCommonSettings<CloudConnectConsumedPoints>({
            maxNumberOfRows,
            getDataRequestUrl: TopDataUrls.CloudConnectServersByPoints,
        });
    const { lang } = settings;

    const columns: GridColumnProps<CloudConnectConsumedPoints>[] = [
        {
            id: 'serverName',
            title: lang.SERVER,
            cell: rowData => rowData.serverName,
        },
        {
            id: 'totalPoints',
            title: lang.TOTAL_POINTS,
            cell: rowData => rowData.totalPoints,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'availablePoints',
            title: lang.AVAILABLE_POINTS,
            cell: rowData => rowData.availablePoints,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'usedPoints',
            title: lang.USED_POINTS,
            cell: rowData => rowData.usedPoints,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_CLOUD_CONNECT_SERVERS_BY_CONSUMED_POINTS}
            columns={columns}
            sectionId={ConfigSectionIds.TopsInfrastructure}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
