/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { BackupServices } from './components';

export interface TrendsBackupServersPageProps {
    currentPath?: string;
}

export const TrendsBackupServersPage: React.FC<TrendsBackupServersPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <BackupServices stateId={currentPath} />
    </TabBarBodyLayout>
);
