/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ExtendedStatuses {
    Complete = 'success',
    Completed = 'success',
    Generated = 'success',
    Healthy = 'success',
    Ready = 'success',
    Resolved = 'success',
    Scheduled = 'success',
    Success = 'success',
    Succeed = 'success',
    Applied = 'success',
    Verified = 'success',
    Active = 'success',

    Idle = 'info',
    Info = 'info',
    Information = 'info',
    Unknown = 'info',
    None = 'info',
    NotConfigured = 'info',
    NotRequested = 'info',

    Warning = 'warning',
    Unverified = 'warning',

    CancellationRequestFailed = 'error',
    Error = 'error',
    Failed = 'error',
    DeployFailed = 'error',
    Inaccessible = 'error',
    UndoFailed = 'error',
    Unavailable = 'error',

    InProgress = 'running',
    DeployInProgress = 'running',
    Installing = 'running',
    Running = 'running',
    UndoInProgress = 'running',
    Connecting = 'running',

    Canceled = 'stopped',
    Disabled = 'stopped',
    Disconnected = 'stopped',
    Stopped = 'stopped',
    NotRunning = 'stopped',

    Stopping = 'stopping',

    Cancelling = 'pending',
    Disabling = 'pending',
    Enabling = 'pending',
    Processing = 'pending',
    Pending = 'pending',
    Removing = 'pending',
    WaitingTape = 'pending',
    WaitingRepository = 'pending',
    Queued = 'pending',
    Upgrading = 'pending',
    Deleting = 'pending',
    Applying = 'pending',

    Starting = 'starting',
    Restarting = 'starting',

    Updating = 'updating',
    UpdateCredentials = 'updating',

    Created = 'created',

    Enabled = 'enabled',

    Acknowledged = 'acknowledge',

    Installed = 'backup-agent-type-installed',

    NotInstalled = 'backup-agent-type-not-installed',

    Unlicensed = 'unlicensed-backup-server',

    Syncing = 'syncing',
}
