/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    StackView,
    STACK_GAP,
    Combobox,
    STACK_DIRECTION,
    Form,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { UserRoles } from 'core/enums';
import { NoteBar } from 'components/layouts/NoteBar';
import { getAdminDocUrl } from '../../../Base/helpers/getAdminDocUrl';

import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';

export const Role: React.FC<WizardStepData<SSORuleModel>> = ({ data, validationState, onDataChange }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.ROLE}
                description={lang.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.role'
                        data={[
                            { value: UserRoles.PortalReadonlyOperator, text: lang.PORTAL_READONLY_OPERATOR },
                            { value: UserRoles.PortalOperator, text: lang.PORTAL_OPERATOR },
                            { value: UserRoles.PortalAdministrator, text: lang.PORTAL_ADMINISTRATOR },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={lang.ROLE}
                        inlineLabel
                    />

                    <NoteBar>
                        {data.model.role === UserRoles.PortalReadonlyOperator && <p>{lang.READ_ONLY_USERS_CAN_MONITOR_DATA}</p>}
                        {data.model.role === UserRoles.PortalOperator && <p>{lang.PORTAL_OPERATOR_CAN_PERFORM}</p>}
                        {data.model.role === UserRoles.PortalAdministrator && <p>{lang.PORTAL_ADMINISTRATOR_CAN_PERFORM}</p>}

                        <p>
                            {lang.CLICK}
                            {' '}

                            <a
                                href={getAdminDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.HERE}
                            </a>

                            {' '}
                            {lang.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Role {...data} />,
    id: 'role',
});
