/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, useGlobalAddons, useGlobalLang } from '@veeam-vspc/shared/components';
import { EConnectionStatus } from '@veeam-vspc/models/web-controllers';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import onlineIconSrc from 'images/filters/discovered-computer-connection-status/online.png';
import offlineIconSrc from 'images/filters/discovered-computer-connection-status/offline.png';
import rejectedIconSrc from 'images/filters/discovered-computer-connection-status/rejected.png';
import unverifiedIconSrc from 'images/filters/discovered-computer-connection-status/unverified.png';
import rebootingIconSrc from 'images/filters/discovered-computer-connection-status/rebooting.png';

export const ConnectionStatusColumn = ({ rowData }: { rowData: DiscoveredComputerModel; }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { status, note } = rowData;
    const iconStatusesSrc = {
        [EConnectionStatus.Online]: onlineIconSrc,
        [EConnectionStatus.Offline]: offlineIconSrc,
        [EConnectionStatus.Rejected]: rejectedIconSrc,
        [EConnectionStatus.Unverified]: unverifiedIconSrc,
        [EConnectionStatus.Rebooting]: rebootingIconSrc,
    };
    const statusText = portalEnums.getEnumDescription('EConnectionStatus', status);

    return (
        <ActionLinkColumn
            contentIconBefore={iconStatusesSrc[status]}
            renderContent={() => statusText}
            isLink={(status === EConnectionStatus.Offline || status === EConnectionStatus.Unverified) && !!note}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.COMPUTER_CONNECTION_STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                >
                    <ParsedText textOrConfig={note} />
                </MessageBox>
            )}
        />
    );
};
