/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { BackupServerJobDuration } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { DurationColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const JobsByDuration: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings = useCommonSettings<BackupServerJobDuration>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.JobsByDuration,
    });
    const { lang } = settings;

    const columns: GridColumnProps<BackupServerJobDuration>[] = [
        {
            id: 'name',
            title: capitalize(lang.JOB_NAME),
            cell: rowData => rowData.name,
        },
        {
            id: 'type',
            title: lang.JOB_TYPE,
            cell: rowData => rowData.type,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'serverName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.serverName,
            width: 175,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'duration',
            title: lang.DURATION,
            cell: rowData => <DurationColumnRender value={rowData.duration} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 175,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_VM_JOBS_BY_AVG_DURATION}
            columns={columns}
            sectionId={ConfigSectionIds.TopsInfrastructure}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
