/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { MonitoredObjectType } from '@veeam-vspc/models/web-controllers';

export class MonitoredObjectsStore {

    @observable monitoredObjectType: MonitoredObjectType;

    constructor(type: MonitoredObjectType, readonly lsStateId: string) {
        makeObservable(this);
        this.monitoredObjectType = type;
    }

    @action.bound
    setMonitoredObjectType(v: MonitoredObjectType): void {
        this.monitoredObjectType = v;
    }
}
