/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { useGlobalAddons, PortalSpinner } from '@veeam-vspc/shared/components';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';

import { BaseBackupPolicyColumn } from 'views/components/columns/BaseBackupPolicyColumn';
import { waitFor } from 'core/utils';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';

export interface BackupPolicyColumnProps {
    model: DiscoveredComputerModel;
}

export const BackupPolicyColumn: React.FC<BackupPolicyColumnProps> = ({ model }) => {
    const { portalEnums } = useGlobalAddons();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <BaseBackupPolicyColumn
                value={model.backupPolicyStr}
                commonVawTemplateStatus={model.commonVawTemplateStatus}
                multipleJobs={model.multipleJobs}
                isLink={!model.disallowJobsDrilldown}
                onClick={() => {
                    const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                    setLoading(true);

                    promise
                        .then(() => {
                            setLoading(false);

                            const agent = Ext.create('RCOP.model.managedBackupAgents.Agent', {
                                agentId: model.vacAgentGuid,
                                companyId: model.companyId,
                                agentName: model.hostName,
                                multijobSupport: model.multipleJobs,
                                systemType: portalEnums.getEnumIntValue('systemTypeRepresentation', model.systemType),
                            });
                            Ext.create('RCOP.components.dialogs.assignedBackupPolicies.AssignedBackupPolicies', {
                                record: {
                                    ...agent,
                                    get: prop => agent[prop],
                                },
                            }).open();
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }}
            />

            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
};
