/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { JobWizardStoreContext } from './job-wizard-store-context';

import type { JobWizardStore } from './job-wizard-store';

export const useJobWizardStore = (): JobWizardStore => useContext(JobWizardStoreContext);
