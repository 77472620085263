/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UrlProtocols } from '../enums';

import type { HostAndPort } from '../interfaces';

export const getHostAndPortFromURL = (urlString = ''): HostAndPort => {
    if (!urlString) {
        return {
            host: '',
            port: 25,
        };
    }

    // with SMTP protocol, instance of the 'new URL' class works incorrectly, that's why we need to replace the protocol
    const url = new URL(urlString.replace(UrlProtocols.Smtp, UrlProtocols.Https));
    const { hostname: host } = url;
    const port = +url.port || 25;

    return { host, port };
};
