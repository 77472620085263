/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, observable, makeObservable } from 'mobx';
import { BackupServicesType, TimeUnit } from '@veeam-vspc/models/web-controllers';

import { ReportingPeriodInfo } from '../interfaces';

export class FilterStore {

    @observable backupServicesType: BackupServicesType;
    @observable reportingPeriod: TimeUnit;

    constructor(period: TimeUnit, type: BackupServicesType) {
        makeObservable(this);
        this.reportingPeriod = period;
        this.backupServicesType = type;
    }

    @action.bound
    setBackupServicesType(backupServicesType: BackupServicesType): void {
        this.backupServicesType = backupServicesType;
    }

    @action.bound
    setReportingPeriod(timeUnit: TimeUnit): void {
        this.reportingPeriod = timeUnit;
    }

    @computed
    get reportingPeriodInfo(): ReportingPeriodInfo {
        return {
            aggregation: { unit: this.reportingPeriod },

            interval: {
                count: this.reportingPeriod === TimeUnit.Months ? 12 : 9,
                unit: this.reportingPeriod,
            },
        };
    }

}
