/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    TextInput,
    Textarea,
    CONTROL_SIZE,
    FormLayout,
    isValid,
    useExternalFormApi,
    useGlobalLang,
    Combobox,
    useGlobalAppData,
    createSearchableControl,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { IsBackupServerJobNameUniqueResponse } from '@veeam-vspc/models/web-controllers';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { PortalUser } from '@veeam-vspc/shared/stores';
import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { StepLayout } from 'components/layouts/StepLayout';
import { useJobWizardStore } from '../../stores';

import type { JobWizardStore } from '../../stores';

export const stepValidate = (portalUser: PortalUser, data: BackupServerBackupJobConfiguration): FormErrors<BackupServerBackupJobConfiguration> => {
    const validator = new FormValidator(data);

    validator
        .validate('name')
        .string()
        .required()
        .maxLength(125);
    validator
        .validate('description')
        .string()
        .maxLength(2048);

    if (portalUser.isResellerPortal() || portalUser.isAdminPortal()) {
        validator
            .validate('mappedOrganizationUid')
            .string()
            .required();
    }

    return validator.result();
};

export const getJobSettingsStep = (
    lang: VspcLang,
    store: JobWizardStore,
    portalUser: PortalUser,
    transportService: TransportService<RequestErrorResponse | Error>,
    notificationService: NotificationService,
): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: capitalize(lang.JOB_NAME),
    validate: () => {
        const valid = isValid(stepValidate.bind(null, portalUser), store.data);

        if (!valid) {
            return false;
        }

        return transportService.request<any, IsBackupServerJobNameUniqueResponse>('/backupserverjob/isuniquename', {
            name: store.data.name,
            backupServerUid: store.serverUid,
            excludeJobWithUid: store.data.instanceUid,
        })
            .then((resp: IsBackupServerJobNameUniqueResponse) => {
                if (resp.data === false) {
                    notificationService.warning(capitalize(lang.NAME), lang.BACKUP_JOB_WITH_THE_SPECIFIED_NAME_ALREADY_EXISTS);

                    return false;
                }
            });

    },
    render: data => <JobInfoStep {...data} />,
});

const JobInfoStep = ({ validationState, isEdit }: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useExternalFormApi();
    const wizardStore = useJobWizardStore();
    const { portalUser } = useGlobalAppData();

    const handleDataChange = (data: BackupServerBackupJobConfiguration) => {
        wizardStore.updateJobData(data);
    };

    return (
        <StepLayout
            title={capitalize(lang.JOB_NAME)}
            description={`${lang.SPECIFY_JOB_NAME_AND_ITS_DESCRIPTION}.`}
        >
            <Form
                value={wizardStore.data}
                validate={stepValidate.bind(null, portalUser)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={handleDataChange}
            >
                <FormLayout inlineLabel>
                    { (portalUser.isResellerPortal() || portalUser.isAdminPortal()) && (
                        <Combobox
                            name='mappedOrganizationUid'
                            value={wizardStore.mappedOrganizationUid}
                            label={lang.COMPANY}
                            data={wizardStore.companies}
                            valueGetter={x => x.companyUid}
                            textGetter={x => x.companyName}
                            size={CONTROL_SIZE.xl}
                            onChange={value => wizardStore.handleSelectCompany(value)}
                            controlRenderer={createSearchableControl()}
                            disabled={isEdit}
                        />
                    )}

                    <TextInput
                        name='name'
                        label={lang.NAME}
                        size={CONTROL_SIZE.xl}
                    />

                    <Textarea
                        name='description'
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.xl}
                        rows={8}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
