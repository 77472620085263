/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ValidationType {
    MainTag,
    Politics,
    RebootTag,
    LicenseTag,
    AllChecksPassed,
}
