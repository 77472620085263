/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { CurrentUsageService } from '../services';

export class CurrentUsageStore {
    @observable categories: string[];
    @observable empty: boolean;
    @observable loading: boolean;
    @observable series: [];

    constructor(protected transportService: TransportService<RequestErrorResponse | Error>) {
        makeObservable(this);
    }

    @action
    fetchData(params): void {
        this.setLoading(true);

        CurrentUsageService
            .fetchData(this.transportService, params)
            .then((data) => {
                this.setCategories(data.categories);
                this.setEmpty(data.empty);
                this.setSeries(data.series);
                this.setLoading(false);
            })
            .catch(() => {
                this.setLoading(false);
            });
    }

    @action.bound
    setCategories(categories: string[]): void {
        this.categories = categories;
    }

    @action.bound
    setEmpty(empty: boolean): void {
        this.empty = empty;
    }

    @action.bound
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action.bound
    setSeries(series): void {
        this.series = series;
    }

}
