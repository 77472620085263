/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormValidator,
    KeyCode,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
    useExternalFormApi,
    useGlobalLang,
    useGlobalServices,
    ValidationState,
} from '@veeam-vspc/shared/components';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { useAutoFocus } from '@veeam-vspc/shared/hooks';

import type { VspcLang } from 'configs/languages';

import { useEditUserProfileStore } from '../../../../stores';

interface SecretCodeDialogProps {
    deactivate: () => void;
}

interface SecretCodeData {
    code: string;
}

const formValidation = (lang: VspcLang, data: SecretCodeData) => {
    const validator = new FormValidator(data);

    validator
        .validate('code')
        .string()
        .required(lang.THIS_FIELD_IS_REQUIRED)
        .check((v) => {
            if (!/[0-9]{6}/.test(v)) {
                return lang.INVALID_VERIFICATION_CODE;
            }
        });

    return validator.result();
};

export const SecretCodeDialog: React.FC<SecretCodeDialogProps> = ({ deactivate }) => {
    const lang = useGlobalLang<VspcLang>();
    const editUserProfileStore = useEditUserProfileStore();
    const canvas = useRef(null);
    const { notificationService } = useGlobalServices();
    const [data] = useState<SecretCodeData>({
        code: '',
    });
    const formApi = useExternalFormApi<SecretCodeData>();
    const [validationState] = useState(new ValidationState());
    const [isFormValid, setIsFormValid] = useState(false);
    const inputCodeRef = useAutoFocus();

    useEffect(() => {
        QRCode.toCanvas(canvas.current, editUserProfileStore.totpSecret.secretUrl, { width: 225 });
    }, []);

    const onAddTotp = () => {
        notificationService.confirm(lang.SECRET_CODE, lang.PLEASE_CONFIRM_THAT_YOU_HAVE_INSTALLED, {
            onEnterNotificationDialogTextKey: NotificationDialogsTextKeys.Yes,
        })
            .then((btn) => {
                if (btn === NotificationDialogsTextKeys.Yes) {
                    return editUserProfileStore.addTotp({
                        userId: editUserProfileStore.data.instanceUid,
                        secretUrl: editUserProfileStore.totpSecret.secretUrl,
                        code: data.code,
                        description: lang.USE_GOOGLE_AUTHENTICATOR_APP,
                    })
                        .then(() => deactivate())
                        .then(() => notificationService.info(lang.GENERATE_THE_QR_CODE, lang.THE_SECRET_VERIFICATION_CODE, {
                            onEnterNotificationDialogTextKey: NotificationDialogsTextKeys.Ok,
                        }));
                }
            });
    };

    const onKeyPress = (event: KeyboardEvent) => {
        validationState.markAsCommon();
        if (event.key === KeyCode[KeyCode.Enter]) {
            if (formApi.validate()) {
                onAddTotp();
            } else {
                validationState.markAsForce();
            }
        }
    };

    return (
        <Dialog
            header={lang.SECRET_CODE}
            size={{
                height: DIALOG_SIZE.xl,
                width: DIALOG_SIZE.xs,
            }}
            actions={[
                {
                    text: lang.OK,
                    onClick: onAddTotp,
                    disabled: !isFormValid,
                },
            ]}
            onKeyPress={onKeyPress}
            onRequestClose={deactivate}
        >
            <Form
                value={data}
                externalFormApi={formApi}
                validate={data => formValidation(lang, data)}
                validationState={validationState}
                onChange={() => setIsFormValid(formApi.isValid())}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xl}>
                    <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                        <Text>{`${lang.SCAN_THE_QR_CODE}:`}</Text>

                        <StackView distribution={STACK_DISTRIBUTION.center}>
                            <canvas ref={canvas} width='225' height='225' />
                        </StackView>
                    </StackView>

                    <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                        <Text>{`${lang.ALTERNATIVELY_TYPE_IN_THE_SECRET_CODE}:`}</Text>
                        <Text>{editUserProfileStore.totpSecret.secret}</Text>
                    </StackView>

                    <TextInput
                        controlRef={inputCodeRef}
                        name={'code'}
                        label={lang.TYPE_IN_THE_RECEIVED_MFA}
                        maxLength={6}
                        size={CONTROL_SIZE.full}
                    />
                </StackView>
            </Form>
        </Dialog>
    );
};
