/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalAddons, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { CompanyTree, CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { reaction } from 'mobx';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ManagedCompaniesService } from './services/managed-companies.service';
import { NotApplicablePlate } from '../../../NotApplicablePlate';

export const ManagedCompanies: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { portalFilters } = useGlobalAddons();
    const [isNotApplicable, setIsNotApplicable] = useState(false);
    const { transportService } = useGlobalServices();

    const applicableToTypes = [
        CompanyTypeRepresentation.Provider,
        CompanyTypeRepresentation.Reseller,
        CompanyTypeRepresentation.ResellerContainer,
    ];

    const getGlobalCompanyFilterString = (company: CompanyTree | null) => company?.type ?? null;

    const params = {
        colors: [palette.B400],
        yTitle: lang.COMPANIES,
    };

    useEffect(() => {
        const type = getGlobalCompanyFilterString(portalFilters?.organizationFilter);

        setIsNotApplicable(type && !applicableToTypes.includes(type));
    }, []);

    useEffect(() => {
        if (portalFilters) {
            return reaction(
                () => [portalFilters.organizationFilter],
                () => {
                    const type = getGlobalCompanyFilterString(portalFilters.organizationFilter);

                    setIsNotApplicable(type && !applicableToTypes.includes(type));
                },
            );
        }
    }, [portalFilters]);

    return (
        <>
            {isNotApplicable && <NotApplicablePlate title={{ text: capitalize(lang.MANAGED_COMPANIES) }} />}

            {!isNotApplicable && (
                <BaseTrendChart
                    params={params}
                    service={new ManagedCompaniesService(transportService, lang)}
                    title={{ text: capitalize(lang.MANAGED_COMPANIES) }}
                />
            )}
        </>
    );
};
