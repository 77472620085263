/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface OverviewFile {
    Overview: ComponentType<any>;
}

export const OverviewPage = () => (
    <LazyLoadedComponent<OverviewFile>
        importPromise={() => (import('./components/Overview') as Promise<OverviewFile>)}
        pageName={'Overview'}
    />
);
