/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    FormValidator,
    isValid,
    Radiobutton,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ReportType } from '../../enums';

import type { ReportModel } from '../../interfaces';

export const stepValidate = (data: ReportModel): FormErrors<ReportModel> => {
    const validator = new FormValidator(data);

    return validator.result();
};

export const getReportTypeStep = (title: string): WizardStep<ReportModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    isHidden: ({ data, isEdit }) => {
        const isCdp = data.type === ReportType.ProtectedVms || data.type === ReportType.ProtectedVmsCdp;
        return isEdit === true || !isCdp;
    },
    render: data => <ReportTypeStep {...data} />,
});

const ReportTypeStep = ({ data, validationState, onDataChange }: WizardStepData<ReportModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const isCdp = data.type === ReportType.ProtectedVms || data.type === ReportType.ProtectedVmsCdp;

    return (
        <StepLayout
            title={capitalize(lang.REPORT_TYPE)}
            description={lang.SPECIFY_THE_REPORT_TYPE}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                onChange={onDataChange}
            >
                {isCdp && (
                    <FormLayout inlineLabel>
                        <Radiobutton
                            name='type'
                            value={ReportType.ProtectedVms}
                        >
                            {lang.RPO_BASED_REPORT}
                        </Radiobutton>

                        <Radiobutton
                            name='type'
                            value={ReportType.ProtectedVmsCdp}
                        >
                            {lang.SLA_BASED_REPORT}
                        </Radiobutton>
                    </FormLayout>
                )}
            </Form>
        </StepLayout>
    );
};
