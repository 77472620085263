/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { emailValidator } from '@veeam-vspc/shared/helpers';
import { resetPasswordFormInitialState, ResetPasswordForm, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { ResetPasswordFormState } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';
import { resetFormState } from '../../utils';

import type { ResetPasswordData } from '../../interfaces';

export const ResetPassword = observer(() => {
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const loginStore = useLoginStore();

    return (
        <BaseFactor width={'442px'}>
            <ResetPasswordForm
                title={lang.RESET_PASSWORD}
                onReturnButtonClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={(formData: ResetPasswordFormState) => {
                    loginStore.setIsLoading(true);

                    const data = {
                        ...formData,
                        returnUrl: '/login',
                    };

                    transportService.request<ResetPasswordData, {}>('/User/CreateResetPasswordRequest', data, {
                        noAuth: true,
                        notShowDefaultError: true,
                    })
                        .then(() => {
                            loginStore.onFinishFactorHandler({
                                goToLoginStart: true,
                                info: lang.A_PASSWORD_RESET_WAS_INITIATED_FOR_YOUR_ACCOUNT,
                            });
                            resetFormState(resetPasswordFormInitialState);
                        })
                        .catch((resp: RequestErrorResponse) => {
                            loginStore.setIsLoading(false);
                            loginStore.setError(resp.errors[0]?.message);
                        });
                }}
                texts={{
                    labelUsername: lang.USERNAME,
                    labelEmail: lang.EMAIL,
                    actionLogIn: lang.RESET,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    warningAboutReset: lang.CLICK_RESET_TO_RECEIVE,
                    returnButtonText: lang.BACK,
                    placeholderUserName: lang.USERNAME_TEXTFIELD_PLACEHOLDER,
                    placeholderEmail: lang.ENTER_EMAIL,
                }}
                customEmailValidation={(value: string) => emailValidator(value, {
                    multipleEmailsError: lang.ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED,
                    wrongEmailFormatError: lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS,
                })}
            />
        </BaseFactor>
    );
});
