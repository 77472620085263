/**
 * Copyright © Veeam Software Group GmbH.
 */

export type UserRoles = 'Unknown'
    | 'PortalAdministrator'
    | 'PortalOperator'
    | 'PortalReadonlyOperator'
    | 'CompanyLocationUser'
    | 'CompanyLocationAdministrator'
    | 'CompanyAdministrator'
    | 'CompanyInvoiceAuditor'
    | 'CompanySubtenant'
    | 'ResellerOperator'
    | 'ResellerUser'
    | 'ResellerInvoiceAuditor'
    | 'ResellerAdministrator'
    | 'CompanyOwner'
    | 'ResellerOwner';

export const UserRoles = {
    Unknown: 'Unknown' as UserRoles,
    PortalAdministrator: 'PortalAdministrator' as UserRoles,
    PortalOperator: 'PortalOperator' as UserRoles,
    PortalReadonlyOperator: 'PortalReadonlyOperator' as UserRoles,
    CompanyOwner: 'CompanyOwner' as UserRoles,
    CompanyLocationUser: 'CompanyLocationUser' as UserRoles,
    CompanyLocationAdministrator: 'CompanyLocationAdministrator' as UserRoles,
    CompanyAdministrator: 'CompanyAdministrator' as UserRoles,
    CompanyInvoiceAuditor: 'CompanyInvoiceAuditor' as UserRoles,
    CompanySubtenant: 'CompanySubtenant' as UserRoles,
    ResellerOwner: 'ResellerOwner' as UserRoles,
    ResellerOperator: 'ResellerOperator' as UserRoles,
    ResellerUser: 'ResellerUser' as UserRoles,
    ResellerInvoiceAuditor: 'ResellerInvoiceAuditor' as UserRoles,
    ResellerAdministrator: 'ResellerAdministrator' as UserRoles,
};
