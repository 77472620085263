/**
 * Copyright © Veeam Software Group GmbH.
 */
import {
    BackupServerBackupJobDailyKinds,
    BackupServerBackupJobDayNumberInMonth,
    BackupServerBackupJobPeriodicallyKindsNullable,
    DaysOfWeek,
    DaysOfWeekNullable,
    Month,
} from '@veeam-vspc/models/rest';

import type { BackupServerBackupJobSchedule } from '@veeam-vspc/models/rest';

export const getDefaultSchedule = (): BackupServerBackupJobSchedule => ({
    runAutomatically: false,
    daily: {
        isEnabled: true,
        localTime: '10:00',
        dailyKind: BackupServerBackupJobDailyKinds.Everyday,
        days: [
            DaysOfWeekNullable.Sunday,
            DaysOfWeekNullable.Monday,
            DaysOfWeekNullable.Tuesday,
            DaysOfWeekNullable.Wednesday,
            DaysOfWeekNullable.Thursday,
            DaysOfWeekNullable.Friday,
            DaysOfWeekNullable.Saturday,
        ],
    },
    monthly: {
        isEnabled: false,
        localTime: '10:00',
        dayOfWeek: DaysOfWeekNullable.Saturday,
        dayNumberInMonth: BackupServerBackupJobDayNumberInMonth.Fourth,
        dayOfMonth: 1,
        months: [
            Month.Jan,
            Month.Feb,
            Month.Mar,
            Month.Apr,
            Month.May,
            Month.Jun,
            Month.Jul,
            Month.Aug,
            Month.Sep,
            Month.Oct,
            Month.Nov,
            Month.Dec,
        ],
    },
    periodically: {
        isEnabled: false,
        periodicallyKind: BackupServerBackupJobPeriodicallyKindsNullable.Hours,
        frequency: 1,
        backupWindow: {
            days: [
                { day: DaysOfWeek.Sunday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Monday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Tuesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Wednesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Thursday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Friday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Saturday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
            ],
        },
        startTimeWithinAnHour: 0,
    },
    continuously: {
        isEnabled: false,
        backupWindow: {
            days: [
                { day: DaysOfWeek.Sunday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Monday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Tuesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Wednesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Thursday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Friday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Saturday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
            ],
        },
    },
    afterThisJob: {
        isEnabled: false,
    },
    retry: {
        isEnabled: false,
        retryCount: 3,
        awaitMinutes: 10,
    },
    backupWindow: {
        isEnabled: false,
        backupWindow: {
            days: [
                { day: DaysOfWeek.Sunday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Monday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Tuesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Wednesday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Thursday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Friday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
                { day: DaysOfWeek.Saturday, hours: '1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1' },
            ],
        },
    },
});
