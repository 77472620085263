/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    FieldLayout,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    Checkbox,
    CONTROL_SIZE,
    Text,
    LinkButton,
    Combobox,
    Radiobutton,
    usePageWizardStore,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import {
    Vb365CopyJobSchedulePolicyDailyType,
    Vb365CopyJobSchedulePolicyPeriodicallyEvery,
    Vb365CopyJobSchedulePolicySchedulePolicyType,
} from '@veeam-vspc/models/rest';

import type { Vb365CopyJob } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { TimeField } from 'components/controls/TimeField';
import { getPeriodicallyReadableValues } from '../../../../helpers';
import { ConfigureBackupWindow } from './components';

export const getScheduleStep = (lang: VspcLang, isScheduleHidden: boolean): WizardStep<Vb365CopyJob> => ({
    title: lang.SCHEDULE,
    isHidden: () => isScheduleHidden,
    render: data => <ScheduleStep {...data} />,
});

const ScheduleStep = ({ data, validationState, onDataChange }: WizardStepData<Vb365CopyJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore();

    const handleDataChange = (data: Vb365CopyJob) => {
        onDataChange(data);
    };

    return (
        <StepLayout
            title={lang.SCHEDULE}
            description={lang.CHOOSE_WHEN_YOU_WANT_BACKUP_JOB}
        >
            <Form
                value={data}
                validationState={validationState}
                onChange={handleDataChange}
            >
                <FormLayout>
                    <FieldLayout>
                        <Radiobutton
                            name='schedulePolicy.schedulePolicyType'
                            value={Vb365CopyJobSchedulePolicySchedulePolicyType.Immediate}
                        >
                            {lang.IMMEDIATELY}
                        </Radiobutton>
                    </FieldLayout>

                    <Text>{lang.COPIES_EVERY_RESTORE_POINT_CREATED_BY_SELECTED_JOB}</Text>

                    <FieldLayout suffix={(
                        <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.row}>
                            <FieldLayout
                                disabled={data.schedulePolicy.schedulePolicyType !== Vb365CopyJobSchedulePolicySchedulePolicyType.DailyAtTime}
                            >
                                <TimeField
                                    name='schedulePolicy.dailyTime'
                                    shortFormat
                                    minutesInterval={15}
                                    utc
                                />
                            </FieldLayout>

                            <FieldLayout
                                disabled={data.schedulePolicy.schedulePolicyType !== Vb365CopyJobSchedulePolicySchedulePolicyType.DailyAtTime}
                            >
                                <Combobox
                                    name='schedulePolicy.dailyType'
                                    data={[
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Everyday, text: lang.EVERYDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Workdays, text: lang.WORKDAYS },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Weekends, text: lang.WEEKENDS },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Monday, text: lang.MONDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Tuesday, text: lang.TUESDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Wednesday, text: lang.WEDNESDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Thursday, text: lang.THURSDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Friday, text: lang.FRIDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Saturday, text: lang.SATURDAY },
                                        { value: Vb365CopyJobSchedulePolicyDailyType.Sunday, text: lang.SUNDAY },
                                    ]}
                                    valueGetter={x => x.value}
                                    textGetter={x => x.text}
                                    autoSelectFirstItem
                                />
                            </FieldLayout>
                        </StackView>
                    )}
                    >
                        <Radiobutton
                            name='schedulePolicy.schedulePolicyType'
                            value={Vb365CopyJobSchedulePolicySchedulePolicyType.DailyAtTime}
                        >
                            {lang.DAILY_AT_THIS_TIME}
                        </Radiobutton>
                    </FieldLayout>

                    <FieldLayout
                        suffix={(
                            <StackView>
                                <FieldLayout
                                    disabled={data.schedulePolicy.schedulePolicyType !== Vb365CopyJobSchedulePolicySchedulePolicyType.Periodically}
                                >
                                    <Combobox
                                        name='schedulePolicy.periodicallyEvery'
                                        data={[
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes5,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes5],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes10,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes10],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes15,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes15],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes30,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Minutes30],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours1,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours1],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours2,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours2],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours4,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours4],
                                            },
                                            {
                                                value: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours8,
                                                text: getPeriodicallyReadableValues(lang)[Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours8],
                                            },
                                        ]}
                                        valueGetter={x => x.value}
                                        textGetter={x => x.text}
                                        size={CONTROL_SIZE.m}
                                        autoSelectFirstItem
                                    />
                                </FieldLayout>
                            </StackView>
                        )}
                    >
                        <Radiobutton
                            name='schedulePolicy.schedulePolicyType'
                            value={Vb365CopyJobSchedulePolicySchedulePolicyType.Periodically}
                        >
                            {lang.PERIODICALLY_EVERY}
                        </Radiobutton>
                    </FieldLayout>

                    <FieldLayout>
                        <Checkbox name='schedulePolicy.backupWindowEnabled'>
                            {lang.TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW}
                        </Checkbox>
                    </FieldLayout>

                    <LinkButton
                        onClick={() => wizardContext.openPanel(hide => <ConfigureBackupWindow hidePanel={hide} data={data} />)}
                        disabled={!data.schedulePolicy.backupWindowEnabled}
                    >
                        {lang.CONFIGURE_BACKUP_WINDOW}
                    </LinkButton>
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
