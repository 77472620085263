/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum Platform {
    All = -1,
    Local = 0,
    PublicCloud = 1,
    ObjectStorage = 2,
}
