/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { ProtectedFileServerModel, ProtectedFileServersFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { FileSharesLocalToolbar } from './components';
import { ConfigSectionIds } from 'core/enums';


export interface LocalProps {
    stateId: string;
}

export const FileSharesLocal: React.FC<LocalProps> = ({ stateId }) => {
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.Local`;

    const columns: GridColumnProps<ProtectedFileServerModel>[] = [
        {
            id: 'name',
            title: lang.SERVER,
            cell: rowData => (
                <SourceItemsColumn
                    value={rowData.name}
                    stateId={`${gridStateId}.Dialogs.SourceItems`}
                    name={rowData.name}
                    sourceItems={rowData.sourceItems}
                />
            ),
        },
        {
            id: 'jobName',
            title: lang.JOB,
            cell: rowData => rowData.jobName,
        },
        {
            id: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServerName,
        },
        {
            id: 'shortTermRestorePointsSize',
            title: lang.BACKUP_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.shortTermRestorePointsSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'longTermRestorePointsSize',
            title: lang.ARCHIVE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.longTermRestorePointsSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'totalBackupSize',
            title: lang.TOTAL_BACKUP_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.totalBackupSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'destination',
            title: capitalize(lang.BACKUP_REPOSITORY),
            cell: rowData => rowData.destination,
            hidden: true,
        },
        {
            id: 'shortTermRestorePointsCount',
            title: lang.RESTORE_POINTS,
            cell: rowData => rowData.shortTermRestorePointsCount,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'longTermRestorePointsCount',
            title: lang.ARCHIVED_POINTS,
            cell: rowData => rowData.longTermRestorePointsCount,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'lastRestorePoint',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastRestorePoint}
                />
            ),
        },
        {
            id: 'lastSourceSize',
            title: lang.PROTECTED_FILES,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.lastSourceSize}
                />
            ),
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'archiveDestination',
            title: lang.ARCHIVE_REPOSITORY,
            cell: rowData => rowData.archiveDestination,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.ProtectedDataFileSharesLocal}
            stateId={gridStateId}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: 'jobName',
                },
            }}
            defaultColumnMinWidth={100}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<ProtectedFileServersFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<ProtectedFileServerModel[]>>('/ProtectedWorkloads/GetFileShares', {
                    ...requestParams,
                })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesLocalToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
