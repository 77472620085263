/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { MAX_INTEGER_32 } from 'core/const';


import type { CompanyRow } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/company-row';
import type { CompanyFilterModel } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/company-filter-model';
import type { ManagedCompaniesProps } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/managed-companies-props';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<CompanyRow, any, CompanyFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.model.managedCompaniesUids?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.model.managedCompaniesUids = api.current.selected.map(({ companyInstanceUid }) => companyInstanceUid);

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <ConfiguredGrid
                initialState={{
                    selected: data.model.managedCompaniesUids?.map(companyInstanceUid => ({ companyInstanceUid })) as CompanyRow[],
                }}
                defaultColumnMinWidth={100}
                columns={[
                    {
                        id: 'name',
                        title: lang.COMPANY,
                        cell: rowData => rowData.name,
                    },
                    {
                        id: 'siteName',
                        title: lang.SITE,
                        cell: rowData => rowData.siteName,
                    },
                ]}
                data={(requestParams: RequestParams<CompanyFilterModel>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<CompanyRow[]>>(
                        '/Company/GetCompanies',
                        { disableGlobalFilters: true, ...requestParams }
                    )}
                toggleable={false}
                selection={{
                    field: 'companyInstanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='name'
                                            placeholder={lang.COMPANY_NAME}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
