/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Text, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

import { ImageUpload } from '../ImageUpload';

export const PortalGraphics: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ImageUpload
            fileExt={'.png'}
            imageTitle={lang.LOGIN_PAGE_GRAPHICS}
            imageDescription={<Text>{lang.THIS_IMAGE_WILL_BE_SHOWN_IN_THE_LOWER}</Text>}
            imageInfoUrl={'/Branding/GetPortalLoginImageBranding'}
            getImageUrl={'/Branding/GetPortalLoginImageBrandImage'}
            uploadImageUrl={'/Branding/UploadPortalLoginImageBrandImage'}
            resetImageUrl={'/Branding/ResetPortalLoginImageBranding'}
            imageSize={{
                width: 756,
                height: 450,
            }}
        />
    );
};
