/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { LinkButton, Notification, useGlobalLang } from '@veeam-vspc/shared/components';

import type { PropsWithChildren } from 'react';
import type { VspcLang } from 'configs/languages';

import helpIcon from 'images/view_help.png';
import hideIcon from 'images/hide.png';

export const ToggleInfo: React.FC<PropsWithChildren> = ({ children }) => {
    const lang = useGlobalLang<VspcLang>();
    const [isOpen, setIsOpen] = useState(false);
    const currentIcon = isOpen ? hideIcon : helpIcon;
    const currentText = isOpen ? lang.HIDE_HELP : lang.VIEW_HELP;

    return (
        <>
            <LinkButton
                iconBefore={currentIcon}
                onClick={() => setIsOpen(!isOpen)}
            >
                {currentText}
            </LinkButton>

            {isOpen && (
                <Notification>
                    {children}
                </Notification>
            )}
        </>
    );
};
