/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SizeColumn } from '@veeam-vspc/shared/components';
import React from 'react';

export const SizeColumnRender = ({ value }: { value: number; }) => (
    <SizeColumn value={value} pointFixed={1} />
);
