/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { useFullPage } from '@veeam-vspc/shared/hooks';
import {
    disableForSelectionWithPropValueCallback,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/shared/helpers';
import { Vb365JobJobType, Vb365JobLastStatus } from '@veeam-vspc/models/rest';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { addActionsParamsToQuery } from '@veeam-vspc/shared/core';

import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type {
    Vb365BackupJob,
    Vb365BackupProxy,
    Vb365BackupRepository,
    Vb365CopyJob,
    Vb365OrganizationBase,
    Vb365Job } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import createJobIconSrc from 'images/actions/add.svg';
import editJobIconSrc from 'images/actions/edit.svg';
import removeJobIconSrc from 'images/actions/remove.svg';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import backupJobIcon from 'images/filters/vb365-job-types/backup.svg';
import backupCopyJobIcon from 'images/filters/vb365-job-types/backup-copy.svg';
import { JobWizard } from '../JobWizard/JobWizard';
import { CopyJobWizard } from '../CopyJobWizard/CopyJobWizard';
import { useRestAsyncAction } from 'views/hooks';
import { vbObjectsService } from '../../services';
import { ConfigSectionIds } from 'core/enums';

export const VBObjectsActionToolbar: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const createJobGroupId = 'createJobGroupId';
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<Vb365Job, unknown, unknown>();
    const { notificationService } = useGlobalServices();

    const [asyncActionLoader, doAsyncAction] = useRestAsyncAction();

    const [
        backupJobWizardWrapper,
        { activate: activateBackupJobWizardWrapper },
    ] = useFullPage<{
        data?: Vb365BackupJob;
        organizations: Vb365OrganizationBase[];
        backupRepositories?: Vb365BackupRepository[];
        backupProxies?: Vb365BackupProxy[];
        edit?: boolean;
    }>({
        isExtMode: true,
        render: ({ onClose, data }) => (
            <JobWizard
                data={data?.data}
                organizations={data?.organizations}
                isEdit={data?.edit}
                onClose={onClose}
                onFinish={() => {
                    onClose();
                    gridApi.reloadGrid();
                }}
            />
        ),
    });

    const [
        copyJobWizardWrapper,
        { activate: activateCopyJobWizardWrapper },
    ] = useFullPage<{
        data?: Vb365CopyJob;
        edit?: boolean;
        sourceJob?: Vb365BackupJob;
        organizations: Vb365OrganizationBase[];
    }>({
        isExtMode: true,
        render: ({ onClose, data }) => (
            <CopyJobWizard
                data={data?.data}
                sourceJob={data?.sourceJob}
                organizations={data?.organizations}
                isEdit={data?.edit}
                onClose={onClose}
                onFinish={() => {
                    onClose();
                    gridApi.reloadGrid();
                }}
            />
        ),
    });

    return (
        <>
            <ToolbarWithExport
                RESTMethod='infrastructure/vb365Servers/organizations/jobs/'
                idProperty='instanceUid'
                lang={lang}
                selectedOnly
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByConsole}
                groups={[
                    {
                        id: createJobGroupId,
                        title: lang.CREATE_JOB,
                        iconSrc: createJobIconSrc,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => {
                            doAsyncAction.post(
                                addActionsParamsToQuery(
                                    '/infrastructure/vb365Servers/organizations/jobs/start/sync',
                                    gridApi.requestRESTParamsForActions as Record<string, string[]>
                                ),
                                lang.START,
                            );
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                disableForSelectionWithPropValueCallback('lastStatus', (value: Vb365JobLastStatus) => [
                                    Vb365JobLastStatus.Running,
                                    Vb365JobLastStatus.Disconnected,
                                ].includes(value)),
                                toolbarItemDecorators.disallowWithoutSelection(),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => {
                            doAsyncAction.post(
                                addActionsParamsToQuery(
                                    '/infrastructure/vb365Servers/organizations/jobs/stop/sync',
                                    gridApi.requestRESTParamsForActions as Record<string, string[]>
                                ),
                                lang.STOP,
                            );
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                disableForSelectionWithPropValueCallback('lastStatus', value => value !== Vb365JobLastStatus.Running),
                                toolbarItemDecorators.disallowWithoutSelection(),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: backupJobIcon,
                        text: lang.BACKUP_JOB,
                        onClick: async() => {
                            setLoading(true);

                            try {
                                const organizations = await vbObjectsService.loadOrganizations();

                                activateBackupJobWizardWrapper({ organizations });

                            } finally {
                                setLoading(false);
                            }
                        },
                        groupId: createJobGroupId,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: backupCopyJobIcon,
                        text: lang.BACKUP_COPY_JOB,
                        groupId: createJobGroupId,
                        onClick: async() => {
                            const job = gridApi.selected[0];

                            if (job.jobType !== Vb365JobJobType.BackupJob) {
                                return;
                            }

                            setLoading(true);

                            try {
                                const sourceJob = await vbObjectsService.loadBackupJobData(job);

                                const organizations = await vbObjectsService.loadOrganizations();

                                activateCopyJobWizardWrapper({ sourceJob, organizations });
                            } finally {
                                setLoading(false);
                            }
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                toolbarItemDecorators.disallowWithoutSingleSelection(),
                                disableForSelectionWithPropValueCallback('jobType', value => value !== Vb365JobJobType.BackupJob),
                                disableForSelectionWithPropValueCallback('isCopyJobAvailable', value => value === false),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: editJobIconSrc,
                        text: lang.EDIT,
                        onClick: async() => {
                            try {
                                setLoading(true);

                                const job = gridApi.selected[0];

                                const [
                                    organizations,
                                    backupRepositories,
                                    backupProxies,
                                ] = await Promise.all([
                                    vbObjectsService.loadOrganizations(),
                                    job.jobType === Vb365JobJobType.BackupJob
                                        ? vbObjectsService.loadAvailableBackupRepositories(job.vb365ServerUid, job.instanceUid)
                                        : vbObjectsService.loadBackupRepositories(job.vb365ServerUid),
                                    vbObjectsService.loadProxies(job.vb365ServerUid),
                                ]);

                                if (job.jobType === Vb365JobJobType.BackupJob) {
                                    const backupJob = await vbObjectsService.loadBackupJobData(job);

                                    activateBackupJobWizardWrapper({
                                        edit: true,
                                        data: backupJob,
                                        organizations,
                                        backupRepositories,
                                        backupProxies,
                                    });

                                    return;
                                }

                                if (job.jobType === Vb365JobJobType.CopyJob) {
                                    setLoading(true);

                                    const copyJob = await vbObjectsService.loadCopyJobData(job);
                                    const backupJob = await vbObjectsService.loadBackupJobData({
                                        vb365ServerUid: copyJob.vb365ServerUid,
                                        instanceUid: copyJob.sourceBackupJobUid,
                                    });

                                    activateCopyJobWizardWrapper({
                                        edit: true,
                                        data: copyJob,
                                        sourceJob: backupJob,
                                        organizations,
                                    });

                                    return;
                                }
                            } finally {
                                setLoading(false);
                            }

                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSingleSelection(),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        text: lang.REMOVE,
                        iconSrc: removeJobIconSrc,
                        onClick: () => {
                            notificationService.confirm(lang.REMOVE_JOB, lang.THIS_ACTION_WILL_REMOVE_ALL_SELECTED_JOBS)
                                .then((btnKey: NotificationResponse) => {
                                    if (btnKey === NotificationDialogsTextKeys.Yes) {
                                        doAsyncAction.delete(
                                            addActionsParamsToQuery(
                                                '/infrastructure/vb365Servers/organizations/jobs/sync',
                                                gridApi.requestRESTParamsForActions as Record<string, string[]>
                                            ),
                                            lang.REMOVE_JOB,
                                        );
                                    }
                                });
                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSelection(),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => {
                            doAsyncAction.post(
                                addActionsParamsToQuery(
                                    '/infrastructure/vb365Servers/organizations/jobs/enable/sync',
                                    gridApi.requestRESTParamsForActions as Record<string, string[]>
                                ),
                                lang.ENABLE,
                            );
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                toolbarItemDecorators.disallowWithoutSelection(),
                                disableForSelectionWithPropValueCallback('isEnabled', value => value === true),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => {
                            doAsyncAction.post(
                                addActionsParamsToQuery(
                                    '/infrastructure/vb365Servers/organizations/jobs/disable/sync',
                                    gridApi.requestRESTParamsForActions as Record<string, string[]>
                                ),
                                lang.DISABLE,
                            );
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                toolbarItemDecorators.disallowWithoutSelection(),
                                disableForSelectionWithPropValueCallback('isEnabled', value => value === false),
                            ]),
                        ],
                    },
                ]}
            />

            {loading && <PortalSpinner />}
            {asyncActionLoader}
            {backupJobWizardWrapper}
            {copyJobWizardWrapper}
        </>
    );
};
