/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { createRef, useEffect } from 'react';
import {
    CONTROL_SIZE,
    StackView,
    STACK_DIRECTION,
    TextInput,
    Text,
    TEXT_WEIGHT,
    TEXT_SIZE,
    SPACE_FILL,
    STACK_DISTRIBUTION,
} from '@veeam/components';
import styled, { css } from 'styled-components';
import { colors, SPACING_S, SPACING_XS } from '@veeam-vspc/shared/components';

import type { StackViewProps } from '@veeam/components';
import type { RenderControlInjectProps } from '@veeam/components/lib/controls/Dropdown/types';

import filterIconOff from 'images/icons/left-menu/filter_off.svg';
import filterIconOn from 'images/icons/left-menu/filter_on.svg';

const DropdownControlStyled = styled(StackView)<StackViewProps & { disabled: boolean; readonly: boolean; selected: boolean; }>`
    width: 100%;
    height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.G400};
    color: ${colors.G000};
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    ${({ disabled }) => disabled && css`
        border-color: ${colors.G500};
    `}

    ${({ selected }) => selected && css`
        border-color: ${colors.G500};
        background-color: ${colors.G500};
    `}

    ${({ readonly }) => readonly && css`
        border: none;
        cursor: pointer;
        padding: ${SPACING_XS};
        padding-left: ${SPACING_S};
        padding-right: 0;

        &:hover {
            background-color: ${colors.G500};

            * {
                color: ${colors.G000};
            }
        }
    `}
`;

const FilterButton = styled.button<{ hoverable: boolean; active: boolean; selected: boolean; }>`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    top: -1px;
    ${({ active, hoverable }) => (active && hoverable) && css`background-color: ${colors.B50};`}

    &:disabled {
        opacity: .6;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        mask-image: url(${({ selected }) => selected ? filterIconOn : filterIconOff });
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: ${({ selected }) => selected ? colors.G000 : colors.G400};
    }

    ${({ hoverable, disabled, theme }) => hoverable && !disabled && css`
        &:hover {
            background-color: ${colors.B50};

            &::before {
                background-color: ${theme.colors.colorAccent};
            }
        }
    `}

`;

const FilterInput = styled(TextInput)`
    background-color: transparent;
    border: unset;
    color: ${colors.G000};

    & input {
        text-overflow: ellipsis;
    }

    &:disabled {
        color: ${colors.G500};
    }
`;

const FilterValue = styled(Text)`
    color: ${colors.G000};
`;

const DropdownControlLabel = styled(Text)`
    color: ${colors.G400};
`;

interface DropdownControlProps {
    disabled: boolean;
    button: RenderControlInjectProps['button'];
    actions: RenderControlInjectProps['actions'];
    query: string;
    onQueryChange?: (query: string, show: () => void) => void;
    readonly?: boolean;
    label?: string;
    hoverable?: boolean;
    active?: boolean;
    selected?: boolean;
}

export const DropdownControl: React.FC<DropdownControlProps> = ({
    disabled,
    readonly,
    button,
    actions,
    query,
    onQueryChange,
    label,
    hoverable = true,
    active,
    selected,
}) => {
    const controlRef = createRef<HTMLInputElement>();

    useEffect(() => {
        controlRef.current?.style.setProperty('color', colors.G000);
    }, []);

    return (
        <DropdownControlStyled
            disabled={disabled}
            selected={selected}
            ref={button.ref}
            readonly={readonly}
            spaceFill={SPACE_FILL.all}
            distribution={STACK_DISTRIBUTION.firstFill}
            {...{ onClick: readonly ? actions.show : undefined }}
        >
            <StackView direction={STACK_DIRECTION.column} distribution={STACK_DISTRIBUTION.center}>
                {
                    label && (
                        <DropdownControlLabel
                            weight={TEXT_WEIGHT.bold}
                            size={TEXT_SIZE.s}
                        >
                            {label}
                        </DropdownControlLabel>
                    )
                }

                { readonly ?
                    <FilterValue asTag='div'>{query}</FilterValue> :
                    (
                        <FilterInput
                            value={query}
                            onChange={value => onQueryChange(value, actions.show)}
                            size={CONTROL_SIZE.full}
                            disabled={disabled}
                            controlRef={controlRef}
                        />
                    )}
            </StackView>

            <FilterButton
                onClick={() => actions.toggle()}
                disabled={disabled}
                hoverable={hoverable}
                active={active}
                selected={selected}
            />
        </DropdownControlStyled>
    );
};
