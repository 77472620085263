/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    immediatelyValidateFlag,
    TextInput,
    useExternalFormApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { useAutoFocus } from '@veeam-vspc/shared/hooks';

import type { VspcLang } from 'configs/languages';

import type { SetCustomNoteState } from '../../interfaces';

const noteInputName = 'note';

export interface SetCustomNoteDialogProps {
    deactivate: () => void;
    onSubmit: (note: string) => void;
    value: string | null;
}

export const SetCustomNoteDialog: React.FC<SetCustomNoteDialogProps> = ({ deactivate, onSubmit, value }) => {
    const lang = useGlobalLang<VspcLang>();
    const noteInput = useAutoFocus();
    const formApi = useExternalFormApi<SetCustomNoteState>();
    const stepValidate = (data: SetCustomNoteState) => {
        const validator = new FormValidator(data);

        validator.validate(noteInputName)
            .string()
            .maxLength(256, `${lang.THE_MAXIMUM_LENGTH_IS}${immediatelyValidateFlag}`);

        return validator.result();
    };
    const customNoteFormInitialState: SetCustomNoteState = {
        [noteInputName]: value,
    };
    const saveNote = (note: string) => {
        const result = note?.trim() || '';

        if (formApi.isValid()) {
            onSubmit(result);
        }
    };

    return (
        <Dialog
            header={lang.ASSIGN_TAG}
            title={lang.SPECIFY_TAG_FOR_MANAGED}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.APPLY, onClick: () => saveNote(formApi.value[noteInputName]) },
                { text: lang.CANCEL, onClick: deactivate, view: ACTION_VIEW.secondary },
            ]}
        >
            <Form
                value={customNoteFormInitialState}
                validate={stepValidate}
                externalFormApi={formApi}
                onSubmit={(noteState: SetCustomNoteState) => saveNote(noteState[noteInputName])}
                fields={[
                    { name: noteInputName, ref: noteInput },
                ]}
            >
                <FormLayout>
                    <TextInput
                        data-tid={`SetCustomNoteDialog_${noteInputName}`}
                        name={noteInputName}
                        controlRef={noteInput}
                        placeholder={''}
                        size={CONTROL_SIZE.full}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
