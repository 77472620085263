/**
 * Copyright © Veeam Software Group GmbH.
 */


import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import type { IsItemExistsParams } from 'core/interfaces';

export class CommonService {
    static isItemExist(transportService: TransportService<RequestErrorResponse | Error>, reqData: IsItemExistsParams): Promise<boolean> {
        return transportService.request<IsItemExistsParams, RequestSuccessResponse<boolean>>('/Common/CheckItemExist', reqData)
            .then(({ data }: RequestSuccessResponse<boolean>) => data);
    }
}
