/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    FormLayout,
    HelpText,
    INDENT,
    Label,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../stores';
import { FormFieldNames } from '../../enums';
import { TimeField } from 'components/controls';
import { getNotificationLevelFlags } from '../../helpers';
import { Suffix } from '../Suffix';

export const DiscoveryRulesSettingsGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formStore: { defaultInputSize, defaultTimeInputSize, formData: { level } } } = useConfigurationStore();
    const { noEmails, summaryEmails } = getNotificationLevelFlags(level);

    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
            <StackView direction={STACK_DIRECTION.column} indentBottom={INDENT.s}>
                <Label>{lang.DISCOVERY_RULES}</Label>
                <HelpText>{`${lang.SPECIFY_EMAIL_SETTINGS_DISCOVERY}:`}</HelpText>
            </StackView>

            <FormLayout inlineLabel disabled={noEmails}>
                <TextInput
                    label={lang.FROM}
                    maxLength={512}
                    name={FormFieldNames.DiscoveryFrom}
                    size={defaultInputSize}
                />

                <TextInput
                    label={lang.TO}
                    maxLength={512}
                    name={FormFieldNames.DiscoveryTo}
                    size={defaultInputSize}
                />

                <TextInput
                    disabled={summaryEmails}
                    label={lang.SUBJECT}
                    maxLength={512}
                    name={FormFieldNames.DiscoverySubject}
                    size={defaultInputSize}
                    suffix={(<Suffix variables={'%company%, %location%,  %ruleName%, %ruleStatus%.'} />)}
                />
            </FormLayout>

            <TimeField
                disabled={!summaryEmails}
                inlineLabel
                label={lang.SEND_NOTIFICATION_DAILY_AT_THIS_TIME}
                name={FormFieldNames.DiscoveryDailyTime}
                shortFormat
                size={defaultTimeInputSize}
                utc
            />
        </StackView>
    );
});
