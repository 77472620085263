/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum FormFieldNames {
    AlarmsFrom = 'alarms.from',
    AlarmsTo = 'alarms.to',
    AlarmsDailySubject = 'alarms.dailySubject',
    AlarmsDailyTime = 'alarms.dailyTime',
    BillingFrom = 'billing.from',
    BillingSubject = 'billing.subject',
    DefaultFrom = 'defaultFrom',
    DiscoveryFrom = 'discovery.from',
    DiscoveryTo = 'discovery.to',
    DiscoverySubject = 'discovery.subject',
    DiscoveryDailyTime = 'discovery.dailyTime',
    Level = 'level',
}
