/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface AlarmsFile {
    Alarms: ComponentType<any>;
}

export const ActiveAlarmsPage = () => (
    <LazyLoadedComponent<AlarmsFile>
        importPromise={() => (import('./components/Alarms') as Promise<AlarmsFile>)}
        pageName={'Alarms'}
    />
);
