/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BodyLayout, useGlobalAddons } from '@veeam-vspc/shared/components';

import { ExtComponent } from '../../components/Ext/ExtComponent';

export const CloudConnectServersPage: React.FC<{}> = () => {
    const xtype = 'rcop.configuration.ccservers.grid';
    const { portalFilters } = useGlobalAddons();

    return (
        <BodyLayout>
            <ExtComponent
                xtype={xtype}
                extProps={{
                    updateGlobalFilters: () => {
                        portalFilters.updatePortalFilters();
                    },
                }}
            />
        </BodyLayout>
    );
};
