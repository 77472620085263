/**
 * Copyright © Veeam Software Group GmbH.
 */

import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';

import type { VbrDeploySettingsModel } from '@veeam-vspc/models/web-controllers';

import { DeploymentScheduleType, DistributionType, VbrDeployType } from '../enums';
import { readAsText } from 'core/utils/file-helpers';

import type {
    Product,
    VbrLicenseParams,
    VbrWizardProps,
} from '../interfaces';

export class VbrDeploySettingsWizardModel {
    adminUserName: VbrDeploySettingsModel['adminUserName'];
    adminPassword: VbrDeploySettingsModel['adminPassword'];
    autoLkConfigured: boolean;
    distribution: VbrDeploySettingsModel['distribution'];
    distributionType: DistributionType;
    installationParametersFile: FileList | undefined;
    organizationUid: string;
    licenseFile: FileList | undefined;
    licenseFileContent: VbrDeploySettingsModel['licenseFileContent'];
    licenseId: VbrDeploySettingsModel['licenseId'];
    licenseSource: VbrDeploySettingsModel['licenseSource'];
    tenantName: VbrDeploySettingsModel['tenantName'];
    tenantPassword: VbrDeploySettingsModel['tenantPassword'];
    isPulseConfigured: boolean;
    isVcdTenant: boolean;
    distributiveFileName: string;
    distributiveSize: number;
    vbrDeployType: VbrDeployType;
    vbrLicenseParams: VbrLicenseParams;
    productUids: string[];
    products: Product[];
    stopAllActivities: VbrDeploySettingsModel['stopAllActivities'] = false;
    autoRebootAllowed: VbrDeploySettingsModel['autoRebootAllowed'] = false;
    deploymentSchedule: DeploymentScheduleType = DeploymentScheduleType.UpgradeNow;
    scheduleDate: Date;
    isInfrastructureAgent: boolean;

    constructor(props: VbrWizardProps) {
        this.autoLkConfigured = props.autoLkConfigured;
        this.tenantName = props.tenantName;
        this.isPulseConfigured = props.pulseConfigured;
        this.isVcdTenant = props.isVcdTenant;
        this.organizationUid = props.organizationUid;
        this.licenseSource = props.pulseConfigured ? VbrDeploymentLicenseSourceType.Pulse : VbrDeploymentLicenseSourceType.LicenseFile;
        this.distributiveFileName = props.distributiveFileName;
        this.distributionType = props.autoLkConfigured ? DistributionType.DownloadFromVeeamServers : DistributionType.DownloadFromFileShare;
        this.distributiveSize = props.distributiveSize;
        this.vbrDeployType = props.vbrDeployType;
        this.vbrLicenseParams = props.vbrLicenseParams;
        this.products = props.products;
        this.productUids = props.products && props.products.length > 0 && [props.products[0].value];
        this.scheduleDate = new Date();
        this.scheduleDate.setHours(this.scheduleDate.getHours() + 1);
        this.isInfrastructureAgent = props.isInfrastructureAgent;
    }

    async serialize(): Promise<VbrDeploySettingsModel> {
        if (this.vbrDeployType === VbrDeployType.Install) {
            const isLicenseFromPulse = this.licenseSource === VbrDeploymentLicenseSourceType.Pulse;
            const isLicenseFromLicFile = this.licenseSource === VbrDeploymentLicenseSourceType.LicenseFile;
            const licenseFile = isLicenseFromLicFile ? await readAsText(this.licenseFile[0]) : undefined;

            return {
                distribution: this.distributionType === DistributionType.DownloadFromFileShare ? this.distribution : undefined,
                answerXml: await readAsText(this.installationParametersFile[0]),
                licenseFileContent: licenseFile,
                licenseId: isLicenseFromPulse ? this.licenseId : undefined,
                licenseSource: this.licenseSource,
                tenantName: this.tenantName,
                tenantPassword: this.tenantPassword,
                stopAllActivities: this.stopAllActivities,
                autoRebootAllowed: this.autoRebootAllowed,
                adminUserName: this.adminUserName,
                adminPassword: this.adminPassword,
                scheduleModel: this.deploymentSchedule === DeploymentScheduleType.ScheduleUpgrade ? {
                    date: this.scheduleDate.toJSON(),
                } : undefined,
            };
        }

        if (this.vbrDeployType === VbrDeployType.Upgrade) {
            return {
                distribution: this.distributionType === DistributionType.DownloadFromFileShare ? this.distribution : undefined,
                answerXml: await readAsText(this.installationParametersFile[0]),
                stopAllActivities: this.stopAllActivities,
                autoRebootAllowed: this.autoRebootAllowed,
                adminUserName: this.adminUserName,
                adminPassword: this.adminPassword,
                scheduleModel: this.deploymentSchedule === DeploymentScheduleType.ScheduleUpgrade ? {
                    date: this.scheduleDate.toJSON(),
                } : undefined,
            };
        }
    }
}
