/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Vb365CopyJobSchedulePolicyDailyType,
    Vb365CopyJobSchedulePolicyPeriodicallyEvery,
    Vb365CopyJobSchedulePolicySchedulePolicyType,
} from '@veeam-vspc/models/rest';

import type { Vb365CopyJob } from '@veeam-vspc/models/rest';

export const copyJobDefaults: Vb365CopyJob = {
    sourceBackupJobUid: '',
    repositoryUid: '',
    schedulePolicy: {
        schedulePolicyType: Vb365CopyJobSchedulePolicySchedulePolicyType.Immediate,
        periodicallyEvery: Vb365CopyJobSchedulePolicyPeriodicallyEvery.Hours1,
        dailyType: Vb365CopyJobSchedulePolicyDailyType.Everyday,
        backupWindowEnabled: false,
        backupWindowSettings: {
            backupWindow: Array.from({ length: 168 }, () => true),
            minuteOffset: 0,
        },
        dailyTime: '07:00',
    },
};
