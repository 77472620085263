/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { RolesAndUsersWizardType } from './enums';
import { AdminSSORule } from './components/AdminSSORule';
import { AdminUser } from './components/AdminUser';
import { CompanySSORule } from './components/CompanySSORule';
import { CompanyUser } from './components/CompanyUser';
import { SPSSORule } from './components/SPSSORule';
import { SPUser } from './components/SPUser';

import type { CompanyUserProps } from './components/CompanyUser/interfaces';
import type { AdminUserProps } from './components/AdminUser/interfaces';
import type { SSORuleProps } from './components/Base/interfaces/sso-rule-props';
import type { SPUserProps } from './components/SPUser/interfaces';
import type { RolesAndUsersProps } from './interfaces';

export const ROLES_AND_USERS_PAGE = 'RolesAndUsersPage';

export const RolesAndUsersPage: React.FC<RolesAndUsersProps> = (props) => {
    switch (props.wizard) {
        case RolesAndUsersWizardType.AdminSSORule:
            return <AdminSSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.AdminUser:
            return <AdminUser {...props as AdminUserProps} />;
        case RolesAndUsersWizardType.CompanySSORule:
            return <CompanySSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.CompanyUser:
            return <CompanyUser {...props as CompanyUserProps} />;
        case RolesAndUsersWizardType.SPSSORule:
            return <SPSSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.SPUser:
            return <SPUser {...props as SPUserProps} />;
    }
};
