/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum TopDataUrls {
    DownloadTraffic = '/TopCompanies/GetTopCompaniesByDownloadTraffic',
    FileBackups = '/TopCompanies/GetTopCompaniesByFileBackups',
    ManagedBackupAgents = '/TopCompanies/GetTopCompaniesByManagedBackupAgents',
    Microsoft365Groups = '/TopCompanies/GetTopCompaniesByVbm365ProtectedGroups',
    Microsoft365Users = '/TopCompanies/GetTopCompaniesByVbm365ProtectedUsers',
    MonitoredWorkloads = '/TopCompanies/GetTopCompaniesByMonitoredWorkloads',
    ProtectedDataWorkloads = '/TopCompanies/GetTopCompaniesByProtectedCloudWorkloads',
    QuotaUsage = '/TopCompanies/GetTopCompaniesByQuotaUsage',
    ReplicatedVMs = '/TopCompanies/GetTopCompaniesByReplicatedVmsToTheCloud',
    StoredVMs = '/TopCompanies/GetTopCompaniesByStoredVms',
    Traffic = '/TopCompanies/GetTopCompaniesByTraffic',
    TransferredData = '/TopCompanies/GetTopCompaniesByTransferredData',

    BackupServersByConsumedPoints = '/TopInfrastructure/GetTopBackupServersByConsumedPoints',
    JobsByDuration = '/TopInfrastructure/GetTopJobsByDuration',
    JobsProcessedObjects = '/TopInfrastructure/GetTopJobsByProcessedObjects',
    UsedRepositories = '/TopInfrastructure/GetTopUsedRepositories',

    CloudConnectServersByPoints = '/TopInfrastructure/GetTopCloudConnectServersByConsumedPoints',
    CloudConnectServersByTenants = '/TopInfrastructure/GetTopCloudConnectServersByTenants',
    UsedCloudRepositories = '/TopInfrastructure/GetTopUsedCloudRepositories',
    UsedHardwarePlansByCompanies = '/TopInfrastructure/GetTopUsedHardwarePlansByCompanies',
}
