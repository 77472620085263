/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface SupportInformationFile {
    SupportInformation: ComponentType<any>;
}

export const ProductUpdatesPage = () => (
    <LazyLoadedComponent<SupportInformationFile>
        importPromise={() => (import('./components/SupportInformation') as Promise<SupportInformationFile>)}
        pageName={'SupportInformation'}
    />
);
