/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/shared/components';
import { emailValidator, emailOrUserGroupListValidator } from '@veeam-vspc/shared/helpers';

import type { FormErrors } from '@veeam-vspc/shared/components';

import { FormFieldNames } from '../enums';

import type { ConfigurationFormData } from '../interfaces';

export interface ErrorTexts {
    emailFormat: string;
    emailGroupFormat: string;
    emailMultipleValues: string;
}

export const formValidate = (formData: ConfigurationFormData, errorTexts: ErrorTexts): FormErrors<ConfigurationFormData> => {
    const validator = new FormValidator(formData);
    const validateEmail = (email: string) => (
        emailValidator(email, {
            multipleEmailsError: errorTexts.emailMultipleValues,
            wrongEmailFormatError: errorTexts.emailFormat,
        })
    );

    const validateEmailGroup = (emails: string) => (
        emailOrUserGroupListValidator(emails, { wrongFormatError: errorTexts.emailGroupFormat })
    );

    validator
        .validate(FormFieldNames.DefaultFrom)
        .string()
        .check(v => v && validateEmail(v));

    validator
        .validate(FormFieldNames.AlarmsFrom)
        .string()
        .check(v => v && validateEmail(v));

    validator
        .validate(FormFieldNames.AlarmsTo)
        .string()
        .check(v => v && validateEmailGroup(v));

    validator
        .validate(FormFieldNames.AlarmsDailySubject)
        .string()
        .required();

    validator
        .validate(FormFieldNames.AlarmsDailyTime)
        .date()
        .required();

    validator
        .validate(FormFieldNames.DiscoveryFrom)
        .string()
        .check(v => v && validateEmail(v));

    validator
        .validate(FormFieldNames.DiscoveryTo)
        .string()
        .check(v => v && validateEmailGroup(v));

    validator
        .validate(FormFieldNames.DiscoverySubject)
        .string()
        .required();

    validator
        .validate(FormFieldNames.DiscoveryDailyTime)
        .date()
        .required();

    validator
        .validate(FormFieldNames.BillingFrom)
        .string()
        .check(v => v && validateEmail(v));

    validator
        .validate(FormFieldNames.BillingSubject)
        .string()
        .required();

    return validator.result();
};
