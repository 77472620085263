/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    useGlobalLang,
    useGlobalServices,
    ComboboxKit,
    CONTROL_SIZE,
    createSearchableControl,
    GridSortDirections,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridStore } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { CompanyInfoShort, CompanyParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { useAsyncAction } from 'views/hooks';

interface AssignCompanyDialogProps {
    deactivate: () => void;
    method: string;
    gridApi: GridStore<any, any, any>;
}

export const AssignCompanyDialog: React.FC<AssignCompanyDialogProps> = ({
    deactivate,
    gridApi,
    method,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<CompanyInfoShort[]>([]);
    const [companyId, setCompanyId] = useState<string>(null);

    const requestData = (params: CompanyParam) => transportService
        .request<CompanyParam, RequestSuccessResponse<CompanyInfoShort[]>>('/Agent/GetCompaniesMoveTo', params)
        .then(({ data }: RequestSuccessResponse<CompanyInfoShort[]>) => data);

    useEffect(() => {
        requestData({
            sort: [{ property: 'name', direction: GridSortDirections.Asc }],
        })
            .then((data) => {
                setData(data);
                setLoading(false);
            });
    }, []);


    return (
        <Dialog
            loading={loading}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        doAsyncAction(
                            method,
                            capitalize(lang.JOB_ASSIGNMENT),
                            {
                                ...gridApi.requestParamsForActions,
                                companyId,
                            }
                        )
                            .then(() => gridApi.reloadGrid())
                            .then(deactivate);
                    },
                    disabled: !companyId,
                },
                { text: lang.CANCEL, onClick: deactivate, view: ACTION_VIEW.secondary },
            ]}
            header={capitalize(lang.JOB_ASSIGNMENT)}
            onRequestClose={deactivate}
            size={{ height: DIALOG_SIZE.auto, width: DIALOG_SIZE.auto }}
            title={lang.SELECT_AN_EXISTING_COMPANY_TO_ASSIGN_THE_JOB_TO}
            description={lang.TYPE_THE_COMPANY_NAME_IN_THE_FIELD_FOR_ADVANCED_SEARCH_OPTIONS}
        >
            <ComboboxKit<CompanyInfoShort, string>
                data={data}
                virtualListLimit={50}
                value={companyId}
                onChange={id => setCompanyId(id)}
                controlRenderer={createSearchableControl()}
                valueGetter={x => x.id}
                textGetter={x => x.siteName ? `${x.name} (${x.siteName})` : x.name}
                size={CONTROL_SIZE.full}
            />

            {asyncActionLoader}
        </Dialog>
    );
};
