/**
 * Copyright © Veeam Software Group GmbH.
 */

import get from 'lodash/get';
import set from 'lodash/set';
import {
    debounce,
    throttle,
    getDevelopmentHeaders,
    AppStorage,
    generateGuid,
    generateRandomValue,
    htmlEncode,
    htmlDecode,
    getStrBetween,
    copyTextToClipboard,
} from '@veeam-vspc/shared/helpers';
import {
    TimeUnitsInMs,
    makeTwoDigit,
    makePercentFromNumber,
    deepEqual,
    deepCopy,
    safelyParseJson,
    delay,
    getParamValueFromUrl,
    addOriginToUrl,
    addParamsToQuery,
    downloadBlob,
} from '@veeam-vspc/shared/core';
import { observe } from 'fast-json-patch';

import type { CoreModuleAddons } from '@veeam-vspc/shared/core-modules';
import type { AppDataStore } from '@veeam-vspc/shared/stores';
import type { AppServices } from '@veeam-vspc/shared/interfaces';

import { baseUrl } from '../../consts';
import {
    calcRoundingPercents,
    getHelpResourceLink,
    getExtProductTheme,
    getReactProductTheme,
    markdownToHtml,
    sanitizeComplexObject,
    sanitize,
    dataToFormData,
} from 'core/utils';
import { ExtTransportService } from '../ext-transport/ext-transport.service';
import { CompaniesPage } from 'views/pages/CompaniesPage';
import { extRouteProviderStore } from 'views/providers/ExtRouteProvider/store/ext-route-provider-store';
import { ExtFileTransportService } from '../ext-file-transport/ext-file-transport.service';

export class ReactToExtService {
    private extTransportService: ExtTransportService;
    private extFileTransportService: ExtFileTransportService;

    private components: Record<string, any> = {
        CompaniesPage,
    };

    private utils: Record<string, any> = {
        sanitize,
        debounce,
        throttle,
        makeTwoDigit,
        makePercentFromNumber,
        deepEqual,
        deepCopy,
        safelyParseJson,
        delay,
        getParamValueFromUrl,
        prepareUrl: addOriginToUrl,
        getDevelopmentHeaders,
        addParamsToQuery,
        download: downloadBlob,
        AppStorage,
        generateGuid,
        generateRandomValue,
        htmlEncode,
        htmlDecode,
        getStrBetween,
        observe,
        copyTextToClipboard,
        calcRoundingPercents,
        sanitizeComplexObject,
        getHelpResourceLink,
        lodash: { get, set },
        baseUrl,
        TimeUnitsInMs,
        markdownToHtml,
        getExtProductTheme,
        getReactProductTheme,
        dataToFormData,
    };

    constructor(
        protected readonly services: AppServices,
        protected appDataStore: AppDataStore,
        protected addons: CoreModuleAddons,
    ) {}

    renderTo(componentName: string, props: Record<string, any> = {}): void {
        extRouteProviderStore.update(componentName, props);
    }

    unmount(): void {
        extRouteProviderStore.update('', {});
    }

    getComponent(name: string): (props: any) => JSX.Element {
        if (!this.components[name]) {
            throw Error(`Failed to find ${name} component`);
        }

        return this.components[name];
    }

    getPortalData(): AppDataStore {
        return this.appDataStore;
    }

    getService(name: string): any {
        if (name === 'enumMapperService') {
            return this.addons.portalEnums;
        }

        if (name === 'transportService') {
            if (!this.extTransportService) {
                this.extTransportService = new ExtTransportService(this.services.transportService, this.addons.portalEnums);
            }

            return this.extTransportService;
        }

        if (name === 'fileTransportService') {
            if (!this.extFileTransportService) {
                this.extFileTransportService = new ExtFileTransportService(this.services.fileTransportService, this.addons.portalEnums);
            }

            return this.extFileTransportService;
        }

        return this.services[name];
    }

    getUtils(name: string): any {
        return this.utils[name];
    }

}
