/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { WizardSummary, useGlobalLang } from '@veeam-vspc/shared/components';
import { IdentityProviderRoleMappingRuleRole } from '@veeam-vspc/models/rest';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { LocationMode } from '../Locations/location-mode';
import { ManagedCompaniesMode } from '../../../AdminUser/enums';

import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';

export const Summary = ({ data }: WizardStepData<SSORuleModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const rolesEnum = IdentityProviderRoleMappingRuleRole;
    const roleMap = {
        [rolesEnum.CompanyOwner]: lang.COMPANY_OWNER,
        [rolesEnum.CompanyAdministrator]: lang.COMPANY_ADMINISTRATOR,
        [rolesEnum.CompanyLocationAdministrator]: lang.LOCATION_ADMINISTRATOR,
        [rolesEnum.CompanyLocationUser]: lang.LOCATION_USER,
        [rolesEnum.CompanyInvoiceAuditor]: lang.COMPANY_INVOICE_AUDITOR,
        [rolesEnum.ResellerOwner]: lang.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR,
        [rolesEnum.ResellerAdministrator]: lang.SERVICE_PROVIDER_ADMINISTRATOR,
        [rolesEnum.ResellerOperator]: lang.SERVICE_PROVIDER_OPERATOR,
        [rolesEnum.ResellerUser]: lang.SERVICE_PROVIDER_USER,
        [rolesEnum.ResellerInvoiceAuditor]: lang.SERVICE_PROVIDER_INVOICE_AUDITOR,
    };

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_RULE_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${lang.RULE_INFO}:`,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: data.model.name,
                            },
                        ],
                    },
                    {
                        title: `${lang.ROLE}:`,
                        fields: [
                            {
                                label: `${lang.ROLE}:`,
                                value: roleMap[data.model.role],
                            },
                        ],
                    },
                    data.model.role === rolesEnum.ResellerAdministrator && {
                        title: `${lang.COMPANIES}:`,
                        fields: [
                            {
                                label: `${lang.COMPANIES}:`,
                                value: lang.ALL,
                            },
                        ],
                    },
                    (data.model.role === rolesEnum.ResellerUser || data.model.role === rolesEnum.ResellerOperator) && {
                        title: `${lang.COMPANIES}:`,
                        fields: [
                            {
                                label: `${lang.COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                                    ? data.model.managedCompaniesUids.length
                                    : lang.ALL,
                            },
                        ],
                    },
                    {
                        title: `${lang.LOCATIONS}:`,
                        fields: [
                            {
                                label: data.locationMode === LocationMode.UseLocationClaim ? `${lang.LOCATIONS_CLAIM}:` : `${lang.LOCATIONS}:`,
                                value: data.locationMode === LocationMode.UseLocationClaim
                                    ? data.model.locationsMappingSourceClaimType
                                    : lang.DEFAULT_LOCATION,
                            },
                        ],
                    },
                    {
                        title: `${lang.CONDITIONS}:`,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: data.model.organizationMappingSourceClaimType,
                            },
                            {
                                label: `${lang.PARAMETERS}:`,
                                value: data.model.additionalMappings?.length ?? '0',
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Summary {...data} />,
    id: 'summary',
});
