/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import {
    DurationColumn,
    GridColumnJustify,
    TimeAgoColumn,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { BackupServerObjectStorageBackupJobModel } from '@veeam-vspc/models/web-controllers';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { BackupFileJobStatusColumn, ObjectStorageColumn } from '../components';

type ColumnsContentType = GridColumnProps<BackupServerObjectStorageBackupJobModel>[];

export const useColumns = (gridStateId: string): ColumnsContentType => {
    const lang = useGlobalLang<VspcLang>();

    return useMemo((): ColumnsContentType => (
        [
            {
                id: 'statusName',
                title: lang.JOB_STATUS,
                cell: rowData => (
                    <BackupFileJobStatusColumn
                        status={rowData.status}
                        statusName={rowData.statusName}
                        lastSessionEndTime={rowData.lastSessionEndTime}
                        lastSessionUid={rowData.lastSessionUid}
                    />
                ),
            },
            {
                id: 'name',
                title: lang.JOB,
                cell: rowData => rowData.name,
            },
            {
                id: 'backupServerName',
                title: capitalize(lang.BACKUP_SERVER),
                cell: rowData => rowData.backupServerName,
            },
            {
                id: 'companyName',
                title: lang.COMPANY,
                cell: rowData => rowData.companyName,
            },
            {
                id: 'siteName',
                title: lang.SITE,
                cell: rowData => rowData.siteName,
                hidden: true,
            },
            {
                id: 'locationName',
                title: lang.LOCATION,
                hidden: true,
                cell: rowData => rowData.locationName,
            },
            {
                id: 'objectStorages',
                title: lang.OBJECT_STORAGE,
                cell: rowData => (
                    <ObjectStorageColumn
                        value={rowData.objectStorages}
                        jobType={rowData.jobType}
                        name={rowData.name}
                        instanceUid={rowData.instanceUid}
                        stateId={`${gridStateId}.Dialogs.ObjectStorages`}
                    />
                ),
            },
            {
                id: 'jobTypeName',
                title: lang.JOB_TYPE,
                cell: rowData => rowData.jobTypeName,
            },
            {
                id: 'sourceAmountOfData',
                title: lang.SOURCE_SIZE,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.sourceAmountOfData} />,
            },
            {
                id: 'backedUpFilesCount',
                title: lang.BACKED_UP_FILES,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => (
                    <ObjectStorageColumn
                        value={rowData.backedUpFiles}
                        jobType={rowData.jobType}
                        name={rowData.name}
                        instanceUid={rowData.instanceUid}
                        stateId={`${gridStateId}.Dialogs.BackupUpFiles`}
                    />
                ),
            },
            {
                id: 'destination',
                title: lang.DESTINATION,
                cell: rowData => rowData.destination,
            },
            {
                id: 'lastSessionStartTime',
                title: lang.LAST_RUN,
                cell: rowData => <TimeAgoColumn lang={lang} value={rowData.lastSessionStartTime} />,
            },
            {
                id: 'longTermDestination',
                title: lang.ARCHIVE_REPOSITORY,
                hidden: true,
                cell: rowData => rowData.longTermDestination,
            },
            {
                id: 'lastSessionDuration',
                title: lang.DURATION,
                hidden: true,
                cell: rowData => <DurationColumn value={rowData.lastSessionDuration} />,
            },
            {
                id: 'avgDuration',
                title: lang.AVG_DURATION,
                hidden: true,
                cell: rowData => <DurationColumn value={rowData.avgDuration} />,
            },
            {
                id: 'processingRate',
                title: lang.PROCESSING_RATE,
                hidden: true,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => (
                    <RestorePointSizeColumn value={rowData.processingRate} unitName={'B/s'} />
                ),
            },
            {
                id: 'transferred',
                title: lang.TRANSFERRED_DATA,
                hidden: true,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.transferred} />,
            },
            {
                id: 'scheduleState',
                title: lang.SCHEDULE,
                hidden: true,
                cell: rowData => rowData.scheduleState,
            },
            {
                id: 'bottleneck',
                title: lang.BOTTLENECK,
                hidden: true,
                cell: rowData => rowData.bottleneck,
            },
        ]
    ), [gridStateId, lang]);
};
