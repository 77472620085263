/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum RolesAndUsersWizardType {
    AdminUser,
    AdminSSORule,
    CompanyUser,
    CompanySSORule,
    SPUser,
    SPSSORule,
}
