/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/shared/components';
import { isPositiveNumber } from '@veeam-vspc/shared/helpers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { FsPublicCloudsBackupDialog } from './components/FsPublicCloudsBackupDialog/FsPublicCloudsBackupDialog';

import type { FsPublicCloudsBackupDialogProps } from './components/FsPublicCloudsBackupDialog/FsPublicCloudsBackupDialog';

export interface FsPublicCloudsBackupColumnProps extends Omit<FsPublicCloudsBackupDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const FsPublicCloudsBackupColumn: React.FC<FsPublicCloudsBackupColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <FsPublicCloudsBackupDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

