/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useCallback } from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CompanyBackupAgents, CompanyTraffic } from '@veeam-vspc/models/web-controllers';

import { TopDataUrls } from '../../../../enums';
import { useCommonSettings, usePeriodsStore } from '../../../../hooks';
import { TopsGrid } from '../../../TopsGrid';
import { SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';
import { GridName } from '../../../../stores';

import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const Traffic: React.FC<TopsGridComponentWrapper> = observer(({ maxNumberOfRows }) => {
    const { setPeriod, periodTopCompaniesByTraffic } = usePeriodsStore();

    const settings = useCommonSettings<CompanyBackupAgents>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.Traffic,
        shouldRenderPeriodSelect: true,
        period: periodTopCompaniesByTraffic,
    });
    const { lang } = settings;

    const columns: GridColumnProps<CompanyTraffic>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'uploadTraffic',
            title: lang.UPLOAD_TRAFFIC,
            cell: rowData => <SizeColumnRender value={rowData.uploadTraffic} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'downloadTraffic',
            title: lang.DOWNLOAD_TRAFFIC,
            cell: rowData => <SizeColumnRender value={rowData.downloadTraffic} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'total',
            title: lang.TOTAL,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            cell: (rowData) => {
                const uploadTraffic = rowData.uploadTraffic;
                const downloadTraffic = rowData.downloadTraffic;
                const totalTraffic =
                    (uploadTraffic || 0) + (downloadTraffic || 0);

                return <SizeColumnRender value={totalTraffic} />;
            },

        },
    ];

    const handleChangePeriod = useCallback((periodValue: number): void => {
        setPeriod(periodValue, GridName.TopCompaniesByTraffic);
    }, [setPeriod]);

    return (
        <TopsGrid
            title={lang.TOP_COMPANIES_BY_TRAFFIC}
            columns={columns}
            sectionId={ConfigSectionIds.TopsCompanies}
            maxNumberOfRows={maxNumberOfRows}
            handleChangePeriod={handleChangePeriod}
            {...settings}
        />
    );
});
