/**
 * Copyright © Veeam Software Group GmbH.
 */

import { RequestGridSortDirections, type FetchMethod, type GridStore, type RequestParams } from '@veeam-vspc/shared/components';
import { NotificationDefaultTitles, NotificationService } from '@veeam-vspc/shared/services';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { VirtualServerTag, VirtualServerVirtualMachine } from '@veeam-vspc/models/rest';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { alternateTransformRequestParams } from 'core/utils';
import { ServerErrorCodes } from 'core/enums';

const genericEmptyResponse: RequestSuccessResponse<{}> = {
    data: [],
    meta: {
        pagingInfo: {
            total: 0,
            count: 0,
            offset: 0,
        },
    },
};

class ScopesResourcesService {
    transportService?: TransportService<RequestErrorResponse | Error> = {
        get() {
            throw new Error('TransportService is undefined');
        },
    } as unknown as TransportService<RequestErrorResponse | Error>;
    notificationService?: NotificationService;

    init(
        transportService: TransportService<RequestErrorResponse | Error>,
        notificationService: NotificationService,
    ) {
        this.transportService = transportService;
        this.notificationService = notificationService;
    }

    abortController = new AbortController();

    abortPreviousRequest() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    showInfo(err) {
        const message = err.errors.length > 1 ? err.errors.map(error => ({
            text: error.message,
            listItem: true,
        })) : err.errors[0].message;

        this.notificationService.info(NotificationService.texts[NotificationDefaultTitles.Information], message);
    }

    showError(err) {
        const message = err.errors.length > 1 ? err.errors.map(error => ({
            text: error.message,
            listItem: true,
        })) : err.errors[0].message;

        this.notificationService.error(NotificationService.texts[NotificationDefaultTitles.Error], message);
    }

    getContainers(
        backupServerUid: string,
        virtualCenterUid: string,
        companyUid: string,
        gridApi: GridStore<VirtualServerTag, unknown, unknown>
    ): FetchMethod<VirtualServerTag, unknown> {
        return (params: RequestParams<unknown> & { nameSortingDirection?: string; }) => {
            this.abortPreviousRequest();

            if (params.sort && params.sort[0].property === 'name') {
                params.nameSortingDirection = params.sort[0].direction === RequestGridSortDirections.Asc ? 'ascending' : 'descending';
                params.sort.shift();
            }

            return this.transportService
                .get<any, RequestSuccessResponse<VirtualServerTag[]>>(
                    `/infrastructure/backupServers/${backupServerUid}/servers/virtualCenter/${virtualCenterUid}/tags`,
                    {
                        companyUid,
                        ...alternateTransformRequestParams(params),
                    },
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                        notShowDefaultError: true,
                    },
                )
                .then((response: RequestSuccessResponse<VirtualServerTag[]>) => response)
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }
                    // @TODO remove after multi action for rest will be added
                    const isInfo = Array.isArray(err.errors)
                        ? err.errors.some(e => e.code === ServerErrorCodes.RetryLater)
                        : err.code === ServerErrorCodes.RetryLater;
                    isInfo ? this.showInfo(err) : this.showError(err);

                    return genericEmptyResponse as RequestSuccessResponse<VirtualServerTag[]>;
                });
        };
    }

    getVMs(
        serverUid: string,
        hostUid: string,
        vmTag: string,
        gridApi: GridStore<VirtualServerVirtualMachine, unknown, unknown>
    ): FetchMethod<VirtualServerVirtualMachine, unknown> {
        return (params: RequestParams<unknown> & { nameSortingDirection?: string; }) => {
            this.abortPreviousRequest();

            if (params.sort && params.sort[0].property === 'name') {
                params.nameSortingDirection = params.sort[0].direction === RequestGridSortDirections.Asc ? 'ascending' : 'descending';
                params.sort.shift();
            }

            return this.transportService
                .get<{}, RequestSuccessResponse<VirtualServerVirtualMachine[]>>(
                    `/infrastructure/backupServers/${serverUid}/servers/virtualCenter/${hostUid}/tags/${vmTag}/virtualMachines`,
                    alternateTransformRequestParams(params),
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                        notShowDefaultError: true,
                    },
                )
                .then((response: RequestSuccessResponse<VirtualServerVirtualMachine[]>) => response)
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    // @TODO remove after multi action for rest will be added
                    const isInfo = Array.isArray(err.errors)
                        ? err.errors.some(e => e.code === ServerErrorCodes.RetryLater)
                        : err.code === ServerErrorCodes.RetryLater;
                    isInfo ? this.showInfo(err) : this.showError(err);

                    return genericEmptyResponse as RequestSuccessResponse<VirtualServerTag[]>;
                });
        };
    }
}

export const scopesResourcesService = new ScopesResourcesService();
