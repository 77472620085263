/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

export enum SelectOnPage {
    CloudConnect = 'Cloud Connect',
    BackupInfrastructure = 'Backup Infrastructure',
    Companies = 'Companies',
}

export class RowsStore {

    @observable maxNumberRowsCloudConnect = 10;
    @observable maxNumberRowsBackupInfrastructure = 10;
    @observable maxNumberRowsCompanies = 10;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setMaxNumberRows(maxNumberRows: number, select: SelectOnPage): void {
        switch (select) {
            case SelectOnPage.CloudConnect:
                this.maxNumberRowsCloudConnect = maxNumberRows;
                break;
            case SelectOnPage.BackupInfrastructure:
                this.maxNumberRowsBackupInfrastructure = maxNumberRows;
                break;
            case SelectOnPage.Companies:
                this.maxNumberRowsCompanies = maxNumberRows;
                break;
        }
    }
}

export const rowsStore = new RowsStore();
