/**
 * Copyright © Veeam Software Group GmbH.
 */

export const REG_EXP = {
    isoFileType: /\.iso$/,
    xmlFileType: /\.xml$/,
    licenseFileType: /\.lic$/,
    defaultNameLight: /^[^<>=%~]+$/,
    includesNumberOrLetter: /(?=\p{L})|(?=\d)/u,
    uncPath: /^(\\\\[^/\\\][":;|<>+=,?* _]+\\[^/\\\][":;|<>+=,?*]+)((?:\\[^\\/:*?"<>|]+)*\\?)$/, // \\computer\folder
    email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
    portalUrl: /^(http|https):\/\/[\w\d-._~:/?#$&'()*+,;=]+$/,
};
