/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConfigItemIds {
    CloudHostCpuRatio = 'CloudHostCpuRatio',
    MonthlyUsageReport = 'MonthlyUsageReport',
    LicenseStatusToolbar = 'LicenseStatusToolbar',
    StorageUsageWidget = 'StorageUsageWidget',
    StoredWorkloadsWidget = 'StoredWorkloadsWidget',
    DataTransferWidget = 'DataTransferWidget',
    CloudConnectServerChart = 'CloudConnectServerChart',
    CompaniesWizardVBRServersActionToolbar = 'CompaniesWizardVBRServersActionToolbar',
    CompaniesWizardVBRRepositoriesActionToolbar = 'CompaniesWizardVBRRepositoriesActionToolbar',
}
