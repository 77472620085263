/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef } from 'react';
import { colors, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { PieChartModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { BillingWidget } from '../../../../BillingWidget';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { getDonutOptions } from 'views/components/HighchartsWrapper/helpers';
import { transformDonutData } from 'views/components/ChartWidget/helpers';
import { useHwPlansStore } from '../../hooks';
import { useIntervalRequest } from 'views/hooks';

import type { PieData } from 'views/components/HighchartsWrapper/interfaces';

export const CloudHostStorage = observer(() => {
    const abortController = useRef(new AbortController());
    const didMount = useRef(false);
    const lang = useGlobalLang<VspcLang>();
    const { hwpId } = useHwPlansStore();

    const request = useIntervalRequest<{}, PieChartModel>(
        '/Billing/GetBillingSummaryCloudHostStorage',
        { hwpId },
        {
            requestInit: {
                signal: abortController.current.signal,
            },
        },
        5 * TimeUnitsInMs.Minute,
    );

    const { empty, series } = request.data
        ? transformDonutData(request.data as PieChartModel, items => items.every(i => !i.capacity && !i.value))
        : {} as PieData;

    const params = {
        colors: [colors.B400, colors.G250],
        lang,
    };

    const options = getDonutOptions({
        categories: [],
        height: 232,
        series,
        size: 200,
        ...params,
    });

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();
            abortController.current = new AbortController();

            return request.forceRequest();
        }

        didMount.current = true;
    }, [hwpId]);

    return (
        <BillingWidget title={{ text: lang.CLOUD_HOST_STORAGE }}>
            <HighchartsWrapper
                empty={empty}
                emptyDesc={lang.PLEASE_START_USING_VCC_BACKUP_JOBS}
                loading={request.loading}
                options={options}
            />
        </BillingWidget>
    );
});
