/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SmtpSettingsTlsMode } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const getTlsModeTypes = (lang: VspcLang) => [
    { id: SmtpSettingsTlsMode.Auto, name: lang.AUTO },
    { id: SmtpSettingsTlsMode.None, name: lang.NONE_NOT_SECURE },
    { id: SmtpSettingsTlsMode.SslOnConnect, name: lang.SSL_ON_CONNECT },
    { id: SmtpSettingsTlsMode.StartTls, name: lang.START_TLS },
    { id: SmtpSettingsTlsMode.StartTlsWhenAvailable, name: lang.START_TLS_WHEN_AVAILABLE },
];
