/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize, formatStr } from '@veeam-vspc/shared/helpers';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ParsedText,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalServices,
    ToolbarWithExport,
    TextColumn,
} from '@veeam-vspc/shared/components';
import { ProtectedVirtualMachineRestoreType } from '@veeam-vspc/models/web-controllers';

import type { ProtectedVirtualMachineBackupRestorePoint, ProtectedVmBackupRestorePointParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { getRestorePointsHelpResource } from 'core/utils';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { MAX_INTEGER_32 } from 'core/const';

const {
    Backup,
    Copy,
    Tape,
} = ProtectedVirtualMachineRestoreType;

export interface VirtualInfrastructureRestorePointProps {
    stateId: string;
    name: string;
    protectionType: ProtectedVirtualMachineRestoreType;
    extraParams: Pick<ProtectedVmBackupRestorePointParam, 'backupUid' | 'vmInstanceUid'>;
    deactivate: () => void;
    isOneBackupPerVmEnabled: boolean;
}

export const VirtualInfrastructureRestorePointDialog = (props: VirtualInfrastructureRestorePointProps) => {
    const { stateId, name, protectionType, extraParams, deactivate, isOneBackupPerVmEnabled } = props;
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const isNoteBarShown = !isOneBackupPerVmEnabled && [Backup, Copy, Tape].includes(protectionType);

    const columns: GridColumnProps<ProtectedVirtualMachineBackupRestorePoint>[] = [
        {
            id: 'creationDate',
            title: lang.DATE,
            cell: rowData => <GridDateColumn value={rowData.creationDate} />,
        },
        {
            id: 'usedSourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.usedSourceSize} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
        },
        {
            id: 'incrementRawDataSize',
            title: lang.BACKED_UP_DATA,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.incrementRawDataSize} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'size',
            title: lang.RESTORE_POINT_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.size} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
    ];

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                {isNoteBarShown && (
                    <NoteBar status={NOTEBAR_STATUS.info} style={{ backgroundColor: RCOP.utils.Colors.Y50 }}>
                        <ParsedText textOrConfig={formatStr(lang.TO_SEE_BACKED_UP_DATA, getRestorePointsHelpResource(portalUser.userRole))} />
                    </NoteBar>
                )}

                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: ProtectedVmBackupRestorePointParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<ProtectedVirtualMachineBackupRestorePoint[]>>(
                            '/ProtectedWorkloads/GetVmsBackupRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                lang={lang}
                                idProperty={'instanceUid'}
                                method={'GetProtectedVmBackupRestorePoints'}
                                extraParams={extraParams}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
