/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGlobalLang,
    useGridApi,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalServices,
    ToolbarWithExport,
    PortalSpinner,
} from '@veeam-vspc/shared/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { ConfigStates, deepCopy } from '@veeam-vspc/shared/core';
import {
    DiscoveryRuleStateRepresentation,
    SystemTypeRepresentation,
    NetworkDiscoveryRuleOsTypeRepresentation,
    CompanyStatusRepresentation,
} from '@veeam-vspc/models/web-controllers';
import { useNavigate } from 'react-router-dom';

import type { CustomToolbarPayload, DecoratorFunction } from '@veeam-vspc/shared/components';
import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type { DiscoveryRuleDataJson, DiscoveryRuleGridDataModel, DiscoveryRuleFilter } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { ConfigActionIds, ConfigSectionIds } from 'core/enums';
import addIconSrc from 'images/actions/add.svg';
import editIconSrc from 'images/actions/edit.svg';
import removeIconSrc from 'images/actions/remove.svg';
import runIconSrc from 'images/actions/run.svg';
import stopIconSrc from 'images/actions/stop.svg';
import resetIconSrc from 'images/actions/reset.png';
import scheduleIconSrc from 'images/actions/link.png';
import viewComputersIconSrc from 'images/actions/view-computers.png';
import windowsSrc from 'images/filters/os/windows.svg';
import linuxSrc from 'images/filters/os/linux.svg';
import { useAsyncAction } from 'views/hooks';
import { startExtDialog } from 'core/utils';

export const RulesActionToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const newRuleGroupId = 'newRuleGroupId';
    const gridApi = useGridApi<DiscoveryRuleGridDataModel, unknown, DiscoveryRuleFilter>();
    const { portalUser } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [loading, setLoading] = useState(false);
    const { configuratorService, notificationService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.DiscoveryComputersRules);

    if (sectionConfig && sectionConfig.items[ConfigActionIds.ActionToolbarRuleActions] === ConfigStates.Hidden) {
        return null;
    }

    const editDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.length !== 1
                    || selected[0].state === DiscoveryRuleStateRepresentation.Running,
            };
        };

    const removeDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.filter(
                    item =>
                        item.state !== DiscoveryRuleStateRepresentation.Running
                        && item.state !== DiscoveryRuleStateRepresentation.CancellationRequested
                ).length === 0,
            };
        };

    const runDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.filter(
                    item =>
                        item.state !== DiscoveryRuleStateRepresentation.Running
                ).length === 0,
            };
        };

    const stopDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.filter(
                    item =>
                        item.state !== DiscoveryRuleStateRepresentation.Canceled
                        && item.state !== DiscoveryRuleStateRepresentation.Created
                        && item.state !== DiscoveryRuleStateRepresentation.Failed
                        && item.state !== DiscoveryRuleStateRepresentation.Success
                        && item.state !== DiscoveryRuleStateRepresentation.CancellationRequestFailed
                        && item.state !== DiscoveryRuleStateRepresentation.CancellationRequested
                ).length === 0,
            };
        };

    const viewComputersDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.length !== 1
                    || selected[0].totalComputers === 0,
            };
        };

    const resetDecorator = (): DecoratorFunction =>
        ({ selectedItems }: CustomToolbarPayload) => {
            const selected = selectedItems as DiscoveryRuleGridDataModel[];
            return {
                disabled: selected.filter(
                    item =>
                        item.state !== DiscoveryRuleStateRepresentation.Running
                        && item.totalComputers !== 0
                ).length === 0,
            };
        };

    const newRule = (os: SystemTypeRepresentation) => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            return notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        }

        setLoading(true);

        startExtDialog(lang, notificationService, () => {
            setLoading(false);

            Ext.create(
                os === SystemTypeRepresentation.Linux
                    ? 'RCOP.view.home.discovery.rules.wizards.linux.Wizard'
                    : 'RCOP.view.home.discovery.rules.wizards.windows.Wizard',
                {
                    callback() {
                        gridApi.reloadGrid();
                    },
                }
            ).show();
        });
    };

    const editRule = () => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            return notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        }

        const selected = gridApi.selected[0];

        doAsyncAction('/DiscoveryRule/GetData', lang.EDIT, { id: selected.id })
            .then((resp) => {
                const errorResp = resp as RequestErrorResponse;
                const successResp = resp as RequestSuccessResponse<DiscoveryRuleDataJson>;

                if (!errorResp.errors) {
                    portalEnums.replaceEnumsInData('DiscoveryRuleDataJson', successResp.data);

                    setLoading(true);

                    startExtDialog(lang, notificationService, () => {
                        setLoading(false);

                        Ext.create(
                            selected.osType === NetworkDiscoveryRuleOsTypeRepresentation.Linux
                                ? 'RCOP.view.home.discovery.rules.wizards.linux.Wizard'
                                : 'RCOP.view.home.discovery.rules.wizards.windows.Wizard',
                            {
                                isEdit: true,
                                commonData: successResp.data,
                                stateId: portalEnums.getEnumIntValue('discoveryRuleStateRepresentation', selected.state),
                                companyName: selected.company,
                                callback() {
                                    gridApi.reloadGrid();
                                },
                            }
                        ).show();
                    });
                }
            });
    };

    const removeRules = () => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        } else {
            notificationService
                .confirm(lang.DELETE_DISCOVERY_RULE, lang.THIS_WILL_DELETE_THE_SELECTED_DISCOVERY_RULE)
                .then((btnKey: NotificationResponse) => {
                    if (btnKey === NotificationDialogsTextKeys.Yes) {
                        const ids = gridApi.selected.map(item => item.id);

                        doAsyncAction(
                            '/DiscoveryRule/Remove',
                            lang.DELETE_DISCOVERY_RULE,
                            {
                                ids,
                            },
                        );
                    }
                });
        }
    };

    const runRules = () => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        } else {
            const ids = gridApi.selected.map(item => item.id);

            return doAsyncAction('/DiscoveryRule/Run', lang.RUN, { ids });
        }
    };

    const runSchedule = () => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            return notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        }

        const scheduleSettings = deepCopy(gridApi.selected[0]);
        portalEnums.replaceEnumsInData('DiscoveryRuleGridDataModel', scheduleSettings);

        setLoading(true);

        startExtDialog(lang, notificationService, () => {
            setLoading(false);

            const dlg = Ext.create('RCOP.view.home.discovery.rules.dialogs.Schedule', {
                value: scheduleSettings,
            });

            dlg.open(
                (data) => {
                    const ids = gridApi.selected.map(item => item.id);
                    portalEnums.restoreStringEnumsInData('DiscoveryRuleGridDataModel', data);

                    const dto = {
                        ruleIds: ids,
                        scheduleSettings: data.scheduleSettings,
                    };

                    doAsyncAction('/DiscoveryRule/SaveRuleSchedule', lang.SCHEDULE, dto).then(() => {
                        dlg.close();
                    });
                }
            );
        });
    };


    const stopRules = () => {
        if (portalUser.companyStatus === CompanyStatusRepresentation.Disabled) {
            notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS);
        } else {
            const ids = gridApi.selected.map(item => item.id);

            doAsyncAction(
                '/DiscoveryRule/Stop',
                lang.STOP,
                {
                    ids,
                },
            );
        }
    };

    const resetRules = () => {
        const ids = gridApi.selected.map(item => item.id);

        doAsyncAction(
            '/DiscoveryRule/Reset',
            lang.STOP,
            {
                ruleIds: ids,
            },
        );
    };

    return (
        <>
            <ToolbarWithExport
                lang={lang}
                hideExport={true}
                sectionId={ConfigSectionIds.DiscoveryComputersRules}
                groups={[
                    {
                        id: newRuleGroupId,
                        title: lang.NEW,
                        iconSrc: addIconSrc,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        groupId: newRuleGroupId,
                        iconSrc: windowsSrc,
                        text: lang.WINDOWS,
                        onClick: () => newRule(SystemTypeRepresentation.Windows),
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        groupId: newRuleGroupId,
                        iconSrc: linuxSrc,
                        text: lang.LINUX,
                        onClick: () => newRule(SystemTypeRepresentation.Linux),
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: editIconSrc,
                        text: lang.EDIT,
                        onClick: () => editRule(),
                        decorators: [editDecorator()],
                    }, {
                        id: ConfigActionIds.ActionToolbarRemoveRule,
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: removeIconSrc,
                        text: lang.REMOVE,
                        onClick: () => removeRules(),
                        decorators: [removeDecorator()],
                    }, {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: runIconSrc,
                        text: lang.RUN,
                        onClick: () => runRules(),
                        decorators: [runDecorator()],
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopIconSrc,
                        text: lang.STOP,
                        onClick: () => stopRules(),
                        decorators: [stopDecorator()],
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: scheduleIconSrc,
                        text: lang.SCHEDULE,
                        onClick: () => runSchedule(),
                        decorators: [toolbarItemDecorators.disallowWithoutSelection()],
                    }, {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    }, {
                        id: ConfigActionIds.ActionToolbarViewDiscoveredComputers,
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: viewComputersIconSrc,
                        text: lang.VIEW_DISCOVERED_COMPUTERS,
                        onClick: () => {
                            RCOP.discoveryComputersRuleId = gridApi.selected[0].id;
                            navigate('/home/discovery/discoveredComputers/computers', { replace: true });
                        },
                        decorators: [viewComputersDecorator()],
                    }, {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: resetIconSrc,
                        text: lang.RESET_DISCOVERED_COMPUTERS,
                        onClick: () => resetRules(),
                        decorators: [resetDecorator()],
                    },
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
            {asyncActionLoader}
        </>
    );
};
