/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    LabelGroup,
    STACK_DIRECTION,
    StackView,
    STACK_GAP,
    Combobox,
    CONTROL_SIZE,
    NumberInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { CSSProperties } from 'react';
import type { VspcLang } from 'configs/languages';

interface Props {
    as?: any;
    label?: string;
    valueName: string;
    unitsName: string;
    suffix?: string;
    suffix2?: string;
    unitSize?: CONTROL_SIZE;
    allowDecimal?: boolean;
    onChange?: () => void;
}

export const PerDateField = (({ as, label, valueName, unitsName, suffix, suffix2, unitSize, allowDecimal }: Props) => {
    const lang = useGlobalLang<VspcLang>();

    const unitList = [
        {
            name: lang.MINUTE,
            value: 5,
        }, {
            name: lang.HOUR,
            value: 1,
        }, {
            name: lang.DAY,
            value: 2,
        }, {
            name: lang.WEEK,
            value: 3,
        }, {
            name: lang.MONTH,
            value: 4,
        },
    ];

    const noLabelOffset: CSSProperties = label ? {} : {}; //{position: 'relative', left: '-8px'};

    return (
        <LabelGroup asTag={as} label={label} style={noLabelOffset}>
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                <NumberInput
                    name={valueName}
                    allowDecimal={allowDecimal !== false}
                    precision={2}
                    minValue={0}
                    suffix={suffix}
                />

                <Combobox
                    name={unitsName}
                    data={unitList}
                    valueGetter={item => item.value}
                    textGetter={item => item.name}
                    size={unitSize || CONTROL_SIZE.xxs}
                    suffix={suffix2}
                />
            </StackView>
        </LabelGroup>
    );
});
