/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    APP_BAR_ITEM_TYPE,
    AppBarConfigurationItem,
    AppBarProfile,
    PortalHeader,
    PortalSpinner,
    useGlobalAppStorage,
    useGlobalAppData,
    useGlobalContext,
    useGlobalLang,
    useGlobalRoutes,
    useGlobalServices,
    useModal,
} from '@veeam-vspc/shared/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { UserMfaPolicyAggregateStatusRepresentation } from '@veeam-vspc/models/web-controllers';
import { BeforeLeave } from '@veeam-vspc/shared/helpers';

import type { UserData, UserGetUserDataParam } from '@veeam-vspc/models/web-controllers';
import type { AppBarItemProps } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { AppBell } from 'views/components/AppBell';
import EditProfileIcon from 'images/icons/edit-profile.png';
import LogoutIcon from 'images/icons/logout.png';
import { whichSection } from 'core/utils';
import { EditUserProfileWizard } from 'views/components/wizard/EditUserProfile';
import { NavigationHistory, RoutePaths, RouteSectionType } from 'configs/enums';
import { ROLES } from 'core/const';
import { usePortalFaviconImage, useLoadImageDataUrl } from 'views/hooks';

export const AppPortalHeader: React.FC = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const routes = useGlobalRoutes();
    const { logout } = useGlobalContext();
    const { transportService, notificationService } = useGlobalServices();
    const globalAppData = useGlobalAppData();
    const { theme } = globalAppData;
    const appStorage = useGlobalAppStorage();
    const { portalUser, isMaintenanceMode, portalLogoSrc } = globalAppData;
    const [logo, setLogo] = useState(portalLogoSrc || '');
    const lang = useGlobalLang<VspcLang>();
    const loadImgDataUrl = useLoadImageDataUrl();
    const [isLoading, setIsLoading] = useState(false);
    const [editUserProfileWizard, editUserProfileWizardActions] = useModal<UserData>({
        render: ({ data, deactivate }) => (
            <EditUserProfileWizard data={data} deactivate={deactivate} />
        ),
    });
    const section = whichSection(location);
    const mainRoutes = routes.filter(({ path }) => path === '')[0];
    const isConfigsRoutesExists = mainRoutes.items.filter(({ path }) => path === RoutePaths.Configs).length > 0;
    const appBellWithSeparator: AppBarItemProps[] = isMaintenanceMode ? [] : [
        {
            render: () => <AppBell />,
            type: APP_BAR_ITEM_TYPE.custom,
        }, {
            type: APP_BAR_ITEM_TYPE.separator,
        },
    ];

    const configLink: AppBarItemProps[] = isConfigsRoutesExists ?
        [{
            render: () => (
                <AppBarConfigurationItem
                    active={section === RouteSectionType.Config}
                    onClick={() => {
                        const lastConfigRoute = appStorage.getItem(NavigationHistory.LastConfigRoute) || `/${RoutePaths.Configs}`;
                        const lastHomeRoute = appStorage.getItem(NavigationHistory.LastHomeRoute) || `/${RoutePaths.Home}`;
                        const toAnotherSection = `${section === RouteSectionType.Home ? lastConfigRoute : lastHomeRoute}${window.location.search}`;

                        if (globalAppData.navigationBlockerPrompt) {
                            return notificationService
                                .confirm(globalAppData.navigationBlockerPrompt.title, globalAppData.navigationBlockerPrompt.message)
                                .then((btn) => {
                                    if (btn === NotificationDialogsTextKeys.Yes) {
                                        globalAppData.navigationBlockerPrompt = null;
                                        navigate(toAnotherSection);
                                    }
                                });
                        }

                        navigate(toAnotherSection);
                    }}
                    text={lang.CONFIGURATION}
                />
            ),
            type: APP_BAR_ITEM_TYPE.custom,
        }]
        : [];
    const loadPortalBrandImage = () => loadImgDataUrl(`/Branding/GetPortalBrandImage?_dc=${Date.now()}`);
    const onUserProfileEdit = () => {
        setIsLoading(true);
        transportService.request<UserGetUserDataParam, RequestSuccessResponse<UserData>>('/User/GetUserData', { id: portalUser.id })
            .then(({ data }: RequestSuccessResponse<UserData>) => {
                setIsLoading(false);
                editUserProfileWizardActions.activate(data);
            })
            .catch(() => setIsLoading(false));
    };

    const onLogout = () => {
        const beforeLeave = BeforeLeave.getInstance();
        if (globalAppData.navigationBlockerPrompt || beforeLeave.isListenerExist) {
            const title = globalAppData.navigationBlockerPrompt?.title || lang.LOG_OUT;
            const message = globalAppData.navigationBlockerPrompt?.message || lang.THE_CONFIGURED_CHANGES_WILL_NOT;

            return notificationService.confirm(title, message)
                .then((btn) => {
                    if (btn === NotificationDialogsTextKeys.Yes) {
                        globalAppData.navigationBlockerPrompt = null;
                        beforeLeave.hideAll();
                        return logout();
                    }
                });
        }
        return logout();
    };

    usePortalFaviconImage();

    useEffect(() => {
        if (
            portalUser
            && !editUserProfileWizardActions.isActive
            && portalUser.mfaPolicyStatus === UserMfaPolicyAggregateStatusRepresentation.NotConfigured
        ) {
            onUserProfileEdit();
        }
    }, [portalUser]);

    useEffect(() => {
        loadPortalBrandImage().then(setLogo);
    }, [theme]);

    return (
        <>
            <PortalHeader
                logo={logo}
                title={null}
                items={[
                    {
                        type: APP_BAR_ITEM_TYPE.fillSpace,
                    },
                    {
                        type: APP_BAR_ITEM_TYPE.custom,
                        render: () => (
                            <AppBarProfile
                                title={portalUser.login}
                                description={ROLES[portalUser.userRole]}
                                menuItems={[
                                    !isMaintenanceMode && {
                                        text: lang.EDIT_PROFILE,
                                        icon: EditProfileIcon,
                                        onClick: onUserProfileEdit,
                                    },
                                    {
                                        text: lang.LOG_OUT,
                                        icon: LogoutIcon,
                                        onClick: onLogout,
                                    },
                                ].filter(Boolean)}
                            />
                        ),
                    },
                    {
                        type: APP_BAR_ITEM_TYPE.separator,
                    },
                    ...appBellWithSeparator,
                    ...configLink,
                ]}
            />

            {editUserProfileWizard}
            {isLoading && <PortalSpinner delayTime={300} />}
        </>
    );
});
