/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VspcLang } from 'configs/languages';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { ReturnUrlManagerContext } from '@veeam-vspc/shared/core';

import { QueryKeys, QueryValues } from 'core/enums';

export const returnUrlActionFromSso = (
    { notificationService }: ReturnUrlManagerContext<RequestErrorResponse | Error>,
    lang: Partial<VspcLang>,
    location: Location,
    queryParams: URLSearchParams,
): void => {
    const backendError = queryParams.get(QueryKeys.SsoBackendError);
    const loginState = queryParams.get(QueryKeys.SsoLoginState);

    if (backendError) {
        notificationService.error(lang.ERROR, [backendError]);
    }

    if (loginState === QueryValues.TestLogin) {
        window.location.assign('/config/security/sso');
    }
};
