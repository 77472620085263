/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Infrastructure } from './components';

export interface TrendsInfrastructurePageProps {
    currentPath?: string;
}

export const TrendsInfrastructurePage: React.FC<TrendsInfrastructurePageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <Infrastructure stateId={currentPath} />
    </TabBarBodyLayout>
);
