/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { PortalBranding } from './components';

export const CompanyInfoPortalBrandingPage: React.FC<{}> = () => (
    <PortalBranding />
);
