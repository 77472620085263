/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import {
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/shared/helpers';
import {
    EDisplayPublicCloudPolicyState,
    EPublicCloudObjectCreatingState,
    JobStateRepresentation,
} from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type {
    PublicCloudAvailableVbModel,
    PublicCloudJobFilter,
    PublicCloudJobModel,
    VbPolicyActionSettingJson,
    VbPolicyCreateActionSettingJson,
} from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import createJobActionIconSrc from 'images/actions/add.svg';
import editJobActionIconSrc from 'images/actions/edit.svg';
import deleteJobActionIconSrc from 'images/actions/remove.svg';
import { useAsyncAction } from 'views/hooks';
import { SelectVbDialog } from '../../../../../SelectVbDialog';
import { oneOfPropsHaveOneOfStatus } from 'views/pages/BackupJobsPage/utils';
import { ConfigSectionIds } from 'core/enums';


export interface PublicCloudsActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const PublicCloudsActionToolbar: React.FC<PublicCloudsActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudJobs', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { transportService, notificationService } = useGlobalServices();
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];
    const gridApi = useGridApi<PublicCloudJobModel, unknown, PublicCloudJobFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [selectVbDialog, selectVbDialogActions] = useModal({
        render: ({ deactivate }) => (
            <SelectVbDialog
                onClose={deactivate}
                onSelect={({ serverTag }: PublicCloudAvailableVbModel) => {
                    setLoading(true);
                    deactivate();

                    transportService.request<VbPolicyCreateActionSettingJson, RequestSuccessResponse<string>>('/PublicCloudVmJob/CreatePolicyLink', {
                        serverTag,
                        backUrl: window.location.href,
                    })
                        .then((resp: RequestSuccessResponse<string>) => {
                            setLoading(false);
                            window.open(resp.data as string, '_self');
                        })
                        .catch((err) => {
                            setLoading(false);

                            if (err) {
                                console.error(err);
                            }
                        });
                }}
            />
        ),
    });
    const propsToCheckForStatus: (keyof PublicCloudJobModel)[] = [
        'lastBackup',
        'lastSnapshot',
        'lastRemoteSnapshot',
        'lastArchive',
    ];

    return (
        <>
            <ToolbarWithExport
                sectionId={ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds}
                {...restProps}
                lang={lang}
                method={method}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/PublicCloudVmJob/StartPolicy', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback<PublicCloudJobModel>('', (value, item) => (
                                    oneOfPropsHaveOneOfStatus(item, propsToCheckForStatus, [
                                        JobStateRepresentation.Starting,
                                        JobStateRepresentation.Running,
                                        JobStateRepresentation.Stopping,
                                        JobStateRepresentation.Deleting,
                                        JobStateRepresentation.Disabling,
                                        JobStateRepresentation.Enabling,
                                    ])
                                )),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => doAsyncAction('/PublicCloudVmJob/StopPolicy', lang.STOP, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback<PublicCloudJobModel>('', (value, item) => (
                                    !oneOfPropsHaveOneOfStatus(item, propsToCheckForStatus, [
                                        JobStateRepresentation.Starting,
                                        JobStateRepresentation.Running,
                                        JobStateRepresentation.Deleting,
                                        JobStateRepresentation.Disabling,
                                        JobStateRepresentation.Enabling,
                                    ])
                                )),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/PublicCloudVmJob/EnablePolicy', lang.ENABLE, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('displayPolicyState', [
                                    EDisplayPublicCloudPolicyState.Enabled,
                                    EDisplayPublicCloudPolicyState.Enabling,
                                    EDisplayPublicCloudPolicyState.Disabling,
                                    EDisplayPublicCloudPolicyState.Deleting,
                                    EDisplayPublicCloudPolicyState.None,
                                ]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/PublicCloudVmJob/DisablePolicy', lang.DISABLE, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('displayPolicyState', [
                                    EDisplayPublicCloudPolicyState.Disabled,
                                    EDisplayPublicCloudPolicyState.Enabling,
                                    EDisplayPublicCloudPolicyState.Disabling,
                                    EDisplayPublicCloudPolicyState.Deleting,
                                    EDisplayPublicCloudPolicyState.None,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: createJobActionIconSrc,
                        text: lang.CREATE_POLICY,
                        onClick: () => {
                            selectVbDialogActions.activate();
                        },
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: editJobActionIconSrc,
                        text: lang.EDIT,
                        onClick: () => {
                            const { selected } = gridApi;

                            setLoading(true);

                            return transportService.request<VbPolicyActionSettingJson, RequestSuccessResponse<string>>(
                                '/PublicCloudVmJob/EditPolicyLink',
                                {
                                    uid: selected[0].id,
                                    backUrl: window.location.href,
                                },
                            )
                                .then((resp: RequestSuccessResponse<string>) => {
                                    setLoading(false);

                                    window.open(resp.data as string, '_self');
                                })
                                .catch((err) => {
                                    setLoading(false);

                                    if (err) {
                                        console.error(err);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                toolbarItemDecorators.disallowWithoutSingleSelection(),
                                disableForSelectionWithPropValues('vbLinksEnabled', [false]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: deleteJobActionIconSrc,
                        text: lang.REMOVE,
                        onClick: () => {
                            notificationService.confirm(lang.REMOVE_POLICY, lang.THIS_ACTION_WILL_REMOVE_ALL_SELECTED_BACKUP_POLICIES)
                                .then((btnKey) => {
                                    if (btnKey === NotificationDialogsTextKeys.Yes) {
                                        doAsyncAction('/PublicCloudVmJob/DeletePolicy', lang.REMOVE_POLICY, gridApi.requestParamsForActions);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback<PublicCloudJobModel>('', (value, item) => (
                                    oneOfPropsHaveOneOfStatus(item, propsToCheckForStatus, [
                                        JobStateRepresentation.Starting,
                                        JobStateRepresentation.Running,
                                        JobStateRepresentation.Stopping,
                                        JobStateRepresentation.Deleting,
                                        JobStateRepresentation.Disabling,
                                        JobStateRepresentation.Enabling,
                                    ])
                                )),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                ]),
                            ]),
                        ],
                    },
                ]}
            />

            {loading && <PortalSpinner />}
            {asyncActionLoader}
            {selectVbDialog}
        </>
    );
};
