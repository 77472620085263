/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, ToolbarWithExport, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, useGlobalLang } from '@veeam-vspc/shared/components';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { EJobStates } from 'core/enums';

export interface JobObjectsFilterToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const JobObjectsFilterToolbar: React.FC<JobObjectsFilterToolbarProps> = (props) => {
    const { method, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        {
            title: lang.RUNNING,
            value: [
                EJobStates.Running,
                EJobStates.Starting,
                EJobStates.Stopping,
            ],
        },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='computerName'
                            placeholder={lang.COMPUTER}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.JOB_STATUS}
                            statuses={statuses}
                            propName={'statuses'}
                        />
                    ),
                },
            ]}
        />
    );
};
