/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors } from '@veeam-vspc/shared/components';

export const DonutCircleStyled = styled.div`
    border-radius: 116px;
    background-color: ${colors.G000};
    height: 232px;
    margin: 32px auto 0;
    width: 232px;
`;
