/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { FieldLayout, SIZE_M } from '@veeam-vspc/shared/components';

export const FieldLayoutStyled = styled(FieldLayout)`
    .vm-FieldLabel {
        margin-top: 0;

        > * {
          height: ${SIZE_M} !important;
        }
    }
`;
