/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo, useState } from 'react';
import {
    ACTION_VIEW,
    constants,
    createDefaultDatetimeInputControl,
    DatetimeInput,
    Dialog,
    DIALOG_SIZE,
    RadiobuttonKit,
    FieldLayout,
    FormLayout,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { VspcLang } from 'configs/languages';

import { normalizeDateWithTime, useDateFormats } from 'core/utils/date-helpers';
import { ScheduleUpdatesType } from './enums';
import { getDialogTexts } from './helpers';

import type { ScheduleActivityChangeModel, ScheduledUpdatesDialogProps } from './interfaces';

export const ScheduledUpdatesDialog: React.FC<ScheduledUpdatesDialogProps> = ({ scheduledVbrTask = {}, onChange, deactivate }) => {
    const [scheduledTaskType] = useState(scheduledVbrTask?.type);
    const initialDate = new Date(scheduledVbrTask?.date);
    const [newDateTime, setNewDateTime] = useState(initialDate);
    const [dateErrorText, setDateErrorText] = useState('');
    const [scheduleUpdatesType, setScheduleUpdatesType] = useState(ScheduleUpdatesType.ScheduleUpdateOn);
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { formats } = useGlobalAppData();
    const dialogTexts = useMemo(() => getDialogTexts(scheduledTaskType, lang), [scheduledTaskType, lang]);
    const dateFormats = useDateFormats();

    const onDateChange = (newDate: Date) => {
        setNewDateTime(newDate);
        const now = new Date();

        if (newDate < now) {
            setDateErrorText(lang.THE_SCHEDULE_DATE_CANNOT);
        } else {
            setDateErrorText('');
        }
    };

    return (
        <Dialog
            header={dialogTexts.header}
            description={dialogTexts.description}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.m,
            }}
            onRequestClose={deactivate}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        transportService.request<ScheduleActivityChangeModel, {}>('/Deployment/ChangeScheduledActivity', {
                            scheduledActivityUid: scheduledVbrTask.scheduledActivityUid,
                            newDateTime: scheduleUpdatesType === ScheduleUpdatesType.ScheduleUpdateOn ? newDateTime.toJSON() : null,
                            cancel: scheduleUpdatesType === ScheduleUpdatesType.Cancel,
                            startNow: scheduleUpdatesType === ScheduleUpdatesType.StartNow,
                        }).then(() => {
                            deactivate();
                            onChange();
                        });
                    },
                    disabled: (scheduleUpdatesType === ScheduleUpdatesType.ScheduleUpdateOn && initialDate.getTime() === newDateTime.getTime()) ||
                        (scheduleUpdatesType === ScheduleUpdatesType.ScheduleUpdateOn && !!dateErrorText),
                },
                {
                    text: lang.CANCEL,
                    onClick: deactivate,
                    view: ACTION_VIEW.secondary,
                },
            ]}
        >
            <FormLayout>
                <RadiobuttonKit
                    value={ScheduleUpdatesType.ScheduleUpdateOn}
                    checked={scheduleUpdatesType === ScheduleUpdatesType.ScheduleUpdateOn}
                    onChange={setScheduleUpdatesType}
                >
                    {dialogTexts.rescheduleRadioBtn}
                </RadiobuttonKit>

                <FieldLayout
                    error={dateErrorText}
                    disabled={scheduleUpdatesType !== ScheduleUpdatesType.ScheduleUpdateOn}
                    style={{ marginLeft: constants.SPACING_L }}
                >
                    <DatetimeInput
                        value={newDateTime}
                        onChange={onDateChange}
                        controlRenderer={createDefaultDatetimeInputControl({
                            valueFormatter: ({ value }) => normalizeDateWithTime(value, formats),
                        })}
                        withTime
                        is12Hours={dateFormats.is12HoursFormat}
                        startDatetime={new Date()}
                        endDatetime={new Date(Date.now() + TimeUnitsInMs.Day * 364)} // +1 year (364 because user can set time a bit in future)
                        error={!!dateErrorText}
                    />
                </FieldLayout>

                <RadiobuttonKit
                    value={ScheduleUpdatesType.StartNow}
                    checked={scheduleUpdatesType === ScheduleUpdatesType.StartNow}
                    onChange={setScheduleUpdatesType}
                >
                    {dialogTexts.startRadioBtn}
                </RadiobuttonKit>

                <RadiobuttonKit
                    value={ScheduleUpdatesType.Cancel}
                    checked={scheduleUpdatesType === ScheduleUpdatesType.Cancel}
                    onChange={setScheduleUpdatesType}
                >
                    {dialogTexts.cancelRadioBtn}
                </RadiobuttonKit>
            </FormLayout>
        </Dialog>
    );
};
