/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import {
    colors,
    FileInputKit,
    Icon,
    LinkButton,
    SPACING_S,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_WEIGHT,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { formatStr, getStatusIconSrc, StatusTypes } from '@veeam-vspc/shared/helpers';
import { observer } from 'mobx-react-lite';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BrandingFileNameModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import resetSrc from 'images/actions/reset.png';
import { useImageLoader } from 'views/hooks';
import { getColorScheme } from '../../../../helpers';
import { getExtProductTheme } from 'core/utils';

interface ImageUploadProps {
    imageTitle: string;
    imageDescription?: React.ReactElement | string;
    withThemeBgColor?: boolean;
    withSizeNote?: boolean;

    promptTitle?: string;

    fileExt: string;
    imageSize?: {
        width?: number;
        height?: number;
    };

    imageInfoUrl: string;
    getImageUrl: string;
    uploadImageUrl: string;
    resetImageUrl: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = observer(({
    getImageUrl,
    imageInfoUrl,
    imageTitle,
    uploadImageUrl,
    resetImageUrl,
    imageDescription,
    fileExt,
    imageSize,
    promptTitle,
    withThemeBgColor = true,
    withSizeNote = true,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const globalAppData = useGlobalAppData();
    const { height, width } = imageSize || {};
    const { transportService, notificationService, fileTransportService } = useGlobalServices();
    const [text, setText] = useState('');
    const [uploadStatus, setUploadStatus] = useState<StatusTypes>(null);
    const [isCustom, setIsCustom] = useState(true);
    const { image, forceReload } = useImageLoader(getImageUrl, false);
    const imageBgColor = useMemo(
        () => getColorScheme().reduce((prev, current) => (
            current.color === getExtProductTheme(globalAppData.theme) ? current.colorfill : prev
        ), colors.B500),
        [globalAppData.theme]
    );

    useEffect(() => {
        getPortalImageInfo();
        text && forceReload();
    }, [globalAppData.theme]);

    const getStatusText = (status: StatusTypes) => {
        switch (status) {
            case StatusTypes.Pending:
                return formatStr(lang.UPLOAD_PENDING, lang.UPLOAD_TYPE_IMAGE_SUBSTR);
            case StatusTypes.Success:
                return formatStr(lang.UPLOAD_SUCCESS, lang.UPLOAD_TYPE_IMAGE_SUBSTR);
            case StatusTypes.Error:
                return lang.UPLOAD_FAILED;
            case StatusTypes.Warning:
                return lang.UPLOAD_UNSUPPORTED_FORMAT;
            default:
                return '';
        }
    };

    const getPortalImageInfo = () => {
        transportService.request<unknown, BrandingFileNameModel>(imageInfoUrl)
            .then((data: RequestSuccessResponse<BrandingFileNameModel>) => {
                setIsCustom(!!data.data.fileName);
                setText(data.data.fileName || `default-${imageTitle.toLowerCase().replace(/ /g, '-')}.png`);
            });
    };

    const upload = (file: FileList) => {
        if (!file[0].name.includes(fileExt)) {
            setUploadStatus(StatusTypes.Warning);
            return;
        }
        setUploadStatus(StatusTypes.Pending);
        const data = new FormData();
        data.append('0', file[0]);
        fileTransportService.uploadFormData(uploadImageUrl, data)
            .then(data => (data as unknown as { status: number; }).status === 500 ? Promise.reject() : Promise.resolve())
            .then(() => getPortalImageInfo())
            .then(() => forceReload())
            .then(() => setUploadStatus(StatusTypes.Success))
            .catch(() => setUploadStatus(StatusTypes.Error));
    };

    const reset = () => {
        setUploadStatus(null);
        notificationService.confirm(
            promptTitle || imageTitle,
            formatStr(lang.USE_A_DEFAULT, (promptTitle || imageTitle).toLowerCase())
        )
            .then((key) => {
                if (key === NotificationDialogsTextKeys.Yes) {
                    return transportService.request(resetImageUrl)
                        .then(getPortalImageInfo)
                        .then(() => forceReload());
                }
            });
    };

    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>

            <Text weight={TEXT_WEIGHT.bold}>{imageTitle}</Text>

            <StackView direction={STACK_DIRECTION.column}>
                {imageDescription}
                {withSizeNote && imageSize && <Text>{formatStr(lang.THE_IMAGE_SHOULD_BE_IN_PNG_FORMAT, `${width} x ${height}`)}</Text>}
            </StackView>

            <StackView style={{ padding: SPACING_S, backgroundColor: colors.G200, width: 'fit-content' }}>
                <StackView style={withThemeBgColor ? { padding: SPACING_S, backgroundColor: imageBgColor } : {}}>
                    <img src={image} alt={imageTitle} style={{ maxHeight: height > 112 ? 112 : height }} />
                </StackView>
            </StackView>

            <StackView gap={STACK_GAP.s}>
                <Text>{text}</Text>

                {uploadStatus && (
                    <>
                        <Icon src={getStatusIconSrc(uploadStatus)} />
                        <Text>{getStatusText(uploadStatus)}</Text>
                    </>
                )}
            </StackView>


            <StackView gap={STACK_GAP.xl}>
                <FileInputKit
                    accept={fileExt}
                    hideInput
                    onChange={upload}
                />

                <LinkButton
                    onClick={reset}
                    iconBefore={<Icon src={resetSrc} />}
                    disabled={!isCustom}
                >
                    {lang.RESET_TO_DEFAULT}
                </LinkButton>
            </StackView>
        </StackView>
    );
});
