/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AdvancedSettingsStoreContext } from './advanced-settings-store-context';

import type { AlarmsAdvancedSettingsStore } from '../alarms-advanced-settings-store';

export const useAdvancedSettingsStore = (): AlarmsAdvancedSettingsStore => useContext(AdvancedSettingsStoreContext);
