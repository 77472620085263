/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { TargetTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { OldRestorePointColumn } from 'views/components/columns/OldRestorePointColumn';
import { JobObjectsFilterToolbar } from '../JobObjectsFilterToolbar/JobObjectsFilterToolbar';
import { BackupStatusColumn } from '../../../../../../../BackupStatusColumn';
import { getOsName } from '../../../../utils';
import { MAX_INTEGER_32 } from 'core/const';


import type { JobObjectsDialogExtraParams, JobObjectsDialogFilterModel, JobObjectsDialogItem, JobObjectsDialogRequestParams } from '../../interfaces';
import type { EJobStates } from 'core/enums';

export interface JobObjectsDialogProps {
    deactivate: () => void;
    title: string;
    url: string;
    exportMethod: string;
    stateId?: string;
    extraParams: JobObjectsDialogExtraParams;
    isExtendedColumns?: boolean;
}

export const JobObjectsDialog: React.FC<JobObjectsDialogProps> = ({
    deactivate,
    title,
    url,
    stateId,
    extraParams,
    isExtendedColumns,
    exportMethod,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const columns: GridColumnProps<JobObjectsDialogItem>[] = [
        {
            id: 'status',
            title: lang.BACKUP_STATUS,
            cell: rowData => (
                <BackupStatusColumn
                    statusName={rowData.statusName as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            id: 'source',
            title: lang.BACKUP_SOURCE,
            cell: rowData => rowData.source,
            defaultValue: lang.NO_INFO,
            isSortable: false,
        },
        {
            id: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServerName,
            hidden: true,
        },
        {
            id: 'computerName',
            title: lang.COMPUTER,
            cell: rowData => rowData.computerName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'osPlatform',
            title: lang.GUEST_OS,
            width: 100,
            cell: rowData => (
                <TextColumn>
                    {getOsName(lang, rowData.osPlatform)}
                </TextColumn>
            ),
        },
        {
            id: 'destination',
            title: lang.DESTINATION,
            cell: rowData => rowData.destination,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'restorePointsCount',
            title: lang.RESTORE_POINTS,
            cell: rowData => (
                <OldRestorePointColumn
                    {...(stateId ? { stateId: `${stateId}.Dialog.RestorePoints` } : {})}
                    value={rowData.restorePointsCount}
                    name={rowData.name}
                    isLink={![
                        TargetTypeRepresentation.ComputerFolder,
                        TargetTypeRepresentation.SharedFolder,
                    ].includes(rowData.destinationType)}
                    extraParams={{
                        agentId: rowData.agentId,
                        configId: rowData.configUid,
                        endpointId: rowData.endpointId,
                    }}
                    isExtendedColumns={isExtendedColumns}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastSessionStartTime}
                />
            ),
            sortKey: 'lastSessionStartTime',
        },
        {
            id: 'lastSessionTime',
            title: lang.LAST_RUN_TIME,
            cell: rowData => (
                <GridDateColumn
                    value={rowData.lastSessionStartTime}
                />
            ),
            hidden: true,
            sortKey: 'lastSessionStartTime',
        },
    ];

    return (
        <Dialog
            header={title}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.OK, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    stateId={stateId ? `${stateId}.JobObjectsDialog` : undefined}
                    defaultColumnMinWidth={145}
                    columns={columns}
                    data={(requestParams: JobObjectsDialogRequestParams<JobObjectsDialogFilterModel>) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<JobObjectsDialogItem[]>>(url, {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={true}
                    selection={{
                        field: 'instanceUid',
                        checkbox: true,
                        multiple: true,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <JobObjectsFilterToolbar
                                method={exportMethod}
                                idProperty={'instanceUid'}
                                extraParams={extraParams}
                                selectedOnly
                                excludeFromExport={['lastSessionTime']}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
