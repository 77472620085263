/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ServerErrorCodes {
    NotAllowedOperation = 1000,
    JsonPatchError = 1001,
    Cancelled = 1050,
    Timeout = 1051,
    RetryableTimeout = 1052,
    RetryLater = 11004,
}
