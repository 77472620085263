/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { Microsoft365ObjectType } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedMicrosoft365ObjectsContext } from './contexts/ProtectedMicrosoft365ObjectsContext';
import { ProtectedMicrosoft365ObjectsService } from './services/protected-microsoft-365-objects.service';
import { ProtectedMicrosoft365ObjectsStore } from './stores/ProtectedMicrosoft365ObjectsStore';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useProtectedMicrosoft365ObjectsStore } from './hooks/use-protected-microsoft-365-objects-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const protectedMs365Object = useProtectedMicrosoft365ObjectsStore();
    const protectedMicrosoft365ObjectsService = useMemo(() =>
        new ProtectedMicrosoft365ObjectsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400],
        yTitle: lang.OBJECTS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_MICROSOFT_365_OBJECTS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedMs365Object.setMicrosoft365ObjectType}
                stateId={protectedMs365Object.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedMs365Object.microsoft365ObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: Microsoft365ObjectType.User, text: capitalize(lang.USERS) },
                    { id: Microsoft365ObjectType.Group, text: capitalize(lang.GROUPS) },
                    { id: Microsoft365ObjectType.SharePoint, text: capitalize(lang.SITES) },
                    { id: Microsoft365ObjectType.Team, text: capitalize(lang.TEAMS) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedMs365Object.microsoft365ObjectType}
            params={params}
            service={protectedMicrosoft365ObjectsService}
            title={title}
        />
    );
});

export const ProtectedMicrosoft365Objects: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ProtectedMs365Objects.Ms365ObjectType`;
    const initialType = useLocalStorageValue<Microsoft365ObjectType>(stateId, Microsoft365ObjectType.User);

    const objectsStore = useMemo(() => new ProtectedMicrosoft365ObjectsStore(initialType, stateId), [initialType, stateId]);

    return (
        <ProtectedMicrosoft365ObjectsContext.Provider value={objectsStore}>
            <ChartLayout />
        </ProtectedMicrosoft365ObjectsContext.Provider>
    );
};
