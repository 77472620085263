/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';
import { FileInput, Form, useExternalFormApi, FieldLayout, useGlobalLang } from '@veeam-vspc/shared/components';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { VbrDeployType } from '../../enums';
import { stepValidate } from './stepValidate';
import { formValidate } from './formValidate';

import type { RadioGroupButtons } from 'components/controls/RadioGroup';
import type { VbrDeploySettingsWizardModel } from '../../entries';

const License = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi<VbrDeploySettingsWizardModel>();
    const lang = useGlobalLang<VspcLang>();
    const radioButtons: RadioGroupButtons<VbrDeploymentLicenseSourceType>[] = [
        {
            label: lang.CONFIGURATION_FILE,
            value: VbrDeploymentLicenseSourceType.AnswerFile,
        },
        {
            label: lang.SPECIFIED_LICENSE_FILE,
            value: VbrDeploymentLicenseSourceType.LicenseFile,
        },
    ];

    if (data.isPulseConfigured) {
        radioButtons.unshift({
            label: lang.VCSP_PULSE,
            value: VbrDeploymentLicenseSourceType.Pulse,
        });
    }

    return (
        <Form
            value={data}
            validate={formValidate(lang)}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
        >
            <StepLayout
                title={lang.LICENSE}
                description={`${lang.PROVIDE_LICENSE_FILE_FOR}.`}
            >

                <FieldLayout label={`${lang.SELECT_WHERE_TO_GET_THE_LICENSE_KEY}:`}>
                    <RadioGroup
                        value={data.licenseSource}
                        onChange={(value: VbrDeploymentLicenseSourceType) => {
                            formApi.setValue('licenseSource', value); // otherwise we don't see errors
                        }}
                        buttons={radioButtons}
                    />
                </FieldLayout>

                <FileInput
                    name='licenseFile'
                    label={lang.FILE}
                    disabled={data.licenseSource !== VbrDeploymentLicenseSourceType.LicenseFile}
                    accept='.lic'
                    inlineLabel
                />
            </StepLayout>
        </Form>
    );
};

export const getLicenseStep = (lang: VspcLang, notificationService: NotificationService): WizardStep<VbrDeploySettingsWizardModel> => ({
    title: lang.LICENSE,
    validate: ({ data }) => stepValidate(lang, notificationService, data),
    isHidden: ({ data }) => data.vbrDeployType !== VbrDeployType.Install,
    render: data => <License {...data} />,
});
