/**
 * Copyright © Veeam Software Group GmbH.
 */

import { CompanyTypeRepresentation, InvoiceScheduleTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, ConfiguredToolbar, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

import myPlansSrc from 'images/filters/subscription-plans/my_plans.png';
import resellerItemSrc from 'images/reseller_item.png';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import notSetSrc from 'images/filters/invoice-schedule-types/not-set.png';
import periodicSrc from 'images/filters/invoice-schedule-types/periodic.png';
import { ConfigActionIds, ConfigSectionIds } from 'core/enums';

export const InvoiceConfigurationFilterToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    const scheduleTypes = [
        { title: lang.NOT_SET, value: InvoiceScheduleTypeRepresentation.NotScheduled, iconSrc: notSetSrc },
        {
            title: lang.MONTHLY,
            value: [InvoiceScheduleTypeRepresentation.Monthly, InvoiceScheduleTypeRepresentation.AfterLicenseReport],
            iconSrc: periodicSrc,
        },
    ];
    const types = [
        { title: lang.MY, value: [CompanyTypeRepresentation.Provider, CompanyTypeRepresentation.Tenant], iconSrc: myPlansSrc },
        { title: lang.RESELLER, value: CompanyTypeRepresentation.Reseller, iconSrc: resellerItemSrc },
    ];

    return (
        <ConfiguredToolbar
            sectionId={ConfigSectionIds.InvoicesConfiguration}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    id: ConfigActionIds.FilterToolbarCompanySearch,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='companyName'
                            placeholder={lang.COMPANY}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarScheduleType,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.SCHEDULING}
                            statuses={scheduleTypes}
                            propName={'scheduleTypes'}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarType,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.TYPE}
                            statuses={types}
                            propName={'type'}
                        />
                    ),
                },
            ]}
        />
    );
};
