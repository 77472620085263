/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Button,
    Combobox,
    CONTROL_SIZE,
    createSearchableControl,
    Form,
    FormLayout,
    FormValidator,
    NoteBar,
    NOTEBAR_STATUS,
    PortalSpinner,
    SignPost,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TabBarBodyLayout,
    TabBarBodyLayoutModes,
    Text,
    TEXT_SIZE,
    TextInput,
    useExternalFormApi,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
    ValidationState,
} from '@veeam-vspc/shared/components';
import { CompanyStatusRepresentation } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';
import { useNavigationBlocker } from '@veeam-vspc/shared/hooks';

import type {
    CompanyGetCompanyParam,
    OrganizationProfile,
} from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { ConfigSectionIds } from 'core/enums';
import { extjsRegExpValidate, extjsValidate, tenantName } from 'core/utils/validators';
import { REG_EXP } from 'core/const';
import { useGridStore } from 'views/components/GeoLocationDialog/components/GeoLocationForm/hooks';
import { HighchartSourceIndicator } from 'views/components/HighchartSourceIndicator';

const formValidate = (lang: VspcLang, data: OrganizationProfile) => {
    const validator = new FormValidator(data);

    const defaultNameLightValidator = (value: string): string =>
        extjsRegExpValidate(value, REG_EXP.defaultNameLight, lang.INVALID_CHARACTER);

    validator.validate('name')
        .string()
        .required()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('proPartnerId')
        .string()
        .maxLength(50);

    validator.validate('alias')
        .string()
        .maxLength(32)
        .check(v => extjsValidate(v, v => tenantName(v, lang)));

    validator.validate('taxId')
        .string()
        .maxLength(100)
        .check(defaultNameLightValidator);

    validator.validate('zipCode')
        .string()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('domain')
        .string()
        .maxLength(128);

    validator.validate('emailAddress')
        .string()
        .maxLength(128)
        .check(v => extjsRegExpValidate(v, REG_EXP.email, lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS));

    validator.validate('telephone')
        .string()
        .maxLength(128);

    validator.validate('city')
        .string()
        .maxLength(100)
        .check(defaultNameLightValidator);

    validator.validate('street')
        .string()
        .maxLength(100)
        .check(defaultNameLightValidator);

    return validator.result();
};

export const Profile: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, notificationService, configuratorService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();
    const configSection = configuratorService.getSectionConfig(ConfigSectionIds.CompanyInfoProfile);
    const formApi = useExternalFormApi<OrganizationProfile>();
    const [validationState] = useState(new ValidationState());
    const [formData, setFormData] = useState<OrganizationProfile>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const gridStore = useGridStore({
        locationAdmin0Code: formData?.locationAdmin0Code,
        locationAdmin1Code: formData?.locationAdmin1Code,
    });

    useNavigationBlocker({
        isDirty,
        prompt: {
            title: lang.PROFILE,
            message: lang.COMPANY_DETAILS_HAVENT_BEEN_SAVED,
        },
    });

    const defaultFieldWidth = CONTROL_SIZE.m;

    const saveProfileEnabled = !portalUser.isPortalOperator();
    const nameEnabled = portalUser.isPortalOrSiteAdmin() || portalUser.isPortalOperator();

    const instanceUidVisible = configSection
        ? configSection.getItemState('instanceUidField') === ConfigStates.Visible
        : false;
    const proPartnerIdVisible = configSection
        ? configSection.getItemState('proPartnerIdField') === ConfigStates.Visible
        : false;
    const emailTooltipIconVisible = configSection
        ? configSection.getItemState('emailTooltipIcon') === ConfigStates.Visible
        : false;
    const profileAliasHidden = configSection
        ? configSection.getItemState('profileAliasField') === ConfigStates.Hidden
        : false;

    const saveDisabled = !saveProfileEnabled || isSaved || !isDirty || !isFormValid;

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            transportService.request<CompanyGetCompanyParam, RequestSuccessResponse<OrganizationProfile>>(
                '/Company/GetCompanyInfo',
                { id: portalUser.companyId }
            )
                .then(({ data }: RequestSuccessResponse<OrganizationProfile>) => data),
        ])
            .then(([companyInfo]) => {
                gridStore.initialize({ locationAdmin0Code: companyInfo.locationAdmin0Code, locationAdmin1Code: companyInfo.locationAdmin1Code });
                setFormData(companyInfo);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onSave = () => {
        setIsLoading(true);
        const companyStatus = portalUser.companyStatus as unknown as CompanyStatusRepresentation;
        const countryName = gridStore?.countryName || '';
        const regionName = gridStore?.regionName || '';
        return Promise.resolve(companyStatus === CompanyStatusRepresentation.Disabled)
            .then(isDisabled => isDisabled ? Promise.reject() : Promise.resolve())
            .catch(() => notificationService.info(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS)
                .then(() => {
                    setIsDirty(false);
                    setIsSaved(false);
                    return Promise.reject();
                })
            )
            .then(() => transportService.request('/Company/SaveCompanyInfo', { ...formApi.value, country: countryName, region: regionName }))
            .then(() => {
                setIsDirty(false);
                setIsSaved(true);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        !isLoading ?
            (
                <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
                    <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xl}>
                        <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.xl}>
                            <Button disabled={saveDisabled} onClick={onSave} style={{ width: 'max-content' }}>{lang.SAVE}</Button>
                            {isDirty && !isSaved && <NoteBar isCompact>{lang.YOUR_CHANGES_ARE_NOT_SAVED}</NoteBar>}
                            {isSaved && !isDirty && <NoteBar status={NOTEBAR_STATUS.ok} isCompact>{lang.YOUR_CHANGES_ARE_SAVED}</NoteBar>}
                        </StackView>

                        <Text size={TEXT_SIZE.m}>{lang.TYPE_IN_YOUR_COMPANY_ADDRESS}</Text>

                        <Form
                            value={formData}
                            externalFormApi={formApi}
                            validationState={validationState}
                            validate={data => formValidate(lang, data)}
                            onChange={() => {
                                const isDataChanged = formApi.isDataChanged();
                                setIsDirty(isDataChanged);
                                setIsSaved(!isDataChanged);
                                setIsFormValid(formApi.isValid());
                            }}
                        >
                            <FormLayout inlineLabel>
                                <TextInput
                                    name={'name'}
                                    label={lang.COMPANY_NAME}
                                    size={defaultFieldWidth}
                                    disabled={!nameEnabled}
                                />

                                {instanceUidVisible && (
                                    <TextInput
                                        name={'instanceUid'}
                                        label={lang.INTERNAL_ID}
                                        size={defaultFieldWidth}
                                        disabled
                                    />
                                )}

                                {proPartnerIdVisible && (
                                    <TextInput
                                        name={'proPartnerId'}
                                        label={lang.VEEAM_PRO_PARTNER_ID}
                                        size={defaultFieldWidth}
                                    />
                                )}

                                {!profileAliasHidden && (
                                    <TextInput
                                        name={'alias'}
                                        label={lang.LOGIN_ALIAS}
                                        size={defaultFieldWidth}
                                    />
                                )}

                                <TextInput
                                    name={'taxId'}
                                    label={lang.TAX_ID}
                                    size={defaultFieldWidth}
                                />

                                <Combobox
                                    name={'locationAdmin0Code'}
                                    label={lang.COUNTRY}
                                    data={gridStore.countries || []}
                                    valueGetter={item => item.id}
                                    textGetter={item => item.name}
                                    size={defaultFieldWidth}
                                    onChange={gridStore.selectCountry}
                                    controlRenderer={createSearchableControl()}
                                    suffix={<HighchartSourceIndicator />}
                                />

                                <Combobox
                                    name={'locationAdmin1Code'}
                                    label={lang.STATE_REGION}
                                    data={gridStore.regions || []}
                                    valueGetter={item => item.id}
                                    textGetter={item => item.name}
                                    disabled={gridStore.regions.length === 0}
                                    size={defaultFieldWidth}
                                    onChange={(v) => {
                                        gridStore.selectRegion(v);
                                        formApi.setValue('locationAdmin1Code', v);
                                    }}
                                    controlRenderer={createSearchableControl()}
                                    isLoading={gridStore.isLoading}
                                />

                                <TextInput
                                    name={'zipCode'}
                                    label={lang.ZIP_CODE}
                                    size={defaultFieldWidth}
                                />

                                <TextInput
                                    name={'domain'}
                                    label={lang.WEB_SITE}
                                    size={defaultFieldWidth}
                                />

                                <TextInput
                                    name={'city'}
                                    label={lang.CITY}
                                    size={defaultFieldWidth}
                                />

                                <TextInput
                                    name={'street'}
                                    label={lang.STREET}
                                    size={defaultFieldWidth}
                                />

                                <TextInput
                                    name={'telephone'}
                                    label={lang.PHONE}
                                    size={defaultFieldWidth}
                                />

                                <TextInput
                                    name={'emailAddress'}
                                    label={lang.EMAIL}
                                    inlineLabel
                                    size={defaultFieldWidth}
                                    suffix={emailTooltipIconVisible && <SignPost text={lang.THIS_EMAIL_WILL_BE_USED_IN_WELCOME} />}
                                />
                            </FormLayout>
                        </Form>
                    </StackView>

                </TabBarBodyLayout>
            )
            : <PortalSpinner delayTime={150} />
    );
};
