/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatStr } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { ScheduledVbrTaskType } from '../enums';

interface DialogTexts {
    header: string;
    description: string;
    startRadioBtn: string;
    cancelRadioBtn: string;
    rescheduleRadioBtn: string;
}

export const getDialogTexts = (taskType: ScheduledVbrTaskType, lang: VspcLang): DialogTexts => {
    let dialogTexts: DialogTexts;

    switch (taskType) {
        case (ScheduledVbrTaskType.Patch):
            const patchLowerCased = lang.PATCH.toLowerCase();
            dialogTexts = {
                header: formatStr(lang.SCHEDULED_0, lang.PATCH),
                description: formatStr(`${lang.MODIFY_CONFIGURED_VEEAM_BACKUP_AND_REPLICATION}.`, patchLowerCased),
                rescheduleRadioBtn: formatStr(lang.RESCHEDULE, patchLowerCased),
                startRadioBtn: formatStr(lang.START_NOW, patchLowerCased),
                cancelRadioBtn: formatStr(lang.CANCEL_0, patchLowerCased),
            }; break;
        case (ScheduledVbrTaskType.Upgrade):
            const upgradeLowerCased = lang.UPGRADE.toLowerCase();
            dialogTexts = {
                header: formatStr(lang.SCHEDULED_0, lang.UPGRADE),
                description: formatStr(`${lang.MODIFY_CONFIGURED_VEEAM_BACKUP_AND_REPLICATION}.`, upgradeLowerCased),
                rescheduleRadioBtn: formatStr(lang.RESCHEDULE, upgradeLowerCased),
                startRadioBtn: formatStr(lang.START_NOW, upgradeLowerCased),
                cancelRadioBtn: formatStr(lang.CANCEL_0, upgradeLowerCased),
            }; break;
        default:
            const deployLowerCased = lang.DEPLOYMENT.toLowerCase();
            dialogTexts = {
                header: formatStr(lang.SCHEDULED_0, lang.DEPLOYMENT),
                description: formatStr(`${lang.MODIFY_CONFIGURED_VEEAM_BACKUP_AND_REPLICATION}.`, deployLowerCased),
                rescheduleRadioBtn: formatStr(lang.RESCHEDULE, deployLowerCased),
                startRadioBtn: formatStr(lang.START_NOW, deployLowerCased),
                cancelRadioBtn: formatStr(lang.CANCEL_0, deployLowerCased),
            };
    }

    return dialogTexts;
};
