/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useRef, useState } from 'react';
import { HttpMethods } from '@veeam-vspc/shared/core';
import { useGlobalServices } from '@veeam-vspc/shared/components';

import type { BlobFileData } from '@veeam-vspc/shared/services';

import type { UseImageLoaderData } from './interfaces';

const initialRandom = Date.now();
const initialState = {
    image: '',
    random: initialRandom,
};

/**
 * @description Using for uploading images via get request, not just get static images
 */
export function useImageLoader(url: string, noAuth = true): UseImageLoaderData {
    const { fileTransportService } = useGlobalServices();
    const [imageLoaderState, setImageLoaderState] = useState(initialState);
    const mountedRef = useRef(true);
    const { image, random } = imageLoaderState;
    const forceReload = (newRandom = Date.now()) => setImageLoaderState({ image, random: newRandom });

    useEffect(() => {
        mountedRef.current = true;
        fileTransportService.downloadFileAjax(url, null, {
            noAuth,
            noAutoDownload: true,
            requestInit: {
                method: HttpMethods.Get,
            },
        })
            .then((resp: BlobFileData) => {
                const reader = new FileReader();

                reader.readAsDataURL(resp.blob);
                reader.onloadend = () => {
                    if (mountedRef.current) {
                        setImageLoaderState({ image: reader.result as string, random });
                    }
                };
            })
            .catch(err => err && console.error(err));

        return () => {
            if (mountedRef.current) {
                mountedRef.current = false;
            }
        };
    }, [random]);

    return { image, forceReload };
}
