/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum CompanyVboRepositoryStorageType {
    Unknown = 'unknown',
    JetDatabase = 'jetDatabase',
    ObjectStorage = 'objectStorage',
    ArchiveObjectStorage = 'archiveObjectStorage',
}
