/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Text,
    FormLayout,
    useGlobalLang,
    FormTitle,
    TEXT_VIEW,
    FieldLayout,
    STACK_DIRECTION,
    Combobox,
    Button,
    LabelGroup,
    CONTROL_SIZE,
    NumberInput,
    Checkbox,
    LinkButton,
    useModal,
    RadiobuttonKit,
    Form,
    useExternalFormApi,
    useGlobalServices,
    SortDirection,
    FilterCollation,
    FormValidator,
    isValid,
    ComboboxKit,
    createSearchableControl,
    combineFilters,
    FilterOperation,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { observer } from 'mobx-react-lite';
import {
    BackupServerBackupJobDailyKinds,
    BackupServerBackupJobDayNumberInMonth,
    BackupServerBackupJobPeriodicallyKindsNullable,
    BackupServerJobStatus,
    BackupServerJobType,
    DaysOfWeekNullable } from '@veeam-vspc/models/rest';
import { deepCopy } from '@veeam-vspc/shared/core';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type {
    BackupServerBackupJobConfiguration,
    BackupServerBackupJobSchedule,
    BackupServerBackupJobWindowSetting,
    BackupServerJob,
    Month } from '@veeam-vspc/models/rest';
import type { FormErrors, WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { useJobWizardStore } from '../../stores';
import { TimeField } from 'components/controls';
import windowIcon from 'images/window.svg';
import { DaysModal } from './components/DaysModal/DaysModal';
import { MonthsModal } from './components/MonthsModal/MonthsModal';
import { ConfigureScheduleModal } from './components/ConfigureScheduleModal/ConfigureScheduleModal';

import type { JobWizardStore } from '../../stores';

export const stepValidate = (schedule: BackupServerBackupJobSchedule): FormErrors<BackupServerBackupJobSchedule> => {
    const validator = new FormValidator(schedule);

    if (
        schedule.runAutomatically
        && schedule.periodically.isEnabled
        && schedule.periodically.periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Minutes
    ) {
        validator
            .validate('periodically.frequency')
            .number()
            .required()
            .max(1440)
            .min(1);
    }

    if (schedule.runAutomatically && schedule.afterThisJob.isEnabled) {
        validator
            .validate('afterThisJob.jobName')
            .string()
            .required();
    }

    if (schedule.retry.isEnabled) {
        validator
            .validate('retry.retryCount')
            .number()
            .required()
            .max(999)
            .min(1);

        validator
            .validate('retry.awaitMinutes')
            .number()
            .required()
            .max(999)
            .min(1);
    }

    return validator.result();
};

export const getJobScheduleStep = (lang: VspcLang, store: JobWizardStore): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: capitalize(lang.JOB_SCHEDULE),
    validate: () => {
        const validationResult = isValid(stepValidate, store.data.schedule);

        return validationResult;
    },
    isHidden: () => store.isScheduleHidden,
    render: data => <JobScheduleStep {...data} />,
});

const JobScheduleStep = observer(({ validationState, isEdit }: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const formApi = useExternalFormApi<BackupServerBackupJobSchedule>();
    const { transportService } = useGlobalServices();
    const schedule = deepCopy(wizardStore.data.schedule);
    const [periodicallyKind, setPeriodicallyKind] =
        useState<BackupServerBackupJobPeriodicallyKindsNullable | -1>(() => {
            if (schedule.continuously.isEnabled) {
                return -1;
            }

            return schedule.periodically.periodicallyKind;
        });

    const [availableJobsToRunAfter, setAvailableJobsToRunAfter] = useState<BackupServerJob[]>([]);

    const handleDataChange = (data: BackupServerBackupJobSchedule) => {
        if (!data.runAutomatically && data.afterThisJob.isEnabled && !data.afterThisJob.jobName) {
            data.afterThisJob.isEnabled = false;
            data.daily.isEnabled = true;
        }

        wizardStore.updateSchedule(data);
    };

    useEffect(() => {
        (async() => {
            const jobs = await transportService
                .get<any, RequestSuccessResponse<BackupServerJob[]>>(
                    `/infrastructure/backupServers/${wizardStore.serverUid}/jobs`,
                    {
                        sort: JSON.stringify([{ property: 'name', direction: SortDirection.Asc, collation: FilterCollation.Ignorecase }]),
                        filter: JSON.stringify([
                            {
                                operation: 'and',
                                items: [
                                    ...(
                                        isEdit ? [{
                                            property: 'instanceUid',
                                            operation: FilterOperation.NotEquals,
                                            value: wizardStore.data.instanceUid,
                                        }]
                                            : []
                                    ),
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.PostgreSqlLogBackup,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.SqlLogBackup,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.OracleLogBackup,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.Unknown,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.SimpleBackupCopy,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.AzureBackupJob,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.AwsBackupJob,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.AhvStorageSnapshotJob,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.GoogleBackupJob,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.SureBackup,
                                    },
                                    {
                                        property: 'type',
                                        operation: FilterOperation.NotEquals,
                                        value: BackupServerJobType.AgentPolicy,
                                    },
                                ],
                            },
                        ]),
                    }
                )
                .then((response: RequestSuccessResponse<BackupServerJob[]>) => response.data);

            if (wizardStore.data.schedule.afterThisJob.jobName) {
                const job = await transportService
                    .get<any, RequestSuccessResponse<BackupServerJob[]>>(
                        `/infrastructure/backupServers/${wizardStore.serverUid}/jobs`,
                        {
                            filter: JSON.stringify(combineFilters({ name: wizardStore.data.schedule.afterThisJob.jobName })),
                        }
                    )
                    .then((response: RequestSuccessResponse<BackupServerJob[]>) => response.data[0]);

                if (!job) {
                    jobs.unshift({
                        name: wizardStore.data.schedule.afterThisJob.jobName,
                        isEnabled: false,
                        status: BackupServerJobStatus.None,
                    });
                }
            }

            setAvailableJobsToRunAfter(jobs);
        })();
    }, []);

    const [daysModal, daysModalActions] = useModal<Array<DaysOfWeekNullable>>({
        render: ({ deactivate, data }) => (
            <DaysModal
                data={data}
                onConfirm={(newData) => {
                    wizardStore.updateSchedule({
                        ...schedule,
                        daily: {
                            ...schedule.daily,
                            days: newData,
                        },
                    });

                    formApi.setValue(deepCopy(wizardStore.data.schedule));
                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [monthsModal, monthsModalActions] = useModal<Array<Month>>({
        render: ({ deactivate, data }) => (
            <MonthsModal
                data={data}
                onConfirm={(newData) => {
                    wizardStore.updateSchedule({
                        ...schedule,
                        monthly: {
                            ...schedule.monthly,
                            months: newData,
                        },
                    });

                    formApi.setValue(deepCopy(wizardStore.data.schedule));
                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [scheduleModal, scheduleModalActions] = useModal<BackupServerBackupJobWindowSetting>({
        render: ({ deactivate, data }) => (
            <ConfigureScheduleModal
                value={data}
                onConfirm={(newData) => {
                    if (schedule.periodically.isEnabled) {
                        wizardStore.updateSchedule({
                            ...schedule,
                            periodically: {
                                ...schedule.periodically,
                                backupWindow: newData,
                            },
                        });
                    } else if (schedule.continuously.isEnabled) {
                        wizardStore.updateSchedule({
                            ...schedule,
                            continuously: {
                                ...schedule.continuously,
                                backupWindow: newData,
                            },
                        });
                    }

                    formApi.setValue(deepCopy(wizardStore.data.schedule));
                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [backupWindowModal, backupWindowModalActions] = useModal<BackupServerBackupJobWindowSetting>({
        render: ({ deactivate, data }) => (
            <ConfigureScheduleModal
                value={data}
                onConfirm={(newData) => {
                    wizardStore.updateSchedule({
                        ...schedule,
                        backupWindow: {
                            ...schedule.backupWindow,
                            backupWindow: newData,
                        },
                    });

                    formApi.setValue(deepCopy(wizardStore.data.schedule));
                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const handleChangeType = (
        type: keyof Pick<BackupServerBackupJobSchedule, 'daily' | 'monthly' | 'periodically' | 'continuously' | 'afterThisJob'>
    ) => {
        const newSchedule = deepCopy(schedule);

        newSchedule.daily.isEnabled = false;
        newSchedule.monthly.isEnabled = false;
        newSchedule.periodically.isEnabled = false;
        newSchedule.continuously.isEnabled = false;
        newSchedule.afterThisJob.isEnabled = false;

        newSchedule[type].isEnabled = true;

        if (type === 'continuously') {
            setPeriodicallyKind(-1);
        }

        if (type !== 'continuously' && schedule.continuously.isEnabled) {
            setPeriodicallyKind(BackupServerBackupJobPeriodicallyKindsNullable.Hours);

            newSchedule.periodically.periodicallyKind = BackupServerBackupJobPeriodicallyKindsNullable.Hours;
            newSchedule.periodically.frequency = 1;
            newSchedule.continuously.isEnabled = false;
        }

        formApi.setValue(newSchedule);
        formApi.validate();
    };

    const daysOfWeek: { id: DaysOfWeekNullable; name: string; }[] = [
        {
            id: DaysOfWeekNullable.Monday,
            name: lang.MONDAY,
        },
        {
            id: DaysOfWeekNullable.Tuesday,
            name: lang.TUESDAY,
        },
        {
            id: DaysOfWeekNullable.Wednesday,
            name: lang.WEDNESDAY,
        },
        {
            id: DaysOfWeekNullable.Thursday,
            name: lang.THURSDAY,
        },
        {
            id: DaysOfWeekNullable.Friday,
            name: lang.FRIDAY,
        },
        {
            id: DaysOfWeekNullable.Saturday,
            name: lang.SATURDAY,
        },
        {
            id: DaysOfWeekNullable.Sunday,
            name: lang.SUNDAY,
        },
    ];

    const dayOfMonth: { id: number; name: string; }[] = Array.from(
        { length: 31 },
        (x, index) => ({ id: index + 1, name: String(index + 1) })
    );

    dayOfMonth.push({ id: 32, name: lang.LAST });

    const hours: number[] = [1, 2, 3, 4, 6, 8, 12, 24];

    return (
        <StepLayout
            title={capitalize(lang.JOB_SCHEDULE)}
        >
            <Form
                value={schedule}
                onChange={handleDataChange}
                externalFormApi={formApi}
                validate={stepValidate}
                validationState={validationState}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.SCHEDULE}</FormTitle>
                    <Text view={TEXT_VIEW.light}>{`${lang.CONFIGURE_JOB_SCHEDULING_SETTINGS}.`}</Text>

                    <Checkbox name={'runAutomatically'}>
                        {lang.RUN_THE_JOB_AUTOMATICALLY}
                    </Checkbox>

                    <LabelGroup
                        disabled={!schedule.runAutomatically}
                        label={(
                            <FieldLayout>
                                <RadiobuttonKit
                                    checked={schedule.daily.isEnabled}
                                    onChange={() => handleChangeType('daily')}
                                >
                                    {lang.DAILY_AT_THIS_TIME}
                                </RadiobuttonKit>
                            </FieldLayout>
                        )}
                    >
                        <FormLayout direction={STACK_DIRECTION.row}>
                            <FieldLayout
                                disabled={!schedule.daily.isEnabled}
                            >
                                <TimeField
                                    name='daily.localTime'
                                    value={schedule.daily.localTime}
                                    shortFormat
                                    minutesInterval={15}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.m}
                                disabled={!schedule.daily.isEnabled}
                            >
                                <Combobox
                                    name='daily.dailyKind'
                                    value={schedule.daily.dailyKind}
                                    onChange={(value) => {
                                        const newSchedule = {
                                            ...schedule,
                                            daily: {
                                                ...schedule.daily,
                                                dailyKind: value,
                                            },
                                        };

                                        wizardStore.updateSchedule(newSchedule);
                                        formApi.setValue(newSchedule);
                                    }}
                                    data={[
                                        { value: BackupServerBackupJobDailyKinds.Everyday, text: lang.EVERYDAY },
                                        { value: BackupServerBackupJobDailyKinds.WeekDays, text: lang.ON_WEEK_DAYS },
                                        { value: BackupServerBackupJobDailyKinds.SelectedDays, text: lang.SELECTED_DAYS },
                                    ]}
                                    valueGetter={x => x.value}
                                    textGetter={x => x.text}
                                    size={CONTROL_SIZE.m}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={
                                    !(schedule.daily.isEnabled && schedule.daily.dailyKind === BackupServerBackupJobDailyKinds.SelectedDays)
                                }
                            >
                                <Button
                                    onClick={() => daysModalActions.activate(schedule.daily.days)}
                                    disabled={
                                        !(schedule.daily.isEnabled && schedule.daily.dailyKind === BackupServerBackupJobDailyKinds.SelectedDays)
                                    }
                                >
                                    {lang.DAYS}
                                </Button>
                            </FieldLayout>
                        </FormLayout>
                    </LabelGroup>

                    <LabelGroup
                        disabled={!schedule.runAutomatically}
                        label={(
                            <FieldLayout>
                                <RadiobuttonKit
                                    checked={schedule.monthly.isEnabled}
                                    onChange={() => handleChangeType('monthly')}
                                >
                                    {lang.MONTHLY_AT_THIS_TIME}
                                </RadiobuttonKit>
                            </FieldLayout>
                        )}
                    >
                        <FormLayout direction={STACK_DIRECTION.row}>
                            <FieldLayout
                                disabled={!schedule.monthly.isEnabled}
                            >
                                <TimeField
                                    name='monthly.localTime'
                                    value={schedule.monthly.localTime}
                                    shortFormat
                                    minutesInterval={15}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={!schedule.monthly.isEnabled}

                            >
                                <Combobox
                                    name='monthly.dayNumberInMonth'
                                    value={schedule.monthly.dayNumberInMonth}
                                    onChange={(value) => {
                                        wizardStore.updateSchedule({
                                            ...schedule,
                                            monthly: {
                                                ...schedule.monthly,
                                                dayNumberInMonth: value,
                                            },
                                        });

                                        formApi.setValue(deepCopy(wizardStore.data.schedule));
                                    }}
                                    data={[
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.First,
                                            text: lang.FIRST,
                                        },
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.Second,
                                            text: lang.SECOND,
                                        },
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.Third,
                                            text: lang.THIRD,
                                        },
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.Fourth,
                                            text: lang.FOURTH,
                                        },
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.Last,
                                            text: lang.LAST,
                                        },
                                        {
                                            id: BackupServerBackupJobDayNumberInMonth.OnDay,
                                            text: lang.THIS_DAY,
                                        },
                                    ]}
                                    valueGetter={x => x.id}
                                    textGetter={x => x.text}
                                    size={CONTROL_SIZE.xs}
                                    disabled={!schedule.monthly.isEnabled}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={!schedule.monthly.isEnabled}
                                style={{ display: schedule.monthly.dayNumberInMonth === BackupServerBackupJobDayNumberInMonth.OnDay
                                    ? 'initial'
                                    : 'none',
                                }}
                            >
                                <Combobox
                                    name='monthly.dayOfMonth'
                                    value={schedule.monthly.dayOfMonth}
                                    onChange={(value) => {
                                        const newSchedule = {
                                            ...schedule,
                                            monthly: {
                                                ...schedule.monthly,
                                                dayOfMonth: value,
                                            },
                                        };
                                        wizardStore.updateSchedule(newSchedule);
                                        formApi.setValue(newSchedule);
                                    }}
                                    size={CONTROL_SIZE.xs}
                                    data={dayOfMonth}
                                    valueGetter={x => x.id}
                                    textGetter={x => x.name}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={!schedule.monthly.isEnabled}
                                style={{ display: schedule.monthly.dayNumberInMonth === BackupServerBackupJobDayNumberInMonth.OnDay
                                    ? 'none'
                                    : 'initial',
                                }}
                            >
                                <Combobox
                                    name='monthly.dayOfWeek'
                                    value={schedule.monthly.dayOfWeek}
                                    onChange={(value) => {
                                        const newSchedule = {
                                            ...schedule,
                                            monthly: {
                                                ...schedule.monthly,
                                                dayOfWeek: value,
                                            },
                                        };
                                        wizardStore.updateSchedule(newSchedule);
                                        formApi.setValue(newSchedule);
                                    }}
                                    size={CONTROL_SIZE.xs}
                                    data={daysOfWeek}
                                    valueGetter={x => x.id}
                                    textGetter={x => x.name}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={!schedule.monthly.isEnabled}
                            >
                                <Button
                                    onClick={() => monthsModalActions.activate(schedule.monthly.months)}
                                    disabled={!schedule.monthly.isEnabled}
                                >
                                    {lang.MONTHS}
                                </Button>
                            </FieldLayout>
                        </FormLayout>
                    </LabelGroup>

                    <LabelGroup
                        disabled={!schedule.runAutomatically}
                        label={(
                            <FieldLayout>
                                <RadiobuttonKit
                                    checked={schedule.periodically.isEnabled || schedule.continuously.isEnabled}
                                    onChange={() => handleChangeType('periodically')}
                                >
                                    {`${lang.PERIODICALLY_EVERY}:`}
                                </RadiobuttonKit>
                            </FieldLayout>
                        )}
                    >
                        <FormLayout direction={STACK_DIRECTION.row}>
                            {
                                periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Hours
                                    ? (
                                        <FieldLayout
                                            size={CONTROL_SIZE.xs}
                                            disabled={!schedule.periodically.isEnabled}
                                        >
                                            <Combobox
                                                name='periodically.frequency'
                                                value={schedule.periodically.frequency}
                                                onChange={(value) => {
                                                    const newSchedule = {
                                                        ...schedule,
                                                        periodically: {
                                                            ...schedule.periodically,
                                                            frequency: value,
                                                        },
                                                    };
                                                    wizardStore.updateSchedule(newSchedule);
                                                    formApi.setValue(newSchedule);
                                                }}
                                                size={CONTROL_SIZE.xs}
                                                data={hours}
                                                valueGetter={x => x}
                                                textGetter={x => String(x)}
                                            />
                                        </FieldLayout>
                                    ) : (
                                        <FieldLayout
                                            size={CONTROL_SIZE.xs}
                                            disabled={!schedule.periodically.isEnabled}
                                        >
                                            <NumberInput
                                                size={CONTROL_SIZE.xs}
                                                name={'periodically.frequency'}
                                                value={schedule.periodically.frequency}
                                                minValue={1}
                                                maxValue={1440}
                                                forceReset
                                            />
                                        </FieldLayout>
                                    )
                            }

                            <FieldLayout
                                size={CONTROL_SIZE.m}
                                disabled={!schedule.periodically.isEnabled && !schedule.continuously.isEnabled}
                            >
                                <ComboboxKit
                                    value={periodicallyKind}
                                    onChange={(value) => {
                                        if (value === -1) {
                                            handleChangeType('continuously');

                                            return;
                                        }

                                        setPeriodicallyKind(value);

                                        const newSchedule = {
                                            ...schedule,
                                            periodically: {
                                                ...schedule.periodically,
                                                periodicallyKind: value,
                                                isEnabled: true,
                                            },
                                            continuously: {
                                                ...schedule.continuously,
                                                isEnabled: false,
                                            },
                                        };

                                        if (
                                            value === BackupServerBackupJobPeriodicallyKindsNullable.Hours
                                            && !hours.includes(schedule.periodically.frequency)
                                        ) {
                                            newSchedule.periodically.frequency = 1;
                                        }

                                        wizardStore.updateSchedule(newSchedule);
                                        formApi.setValue(newSchedule);
                                        formApi.validate();
                                    }}
                                    data={[
                                        { value: BackupServerBackupJobPeriodicallyKindsNullable.Hours, text: capitalize(lang.HOURS) },
                                        { value: BackupServerBackupJobPeriodicallyKindsNullable.Minutes, text: lang.MINUTES },
                                        { value: -1 as const, text: lang.CONTINUOUSLY },
                                    ]}
                                    valueGetter={x => x.value}
                                    textGetter={x => x.text}
                                    size={CONTROL_SIZE.m}
                                />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.xs}
                                disabled={!schedule.periodically.isEnabled && !schedule.continuously.isEnabled}
                            >
                                <Button
                                    onClick={() => {
                                        if (schedule.periodically.isEnabled) {
                                            scheduleModalActions.activate(schedule.periodically.backupWindow);
                                        } else if (schedule.continuously.isEnabled) {
                                            scheduleModalActions.activate(schedule.continuously.backupWindow);
                                        }
                                    }}
                                >
                                    {lang.SCHEDULE}
                                </Button>
                            </FieldLayout>
                        </FormLayout>
                    </LabelGroup>

                    <LabelGroup
                        disabled={!schedule.runAutomatically}
                        label={(
                            <FieldLayout>
                                <RadiobuttonKit
                                    checked={schedule.afterThisJob.isEnabled}
                                    onChange={() => handleChangeType('afterThisJob')}
                                >
                                    {`${lang.AFTER_THIS_JOB}:`}
                                </RadiobuttonKit>
                            </FieldLayout>
                        )}
                    >
                        <FormLayout direction={STACK_DIRECTION.row}>
                            <FieldLayout size={CONTROL_SIZE.xs}>
                                <span />
                            </FieldLayout>

                            <FieldLayout
                                size={CONTROL_SIZE.m}
                                disabled={!schedule.afterThisJob.isEnabled}
                            >
                                <Combobox
                                    name='afterThisJob.jobName'
                                    value={schedule.afterThisJob.jobName}
                                    onChange={(value) => {
                                        const newSchedule = {
                                            ...schedule,
                                            afterThisJob: {
                                                ...schedule.afterThisJob,
                                                jobName: value,
                                            },
                                        };
                                        wizardStore.updateSchedule(newSchedule);
                                        formApi.setValue(newSchedule);
                                    }}
                                    data={availableJobsToRunAfter}
                                    valueGetter={x => x.name}
                                    textGetter={x => x.name}
                                    size={CONTROL_SIZE.m}
                                    isOptionDisabled={x => !x.instanceUid}
                                    controlRenderer={createSearchableControl()}
                                />
                            </FieldLayout>
                        </FormLayout>
                    </LabelGroup>
                </FormLayout>

                <FormLayout inlineLabel>
                    <FormTitle>{lang.AUTOMATIC_RETRY}</FormTitle>

                    <LabelGroup
                        disabled={!schedule.runAutomatically}
                        label={(
                            <Checkbox
                                name={'retry.isEnabled'}
                            />
                        )}
                    >
                        <FormLayout inlineLabel>
                            <NumberInput
                                label={lang.RETRY_FAILED_OBJECT_PROCESSING}
                                name={'retry.retryCount'}
                                suffix={lang.TIMES}
                                disabled={!schedule.retry.isEnabled}
                                minValue={1}
                                maxValue={999}
                                forceReset
                            />

                            <NumberInput
                                name={'retry.awaitMinutes'}
                                suffix={lang.MINUTES.toLowerCase()}
                                label={lang.WAIT_BEFORE_EACH_RETRY_ATTEMPT_FOR}
                                disabled={!schedule.retry.isEnabled || schedule.continuously.isEnabled}
                                minValue={1}
                                maxValue={999}
                                forceReset
                            />
                        </FormLayout>
                    </LabelGroup>
                </FormLayout>


                <FormLayout>
                    <FormTitle>{lang.BACKUP_WINDOW}</FormTitle>

                    <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                        <Checkbox
                            name={'backupWindow.isEnabled'}
                            disabled={!schedule.runAutomatically}
                        >
                            {lang.TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW}
                        </Checkbox>

                        <LinkButton
                            iconBefore={windowIcon}
                            onClick={() => backupWindowModalActions.activate(schedule.backupWindow.backupWindow)}
                            disabled={!schedule.backupWindow.isEnabled || !schedule.runAutomatically}
                        >
                            {`${lang.WINDOW}...`}
                        </LinkButton>
                    </FormLayout>
                </FormLayout>
            </Form>

            {daysModal}
            {monthsModal}
            {scheduleModal}
            {backupWindowModal}
        </StepLayout>
    );
});
