/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SIZE_XL, SIZE_XXXL } from '@veeam-vspc/shared/components';

import sprites from 'images/sprite-icons.png';
import openApi from 'images/open-api.png';

export const OpenApiIcon = styled.div`
  display: inline-block;
  background-image: url(${openApi});
  width: ${SIZE_XL};
  height: ${SIZE_XL};
`;

export const SpriteIcons = styled.div`
  display: inline-block;
  background-image: url(${sprites});
`;

const SpriteIconsXl = styled(SpriteIcons)`
  width: ${SIZE_XL};
  height: ${SIZE_XL};
`;

export const ConfigIcon = styled(SpriteIcons)`
  background-position: 0 0;
  width: ${SIZE_XXXL};
  height: ${SIZE_XXXL};

  margin-bottom: ${SIZE_XL};
`;

export const SwaggerIcon = styled(SpriteIconsXl)`
  background-position: -66px 0;
`;

export const UserGuideIcon = styled(SpriteIconsXl)`
  background-position: -66px -34px;
`;

export const VeeamForumsIcon = styled(SpriteIconsXl)`
  background-position: -98px 0;
`;
