/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    useGlobalLang,
    TabBar,
    StackView,
    SPACE_FILL,
    INDENT,
    STACK_DIRECTION,
} from '@veeam-vspc/shared/components';
import { deepCopy } from '@veeam-vspc/shared/core';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { BackupServerBackupJobIndexingSettings } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { WindowsSettings } from '../WindowsSettings/WindowsSettings';
import { LinuxSettings } from '../LinuxSettings/LinuxSettings';

interface IndexingSettingsDialogProps {
    deactivate: () => void;
    onConfirm: (data: BackupServerBackupJobIndexingSettings) => void;
    data?: BackupServerBackupJobIndexingSettings;
}

export const IndexingSettingsDialog = observer((props: IndexingSettingsDialogProps) => {
    const lang = useGlobalLang<VspcLang>();

    const [data, setData] = useState(deepCopy(props.data));

    const pages = {
        windows: lang.WINDOWS,
        linux: lang.LINUX,
    };

    const [activePage, selectPage] = useState(pages.windows);

    return (
        <Dialog
            header={capitalize(lang.INDEXING_SETTINGS)}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        props.onConfirm(data);

                        props.deactivate();
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <StackView direction={STACK_DIRECTION.column}>
                <TabBar
                    items={[
                        {
                            title: pages.windows,
                            onClick: () => selectPage(pages.windows),
                            active: pages.windows === activePage,
                        },
                        {
                            title: pages.linux,
                            onClick: () => selectPage(pages.linux),
                            active: pages.linux === activePage,
                        },
                    ]}
                    style={{ flexGrow: 0 }}
                />

                <StackView spaceFill={SPACE_FILL.all} spaceTop={INDENT.m}>
                    <WindowsSettings active={activePage === pages.windows} data={data} onChange={setData} />
                    <LinuxSettings active={activePage === pages.linux} data={data} onChange={setData} />
                </StackView>
            </StackView>
        </Dialog>
    );
});
