/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { NotificationSettings } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { SmtpStore } from './smtp-store';
import { ConfigurationFormStore } from './configuration-form-store';

import type { ConfigurationRequestsStore } from './configuration-requests-store';

export class ConfigurationStore {
    readonly formStore: ConfigurationFormStore;
    readonly smtpStore: SmtpStore;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly requestsStore: ConfigurationRequestsStore,
        private readonly lang: VspcLang
    ) {
        this.formStore = new ConfigurationFormStore();
        this.smtpStore = new SmtpStore(requestsStore);
    }

    readonly initiateStoreData = (): void => {
        this.requestsStore.loadSettings()
            .then((notificationSettings: NotificationSettings): void => this.setPageStoresData(notificationSettings))
            .then(() => this.requestsStore.isLoading = false);
    };

    readonly setPageStoresData = (notificationSettings: NotificationSettings): void => {
        this.formStore.initiateStoreData(notificationSettings);
        this.smtpStore.initiateStoreData(notificationSettings.smtp);
    };

    readonly saveSettings = (): void => {
        this.formStore.resetChangesAreSavedFlag();

        this.requestsStore.patchSettings(this.formStore.initialData, this.formStore.dataForSaving)
            .then((notificationSettings: NotificationSettings): void => this.setPageStoresData(notificationSettings))
            .then((): void => {
                this.formStore.changesAreSaved = true;
                this.requestsStore.isLoading = false;
            });
    };

    readonly onResetClick = (): void => {
        this.notificationService
            .confirm(this.lang.EMAIL_SERVER_SETTINGS, this.lang.THIS_ACTION_WILL_RESET_THE_CONFIGURED)
            .then((buttonKey): void => {
                if (buttonKey === NotificationDialogsTextKeys.Yes) {
                    this.resetSmtpSettings();
                }
            });
    };

    private readonly resetSmtpSettings = (): void => {
        this.formStore.resetChangesAreSavedFlag();

        this.requestsStore.patchSettings(this.formStore.initialData, this.formStore.dataForSmtpResetting)
            .then((notificationSettings: NotificationSettings): void => {
                this.setPageStoresData(notificationSettings);
                this.requestsStore.isLoading = false;

                this.notificationService
                    .success(this.lang.EMAIL_SERVER_SETTINGS, this.lang.EMAIL_SERVER_SETTINGS_HAVE_BEEN_RESET);
            });
    };
}
