/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum DefaultEmailOptions {
    Empty = '',
    CompanyOwner = '%Company Owner%',
    PortalAdmins = '%Portal Admins%',
    LocationAdmins = '%Location Admins%',
    Reseller = '%Reseller Owner%',
    ResellerAdmins = '%Reseller Admins%',
    ResellerOperators = '%Reseller Operators%',
}
