/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { NotificationSettingsLevel } from '@veeam-vspc/models/rest';
import {
    BUTTON_VIEW,
    INDENT,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    StackView,
    Text,
    TEXT_WEIGHT,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { FormSliderBtn, FormSlider } from './components';
import { FormFieldNames } from '../../enums';
import { getLevelForForm, getLevelForSlider, getNotificationLevelFlags } from '../../helpers';
import { useConfigurationStore } from '../../stores';

export const FormSliderGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formStore: { formData: { level } } } = useConfigurationStore();
    const { everyEventEmails, noEmails, summaryEmails } = getNotificationLevelFlags(level);

    return (
        <StackView direction={STACK_DIRECTION.column} indentBottom={INDENT.xl}>
            <Text weight={TEXT_WEIGHT.bold}>{`${lang.CHOOSE_THE_LEVEL_OF_NOTIFICATIONS}:`}</Text>

            <StackView distribution={STACK_DISTRIBUTION.between} indentTop={INDENT.m}>
                <FormSliderBtn
                    formValue={NotificationSettingsLevel.Disabled}
                    hasBorder={noEmails}
                    label={lang.NO_EMAIL_NOTIFICATIONS}
                    name={FormFieldNames.Level}
                    ui={BUTTON_VIEW.alternate}
                />

                <FormSliderBtn
                    formValue={NotificationSettingsLevel.Summary}
                    hasBorder={summaryEmails}
                    label={lang.SUMMARY_EMAILS_ONLY}
                    name={FormFieldNames.Level}
                    ui={BUTTON_VIEW.alternate}
                />

                <FormSliderBtn
                    formValue={NotificationSettingsLevel.All}
                    hasBorder={everyEventEmails}
                    label={lang.EMAILS_ON_EVERY_EVENT}
                    name={FormFieldNames.Level}
                    ui={BUTTON_VIEW.alternate}
                />
            </StackView>

            <FormSlider
                convertValue
                min={0}
                max={100}
                name={FormFieldNames.Level}
                step={50}
                convertValueForFormCb={getLevelForForm}
                convertValueForSliderCb={getLevelForSlider}
            />
        </StackView>
    );
});
