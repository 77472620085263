/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { ProtectedVirtualMachineRestoreType } from '@veeam-vspc/models/web-controllers';

import type { ProtectedVirtualMachine, ProtectedVmFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { VirtualInfrastructureColumn, VirtualInfrastructureToolbar } from './components';


export interface VirtualInfrastructureProps {
    stateId: string;
}

export const VirtualInfrastructure: React.FC<VirtualInfrastructureProps> = ({ stateId }) => {
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.VirtualInfrastructure`;
    const columns: GridColumnProps<ProtectedVirtualMachine>[] = [
        {
            id: 'vmName',
            title: lang.VM,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'platform',
            hidden: true,
            title: lang.PLATFORM,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('ProtectedVmVirtualPlatformType', rowData.platform)}
                </TextColumn>
            ),
        },
        {
            id: 'serverName',
            hidden: true,
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.serverName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'guestOs',
            title: lang.GUEST_OS,
            cell: rowData => rowData.guestOs,
        },
        {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
        },
        {
            id: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.sourceSize} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.restorePoints',
            title: lang.BACKUPS,
            width: 90,
            cell: rowData => (
                <VirtualInfrastructureColumn
                    value={rowData.backup?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Backup`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        protectionType: ProtectedVirtualMachineRestoreType.Backup,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backup?.lastRestorePointDate}
                />
            ),
        },
        {
            id: 'backup.backupSize',
            title: lang.BACKUP_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.backup?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.destination',
            title: capitalize(lang.BACKUP_TARGET),
            cell: rowData => rowData.backup?.destination,
            hidden: true,
        },
        {
            id: 'replication.restorePoints',
            title: lang.REPLICAS,
            width: 90,
            cell: rowData => (
                <VirtualInfrastructureColumn
                    value={rowData.replication?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Replica`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        protectionType: ProtectedVirtualMachineRestoreType.Replica,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'replication.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_REPLICATION,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.replication?.lastRestorePointDate}
                />
            ),
        },
        {
            id: 'replication.destination',
            title: lang.REPLICATION_TARGET,
            cell: rowData => rowData.replication?.destination,
            hidden: true,
        },
        {
            id: 'cdpReplication.restorePoints',
            title: lang.CDP_REPLICAS,
            cell: rowData => (
                <VirtualInfrastructureColumn
                    value={rowData.cdpReplication?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.CdpReplica`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        protectionType: ProtectedVirtualMachineRestoreType.CdpReplica,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                    vmInstanceUid={rowData.vmInstanceUid}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'cdpReplication.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_CDP_REPLICATION,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.cdpReplication?.lastRestorePointDate}
                />
            ),
        },
        {
            id: 'cdpReplication.destination',
            title: lang.CDP_REPLICATION_TARGET,
            cell: rowData => rowData.cdpReplication?.destination,
            hidden: true,
        },
        {
            id: 'backupCopy.restorePoints',
            title: lang.BACKUP_COPIES,
            cell: rowData => (
                <VirtualInfrastructureColumn
                    value={rowData.backupCopy?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Copy`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        protectionType: ProtectedVirtualMachineRestoreType.Copy,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backupCopy.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP_COPY,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backupCopy?.lastRestorePointDate}
                />
            ),
        },
        {
            id: 'backupCopy.backupSize',
            title: lang.BACKUP_COPY_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.backupCopy?.backupSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backupCopy.destination',
            title: lang.BACKUP_COPY_TARGET,
            cell: rowData => rowData.backupCopy?.destination,
            hidden: true,
        },
        {
            id: 'tape.restorePoints',
            title: lang.BACKUPS_ON_TAPE,
            cell: rowData => (
                <VirtualInfrastructureColumn
                    value={rowData.tape?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Tape`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        protectionType: ProtectedVirtualMachineRestoreType.Tape,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            id: 'tape.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP_ON_TAPE,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.tape?.lastRestorePointDate}
                />
            ),
        },
        {
            id: 'tape.backupSize',
            title: lang.BACKUPS_ON_TAPE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.tape?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'tape.destination',
            title: lang.TAPE_MEDIA_POOL,
            cell: rowData => rowData.tape?.destination,
            hidden: true,
        },
        {
            id: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.resourceId,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataVirtualMachinesVirtualInfrastructure}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            defaultColumnMinWidth={100}
            columns={columns}
            data={(requestParams: RequestParams<ProtectedVmFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<ProtectedVirtualMachine[]>>('/ProtectedWorkloads/GetVms', {
                    ...requestParams,
                })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VirtualInfrastructureToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
