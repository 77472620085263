/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    Search,
    ConfiguredGrid,
    SortingMode,
    ConfiguredToolbar,
    useGlobalLang,
    GridSortDirections,
} from '@veeam-vspc/shared/components';

import type { VirtualServerVirtualMachine } from '@veeam-vspc/models/rest';
import type { ConfiguredGridProps, GridStore } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';
import { scopesResourcesService } from '../../services/scopes-resources-service';


interface VMsGridProps<
    RowDataT extends VirtualServerVirtualMachine,
    TotalRowDataT,
    FilterT
> extends Omit<ConfiguredGridProps<RowDataT, TotalRowDataT, FilterT>, 'columns' | 'data'> {
    onSelectionChange: (vms: VirtualServerVirtualMachine[]) => void;
    selected: VirtualServerVirtualMachine[];
}

export const VMsGrid: React.FC<VMsGridProps<VirtualServerVirtualMachine, unknown, unknown>> = ({
    onSelectionChange,
    selected,
    ...props
}) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<VirtualServerVirtualMachine, unknown, unknown>>();

    return (
        <ConfiguredGrid
            api={api}
            columns={[
                {
                    id: 'name',
                    title: lang.NAME,
                    cell: rowData => rowData.name,
                },
                {
                    id: 'type',
                    title: lang.TYPE,
                    cell: () => lang.VM,
                    isSortable: false,
                },
                {
                    id: 'size',
                    title: lang.SIZE,
                    cell: rowData => rowData.size,
                    isSortable: false,
                },
            ]}
            data={scopesResourcesService.getVMs(
                wizardStore.serverUid,
                wizardStore.selectedCompanyVC.hostUid,
                wizardStore.selectedCompanyVC.tagUrn,
                api.current,
            )}
            defaultColumnMinWidth={100}
            disableAutoUpdate
            sortingMode={SortingMode.Remote}
            toolbars={[
                () => (
                    <ConfiguredToolbar
                        view={TOOLBAR_VIEW_TYPE.spotted}
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='nameFilter'
                                        placeholder={lang.NAME}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
            selection={{
                field: 'urn',
                checkbox: true,
                multiple: true,
            }}
            disableAllPageSelection
            isRowDisabled={rowData => wizardStore.isItemSelectedForScope(rowData.urn)}
            onSelectionChange={onSelectionChange}
            initialState={{
                selected,
                sort: {
                    direction: GridSortDirections.Asc,
                    key: 'name',
                },
            }}
            {...props}
        />
    );
};
