/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileTagAttributesValues, XmlFileTagQueries } from '../../../enums';
import { getTagValueAttr, getXmlTagsByQuery } from '../../../helpers';

export const checkPolitics = (xml: XMLDocument): boolean => {
    const tagsQueries = [
        XmlFileTagQueries.Eula,
        XmlFileTagQueries.LicensingPolicy,
        XmlFileTagQueries.RequiredSoftware,
        XmlFileTagQueries.ThirdPartyLicenses,
    ];

    return getXmlTagsByQuery(xml, tagsQueries).every(tag => tag && (getTagValueAttr(tag) === XmlFileTagAttributesValues.Accept));
};
