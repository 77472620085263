/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Checkbox,
    constants,
    Datetime,
    Form,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useExternalFormApi,
    createDefaultDatetimeInputControl,
    NoteBar,
    NOTEBAR_STATUS,
    Text,
    useGlobalLang,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { DeploymentScheduleType, VbrDeployType } from '../../enums';
import { stepValidate } from './stepValidate';
import { useDateFormats, normalizeDateWithTime } from 'core/utils/date-helpers';

import type { VbrDeploySettingsWizardModel } from '../../entries';

const DeploymentSchedule = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi();
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const dateFormats = useDateFormats();
    const isVbrUpgrade = data.vbrDeployType === VbrDeployType.Upgrade;

    let title = lang.SCHEDULE;
    let nowRadioBtnText = lang.DEPLOY_NOW;
    let scheduleRadioBtnText = formatStr(lang.SCHEDULED_0, lang.DEPLOYMENT.toLowerCase());
    let description = formatStr(`${lang.SET_SCHEDULE_FOR_BACKUP_SERVER}.`, lang.DEPLOYMENT.toLowerCase());

    if (isVbrUpgrade) {
        title = lang.SCHEDULE;
        nowRadioBtnText = lang.UPGRADE_NOW;
        scheduleRadioBtnText = formatStr(lang.SCHEDULED_0, lang.UPGRADE.toLowerCase());
        description = formatStr(`${lang.SET_SCHEDULE_FOR_BACKUP_SERVER}.`, lang.UPGRADING.toLowerCase());
    }

    return (
        <Form
            value={data}
            validate={(val: VbrDeploySettingsWizardModel) => stepValidate(lang, val)}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
        >
            <StepLayout
                title={title}
                description={description}
            >
                <RadioGroup
                    value={data.deploymentSchedule}
                    onChange={(value: DeploymentScheduleType) => {
                        formApi.setValue('deploymentSchedule', value); // otherwise we don't see errors
                    }}
                    buttons={[
                        {
                            label: nowRadioBtnText,
                            value: DeploymentScheduleType.UpgradeNow,
                        },
                        {
                            label: scheduleRadioBtnText,
                            value: DeploymentScheduleType.ScheduleUpgrade,
                        },
                    ]}
                />

                <StackView
                    direction={STACK_DIRECTION.column}
                    gap={STACK_GAP.s}
                >
                    <Datetime
                        name='scheduleDate'
                        disabled={data.deploymentSchedule === DeploymentScheduleType.UpgradeNow}
                        controlRenderer={createDefaultDatetimeInputControl({
                            valueFormatter: ({ value }) => normalizeDateWithTime(value, formats),
                        })}
                        withTime
                        is12Hours={dateFormats.is12HoursFormat}
                        style={{ marginLeft: constants.SPACING_XL }}
                        startDatetime={new Date()}
                        endDatetime={new Date(Date.now() + TimeUnitsInMs.Day * 364)} // +1 year (364 days because user can set time a bit in future)
                    />

                    {data.vbrDeployType !== VbrDeployType.Install && (
                        <Checkbox
                            name='stopAllActivities'
                            inlineLabel
                        >
                            {lang.TERMINATE_ALL_ACTIVITIES_AUTOMATICALLY}
                        </Checkbox>
                    )}

                    <Checkbox
                        name='autoRebootAllowed'
                        inlineLabel
                    >
                        {lang.REBOOT_AUTOMATICALLY_IF_REQUIRED}
                    </Checkbox>

                    {isVbrUpgrade && (
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text>{`${lang.THE_UPGRADE_NOW_OPTION_OVERRIDES}.`}</Text>
                        </NoteBar>
                    )}
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getDeploymentScheduleStep = (lang: VspcLang, title: string): WizardStep<VbrDeploySettingsWizardModel> => ({
    title,
    validate: ({ data }) => isValid(val => stepValidate(lang, val), data),
    render: data => <DeploymentSchedule {...data} />,
});
