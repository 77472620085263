/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { capitalize, isPositiveNumber } from '@veeam-vspc/shared/helpers';
import {
    DurationColumn,
    GridColumnJustify,
    SizeColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useModal,
} from '@veeam-vspc/shared/components';
import { JobMode } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VbrAgentBackupJobModel, VbrBackupAgentJobRequestParam, VbrBackupAgentJobContainerFilter } from '@veeam-vspc/models/web-controllers';
import type {
    GridColumnProps,
    GridStore,
} from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds, JobTypes } from 'core/enums';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { EJobStatuses } from '../../../../enums';
import { ManagedByBackupServerActionToolbar, ManagedByBackupServerFilterToolbar, ObjectsColumn } from './components';
import { BackupStatusColumn } from '../../../BackupStatusColumn';
import { getJobModeNames, getLicenceTypeNames, getOsName } from './utils';
import { AssignCompanyDialog } from 'views/components/AssignCompanyDialog/AssignCompanyDialog';


import type { ValScheduleTypeRepresentations } from '../../../../enums';
import type { EJobStates } from 'core/enums';

export const ManagedByBackupServer: React.FC<{}> = () => {
    const { transportService } = useGlobalServices();
    const { pathname } = useLocation();
    const lang = useGlobalLang<VspcLang>();

    const gridApi = useRef<GridStore<VbrAgentBackupJobModel, unknown, VbrBackupAgentJobContainerFilter>>();

    const [assignCompanyDialog, assignCompanyDialogActions] = useModal({
        render: ({ deactivate }) => (
            <AssignCompanyDialog
                method='/backupserveragentmanagement/assignmanagedagentjobtocompany'
                gridApi={gridApi.current}
                deactivate={deactivate}
            />
        ),
    });

    const gridStateId = `${pathname}.ManagedByBackupServer`;
    const rendererIfPolicy = (value: number, jobType: JobTypes): number => jobType === JobTypes.AgentPolicy ? null : value;
    const columns: GridColumnProps<VbrAgentBackupJobModel>[] = [
        {
            id: 'status',
            title: lang.BACKUP_STATUS,
            cell: rowData => (
                <BackupStatusColumn
                    statusName={rowData.statusName as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServerName,
        },
        {
            id: 'name',
            title: lang.JOB,
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'jobKindName',
            title: lang.JOB_TYPE,
            cell: rowData => rowData.jobKindName,
        },
        {
            id: 'jobMode',
            title: capitalize(lang.JOB_MODE),
            cell: rowData => getJobModeNames(lang, rowData.jobMode),
        },
        {
            id: 'scheduleState',
            title: lang.SCHEDULE,
            cell: rowData => rowData.scheduleState,
            defaultValue: lang.DISABLED,
            hidden: true,
        },
        {
            id: 'successfulPercentage',
            title: lang.PROCESSED_OBJECTS,
            width: 200,
            cell: rowData => (
                <ObjectsColumn
                    stateId={gridStateId}
                    successfulPercentage={rowData.successfulPercentage}
                    successfulJobsCount={rowData.successfullJobsCount}
                    totalJobsCount={rowData.jobsCount}
                    scheduleState={rowData.scheduleState as keyof typeof ValScheduleTypeRepresentations}
                    jobKind={rowData.jobKind}
                    title={`${rowData.name} - ${getJobModeNames(lang, rowData.jobMode)}`}
                    instanceUid={rowData.instanceUid}
                />
            ),
        },
        {
            id: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: (rowData) => {
                const value = rowData.transferred;

                if (rowData.status === EJobStatuses.None || (rowData.jobMode === JobMode.ManagedByBackupServer && value === -1)) {
                    return null;
                }

                return (
                    <SizeColumn value={isPositiveNumber(value) ? rendererIfPolicy(value, rowData.jobType) : null} pointFixed={1} />
                );
            },
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            id: 'lastSessionDuration',
            title: lang.DURATION,
            cell: (rowData) => {
                const value = rowData.lastSessionDuration;

                if (rowData.status === EJobStatuses.None) {
                    return null;
                }

                return (
                    <DurationColumn value={isPositiveNumber(value) ? rendererIfPolicy(value, rowData.jobType) : null} />
                );
            },
        },
        {
            id: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastSessionStartTime}
                />
            ),
            sortKey: 'lastSessionStartTime',
        },
        {
            id: 'lastSessionTime',
            title: lang.LAST_RUN_TIME,
            cell: rowData => (
                <GridDateColumn
                    value={rowData.lastSessionStartTime}
                />
            ),
            sortKey: 'lastSessionStartTime',
            hidden: true,
        },
        {
            id: 'osPlatform',
            title: lang.GUEST_OS,
            cell: rowData => getOsName(lang, rowData.osPlatform),
            hidden: true,
        },
        {
            id: 'licenseType',
            title: lang.OPERATION_MODE,
            cell: rowData => getLicenceTypeNames(lang, rowData.licenseType),
        },
    ];

    return (
        <>
            <ConfiguredGrid
                stateId={gridStateId}
                api={gridApi}
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByBackupServer}
                defaultColumnMinWidth={100}
                columns={columns}
                data={(requestParams: VbrBackupAgentJobRequestParam) => transportService
                    .request<VbrBackupAgentJobRequestParam, RequestSuccessResponse<VbrAgentBackupJobModel[]>>(
                        '/BackupServerAgentManagement/GetBackupAgentJobContainers',
                        { ...requestParams },
                    )}
                isRowInactive={rowData => !rowData.scheduleEnabled}
                toggleable={true}
                selection={{
                    field: 'instanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <ManagedByBackupServerFilterToolbar />,
                    () => (
                        <ManagedByBackupServerActionToolbar
                            idProperty={'instanceUid'}
                            selectedOnly
                            excludeFromExport={['lastSessionTime']}
                            assignCompanyDialogActions={assignCompanyDialogActions}
                        />
                    ),
                ]}
            />

            {assignCompanyDialog}
        </>
    );
};
