/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout, TabBarBodyLayoutModes } from '@veeam-vspc/shared/components';

import { Companies } from './components';
import { PeriodsContext, RowsContext } from './contexts';
import { periodsStore, rowsStore } from './stores';

export const TopsCompaniesPage: React.FC<{}> = () => (
    <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
        <RowsContext.Provider value={rowsStore}>
            <PeriodsContext.Provider value={periodsStore}>
                <Companies />
            </PeriodsContext.Provider>
        </RowsContext.Provider>
    </TabBarBodyLayout>
);
