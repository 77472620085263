/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredAdvancedFilter,
    useGlobalLang,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { MalwareState } from '@veeam-vspc/models/web-controllers';

import type { ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import serverModeIconSrc from 'images/filters/agent-job-types/server.png';
import workstationModeIconSrc from 'images/filters/agent-job-types/workstation.png';
import yesCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/yes.png';
import noCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/no.png';
import entireComputerTypeIconSrc from 'images/filters/protected-computer/backup-os-level.png';
import volumeLevelTypeIconSrc from 'images/filters/protected-computer/backup-volume-level.png';
import fileLevelTypeIconSrc from 'images/filters/protected-computer/backup-file-level.png';
import { EndpointBackupModes, VawOperationModes } from 'core/enums';
import { useOsTypesAdvancedFilter } from 'views/components/filters/OsTypesFilters';
import cleanMalwareStateIconSrc from 'images/filters/malware-state/clean.svg';
import infectedMalwareStateIconSrc from 'images/filters/malware-state/infected.svg';
import suspiciousMalwareStateIconSrc from 'images/filters/malware-state/suspicious.svg';
import unknownMalwareStateIconSrc from 'images/filters/malware-state/unknown.svg';

export const ComputersFilterToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const osTypesAdvancedFilter = useOsTypesAdvancedFilter();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_BACKUP_AGENTS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: capitalize(lang.OPERATION_MODE, true),
                        items: [
                            {
                                iconSrc: serverModeIconSrc,
                                label: lang.SERVER,
                                value: {
                                    id: VawOperationModes.Server,
                                    name: 'operationMode',
                                },
                            },
                            {
                                iconSrc: workstationModeIconSrc,
                                label: lang.WORKSTATION,
                                value: {
                                    id: VawOperationModes.Workstation,
                                    name: 'operationMode',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.CLOUD_COPY, true),
                        items: [
                            {
                                iconSrc: yesCloudCopyTypeIconSrc,
                                label: lang.YES,
                                value: {
                                    id: true,
                                    name: 'cloudCopy',
                                },
                            },
                            {
                                iconSrc: noCloudCopyTypeIconSrc,
                                label: lang.NO,
                                value: {
                                    id: false,
                                    name: 'cloudCopy',
                                },
                            },
                        ],
                    },
                    {
                        title: lang.MALWARE_STATE,
                        items: [
                            {
                                iconSrc: cleanMalwareStateIconSrc,
                                label: lang.CLEAN,
                                value: {
                                    id: MalwareState.Clean,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: infectedMalwareStateIconSrc,
                                label: lang.INFECTED,
                                value: {
                                    id: MalwareState.Infected,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: suspiciousMalwareStateIconSrc,
                                label: lang.SUSPICIOUS,
                                value: {
                                    id: MalwareState.Suspicious,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: unknownMalwareStateIconSrc,
                                label: lang.UNKNOWN,
                                value: {
                                    id: MalwareState.Unknown,
                                    name: 'malwareStates',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        title: lang.BACKUP_TYPE,
                        items: [
                            {
                                iconSrc: entireComputerTypeIconSrc,
                                label: lang.ENTIRE_COMPUTER,
                                value: {
                                    id: EndpointBackupModes.EntireComputer,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: volumeLevelTypeIconSrc,
                                label: lang.VOLUME_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Volume,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: fileLevelTypeIconSrc,
                                label: lang.FILE_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Folder,
                                    name: 'backupType',
                                },
                            },
                        ],
                    },
                    osTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <ToolbarWithExport
            selectedOnly
            lang={lang}
            idProperty={'instanceUid'}
            method='GetComputersManagedByVBR'
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.COMPUTER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='serverName'
                            placeholder={lang.SERVER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
