/**
 * Copyright © Veeam Software Group GmbH.
 */

/* eslint-disable @typescript-eslint/naming-convention */

export const enLocale = {
    ABOVE: 'Above',
    ABOUT: 'About',
    ACTION: 'Action',
    ACTIONS: 'Actions',
    ACTIVE: 'Active',
    ACTIVITY_LOG: 'Activity Log',
    ACTIVE_SUBTENANTS: 'Active Subtenants',
    ACTIVE_TENANTS: 'Active Tenants',
    ACTIVE_FULL_BACKUP: 'Active full backup',
    ACTIVE_FULL_BACKUPS_SCHEDULING: 'Active full backups scheduling',
    ACTIVE_DIRECTORY_DISCOVERY: 'Active Directory discovery',
    ACCEPT_CONNECTION: 'Accept Connection',
    ACTIVATED_MANAGED: 'Activated (Managed)',
    ACCEPT_NEW_MANAGEMENT_AGENTS: 'Accept new management agents',
    ACCEPT_EULA: 'ACCEPT_EULA',
    ACCEPT_LICENSING_POLICY: 'ACCEPT_LICENSING_POLICY',
    ACCEPT_THIRDPARTY_LICENSES: 'ACCEPT_THIRDPARTY_LICENSES',
    ACCEPT_REQUIRED_SOFTWARE: 'ACCEPT_REQUIRED_SOFTWARE',
    ACCESS: 'Access',
    ACCESS_ACCOUNT: 'Access account',
    ACCESS_TYPE: 'Access Type',
    ACTIVATION: 'Activation',
    ACTIVATION_STATUS: 'Activation Status',
    ACCOUNT_CREDENTIALS: 'Account credentials',
    ACCOUNT_SPECIFIED_IN_THE_DISCOVERY: 'Account specified in the discovery rule or in the management agent settings',
    ACKNOWLEDGE: 'Acknowledge',
    ACKNOWLEDGED: 'Acknowledged',
    ACKNOWLEDGE_ALARM: 'Acknowledge Alarm',
    ACKNOWLEDGE_ALARM_IN_THE_CLIENTS_PORTAL: 'Acknowledge alarm in the client\'s portal',
    ADDITIONAL_NOTES: 'Additional notes',
    ADD_COMPANY: 'Add Company',
    ADD_OBJECTS: 'Add objects',
    ADD_CUSTOM_TEXT_TO_WELCOME_EMAIL: 'Add custom text to welcome email.',
    ADD_ITEM_TO_BACK_UP: 'Add at least one item to back up.',
    ADD_REPLICA_RESOURCES_OR_DISABLE: 'Add replica resources or disable this type of service on the Cloud Resources step of the wizard',
    ADD_BACKUP_RESOURCES_OR_DISABLE: 'Add backup resources or disable this type of service on the Cloud Resources step of the wizard',
    ADD_ACCOUNT_TO_THE_SUDOERS_FILE: 'Add account to the sudoers file',
    ADD_ANOTHER_FILE: 'Add another file',
    ADD_COMMENT: 'Add comment about resolution for the alarm history.',
    ADD_VEEAM_CLOUD_CONNECT_SERVER: 'Add Veeam Cloud Connect server',
    ADD_INDIVIDUAL_IP_ADDRESS: 'Add Individual IP Address',
    ADD_RESOURCES: 'Add Resources',
    ADD_OBJECT: 'Add Object',
    ADD_FOLDER: 'Add folder',
    ADD_IP_RANGE: 'Add IP Range',
    ADD_YOUR_FIRST_USER_ENABLE: 'Add your first user, enable the services you are going to provide and specify license management configuration',
    ADD_YOUR_FIRST_USER_TO: 'Add your first user to the backup portal',
    ADDRESS: 'Address',
    ADJUST_COUNT: 'Adjust Count',
    ADJUSTED: 'Adjusted',
    ADVANCED_KEY: 'Advanced Key',
    ADVANCED_CLOUD_REPOSITORY_SERVICES: 'Advanced Cloud Repository Services',
    AGENTS: 'Agents',
    AGENT_BACKUP_JOB: 'Agent Backup Job',
    AGENT_CONNECTION_SETTINGS: 'Agent Connection Settings',
    AGO: '{0} ago',
    AHV: 'AHV',
    AHV_INSTALL: 'AHV_INSTALL',
    AWS_INSTALL: 'AWS_INSTALL',
    APPLIANCE_STATUS: 'Appliance Status',
    APPLICATION_AWARE_PROCESSING: 'Application-aware processing',
    APPLICATION_PROCESSING_IS_DISABLED: 'Application processing is disabled',
    APPLICATION_STATUS: 'Application Status',
    APPLICATIONS: 'Applications',
    APPROVAL_DATE: 'Approval Date',
    APPROVAL_REQUIRED: 'Approval required',
    ADD_COMPANY_CLOUD_RESOURCES_WARNING: 'Specified {0} using {1}. {2} will be enabled automatically for the reseller.',
    ANY_STATE: 'Any state',
    ANALYTICS: 'Analytics',
    AND: 'And',
    ANDROID: 'Android',
    ALARM_DESCRIPTION: 'Alarm Description',
    ALL: 'All',
    ATTACHMENTS: 'Attachments',
    ALL_BACKUP_AGENTS_RUNNING_ON_THESE_COMPUTERS_WILL_BE: 'All backup agents running on these computers will be switched to the free mode: {0}.',
    ALL_USERS_DATA: 'All users data',
    ALL_COMPANIES_INCLUDING_NEWLY_ADDED: 'All companies, including newly added',
    ALL_COMPANY_MANAGEMENT: 'All company management agents will be removed',
    ALL_COMPANY_BACKUP: 'All company backup agents will be switched to the FREE mode',
    ALL_COMPANY_MAPPINGS: 'All company mappings within the backup portal will be removed',
    ALL_HARDWARE_PLANS: 'All hardware plans',
    ALL_APPLICATIONS: 'All applications',
    ALL_INVOICES: 'All Invoices',
    ALL_NEW_LOCATIONS: 'All new locations',
    ALL_PLATFORMS: 'All platforms',
    ALL_REPORTS: 'All Reports',
    ALL_SUPPORTED_OPERATING_SYSTEMS: 'All supported operating systems',
    ALL_TIME: 'All Time',
    ALL_TASKS: 'All tasks',
    ALL_WORKLOAD_COUNTS_WILL_BE_RESET: 'All workload counts will be reset to the reported values. Do you want to proceed?',
    ALL_LOCATIONS: 'All Locations',
    ALLOCATED_SPACE_QUOTA: 'Allocated space (quota)',
    ALLOCATION_RATIO: 'Allocation Ratio',
    ALLOCATE_THE_FOLLOWING_NUMBER: 'Allocate the following number of public IP addresses',
    ALLOW_FILE_LEVEL_RECOVERY: 'Allow file level recovery without administrative account',
    ALLOW_BACKUP_SERVER_TO_PRUNE: 'Allow backup server to prune old backups if backup agent was unavailable for the following period',
    ALLOW_THIS_RESELLER_TO_CREATE: 'Allow this reseller to create, update and manage Veeam license keys.',
    ALARM: 'Alarm',
    ALARMS: 'Alarms',
    ALARMS_MANAGEMENT: 'Alarms Management',
    ALARM_NAME: 'Alarm Name',
    ALARM_TYPE: 'Alarm type',
    ALARM_TYPE_INTERNAL: 'Internal',
    ALERTING: 'Alerting',
    ALARM_SYNCHRONIZATION_STATUS: 'Alarm synchronization status',
    AGENT: 'agent',
    AGENT_DEPLOYMENT: 'Agent Deployment',
    AGENT_ROLE: 'Agent Role',
    AGENT_STATUS: 'Agent status',
    AGENT_VERSION: 'Agent Version',
    APRIL: 'April',
    AUGUST: 'August',
    ARCHIVE: 'Archive',
    ARCHIVES: 'Archives',
    ARCHIVE_REPOSITORY: 'Archive Repository',
    ARCHIVE_SIZE: 'Archive Size',
    ARCHIVED_LOGS_PROCESSING: 'Archived logs processing',
    ARCHIVED_POINTS: 'Archived Points',
    APPLIED_MASK: 'Applied Mask',
    APPLYING: 'Applying',
    APPLYING_SETTING_WILL_DISCONNECT_OTHER_SERVER: 'Applying this setting will disconnect other server from the database. '
        + 'Do you want to proceed?',
    ADVANCED_SERVICES: 'Advanced services',
    ADVANCED_SETTINGS: 'Advanced settings',
    ALLOW_KEEPING_DELETED_BACKUP_FILES: 'Allow keeping deleted backup files',
    ALTERNATIVELY_TYPE_IN_THE_SECRET_CODE: 'Alternatively, type in the secret code',
    APACHE: 'Apache',
    APACHE_HTTP_SERVER: 'Apache HTTP Server',
    API_KEY_INTERNAL: 'API key for plugins',
    API_KEY_EXTERNAL: 'API key for custom integrations',
    API_KEYS: 'API Keys',
    API_KEYS_DISABLE_TITLE: 'Disable Key',
    API_KEYS_DISABLE_MESSAGE: 'This will disable all integrations using this key. Do you want to proceed?',
    APPLICATION: 'Application',
    APPROVE: 'Approve',
    APPROVED: 'Approved',
    ADMINISTRATORS: 'Administrators',
    AT: 'At',
    ACTIVE_ALARMS: 'Active Alarms',
    AT_LOCK: 'at lock',
    AT_LOCK_OFF: 'at log off',
    AT_LEAST_ONE_CONDITION_NEEDS: 'At least one condition needs to be selected.',
    ATTEMPTS: 'Attempts',
    AUTOMATIC: 'Automatic',
    AUTOMATIC_REPORT_APPROVAL: 'Automatic Report Approval',
    AUTOMATIC_REPORT_DELIVERY: 'Automatic Report Delivery',
    ASSIGN_FROM_THE_VCSP_PULSE_PORTAL: 'Assign from the VCSP Pulse portal',
    ASSIGN_TAG: 'Assign Tag',
    ASSIGN_ALARM_SEVERITY: 'Assign alarm severity',
    ASSIGNED_BACKUP_POLICIES: 'Assigned Backup Policies',
    ASSIGN_CLOUD_STORAGE_RESOURCES: 'Assign cloud storage resources for the reseller',
    ASSIGNED_TO: 'Assigned To',
    ASSIGNED_POLICY: 'Assigned policy',
    ASSIGNMENT_STATUS: 'Assignment Status',
    ASSIGN_VMWARE_CLOUD_DIRECTOR_ORGANIZATION_TO_THE_RESELLER: 'Assign VMware Cloud Director organization to the reseller',
    ASSIGN_TO_COMPANY: 'Assign to Company',
    APPLY_SETTINGS_TO_BACKUP_AGENTS: 'Do you want to apply new settings to {0} backup {1}?',
    AFFECT_TYPE_JOB_TEMPLATE_EXISTS_TEXT: 'Backup job from the same template already exists on the following backup {0} ' +
        'and will be updated with the new settings:',
    AFFECT_TYPE_LICENSE_ESCALATE_TEXT: 'New job settings are only available in the server edition of the backup agent. ' +
        'Operation mode for the following backup {0} will be switched from workstation to server mode:',
    AFFECT_TYPE_TARGET_VBR_CHANGE_TEXT: 'Backing up to different local backup servers is not supported. ' +
        'Jobs targeted to a backup repository for the following backup {0} will be updated with a new destination target:',
    AFFECT_TYPE_VAW2_JOB_REPLACEMENT_TEXT: 'Multiple jobs are only supported for the new version of the backup agent. ' +
        'Existing job will be replaced with a new template for the following backup {0}:',
    AFFECT_TYPE_RETENTION_TEXT: 'Cannot apply retention settings specified in days to {0} backup {1}. ' +
        'Upgrade {2} {3} to the most recent version and try again.',
    AFFECT_TYPE_VAW2_MULTI_JOB_ATTEMPT_TEXT: 'Cannot apply the selected backup policies to an agent. ' +
        'Backup agent version does not support multiple jobs. ' +
        'To proceed with this operation, please upgrade {0} backup {1} to the most recent version:',
    AFTER_THE_CONNECTION_TOKEN_IS_EXPIRED: 'After the connection token is expired, the management agent will need to be verified manually.',
    AFTER_THIS_JOB: 'After this job',
    AFTER_MONTHLY_LICENSE_USAGE_REPORT: 'After the monthly license usage report',
    CLICK_RESET_TO_RECEIVE: 'Click Reset to receive an email to your mailbox with instructions for creating a new password.',
    A_NEW_BACKUP_POLICY_HAS_BEEN: 'A new backup policy has been created based on the selected job configuration. \n' +
        'This policy can now be linked with the backup agent. \n' +
        'For cloud policies, if the tenant connection selection on the Backup Quota step has a different value compared to the original job, ' +
        'a new backup chain will be created. All previous backups will have to be removed manually. \n' +
        'Do you want to proceed and link this policy with the backup agent?',
    ACCOUNT: 'Account',
    A_BUSINESS_CRITICAL_SOFTWARE: 'A business critical software component or a Veeam managed system is ' +
        'inoperable or unavailable; production system is down or there is an emergency condition. Requires an immediate workaround or solution.',
    ADVERSELY_IMPACTING: 'Adversely impacting production operations, but the production system is not ' +
        'down; product operates, but is seriously restricted.',
    A_NON_PRODUCTION_ISSUE: 'A non-production issue; the majority of functions are still usable, ' +
        'a limited condition that can be readily circumvented.',
    APPLY: 'Apply',
    ASSIGN: 'Assign',
    ASSIGNED: 'Assigned',
    AUTO_DEPLOYMENT_SETTINGS: 'Auto Deployment Settings',
    AUTOMATIC_SELECTION: 'Automatic selection',
    ADDITIONAL_DETAILS: 'Additional details',
    A_CRITICAL_SOFTWARE: 'A critical software component is unavailable',
    AUTO_DEPLOYMENT: 'Auto Deployment',
    AUTOMATIC_RETRY: 'Automatic retry',
    AUTHENTICATION: 'Authentication',
    AUTHENTICATION_TYPE: 'Authentication type',
    ACTIVITY_LOG_AND_EVENTS_DATA: 'Activity log and events data',
    ALARMS_HISTORY: 'Alarms history',
    ADVANCED: 'Advanced',
    AVERAGE: 'Average',
    AVG_DATA: 'Avg. Data',
    AVG_DURATION: 'Avg. Duration',
    ADD: 'Add',
    ARCHIVE_TARGET: 'Archive Target',
    ARCHIVE_TIER: 'Archive tier',
    ARCHIVED_LOGS: 'Archived logs',
    ARCHIVE_STORAGE: 'Archive storage',
    ARCHIVE_STORAGE_FREE_OF_CHARGE: 'Archive storage free of charge',
    A_PASSWORD_RESET_WAS_INITIATED_FOR_YOUR_ACCOUNT: 'A password reset was initiated for your account. ' +
        'Check your inbox for the confirmation email.',
    AMAZON_WEB_SERVICES: 'Amazon Web Services',
    AT_THE_FOLLOWING_EVENTS: 'At the following events',
    ATTACHING_A_USB_BACKUP_TARGET: 'Attaching a USB backup target, or connecting to a network where a remote backup target resides.',
    ATTENTION_REQUIRED: 'Attention required',
    ACTIVE_FULL_BACKUP_IS_ENABLED: 'Active full backup is enabled, but image-level backup schedule is not configured. Continue anyway?',
    APPLIANCE_DEPLOYMENT: 'Appliance Deployment',
    APPLICABLE_FOR_EVERYTHING_EXCEPT: 'Applicable for everything except Public Cloud',
    APPLICABLE_ONLY_TO_PUBLIC_CLOUD_AND: 'Applicable only to Public Cloud and Object Storage',
    APPLICABLE_TO_ON_PREMISES_AND: 'Applicable to On-premises and Object Storage',
    APPLICABLE_TO_PUBLIC_CLOUD: 'Applicable to Public Cloud and Object Storage',
    APPLICATION_DATA: 'Application Data',
    AMAZON: 'Amazon',
    ANY_NUMBER_OF_JOBS_CAN: 'Any number of jobs can be configured.',
    AVAILABILITY: 'Availability',
    ADFS: 'ADFS',
    AVAILABLE: 'Available',
    AVAILABLE_COMPANIES: 'Available Companies',
    AVAILABLE_COMPUTERS: 'Available Computers',
    AVAILABLE_SERVERS: 'Available Servers',
    AVAILABLE_CLOUD_BACKUP: 'Available Cloud Backup Appliances',
    AVAILABLE_POINTS: 'Available Points',
    AVAILABLE_PATCHES: 'Available patches',
    ALL_SITES: 'All sites',
    ALL_MANAGED_COMPANIES: 'All managed companies',
    ALL_CHANGES_WILL_BE_LOST: 'All changes will be lost. Do you want to proceed?',
    ACTIVATE: 'Activate',
    ACCOUNT_TYPE: 'Account type',
    ADMINS: 'Admins',
    APPLYING_THE_MODIFIED_GFS: 'Applying the modified GFS retention policy settings might delete some of the existing' +
        ' GFS full backups. Continue anyway?',
    AUTHENTICATION_PARAMETERS: 'Authentication parameters',
    ACCOUNT_IS_NOT_SET: 'Account is not set. Please select an account from the list to proceed.',
    ASSERTION_CONSUMER_URL: 'Assertion consumer URL',
    AZURE_FILES: 'Azure Files',
    AZURE_INSTALL: 'AZURE_INSTALL',
    AZURE_SQL_DATABASES: 'Azure SQL Databases',
    AZURE_SQL: 'Azure SQL',
    ACCOUNT_IS_DISABLED: 'Account is disabled',
    AVAILABLE_ORGANIZATIONS: 'Available Organizations',
    AVAILABLE_REPOSITORIES: 'Available Repositories',
    AVAILABLE_UPDATES: 'Available Updates',
    AVAILABLE_SPACE: 'Available space',
    ACCESS_TO_REST_API: 'Access to REST API of the backup portal',
    ACCOUNT_EXPIRATION_DATE: 'Account expiration date',
    ADD_USERS: 'Add Users',
    ADD_GROUPS: 'Add Groups',
    ADD_SITES: 'Add Sites',
    ADD_TEAMS: 'Add Teams',
    ALL_COMPANIES: 'All companies',
    ALL_RESELLERS_COMPANIES: 'All Resellers/Companies',
    ASSIGN_ALARM: 'Assign Alarm',
    ALL_NEW_COMPANIES: 'All new companies',
    ALL_NEW_RESELLERS: 'All new resellers',
    ALL_RULES: 'All rules',
    ALL_CONFIGURED_OBJECTS_TO_BACK_UP_WILL_BE_REMOVED: 'All configured objects to back up will be removed from the job settings.' +
        '\nDo you want to proceed?',
    APPLIANCE: 'Appliance',
    A_NEW_BACKUP_POLICY: 'A new backup policy "{0}" has been successfully created.',
    APPLICATION_CLIENT_ID: 'Application client ID',
    APPLICATION_IS_AUTHORIZED: 'Application is authorized',
    AUTHORIZE: 'Authorize',
    ALL_NEW_CONTAINERS: 'All new containers',
    AUTO: 'Auto',
    AZURE: 'Azure',
    ARCHIVE_OBJECT_STORAGE: 'Archive object storage',
    A_LIST_OF_LOGS_COLLECTED_IS_AVAILABLE: 'A list of logs collected is available in the following',
    ALSO_THIS_OPTION_CANNOT_BE_APPLIED: 'Also, this option cannot be applied to allocated space (quota) billing model.',
    APPLICATION_AWARE_PROCESSING_OPTIONS: 'Application-Aware Processing Options',
    APPLICATION_AWARE_PROCESSING_DETECTS_AND_PREPARES_APPLICATIONS: 'Application-aware processing detects and prepares applications for ' +
        'consistent backup using application-specific methods, and configures the OS to perform required application restore steps upon first boot.',

    B: 'B',
    BACK: 'Back',
    BACKUP: 'Backup',
    BACKUP_LOGS: 'Backup logs',
    BACKUP_LOGS_EVERY_VAL: 'Backup logs every {0}',
    BACKUP_LOGS_EVERY: 'Backup logs every',
    BACKUP_LOGS_PERIODICALLY: 'Backup logs periodically (backed up logs are truncated)',
    BACKUP_SOURCE: 'Backup Source',
    BACKING_UP_DISK_TO_ITSELF_IS_NOT_RECOMMENDED: 'Backing up disk to itself is not recommended, as your backup ' +
        'will be lost along with the disk.',
    BACKUP_NO_MORE_OFTEN_THAN_EVERY: 'Backup no more often than every',
    BACKUP_AGENTS_PER_MONTH: 'Backup agents, per month',
    BACKUP_AGENT_DEPLOYMENT: 'Backup agent deployment',
    BACKUP_AGENTS_MANAGEMENT: 'Backup agents management',
    BACKUP_APPLIANCE: 'Backup Appliance',
    BACKUP_CACHE: 'Backup Cache',
    BACKUP_SERVER_0: 'Backup Server {0}.',
    BACKUP_COPY: 'Backup copy',
    BACKUP_COPY_TARGET: 'Backup Copy Target',
    BACKUP_COPY_SIZE: 'Backup Copy Size',
    BACKUP_MODE: 'Backup Mode',
    BACKUP_HOME_FOLDER_PATH: 'Backup home folder path is "C:\\Program Files\\Veeam\\Backup and Replication\\"',
    BACKUP_AGENTS_MANAGED_BY_THE_CONSOLE: 'Backup agents managed by the console',
    BACKUPS_ON_TAPE_SIZE: 'Backups on Tape Size',
    BACKUP_POLICY_HAS_BEEN_APPLIED: 'Backup policy has been applied with a warning.',
    BACKUPS_REMAIN_IN_THE_CACHE_UNTIL: 'Backups remain in the cache until a connection to the backup target can be established. '
        + 'Once that happens, cached backups are automatically uploaded to the backup target and then deleted from the cache.',
    BACKUP_INFRASTRUCTURE: 'Backup Infrastructure',
    BACKUP_IS_NOT_SCHEDULED_TO_RUN_AUTOMATICALLY: 'Backup is not scheduled to run automatically',
    BACK_UP_DATA_OF_YOUR_MANAGED_CLIENTS: 'Back up data of your managed clients',
    BACK_UP_IMAGES_OF_SELECTED_VOLUMES: 'Back up images of selected volumes, for example only data volumes. Deleted,' +
        ' temporary and page files are automatically excluded from the image to reduce the backup size.',
    BACKUP_SERVER: 'Backup server',
    BACKUP_SERVER_STATUS: 'Backup Server Status',
    BACKUP_SERVERS: 'Backup Servers',
    BACKUP_SERVERS_PER_MONTH: 'Backup servers, per month',
    BACKUP_SERVER_TYPE: 'Backup server type',
    BACKUP_SERVICES: 'Backup Services',
    BACKUP_SIZE: 'Backup Size',
    BACKUP_SCOPE: 'Backup scope',
    BACKUP_TARGET: 'Backup target',
    BACKUP_TO_TAPE: 'Backup to tape',
    BACK_UP_YOUR_ENTIRE_COMPUTER_IMAGE_FOR: 'Back up your entire computer image for fast recovery on any level. ' +
        'Deleted, temporary and page files are automatically excluded from the image to reduce the backup size.',
    BACKUP_TYPE: 'Backup type',
    BACKED_UP: 'Backed Up',
    BACKED_UP_DATA: 'Backed Up Data',
    BACK_UP_INDIVIDUAL_FILES: 'Back up individual files and folders by mask. This mode produces an image-based ' +
        'backup with only selected files included in the image.',
    BACKED_UP_FILES: 'Backed Up Files',
    BACKED_UP_ITEMS: 'Backed Up Items',
    BACKED_UP_COMPUTERS: 'Backed Up Computers',
    BACK_UP_DIRECTLY_FROM_LIVE: 'Back up directly from live file system',
    BACKUP_REPOSITORY: 'Backup repository',
    BACKUP_RESOURCES: 'Backup resources',
    BACKUP_JOB: 'Backup Job',
    BACKUP_JOBS: 'Backup Jobs',
    BACKUP_ON_TAPE: 'Backup on Tape',
    BACKUP_ONCE_POWERED_ON: 'Backup once powered on',
    BACKUP_POLICY: 'Backup Policy',
    BACKUP_POLICY_NAME: 'Backup Policy Name',
    BACKUP_POLICIES: 'Backup Policies',
    BACKUP_POLICY_CONFIGURATION_INFO: 'Backup Policy Configuration Info',
    BACKUP_AGENT: 'Backup Agent',
    BACKUP_AGENT_STATUS: 'Backup Agent Status',
    BACKUP_AGENTS: 'Backup agents',
    BACKUP_AGENT_LICENSE: 'Backup Agent License',
    BACKUP_AGENT_QUOTA: 'Backup Agent Quota',
    BACKUP_AGENTS_QUOTA: 'Backup agents quota',
    BACKUP_AGENTS_WILL_NOT_BE: 'Backup agents will not be installed to computers of these companies.',
    BACKUP_AGENT_VERSION: 'Backup Agent Version',
    BACKUP_POLICY_TO_APPLY: 'Backup policy to apply',
    BACKUP_SERVER_VERSION: 'Backup Server Version',
    BACKUP_SERVERS_MANAGEMENT: 'Backup servers management',
    BACKUPS: 'Backups',
    BUSINESS: 'business',
    BACKUP_WINDOW: 'Backup window',
    BACKUP_QUOTA: 'Backup Quota',
    BANDWIDTH: 'Bandwidth',
    BACK_UP_NO_MORE_OFTEN_THAN_EVERY: 'Back up no more often than every',
    BILLING: 'Billing',
    BEFORE_PROCEEDING: 'Before proceeding with the backup server upgrade, make sure the following requirements are met',
    BELOW: 'Below',
    BELOW_YOU_CAN_FIND_A_LIST_OF_APPLICATIONS: 'Below you can find a list of applications to use for the most popular mobile platforms',
    BOTTLENECK: 'Bottleneck',
    BLOCKING_TIME_PERIOD: 'Blocking Time Period',
    BLOCK_DEVICES: 'Block devices',
    BRAND_YOUR_PORTAL_WITH: 'Brand',
    BRAND_YOUR_PORTAL_WITH_YOUR_PORTAL: 'your portal with a company logo, fill in your',
    BRAND_YOUR_PORTAL_WITH_COMPANY_PROFILE: 'company profile',
    BRAND_YOUR_PORTAL_WITH_INFORMATION: 'information',
    BRANDING_COLOR_SCHEME_TITLE: 'Color scheme',
    BRANDING_COLOR_SCHEME_DESCRIPTION: 'The color scheme determines the set of colors used in the user interface. '
        + 'Color scheme<br />selection applies to all users logged in to the portal.',
    BRANDING_FAVICON_TITLE: 'Web site icon',
    BRANDING_FAVICON_DESCRIPTION: 'The web site icon determines what image is displayed in the web browser for this site.\n'
            + 'The image should be in ICO format.',
    BRANDING_PORTAL_TITLE: 'Portal logo',
    BRANDING_PORTAL_DESCRIPTION: 'The portal logo determines what image is going to be used for the web site.',
    BRANDING_REPORT_TITLE: 'Report logo',
    BRANDING_REPORT_DESCRIPTION: 'This logo determines what image is displayed in all generated reports and email notifications.',
    BRANDING_WEB_ADDRESS_TITLE: 'Portal web address',
    BROWSE: 'Browse...',
    BY_OS_TYPE: 'By OS type',
    BY_APPLICATION: 'By application',
    BY_PLATFORM: 'By platform',
    BACK_UP_ALL_DATA_FROM: 'Back up all data from {0} directory.',
    BACK_UP_SELECTED_VOLUMES_ONLY: 'Back up selected volumes only',
    BACK_UP_ALL_VOLUMES_EXCEPT: 'Back up all volumes except',
    BACKUP_STATUS: 'Backup Status',
    BACKUP_STORAGE: 'Backup storage',
    BACKUP_STORAGE_FREE_OF_CHARGE: 'Backup storage free of charge',
    BACK_UP_ALL_USER_PERSONAL_FILES: 'Back up all user personal files',
    BACK_UP_SELECTED_PROFILE: 'Back up selected user folders',
    BTRFS_SUBVOLUMES: 'Btrfs subvolumes',
    BACKUP_COPIES: 'Backup Copies',
    BACKUPS_ON_TAPE: 'Backups on Tape',
    BACKUP_PORTAL: 'Backup Portal',
    BUILT_IN_PLUGINS: 'Built-in plugins',
    BY_UPLOAD: 'By upload',
    BY_DEFAULT_APPLICATION_AWARE_PROCESSING_ON_WINDOWS_IS_PERFORMED_BY: 'By default, application-aware processing on Windows is ' +
        'performed by a non-persistent runtime process. Deploying a persistent agent removes security and port requirements of the runtime process ' +
        'injection.',
    BACKUP_INVOICE_WILL_NOW_BE_DOWNLOADED: 'Backup invoice will now be downloaded to your computer.',
    BUTTON: 'button',
    BACKUP_AGENT_UI: 'Backup Agent UI',
    BACKUP_PROXY: 'Backup proxy',
    BACKUP_COPY_JOB: 'Backup Copy Job',
    BACK_UP_THE_SPECIFIED_OBJECTS: 'Back up the specified objects',
    BASIC: 'Basic',
    BUCKET: 'Bucket',
    BACKUP_AGENT_DEPLOYMENT_AND_MANAGEMENT: 'Backup agent deployment and management has been disabled for the company',
    BACKUP_RESOURCES_ARE_SWITCHED_ON_BUT_NOT_CONFIGURED: 'Backup resources are switched on but not configured.',
    BLOCK_SIZE: 'block size',
    BROWSE_TO_SELECT_OBJECTS: 'Browse to select objects',
    BACKUP_JOB_WITH_THE_SPECIFIED_NAME_ALREADY_EXISTS: 'Backup job with the specified name already exists.',

    CLAIM: 'Claim',
    COMPANIES_HEALTH: 'Companies Health',
    COMPANIES_STATE_BY_REGIONS: 'Companies State by Regions',
    CHOOSE_THIS_OPTION_CLOUD: 'Choose this option if you want to investigate the policy failure reasons on managed cloud backup appliances.',
    CACHE_SETTING: 'Cache setting',
    CANNOT_CREATE_A_NEW_RESELLER: 'Cannot create a new reseller. Add at least one Veeam Cloud Connect server to proceed.',
    CANNOT_INSTALL_A_LICENSE_KEY: 'Cannot install a license key. The VCSP Pulse portal is inaccessible.',
    CANNOT_APPLY_BACKUP_POLICIES: 'Cannot apply backup policies to agents managed by the Veeam Backup & Replication server:',
    CANNOT_DEACTIVATE_THE_BACKUP_AGENT_MANAGEMENT: 'Cannot deactivate the backup agent management feature. '
        + 'This company has some active backup agents in the infrastructure.',
    CANNOT_DEACTIVATE_THE_BACKUP_SERVER_MANAGEMENT: 'Cannot deactivate the backup server management feature. '
        + 'This company has some active backup servers in the infrastructure.',
    CANNOT_ACTIVATE_THE_BACKUP_AGENT_MANAGEMENT: 'Cannot activate the backup agent management feature. '
        + 'The reseller managing this company does not have allocated workstation agents quota.',
    CANNOT_ACTIVATE_THE_BACKUP_SERVER_MANAGEMENT: 'Cannot activate the backup server management feature. '
        + 'The reseller managing this company does not have this feature enabled.',
    CANNOT_PROCEED_WITH_THE_INSTALLATION: 'Cannot proceed with the installation. '
        + 'Specify to accept license agreements in the configuration file and re-upload the configuration file.',
    CANNOT_PROCEED_WITH_THE_REMOTE: 'Cannot proceed with the remote installation of the backup server. '
        + 'The configuration file contains restricted parameter "REBOOT_IF_REQUIRED". '
        + 'To continue, disable these parameters by setting 0 in their value in the configuration file, or delete these parameters completely.',
    CANNOT_RUN_THE_DISCOVERY_RULE_FOR_EXPIRED: 'Cannot run the discovery rule for expired companies. '
        + 'To proceed, activate these companies or remove them from the discovery rule scope',
    CANNOT_RUN_THE_DISCOVERY_RULE_FOR_DISABLED: 'Cannot run the discovery rule for disabled companies. '
        + 'To proceed, enable these companies or remove them from the discovery rule scope',
    CANNOT_EDIT_THE_BACKUP_POLICY: 'Cannot edit the backup policy. Please make a copy of this policy to apply changes to the configuration.',
    CANNOT_EDIT_THE_REPORT_AT: 'Cannot edit the report at this time.',
    CANNOT_ENABLE_THIS_OPTION: 'Cannot enable this option. This setting is available starting from Veeam Cloud Connect 9.5 Update 3.',
    CANNOT_FIND_ANY_AVAILABLE_VM_NETWORKS: 'Cannot find any available VM networks.',
    CANNOT_ACCESS_CONNECTWISE: 'Cannot access ConnectWise Manage plugin. Plugin service is not running.',
    CANNOT_ADD_A_VEEAM_CLOUD_CONNECT_SERVER: 'Cannot add a Veeam Cloud Connect server. Please install a' +
        ' <a href="config/security/certificate">security certificate</a> to the backup portal to proceed.',
    CANNOT_APPLY_THE_BACKUP_POLICY: 'Cannot apply the backup policy. The target computer platform does not match the configured policy type:',
    CANNOT_UPDATE_THE_BACKUP_POLICY: 'Cannot update the backup policy on the backup {0}. ' +
        'The selected backup policy is already applied.',
    CANNOT_CHANGE_CONFIGURATION_DATABASE: 'Cannot change configuration database',
    CANNOT_DETECT_EXISTING_DATABASE: 'Cannot detect existing database. Do you want to create it?',
    CANNOT_DISABLE_RESTFUL_APIS_ACCESS: 'Cannot disable RESTful APIs access for the reseller. To complete the operation,' +
        ' disable RESTful APIs access for managed companies of this reseller: {0}.',
    CANNOT_DISABLE_THE_MFA_FEATURE: 'Cannot disable the MFA feature as this setting was enforced by your portal administrator.',
    CANNOT_DISABLE_THIS_FEATURE: 'Cannot disable this feature. This service is used by the following companies: {0}.\n\n'
        + 'To disable this feature, remove this option from the configuration of managed companies by this reseller.',
    CANNOT_CREATE_A_NEW_COMPANY: 'Cannot create a new company. Company with the same name already exists on the selected site.',
    CANNOT_CREATE_A_NEW_COMPANY_PLEASE_SELECT: 'Cannot create a new company. '
        + 'The configured quota of managed companies for this site has been exceeded. '
        + 'Please select a different site to proceed.',
    CANNOT_REMOVE_THE_SELECTED: 'Cannot remove the selected {0}: {1} from the scope. {2} companies managed by the reseller.' +
        ' Migrate these companies to another site to proceed.',
    CANNOT_REMOVE_VMWARE_CLOUD_DIRECTOR_RESOURCES: 'Cannot remove VMware Cloud Director resources {0}.' +
        ' These resources are used by the companies ({1}).\n' +
        'To disable these VMware Cloud Director resources, remove them from' +
        ' the VMware Cloud Director or unassign these companies from the reseller.',
    CANNOT_SET_LOCATION_FOR_THE_SELECTED: 'Cannot set location for the selected {0}. Install management agent first.',
    CANNOT_START_THE_REMOTE_POWERSHELL_SESSION: 'Cannot start the remote PowerShell session. The WebSocket Protocol feature is' +
        ' not enabled in the IIS server settings hosting the backup portal web UI.',
    CANNOT_START_THE_REMOTE_POWERSHELL_SESSION_CONTACT: 'Cannot start the remote PowerShell session.' +
        ' Contact your service provider for more information.',
    CANNOT_VERIFY_THE_REMOTE: 'Cannot verify the remote SMTP server',
    CANNOT_USE_SPECIAL_CHARACTERS: 'Cannot use special characters in the subscription plan name.',
    CANNOT_EDIT_SELECTED_JOB: 'Cannot edit selected job. Only VMware vSphere backup jobs are supported.',
    CANNOT_REMOVE_CREDENTIALS: 'Cannot remove credentials that are used by this job',
    CAPS_LOCK_IS_ON: 'Caps Lock is on',
    CATEGORY: 'Category',
    CBT_DRIVER: 'CBT Driver',
    CHOOSE_THIS_OPTION_CLOUD_LICENSING: 'Choose this option if you have issues with licensing of managed ' +
        'backup appliances for public cloud.',
    CDP_POLICY: 'CDP policy',
    CHOOSE_THIS_OPTION_365: 'Choose this option if you want to investigate the job failure reasons on a managed Veeam Backup for ' +
        'Microsoft 365 server.',
    CHOOSE_THIS_OPTION_VA: 'Choose this option if you want to investigate the job failure reasons on managed Veeam Agents.',
    CHOOSE_THIS_OPTION_VBR: 'Choose this option if you want to investigate the job failure reasons on a managed Veeam Backup & Replication server.',
    CDP_REPLICATION: 'CDP replication',
    CDP_REPLICATION_TARGET: 'CDP Replication Target',
    CDP_REPLICAS: 'CDP Replicas',
    CHOOSE_THIS_OPTION_IF_YOU_WANT_TO_INVESTIGATE: 'Choose this option if you want to investigate the appearance of a certain backup portal alarm.',
    CDP_SESSION_ERRORS: 'CDP Session Errors',
    CHOOSE_THIS_OPTION_365_LICENSING: 'Choose this option if you have issues with licensing of a managed ' +
        'Veeam Backup for Microsoft 365 server.',
    CHECKING_FOR_UPDATES: 'Checking for updates',
    CODE: 'Code',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    CONFIGURE_NOTIFICATION_SETTINGS: 'Configure notification settings for the {0}.',
    CONFIGURE_NOTIFICATION_SETTINGS_AND: 'Configure',
    CONFIGURE_NOTIFICATION_SETTINGS_AND_NOTIFICATION_SETTINGS: 'notification settings',
    CONFIGURE_NOTIFICATION_SETTINGS_AND_CUSTOMIZE: 'and customize the pre-defined',
    CONFIGURE_NOTIFICATION_SETTINGS_AND_SUBSCRIPTION_PLAN: 'subscription plan',
    CONFIGURE_SERVICES_THAT_YOU_WANT: 'Configure services that you want to provide to the company.',
    CONFIGURE_SETTINGS: 'Configure Settings',
    CONNECTION_HAS_BEEN_SUCCESSFULLY: 'Connection has been successfully verified',
    CITY: 'City',
    CERTIFICATE_TYPE_PORTAL: 'Server',
    CERTIFICATE_TYPE_SSL: 'Web UI',
    COMPANIES_OVERVIEW: 'Companies Overview',
    CERTIFICATE_TYPE: 'Certificate Type',
    CREATE: 'Create',
    CREATE_JOB: 'Create Job',
    CREATE_A_NEW_JOB: 'Create a new job',
    CREATE_DOWNLOAD_LINK: 'Create Download Link',
    CREATE_SP_ENTITY_ID_LINK: 'Create SP entity ID link...',
    CREATE_ASSERTION_CONSUMER_LINK: 'Create Assertion consumer link...',
    CREATE_KEY: 'Create Key',
    CREATE_NEW: 'Create New',
    CREATE_REPORT: 'Create Report',
    CREATE_ACTIVE_FULL_BACKUPS_PERIODICALLY: 'Create active full backups periodically',
    CONFIGURATION: 'Configuration',
    CONFIGURATIONS: 'Configurations',
    CONFIRM_PASSWORD: 'Confirm password',
    CONTINUOUSLY: 'Continuously',
    CONNECTWISE_MANAGE_PLUGIN: 'ConnectWise Manage Plugin',
    CONNECTED: 'Connected',
    COMPUTER_NAME_AND_COMPANY: 'Computer name: {0}. Company: {1}.',
    COMPANY: 'Company',
    COMPANIES: 'Companies',
    COMPANIES_QUOTA: 'Companies quota',
    COMPANY_ADMINISTRATOR: 'Company Administrator',
    CHOOSE_THIS_OPTION_VONE_LICENSING: 'Choose this option if you have issues with licensing of a managed ' +
        'Veeam ONE server.',
    COMPANY_DETAILS_HAVENT_BEEN_SAVED: 'Company details haven\'t been saved. All changes will be lost. Do you want to leave this page?',
    CONNECTION_ERROR: 'Connection Error',
    CHECKS_FOR_BACKUP_POLICY_UPDATES: 'Checks for backup policy updates and applies the latest configuration to the backup agent',
    CHARGE_FOR_THE_AMOUNT_OF_TRANSFERRED_NBD: '[NBD] Charge for the amount of transferred data by Tenant to tape job.',
    CHOOSE_REPORTING_OPTIONS: 'Choose reporting options for the invoice',
    SELECT_PRODUCT_TO_INSTALL: 'Select product to install the license key',
    CHOOSE_WHEN_YOU_WANT_BACKUP_JOB: 'Choose when you want backup job to be started automatically',
    CHOOSE_BACKUP_REPOSITORY_TO_BACK_UP: 'Choose backup repository to back up to and configure retention policy settings.',
    CHOOSE_THE_LEVEL_OF_NOTIFICATIONS: 'Choose the level of notifications you want to use',
    COMPUTER_CONNECTION: 'Computer Connection',
    COMPUTER_CONNECTION_STATUS: 'Computer Connection Status',
    CHOOSE_THIS_OPTION_VA_LICENSING: 'Choose this option if you have issues with licensing of managed ' +
        'Veeam Agents.',
    COMPRESSION_LEVEL: 'Compression level',
    COMPUTE_RESOURCES: 'Compute resources',
    COMPUTE_RESOURCES_FREE_OF_CHARGE: 'Compute resources free of charge',
    COMPUTERS: 'Computers',
    CONNECTION_STATUS: 'Connection status',
    CONFIGURE_VEEAM_SERVICE_PROVIDER_CONSOLE: 'Configure Veeam Service Provider Console',
    CHANGED_FILES: 'Changed Files',
    CHANGE_SETTINGS: 'Change Settings',
    CHOOSE_LOCALLY_ATTACHED_DRIVE_TO_BACK_UP_TO: 'Choose locally attached drive to back up to.',
    CHOOSE_HOW_THIS_JOB_SHOULD: 'Choose how this job should process Microsoft SQL Server transaction logs.',
    CHOOSE_CERTIFICATE_TO_BE_USED_FOR: 'Choose certificate to be used for encrypted connection.',
    CHOOSE_DIRECTORIES_TO_BACK_UP: 'Choose directories to back up',
    COPY_ALARM: 'Copy Alarm',
    COPY_PRIVATE_PART_OF_KEY: 'Copy the private part of the API key',
    COPY_KEY_TO_CLIPBOARD: 'Copy key to clipboard',
    COPY_ALL_TO_CLIPBOARD: 'Copy All to Clipboard',
    COPIES_EVERY_RESTORE_POINT_CREATED_BY_SELECTED_JOB: 'Copies every restore point created by the selected backup job as soon as ' +
        'it appears in the primary backup repository.',
    CREATED_BY_VEEAM_AT: 'Created by Veeam Service Provider Console at',
    CREATED_BY_COMPANY_NAME: 'Created by {0} with Veeam Service Provider Console at {1}',
    CLOUD_GATEWAY: 'Cloud gateway',
    CLOUD_APPLIANCES: 'Cloud Appliances',
    CLOUD_BACKUP_APPLIANCE: 'Cloud backup appliance',
    CLOUD_CONNECT_BACKUP_RESOURCES: 'Cloud Connect backup resources',
    CLOUD_CONNECT_REPLICATION_RESOURCES: 'Cloud Connect replication resources',
    CLOUD_CONNECT_RESOURCES: 'Cloud Connect resources',
    CLOUD_HOST: 'Cloud host',
    CLOUD_HOST_CPU_RATIO: 'Cloud Host CPU Ratio',
    CLOUD_HOST_MEMORY: 'Cloud Host Memory',
    CLOUD_HOST_STORAGE: 'Cloud Host Storage',
    CLOUD_COPY: 'Cloud Copy',
    CLOUD_REPOSITORY_WITH_THE_SAME_NAME: 'Cloud repository with the same name already exists',
    CLOUD_REPLICATION_ADVANCED_SETTINGS: 'Cloud Replication Advanced Settings',
    CLOUD_SERVICES: 'Cloud Services',
    CLOUD_SQL_DATABASES: 'Cloud SQL Databases',
    CLOUD_SQL: 'Cloud SQL',
    CLOUD_STORAGE_QUOTA: 'Cloud storage quota',
    CLOUD_STORAGE_RESOURCE_WITH: 'Cloud storage resource with the same name already exists',
    CLOUD_STORAGE: 'Cloud storage',
    CLOUD_STORAGE_NAME: 'Cloud storage name',
    CLOUD_STORAGE_FREE_OF_CHARGE_BACKUP: 'Cloud storage free of charge (backup)',
    CLOUD_STORAGE_FREE_OF_CHARGE_REPLICATION: 'Cloud storage free of charge (replication)',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    CANCELED: 'Canceled',
    COMPANY_STATE: 'Company State',
    CONTINUE: 'Continue',
    COPY: 'Copy',
    CLONE: 'Clone',
    COMMENT: 'Comment',
    CONTRACT_ID: 'Contract ID',
    CONTRACT: 'Contract',
    CENT_OS: 'CentOS',
    CLOUD_BACKUP_AND_REPLICA_RESOURCES: 'Cloud backup and replica resources',
    CLOUD_BACKUP_AND_REPLICATION_QUOTA: 'Cloud Backup and Replication Quota',
    CLOUD_BACKUP_QUOTA: 'Cloud Backup Quota',
    CLOUD_BACKUP_RESOURCES: 'Cloud backup resources', // todo: to delete
    CLOUD_COPIES: 'Cloud Copies',
    CLOUD_DATABASES: 'Cloud Databases',
    CHOOSE_THIS_OPTION_VBR_LICENSING: 'Choose this option if you have issues with licensing of a managed ' +
        'Veeam Backup & Replication server.',
    CLOUD_DATABASE: 'Cloud database',
    CLOUD_DATABASES_FREE_OF_CHARGE: 'Cloud databases free of charge',
    CHOOSE_REGION_WITH_MANAGED_COMPANIES: 'Choose region with managed companies',
    CLOUD_REPLICATION: 'Cloud Replication',
    CLOUD_REPLICATION_QUOTA: 'Cloud Replication Quota',
    CLOUD_REPOSITORY_USAGE: 'Cloud Repository Usage (%)',
    CLOUD_REPOSITORY_NAME: 'Cloud repository name',
    CANNOT_FIND_ANY_MICROSOFT_365: 'Cannot find any Veeam Backup for Microsoft 365 server',
    CANNOT_FIND_ANY_CLOUD_CONNECT: 'Cannot find any Veeam Agent Veeam Cloud Connect server',
    CANNOT_FIND_ANY_VEEAM_AGENT: 'Cannot find any Veeam Agent',
    CANNOT_FIND_ANY_VEEAM_ONE: 'Cannot find any Veeam ONE server',
    CANNOT_FIND_ANY_VBR: 'Cannot find any Veeam Backup & Replication server',
    CANNOT_FIND_ANY_CLOUD_BACKUP: 'Cannot find any cloud backup appliance',
    COMPANY_TYPE: 'Company type',
    CONFIGURED: 'Configured',
    COLLECT_DATA: 'Collect Data',
    COUNTRY: 'Country',
    CONCURRENT_TASKS: 'Concurrent Tasks',
    CAPACITY: 'Capacity',
    CREATED_BY: 'Created by',
    CREATE_USER_ACCOUNT_FOR_THE_RESELLER: 'Create user account for the reseller to log in to the backup portal.',
    CREATE_A_COMPANY_ACCOUNT_FOR: 'Create a',
    CREATE_A_COMPANY_ACCOUNT_FOR_COMPANY_ACCOUNT: 'company account',
    CREATE_A_COMPANY_ACCOUNT_FOR_OR_ADD_A: 'or add a',
    CREATE_A_COMPANY_ACCOUNT_FOR_RESELLER_ACCOUNT: 'reseller account',
    CLOUD: 'Cloud',
    CLOUD_CONNECT_SERVER: 'Cloud Connect Server',
    CLOUD_CONNECT_SERVERS: 'Cloud Connect Servers',
    CLOUD_CONNECT_SERVER_SETTINGS: 'Cloud Connect Server Settings',
    CLOUD_VM: 'Cloud VM',
    CLOUD_VMS: 'Cloud VMs',
    CLOUD_VM_FREE_OF_CHARGE: 'Cloud VM free of charge',
    CLOUD_CONNECT: 'Cloud Connect',
    CLOUD_CONNECT_BACKUP: 'Cloud Connect Backup',
    CLOUD_CONNECT_REPLICATION: 'Cloud Connect Replication',
    CLOUD_BACKUP_RESOURCE_USAGE: 'Cloud Backup Resource Usage',
    CLOUD_BACKUP: 'Cloud Backup',
    CLOUD_REPLICATION_RESOURCE: 'Cloud Replication Resource',
    CLOUD_REPLICA_TYPE: 'Cloud Replica Type',
    CLOUD_REPOSITORY: 'Cloud Repository',
    CLOUD_RESOURCES: 'Cloud Resources',
    CHOOSE_THIS_OPTION_IF_NONE: 'Choose this option if none of the categories above fit your issue. ' +
        'If you select this option, Veeam Service Provider Console will not be able to collect logs automatically. ' +
        'You will have to attach the logs manually.',
    CPU: 'CPU',
    CPU_USAGE: 'CPU Usage',
    COMPUTERS_FROM_CSV_FILE: 'Computers from CSV file',
    COMMUNITY: 'Community',
    COMPANY_LOCATION: 'Company\\Location',
    CONFIGURE_THE_RPO_THRESHOLD: 'Configure the RPO threshold for protected workload and define severity for each rule',
    CONFIGURE_THE_RPO_THRESHOLD_COMPUTER: 'Configure the RPO threshold for computer protection and define severity for each rule',
    CONNECTIONS: 'Connections',
    CONDITION: 'Condition',
    CONCURRENT_SQL_SERVER_AND_ORACLE: 'Concurrent SQL Server and Oracle transaction log processing is not supported.',
    CHOOSE_USERS: 'Choose Users',
    CHECK_FOR_UPDATES: 'Check for Updates',
    CHOOSE: 'Choose...',
    CHOOSE_SEVERITY: 'Choose severity of the support case.',
    CANCEL_DEPLOYMENT: 'Cancel deployment',
    CANCEL_UPDATE: 'Cancel update',
    CANCEL_0: 'Cancel {0}',
    CHOOSE_WHETHER_JOBS_WITHIN: 'Choose whether jobs within the backup window should be permitted or denied.',
    CHOOSE_BACKUP_REPOSITORY_TO_BACKUP_TO: 'Choose backup repository to backup to.',
    CHOOSE_WHAT_DATA_DO_YOU_WANT: 'Choose what data do you want to back up from this computer',
    CHOOSE_GUEST_OS_PROCESSING_OPTIONS: 'Choose Guest OS processing options.',
    CHOOSE_GUEST_OS_PROCESSING_OPTIONS_FOR_RUNNING_VMS: 'Choose guest OS processing options for running VMs.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_LOCALLY: 'Choose this option to back up to a locally attached storage device such as USB,' +
        ' Firewire or eSATA external hard drive. Backing up to internal hard drives is not recommended.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_SMB: 'Choose this option to back up to an SMB (CIFS) share on a Network Attached Storage (NAS)' +
        ' device, or on a regular file server.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_SMB_NFS: 'Choose this option to back up to an SMB (CIFS) or' +
        ' NFS share on a Network Attached Storage (NAS) device, or on a regular file server.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_REPOSITORY: 'Choose this option to back up to a backup repository managed by ' +
        'Veeam Backup & Replication 12a or later server.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_CLOUD: 'Choose this option to back up to a cloud repository managed by Veeam Cloud Connect server.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_ONE_DRIVE: 'Choose this option to back up to a Microsoft OneDrive.',
    CHOOSE_THIS_OPTION_TO_BACK_UP_TO_AN: 'Choose this option to back up to an object storage that is not managed by Veeam Cloud Connect server',
    CHOOSE_WHERE_YOU_WANT_TO_BACKUP: 'Choose where you want to back up your data to.',
    CHOOSE_WHETHER_THIS_JOB_SHOULD_PROCESS_TRANSACTION: 'Choose whether this job should process transaction logs upon successful backup. L' +
        'ogs pruning is supported for Microsoft Exchange, Microsoft SQL Server and Oracle.',
    CUSTOMIZE_APPLICATION_HANDLING_OPTIONS: 'Customize application handling options',
    CUSTOMIZE_APPLICATION_HANDLING_OPTIONS_FOR_INDIVIDUAL_MACHINES_AND_APPLICATIONS: 'Customize application handling options for individual ' +
        'machines and applications.',
    CUSTOMIZE_ADVANCED_GUEST_FILE_SYSTEM_INDEXING_OPTIONS_FOR_INDIVIDUAL_MACHINES: 'Customize advanced guest file system indexing ' +
        'options for individual machines.',
    CUSTOMIZE_SCRIPTS_SETTINGS: 'Customize scripts settings',
    CURRENCY_PER: '{0} per',
    CURRENCY_PER_AGENT: '{0} per agent',
    CURRENCY_PER_INSTANCE_PER_MONTH: '{0} per instance per month',
    CURRENCY_PER_VM: '{0} per VM',
    CURRENCY_PER_VM_PER_MONTH: '{0} per VM per month',
    CURRENCY_PER_MONTH: '{0} per month',
    CURRENCY_PER_USER: '{0} per user',
    CREATES_CATALOG_OF_FILES_TO_ENABLE_BROWSING: 'Creates catalog of files to enable browsing, ' +
        'searching and 1-click restores of individual files. ' +
        'Indexing is optional, and is not required to perform instant file level recoveries.',
    CUSTOMIZE_ADVANCED_FILE_SYSTEM_INDEXING_OPTIONS: 'Customize advanced file system indexing options',
    CREDENTIALS: 'Credentials',
    CLIENT: 'Client',
    CLIENTS: 'Clients',
    CREATOR: 'Creator',
    CLEAR_ALL: 'Clear All',
    CLEAR_LOGS: 'Clear Logs',
    CLEAN: 'Clean',
    CLEAN_UP: 'clean up, ',
    CLICK_THE_NEW_BUTTON: 'Click the "New" button',
    COMPANY_ADMINS: 'Company Admins',
    COMPANY_EMAIL: 'Company email',
    COMPANY_INVOICE_AUDITOR: 'Company Invoice Auditor',
    COMPANY_OWNER: 'Company Owner',
    COMPLETED: 'Completed',
    CONNECTION_ACCOUNT: 'Connection account',
    COMPANY_NAME: 'Company Name',
    COMPANY_INFO: 'Company Info',
    COMPUTER_HOSTNAME: 'Computer hostname',
    CONSUMED_POINTS: 'Consumed points',
    CONSUMED_SPACE: 'Consumed space',
    CONSUMED_STORAGE: 'Consumed Storage',
    COUNT: 'Count',
    CURRENCY: 'Currency',
    CURRENT_PASSWORD: 'Current password',
    CURRENT_ORGANIZATION: 'Current organization',
    CURRENT_WEEK: 'Current Week',
    CUSTOM: 'Custom',
    CAPACITY_TIER: 'Capacity tier',
    CLOUD_CONNECT_SERVER_VERSION: 'Cloud Connect Server Version',
    CLOUD_REPOSITORY_STORAGE_QUOTA: 'Cloud repository storage quota',
    CONFIGURATION_FILE_CONTAINS: 'Configuration file contains the following installation parameters',
    CONFIGURATION_FILE_SAMPLE: 'Configuration File Sample',
    CONFIGURATION_INSTRUCTION: 'Configuration Instruction',
    CONFIGURATION_DATABASE_NAME: 'configuration database name',
    CONFIGURE: 'Configure...',
    CONFIGURE_GFS: 'Configure GFS',
    CONFIGURE_CONNECTION_SETTINGS_TO_A_SQL: 'Configure connection settings to a SQL Server instance hosting Veeam ' +
        'Service Provider Console database.',
    CONFIGURE_MANAGED_SERVICES: 'Configure managed services',
    CONFIGURE_MFA_ACCESS_FOR_THE_COMPANY: 'Configure MFA access for the company.',
    CONFIGURE_MFA_ACCESS_FOR_THIS_RESELLER: 'Configure MFA access for this reseller and all his managed companies.',
    CONFIGURE_MFA_ACCESS: 'Configure MFA access.',
    CONFIGURE_MFA_ACCESS_FOR_YOUR_USER_ACCOUNT: 'Configure MFA access for your user account',
    CONFIGURE_MONITORING_AND_REPORTING: 'Configure monitoring and reporting of the data protection state',
    CONFIGURE_BACKUP_WINDOW: 'Configure backup window...',
    CONFIGURE_BACKUP_JOB_SCOPE: 'Configure backup job scope',
    CONFIGURE_JOB_SCHEDULING_SETTINGS: 'Configure job scheduling settings',
    CRASH_CONSISTENT_FILE_LEVEL_BACKUP: 'Crash-consistent file-level backup without snapshot. ' +
        'Required when backing up from shared folders and file systems not supported by Veeam snapshot module.',
    CREATE_POLICY_FROM_JOB: 'Create Policy from Job',
    CREATE_SYNTHETIC_FULL_BACKUPS_PERIODICALLY: 'Create synthetic full backups periodically',
    CSV: 'CSV',
    CSV_FILE: 'CSV file',
    CUSTOMIZE_APPLICATION_HANDING_OPTIONS: 'Customize application handing options for individual applications...',
    CUSTOMIZE_APPLICATION: 'Customize application',
    CUSTOMIZE_INDEXING: 'Customize indexing',
    COMPUTER: 'Computer',
    CHOOSE_THIS_OPTION_VAC: 'Choose this option if you have issues with the backup portal configuration ' +
       '(multi-factor authentication, security certificates, single sign-on), your company info (profile and portal ' +
       'branding), subscription plans, server settings or management agents.',
    CHOOSE_THIS_OPTION_CC: 'Choose this option if you have issues with Veeam ' +
        'Cloud Connect server registration, connection or management.',
    CHOOSE_THIS_OPTION_VONE: 'Choose this option if you have issues with ' +
        'Veeam ONE server configuration or if you cannot connect a Veeam ONE server to the backup portal.',
    CHOOSE_THIS_OPTION_CLOUD_CONFIGURATION: 'Choose this option if you have issues with ' +
        'public cloud account registration, backup appliance configuration, or cloud backup policies management.',
    CHOOSE_THIS_OPTION_365_CONFIGURATION: 'Choose this option if you have issues with ' +
        'Veeam Backup for Microsoft 365 account registration, server configuration, or backup jobs management.',
    CHOOSE_THIS_OPTION_VA_CONFIGURATION: 'Choose this option if you have issues with ' +
        'Veeam Agent installation, configuration, backup jobs creation, or restore operations.',
    CHOOSE_THIS_OPTION_VBR_CONFIGURATION: 'Choose this option if you have issues with ' +
        'Veeam Backup & Replication infrastructure configuration, backup jobs management, or restore operations.',
    CANNOT_USE_THIS_COMPANY_NAME: 'Cannot use this company name or login alias. Please contact your service provider.',
    CANNOT_USE_THIS_ALIAS: 'Cannot use this alias for the company. Please select a different one to continue.',
    CURRENT_MONTH: 'Current month',
    CANNOT_DOWNLOAD_THIS_FILE: 'Cannot download this file. Access is denied.',
    COMPANY_NAME_MATCHES_THE_ALIAS: 'Company name matches the name or the alias of already registered company "{0}". ' +
        'Change the name of the company to continue.',
    CLIENTS_INFRASTRUCTURE: 'Clients Infrastructure',
    CLOUD_CONNECT_INFRASTRUCTURE: 'Cloud Connect Infrastructure',
    CLOUD_FILE_SHARE: 'Cloud file share',
    CLOUD_FILE_SHARES: 'Cloud file shares',
    CLOUD_FILE_SHARES_FREE_OF_CHARGE: 'Cloud file shares free of charge',
    CREATE_AT_LEAST_ONE_SUCCESSFUL_RESTORE_POINT: 'Create at least one successful restore point to view data.',
    CREATE_ON: 'Create on',
    CREATE_NEW_BACKUP_JOB: 'New backup job',
    COMPLETE_CONFIGURATION: 'Complete Configuration',
    CREATE_AT_LEAST_ONE_MAPPING_RULE: 'Create at least one mapping rule for this identity provider.',
    CHANGE_USERS_STATUS: 'Change Users Status',
    CLICK: 'Click',
    CHOOSE_WHEN_JOBS_WITHIN_THE_BACKUP_WINDOW: 'Choose when jobs within the backup window should be permitted or denied.',
    CHOOSE_THIS_OPTION_VAC_GENERAL: 'Choose this option if you have issues with VCSP Pulse, ConnectWise Automate or ConnectWise Manage plugins.',
    CHOOSE_THIS_OPTION_VAC_REPORTING: 'Choose this option if you have issues with report data or with its configuration and generation tasks.',
    CANNOT_EDIT_THE_SITE: 'Cannot edit the site. The selected site hosts companies managed by the reseller. '
        + 'To proceed, migrate these companies to another site.',
    CHOOSE_WHETHER_THIS_JOB_SHOULD_PROCESS_TRANSACTION_LOGS_UPON_SUCCESSFUL_BACKUP: 'Choose whether this job should process transaction ' +
        'logs upon successful backup.\nLogs pruning is supported for Microsoft Exchange, Microsoft SQL Server and other applications that ' +
        'support Microsoft VSS.',

    CONTAINS: 'Contains',
    CONTAINER: 'Container',
    COMPANY_INVOICE_AUDITOR_HAS_ACCESS: 'Company Invoice Auditor has access to all billing details of a company. This user can view ' +
        'invoices and receive billing notifications.',
    CANNOT_COMPLETE_THE_LOGIN_OPERATION: 'Cannot complete the login operation. The server is busy. Please wait and try again later.',
    CANNOT_CREATE_A_NEW_USER: 'Cannot create a new user with the specified scope of managed companies: {0}. ' +
        'These companies are managed by reseller.',
    CANNOT_CREATE_A_NEW_AUTHORIZATION: 'Cannot create a new authorization rule with the specified scope of managed companies: {0}. ' +
        'These companies are managed by reseller.',
    CONFIGURE_COMPANIES: 'Configure companies',
    CLAIM_MAPPING_RULES: 'Claim Mapping Rules',
    COMPARISON_METHOD: 'Comparison method',
    CONTEXT_CLASS: 'Context class',
    CONDITIONS: 'Conditions',
    CONFIGURE_MAPPING_CONDITIONS_FOR_THE_SELECTED_ROLE: 'Configure mapping conditions for the selected role.',
    CONFIGURE_ADDITIONAL_MAPPING_CONDITIONS: 'Configure additional mapping conditions for the selected user role',
    COMPANY_ADMINISTRATOR_HAS_ACCESS_TO_ALL_MONITORING: 'Company Administrator has access to all monitoring, reporting, and billing data ' +
        'and can perform all types of management actions. This role cannot modify or remove the Company Owner account.',
    COMPANY_OWNER_HAS_ACCESS_TO_ALL: 'Company Owner has access to all monitoring, reporting, and billing data and can ' +
        'perform all types of management actions for the company.',
    COMPANY_IS_NOT_SET: 'Company is not set. Select a company that will be assigned to the user to proceed.',
    CLIENTS_OVERVIEW: 'Clients Overview',
    CLAIM_IS_AN_ATTRIBUTE: 'Claim is an attribute that is configured on the Identity Provider site. ' +
        'For different Identity providers, its name can be different:',
    CLAIM_NAME: 'Claim name',
    CLIENT_ID: 'Client ID',
    CHANGE_PASSWORD: 'Change Password',
    CURRENT_USAGE: 'Current Usage',
    CUSTOM_PERIOD: 'Custom period',
    CHOOSE_THIS_OPTION_OTHER_PRODUCTS: 'Choose this option if you have issues with a product not integrated with ' +
        'Veeam Service Provider Console.',
    CHOOSE_AN_AREA_APPLICABLE: 'Choose an area applicable for the support case.',
    CHOOSE_GATEWAY_POOL_FOR_THIS_COMPANY: 'Choose gateway pool for this company. ' +
        'For managed companies with dedicated channels such as MPLS or similar, ' +
        'we recommend to select a specific pool. For all other configurations,  select an automatic option.',
    CANNOT_ADD_THE_SELECTED_HARDWARE_PLAN: 'Cannot add the selected hardware plan. This plan is already added to the list.',
    CREATE_THE_BACKUP_PORTAL_USER_ACCOUNT: 'Create the backup portal user account and select a hosting site for the company.',
    CANNOT_DELETE_THE_CLOUD_REPOSITORY: 'Cannot delete the cloud repository {0}. This repository has been assigned to the companies ({1}).\n' +
        'To delete these backup resources, remove them from the managed companies or unassign these companies from the reseller.',
    CANNOT_REMOVE_THE_MICROSOFT_365_SERVER: 'Cannot remove the Microsoft 365 backup server. ' +
        'This server is assigned to the companies ({0}).\n' +
        'To delete this server, remove it from the managed companies or unassign these companies from the reseller.',
    CANNOT_REMOVE_THE_MICROSOFT_365_REPOSITORY: 'Cannot remove the Microsoft 365 backup repository. ' +
        'This repository is assigned to the companies ({0}).\n' +
        'To delete this repository, remove it from the managed companies or unassign these companies from the reseller.',
    CANNOT_DELETE_THE_HARDWARE_PLAN: 'Cannot delete the hardware plan {0}. This plan has been assigned to the companies ({1}).\n' +
        'To delete these replica resources, remove them from the managed companies or unassign these companies from the reseller.',
    CANNOT_INSTALL_VEEAM_BACKUP: 'Cannot install Veeam Backup & Replication on the selected computer. Another deployment task is running.',
    CANNOT_UPGRADE_VEEAM_BACKUP: 'Cannot upgrade Veeam Backup & Replication on the selected computer. Another upgrade task is running.',
    COMPUTER_BACKUP: 'Computer Backup',
    COMPUTER_BACKUPS: 'Computer Backups',
    CHOOSE_THIS_OPTION_VAC_LICENSING: 'Choose this option if you have issues with licensing of your backup ' +
        'portal and integrated products, or Veeam Service Provider Console REST API.',
    CHOOSE_THIS_OPTION_CC_LICENSING: 'Choose this option if you have issues with licensing of a managed ' +
        'Veeam Cloud Connect server.',
    CLIENT_SECRET: 'Client secret',
    CANNOT_DELETE_THE_WAN: 'Cannot delete the WAN accelerator {0}. This server is assigned to the companies ({1}).\n' +
        'To delete this WAN accelerator, remove it from the managed companies or unassign these companies from the reseller.',
    CAUSE: 'Cause',
    CANNOT_COLLECT_SUPPORT_LOGS: 'Cannot collect support logs from offline management agents',
    CONTENT_SCAN_MAY_RESULT: 'Content scan may result in significant charges from your cloud object storage provider. ' +
        'Enable this setting anyway?',
    CONFIGURATION_FILE: 'Configuration file',
    CASE_ID: 'Case ID',
    CREATION_DATE: 'Creation date',
    CANNOT_ENABLE_THIS_FEATURE_CONTACT: 'Cannot enable this feature. Contact your service provider for assistance.',
    CREATE_POLICY: 'Create Policy',
    CREATES_AN_OVERVIEW_REPORT: 'Creates an overview report for all selected companies, including all their locations.',
    CREATES_A_SEPARATE_REPORT: 'Creates a separate report for each selected company.',
    CANNOT_LIMIT_INCOMING_NETWORK_TRAFFIC: 'Cannot limit incoming network traffic. '
        + 'The configured limit exceeds {0} - the quota allowed for reseller managing this company.',
    CANNOT_LIMIT_INCOMING_MAX_CONCURRENT_TASKS: 'Cannot limit incoming max concurrent tasks number. '
        + 'The configured number exceeds {0} - the quota allowed for reseller managing this company.',
    CANNOT_DETECT_THE_CONFIGURATION_FILE: 'Cannot detect the configuration file. The configuration file was moved or changed. '
        + 'Re-upload the file again to proceed.',
    CANNOT_REMOVE_THE_SELECTED_REPOSITORY: 'Cannot remove the selected repository. Microsoft 365 organizations mapped '
        + 'to this company have jobs targeted to this repository. To remove the repository, remove these backup jobs.',
    CONTACT_YOUR_SERVICE_PROVIDER: 'Contact your service provider to allow automatic installation and update of the backup server.',
    CANNOT_SET_A_LIMIT_TO_THE_INCOMING_NETWORK_TRAFFIC: 'Cannot set a limit to the incoming network traffic for the selected reseller. ' +
        'The managed companies have unlimited quotas. Change companies quotas and try again.',
    CANNOT_ENABLE_THIS_FEATURE_UPGRADE_YOUR_CLOUD: 'Cannot enable this feature. ' +
        'Upgrade your Cloud Connect server to the most recent version and try again.',
    CANNOT_SET_A_LIMIT_TO: 'Cannot set a limit to the incoming network traffic for the selected reseller. ' +
        'The managed companies quota ({0} {1}) is higher than the specified limit.',
    CHANNELS_TABS_FILES: 'Channels, tabs, files, membership',
    CHATS: 'Chats',
    CANNOT_DISABLE_THE_PUBLIC_CLOUD: 'Cannot disable the public cloud backup option. This company still has some assigned backup appliances. ' +
        'To disable this option, revoke those backup appliances using the corresponding plugin in the Configuration section.',
    COMPANY_IS: 'company is',
    COMPANIES_ARE: 'companies are',
    COMPANY_ID: 'Company ID',
    CANNOT_UPDATE_THE_LICENSE_KEY: 'Cannot update the license key. Missing the support ID parameter.',
    CANNOT_SET_THE_UNLIMITED_DATA: 'Cannot set the unlimited Data Transfer Out quota.' +
        ' This setting is more than the allowed quota for the reseller managing this company.',

    DOES_NOT_CONTAIN: 'Does not contain substring',
    DO_YOU_WANT_TO_REMOVE_THIS_MAPPING_RULE: 'Do you want to remove this mapping rule? ' +
        'After that, users authenticated through this rule will not be able to access the backup portal UI.',
    DATA: 'Data',
    DATA_COLLECTION: 'Data Collection',
    DATA_BACKUP: 'Data Backup',
    DATABASE_NAME: 'Database name',
    DATABASE_USER_WITH_PASSWORD: 'Database user with password',
    DATABASE_TRANSACTION_LOGS: 'Database Transaction Logs',
    DATABASE_USER_FROM_PASSWORD_FILE: 'Database user from password file ({0})',
    DATABASE_CONNECTION_SETTINGS_UPDATED: 'Database connection settings have been updated. ' +
        'Veeam Service Provider Console services will be restarted. Press OK to reload the web page.',
    DATABASE_SIZE: 'database size',
    DAYS_OF_LOG_BACKUPS: 'days of log backups',
    DAY_OF_MONTH: 'day of month',
    DAY_OF_MONTH_AT: 'Day of month at',
    DAILY_AT: 'Daily at',
    DAILY_AT_THIS_TIME: 'Daily at this time:',
    DAILY: 'Daily',
    DEFAULT: 'Default',
    DEFAULTS: 'Defaults',
    DEFAULT_GATEWAY: 'Default gateway',
    DEFINE_SEVERITY_FOR_THE_ALARM: 'Define severity for the alarm',
    DEFINE_SEVERITY_FOR_THE_ALARM_RULE: 'Define severity for the alarm rule',
    DESTINATION: 'Destination',
    DESCRIPTION: 'Description',
    DENY_ALL: 'Deny All',
    DEBIAN: 'Debian',
    DEV_SDA_SDB_SDD1: '/dev/sda, sdb, sdd1',
    DEV_GR1_VOL1: '/dev/gr1/vol1, gr1/vol2, /dev/gr2, gr2',
    DURATION: 'Duration',
    DASH_SYMBOL: '-',
    DISABLE: 'Disable',
    DISABLE_INDEXING: 'Disable indexing',
    DISABLE_RECOMMENDED: 'Disable (Recommended)',
    DISABLED: 'Disabled',
    DISABLE_SCHEDULED_BACKUPS: 'Disable scheduled backups',
    DISABLE_ACCESS: 'Disable Access',
    DISABLE_ACCOUNT_AUTOMATICALLY_ON: 'Disable account automatically on',
    DISABLE_APPLICATION_PROCESSING: 'Disable application processing',
    DISABLE_THIS_RESELLER: 'Disable this reseller?\n\n' +
        'This action will disable the access to the backup portal for this reseller. All management ' +
        'actions for companies and managed servers will become unavailable for this reseller. Do you want to disable this reseller?',
    DISABLE_THESE_RESELLERS: 'Disable these resellers?\n\n' +
        'This action will disable the access to the backup portal for these resellers. All management ' +
        'actions for companies and managed servers will become unavailable for these resellers. Do you want to disable these resellers?',
    DAY: 'day',
    DAYS: 'Days',
    DAYS_TO_EXPIRATION: '(Days to expiration)',
    DATA_TRANSFER_OUT: 'Data Transfer Out',
    DATA_TRANSFER_OUT_QUOTA: 'Data transfer out quota',
    DATA_TRANSFERRED: 'Data Transferred',
    DEFAULT_REPOSITORY: 'Default Repository',
    DEFINE_CURRENCY: 'Define currency, tax and discount settings for the subscription plan.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_BACKUPS: 'Define subscription plan price for backups in the cloud repository',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_VMS_REPLICATED: 'Define subscription plan price for VMs replicated to the cloud host.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_PUBLIC: 'Define subscription plan price for public cloud managed services.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_MICROSOFT_365: 'Define subscription plan price for Microsoft 365 managed services.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_CLOUD: 'Define subscription plan price for Veeam Cloud Connect services.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_FILE: 'Define subscription plan price for file share backup',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_GENERAL_BACKUP: 'Define subscription plan price for general backup and monitoring services.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_MONTHLY: 'Define subscription plan price for monthly services, managed VMs and backup agents',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_MANAGED_BACKUP: 'Define subscription plan price for managed backup services.',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_VMS: 'Define subscription plan price for VMs replicated to the cloud host',
    DEFINED_SUBSCRIPTION_PLAN: 'Defined subscription plan',
    DEFRAGMENT_AND_COMPACT_FULL_BACKUP_FILE: 'Defragment and compact full backup file',
    DELETE: 'Delete',
    DELETING: 'Deleting',
    DELETE_AGENT: 'Delete Agent',
    DELETE_AGENTS: 'Delete Agents',
    DELETE_MANAGEMENT_AGENT: 'Delete management agent',
    DELETE_MANAGEMENT_AGENTS: 'Delete management agents',
    DELETE_ALARM: 'Delete Alarm',
    DELETE_ALL: 'Delete All',
    DELETE_BACKUP_JOB: 'Delete Backup Job',
    DELETE_BACKUP_JOBS: 'Delete Backup Jobs',
    DELETE_BACKUP_RESOURCES: 'Delete Backup Resources',
    DELETE_COMPANY: 'Delete Company',
    DELETE_COMPANY_MSG: 'Remove {1} {0}?\n ' +
        'This action will delete all management agents registered to {1} {0} and will switch all backup agents to the free mode.',
    DELETE_COMPANY_MSG_ADVANCED: 'This action will delete all management agents of the selected {0} ' +
        'and will switch all backup agents to the FREE mode.' +
        '\n\nDo you also want to delete cloud backups from your repository for the selected {0}?',
    DELETE_DISCOVERY_RULE: 'Delete Discovery Rule',
    DELETE_JOB: 'Delete Job',
    DELETE_JOBS: 'Delete Jobs',
    DELETE_LOGS_OLDER_THAN: 'Delete logs older than',
    DELETE_LOGS_OVER: 'Delete logs over',
    DELETE_REPORT_CONFIGURATION: 'Delete report configuration',
    DELETE_SELECTED_AGENT: 'Delete selected agent?',
    DENIED: 'Denied',
    DEPLOYMENT_PROGRESS: 'Deployment Progress',
    DEPLOY_IN_PROGRESS: 'Deploy In Progress',
    DEPLOY_FAILED: 'Deploy Failed',
    DEPLOYMENT_STATUS: 'Deployment Status',
    DESTINATION_FOLDER: 'Destination folder',
    DESTINATION_TARGET: 'Destination target',
    DECEMBER: 'December',
    DESIGNED_SPECIFICALLY_FOR_END_USER: 'Designed specifically for end user\'s computers, laptops and office desktops. ' +
        'Ease of use and friendly job scheduling options',
    DIRECTORY_PATH_MUST_BE_SPECIFIED: 'Directory paths must be specified in the following format: "C:\\FolderName". ' +
        'To back up specific files from the directory, set file mask in the following format: "*.doc"',
    DIRECTORY_PATH_MUST_BE_SPECIFIED_MAC: 'Directory paths must be specified in the following format: "/home". ' +
        'To back up specific files from the directory, set file mask in the following format: "*.doc"',
    DISCOVERY: 'Discovery',
    DISCOVERY_TIME: 'Discovery Time',
    DISABLE_COMPANY_MSG: 'This action will disable Cloud Connect user account for the selected companies. ' +
        'Remote job management, invoice scheduling and other tasks will not be available for the companies in a disabled state.\n' +
        'Do you want to proceed?',
    DISABLED_TIME: 'Disabled time',
    DETAILED_REPORT: 'Detailed report',
    DETAILS: 'Details',
    DELIVERY_DATE: 'Delivery Date',
    DISABLE_CONTROL_PANEL_NOTIFICATIONS: 'Disable Control Panel notifications',
    DISABLE_BACKUP_OVER_METERED_CONNECTION: 'Disable backup over metered connection',
    DISCONNECTED: 'Disconnected',
    DOMAIN: 'Domain',
    DOWN: 'down',
    DO_NOT_SHOW_INACCESSIBLE_COMPUTERS: 'Do not show inaccessible computers',
    DO_NOT_DELETE_ARCHIVED_LOGS: 'Do not delete archived logs',
    DO_NOT_TRUNCATE_LOGS: 'Do not truncate logs',
    DO_YOU_WANT_TO_DELETE_BACKUP_FILES: 'Do you want to delete backup files of {0} too?',
    DO_YOU_ALSO_WANT_TO_DELETE: 'Do you also want to delete cloud backups from the repository for selected companies?',
    DO_YOU_WANT_TO_PROCEED: 'Do you want to proceed?',
    DOWNLOAD: 'Download',
    DOWNLOADING_SUPPORT_LOGS: 'Downloading support logs... This operation might take some time to complete.',
    DOWNLOAD_MANAGEMENT_AGENT: 'Download management agent',
    DOWNLOAD_LOGS: 'Download Logs',
    DOWNLOAD_MANAGEMENT_AGENT_FOR_WINDOWS: 'Download Management Agent for Windows',
    DOWNLOAD_MANAGEMENT_AGENT_FOR_LINUX: 'Download Management Agent for Linux',
    DOWNLOAD_MANAGEMENT_AGENT_FOR_MAC: 'Download Management Agent for Mac',
    DOWNLOAD_PATCHING_LOGS: 'Download Patching Logs',
    DOWNLOAD_REMOTE_BACKUP_CONSOLE: 'Download Remote Backup Console',
    DOWNLOAD_WEB_UI_AND_WEB: 'Download web UI and web server debug logs.',
    DELETED_COMPANIES_DATA: 'Deleted companies data',
    DELETED_BACKUPS_RECYCLE_BIN: 'Deleted Backups Recycle Bin',
    DETECTS_AND_PREPARES_APPLICATIONS_FOR: 'Detects and prepares applications for consistent backup, performs ' +
        'transaction logs processing, and configures the OS to perform required application restore steps upon first boot.',
    DATE: 'Date',
    DATABASES: 'Databases',
    DISABLE_JOB: 'Disable Job',
    DISABLE_SCRIPT_EXECUTION: 'Disable script execution',
    DISCOVER_REMOTE_COMPUTER_INSTALL_BACKUP: 'Discover remote computer, install backup agent and assign the selected backup policy',
    DISCOVER_REMOTE_COMPUTER_WITHOUT_INSTALLING: 'Discover remote computer without installing backup agent (install backup agent later)',
    DEPLOY_MANAGEMENT_AGENTS: 'Deploy management agents',
    DEPLOY_MANAGEMENT_AGENTS_TO_REMOTE_COMPUTERS: 'to remote computers and start protecting on-premises or public cloud infrastructures for your' +
        ' clients',
    DISCOVERED_APPLICATIONS: 'Discovered applications:',
    DISCOVERED_COMPUTER: 'Discovered computer',
    DISCOVERED_COMPUTERS: 'Discovered Computers',
    DISCOVERED_CUMULATIVE_PATCHES_FOR: 'Discovered {0} cumulative {1} for {2} Veeam Backup & Replication server {3}.',
    DISCOVERY_METHOD: 'Discovery method',
    DISCOVERY_RULE: 'Discovery Rule',
    DISCOVERY_RULE_SCHEDULE: 'Discovery Rule Schedule',
    DISCOVERY_RULES: 'Discovery Rules',
    DISCOVERY_SCOPE: 'Discovery Scope',
    DISCOVERY_FILTERS: 'Discovery Filters',
    DEFAULT_SENDER: 'Default sender',
    DISCOUNT: 'Discount',
    DOWNLOAD_LOGS_FOR_THE_LAST: 'Download logs for the last',
    DESKTOP: 'Desktop',
    DOCUMENTS: 'Documents',
    DOWNLOADS: 'Downloads',
    DUE_DATE: 'Due Date',
    DNS_NAME_OR_IP_ADDRESS_OF_THE_SERVER: 'DNS name or IP address of the server',
    DO_NOT_TRUNCATE_LOGS_REQUIRES_SIMPLE: 'Do not truncate logs (requires simple recovery model)',
    DYNAMIC_DISCOVERY_DEFINED_BY_ACTIVE: 'Dynamic discovery defined by Active Directory containers. ' +
        'Recommended for Active Directory domain environments of any size.',
    DYNAMIC_DISCOVERY_DEFINED_BY_THE_CONTENT: 'Dynamic discovery defined by the content of a comma-separated values (.csv) ' +
        'file with computer names. Recommended for environments which have CMDB integration.',
    DYNAMIC_DISTRIBUTION_GROUP: 'Dynamic Distribution Group',
    DETECTS_AND_PREPARES_DATABASES: 'Detects and prepares databases for consistent backup, performs logs processing, ' +
        'and configures the OS to perform required application restore steps upon first boot.',
    DELETED_BACKUP_RECYCLE_BIN: 'Deleted Backup Recycle Bin',
    DISPLAY_NAME: 'Display Name',
    DOWNLOAD_METADATA_FILE: 'Download Metadata File',
    DOWNLOAD_LICENCE_KEY: 'Download Licence Key',
    DO_YOU_WANT_TO_DISABLE_THIS_MAPPING_RULE: 'Do you want to disable this mapping rule? ' +
        'After that, users authenticated through this rule will not be able to access the backup portal UI.',
    DO_YOU_WANT_TO_ENABLE_THIS_RULE: 'Do you want to enable this rule? ' +
        'After that, users authenticated through this rule will have access to the backup portal UI.',
    DO_YOU_WANT_TO_DISABLE_THIS_IDENTITY_PROVIDER: 'Do you want to disable this identity provider? ' +
        'After that, users authenticated through this provider will not be able to access the backup portal UI.',
    DO_YOU_WANT_TO_ENABLE_THIS_IDENTITY_PROVIDER: 'Do you want to enable this identity provider? ' +
        'After that, users authenticated through this provider will have access to the backup portal UI.',
    DOMAIN_NAME: 'Domain name',
    DO_YOU_WANT_TO_DELETE_THIS_IDENTITY_PROVIDER: 'Do you want to delete this identity provider? ' +
        'After that, users authenticated through this provider will not be able to access the backup portal UI.',
    DEFAULT_LOCATION: 'Default location',
    CANNOT_LIMIT_THE_DATA: 'Cannot limit the Data Transfer Out quota. Some of the managed by this reseller companies have unlimited quota: {0}.',
    THE_DATA_TRANSFER_OUT_QUOTA_WILL: 'The Data Transfer Out quota will be disabled for this reseller because' +
        ' newly managed companies have unlimited quota: {0}.',
    DO_NOT_LOSE_YOUR_PASSWORDS: 'Do not lose your passwords. Veeam Support cannot recover lost passwords or' +
        ' retrieve your data from encrypted backup files.',
    DEPLOY_BACKUP_AGENTS: 'Deploy backup agents',
    DISABLE_RESELLER: 'Disable Reseller',
    DISABLE_RESELLERS: 'Disable Resellers',
    DOWNLOAD_LICENSE: 'Download License',
    THRESHOLD_TILL_REACHING: 'Threshold till reaching the limit',
    DISTRIBUTION_GROUP: 'Distribution Group',
    DOWNLOAD_DEPLOYMENT_LOGS: 'Download Deployment Logs',
    DOWNLOADING_DEPLOYMENT_LOGS: 'Downloading deployment logs... This operation might take some time to complete.',
    DOWNLOAD_THE_BACKUP_SERVER_SETUP_FILE: 'Download the backup server setup file from a Veeam web server (size: {0})',
    DOWNLOAD_THE_BACKUP_SERVER_SETUP_FILE_FROM: 'Download the backup server setup file from a Veeam web server',
    DELETE_INVOICE: 'Delete Invoice',
    DELETE_REPORT: 'Delete Report',
    DEPLOY: 'Deploy',
    DEPLOY_NOW: 'Deploy now',
    DEPLOYMENT: 'Deployment',
    DEFINES_MAXIMUM_INCOMING_NETWORK_TRAFFIC: 'Defines maximum incoming network traffic bandwidth' +
        ' that will be accepted from the companies managed by the reseller.' +
        ' Traffic from companies with more bandwidth will be throttled to the specified amount.',
    DISABLING_COMPRESSION_REDUCES_PERFORMANCE: 'Disabling compression reduces performance due to increased amount of data' +
        ' that must be transferred to the target storage.',
    DO_NOT_SET_ANY_LIMIT: 'Do not set any limit',
    DATA_TRANSFER_OUT_BACKUP: 'Data transfer out (backup)',
    DATA_TRANSFER_OUT_REPLICATION: 'Data transfer out (replication)',
    DATA_REDUCTION: 'Data reduction',
    DOWNLOAD_TRAFFIC: 'Download Traffic',
    DELETE_RESELLERS: 'Delete resellers',
    DELETE_RESELLER: 'Delete reseller',
    DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_LICENSES: 'Define subscription plan price for consumed Veeam rental licenses.',
    DATA_FOR_THIS_INVOICE_IS_BASED_ON_THE_MONTHLY: 'Data for this invoice is based on the monthly license usage report. ' +
        'To generate the invoice correctly, go to \n' +
        'Invoices -> Configuration and adjust the scheduling options to match the monthly usage report generation time.',
    DATA_FOR_COUNTRIES_AND_REGIONS_IS_TAKEN_FROM: 'Data for countries and regions is taken from these sources: ',

    EC2: 'EC2',
    EFS: 'EFS',
    EXPORT: 'Export',
    EXPORT_MAPPING_RULES: 'Export Mapping Rules',
    EXPRESSION: 'Expression',
    EXPORT_TO: 'Export to...',
    ENABLE_ALERTING: 'Enable Alerting',
    ENABLE_ARCHIVING: 'Enable archiving',
    ENABLE_BACKUP_FILE_ENCRYPTION: 'Enable backup file encryption',
    ENABLE_BACKUP_SERVICES: 'Enable backup services and configure reporting',
    ENABLE_FILE_SYSTEM_INDEXING: 'Enable file system indexing',
    ENABLE_THE_POSSIBILITY_TO_COLLECT_DATA: 'Enable the possibility to collect data and control remote backup servers of managed companies.',
    ENABLE_THE_POSSIBILITY_TO_COLLECT_DATA_FROM_COMPANY: 'Enable the possibility to collect data from company standalone backup agents.',
    ENABLE_THE_POSSIBILITY_TO_REPLICATE: 'Enable the possibility to replicate VMs to a cloud host.',
    ENABLE_THE_POSSIBILITY_TO_PROTECT: 'Enable the possibility to protect Microsoft 365 objects.',
    ENABLE_THE_POSSIBILITY_TO_STORE_BACKUPS: 'Enable the possibility to store backups in a cloud repository.',
    ENABLE_THE_POSSIBILITY_TO_TARGET_REPLICATION: 'Enable the possibility to target replication job to a virtual data center.',
    ENABLE_THE_POSSIBILITY_TO_USE_NETWORK_RESOURCES: 'Enable the possibility to use network resources for performing failover tasks.',
    ENABLE_THE_REQUIRED_INTEGRATIONS: 'Enable the required integrations via',
    ENABLE_THE_REQUIRED_INTEGRATIONS_PLUGIN_LIBRARY: 'Plugin library',
    EMAIL: 'Email',
    EMAIL_ADDRESS: 'Email address',
    EMAIL_NOTIFICATION_WILL_BE_SENT: 'Email notification will be sent according to the schedule only if new computers are discovered.',
    EXCLUSION_MASK: 'Exclusion mask',
    EXCLUSION_MASK_NAME: 'Exclusion mask (names)',
    EXIT_CONFIGURATION: 'Exit Configuration',
    EXAMPLES_EXCESSIVE: 'Examples: Excessive abnormal terminations impacting all monitoring, backups and ' +
        'schedules or a down/offline production system cannot be restored; application or system failure caused by Veeam product.',
    EXAMPLES_PRODUCTION: 'Examples: Production application response times or system performance are ' +
        'slow, system is available. Some monitoring or backups are impacted.',
    EXAMPLES_NON_PRODUCTION: 'Examples: non-production application response times or system ' +
        'performance are slow, system is available. Some monitoring or backups are impacted.',
    EXAMPLES_HOW_TO: 'Examples: “How to” questions; the text of a message, or page of ' +
        'documentation is worded poorly or misspelled, General Feedback.',
    EDIT: 'Edit',
    EDIT_VSPHERE_BACKUP_JOB: 'Edit vSphere Backup Job',
    EDIT_BACKUP_JOB: 'Edit Backup Job',
    EDIT_COPY_JOB: 'Edit Backup Copy Job',
    EDIT_PLAN: 'Edit Plan',
    EDIT_PROFILE: 'Edit Profile',
    EDIT_OBJECT: 'Edit Object',
    EDIT_LOCATION_SETTINGS: 'Edit location settings',
    EDIT_PROCESSING_OPTIONS: 'Edit processing options',
    EDITION: 'Edition',
    EJECT_REMOVABLE_STORAGE_ONCE_BACKUP: 'Eject removable storage once backup is completed (CryptoLocker protection)',
    EJECT_REMOVABLE_STORAGE_ONCE_BACKUP_IS_COMPLETED: ' (eject removable storage once backup is completed)',
    ELEVATE_ACCOUNT_PRIVILEGES_AUTOMATICALLY: 'Elevate account privileges automatically',
    EMAILS_ON_EVERY_EVENT: 'Emails on every event',
    ENCRYPTION: 'Encryption',
    ENABLE: 'Enable',
    ENABLED: 'Enabled',
    ENABLE_ALARMS: 'Enable {0} alarms',
    ENABLE_MAINTENANCE_MODE: 'Enable Maintenance Mode',
    ENABLE_MAINTENANCE_MODE_FOR: 'Enable maintenance mode for the backup portal',
    ENABLE_THE_MFA_ACCESS: 'Enable the MFA access',
    ENABLE_WAN_ACCELERATION_THROUGH: 'Enable WAN acceleration through the following server',
    ENABLE_READ_ONLY_UI_ACCESS: 'Enable read-only UI access',
    ENABLE_READ_ONLY_UI_ACCESS_FOR: 'Enable read-only UI access for the backup agent',
    ENFORCE_MFA_FEATURE_TO_ALL_BACKUP_PORTAL_USERS: 'Enforce MFA feature to all backup portal users',
    ENFORCE_MFA_FOR_CLIENTS_AND_RESELLERS: 'Enforce MFA for clients and resellers',
    ENFORCE_MFA_FOR_YOU_COMPANY_USERS: 'Enforce MFA for your company users',
    ENFORCE: 'Enforce',
    ENFORCE_MULTI_FACTOR_AUTHENTICATION: 'Enforce Multi-Factor Authentication',
    ENFORCE_THE_MFA_ACCESS: 'Enforce the MFA access',
    ENFORCED: 'Enforced',
    ENGINE: 'Engine',
    ENTER_PASSWORD: 'Enter password',
    ENTER_USERNAME: 'Enter username',
    ENTER_TENANT_ID: 'Enter tenant ID',
    ENTER_CLIENT_SECRET: 'Enter client secret',
    ENTER_APPLICATION_CLIENT_ID: 'Enter application client ID',
    ENTIRE_COMPUTER: 'Entire computer',
    ENTIRE_EFS: 'Entire EFS',
    EXCLUSION_MASK_NAMES: 'Exclusion mask (names)',
    EXECUTE_FILE: 'Execute file',
    EXPIRATION_DATE: 'Expiration Date',
    EXPIRED: 'Expired',
    EXPIRATION_PERIOD: 'Expiration period',
    EXTERNAL: 'External',
    EXTERNAL_NETWORK: 'External Network',
    ERROR: 'Error',
    ERRORS: 'Errors',
    ERRORS_AND_WARNINGS: 'Errors and warnings',
    ERRORS_ONLY: 'Errors only',
    ENABLE_RULE: 'Enable rule',
    EMAIL_NOTIFICATION: 'Email Notification',
    EMAIL_NOTIFICATION_SUBJECT_ALLOWS_USING: 'Email notification subject allows using custom text with these variables',
    ENABLE_BACKUP_CACHE: 'Enable backup cache',
    EMAIL_SETTINGS_HAVENT_BEEN_SAVED: 'Email settings haven\'t been saved. All changes will be lost. Do you want to leave this page?',
    ENABLE_APPLICATION_PROCESSING: 'Enable application processing',
    ENABLE_APPLICATION_AWARE_PROCESSING: 'Enable application-aware processing',
    ENABLE_GUEST_FILE_SYSTEM_INDEXING: 'Enable guest file system indexing',
    ENABLE_ACCESS_TO_REST_API: 'Enable access to REST API of the backup portal',
    ENABLE_SCRIPT_EXECUTION: 'Enable script execution',
    EXCLUDE_MASKS: 'Exclude masks',
    ENTER_AT_LEAST_ONE_NETWORK_IP_RANGE: 'Enter at least one network IP range',
    ENTER_USERNAME_AND_CONTACT_INFORMATION: 'Enter username and contact information.',
    ENTERPRISE_APPLICATION: 'Enterprise Application',
    EXCLUDE_FROM_BACKUP: 'Exclude from backup',
    EXCLUDE_ROAMING_USER_PROFILES: 'Exclude roaming user profiles from backup',
    EXCLUDING_DAYS_WITH_NO_BACKUP: 'excluding days with no backup',
    ENABLE_PERIODIC_CHECKS: 'Enable periodic checks (recommended)',
    END_DATE: 'End Date',
    END_DATE_SHOULD_BE_GREATER_THAN: 'End date should be greater than Start date',
    END_TIME: 'End Time',
    ENTIRE_COMPUTER_RECOMMENDED: 'Entire computer (recommended)',
    END_DATE_MUST_BE_LATER: 'End date must be later than start date.',
    EMAIL_SERVER_SETTINGS: 'Email Server Settings',
    EVERY: 'Every',
    EVERY_VAL: 'Every {0}',
    EVERYDAY: 'Everyday',
    EVERYDAY_AT: 'Everyday at {0}',
    EVERY_MONTH: 'every month',
    EXCLUSION_MASKS: 'Exclusion masks',
    ENABLE_PERIODIC_FULLS: 'Enable periodic fulls, or schedule a script to create them if you are not using object storage as a target.',
    EXCLUDE_FILES_OR_FOLDERS: 'Exclude files or folders',
    EXCLUSION: 'exclusion',
    EXCLUSIONS: 'Exclusions',
    EVENT_LOG: 'Event Log',
    EVENT_NAME: 'Event name',
    EQUALS: 'Equals',
    EXCLUDED_ITEMS: 'Excluded items',
    EXCLUDED_OBJECTS: 'Excluded objects',
    EXCLUDE_MICROSOFT_ONEDRIVE_FOLDER: 'Exclude Microsoft OneDrive folder',
    EDIT_JOB: 'Edit Job',
    ENABLE_JOB: 'Enable Job',
    ENABLE_JOB_SCHEDULING: 'Enable job scheduling',
    ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES: 'Enable automatic delivery of invoices for this user',
    ENABLING_THIS_WILL_ALLOW_CONFIGURE_SCHEDULE_FOR_MICROSOFT_365_JOBS: 'Enabling this will allow the company to configure its own schedule for ' +
        'Microsoft 365 jobs',
    ENABLING_THIS_WILL_ALLOW_CONFIGURE_SCHEDULE_FOR_VSPHERE_JOBS: 'Enabling this will allow the company to configure its own schedule for ' +
        'vSphere backup jobs',
    EXTERNAL_USB_DRIVES: 'External USB drives',
    EDIT_IDENTITY_PROVIDER: 'Edit Identity Provider',
    EDIT_AUTHORIZATION_RULE: 'Edit Authorization Rule',
    EDIT_INTERNAL_USER: 'Edit Internal User',
    EDIT_LOCAL_USER: 'Edit Local User',
    EDIT_WINDOWS_USER: 'Edit Windows User',
    EDIT_USER: 'Edit User',
    ENTITY_ID: 'Entity ID',
    ENABLED_OPTION: 'Enabled option',
    EXTERNAL_DISCOVERY: 'External discovery',
    ENFORCE_MFA_FOR_LOCAL_USERS: 'Enforce MFA for local users',
    ENABLE_CLOUD_RESOURCES: 'Enable cloud resources to store backups and VM replicas for the company (optional).',
    ENABLE_THE_POSSIBILITY_TO_DEPLOY_AND_MANAGE: 'Enable the possibility to deploy and manage Veeam Backup appliances using backup portal.',
    ENTER_NAME_OR_ADDRESS: 'Enter name or address',
    EXTREME_COMPRESSION_PROVIDES: 'Extreme compression provides additional 3% compression ratio over High, ' +
        'at the cost of 4x higher CPU usage.',
    EXTENSIBLE_STORAGE_ENGINE: 'Extensible Storage Engine (JET databases)',
    EMAIL_SERVER_SETTINGS_HAVE_BEEN_SAVED: 'Email server settings have been saved successfully.',
    EMAIL_SERVER_SETTINGS_HAVE_BEEN_RESET: 'Email server settings have been reset successfully.',
    ENABLE_PRODUCT_UPDATES: 'Enable Product Updates & Download feature in the Configuration to allow automatic installation ' +
        'and update of the backup server.',
    ENCRYPTION_PROTOCOL: 'Encryption protocol',
    ENTERPRISE_PLUS: 'Enterprise Plus',
    ENTERPRISE: 'Enterprise',
    ENTERPRISE_EDITION: 'Enterprise Edition',
    ENTERPRISE_PLUS_EDITION: 'Enterprise Plus Edition',
    ENTER_EMAIL: 'Enter email',
    ENABLE_THE_POSSIBILITY_TO_CREATE: 'Enable the possibility to create and manage backup jobs on a hosted backup server.',

    FAVICON: 'Favicon',
    FEBRUARY: 'February',
    FEDORA: 'Fedora',
    FAILED_TO_CONFIRM_THE_VERIFICATION: 'Failed to confirm the verification code. Please retry the operation in a couple of minutes.',
    FAILED_TO_DOWNLOAD_LOG_FILES: 'Failed to download log files. Contact technical support team for assistance.',
    FAILED_TO_UPLOAD_PATCH: 'Failed to upload patch files to the {0}. Please review the debug logs for more information.',
    FILE: 'File',
    FILES: 'Files',
    FILE_BACKED_UP: 'File Backed up',
    FILE_LEVEL: 'File-level',
    FILES_UPLOAD: 'Files Upload',
    FILE_LEVEL_BACKUP_SLOWER: 'File level backup (slower)',
    FILE_LEVEL_BACKUP: 'File level backup',
    FILE_SYSTEM_INDEXING_MODE: 'File System Indexing Mode',
    FILE_SHARE_BACKUP: 'File Share Backup',
    FILE_BACKUP: 'File backup',
    FILE_BACKUP_COPY: 'File backup copy',
    FILE_SHARE_BACKUP_ADVANCED_SETTINGS: 'File Share Backup Advanced Settings',
    FILE_SHARE: 'File Share',
    FILE_SHARES: 'File Shares',
    FILE_SHARE_TYPE: 'File share type',
    FILES_AND_FOLDERS: 'Files & Folders',
    FILE_COPY: 'File copy',
    FILE_TO_TAPE: 'File to tape',
    FILTER: 'Filter',
    FILTER_ACTIVE_ALARMS_BY: 'Filter active alarms by',
    FILTER_AGENTS_BACKUP_JOBS_BY: 'Filter agents backup jobs by',
    FILTER_APPLIANCES_BY: 'Filter appliances by',
    FILTER_BACKUP_AGENTS_BY: 'Filter backup agents by',
    FILTER_JOBS_BY: 'Filter jobs by',
    FILTER_JOBS_BY_TYPE: 'Filter jobs by type',
    FILTER_LICENSE_KEYS_BY: 'Filter license keys by',
    FILTER_SERVERS_BY: 'Filter servers by',
    FILTER_DISCOVERY_COMPUTERS_BY: 'Filter discovered computers by',
    FILTER_REPORTS_BY: 'Filter reports by',
    FILTER_COMPANIES_BY: 'Filter companies by',
    FILTER_COMPUTERS_BY: 'Filter computers by',
    FILTER_BACKUP_POLICIES_BY: 'Filter backup policies by',
    FILTER_POLICIES_BY: 'Filter policies by',
    FILTER_PROTECTED_WORKLOADS_BY: 'Filter protected workloads by',
    FILTER_VMS_BY: 'Filter VMs by',
    FINALIZATION: 'Finalization...',
    FIRST: 'First',
    FIRST_NAME: 'First name',
    FAILED: 'Failed',
    FAILED_SESSIONS: 'Failed Sessions',
    FAILED_TO_LOAD_DATA: 'Failed to load data. Try again later.',
    FAILED_TO_LOAD_THE_PAGE: 'Failed to load the page due to untrusted security certificate. Verify the certificate for server {0} to continue' +
        ' using the backup portal.',
    FAILED_TO_CONNECT_TO: 'Failed to connect to the remote backup server. Trying to reconnect...',
    FAILED_TO_UPDATE: 'Failed to update',
    FAILED_TO_DOWNLOAD_THE_SELECTED: 'Failed to download the selected license key. Contact your service provider for assistance.',
    FAILED_TO_APPLY: 'Failed to apply',
    FAILED_TO_APPLY_ALARM_CONFIGURATION: 'Failed to apply alarm configuration',
    FAILED_TO_AUTHENTICATE: 'Failed to authenticate',
    FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT: 'Failed to authenticate a user account',
    FAILED_TO_AUTO_APPROVE_YOUR_MONTHLY: 'Failed to auto-approve your monthly usage report. Please review the report and approve it manually.',
    FAILOVER_PLAN: 'Failover Plan',
    FAILOVER_PLANS: 'Failover Plans',
    FAILOVER_PROGRESS: 'Failover progress',
    FAILOVER_PLAN_OPERATION_FAILED_AT: 'Failover plan operation failed at',
    FAILOVER_PLAN_UNDO_OPERATION_FAILED_AT: 'Failover plan undo operation failed at',
    FAILOVER_PLAN_OPERATION_FINISHED_WITH_WARNING_AT: 'Failover plan operation finished with warning at',
    FAILOVER_PLAN_UNDO_OPERATION_FINISHED_WITH_WARNING_AT: 'Failover plan undo operation finished with warning at',
    FAILOVER_PLAN_STATUS: 'Failover Plan Status',
    FAILOVER_TO_OTHER_CLOUD_GATEWAYS: 'Failover to other cloud gateways if all gateways from the selected pool are unavailable',
    FINISH_ALL_RESTORE_PROCESSES: 'Finish all restore processes, stop and disable all jobs, make sure that the backup console' +
        ' is closed before starting the patch process.',
    FILTERING_DATA_BY_LOCATIONS: 'Filtering data by locations is not supported for the selected view',
    FILE_FILTERS: 'File Filters',
    FILE_SHARE_SIZE: 'File share size',
    FILE_SHARE_SIZE_FREE_OF_CHARGE: 'File share size free of charge',
    FILE_SIZE_MUST_BE_LESS: 'File size must be less than 1 MB.',
    FOR_MORE_DETAILS: 'for more details.',
    FILTER_ALARMS_BY: 'Filter alarms by',
    FILTER_ALARMS_BY_CATEGORY: 'Filter alarms by category',
    FREE_OF_CHARGE: 'Free of charge',
    FREE_SPACE: 'Free Space',
    FRIENDLY_NAME: 'Friendly name',
    FROM_LOCAL_FOLDER: 'From local folder',
    FROM_VEEAM_WEB_SERVER: 'From Veeam web server',
    FOR_WINDOWS_OPERATING_SYSTEMS_ONLY: 'For Windows operating systems only.',
    ENABLE_THE_LOCALSTORAGE: 'Enable the localStorage option in the browser settings to continue working with the {0}.\n\n' +
        'Change the browser settings, then refresh the web page.',
    FOR_EXCLUDES_YOU_CAN: 'For excludes, you can additionally specify path to a folder.',
    FOR_INFORMATION_ON_SUPPORTED_FILES: 'For information on supported files format, please open ' +
        '<a href="{0}" target="_blank" rel="noopener noreferrer">this link</a>',
    FORGOT_PASSWORD: 'Forgot password?',
    FORMAT: 'Format',
    FOURTH: 'Fourth',
    FULL: 'Full',
    FULL_ACCESS: 'Full access',
    FULL_BACKUP_FILE_MAINTENANCE: 'Full backup file maintenance',
    FRIDAY: 'Friday',
    FROM: 'From',
    FROM_VCSP_PULSE: 'From VCSP Pulse',
    FAVORITES: 'Favorites',
    FULL_RESYNC: 'Full Resync',
    FOR_KEYCLOAK_SPECIFY_URL: 'For Keycloak: specify realms URL.<br />' +
        'For ADFS: specify ADFS server URL.<br />' +
        'For Custom or Okta: specify provider\'s metadata URL.',
    FOR_OKTA: 'For Okta: Statement;',
    FOR_KEYCLOAK_ADFS: 'For Keycloak, ADFS, Azure: Claim',
    FILE_LEVEL_RECOVERY: 'File-Level Recovery',
    FILE_LEVEL_RESTORE_PORTAL: 'File-Level Restore Portal',
    RESTORE_STATUS: 'Restore Status',
    FREE_SPACE_LESS_THAN: 'Free space less than',
    ENTIRE_ORGANIZATION: 'Entire organization',
    FILTER_CASES_BY: 'Filter cases by',
    FOLDER_PATH: '\\\\folder\\file.iso',
    FOLDER: 'Folder',
    FAILED_TO_INSTALL: 'Failed to install license key',
    FILE_LEVEL_RESTORE: 'File-level restore',
    FILE_LEVEL_RESTORE_CAPITALIZED: 'File-Level Restore',
    FILE_SHARE_BACKUP_COPY: 'File share backup copy',
    FILE_SHARE_SOURCE_SIZE: 'File share source size',

    GB: 'GB',
    GENERAL_SERVICES: 'General Services',
    GENERATE_INVOICE: 'Generate invoice',
    GENERATE_INVOICE_TO_VIEW_THE_DATA: 'Generate invoice to view the data',
    GENERATE_QUOTA_USAGE_REPORT: 'Generate Quota Usage Report',
    GCP_INSTALL: 'GCP_INSTALL',
    GOOGLE: 'Google',
    GOOGLE_AUTHENTICATOR: 'Google Authenticator',
    GENERATE_THE_QR_CODE: 'Generate the QR code',
    GUEST_OS: 'Guest OS',
    GUEST_OPERATING_SYSTEMS: 'Guest Operating Systems',
    GENERATION_DATE: 'Generation Date',
    GENERATED_ON: 'Generated On',
    GENERATE_A_KEY_FOR_ME: 'Generate a key for me',
    GENERATE_NEW_CERTIFICATE: 'Generate new certificate',
    GENERATE_A_NEW_SELF_SIGNED_CERTIFICATE: 'Generate a new self-signed certificate that is verifiable with the thumbprint.',
    GENERATE_AN_API_KEY_FOR_AUTHENTICATION: 'Generate an API key for authentication of 3rd party applications',
    GENERATE_CERTIFICATE: 'Generate Certificate',
    GENERAL: 'General',
    GET_FROM_THE_CONFIGURATION_FILE: 'Get from the configuration file',
    GROUP: 'Group',
    GROUPS: 'Groups',
    GROUP_BY: 'Group by',
    GROUP_SITE: 'Group Site',
    GROUP_MAIL: 'Group Mail',
    GRACEFULLY: 'Gracefully',
    GST: 'GST',
    GATEWAY_POOLS: 'Gateway Pools',
    GATEWAY_POOL: 'Gateway pool',
    GATEWAY_POOL_NAME: 'Gateway Pool Name',
    GUEST_PROCESSING: 'Guest Processing',
    GUEST_FILE_SYSTEM_INDEXING: 'Guest file system indexing',
    GUEST_FILE_SYSTEM_INDEXING_MODE: 'Guest file system indexing mode',
    GUEST_FILE_SYSTEM_INDEXING_OPTIONS: 'Guest file system indexing options',
    GFS_RETENTION_POLICY: 'GFS retention policy',
    GFS_RETENTION_SETTINGS: 'GFS Retention Settings',
    GFS_RETENTION_POLICY_IS_NOT: 'GFS retention policy is not configured',
    GRANULAR_FOR_EACH_SCALE_OUT_BACKUP: 'Granular for each scale-out backup repository tier usage',
    GENERATED_A_SELF_SIGNED_CERTIFICATE: 'Generate a self-signed certificate',
    GFS_RETENTION_IS_NOT_COMPATIBLE: 'GFS retention is not compatible with defragment and compact functionality.',
    GUID: 'GUID',
    GATEWAY_POOLS_SELECTED: '{0} gateway pools selected',
    GATEWAY_SELECTION: 'Gateway selection',
    GATEWAY_POOLS_ARE_NOT_SUPPORTED: 'Gateway pools are not supported by the site hosting this company.',
    GOOGLE_CLOUD: 'Google Cloud',
    GETTING_INFORMATION: 'Getting information about certificate status',
    GETTING_STARTED: 'Getting Started',
    GENERATE_THIS_INVOICE_AUTOMATICALLY: 'Generate this invoice automatically',
    GHz: 'GHz',
    GUEST_OS_CREDENTIALS: 'Guest OS credentials',
    GFS_RETENTION_POLICY_IS_NOT_CONFIGURED: 'GFS retention policy is not configured',

    HOSTED_SERVICES: 'Hosted Services',
    HOSTED: 'Hosted',
    HOSTNAME: 'Hostname',
    HERE_YOU_CAN_FIND_ALL_INTEGRATIONS: 'Here you can find all predefined integrations available in your currently installed ' +
        'version of Veeam Service Provider Console.\nTo enable the integration, simply click on the tile ' +
        'and follow the instructions specified for each application plugin.',
    HOST_OR_CLUSTER: 'Host or Cluster',
    HINT: 'Hint',
    HIBERNATE: 'Hibernate',
    HARDWARE_PLAN: 'Hardware Plan',
    HARDWARE_PLAN_USAGE: 'Hardware Plans Usage',
    HARDWARE_PLANS_SLOTS: 'Hardware Plans Slots',
    HOME_PATH: '/home',
    HOME_ME_MYDIR_PATH: '/home/me/mydir',
    HOUR: 'hour',
    HOURS: 'hours',
    HEALTHY: 'Healthy',
    HEALTH_STATE: 'Health State',
    HYPER_V: 'Hyper-V',
    HIDE_HELP: 'Hide Help',
    HERE: 'here',
    HIGH_COMPRESSION_LEVEL: 'High compression level provides additional 15% compression ratio over Optimal at the cost of 2x higher CPU usage.',
    HOSTED_BACKUP_RESOURCES: 'Hosted backup resources',
    HOSTED_BACKUP_RESOURCES_ARE_SWITCHED_ON_BUT_NOT_CONFIGURED: 'Hosted backup resources are switched on but not configured.',
    HOSTED_BACKUP_RESOURCES_WERE_ASSIGNED_TO_THIS_COMPANY_BY: 'Hosted backup resources were assigned to this company by a Service provider. If you' +
        ' disable these resources this company will not be able to create jobs on the assigned server. Are you sure you want to continue?',

    IDEAL_FOR_APPLICATION_SERVER_BACKUP: 'Ideal for application server backup due to application-aware backups, ' +
        'guest indexing support and flexible job schedule',
    INFECTED: 'Infected',
    INFORMATION_ABOUT_PRODUCT_UPDATES: 'Information about product updates is not available',
    INFRASTRUCTURE: 'Infrastructure',
    INFRASTRUCTURE_HEALTH: 'Infrastructure Health',
    IGNORE_OFFLINE_COMPUTERS: 'Ignore offline computers (30 days)',
    IGNORE_TIME_PERIOD_NBD: '[NBD] Ignore Time Period',
    IGNORE: 'Ignore',
    IF_THE_BACKUP_JOB_HAS: 'If the backup job has a backup copy job configured,' +
        ' the list includes only repositories that are located on the same proxy and have the same retention type as the backup copy job repository.',
    IGNORE_MULTI_FACTOR_AUTHENTICATION: 'Ignore Multi-Factor Authentication',
    IGNORE_IMPORTED_BACKUPS: 'Ignore imported backups',
    ID: 'ID',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_SELECT: 'In the advanced settings you can select the charging model (allocation,'
        + ' consumption, or usage per tier) for the cloud storage. Additionally, you can set price for the space consumed'
        + ' by deleted backups and for data transfer out.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_SET_PRICE: 'In the advanced settings you can set price for the cloud storage,'
        + ' compute recourses and data transfer out. Additionally, you can configure how much compute time is included'
        + ' free of charge for testing VM failover operations and how much disk storage comes in for free for VM virtual disks.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_THE_NUMBER_OF_WORKLOADS: 'In the advanced settings you can configure the number of workloads '
        + 'provided free of charge.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_HOW_MUCH_COMPUTE_TIME: 'In the advanced settings you can configure how much compute time'
        + ' is included free of charge for testing VM failover operations and how much disk storage comes in for free for VM virtual disks.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_THE_NUMBER_OF_USERS: 'In the advanced settings you can configure the ' +
        'number of users and the amount of storage provided free of charge.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_THE_NUMBER_OF_VMS: 'In the advanced settings you can configure the number of VMs '
        + 'and managed agents included free of charge and define the extra cost for an operating system used to run a backup agent.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_THE_NUMBER_OF_WORKLOADS_AND: 'In the advanced settings you can configure the number of workloads'
        + ' provided free of charge and define the extra charges for an operating system used to run a backup agent.',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_DEFINE_HOW_MUCH_DATA: 'In the advanced settings you can define how much data will be included'
        + ' free of charge for the file share size, backup size, and the archive. ',
    IN_THE_ADVANCED_SETTINGS_YOU_CAN_SELECT_THE_CHARGING_MODEL: 'In the advanced settings, you can select the charging model '
        + '(allocation, consumption, or usage per tier) for the cloud storage. Additionally, you can set price for the'
        + ' space consumed by deleted backups.',
    INVOICE_CONFIGURATION: 'Invoice Configuration',
    INVOICE_PARAMETERS: 'Invoice Parameters',
    INCLUDE_DETAILS: 'Include details',
    ISSUE_SUMMARY: 'Issue summary',
    INCLUDE_DETAILED_INFORMATION_TO: 'Include detailed information to the report',
    INCLUDE_PERIODICALLY_CONNECTED_USB_DRIVES: 'Include periodically connected USB drives',
    INCLUDE_THE_PREDEFINED_SELF_SERVICE_SECTION_TO: 'Include the predefined self-service section to the welcome email',
    INCLUSION: 'inclusion',
    INVALID_PATH_FORMAT: 'Invalid path format',
    INACCESSIBLE: 'Inaccessible',
    INVOICE_DOWNLOAD: 'Invoice Download',
    INVOICE_SCHEDULING: 'Invoice Scheduling',
    INVOICE_STATUS: 'Invoice status',
    INVALID_VALUE: 'Invalid value',
    INFO: 'Info',
    INFORMATION: 'Information',
    ISSUED_TO: 'Issued to',
    ISSUED_BY: 'Issued by',
    ISSUER: 'Issuer',
    IS_NOT_SET: 'Is not set',
    IF_THERE_ARE_ACTIVE_RESTORE_OPERATIONS: 'If there are active restore operations, they will fail upon restarting backup services.'
        + '\n\nDo you want to proceed?',
    IF_THE_JOB_DOES_NOT_COMPLETE_WITHIN: 'If the job does not complete within allocated backup window, ' +
        'it will be terminated to prevent snapshot commit during production hours.',
    THIS_WILL_SET_A_NEW_COMPANY: 'This will set a new company to the selected agents. If there are existing cloud backup policies,' +
        ' then update the corresponding backup target in the policy settings afterward.',
    IP_ADDRESS: 'IP address',
    INSTALL: 'Install',
    INSTALL_FROM_LOCAL_FOLDER: 'Install from Local Folder',
    INSTALL_SECURITY_CERTIFICATE: 'Install security certificate',
    INSTALL_NEW_OR_SPECIFY_EXISTING: 'Install new or specify existing',
    INSTALL_NEW_OR_SPECIFY_EXISTING_SECURITY_CERTIFICATE: 'security certificate',
    INSTALL_NEW_OR_SPECIFY_EXISTING_TO_USE_IN_THIS_PORTAL: 'to use in this portal',
    INSTALL_BACKUP_AGENT: 'Install Backup Agent',
    INSTALL_LICENSE: 'Install license',
    INSTALLDIR: 'INSTALLDIR',
    INSTALLED_EXPIRATION_DATE: 'Installed (Expiration date: {0})',
    INSTALLING_THE_SAME_LICENSE_KEY: 'Installing the same license key on multiple servers is not recommended. The VCSP ' +
        'Pulse portal will not accept monthly license usage statistics coming from these servers. Install one key ' +
        'per server to follow the license deployment best practices.',
    INVOICE: 'Invoice',
    INVOICES: 'Invoices',
    INVOICE_ID: 'Invoice ID',
    IMMEDIATELY: 'Immediately',
    IMPORT_CERTIFICATE: 'Import Certificate',
    IMPORT_CERTIFICATE_FROM_A_FILE: 'Import certificate from a file',
    IMPORT_CERTIFICATE_MSG: 'Select this option to import certificate from PFX file. ' +
        'The certificate will be automatically imported into the local Certificate Store of this server.',
    IMPORT_CERTIFICATE_TITLE_MSG: 'Specify the PFX file to import certificate from. ' +
        'The certificate will be automatically imported into the local Certificate Store of this server.',
    IMPORTED_FILE_SHARE_BACKUP: 'Imported File Share Backup',
    IMPORTED_FILE_BACKUP: 'Imported file backup',
    INDEX_EVERYTHING: 'Index everything',
    INDEX_EVERYTHING_EXCEPT: 'Index everything except',
    INDEX_ONLY_FOLLOWING_FOLDERS: 'Index only following folders',
    INDEXING_SETTINGS: 'Indexing settings',
    INVALID_EMAIL_FORMAT: 'Invalid email format',
    INCLUDING_INVOICES_BACKUP_POLICIES: 'Including invoices, backup policies, subscription plans',
    EXCLUDE_MASKS_MUST: 'Exclude mask must be a windows file wildcard and cannot contain a file path.',
    INCLUDE_MASKS: 'Include masks',
    INCLUDE_COMPANIES_MANAGED_BY_RESELLERS: 'Include companies managed by resellers',
    INCLUDED_ITEMS: 'Included items',
    INCLUDED_OBJECTS: 'Included objects',
    INCLUSION_MASKS: 'Inclusion masks',
    INSTALL_CBT_DRIVER: 'Install CBT Driver',
    INSTALLING: 'Installing',
    INSTALLED: 'Installed',
    INSTALLATION_OPTION: 'Installation option',
    INSTALL_BACKUP_AGENT_AUTOMATICALLY: 'Install backup agent automatically',
    INSTALL_BACKUP_AGENT_LATER: 'Install backup agent later',
    INTEGRATE_WITH_VCSP_PULSE: 'Integrate with VCSP Pulse',
    INTERNAL: 'Internal',
    INITIATED: 'Initiated',
    INSTALL_A_COMPATIBLE_APP_ON_YOUR_MOBILE: 'Install a compatible app on your mobile device or computer',
    INSTALL_AGENT: 'Install Agent',
    INVALID_SHARED_FOLDER: 'Invalid shared folder',
    INVALID_CHARACTER: 'Invalid character',
    INVALID_CHARACTERS_IN_THE: 'Invalid characters in the {0}.',
    INSTANCE_BASED: 'Instance-based',
    INSTANCE: 'Instance',
    INSTANCE_RESTORE: 'Instance Restore',
    INSTANCES: 'Instances',
    I_AGREE_THAT_DEBUG: 'I agree that debug logs for this support case will be uploaded to Veeam servers.',
    IN_PROGRESS: 'In Progress',
    IGNORE_SCRIPT_EXECUTION_FAILURES: 'Ignore script execution failures',
    ITEM_REQUIRES_APPROVAL: 'item requires approval',
    ITEMS_REQUIRE_APPROVAL: 'items require approval',
    INVALID_IP_ADDRESS: 'Invalid IP address',
    IP_RANGE_MUST_BE_IN: 'IP range must be in the same network class',
    IF_COMPUTER_IS_POWERED_OFF_AT_THIS_TIME: 'If computer is powered off at this time',
    IF_ANY_USER_WITH_ENABLED_MFA_IS_USING: 'If any user with enabled MFA is using backup portal RESTful APIs to integrate with' +
        ' 3rd party applications via username/password method, then these integrations will stop working.',
    IF_ANY_USER_FROM_THE_GROUP_IS_UTILIZING: 'If any user from the group is utilizing backup portal RESTful APIs to integrate with' +
        ' 3rd party applications via username/password method, then these integrations will stop working.',
    IF_MULTIPLE_FULL_BACKUPS_EXIST: 'If multiple full backups exist, use the one from',
    IF_THIS_CLIENT_IS_USING_BACKUP_PORTAL: 'If this client is using backup portal RESTful APIs to integrate with 3rd party' +
        ' applications via username/password method, then these integrations will stop working.',
    IF_THIS_COMPANY_IS_USING_BACKUP_PORTAL: 'If this company is using backup portal RESTful APIs to integrate with 3rd party applications via' +
        ' username/password method, then these integrations will stop working.',
    IF_THIS_RESELLER_OR_HIS_MANAGED_COMPANIES: 'If this reseller or his managed companies are using backup portal RESTful APIs to' +
        ' integrate with 3rd party applications via username/password method, then these integrations will stop working.',
    IF_THIS_USER_IS_LEVERAGING_BACKUP_PORTAL: 'If this user is leveraging backup portal RESTful APIs to integrate with 3rd party' +
        ' applications and use his credentials for authentication, then these integrations will stop working.',
    IF_THIS_USER_IS_UTILIZING_BACKUP_PORTAL: 'If this user is utilizing backup portal RESTful APIs to integrate with 3rd party' +
        ' applications via username/password method, then these integrations will stop working.',
    IF_YOU_ARE_USING_BACKUP_PORTAL_RESTFUL_APIS: 'If you are using backup portal RESTful APIs to integrate with 3rd party applications' +
        ' and use your credentials for authentication, then these integrations will stop working.',
    IF_YOU_DELETE_THIS_AGENT: 'If you delete this agent, the following products will be removed from your managed infrastructure: {0}.',
    IF_YOUR_BACKUP_SERVER_IS_MANAGED_BY: 'If your backup server is managed by Veeam Backup Enterprise Manager, ' +
        'make sure you upgrade this server first',
    IPHONE: 'iPhone',
    INCLUDE_CUSTOM_TEXT: 'Include custom text',
    INCLUDE_EXTERNAL_USB_DRIVES: 'Include external USB drives',
    INCLUDE_FILES_OR_FOLDERS: 'Include files or folders',
    INCOMING_NETWORK_TRAFFIC_WILL: 'Incoming network traffic will be limited to {0} {1}.',
    INDIVIDUAL_REPORT: 'Individual report',
    INDIVIDUAL_REPORTS: 'Individual reports',
    IP_RANGE: 'IP range',
    IP_RANGES: 'IP ranges',
    IDENTITY_PROVIDER: 'Identity Provider',
    IDENTITY_PROVIDER_SETTINGS: 'Identity provider settings',
    THIS_ACTION_WILL_ENABLE_VEEAM: 'This action will enable Veeam Backup for Microsoft 365 REST API service on selected servers.' +
        ' Do you want to proceed?',
    IT_IS_NOT_RECOMMENDED_TO_SHARE: 'It is not recommended to share the same license key on different servers. ' +
        'Press the Copy button to create multiple copies of the selected license key and to install it on each server.',
    IT_IS_RECOMMENDED_TO_PERFORM_A_FULL: 'It is recommended to perform a full backup before changing the backup repository. '
        + 'After you change the repository, all previously created restore points will become unavailable.',
    IDENTITY_PROVIDER_STATE: 'Identity Provider State',
    IDENTITY_PROVIDER_STATUS: 'Identity Provider Status',
    IDENTITY_PROVIDER_NAME: 'Identity Provider Name',
    THIS_BACKUP_SERVER_IS_MANAGED: 'This backup server is managed by a Veeam Backup Enterprise Manager.' +
        ' Backup server license key will be overwritten by the Veeam Backup Enterprise Manager if the corresponding option is enabled.' +
        ' Do you want to proceed?',
    IDENTITY_PROVIDER_INFO: 'Identity Provider Info',
    IDENTITY_PROVIDER_URL: 'Identity provider URL',
    IDENTITY_PROVIDER_WITH_THE_SAME_NAME_ALREADY_EXISTS: 'Identity provider with the same name already exists.',
    IMPORT_MAPPING_RULES: 'Import Mapping Rules',
    ITEMS: 'Items',
    IF_YOU_WANT_TO_SAVE: 'If you want to save already configured mapping rules for future use, click the ',
    INSTALL_BACKUP_SERVER: 'Install Backup Server',
    INCLUDE_SELF_SERVICE: 'Include "Self-Service Portal" section to the welcome email',
    INCLUDE_DATA_ON_EXTERNAL_USB_DRIVES: 'Include data on external USB drives',
    ITEMS_COUNT_NEED_UPDATE: '{0} need update',
    ITEMS_COUNT_NEEDS_UPDATE: '{0} needs update',
    INVALID_FILE: 'Invalid File',
    INSTALL_LICENSE_FILE: 'Install license file',
    INVALID_LOCATION_PATH: 'Invalid location path',
    INVALID_VERIFICATION_CODE: 'Invalid verification code',
    IF_THERE_ARE_NO_OTHER_VEEAM_PRODUCS: 'If there are no other Veeam products, {0} management agent{1} will be removed ' +
        'from the remote machine{1} and {0} backup agent{1} will be switched to the FREE mode. ' +
        'Do you want to proceed?',
    INTERNAL_ID: 'Internal ID',

    JANUARY: 'January',
    JUNE: 'June',
    JULY: 'July',
    JOB_ASSIGNMENT: 'Job assignment',
    JOB_FINISHED_WITH: 'Job finished with',
    JOB_STATUS: 'Job Status',
    JOB: 'Job',
    JOBS: 'jobs',
    JOBS_DASHBOARD: 'Jobs Dashboard',
    JOB_TYPE: 'Job Type',
    JOB_TYPES: 'Job Types',
    JOB_DETAILS: 'Job Details',
    JOB_MODE: 'Job mode',
    JOB_NAME: 'Job name',
    JOB_STATE: 'Job state',
    JOB_STATISTICS: 'Job Statistics',
    JUST_NOW: 'just now',
    JOBS_OVERVIEW: 'Jobs Overview',
    JOB_SCHEDULING: 'Job scheduling',
    JOB_SCHEDULE: 'Job schedule',
    JET_DATABASE: 'Jet database',

    K_PLUS: 'K+',
    KASTEN_INSTALL: 'KASTEN_INSTALL',
    KEEP_RESTORE_POINTS_FOR_THE_LAST: 'Keep restore points for the last {0}',
    KEEP_LAST_N_RESTORE_POINTS: 'Keep last {0} restore points',
    KEEP_RUNNING: 'Keep running',
    KEEP_SOME_PERIODIC_FULL_BACKUPS: 'Keep some periodic full backups longer for archival purposes',
    KEEP_WEEKLY_FULL_BACKUP_FOR: 'Keep weekly full backup for',
    KEEP_MONTHLY_FULL_BACKUPS_FOR: 'Keep monthly full backups for',
    KEEP_YEARLY_FULL_BACKUPS_FOR: 'Keep yearly full backups for',
    KEEP_BACKUPS_FOR: 'Keep backups for',
    KEEP_ONLY_LAST: 'Keep only last:',
    KEEP_ONLY_LAST_OF_LOG_BACKUPS: 'Keep only last {0} of log backups',
    KEEP_CERTAIN_FULL_BACKUPS_LONGER: 'Keep certain full backups longer for archival purposes',
    KEY_ID: 'Key ID',
    KB_ARTICLE: 'KB article',
    KNOWN_COMPUTERS_ONLY: 'Known Computers Only',
    KEYCLOAK: 'Keycloak',
    KB: 'KB',
    KBS: 'KB/s',
    KNOWLEDGE: 'Knowledge',
    KNOWLEDGE_BASE: 'Knowledge Base',

    LAST: 'Last',
    LAST_24_HOURS: 'Last 24 hours',
    LAST_CDP_REPLICATION: 'Last CDP Replication',
    LAST_DAY: 'last day',
    LAST_DURATION: 'Last Duration',
    LAST_7_DAYS: 'Last 7 Days',
    LOG_STORAGE_LOCATION: 'Log storage location',
    LAST_ARCHIVE: 'Last Archive',
    LAST_BACKUP_COPY: 'Last Backup Copy',
    LAST_BACKUP_ON_TAPE: 'Last Backup on Tape',
    LAST_CHECKED: 'Last checked',
    LAST_INVOICE: 'Last Invoice',
    LAST_JOB_RUN: 'Last Job Run',
    LAST_MODIFIED: 'Last Modified',
    LAST_NAME: 'Last name',
    LAST_OBSERVED_ERRORS: 'Last observed errors',
    LAST_PAYMENT: 'Last Payment',
    LAST_PERIOD: 'Last period',
    LAST_UPDATE_DATE: 'Last update date',
    LATEST_ALARMS: 'Latest Alarms',
    LATEST_BACKUP_SERVER_ALARMS: 'Latest Backup Server Alarms',
    LATEST_PATCH: 'Latest patch',
    LAUNCH_THE_DISCOVERY_RULE_WHEN: 'Launch the discovery rule, when I click Finish',
    LEAVE_CONFIRM_MSG: '{0} haven\'t been saved. All changes will be lost. Do you want to leave this page?',
    LEFT: 'Left',
    LICENSE_CREATION: 'License creation',
    LICENSE_ID: 'License ID',
    LICENSE_KEY: 'License key',
    LICENSE_KEYS: 'License keys',
    LICENSE_KEY_WITH_THE_SAME_ID: 'License key with the same ID installed on multiple servers',
    LICENSE_MANAGEMENT: 'License Management',
    LICENSE_HAS_BEEN_INSTALLED: 'License has been installed.',
    LICENSE_REPORTS_SETTINGS_NOTE: 'To specify multiple report recipients use a semicolon ";" separator.',
    LICENSE_REPORTS_SETTINGS_TITLE: 'Report Delivery Options',
    LICENSE_REPORTS_SETTINGS_HEADER: 'Enable automatic delivery and configure email settings for the usage report.',
    LICENSE_REPORTS_SETTINGS_CHECKBOX_LABEL: 'Deliver usage report automatically',
    LICENSE_AUTO_UPDATE: 'License Auto Update',
    LICENSEE_COMPANY: 'Licensee Company',
    LICENSE_TYPE: 'License Type',
    LICENSE_TYPE_LOWER: 'License type',
    LICENSE_EDITION: 'License edition',
    LICENSE_EXCEEDED: 'License exceeded',
    LICENSE_EXPIRATION: 'License Expiration',
    LICENSE_EXPIRES: 'License expires',
    LICENSE_QUOTA_USAGE: 'License Quota Usage',
    LICENSE_STATUS: 'License status',
    LICENSE_STATE: 'License State',
    LICENSE_USAGE: 'License Usage',
    LICENSE_USAGE_REPORTS: 'License Usage Reports',
    LICENSE_UPDATE_STATUS: 'License Update Status',
    LICENSED: 'Licensed',
    LOGS: 'Logs',
    LOGS_UPLOAD_STATUS: 'Logs Upload Status',
    LICENSED_COUNT: 'Licensed Count',
    LIMITED: 'Limited',
    LIMIT_INCOMING_NETWORK_TRAFFIC_TO: 'Limit incoming network traffic to:',
    LEGACY_CONTACT_PERSON: 'Legacy Contact Person',
    LOADING: 'Loading...',
    LOCATION: 'Location',
    LOCATIONS: 'Locations',
    LOCALHOST: 'localhost',
    LOCAL_BACKUP_CACHE_ALLOWS_BACKUPS: 'Local backup cache allows backups to continue on schedule even if remote ' +
        'backup target is temporarily unavailable.',
    LOCAL_ENCRYPTION_IS_NOT_SUPPORTED: 'Local encryption is not supported for Veeam backup repository',
    LOCAL_END_TIME: 'Local End Time',
    LOCAL_START_TIME: 'Local Start Time',
    LOCAL_FOLDER: 'Local folder',
    LAST_RUN: 'Last Run',
    LAST_RUN_TIME: 'Last Run Time',
    LOW_IMPACT: 'Low impact or a question',
    LONG_TERM_DESTINATION: 'Long-term Destination',
    LAST_BACKUP: 'Last Backup',
    LAST_HEARTBEAT: 'Last Heartbeat',
    LAST_REPLICA_SNAPSHOT: 'Last Replica Snapshot',
    LAST_REPLICATION: 'Last Replication',
    LAST_SNAPSHOT: 'Last Snapshot',
    LATEST_RESTORE_POINT: 'Latest Restore Point',
    LATEST_STATUS: 'Latest Status',
    LOG_IN_TO_THE_VEEAM: 'Log in to the Veeam Customer Support Portal with a case or license administrator account to continue.',
    LEASE_EXPIRATION_DATE: 'Lease Expiration Date',
    LOGIN_URL: 'Login URL',
    LOCAL: 'Local',
    ON_PREMISES: 'On-premises',
    LOCAL_BACKUP: 'Local Backup',
    LOCAL_STORAGE: 'Local storage',
    LOCAL_SYSTEM_ACCOUNT: 'Local System account',
    LOCATION_WITH_THE_SAME_NAME: 'Location with the same name already exists.',
    LOG_IN: 'Log in',
    LOGIN: 'Login',
    LOGIN_INFO: 'Login Info',
    LOGIN_WINDOW: 'Login Window',
    LOGINID_TO_CONNECT_TO_THE_MICROSOFT_SQL: 'LoginID to connect to the SQL Server in the SQL Server authentication mode',
    LOG_IN_WITH_SINGLE_SIGN_ON: 'Log in with Single Sign-On',
    LOG_IN_WITH_SSO: 'Log in with SSO',
    LOG_OUT: 'Log Out',
    LOG_OFF: 'Log off',
    LOGIN_ALIAS: 'Login alias',
    LIMIT_BANDWIDTH_CONSUMPTION_TO: 'Limit bandwidth consumption to:',
    LIMIT_NETWORK_TRAFFIC_DESCRIPTION: 'Defines maximum incoming network traffic bandwidth that will be accepted from the company. '
        + 'Traffic from companies with more bandwidth will be throttled to the specified amount.',
    LINK: 'Link',
    LINUX: 'Linux',
    LINUX_OS: 'Linux OS',
    LINUX_ACCOUNT: 'Linux Account',
    LINUX_CREDENTIALS: 'Linux Credentials',
    LOCATION_ADMINISTRATOR: 'Location Administrator',
    LOCATION_ADMINS: 'Location Admins',
    LOCATION_USER: 'Location User',
    LOCK: 'Lock',
    LVM_VOLUMES: 'LVM volumes',
    LAST_30_DAYS: 'Last 30 days',
    LOCAL_USERS: 'Local Users',
    LOCATION_ADMINISTRATOR_HAS_ACCESS_TO_ALL_DATA: 'Location Administrator has access to all data for the specified company ' +
        'locations and can perform all types of available management operations. Location Administrator cannot create new locations.',
    LOCATION_USER_HAS_ACCESS_TO_THE_COMPANY: 'Location User has access to the company monitoring data but cannot perform ' +
        'most configuration tasks. Data available to a Location User can be limited to one or more company locations.',
    LAST_RESTORE: 'Last Restore',
    LOCATIONS_CLAIM: 'Locations claim',
    LICENSE: 'License',
    LICENSING: 'Licensing',
    PATH: 'Path',
    LOCATION_HAS_BEEN_SUCCESSFULLY_SET: 'Location "{0}" has been successfully set to the selected {1}.',
    LIMIT_BANDWIDTH: 'Limit bandwidth',
    LAST_CLOUD_CONNECT_ACTIVITY_DATE: 'Last cloud connect activity date',
    LOCAL_NETWORK_INTERNAL_USAGE: ' (local network, internal usage)',
    LAST_ACTIVITY: 'Last Activity',
    LOCATION_USERS: 'Location users',
    LICENSES: 'Licenses',

    MAC_ADDRESS: 'MAC Address',
    MAC: 'Mac',
    MAC_OS: 'macOS',
    MACHINES_TRUST_OPTIONS: 'Machines trust options',
    MAINTENANCE: 'Maintenance',
    MAINTENANCE_MODE: 'Maintenance Mode',
    MAINTENANCE_IS_NOT_SUPPORTED_FOR: 'Maintenance is not supported for {0}',
    MAKE_THIS_KEY_READ_ONLY: 'Make this key read-only',
    MANAGE_MASTER_AGENT: 'Manage Master Agent',
    MANAGE_LICENSE_KEYS: 'Manage',
    MINOR_IMPACT: 'Minor impact, the majority of functions are usable',
    MAJOR_IMPACT: 'Major impact on production operations',
    MANAGE_LICENSE_KEYS_LICENSE_KEYS: 'license keys',
    MANAGE_LICENSE_KEYS_AND_AUTOMATE: 'and automate usage reporting',
    MANAGED_BACKUP_AGENTS: 'Managed Backup Agents',
    MANAGED_COMPANIES: 'Managed Companies',
    MANAGED_COMPUTER: 'Managed Computer',
    MANAGED_COMPUTERS: 'Managed Computers',
    MANAGED_SERVICES: 'Managed Services',
    MANAGED_SERVERS: 'Managed Servers',
    MANAGED_SERVERS_BACKUP_SERVER: 'Managed Servers (Backup Server)',
    MANAGED_VM: 'Managed VM',
    MANAGED_VM_FREE_OF_CHARGE: 'Managed VM free of charge',
    MANAGED_VMS: 'Managed VMs',
    MANAGEMENT: 'Management',
    MAPPING_RULES: 'Mapping Rules',
    MALWARE_STATE: 'Malware state',
    MAY: 'May',
    MARCH: 'March',
    MINOR_ISSUE: 'Minor issue or question that does not affect the product function, and can be readily circumvented.',
    MARK_AS_PAID: 'Mark as Paid',
    MARK_AS_UNPAID: 'Mark as Unpaid',
    MASTER_AGENT: 'Master Agent',
    MONITORED_BACKUP_AGENTS: 'Monitored Backup Agents',
    MONGO_DB: 'MongoDB',
    MAX_CONCURRENT_TASK_DESCRIPTION: 'Each task slot allows processing of a single disk, ' +
        'so companies with one slot assigned will not be able to leverage parallel processing, ' +
        'or run multiple jobs concurrently. This setting applies to direct mode transfers only ' +
        '(WAN accelerators process disks sequentially).',
    MAX_CONCURRENT_TASKS: 'Max concurrent tasks',
    MAX_DURATION: 'Max Duration',
    MAX_TENANTS_PER_PLAN: 'Max tenants per plan',
    MAX_DAYS: 'Max. days',
    MAX_DELAY: 'Max delay',
    MAX_NUMBER_OF_TENANTS: 'Max Number of Tenants',
    MAX_VALUE_IS: 'Max value is {0}',
    MAX_NUMBER_OF_ROWS: 'Max Number of Rows',
    MAXIMUM: 'Maximum',
    MAXIMUM_DATA: 'Maximum Data',
    MAXIMUM_SIZE: 'Maximum size',
    MAXIMUM_VCPU_PER_CORE_RATIO: 'Maximum vCPU per core ratio',
    MANAGED_WORKLOADS: 'Managed Workloads',
    MANAGED_WORKSTATIONS: 'Managed Workstations',
    MANAGED_WORKSTATIONS_BACKUP_SERVER: 'Managed Workstations (Backup Server)',
    MANAGED_AGENTS_WORKSTATIONS: 'Managed Agents (Workstations)',
    MANAGED_AGENTS_SERVERS: 'Managed Agents (Servers)',
    MANAGED_AGENTS: 'Managed Agents',
    MANAGED_SERVICES_ADVANCED_SETTINGS: 'Managed Services Advanced Settings',
    MANAGED_SERVICE_ACCOUNT: 'Managed service account',
    MANAGE: 'Manage',
    MANAGEMENT_AGENT: 'Management Agent',
    MANAGEMENT_MODE: 'Management mode',
    MANAGEMENT_TYPE: 'Management type',
    MANUAL: 'Manual',
    MANUALLY_CREATED_BACKUP_POLICY: 'Manually created backup policy',
    MEDIA_POOL: 'Media Pool',
    MEMORY: 'Memory',
    MEMORY_USAGE: 'Memory Usage',
    MODIFIED_BY: 'Modified by',
    MODIFIED_DATE: 'Modified Date',
    MODIFY_CONFIGURED_VEEAM_BACKUP_AND_REPLICATION: 'Modify configured Veeam Backup & Replication {0}',
    MOUNT_POINTS: 'Mount points',
    MORE_THAN: 'More than',
    MANAGED_BY: 'Managed by',
    MANAGED_BY_CONSOLE: 'Managed by Console',
    MANAGED_BY_BACKUP_SERVER: 'Managed by Backup Server',
    MANAGED_BY_BACKUP_SERVER_JOB: 'Managed by backup server (job)',
    MANAGED_BY_AGENT: 'Managed by agent (policy)',
    MANAGE_CERTIFICATE: 'Manage Certificate',
    MIN_VALUE_IS: 'Min value is {0}',
    MICROSOFT_ONEDRIVE_FOLDER: 'Microsoft OneDrive folder',
    MINUTES: 'Minutes',
    MINUTE: 'minute',
    MICROSOFT_365: 'Microsoft 365',
    MICROSOFT_365_BACKUP: 'Microsoft 365 Backup',
    MICROSOFT_ACTIVE_DIRECTORY_DISCOVERY: 'Microsoft Active Directory discovery',
    MICROSOFT_ACTIVE_DIRECTORY: 'Microsoft Active Directory',
    MICROSOFT_EXCHANGE_SERVER: 'Microsoft Exchange Server',
    MICROSOFT_ONEDRIVE: 'Microsoft OneDrive',
    MICROSOFT_SQL_TRANSACTION_LOGS: 'Microsoft SQL transaction logs',
    MICROSOFT_SQL_SERVER: 'Microsoft SQL Server',
    MICROSOFT_SHAREPOINT: 'Microsoft SharePoint',
    MIGRATE: 'Migrate',
    MIGRATE_CLOUD_CONNECT_SERVER: 'Migrate Cloud Connect Server',
    MFA: 'MFA',
    MFA_ACCESS_CONFIGURATION: 'MFA Access Configuration',
    MFA_NOT_CONFIGURED: 'Multi-factor authentication is not configured. Generate the QR code and set up an ' +
        'authentication app to complete the operation.',
    MFA_STATUS: 'MFA status',
    MY: 'My',
    ME: 'Me',
    MICROSOFT_AZURE: 'Microsoft Azure',
    MICROSOFT_365_GROUP: 'Microsoft 365 Group',
    MICROSOFT_365_10_USERS: 'Microsoft 365 (10 users pack)',
    MY_SQL: 'MySQL',
    MYSQL_DATABASE: 'MySQL Database',
    MODE: 'Mode',
    MONDAY: 'Monday',
    MONITORING: 'Monitoring',
    MONITORING_PLATFORM: 'Monitoring platform',
    MONITORING_SERVICES: 'Monitoring services',
    MONTHS: 'Months',
    MONTH: 'month',
    MANAGED_BACKUP: 'Managed Backup',
    MONTHLY: 'Monthly',
    MONTHLY_ON: 'Monthly on:',
    MONTHLY_AT_THIS_TIME: 'Monthly at this time:',
    MONTHLY_AT: 'Monthly at',
    MONTHLY_ON_THE: 'Monthly on the {0} of {1} at {2}',
    MONTHLY_REVENUE_STATE: 'Monthly Revenue State',
    MONTHLY_USAGE_REPORT: 'Monthly Usage Report',
    MONTHLY_USAGE_REPORT_HAS_BEEN_GENERATED: 'Monthly usage report has been generated.',
    MULTI_FACTOR_AUTHENTICATION: 'Multi-Factor Authentication',
    MUSIC: 'Music',
    MUST_BE_A_NUMERIC_IP_ADDRESS: 'Must be a numeric IP address',
    MULTIPLE: 'Multiple',
    MANAGEMENT_AGENT_WILL_NOW_BE_DOWNLOADED: 'Management agent will now be downloaded to your computer.',
    MANAGEMENT_AGENT_DOWNLOAD: 'Management Agent Download',
    MICROSOFT: 'Microsoft',
    MICROSOFT_365_OBJECTS: 'Microsoft 365 Objects',
    MICROSOFT_365_BACKUP_JOBS: 'Microsoft 365 backup jobs',
    MICROSOFT_365_OBJECT_BACKUP_SLA: 'Microsoft 365 object backup (SLA)',
    MR: 'Mr.',
    MISS: 'Miss',
    MRS: 'Mrs.',
    MS: 'Ms.',
    MY_COMPANY: 'My Company',
    MANAGEMENT_AGENTS: 'Management Agents',
    MAPPING_RULE_STATE: 'Mapping Rule State',
    MANAGED_USERS: 'Managed Users',
    MAPPING_CONDITIONS: 'Mapping Conditions',
    MB: 'MB',
    MBPS: 'Mbps',
    MBS: 'MB/s',
    MINIMUM_ACCEPTED_INCOMING_SIGNING_ALGORITHM: 'Minimum accepted incoming signing algorithm',
    MICROSOFT_365_MANAGED_BACKUP: 'Microsoft 365 managed backup',
    MICROSOFT_365_SERVERS: 'Veeam Backup for Microsoft 365 Servers',
    MICROSOFT_365_ONLY: 'Microsoft 365 only',
    MANAGED_COMPANIES_QUOTA: 'Managed companies quota',
    MAIL: 'Mail',
    MEMBERS: 'Members',
    MICROSOFT_365_BACKUP_SERVERS: 'Microsoft 365 Backup Servers',
    MANUAL_UPGRADE_REQUIRED: 'Manual upgrade required',
    MAX_CONCURRENT_TASKS_NUMBER: 'Max concurrent tasks number per managed company will be limited to {0}.',
    MODERN: 'Modern',
    MAIL_SERVICE: 'Mail service',
    MANAGEMENT_AGENT_STATUS: 'Management agent status',
    MANAGEMENT_AGENT_VERSION: 'Management agent version',
    MANAGED_COMPANIES_QUOTA_WILL: 'Managed companies quota will be limited to {0}.',
    MAX_CONCURRENT_TASKS_NUMBER_CANNOT: 'Max concurrent tasks number cannot be less than {0}. '
        + 'To decrease the value, modify managed companies settings.',
    MONITORED_WORKLOADS: 'Monitored workloads',
    MICROSOFT_365_MANAGED_BACKUP_IS_SWITCHED_ON_BUT_NOT_CONFIGURED: 'Microsoft 365 managed backup is switched on but not configured.',
    MANAGE_LOCATIONS: 'Manage locations',
    MHz: 'MHz',
    MY_PLANS: 'My Plans',
    MONITORED_OBJECT: 'Monitored object',
    MICROSOFT_WINDOWS_ACCOUNT: 'Microsoft Windows Account',
    MICROSOFT_WINDOWS: 'Microsoft Windows',

    N_A: 'N/A',
    NATIVE: 'Native',
    NOTIFICATION_OPTIONS: 'Notification options',
    NOTES: 'Notes',
    NOT_ASSIGNED: 'Not assigned',
    NOT_EQUAL: 'Not equal',
    N_OF_VIRTUAL_DATA_CENTERS: 'N. of Virtual Data Centers',
    NO_INFO: 'No info',
    NO_BACKUP_REPOSITORIES_AVAILABLE: 'No backup repositories available',
    NO_BILLING: 'No billing',
    NO_EMAIL_NOTIFICATIONS: 'No email notifications',
    NOT_SET: 'Not set',
    NOT_SET_CLIENT_TYPE_ONLY: 'Not set (remote connection via Cloud Connect)',
    NO_COPIES: 'No Copies',
    NO_DATA_TO_DISPLAY: 'No data to display',
    N_OF_FILES: 'N. of Files',
    NO_NOTIFICATIONS: 'No notifications',
    NO_ORGANIZATIONAL_UNITS_HAVE_BEEN: 'No organizational units have been discovered. Please make sure that master ' +
        'agent has access to the Microsoft Active Directory domain and try again.',
    NO_DATA_AVAILABLE: 'No data available',
    NO_HARDWARE_PLANS_FOUND: 'No hardware plans found',
    NO_POLICY: 'No policy',
    NO_POLICY_REQUIRES_FULL_ACCESS: 'No Policy (requires full access UI mode)',
    NOVEMBER: 'November',
    NOTE_THAT_THE_INFORMATION: 'Note that the information provided to Veeam for purposes of customer ' +
        'support may be considered protected under certain data protection regulations and/or internal company policies.' +
        ' A list of potentially sensitive data types is available in the following',
    N_OF_JOBS: 'N. of Jobs',
    N_OF_JOB_TYPES: 'N. of job types',
    N_OF_COUNT_TYPES: '{0} of {1} job types',
    N_OF_COUNT_STATUSES: '{0} of {1} job statuses',
    N_OF_COUNT_PLATFORMS: '{0} of {1} job platforms',
    N_OF_OBJECT_TYPES: '{0} of {1} object types',
    N_OF_REPEATS: 'N. of Repeats',
    NO_ACTIVE_ALARMS: 'No active alarms',
    N_OF_GATEWAY_SERVERS: 'N. of Gateway Servers',
    N_OF_VMS: 'N. of VMs',
    NFS: 'NFS',
    NAME: 'Name',
    NO: 'No',
    NONE: 'None',
    NONE_NOT_SECURE: 'None (not secure)',
    NO_DOWNLOADS_HAVE_BEEN_MADE: 'No downloads have been made',
    NO_FILTERS_APPLIED: 'No filters applied',
    NO_LICENSES_HAVE_BEEN_USED: 'No licenses have been used',
    NO_MANAGED_BACKUP_SERVERS: 'No managed backup servers',
    NO_MANAGED_SERVERS: 'No managed servers',
    NO_LICENSE_USAGE_REPORTS: 'No license usage reports generated yet',
    NO_ORGANIZATIONS_FOUND: 'No organizations found',
    NO_JOBS_HAVE_BEEN_RUN: 'No jobs have been run for this time period',
    NO_RECORDS: 'No records',
    NO_SERVERS_IN_THE_REPOSITORY: 'No Servers in the repository',
    NO_VMS_IN_THE_REPOSITORY: 'No VMs in the repository',
    NO_MANAGED_WORKLOADS: 'No managed workloads',
    NO_WORKSTATIONS_IN_THE_REPOSITORY: 'No Workstations in the repository',
    NON_ROOT_ACCOUNT: 'Non-root account',
    NOT_DEFINED: 'Not Defined',
    NOT_ENFORCED: 'Not enforced',
    NOT_CONFIGURED: 'Not configured',
    NOT_INSTALLED: 'Not installed',
    NOT_ACTIVATED: 'Not Activated',
    NOT_MANAGED: 'Not Managed',
    NOT_ACTIVATED_UNMANAGED: 'Not activated (Unmanaged)',
    NOT_AVAILABLE: 'Not available',
    NOT_APPLICABLE: 'Not applicable',
    NOT_SELECTED: 'Not selected',
    NOT_SCHEDULED: 'Not scheduled',
    NO_AGENTS_INSTALLED: 'No agents installed',
    NO_ASSIGNED_LICENSES_TO: 'No assigned licenses to this company',
    NOTIFICATIONS: 'Notifications',
    NOTIFICATION_POLICY_AND_EMAIL_SERVER: 'Notification policy and email server settings are not configured.\n' +
        'Navigate to the configuration tab to set email server settings and ' +
        'fill in your company contact information to complete the operation.',
    NOTE_IF_MASTER_MANAGEMENT_AGENT_CREDENTIALS: 'Note: If master management agent credentials are not set or ' +
        'invalid, discovery rule will use the credentials specified above.',
    NETWORK_DISCOVERY: 'Network discovery',
    NETWORK_SETTINGS: 'Network Settings',
    NETWORK: 'Network',
    NETWORKS: 'Networks',
    NETWORK_BASED_DISCOVERY: 'Network-based discovery',
    NETWORK_EXTENSION: 'Network extension',
    NETWORK_EXTENSION_APPLIANCE: 'Network extension appliance',
    NETWORK_EXTENSION_APPLIANCES: 'Network Extension Appliances',
    NEVER: 'Never',
    NEW: 'New',
    NEW_COUNT: 'New Count',
    NEW_LINK: 'New Link',
    NEW_BACKUP_JOB: 'New Backup Job',
    NEW_BACKUP_COPY_JOB: 'New Backup Copy Job',
    NETWORK_IP_RANGE: 'Network IP Range',
    NETWORK_IP_RANGE_TO_SCAN: 'Network IP range to scan',
    NETWORK_WITH_THE_SAME_NAME: 'Network with the same name already exists',
    NETWORK_WITH_THE_SAME_RANGE: 'Network with the same IP range already exists.',
    NEW_PASSWORD: 'New password',
    NEXT_INVOICE: 'Next Invoice',
    NEXT_JOB_RUN: 'Next Job Run',
    NEW_SUPPORT_CASE: 'New Support Case',
    NEXT_RUN: 'Next Run',
    NO_UPDATES: 'No updates',
    NO_SCHEDULE: 'No schedule (run manually)',
    NUMBER_OF_JOBS: 'Number of jobs',
    NAVIGATE_TO_ALL_REPORT: 'Navigate to {0} tab to see generated reports',
    NAVIGATE_TO_ALL_INVOICES: 'Navigate to {0} tab to see generated reports.',
    STORAGE_SNAPSHOT: 'Storage snapshot',
    NEW_AUTHORIZATION_RULE: 'New Authorization Rule',
    NEW_WINDOWS_USER: 'New Windows User',
    NEW_USER: 'New User',
    NEW_IDENTITY_PROVIDER: 'New Identity Provider',
    NEW_INTERNAL_USER: 'New Internal User',
    NEW_LOCAL_USER: 'New Local User',
    NOT_RUNNING: 'Not Running',
    NO_GATEWAY_POOLS_SELECTED: 'No gateway pools selected',
    NO_BACKUP_JOBS_AND_RESTORE_OPERATIONS: 'No backup jobs and restore operations are running on the selected backup server',
    NOT_SET_YET: 'Not set yet',
    N_OF_WORKSTATION_AGENTS_BEFORE_REACHING: 'N. of workstation agents before reaching the quota',
    N_OF_MANAGED_COMPANIES_BEFORE_REACHING: 'N. of managed companies before reaching the quota',
    N_OF_MANAGED_AGENTS_BEFORE_REACHING: 'N. of managed agents before reaching the quota',
    N_OF_TENANTS_BEFORE_REACHING: 'N. of tenants before reaching the quota',
    N_OF_VM_BACKUPS_BEFORE_REACHING: 'N. of VM backups before reaching the quota',
    N_OF_SERVER_BACKUPS_BEFORE_REACHING: 'N. of server backups before reaching the quota',
    N_OF_WORKSTATION_BACKUPS_BEFORE_REACHING: 'N. of workstation backups before reaching the quota',
    NOTE_THAT_ENABLING_THE_OPTION_TO_ROUND_UP_STORAGE: 'Note that enabling the option to round up storage usage ' +
        'automatically disables free of charge storage resources.',

    OBJECTS: 'Objects',
    OBJECT_TYPES: 'Object types',
    OBJECT_TYPE: 'Object type',
    OBJECT_STORAGE: 'Object Storage',
    OBJECT_TO_PROTECT: 'Object to protect',
    OBJECTS_TO_PROTECT: 'Objects to protect',
    OBJECT_TO_INCLUDE: 'Object to include',
    OBJECTS_TO_INCLUDE: 'Objects to include',
    OBJECT_TO_EXCLUDE: 'Object to exclude',
    OBJECTS_TO_EXCLUDE: 'Objects to exclude',
    OBJECT_INCLUDED: 'Object included',
    OBJECTS_INCLUDED: 'Objects included',
    OBJECT_EXCLUDED: 'Objects excluded',
    OBJECTS_EXCLUDED: 'Objects excluded',
    OBJECT_STORAGE_BACKUP_JOB: 'Object storage backup job',
    OBJECT_STORAGE_DATA_BACKUP: 'Object storage data backup',
    OBJECT_STORAGE_DATA_BACKUP_COPY: 'Object storage data backup copy',
    OBTAIN_AN_IP_ADDRESS_AUTOMATICALLY: 'Obtain an IP address automatically',
    OCTOBER: 'October',
    OF_TOTAL_CAPACITY: 'of total capacity',
    OBJECT_EXCLUSIONS_RULE: 'Object exclusions rule',
    OK: 'OK',
    ON: 'On',
    OR: 'Or',
    ON_WEEK_DAYS: 'On week-days',
    ON_WEEK_DAYS_AT: 'On week-days at: {0}',
    ON_THESE_DAYS: 'On these days',
    ONLINE: 'Online',
    ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED: 'Only one email address is supported',
    OF: 'of',
    OFF: 'Off',
    OFFSITE: 'Offsite',
    ONE_COPY: 'One Copy',
    ONE_SERVERS: 'ONE Servers',
    ORACLE: 'Oracle',
    ORACLE_DATABASE: 'Oracle Database',
    ORACLE_TRANSACTION_LOGS: 'Oracle transaction logs',
    ORACLE_LOG_BACKUP_JOB: 'Oracle Log Backup Job',
    ORACLE_DATABASE_LOG_BACKUP_JOB: 'Oracle database log backup job',
    ORGANIZATION: 'Organization',
    OS_TYPE: 'OS type',
    OS_VERSION: 'OS version',
    OVERVIEW: 'Overview',
    ORACLE_ACCOUNT: 'Oracle account',
    ORGANIZATIONAL_UNITS: 'Organizational Units',
    OPEN_SUSE: 'openSUSE',
    OPEN_APPLIANCE_UI: 'Open Appliance UI',
    OPERATION_MODE: 'Operation Mode',
    OPTIONS: 'Options',
    OPERATORS: 'Operators',
    OBJECT: 'Object',
    OBJECTS_TO_BACKUP: 'Objects to backup.',
    OBJECTS_TO_BACK_UP: 'Objects to back up',
    OPERATING_SYSTEM: 'Operating system',
    OPTIONAL: 'Optional',
    ONCE_A: 'Once a',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED: 'Once all products are installed and switched to the managed mode, create',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED_BACKUP_REPORTS: 'backup reports',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED_ENABLE_THE: ', enable the',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED_REQUIRED_ALARMS: 'required alarms',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED_AND: 'and',
    ONCE_ALL_PRODUCTS_ARE_INSTALLED_INSTALL_MONITORING: 'install monitoring servers to your or client\'s infrastructures',
    ONCE_BACKUP_IS_TAKEN_COMPUTER_SHOULD: 'Once backup is taken, computer should',
    ONE_OR_MORE_ALARM_ACTIONS_CANNOT: 'One or more alarm actions cannot be matched to the severity rules defined in the alarm settings.',
    ORACLE_LINUX: 'Oracle Linux',
    OUT_OF_DATE: 'Out of date',
    OUT_OF_DATE_HYPHENATE: 'Out-of-date',
    OTHER_ACTIONS: 'Other Actions',
    OTHER_APPLICATIONS: 'Other applications',
    OU_DOMAIN_CONTROLLERS: 'OU=Domain Controllers, DC=Contoso, DC=local',
    OUT_OF_SYNC: 'Out-of-sync',
    OUT_OF: '{0} out of {1} ({2}%)',
    ONE_OR_MORE_ADDRESSES_IS: 'One or more addresses is in wrong format',
    ON_THIS_DAYS_AT: 'On {0} at {1}',
    OTHER_FILES_AND_FOLDERS: 'Other files and folders',
    ONE_JOB_PER_LOCAL_TARGET: 'One job per local target and any number of jobs to a cloud repository.',
    OUTBOUND_SIGNING_ALGORITHM: 'Outbound signing algorithm',
    OKTA: 'Okta',
    ONE_GATEWAY_POOL_SELECTED: '1 gateway pool selected',
    OBTAIN_AUTOMATICALLY: 'Obtain automatically',
    OBTAIN_FROM_BACKUP_SERVER: 'Obtain from backup server',
    OTHER: 'Other',
    ONE_DRIVE: 'OneDrive',
    ONEDRIVE_FOLDER: 'OneDrive Folder',
    OBJECTS_TO_EXCLUDE_ARE_NOT_SELECTED: 'Objects to exclude are not selected. Select at least one object to exclude or disable exclusions.',
    OBJECT_STORAGE_REPOSITORY: 'Object storage repository',
    OPTIMAL_COMPRESSION_PROVIDES: 'Optimal compression provides for best compression to performance ratio, and lowest CPU usage.',
    OFFICE: 'Office',
    OPTION_TO_PROTECT: 'Option to protect deleted backup files is not available for object storage repositories.',
    OVER_QUOTA: 'Over Quota',
    OVERDUE: 'Overdue',
    OVERPAYMENT: 'Overpayment',
    OPEN_API_SPECIFICATION: 'OpenAPI specification',
    OBJECT_STORAGE_SOURCE_SIZE: 'Object storage source size',

    LOGIN_PAGE_GRAPHICS: 'Login page graphics',
    LOGIN_PAGE_LOGO: 'Login page logo',

    PORTAL_CONFIGURATION: 'Portal Configuration',
    PORTAL_NAME: 'Portal name',
    PB: 'PB',
    PRODUCT_UPDATES: 'Product Updates',
    PRODUCT_UPDATES_AND_DOWNLOAD: 'Product Updates & Download',
    PROGRESS: 'Progress',
    PAID: 'Paid',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    PASSWORD_FIELD_CANNOT_BE_EMPTY: 'Password field cannot be empty',
    PASSWORD_HINT_MUST_NOT_CONTAIN: 'Password hint must not contain your encryption password.',
    PASSWORD_FILE: 'Password file',
    PASSWORD_FOR_THE_ACCOUNT_UNDER: 'password for the account under which the Veeam Backup Service will run',
    PASSWORD_TO_CONNECT_TO_THE_MICROSOFT_SQL: 'password to connect to the SQL Server in the SQL Server authentication mode',
    PAST_WEEK: 'Past Week',
    PACKAGE: 'Package',
    PROCESS_NAME: 'Process name',
    PROCESSED_VMS: 'Processed VMs',
    PROVIDE_A_DETAILED: 'Provide a detailed issue description and error messages',
    PLEASE_A_BRIEF: 'Provide a brief summary of the issue',
    PROCESSED_OBJECTS: 'Processed Objects',
    PROCESSED_OBJECTS_LAST_12_HOURS: 'Processed Objects, Last 12 Hours',
    PROCESSED_OBJECTS_LAST_12_HOURS_OR_SESSION: 'Processed Objects (Last 12 Hours or Session)',
    PAID_DATE: 'Paid Date',
    PATH_CANNOT_BE_EMPTY: 'Path cannot be empty',
    PATH_TO_A_BLOCK_DEVICE: 'Path to a block device',
    PATH_TO_A_MOUNT_POINT: 'Path to a mount point',
    PATH_TO_LOGICAL_VOLUME: 'Path to a logical volume or a volume group',
    PATH_TO_THE_CATALOG_FOLDER: 'path to the catalog folder where index files will be stored.',
    PATH_TO_THE_DIRECTORY_WHERE: 'path to the directory where Veeam Backup & Replication will be installed',
    PATH_TO_THE_FOLDER_WHERE: 'path to the folder where the instant recovery cache will be stored',
    PATH_TO_THE_LICENSE_FILE: 'path to the license file',
    PATCH: 'Patch',
    PATCHES: 'Patches',
    PATCH_AVAILABLE: 'Patch available',
    PATCH_BACKUP_SERVER: 'Patch Backup Server',
    PATCH_INFO: '{0} (size: {1}, date: {2})',
    PATCH_LEVEL: 'Patch Level',
    PATCHING_METHOD: 'Patching method',
    PERIODIC: 'Periodic',
    PERIODICALLY: 'Periodically',
    PERIODICALLY_EVERY: 'Periodically every',
    PER: 'per',
    PER_VM: 'per VM',
    PER_AGENT_PER_MONTH: 'per agent per month',
    PER_ALLOCATED: 'per allocated',
    PER_CONSUMED: 'per consumed',
    PER_MONTH: 'per month',
    PERFORM_COPY_ONLY_LETS: 'Perform copy only (lets another application use logs)',
    PERFORM_COPY_ONLY: 'Perform copy only',
    PERFORMING_AN_ACTIVE_FULL_BACKUP_STARTS: 'Performing an active full backup starts a new, independent backup chain. ' +
        'The entire previous backup chain will  only be removed after the last incremental restore point ' +
        'falls out of the retention scope, which results in additional disk space requirements.',
    PHYSICAL: 'Physical',
    PHONE: 'Phone',
    PLEASE_SELECT_A_USER_TO_PROCEED: 'Please select a user to proceed.',
    PLEASE_SELECT_PLAN_FOR_COMPANY: 'Please select a subscription plan for this company.',
    PLEASE_SELECT_PLAN_FOR_COMPANIES: 'Please select a subscription plan for these companies.',
    PLEASE_SELECT_AT_LEAST_ONE: 'Please select at least one company for this report template.',
    PLEASE_SELECT_FILE_IN_CSV: 'Please select file in CSV format to use in the discovery rule.',
    PLEASE_ENTER_OBJECT_PATH: 'Please enter object path in the following format',
    PRIORITY: 'Priority:',
    PRODUCT: 'Product',
    PROVIDE_FILE_WITH_INSTALLATION_PARAMETERS: 'Provide file with installation parameters',
    PROTECTED_AWS_WORKLOADS: 'Protected AWS Workloads',
    PROTECTED_AZURE_WORKLOADS: 'Protected Azure Workloads',
    PROTECTED_GCP_WORKLOADS: 'Protected Google Cloud Workloads',
    PROTECTED_FILES: 'Protected Files',
    PROTECTED_MICROSOFT_365_OBJECTS: 'Protected Microsoft 365 Objects',
    PROGRESS_SHOWN: '{0} of {1} shown',
    PROCESSING: 'Processing',
    PROCESSING_RATE: 'Processing Rate',
    PROCESSING_OPTIONS: 'Processing Options',
    PROTECTION_TYPE: 'Protection type',
    PROTECTED_DATA: 'Protected Data',
    PROTECTED_COMPUTERS_BY_BACKUP_TARGET: 'Protected Computers by Backup Target',
    PROTECTED_COMPUTERS_BY_JOB_STATUS: 'Protected Computers by Job Status',
    PROTECTED_SERVERS: 'Protected Servers',
    PROTECTED_WORKSTATIONS: 'Protected Workstations',
    PROTECTED_USER: 'Protected user',
    PROTECTED_USERS: 'Protected users',
    PROTECTED_USERS_FREE_OF_CHARGE: 'Protected users free of charge',
    PROTECTED_OBJECTS: 'Protected objects',
    PROFILE: 'Profile',
    PUBLIC: 'Public',
    PUBLIC_KEY: 'Public Key',
    PUBLIC_KEY_S: 'Public key',
    PRIVATE: 'Private',
    PRIVATE_KEY: 'Private Key',
    PRIVATE_KEY_S: 'Private key',
    PRIVATE_KEY_IS_REQUIRED_FOR_THIS_CONNECTION: 'Private key is required for this connection',
    PROTECTED: 'Protected',
    PROTECTED_VMS: 'Protected VMs',
    PROTECTED_VMS_RPO: 'Protected VMs (RPO)',
    PROTECTED_VMS_RPO_SLA: 'Protected VMs (RPO & SLA)',
    POINT: 'Point',
    POINTS: 'Points',
    POLICY_NAME: 'Policy name',
    POLICY_SCHEDULING: 'Policy scheduling',
    POLICY_STATE: 'Policy State',
    PORTAL_READONLY_OPERATOR: 'Read-only User',
    PORTAL_ADMINISTRATOR: 'Portal Administrator',
    PORTAL_OPERATOR: 'Portal Operator',
    PORTAL_USERS: 'Portal Users',
    POLICY_TYPE: 'Policy Type',
    POLICY_OR_JOB: 'Policy or Job',
    POLICY_TYPE_LOWER: 'Policy type',
    PROTECTED_COMPUTERS: 'Protected computers',
    PROTECTED_COMPUTERS_RPO: 'Protected computers (RPO)',
    PARAMETERS: 'Parameters',
    PARAMETER: 'Parameter',
    POLICY: 'Policy',
    POSTGRE_SQL: 'PostgreSQL',
    PASSWORD: 'Password',
    PASSPHRASE: 'Passphrase',
    PROTECTED_DATA_BACKUP: 'Protected data backup',
    PROTECTED_DATA_BACKUP_RPO: 'Protected data backup (RPO)',
    PASSWORD_IS_REQUIRED_MSG: 'Password is required only if this certificate was exported with the password protection enabled.',
    PERPETUAL: 'Perpetual',
    PENDING_REMOVAL: 'Pending removal',
    PERMITTED: 'Permitted',
    PERMIT_ALL: 'Permit All',
    PERFORM_BACKUP_FILES_HEALTH: 'Perform backup files health check',
    PERFORM_BACKUP_FILES_FULL: 'Perform backup files full health check',
    PREDEFINED: 'Predefined',
    PREPARE_YOUR_PERSONAL_SETTINGS: 'Prepare your personal settings',
    PREPARE_YOUR_PORTAL: 'Prepare your portal for public use',
    PROPAGATE_CHANGES: 'Propagate Changes',
    PRIMARY_BACKUP: 'Primary Backup',
    PROTECTED_SHARES: 'Protected Shares',
    PROCEED_WITH_THIS_CONFIGURATION_ANYWAY: 'Proceed with this configuration anyway?',
    PLATFORM: 'Platform',
    PLATFORM_TYPE: 'Platform type',
    PLAN: 'Plan',
    PLAN_INFO: 'Plan info',
    PLEASE_CONFIRM_THAT_YOU_HAVE_INSTALLED: 'Please confirm that you have installed the app and configured the MFA access. ' +
        'Otherwise, you will not be able to access the backup portal.',
    PLEASE_CHOOSE_A_SITE_TO_LOG_IN: 'Please choose a site to log in.',
    PROVIDE_USER_CREDENTIALS_TO: 'Provide user credentials to log in to the backup server {0}',
    PLEASE_RESTART_BACKUP_AGENT_SERVICES: 'Please restart backup agent services to apply these settings.',
    PLEASE_START_USING_VCC_BACKUP_JOBS: 'Please start using Veeam Cloud Connect backup jobs to view data',
    PLEASE_START_USING_VCC_REPLICATION_JOBS: 'Please start using Veeam Cloud Connect replication jobs to view data',
    PORTAL_ADMINS: 'Portal Admins',
    PORT_NUMBER_SHOULD_BE_MORE: 'Port number should be more than 0 and less than 65536',
    PORT: 'Port',
    PORT_USED_FOR_COMMUNICATION_BETWEEN_THE_MOUNT_SERVER: 'port used for communication between the mount server and the backup server',
    PORT_USED_FOR_COMMUNICATION_WITH_REST: 'port used for communication with REST API service',
    POINTS_QUOTA: 'Points quota',
    PLEASE_REVIEW_THIS_LINK: 'Please review {0} to review how to specify shared folder settings in the backup job.',
    PLEASE_REVIEW_AND_SUBMIT_THE_REPORT: 'Please review and submit the report',
    PRE_JOB_SCRIPT: 'Pre-job script',
    POST_JOB_SCRIPT: 'Post-job script',
    PORTAL_BRANDING: 'Portal Branding',
    PORTAL_BRANDING_SETTINGS: 'Portal branding settings',
    PRE_FREEZE_SCRIPT: 'Pre-freeze script',
    POST_THAW_SCRIPT: 'Post-thaw script',
    POSTPONE: 'Postpone',
    PREDEFINED_ALARMS: 'Predefined Alarms',
    PREDEFINED_ALARMS_CANNOT_BE_DELETED: 'Predefined alarms cannot be deleted.',
    PROTECTED_DATABASES: 'Protected Databases',
    PROTECTED_DATABASES_RPO: 'Protected databases (RPO)',
    PROCEED: 'Proceed',
    PROCESS_TRANSACTION_LOGS_WITH_THIS_JOB_RECOMMENDED: 'Process transaction logs with this job (recommended)',
    PROCESS_TRANSACTION_LOGS_WITH_THIS_JOB: 'Process transaction logs with this job',
    PROCESSING_DATA: 'Processing data',
    PROCESSING_SETTINGS: 'Processing Settings',
    PROTECT_DELETED_BACKUP_FILES_FOR: 'Protect deleted backup files for',
    PLEASE_ADD_AT_LEAST_ONE: 'Please add at least one rule to alarm configuration to continue.',
    PLEASE_ADD_AT_LEAST_ONE_SITE: 'Please add at least one site for this reseller.',
    PLEASE_ENTER_A_NEW_PASSWORD: 'Please enter a new password',
    PLEASE_ENTER_DESTINATION_FOLDER_NAME: 'Please enter destination folder name in the format specified below',
    PLEASE_LOG_IN: 'Please log in',
    YOUR_SESSION_HAS_EXPIRED: 'Your session has expired. Reconnect to the backup server to continue.',
    PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING: 'Please log in again to continue using the backup portal.',
    PLEASE_SELECT_AT_LEAST_ONE_LOCATION: 'Please select at least one location for this rule.',
    PLEASE_SELECT_AT_LEAST_ONE_LOCATION_REPORT: 'Please select at least one location.',
    PLEASE_SELECT_AT_LEAST_ONE_MANAGEMENT_AGENT: 'Please select at least one {0} management agent for this rule.',
    PLEASE_SELECT_AT_LEAST_ONE_COMPANY: 'Please select at least one company for this rule',
    PLEASE_SELECT_AT_LEAST_ONE_UNIT: 'Please select at least one unit for discovery.',
    PLEASE_SELECT_AT_LEAST_ONE_ORGANIZATION: 'Please select at least one organization unit for the discovery.',
    PLEASE_SELECT_AT_LEAST_ONE_COMPANY_FOR_USER: 'Please select at least one company for this user.',
    PLEASE_SPECIFY_USER_CREDENTIALS: 'Please specify user credentials to run this rule',
    PLEASE_USE_THE_FOLLOWING_FORMAT: 'Please use the following format "{0}" to set the date',
    PUBLIC_CLOUD: 'Public Cloud',
    PUBLIC_CLOUD_BACKUP: 'Public Cloud Backup',
    PUBLIC_CLOUD_BACKUP_ADVANCED_SETTINGS: 'Public Cloud Backup Advanced Settings',
    PUBLIC_CLOUDS: 'Public Clouds',
    PUBLIC_IP_ADDRESS: 'Public IP Address',
    PLEASE_ENTER_VOLUME_NAME: 'Please enter volume name in the format specified above',
    PERFORM_BACKUP_FILES_HEALTH_CHECK: 'Perform backup files health check (detects and auto-heals corruption)',
    PERSONAL_FILES: 'Personal files',
    PERSONAL_FILES_SELECTION: 'Personal Files Selection',
    PERSONAL_SITES: 'Personal Sites',
    PERSISTENT_GUEST_AGENT: 'Persistent guest agent',
    PERSONALIZE_YOUR_BACKUP_PORTAL: 'Personalize your backup portal',
    CONFIGURE_SECURITY_CERTIFICATES: 'Configure security certificates, register first Veeam Cloud Connect server '
        + 'and specify your personal settings',
    PERIOD: 'Period',
    PICTURES: 'Pictures',
    PICK_CERTIFICATE: 'Pick Certificate',
    PICK_THE_EXISTING_CERTIFICATE: 'Pick the existing certificate from local Certificate Store of this server.',
    PUBLIC_CLOUD_MANAGED_BACKUP: 'Public cloud managed backup',
    PUBLIC_IP_ADDRESS_RANGE: 'Public IP Address Range',
    PLEASE_WAIT: 'Please wait...',
    PURPOSE: 'Purpose',
    PREVIEW_WELCOME_EMAIL: 'Preview Welcome Email',
    PREVIOUS_MONTH: 'Previous month',
    PERFORMANCE_TIER: 'Performance tier',
    PROTOCOL: 'Protocol',
    PROVIDE_LICENSE_FILE_FOR: 'Provide license file for Veeam Backup & Replication',
    PROVIDER_TYPE: 'Provider Type',
    PENDING: 'Pending',
    PORTAL_OPERATOR_CAN_PERFORM: 'Portal Operator can perform full management for companies in the specified scope.',
    PORTAL_ADMINISTRATOR_CAN_PERFORM: 'Portal Administrator can perform all administrative activities in ' +
        'Veeam Service Provider Console including portal configuration, reseller and company accounts creation, ' +
        'subscription plans and invoices management and can access the data of all managed companies.',
    PROVIDER_INFO: 'Provider Info',
    PROVIDER_WITH_THE_SAME_NAME_ALREADY_EXISTS: 'Provider with the same name already exists.',
    PROVIDER: 'Provider',
    PHYSICAL_COMPUTERS: 'Physical computers',
    PRODUCT_NAME: 'Product Name',
    PASSWORD_FIELD_CANNOT_ONLY: 'Password field cannot only contain space characters',
    THE_PROVIDED_CONFIGURATION_FILE_IS_INVALID: 'The provided configuration file is invalid.',
    PER_COMPANY: 'per company',
    POINTS_BEFORE_REACHING_THE_LIMIT: 'Points before reaching the limit',
    PUBLIC_MAILBOX: 'Public Mailbox',
    PAST_MONTH: 'Past month',
    PAST_QUARTER: 'Past quarter',
    PAST_YEAR: 'Past year',
    PROCESSED_PERCENTAGE: 'Processed percentage',
    POSTGRESQL_DATABASE_LOG: 'PostgreSQL database log backup job',

    QUOTA: 'Quota',
    QUOTA_REACHED: 'Quota Reached',
    QUOTA_AND_LICENSE_USAGE: 'Quota & License Usage',
    QUOTA_SIZE: 'Quota Size',
    QUARTER: 'quarter',
    QUOTA_USAGE_REPORT: 'Quota Usage Report',
    QUEUED: 'Queued',
    QUOTA_LIMIT_FOR_THIS: 'Quota limit for this resource is set to {0}.',
    QUOTA_FOR_THIS_CANNOT_EXCEED: 'Quota for this {0} cannot exceed quota for the entire {1} ({2} GB)',
    QUOTA_FOR_THIS: 'Quota for this user cannot exceed the tenant quota ({0})',
    QUOTA_FOR_THIS_LOCATION_CANNOT_EXCEED: 'Quota for this location cannot exceed remaining quota for the entire company ({0} GB). ' +
        'Specify less value or increase company\'s quota and try again.',
    QUICK_RESYNC: 'Quick Resync',

    RATIO: 'Ratio',
    RDS: 'RDS',
    REACHING_QUOTA: 'Reaching Quota',
    READ: 'Read',
    READ_ONLY: 'Read-only',
    READY: 'Ready',
    REBOOT_AUTOMATICALLY_IF_REQUIRED: 'Reboot automatically if required',
    REBOOT_REMOTE_COMPUTER: 'Reboot Remote Computer',
    REBOOT_REQUIRED: 'Reboot Required',
    RED_HAT_ENTERPRISE_LINUX: 'Red Hat Enterprise Linux (RHEL)',
    REJECTED: 'Rejected',
    REMOTE_UI_ACCESS: 'Remote UI Access',
    REQUIRE_SUCCESSFUL_PROCESSING: 'Require successful processing (recommended)',
    RESCAN: 'Rescan',
    RESTORE: 'Restore',
    RESTORE_POINTS: 'Restore Points',
    RESTORE_POINT: 'Restore Point',
    RESTORE_POINT_REGION: 'Restore Point Region',
    RESTORE_POINT_SIZE: 'Restore Point Size',
    RESTART_BACKUP_SERVICES: 'Restart Backup Services',
    RETENTION_POLICY: 'Retention policy',
    RETENTION_TYPE: 'Retention Type',
    REMOVE: 'Remove',
    REMOVE_ALARM: 'Remove Alarm',
    REMOVE_DELETED_ITEMS_DATA_AFTER: 'Remove deleted items data after',
    REMOVE_PLAN: 'Remove Plan',
    REGION: 'Region',
    REMOVE_JOB: 'Remove Job',
    REMOVE_CREDENTIALS: 'Remove credentials',
    REMOVE_POLICY: 'Remove Policy',
    RETRY: 'Retry',
    RETRY_FAILED: 'Retry failed items processing',
    RETRY_FAILED_OBJECTS: 'Retry failed objects processing',
    RETAIN_LOG_BACKUPS: 'Retain log backups',
    RESELLER: 'Reseller',
    RESELLERS: 'Resellers',
    REPLICATION_TARGET: 'Replication Target',
    REPLICA_SNAPSHOT_REGION: 'Replica Snapshot Region',
    REPLICA_RESOURCES_VCD: 'Replication Resources (VMware Cloud Director)',
    REPLICA: 'Replica',
    REPLICAS: 'Replicas',
    REPLICATION: 'Replication',
    REPLICATION_RESOURCE: 'Replication Resource',
    REPLICATION_RESOURCES: 'Replication resources',
    RESTART_AGENT_SERVICE: 'Restart Agent Service',
    RESELLER_EMAIL: 'Reseller email',
    RESELLER_NAME: 'Reseller name',
    RESELLER_OWNER: 'Reseller owner',
    REPORT_LIST_PROTECTED_FILES_MSG: 'This report lists all protected file shares.',
    REPORT_LIST_PROTECTED_VMS_MSG: 'This report lists all protected VMs that meet the defined RPO period.',
    REPORT_LIST_PROTECTED_COMP_MSG: 'This report lists all protected computers running Veeam backup agent that meet the defined RPO period.',
    REPORT: 'Report',
    REPORTS: 'Reports',
    REPORT_CONFIGURATION: 'Report configuration',
    REPORT_DOWNLOAD: 'Report Download',
    REPORT_TYPE: 'Report Type',
    REPORT_PARAMETERS: 'Report parameters',
    REPORTED: 'Reported',
    REPORT_STATUS: 'Report Status',
    REPORT_SCOPE: 'Report scope',
    REPLICA_SNAPSHOT: 'Replica snapshot',
    REPLACE_FILES_AND_RESTART_VEEAM_BACKUP: 'Replace files and restart Veeam backup services',
    RUNNING: 'Running',
    RUN_BACKUP_AT: 'Run backup at',
    RUN_REPORT: 'Run Report',
    REASON_FOR_ADJUSTMENT: 'Reason for adjustment',
    REMOTE_AGENT_INSTALLATION_IS_NOT_SUPPORTED_FOR_MACOS: 'Remote agent installation is not supported for macOS.\n\n' +
        'Please <a >download Backup Agent for Mac</a> and install it manually on a remote computer.',
    REMOTE_COMPUTER: 'Remote computer',
    POWERSHELL_SESSION: 'PowerShell Session',
    REMOTE_SNAPSHOTS: 'Remote Snapshots',
    REPLICA_SNAPSHOTS: 'Replica Snapshots',
    REMOTE_SNAPSHOT: 'Remote Snapshot',
    REPLICA_SNAPSHOT_SIZE: 'Replica Snapshot Size',
    REMOVE_SELECTIONS: 'Remove selections',
    REMOTE_BACKUP_CONSOLE_DOWNLOAD: 'Remote Backup Console Download',
    REMOVE_BACKUP_SERVER_LICENSE: 'Remove Backup Server License',
    REMOVE_BACKUP_SERVER_LICENSE_TEXT_SINGLE: 'This will delete license from backup server.\nDo you want to proceed?',
    REMOVE_BACKUP_SERVER_LICENSE_TEXT_MULTI: 'Select what backup server license you want to delete?',
    REMOVE_LICENSE: 'Remove License',
    THIS_WILL_REMOVE_THE_LICENSE_KEY: 'This will remove the license key from the Veeam ONE server and will' +
        ' switch it to the Community Edition mode.\nDo you want to proceed?',
    REMOVE_RESOURCE: 'Remove Resource',
    READ_ONLY_COMPANY_STATUS: 'You\'re logged in read-only mode.\n' +
        'This company was disabled by the administrator.',
    REVIEW: 'Review',
    REVIEW_AND_COPY_MSG: 'Review and copy data, and click Finish to exit the wizard.',
    REVIEW_AND_COPY_CLOSE_MSG: 'Review and copy data, and click Close to exit wizard',
    RESCHEDULE: 'Reschedule {0}',
    RENTAL: 'Rental',
    REVIEW_AND_COPY: 'Review and copy data and click Finish to exit the wizard.',
    RENTAL_COUNT: 'Rental Count',
    REJECT_AGENT_CONNECTION: 'Reject Agent Connection',
    REJECT_CONNECTION: 'Reject Connection',
    REJECT_REASON: 'Reject Reason',
    RESTFUL_APIS_ACCESS: 'RESTful APIs access',
    RESTART: 'Restart',
    RESTARTING: 'Restarting',
    RESOLVE: 'Resolve',
    RESOLVED: 'Resolved',
    RESOLVE_ALARM: 'Resolve Alarm',
    RESOLVE_ALARM_IN_THE_CLIENTS_PORTAL: 'Resolve alarm in the client\'s portal',
    RULE_SIMPLE_MODE_TITLE: 'Select what type of RPO monitor you want to use for the alarm',
    READONLY_USERS: 'Readonly Users',
    REGISTRATION_TIME: 'Registration Time',
    REPORTS_HAVE_BEEN_SENT: 'Reports have been sent.',
    REPORT_HAS_BEEN_SENT: 'Report has been sent.',
    REPOSITORY: 'Repository',
    REPOSITORIES: 'Repositories',
    REPLICATED: 'Replicated',
    REPLICATED_VM: 'Replicated VM',
    REPOSITORY_NAME: 'Repository Name',
    REPOSITORY_FREE_SPACE: 'Repository Free Space',
    REPOSITORY_CAPACITY_SOME: 'Repository capacity {0}',
    RESELLER_ADMINS: 'Reseller Admins',
    RESELLER_OPERATORS: 'Reseller Operators',
    REQUIRE_SUCCESSFUL_SCRIPT_EXECUTION: 'Require successful script execution',
    REQUIRES_AUTHENTICATION: 'Requires authentication',
    RESET: 'Reset',
    REVIEW_THE_LOGIN_LINK_AND: 'Review the login link and check if it is available.',
    RESET_ALL_CHANGES: 'Reset All Changes',
    REGENERATE_INSTALLATION_ID: 'Regenerate Installation ID',
    RESET_CHANGES: 'Reset Changes',
    RESET_DISCOVERED_COMPUTERS: 'Reset Discovered Computers',
    RESET_MULTI_FACTOR_AUTHENTICATION_ACCESS: 'Reset Multi-Factor Authentication Access',
    RESET_PASSWORD: 'Reset Password',
    RESET_SECURITY_TOKEN: 'Reset Security Token',
    RESET_TO_DEFAULT: 'Reset to Default',
    RESOURCE_ID: 'Resource ID',
    RESOURCES_CANNOT_BE_DISABLED: '{0} cannot be disabled. These resources are used by companies ({1}) managed by this reseller.\n\n'
        + 'To disable these resources, remove these resources for the managed companies or unassign them from the reseller.',
    RESTORE_POINTS_TO_KEEP_ON_DISK: 'Restore points to keep on disk',
    RESTRICT_VPN_CONNECTION_USAGE: 'Restrict VPN connection usage for backup',
    RETRY_FAILED_JOB_EVERY: 'Retry failed job every',
    RETRY_FAILED_JOB: 'Retry failed job',
    RETRY_FAILED_OBJECT_PROCESSING: 'Retry failed object processing',
    REVENUE_BY_SERVICE_TYPE: 'Revenue by Service Type',
    REVIEW_THE_DISCOVERY_RULE_SUMMARY: 'Review the discovery rule summary',
    REVIEW_THE_SECURITY_CERTIFICATE: 'Review the security certificate installed on the backup appliance.',
    REVOKE_LICENSE_KEY: 'Revoke License Key',
    RHV_INSTALL: 'RHV_INSTALL',
    ROLE: 'Role',
    ROLES_AND_USERS: 'Roles & Users',
    ROOT_PASSWORD: 'Root password',
    RUN: 'Run',
    RUN_MANAGEMENT_AGENT_SERVICE_UNDER: 'Run management agent service under',
    RUN_CUSTOM_QUERY: 'Run custom query:',
    RUNNING_JOBS: 'Running Jobs',
    RULE: 'Rule',
    RULES: 'Rules',
    RULE_NAME: 'Rule name',
    RULES_FOR_COMPANIES_BELOW: 'Rules for companies below would not be created. These companies do not have {0} management agents: ',
    RULE_SCHEDULING: 'Rule scheduling',
    RUN_POLICY_AT_THESE_EVENTS: 'Run policy at these events',
    RUN_THE_JOB_AUTOMATICALLY: 'Run the job automatically',
    RUN_THIS_RULE_AUTOMATICALLY: 'Run this rule automatically',
    GENERATE_THIS_REPORT_AUTOMATICALLY: 'Generate this report automatically',
    RUN_CONTINUOUSLY: 'Run continuously',
    RULE_IS_NOT_SCHEDULED: 'Rule is not scheduled to run automatically',
    ROAMING_USER_PROFILES: 'Roaming user profiles',
    RPO: 'RPO',
    RPO_BASED_REPORT: 'RPO-based report',
    RPO_AND_SLA: 'RPO & SLA',
    RESYNC_CONFIGURATION: 'Resync Configuration',
    REMOVE_USER: 'Remove User',
    REVIEW_THE_LIST_OF_MANAGED_COMPANIES: 'Review the list of managed companies',
    REVIEW_THE_LIST_OF_CLAIM_MAPPING_RULES: 'Review the list of claim mapping rules',
    REGISTER_AT_LEAST_ONE_IDENTITY_PROVIDER: 'Register at least one identity provider on the Security section -> Single Sign-On tab',
    REGISTER_VEEAM_CLOUD_CONNECT_SERVERS: 'Register Veeam Cloud Connect servers',
    REMOVE_MAPPING_RULE: 'Remove Mapping Rule',
    REMOVE_IDENTITY_PROVIDER: 'Remove Identity Provider',
    RULE_STATUS: 'Rule Status',
    RULE_INFO: 'Rule Info',
    READ_ONLY_USERS_CAN_MONITOR_DATA: 'Read-only Users can monitor data of managed companies in the specified ' +
        'scope and cannot perform any management actions.',
    REGULAR_EXPRESSION: 'Regular expression',
    RECEIVE_LOCATIONS_LIST: 'Receive locations list from the identity provider',
    REVIEW_RULE_DETAILS: 'Review rule details and click Finish to exit the wizard.',
    REVIEW_USER_DETAILS: 'Review user details and click Finish to exit the wizard.',
    RESTORING: 'Restoring',
    REPOSITORY_QUOTA: 'Repository quota',
    RECOMMENDED: 'Recommended',
    REVIEW_SETTINGS_AND_CLICK_FINISH: 'Review settings and click Finish to exit the wizard',
    RESELLER_INFO: 'Reseller info',
    REMOVE_SITE: 'Remove site',
    RESET_THE_SECURITY_TOKEN: 'Reset the security token?\n\n' +
        'This action will reset a connection token for the selected companies. ' +
        'Management agents using the previously generated token will not be able to connect to the backup portal. ' +
        'To resolve this, download the updated package for remote agent installation.',
    RESTORE_PORTAL: 'Restore Portal',
    REMOTE_MANAGEMENT: 'Remote Management',
    REST_API: 'REST API',
    RESOURCES_AND_BILLING: 'Resources & Billing',
    RESOURCES: 'Resources',
    RESELLER_WILL_NOT_BE_ABLE_TO_CONFIGURE: 'Reseller will not be able to configure the number of concurrent tasks '
        + 'for a managed company more than specified value.',
    REGISTER_YOUR_APPLICATION: 'Register your application in the mail service and enter the application client ID here.',
    REGISTER_YOUR_FIRST_VEEAM_CLOUD: 'Register your first',
    REGISTER_YOUR_FIRST_VEEAM_CLOUD_VEEAM_CLOUD_CONNECT: 'Veeam Cloud Connect',
    REGISTER_YOUR_FIRST_VEEAM_CLOUD_SERVER: 'server',
    REDIRECT_URL: 'Redirect URL',
    RECOMMENDED_COMPRESSION_LEVEL: 'Recommended compression level for deduplicating storage appliances and external WAN accelerators.',
    RESOLUTION: 'Resolution',
    RESET_EMAIL_SERVER_SETTINGS: 'Reset email server settings',
    REMOTE_MANAGEMENT_AGENT: 'Remote management agent will not be deleted automatically due to the following reasons',
    REPLICATION_RESOURCES_ARE_SWITCHED_ON_BUT_NOT_CONFIGURED: 'Replication resources are switched on but not configured.',
    REMOVE_SELECTED_MANAGED: 'Remove selected managed {0}',
    REPOSITORY_TYPE: 'Repository type',
    REMOVE_THESE_RESELLERS: 'Remove these resellers?\n\n' +
        'This action will remove all data about these resellers, including the assignment scope of managed companies. ' +
        'All managed companies will be switched to the directly managed mode.',
    REMOVE_THIS_RESELLER: 'Remove this reseller?\n\n' +
        'This action will remove all data about this reseller, including the assignment scope of managed companies. ' +
        'All managed companies will be switched to the directly managed mode.',
    ROUND_UP_STORAGE_USAGE_COST: 'Round up storage usage cost to the higher value when exceeding the defined block size',
    ROUND_UP_STORAGE_FOR_USAGE_COST: 'Round up for storage usage cost',
    RENTAL_LICENSES: 'Rental Licenses',
    REST_API_USER_GUIDE: 'REST API User Guide',
    REST_API_VEEAM_FORUMS: 'REST API Veeam forums',

    SELECT_A_BACKUP_REPOSITORY_TO_ASSIGN_TO_THE_COMPANY: 'Select a backup repository to assign to the company.',
    SELECT_A_LICENSE_KEY_FROM: 'Select a license key from the table to install the backup server',
    SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER: 'Select companies accessible for this user.',
    SIGN_IN_TO_VEEAM: 'Sign in to Veeam Customer Support portal to continue',
    SATURDAY: 'Saturday',
    SALES_TAX: 'Sales Tax',
    SAVE: 'Save',
    SAVING_DATA: 'Saving data...',
    SCAN_THE_QR_CODE_USING_YOUR_DEVICES_CAMERA: 'Scan the QR code using your device\'s camera',
    SCAN_THE_QR_CODE: 'Scan the QR code',
    SCOPE: 'Scope',
    SPECIFY_SUPPORT_CASE: 'Specify support case scope.',
    SELECT_THE_CONTRACT: 'Select the contract',
    SECURITY: 'Security',
    SECURITY_CERTIFICATE: 'Security certificate',
    CANNOT_VERIFY_THE_SECURITY: 'Cannot verify the security certificate for server {0}. Untrusted certificate.',
    SECURITY_CERTIFICATES: 'Security Certificates',
    SECURITY_GROUP: 'Security Group',
    SESSION_EXPIRED: 'Session Expired',
    SESSION_STATES: 'Session States',
    SET_BILLING: 'Set Billing',
    SEPTEMBER: 'September',
    SIGNED_IN_AS: 'Signed in as',
    SELECTED_A_MANAGED_COMPANY: 'Select a managed company for the support case.',
    SELECT_COMPUTERS_HOSTING: 'Select computers hosting Veeam management agents.',
    SELECT_A_VEEAM_BACKUP: 'Select a Veeam Backup & Replication server.',
    SELECT_A_COMPUTER_HOSTING: 'Select a computer hosting Veeam Agent.',
    SELECT_A_VEEAM_BACKUP_FOR_MICROSOFT_365: 'Select a Veeam Backup for Microsoft 365 server.',
    SELECT_A_VEEAM_ONE_SERVER: 'Select a Veeam ONE server.',
    SELECT_A_VEEAM_CLOUD_CONNECT_SERVER: 'Select a Veeam Cloud Connect server.',
    SELECT_A_CLOUD_BACKUP_APPLIANCE: 'Select a cloud backup appliance.',
    SET_CUSTOM_PRO: 'Set custom RPO',
    SET_COMMON_VALUE: 'Set common value',
    SET_COMPANY: 'Set Company',
    SET_COMPANY_FOR_THE_SELECTED_AGENTS: 'Set company for the selected agents',
    SET_CLOUD_REPOSITORY_QUOTA_FOR_THE_USER: 'Set cloud repository quota for the user.',
    SELECT_SCHEDULING_OPTIONS_FOR: 'Select scheduling options for the discovery rule',
    SELECT_THE_CHARGING_MODEL: 'Select the charging model for your cloud storage.',
    SET_DATA_TRANSFER_OUT: 'Set data transfer out quota for Veeam Cloud Connect.',
    SET_DEFAULT_SETTINGS_FOR_A_WINDOWS: 'Set default settings for a Windows backup agent:',
    SET_PRICE_FOR_OPERATING_SYSTEMS: 'Set price for operating systems of managed backup agents.',
    SET_PUBLIC_CLOUD_RESOURCES: 'Set public cloud resources provided free of charge.',
    SET_USER_QUOTA: 'Set user quota',
    SELECT_A_TIME_PERIOD_TO: 'Select a time period to generate a usage report',
    SELECT_AN_EXISTING_BACKUP_REPOSITORY: 'Select an existing backup repository...',
    SELECT_ORGANIZATIONAL_UNIT_TO: 'Select organizational unit to search computers in.',
    SELECT_ORGANIZATION_FROM_THE_LIST: 'Select organization from the list',
    SELECT_REPOSITORY_FROM_THE_LIST: 'Select a repository from the list to continue',
    REPORTING: 'Reporting',
    REPORTING_PERIOD: 'Reporting period',
    SELECT_OPERATION_MODE_FOR_THE_MBA: 'Select operation mode for the managed backup agent',
    SELECT_SUBSCRIPTION_PLAN_FOR_COMPANY: 'Select subscription plan for the selected company',
    SELECT_SUBSCRIPTION_PLAN_FOR_COMPANIES: 'Select subscription plan for the selected companies',
    SELECT_DAYS: 'Select Days',
    SELECT_CERTIFICATE_FROM_THE_CERTIFICATE_STORE: 'Select certificate from the Certificate Store',
    SELECT_MONTHS: 'Select Months',
    SELECTED_ALARMS_WILL_BE_REMOVED_FROM: 'Selected alarms will be removed from the list of available alarms. Do you want to proceed?',
    SELECTED_DAYS: 'Selected days',
    SELECTED_FILES_AND_FOLDERS: 'Selected files and folders',
    SELECT_LOCATION: 'Select location',
    SELECT_LOCATION_FOR_THE: 'Select location for the {0}',
    SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_RULE: 'Select locations accessible for this rule',
    SELECT_A_WAN_ACCELERATOR: 'Select a WAN accelerator',
    SELECT_HARDWARE_PLAN: 'Select Hardware Plan',
    SELECT_ORGANIZATION: 'Select Organization',
    SELECT_ONE_OR_MORE_HARDWARE: 'Select one or more hardware plans for this company.',
    SELECT_ONE_OR_MORE_VCD_ORGANIZATIONS: 'Select one or more VCD organizations available to the reseller.',
    SELECT_A_CLOUD_APPLIANCE_TO_CREATE: 'Select a cloud appliance to create a new policy',
    SELECT_VIRTUAL_DATA_CENTER: 'Select virtual data center',
    SELECT_USER: 'Select User',
    SELECT_UNIT: 'Select unit...',
    SELECT_HOW_TO_CREATE_A_BACKUP_JOB: 'Select how to create a backup job',
    SELECT_SCHEDULING_OPTIONS_FOR_THE: 'Select scheduling options for the backup report.',
    SELECT_THE_PREFERRED_ACTIVE: 'Select the preferred Active Directory discovery method',
    SELF_MANAGED_ALARMS: 'Self-managed alarms',
    SERVER_VERSION: 'Server Version',
    SERVER_BACKUP: 'Server backup',
    SEND: 'Send',
    SEND_NOTIFICATION_EMAIL_AFTER: 'Send notification email after the first run',
    SHUTDOWN: 'Shutdown',
    SETUP_OPTIONS: 'Setup Options',
    SET_CLOUD_STORAGE_QUOTA: 'Set Cloud Storage Quota',
    SET_CONNECTION_ACCOUNT_TO_THE_CLOUD: 'Set connection account to the cloud repository and define user quota',
    SET_ALARM_ACTIONS_AND_CONDITIONS: 'Set alarm actions and conditions when they should be executed',
    SET_PRIVATE_KEY_FROM_FILE: 'Set private key from file',
    SET_PRICE_FOR_STORAGE_CONSUMED: 'Set price for storage consumed by deleted backups.',
    SET_QUOTA: 'Set quota',
    SINGLE: 'Single',
    SIZE: 'Size',
    SITES: 'Sites',
    SMTP_SERVER: 'SMTP server',
    SELECTED: 'Selected',
    SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER: 'Select locations accessible for this user.',
    SPECIFY_REPORT_ACCESS_TYPE: 'Specify report access type.',
    SMTP_SERVER_NAME_OR_ADDRESS: 'SMTP server name or address',
    START_USING_VEEAM_CLOUD_CONNECT: 'Start using Veeam Cloud Connect',
    SPECIFIES_AUTHENTICATION_MODE_TO_CONNECT: 'specifies authentication mode to connect to the SQL Server where the '
        + 'Veeam Backup & Replication configuration database is deployed. Specify 1 to use the SQL Server authentication mode '
        + 'or specify 0 to use the Microsoft Windows authentication mode',
    SPECIFY_THE_PRODUCT_AND_SELECT: 'Specify the product and select a license key to install.',
    SPECIFY_TIME_PERIOD: 'Specify time period',
    SPECIFY_YOUR_PORTAL_WEB_ADDRESS: 'Specify your portal web address. This address will be used in the Welcome customer email',
    SPECIFY_THE_SERVICES: 'Specify the services you are going to provide',
    SPECIFY_ORACLE_DATABASE_ACCOUNT: 'Specify Oracle database account with SYSDBA privileges:',
    SPECIFY_PRE_FREEZE_OR_POST_THAW: 'Specify pre-freeze or post-thaw script path.',
    SPECIFY_PUBLIC_IP_ADDRESS: 'Specify public IP address',
    SPECIFY_PUBLIC_IP_ADDRESS_RANGE: 'Specify public IP address range',
    SPECIFY_VEEAM_BACKUP_REPLICATION_SERVER: 'Specify Veeam Backup & Replication server to use as a target for the backup job.',
    SPECIFY_VEEAM_CLOUD_CONNECT_SERVER: 'Specify Veeam Cloud Connect server address and type in a friendly name for the server.',
    SPECIFY_ACCOUNT_WITH_SUPERUSER_PRIVILEGES: 'Specify account with superuser privileges',
    SPECIFIED_IP_IS_ALREADY_ASSIGNED: 'Specified IP is already assigned',
    SPECIFIED_IP_ADDRESS_RANGE_IS_INVALID: 'Specified IP address range is invalid.',
    SPECIFIED_IP_RANGE_IS_ALREADY_ASSIGNED: 'Specified IP range is already assigned.',
    SPECIFIED_LICENSE_FILE: 'Specified license file',
    SPECIFIED_VALUE_IS_NOT_SUPPORTED: 'Specified value is not supported. First three octets should be the same.',
    SPECIFY_JOB_NAME_AND_ITS_DESCRIPTION: 'Specify job name and its description',
    SPECIAL_CHARACTERS_CANNOT_BE_USED: 'Special characters cannot be used for the backup job name.',
    SPECIFY_CONNECTION_STATE_AND: 'Specify connection state and define severity for this alarm',
    SPECIFY_EMAIL_SETTINGS_TO_USE: 'Specify email settings to use in alarm notifications',
    SPECIFY_FILE_SHARE_BACKUP_SERVICES: 'Specify file share backup services provided free of charge.',
    SPECIFY_EMAIL_SETTINGS_DISCOVERY: 'Specify email settings to use in the discovery email',
    SPECIFY_NETWORK_SETTINGS_TO: 'Specify network settings to be used during failover',
    SPECIFY_MICROSOFT_SQL_SERVER_ACCOUNT: 'Specify Microsoft SQL Server account with database admin privileges:',
    SPECIFY_USERNAME_FOR_THE_USER: 'Specify username{0} for the user',
    SPECIFY_LOGIN_AND_PASSWORD: 'Specify login and password for the user.',
    SPECIFY_THE_TEXT_TO_ADD_TO_WELCOME_EMAIL: 'Specify the text to add to welcome email. It may contain URL',
    SPECIFY_THE_REPORT_TYPE: 'Specify the report type.',
    SPECIFY_THE_RULE_NAME: 'Specify the rule name',
    SPECIFY_THE_SEVERITY_LEVEL_FOR_THIS_ALARM: 'Specify the severity level for this alarm',
    SELECT_ALARM_SEVERITY: 'Select alarm severity to include in the daily summary email',
    SPECIFY_THE_SLA_THRESHOLD: ' Specify the SLA threshold and select the severity level for this alarm',
    SPECIFY_THRESHOLDS_AND_DEFINE_SEVERITY: 'Specify thresholds and define severity for this alarm',
    SPECIFY_OBJECTS_YOU_WOULD_LIKE: 'Specify objects you would like to include in the backup',
    SPECIFY_PATH_TO_A_SUBVOLUME_MOUNT: 'Specify path to a subvolume mount point or Btrfs device',
    SPECIFY_ORGANIZATION_TO_BACKUP: 'Specify organization to back up',
    SPECIFY_OBJECTS_TO_INCLUDE_IN_THE_BACKUP: 'Specify objects to include in the backup',
    SPECIFY_OBJECTS_TO_EXCLUDE_FROM_THE_BACKUP: 'Specify objects to exclude from the backup',
    SPECIFY_0_TO_USE_AN_EXISTING_SQL: 'specify 0 to use an existing SQL server instance or specify 1 to create a new SQL server instance',
    SPECIFY_0_TO_USE_POSTGRESQL_ENGINE: 'specify 0 to use PostgreSQL engine or specify 1 to use Microsoft SQL engine. '
        + 'Note that if you want to create a new SQL server instance, you can only choose the PostgreSQL engine',
    SPECIFY_1_TO_ACCEPT_VEEAM_LICENSE_AGREEMENT: 'specify 1 to accept Veeam license agreement and proceed with installation',
    SPECIFY_1_TO_ACCEPT_THE_LICENSING_POLICY: 'specify 1 to accept the licensing policy and proceed with installation',
    SPECIFY_1_TO_ACCEPT_ALL_REQUIRED_SOFTWARE_LICENSE: 'specify 1 to accept all required software license agreements ' +
        'and proceed with installation',
    SPECIFY_1_TO_ENABLE_AUTOMATIC_LICENSE_UPDATE: 'specify 1 to enable automatic license update and usage reporting or specify 0'
        + ' to update the license manually. For NFR licenses, specify 1. For licenses without license ID information it must be set to 0',
    SPECIFY_1_TO_ACCEPT_THE_LICENSE_AGREEMENT_FOR_3RD: 'specify 1 to accept the license agreement for 3rd party components '
        + 'that Veeam incorporates and proceed with installation',
    SPECIFY_1_TO_AUTOMATICALLY_CHECK_FOR_NEW_PRODUCT: 'specify 1 to automatically check for new product versions and updates. '
        + 'Specify 0 if you do not want to check for updates',
    SPECIFY_1_TO_ENABLE_AUTOMATIC_LICENSE_UPGRADE: 'specify 1 to enable automatic upgrade of existing components '
        + 'in the backup infrastructure or specify 0 to upgrade the components manually',
    SPECIFY_1_TO_INSTALL_GOOGLE_CLOUD: 'specify 1 to install Google Cloud Plug-in for Veeam Backup & Replication',
    SPECIFY_1_TO_INSTALL_KASTEN: 'specify 1 to install Kasten Plug-in for Veeam Backup & Replication',
    SPECIFY_1_TO_INSTALL_MICROSOFT_AZURE: 'specify 1 to install Microsoft Azure Plug-in for Veeam Backup & Replication',
    SPECIFY_1_TO_INSTALL_NUTANIX_AHV: 'specify 1 to install Nutanix AHV Plug-in for Veeam Backup & Replication',
    SPECIFY_1_TO_INSTALL_NUTANIX_RHV: 'specify 1 to install Nutanix RHV Plug-in for Veeam Backup & Replication',
    SPECIFY_1_TO_INSTALL_AWS_PLUGIN: 'specify 1 to install AWS Plug-in for Veeam Backup & Replication',
    SSH_PORT: 'SSH port',
    START: 'Start',
    STOP: 'Stop',
    SUBSCRIPTION_PLAN: 'Subscription Plan',
    SUBSCRIPTION_PLANS: 'Subscription Plans',
    SUBJECT: 'Subject',
    SUBJECT_MASK_NBD: '[NBD] Subject mask',
    SUMMARY_REPORT: 'Summary report',
    SITE: 'Site',
    SITE_NAME: 'Site name',
    SITE_SCOPE: 'Site Scope',
    SECOND: 'Second',
    SECRET_CODE: 'Secret Code',
    SEARCH_THROUGH_ALL_ACTIVE_DIRECTORY_CONTAINERS: 'Search through all Active Directory containers',
    SERVER: 'Server',
    SERVER_ACTIONS: 'Server Actions',
    SERVER_NAME_OR_IP_ADDRESS: 'Server name or IP address',
    SERVER_SETTINGS: 'Server Settings',
    SERVERS: 'Servers',
    SIGNATURE_ALGORITHM: 'Signature algorithm',
    SLA: 'SLA',
    SLA_BASED_REPORT: 'SLA-based report (CDP policies only)',
    SLA_SERVICE_LEVEL_AGREEMENT: 'SLA – service-level agreement represents a percentage of sessions completed within the desired threshold.',
    SLEEP: 'Sleep',
    SMB_CIFS: 'SMB (CIFS)',
    SNAPSHOT: 'Snapshot',
    SNAPSHOTS: 'Snapshots',
    SOURCE_SIZE: 'Source Size',
    SOURCE_REPOSITORY: 'Source Repository',
    SOURCE_BACKUP_JOB: 'Source backup job',
    SOCKETS: 'Sockets',
    SOCKET_BASED: 'Socket-based',
    SORT_ALARMS_BY: 'Sort alarms by',
    SELECT_THE_SORTING_OPTION: 'Select the sorting option for the daily summary email',
    SCHEDULING: 'Scheduling',
    SCHEDULE: 'Schedule',
    SCHEDULING_OPTIONS: 'Scheduling options',
    SCHEDULE_UPDATE: 'Schedule update',
    SUPPORT_LOGS: 'Support Logs',
    SUBSCRIPTION_DATE: 'Subscription Date',
    SUMMARY_ONLY: 'Summary only',
    SUNDAY: 'Sunday',
    SPECIFY_THE_INVOICE_SCHEDULING: 'Specify the invoice scheduling options',
    SELECT_FROM_ORGANIZATIONAL_UNITS: 'Select from organizational units',
    AN_ERROR_OCCURRED: 'An error occurred. Please reload the page.',
    ANOTHER_USER_IS_CURRENTLY_CONNECTED: 'Another user is currently connected to the remote server.' +
        ' Do you want to terminate that session and continue?',
    SOME_USAGE_REPORTS_FROM_MANAGED_COMPANIES: 'Some usage reports from managed companies have not been approved yet. '
        + 'If you choose to create usage report now, then these reports will be approved automatically.',
    SOURCE: 'Source',
    SOURCE_DATA_SIZE: 'Source Data Size',
    SOURCE_FILE: 'Source file',
    SOURCE_ITEMS: 'Source Items',
    SQL_LOG_BACKUP_JOB: 'SQL Log Backup Job',
    SQL_DATABASE_LOG_BACKUP_JOB: 'SQL database log backup job',
    SEND_REPORT: 'Send Report',
    SEND_REPORTS_TO_THE_FOLLOWING_EMAIL: 'Send reports to the following email addresses',
    SERVER_AGENT: 'Server Agent',
    SERVER_AGENT_FREE_OF_CHARGE: 'Server agent free of charge',
    SERVER_AGENTS: 'Server agents',
    SERVER_NAME: 'Server Name',
    SERVERS_QUOTA: 'Servers quota',
    SERVERS_STORED_IN_CLOUD_REPOSITORY: 'Servers Stored in Cloud Repository',
    SUSE_LINUX_ENTERPRISE_SERVER: 'SUSE Linux Enterprise Server (SLES)',
    SUSPICIOUS: 'Suspicious',
    SITE_ADMINISTRATOR: 'Site Administrator',
    SHOW: 'Show',
    SHOW_DATA_BY: 'Show data by',
    SHOW_CERTIFICATE: 'Show Certificate',
    SHAREPOINT: 'SharePoint',
    SHARED_FOLDER_PATH: 'Shared folder path',
    SEND_WELCOME_EMAIL: 'Send Welcome Email',
    SPECIFY_THE_NAME: 'Specify the name',
    SPECIFY_MICROSOFT_SHAREPOINT_ADMIN_ACCOUNT: 'Specify Microsoft SharePoint admin account:',
    SPECIFY_USER_CREDENTIALS_WITH: 'Specify user credentials with local administrator privileges on the remote computers.',
    SPECIFY_REMOTE_COMPUTER_DISCOVERY_OPTIONS: 'Specify remote computer discovery options and set their priorities.',
    STATE: 'State',
    STORAGE_LEVEL_CORRUPTION_GUARD: 'Storage-level corruption guard',
    SET_AS_DEFAULT: 'Set as Default',
    SET_AS_MASTER: 'Set as Master',
    SET_LIMIT_FOR_THE_NUMBER_OF_MANAGED: 'Set limit for the number of managed through the portal backup agents '
        + 'and enable cloud resources for a reseller',
    SET_LOCATION: 'Set Location',
    SET_UP_A_NEW_LOCATION: 'Set up a new location',
    SET_UNLIMITED_QUOTA: 'Set unlimited quota',
    SUCCESS: 'Success',
    SUCCESSFUL_BACKUP_TASKS_RATIO: 'Successful Backup Tasks Ratio',
    SUCCESSFUL_JOBS: 'Successful Jobs',
    SUCCESSFUL_SESSIONS: 'Successful Sessions',
    SUBNET_MASK: 'Subnet mask',
    SELECT: 'Select...',
    SELECT_AGENT: 'Select agent',
    SELECT_BACKUP_REPOSITORY: 'Select backup repository',
    SELECT_BACKUP_REPOSITORY_FROM_THE_LIST: 'Select backup repository from the list',
    SELECT_BACKUP_POLICY_MGS: 'Select backup policy to use for the backup job creation',
    SELECT_BACKUP_POLICY_AS_A_TEMPLATE_MSG: 'Select backup policy as a template for the backup job.',
    SELECT_GUEST_OS_THAT_YOU: 'Select guest OS that you want to discover',
    SELECT_FILTERS_TO_APPLY: 'Select filters to apply',
    SELECT_ONE_OR_MORE_HARDWARE_RESELLER: 'Select one or more hardware plans available to the reseller.',
    SELECT_SITES_AVAILABLE_FOR: 'Select sites available for the reseller and set quota for the number of managed companies.',
    SELECT_SELECT_THE_CHARGING_MODEL: 'Select the charging model (allocation, consumption, or usage per tier) for the cloud storage'
        + ' and set price for the space consumed by deleted backups and for data transfer out.',
    SPECIFY_TAG_FOR_MANAGED: 'Specify tag for the managed computer.',
    SPECIFY_USER_CREDENTIALS_TO_ACCESS: 'Specify user credentials to access the selected Veeam Backup & Replication ' +
        'server and its backup repository. ' +
        'If no user credentials are specified, backup job will use Active Directory computer account instead.',
    SPECIFY_MANAGED_BACKUP_SERVICES_PROVIDED: 'Specify managed backup services provided free of charge.',
    SPECIFY_MANAGED_BACKUP_SERVICES_PROVIDED_FREE_OF_CHARGE: 'Specify managed backup services provided free of charge'
        + ' and set price for operating systems of managed backup agents.',
    SPECIFY_THE_NUMBER_OF_USERS: 'Specify the number of users and the amount of storage provided free of charge.',
    SPECIFY_TARGET_SHARED_FOLDER_WIN: 'Specify target shared folder for this backup in the following format: \\\\server\\folder',
    SPECIFY_A_REPOSITORY_FOR_THE_BACKUP_COPY_JOB: 'Specify a repository for the backup copy job',
    SPECIFY_DESTINATION_TARGET_FOR: 'Specify destination target for this backup in the following format "{0}"',
    SPECIFY_EMAIL_ADDRESS_TO_SEND: 'Specify email address to send email notifications to',
    SPECIFY_A_FRIENDLY_REPOSITORY: 'Specify a friendly repository name that will be shown to the user',
    SPECIFY_PARAMETERS_FOR_THIS_BACKUP_REPORT_MSG: 'Specify parameters for this backup report.',
    SPECIFY_NAME_FOR_THIS_ALARM: 'Specify name for this alarm',
    SPECIFY_ORACLE_ACCOUNT: 'Specify Oracle account with SYSDBA privileges',
    SET_SCHEDULE_FOR_BACKUP_SERVER: 'Set schedule for backup server {0}',
    SPECIFY_FILE_NAME_AND_EXTENTION_MASKS: 'Specify file name and extension masks to include or exclude files',
    SPECIFY_FILE_NAMES_EXTENTION: 'Specify file names, extension masks or directory paths.',
    SPECIFY_FILE_NAMES_OR_EXTENTION_MASK: 'Specify file names or extension masks to exclude from backup.',
    SPECIFY_EXTENSION_MASKS_OR_DIRECTORY: 'Specify extension masks or directory paths to include in the backup.',
    SPECIFY_EXTENSION_MASKS_TO_EXCLUDE: 'Specify extension masks to exclude from the backup.',
    SPECIFY_PATCH_SETTINGS: 'Select patch settings for the backup server',
    SPECIFY_PATH_TO_THE_PASSWORD: 'Specify path to the password file',
    SPECIFY_SHARED_FOLDER_PATH: 'Specify shared folder path in the following format: "server/folder"',
    SPECIFY_CONNECTION_SETTINGS: 'Specify connection settings and the type of a network shared folder to back up data to.',
    SPECIFY_USER_ACCOUNT_THAT_BELONGS: 'Specify user account that belongs to Veeam backup and operating system administrator groups. '
        + ' This user will be used to run management agent service.',
    SPECIFY_USER_ACCOUNT_TO_CONNECT_TO: 'Specify user account to connect to the backup portal',
    STATUS: 'Status',
    SUPPRESS_AFTER: 'Suppress after',
    SCHEDULED_UPDATES: 'Scheduled Updates',
    SUPPRESS_ALARM_DURING_BACKUP_CREATION_TIME: 'Suppress alarm during backup creation time',
    SUPPORT_ID: 'Support ID',
    SUPPORT_EXPIRATION: 'Support Expiration',
    SSL_CREDENTIALS_STEP_TITLE: 'Credentials',
    SSL_CREDENTIALS_STEP_DESCRIPTIONS: 'Specify Internet Information Services (IIS) user credentials ' +
        'to install the web site certificate of the backup portal',
    SUMMARY: 'Summary',
    SERIAL_NUMBER: 'Serial Number',
    START_PROVIDING_REMOTE_BACKUP_SERVICES: 'Start providing remote backup services',
    STREET: 'Street',
    STORAGE: 'Storage',
    STORAGE_USAGE: 'Storage Usage',
    STORAGE_ENCRYPTION: 'Storage encryption',
    STORAGE_OPTIMIZATION: 'Storage optimization',
    SUBSCRIPTION: 'Subscription',
    SELECT_LOCATIONS_TO_CREATE: 'Select locations to create a backup report for.',
    SELECT_ANY_REPOSITORY_TO_STORE: 'Select any repository to store your backup data.',
    SELECTED_GATEWAY_POOL: 'Selected gateway pool',
    SELECT_AT_LEAST_ONE_TO_REVIEW_WORKLOAD: 'Select at least one {0} to review workload usage',
    SELECT_AT_LEAST_ONE_GATEWAY_POOL: 'Select at least one gateway pool.',
    SELECT_COMPANIES_TO_CREATE_A_DISCOVERY: 'Select companies to create a discovery rule for',
    SELECT_COMPANIES_TO_CREATE_A_REPORT: 'Select companies to create a report for.',
    SELECT_A_LOCATION_TO_FILTER: 'Select a location to filter the data',
    SELECT_A_HARDWARE_PLAN: 'Select a hardware plan',
    SYSTEM: 'System',
    SYSTEM_ACCOUNT: 'System account',
    SELECT_ALARM_SEVERITY_FOR: 'Select alarm severity for the SureBackup job state',
    SYSTEM_USER_WITHOUT_PASSWORD: 'System user without password (peer)',
    SELECT_API_KEY_TYPE: 'Select API key type',
    SELECT_AT_LEAST_ONE_HARDWARE: 'Select at least one hardware plan to enable this option',
    SELECTED_DAYS_AT: 'Selected days at {0}: {1}',
    SELECT_A_SECURITY_CERTIFICATE_TO_PROCEED: 'Select a security certificate to proceed.',
    START_BACKING_UP_VMS: 'Start backing up VMs<br />to view the data',
    START_DEPLOYING_BACKUP_AGENTS: 'Start deploying backup agents<br />to view the data',
    START_PROTECTING_FILE_SHARES: 'Start protecting file shares<br />to view the data',
    START_PROTECTING_VMS: 'Start protecting VMs<br />to view the data',
    START_REPLICATING_VMS_TO_THE_CLOUD_HOST: 'Start replicating VMs to the cloud host<br />to view the data',
    START_SENDING_BACKUPS_TO_THE_CLOUD_REPOSITORY: 'Start sending backups to the cloud repository<br />to view the data',
    SET_BACKUP_AGENT_SETTINGS: 'Set backup agent settings',
    SELECT_TIME_PERIODS: 'Select Time Periods',
    SET_BACKUP_WINDOW: 'Set Backup Window',
    SELECT_BACKUP_JOBS_FOR_THE_SUPPORT_CASE: 'Select backup jobs for the support case.',
    SELECT_BACKUP_POLICIES_FOR_THE_SUPPORT_CASE: 'Select backup policies for the support case.',
    SETTINGS: 'Settings',
    SEVERITY: 'Severity',
    SET_THE_DESIRED_RETENTION_POLICY: 'Set the desired retention policy for each data type. ' +
        'For optimized performance it is recommended to use the default values.',
    SUBTENANTS: 'Subtenants',
    SPECIFIED_USERNAME_FORMAT_IS_NOT_SUPPORTED: 'Specified username format is not supported',
    SPECIFY_ADMIN_ACCOUNT_FOR_SCRIPT_EXECUTION: 'Specify admin account for script execution:',
    SPECIFY_JOB_DURATION_THRESHOLDS_AND: 'Specify job duration thresholds and define severity for this alarm',
    SPECIFY_JOB_NAMES_AND_JOB: 'Specify job names and job state you want to monitor',
    SPECIFY_POLICY_NAMES: 'Specify policy names and policy state you want to monitor',
    SPECIFY_BATCH_OR_POWERSHELL_SCRIPT_LOCATION: 'Specify batch or PowerShell script location on the managed ' +
        'computer that should be executed as a post-alarm action. You can pass these parameters to the executed ' +
        'script when necessary: %AlarmName% , %NewState% , %Time% , %ObjectName%',
    SPECIFY_BATCH_OR_POWERSHELL_SCRIPT_ON_VSPC: 'Specify batch or PowerShell script location on the backup portal ' +
        'server that should be executed as a post-alarm action. You can pass these parameters to the executed ' +
        'script when necessary: %AlarmName% , %NewState% , %Time% , %ObjectName%',
    SPECIFY_EXPIRATION_TIME_PERIOD: 'Specify expiration time period, and assign alarm severity',
    SPECIFY_BACKUP_REPOSITORY_NAME: 'Specify backup repository name',
    SPECIFY_THE_REQUIRED_POST_UPDATE_ACTION: 'Specify the required post-update action ' +
        'and select files to upload to the remote computer.',
    SPECIFY_THE_THRESHOLD_FOR_VM_BACKUPS: 'Specify the threshold for VM backups stored in the cloud repository and define its severity',
    SPECIFY_THE_THRESHOLD_FOR_MICROSOFT: 'Specify the threshold for Microsoft 365 user backups ' +
        'stored in the cloud repository and define its severity',
    SPECIFY_THE_NUMBER_OF_VMS: 'Specify the number of VMs that can be stored in the backup repository',
    SPECIFY_THE_NUMBER_FAILED_LOGON: 'Specify the number failed logon attempts and user suspend time',
    SPECIFY_THE_THRESHOLD_FOR_LICENSE_USAGE: 'Specify the threshold for license usage',
    SPECIFY_THRESHOLD_FOR_THE_NUMBER: 'Specify threshold for the number of managed companies (tenants) and define rules severity',
    SPECIFY_THRESHOLD_FOR_THE_NUMBER_TENANTS: 'Specify threshold for the number of tenants per hardware plan and define rules severity',
    SPECIFY_THRESHOLD_FOR_THE_NUMBER_BACKUP: 'Specify threshold for the number of managed backup agents running in the ' +
        'workstation mode and define rules severity',
    SPECIFY_THRESHOLD_FOR_THE_NUMBER_SERVER: 'Specify threshold for the number of managed backup agents running in ' +
        'the server mode and define rules severity',
    SPECIFY_THRESHOLD_FOR_WORKSTATION: 'Specify threshold for the number of workstation backups stored in a cloud repository',
    SPECIFY_THRESHOLD_FOR_CLOUD: 'Specify threshold for the number of server backups stored in a cloud repository',
    SPECIFY_EMAIL_SETTINGS_TO_USE_WHEN_SENDING_INVOICES: 'Specify email settings to use when sending invoices',
    SPECIFY_MANAGEMENT_AGENT_SETTINGS: 'Specify management agent settings',
    SPECIFY_MAXIMUM_ALLOWED_TIME: 'Specify maximum allowed time for job to be in a disabled state',
    SELECT_REGION_AND_LOG_STORAGE: 'Select region and log storage location for the support case.',
    SELECT_REGION: 'Select region',
    SPECIAL_CHARACTERS_CANNOT_BE_USED_FOR_BACKUP_POLICY: 'Special characters cannot be used for the backup policy name.',
    SPECIFY_CLOUD_REPOSITORY_QUOTA: 'Specify cloud repository quota usage and assign alarm severity',
    SPECIFY_DOWNLOAD_TRAFFIC_QUOTA: 'Specify download traffic quota and assign alarm severity',
    SPECIFY_DOWNLOAD_TRAFFIC_QUOTA_USAGE: 'Specify download traffic quota usage, and assign alarm severity',
    SPECIFY_NAME_AND_DESCRIPTION: 'Specify name and description of your backup policy',
    SPECIFY_JOB_STATE_AND_DEFINE: 'Specify job state and define severity for the alarm',
    SPECIFY_PLAN_STATE_AND_DEFINE: 'Specify plan state and define severity for this alarm',
    SPECIFY_THRESHOLD_FOR_DELETED_BACKUPS: 'Specify threshold for deleted backups recycle bin quota and define severity for the alarm.',
    SPECIFIED_VALUE_IS_INVALID: 'Specified value is invalid',
    SPECIFY_KEYWORDS_EMAIL_SUBJECT_INCLUDES_NBD: '[NBD] Specify keywords email subject includes and timings',
    SHARED_FOLDER: 'Shared Folder',
    SCRIPTS: 'Scripts',
    START_NOW: 'Start {0} now',
    SKIP_BACKUP: 'Skip backup',
    SCRIPT_SETTINGS: 'Script Settings',
    SCRIPTS_THAT_RUN_AFTER_SNAPSHOT: 'Scripts that run on the remote computer before and after the volume snapshot is created',
    SCRIPTS_THAT_RUN_AFTER_BACKUP: 'Scripts that run on the remote computer before and after the backup job session',
    SNAPSHOT_ONLY_JOB: 'Snapshot-only job',
    SNAPSHOT_SIZE: 'Snapshot Size',
    SCRIPT_PROCESSING: 'Script processing',
    SETTINGS_HAVE_NOT_BEEN_SAVED: 'Settings haven\'t been saved. All changes will be lost. Do you want to leave this page?',
    START_DATE: 'Start Date',
    START_TIME: 'Start Time',
    STATIC_DISCOVERY_DEFINED_BY_THE: 'Static discovery defined by the network IP range.',
    START_JOB: 'Start Job',
    STOP_JOB: 'Stop Job',
    SET_PRICE_FOR_THE_CLOUD_STORAGE: 'Set price for the cloud storage, compute resources and data transfer out and' +
        ' specify services provided free of charge.',
    SET_PRICE_FOR_CONSUMED_STORAGE_SPACE_IN_THE_CLOUD: 'Set price for consumed storage space in the capacity tier of your scale-out backup ' +
        'repository',
    SET_PRICE_FOR_CONSUMED_STORAGE_SPACE_IN_THE_ARCHIVE: 'Set price for consumed storage space in the archive tier of your scale-out backup ' +
        'repository',
    SET_RULES_AND_DEFINE_SEVERITY: 'Set rules and define severity for the alarm',
    SET_THRESHOLDS_FOR_VCPU: 'Set thresholds for vCPU ratio quota and define its severity',
    SET_THRESHOLDS_FOR_HOST: 'Set thresholds for host memory quota and define its severity',
    SET_THRESHOLDS_FOR_STORAGE: 'Set thresholds for storage quota and define its severity',
    SWITCH_TO_FULL_ADMIN_ACCESS: 'Switch to Full Admin Access',
    SWITCH_TO_READ_ONLY_UI: 'Switch to Read-only UI',
    SWITCH_TO_MANAGED_MODE: 'Switch to Managed Mode',
    SWITCH_TO_UNMANAGED_MODE: 'Switch to Unmanaged Mode',
    SELECT_ALL: 'Select All',
    SPECIFY_ISSUE_SUMMARY: 'Specify issue summary and detailed description.',
    SELECT_THE_TIME_PERIOD_FOR_COLLECTING_LOGS_TO_BE_DOWNLOADED: 'Select the time period for collecting logs to be downloaded',
    SUBMITTED_TO_VEEAM: 'Submitted To Veeam',
    SURE_BACKUP: 'SureBackup',
    SPECIFY_AN_ARBITRARY_BACKUP_SCOPE: 'Specify an arbitrary backup scope.',
    SQL_SERVER_CONNECTION: 'SQL Server Connection',
    SQL_SERVER_AUTHENTICATION: 'SQL Server Authentication',
    SQL_SERVER_AND_INSTANCE_FOR_MICROSOFT_SQL: 'SQL server and instance (for Microsoft SQL) or SQL server and port (for PostgreSQL) where the '
        + 'configuration database will be deployed. Note that if you want to create a new SQL instance, you can only connect to a local host',
    SIMPLE_KEY: 'Simple Key',
    SIMPLE_KEY_RECOMMENDED: 'Simple Key (Recommended)',
    SYNC_INTERVAL: 'Sync interval',
    SYNC_SESSIONS: 'Sync sessions',
    SYNTHETIC_FULL_BACKUPS: 'Synthetic full backups',
    SYNTHETIC_FULL_BACKUPS_SCHEDULING: 'Synthetic full backups scheduling',
    SPECIFIED_PATH_ALREADY_EXISTS: 'Specified path already exists.',
    SEND_NOTIFICATIONS: 'Send notifications',
    SEND_NOTIFICATION_DAILY_AT_THIS_TIME: 'Send notification daily at this time',
    SELECT_APPLICATIONS_THAT_YOU_WANT_TO_DISCOVER: 'Select applications that you want to discover',
    SELECT_AT_LEAST_ONE_GFS: 'Select at least one GFS generation.',
    SELECT_AT_LEAST_ONE_ITEM: 'Select at least one item to backup.',
    SELECT_BACKUP_AGENT_INSTALLATION_OPTIONS: 'Select backup agent installation options',
    SELECT_PLATFORM_TYPE_FOR_THE: 'Select platform type for the computers to run the discovery for',
    SERVICE_ACCOUNT: 'Service Account',
    SIGN_IN_WITH: 'Sign in with the following link',
    SELECT_THE_ACCOUNT: 'Select the account',
    SELECT_PRODUCT_FOR: 'Select a product for the support case.',
    SELECT_PRODUCT: 'Select product',
    SERVICE_PROVIDER_ADMINISTRATOR: 'Service Provider Administrator',
    SERVICE_PROVIDER: 'Service provider',
    SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR: 'Service Provider Global Administrator',
    SERVICE_PROVIDER_INVOICE_AUDITOR: 'Service Provider Invoice Auditor',
    SERVICE_PROVIDER_OPERATOR: 'Service Provider Operator',
    SERVICE_PROVIDER_USER: 'Service Provider User',
    SET_A_MASTER_MANAGEMENT_AGENT: 'Set a master management agent for the selected {0}.',
    SPECIFY_ADMIN_ACCOUNT_FROM_VMWARE_CLOUD: 'Specify admin account from the VMware Cloud Director organization to be used' +
        ' to register the new backup server. The user name of the tenant account must be specified in the Organization\\Username format.',
    START_PLAN: 'Start Plan',
    START_MANAGING_VEEAM_AGENT: 'Start managing Veeam Agent',
    START_MANAGING_VEEAM_BACKUP_AND_REPLICATION: 'Start managing Veeam Backup & Replication',
    START_MANAGING_VEEAM_BACKUP_FOR_MICROSOFT_365: 'Start managing Veeam Backup for Microsoft 365',
    START_MANAGING_VEEAM_ONE: 'Start managing Veeam ONE',
    START_TO: 'Start to',
    START_TO_THE_MOST_RECENT_VM_REPLICA: 'Start to the most recent VM replica',
    START_TO_THE_LATEST_RESTORE_POINT: 'Start to the latest restore point',
    START_TIME_WITHIN_AN_HOUR: 'Start time within an hour',
    STATIC_DISCOVERY_DEFINED_BY_THE_NETWORK: 'Static discovery defined by the network IP range. Recommended for ' +
        'smaller environments without Active Directory domain.',
    START_THE_JOB_WHEN_I_CLICK_FINISH: 'Start the job when I click finish',
    SSH_PORT_MUST_BE_OPENED: 'SSH port must be opened on remote computers for discovery and backup agent deployment.',
    SUMMARY_EMAILS_ONLY: 'Summary emails only',
    SUBTENANT: 'Subtenant',
    SUBVOLUME_OR_DEV_SDA1: '/@/subvolume or /dev/sda1',
    SUPPORTED: 'Supported',
    SUPPORTED_FORMATS: 'Supported formats: plain text; HTML tags; mixed (plain text + HTML tags)',
    SESSION_START_TIME: 'Session start time',
    SESSION_START_TIME_IN_YOUR_TIME_ZONE: 'Session start time in your time zone',
    SESSION_STOP_TIME_IN_YOUR_TIME_ZONE: 'Session stop time in your time zone',
    START_DATE_SHOULD_BE_LESS_THAN: 'Start date should be less than End date',
    SPECIFY_COMMENT_FOR: 'Specify comment for the triggered alarm.',
    SERVER_TIME_SYNC_STATUS: 'Server time sync status',
    SINGLE_SIGN_ON: 'Single Sign-On',
    SECURITY_CONFIGURATION: 'Security Configuration',
    SERVICE_PROVIDER_SETTINGS: 'Service Provider Settings',
    SERVICE_PROVIDER_ADMINISTRATOR_HAS_ACCESS: 'Service Provider Administrator has access to all monitoring, reporting, and billing data ' +
        'and can perform all types of management actions for the managed companies but cannot modify or remove ' +
        'Service Provider Global Administrator.',
    SERVICE_PROVIDER_OPERATOR_CAN_PERFORM: 'Service Provider Operator can perform all kinds of management tasks for companies ' +
        'in the access scope specified for this user.',
    SERVICE_PROVIDER_USER_HAS_ACCESS: 'Service Provider User has access to the client companies monitoring data but cannot ' +
        'perform most configuration and management tasks.',
    SERVICE_PROVIDER_INVOICE_AUDITOR_HAS_ACCESS: 'Service Provider Invoice Auditor has access to the billing data for all managed companies.',
    SPECIFY_USER: 'Specify User',
    SELECT_USER_FROM_THE_LIST: 'Select user from the list.',
    SPECIFY_ACCOUNT_TYPE: 'Specify account type and select user or group from the list.',
    SELECT_COMPANY_THAT_WILL_BE_ASSIGNED: 'Select company that will be assigned to the user.',
    SERVER_MANAGEMENT: 'Server Management',
    SSO_CONFIGURATION: 'SSO Configuration',
    SSO_STATUS: 'SSO Status',
    SSO_RULES: 'SSO Rules',
    SELECTED_COMPANIES_ONLY: 'Selected companies only',
    SPECIFY_A_SECURITY_CERTIFICATE: 'Specify a security certificate to be used by the backup portal when connecting to the identity provider.',
    SPECIFY_THE_IDENTITY_PROVIDER_NAME: 'Specify the identity provider name and its settings.',
    SPECIFY_CLAIM_NAME: 'Specify claim name from the identity provider that will contain your company name',
    SPECIFY_CLAIM_NAME_FROM: 'Specify claim name from the identity provider that will correspond to the company name in the backup portal',
    SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER: 'Select companies accessible to this user.',
    SUBTENANT_IS_A_USER_WHO_HAS_CLOUD_REPOSITORY: 'Subtenant is a user who has cloud repository resources allocated and acts ' +
        'as a subtenant in Veeam Cloud Connect. This user can store backups created with the Veeam backup agent, using own credentials. ' +
        'The scope of data monitoring is limited to a computer (or computers) protected with Veeam backup agent, ' +
        'and whose data is stored on a cloud repository.',
    SPECIFY_SAML_ADVANCED_SETTINGS: 'Specify SAML advanced settings.',
    SPECIFIED_FOLDER_ALREADY_EXISTS: 'Specified folder already exists',
    SELECT_THE_IDENTITY_PROVIDER_TO_USE: 'Select the identity provider to use for authentication.',
    SELECT_A_BACKUP_PORTAL_ROLE: 'Select a backup portal role that will be assigned to a user',
    SELECT_A_BACKUP_REPOSITORY: 'Select a backup repository',
    SELECT_A_BACKUP_SERVER: 'Select a backup server',
    SELECT_A_PROXY_SERVER: 'Select a proxy server',
    SELECT_A_SERVER_FOR_WAN_ACCELERATION: 'Select a server for WAN acceleration',
    SPECIFY_LOCATIONS_THAT_WILL_BE_ACCESSIBLE: 'Specify locations that will be accessible for the user.',
    SPECIFY_DOMAIN_NAME_AND_CONNECTION_ACCOUNT: 'Specify domain name and connection account.',
    SPECIFY_CLAIM: 'Specify claim with the managed locations list',
    SPECIFY_RULE: 'Specify rule name and description.',
    SPECIFY_A_ROLE_TO_ASSIGN: 'Specify a role to assign to the user.',
    SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR_OF_A_RESELLER: 'Service Provider Global Administrator of a reseller has access to all monitoring, ' +
        'reporting, and billing data and can perform all types of management actions for the managed companies.',
    SP_ENTITY_ID_URL: 'SP entity ID URL',
    SELECT_AN_ACCOUNT_WITH_LOCAL: 'Select an account with local administrator privileges on the server you are adding.' +
        ' This account will be used to install the management agent.',
    SELECT_AN_ARCHIVE_REPOSITORY: 'Select an archive repository',
    SPECIFY_JOB_NAME_OR_LEAVE: 'Specify job name or leave the * character to include all jobs in the alarm detection.',
    SPECIFY_POLICY_NAME_OR_LEAVE: 'Specify policy name or leave the * character to include all policies in the alarm detection.',
    SPECIFY_JOB_NAMES_TO_EXCLUDE: 'Specify job names to exclude from the alarm detection.',
    STARTS_THE_DATA_COLLECTION: 'Starts the data collection task from remote servers',
    STARTS_THE_DATA_COLLECTION_TASK: 'Starts the data collection task for selected appliances',
    SPECIFY_THE_BACKUP_SERVER_SETUP_FILE_LOCATION: 'Specify the backup server setup file location',
    SPECIFY_LICENSE_FILE_TO_USE: 'Specify license file to use for the Veeam Backup & Replication server',
    SPECIFY_PATH_TO_THE_BACKUP_SERVER: 'Specify path to the backup server setup file and credentials to the remote file share',
    SPECIFY_THE_COMPANY_USER_INFO: 'Specify the company user info to connect to the Veeam Cloud Connect server',
    SELECT_A_VIRTUAL_DATA_CENTER_FOR: 'Select a virtual data center for this company to use as a target for the replication jobs.',
    SPECIFY_MAXIMUM_NUMBER_OF_TASK: 'Specify maximum number of task slots available to this company and if desired, ' +
        'limit the incoming network traffic.',
    SPECIFY_A_FRIENDLY_SERVER_NAME: 'Specify a friendly server name that will be shown to the user',
    SELECT_AN_ORGANIZATION: 'Select an organization',
    SELECT_AN_ORGANIZATION_FOR: 'Select an organization for the company',
    SELECT_AT_LEAST_ONE_LOCATION: 'Select at least one location for each company.',
    SELECT_THE_DESIRED_SUBSCRIPTION: 'Select the desired subscription plan.',
    SPECIFY_COMPANY_NAME: 'Specify company name and contact information.',
    SELECT_TYPE_FOR_THE_NEW_COMPANY: 'Select type for the new company',
    SELECT_BACKUP_REPOSITORY_AND_ASSIGN: 'Select backup repository and assign cloud storage quota.',
    SELECT_THE_VMWARE_CLOUD_DIRECTOR: 'Select the VMware Cloud Director organization to proceed',
    SELECT_GUEST_OS_CREDENTIALS_FOR_RUNNING_VMS: 'Select guest OS credentials for running VMs.',
    SEND_WELCOME_EMAIL_NOTIFICATION: 'Send welcome email notification to the client when I click Finish',
    SEND_INVOICE: 'Send Invoice',
    SERVICES: 'Services',
    SELECT_NETWORK_EXTENSION: 'Select network extension appliances.',
    SPECIFY_RESELLER_NAME: 'Specify reseller name and contact information.',
    SPECIFY_SERVICES_THAT_THE_RESELLER: 'Specify services that the reseller can provide to the companies and set quota for these services.',
    SPECIFY_REPORT_TYPE_AND_SELECT_COMPANIES: 'Specify report type and select companies to include in this report',
    SELECT_ORGANIZATIONAL_UNITS: 'Select organizational units to do the discovery for.',
    SELECT_ONE_OR_MORE_MICROSOFT_365: 'Select one or more Microsoft 365 servers available to the reseller.',
    SELECT_A_VIRTUAL_DATA_CENTER: 'Select a virtual data center',
    SPECIFY_A_SHARED_FOLDER: 'Specify a shared folder to backup to, and account to connect to a shared folder with',
    SELECT_SITE_AND_SET_QUOTA: 'Select site and set quota for managed companies',
    SET_QUOTA_FOR_THE: 'Set quota for the selected site',
    SET_QUOTA_FOR: 'Set quota for {0} selected sites',
    SCHEDULED_DEPLOYMENT: 'Scheduled Deployment',
    SPECIFY_LOGIN_INFORMATION: 'Specify login information for the selected user',
    SET_SCHEDULE: 'Set schedule...',
    SELECT_AT_LEAST_ONE_OBJECT_TO_BACK_UP: 'Select at least one object to back up.',
    SCHEDULED_0: 'Scheduled {0}',
    SELECT_A_COMPANY_TO_FILTER: 'Select a company to filter the data',
    SELECT_A_HOSTING_SITE: 'Select a hosting site and Cloud Director organization for the user.',
    SYNCING: 'Syncing',
    SELECT_OBJECTS_TO_PROCESS: 'Select objects to process',
    SELECT_OBJECTS_TO_BE_INCLUDED_IN_THE_BACKUP: 'Select objects to be included in the backup',
    SELECT_OBJECTS_TO_BE_EXCLUDED_FROM_THE_BACKUP: 'Select objects to be excluded from the backup',
    SELECT_OBJECTS_BACK_UP: 'Select objects to back up',
    SELECT_ORGANIZATION_TO_CONTINUE: 'Select organization to continue.',
    START_USING_VEEAM_BACKUP_FOR_AWS: 'Start using Veeam Backup for AWS',
    START_USING_VEEAM_BACKUP_FOR_MICROSOFT_AZURE: 'Start using Veeam Backup for Microsoft Azure',
    START_USING_VEEAM_BACKUP_FOR_MICROSOFT_365: 'Start using Veeam Backup for Microsoft365',
    START_USING_VEEAM_BACKUP_FOR_GOOGLE_CLOUD: 'Start using Veeam Backup for Google Cloud',
    SELECT_ONE_OR_MORE_WAN_ACCELERATION_SERVERS: 'Select one or more WAN acceleration servers available to the reseller.',
    STORED_WORKLOADS: 'Stored Workloads',
    SPECIFY_MAXIMUM_NUMBER_OF_TASK_SLOTS: 'Specify maximum number of task slots available to the companies' +
        ' managed by the reseller and if desired, limit the incoming network traffic.',
    SERVER_BACKUPS: 'Server Backups',
    SELECT_WHERE_TO_GET_THE_LICENSE_KEY: 'Select where to get the license key from',
    SSL_ON_CONNECT: 'SSL on connect',
    START_TLS: 'StartTLS',
    START_TLS_WHEN_AVAILABLE: 'StartTLS when available',
    SET_THE_DEFAULT_SENDER: 'Set the default sender email address',
    SUREBACKUP: 'SureBackup',
    SOME_OF_THE_REMOTE_MANAGEMENT_AGENTS: 'Some of the remote management agents will not be deleted automatically due to the following reasons',
    SHARED_MAILBOX: 'Shared Mailbox',
    SELECT_A_BACKUP_REPOSITORY_TO_PROCEED: 'Select a backup repository to proceed.',
    SELECT_OBJECTS_TO_INCLUDE: 'Select objects to include in the backup.',
    SELECT_AN_ACCOUNT: 'Select an account with local administrator privileges on the remote server. ' +
        'This account will be used to install the backup server. Use DOMAIN\\USER format for domain accounts, or HOST\\USER for local accounts.',
    SELECT_AN_EXISTING_COMPANY_TO_ASSIGN_THE_JOB_TO: 'Select an existing company to assign the job to.',
    SETTING_LIMIT_FOR_USERS: 'Setting limit for users is not available for archive repositories.',
    SELECT_A_REPOSITORY_TYPE: 'Select a repository type',
    STANDARD_STORAGE: 'Standard storage',
    STANDARD_STORAGE_FREE_OF_CHARGE: 'Standard storage free of charge',
    SUPPORT_CASE: 'Support case',
    SUBMITTED_FILES_HAVE_BEEN_UPLOADED: 'Submitted files have been uploaded to Veeam servers.',
    SERVER_AGENTS_QUOTA: 'Server agents quota will be increased by {0}.',
    SET_COMPANY_LOCATION: 'Set Company Location',
    SPECIFY_COUNTRY: 'Specify country...',
    SPECIFY_REGION: 'Specify region...',
    SPECIFY_COUNTRY_AND_REGION: 'Specify country and region...',
    STANDARD: 'Standard',
    STANDARD_EDITION: 'Standard Edition',
    SELECT_AN_EDITION_YOU_WANT: 'Select an edition you want to set a price for:',
    STATE_REGION: 'State/Region',
    SELECT_A_BACKUP_SERVER_TO_ASSIGN: 'Select a backup server to assign to the company.',
    STANDARD_ACCOUNT: 'Standard account',
    SPECIFY_TARGET_BACKUP_REPOSITORY: 'Specify target backup repository',
    SPECIFY_RETENTION_POLICY_SETTINGS: 'Specify retention policy settings',
    SPECIFY_APPLICATION_AWARE_PROCESSING_SETTINGS_FOR_INDIVIDUAL_OBJECTS: 'Specify application-aware processing settings for individual objects',
    SPECIFY_GUEST_FILE_SYSTEM_INDEXING_SETTINGS_FOR_INDIVIDUAL_OBJECTS: 'Specify guest file system indexing settings for individual objects',
    SWAGGER_UI: 'SwaggerUI',
    SELECT_INSTANCES_TO_PROTECT_OR_EXCLUDE: 'Select instances to protect or exclude',

    TAG: 'Tag',
    TAG_NAME_CANNOT_BE_EMPTY: 'Tag name cannot be empty',
    TAPE_MEDIA_POOL: 'Tape Media Pool',
    TARGET_HOST: 'Target Host',
    TARGET: 'Target',
    TARGET_REPOSITORY: 'Target Repository',
    TASKS: 'Tasks',
    TASK_SESSION_DETAILS: 'Task Session Details',
    TAX_ID: 'Tax ID',
    TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_GUEST_CATALOG_SERVICE: 'TCP port that will be used by the Veeam Guest Catalog Service',
    TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_BACKUP_SERVICE: 'TCP port that will be used by the Veeam Backup Service',
    TEAM: 'Team',
    TEAMS: 'Teams',
    TEMPLATE: 'Template',
    TEMPLATES: 'Templates',
    THE_CONFIGURED_CHANGES_WILL_NOT: 'The configured changes will not be saved.\n\nDo you want leave site?',
    THE_IMAGE_SHOULD_BE_IN_PNG_FORMAT: 'The image should be in PNG format with the max dimensions of {0} pixels.',
    THE_RESTORE_PORTAL_IS_NOT_CONFIGURED: 'The restore portal is not configured on the managed backup server. '
        + 'Configure this portal or contact your service provider for assistance',
    THIS_NAME_WILL_BE_DISPLAYED: 'This name will be displayed in the web browser and on the login page.',
    THIS_FEATURE_ALLOWS_RESELLER_TO_PERFORM: 'This feature allows reseller to perform file-level restore operations for managed backup agents '
        + 'in the backup portal. To enable this feature for the reseller, you must enable it globally in the Plugin Library.',
    THIS_VERIFICATION_ALSO_DOWNLOADS_THE_LATEST_VERSIONS: 'This verification also downloads the latest versions of backup agents to be used during ' +
    'the deployment process.\nTo download available patches or upgrades for the Veeam Service Provider Console, ' +
    'follow the instructions provided in the corresponding notifications.',
    TIME: 'Time',
    TIMES: 'times',
    THE_FILE_UPLOAD_OPERATION_HAS_BEEN: 'The files upload operation has been cancelled',
    TERMINATE_ALL_ACTIVITIES_AUTOMATICALLY: 'Terminate all activities automatically before install',
    TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW: 'Terminate job if it exceeds allowed backup window',
    TEST_EMAIL_NOTIFICATION: 'Test email notification',
    TEST_EMAIL_NOTIFICATION_HAS_BEEN_SENT: 'Test email notification has been sent.',
    TIME_PERIOD: 'Time period',
    TIME_PERIODS: 'Time periods',
    TIME_ZONE: 'Time zone',
    TITLE: 'Title',
    TRENDS: 'Trends',
    TRIGGERED_ALARMS: 'Triggered Alarms',
    TYPE_IN_A_NAME_AND_DESCRIPTION: 'Type in a name and description for this subscription plan.',
    TYPE_IN_CREDENTIALS: 'Type in credentials',
    TYPE_IN_YOUR_COMPANY_ADDRESS: 'Type in your company address and other information to be used in the billing reports.',
    TYPE_THE_COMPANY_NAME_IN_THE_FIELD_FOR_ADVANCED_SEARCH_OPTIONS: 'Type the company name in the field for advanced search options.',
    TRY_APPLICATION_PROCESSING: 'Try application processing, but ignore failures',
    TRYING_TO_RECONNECT: 'Trying to connect',
    TYPE: 'Type',
    TRIGGER_TIME_PERIOD_NBD: '[NBD] Trigger Time Period',
    TRANSFERRED: 'Transferred',
    TRANSFERRED_DATA: 'Transferred Data',
    TRANSFERRED_FILES: 'Transferred Files',
    TRUST_ALL_COMPUTERS_FROM: 'Trust all computers from the IP range',
    TRUST_COMPUTERS_FROM_THE_KNOWN: 'Trust computers from the known hosts list only:',
    TO_DO_THIS_CONTACT_YOUR_SERVICE_PROVIDER: 'To do this, contact your service provider.',
    TO_DO_THIS_SET_THE_COMPANY_NAME: 'To do this, set the company name for the management ' +
        'agent on the Discovered Computers -> Computers tab.',
    TO_VIEW_THE_DATA: '{0} to view the data',
    TODAY: 'Today',
    TOKEN_EXPIRY_PERIOD: 'Token expiry period',
    TOTAL: 'Total',
    TOTAL_AGENTS: 'Total Agents',
    TOTAL_BACKUP_SIZE: 'Total Backup Size',
    TOTAL_COMPUTERS: 'Total Computers',
    TOTAL_DATA: 'Total Data',
    TOTAL_NUMBER_OF_WORKLOADS_MANAGED_FOR: 'Total number of workloads managed for the previous month is',
    TOTAL_N_OF_TENANTS: 'Total N. of Tenants',
    TOTAL_POINTS: 'Total Points',
    TOTAL_SIZE: 'Total Size',
    TOTAL_WORKLOADS: 'Total workloads',
    THIS_IMAGE_WILL_BE_SHOWN_IN_THE_LOWER: 'This image will be shown in the lower right corner of the login page.',
    THIS_IMAGE_WILL_BE_SHOWN: 'This image will be shown as a portal logo on the login page.',
    THE_SELECTED_AGENT_IS_NOT: 'The selected agent is not up-to-date. To run the discovery rule, update this agent ' +
        'to the latest version.',
    THE_SELECTED_AGENTS_ARE_NOT: 'The selected agents are not up-to-date. To run the discovery rules, update these ' +
        'agents to the latest version.',
    THE_SELECTED_AGENT_IS_OFFLINE: 'The selected agent is offline or not running. To run the discovery rule, make ' +
        'sure this agent is online.',
    THE_SELECTED_AGENTS_ARE_OFFLINE: 'The selected agents are offline or not running. To run the discovery rules, ' +
        'make sure these agents are online.',
    THE_PRIVATE_PART_IS_ONLY_AVAILABLE: 'The private part is only available at the time when key is created.<br />' +
        'Please save it for future use.',
    THE_SPECIFIED_FILE_IS_INVALID: 'The specified file is invalid',
    THE_SPECIFIED_MASK_IS_INVALID: 'The specified mask is invalid',
    THIS_SUBSCRIPTION_PLAN_IS_USED: 'This subscription plan is used by "{0}" reseller company.<br />Do you want to proceed with the changes?',
    THIS_DAY: 'This day',
    THIRD: 'Third',
    THIS_EMAIL_WILL_BE_USED_IN_WELCOME: 'This email will be used in Welcome emails and in ' +
        'notification messages about user creation.',
    TO_RESOLVE_THAT_ADMINISTRATORS_OF_THIS_COMPANY: 'To resolve that, administrators of this company need to start using other' +
        ' authentication methods.',
    TO_RESOLVE_THAT_ALL_INTEGRATIONS_NEED_TO: 'To resolve that, all integrations need to switch to another authentication method.',
    TO_RESOLVE_THAT_RESELLERS_AND_HIS_MANAGED: 'To resolve that, reseller and his managed companies need to start using other' +
        ' authentication methods.',
    TO_RESOLVE_THAT_THIS_CLIENT_NEEDS_TO_START: 'To resolve that, this client needs to start using other authentication methods.',
    TO_RESOLVE_THAT_THIS_CLIENT_NEEDS_TO_START_USING: 'To resolve that, this client needs to start using other authentication' +
        ' methods for the integration.',
    TO_RESOLVE_THAT_THIS_USER_NEEDS_TO_START: 'To resolve that, this user needs to start using other authentication methods for the integration.',
    TO_RESOLVE_THAT_YOU_NEED_TO_START_USING: 'To resolve that, you need to start using other authentication methods.',
    THIS_WILL_PUT_YOUR_BACKUP_PORTAL_INTO_MAINTENANCE_MODE: 'This will put your backup portal into maintenance mode. '
        + 'When maintenance mode is enabled, remote users will not be able to log in to the portal and review their usage data.'
        + '<br /><br />To disable maintenance mode you will have to access the backup portal via the localhost address.'
        + '<br /><br />Do you want to proceed?',
    THIS_WILL_REVERT_ALARM: 'This will revert alarm to its default values. Do you want to proceed?',
    THIS_ACTION_WILL_UNINSTALL: 'This action will uninstall management agents from remote computers.',
    TOP_COMPANIES: 'Top Companies',
    TOP_COMPANIES_BY_PAYMENT: 'Top Companies by Payment',
    TOP_CLOUD_REPOSITORIES_BY_USED_SPACE: 'Top Cloud Repositories by Used Space',
    TOP_HARDWARE_PLANS_BY_SUBSCRIBED_COMPANIES: 'Top Hardware Plans by Subscribed Companies',
    TOP_COMPANIES_BY_MANAGED_BACKUP_AGENTS: 'Top Companies by Managed Backup Agents',
    TOP_COMPANIES_BY_USED_SPACE: 'Top Companies by Used Space',
    TOP_COMPANIES_BY_PROTECTED_VMS: 'Top Companies by Protected VMs',
    TOP_COMPANIES_BY_DATA_TRANSFER_OUT: 'Top Companies by Data Transfer Out',
    TOP_COMPANIES_BY_REPLICATED_VMS_TO_THE_CLOUD: 'Top Companies by Replicated VMs to the Cloud',
    TOP_COMPANIES_BY_TRAFFIC: 'Top Companies by Traffic',
    TOP_COMPANIES_BY_TRANSFERRED_DATA: 'Top Companies by Transferred Data',
    TOP_COMPANIES_BY_PROTECTED_UNSTRUCTURED_DATA: 'Top Companies by Protected Unstructured Data',
    TOP_COMPANIES_BY_MICROSOFT_365_USERS: 'Top Companies by Microsoft 365 Users',
    TOP_COMPANIES_BY_MICROSOFT_365_GROUPS: 'Top Companies by Microsoft 365 Groups',
    TOP_COMPANIES_BY_PROTECTED_CLOUD_WORKLOADS: 'Top Companies by Protected Cloud Workloads',
    TOP_COMPANIES_BY_MONITORED_WORKLOADS: 'Top Companies by Monitored Workloads',
    TO_SELECT_BACKUP_JOBS: 'To select backup jobs, select a backup server on the previous step of the wizard.',
    TO_SELECT_BACKUP_POLICIES: 'To select backup policies, select a cloud backup appliance on the previous step of the wizard.',
    TOP_VM_JOBS_BY_AVG_DURATION: 'Top VM Jobs by Avg. Duration',
    TOP_JOBS_BY_PROCESSED_OBJECTS: 'Top Jobs by Processed Objects',
    TOP_REPOSITORIES_BY_USED_SPACE: 'Top Repositories by Used Space',
    TOP_USED_HARDWARE_PLANS_BY_COMPANIES: 'Top Used Hardware Plans by Companies',
    TOP_RENTAL_BACKUP_SERVERS_BY_CONSUMED_POINTS: 'Top Rental Backup Servers by Consumed Points',
    TOP_CLOUD_CONNECT_SERVERS_BY_TENANTS: 'Top Cloud Connect Servers by Tenants',
    TOP_CLOUD_CONNECT_SERVERS_BY_CONSUMED_POINTS: 'Top Cloud Connect Servers by Consumed Points',
    TOLERANCE: 'Tolerance',
    TENANT_CONFIGURATION: 'Tenant configuration',
    TENANT_MANAGEMENT: 'Tenant Management',
    TENANT_MANAGEMENT_IN_CLOUD_CONNECT: 'Tenant Management in Cloud Connect',
    TB: 'TB',
    THIS: 'this',
    THE_FIELD_IS_REQUIRED: 'The field is required',
    THE_FOLLOWING_USER_ACCOUNT: 'The following user account:',
    THIS_WILL_CANCEL_THE_UPLOAD: 'This will cancel the upload operation. Do you want to proceed?',
    THE_FOLLOWING_VARIABLES_FOR_PORTAL_ADMINS: '%Portal Admins% — to send a notification to users with the Portal ' +
        'Administrator role.\n',
    THE_FOLLOWING_VARIABLES_FOR_OPERATORS: '%Operators% — to send a notification to users with the Portal Operator role.\n',
    THE_FOLLOWING_VARIABLES_FOR_READONLY_USERS: '%Readonly Users% — to send a notification to users with the Read-only User role.\n',
    THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER: '%Company Owner% — to send a notification to users with the Company Owner role.\n',
    THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS: '%Company Admins% — to send a notification to users with the Company Administrator ' +
        'and Company Owners roles.\n',
    THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS: '%Location Admins% — to send a notification to users with the Location Administrator role.\n',
    THE_FOLLOWING_VARIABLES_FOR_RESELLERS: '%Reseller Owner% — to send a notification to ' +
        'users with the Service Provider Global Administrator role.\n',
    THE_FOLLOWING_VARIABLES_FOR_RESELLERS_EMAIL: '%Reseller Email% — to send a notification to the reseller email.\n',
    THE_FOLLOWING_VARIABLES_FOR_RESELLERS_ADMINS: '%Reseller Admins% — to send a notification to users with the Service Provider ' +
        'Administrator and Service Provider Global Administrator roles.\n',
    THE_FOLLOWING_VARIABLES_FOR_RESELLERS_USERS: '%Reseller Users% — to send a notification to users with the Service Provider ' +
        'User role.\n',
    THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS: '%Reseller Operators% — to send a notification to users with the Service ' +
        'Provider Operator role.\n',
    TO_SEND_A_NOTIFICATION_TO_EMAIL_ADDRESS_SPECIFIED: '%Company Email% — to send a notification to email address specified in ' +
        'a managed company contact information.\n',
    THE_AMOUNT_OF_TRANSFERRED_DATA_NBD: '[NBD] The amount of transferred data',
    THE_PATCH_OPERATION_HAS_STARTED: 'The patch operation has started. Depending on the number of agents, this operation can take some time.',
    THE_UPGRADE_NOW_OPTION_OVERRIDES: 'The "Upgrade now" option overrides any previously configured schedule',
    THIS_WILL_ATTEMPT_TO_INSTALL: 'This will attempt to install and perform the initial configuration of' +
        ' Veeam backup agents after management agents are verified in the portal.',
    AUDIT: 'Audit',
    AUTO_DEPLOYMENT_SETTINGS_WILL_BE_APPLIED: 'Auto deployment settings will be applied to all management agents ' +
        'that were deployed manually or via 3rd party automation tools. Use rules settings, to automatically deploy ' +
        'backup agents detected by built-in discovery rules.',
    THIS_OPTION_IS_RECOMMENDED_FOR_MAXIMUM: 'This option is recommended for maximum flexibility of job creation.',
    THIS_SPECIFIED_DAY_IS_NO_PRESENT: 'This specified day is no present in any of the selected months',
    THIS_SUBSCRIPTION_PLAN_IS_USED_BY: 'This subscription plan is used by "{0}" reseller company.\nDo you want to proceed with the changes?',
    THE_SPECIFIED_DAY_IS_NO_PRESENT: 'The specified day is not present in any of the selected months',
    THE_SPECIFIED_MASK_OR_FOLDER: 'The specified {0} mask or folder is already added.',
    THIS_ACTION_WILL_DELETE_ALL: 'This action will delete all records for selected companies and will perform the following actions:',
    THESE_JOBS: 'these jobs',
    TO_ASSIGN_A_CASE: 'To assign a case or license administrator role to your account, check',
    THIS_KB_ARTICLE: 'this KB article',
    THIS_JOB: 'this job',
    THESE: 'these',
    THUMBPRINT: 'Thumbprint',
    THE_LEAST_LOADED_CLOUD_GATEWAY_NOT: 'The least loaded cloud gateway not belonging to any gateway pool will be selected ' +
        'each time the tenant connects.',
    THE_LEAST_LOADED_CLOUD_GATEWAY_FROM: 'The least loaded cloud gateway from the selected gateway pool will be selected ' +
        'each time the tenant connects.',
    THE_LICENSE_KEY_WILL_NOW_BE_DOWNLOADED: 'The license key will now be downloaded to your computer.',
    THE_VALUE_IN_THIS_FIELD_MUST_BE_NUMERIC: 'The value in this field must be numeric',
    TRUNCATE_LOGS: 'Truncate logs',
    TRUNCATE_LOGS_PREVENTS_LOGS: 'Truncate logs (prevents logs from growing forever)',
    TRANSACTION_LOG_BACKUP_DOES_NOT_SUPPORT_LOCAL_BACKUP_CACHE: 'Transaction log backup does not support local backup cache ' +
        'as a temporary target. ' +
        'Please disable backup cache feature or turn off transaction log backup.',
    TRANSACTION_LOG_BACKUP_DOES_NOT_SUPPORT_VEEAM_CLOUD_CONNECT_REPOSITORY: 'Transaction log backup does not support ' +
        'Veeam Cloud Connect repository as the target. ' +
        'Please specify another backup destination.',
    TO_SPECIFY_FILE_INCLUSION_AND_EXCLUSION: 'To specify file inclusion and exclusion settings, click',
    THIRD_PARTY_INTEGRATIONS: '3<sup>rd</sup> party integrations',
    THIS_ACTION_FORBIDS_EDITING_TENANT: 'This action forbids editing tenants on the Cloud Connect server. '
        + 'Tenant management will be possible only through Veeam Service Provider Console (recommended).',
    THIS_ACTION_WILL_ALSO_DELETE_ALL_HISTORICAL_DATA: 'This action will also delete all historical data collected from selected backup agents. ' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_DROP_A_CONNECTION: 'This action will drop a connection to the backup portal for selected management agents. ' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_ENABLE_MAINTENANCE_MODE: 'This action will enable maintenance mode for the Cloud Connect server. ' +
        'All active backup job connections will be dropped, and users will not be able to start new jobs targeted to the cloud repository. ' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_ENFORCE_MFA_FOR_SELECTED: 'This action will enforce multi-factor authentication for the selected {0}.',
    THIS_ACTION_WILL_ENFORCE_MFA_FOR_ALL_USERS: 'This action will enforce multi-factor authentication for all users in the selected {0}.',
    THIS_ACTION_WILL_DISABLE_MFA_FOR_SELECTED: 'This action will disable multi-factor authentication for the selected {0}.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_RESET: 'This action will reset multi-factor authentication config for the selected {0}.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_THE_SELECTED: 'This action will remove the selected {0} from the reseller and' +
        ' the reseller will not be able to manage {1} {0}.\n\nDo you want to proceed?',
    THIS_ACTION_WILL_REMOVE_VMWARE_CLOUD_DIRECTOR: 'This action will remove VMware Cloud Director resources available for this reseller.\n\n' +
        'Do you want to proceed?',
    THIS_LINK: 'this link',
    THIS_RULE_MONITORS_MANAGED_VMS: 'This rule monitors managed VMs number and raises an alarm when licensed<br />VM number is above the limit',
    THIS_OPERATION_WILL_ATTEMPT_TO_INSTALL: 'This operation will attempt to install and perform initial configuration ' +
        'of Veeam backup agents',
    THIS_QUOTA_IS_ALSO_BY_SUBTENANTS: 'This quota is also used by sub-tenants of this company. ' +
        'Please update subtenants settings with a new quota to continue running backup jobs.',
    THIS_REPOSITORY_IS_SET_AS_DEFAULT: 'This repository is set as default. ' +
        'If you want to remove this repository, you must first change the default repository.',
    THIS_ACTION_WILL_UNASSIGN: 'This action will unassign the selected subscription plan from all companies. Do you want to proceed?',
    THIS_WILL_DELETE_THE_BACKUP: 'This will delete the backup {0} from the backup agent.',
    THIS_WILL_DELETE_THE_SELECTED_DISCOVERY_RULE: 'This will delete the selected discovery rule. All its computers will be assigned to' +
        ' the external discovery rule.\n\nDo you want to proceed?',
    THIS_WILL_ENABLE_TENANT_MANAGEMENT_CAPABILITIES: 'This will enable tenant management capabilities on Cloud Connect servers and' +
        ' the Veeam Service Provider Console.',
    THIS_WILL_REBOOT_THE_SELECTED_COMPUTER: 'This will reboot the selected computer. Complete all ongoing tasks on that ' +
        'server before restarting the server.\nDo you want to proceed?',
    THIS_WILL_REBOOT_THE_SELECTED_COMPUTERS: 'This will reboot the selected computers. Make sure to save all files before restarting. ' +
        'Do you want to proceed?',
    THIS_WILL_REBOOT_THE_SELECTED: 'This will reboot the selected computers. Make sure there are no ' +
        'active users logged in to these computers before restarting. Do you want to proceed?',
    THIS_WILL_REMOVE_ALL_RECORDS: 'This will remove all records about this alarm from the database.\n\n' +
        'Do you also want to delete alarm in the client portal?',
    THIS_WILL_REMOVE_ALL_RECORDS_DO_YOU_WANT_PROCEED: 'This will remove all records about this alarm from the database.\n\n' +
        'Do you want to proceed?',
    THIS_WILL_REMOVE_ALL_CLOUD_RESOURCES: 'This will remove all cloud resources and assigned companies from Resellers. ' +
        'To resolve this, reassign required resources and companies to Resellers manually after you complete the migration process.\n\n' +
        'Do you want to proceed?',
    THIS_WILL_REMOVE_THE_SELECTED_CLOUD_CONNECT: 'This will remove the selected Cloud Connect server from the backup portal. ' +
        'Tenant accounts registered on the Cloud Connect server will not be deleted. ' +
        'This will also uninstall management agents from all managed computers.\n\n Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_ALL_SELECTED_JOBS: 'This action will remove all selected backup jobs.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_SELECTED_CREDENTIALS: 'This action will remove selected credentials.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_ALL_SELECTED_BACKUP_POLICIES: 'This action will remove all selected backup policies.\n\n' +
        'Do you want to proceed?',
    THIS_WILL_RESET_BACKUP_SERVER_INSTALLATION_ID: 'This will reset backup server installation ID.' +
        ' During this operation backup server will be inaccessible.\n\nDo you want to proceed?',
    THIS_WILL_UNINSTALL_MANAGEMENT_AGENTS_FROM: 'This will uninstall management agents from the remote computers. ' +
        'All backup agents running on these computers will be switched to the free mode.',
    THIS_WILL_UNINSTALL_MANAGEMENT_AGENT_FROM: 'This will uninstall management agent from the remote computer.',
    THIS_USERNAME_IS_ALREADY_USED: 'This username is already used',
    THIS_LOGIN_IS_ALREADY_USED: 'This login is already used',
    THIS_OPTION_ALLOWS_REUSING_AN_EXISTING: 'This option allows re-using an existing job policy as a source for the new job.',
    THIS_WILL_START_THE_RECOLLECTION: 'This will start the recollection of all data from managed {0}. ' +
        'Depending on the available data, this operation can take a considerable period of time.\n\n' +
        'Do you want to proceed?',
    THIS_WILL_START_THE_RECOLLECTION_VBO: 'This will start the recollection of all data from managed server. ' +
        'Depending on the available data, this operation can take a considerable period of time.\n' +
        'Microsoft 365 SLA statistics will be reset.\n\n' +
        'Do you want to proceed?',
    THIS_WILL_START_THE_RECOLLECTION_APPLIANCE: 'This will start the recollection of all data for the managed backup appliance. ' +
        'Depending on the available data, this operation can take a considerable period of time.\n\n' +
        'Do you want to proceed?',
    THERE_ARE_NO_WORKLOADS: 'There are no workloads to include in the usage report',
    THERE_IS_NO_USER_WITH_SUCH: 'There is no user with such name in the company',
    THROTTLING: 'Throttling',
    TARGET_PRODUCTION_RESPONSE_SLA: 'Target Production Response SLA',
    TARGET_BASIC_RESPONSE_SLA: 'Target Basic Response SLA',
    THIS_CASE_INVOLVES_RANSOMWARE: 'The case involves ransomware mitigation or impact',
    THE_UPDATE_NOW_OPTION_OVERRIDES: 'The "Update now" option overrides any previously configured schedule.',
    THE_SELECTED_REPORT_SCOPE: 'The selected report scope includes disabled or expired companies.',
    THROTTLE_BACKUP_ACTIVITY_WHEN_SYSTEM_IS_BUSY: 'Throttle backup activity when system is busy',
    THIS_FIELD_IS_REQUIRED: 'This field is required',
    THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS: 'This field should be an email address in the format "user@example.com"',
    THIS_FIELD_SHOULD_BE_IN_THE_URL: 'This field should be in the URL format, for example: https://vspc.com',
    THIS_FRIENDLY_NAME: 'This friendly name is already in use',
    THIS_WELCOME_PAGE_WILL_GUIDE: 'This welcome page will guide you through the initial configuration steps.',
    THIS_SHARE_REQUIRES_ACCESS_CREDENTIALS: 'This share requires access credentials. If no user credentials are ' +
        'specified, backup job will use Active Directory computer account or account configured via Backup Agent UI instead.',
    THE_CERTIFICATE_CREATED_BY_THIS: 'The certificate created by this feature will not originate from a trusted certification authority (CA). ' +
        'Veeam Service Provider Console users will be notified about this fact when establishing the initial connection to your service, ' +
        'and provided with the ability to verify the certificate with the thumbprint.',
    THE_CHOSEN_COMPANY_WILL_BE_SET_FOR_THE_SELECTED_AGENTS: 'The chosen company will be set for the selected agents.' +
        ' When the company is set, the agents will be activated and will be able to connect to the backup portal.',
    THE_CONNECTION_FROM_THE_MANAGEMENT_AGENTS: 'The connection from management agents installed on the remote computers {0} will be declined.',
    THE_CONNECTION_FROM_THE_MANAGEMENT_AGENT: 'The connection from the management agent installed on the remote computer {0} will be declined.',
    THE_NETWORK_EXTENSION_APPLIANCE: `The network extension appliance will be deployed to the tenant environment. Skip this if you are <br />
                                    already using a 3rd party solution like VMware NSX Edge to manage networking during failover`,
    THE_DISCOVERY_PROCESS_CAN_TAKE: 'The discovery process can take considerable time when using this IP range. '
        + 'It is recommended to use smaller IP range in the rule settings.',
    THE_MAXIMUM_LENGTH_IS: 'The maximum length is {0}',
    THE_MANAGEMENT_AGENTS_WILL_BE_UNINSTALLED: 'The management agents will be uninstalled from the remote computers. ' +
        'All other Veeam products installed on the remote computers will be removed from the managed infrastructure.',
    THE_MANAGEMENT_AGENT_WILL_BE_UNINSTALLED: 'The management agent will be uninstalled from the remote computer. ' +
        'All other Veeam products installed on the remote computer will be removed from the managed infrastructure.',
    THE_BACKUP_AGENT_MANAGEMENT_FUNCTIONALITY: 'The backup agent management functionality is not enabled for these companies: ',
    THE_BACKUP_AGENT_RUNNING_ON_THIS_COMPUTER: 'The backup agent running on this computer will be switched to the free mode: {0}.',
    TECHNICAL_SUPPORT: 'Technical Support',
    THE_MULTI_FACTOR_AUTHENTICATION: 'The multi-factor authentication has been configured successfully.',
    THE_SELECTED_PATCH_FILES_HAVE_BEEN: 'The selected patch files have been successfully uploaded to the {0}.',
    THE_MINIMUM_VALUE_FOR: 'The minimum value for this field is {0}',
    THREE_AND_MORE_COPIES: 'Three+ Copies',
    THURSDAY: 'Thursday',
    THE_RULE_WITH_THE_SAME_NAME_ALREADY_EXISTS: 'The rule with the same name already exists.',
    TRANSACTIONS_LOGS: 'Transactions logs',
    TO: 'To',
    TO_COMPLETE_THE_SSO_CONFIGURATION: 'To complete the SSO configuration, perform the following steps:' +
        '<ol>' +
        '<li>Import the metadata XML file to the identity provider.</li>' +
        '<li>Verify the configured connection on the identity provider side.</li>' +
        '<li>After verifying the connection, click Complete Configuration.</li>' +
        '</ol>',
    TO_GET_DETAILED_INFORMATION: 'to get detailed information on the permissions for each user role.',
    TO_PERFORM_THIS_ACTION_CONTACT_YOUR_SERVICE_PROVIDER: 'To perform this action, contact your service provider and' +
        ' verify the appliance deployment settings.',
    TO_PERFORM_THIS_ACTION_VERIFY_THE_APPLIANCE: 'To perform this action, verify the appliance deployment settings using' +
        ' the corresponding plugin in the Configuration section of the backup portal.',
    TO_START_THIS_OPERATION_LINUX: 'To start this operation, please specify an account with administrative privileges on the remote computer.',
    TO_START_THIS_OPERATION_WINDOWS: 'To start this operation, please specify local administration credentials to use.',
    TAX: 'Tax',
    TYPE_IN_A_FRIENDLY_NAME_FOR: 'Type in a friendly name for the self-signed certificate.',
    TYPE_IN_THE_RECEIVED_MFA: 'Type in the received MFA response code',
    TUESDAY: 'Tuesday',
    TASK: 'Task',
    TASK_DETAILS: 'Task Details',
    TYPE_IN_THE_OBJECT_NAME: 'Type in the object (user, group or team) name mask to define objects to exclude from the report',
    TYPE_IN_THE_VM_NAME: 'Type in the VM name mask to define VMs to exclude from the report.',
    TYPE_IN_THE_NAME_OF_THE_VOLUME: 'Type in the name of the volume you want to back up in the following format "C:\\"',
    TYPE_IN_REPORT_NAME_AND: 'Type in report name and its description.',
    TYPE_IN_THE_NAME_TO_EXCLUDE: 'Type in the name of the volume you want to exclude from backup in the following format "C:\\"',
    TAPE_NBD: '[NBD] Tape',
    TAPE: 'Tape',
    TRUSTED_COMPUTERS: 'Trusted Computers',
    TRANSACTION_LOG_BACKUP_IS_ENABLED: 'Transaction log backup is enabled, but image-level backup schedule is not ' +
        'configured. Continue anyway?',
    THE_SELECTED_BACKUP_AGENTS_IS_NOT: 'The selected backup agent is not managed by the backup portal. ' +
        'Please activate this agent and try again.',
    THE_REMOTE_VEEAM_BACKUP_AND_REPLICATION: 'The remote Veeam Backup & Replication console will now be downloaded to your computer.',
    THE_REMOTE_COMPUTERS_ARE_OFFLINE: 'The remote computers are offline or do not have the management agent installed: {0}.' +
        ' All information about the management agents will be removed from the configuration database.',
    THE_REMOTE_COMPUTER_ARE_OFFLINE: 'The remote computer is offline or does not have the management agent installed: {0}.' +
        ' All information about the management agent will be removed from the configuration database.',
    THE_SECRET_VERIFICATION_CODE: 'The secret verification code has been generated.',
    THE_NEW_PASSWORD_HAS_BEEN_SET: 'The new password has been set',
    THIS_OPTION_REFER_VAC: 'This option refers to technical support regarding your backup portal. Choose this option ' +
        'if you have issues with portal configuration, management agents, alarms, reporting, plugins, licensing or REST API.',
    THIS_OPTION_REFER_CC: 'This option refers to technical support regarding Veeam Cloud Connect server used by your ' +
        'backup portal. Choose this option if you have issues with Veeam Cloud Connect configuration or licensing.',
    THIS_OPTION_REFER_VBR: 'This option refers to technical support regarding managed ' +
        'Veeam Backup & Replication servers. Choose this option if you have issues with Veeam Backup & Replication ' +
        'configuration, backup jobs or licensing.',
    THIS_OPTION_REFER_VA: 'This option refers to technical support regarding managed Veeam Agent ' +
        'instances. Choose this option if you have issues with Veeam Agent configuration, backup jobs or licensing.',
    THIS_OPTION_REFER_MICROSOFT365: 'This option refers to technical support regarding managed Veeam ' +
        'Backup for Microsoft 365 servers. Choose this option if you have issues with Veeam Backup for Microsoft 365 ' +
        'configuration, backup jobs or licensing.',
    THIS_OPTION_REFER_PUBLIC_CLOUDS: 'This option refers to technical support regarding managed Veeam ' +
        'Backup for Public Cloud appliances. Choose this option if you have issues with Veeam Backup for ' +
        'Azure/AWS/Google Cloud configuration, backup policies or licensing.',
    THIS_OPTION_REFER_VONE: 'This option refers to technical support regarding managed Veeam ONE ' +
        'servers. Choose this option if you have issues with Veeam ONE configuration or licensing.',
    THE_SELECTED_REPORT_WILL_NOW: 'The selected report will now be downloaded to your computer.',
    TWO_COPIES: 'Two Copies',
    TO_SEE_BACKED_UP_DATA: 'To see backed up data for individual VMs, enable the per-VM backup files ' +
        '<a href="{0}" target="_blank" rel="noopener noreferrer">option</a> in the repository settings.',
    TO_SEE_DATA_YOU_SHOULD_HAVE_AT_LEAST: 'To see data, you should have at least one paid invoice',
    THIS_WILL_DISABLE_ACCESS_TO_THE_PORTAL: 'This will disable access to the portal for the selected users. Do you want to proceed?',
    THIS_WILL_DISABLE_THE_ONLY_CONFIGURED: 'This will disable the only configured rule. '
        + 'After you disable this rule, the alarm will be disabled.\nDo you want to proceed?',
    THIS_WILL_DELETE_USERS: 'This will delete users from the database. Do you want to proceed?',
    THIS_WILL_DELETE_REPORT: 'This will delete report {0} and you will not be able to generate {1} {2}. Do you want to proceed?',
    TEST_LOGIN: 'Test Login',
    THIS_OPTION_ENFORCES_MFA: 'This option enforces MFA for the local users only. For the users logging in with Single Sign-On option, ' +
        'MFA configuration should be done on the Identity Provider side.',
    THIS_ACTION_WILL_REMOVE: 'This action will remove the Identity provider. After that, users authenticated ' +
        'with this provider will not be able to access the backup portal UI.',
    THE_VALUE_THAT_UNIQUELY_IDENTIFIES: 'The value that uniquely identifies this Identity Provider.',
    THE_VALUE_THAT_UNIQUELY_IDENTIFIES_VEEAM: 'The value that uniquely identifies Veeam Service Provider Console instance on the ' +
        'integration with the identity provider. SP Entity ID URL is created based on this value.',
    THE_SPECIFIED_ACCOUNT_SHOULD: 'The specified account should have an access to the administrative share on the target machine.',
    THIS_ACTION_WILL_DELETE_INVOICES: 'This action will delete selected invoices from the database. Do you want to proceed?',
    THIS_ACTION_WILL_DELETE_REPORTS: 'This action will delete selected reports from the database. Do you want to proceed?',
    THIS_OPTION_IS_RECOMMENDED_IF_YOU: 'This option is recommended if you don\'t have VMware Cloud Director ' +
        'and would like to use native functionality of the backup portal.',
    THIS_OPTION_REQUIRES_INTEGRATION: 'This option requires integration between VMware Cloud Director ' +
        'and the backup portal, and allows selecting VCD resources for replication jobs.',
    TELEPHONE: 'Telephone',
    TO_SELECT_BACKUP_JOBS_AGENT: 'To select backup jobs, select a backup agent on the previous step of the wizard.',
    TO_SELECT_BACKUP_JOBS_SERVER: 'To select backup jobs, select a backup server on the previous step of the wizard.',
    THIS_SITE_CONTAINS: 'This site contains',
    THESE_SITES_CONTAIN: 'These sites contain',
    THIS_ACTION_WILL_REMOVE_CLOUD_BACKUP: 'This action will remove cloud backup resources available for this reseller.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_CLOUD_REPLICATION: 'This action will remove cloud replication resources available for this reseller.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_MICROSOFT_365_SERVERS: 'This action will remove Microsoft 365 servers available for this reseller.\n\n' +
        'Do you want to proceed?',
    THIS_ACTION_WILL_REMOVE_MICROSOFT_365_RESOURCES: 'This action will remove Microsoft 365 repositories ' +
        'available for this reseller.\n\n' +
        'Do you want to proceed?',
    THIS_ADDRESS_WILL_BE_USED_IN_THE_WELCOME_EMAIL: 'This address will be used in the Welcome Email and for the integration ' +
        'with the identity provider.',
    TOPS: 'TOPs',
    THE_VEEAM_BACKUP_AND_REPLICATION_SETUP_FILE: 'The Veeam Backup & Replication setup file will be '
        + 'automatically downloaded from Veeam web server to the target machine.',
    THIS_SETTING_WILL_NOT_BE: 'This setting will not be applied if backup is targeted to an object storage repository.',
    THIS_SETTING_WILL_BE: 'This setting will be applied only if backup is targeted to an object storage repository.',
    THIS_OPTION_IS_NOT_AVAILABLE_TO_BE: 'This option is not available to be configured via the backup portal. '
        + 'Go to the backup agent UI to make the required changes',
    TENANT_ID: 'Tenant ID',
    TO_AUTHORIZE_THE_APPLICATION: 'To authorize the application, input application client and tenant IDs ' +
        'and press Authorize. You will be redirected to the mail service authentication page to finish the configuration. ' +
        'After you complete the authorization, you will be redirected back to the backup portal.',
    THIS_ACTION_WILL_REMOVE_WAN: 'This action will remove WAN acceleration servers available for this reseller.\n\n' +
        'Do you want to proceed?',
    THE_CONFIGURATION_FILE_INCLUDES: 'The configuration file includes invalid license parameter. '
        + 'Change the license parameter and upload the file again.',
    TRIGGERS_DATA_COLLECTION_TASKS: 'Triggers data collection tasks from the source servers',
    THIS_REPOSITORY_DOES_NOT: 'This repository does not support WAN acceleration.',
    THE_SELECTED_LOGIN_URL: 'The selected login URL is already in use',
    THIS_ACTION_WILL_RESET_THE_CONFIGURED: 'This action will reset the configured email server settings. Do you want to proceed?',
    THE_MAXIMUM_VALUE_FOR_THIS_FIELD_IS: 'The maximum value for this field is',
    THIS_REPORT_WILL_BE_ACCESSIBLE_BY_ANY_USER: 'This report will be accessible by any user who has access to the specified scope of companies.',
    THIS_REPORT_WILL_BE_ACCESSIBLE_BY_A_GROUP: 'This report will be accessible by a group ' +
        'of users who created this report, including users which belong to selected companies.',
    TYPE_IN_THE_USERNAME: 'Type in the username in the DOMAIN\\USERNAME format',
    THE_DEFAULT_CLOUD_REPOSITORY_WILL_BE_SELECTED: 'The default cloud repository will be selected from the list of available repositories.',
    THE_SCHEDULE_DATE_CANNOT: 'The schedule date cannot be in the past',
    TO_BACK_UP_TO_AN_OBJECT: 'To back up to an object storage, use a Veeam Cloud Connect repository.<br>' +
        '<a href="{0}" target="_blank" rel="noopener noreferrer">Click here</a> to see how it should be configured.',
    TEAMS_CHATS: 'Teams chats',
    THIS_POLICY_IS_USING: 'This policy is using scheduling options that are available in the server policy only. ' +
        'To continue using the existing schedule, switch this policy to the server mode. ' +
        'Alternatively, select new scheduling options that are available in the workstation policy.',
    THIS_JOB_IS_USING: 'This job is using scheduling options that are available in the server operation mode only. ' +
        'To continue running this backup job, select new scheduling options that are available in the workstation operation mode. ' +
        'Alternatively, switch this backup agent to the server mode and set your previous job schedule.',
    TOTAL_SUM: 'Total Sum',
    TEST: 'Test',
    THE_PRICE_CAN_BE_SET_TO_ALL_EDITIONS: 'The price can be set to all editions discovered at a customer environment',
    THE_ROUND_UP_SETTING_DOES_NOT_APPLY: 'The round up setting does not apply to allocated space (quota) billing model.',
    TO_BE_ABLE_TO_ASSIGN: 'To be able to assign hosted backup servers to the company, connect hosted backup ' +
        'servers as described in the Veeam Backup & Replication plugin in the Plugin Library.',
    THIS_FUNCTIONALITY_REQUIRES_VEEAM_BACKUP: 'This functionality requires Veeam Backup & Replication v12a or later for the hosted scenario.\n' +
        'Note that the price can be set to all editions of backup servers discovered in the infrastructure.',
    TO_ENABLE_THIS_FUNCTIONALITY: 'To enable this functionality, contact your service provider.',
    TO_CREATE_A_BACKUP_JOB_ASSIGN_HOSTED_BACKUP: 'To create a backup job assign hosted backup resources to managed companies and ' +
        'assign tags for these companies in the Veeam Backup & Replication plugin.',

    UBUNTU: 'Ubuntu',
    UI_MODE: 'UI mode',
    UNINSTALL_CBT_DRIVER: 'Uninstall CBT Driver',
    UNABLE_TO_PERFORM_THE_OPERATION_NETWORK_SETTINGS_FOR: 'Unable to perform the operation. Network settings for the backup appliance' +
        ' are not configured. See the help center guide for more information.',
    UPDATING_USAGE_DATA: 'Updating usage data',
    URL: 'URL',
    USAGE: 'Usage',
    USAGE_REPORT: 'Usage Report',
    USAGE_REPORTS: 'Usage Reports',
    USAGE_REPORT_HAS_BEEN_GENERATED: 'Usage report has been generated',
    USER: 'User',
    USER_ACCOUNT_UNDER_WHICH_THE_VEEAM: 'user account under which the Veeam Backup Service will run. '
        + 'If you do not specify this parameter, the service will run under the LocalSystem account',
    USER_COMPANY: 'User Company',
    USERS: 'Users',
    USAGE_REPORT_WILL_NOW_BE_DOWNLOADED: 'Usage report will now be downloaded to your computer.',
    USE_A_JOB_TEMPLATE: 'Use a job template',
    USE_LOCAL_USERS: 'Use local users',
    USE_SU_IF_SUDO_FAILS: 'Use "su" if "sudo" fails',
    USED: 'Used',
    USED_STORAGE: 'Used Storage',
    USED_CPU_RESOURCES: 'Used CPU Resources',
    USED_CLOUD_MEMORY: 'Used Cloud Memory',
    USED_CLOUD_STORAGE: 'Used Cloud Storage',
    USED_CAPACITY: 'Used Capacity',
    USE_THRESHOLD_DEFINED_ON_BACKUP_SERVER: 'Use threshold defined on the backup server',
    UNABLE_TO_EDIT_SELECTED_JOB: 'Unable to edit selected job. Company {0} does not have hosted backup resources assigned.' +
        'Check if this company has a hosted backup server and a repository assigned and navigate to Veeam Backup & Replication plugin' +
        ' to assign a VM tag.',
    UNPAID: 'Unpaid',
    UNITS: 'Units',
    UNKNOWN: 'Unknown',
    UNCHANGED: 'unchanged',
    UPLOAD_LOGS_FOR_THE_LAST: 'Upload logs for the last',
    UNDEFINED: 'Undefined',
    UNDO: 'Undo',
    UNDO_IN_PROGRESS: 'Undo in progress',
    UNINSTALLING: 'Uninstalling',
    UNLIMITED: 'Unlimited',
    UNLIMITED_QUOTA: 'Unlimited quota',
    UNPROTECTED: 'Unprotected',
    UNSUPPORTED: 'Unsupported',
    UNTITLED_ITEM: 'Untitled item',
    UNTIL_THE_CORRESPONDING_IMAGE_LEVEL: 'Until the corresponding image-level backup is deleted',
    UNTRUSTED_SECURITY_CERTIFICATE: 'Untrusted Security Certificate',
    UNREGISTER: 'Unregister',
    UNREGISTER_CLOUD_CONNECT_SERVER: 'Unregister Cloud Connect Server',
    UNVERIFIED: 'Unverified',
    UPON_ENABLING_THIS_OPTION: 'Upon enabling this option, a managed company will have its own set ' +
        'of alarms that will be triggered independently in the client\'s backup portal. This option is recommended ' +
        'when managed companies have their own technicians monitoring backup job states.',
    USE_GUEST_OS_CREDENTIALS_FROM: 'Use guest OS credentials from',
    USE_SECURE_CONNECTION: 'Use secure connection',
    USED_UNITS: 'Used Units',
    USED_SOCKETS: 'Used Sockets',
    USED_SPACE: 'Used Space',
    USERNAME_TEXTFIELD_PLACEHOLDER: 'Enter Company\\User',
    USERNAME: 'Username',
    USER_OR_GROUP: 'User or group',
    USER_INFO: 'User Info',
    USE_THE_FOLLOWING_VARIABLES: 'Use the following variables for sending reports via email: \n\n',
    USE_CREDENTIALS_SPECIFIED_IN_THE_MASTER: 'Use credentials specified in the master management agent configuration',
    USE_SUB_TENANT_ACCOUNTS_FOR_EACH_MANAGED: 'Use sub-tenant accounts for each managed backup agent with the following quota:',
    USE_SINGLE_TENANT_ACCOUNT: 'Use single tenant account for all computers managed by the company (not recommended)',
    UPDATE: 'Update',
    UP: 'up',
    UP_TO_DATE: 'Up-to-date',
    UPDATING: 'Updating',
    UPDATE_CONFIG: 'Update Config',
    UPDATE_LICENSE_AUTOMATICALLY: 'Update license automatically (enables usage reporting)',
    UPDATE_NOW: 'Update now',
    UPDATE_AVAILABLE: 'Update available',
    UPDATE_REQUIRED: 'Update required',
    UPDATE_STATUS: ' Update Status',
    UPGRADE: 'Upgrade',
    UPGRADE_MANAGEMENT_AGENT: 'Upgrade Management Agent',
    UPGRADING: 'Upgrading',
    UPLOAD: 'Upload',
    UPLOAD_FAILED: 'Failed to upload the image.',
    UPLOAD_PENDING: 'Uploading the {0}...',
    UPLOAD_SUCCESS: 'Custom {0} has been uploaded.',
    UPLOAD_TYPE_CSS_SUBSTR: 'CSS file',
    UPLOAD_ATTACHMENTS: 'Upload attachments',
    UPLOAD_TYPE_IMAGE_SUBSTR: 'image',
    UPLOAD_UNSUPPORTED_FORMAT: 'Unsupported image format',
    UPLOADING_PATCH_FILES_TO_THE: 'Uploading patch files to the {0}...{1}%',
    UPLOAD_A_CSV_FILE_WITH_THE: 'Upload a CSV file with the list of computers you want to deploy backup agent to',
    UPON_ENABLING_THIS_OPTION_ALL_USERS_OF_THIS_COMPANY: 'Upon enabling this option all local users of this company will be prompted ' +
        'to put in a secret code after logging in to the backup portal.',
    UPON_ENABLING_THIS_OPTION_ALL_USERS_OF_THIS_RESELLER: 'Upon enabling this option all users of this reseller, including users ' +
        'from all managed companies, will be prompted to put in a secret code after logging in to the backup portal.',
    UPON_ENABLING_THIS_OPTION_ALL_YOUR_PORTAL_USERS: 'Upon enabling this option all your portal users including your managed companies' +
        ' will be offered to configure MFA for their accounts. The multi-factor authentication engine is based' +
        ' on the TOTP (Time-based One-time Password) algorithm.',
    UPON_ENABLING_THIS_OPTION_USER_WILL_BE_ASKED: 'Upon enabling this option user will be asked to configure multi-factor authentication' +
        ' for the backup portal.',
    UPON_ENABLING_THIS_OPTION_USER_WILL_BE_PROMPTED: 'Upon enabling this option user will be prompted to put in a secret code after' +
        ' logging in to the backup portal.',
    UPON_ENABLING_THIS_OPTION_YOU_WILL_BE: 'Upon enabling this option you will be prompted to put in a secret code after logging in' +
        ' to the backup portal.',
    UPON_ENABLING_THIS_OPTION_THIS_RESELLER: 'Upon enabling this option this reseller will be able to create and edit ' +
        'license keys in the backup portal. To use this feature, you and the reseller must configure VCSP Pulse integration ' +
        'in the Plugin Library.',
    USE_A_DEFAULT: 'Use a default {0}?',
    USE_ASTERISK: 'Use * to exclude any number of characters, and ? to exclude a single character.',
    USE_BUILT_IN_NETWORK_MANAGEMENT: 'Use built-in network management capabilities during failover',
    USE_DOMAIN_USER_FORMAT: 'Use DOMAIN\\USER format for domain accounts, or HOST\\USER for local accounts.',
    USE_THE_SPECIFIED_ACCOUNT_TO_CREATE: 'Use the specified account to create individual sub-folders for each managed backup agent. ' +
        'Backup policy will use the corresponding Active Directory computer account to connect to the network share.',
    USE_WEEKLY_FULL_BACKUP_FOR: 'Use weekly full backup for the following week of a month',
    USE_WAKE_UP_TIMERS_TO_START_JOBS: 'Use wake up timers to start jobs',
    USE_MONTHLY_FULL_BACKUP_FOR: 'Use monthly full backup for the following month',
    USE_THE_FOLLOWING_IP_ADDRESS: 'Use the following IP address:',
    USE_THE_FOLLOWING_ACCOUNT: 'Use the following account',
    USE_THIS_TEMPLATE_AS_DEFAULT: 'Use this template as default for all managed companies',
    USE_THIS_TEMPLATE_AS_DEFAULT_RESELLERS: 'Use this template as default for all resellers',
    USER_QUOTA: 'User quota',
    UNASSIGNED: 'Unassigned',
    USE_AN_EXISTING_CERTIFICATE_ALREADY: 'Use an existing certificate already present in the local Certificate Store of this server.',
    USE_GOOGLE_AUTHENTICATOR_APP: 'Use Google Authenticator App',
    USE_THESE_SETTINGS_TO: 'Use these settings to {0}defragment and compact full backup file ' +
        'periodically when the job schedule does not include periodic fulls.',
    USE_VEEAM_NETWORK_EXTENSION_CAPABILITIES: 'Use Veeam network extension capabilities during partial and full site failover',
    USES_SCRIPTS_TO_PREPARE_APPLICATIONS: 'Uses scripts to prepare applications for hot backup ' +
        'or integrate agent job with 3rd party tools.',
    USE_DEFAULT_CONFIGURATION: 'Use default configuration',
    USE_DEFAULT_LOCATION: 'Use default location',
    USE_THIS_PERSONAL_URL: 'Use this personal URL to log in to the backup portal with your identity provider.<br />' +
        'Before configuring an identity provider, check if the generated link is available. If the link is not available, ' +
        'contact your service provider.',
    USE_PERSISTENT_GUEST_AGENT: 'Use persistent guest agent',
    USER_TYPE: 'User type',
    UPLOADING_FILE: 'Uploading file {0}...{1}% ({2}/{3})',
    USE_THE_SELECTED_SECURITY_CERTIFICATE: 'Use the selected security certificate',
    USED_POINTS: 'Used Points',
    USE_DOMAIN_USER: 'Use DOMAIN\\USER format for domain accounts, or HOST\\USER for local accounts.',
    USD: 'USD',
    GO_BACK: 'Go Back',
    UPGRADE_BACKUP_SERVER: 'Upgrade Backup Server',
    USE_THE_BACKUP_SERVER_SETUP_FILE: 'Use the backup server setup file from the remote file share',
    USERS_QUOTA: 'Users quota',
    USE_VEEAM_NETWORK_MANAGEMENT: 'Use Veeam network management capabilities during partial and full site failover',
    UNSUPPORTED_LICENSE_FORMAT: 'Unsupported license format',
    UNAVAILABLE: 'Unavailable',
    UPGRADE_NOW: 'Upgrade now',
    UPGRADE_AVAILABLE: 'Upgrade available',
    UPLOAD_LOGS_AND_ADDITIONAL: 'Upload logs and additional attachments for the support case.',
    USED_STORAGE_QUOTA: 'Used Storage Quota',
    UNSUPPORTED_FORMAT_SPECIFY_DNS: 'Unsupported format. Specify DNS name or IP address of the server.',
    UPLOAD_TRAFFIC: 'Upload Traffic',
    UNSTRUCTURED_DATA: 'Unstructured data',
    UNSTRUCTURED_DATA_SIZE: 'Unstructured data size',
    UNSTRUCTURED_DATA_BACKUP_SIZE: 'Unstructured data backup size',
    UNSTRUCTURED_DATA_ARCHIVE_SIZE: 'Unstructured data archive size',
    UNSTRUCTURED_DATA_SIZE_FREE_OF_CHARGE: 'Unstructured data size free of charge',
    UNSTRUCTURED_DATA_BACKUP_SIZE_FREE_OF_CHARGE: 'Unstructured data backup size free of charge',
    UNSTRUCTURED_DATA_ARCHIVE_SIZE_FREE_OF_CHARGE: 'Unstructured data archive size free of charge',
    USE_WEEKLY_FULL_BACKUP_FROM_THE_FOLLOWING_WEEK_OF_THE_MONTH: 'Use weekly full backup from the following week of a month',
    USE_MONTHLY_FULL_BACKUP_FROM_THE_FOLLOWING_MONTH: 'Use monthly full backup from the following month',

    VALID_FROM: 'Valid from',
    VALID_TO: 'Valid to',
    VALUE: 'Value',
    VALID: 'Valid',
    VALUE_MUST_BE_A_POSITIVE_INTEGER: 'Value must be a positive integer number',
    VAT: 'VAT',
    VAW_ISTALL_NOTE: 'Target computers must be part of domain or admin shares must be remotely accessible and ' +
        '"File and Printer Sharing" and "Remote Scheduled Tasks Management (RPC)" rules must be open on the computers firewall.',
    VMWARE_CLOUD_DIRECTOR_RESOURCES: 'VMware Cloud Director resources',
    VMWARE_CLOUD_DIRECTOR_REPLICATION: 'VMware Cloud Director replication',
    NEW_VSPHERE_BACKUP_JOB: 'New vSphere Backup Job',
    VSPHERE_BACKUP_JOB: 'vSphere Backup Job',
    VEEAM_BACKUP_FOR_MICROSOFT_365_REPOSITORIES: 'Veeam Backup for Microsoft 365 Repositories',
    VEEAM_BACKUP_FOR_MICROSOFT_365_SERVERS: 'Veeam Backup for Microsoft 365 Servers',
    VEEAM_SERVICE_PROVIDER_CONSOLE: 'Veeam Service Provider Console',
    VEEAM_BACKUP_SERVER: 'Veeam backup server',
    VEEAM_SERVICE_PROVIDER_CONSOLE_DATABASE: 'Veeam Service Provider Console Database',
    VEEAM_CLOUD_CONNECT: 'Veeam Cloud Connect',
    VEEAM_CLOUD_CONNECT_SERVER: 'Veeam Cloud Connect server',
    VEEAM_CLOUD_CONNECT_BACKUP_RESOURCES: 'Veeam Cloud Connect backup resources',
    VEEAM_CLOUD_CONNECT_REPLICATION_RESOURCES: 'Veeam Cloud Connect replication resources',
    VEEAM_CLOUD_CONNECT_BACKUP_ADVANCED_SETTINGS: 'Veeam Cloud Connect Backup Advanced Settings',
    VEEAM_CLOUD_CONNECT_REPLICATION_ADVANCED_SETTINGS: 'Veeam Cloud Connect Replication Advanced Settings',
    VEEAM_CLOUD_CONNECT_REPOSITORY: 'Veeam Cloud Connect repository',
    VEEAM_MANAGEMENT_AGENT_CONNECTION_SETTINGS: 'Veeam Management Agent Connection Settings',
    VERSION: 'Version',
    VERIFIES_IF_THERE_ARE_ANY_UPDATES: 'Verifies if there are any product updates, patches ' +
        'or major versions available for the Veeam Service Provider Console and all managed Veeam products.',
    VIEW_DETAILS: 'View details',
    VIEW_DISCOVERED_COMPUTERS: 'View Discovered Computers',
    VIEW_HELP: 'View Help',
    VIEW: 'View',
    VIRTUAL_MACHINES: 'Virtual Machines',
    VIRTUAL_DATA_CENTER: 'Virtual data center',
    VIRTUAL_DATA_CENTERS: 'Virtual Data Centers',
    VM_REGION: 'VM Region',
    VM: 'VM',
    VM_EXCLUSIONS_RULE: 'VM exclusions rule',
    VM_BACKUP: 'VM Backup',
    VM_BACKUPS: 'VM Backups',
    VM_REPLICAS: 'VM Replicas',
    VM_REPLICA: 'VM Replica',
    VM_COPY: 'VM copy',
    VM_PLATFORM: 'VM Platform',
    VMS: 'VMs',
    VM_WITH_CDP_ENABLED: 'VM with CDP enabled',
    VMS_WITH_CDP_ENABLED: 'VMs with CDP enabled',
    VMS_WITH_CDP_ENABLED_FREE_OF_CHARGE: 'VMs with CDP enabled free of charge',
    VMS_WITH_CDP_SLA: 'VMs with CDP (SLA)',
    VM_JOB_STATUS: 'VM Job Status',
    VMS_QUOTA: 'VMs quota',
    VMS_IN_THE_CLOUD_REPOSITORY: 'VMs in the Cloud Repository',
    VMS_STORED_IN_CLOUD_REPOSITORY: 'VMs Stored in Cloud Repository',
    VMS_BY_BACKUP_TYPE: 'VMs by Backup Type',
    VMS_BY_N_OF_JOBS: 'VMs by N. of Jobs',
    V_CPUS: 'vCPUs',
    VMWARE_CLOUD_DIRECTOR: 'VMware Cloud Director',
    VMWARE_CLOUD_DIRECTOR_RESOURCE: 'VMware Cloud Director Resource',
    VMWARE_CLOUD_DIRECTOR_ORGANIZATION: 'VMware Cloud Director organization',
    VCD_ORGANIZATION: 'VCD organization',
    VCD_ORGANIZATIONS: 'VCD Organizations',
    VMWARE_CLOUD_DIRECTOR_COMPANY: 'VMware Cloud Director company',
    VERIFICATION: 'Verification',
    VERIFICATION_CODE_FORMAT_INVALID: 'Verification code format invalid',
    VERIFY_THAT_EMAIL_SERVER_ARE_CONFIGURED: 'Verify that email server settings are configured. In case of sending notifications' +
        ' to a group, make sure all accounts in the group have their email addresses specified.',
    VERIFYING: 'Verifying...',
    VERIFYING_SETTINGS: 'Verifying settings',
    VEEAM_BACKUP_DATA: 'Veeam backup data',
    VEEAM_BACKUP_AND_REPLICATION: 'Veeam Backup & Replication',
    VEEAM_BACKUP_AND_REPLICATION_REPOSITORIES: 'Veeam Backup & Replication Repositories',
    VEEAM_BACKUP_REPOSITORY: 'Veeam backup repository',
    VERIFY_LINK: 'Verify link',
    VIRTUAL: 'Virtual',
    VIRTUAL_INFRASTRUCTURE: 'Virtual Infrastructure',
    PLATFORMS: 'Platforms',
    PLUGIN_LIBRARY: 'Plugin Library',
    VOLUMES: 'Volumes',
    VOLUME_LEVEL: 'Volume-level',
    VOLUME_LEVEL_BACKUP: 'Volume level backup',
    VIDEOS: 'Videos',
    VSPHERE: 'vSphere',
    VOLUME_RESTORE: 'Volume Restore',
    VOLUME_WITH_THE_SAME_PATH: 'Volume with the same path has already been added',
    VEEAM_PRO_PARTNER_ID: 'Veeam ProPartner ID',
    VSPC: 'vspc',
    VSS: 'VSS',
    VSS_SETTINGS: 'VSS settings',
    VEEAM_AGENT: 'Veeam Agent',
    VEEAM_AGENTS: 'Veeam Agents',
    VEEAM_ONE: 'Veeam ONE',
    VEEAM_ONE_SERVER: 'Veeam ONE server',
    VEEAM_BACKUP_FOR_MICROSOFT: 'Veeam Backup for Microsoft 365',
    VM_SIZE: 'VM Size',
    VEEAM_SERVICE_PROVIDER_CONSOLE_METADATA_URL: 'Veeam Service Provider Console metadata url',
    VEEAM_SERVICE_PROVIDER_CONSOLE_SETTINGS: 'Veeam Service Provider Console settings',
    VCSP_PULSE: 'VCSP Pulse',
    VEEAM_BACKUP_FOR_MICROSOFT_365_SERVER: 'Veeam Backup for Microsoft 365 server',
    VEEAM_BACKUP_FOR_PUBLIC_CLOUD: 'Veeam Backup for Public Cloud',
    VEEAM_BACKUP_FOR_PUBLIC_CLOUD_DEPLOYMENT: 'Veeam Backup for public cloud deployment',
    VEEAM_CLOUD_CONNECT_BACKUP_RESOURCES_PER: 'Veeam Cloud Connect backup resources, per month',
    VEEAM_CLOUD_CONNECT_REPLICATION_RESOURCES_PER: 'Veeam Cloud Connect replication resources, per month',
    VEEAM_CUSTOMER_SUPPORT_PORTAL: 'Veeam Customer Support Portal',
    VBR_IS_ALREADY_INSTALLED: 'Veeam Backup & Replication is already installed on this machine',
    VEEAM_BACKUP_AND_REPLICATION_SERVER: 'Veeam Backup & Replication server',
    VBR_LICENSE_FILE: 'VBR_LICENSE_FILE',
    VEEAM_WILL_ONLY: 'Veeam will only utilize ' +
        'this information for the specific purpose of providing customer support and will protect the information ' +
        'in accordance with its privacy policy.',
    VBR_LICENSE_AUTOUPDATE: 'VBR_LICENSE_AUTOUPDATE',
    VBR_RESTSERVICE_PORT: 'VBR_RESTSERVICE_PORT',
    VBR_SERVICE_PASSWORD: 'VBR_SERVICE_PASSWORD',
    VBR_SERVICE_USER: 'VBR_SERVICE_USER',
    VBR_SQLSERVER_AUTHENTICATION: 'VBR_SQLSERVER_AUTHENTICATION',
    VBR_SQLSERVER_DATABASE: 'VBR_SQLSERVER_DATABASE',
    VBR_SQLSERVER_ENGINE: 'VBR_SQLSERVER_ENGINE',
    VBR_SQLSERVER_INSTALL: 'VBR_SQLSERVER_INSTALL',
    VBR_AUTO_UPGRADE: 'VBR_AUTO_UPGRADE',
    VBR_IRCACHE: 'VBR_IRCACHE',
    VM_CATALOGPATH: 'VM_CATALOGPATH',
    VBR_CHECK_UPDATES: 'VBR_CHECK_UPDATES',
    VBR_SECURE_CONNECTIONS_PORT: 'VBR_SECURE_CONNECTIONS_PORT',
    VBR_SQLSERVER_PASSWORD: 'VBR_SQLSERVER_PASSWORD',
    VBR_SQLSERVER_SERVER: 'VBR_SQLSERVER_SERVER',
    VBR_SQLSERVER_USERNAME: 'VBR_SQLSERVER_USERNAME',
    VBR_SERVICE_PORT: 'VBR_SERVICE_PORT',
    VBRC_SERVICE_PORT: 'VBRC_SERVICE_PORT',
    VEEAM_TENANT_ID: 'Veeam Tenant ID',
    VEEAM_BACKUP_AND_REPLICATION_INCLUDING_PUBLIC_CLOUD: 'Veeam Backup & Replication (including public cloud backup)',
    VM_CLOUD_REPLICAS_IN_THE_FAILOVER_STATE: 'VM cloud replicas in the failover state will be removed ' +
        'from the backup console UI of the corresponding backup server of the company account.',
    VERIFY_CREATED_BACKUP_FILES: 'Verify created backup files (object storage only)',
    VEEAM_BACKUP_REPLICATION_SERVER: 'Veeam Backup & Replication server',
    VEEAM_BACKUP_REPLICATION_REPOSITORIES: 'Veeam Backup & Replication repositories',

    WAN_ACCELERATION: 'WAN acceleration',
    WAN_ACCELERATION_SERVER: 'WAN acceleration server',
    WAN_ACCELERATOR: 'WAN Accelerator',
    WAN_ACCELERATION_IS_NOT_ENABLED: 'WAN acceleration is not enabled for your account. Please contact your ' +
        'service provider for more information.',
    WAIT_BEFORE_RETRY: 'Wait before each retry attempt for',
    WAIT_BEFORE_EACH_RETRY_FOR: 'Wait before each retry for',
    WAIT_BEFORE_EACH_RETRY_ATTEMPT_FOR: 'Wait before each retry attempt for',
    WAITING_FOR_APPROVAL: 'Waiting for Approval',
    WEB_SITE: 'Web site',
    WEB_SERVER_IS_NOT_RESPONDING: 'Web server is not responding. Please refresh the web page and try again.',
    WE_WILL_WAKE_YOUR_COMPUTER_FROM_SLEEP: 'We will wake your computer from sleep to take a backup unless the ' +
        'connected standby power model is enabled. Normally, this model is only enabled on mobile devices such as tablets.',
    WE_HIGHLY_RECOMMEND_THAT_YOU: 'We highly recommend that you do not store your backups on the same computer that you are protecting.',
    WE_DO_NOT_RECOMMEND_BACKING_UP: 'We do not recommend backing up disk to itself, as your backup will be lost along with the disk.',
    WE_WILL_ALSO_NEED_TO_EXCLUDE: 'We will also need to exclude the target folder from backup to prevent ' +
        'backup set from growing in size forever.',
    WE_CAN_STOP_THIS_JOB_GRACEFULLY: 'We can stop this job gracefully by taking additional time to finalize the ' +
        'incomplete restore point. Alternatively, we can terminate the job immediately, leaving an unusable restore point.\n' +
        'How would you like this job to be stopped?',
    WE_RECOMMEND_THAT_POLICY_NAME_DOES: 'We recommend that policy name does not exceed 50 symbols.\n\nContinue anyway?',
    WE_RECOMMEND_THAT_JOB_NAME_DOES: 'We recommend that job name does not exceed 50 symbols.\n\nContinue anyway?',
    WEDNESDAY: 'Wednesday',
    USAGE_TREND: 'Usage Trend',
    WEEKLY_ON_SELECTED_DAYS: 'Weekly on selected days:',
    WEEKLY_ON: 'Weekly on {0}',
    WELCOME_EMAIL: 'Welcome Email',
    WELCOME_TO_THE_VEEAM_SERVICE: 'Welcome to the Veeam Service Provider Console plugin library!',
    WELCOME_TO_VEEAM_SERVICE_PROVIDER: 'Welcome to Veeam Service Provider Console',
    WORKSTATION: 'Workstation',
    WORKSTATIONS: 'Workstations',
    WORKSTATION_AGENT: 'Workstation Agent',
    WORKSTATION_AGENT_FREE_OF_CHARGE: 'Workstation agent free of charge',
    WORKSTATION_AGENTS: 'Workstation agents',
    WORKSTATIONS_QUOTA: 'Workstations quota',
    WORKSTATIONS_STORED_IN_CLOUD_REPOSITORY: 'Workstations Stored in Cloud Repository',
    WARNING: 'Warning',
    WARNINGS: 'Warnings',
    WORKDAYS: 'Workdays',
    WEEKENDS: 'Weekends',
    THE_SECURITY_CERTIFICATE_IS_NOT: 'The security certificate is not verified',
    WINDOWS_ACCOUNT: 'Windows account',
    WINDOW: 'Window',
    WINDOWS: 'Windows',
    WINDOWS_AUTHENTICATION: 'Windows Authentication',
    WORKLOAD: 'Workload',
    WORKLOADS: 'Workloads',
    WORKLOAD_COUNT: 'Workload Count',
    WORKLOAD_TYPE: 'Workload type',
    WEEK: 'week',
    WEB_UI_VERSION: 'Web UI Version',
    WEEKS: 'Weeks',
    WEEKLY: 'Weekly',
    WORKSTATION_BACKUP: 'Workstation backup',
    WINDOWS_WORKSTATION: 'Windows Workstation',
    WINDOWS_WORKSTATION_OS: 'Windows workstation OS',
    WINDOWS_SERVER: 'Windows Server',
    WINDOWS_SERVER_OS: 'Windows server OS',
    WITH_NO_REGION_SET: 'with no region set',
    WITH_THE_SAME_NAME_ALREADY_EXISTS: '{0} with the same name already exists.',
    WITHIN_THE_NEXT: 'Within the next',
    WHEN_BACKUP_TARGET_IS_CONNECTED: 'When backup target is connected',
    WHEN_STORAGE_IS_ATTACHED: 'when storage is attached{0}',
    WHEN: 'When',
    WEBSITE_CONFIGURATION_HAS_BEEN_UPDATED: 'The website configuration has been updated. Please refresh the web ' +
        'page (Ctrl + F5) to continue working with the portal.',
    WINDOWS_USERS: 'Windows Users',
    WE_HIGHLY_RECOMMEND_SETTING: 'We highly recommend setting deleted object retention to at least {0} days, ' +
        'otherwise temporary infrastructure issues may cause loss of all backups.',
    WAN_ACCELERATION_SERVERS: 'WAN acceleration servers',
    WORKSTATION_BACKUPS: 'Workstation Backups',
    WORKSTATION_AGENTS_QUOTA: 'Workstation agents quota will be increased by {0}.',
    WHEN_CLOUD_REPOSITORY_QUOTA: 'When cloud repository quota used by recycle bin exceeds',
    WAIT_BEFORE_EACH_RETRY: 'Wait before each retry',
    WHERE_TO_GO_FROM_HERE: 'Where to go from here:',
    WELCOME_TO_REST_API: 'Welcome to REST API v{0} for Veeam Service Provider Console.',

    XML: 'XML',

    YEARS: 'Years',
    YES: 'Yes',
    SPECIFY_CLOUD_REPLICATION_RESOURCES: 'Specify cloud replication resources provided free of charge.',
    THIS_ACTION_WILL_UNINSTALL_MANAGEMENT_AGENTS: 'This action will uninstall management agents from selected servers.' +
        ' Other Veeam products installed on these servers will also be removed from the portal.',
    YOU_CAN_SEND_EMAIL_NOTIFICATIONS_TO: 'You can send email notifications to one of the following groups',
    YOU_CAN_ONLY_SPECIFY_REPOSITORIES: 'You can only specify repositories that allow connections for backup agents.',
    YOU_ARE_CURRENTLY_UPLOADING_LOGS: 'You are currently uploading logs for the different support case. ' +
        'Leaving this page now will result in the loss of the progress. Are you sure you want to continue?',
    YOU_ARE_CURRENTLY_UPLOADING_PATCH: 'You are currently uploading patch files to a backup server. If you proceed with opening the ' +
        'support ticket, the upload operation will be canceled. Are you sure you want to continue?',
    YOUR_PERSONAL_LOGIN_URL: 'Your personal login URL is based on the company alias. If you change the company alias after the ' +
        'identity provider registration, the login URL will not be changed.',
    YOU_CAN_GRANULARLY_ENABLE_AND_DISABLE_MFA_FOR_COMPANIES: 'You can granularly enable and disable MFA access for managed companies ' +
        'if this setting was not enforced by your service provider. To do that, use company profile settings.',
    YOU_CAN_GRANULARLY_ENABLE_AND_DISABLE_MFA_FOR_COMPANIES_AND_RESELLERS: 'You can granularly enable and disable MFA access for managed' +
        ' companies and resellers. To do that, use company and reseller profile settings.',
    YOU_HAVE_DECREASED_RETENTION_POLICY_SETTINGS: 'You have decreased the retention policy settings. '
        + 'All historical data will be cleaned up according to the new policy.\nDo you want to proceed?',
    YOU_HAVE_INSTALLED_A_RENTAL_LICENSE: 'You have installed the Rental license expiring on {0}.\n' +
        'Would you like to enable the VCSP Pulse integration and have license extensions downloaded automatically?\n' +
        'This requires sending the license ID, the installation ID and workload usage counters to Veeam servers periodically.',
    YOU_HAVE_INSTALLED_A_SUBSCRIPTION_LICENSE: 'You have installed the Subscription license expiring on {0}.\n' +
        'Would you like to have license extensions downloaded automatically when you renew or expand your contract?\n' +
        'This requires sending the license ID, the installation ID and workload usage counters to Veeam servers periodically.',
    YOUR_ACCOUNT_USES_TWO_FACTOR_AUTHENTICATION: 'Your account uses two factor authentication, '
        + 'to login you need to enter the verification code from your device',
    YOUR_CHANGES_ARE_NOT_SAVED: 'Your changes are not saved yet.',
    YOUR_CHANGES_ARE_SAVED: 'Your changes are saved.',
    YOU_SHOULD_SPECIFY_THE_ACCOUNT_WITH: 'You should specify the account with root privileges.<br />Continue anyway?',
    YEARLY: 'Yearly',
    YOUR_LICENSE_KEY_HAS_EXPIRED: 'Your license key has expired.\nTo continue using the product, install a new license.',
    YOUR_PORTAL_ADMINISTRATOR_HAS_ENFORCED_THE_MULTI: 'Your portal administrator has enforced the multi-factor authentication (MFA) policy.' +
        ' To continue working with the portal, you must set up the MFA access for your account.',
    YOU_CANNOT_ADD_DIFFERENT_TYPES: 'You cannot add different types of hardware plan.',
    YOU_CAN_SELECT_ONLY_A_REPOSITORY_THAT_IS_CONNECTED_TO_THE_SAME_BACKUP_PROXY: 'You can select only a repository that is connected to the' +
        ' same backup proxy and has the same retention type as the source backup repository',
    YOU_HAVE_THE_UP_TO_DATE_CONFIGURATION: 'You have the up-to-date configuration backup file of the selected backup server',
    YOU_MUST_CONFIGURE_THE_MULTI_FACTOR: 'You must configure the multi-factor authentication (MFA) policy '
        + 'for the company or disable the MFA access for the user',
    YOU_MUST_SET_A_LIMIT_FOR_INCOMING: 'You must set a limit for incoming network traffic. '
        + 'The maximum allowed bandwidth is limited by quota configured for reseller managing this company.',
    YOU_HAVE_REACHED_THE_VEEAM_SERVICE_PROVIDER_CONSOLE_APIS: 'You have reached the Veeam Service Provider Console APIs web page. '
        + 'Please review the links below to utilize our APIs for integration with your existing portals and billing applications.',

    ZIP_CODE: 'ZIP code',
};

if (typeof window === 'object') {
    // @ts-expect-error langOverwrites initing in js and not typed
    const localesOverwrite = window?.VSPC?.localesOverwrite?.locale;
    if (localesOverwrite) {
        Object.assign(enLocale, localesOverwrite);
    }
}

