/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    LoginUserIcon,
    LoginSsoForm,
    AlternativeButton,
    useGlobalLang,
    useGlobalServices,
    loginSsoFormInitialState,
} from '@veeam-vspc/shared/components';

import type { TokenManagerErrorResponse, TokenManagerResponse } from '@veeam-vspc/shared/services';
import type { LoginSsoFormState } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';
import { resetFormState } from '../../utils';

export const LoginSso = observer(() => {
    const { tokenManagerService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const loginStore = useLoginStore();
    const goToLoginStartButton = (
        <AlternativeButton
            iconBefore={<LoginUserIcon />}
            disabled={loginStore.isLoading}
            onClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
        >
            {lang.USE_LOCAL_USERS}
        </AlternativeButton>
    );

    return (
        <BaseFactor alternativeButton={goToLoginStartButton}>
            <LoginSsoForm
                title={lang.PLEASE_LOG_IN}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={({ login }: LoginSsoFormState) => {
                    const trimmedLogin = login.trim();

                    loginStore.setIsLoading(true);

                    tokenManagerService.authorizationCode(trimmedLogin)
                        .then((resp: TokenManagerResponse) => {
                            loginStore.onFinishFactorHandler(resp);
                            resetFormState(loginSsoFormInitialState);
                        })
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.setIsLoading(false);
                            loginStore.setError(errorDescription || lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT);
                        });
                }}
                texts={{
                    placeholderLogin: lang.EMAIL,
                    actionLogIn: lang.LOG_IN,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                }}
            />
        </BaseFactor>
    );
});
