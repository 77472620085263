/**
 * Copyright © Veeam Software Group GmbH.
 */

import { mergeWith } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { deepCopy, deepEqual } from '@veeam-vspc/shared/core';
import { CONTROL_SIZE, ValidationState } from '@veeam-vspc/shared/components';
import { NotificationSettings } from '@veeam-vspc/models/rest';

import { areSetsEqual } from 'core/utils';
import { customizeDataForSaving, getInitialConfigurationFormData, trimConfigurationFormData } from '../helpers';
import { ConfigurationFormData } from '../interfaces';

import type { AlarmsAdvancedSettingsStore } from '../components/AlarmsSettingsGroup/stores';

const CONTROL_SIZE_XS_MINUS = '110px';

export class ConfigurationFormStore {

    @observable changesAreSaved = false;
    readonly defaultInputSize = CONTROL_SIZE.xl;
    readonly defaultTimeInputSize = CONTROL_SIZE_XS_MINUS;
    readonly validationState = new ValidationState();

    @observable formData: ConfigurationFormData = getInitialConfigurationFormData({} as NotificationSettings);
    @observable initialData: NotificationSettings;
    @observable private initialFormData: ConfigurationFormData;

    @action.bound
        initiateStoreData = (data: NotificationSettings) => {
            this.initialData = deepCopy(data);
            this.initialFormData = getInitialConfigurationFormData(deepCopy(data));
            this.formData = getInitialConfigurationFormData(deepCopy(data));
        };

    @action.bound
    readonly onFormChange = (newFormData: ConfigurationFormData): void => {
            this.resetChangesAreSavedFlag();
            this.formData = trimConfigurationFormData(newFormData);
        };

    @action.bound
    readonly applyAlarmsAdvancedSettings = (
            newSorting: AlarmsAdvancedSettingsStore['dailySorting'],
            newStatusFilterSet: AlarmsAdvancedSettingsStore['dailyStatusFilter'],
        ): void => {
            this.formData.alarms.dailySorting = newSorting;

            // checking if filter elements were actually changed (and not only their order were changed)
            const initialStatusFilterSet = new Set(this.initialFormData.alarms.dailyStatusFilter);
            const filterHasChanged = !areSetsEqual(newStatusFilterSet, initialStatusFilterSet);

            if (filterHasChanged) {
                this.formData.alarms.dailyStatusFilter = [...newStatusFilterSet];
            } else {
                this.formData.alarms.dailyStatusFilter = [...initialStatusFilterSet];
            }
        };

    @action.bound
    readonly resetChangesAreSavedFlag = (): void => {
            if (this.changesAreSaved) {
                this.changesAreSaved = false;
            }
        };

    constructor() {
        makeObservable(this);
    }

    @computed
    get showSavedMsg(): boolean {
        return this.changesAreSaved && !this.formIsDirty;
    }

    @computed
    get formIsDirty(): boolean {
        return !deepEqual(this.initialFormData, this.formData);
    }

    @computed
    get dataForSaving(): NotificationSettings {
        const initialData = deepCopy(this.initialData);
        const formData = deepCopy(this.formData);

        return mergeWith(initialData, formData, customizeDataForSaving) as NotificationSettings;
    }

    @computed
    get dataForSmtpResetting(): NotificationSettings {
        const newFormData = deepCopy(this.initialData);

        newFormData.smtp = null;

        return newFormData;
    }
}
