/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';

import type { OAuth2Credential } from '@veeam-vspc/models/rest';

export interface OAuthTexts {
    azure: string;
    google: string;
}

export const getOAuthHostText = (credential: OAuth2Credential, texts: OAuthTexts): string => {
    const isGoogleType = credential.clientSettings.kind === OAuth2ClientSettingsKind.Google;
    const userText = credential.userId;
    const typeText = isGoogleType ? texts.google : texts.azure;

    return `${typeText} (${userText})`;
};
