/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { ManagedByConsole } from './components';

export interface ProtectedDataManagedByConsolePageProps {
    currentPath?: string;
}

export const ProtectedDataManagedByConsolePage: React.FC<ProtectedDataManagedByConsolePageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <ManagedByConsole stateId={currentPath} />
    </TabBarBodyLayout>
);
