/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { FileSharesPublicClouds } from './components';

export interface ProtectedDataFileSharesPublicCloudsPageProps {
    currentPath?: string;
}

export const ProtectedDataFileSharesPublicCloudsPage: React.FC<ProtectedDataFileSharesPublicCloudsPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <FileSharesPublicClouds stateId={currentPath} />
    </TabBarBodyLayout>
);
