/**
 * Copyright © Veeam Software Group GmbH.
 */

export const localToUtc = (utcDate: Date) => {
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth();
    const day = utcDate.getUTCDate();
    const hour = utcDate.getUTCHours();
    const minute = utcDate.getUTCMinutes();
    const second = utcDate.getUTCSeconds();
    return new Date(year, month, day, hour, minute, second, 0);
};
