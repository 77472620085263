/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type {
    BackupServerObjectStorageBackupJobSourceModel,
    BackupServerObjectStorageBackupJobSourceParam,
} from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { ObjectStorageDialogToolbar } from '../ObjectStorageDialogToolbar';
import { MAX_INTEGER_32 } from 'core/const';
import { useColumns } from './hooks';

const GRID_DATA_METHOD = 'GetObjectStorageBackupJobSources';
const GRID_DATA_URL = `/BackupServerJob/${GRID_DATA_METHOD}`;

interface ObjectStorageDialogProps {
    deactivate: () => void;
    title: string;
    jobUid: string;
    stateId: string;
}

export const ObjectStorageDialog: React.FC<ObjectStorageDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { deactivate, title, jobUid, stateId } = props;
    const { transportService } = useGlobalServices();
    const columns = useColumns(stateId);

    return (
        <Dialog
            header={title}
            size={{ width: DIALOG_SIZE.xxl, height: DIALOG_SIZE.xl }}
            onRequestClose={deactivate}
            actions={[{ text: lang.OK, onClick: deactivate }]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'status',
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns}
                    data={(requestParams: BackupServerObjectStorageBackupJobSourceParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<BackupServerObjectStorageBackupJobSourceModel[]>>(
                            `${GRID_DATA_URL}`,
                            {
                                ...requestParams,
                                jobUid,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ObjectStorageDialogToolbar
                                idProperty={'instanceUid'}
                                extraParams={{ jobUid }}
                                method={GRID_DATA_METHOD}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
