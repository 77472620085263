/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ComboboxKit,
    DatePeriod,
    Search,
    STACK_ALIGN,
    STACK_GAP,
    StackView, Text,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    useGridApi,
    useGlobalLang,
    ToolbarWithExport,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { AgentTaskStatus } from '@veeam-vspc/models/web-controllers';

import type { DatePeriodValue } from '@veeam-vspc/shared/components';
import type { AgentTask, CompanyShortData, AgentTaskName, ETaskTypeRepresentation, AgentTaskFilter } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getDatePeriod } from 'core/utils/date-helpers';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import successSrc from 'images/statuses/success.svg';
import runningSrc from 'images/statuses/running.svg';

import type { ToggleFilterStatusItem } from 'views/components/filters/StatusToggleFilter/interfaces';

interface TasksFilterToolbarProps {
    companiesList: CompanyShortData[];
    getCompaniesList: () => void;
    agentTaskNames: AgentTaskName[];
    getAgentTaskNames: () => void;
}

export const TasksFilterToolbar: React.FC<TasksFilterToolbarProps> = ({ companiesList, getCompaniesList, agentTaskNames, getAgentTaskNames }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<AgentTask, unknown, AgentTaskFilter>();
    const { dateFrom, dateTo, companyId, task } = gridApi.filters;
    const dateValue: DatePeriodValue = dateFrom || dateTo ? [new Date(gridApi.filters.dateFrom), new Date(gridApi.filters.dateTo)] : [];
    const { formats } = useGlobalAppData();

    const statuses: ToggleFilterStatusItem[] = [
        { title: lang.QUEUED, value: AgentTaskStatus.WaitToRun },
        { title: lang.COMPLETED, value: AgentTaskStatus.Completed, iconSrc: successSrc },
        { title: lang.CANCELED, value: AgentTaskStatus.Canceled },
        { title: lang.INITIATED, value: AgentTaskStatus.Running, iconSrc: runningSrc },
    ];

    return (
        <ToolbarWithExport
            view={TOOLBAR_VIEW_TYPE.spotted}
            lang={lang}
            method={'GetAgentTasks'}
            disableGlobalFilters={true}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='agentName'
                            placeholder={capitalize(lang.AGENT)}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                            <Text>{`${lang.COMPANY}:`}</Text>

                            <ComboboxKit
                                value={companyId || null}
                                data={[
                                    { name: lang.ALL_COMPANIES, id: null },
                                    ...companiesList,
                                ]}
                                textGetter={data => data.name}
                                valueGetter={data => data.id}
                                onChange={(companyId: number) => {
                                    gridApi.applyFilters({ companyId });
                                    getCompaniesList();
                                }}
                            />
                        </StackView>
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                            <Text>{`${lang.TASK}:`}</Text>

                            <ComboboxKit
                                value={task || null}
                                data={[
                                    { name: lang.ALL_TASKS, type: null },
                                    ...agentTaskNames,
                                ]}
                                textGetter={data => data.name}
                                valueGetter={data => data.type}
                                onChange={(task: ETaskTypeRepresentation) => {
                                    gridApi.applyFilters({ task });
                                    getAgentTaskNames();
                                }}
                            />
                        </StackView>
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.TYPE}
                            statuses={statuses}
                            propName={'statuses'}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <DatePeriod
                            format={formats.netShortDate}
                            value={dateValue}
                            onChange={(value) => {
                                if (value.length === 0) {
                                    const dateTo = new Date();
                                    dateTo.setUTCHours(21, 59, 59, 999);
                                    const dateFrom = new Date(dateTo.getTime() - (7 * 24 * 60 * 60 * 1000));
                                    dateFrom.setUTCHours(22, 0, 0, 0);
                                    gridApi.applyFilters({
                                        dateFrom: dateFrom.toISOString(),
                                        dateTo: dateTo.toISOString(),
                                    });
                                } else {
                                    gridApi.applyFilters(getDatePeriod(value));
                                }
                            }}
                            texts={{
                                allTimeLabel: capitalize(lang.LAST_7_DAYS),
                                allTimeValueText: capitalize(lang.LAST_7_DAYS),
                            }}
                        />
                    ),
                },
            ].filter(Boolean)}
        />
    );
};
