/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    Search,
    ConfiguredGrid,
    SortingMode,
    ConfiguredToolbar,
    useGlobalLang,
    GridSortDirections,
} from '@veeam-vspc/shared/components';

import type { VirtualServerTag } from '@veeam-vspc/models/rest';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';
import { scopesResourcesService } from '../../services/scopes-resources-service';

export const ContainersGrid: React.FC<{
    onSelectionChange: (containers: VirtualServerTag[]) => void;
    selected: VirtualServerTag[];
}> = ({ onSelectionChange, selected }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<VirtualServerTag, unknown, unknown>>();

    return (
        <ConfiguredGrid
            api={api}
            columns={[
                { id: 'name', title: lang.NAME, cell: rowData => rowData.name },
                {
                    id: 'type',
                    title: lang.TYPE,
                    cell: () => lang.TAG,
                    isSortable: false,
                },
                {
                    id: 'size',
                    title: lang.SIZE,
                    cell: rowData => rowData.size,
                    isSortable: false,
                },
            ]}
            data={scopesResourcesService.getContainers(
                wizardStore.serverUid,
                wizardStore.selectedCompanyVC.hostUid,
                wizardStore.selectedCompany.companyUid,
                api.current,
            )}
            defaultColumnMinWidth={100}
            disableAutoUpdate
            sortingMode={SortingMode.Remote}
            toolbars={[
                () => (
                    <ConfiguredToolbar
                        view={TOOLBAR_VIEW_TYPE.spotted}
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='nameFilter'
                                        placeholder={lang.NAME}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
            selection={{
                field: 'urn',
                checkbox: true,
                multiple: true,
            }}
            disableAllPageSelection
            isRowDisabled={rowData => wizardStore.isItemSelectedForScope(rowData.urn)}
            onSelectionChange={onSelectionChange}
            initialState={{
                selected,
                sort: {
                    direction: GridSortDirections.Asc,
                    key: 'name',
                },
            }}
        />
    );
};
