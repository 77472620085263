/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { EmptyPlate } from 'views/components/ChartWidget/components/EmptyPlate';
import { ChartWidget } from 'views/components/ChartWidget';
import { TREND_WIDGET_HEIGHT } from '../BaseTrendChart/const';

export const NotApplicablePlate = ({ title }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ChartWidget
            style={{ height: TREND_WIDGET_HEIGHT }}
            title={title}
        >
            <EmptyPlate emptyText={lang.NOT_APPLICABLE} />
        </ChartWidget>
    );
};
