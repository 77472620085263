/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { ACTION_VIEW, MessageBox, MESSAGE_BOX_ICON_VIEW, useGlobalLang, useModal } from '@veeam-vspc/shared/components';

import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { SmtpSettingsServerCertificate } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { CertInfoDialog } from './CertInfoDialog';
import { useTestedSmtpSettings } from '../../context';

import type { UntrustedCertDialogData } from '../../interfaces';

export interface UntrustedCertificateDialogProps extends UntrustedCertDialogData{
    deactivateDialog: UseModalActionsProps['deactivate'];
}

export const UntrustedCertDialog: React.FC<UntrustedCertificateDialogProps> = observer((props) => {
    const lang = useGlobalLang<VspcLang>();
    const { deactivateDialog, onSaveCallback } = props;
    const { serverAddress, serverCertificate: certInfoData } = useTestedSmtpSettings();
    const message = formatStr(lang.CANNOT_VERIFY_THE_SECURITY, serverAddress);

    const [certificateInfoDialog, certificateInfoDialogActions] = useModal<SmtpSettingsServerCertificate>({
        render: ({ deactivate, data }) => <CertInfoDialog deactivateDialog={deactivate} {...data} />,
    });

    const showCertInfo = (certInfo: SmtpSettingsServerCertificate) => (certInfo && certificateInfoDialogActions.activate(certInfo));

    const saveChanges = (deactivateModal: UseModalActionsProps['deactivate']) => {
        onSaveCallback();
        deactivateModal();
    };

    return (
        <>
            {certificateInfoDialog}

            <MessageBox
                header={lang.UNTRUSTED_SECURITY_CERTIFICATE}
                iconView={MESSAGE_BOX_ICON_VIEW.info}
                onRequestClose={deactivateDialog}
                actions={[
                    { text: `${lang.VIEW}...`, onClick: () => showCertInfo(certInfoData) },
                    { text: lang.CONTINUE, view: ACTION_VIEW.secondary, onClick: () => saveChanges(deactivateDialog) },
                    { text: lang.CANCEL, view: ACTION_VIEW.secondary, onClick: deactivateDialog },
                ]}
            >
                {message}
            </MessageBox>
        </>
    );
});
