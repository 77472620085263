/**
 * Copyright © Veeam Software Group GmbH.
 */

export const matchDataLengthWithCategories = ({ series, categories }) => {
    if (series && categories) {
        series.forEach(({ data }) => {
            if (data && Array.isArray(data)) {
                data.length = categories.length;
            }
        });
    }
};
