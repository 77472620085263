/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedFileSharesService } from './services/protected-file-shares.service';

export const ProtectedFileShares: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const title = capitalize(lang.UNSTRUCTURED_DATA);
    const protectedFileSharesService = useMemo(() => new ProtectedFileSharesService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400, palette.Grn200],
        yTitle: title,
    };

    return (
        <BaseTrendChart
            params={params}
            title={{ text: title }}
            service={protectedFileSharesService}
        />
    );
};
