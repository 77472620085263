/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { FieldLayout, RadiobuttonKit } from '@veeam-vspc/shared/components';

import type { NotificationAlarmsSettingsDailySorting } from '@veeam-vspc/models/rest';

import { useAdvancedSettingsStore } from '../../stores';

export interface SortingRadiobuttonItem {
    checked: boolean;
    label: string;
    value: NotificationAlarmsSettingsDailySorting;
}

export interface SortingRadiobuttonProps {
    items: SortingRadiobuttonItem[];
}

export const SortingRadioButtons: React.FC<SortingRadiobuttonProps> = observer((props) => {
    const { items } = props;
    const advancedSettingsStore = useAdvancedSettingsStore();

    return (
        <>
            {items.map(item => (
                <FieldLayout key={item.value}>
                    <RadiobuttonKit
                        checked={item.checked}
                        onChange={(newValue: NotificationAlarmsSettingsDailySorting): void => {
                            advancedSettingsStore.dailySorting = newValue;
                        }}
                        value={item.value}
                    >
                        {item.label}
                    </RadiobuttonKit>
                </FieldLayout>
            ))}
        </>
    );
});
