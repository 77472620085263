/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { UserMfaStatuses } from 'views/pages/RolesAndUsersPage/components/Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { StepLayout } from 'components/layouts/StepLayout';
import { CompanyGrid } from './components/CompanyGrid';

import type { Repository } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/repository';
import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

const Company = (stepData: WizardStepData<CompanyUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, notificationService } = useGlobalServices();
    const { data, onDataChange } = stepData;

    if (stepData.validationState.isForce()) {
        stepData.validationState.markAsHandledForce();

        if (!stepData.data.companyID) {
            notificationService.error(lang.ERROR, [lang.COMPANY_IS_NOT_SET]);
        }
    }

    useEffect(() => {
        async function checkMfaPolicy() {
            const { data: isMfaPolicyEnforced }: any = await transportService.request('/User/IsEnforcedMfaPolicy', { companyId: data.companyID });

            data.mfaPolicyStatus = isMfaPolicyEnforced ? UserMfaStatuses.NotConfigured : UserMfaStatuses.Disabled;
            data.isAllowedToSwitchMfaPolicyStatus = !isMfaPolicyEnforced;

            onDataChange(data);
        }

        if (data.companyID) {
            checkMfaPolicy();
        }

    }, [data.companyID]);

    useEffect(() => {
        async function checkRepositories() {
            const { data: repositories } = await transportService.request<{ companyId: number; }, RequestSuccessResponse<Repository[]>>(
                '/Company/GetTenantRepositories',
                { companyId: data.companyID }
            ) as RequestSuccessResponse<Repository[]>;

            data.repositories = repositories.length > 0 ? repositories : [];

            onDataChange(data);
        }

        if (data.companyID) {
            checkRepositories();
        }

    }, [data.companyID]);


    return (
        <StepLayout
            title={lang.COMPANY}
            description={lang.SELECT_COMPANY_THAT_WILL_BE_ASSIGNED}
        >
            <CompanyGrid {...stepData} />
        </StepLayout>
    );
};

export const getCompanyStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ isEdit, data }) => data.isCompanyAdminPortal || isEdit,
    validate: ({ data }) => !!data.companyID,
    render: data => <Company {...data} />,
});
