/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatDate } from '@veeam-vspc/shared/helpers';

export function formatStringTime(time: string, format: string, utc?: boolean): string {
    // eslint-disable-next-line prefer-const
    let [h, m] = time.split(':').map(Number);

    if (utc) {
        m = m - (new Date().getTimezoneOffset());
    }

    const date = new Date();
    date.setHours(h, m);

    return formatDate(date, format);
}
