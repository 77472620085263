/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    toolbarItemDecorators,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { processDecoratorsForDisabledByPriority } from '@veeam-vspc/shared/helpers';

import type { BackupServerBackupJobVmwareObjectSize } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import removeIcon from 'images/actions/remove.svg';
import { useJobWizardStore } from '../../../../stores';

import type { BackupOptionsTarget } from '../../../../enums';

interface BackupOptionsToolbarProps {
    onRemove: (items: BackupServerBackupJobVmwareObjectSize[]) => void;
    viewType: BackupOptionsTarget;
}

export const BackupOptionsToolbar: React.FC<BackupOptionsToolbarProps> = ({
    onRemove,
    viewType,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<BackupServerBackupJobVmwareObjectSize, unknown, unknown>();
    const wizardStore = useJobWizardStore();

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.default}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            maxLength={100}
                            placeholder={lang.OBJECT}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: removeIcon,
                    text: lang.REMOVE,
                    onClick: () => {
                        const { exclude, onlyThese } = gridApi.requestParamsForActions;
                        let itemsToDelete;

                        if (onlyThese.length) {
                            itemsToDelete = wizardStore[viewType]
                                .filter(({ inventoryObject }) => (onlyThese as string[]).includes(inventoryObject.objectId));
                        }

                        if (exclude.length) {
                            itemsToDelete = wizardStore[viewType]
                                .filter(({ inventoryObject }) => !(exclude as string[]).includes(inventoryObject.objectId));
                        }

                        if (onlyThese.length === 0 && exclude.length === 0) {
                            itemsToDelete = wizardStore[viewType];
                        }

                        onRemove(itemsToDelete);
                    },
                    decorators: [
                        processDecoratorsForDisabledByPriority([
                            toolbarItemDecorators.disallowWithoutSelection(),
                        ]),
                    ],
                },
            ]}
        />
    );
};
