/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { JobStateRepresentation, JobTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { ToolbarProps, ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters';
import dataBackupIcon from 'images/filters/job-types/object-storage-data-backup.svg';
import dataBackupCopyIcon from 'images/filters/job-types/object-storage-data-backup-copy.svg';

const JOB_TYPE_FILTER_NAME = 'types';

interface DataBackupObjectStorageFilterToolbarProps extends Omit<ToolbarProps, 'items'> {}

export const DataBackupObjectStorageFilterToolbar: React.FC<DataBackupObjectStorageFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: JobStateRepresentation.Success },
        { title: lang.WARNING, value: JobStateRepresentation.Warning },
        { title: lang.FAILED, value: JobStateRepresentation.Failed },
        { title: lang.RUNNING, value: JobStateRepresentation.Running },
        {
            title: lang.INFORMATION,
            value: [
                JobStateRepresentation.None,
                JobStateRepresentation.Idle,
                JobStateRepresentation.Starting,
                JobStateRepresentation.Stopping,
                JobStateRepresentation.Enabling,
                JobStateRepresentation.Disabling,
                JobStateRepresentation.WaitingTape,
                JobStateRepresentation.WaitingRepository,
            ],
        },
    ];

    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_JOBS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: dataBackupIcon,
                                label: lang.OBJECT_STORAGE_DATA_BACKUP,
                                value: {
                                    id: JobTypeRepresentation.ObjectStorageBackup,
                                    name: JOB_TYPE_FILTER_NAME,
                                },
                            },
                            {
                                iconSrc: dataBackupCopyIcon,
                                label: lang.OBJECT_STORAGE_DATA_BACKUP_COPY,
                                value: {
                                    id: JobTypeRepresentation.ObjectStorageBackupCopy,
                                    name: JOB_TYPE_FILTER_NAME,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <Search name='jobName' placeholder={lang.JOB} />,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <Search name='serverName' placeholder={lang.OBJECT_STORAGE} />,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <StatusToggleFilter statuses={statuses} />,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
