/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    SchedulingMatrix,
    HoursLegendHeader,
    SCHEDULING_MATRIX_SELECT_MOD,
    createSchedulingMatrixSelectModeFooter,
    useGlobalLang,
    Dialog,
    DIALOG_SIZE,
} from '@veeam-vspc/shared/components';
import { DaysOfWeek, type BackupServerBackupJobWindowSetting } from '@veeam-vspc/models/rest';

import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

interface ConfigureScheduleModalProps {
    deactivate: () => void;
    onConfirm: (data: BackupServerBackupJobWindowSetting) => void;
    value: BackupServerBackupJobWindowSetting;
}

const defaultRow = '0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0';

const toMatrix = (data: BackupServerBackupJobWindowSetting): boolean[][] => [
    (data.days.find(d => d.day === DaysOfWeek.Sunday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Monday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Tuesday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Wednesday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Thursday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Friday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
    (data.days.find(d => d.day === DaysOfWeek.Saturday)?.hours ?? defaultRow).split(',').map(v => Boolean(Number(v))),
];

const fromMatrix = (matrix: boolean[][]): BackupServerBackupJobWindowSetting => ({
    days: [
        { day: DaysOfWeek.Sunday, hours: matrix[0].map(x => Number(x)).join() },
        { day: DaysOfWeek.Monday, hours: matrix[1].map(x => Number(x)).join() },
        { day: DaysOfWeek.Tuesday, hours: matrix[2].map(x => Number(x)).join() },
        { day: DaysOfWeek.Wednesday, hours: matrix[3].map(x => Number(x)).join() },
        { day: DaysOfWeek.Thursday, hours: matrix[4].map(x => Number(x)).join() },
        { day: DaysOfWeek.Friday, hours: matrix[5].map(x => Number(x)).join() },
        { day: DaysOfWeek.Saturday, hours: matrix[6].map(x => Number(x)).join() },
    ],
});

export const ConfigureScheduleModal: FC<ConfigureScheduleModalProps> = ({ deactivate, onConfirm, value = { days: [] } }) => {
    const lang = useGlobalLang<VspcLang>();
    const [selectMode, setMode] = useState(SCHEDULING_MATRIX_SELECT_MOD.select);
    const [data, setData] = useState<boolean[][]>(() => toMatrix(value));

    const footer = createSchedulingMatrixSelectModeFooter({
        getValue: () => selectMode,
        onChange: setMode,
        onSelectAllCell() {
            setData(data.map(x => x.map(() => true)));
        },
        onUnSelectAllCell() {
            setData(data.map(x => x.map(() => false)));
        },
    });

    return (
        <Dialog
            header={lang.SELECT_TIME_PERIODS}
            actions={[
                {
                    text: lang.OK,
                    disabled: data.every(x => x.every(x => x === false)),
                    onClick: () => {
                        onConfirm(fromMatrix(data));
                        deactivate();
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
            size={{
                width: DIALOG_SIZE.l,
                height: DIALOG_SIZE.xl,
            }}
        >
            <SchedulingMatrix
                legendHeader={{
                    // sideOffset: 8,
                    render: () => <HoursLegendHeader />,
                }}
                columns={SchedulingMatrix.columnPresets.timeRange}
                rows={[
                    { title: lang.SUNDAY },
                    { title: lang.MONDAY },
                    { title: lang.TUESDAY },
                    { title: lang.WEDNESDAY },
                    { title: lang.THURSDAY },
                    { title: lang.FRIDAY },
                    { title: lang.SATURDAY },
                ]}
                mode={selectMode}
                data={data}
                onSelect={(items) => {
                    items.forEach(({ rowIndex, columnIndex }) => {
                        data[rowIndex][columnIndex] = selectMode === SchedulingMatrix.modes.select;
                    });
                    setData([...data]);
                }}
                footer={{
                    ...footer,
                }}
            />
        </Dialog>
    );
};
