/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    CheckboxKit,
    Combobox,
    CONTROL_SIZE,
    Text,
    TEXT_WHITE_SPACE,
    FieldLayout,
    Form,
    FormLayout,
    FormTitle,
    FormValidator,
    isValid,
    LabelGroup,
    MultipleChoice,
    NoLabelGroup,
    RadiobuttonKit,
    STACK_DIRECTION,
    TextInput,
    useExternalFormApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize, emailOrUserGroupListValidator } from '@veeam-vspc/shared/helpers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { TimeField } from 'components/controls/TimeField';
import calendarSrc from 'images/calendar.svg';
import { DailyKindsEnums, DayInMonth, DayOfWeek, Months, ReportAggregationModeRepresentation, ReportScheduleType } from '../../enums';
import { ToggleInfo } from './controls';
import { useReportStore } from '../../hooks';

import type { ReportModel, Timezone } from '../../interfaces';

export const stepValidate = (data: ReportModel, lang?) => {
    const validator = new FormValidator(data);

    if (data.schedule.type === ReportScheduleType.Daily && DailyKindsEnums.SelectedDays === data.schedule.daily.kind) {
        validator
            .validate('schedule.daily.days')
            .string()
            .check((val) => {
                // val is array here
                if (val.length === 0) {
                    return 'error';
                }
            });
    }

    if (data.schedule.type === ReportScheduleType.Monthly) {
        validator
            .validate('schedule.monthly.months')
            .string()
            .check((val) => {
                // val is array here
                if (val.length === 0) {
                    return 'error';
                }
            });
    }

    validator
        .validate('parameters.emailOptions')
        .string()
        .check((val) => {
            if (val) {
                return emailOrUserGroupListValidator(val, { wrongFormatError: lang ? lang.INVALID_EMAIL_FORMAT : '-' });
            }
        });

    return validator.result();
};

export const getScheduleStep = (title: string): WizardStep<ReportModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <ScheduleStep {...data} />,
});

const ScheduleStep = ({ data, validationState, onDataChange }: WizardStepData<ReportModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useExternalFormApi();
    const reportStore = useReportStore();
    const [isScheduled, setIsScheduled] = useState(data.schedule.type !== ReportScheduleType.NotScheduled);
    const [timezones, setTimezones] = useState<Timezone[]>([]);
    const [innerScheduledType, setInnerScheduledType] = useState<ReportScheduleType>(
        () => data.schedule.type === ReportScheduleType.NotScheduled ? ReportScheduleType.Daily : data.schedule.type,
    );
    const allDayNumbers = Array(32).fill(0)
        .map(
            (val, i) => ({
                id: i + 1,
                name: i < 31 ? (i + 1).toString() : lang.LAST,
            }),
        );

    const toggleScheduled = (newScheduled: boolean) => {
        setIsScheduled(newScheduled);

        if (!newScheduled) {
            formApi.setValue('schedule.type', ReportScheduleType.NotScheduled);
        } else {
            formApi.setValue('schedule.type', innerScheduledType);
        }
    };

    const changeScheduleType = (val: ReportScheduleType) => {
        setInnerScheduledType(val);
        formApi.setValue('schedule.type', val);
    };

    useEffect(() => {
        reportStore.timezoneListCache.load()
            .then(items => setTimezones(items));
    }, []);

    const iconPropAsSpread = {
        iconBefore: calendarSrc,
    };

    const isAggregated = data.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany;
    const helpText = reportStore.getScheduleHint(isAggregated);

    return (
        <StepLayout
            title={capitalize(lang.SCHEDULE)}
            description={lang.SELECT_SCHEDULING_OPTIONS_FOR_THE}
        >
            <Form
                value={data}
                validate={formData => stepValidate(formData as ReportModel, lang)}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.SCHEDULING_OPTIONS}</FormTitle>

                    <NoLabelGroup
                        control={(
                            <div>
                                <CheckboxKit
                                    checked={isScheduled}
                                    onChange={toggleScheduled}
                                >
                                    {lang.GENERATE_THIS_REPORT_AUTOMATICALLY}
                                </CheckboxKit>
                            </div>
                        )}
                    >
                        <FormLayout
                            inlineLabel
                            disabled={!isScheduled}
                        >
                            <LabelGroup
                                label={(
                                    <RadiobuttonKit
                                        value={ReportScheduleType.Daily}
                                        checked={innerScheduledType === ReportScheduleType.Daily}
                                        onChange={changeScheduleType}
                                    >
                                        {`${lang.DAILY_AT}:`}
                                    </RadiobuttonKit>
                                )}
                            >
                                <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                                    <TimeField
                                        name='schedule.daily.time'
                                        shortFormat
                                        disabled={data.schedule.type !== ReportScheduleType.Daily}
                                    />

                                    <Combobox
                                        name={'schedule.daily.kind'}
                                        data={[
                                            {
                                                id: DailyKindsEnums.EveryDay,
                                                text: lang.EVERYDAY,
                                            }, {
                                                id: DailyKindsEnums.WeekDays,
                                                text: lang.ON_WEEK_DAYS,
                                            }, {
                                                id: DailyKindsEnums.SelectedDays,
                                                text: lang.ON_THESE_DAYS,
                                            },
                                        ]}
                                        valueGetter={item => item.id}
                                        textGetter={item => item.text}
                                        disabled={data.schedule.type !== ReportScheduleType.Daily}
                                    />

                                    <FieldLayout
                                        disabled={
                                            data.schedule.type !== ReportScheduleType.Daily
                                            || data.schedule.daily.kind !== DailyKindsEnums.SelectedDays
                                        }
                                    >
                                        <MultipleChoice
                                            error={data.schedule.daily.days.length === 0}
                                            titleFormatter={() => lang.DAYS}
                                            value={data.schedule.daily.days}
                                            {...iconPropAsSpread}
                                            data={[
                                                {
                                                    id: DayOfWeek.Monday,
                                                    name: lang.MONDAY,
                                                }, {
                                                    id: DayOfWeek.Tuesday,
                                                    name: lang.TUESDAY,
                                                }, {
                                                    id: DayOfWeek.Wednesday,
                                                    name: lang.WEDNESDAY,
                                                }, {
                                                    id: DayOfWeek.Thursday,
                                                    name: lang.THURSDAY,
                                                }, {
                                                    id: DayOfWeek.Friday,
                                                    name: lang.FRIDAY,
                                                }, {
                                                    id: DayOfWeek.Saturday,
                                                    name: lang.SATURDAY,
                                                }, {
                                                    id: DayOfWeek.Sunday,
                                                    name: lang.SUNDAY,
                                                },
                                            ]}
                                            onChange={(values) => {
                                                formApi.setValue('schedule.daily.days', values);
                                            }}
                                        />
                                    </FieldLayout>
                                </FormLayout>
                            </LabelGroup>

                            <LabelGroup
                                label={(
                                    <RadiobuttonKit
                                        value={ReportScheduleType.Monthly}
                                        checked={innerScheduledType === ReportScheduleType.Monthly}
                                        onChange={changeScheduleType}
                                    >
                                        {lang.MONTHLY_AT}
                                    </RadiobuttonKit>
                                )}
                            >
                                <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                                    <TimeField
                                        name='schedule.monthly.time'
                                        shortFormat
                                        disabled={data.schedule.type !== ReportScheduleType.Monthly}
                                    />

                                    <Combobox
                                        name={'schedule.monthly.week'}
                                        data={[
                                            {
                                                id: DayInMonth.First,
                                                text: lang.FIRST,
                                            }, {
                                                id: DayInMonth.Second,
                                                text: lang.SECOND,
                                            }, {
                                                id: DayInMonth.Third,
                                                text: lang.THIRD,
                                            }, {
                                                id: DayInMonth.Fourth,
                                                text: lang.FOURTH,
                                            }, {
                                                id: DayInMonth.Last,
                                                text: lang.LAST,
                                            }, {
                                                id: DayInMonth.Day,
                                                text: lang.THIS_DAY,
                                            },
                                        ]}
                                        valueGetter={item => item.id}
                                        textGetter={item => item.text}
                                        disabled={data.schedule.type !== ReportScheduleType.Monthly}
                                    />

                                    {data.schedule.monthly.week !== DayInMonth.Day && (
                                        <Combobox
                                            name={'schedule.monthly.day'}
                                            data={[
                                                {
                                                    id: DayOfWeek.Monday,
                                                    name: lang.MONDAY,
                                                }, {
                                                    id: DayOfWeek.Tuesday,
                                                    name: lang.TUESDAY,
                                                }, {
                                                    id: DayOfWeek.Wednesday,
                                                    name: lang.WEDNESDAY,
                                                }, {
                                                    id: DayOfWeek.Thursday,
                                                    name: lang.THURSDAY,
                                                }, {
                                                    id: DayOfWeek.Friday,
                                                    name: lang.FRIDAY,
                                                }, {
                                                    id: DayOfWeek.Saturday,
                                                    name: lang.SATURDAY,
                                                }, {
                                                    id: DayOfWeek.Sunday,
                                                    name: lang.SUNDAY,
                                                },
                                            ]}
                                            valueGetter={item => item.id}
                                            textGetter={item => item.name}
                                            disabled={data.schedule.type !== ReportScheduleType.Monthly}
                                        />
                                    )}

                                    {data.schedule.monthly.week === DayInMonth.Day && (
                                        <Combobox
                                            name={'schedule.monthly.dayNumber'}
                                            data={allDayNumbers}
                                            valueGetter={item => item.id}
                                            textGetter={item => item.name}
                                            disabled={data.schedule.type !== ReportScheduleType.Monthly}
                                        />
                                    )}

                                    <FieldLayout
                                        disabled={data.schedule.type !== ReportScheduleType.Monthly}
                                    >
                                        <MultipleChoice
                                            error={data.schedule.monthly.months.length === 0}
                                            titleFormatter={() => lang.MONTHS}
                                            value={data.schedule.monthly.months}
                                            {...iconPropAsSpread}
                                            data={[
                                                {
                                                    id: Months.January,
                                                    name: lang.JANUARY,
                                                }, {
                                                    id: Months.February,
                                                    name: lang.FEBRUARY,
                                                }, {
                                                    id: Months.March,
                                                    name: lang.MARCH,
                                                }, {
                                                    id: Months.April,
                                                    name: lang.APRIL,
                                                }, {
                                                    id: Months.May,
                                                    name: lang.MAY,
                                                }, {
                                                    id: Months.June,
                                                    name: lang.JUNE,
                                                }, {
                                                    id: Months.July,
                                                    name: lang.JULY,
                                                }, {
                                                    id: Months.August,
                                                    name: lang.AUGUST,
                                                }, {
                                                    id: Months.September,
                                                    name: lang.SEPTEMBER,
                                                }, {
                                                    id: Months.October,
                                                    name: lang.OCTOBER,
                                                }, {
                                                    id: Months.November,
                                                    name: lang.NOVEMBER,
                                                }, {
                                                    id: Months.December,
                                                    name: lang.DECEMBER,
                                                },
                                            ]}
                                            columnSize={6}
                                            onChange={(values) => {
                                                formApi.setValue('schedule.monthly.months', values);
                                            }}
                                        />
                                    </FieldLayout>
                                </FormLayout>
                            </LabelGroup>

                            <Combobox
                                name={'schedule.timeZoneId'}
                                label={lang.TIME_ZONE}
                                data={timezones}
                                valueGetter={item => item.id}
                                textGetter={item => item.description}
                                size={'616px'}
                            />
                        </FormLayout>
                    </NoLabelGroup>

                    <FormTitle>{lang.NOTIFICATION_OPTIONS}</FormTitle>

                    <TextInput
                        name='parameters.emailOptions'
                        label={lang.SEND_REPORTS_TO_THE_FOLLOWING_EMAIL}
                        size={CONTROL_SIZE.l}
                        onChange={() => {
                            reportStore.isDefaultEmailOptions = false;
                        }}
                    />

                    {helpText && (
                        <NoLabelGroup
                            content={(
                                <ToggleInfo>
                                    <Text whitespace={TEXT_WHITE_SPACE.pre}>
                                        {helpText}
                                    </Text>
                                </ToggleInfo>
                            )}
                        />
                    )}
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
