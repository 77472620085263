/**
 * Copyright © Veeam Software Group GmbH.
 */

import { JobSessionTaskStatusRepresentation } from './job-session-task-status-representation';

export const JobSessionTaskStatusRepresentationText = {
    [JobSessionTaskStatusRepresentation.Success]: 'Success',
    [JobSessionTaskStatusRepresentation.Unknown]: 'Unknown',
    [JobSessionTaskStatusRepresentation.Failed]: 'Failed',
    [JobSessionTaskStatusRepresentation.Warning]: 'Warning',
    [JobSessionTaskStatusRepresentation.InProgress]: 'Running...',
    [JobSessionTaskStatusRepresentation.Pending]: 'Pending',
};
