/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import {
    EPublicCloudRestoreType,
    PublicCloudVmRestoreType,
} from '@veeam-vspc/models/web-controllers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { PublicCloudProtectedVmModel, PublicCloudProtectedVmParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { PublicCloudsActionToolbar, PublicCloudsColumn, PublicCloudsFilterToolbar } from './components';
import { FlrPortalColumn } from '../../../FlrPortalColumn';
import { ConfigSectionIds } from 'core/enums';


export interface PublicCloudsProps {
    stateId: string;
}

export const PublicClouds: React.FC<PublicCloudsProps> = ({ stateId }) => {
    const gridStateId = `${stateId}.PublicClouds`;
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();

    const columns: GridColumnProps<PublicCloudProtectedVmModel>[] = [
        {
            id: 'vmName',
            title: lang.VM,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
        },
        {
            id: 'platformType',
            title: lang.PLATFORM,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platformType)}
                </TextColumn>
            ),
        },
        {
            id: 'restoreStatusModel.status',
            title: lang.FILE_LEVEL_RESTORE_PORTAL,
            cell: rowData => (
                <FlrPortalColumn
                    instanceUid={rowData.instanceUid}
                    vbServerId={rowData.vbServerId}
                    restoreType={EPublicCloudRestoreType.RestoreVmFileLevel}
                    restoreStatusModel={rowData.restoreStatusModel}
                />
            ),
        },
        {
            id: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServerName,
            hidden: true,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.sourceSize} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.backupCount',
            title: lang.BACKUPS,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.backup?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Backup`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Backup,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.lastBackup',
            title: lang.LAST_BACKUP,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backup?.lastBackup}
                />
            ),
        },
        {
            id: 'backup.backupSize',
            title: lang.BACKUP_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.backup?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backup.destination',
            title: capitalize(lang.BACKUP_TARGET),
            cell: rowData => rowData.backup?.destination,
            hidden: true,
        },
        {
            id: 'snapshot.backupCount',
            title: lang.SNAPSHOTS,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.snapshot?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Snapshot`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Snapshot,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'snapshot.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.snapshot?.lastBackup}
                />
            ),
        },
        {
            id: 'snapshot.destination',
            title: lang.VM_REGION,
            cell: rowData => rowData.snapshot?.destination,
            hidden: true,
        },
        {
            id: 'remoteSnapshot.backupCount',
            title: lang.REPLICA_SNAPSHOTS,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.remoteSnapshot?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.RemoteSnapshot`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.RemoteSnapshot,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'remoteSnapshot.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.remoteSnapshot?.lastBackup}
                />
            ),
        },
        {
            id: 'remoteSnapshot.destination',
            title: lang.REPLICA_SNAPSHOT_REGION,
            cell: rowData => rowData.remoteSnapshot?.destination,
            hidden: true,
        },
        {
            id: 'archive.backupCount',
            title: lang.ARCHIVES,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.archive?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.PublicCloudArchive`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Archive,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'archive.lastBackup',
            title: lang.LAST_ARCHIVE,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.archive?.lastBackup}
                />
            ),
        },
        {
            id: 'archive.backupSize',
            title: lang.ARCHIVE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.archive?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'archive.destination',
            title: lang.ARCHIVE_TARGET,
            cell: rowData => rowData.archive?.destination,
            hidden: true,
        },
        {
            id: 'backupCopy.backupCount',
            title: lang.BACKUP_COPIES,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.backupCopy?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.BackupCopy`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Copy,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backupCopy.lastBackup',
            title: lang.LAST_BACKUP_COPY,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.backupCopy?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'backupCopy.backupSize',
            title: lang.BACKUP_COPY_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.backupCopy?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backupCopy.destination',
            title: lang.BACKUP_COPY_TARGET,
            cell: rowData => rowData.backupCopy?.destination,
            hidden: true,
        },
        {
            id: 'tape.backupCount',
            title: lang.BACKUPS_ON_TAPE,
            cell: rowData => (
                <PublicCloudsColumn
                    value={rowData.tape?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Tape`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Tape,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'tape.lastBackup',
            title: lang.LAST_BACKUP_ON_TAPE,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.tape?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            id: 'tape.backupSize',
            title: lang.BACKUPS_ON_TAPE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.tape?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'tape.destination',
            title: lang.TAPE_MEDIA_POOL,
            cell: rowData => rowData.tape?.destination,
            hidden: true,
        },
        {
            id: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.resourceId,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataVirtualMachinesPublicClouds}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            defaultColumnMinWidth={100}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudProtectedVmParam['filter']>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<PublicCloudProtectedVmModel[]>>('/PublicCloudVm/GetProtectedVms', {
                    ...requestParams,
                })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <PublicCloudsFilterToolbar />,
                () => <PublicCloudsActionToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
