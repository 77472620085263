/**
 * Copyright © Veeam Software Group GmbH.
 */

import { delay } from '@veeam-vspc/shared/core';

import type { NotificationService } from '@veeam-vspc/shared/services';

import type { VspcLang } from '../../../configs/languages';
import type { ExtApplicationModel } from '../../../views/components/Ext/ExtComponent';

export function waitFor<T>(obj: Object, field: string, condition?: (obj: T) => boolean): { promise: Promise<T>; cancel: () => void; } {
    let interval;
    const cancel = () => window.clearInterval(interval);

    const conditionFn = condition
        ? () => Boolean(obj[field]) && condition(obj[field])
        : () => Boolean(obj[field]);

    return {
        promise: new Promise((resolve) => {
            if (conditionFn()) {
                resolve(obj[field]);
            }

            interval = window.setInterval(() => {
                if (conditionFn()) {
                    resolve(obj[field]);
                    cancel();
                }
            }, 50);
        }),
        cancel,
    };
}

export const startExtDialog = (lang: VspcLang, notificationService: NotificationService, callback: Function): Promise<void> => {
    const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

    return promise
        .then(() => delay(500))
        .then(() => callback())
        .catch(() => (
            notificationService.error(lang.ERROR, lang.AN_ERROR_OCCURRED, { canClose: false })
                .then(() => window.location.reload())
        ));
};
