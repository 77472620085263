/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { WizardSummary, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { BackupServerBackupJobRetentionPolicyType, BackupServerBackupJobPeriodicallyKindsNullable } from '@veeam-vspc/models/rest';
import { capitalize, plural } from '@veeam-vspc/shared/helpers';

import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../stores';
import { StepLayout } from 'components/layouts/StepLayout';
import { getGfsPolicyHelpText } from '../utils';

export const getSummaryStep = (lang: VspcLang): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: lang.SUMMARY,
    render: data => <SummaryStep {...data} />,
});

const SummaryStep = observer(({ isEdit }: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();

    const jobData = wizardStore.data;
    const protectedObjectsLength = jobData.virtualMachines.includes.length;
    const excludedObjectsLength = jobData.virtualMachines.excludes?.vms?.length;

    const getPeriodicallyEveryText = () => {
        let value = '';
        if (jobData.schedule.periodically.periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Days) {
            value = plural(jobData.schedule.periodically.frequency, lang.DAY);
        }

        if (jobData.schedule.periodically.periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Hours) {
            value = plural(jobData.schedule.periodically.frequency, lang.HOUR);
        }

        if (jobData.schedule.periodically.periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Minutes) {
            value = plural(jobData.schedule.periodically.frequency, lang.MINUTE);
        }

        if (jobData.schedule.periodically.periodicallyKind === BackupServerBackupJobPeriodicallyKindsNullable.Seconds) {
            value = plural(jobData.schedule.periodically.frequency, lang.SECOND);
        }

        return `${lang.EVERY} ${value}`;
    };

    const getScheduleText = () => {
        if (!jobData.schedule.runAutomatically) {
            return ColumnTexts.DashSymbol;
        }

        if (jobData.schedule.daily.isEnabled) {
            return `${lang.DAILY_AT} ${jobData.schedule.daily.localTime}`;
        }

        if (jobData.schedule.monthly.isEnabled) {
            return `${lang.MONTHLY_AT} ${jobData.schedule.monthly.localTime}`;
        }

        if (jobData.schedule.continuously.isEnabled) {
            return lang.CONTINUOUSLY;
        }

        if (jobData.schedule.periodically.isEnabled) {
            return getPeriodicallyEveryText();
        }

        if (jobData.schedule.afterThisJob.isEnabled) {
            return `${lang.AFTER_THIS_JOB} ${jobData.schedule.afterThisJob.jobName}`;
        }
    };

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: lang.JOB_NAME,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: jobData.name || ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.DESCRIPTION}:`,
                                value: jobData.description || ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: lang.SCOPE,
                        fields: [
                            {
                                label: protectedObjectsLength === 1 ? `${lang.OBJECT_TO_PROTECT}:` : `${lang.OBJECTS_TO_PROTECT}:`,
                                value: jobData.virtualMachines.includes.length
                                    ? jobData.virtualMachines.includes.map(x => x.inventoryObject.name).join()
                                    : ColumnTexts.DashSymbol,

                            },
                            {
                                label: excludedObjectsLength === 1 ? `${lang.OBJECT_TO_EXCLUDE}:` : `${lang.OBJECTS_TO_EXCLUDE}:`,
                                value: jobData.virtualMachines.excludes.vms.length
                                    ? jobData.virtualMachines.excludes.vms.map(x => x.inventoryObject.name).join()
                                    : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: lang.STORAGE,
                        fields: [
                            {
                                label: `${lang.BACKUP_REPOSITORY}:`,
                                value: wizardStore.selectedRepository.name,
                            },
                            {
                                label: `${lang.RETENTION_POLICY}:`,
                                value: plural(wizardStore.data.storage.retentionPolicy.quantity,
                                    wizardStore.data.storage.retentionPolicy.type === BackupServerBackupJobRetentionPolicyType.Days
                                        ? lang.DAY
                                        : lang.RESTORE_POINT
                                ),
                            },
                            {
                                label: `${lang.KEEP_CERTAIN_FULL_BACKUPS_LONGER}:`,
                                value: wizardStore.data.storage.gfsPolicy.isEnabled
                                    ? getGfsPolicyHelpText(wizardStore.data, lang)
                                    : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.GUEST_PROCESSING, true),
                        fields: [
                            {
                                label: `${lang.ENABLE_APPLICATION_AWARE_PROCESSING}:`,
                                value: jobData.guestProcessing.appAwareProcessing.isEnabled ? lang.YES : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.ENABLE_GUEST_FILE_SYSTEM_INDEXING}:`,
                                value: jobData.guestProcessing.guestFSIndexing.isEnabled ? lang.YES : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.CREDENTIALS}:`,
                                value: wizardStore.areProcessingOptionsEnabled
                                    ? jobData.guestProcessing?.guestCredentials?.credentialsType
                                    : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: lang.JOB_SCHEDULE,
                        fields: [
                            {
                                label: `${lang.RUN_THE_JOB_AUTOMATICALLY}:`,
                                value: getScheduleText(),
                            },
                            {
                                label: `${lang.RETRY_FAILED_OBJECT_PROCESSING}:`,
                                value: jobData.schedule.runAutomatically && jobData.schedule.retry.isEnabled
                                    ? plural(jobData.schedule.retry.retryCount, lang.TIME.toLowerCase())
                                    : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.WAIT_BEFORE_EACH_RETRY}:`,
                                value: jobData.schedule.runAutomatically &&
                                    jobData.schedule.retry.isEnabled &&
                                    !jobData.schedule.continuously.isEnabled
                                    ? plural(jobData.schedule.retry.awaitMinutes, lang.MINUTE)
                                    : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.BACKUP_WINDOW}:`,
                                value: jobData.schedule.backupWindow.isEnabled ? lang.YES : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
});
