/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';
import { NotificationDefaultTitles, NotificationService } from '@veeam-vspc/shared/services';

import type { Vb365JobItemGroup, Vb365JobItemSite, Vb365JobItemTeam, Vb365JobItemUser } from '@veeam-vspc/models/rest';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { FetchMethod, GridStore, RequestParams } from '@veeam-vspc/shared/components';

import { alternateTransformRequestParams } from 'core/utils';
import { MAX_INTEGER_32 } from 'core/const';

import type { GroupsFilters, SitesFilters, TeamsFilters, UsersFilters } from '../interfaces';

const genericEmptyResponse = {
    data: [],
    meta: {
        pagingInfo: {
            total: 0,
            count: 0,
            offset: 0,
        },
    },
};

class BackupModeService {
    transportService: TransportService<RequestErrorResponse | Error>;
    notificationService: NotificationService;

    init(
        transportService: TransportService<RequestErrorResponse | Error>,
        notificationService: NotificationService,
    ) {
        this.transportService = transportService;
        this.notificationService = notificationService;
    }

    abortController = new AbortController();

    abortPreviousRequest() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    addWildcardToSearchQuery(params: RequestParams<unknown>) {
        if (!params.filter) {
            return params;
        }

        const target = deepCopy(params);
        target.filter = {};

        for (const key in params.filter) {
            if (params.filter[key] === undefined || params.filter[key] === '') {
                continue;
            }

            target.filter[key] = `*${params.filter[key]}*`;
        }

        return target;
    }

    calculateTotalCount(requestParams: RequestParams<unknown>, response: RequestSuccessResponse<unknown>) {
        const { pagingInfo } = response.meta;
        const { limit, page } = requestParams;

        // enable when there be an information about the end
        // return isEnd ? limit * page - (limit - pagingInfo.count) : MAX_INTEGER_32;
        return pagingInfo.count < limit ? limit * page - (limit - pagingInfo.count) : MAX_INTEGER_32;
    }

    showError(err) {
        const message = err.errors.length > 1 ? err.errors.map(error => ({
            text: error.message,
            listItem: true,
        })) : err.errors[0].message;

        this.notificationService.error(NotificationService.texts[NotificationDefaultTitles.Error], message);
    }

    getGroups(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemGroup, unknown, GroupsFilters>
    ): FetchMethod<Vb365JobItemGroup, GroupsFilters> {
        return (params: RequestParams<GroupsFilters>) => {
            this.abortPreviousRequest();

            return this.transportService
                .get<{}, RequestSuccessResponse<Vb365JobItemGroup[]>>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/groups/sync`,
                    alternateTransformRequestParams(this.addWildcardToSearchQuery(params)),
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                    },
                )
                .then((response: RequestSuccessResponse<Vb365JobItemGroup[]>) => {
                    response.meta.pagingInfo.total = this.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    this.showError(err);

                    return genericEmptyResponse;
                });
        };
    }

    getUsers(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemUser, unknown, UsersFilters>
    ): FetchMethod<Vb365JobItemUser, UsersFilters> {
        return (params: RequestParams<UsersFilters>) => {
            this.abortPreviousRequest();

            return this.transportService
                .get<{}, RequestSuccessResponse<Vb365JobItemUser[]>>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/users/sync`,
                    alternateTransformRequestParams(this.addWildcardToSearchQuery(params)),
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                    },
                )
                .then((response: RequestSuccessResponse<Vb365JobItemUser[]>) => {
                    response.meta.pagingInfo.total = this.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    this.showError(err);

                    return genericEmptyResponse;
                });
        };
    }

    getSites(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemSite, unknown, SitesFilters>
    ): FetchMethod<Vb365JobItemSite, SitesFilters> {
        return (params: RequestParams<SitesFilters>) => {
            this.abortPreviousRequest();

            return this.transportService
                .get<{}, RequestSuccessResponse<Vb365JobItemSite[]>>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/sites/sync`,
                    alternateTransformRequestParams(this.addWildcardToSearchQuery(params)),
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                    },
                )
                .then((response: RequestSuccessResponse<Vb365JobItemSite[]>) => {
                    response.meta.pagingInfo.total = this.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    this.showError(err);

                    return genericEmptyResponse;

                });
        };
    }

    getTeams(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemTeam, unknown, TeamsFilters>
    ): FetchMethod<Vb365JobItemTeam, TeamsFilters> {
        return (params: RequestParams<TeamsFilters>) => {
            this.abortPreviousRequest();

            return this.transportService
                .get<{}, RequestSuccessResponse<Vb365JobItemTeam[]>>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/teams/sync`,
                    alternateTransformRequestParams(this.addWildcardToSearchQuery(params)),
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                    },
                )
                .then((response: RequestSuccessResponse<Vb365JobItemTeam[]>) => {
                    response.meta.pagingInfo.total = this.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    this.showError(err);

                    return genericEmptyResponse;

                });
        };
    }
}

export const backupModeService = new BackupModeService();
