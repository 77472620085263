/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';

import { useExtRouteProviderStore } from './store/use-ext-route-provider-store';
import { ROLES_AND_USERS_PAGE, RolesAndUsersPage } from '../../pages/RolesAndUsersPage';

import type { RolesAndUsersProps } from '../../pages/RolesAndUsersPage/interfaces';

export const ExtRouteProvider = observer(() => {
    const store = useExtRouteProviderStore();
    const { pageName, pageProps } = store;

    switch (pageName) {
        case ROLES_AND_USERS_PAGE:
            return <RolesAndUsersPage {...pageProps as RolesAndUsersProps} />;
        default:
            return <div />;
    }
});
