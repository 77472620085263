/**
 * Copyright © Veeam Software Group GmbH.
 */

import dhondt from 'dhondt';

/**
 * Pass an array of absolute numbers to transform it to rounding percents
 */
export const calcRoundingPercents = (numbers: number[]): number[] => dhondt.compute(numbers, 100, { base: 0 });
