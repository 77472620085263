/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { CloudDatabases } from './components';

export interface BackupJobsCloudDatabasesPageProps {
    currentPath?: string;
}

export const BackupJobsCloudDatabasesPage: React.FC<BackupJobsCloudDatabasesPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <CloudDatabases stateId={currentPath} />
    </TabBarBodyLayout>
);
