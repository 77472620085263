/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { TabBarBodyLayout, useGlobalLang, useGlobalServices, useModal } from '@veeam-vspc/shared/components';
import { useFullPage } from '@veeam-vspc/shared/hooks';
import { BeforeLeave } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { ExtComponent } from '../../components/Ext/ExtComponent';
import { InstallAndUpdateVBRWizard } from './components/InstallAndUpdateVBRWizard';
import { ScheduledUpdatesDialog } from './components/ScheduledUpdatesDialog';
import { DiscoveryBackupStore } from './stores';
import { useNotificationBar } from '../../components/NotificationBar/hooks/use-notification-bar';

import type { ScheduledUpdatesDialogProps } from './components/ScheduledUpdatesDialog/interfaces';
import type { VbrWizardProps } from './components/InstallAndUpdateVBRWizard/interfaces';

export const DiscoveryBackupServersPage: React.FC<{}> = () => {
    const xtype = 'rcop.agents.grid';
    const { fileTransportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const notificationBar = useNotificationBar();
    const discoveryBackupStore = useMemo(() => (
        new DiscoveryBackupStore(lang, fileTransportService, BeforeLeave.getInstance(), notificationBar)
    ), []);

    const [installAndUpdateVbrWizard, installAndUpdateVbrWizardActions] = useFullPage<VbrWizardProps>({
        render: ({ onClose, data }) => (
            <InstallAndUpdateVBRWizard
                {...data}
                deactivate={onClose}
            />
        ),
    });
    const [scheduledUpdatesDialog, scheduledUpdatesDialogActions] = useModal<ScheduledUpdatesDialogProps>({
        render: ({ deactivate, data }) => (
            <ScheduledUpdatesDialog
                {...data}
                deactivate={deactivate}
            />
        ),
    });

    return (
        <TabBarBodyLayout>
            <ExtComponent
                xtype={xtype}
                extProps={{
                    installAndUpdateVbrWizardActions,
                    scheduledUpdatesDialogActions,
                    discoveryBackupStore,
                }}
            />

            {installAndUpdateVbrWizard}
            {scheduledUpdatesDialog}
        </TabBarBodyLayout>
    );
};
