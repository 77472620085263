/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import {
    DurationColumn,
    GridColumnJustify,

    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { BackupServerObjectStorageBackupJobSourceModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ObjectStorageStatusColumn } from '../../ObjectStorageStatusColumn';
import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';

type ColumnsContentType = GridColumnProps<BackupServerObjectStorageBackupJobSourceModel>[];

export const useColumns = (gridStateId: string): ColumnsContentType => {
    const lang = useGlobalLang<VspcLang>();

    return useMemo((): ColumnsContentType => (
        [
            {
                id: 'statusName',
                title: lang.STATUS,
                cell: rowData => (
                    <ObjectStorageStatusColumn
                        status={rowData.status}
                        statusName={rowData.statusName}
                        messages={rowData.messages}
                    />
                ),
            },
            {
                id: 'name',
                title: lang.OBJECT_STORAGE,
                width: 130,
                cell: rowData => rowData.name,
            },
            {
                id: 'sourceItemsCount',
                title: lang.FILES_AND_FOLDERS,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => (
                    <SourceItemsColumn
                        value={rowData.sourceItemsCount}
                        stateId={`${gridStateId}.Dialogs.SourceItems`}
                        name={rowData.name}
                        sourceItems={rowData.sourceItems}
                    />
                ),
            },
            {
                id: 'sourceFiles',
                title: lang.N_OF_FILES,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => rowData.sourceFiles || lang.N_A,
            },
            {
                id: 'changedFiles',
                title: lang.CHANGED_FILES,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => rowData.changedFiles,
            },
            {
                id: 'backedUpFilesCount',
                title: lang.TRANSFERRED_FILES,
                width: 145,
                cell: rowData => rowData.backedUpFiles,
            },
            {
                id: 'transferredData',
                title: lang.TRANSFERRED_DATA,
                hidden: true,
                headerJustify: GridColumnJustify.Right,
                justify: GridColumnJustify.Right,
                cell: rowData => <RestorePointSizeColumn value={rowData.transferredData} />,
            },
            {
                id: 'duration',
                title: lang.DURATION,
                cell: rowData => <DurationColumn value={rowData.duration} />,
            },
        ]
    ), [gridStateId, lang]);
};
