/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

const helpVersion = 80;

const getHelpResourceLink = (resourceId: string) => `https://redirect.veeam.com/helpvac${helpVersion}topicid=${resourceId}`;

const getRestorePointsHelpResource = (userRole: UserRoleRepresentation) => {
    let resourceId;

    if (
        userRole === UserRoleRepresentation.CompanyLocationAdministrator
        || userRole === UserRoleRepresentation.CompanyOwner
        || userRole === UserRoleRepresentation.CompanyAdministrator
        || userRole === UserRoleRepresentation.CompanyLocationUser
    ) {
        resourceId = '527';
    } else if (
        userRole === UserRoleRepresentation.ResellerOwner
        || userRole === UserRoleRepresentation.ResellerAdministrator
        || userRole === UserRoleRepresentation.ResellerOperator
    ) {
        resourceId = '526';
    } else {
        resourceId = '525';
    }

    return getHelpResourceLink(resourceId);
};

export {
    getHelpResourceLink,
    getRestorePointsHelpResource,
};
