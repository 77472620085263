/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Form,
    FormLayout,
    FormValidator,
    isValid,
    Radiobutton,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ReportAccessMode, ReportAggregationModeRepresentation } from '../../enums';
import { useReportStore } from '../../hooks';

import type { ReportModel } from '../../interfaces';

export const stepValidate = (data: ReportModel): FormErrors<ReportModel> => {
    const validator = new FormValidator(data);

    return validator.result();
};

export const getAccessModeStep = (title: string, isCompanyRole: boolean): WizardStep<ReportModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    isHidden: ({ data }) => isCompanyRole || data.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany,
    render: data => <AccessModeStep {...data} />,
});

const AccessModeStep = ({ data, validationState, onDataChange }: WizardStepData<ReportModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const reportStore = useReportStore();
    const companies = reportStore.companies;
    const [isJustProviderCompanies, setIsJustProviderCompanies] = useState(false);
    const isIndividualMode = data.parameters.aggregationMode === ReportAggregationModeRepresentation.SingleCompany;

    useEffect(() => {
        reportStore.companiesListCache.load()
            .then((items) => {
                const tmpIsJustProviderCompanies = items
                    .filter(company => companies.indexOf(company.id) > -1)
                    .every(
                        company => company.type === CompanyTypeRepresentation.Provider
                    );
                setIsJustProviderCompanies(tmpIsJustProviderCompanies);
            });
    }, []);

    return (
        <StepLayout
            title={capitalize(lang.ACCESS_TYPE)}
            description={lang.SPECIFY_REPORT_ACCESS_TYPE}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                onChange={onDataChange}
            >
                {
                    <FormLayout inlineLabel disabled={isJustProviderCompanies && isIndividualMode}>
                        <Radiobutton
                            name='parameters.accessMode'
                            value={ReportAccessMode.Public}
                            helpText={lang.THIS_REPORT_WILL_BE_ACCESSIBLE_BY_ANY_USER}
                        >
                            {lang.PUBLIC}
                        </Radiobutton>

                        <Radiobutton
                            name='parameters.accessMode'
                            value={ReportAccessMode.Internal}
                            helpText={lang.THIS_REPORT_WILL_BE_ACCESSIBLE_BY_A_GROUP}
                        >
                            {lang.PRIVATE}
                        </Radiobutton>
                    </FormLayout>
                }
            </Form>
        </StepLayout>
    );
};
