/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { PublicCloudProtectedVmBackupModel, PublicCloudProtectedVmBackupParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PageDialogProps, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { PublicCloudsDialogToolbar } from '../PublicCloudsDialogToolbar';
import {
    getBackupSizeColumnHeader,
    getDestinationColumnHeader,
    getDialogTitle,
    getJobNameColumnHeader,
    getRestorePointColumnHeader,
    getSourceSizeColumnHeader,
} from '../../utils';
import { PublicCloudsRestorePointColumn } from '../../../PublicCloudsRestorePointColumn';


export interface PublicCloudsDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    name: string;
    extraParams: Pick<PublicCloudProtectedVmBackupParam, 'backupServerUid' | 'backupType' | 'vmUid' | 'platformType'>;
}

const { Snapshot, RemoteSnapshot } = PublicCloudVmRestoreType;

export const PublicCloudsDialog: React.FC<PublicCloudsDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const idProperty = 'backupUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        name,
        extraParams,
    } = props;
    const { backupType, platformType, backupServerUid } = extraParams;
    const isSnapshot = [Snapshot, RemoteSnapshot].includes(backupType);
    const columns: GridColumnProps<PublicCloudProtectedVmBackupModel>[] = [
        {
            id: 'jobName',
            title: getJobNameColumnHeader(backupType, lang),
            cell: rowData => rowData.jobName,
        },
        {
            id: 'lastRun',
            title: lang.LAST_JOB_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastRun}
                />
            ),
        },
        {
            id: 'sourceSize',
            title: getSourceSizeColumnHeader(backupType, lang),
            cell: rowData => <RestorePointSizeColumn value={rowData.sourceSize} />,
        },
        {
            id: 'restorePoints',
            title: getRestorePointColumnHeader(backupType, lang),
            cell: rowData => (
                <PublicCloudsRestorePointColumn
                    value={rowData.restorePoints}
                    stateId={`${stateId}.${PublicCloudVmRestoreType[backupType]}`}
                    name={rowData.jobName}
                    extraParams={{
                        backupServerUid,
                        vmUid: rowData.vmInstanceUid,
                        backupUid: rowData.backupUid,
                        platformType,
                        backupType,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        !isSnapshot && {
            id: 'backupSize',
            title: getBackupSizeColumnHeader(backupType, lang),
            cell: rowData => <RestorePointSizeColumn value={rowData.backupSize} />,
        },
        {
            id: 'destination',
            title: getDestinationColumnHeader(backupType, lang),
            cell: rowData => rowData.destination,
        },
        {
            id: 'backupServer',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServer,
        },
    ];

    return (
        <PageDialog
            title={`${getDialogTitle(backupType, lang)} - ${name}`}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: PublicCloudProtectedVmBackupParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<PublicCloudProtectedVmBackupModel[]>>(
                            '/PublicCloudVm/GetProtectedVmBackups',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={false}
                    selection={{
                        field: idProperty,
                        checkbox: false,
                        multiple: false,
                    }}
                    toolbars={[
                        () => (
                            <PublicCloudsDialogToolbar
                                idProperty={idProperty}
                                extraParams={extraParams}
                                backupType={backupType}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
