/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useCallback } from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CompanyUploadTraffic } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings, usePeriodsStore } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';
import { GridName } from '../../../../stores';

import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const TransferredData: React.FC<TopsGridComponentWrapper> = observer(({ maxNumberOfRows }) => {
    const { setPeriod, periodTopCompaniesByTransferredData } = usePeriodsStore();

    const settings = useCommonSettings<CompanyUploadTraffic>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.TransferredData,
        shouldRenderPeriodSelect: true,
        period: periodTopCompaniesByTransferredData,
    });
    const { lang } = settings;

    const columns: GridColumnProps<CompanyUploadTraffic>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'transmitted',
            title: lang.DATA_TRANSFERRED,
            cell: rowData => <SizeColumnRender value={rowData.transmitted} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    const handleChangePeriod = useCallback((periodValue: number): void => {
        setPeriod(periodValue, GridName.TopCompaniesByTransferredData);
    }, [setPeriod]);

    return (
        <TopsGrid
            title={lang.TOP_COMPANIES_BY_TRANSFERRED_DATA}
            columns={columns}
            sectionId={ConfigSectionIds.TopsCompanies}
            maxNumberOfRows={maxNumberOfRows}
            handleChangePeriod={handleChangePeriod}
            {...settings}
        />
    );
});
