/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum HostIconTypeEnum {
    Error = 'error',
    Loading = 'loading',
    Success = 'success',
    Warning = 'warning',
}
