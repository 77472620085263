/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    FormLayout,
    HelpText,
    INDENT,
    Label,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../stores';
import { FormFieldNames } from '../../enums';
import { getNotificationLevelFlags } from '../../helpers';
import { Suffix } from '../Suffix';

export const BillingSettingsGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formStore: { defaultInputSize, formData: { level } } } = useConfigurationStore();
    const { noEmails } = getNotificationLevelFlags(level);

    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
            <StackView direction={STACK_DIRECTION.column} indentBottom={INDENT.s}>
                <Label>{lang.BILLING}</Label>
                <HelpText>{`${lang.SPECIFY_EMAIL_SETTINGS_TO_USE_WHEN_SENDING_INVOICES}:`}</HelpText>
            </StackView>

            <FormLayout inlineLabel disabled={noEmails}>
                <TextInput
                    label={lang.FROM}
                    maxLength={512}
                    name={FormFieldNames.BillingFrom}
                    size={defaultInputSize}
                />

                <TextInput
                    disabled={noEmails}
                    label={lang.SUBJECT}
                    maxLength={512}
                    name={FormFieldNames.BillingSubject}
                    size={defaultInputSize}
                    suffix={(<Suffix variables={'%company%, %invoicePeriod%.'} />)}
                />
            </FormLayout>
        </StackView>
    );
});
