/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable, observable } from 'mobx';

import type {
    InlineObject2,
    EmptyResponse,
    TestEmailOptions,
    CompleteSmtpOAuth2SignInResponseData,
    SmtpSettings,
    NotificationSettings,
    OAuth2SignInParameters,
} from '@veeam-vspc/models/rest';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PortalUser } from '@veeam-vspc/shared/stores';

export type TransportServiceErrorType = RequestErrorResponse | Error;

export class ConfigurationRequestsStore {

    @observable isLoading = true;
    testSmtpAbortController = new AbortController();

    constructor(
        private readonly transportService: TransportService<TransportServiceErrorType>,
        private readonly organizationUid: PortalUser['organizationUid'],
        private readonly backendErrorTitle: string,
    ) {
        makeObservable(this);
    }

    @action.bound
    readonly setIsLoadingToFalse = (): void => {
            this.isLoading = false;
        };

    private readonly successResponseHandler = <T>(response: RequestSuccessResponse<T>): T => response.data;

    private readonly errorResponseHandler = (error: TransportServiceErrorType): Promise<TransportServiceErrorType> => {
        this.setIsLoadingToFalse();
        return Promise.reject(error);
    };

    @action.bound
    readonly loadSettings = (): Promise<NotificationSettings | TransportServiceErrorType> => {
            this.isLoading = true;

            return this.transportService
                .get<{}, RequestSuccessResponse<NotificationSettings>>(
                    `/organizations/${this.organizationUid}/configuration/notification`,
                    {},
                    { backendErrorTitle: this.backendErrorTitle }
                )
                .then(this.successResponseHandler<NotificationSettings>)
                .catch(this.errorResponseHandler);
        };

    @action.bound
    readonly patchSettings = (
            original: NotificationSettings,
            current: NotificationSettings,
            toggleLoadingFlag = true,
        ): Promise<NotificationSettings | TransportServiceErrorType> => {
            if (toggleLoadingFlag) {
                this.isLoading = true;
            }

            return this.transportService
                .patch<NotificationSettings, RequestSuccessResponse<NotificationSettings>>(
                    `/organizations/${this.organizationUid}/configuration/notification`,
                    { original, current },
                    { backendErrorTitle: this.backendErrorTitle }
                )
                .then(this.successResponseHandler<NotificationSettings>)
                .catch(this.errorResponseHandler);
        };

    @action.bound
    readonly testSmtpSettings = (testSettings: SmtpSettings, toggleLoadingFlag = true): Promise<SmtpSettings | TransportServiceErrorType> => {
            if (toggleLoadingFlag) {
                this.isLoading = true;
            }

            return this.transportService
                .post<SmtpSettings, RequestSuccessResponse<SmtpSettings>>(
                    `/organizations/${this.organizationUid}/configuration/notification/smtp/test`,
                    testSettings,
                    {
                        backendErrorTitle: this.backendErrorTitle,
                        requestInit: {
                            signal: this.testSmtpAbortController.signal,
                        },
                    },
                )
                .then(this.successResponseHandler<SmtpSettings>)
                .catch(this.errorResponseHandler);
        };

    @action.bound
    readonly oAuthCompletionRequest = (queryString: string): Promise<CompleteSmtpOAuth2SignInResponseData | TransportServiceErrorType> => {
            this.isLoading = true;

            return this.transportService
                .get<{}, RequestSuccessResponse<CompleteSmtpOAuth2SignInResponseData>>(
                    `/organizations/${this.organizationUid}/configuration/notification/oauth2/signin/completion?${queryString}`,
                    {},
                    { backendErrorTitle: this.backendErrorTitle }
                )
                .then(this.successResponseHandler<CompleteSmtpOAuth2SignInResponseData>)
                .catch(this.errorResponseHandler);
        };

    readonly sendTestNotificationRequest = (
        requestData: TestEmailOptions
    ): Promise<RequestSuccessResponse<EmptyResponse> | TransportServiceErrorType> => (
        this.transportService
            .post(
                `/organizations/${this.organizationUid}/configuration/notification/test`,
                requestData,
                { backendErrorTitle: this.backendErrorTitle }
            )
    );

    readonly oAuthSignInRequest = (oAuthSignInBody: InlineObject2): Promise<OAuth2SignInParameters | TransportServiceErrorType> => (
        this.transportService
            .post<InlineObject2, RequestSuccessResponse<OAuth2SignInParameters>>(
                `/organizations/${this.organizationUid}/configuration/notification/oauth2/signin`,
                oAuthSignInBody,
                { backendErrorTitle: this.backendErrorTitle }
            )
            .then(this.successResponseHandler<OAuth2SignInParameters>)
    );
}
