/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';
import type { CompleteSmtpOAuth2SignInResponseData, NotificationSettings, SmtpSettings } from '@veeam-vspc/models/rest';

import { QueryKeys } from 'core/enums';
import { getOAuthQueryValue } from 'core/utils';
import { getOAuthSmtpSettings } from '../helpers';
import { useConfigurationRequestsStore, useConfigurationStore } from '../stores';

export interface SmtpRedirectControllerReturnedValues {
    handleOAuthRedirect: (searchParams: URLSearchParams, userRole: UserRoleRepresentation) => void;
}

export const useSmtpRedirectController = (): SmtpRedirectControllerReturnedValues => {
    const lang = useGlobalLang<VspcLang>();
    const configurationStore = useConfigurationStore();
    const { notificationService } = useGlobalServices();
    const requestsStore = useConfigurationRequestsStore();

    const handleOAuthRedirect = (searchParams: URLSearchParams, userRole: UserRoleRepresentation): void => {
        const stateQuery = searchParams.get(QueryKeys.SmtpOAuthState) || '';
        const oAuthQueryValue = getOAuthQueryValue(stateQuery);
        const userRoleMatches = oAuthQueryValue.user === userRole;

        userRoleMatches ? handleOAuthQueryParams(searchParams) : errorHandler();
    };

    const handleOAuthQueryParams = (searchParams: URLSearchParams): void => {
        requestsStore.loadSettings()
            .then((originalSettings: NotificationSettings): void => completeOAuthSmtpSetting(originalSettings, searchParams))
            .catch(errorHandler);
    };

    const completeOAuthSmtpSetting = (originalSettings: NotificationSettings, searchParams: URLSearchParams): void => {
        requestsStore.oAuthCompletionRequest(searchParams.toString())
            .then((data: CompleteSmtpOAuth2SignInResponseData): Promise<[SmtpSettings, SmtpSettings]> => {
                const oAuthSmtpSettings: SmtpSettings = getOAuthSmtpSettings(originalSettings.smtp, data.credential);

                return Promise.all([
                    Promise.resolve(oAuthSmtpSettings), // these settings are used for further saving, but only if they pass the test request
                    requestsStore.testSmtpSettings(oAuthSmtpSettings) as Promise<SmtpSettings>,
                ]);
            })
            .then((responses: [SmtpSettings, SmtpSettings]): NotificationSettings => {
                const [oAuthSmtpSettingsForSaving] = responses;
                return { ...originalSettings, smtp: oAuthSmtpSettingsForSaving };
            })
            .then((newSettings: NotificationSettings) => requestsStore.patchSettings(originalSettings, newSettings))
            .then((newSettings: NotificationSettings): void => configurationStore.setPageStoresData(newSettings))
            .then(() => requestsStore.isLoading = false)
            .then(() => clearQueryParams())
            .then(() => notificationService.success(lang.EMAIL_SERVER_SETTINGS, lang.EMAIL_SERVER_SETTINGS_HAVE_BEEN_SAVED))
            .catch(errorHandler);
    };

    const errorHandler = () => {
        clearQueryParams();
        configurationStore.initiateStoreData();
    };

    const clearQueryParams = () => {
        // we use 'window.history.replaceState' to avoid unnecessary additional requests
        const url = new URL(`${window.location.origin}${window.location.pathname}`);
        window.history.replaceState({}, window.document.title, url.toString());
    };

    return { handleOAuthRedirect };
};
