/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import type React from 'react';
import type { GridStore } from '@veeam-vspc/shared/components';

import { parseJsonFromQueryUrl } from 'core/utils';
import { QueryKeys } from 'core/enums';
import { GridFiltersApplyMode } from './enums';

export interface ApplyToInitialState<TFilters> {
    isForce: boolean;
    filters: TFilters;
}

export interface ApplyToGridFiltersState {
    gridAutoLoad: boolean;
}

function useGridFilters<TFilters>(
    gridFiltersApplyMode: GridFiltersApplyMode.ApplyToGridFilters,
    gridApi: React.MutableRefObject<GridStore<any, any, TFilters>>,
): ApplyToGridFiltersState;
function useGridFilters<TFilters>(
    gridFiltersApplyMode: GridFiltersApplyMode.ApplyToInitialState,
    gridApi: void,
): ApplyToInitialState<TFilters>;
function useGridFilters<TFilters>(
    gridFiltersApplyMode: GridFiltersApplyMode,
    gridApi: React.MutableRefObject<GridStore<any, any, TFilters>> | void
): ApplyToInitialState<TFilters> | ApplyToGridFiltersState {
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const filters = parseJsonFromQueryUrl<TFilters>(QueryKeys.GridFilters);

    useEffect(() => {
        if (gridApi && gridApi.current && filters && gridFiltersApplyMode === GridFiltersApplyMode.ApplyToGridFilters) {
            // Checking on filters from URL and grid store
            // Grid api can't send request if filters are the same and autoLoad prop is false
            const sameFilters = Object.keys(filters).every(key =>
                JSON.stringify(filters[key]) === JSON.stringify(gridApi.current.filters[key])
            );
            sameFilters ? gridApi.current.reloadGrid() : gridApi.current.applyFilters(filters);
            searchParams.delete(QueryKeys.GridFilters);
            window.history.replaceState({}, '', `${pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`);
        }
    }, [gridApi]);

    if (gridFiltersApplyMode === GridFiltersApplyMode.ApplyToInitialState) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete(QueryKeys.GridFilters);

        window.history.replaceState({}, '', newUrl);

        return {
            isForce: Boolean(filters),
            filters,
        };
    }

    if (gridFiltersApplyMode === GridFiltersApplyMode.ApplyToGridFilters) {
        return { gridAutoLoad: !Boolean(filters) };
    }
}

export { useGridFilters };
