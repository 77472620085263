/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, useGlobalAddons, useGridApi } from '@veeam-vspc/shared/components';
import { DeployAgentStatusRepresentation } from '@veeam-vspc/models/web-controllers';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';

import successIconSrc from 'images/statuses/success.svg';
import pendingIconSrc from 'images/statuses/pending.svg';
import warningIconSrc from 'images/statuses/warning.svg';
import errorIconSrc from 'images/statuses/error.svg';
import runningIconSrc from 'images/statuses/running.svg';
import { TaskDetailsDialog } from '../TaskDetailsDialog';

export const DeploymentStatusColumn = ({ rowData }: { rowData: DiscoveredComputerModel; }) => {
    const { portalEnums } = useGlobalAddons();
    const { deployStatus, hostName, biosUuid, vacAgentGuid } = rowData;
    const iconStatusesSrc = {
        [DeployAgentStatusRepresentation.Success]: successIconSrc,
        [DeployAgentStatusRepresentation.Warning]: warningIconSrc,
        [DeployAgentStatusRepresentation.Failed]: errorIconSrc,
        [DeployAgentStatusRepresentation.Installing]: runningIconSrc,
        [DeployAgentStatusRepresentation.Upgrading]: pendingIconSrc,
    };
    const statusText = portalEnums.getEnumDescription('DeployAgentStatusRepresentation', deployStatus);
    const gridApi = useGridApi();

    return (
        <ActionLinkColumn
            contentIconBefore={iconStatusesSrc[deployStatus]}
            renderContent={() => statusText}
            isLink={deployStatus !== DeployAgentStatusRepresentation.Unknown}
            renderModal={({ deactivate }) => (
                <TaskDetailsDialog
                    deactivate={deactivate}
                    onCancelTaskHandler={() => {
                        gridApi.reloadGrid();
                        deactivate();
                    }}
                    onClearLogsHandler={() => {
                        gridApi.reloadGrid();
                        deactivate();
                    }}
                    hostName={hostName}
                    agentId={vacAgentGuid}
                    biosUuid={biosUuid}
                />
            )}
        />
    );
};
