/**
 * Copyright © Veeam Software Group GmbH.
 */

import { colors } from '@veeam-vspc/shared/components';

import { ColorSchemes } from '../enums';

export const getColorScheme = () => [
    {
        color: ColorSchemes.Blue.toLowerCase(),
        name: ColorSchemes.Blue,
        colorfill: colors.B500,
    },
    {
        color: ColorSchemes.Green.toLowerCase(),
        name: ColorSchemes.Green,
        colorfill: colors.Grn500,
    },
    {
        color: ColorSchemes.Red.toLowerCase(),
        name: ColorSchemes.Red,
        colorfill: colors.R900,
    },
    {
        color: ColorSchemes.Turquoise.toLowerCase(),
        name: ColorSchemes.Turquoise,
        colorfill: colors.T500,
    },
    {
        color: ColorSchemes.Yellow.toLowerCase(),
        name: ColorSchemes.Yellow,
        colorfill: colors.Y500,
    },
];
