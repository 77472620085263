/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { formatStr } from '@veeam-vspc/shared/helpers';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ParsedText,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BackupJobRestorePointModel, JobRestorePointParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getRestorePointsHelpResource } from 'core/utils';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointDialogToolbar } from '../RestorePointDialogToolbar/RestorePointDialogToolbar';
import { MAX_INTEGER_32 } from 'core/const';

export const RestorePointColumnsFields: Record<string, keyof BackupJobRestorePointModel> = {
    Date: 'date',
    SourceSize: 'sourceSizeKb',
    BackedUpData: 'backedUpDataKb',
    RestorePointSize: 'restorePointSizeKb',
};

export interface RestorePointDialogProps {
    stateId: string;
    name: string;
    extraParams: Record<string, string>;
    deactivate: () => void;
    noteBarIsHidden?: boolean;
    hiddenColumns?: (keyof typeof RestorePointColumnsFields)[] | [];
}

export const RestorePointDialog: React.FC<RestorePointDialogProps> = (
    {
        name,
        stateId,
        deactivate,
        extraParams,
        noteBarIsHidden = false,
        hiddenColumns = [],
    }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { transportService } = useGlobalServices();

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                {!noteBarIsHidden && (
                    <NoteBar status={NOTEBAR_STATUS.info} style={{ backgroundColor: RCOP.utils.Colors.Y50 }}>
                        <ParsedText textOrConfig={formatStr(lang.TO_SEE_BACKED_UP_DATA, getRestorePointsHelpResource(portalUser.userRole))} />
                    </NoteBar>
                )}

                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: RestorePointColumnsFields.Date,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={[
                        {
                            id: RestorePointColumnsFields.Date,
                            title: lang.DATE,
                            cell: rowData => (
                                <GridDateColumn value={rowData.date} />
                            ),
                        },
                        {
                            id: RestorePointColumnsFields.SourceSize,
                            title: lang.SOURCE_SIZE,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.sourceSizeKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: RestorePointColumnsFields.BackedUpData,
                            title: lang.BACKED_UP_DATA,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.backedUpDataKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: RestorePointColumnsFields.RestorePointSize,
                            title: lang.RESTORE_POINT_SIZE,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.restorePointSizeKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ].filter(column => !hiddenColumns.includes(column.id))}
                    data={(requestParams: JobRestorePointParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<BackupJobRestorePointModel[]>>('/BackupServerJob/GetRestorePoints', {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => <RestorePointDialogToolbar extraParams={extraParams} idProperty={'instanceUid'} />,
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
