/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ExtRouteProviderStoreContext } from './ext-route-provider-store-context';

import type { ExtRouteProviderStoreType } from './ext-route-provider-store';

export const useExtRouteProviderStore = (): ExtRouteProviderStoreType => useContext(ExtRouteProviderStoreContext);
