/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Backup } from './components/CloudConnectResources/Backup';
import { HighchartsStyles } from 'views/components/HighchartsWrapper';

export interface ResourcesAndBillingCloudConnectBackupPageProps {
    currentPath: string;
}

export const ResourcesAndBillingCloudConnectBackupPage: React.FC<ResourcesAndBillingCloudConnectBackupPageProps> = () => (
    <TabBarBodyLayout>
        <HighchartsStyles />
        <Backup />
    </TabBarBodyLayout>
);
