/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { NoteBar } from 'components/layouts/NoteBar';
import { getResellerDocUrl } from '../../../Base/helpers/getResellerDocUrl';

import type { SPUserModel } from '../../interfaces';

export const stepValidate = (data: SPUserModel) => {
    const validator = new FormValidator(data);

    validator.validate('userRole').number();

    return validator.result();
};

export const Role: React.FC<WizardStepData<SPUserModel>> = ({ data, validationState, onDataChange }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.ROLE}
                description={lang.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='userRole'
                        size={CONTROL_SIZE.m}
                        data={[
                            { value: UserRoleRepresentation.ResellerAdministrator, text: lang.SERVICE_PROVIDER_ADMINISTRATOR },
                            { value: UserRoleRepresentation.ResellerOperator, text: lang.SERVICE_PROVIDER_OPERATOR },
                            { value: UserRoleRepresentation.ResellerUser, text: lang.SERVICE_PROVIDER_USER },
                            { value: UserRoleRepresentation.ResellerFinance, text: lang.SERVICE_PROVIDER_INVOICE_AUDITOR },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={lang.ROLE}
                        inlineLabel
                    />

                    <NoteBar>
                        {data.userRole === UserRoleRepresentation.ResellerAdministrator &&
                            <p>{lang.SERVICE_PROVIDER_ADMINISTRATOR_HAS_ACCESS}</p>}

                        {data.userRole === UserRoleRepresentation.ResellerOperator &&
                            <p>{lang.SERVICE_PROVIDER_OPERATOR_CAN_PERFORM}</p>}

                        {data.userRole === UserRoleRepresentation.ResellerUser &&
                            <p>{lang.SERVICE_PROVIDER_USER_HAS_ACCESS}</p>}

                        {data.userRole === UserRoleRepresentation.ResellerFinance &&
                            <p>{lang.SERVICE_PROVIDER_INVOICE_AUDITOR_HAS_ACCESS}</p>}

                        <p>
                            {lang.CLICK}
                            {' '}

                            <a
                                href={getResellerDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.HERE}
                            </a>

                            {' '}
                            {lang.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    isHidden: ({ data }) => data.selfEdit || data.userRole === UserRoleRepresentation.ResellerOwner,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <Role {...data} />,
});
