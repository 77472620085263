/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { PortalFiltersIds } from '@veeam-vspc/shared/addons';
import { ExportActionIds } from '@veeam-vspc/shared/components';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { RouteIds, RoutePaths } from '../../enums';
import { ConfigActionIds, ComputersActionIds, ConfigAdvancedFilterItemsIds, ConfigSectionIds, ConfigItemIds } from 'core/enums';
import { allCustomRoutes, allHomeRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

import type { VspcLang } from '../../languages';

export const companyLocationUserConfig: UserConfigData<typeof UserRoleRepresentation.CompanyLocationUser, ConfigSectionIds | PortalFiltersIds> = {
    role: UserRoleRepresentation.CompanyLocationUser,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.ActiveAlarms],
                        allHomeRoutes[RouteIds.ProtectedDataSummary],
                        {
                            ...allHomeRoutes[RouteIds.BillingSummary],
                            navNameKey: 'RESOURCES' as VspcLang['RESOURCES'],
                        },
                        allHomeRoutes[RouteIds.BackupJobs],
                        allHomeRoutes[RouteIds.FailoverPlans],
                        allHomeRoutes[RouteIds.ProtectedData],
                        allHomeRoutes[RouteIds.ManagedComputers],
                        allHomeRoutes[RouteIds.Reports],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByConsole,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsDataBackupObjectStorage,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataComputers,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                [ComputersActionIds.FileLevelRestorePortalTBD]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataDataBackupObjectStorage,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsCloudDatabases,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataCloudDatabases,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ReportsAllReports,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ConfigActionIds.ActionToolbarViewReports]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.InvoicesConfiguration,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesAllInvoices,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
            columns: {
                companyName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarms,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
                regionName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarmsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.AlarmProductVspc]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmProductCloudConnect]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmScope]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersComputers,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
            columns: {
                rejectReason: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersRules,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: PortalFiltersIds.PortalFiltersSection,
            items: {
                [PortalFiltersIds.SitesPortalFilter]: ConfigStates.Hidden,
                [PortalFiltersIds.OrganizationsPortalFilter]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarmsFilterToolbar,
            actions: {
                [ConfigActionIds.FilterToolbarRegionSearch]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.FailoverPlansFailoverPlans,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BillingSummaryCloudConnectResourcesCloudConnectBackup,
            items: {
                [ConfigItemIds.StorageUsageWidget]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ReportsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.ReportsManagingCompany]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.ReportsReportConfiguration]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructureAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructureBackupServerType]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructurePlatformType]: ConfigStates.Hidden,
            },
        },
    ],
};
