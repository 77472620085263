/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { DatePeriodValue } from '@veeam-vspc/shared/components';

import { DatePeriodValueEnum } from './enums';
import { getEndOfTheDay } from '../get-end-of-the-day';
import { getStartOfTheDay } from '../get-start-of-the-day';

export type DatePeriod = Record<string, Date|undefined>;

export const getDatePeriod = (newValue: DatePeriodValue, from = 'dateFrom', to = 'dateTo'): DatePeriod => {
    const datePeriod: DatePeriod = {
        [from]: undefined,
        [to]: undefined,
    };

    if (!newValue) {
        return datePeriod;
    }

    switch (newValue.length) {
        case DatePeriodValueEnum.SingleDay:
            datePeriod[from] = getStartOfTheDay(newValue[0]);
            datePeriod[to] = getEndOfTheDay(newValue[0]);
            break;

        case DatePeriodValueEnum.Period:
            datePeriod[from] = getStartOfTheDay(newValue[0]);
            datePeriod[to] = getEndOfTheDay(newValue[1]);
            break;

        case DatePeriodValueEnum.AllTime:
        default: break;
    }

    return datePeriod;
};
