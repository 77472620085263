/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum PluginNames {
    Agents = 'Agents',
    ConnectWise = 'ConnectWise',
    ConnectWiseAutomate = 'ConnectWiseAutomate',
    Grafana = 'Grafana',
    ObjectStorage = 'ObjectStorage',
    Pulse = 'Pulse',
    Vb = 'Vb',
    Vb365provider = 'Vb365provider',
    VeeamOne = 'VeeamOne',
    Vbr = 'Vbr',
}
