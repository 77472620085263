/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface CompaniesFile {
    Companies: ComponentType<any>;
}

export interface CompaniesPageProps {
    currentPath: string;
}

export const CompaniesPage: React.FC<CompaniesPageProps> = ({ currentPath }) => (
    <LazyLoadedComponent<CompaniesFile>
        importPromise={() => (import('./components/Companies') as Promise<CompaniesFile>)}
        pageName={'Companies'}
        pageProps={{
            parentStateId: currentPath,
        }}
    />
);
