/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ReportContext } from '../stores';

import type { ReportStore } from '../stores';

export const useReportStore = (): ReportStore => useContext(ReportContext);
