/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ComboboxKit,
    CONTROL_SIZE,
    Search,
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { SiteModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds, ConfigActionIds } from 'core/enums';

export interface SelectVbFilterToolbarProps {
    cloudConnectServers: SiteModel[] | null;
    selectedAgentGuid: string;
    onChangeAgentGuid: (newAgentGuid: string) => void;
}

export const SelectVbFilterToolbar: React.FC<SelectVbFilterToolbarProps> = (props) => {
    const { cloudConnectServers, selectedAgentGuid, onChangeAgentGuid, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();

    return (
        <ConfiguredToolbar
            {...restProps}
            sectionId={ConfigSectionIds.BackupJobsSelectVbDialogFilterToolbar}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    id: ConfigActionIds.FilterToolbarSiteSearch,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView gap={STACK_GAP.m} align={STACK_ALIGN.center}>
                            <Text>{`${lang.SITE}:`}</Text>

                            <ComboboxKit
                                data={
                                    [
                                        ...cloudConnectServers,
                                        { agentGuid: null, siteName: lang.ALL_SITES },
                                    ]
                                }
                                valueGetter={({ agentGuid }) => agentGuid}
                                textGetter={({ siteName }) => siteName}
                                value={selectedAgentGuid}
                                size={CONTROL_SIZE.m}
                                onChange={(agentGuid: string) => {
                                    onChangeAgentGuid(agentGuid);
                                }}
                            />
                        </StackView>
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='hostName'
                            placeholder={lang.HOSTNAME}
                            size={CONTROL_SIZE.s}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarCompanySearch,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='companyName'
                            placeholder={lang.COMPANY}
                            size={CONTROL_SIZE.s}
                        />
                    ),
                },
            ]}
        />
    );
};
