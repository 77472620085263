/**
 * Copyright © Veeam Software Group GmbH.
 */

export class CompanyUserStore {
    private dialogMsgBeenShownState: boolean;

    constructor() {
        this.dialogMsgHasBeenShown = false;
    }

    get dialogMsgHasBeenShown() {
        return this.dialogMsgBeenShownState;
    }

    set dialogMsgHasBeenShown(beenShown: boolean) {
        this.dialogMsgBeenShownState = beenShown;
    }
}
