/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    FormTitle,
    Icon,
    Link,
    LINK_TARGET,
    NoteBar,
    ParsedText,
    PortalSpinner,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    ToggleKit,
    useExternalFormApi,
    useGlobalServices,
    useModal,
} from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { UserMfaPolicyAggregateStatusRepresentation } from '@veeam-vspc/models/web-controllers';
import { TextDividerSymbols } from '@veeam-vspc/shared/helpers';
import styled from 'styled-components';

import type { UserData } from '@veeam-vspc/models/web-controllers';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { NotificationService } from '@veeam-vspc/shared/services';
import type { StackViewProps } from '@veeam/components/lib/StackView/types';
import type { VspcLang } from 'configs/languages';

import { useEditUserProfileStore } from '../../stores';
import { StatusTypes } from 'core/utils/status-helpers/enums';
import { getStatusIconSrc } from 'core/utils';
import { AuthenticatorHref } from './enums';
import { SecretCodeDialog } from './components';
import { EditUserProfileStepIds } from '../../enums';

import type { EditUserProfileStore } from '../../stores';

interface MfaStepProps {
    lang: VspcLang;
    stepModel: WizardStepData<UserData>;
}

const StyledStackView = styled(StackView)<StackViewProps & { disabled: boolean; }>`
    ${({ disabled, theme }) => disabled && `
        & > * {
            color: ${theme.colorTextSecondary} !important;
        }
        pointer-events: none;
    `}
`;

const mfaStepValidate = (
    lang: VspcLang,
    editUserProfileStore: EditUserProfileStore,
    notificationService: NotificationService
) => {
    if (editUserProfileStore.shouldConfigureMfa || (editUserProfileStore.mfaEnabled && !editUserProfileStore.isSecretCodeGenerated)) {
        return notificationService.info(
            lang.EDIT_USER,
            editUserProfileStore.isMfaStrictPolicy
                ? lang.YOUR_PORTAL_ADMINISTRATOR_HAS_ENFORCED_THE_MULTI
                : lang.YOU_MUST_CONFIGURE_THE_MULTI_FACTOR
        ).then(() => false);
    }
    return true;
};

const MfaStep: React.FC<MfaStepProps> = observer(({ lang, stepModel }) => {
    const { data } = stepModel;
    const formApi = useExternalFormApi<UserData>();
    const editUserProfileStore = useEditUserProfileStore();
    const { mfaEnabled, setMfaStatus, isSecretCodeGenerated } = editUserProfileStore;
    const { notificationService } = useGlobalServices();
    const [secretCodeDialog, secretCodeDialogActions] = useModal({
        render: ({ deactivate }) => (
            <SecretCodeDialog deactivate={deactivate} />
        ),
    });

    return (
        <>
            <Form value={data} externalFormApi={formApi}>
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                        <Text>{`${lang.ENABLE_THE_MFA_ACCESS}:`}</Text>

                        <ToggleKit
                            showSuffix
                            value={mfaEnabled}
                            onChange={(value) => {
                                if (!data.isAllowedToSwitchMfaPolicyStatus) {
                                    notificationService.error(lang.MFA_ACCESS_CONFIGURATION, lang.CANNOT_DISABLE_THE_MFA_FEATURE);
                                } else {
                                    setMfaStatus(value);
                                    formApi.setValue(
                                        'mfaPolicyStatus',
                                        value
                                            ? UserMfaPolicyAggregateStatusRepresentation.Enabled
                                            : UserMfaPolicyAggregateStatusRepresentation.Disabled
                                    );
                                }
                            }}
                            disabled={!data.isAllowedToSwitchMfaPolicyStatus}
                        />
                    </StackView>

                    {mfaEnabled && isSecretCodeGenerated && (
                        <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                            <Icon src={getStatusIconSrc(StatusTypes.Success)} />

                            <Text>
                                {lang.THE_MULTI_FACTOR_AUTHENTICATION}
                            </Text>
                        </StackView>
                    )}

                    {mfaEnabled && !isSecretCodeGenerated && (
                        <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                            <Icon src={getStatusIconSrc(StatusTypes.Info)} />

                            <Text>
                                {lang.MFA_NOT_CONFIGURED}
                            </Text>
                        </StackView>
                    )}

                    <NoteBar>
                        <ParsedText
                            textOrConfig={[
                                lang.UPON_ENABLING_THIS_OPTION_YOU_WILL_BE,
                                TextDividerSymbols.BlankLine,
                                lang.IF_YOU_ARE_USING_BACKUP_PORTAL_RESTFUL_APIS,
                                TextDividerSymbols.BlankLine,
                                lang.TO_RESOLVE_THAT_YOU_NEED_TO_START_USING,
                            ]}
                        />
                    </NoteBar>

                    <FormLayout inlineLabel>
                        <FormTitle>{lang.INSTALL_A_COMPATIBLE_APP_ON_YOUR_MOBILE}</FormTitle>

                        <StyledStackView disabled={!mfaEnabled} direction={STACK_DIRECTION.column} gap={STACK_GAP.l}>
                            <Text>
                                {`1. ${lang.INSTALL_A_COMPATIBLE_APP_ON_YOUR_MOBILE}:`}
                            </Text>

                            <Text>
                                {`${lang.ANDROID} — `}

                                <Link disabled={!mfaEnabled} target={LINK_TARGET.blank} href={AuthenticatorHref.Android}>
                                    {lang.GOOGLE_AUTHENTICATOR}
                                </Link>
                            </Text>

                            <Text>
                                {`${lang.IPHONE} — `}

                                <Link disabled={!mfaEnabled} target={LINK_TARGET.blank} href={AuthenticatorHref.IOS}>
                                    {lang.GOOGLE_AUTHENTICATOR}
                                </Link>
                            </Text>

                            <Text>
                                {`2. ${lang.SCAN_THE_QR_CODE_USING_YOUR_DEVICES_CAMERA}: `}

                                <Link
                                    disabled={!mfaEnabled}
                                    onClick={() => editUserProfileStore.generateTotpSecret().then(secretCodeDialogActions.activate)}
                                >
                                    {`${lang.GENERATE_THE_QR_CODE}...`}
                                </Link>
                            </Text>
                        </StyledStackView>
                    </FormLayout>
                </StackView>
            </Form>

            {editUserProfileStore.loading && <PortalSpinner delayTime={300} />}
            {secretCodeDialog}
        </>
    );
});

export const getMfaStep = (
    lang: VspcLang,
    editUserProfileStore: EditUserProfileStore,
    notificationService: NotificationService
): WizardStep<UserData> => ({
    title: lang.MULTI_FACTOR_AUTHENTICATION,
    description: lang.CONFIGURE_MFA_ACCESS_FOR_YOUR_USER_ACCOUNT,
    render: stepModel => <MfaStep stepModel={stepModel} lang={lang} />,
    validate: () => mfaStepValidate(lang, editUserProfileStore, notificationService),
    id: EditUserProfileStepIds.MfaStep,
});
