/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { deepCopy } from '@veeam-vspc/shared/core';
import { IdentityProviderAttributeMappingAttribute, IdentityProviderRoleMappingRuleRole } from '@veeam-vspc/models/rest';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { IdentityProviderRoleMappingRule } from '@veeam-vspc/models/rest';
import type { WizardStep } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { PageWizard } from 'components/wizards/PageWizard';
import { saveRule } from 'views/pages/RolesAndUsersPage/components/Base/helpers/saveRule';
import { getRuleInfoStep } from '../Base/components/RuleInfo';
import { getIdentityProviderStep } from '../Base/components/IdentityProvider';
import { getConditionsStep } from '../Base/components/Conditions';
import { ManagedCompaniesMode } from '../AdminUser/enums';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';
import { getCompaniesStep } from './components/Companies';
import { getInitialProviderName } from '../Base/helpers/getInitialProviderName';

import type { SSORuleModel } from '../Base/interfaces/sso-rule-model';
import type { SSORuleProps } from '../Base/interfaces/sso-rule-props';

export const AdminSSORule = (props: SSORuleProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const rolesEnum = IdentityProviderRoleMappingRuleRole;
    const defaultModel: IdentityProviderRoleMappingRule = {
        name: '',
        role: rolesEnum.PortalOperator,
        organizationMappingSourceClaimType: '',
        additionalMappings: [],
        attributeMappings: [
            {
                claimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/firstName',
                allowAliases: true,
                attribute: IdentityProviderAttributeMappingAttribute.FirstName,
            },
            {
                claimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/lastName',
                allowAliases: true,
                attribute: IdentityProviderAttributeMappingAttribute.LastName,
            },
            {
                claimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
                allowAliases: true,
                attribute: IdentityProviderAttributeMappingAttribute.Name,
            },
            {
                claimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/address',
                allowAliases: true,
                attribute: IdentityProviderAttributeMappingAttribute.Address,
            },
            {
                claimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/phone',
                allowAliases: true,
                attribute: IdentityProviderAttributeMappingAttribute.Phone,
            },
        ],
    };
    const title = props.isEdit ? lang.EDIT_AUTHORIZATION_RULE : lang.NEW_AUTHORIZATION_RULE;

    return (
        <PageWizard
            title={title}
            deactivate={() => {}}
            isEdit={props.isEdit}
            data={{
                model: props.isEdit ? deepCopy(props.model) : defaultModel,
                providers: props.providers,
                rules: props.rules,
                identityProviderName: getInitialProviderName(props.providers),
                selectedCompanies: props.selectedCompanies || [],
                selectAllCompanies: props.selectAllCompanies ? ManagedCompaniesMode.AllCompanies : ManagedCompaniesMode.CustomSelection,
                wizard: props.wizard,
            } as SSORuleModel}
            steps={[
                getRuleInfoStep(lang, lang.RULE_INFO),
                getIdentityProviderStep(lang.IDENTITY_PROVIDER),
                getRoleStep(lang.ROLE),
                getCompaniesStep(lang.COMPANIES),
                getConditionsStep(lang, lang.CONDITIONS),
                getSummaryStep(lang.SUMMARY),
            ] as WizardStep<SSORuleModel>[]}
            onFinish={({ data }) => {
                const isRoleWithManagedCompanies = [rolesEnum.PortalOperator, rolesEnum.PortalReadonlyOperator].includes(data.model.role);

                const shouldSendCompaniesUids = isRoleWithManagedCompanies && data.selectAllCompanies === ManagedCompaniesMode.CustomSelection;
                const manageAllCompaniesValue = isRoleWithManagedCompanies
                    ? data.selectAllCompanies === ManagedCompaniesMode.AllCompanies
                    : true;

                const model: IdentityProviderRoleMappingRule = {
                    ...data.model,
                    managedCompaniesUids: shouldSendCompaniesUids
                        ? data.selectedCompanies.map(({ companyUid }) => companyUid)
                        : undefined,
                    manageAllCompanies: manageAllCompaniesValue,
                    organizationMappingSourceClaimType: data.model.organizationMappingSourceClaimType.trim(),
                    additionalMappings: data.model.additionalMappings.map(mapping => ({
                        ...mapping,
                        value: mapping.value.trim(),
                        claimType: mapping.claimType.trim(),
                    })),
                };

                return saveRule(transportService, { title, data, props, model });
            }}
        />
    );
};
