/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum VirtualReportPlatformType {
    Unknown = 0,
    VSphere = 1,
    HyperV = 2,
    NutanixAHV = 3,
}
