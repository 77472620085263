/**
 * Copyright © Veeam Software Group GmbH.
 */

import { createContext } from 'react';

import { extRouteProviderStore } from './ext-route-provider-store';

import type { ExtRouteProviderStoreType } from './ext-route-provider-store';

export const ExtRouteProviderStoreContext = createContext<ExtRouteProviderStoreType>(extRouteProviderStore);
