/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';

import type { OAuth2ClientSettings } from '@veeam-vspc/models/rest';

import { trimTextFields } from 'core/utils';

import type { ServerSettingsDialogFormData } from '../interfaces';

export const trimFormData = (formData: ServerSettingsDialogFormData): ServerSettingsDialogFormData => {
    const newFormData = deepCopy(formData);

    trimTextFields<ServerSettingsDialogFormData>(newFormData, ['host']);
    trimTextFields<OAuth2ClientSettings>(newFormData.smtp.oAuth2Credential.clientSettings, ['clientId', 'clientSecret', 'tenantId']);

    return newFormData;
};
