/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { LoginContext } from '../stores';

import type { LoginStore } from '../stores';

export const useLoginStore = (): LoginStore => useContext(LoginContext);
