/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ComboboxKit,
    STACK_GAP,
    Text,
    INDENT,
    constants,
    StackView,
    useGridApi,
} from '@veeam-vspc/shared/components';

import type {
    ComboboxProps,
} from '@veeam-vspc/shared/components';

export interface FilteringComboboxProp<T, K> extends ComboboxProps<T, K> {
    name: string;
    label?: string;
    defaultValue?: K;
}

export const FilteringCombobox = <T, K>(prop: FilteringComboboxProp<T, K>) => {
    const gridApi = useGridApi();
    const [value, setValue] = useState(gridApi.filters[prop.name] as string || prop.defaultValue);

    const getComboboxElement = () => (
        <ComboboxKit
            size={constants.CONTROL_SIZE_XXM}
            {...prop}
            value={value}
            onChange={(newValue) => {
                setValue(newValue);
                gridApi.applyFilters({ [prop.name]: newValue === prop.defaultValue ? null : newValue });
            }}
        />
    );

    if (!prop.label) {
        return getComboboxElement();
    }

    const labelText = `${prop.label}:`;

    return (
        <StackView
            gap={STACK_GAP.s}
        >
            <Text leader={INDENT.s}>
                {labelText}
            </Text>

            {getComboboxElement()}
        </StackView>
    );
};
