/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { colors, STACK_DIRECTION, StackView, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { ChartTitleStyled } from 'views/components/ChartWidget/components/ChartTitle';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { UsageTrendStore } from './stores';
import { UsageTrendContext } from './contexts';
import { getLineOptions } from 'views/components/HighchartsWrapper/helpers';
import { useProductStore } from '../../../../hooks';
import { useUsageTrendStore } from './hooks';

const UsageTrendChart = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { type } = useProductStore();
    const store = useUsageTrendStore();
    const { categories, empty, loading, series } = store;

    useEffect(() => {
        store.fetchData({ productType: type });
    }, [store, type]);

    const params = {
        widgetBgColor: colors.G100,

        colors: [
            colors.B400,
            colors.Grn400,
            '#834799',
            '#a55d00',
            colors.B250,
            colors.R300,
            colors.T500,
            '#eb8B0f',
            '#22C4c9',
            '#ffbc00',
            '#0066ad',
            '#4a9433',
        ],
    };

    const options = getLineOptions({
        categories,
        height: 332,
        series,
        ...params,
    });

    return (
        <StackView direction={STACK_DIRECTION.column} style={{ flex: 1 }}>
            <ChartTitleStyled>{lang.USAGE_TREND}</ChartTitleStyled>

            <HighchartsWrapper
                empty={empty}
                loading={loading}
                options={options}
            />
        </StackView>


    );
});

export const UsageTrend = () => {
    const { transportService } = useGlobalServices();
    const store = useMemo(() => new UsageTrendStore(transportService), []);

    return (
        <UsageTrendContext.Provider value={store}>
            <UsageTrendChart />
        </UsageTrendContext.Provider>
    );
};
