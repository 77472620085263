/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useGlobalAppData,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { FormErrors, WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { NoteBar } from 'components/layouts/NoteBar';
import { getClientDocUrl } from '../../../Base/helpers/getClientDocUrl';

import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

export const stepValidate = (data: CompanyUserModel): FormErrors<CompanyUserModel> => {
    const validator = new FormValidator(data);

    validator.validate('userRole').number();

    return validator.result();
};

export const Role: React.FC<WizardStepData<CompanyUserModel>> = ({ data, validationState, onDataChange }) => {
    const lang = useGlobalLang<VspcLang>();
    const roles = [];
    const { portalUser } = useGlobalAppData();

    if (portalUser.isLocationAdministrator()) {
        roles.push({ value: UserRoleRepresentation.CompanyLocationUser, text: lang.LOCATION_USER });
        roles.push({ value: UserRoleRepresentation.Subtenant, text: lang.SUBTENANT });
    } else {
        roles.push({ value: UserRoleRepresentation.CompanyAdministrator, text: lang.COMPANY_ADMINISTRATOR });
        roles.push({ value: UserRoleRepresentation.CompanyLocationAdministrator, text: lang.LOCATION_ADMINISTRATOR });
        roles.push({ value: UserRoleRepresentation.CompanyLocationUser, text: lang.LOCATION_USER });
        roles.push({ value: UserRoleRepresentation.Finance, text: lang.COMPANY_INVOICE_AUDITOR });
        roles.push({ value: UserRoleRepresentation.Subtenant, text: lang.SUBTENANT });
    }

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.ROLE}
                description={lang.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='userRole'
                        size={CONTROL_SIZE.m}
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={lang.ROLE}
                        inlineLabel
                    />

                    <NoteBar>
                        {data.userRole === UserRoleRepresentation.CompanyAdministrator && (
                            <p>
                                {lang.COMPANY_ADMINISTRATOR_HAS_ACCESS_TO_ALL_MONITORING}
                            </p>
                        )}

                        {data.userRole === UserRoleRepresentation.CompanyLocationAdministrator && (
                            <p>
                                {lang.LOCATION_ADMINISTRATOR_HAS_ACCESS_TO_ALL_DATA}
                            </p>
                        )}

                        {data.userRole === UserRoleRepresentation.CompanyLocationUser && (
                            <p>
                                {lang.LOCATION_USER_HAS_ACCESS_TO_THE_COMPANY}
                            </p>
                        )}

                        {data.userRole === UserRoleRepresentation.Finance && (
                            <p>
                                {lang.COMPANY_INVOICE_AUDITOR_HAS_ACCESS}
                            </p>
                        )}

                        {data.userRole === UserRoleRepresentation.Subtenant && (
                            <p>
                                {lang.SUBTENANT_IS_A_USER_WHO_HAS_CLOUD_REPOSITORY}
                            </p>
                        )}

                        <p>
                            {lang.CLICK}
                            {' '}

                            <a
                                href={getClientDocUrl(portalUser)}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.HERE}
                            </a>

                            {' '}
                            {lang.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    isHidden: ({ data, isEdit }) => {
        if (isEdit) {
            return true;
        }

        if (data.userRole === UserRoleRepresentation.CompanyOwner || data.userRole === UserRoleRepresentation.ResellerOwner) {
            return true;
        }
    },
    render: data => <Role {...data} />,
});
