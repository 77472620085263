/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import {
    LinkButton,
    usePageWizardStore,
    useGlobalLang,
    Text,
    STACK_DIRECTION,
    FormLayout,
    FormTitle,
    Spacer,
    FieldLayout,
    NumberInput,
    Checkbox,
    TEXT_VIEW,
    StackView,
    STACK_ALIGN,
    SPACING_M,
    SPACING_S,
    FIELD_LAYOUT_HELP_TEXT_VIEW,
    STACK_GAP,
    Form,
    useExternalFormApi,
    FormValidator,
    isValid,
    Combobox,
    CONTROL_SIZE,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { BackupServerBackupJobRetentionPolicyType, type BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { FormErrors, WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { BackupRepositoryPanel } from './components/BackupRepository/BackupRepository';
import { ConfigureGFS } from './components/ConfigureGFS/ConfigureGFS';
import { useJobWizardStore } from '../../stores';
import { getGfsPolicyHelpText } from '../utils/get-gfs-help-text';
import configureIcon from 'images/configure.svg';

import type { JobWizardStore } from '../../stores';

export const stepValidate = (data: BackupServerBackupJobConfiguration): FormErrors<BackupServerBackupJobConfiguration> => {
    const validator = new FormValidator(data);

    validator
        .validate('storage.retentionPolicy.quantity')
        .number()
        .required()
        .max(9999)
        .min(1);

    return validator.result();
};

const isGFSConfigured = (data: BackupServerBackupJobConfiguration) => {
    if (!data.storage.gfsPolicy.isEnabled) {
        return true;
    }

    return data.storage.gfsPolicy.weekly.isEnabled || data.storage.gfsPolicy.monthly.isEnabled || data.storage.gfsPolicy.yearly.isEnabled;
};

export const getStorageStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: lang.STORAGE,
    validate: () => {
        const valid = isValid(stepValidate, store.data) && Boolean(store.selectedRepository);

        if (!valid) {
            return false;
        }

        if (!isGFSConfigured(store.data)) {
            notificationService.warning(capitalize(lang.STORAGE), lang.GFS_RETENTION_POLICY_IS_NOT_CONFIGURED);
            return false;
        }

        return true;
    },
    render: data => <StorageStep {...data} />,
});

export const StorageStep = observer((wizardStepData: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore();
    const wizardStore = useJobWizardStore();
    const formApi = useExternalFormApi();
    const isEdit = wizardStepData.isEdit;

    const handleDataChange = (data: BackupServerBackupJobConfiguration) => {
        wizardStore.updateJobData({
            ...wizardStore.data,
            storage: {
                ...wizardStore.data.storage,
                ...data.storage,
                backupProxies: wizardStore.data.storage.backupProxies,
                backupRepositoryId: wizardStore.data.storage.backupRepositoryId,
            },
        });
    };

    const retentionType = useMemo(() => wizardStore.data.storage.retentionPolicy.type, []);
    const availableChangeRetentionType = isEdit && retentionType === BackupServerBackupJobRetentionPolicyType.RestorePoints;
    const retentionTypeReadOnlyLabel = retentionType === BackupServerBackupJobRetentionPolicyType.RestorePoints
        ? lang.RESTORE_POINTS.toLowerCase()
        : lang.DAYS.toLowerCase();

    return (
        <StepLayout
            title={lang.STORAGE}
        >
            <Form
                value={wizardStore.data}
                externalFormApi={formApi}
                onChange={handleDataChange}
                validate={stepValidate}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.BACKUP_REPOSITORY}</FormTitle>
                    <Text view={TEXT_VIEW.light}>{`${lang.SPECIFY_TARGET_BACKUP_REPOSITORY}.`}</Text>

                    <FormLayout>
                        <StackView
                            direction={STACK_DIRECTION.row}
                            align={STACK_ALIGN.center}
                            style={{ paddingTop: SPACING_S }}
                            gap={STACK_GAP.s}
                        >
                            <Text>
                                {`${lang.BACKUP_REPOSITORY}: `}
                            </Text>

                            <LinkButton
                                onClick={() => wizardContext.openPanel(hide => <BackupRepositoryPanel hidePanel={hide} />)}
                                error={!wizardStepData.validationState.isCommon() && !wizardStore.selectedRepository}
                            >
                                { wizardStore.selectedRepository?.name ?? lang.SELECT }
                            </LinkButton>
                        </StackView>
                    </FormLayout>

                    <FormTitle>{lang.RETENTION_POLICY}</FormTitle>
                    <Text view={TEXT_VIEW.light}>{`${lang.SPECIFY_RETENTION_POLICY_SETTINGS}.`}</Text>

                    <FormLayout
                        inlineLabel
                        direction={STACK_DIRECTION.row}
                    >
                        <FieldLayout>
                            <StackView style={{ paddingTop: SPACING_M, paddingBottom: SPACING_M }}>
                                <NumberInput
                                    inlineLabel={true}
                                    name={'storage.retentionPolicy.quantity'}
                                    label={lang.RETENTION_POLICY}
                                    suffix={availableChangeRetentionType ? null : retentionTypeReadOnlyLabel}
                                    minValue={1}
                                    maxValue={9999}
                                />
                            </StackView>
                        </FieldLayout>

                        { availableChangeRetentionType && (
                            <StackView style={{ paddingTop: SPACING_M, paddingBottom: SPACING_M }}>
                                <Combobox
                                    name='storage.retentionPolicy.type'
                                    data={[
                                        { value: BackupServerBackupJobRetentionPolicyType.Days, text: lang.DAYS },
                                        { value: BackupServerBackupJobRetentionPolicyType.RestorePoints, text: lang.RESTORE_POINTS },
                                    ]}
                                    valueGetter={x => x.value}
                                    textGetter={x => x.text}
                                    size={CONTROL_SIZE.s}
                                />
                            </StackView>
                        )}
                    </FormLayout>

                    <FormLayout
                        inlineLabel
                        direction={STACK_DIRECTION.row}
                    >
                        <Checkbox
                            name='storage.gfsPolicy.isEnabled'
                            helpText={getGfsPolicyHelpText(wizardStore.data, lang)}
                            helpTextView={FIELD_LAYOUT_HELP_TEXT_VIEW.labelDescription}
                        >
                            {lang.KEEP_CERTAIN_FULL_BACKUPS_LONGER}
                        </Checkbox>

                        <Spacer />

                        <LinkButton
                            iconBefore={configureIcon}
                            onClick={() => wizardContext.openPanel(hide => <ConfigureGFS hidePanel={hide} />)}
                            disabled={!wizardStore.data.storage.gfsPolicy.isEnabled}
                            error={!wizardStepData.validationState.isCommon() && !isGFSConfigured(wizardStore.data)}
                        >
                            {lang.CONFIGURE}
                        </LinkButton>
                    </FormLayout>
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
