/** 
* Copyright © Veeam Software Group GmbH.
*/

import { EnumMap } from './interfaces/enum-map';

export const webControllersEnumsMap: {
    [pathName: string] : {
        request?: EnumMap[];
        response?: EnumMap[];
    }
} = {
    '/accountmanagement/get': {
        request: [
            {
                path: [
                    'principal',
                    'contextType'
                ],
                enumName: 'contextType'
            },
            {
                path: [
                    'principal',
                    'roleType'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'principalObject',
                    '$i',
                    'accessType'
                ],
                enumName: 'ePrincipalAccessTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'principalObject',
                    '$i',
                    'type'
                ],
                enumName: 'ePrincipalObjectTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'mfaPolicyStatus'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'principalType',
                    '$i'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'roleType',
                    '$i'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'contextType'
                ],
                enumName: 'contextType'
            },
            {
                path: [
                    'roleType'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'accessType'
                ],
                enumName: 'ePrincipalAccessTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'type'
                ],
                enumName: 'ePrincipalObjectTypeRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/accountmanagement/getcompanies': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/accountmanagement/remove': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/accountmanagement/save': {
        request: [
            {
                path: [
                    'principal',
                    'contextType'
                ],
                enumName: 'contextType'
            },
            {
                path: [
                    'principal',
                    'roleType'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'principalObject',
                    '$i',
                    'accessType'
                ],
                enumName: 'ePrincipalAccessTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'principalObject',
                    '$i',
                    'type'
                ],
                enumName: 'ePrincipalObjectTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'principal',
                    'mfaPolicyStatus'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'principalType',
                    '$i'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'roleType',
                    '$i'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/accountmanagement/enablestatus': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/accountmanagement/disablestatus': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/accountmanagement/discoverysamaccount': {
        request: [
            {
                path: [
                    'discoveryModel',
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'contextType'
                ],
                enumName: 'contextType'
            },
            {
                path: [
                    'roleType'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'accessType'
                ],
                enumName: 'ePrincipalAccessTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'type'
                ],
                enumName: 'ePrincipalObjectTypeRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/accountmanagement/enablemfapolicystatus': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/accountmanagement/disablemfapolicystatus': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/hostedbackupserver/getservers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'backupServerStatus'
                ],
                enumName: 'backupServerStatusRepresentation'
            },
            {
                path: [
                    'backupServerVersionStatus'
                ],
                enumName: 'vbrVersionStatus'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'eVacAgentStatusInteg'
            }
        ]
    },
    '/hostedbackupserver/getvcdorganizationmapping': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'mappingStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'mappingStatusRepresentation'
            }
        ]
    },
    '/hostedbackupserver/getautomappedvcdorganizations': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'mappingStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'mappingStatusRepresentation'
            }
        ]
    },
    '/hostedbackupserver/getvirtualinfrastructuremapping': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/hostedbackupserver/removevcdorganizationmapping': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'mappingStatusRepresentation'
            }
        ]
    },
    '/hostedbackupserver/removeTag': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/get': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'serverVersionStatus'
                ],
                enumName: 'vbrVersionStatus'
            },
            {
                path: [
                    'serverType'
                ],
                enumName: 'serverTypeInteg'
            },
            {
                path: [
                    'vbrUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'backupServerStatus'
                ],
                enumName: 'backupServerStatusRepresentation'
            },
            {
                path: [
                    'uniquenessState'
                ],
                enumName: 'vbrInstanceUniquenessState'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'scheduledVbrTask',
                    'type'
                ],
                enumName: 'scheduledTaskType'
            },
            {
                path: [
                    'guestOSType'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'agentRole'
                ],
                enumName: 'agentRoleInteg'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'agentStatusInteg'
            },
            {
                path: [
                    'connectionStatus'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'companyManagerType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/backupserver/delete': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/regenerateuid': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/getlocation': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ]
    },
    '/backupserver/savelocation': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/resync': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/validatevbrupgradeparameters': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/upgrade': {
        request: [
            {
                path: [
                    'deploySettings',
                    'licenseSource'
                ],
                enumName: 'vbrDeploymentLicenseSourceType'
            },
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserver/getvbrtasklog': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'logRecordStatusInteg'
            }
        ]
    },
    '/backupserver/getupgradevbrwizardparameters': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ]
    },
    '/backupagent/get': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'operationModeId'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'vawLicenseStatus'
                ],
                enumName: 'backupAgentLicenseEditionRepresentation'
            },
            {
                path: [
                    'vawTemplateStatusId',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'commonVawTemplateStatus'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'guiMode'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'commonStatus'
                ],
                enumName: 'backupAgentStatusInteg'
            },
            {
                path: [
                    'cbtDriverStatus'
                ],
                enumName: 'backupAgentCbtDriverStatusRepresentation'
            },
            {
                path: [
                    'vawManagedMode'
                ],
                enumName: 'backupAgentRmmProviderModeRepresentation'
            },
            {
                path: [
                    'vawInstallationType'
                ],
                enumName: 'backupAgentInstallationTypeRepresentation'
            },
            {
                path: [
                    'vawPlatformId'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'vawVersionStatus'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'vawUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'guestOSType'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'agentRole'
                ],
                enumName: 'agentRoleInteg'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'agentStatusInteg'
            },
            {
                path: [
                    'connectionStatus'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'companyManagerType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/backupagent/assignbackuppolicydiscovery': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/canassignbackuppolicydiscovery': {
        request: [
            {
                path: [
                    'agents',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backupagent/assignbackuppolicy': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/canassignbackuppolicy': {
        request: [
            {
                path: [
                    'agents',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backupagent/reassignbackuppolicy': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/canreassignbackuppolicy': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backupagent/savelocation': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/getlocation': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/backupagent/update': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/delete': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/canactivate': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backupagent/activate': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/deactivate': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/resync': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/fullresync': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/installdriver': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/uninstalldriver': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/restartbackupagentservice': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/canrunpatch': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/runpatch': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/getbackupagents': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'guiMode'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'vawPlatform'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'jobsStatus'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'commonVawTemplateStatus'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'vawVersionStatus'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'vawUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            }
        ]
    },
    '/backupagent/getjobs': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'lastSessionStatus'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'scheduleType'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'vawTemplateStatus'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            }
        ]
    },
    '/backupagent/getsettings': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            }
        ]
    },
    '/backupagent/setsettings': {
        request: [
            {
                path: [
                    'settingsModel',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/startbackupagentjobbyagent': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/stopbackupagentjobbyagent': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/startbackupagentjob': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/stopbackupagentjob': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/deletejob': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/setdisabled': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/setfullmode': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/setreadonly': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/getconfiguration': {
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ]
    },
    '/backupagent/saveconfiguration': {
        request: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/cansaveconfiguration': {
        request: [
            {
                path: [
                    'agents',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backupagent/getrestorepoints': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/backupagent/getbackuppolicyfromjobconfiguration': {
        response: [
            {
                path: [
                    'accessType'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'saveMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ]
    },
    '/backupagent/downloadjoblogs': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/checktemplateexists': {
        request: [
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/backupagent/checkbackupagentquotausage': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupagent/rebootvawagents': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/getjobs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'lastSessionStateId'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'durationTrend'
                ],
                enumName: 'eTrend'
            },
            {
                path: [
                    'agentRole'
                ],
                enumName: 'agentRoleInteg'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'processedVmCounter',
                    'heaviestStatus'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'backupJobSourceTypeInteg'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'virtualPlatformType'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ]
    },
    '/backupserverjob/getprocessedvms': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            }
        ]
    },
    '/backupserverjob/getcdpprocessedvms': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'bottleneck'
                ],
                enumName: 'cdpBottleneckRepresentation'
            }
        ]
    },
    '/backupserverjob/getrestorepoints': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/backupserverjob/getjobstatistics': {
        response: [
            {
                path: [
                    'bottleneck'
                ],
                enumName: 'jobBottleneckRepresentation'
            }
        ]
    },
    '/backupserverjob/getcdpjobstatistics': {
        response: [
            {
                path: [
                    'lastPeriod',
                    'bottleneck'
                ],
                enumName: 'cdpBottleneckRepresentation'
            },
            {
                path: [
                    'lastDay',
                    'bottleneck'
                ],
                enumName: 'cdpBottleneckRepresentation'
            }
        ]
    },
    '/backupserverjob/getfilejobs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            }
        ]
    },
    '/backupserverjob/getfilejobsources': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sourceItems',
                    '$i',
                    'type'
                ],
                enumName: 'backupServerFileJobSourceItemType'
            }
        ]
    },
    '/backupserverjob/startjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/stopjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/retryjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/enablejob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/deleteJob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/assignjobtocompany': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/assignfilejobtocompany': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/getbackuprepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/backupserverjob/getbackupservercredentials': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'backupServerJobCredentialType'
            }
        ]
    },
    '/backupserveragentmanagement/assignmanagedagentjobtocompany': {
        request: [
            {
                path: [
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/assignobjectstoragejobtocompany': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/downloadjoblogs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/startfilejob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/stopfilejob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/retryfilejob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/enablefilejob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/downloadfilejoblogs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/getobjectstoragebackupjobs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            }
        ]
    },
    '/backupserverjob/getobjectstoragebackupjobsources': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sourceItems',
                    '$i',
                    'type'
                ],
                enumName: 'backupServerFileJobSourceItemType'
            }
        ]
    },
    '/backupserverjob/startobjectstoragebackupjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/stopobjectstoragebackupjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/retryobjectstoragebackupjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/enableobjectstoragebackupjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverjob/downloadobjectstoragebackupjoblogs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/protectedoverview/onpremises': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'vbrWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/backupagents': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'backupAgentWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/cloudconnect': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'cloudConnectWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/microsoft365': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'microsoft365Workloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/aws': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'awsWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/azure': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'azureWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/protectedoverview/google': {
        request: [
            {
                path: [
                    'filterType'
                ],
                enumName: 'protectedDataFilterBy'
            }
        ],
        response: [
            {
                path: [
                    'workloadType'
                ],
                enumName: 'googleWorkloads'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectionType'
                ],
                enumName: 'protectionType'
            },
            {
                path: [
                    'protectionData',
                    '$i',
                    'protectedCount',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/publiccloudefs/getprotectedefs': {
        request: [
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'policyState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'backup',
                    'backupType'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'backupCopy',
                    'backupType'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'restoreStatusModel',
                    'status'
                ],
                enumName: 'eVbFlrRestoreStatus'
            }
        ]
    },
    '/publiccloudefs/getprotectedefsbackuprestorepoints': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/publiccloudefsjob/getefsjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastBackupCopy',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            }
        ]
    },
    '/publiccloudefsjob/getbackuptasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudefsjob/getbackupcopytasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudefsjob/startpolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudefsjob/stoppolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudefsjob/deletepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudefsjob/enablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudefsjob/disablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrds/getprotectedrds': {
        request: [
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'policyState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'engine'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'snapshot',
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'remoteSnapshot',
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'backup',
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'archive',
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            }
        ]
    },
    '/publiccloudrds/getprotectedrdsbackuprestorepoints': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/publiccloudrdsjob/getrdsjob': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            }
        ]
    },
    '/publiccloudrdsjob/getrdsjobhierarchy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'data',
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            }
        ]
    },
    '/publiccloudrdsjob/getsnapshottasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudrdsjob/getremotesnapshottasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudrdsjob/getbackuptasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudrdsjob/getarchivetasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudrdsjob/startpolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrdsjob/stoppolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrdsjob/deletepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrdsjob/enablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrdsjob/disablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudrdsjob/createpolicylink': {
        request: [
            {
                path: [
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            }
        ]
    },
    '/publiccloudrdsjob/editpolicylink': {
        request: [
            {
                path: [
                    'engineType'
                ],
                enumName: 'eRdsEngineType'
            }
        ]
    },
    '/publiccloudvm/getprotectedvms': {
        request: [
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'snapshot',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'backup',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'remoteSnapshot',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'archive',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'backupCopy',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'tape',
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'restoreStatusModel',
                    'status'
                ],
                enumName: 'eVbFlrRestoreStatus'
            }
        ]
    },
    '/publiccloudvm/getprotectedvmbackups': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/publiccloudvm/getprotectedvmbackuprestorepoints': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopjobsbyduration': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'trend'
                ],
                enumName: 'eTrend'
            }
        ]
    },
    '/topinfrastructure/gettopjobsbyprocessedobjects': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopusedrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopbackupserversbyconsumedpoints': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopcloudconnectserversbytenants': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopusedcloudrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopcloudconnectserversbyconsumedpoints': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topinfrastructure/gettopusedhardwareplansbycompanies': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/trends/triggeredalarms': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'alarmStates'
            }
        ]
    },
    '/trends/successfulbackup': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'backupTaskTypes'
            }
        ]
    },
    '/trends/managedcompanies': {
        request: [
            {
                path: [
                    'organizationType'
                ],
                enumName: 'consumerOrganizationType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/consumedpoints': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'productType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'licenseUnitsTypes'
            }
        ]
    },
    '/trends/consumedpointspercompanytype': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'productType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'consumerOrganizationType'
            }
        ]
    },
    '/trends/managedbackupagents': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'managedAgentMode'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'managedAgentType'
            }
        ]
    },
    '/trends/managedvms': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'managedVmType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/monitoredobjects': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'monitoredObjectType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/protectedfileshares': {
        request: [
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'fileShareObjectType'
            }
        ]
    },
    '/trends/protectedmicrosoft365objects': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'microsoft365ObjectType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/protectedamazonobjects': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'amazonObjectType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/protectedazureobjects': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'azureObjectType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/protectedgoogleobjects': {
        request: [
            {
                path: [
                    'objectsType'
                ],
                enumName: 'googleCloudObjectType'
            },
            {
                path: [
                    'backupServicesType'
                ],
                enumName: 'backupServicesType'
            },
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'countTypes'
            }
        ]
    },
    '/trends/cloudvmbackups': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'backupTaskTypes'
            }
        ]
    },
    '/trends/cloudvmreplicas': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'backupTaskTypes'
            }
        ]
    },
    '/trends/cloudagentbackups': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'managedAgentType'
            }
        ]
    },
    '/trends/cloudsobrusage': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'sobrUsageType'
            }
        ]
    },
    '/trends/cloudstorageconsumed': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'sizeUnit'
            }
        ]
    },
    '/trends/getcloudtraffictrend': {
        request: [
            {
                path: [
                    'aggregation',
                    'unit'
                ],
                enumName: 'timeUnit'
            },
            {
                path: [
                    'interval',
                    'unit'
                ],
                enumName: 'timeUnit'
            }
        ],
        response: [
            {
                path: [
                    'trends',
                    '$i',
                    'counters',
                    '$i',
                    'type'
                ],
                enumName: 'trafficType'
            }
        ]
    },
    '/vbdeployaccount/getplatformaccounts': {
        request: [
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'serviceAccountStatus'
                ],
                enumName: 'publicCloudServiceAccountStatus'
            }
        ]
    },
    '/vbdeployaccount/removeplatformaccount': {
        request: [
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployaccount/validateremoveplatformaccount': {
        request: [
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployaccount/getguestosaccounts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'userRole'
                ],
                enumName: 'eExternalCredentialUserRoleRepresentation'
            }
        ]
    },
    '/vbdeployaccount/removeguestosaccount': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployaccount/getappliancesguestosusage': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployaccount/getappliancesplatformusage': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployaccount/getsqlaccounts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'ePublicCloudSqlAccountStatusRepresentation'
            }
        ]
    },
    '/vbdeployaccount/removesqlaccount': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployaccount/syncsqlaccount': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployaccount/getvbserverforsqlaccount': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/license/getvonelicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'eLicenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'manageLicenseProgressState'
                ],
                enumName: 'vbrLicenseManageActionStateRepresentation'
            },
            {
                path: [
                    'packages',
                    '$i'
                ],
                enumName: 'licensePackageRepresentation'
            },
            {
                path: [
                    'sectionToDelete',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'sectionTypes',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'unitsType'
                ],
                enumName: 'licenseUnitsTypeRepresentation'
            }
        ]
    },
    '/license/installvonelicense': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/setvonelicenseautoupdate': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/updatevonelicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/revokevonelicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/getlicenseinfo': {
        response: [
            {
                path: [
                    'licenseEdition'
                ],
                enumName: 'licenseEditionRepresentation'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'licensePackage'
                ],
                enumName: 'licensePackageRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'cloudConnectProvider'
                ],
                enumName: 'licenseCloudConnectStateRepresentation'
            },
            {
                path: [
                    'lastUpdateStatus'
                ],
                enumName: 'licenseUpdateState'
            }
        ]
    },
    '/license/checklicense': {
        response: [
            {
                path: [
                    'licenseEdition'
                ],
                enumName: 'licenseEditionRepresentation'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            }
        ]
    },
    '/license/getlicenseedition': {
        response: [
            {
                path: [
                    'licenseEdition'
                ],
                enumName: 'licenseEditionRepresentation'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            }
        ]
    },
    '/license/getlicenseusage': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            }
        ]
    },
    '/license/getlicenseusagetrend': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            }
        ]
    },
    '/license/getlicensestatistics': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            }
        ]
    },
    '/license/getmonthlyusageinfo': {
        response: [
            {
                path: [
                    'usageInfo',
                    '$i',
                    'usageType'
                ],
                enumName: 'approvalTarget'
            },
            {
                path: [
                    'popupType'
                ],
                enumName: 'popupType'
            }
        ]
    },
    '/license/getbackuplicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'eLicenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'manageLicenseProgressState'
                ],
                enumName: 'vbrLicenseManageActionStateRepresentation'
            },
            {
                path: [
                    'packages',
                    '$i'
                ],
                enumName: 'licensePackageRepresentation'
            },
            {
                path: [
                    'sectionToDelete',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'sectionTypes',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'unitsType'
                ],
                enumName: 'licenseUnitsTypeRepresentation'
            }
        ]
    },
    '/license/getlicenseworkloads': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'unitsType'
                ],
                enumName: 'licenseUnitsTypeRepresentation'
            }
        ]
    },
    '/license/getcloudconnectlicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'eLicenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'manageLicenseProgressState'
                ],
                enumName: 'vbrLicenseManageActionStateRepresentation'
            },
            {
                path: [
                    'packages',
                    '$i'
                ],
                enumName: 'licensePackageRepresentation'
            },
            {
                path: [
                    'sectionToDelete',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'sectionTypes',
                    '$i'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'unitsType'
                ],
                enumName: 'licenseUnitsTypeRepresentation'
            }
        ]
    },
    '/license/getvawlicense': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'licenseStatusFilter',
                    '$i'
                ],
                enumName: 'licenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'connectionStatusFilter',
                    '$i'
                ],
                enumName: 'backupAgentConnectionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'backupAgentPlatformId'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'licenseStatus'
                ],
                enumName: 'licenseStateRepresentation'
            },
            {
                path: [
                    'backupAgentPaidEditionStatus'
                ],
                enumName: 'backupAgentLicenseEditionRepresentation'
            },
            {
                path: [
                    'connectionStatus'
                ],
                enumName: 'backupAgentConnectionStatusRepresentation'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/license/installbackuplicense': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/setbackuplicenseautoupdate': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/updatebackuplicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/removebackuplicense': {
        request: [
            {
                path: [
                    'licenseSectionType'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/setcloudconnectlicenseautoupdate': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/updatecloudconnectlicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/removecloudconnectlicense': {
        request: [
            {
                path: [
                    'licenseSectionType'
                ],
                enumName: 'licenseSectionTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/installcloudconnectlicense': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/revokevawlicense': {
        request: [
            {
                path: [
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'licenseStatusFilter',
                    '$i'
                ],
                enumName: 'licenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'connectionStatusFilter',
                    '$i'
                ],
                enumName: 'backupAgentConnectionStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/updatelicense': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'licenseUpdateState'
            }
        ]
    },
    '/license/installlicense': {
        response: [
            {
                path: [
                    'licenseEdition'
                ],
                enumName: 'licenseEditionRepresentation'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'licensePackage'
                ],
                enumName: 'licensePackageRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'cloudConnectProvider'
                ],
                enumName: 'licenseCloudConnectStateRepresentation'
            },
            {
                path: [
                    'lastUpdateStatus'
                ],
                enumName: 'licenseUpdateState'
            }
        ]
    },
    '/license/getlicenseusagemonthlystatistics': {
        request: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            }
        ]
    },
    '/license/getlicenseusagereports': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'licenseUsageReportTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licenseUsageReportApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'licenseUsageReportApprovalStatus'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'licenseUsageReportTypeRepresentation'
            }
        ]
    },
    '/license/getvbolicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'eLicenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'state'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'manageLicenseProgressState'
                ],
                enumName: 'vbrLicenseManageActionStateRepresentation'
            }
        ]
    },
    '/license/installvbolicense': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/setvbolicenseautoupdate': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/updatevbolicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/license/revokevbolicense': {
        request: [
            {
                path: [
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/deployment/gettasklog': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'logRecordStatusInteg'
            }
        ]
    },
    '/deployment/resettasklog': {
        request: [
            {
                path: [
                    'logType'
                ],
                enumName: 'eTaskLogType'
            }
        ]
    },
    '/publiccloudvmjob/getvmjobs': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'policyType'
                ],
                enumName: 'ePublicCloudPolicyType'
            },
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'lastSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastBackup',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastRemoteSnapshot',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'lastArchive',
                    'sessionState'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobSessionResultRepresentation'
            },
            {
                path: [
                    'displayPolicyState'
                ],
                enumName: 'eDisplayPublicCloudPolicyState'
            },
            {
                path: [
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            }
        ]
    },
    '/publiccloudvmjob/getsnapshottasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudvmjob/getbackuptasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudvmjob/getremotesnapshottasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudvmjob/getarchivetasksfailuremessages': {
        request: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/publiccloudvmjob/startpolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudvmjob/stoppolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudvmjob/deletepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudvmjob/enablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudvmjob/disablepolicy': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/certificate/getcurretnvaccertificate': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/setvaccertificate': {
        request: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/setiiscertificate': {
        request: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/generateselfsigncertificate': {
        response: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/getcertificatesfromstore': {
        response: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/getiiscertificatesfromstore': {
        response: [
            {
                path: [
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/certificate/getvbcertificate': {
        response: [
            {
                path: [
                    'vbCertificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            },
            {
                path: [
                    'flrRootCertificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/backupserverpatching/initpatching': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverpatching/startautomaticpatching': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverpatching/restartcomputer': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverpatching/restartvbrservice': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserverpatching/getavailablepatches': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ]
    },
    '/backupserverpatching/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/get': {
        request: [
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'discoveryRuleStateRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            }
        ]
    },
    '/discoveryrule/getrulecredentials': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'linuxAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            }
        ]
    },
    '/discoveryrule/getdata': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'networkDiscoveryRuleTypeRepresentation'
            },
            {
                path: [
                    'adMethod'
                ],
                enumName: 'adMethodRepresentation'
            },
            {
                path: [
                    'notification',
                    'period'
                ],
                enumName: 'networkRuleNotificationPeriodRepresentation'
            },
            {
                path: [
                    'notification',
                    'dayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'filters',
                    'os',
                    'items',
                    '$i'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'filters',
                    'platform',
                    'items',
                    '$i'
                ],
                enumName: 'eMachineTypeRepresentation'
            },
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'winNTAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'linuxAccount',
                    '$i',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ]
    },
    '/discoveryrule/getglobalautodeployconfiguration': {
        response: [
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            }
        ]
    },
    '/discoveryrule/saveglobalautodeployconfiguration': {
        request: [
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            }
        ]
    },
    '/discoveryrule/save': {
        request: [
            {
                path: [
                    'type'
                ],
                enumName: 'networkDiscoveryRuleTypeRepresentation'
            },
            {
                path: [
                    'adMethod'
                ],
                enumName: 'adMethodRepresentation'
            },
            {
                path: [
                    'notification',
                    'period'
                ],
                enumName: 'networkRuleNotificationPeriodRepresentation'
            },
            {
                path: [
                    'notification',
                    'dayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'filters',
                    'os',
                    'items',
                    '$i'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'filters',
                    'platform',
                    'items',
                    '$i'
                ],
                enumName: 'eMachineTypeRepresentation'
            },
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'winNTAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'linuxAccount',
                    '$i',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/run': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/remove': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/stop': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/getcomputers': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'isVawInstalled'
                ],
                enumName: 'softwareStatusRepresentation'
            },
            {
                path: [
                    'isVacInstalled'
                ],
                enumName: 'softwareStatusRepresentation'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'platform'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'agentUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'machineType'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'vawInstallationType'
                ],
                enumName: 'backupAgentInstallationTypeRepresentation'
            },
            {
                path: [
                    'supportingVawStatus'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'vacVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'vawTemplateStatusId',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'commonVawTemplateStatus'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'scheduledVbrTask',
                    'type'
                ],
                enumName: 'scheduledTaskType'
            }
        ]
    },
    '/discoveryrule/deploy': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'config',
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'config',
                    'linuxAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'config',
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/getadtree': {
        response: [
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'domainNodeTypeRepresentation'
            }
        ]
    },
    '/discoveryrule/saveruleschedule': {
        request: [
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/gettasklog': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'logRecordStatusInteg'
            }
        ]
    },
    '/discoveryrule/validatevbrdeployparameters': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/deploybackupserver': {
        request: [
            {
                path: [
                    'deploySettings',
                    'licenseSource'
                ],
                enumName: 'vbrDeploymentLicenseSourceType'
            },
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/discoveryrule/getdeployvbrwizardparameters': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/discoveryrule/reset': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backuppolicy/get': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'backupPolicyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'creatorTypes',
                    '$i'
                ],
                enumName: 'backupPolicyCreatorTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'accessModes',
                    '$i'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'agents',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'templateType'
                ],
                enumName: 'backupPolicyTypeRepresentation'
            },
            {
                path: [
                    'creatorType'
                ],
                enumName: 'backupPolicyCreatorTypeRepresentation'
            },
            {
                path: [
                    'accessType'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'osPlatform'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/backuppolicy/getpolicy': {
        response: [
            {
                path: [
                    'accessType'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'saveMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ]
    },
    '/backuppolicy/save': {
        request: [
            {
                path: [
                    'accessType'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'saveMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'volumes',
                    '$i',
                    'type'
                ],
                enumName: 'valVolumeSourceType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupSource',
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileElementRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'targetType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'backupTarget',
                    'sharedFolder',
                    'sharedFolderTargetType'
                ],
                enumName: 'valSharedFolderTargetType'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'valJobCompressionLevel'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'storage',
                    'blockSize'
                ],
                enumName: 'valStorageBlockSizeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'indexingSettings',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scriptInfo',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'periodicallySchedule',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'activeFullSchedule',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'scheduleType'
                ],
                enumName: 'valScheduleTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'dailySchedule',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'days'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'scheduleInfo',
                    'healthCheckInfo',
                    'monthlySchedule',
                    'weekInMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'oracleInfo',
                    'truncationConfig',
                    'truncationMode'
                ],
                enumName: 'valArchivedLogsTruncationModeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'mySqlInfo',
                    'authType'
                ],
                enumName: 'valMySQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'processingType'
                ],
                enumName: 'valAapTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'aapInfo',
                    'postgreSqlInfo',
                    'authType'
                ],
                enumName: 'valPostgreSQLAuthTypeRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'valWeekInMonthRepresentation'
            },
            {
                path: [
                    'valBackupPolicyConfig',
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'operationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'destination'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'userFolders',
                    '$i'
                ],
                enumName: 'eUserProfileFolderTypeRepresentation'
            },
            {
                path: [
                    'personalFilesOptions',
                    'excludeSpecials',
                    '$i'
                ],
                enumName: 'eSpecialUserExcludesRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleInfoTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'backupPolicyScheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'frequencyTimeUnit'
                ],
                enumName: 'backupPolicyScheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'completionMode'
                ],
                enumName: 'scheduleCompletionModeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'backupWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'syntheticFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'activeFull',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'activeFull',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'healthCheck',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'healthCheck',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'compact',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'kind'
                ],
                enumName: 'activeFullKindRepresentation'
            },
            {
                path: [
                    'compact',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'compact',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'compact',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'storage',
                    'compressionLevel'
                ],
                enumName: 'storageCompressionLevelRepresentation'
            },
            {
                path: [
                    'storage',
                    'blockSize'
                ],
                enumName: 'storageBlockSizeRepresentation'
            },
            {
                path: [
                    'retention',
                    'retentionType'
                ],
                enumName: 'retentionTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'indexing',
                    'type'
                ],
                enumName: 'guestIndexingTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'sql',
                    'mode'
                ],
                enumName: 'guestSqlModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'mode'
                ],
                enumName: 'guestOracleModeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'oracle',
                    'accountType'
                ],
                enumName: 'guestOracleAccountTypeRepresentation'
            },
            {
                path: [
                    'guestProcessing',
                    'script',
                    'mode'
                ],
                enumName: 'guestScriptModeRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'weeklyPolicy',
                    'desiredDayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'gfsPolicy',
                    'monthlyPolicy',
                    'desiredWeekOfMonth'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'gfsPolicy',
                    'yearlyPolicy',
                    'desiredMonth'
                ],
                enumName: 'monthsRepresentation'
            }
        ]
    },
    '/backuppolicy/updatepolicy': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backuppolicy/canupdatepolicy': {
        response: [
            {
                path: [
                    'responseWindowMode'
                ],
                enumName: 'eResponseWindowMode'
            },
            {
                path: [
                    'affectedAgents',
                    '$i',
                    'affectType'
                ],
                enumName: 'eJobApplyAffectType'
            }
        ]
    },
    '/backuppolicy/remove': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backuppolicy/getassignedcompanies': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/backuppolicy/getassignedlocations': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/failoverplan/get': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'planStatus'
                ],
                enumName: 'failoverPlanStatusRepresentation'
            },
            {
                path: [
                    'planType'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            },
            {
                path: [
                    'sessionResult'
                ],
                enumName: 'jobSessionResultRepresentation'
            }
        ]
    },
    '/failoverplan/start': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/failoverplan/stop': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/failoverplan/test': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/failoverplan/setscripts': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/failoverplan/getsessionstates': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'failoverPlanSessionStatusRepresentation'
            }
        ]
    },
    '/failoverplan/getvms': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'vBRPlatformRepresentation'
            }
        ]
    },
    '/licensereport/getapprovalservicesaggregatedinfo': {
        response: [
            {
                path: [
                    'cloudServicesInfo',
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'managedServicesInfo',
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'hostedServicesInfo',
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'reportApprovalStatus'
                ],
                enumName: 'licenseUsageReportApprovalStatus'
            }
        ]
    },
    '/licensereport/getcloudservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/getmanagedservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'companyType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/gethostedservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/getcloudworkloads': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'companyType'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustStatus'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustableResource',
                    'items',
                    '$i',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'serviceApprovalStatus'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/getmanagedworkloads': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'companyType'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustStatus'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustableResource',
                    'items',
                    '$i',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'serviceApprovalStatus'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/gethostedworkloads': {
        request: [
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'companyType'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustStatus'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'workloadInfos',
                    '$i',
                    'adjustableResource',
                    'items',
                    '$i',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'serviceApprovalStatus'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ]
    },
    '/licensereport/approvecloudservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/approvemanagedservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/approvehostedservices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/adjustcloudworkloads': {
        request: [
            {
                path: [
                    'adjust',
                    'resourceAdjustment',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/adjustmanagedworkloads': {
        request: [
            {
                path: [
                    'adjust',
                    'resourceAdjustment',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/adjusthostedworkloads': {
        request: [
            {
                path: [
                    'adjust',
                    'resourceAdjustment',
                    'properties',
                    '$i',
                    'type'
                ],
                enumName: 'usageResourcePropertyType'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/resetcloudworkloads': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/resetmanagedworkloads': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/licensereport/resethostedworkloads': {
        request: [
            {
                path: [
                    'servicesFilter',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'licensedServiceApprovalStatus'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'workloadAdjustingStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getpulseconfiguration': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'pulseStatus'
            }
        ]
    },
    '/pulse/connect': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/disconnect': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/setcompanymapping': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/setlicensemanagement': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/setpushnewcompaniestopulse': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/synccompanies': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getsynccompanieslatestresult': {
        response: [
            {
                path: [
                    'syncStatus'
                ],
                enumName: 'pulseSyncStatus'
            }
        ]
    },
    '/pulse/getcompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'mappingStatus'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'pulseCompanySourceType'
            }
        ]
    },
    '/pulse/getresellers': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'mappingStatus'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'pulseCompanySourceType'
            }
        ]
    },
    '/pulse/removemapping': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getsites': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/pulse/getautomaticmappingoffers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'requestedCompanySourceType'
                ],
                enumName: 'pulseCompanySourceType'
            }
        ]
    },
    '/pulse/mapcompanies': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getmappingoffersforcompany': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'requestedCompanySourceType'
                ],
                enumName: 'pulseCompanySourceType'
            }
        ]
    },
    '/pulse/createvspccompany': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/createpulsecompany': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/mergecompanies': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/removemergeforcompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'pulseCompanyMappingStatus'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'pulseCompanySourceType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/synclicensekeys': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getsynclicensekeyslatestresult': {
        response: [
            {
                path: [
                    'syncStatus'
                ],
                enumName: 'pulseSyncStatus'
            }
        ]
    },
    '/pulse/getlicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getlicensekeycompanies': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            }
        ]
    },
    '/pulse/getassignoffers': {
        request: [
            {
                path: [
                    'filter',
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            }
        ]
    },
    '/pulse/getrevokelicensekeysinfo': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/pulse/revokelicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/deletelicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/assignlicensekeystocompany': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getproducts': {
        request: [
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/getcontracts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'automaticReporting'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            }
        ]
    },
    '/pulse/getapplicableworkloads': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/pulse/savelicensekey': {
        request: [
            {
                path: [
                    'licenseType'
                ],
                enumName: 'pulseLicenseType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getlicensekey': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'product',
                    'productType'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'contract',
                    'automaticReporting'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'licenseTypeAndExpiration',
                    'licenseType'
                ],
                enumName: 'pulseLicenseType'
            },
            {
                path: [
                    'automaticReporting'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            }
        ]
    },
    '/pulse/getlicensetypesandexpirations': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'licenseTypesAndExpirations',
                    '$i',
                    'licenseType'
                ],
                enumName: 'pulseLicenseType'
            }
        ]
    },
    '/pulse/copylicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/setlicensekeysautomaticreporting': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getlicensekeycompaniesshieldedxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            }
        ]
    },
    '/pulse/getcloudconnectserverslicensekeys': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getcloudconnectserversproducts': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/installcloudconnectserverslicense': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getvbrserverslicensekeys': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getvbrserversproducts': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/installvbrserverslicense': {
        request: [
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vbrLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getvoneserverslicensekeys': {
        request: [
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getvoneserversproducts': {
        request: [
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/installvoneserverslicense': {
        request: [
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vOneLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getvboserverslicensekeys': {
        request: [
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getvboserversproducts': {
        request: [
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/installvboserverslicense': {
        request: [
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'vboLicenseParams',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/pulse/getorganizationlicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'assignStatus'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'companyType'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'licenseUsageType'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'automaticReportingStatus'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'availability'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'installationStatus'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            }
        ]
    },
    '/pulse/getlicensekeysproductsfororganization': {
        request: [
            {
                path: [
                    'filter',
                    'productTypes',
                    '$i'
                ],
                enumName: 'pulseProductType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'pulseProductType'
            }
        ]
    },
    '/pulse/downloadlicensekeys': {
        request: [
            {
                path: [
                    'filter',
                    'assignStatuses',
                    '$i'
                ],
                enumName: 'pulseAssignStatus'
            },
            {
                path: [
                    'filter',
                    'licenseUsageTypes',
                    '$i'
                ],
                enumName: 'pulseLicenseUsageType'
            },
            {
                path: [
                    'filter',
                    'companyTypes',
                    '$i'
                ],
                enumName: 'pulseCompanyType'
            },
            {
                path: [
                    'filter',
                    'automaticReportingStatuses',
                    '$i'
                ],
                enumName: 'pulseAutomaticReportingStatus'
            },
            {
                path: [
                    'filter',
                    'availability',
                    '$i'
                ],
                enumName: 'licenseAvailability'
            },
            {
                path: [
                    'filter',
                    'installationStatus',
                    '$i'
                ],
                enumName: 'pulseLicenseInstallationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/report/getreports': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'scheduleTypes',
                    '$i'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'organizationTypes',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'aggregationModes',
                    '$i'
                ],
                enumName: 'reportAggregationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'accessModes',
                    '$i'
                ],
                enumName: 'reportAccessModeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyStatusId'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleType'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'stateId'
                ],
                enumName: 'reportGenerationStateRepresentation'
            },
            {
                path: [
                    'accessMode'
                ],
                enumName: 'reportAccessModeRepresentation'
            }
        ]
    },
    '/report/getgeneratedreports': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'organizationTypes',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'aggregationModes',
                    '$i'
                ],
                enumName: 'reportAggregationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'accessModes',
                    '$i'
                ],
                enumName: 'reportAccessModeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'sendingStateId'
                ],
                enumName: 'generatedReportStateRepresentation'
            },
            {
                path: [
                    'accessMode'
                ],
                enumName: 'reportAccessModeRepresentation'
            }
        ]
    },
    '/report/generatereport': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/report/getreportdata': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'rpoInterval',
                    'period'
                ],
                enumName: 'ePeriod'
            },
            {
                path: [
                    'parameters',
                    'aggregationMode'
                ],
                enumName: 'reportAggregationModeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'accessMode'
                ],
                enumName: 'reportAccessModeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'slaSettings',
                    'periodType'
                ],
                enumName: 'slaPeriodRepresentation'
            },
            {
                path: [
                    'parameters',
                    'vboObjectTypes',
                    '$i'
                ],
                enumName: 'vboObjectTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ]
    },
    '/report/save': {
        request: [
            {
                path: [
                    'type'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'rpoInterval',
                    'period'
                ],
                enumName: 'ePeriod'
            },
            {
                path: [
                    'parameters',
                    'aggregationMode'
                ],
                enumName: 'reportAggregationModeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'accessMode'
                ],
                enumName: 'reportAccessModeRepresentation'
            },
            {
                path: [
                    'parameters',
                    'slaSettings',
                    'periodType'
                ],
                enumName: 'slaPeriodRepresentation'
            },
            {
                path: [
                    'parameters',
                    'vboObjectTypes',
                    '$i'
                ],
                enumName: 'vboObjectTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/report/setreportscheduling': {
        request: [
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/report/getreportscheduling': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ]
    },
    '/report/delete': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/report/deletegeneratedreport': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/report/sendgeneratedreport': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/getresellers': {
        request: [
            {
                path: [
                    'reseller',
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'reseller',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/reseller/getreseller': {
        response: [
            {
                path: [
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/reseller/savereseller': {
        request: [
            {
                path: [
                    'reseller',
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'reseller',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/reseller/removeresellers': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/getcompanies': {
        request: [
            {
                path: [
                    'reseller',
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'reseller',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            }
        ]
    },
    '/reseller/addcompanies': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/removecompanies': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/getavailablecompanies': {
        request: [
            {
                path: [
                    'reseller',
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'reseller',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            }
        ]
    },
    '/reseller/enableresellers': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/disableresellers': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/resenduseremails': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/cancreatereseller': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/getassignedvcdresources': {
        request: [
            {
                path: [
                    'reseller',
                    'companyMap',
                    '$i',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'reseller',
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'reseller',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/reseller/enablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/disablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/reseller/getwelcomeemailcustomsection': {
        response: [
            {
                path: [
                    'organizationType'
                ],
                enumName: 'notificationOrganizationTypeRepresentation'
            },
            {
                path: [
                    'organizationScope'
                ],
                enumName: 'notificationOrganizationScopeRepresentation'
            },
            {
                path: [
                    'emailType'
                ],
                enumName: 'notificationEmailTypeRepresentation'
            }
        ]
    },
    '/backupserveragentmanagement/getbackupagentjobcontainers': {
        request: [
            {
                path: [
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'jobMode'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'jobKind'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'osPlatform'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            }
        ]
    },
    '/backupserveragentmanagement/getbackupagentjobs': {
        request: [
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'osPlatform'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            }
        ]
    },
    '/backupserveragentmanagement/getvbragentcopyjobobjects': {
        request: [
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'osPlatform'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'licenseType'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            }
        ]
    },
    '/backupserveragentmanagement/startjob': {
        request: [
            {
                path: [
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserveragentmanagement/stopjob': {
        request: [
            {
                path: [
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/backupserveragentmanagement/enablejob': {
        request: [
            {
                path: [
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/protectedworkloads/getvms': {
        request: [
            {
                path: [
                    'filter',
                    'targetTypes',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'jobTypes',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'filter',
                    'platformTypes',
                    '$i'
                ],
                enumName: 'protectedVmVirtualPlatformType'
            },
            {
                path: [
                    'filter',
                    'cloudCopyStatus',
                    '$i'
                ],
                enumName: 'protectedVmCloudCopyStatus'
            },
            {
                path: [
                    'filter',
                    'similarJobTypes',
                    '$i'
                ],
                enumName: 'protectedVmSimilarJobTypes'
            },
            {
                path: [
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'restoreTypes',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'targetType'
                ],
                enumName: 'protectedVmCloudCopyStatus'
            },
            {
                path: [
                    'platform'
                ],
                enumName: 'protectedVmVirtualPlatformType'
            },
            {
                path: [
                    'backup',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'replication',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'backupCopy',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'tape',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'snapshot',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'remoteSnapshot',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'archive',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'cdpReplication',
                    'type'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getvmsrestorepoints': {
        request: [
            {
                path: [
                    'protectionType'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getvmsbackuprestorepoints': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'algorithm'
                ],
                enumName: 'restorePointAlgorithm'
            },
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getcomputers': {
        request: [
            {
                path: [
                    'filter',
                    'restoreStatus',
                    '$i'
                ],
                enumName: 'eRestoreStatus'
            },
            {
                path: [
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'flrStatusInfo',
                    'status'
                ],
                enumName: 'eRestoreStatus'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'backupTargetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/protectedworkloads/getcomputersmanagedbyvbr': {
        request: [
            {
                path: [
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'backupTargetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getcomputersprotectingjobs': {
        request: [
            {
                path: [
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'backupTargetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'jobKind'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'jobManagementMode'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'endpointSourceTypeRepresentation'
            },
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getcomputersprotectingcopyjobs': {
        request: [
            {
                path: [
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'backupMode'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'backupTargetType'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'jobKind'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'jobManagementMode'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'destinationType'
                ],
                enumName: 'targetTypeRepresentation'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'endpointSourceTypeRepresentation'
            },
            {
                path: [
                    'malwareState'
                ],
                enumName: 'malwareState'
            }
        ]
    },
    '/protectedworkloads/getfileshares': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'sourceItems',
                    '$i',
                    'type'
                ],
                enumName: 'backupServerFileJobSourceItemType'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ]
    },
    '/protectedworkloads/getobjectstorages': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'sourceItems',
                    '$i',
                    'type'
                ],
                enumName: 'backupServerFileJobSourceItemType'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'jobTypeRepresentation'
            }
        ]
    },
    '/vbcredentials/validateguestospasswordonvb': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbcredentials/saveguestoscredentials': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'role'
                ],
                enumName: 'eExternalCredentialUserRoleRepresentation'
            }
        ]
    },
    '/vbcredentials/checkvbavailability': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbcredentials/savecloudplatformcredentials': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbcredentials/getawsaccountdata': {
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbcredentials/getazureaccountdata': {
        response: [
            {
                path: [
                    'subscriptions',
                    '$i',
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            },
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/saveazureaccount': {
        response: [
            {
                path: [
                    'subscriptions',
                    '$i',
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/saveazureoperationalaccount': {
        request: [
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ],
        response: [
            {
                path: [
                    'subscriptions',
                    '$i',
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/getazurevbsubscriptionsbyapp': {
        request: [
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/getazurevbsubscriptionsbydevicecode': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/getazurevbtenantsbydevicecode': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbcredentials/getazurevbmanagementgroupsbydevicecode': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbcredentials/checkazureaccountpermissions': {
        request: [
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ],
        response: [
            {
                path: [
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/exportpermissionsbyapp': {
        request: [
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/exportpermissionsbydevicecode': {
        request: [
            {
                path: [
                    'purposes',
                    '$i'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbcredentials/getcredentialsforguestos': {
        request: [
            {
                path: [
                    'roles',
                    '$i'
                ],
                enumName: 'eExternalCredentialUserRoleRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'eVbCredentialType'
            }
        ]
    },
    '/vbcredentials/getcredentialsforcloudplatform': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'eVbCredentialType'
            }
        ]
    },
    '/vbcredentials/getsites': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbcredentials/getvbappliances': {
        request: [
            {
                path: [
                    'filter',
                    'platforms',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbcredentials/getgcpprojects': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbcredentials/removegcpproject': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployawsbrowse/getaccounts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getregions': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/deleteconnection': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployawsbrowse/getinstances': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/validatevirtualmachine': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployawsbrowse/validatevirtualmachineavailable': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeployawsbrowse/getnetworks': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getsubnetworks': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getappliancepublicaddresses': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getkeypairs': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getsecuritygroups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/getrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployawsbrowse/validateserverconnection': {
        response: [
            {
                path: [
                    'errorType'
                ],
                enumName: 'eAwsServerConnectionValidationErrorType'
            },
            {
                path: [
                    'confirmType',
                    '$i'
                ],
                enumName: 'eAwsServerConnectionValidationConfirmType'
            },
            {
                path: [
                    'certificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/vbdeployawsbrowse/validateserverconnectiondirect': {
        response: [
            {
                path: [
                    'errorType'
                ],
                enumName: 'eAwsServerConnectionValidationErrorType'
            },
            {
                path: [
                    'confirmType',
                    '$i'
                ],
                enumName: 'eAwsServerConnectionValidationConfirmType'
            },
            {
                path: [
                    'certificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/vbdeployawsbrowse/validateserver': {
        response: [
            {
                path: [
                    'errorType'
                ],
                enumName: 'eAwsServerValidationErrorType'
            }
        ]
    },
    '/vbdeployazurebrowse/getaccounts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getsubscriptions': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'missingPurposes',
                    '$i',
                    'purpose'
                ],
                enumName: 'azureAccountPurposeJson'
            }
        ]
    },
    '/vbdeployazurebrowse/getdatacenters': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getresourcegroups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/validatevirtualmachine': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getvirtualmachines': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getnetworks': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getsecuritygroups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getbackupserverpublicaddress': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getappliancepublicaddresses': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'ipAllocationMethod'
                ],
                enumName: 'eIpAllocationMethod'
            }
        ]
    },
    '/vbdeployazurebrowse/getvbappliancecredentials': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/getkeypairs': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeployazurebrowse/validateserverconnection': {
        response: [
            {
                path: [
                    'result'
                ],
                enumName: 'azureServerConnectionValidationResultType'
            },
            {
                path: [
                    'certificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/vbdeploy/validateplatformserviceversion': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'operationType'
                ],
                enumName: 'eValidatePlatformServiceVersionOperationType'
            }
        ]
    },
    '/vbdeploygcpbrowse/getaccounts': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getregions': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getavailabilityzones': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getnetworks': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getsubnetworks': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getsecuritygroups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getinstances': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getstaticipaddresses': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/getbackupserverpublicaddress': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbdeploygcpbrowse/validateserverconnection': {
        response: [
            {
                path: [
                    'result'
                ],
                enumName: 'gcpServerConnectionValidationResultType'
            },
            {
                path: [
                    'certificate',
                    'role'
                ],
                enumName: 'vacCertificateRole'
            }
        ]
    },
    '/vbdeploygcpbrowse/validateserver': {
        response: [
            {
                path: [
                    'errorType'
                ],
                enumName: 'gcpServerValidationErrorType'
            }
        ]
    },
    '/vbdeploy/deployawsnewvbappliance': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/getawsnewappliancesettings': {
        response: [
            {
                path: [
                    'deploymentMode'
                ],
                enumName: 'eApplianceDeploymentModes'
            }
        ]
    },
    '/vbdeploy/getazurenewappliancesettings': {
        response: [
            {
                path: [
                    'deploymentMode'
                ],
                enumName: 'eApplianceDeploymentModes'
            }
        ]
    },
    '/vbdeploy/getgcpnewappliancesettings': {
        response: [
            {
                path: [
                    'deploymentMode'
                ],
                enumName: 'eApplianceDeploymentModes'
            }
        ]
    },
    '/vbdeploy/getazureeditappliancesettings': {
        response: [
            {
                path: [
                    'deploymentMode'
                ],
                enumName: 'eApplianceDeploymentModes'
            }
        ]
    },
    '/vbdeploy/getgcpeditappliancesettings': {
        response: [
            {
                path: [
                    'deploymentMode'
                ],
                enumName: 'eApplianceDeploymentModes'
            }
        ]
    },
    '/vbdeploy/removevbappliance': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/removevbappliance/validate': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/vbupgradeappliance': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/vbplugincollectdata': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/vbcollectdata': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'managementType',
                    '$i'
                ],
                enumName: 'eApplianceManagementType'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbdeploy/validateawscloudaccountsmigratepermissions': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ]
    },
    '/publiccloudpatching/initpatching': {
        request: [
            {
                path: [
                    'patchSessionModel',
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/publiccloudpatching/waittaskresult': {
        response: [
            {
                path: [
                    'taskStatus',
                    '$i',
                    'taskStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            }
        ]
    },
    '/publiccloudpatching/waittaskresultma': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbplugin/getappliances': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'applianceStatus'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'updateStatus'
                ],
                enumName: 'eVbPluginApplianceUpdateStatus'
            },
            {
                path: [
                    'deploymentStatus'
                ],
                enumName: 'eVbPluginApplianceDeploymentStatus'
            },
            {
                path: [
                    'certificateStatus'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'isSelectRemoteUiAccessEnabled'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ]
    },
    '/vbplugin/getcloudbackupappliances': {
        request: [
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'managementType',
                    '$i'
                ],
                enumName: 'eApplianceManagementType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'applianceStatus'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'updateStatus'
                ],
                enumName: 'eVbPluginApplianceUpdateStatus'
            },
            {
                path: [
                    'managementType'
                ],
                enumName: 'eApplianceManagementType'
            },
            {
                path: [
                    'certificateStatus'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'isSelectRemoteUiAccessEnabled'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            }
        ]
    },
    '/vbplugin/gettasklog': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'logRecordStatusInteg'
            }
        ]
    },
    '/vbplugin/getmappingcompanylist': {
        response: [
            {
                path: [
                    'companyType'
                ],
                enumName: 'publicCloudCompanyTypeRepresentation'
            }
        ]
    },
    '/vbplugin/getrestorelink': {
        request: [
            {
                path: [
                    'restoreType'
                ],
                enumName: 'ePublicCloudRestoreType'
            }
        ]
    },
    '/vbplugin/getflrsessionworkerlink': {
        request: [
            {
                path: [
                    'restoreType'
                ],
                enumName: 'ePublicCloudRestoreType'
            }
        ]
    },
    '/vbplugin/getsites': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vbplugin/getavailablevb': {
        request: [
            {
                path: [
                    'filter',
                    'platforms',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbplugin/getrepositories': {
        request: [
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'storageClass'
                ],
                enumName: 'eStorageClassRepresentation'
            },
            {
                path: [
                    'creatingState',
                    'state'
                ],
                enumName: 'ePublicCloudObjectCreatingState'
            }
        ]
    },
    '/vbplugin/removerepository': {
        request: [
            {
                path: [
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vbplugin/getrepositoriesappliancefilter': {
        request: [
            {
                path: [
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            }
        ]
    },
    '/vbplugin/newpolicysession': {
        request: [
            {
                path: [
                    'type'
                ],
                enumName: 'ePublicCloudPolicyType'
            }
        ]
    },
    '/vboserver/getvboserver': {
        response: [
            {
                path: [
                    'connectionAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            },
            {
                path: [
                    'serviceAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            }
        ]
    },
    '/vboserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'applicationStatus'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'activationStatus'
                ],
                enumName: 'vboServerActivationStatusRepresentation'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'upgradeInfo',
                    'status'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'vboUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ]
    },
    '/vboserver/deletesystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/registervboserver': {
        request: [
            {
                path: [
                    'connectionAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            },
            {
                path: [
                    'serviceAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/getmappedorganizations': {
        request: [
            {
                path: [
                    'filter',
                    'mappingStatus',
                    '$i'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'mappingStatus'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            }
        ]
    },
    '/vboserver/getvboorganizationstomap': {
        request: [
            {
                path: [
                    'filter',
                    'mappingStatus',
                    '$i'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'mappingStatus'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'sourceType'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            }
        ]
    },
    '/vboserver/getsites': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vboserver/unmap': {
        request: [
            {
                path: [
                    'filter',
                    'mappingStatus',
                    '$i'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            }
        ]
    },
    '/vboserver/getautomaporganizations': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/vboserver/createorganization': {
        request: [
            {
                path: [
                    'filter',
                    'mappingStatus',
                    '$i'
                ],
                enumName: 'vboCompanyMappingStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'sourceType',
                    '$i'
                ],
                enumName: 'vboMappedCompanySourceTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/get': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'applicationStatus'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'activationStatus'
                ],
                enumName: 'vboServerActivationStatusRepresentation'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'upgradeInfo',
                    'status'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'vboUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ]
    },
    '/vboserver/activate': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/resync': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/fullresync': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/delete': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/vboserver/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/get': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'applicationStatus'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'upgradeInfo',
                    'status'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'voneUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'alarmsSynchronizationStatus'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ]
    },
    '/voneserver/features/alarmsynchronization': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/resync': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/delete': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/upgrade': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/restart': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/reboot': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/getvoneserver': {
        response: [
            {
                path: [
                    'connectionAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            },
            {
                path: [
                    'serviceAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            }
        ]
    },
    '/voneserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'applicationStatus'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'upgradeInfo',
                    'status'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'voneUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'alarmsSynchronizationStatus'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ]
    },
    '/voneserver/deletesystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/registervoneserver': {
        request: [
            {
                path: [
                    'connectionAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            },
            {
                path: [
                    'serviceAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/voneserver/validateserviceaccount': {
        request: [
            {
                path: [
                    'connectionAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            },
            {
                path: [
                    'serviceAccount',
                    'authMode'
                ],
                enumName: 'accountAuthMode'
            }
        ]
    },
    '/xmlexport/getcompaniesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getresellersxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getbackuppoliciesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'backupPolicyTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'creatorTypes',
                    '$i'
                ],
                enumName: 'backupPolicyCreatorTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'accessModes',
                    '$i'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getfilejobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getfilejobsourcesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getobjectstoragebackupjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getobjectstoragebackupjobsourcesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getactivealarmsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'data',
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'data',
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getbackupserversxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvoneserversxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvonelicensesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvawagentsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvboserversxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvbolicensesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getaccesslogsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'activityKind',
                    '$i'
                ],
                enumName: 'activityKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getsubscriptionplansxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getalarmsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'alarmObjectCategory',
                    '$i'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getdiscoveredcomputersxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getbackupagentsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getbackupagentsjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getfailoverplansxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getagenttasksxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'task'
                ],
                enumName: 'eTaskTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'agentTaskStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvawlicensexml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseStatusFilter',
                    '$i'
                ],
                enumName: 'licenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'connectionStatusFilter',
                    '$i'
                ],
                enumName: 'backupAgentConnectionStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getbackuplicensexml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcloudconnectlicensexml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvbragentjobcontainersxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/cdpprocessedvmsxml': {
        request: [
            {
                path: [
                    'data',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/exporthostedbackupserversxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/exporthostedvcdorganizationmappingxml': {
        request: [
            {
                path: [
                    'data',
                    'status',
                    '$i'
                ],
                enumName: 'mappingStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/exporthostedvirtualinfrastructuremappingxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvbragentjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvbragentcopyjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvirtualmachinesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'targetTypes',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobTypes',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platformTypes',
                    '$i'
                ],
                enumName: 'protectedVmVirtualPlatformType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'cloudCopyStatus',
                    '$i'
                ],
                enumName: 'protectedVmCloudCopyStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'similarJobTypes',
                    '$i'
                ],
                enumName: 'protectedVmSimilarJobTypes'
            },
            {
                path: [
                    'data',
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getprotectedvmrestorepointsxml': {
        request: [
            {
                path: [
                    'protectionType'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getprotectedvmbackuprestorepointsxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcomputersxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'restoreStatus',
                    '$i'
                ],
                enumName: 'eRestoreStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcomputersmanagedbyvbrxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcomputersprotectingjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcomputersrestorepointsxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcomputersprotectingcopyjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getfilesharesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getobjectstoragesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudefsjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudrdsjobsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getjobheatmapdetailsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'heatmapJobType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'workloadType',
                    '$i'
                ],
                enumName: 'workloadTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'platformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudvmxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudvmbackupxml': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudvmrestorepointxml': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudefsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudefsrestorepointsxml': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudrdsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getpubliccloudrdsrestorepointsxml': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getprocessedvmsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getrestorepointsxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getvbappliancesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'data',
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'data',
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getcloudbackupappliancesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'managementType',
                    '$i'
                ],
                enumName: 'eApplianceManagementType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getplatformaccountsxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getguestosaccountsxml': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/getrepositoriesxml': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/vboserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/xmlexport/voneserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/agent/getagents': {
        request: [
            {
                path: [
                    'filter',
                    'excludeStatuses',
                    '$i'
                ],
                enumName: 'agentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'guestOSType'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'agentRole'
                ],
                enumName: 'agentRoleInteg'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'agentStatusInteg'
            },
            {
                path: [
                    'connectionStatus'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'agentVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'companyManagerType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/agent/upgrade': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/restart': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/savelocation': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/setrejectstate': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/movetocompany': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/getcompaniesmoveto': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/agent/getcompaniesdownloadto': {
        request: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/agent/candownloadagent': {
        request: [
            {
                path: [
                    'osType'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/agent/getagentstodelete': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/agent/delete': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/reboot': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/getlocation': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/agent/setcustomnote': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/setasmaster': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/downloadlogs': {
        request: [
            {
                path: [
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/agent/getagenttasks': {
        request: [
            {
                path: [
                    'filter',
                    'task'
                ],
                enumName: 'eTaskTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'agentTaskStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'agentTaskStatus'
            }
        ]
    },
    '/agent/getagenttasknames': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'eTaskTypeRepresentation'
            }
        ]
    },
    '/agent/getbackupagenttasklog': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'items',
                    '$i',
                    'status'
                ],
                enumName: 'logRecordStatusInteg'
            }
        ]
    },
    '/alarm/getactivealarms': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'statusID'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'objectTypeID'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'area'
                ],
                enumName: 'alarmArea'
            }
        ]
    },
    '/alarm/resolvealarm': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/alarm/acknowledgealarm': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/alarm/clearalarmhistory': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/alarm/getalarms': {
        request: [
            {
                path: [
                    'filter',
                    'alarmObjectCategory',
                    '$i'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'category'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'exclusionType'
                ],
                enumName: 'eAlarmExclusionType'
            }
        ]
    },
    '/alarm/getalarminfo': {
        response: [
            {
                path: [
                    'alarmCategory'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'triggers',
                    '$i',
                    'triggerType'
                ],
                enumName: 'triggerTypeRepresentation'
            },
            {
                path: [
                    'defaultTriggerType'
                ],
                enumName: 'triggerTypeRepresentation'
            }
        ]
    },
    '/alarm/getalarm': {
        response: [
            {
                path: [
                    'alarmCategory'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'triggers',
                    '$i',
                    'triggerType'
                ],
                enumName: 'triggerTypeRepresentation'
            },
            {
                path: [
                    'defaultTriggerType'
                ],
                enumName: 'triggerTypeRepresentation'
            }
        ]
    },
    '/alarm/gettriggeredalarmhistoryext': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'items',
                    '$i',
                    'severity'
                ],
                enumName: 'triggerSeverityRepresentation'
            }
        ]
    },
    '/alarm/setalarm': {
        request: [
            {
                path: [
                    'triggers',
                    '$i',
                    'triggerType'
                ],
                enumName: 'triggerTypeRepresentation'
            }
        ]
    },
    '/alarm/getalarmassignstatestree': {
        response: [
            {
                path: [
                    'states',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            }
        ]
    },
    '/alarm/savealarmassign': {
        request: [
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            },
            {
                path: [
                    'dataArray',
                    '$i',
                    'states',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'objectType'
                ],
                enumName: 'assignAndExcludeTypeRepresentation'
            }
        ]
    },
    '/alarm/setalarmsenabled': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/alarm/deletealarms': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/billing/getbillingsummarymonthlyincomestate': {
        request: [
            {
                path: [
                    'interval'
                ],
                enumName: 'reportIntervalRepresentation'
            }
        ]
    },
    '/billing/getbillingsummaryinvoicestatus': {
        request: [
            {
                path: [
                    'interval'
                ],
                enumName: 'reportIntervalRepresentation'
            }
        ]
    },
    '/billing/getbillingsummaryrevenue': {
        request: [
            {
                path: [
                    'interval'
                ],
                enumName: 'reportIntervalRepresentation'
            }
        ]
    },
    '/billing/getbillingsummarytopcompanies': {
        request: [
            {
                path: [
                    'interval'
                ],
                enumName: 'reportIntervalRepresentation'
            }
        ]
    },
    '/invoice/getinvoices': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'invoicePaidState'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'statusId'
                ],
                enumName: 'invoicePaidState'
            },
            {
                path: [
                    'sendingStateId'
                ],
                enumName: 'generatedReportStateRepresentation'
            },
            {
                path: [
                    'dealerType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/invoice/getinvoiceoptions': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'invoiceOptionsTypeRepresentation'
            }
        ]
    },
    '/invoice/resendinvoice': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/deleteinvoice': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/setinvoiceoptions': {
        request: [
            {
                path: [
                    'opts',
                    'type'
                ],
                enumName: 'invoiceOptionsTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/getinvoicesettings': {
        request: [
            {
                path: [
                    'filter',
                    'scheduleTypes',
                    '$i'
                ],
                enumName: 'invoiceScheduleTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyStatusId'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'reportTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'schedule',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'schedule',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'schedule',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'schedule',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleType'
                ],
                enumName: 'invoiceScheduleTypeRepresentation'
            },
            {
                path: [
                    'stateId'
                ],
                enumName: 'reportGenerationStateRepresentation'
            }
        ]
    },
    '/invoice/enableinvoicesettings': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/createinvoiceimmediately': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/createquotausageimmediately': {
        request: [
            {
                path: [
                    'options',
                    'pastIntervalMeasure'
                ],
                enumName: 'invoiceIntervalMeasureRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/invoice/setinvoicestatus': {
        request: [
            {
                path: [
                    'status'
                ],
                enumName: 'invoicePaidState'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/get': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'deployStatus'
                ],
                enumName: 'deployAgentStatusRepresentation'
            },
            {
                path: [
                    'serverVersionStatus'
                ],
                enumName: 'vbrVersionStatus'
            },
            {
                path: [
                    'vbrUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            },
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'agentRole'
                ],
                enumName: 'agentRoleInteg'
            },
            {
                path: [
                    'vacVersionStatus'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'inMaintenanceMode'
                ],
                enumName: 'backupServerCloudMaintenanceMode'
            },
            {
                path: [
                    'inTenantManagementMode'
                ],
                enumName: 'backupServerCloudTenantManagementMode'
            }
        ]
    },
    '/cloudconnectservers/checkagent': {
        response: [
            {
                path: [
                    'role'
                ],
                enumName: 'agentRoleInteg'
            }
        ]
    },
    '/cloudconnectservers/remove': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/resync': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/upgrade': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/reboot': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/setmaintenancemode': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/settenantmanagementmode': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/cloudconnectservers/getcloudgatewaypools': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/cloudconnectservers/accept': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/common/checkitemexist': {
        request: [
            {
                path: [
                    'type'
                ],
                enumName: 'vacInternalItemType'
            }
        ]
    },
    '/company/getcompaniestree': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'children',
                    '$i',
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/company/getcompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'companyStatusId'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'managerType'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'tenantType'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            }
        ]
    },
    '/company/getcompanylist': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'tenantType'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'managerType'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ]
    },
    '/company/getcompany': {
        response: [
            {
                path: [
                    'allowedBandwidthUnits'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'hwPlans',
                    '$i',
                    'platform'
                ],
                enumName: 'vBRPlatformRepresentation'
            },
            {
                path: [
                    'gatewaySelectionType'
                ],
                enumName: 'cloudGatewaySelectionTypeRepresentation'
            },
            {
                path: [
                    'tenantType'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/company/savecompany': {
        request: [
            {
                path: [
                    'data',
                    'allowedBandwidthUnits'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            },
            {
                path: [
                    'data',
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'data',
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'hwPlans',
                    '$i',
                    'platform'
                ],
                enumName: 'vBRPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'gatewaySelectionType'
                ],
                enumName: 'cloudGatewaySelectionTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'tenantType'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'mfaPolicyStatus'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            }
        ]
    },
    '/company/setbillingforcompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/disablecompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/removecompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/getcompaniesmappedtopulsetenants': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ]
    },
    '/company/enablecompanies': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/resenduseremails': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/getavailablebackupresource': {
        response: [
            {
                path: [
                    'data',
                    '$i',
                    'type'
                ],
                enumName: 'repositoryTypeRepresentation'
            }
        ]
    },
    '/company/getavailablereplicationresource': {
        response: [
            {
                path: [
                    'data',
                    '$i',
                    'platform'
                ],
                enumName: 'vBRPlatformRepresentation'
            }
        ]
    },
    '/company/getavailablevbobackuprepositories': {
        response: [
            {
                path: [
                    'storageType'
                ],
                enumName: 'companyVboRepositoryStorageType'
            },
            {
                path: [
                    'retentionType'
                ],
                enumName: 'companyVboRepositoryRetentionType'
            }
        ]
    },
    '/company/getavailablebandwithlimit': {
        response: [
            {
                path: [
                    'throttlingUnit'
                ],
                enumName: 'nullableThrottlingUnitRepresentation'
            }
        ]
    },
    '/company/gettenantrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/company/getpoliciesbycompany': {
        request: [
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            }
        ]
    },
    '/company/getvcdorganizationusers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/company/cancreatecompany': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/caneditcompany': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/cansendemail': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/getasymmetricalgorithmlogins': {
        request: [
            {
                path: [
                    'filter',
                    'owner',
                    '$i'
                ],
                enumName: 'companyOwnerFilter'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/company/enablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/disablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/resetcompanytokenid': {
        request: [
            {
                path: [
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/company/previewwelcomeemail': {
        request: [
            {
                path: [
                    'gateways',
                    '$i',
                    'selectionType'
                ],
                enumName: 'cloudGatewaySelectionTypeRepresentation'
            },
            {
                path: [
                    'title'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'tenantType'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'organizationType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/company/getwelcomeemailcustomsection': {
        response: [
            {
                path: [
                    'organizationType'
                ],
                enumName: 'notificationOrganizationTypeRepresentation'
            },
            {
                path: [
                    'organizationScope'
                ],
                enumName: 'notificationOrganizationScopeRepresentation'
            },
            {
                path: [
                    'emailType'
                ],
                enumName: 'notificationEmailTypeRepresentation'
            }
        ]
    },
    '/configuration/getabout': {
        response: [
            {
                path: [
                    'updatesInfo',
                    'status'
                ],
                enumName: 'sUpdateStatus'
            }
        ]
    },
    '/configuration/checkforupdates': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'sUpdateStatus'
            }
        ]
    },
    '/configuration/getaccesslogs': {
        request: [
            {
                path: [
                    'filter',
                    'activityKind',
                    '$i'
                ],
                enumName: 'activityKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'auditLogType'
                ],
                enumName: 'auditLogTypeRepresentation'
            },
            {
                path: [
                    'activityKind'
                ],
                enumName: 'activityKind'
            }
        ]
    },
    '/configuration/getservertimesyncinfo': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'serverTimeSyncStatus'
            }
        ]
    },
    '/csv/getcompaniescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'statusFilter',
                    '$i'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'tenantType',
                    '$i'
                ],
                enumName: 'cloudTenantTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'managerType',
                    '$i'
                ],
                enumName: 'managerTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getresellerscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'eOrganizationMfaPolicyStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'excludeTypes',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKind',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'virtualPlatformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getfilejobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getbackuppoliciescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'backupPolicyTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'creatorTypes',
                    '$i'
                ],
                enumName: 'backupPolicyCreatorTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'accessModes',
                    '$i'
                ],
                enumName: 'backupPolicyAccessModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getfilejobsourcescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getobjectstoragebackupjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getobjectstoragebackupjobsourcescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getactivealarmscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'alarmStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'scopes',
                    '$i'
                ],
                enumName: 'alarmScope'
            },
            {
                path: [
                    'data',
                    'filter',
                    'products',
                    '$i'
                ],
                enumName: 'alarmProduct'
            },
            {
                path: [
                    'data',
                    'filter',
                    'areas',
                    '$i'
                ],
                enumName: 'alarmArea'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getbackupserverscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'serverType',
                    '$i'
                ],
                enumName: 'serverTypeInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvboserverscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvbolicensescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvoneserverscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvonelicensescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvawagentscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentInstalled',
                    '$i'
                ],
                enumName: 'vacAgentInstallationStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawVersionFilter',
                    '$i'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getaccesslogscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'activityKind',
                    '$i'
                ],
                enumName: 'activityKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getsubscriptionplanscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getalarmscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'alarmObjectCategory',
                    '$i'
                ],
                enumName: 'alarmObjectCategoryRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getdiscoveredcomputerscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'agentStatus',
                    '$i'
                ],
                enumName: 'eConnectionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawSupportStatus',
                    '$i'
                ],
                enumName: 'eSupportOsStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'eVacAgentStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'machineType',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getbackupagentscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'guiModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'guestOsFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'cloudCopyFilter',
                    '$i'
                ],
                enumName: 'backupAgentCloudCopy'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobStatus',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getbackupagentsjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKindFilter',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawBackupPolicyFilter',
                    '$i'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getfailoverplanscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'failoverPlanTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getagenttaskscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'task'
                ],
                enumName: 'eTaskTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'agentTaskStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvawlicensecsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'vawOperationModeFilter',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'vawPlatformFilter',
                    '$i'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseStatusFilter',
                    '$i'
                ],
                enumName: 'licenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'connectionStatusFilter',
                    '$i'
                ],
                enumName: 'backupAgentConnectionStatusRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getbackuplicensecsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcloudconnectlicensecsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'state',
                    '$i'
                ],
                enumName: 'commonLicenseStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'licenseTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'format',
                    '$i'
                ],
                enumName: 'backupServerLicenseFormatRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvbragentjobcontainerscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'modes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvbragentjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvbragentcopyjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'osPlatforms',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'targetKinds',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'licenseTypes',
                    '$i'
                ],
                enumName: 'backupServerMbaLicenseRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvirtualmachinescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'targetTypes',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobTypes',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platformTypes',
                    '$i'
                ],
                enumName: 'protectedVmVirtualPlatformType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'cloudCopyStatus',
                    '$i'
                ],
                enumName: 'protectedVmCloudCopyStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'similarJobTypes',
                    '$i'
                ],
                enumName: 'protectedVmSimilarJobTypes'
            },
            {
                path: [
                    'data',
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getprotectedvmrestorepointscsv': {
        request: [
            {
                path: [
                    'protectionType'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getprotectedvmbackuprestorepointscsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcomputerscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'restoreStatus',
                    '$i'
                ],
                enumName: 'eRestoreStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcomputersmanagedbyvbrcsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'malwareStates',
                    '$i'
                ],
                enumName: 'malwareState'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcomputersprotectingjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcomputersrestorepointscsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcomputersprotectingcopyjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'operationMode',
                    '$i'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupTargetType',
                    '$i'
                ],
                enumName: 'jobTargetKindInteg'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobKind',
                    '$i'
                ],
                enumName: 'vbrBackupAgentJobKind'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getfilesharescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getobjectstoragescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'jobTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudefsjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudrdsjobscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getjobheatmapdetailscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'heatmapJobType'
            },
            {
                path: [
                    'data',
                    'filter',
                    'workloadType',
                    '$i'
                ],
                enumName: 'workloadTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'platformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudvmcsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'protectedVirtualMachineRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudvmbackupcsv': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudvmrestorepointcsv': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'publicCloudVmRestoreType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudefscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudefsrestorepointscsv': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eEfsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudrdscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'backupType',
                    '$i'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getpubliccloudrdsrestorepointscsv': {
        request: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'backupType'
                ],
                enumName: 'eRdsBackupType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getprocessedvmscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getrestorepointscsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getvbappliancescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'configuredCompany',
                    '$i'
                ],
                enumName: 'eVbApplianceConfiguredCompany'
            },
            {
                path: [
                    'data',
                    'filter',
                    'isSelectSelfServiceEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceSelfServiceEnabled'
            },
            {
                path: [
                    'data',
                    'filter',
                    'certificateStatus',
                    '$i'
                ],
                enumName: 'eVbApplianceCertificateStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'isSelectRemoteUiAccessEnabled',
                    '$i'
                ],
                enumName: 'eVbApplianceRemoteUiAccessEnabled'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getplatformaccountscsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getguestosaccountscsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getrepositoriescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/getcloudbackupappliancescsv': {
        request: [
            {
                path: [
                    'data',
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'eVbPluginApplianceStatus'
            },
            {
                path: [
                    'data',
                    'filter',
                    'platform',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'data',
                    'filter',
                    'managementType',
                    '$i'
                ],
                enumName: 'eApplianceManagementType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/vboserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vboApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/voneserver/getsystem': {
        request: [
            {
                path: [
                    'filter',
                    'applicationStatus',
                    '$i'
                ],
                enumName: 'vOneApplicationStatus'
            },
            {
                path: [
                    'filter',
                    'vacAgentStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'vacVersion',
                    '$i'
                ],
                enumName: 'eVacVersionStatus'
            },
            {
                path: [
                    'filter',
                    'agentCommonStatus',
                    '$i'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'filter',
                    'alarmsSynchronizationStatus',
                    '$i'
                ],
                enumName: 'externalAlarmSynchronizationStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/cdpprocessedvmsCsv': {
        request: [
            {
                path: [
                    'data',
                    'status',
                    '$i'
                ],
                enumName: 'jobSessionTaskStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/exporthostedbackupserverscsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/exporthostedvcdorganizationmappingcsv': {
        request: [
            {
                path: [
                    'data',
                    'status',
                    '$i'
                ],
                enumName: 'mappingStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/csv/exporthostedvirtualinfrastructuremappingcsv': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/dashboard/getcloudstoredworkloads': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'cloudWorkloadType'
            }
        ]
    },
    '/dashboard/getcloudconnectrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'eInfrastructureItemState'
            }
        ]
    },
    '/dashboard/getclientrepositories': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'eInfrastructureItemState'
            }
        ]
    },
    '/dashboard/getcloudconnectinfrastructure': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'eInfrastructureItemType'
            }
        ]
    },
    '/dashboard/getbackupinfrastructure': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'eInfrastructureItemType'
            }
        ]
    },
    '/dashboard/getjobsheatmapoverviewstatistics': {
        request: [
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'heatmapJobType'
            },
            {
                path: [
                    'filter',
                    'workloadType',
                    '$i'
                ],
                enumName: 'workloadTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'platformTypeRepresentation'
            }
        ]
    },
    '/dashboard/getjobsheatmapdailystatistics': {
        request: [
            {
                path: [
                    'filter',
                    'jobType',
                    '$i'
                ],
                enumName: 'heatmapJobType'
            },
            {
                path: [
                    'filter',
                    'workloadType',
                    '$i'
                ],
                enumName: 'workloadTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'platformType',
                    '$i'
                ],
                enumName: 'platformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'status',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'result'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'jobType'
                ],
                enumName: 'heatmapJobType'
            },
            {
                path: [
                    'workloadType'
                ],
                enumName: 'workloadTypeRepresentation'
            },
            {
                path: [
                    'platformType'
                ],
                enumName: 'platformTypeRepresentation'
            }
        ]
    },
    '/location/getlocations': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/location/getlocationdata': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/location/removelocation': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/log/downloadlogsgetprogress': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'downloaderState'
            }
        ]
    },
    '/overview/updates': {
        response: [
            {
                path: [
                    'productType'
                ],
                enumName: 'licenseProductType'
            }
        ]
    },
    '/overview/activealarms': {
        response: [
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/agentstatealarms': {
        response: [
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/backupinfrastructurealarms': {
        response: [
            {
                path: [
                    'itemType'
                ],
                enumName: 'backupInfrastructureObjectType'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/cloudinfrastructurealarms': {
        response: [
            {
                path: [
                    'itemType'
                ],
                enumName: 'cloudInfrastructureObjectType'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/microsoft365infrastructurealarms': {
        response: [
            {
                path: [
                    'itemType'
                ],
                enumName: 'microsoft365InfrastructureObjectType'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/backupagentinfrastructurealarms': {
        response: [
            {
                path: [
                    'itemType'
                ],
                enumName: 'backupAgentInfrastructureObjectType'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/voneinfrastructurealarms': {
        response: [
            {
                path: [
                    'itemType'
                ],
                enumName: 'vOneInfrastructureObjectType'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/companies': {
        response: [
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/overview/companyhealth': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'companyHealthStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'companyHealthStatus'
            },
            {
                path: [
                    'organizationType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/overview/aggregatedcompanyhealth': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'companyHealthStatus'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'companyHealthStatus'
            },
            {
                path: [
                    'errors',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'warnings',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'healthy',
                    'trend'
                ],
                enumName: 'overviewTrend'
            },
            {
                path: [
                    'unknown',
                    'trend'
                ],
                enumName: 'overviewTrend'
            }
        ]
    },
    '/setting/getemailnotificationsettings': {
        response: [
            {
                path: [
                    'dailyAlarmsStatusFilter',
                    '$i'
                ],
                enumName: 'alarmDailySummaryStatusFilter'
            },
            {
                path: [
                    'dailyAlarmsSorting'
                ],
                enumName: 'alarmDailySummarySorting'
            },
            {
                path: [
                    'notificationLevel'
                ],
                enumName: 'notificationLevelRepresentation'
            }
        ]
    },
    '/setting/getdiscoverynotificationsettings': {
        response: [
            {
                path: [
                    'notificationLevel'
                ],
                enumName: 'notificationLevelRepresentation'
            }
        ]
    },
    '/setting/setemailnotificationsettings': {
        request: [
            {
                path: [
                    'dailyAlarmsStatusFilter',
                    '$i'
                ],
                enumName: 'alarmDailySummaryStatusFilter'
            },
            {
                path: [
                    'dailyAlarmsSorting'
                ],
                enumName: 'alarmDailySummarySorting'
            },
            {
                path: [
                    'notificationLevel'
                ],
                enumName: 'notificationLevelRepresentation'
            }
        ]
    },
    '/setting/getretentionstatus': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'retentionStateRepresentation'
            }
        ]
    },
    '/setting/getretentionsettings': {
        response: [
            {
                path: [
                    'scheduleType'
                ],
                enumName: 'eScheduleTypeRepresentation'
            }
        ]
    },
    '/setting/setretentionsettings': {
        request: [
            {
                path: [
                    'scheduleType'
                ],
                enumName: 'eScheduleTypeRepresentation'
            }
        ]
    },
    '/setting/getdatabasesettingsportal': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/setdatabasesettingsportal': {
        request: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/checkdatabasesettingsportal': {
        request: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ],
        response: [
            {
                path: [
                    'databaseState'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/checkdatabasesettings': {
        request: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ],
        response: [
            {
                path: [
                    'databaseState'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/getdatabasesettings': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/setdatabasesettings': {
        request: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/setting/getmaintenancemode': {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'eDatabaseState'
            }
        ]
    },
    '/subscriptionplan/getplans': {
        request: [
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'vatAlias'
                ],
                enumName: 'vatAliasRepresentation'
            }
        ]
    },
    '/subscriptionplan/getplansforassignto': {
        request: [
            {
                path: [
                    'filter',
                    'type',
                    '$i'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'vatAlias'
                ],
                enumName: 'vatAliasRepresentation'
            }
        ]
    },
    '/subscriptionplan/getplan': {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'vatAlias'
                ],
                enumName: 'vatAliasRepresentation'
            }
        ]
    },
    '/subscriptionplan/getsubscriptionplanassigments': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/subscriptionplan/savesubscriptionplan': {
        request: [
            {
                path: [
                    'type'
                ],
                enumName: 'subscriptionPlanCreatorType'
            },
            {
                path: [
                    'vatAlias'
                ],
                enumName: 'vatAliasRepresentation'
            }
        ]
    },
    '/subscriptionplan/caneditsubscriptionplan': {
        response: [
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/subscriptionplan/removeplan': {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyquotausage': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbystoredvms': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbytransferreddata': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbydownloadtraffic': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyreplicatedvmstothecloud': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbymanagedbackupagents': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbytraffic': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyfilebackups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyvbm365protectedusers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyvbm365protectedgroups': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbyprotectedcloudworkloads': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/topcompanies/gettopcompaniesbymonitoredworkloads': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ]
    },
    '/user/getusers': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    '/user/getuserdata': {
        response: [
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'userTitle'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    '/user/removeuser': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/user/setuserstatus': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/user/getuserslocations': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    '/user/saveuserlocation': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/user/createuser': {
        request: [
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'userTitle'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    '/user/updateuser': {
        request: [
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'userTitle'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    '/user/generatetotpsecret': {
        response: [
            {
                path: [
                    'errors',
                    '$i',
                    'type'
                ],
                enumName: 'errorType'
            }
        ]
    },
    '/user/addtotplogin': {
        request: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            }
        ],
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/user/addapikeylogin': {
        request: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            }
        ],
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/user/addasymmetricalgorithmlogin': {
        request: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            }
        ],
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/user/deleteuserloginbytotp': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/user/getlogins': {
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/user/getlogin': {
        response: [
            {
                path: [
                    'scopes',
                    '$i'
                ],
                enumName: 'userLoginScope'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'userLoginStatus'
            }
        ]
    },
    '/user/createresetpasswordrequest': {
        response: [
            {
                path: [
                    'errors',
                    '$i',
                    'type'
                ],
                enumName: 'errorType'
            }
        ]
    },
    '/user/completeresetpasswordrequest': {
        response: [
            {
                path: [
                    'errors',
                    '$i',
                    'type'
                ],
                enumName: 'errorType'
            }
        ]
    },
    '/user/enablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/user/disablemfapolicystatus': {
        request: [
            {
                path: [
                    'filter',
                    'types',
                    '$i'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'filter',
                    'mfaPolicyStatus',
                    '$i'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ],
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    '/support/getsupportcasewizardmanagementagents': {
        request: [
            {
                path: [
                    'filter',
                    'osTypes',
                    '$i'
                ],
                enumName: 'supportCaseWizardManagementAgentOsType'
            },
            {
                path: [
                    'filter',
                    'companyManagerTypes',
                    '$i'
                ],
                enumName: 'companyTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'supportCaseWizardManagementAgentOsType'
            },
            {
                path: [
                    'companyManagerType'
                ],
                enumName: 'companyTypeRepresentation'
            }
        ]
    },
    '/support/getsupportcasewizardcloudconnectservers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'agentStatus'
                ],
                enumName: 'eVacAgentStatusInteg'
            }
        ]
    },
    '/support/getsupportcasewizardbackupservers': {
        request: [
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'backupServerStatus'
                ],
                enumName: 'backupServerStatusRepresentation'
            },
            {
                path: [
                    'agentCommonStatus'
                ],
                enumName: 'commonAgentStatusInteg'
            }
        ]
    },
    '/support/getsupportcasewizardbackupservervirtualinfrastructurejobs': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platformTypes',
                    '$i'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platformType'
                ],
                enumName: 'virtualPlatformTypeRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            }
        ]
    },
    '/support/getsupportcasewizardbackupservermanagedagentjobs': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'jobModes',
                    '$i'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'jobMode'
                ],
                enumName: 'jobMode'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            }
        ]
    },
    '/support/getsupportcasewizardbackupserverpubliccloudjobs': {
        request: [
            {
                path: [
                    'filter',
                    'statuses',
                    '$i'
                ],
                enumName: 'jobStateRepresentation'
            },
            {
                path: [
                    'filter',
                    'platforms',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'filter',
                    'objectTypes',
                    '$i'
                ],
                enumName: 'supportCaseWizardBackupServerPublicCloudJobObjectType'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'objectType'
                ],
                enumName: 'supportCaseWizardBackupServerPublicCloudJobObjectType'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'jobStateRepresentation'
            }
        ]
    },
    '/support/getsupportcasewizardpubliccloudappliances': {
        request: [
            {
                path: [
                    'filter',
                    'platforms',
                    '$i'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'sort',
                    '$i',
                    'nullComparationMode'
                ],
                enumName: 'nullComparationMode'
            }
        ],
        response: [
            {
                path: [
                    'platform'
                ],
                enumName: 'publicCloudPlatformTypeRepresentation'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eVbPluginApplianceStatus'
            }
        ]
    },
    '/login/islogged': {
        response: [
            {
                path: [
                    'user',
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'user',
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'user',
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'user',
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'user',
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            },
            {
                path: [
                    'license',
                    'licenseEdition'
                ],
                enumName: 'licenseEditionRepresentation'
            },
            {
                path: [
                    'license',
                    'licenseType'
                ],
                enumName: 'licenseTypeRepresentation'
            }
        ]
    },
    BackupAgentJobContainerModel: {
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'guiMode'
                ],
                enumName: 'backupAgentGuiModeRepresentation'
            },
            {
                path: [
                    'vawPlatform'
                ],
                enumName: 'backupAgentPlatformRepresentation'
            },
            {
                path: [
                    'backupMode',
                    '$i'
                ],
                enumName: 'backupAgentBackupModeRepresentation'
            },
            {
                path: [
                    'operationMode'
                ],
                enumName: 'backupAgentOperationModeRepresentation'
            },
            {
                path: [
                    'jobsStatus'
                ],
                enumName: 'eJobStatusInteg'
            },
            {
                path: [
                    'commonVawTemplateStatus'
                ],
                enumName: 'backupAgentPolicyRepresentation'
            },
            {
                path: [
                    'vawVersionStatus'
                ],
                enumName: 'backupAgentVersionStatusRepresentation'
            },
            {
                path: [
                    'vawUpgradeStatus'
                ],
                enumName: 'eProductUpgradeStatus'
            }
        ]
    },
    IsLoggedUserJson: {
        response: [
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    UserData: {
        response: [
            {
                path: [
                    'userRole'
                ],
                enumName: 'userRoleRepresentation'
            },
            {
                path: [
                    'userStatus'
                ],
                enumName: 'userStatusRepresentation'
            },
            {
                path: [
                    'userTitle'
                ],
                enumName: 'userTitleRepresentation'
            },
            {
                path: [
                    'companyStatus'
                ],
                enumName: 'companyStatusRepresentation'
            },
            {
                path: [
                    'creationSource'
                ],
                enumName: 'userCreationSourceRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'userMfaPolicyAggregateStatusRepresentation'
            }
        ]
    },
    MultiActionResult: {
        response: [
            {
                path: [
                    'results',
                    '$i',
                    'status'
                ],
                enumName: 'eActionResultStatus'
            },
            {
                path: [
                    'status'
                ],
                enumName: 'eActionResultStatus'
            }
        ]
    },
    WinPrincipalModel: {
        response: [
            {
                path: [
                    'contextType'
                ],
                enumName: 'contextType'
            },
            {
                path: [
                    'roleType'
                ],
                enumName: 'ePrincipalRoleTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'accessType'
                ],
                enumName: 'ePrincipalAccessTypeRepresentation'
            },
            {
                path: [
                    'principalObject',
                    '$i',
                    'type'
                ],
                enumName: 'ePrincipalObjectTypeRepresentation'
            },
            {
                path: [
                    'type'
                ],
                enumName: 'ePrincipalTypeRepresentation'
            },
            {
                path: [
                    'mfaPolicyStatus'
                ],
                enumName: 'ePrincipalMfaPolicyStatusRepresentation'
            }
        ]
    },
    DiscoveryRuleDataJson: {
        response: [
            {
                path: [
                    'type'
                ],
                enumName: 'networkDiscoveryRuleTypeRepresentation'
            },
            {
                path: [
                    'adMethod'
                ],
                enumName: 'adMethodRepresentation'
            },
            {
                path: [
                    'notification',
                    'period'
                ],
                enumName: 'networkRuleNotificationPeriodRepresentation'
            },
            {
                path: [
                    'notification',
                    'dayOfWeek'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'filters',
                    'os',
                    'items',
                    '$i'
                ],
                enumName: 'eOsTypeInteg'
            },
            {
                path: [
                    'filters',
                    'platform',
                    'items',
                    '$i'
                ],
                enumName: 'eMachineTypeRepresentation'
            },
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'winNTAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'credentials',
                    'linuxAccount',
                    '$i',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            }
        ]
    },
    DiscoveryRuleGridDataModel: {
        response: [
            {
                path: [
                    'state'
                ],
                enumName: 'discoveryRuleStateRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'type'
                ],
                enumName: 'scheduleTypeRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'kind'
                ],
                enumName: 'scheduleDailyKindRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'daily',
                    'days',
                    '$i'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'week'
                ],
                enumName: 'weekInMonthRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'scheduleSettings',
                    'monthly',
                    'months',
                    '$i'
                ],
                enumName: 'monthsRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'periodically',
                    'timeUnit'
                ],
                enumName: 'scheduleFrequencyTimeUnitRepresentation'
            },
            {
                path: [
                    'scheduleSettings',
                    'scheduleWindow',
                    'items',
                    '$i',
                    'day'
                ],
                enumName: 'dayOfWeek'
            },
            {
                path: [
                    'osType'
                ],
                enumName: 'networkDiscoveryRuleOsTypeRepresentation'
            }
        ]
    },
    DeploymentConfigurationRepresentation: {
        response: [
            {
                path: [
                    'systemType'
                ],
                enumName: 'systemTypeRepresentation'
            },
            {
                path: [
                    'linuxAccount',
                    'credentialsType'
                ],
                enumName: 'networkDiscoveryRuleCredentialsTypeRepresentation'
            },
            {
                path: [
                    'backupAgentSettings',
                    'limitBandwidthSpeedUnit'
                ],
                enumName: 'backupAgentBandwidthSpeedUnitRepresentation'
            }
        ]
    }
}