/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SPACING_M } from '@veeam-vspc/shared/components';

export const WidgetViewStyled = styled.div`
    display: grid;
    gap: ${SPACING_M};
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: hidden;
    width: 100%;
`;
