/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { HwPlansService } from '../services';

import type { ComboboxItem } from 'core/interfaces';

export class HwPlansStore {
    private lang: any;
    private transportService: TransportService<RequestErrorResponse | Error>;

    @observable hwpId = '';
    @observable hwPlanList: ComboboxItem[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    fetchData(): void {
        HwPlansService
            .fetchData(this.transportService, this.lang)
            .then((list) => {
                this.setHwPlanList(list);
            })
            .catch(() => {});
    }

    @action.bound
    setHwpId(planId: string): void {
        this.hwpId = planId;
    }

    @action.bound
    setHwPlanList(list: ComboboxItem[]): void {
        this.hwPlanList = list;
    }

    @action
    setLang(lang: any) {
        this.lang = lang;
    }

    @action
    setTransportService(transportService: TransportService<RequestErrorResponse | Error>) {
        this.transportService = transportService;
    }

    @action
    setInitial(transportService: TransportService<RequestErrorResponse | Error>, lang: any) {
        this.setTransportService(transportService);
        this.setLang(lang);
    }
}

export const hwPlansStore = new HwPlansStore();
