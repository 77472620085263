/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef } from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors, useGlobalLang } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { PieChartModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { BillingWidget } from '../../../../BillingWidget';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { getPieOptions } from 'views/components/HighchartsWrapper/helpers';
import { transformPieData } from 'views/components/ChartWidget/helpers';
import { useIntervalRequest } from 'views/hooks';

import type { PieData } from 'views/components/HighchartsWrapper/interfaces';

export const StorageUsage = observer(() => {
    const abortController = useRef(new AbortController());
    const didMount = useRef(false);
    const lang = useGlobalLang<VspcLang>();

    const request = useIntervalRequest<{}, PieChartModel>(
        '/Dashboard/GetCloudSobrUsage',
        {},
        {
            requestInit: {
                signal: abortController.current.signal,
            },
        },
        5 * TimeUnitsInMs.Minute,
    );

    const { empty, series } = request.data
        ? transformPieData(request.data as PieChartModel)
        : {} as PieData;

    const params = {
        colors: [colors.B400, colors.Grn200, colors.B250, colors.T500, '#834799'],
        sizeFormat: { digits: 1 },
    };

    const options = getPieOptions({
        categories: [],
        height: 306,
        lang,
        series,
        size: 196,
        ...params,
    });


    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();
            abortController.current = new AbortController();

            return request.forceRequest();
        }

        didMount.current = true;
    }, []);

    return (
        <BillingWidget title={{ text: capitalize(lang.STORAGE_USAGE) }}>
            <HighchartsWrapper
                empty={empty}
                emptyDesc={lang.PLEASE_START_USING_VCC_BACKUP_JOBS}
                loading={request.loading}
                options={options}
            />
        </BillingWidget>
    );
});
