/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getTagValueAttr, getXmlTagByQuery } from './xml-helpers';
import { XmlFileTagAttributesValues, XmlFileTagQueries } from '../enums';

export const checkInstallLicense = (xml: XMLDocument): boolean => {
    const licenseTag = getXmlTagByQuery(xml, XmlFileTagQueries.License);

    if (!licenseTag) {
        return false;
    }

    const licenceValue = getTagValueAttr(licenseTag);

    return licenceValue ? (licenceValue !== XmlFileTagAttributesValues.Decline) : false;
};

export const checkUpgradeLicense = (xml: XMLDocument): boolean => {
    const licenseTag = getXmlTagByQuery(xml, XmlFileTagQueries.License);

    if (!licenseTag) {
        return true;
    }

    const licenceValue = getTagValueAttr(licenseTag);

    return licenceValue !== XmlFileTagAttributesValues.Decline;
};
