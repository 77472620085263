/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationAlarmsSettingsDailySorting, NotificationSettingsLevel } from '@veeam-vspc/models/rest';

import type { NotificationSettings } from '@veeam-vspc/models/rest';

import type { ConfigurationFormData } from '../interfaces';

export const getInitialConfigurationFormData = (data: NotificationSettings): ConfigurationFormData => {
    const defaultTime = '09:00';

    return {
        alarms: {
            from: data.alarms?.from || '',
            to: data.alarms?.to || '',
            dailyStatusFilter: data.alarms?.dailyStatusFilter || [],
            dailySorting: data.alarms?.dailySorting || NotificationAlarmsSettingsDailySorting.ByTime,
            dailySubject: data.alarms?.dailySubject || '',
            dailyTime: data.alarms?.dailyTime || defaultTime,
        },
        billing: {
            from: data.billing?.from || '',
            subject: data.billing?.subject || '',
        },
        defaultFrom: data.defaultFrom || '',
        discovery: {
            from: data.discovery?.from || '',
            to: data.discovery?.to || '',
            subject: data.discovery?.subject || '',
            dailyTime: data.discovery?.dailyTime || defaultTime,
        },
        level: data.level || NotificationSettingsLevel.Disabled,
    };
};
