/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { ManagedAgentMode } from '@veeam-vspc/models/web-controllers';

export class ManagedBackupAgentsStore {

    @observable managedBackupAgentType: ManagedAgentMode;

    constructor(type: ManagedAgentMode, readonly lsStateId: string) {
        makeObservable(this);
        this.managedBackupAgentType = type;
    }

    @action.bound
    setManagedBackupAgentType(v: ManagedAgentMode): void {
        this.managedBackupAgentType = v;
    }
}
