/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

export class ProductStore {

    @observable type: LicenseProductType;

    constructor(type: LicenseProductType, readonly localStorageStateId: string) {
        makeObservable(this);
        this.type = type;
    }

    @action.bound
    setType(type: LicenseProductType): void {
        this.type = type;
    }
}
