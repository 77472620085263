/**
 * Copyright © Veeam Software Group GmbH.
 */

import { colors, NestedTabBarLayout, TabBarLayout } from '@veeam-vspc/shared/components';

import type { AppRouteItem } from '@veeam-vspc/shared/stores';

import { OverviewPage } from 'views/pages/OverviewPage';
import overviewIcon from 'images/icons/left-menu/overview.svg';
import { RouteGroups, RouteIds } from '../../enums';
import { ActiveAlarmsPage } from 'views/pages/ActiveAlarmsPage';
import activeAlarmsIcon from 'images/icons/left-menu/active-alarms.svg';
import { ProtectedDataSummaryPage } from 'views/pages/ProtectedDataSummaryPage';
import protectedDataSummaryIcon from 'images/icons/left-menu/client-summary.svg';
import billingSummaryIcon from 'images/icons/left-menu/summary.svg';
import { JobsSummaryPage } from 'views/pages/JobsSummaryPage';
import heatMapIcon from 'images/icons/left-menu/heatmap.svg';
import { TopsBackupInfrastructurePage, TopsCloudConnectPage, TopsCompaniesPage } from 'views/pages/TopsPage';
import topsIcon from 'images/icons/left-menu/tops.svg';
import { TrendsBackupServersPage, TrendsCloudConnectPage, TrendsInfrastructurePage } from 'views/pages/TrendsPage';
import trendsIcon from 'images/icons/left-menu/trends.svg';
import {
    BackupJobsCloudDatabasesPage,
    BackupJobsFileSharesLocalPage,
    BackupJobsFileSharesPublicCloudsPage,
    BackupJobsDataBackupObjectStoragePage,
    BackupJobsManagedByBackupServerPage,
    BackupJobsManagedByConsolePage,
    BackupJobsPublicCloudsPage,
    BackupJobsVBObjectsPage,
    BackupJobsVirtualInfrastructurePage,
} from 'views/pages/BackupJobsPage';
import backupJobsIcon from 'images/icons/left-menu/backup-jobs.svg';
import { FailoverPlansPage } from 'views/pages/FailoverPlansPage';
import failoverPlanIcon from 'images/icons/left-menu/failover-plans.svg';
import {
    ProtectedDataCloudDatabasesPage,
    ProtectedDataFileSharesLocalPage,
    ProtectedDataFileSharesPublicCloudsPage,
    ProtectedDataDataBackupObjectStoragePage,
    ProtectedDataManagedByBackupServerPage,
    ProtectedDataManagedByConsolePage,
    ProtectedDataPublicCloudsPage,
    ProtectedDataVBObjectsPage,
    ProtectedDataVirtualInfrastructurePage,
} from 'views/pages/ProtectedDataPage';
import protectedDataIcon from 'images/icons/left-menu/protected-vms.svg';
import { CompaniesPage } from 'views/pages/CompaniesPage';
import companiesIcon from 'images/icons/left-menu/companies.svg';
import { ResellersPage } from 'views/pages/ResellersPage';
import resellersIcon from 'images/icons/left-menu/resellers.svg';
import {
    DiscoveryBackupAgentsPage,
    DiscoveryBackupServersPage,
    DiscoveryCloudAppliancesPage,
    DiscoveryComputersPage,
    DiscoveryOneServersPage,
    DiscoveryRulesPage,
    DiscoveryVb365ServersPage, ManagedComputersRulesPage,
} from 'views/pages/DiscoveryPage';
import discoveryRulesIcon from 'images/icons/left-menu/discovery-rules.svg';
import discoveryIcon from 'images/icons/left-menu/discovered-computers.svg';
import managedComputersIcon from 'images/icons/left-menu/managed-computers.svg';
import { AllInvoicesPage, CompanyAllInvoicesPage, InvoiceConfigurationPage } from 'views/pages/InovicesPage';
import invoicesIcon from 'images/icons/left-menu/current-invoices.svg';
import { ReportsAllReportsPage, ReportsConfigurationPage } from 'views/pages/ReportPage';
import reportsIcon from 'images/icons/left-menu/reports.svg';
import { AppTabBarLayoutGreyBg, AppTabBarLayoutVisibleSingleTab } from 'views/components/layouts/AppTabBarLayout';
import {
    ResourcesAndBillingCloudConnectBackupPage,
    ResourcesAndBillingCloudConnectReplicaPage,
    ResourcesAndBillingInvoicesPage,
} from 'views/pages/ResourcesAndBillingPage';

import type { VspcLang } from '../../languages';

export const OverviewRoute: AppRouteItem = {
    path: 'overview',
    exact: true,
    component: OverviewPage,
    navIconSrc: overviewIcon,
    navNameKey: 'OVERVIEW' as VspcLang['OVERVIEW'],
    navGroupId: RouteGroups.Monitoring,
    navGroupTitleKey: 'MONITORING' as VspcLang['MONITORING'],
    triangleColor: colors.G200,
};

export const ActiveAlarmsPageRoute: AppRouteItem = {
    path: 'activeAlarms',
    exact: true,
    component: ActiveAlarmsPage,
    navIconSrc: activeAlarmsIcon,
    navNameKey: 'ACTIVE_ALARMS' as VspcLang['ACTIVE_ALARMS'],
    navGroupId: RouteGroups.Monitoring,
    navGroupTitleKey: 'MONITORING' as VspcLang['MONITORING'],
};

export const ProtectedDataSummaryPageRoute: AppRouteItem = {
    path: 'protectedDataSummary',
    exact: true,
    component: ProtectedDataSummaryPage,
    navIconSrc: protectedDataSummaryIcon,
    navNameKey: 'RPO_AND_SLA' as VspcLang['RPO_AND_SLA'],
    navGroupId: RouteGroups.Monitoring,
    navGroupTitleKey: 'MONITORING' as VspcLang['MONITORING'],
    triangleColor: colors.G200,
};

const ResourcesAndBillingInvoicesPageRoute: AppRouteItem = {
    path: 'invoices',
    exact: true,
    component: ResourcesAndBillingInvoicesPage,
    navNameKey: 'INVOICES' as VspcLang['INVOICES'],
    isRouteHidden(routeHiddenContext) {
        return [
            !routeHiddenContext.portalPreferences?.isViewVisible('billing'),
            routeHiddenContext.portalUser?.isCompanyOwner(),
            routeHiddenContext.portalUser?.isCompanyAdministrator(),
        ].includes(true);
    },
};

const ResourcesAndBillingCloudConnectBackupPageRoute: AppRouteItem = {
    path: 'cloudConnectBackup',
    exact: true,
    component: ResourcesAndBillingCloudConnectBackupPage,
    navNameKey: 'BACKUP' as VspcLang['BACKUP'],
};

const ResourcesAndBillingCloudConnectReplicaPageRoute: AppRouteItem = {
    path: 'cloudConnectReplica',
    exact: true,
    component: ResourcesAndBillingCloudConnectReplicaPage,
    navNameKey: 'REPLICATION' as VspcLang['REPLICATION'],
    isRouteHidden(routeHiddenContext) {
        return (
            routeHiddenContext.portalUser?.isSubtenant()
        );
    },
};

const ResourcesAndBillingCloudConnectResourcesPageRoute: AppRouteItem = {
    path: 'cloudConnectResources',
    component: NestedTabBarLayout,
    navNameKey: 'CLOUD_CONNECT_RESOURCES' as VspcLang['CLOUD_CONNECT_RESOURCES'],
    items: [
        ResourcesAndBillingCloudConnectBackupPageRoute,
        ResourcesAndBillingCloudConnectReplicaPageRoute,
    ],
};

export const ResourcesAndBillingPageRoute: AppRouteItem = {
    path: 'billingSummary',
    component: AppTabBarLayoutVisibleSingleTab,
    navIconSrc: billingSummaryIcon,
    navNameKey: 'RESOURCES_AND_BILLING' as VspcLang['RESOURCES_AND_BILLING'],
    navGroupId: RouteGroups.Monitoring,
    navGroupTitleKey: 'MONITORING' as VspcLang['MONITORING'],
    items: [
        ResourcesAndBillingInvoicesPageRoute,
        ResourcesAndBillingCloudConnectResourcesPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isAuditor()) {
            return false;
        }

        if (routeHiddenContext.portalUser?.isServiceProviderUser()) {
            return true;
        }

        return !routeHiddenContext.portalPreferences?.isViewVisible('billing') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('cloudBackupResources') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('cloudReplicationResources');
    },
};

export const JobsSummaryPageRoute: AppRouteItem = {
    path: 'jobsSummary',
    exact: true,
    component: JobsSummaryPage,
    navIconSrc: heatMapIcon,
    navNameKey: 'SESSION_STATES' as VspcLang['SESSION_STATES'],
    navGroupId: RouteGroups.Analytics,
    navGroupTitleKey: 'ANALYTICS' as VspcLang['ANALYTICS'],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isCompanyOwner() || routeHiddenContext.portalUser?.isCompanyAdministrator()) {
            return !routeHiddenContext.portalPreferences?.isViewVisible('heatmapForCompany');
        }

        return false;
    },
};

const TopsCompaniesPageRoute: AppRouteItem = {
    path: 'companies',
    exact: true,
    component: TopsCompaniesPage,
    navNameKey: 'COMPANIES' as VspcLang['COMPANIES'],
};

const TopsBackupInfrastructurePageRoute: AppRouteItem = {
    path: 'backupInfrastructure',
    exact: true,
    component: TopsBackupInfrastructurePage,
    navNameKey: 'BACKUP_INFRASTRUCTURE' as VspcLang['BACKUP_INFRASTRUCTURE'],
};

const TopsCloudConnectPageRoute: AppRouteItem = {
    path: 'cloudConnect',
    exact: true,
    component: TopsCloudConnectPage,
    navNameKey: 'CLOUD_CONNECT' as VspcLang['CLOUD_CONNECT'],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isReadOnlyRole() || routeHiddenContext.portalUser?.isPortalOperator()) {
            return true;
        }
    },
};

const TopsInfrastructurePageRoute: AppRouteItem = {
    path: 'topsInfrastructure',
    component: NestedTabBarLayout,
    navNameKey: 'INFRASTRUCTURE' as VspcLang['INFRASTRUCTURE'],
    items: [
        TopsBackupInfrastructurePageRoute,
        TopsCloudConnectPageRoute,
    ],
};

export const TopsPageRoute: AppRouteItem = {
    path: 'tops',
    component: TabBarLayout,
    navIconSrc: topsIcon,
    navNameKey: 'TOPS' as VspcLang['TOPS'],
    navGroupId: RouteGroups.Analytics,
    navGroupTitleKey: 'ANALYTICS' as VspcLang['ANALYTICS'],
    items: [
        TopsCompaniesPageRoute,
        TopsInfrastructurePageRoute,
    ],
};

const TrendsInfrastructurePageRoute: AppRouteItem = {
    path: 'infrastructure',
    exact: true,
    component: TrendsInfrastructurePage,
    navNameKey: 'INFRASTRUCTURE' as VspcLang['INFRASTRUCTURE'],
};

const TrendsBackupServersPageRoute: AppRouteItem = {
    path: 'backupServices',
    exact: true,
    component: TrendsBackupServersPage,
    navNameKey: 'BACKUP_SERVICES' as VspcLang['BACKUP_SERVICES'],
};

const TrendsCloudConnectPageRoute: AppRouteItem = {
    path: 'cloudConnect',
    exact: true,
    component: TrendsCloudConnectPage,
    navNameKey: 'CLOUD_CONNECT' as VspcLang['CLOUD_CONNECT'],
};

export const TrendsPageRoute: AppRouteItem = {
    path: 'trends',
    component: AppTabBarLayoutGreyBg,
    navIconSrc: trendsIcon,
    navNameKey: 'TRENDS' as VspcLang['TRENDS'],
    navGroupId: RouteGroups.Analytics,
    navGroupTitleKey: 'ANALYTICS' as VspcLang['ANALYTICS'],
    triangleColor: colors.G200,
    items: [
        TrendsInfrastructurePageRoute,
        TrendsBackupServersPageRoute,
        TrendsCloudConnectPageRoute,
    ],
};

const BackupJobsManagedByConsolePageRoute: AppRouteItem = {
    path: 'managedByConsole',
    exact: true,
    component: BackupJobsManagedByConsolePage,
    navNameKey: 'MANAGED_BY_CONSOLE' as VspcLang['MANAGED_BY_CONSOLE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console');
    },
};

const BackupJobsManagedByBackupServerPageRoute: AppRouteItem = {
    path: 'managedByBackupServer',
    exact: true,
    component: BackupJobsManagedByBackupServerPage,
    navNameKey: 'MANAGED_BY_BACKUP_SERVER' as VspcLang['MANAGED_BY_BACKUP_SERVER'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr');
    },
};

const BackupJobsComputersPageRoute: AppRouteItem = {
    path: 'computers',
    component: NestedTabBarLayout,
    navNameKey: 'COMPUTERS' as VspcLang['COMPUTERS'],
    items: [
        BackupJobsManagedByConsolePageRoute,
        BackupJobsManagedByBackupServerPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console') &&
           !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr');
    },
};

const BackupJobsVirtualInfrastructurePageRoute: AppRouteItem = {
    path: 'virtualInfrastructure',
    exact: true,
    component: BackupJobsVirtualInfrastructurePage,
    navNameKey: 'VIRTUAL_INFRASTRUCTURE' as VspcLang['VIRTUAL_INFRASTRUCTURE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure');
    },
};

const BackupJobsPublicCloudsPageRoute: AppRouteItem = {
    path: 'publicCloud',
    exact: true,
    component: BackupJobsPublicCloudsPage,
    navNameKey: 'PUBLIC_CLOUDS' as VspcLang['PUBLIC_CLOUDS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const BackupJobsVirtualMachinesPageRoute: AppRouteItem = {
    path: 'virtualMachines',
    component: NestedTabBarLayout,
    navNameKey: 'VIRTUAL_MACHINES' as VspcLang['VIRTUAL_MACHINES'],
    items: [
        BackupJobsVirtualInfrastructurePageRoute,
        BackupJobsPublicCloudsPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure');
    },
};

const BackupJobsFileSharesLocalPageRoute: AppRouteItem = {
    path: 'local',
    exact: true,
    component: BackupJobsFileSharesLocalPage,
    navNameKey: 'VIRTUAL_INFRASTRUCTURE' as VspcLang['VIRTUAL_INFRASTRUCTURE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares');
    },
};

const BackupJobsFileSharesPublicCloudsPageRoute: AppRouteItem = {
    path: 'publicCloud',
    exact: true,
    component: BackupJobsFileSharesPublicCloudsPage,
    navNameKey: 'PUBLIC_CLOUDS' as VspcLang['PUBLIC_CLOUDS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const BackupJobsDataBackupObjectStoragePageRoute: AppRouteItem = {
    path: 'objectStorage',
    exact: true,
    component: BackupJobsDataBackupObjectStoragePage,
    navNameKey: 'OBJECT_STORAGE' as VspcLang['OBJECT_STORAGE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

const BackupJobsFileSharesPageRoute: AppRouteItem = {
    path: 'fileShares',
    component: NestedTabBarLayout,
    navNameKey: 'DATA_BACKUP' as VspcLang['DATA_BACKUP'],
    items: [
        BackupJobsFileSharesLocalPageRoute,
        BackupJobsFileSharesPublicCloudsPageRoute,
        BackupJobsDataBackupObjectStoragePageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

const BackupJobsCloudDatabasesPageRoute: AppRouteItem = {
    path: 'cloudDatabases',
    exact: true,
    component: BackupJobsCloudDatabasesPage,
    navNameKey: 'CLOUD_DATABASES' as VspcLang['CLOUD_DATABASES'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const BackupJobsVBObjectsPageRoute: AppRouteItem = {
    path: 'vbObjects',
    exact: true,
    component: BackupJobsVBObjectsPage,
    navNameKey: 'MICROSOFT_365_OBJECTS' as VspcLang['MICROSOFT_365_OBJECTS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo');
    },
};

export const BackupJobsPageRoute: AppRouteItem = {
    path: 'backupJobs',
    component: TabBarLayout,
    navIconSrc: backupJobsIcon,
    navNameKey: 'BACKUP_JOBS' as VspcLang['BACKUP_JOBS'],
    navGroupId: RouteGroups.Management,
    navGroupTitleKey: 'MANAGEMENT' as VspcLang['MANAGEMENT'],
    items: [
        BackupJobsComputersPageRoute,
        BackupJobsVirtualMachinesPageRoute,
        BackupJobsFileSharesPageRoute,
        BackupJobsCloudDatabasesPageRoute,
        BackupJobsVBObjectsPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isSubtenant()) {
            return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console');
        }
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'cloudBackupAppliances') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

export const FailoverPlansPageRoute: AppRouteItem = {
    path: 'failoverPlans',
    component: FailoverPlansPage,
    navIconSrc: failoverPlanIcon,
    navNameKey: 'FAILOVER_PLANS' as VspcLang['FAILOVER_PLANS'],
    navGroupId: RouteGroups.Management,
    navGroupTitleKey: 'MANAGEMENT' as VspcLang['MANAGEMENT'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'failoverPlans');
    },
};

const ProtectedDataManagedByConsolePageRoute: AppRouteItem = {
    path: 'managedByConsole',
    exact: true,
    component: ProtectedDataManagedByConsolePage,
    navNameKey: 'MANAGED_BY_CONSOLE' as VspcLang['MANAGED_BY_CONSOLE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console');
    },
};

const ProtectedDataManagedByBackupServerPageRoute: AppRouteItem = {
    path: 'managedByBackupServer',
    exact: true,
    component: ProtectedDataManagedByBackupServerPage,
    navNameKey: 'MANAGED_BY_BACKUP_SERVER' as VspcLang['MANAGED_BY_BACKUP_SERVER'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr');
    },
};

const ProtectedDataComputersPageRoute: AppRouteItem = {
    path: 'computers',
    component: NestedTabBarLayout,
    navNameKey: 'COMPUTERS' as VspcLang['COMPUTERS'],
    items: [
        ProtectedDataManagedByConsolePageRoute,
        ProtectedDataManagedByBackupServerPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console') &&
           !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr');
    },
};

const ProtectedDataVirtualInfrastructurePageRoute: AppRouteItem = {
    path: 'virtualInfrastructure',
    exact: true,
    component: ProtectedDataVirtualInfrastructurePage,
    navNameKey: 'VIRTUAL_INFRASTRUCTURE' as VspcLang['VIRTUAL_INFRASTRUCTURE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure');
    },
};

const ProtectedDataPublicCloudsPageRoute: AppRouteItem = {
    path: 'publicCloud',
    exact: true,
    component: ProtectedDataPublicCloudsPage,
    navNameKey: 'PUBLIC_CLOUDS' as VspcLang['PUBLIC_CLOUDS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const ProtectedDataVirtualMachinesPageRoute: AppRouteItem = {
    path: 'virtualMachines',
    component: NestedTabBarLayout,
    navNameKey: 'VIRTUAL_MACHINES' as VspcLang['VIRTUAL_MACHINES'],
    items: [
        ProtectedDataVirtualInfrastructurePageRoute,
        ProtectedDataPublicCloudsPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure');
    },
};

const ProtectedDataFileSharesLocalPageRoute: AppRouteItem = {
    path: 'local',
    exact: true,
    component: ProtectedDataFileSharesLocalPage,
    navNameKey: 'VIRTUAL_INFRASTRUCTURE' as VspcLang['VIRTUAL_INFRASTRUCTURE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares');
    },
};

const ProtectedDataFileSharesPublicCloudsPageRoute: AppRouteItem = {
    path: 'publicCloud',
    exact: true,
    component: ProtectedDataFileSharesPublicCloudsPage,
    navNameKey: 'PUBLIC_CLOUDS' as VspcLang['PUBLIC_CLOUDS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const ProtectedDataDataBackupObjectStoragePageRoute: AppRouteItem = {
    path: 'objectStorage',
    exact: true,
    component: ProtectedDataDataBackupObjectStoragePage,
    navNameKey: 'OBJECT_STORAGE' as VspcLang['OBJECT_STORAGE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

const ProtectedDataFileSharesPageRoute: AppRouteItem = {
    path: 'fileShares',
    component: NestedTabBarLayout,
    navNameKey: 'DATA_BACKUP' as VspcLang['DATA_BACKUP'],
    items: [
        ProtectedDataFileSharesLocalPageRoute,
        ProtectedDataFileSharesPublicCloudsPageRoute,
        ProtectedDataDataBackupObjectStoragePageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

const ProtectedDataCloudDatabasesPageRoute: AppRouteItem = {
    path: 'cloudDatabases',
    exact: true,
    component: ProtectedDataCloudDatabasesPage,
    navNameKey: 'CLOUD_DATABASES' as VspcLang['CLOUD_DATABASES'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud');
    },
};

const ProtectedDataVBObjectsPageRoute: AppRouteItem = {
    path: 'vbObjects',
    exact: true,
    component: ProtectedDataVBObjectsPage,
    navNameKey: 'MICROSOFT_365_OBJECTS' as VspcLang['MICROSOFT_365_OBJECTS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo');
    },
};

export const ProtectedDataPageRoute: AppRouteItem = {
    path: 'protectedData',
    component: TabBarLayout,
    navIconSrc: protectedDataIcon,
    navNameKey: 'PROTECTED_DATA' as VspcLang['PROTECTED_DATA'],
    navGroupId: RouteGroups.Management,
    navGroupTitleKey: 'MANAGEMENT' as VspcLang['MANAGEMENT'],
    items: [
        ProtectedDataComputersPageRoute,
        ProtectedDataVirtualMachinesPageRoute,
        ProtectedDataFileSharesPageRoute,
        ProtectedDataCloudDatabasesPageRoute,
        ProtectedDataVBObjectsPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'cloudBackupAppliances') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'fileShares') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'objectStorages');
    },
};

export const CompaniesPageRoute: AppRouteItem = {
    path: 'companies',
    exact: true,
    component: CompaniesPage,
    navIconSrc: companiesIcon,
    navNameKey: 'COMPANIES' as VspcLang['COMPANIES'],
    navGroupId: RouteGroups.Clients,
    navGroupTitleKey: 'CLIENTS' as VspcLang['CLIENTS'],
};

export const ResellersPageRoute: AppRouteItem = {
    path: 'resellers',
    exact: true,
    component: ResellersPage,
    navIconSrc: resellersIcon,
    navNameKey: 'RESELLERS' as VspcLang['RESELLERS'],
    navGroupId: RouteGroups.Clients,
    navGroupTitleKey: 'CLIENTS' as VspcLang['CLIENTS'],
};

export const DiscoveryRulesPageRoute: AppRouteItem = {
    path: 'rules',
    exact: true,
    component: ManagedComputersRulesPage,
    navIconSrc: discoveryRulesIcon,
    navNameKey: 'RULES' as VspcLang['RULES'],
    navGroupId: RouteGroups.Discovery,
    navGroupTitleKey: 'DISCOVERY' as VspcLang['DISCOVERY'],
};

const DiscoveryDiscoveredComputersComputersPageRoute: AppRouteItem = {
    path: 'computers',
    exact: true,
    component: DiscoveryComputersPage,
    navNameKey: 'COMPUTERS' as VspcLang['COMPUTERS'],
};

const DiscoveryDiscoveredComputersRulesPageRoute: AppRouteItem = {
    path: 'rules',
    exact: true,
    component: DiscoveryRulesPage,
    navNameKey: 'RULES' as VspcLang['RULES'],
    isRouteHidden(routeHiddenContext) {
        if (
            routeHiddenContext.portalUser?.isCompanyAdministrator()
            || routeHiddenContext.portalUser?.isLocationAdministrator()
            || routeHiddenContext.portalUser?.isCompanyOwner()
            || routeHiddenContext.portalUser?.isServiceProviderOperator()
        ) {
            return true;
        }

        return false;
    },
};

const DiscoveryDiscoveredComputersPageRoute: AppRouteItem = {
    path: 'discoveredComputers',
    component: NestedTabBarLayout,
    navNameKey: 'DISCOVERED_COMPUTERS' as VspcLang['DISCOVERED_COMPUTERS'],
    items: [
        DiscoveryDiscoveredComputersComputersPageRoute,
        DiscoveryDiscoveredComputersRulesPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        if (
            routeHiddenContext.portalUser?.isLocationUser()
            || routeHiddenContext.portalUser?.isSubtenant()
            || routeHiddenContext.portalUser?.isServiceProviderUser()
        ) {
            return true;
        }

        return false;
    },
};

const DiscoveryBackupAgentsPageRoute: AppRouteItem = {
    path: 'backupAgents',
    exact: true,
    component: DiscoveryBackupAgentsPage,
    navNameKey: 'BACKUP_AGENTS' as VspcLang['BACKUP_AGENTS'],
};

const DiscoveryBackupServersPageRoute: AppRouteItem = {
    path: 'backupServers',
    exact: true,
    component: DiscoveryBackupServersPage,
    navNameKey: 'BACKUP_SERVERS' as VspcLang['BACKUP_SERVERS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbr');
    },
};

const DiscoveryOneServersPageRoute: AppRouteItem = {
    path: 'oneServers',
    exact: true,
    component: DiscoveryOneServersPage,
    navNameKey: 'ONE_SERVERS' as VspcLang['ONE_SERVERS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vOne');
    },
};

const DiscoveryVb365ServersPageRoute: AppRouteItem = {
    path: 'vb365servers',
    exact: true,
    component: DiscoveryVb365ServersPage,
    navNameKey: 'MICROSOFT_365_BACKUP_SERVERS' as VspcLang['MICROSOFT_365_BACKUP_SERVERS'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo');
    },
};

const DiscoveryCloudAppliancesPageRoute: AppRouteItem = {
    path: 'cloudAppliances',
    exact: true,
    component: DiscoveryCloudAppliancesPage,
    navNameKey: 'CLOUD_APPLIANCES' as VspcLang['CLOUD_APPLIANCES'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'cloudBackupAppliances');
    },
};

export const ManagedComputersPage: AppRouteItem = {
    path: 'discovery',
    component: AppTabBarLayoutVisibleSingleTab,
    navIconSrc: managedComputersIcon,
    navNameKey: 'MANAGED_COMPUTERS' as VspcLang['MANAGED_COMPUTERS'],
    navGroupId: RouteGroups.Discovery,
    navGroupTitleKey: 'DISCOVERY' as VspcLang['DISCOVERY'],
    items: [
        DiscoveryDiscoveredComputersPageRoute,
        DiscoveryBackupAgentsPageRoute,
        DiscoveryBackupServersPageRoute,
        DiscoveryOneServersPageRoute,
        DiscoveryVb365ServersPageRoute,
        DiscoveryCloudAppliancesPageRoute,
    ],
};

export const DiscoveryPageRoute: AppRouteItem = {
    path: 'discovery',
    component: TabBarLayout,
    navIconSrc: discoveryIcon,
    navNameKey: 'DISCOVERY' as VspcLang['DISCOVERY'],
    navGroupId: RouteGroups.Clients,
    navGroupTitleKey: 'CLIENTS' as VspcLang['CLIENTS'],
    items: [
        DiscoveryDiscoveredComputersPageRoute,
        DiscoveryBackupAgentsPageRoute,
        DiscoveryBackupServersPageRoute,
        DiscoveryOneServersPageRoute,
        DiscoveryVb365ServersPageRoute,
        DiscoveryCloudAppliancesPageRoute,
    ],
};

const InvoicesAllInvoicesPageRoute: AppRouteItem = {
    path: 'allInvoices',
    exact: true,
    component: AllInvoicesPage,
    navNameKey: 'ALL_INVOICES' as VspcLang['ALL_INVOICES'],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isAuditor()) {
            return false;
        }

        if (routeHiddenContext.portalUser?.isServiceProviderUser()) {
            return true;
        }

        return !routeHiddenContext.portalPreferences?.isViewVisible('billing');
    },
};

const InvoicesInvoiceConfigurationPageRoute: AppRouteItem = {
    path: 'configurations',
    exact: true,
    component: InvoiceConfigurationPage,
    navNameKey: 'CONFIGURATIONS' as VspcLang['CONFIGURATIONS'],
    isRouteHidden(routeHiddenContext) {
        return routeHiddenContext.portalUser?.isCompanyOwner();
    },
};

export const InvoicesPageRoute: AppRouteItem = {
    path: 'invoices',
    component: TabBarLayout,
    navIconSrc: invoicesIcon,
    navNameKey: 'INVOICES' as VspcLang['INVOICES'],
    navGroupId: RouteGroups.Reporting,
    navGroupTitleKey: 'REPORTING' as VspcLang['REPORTING'],
    items: [
        InvoicesAllInvoicesPageRoute,
        InvoicesInvoiceConfigurationPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isAuditor()) {
            return false;
        }

        if (routeHiddenContext.portalUser?.isServiceProviderUser()) {
            return true;
        }

        return !routeHiddenContext.portalPreferences?.isViewVisible('billing');
    },
};

export const CompanyAllInvoicesPageRoute: AppRouteItem = {
    path: 'invoices',
    component: CompanyAllInvoicesPage,
    navIconSrc: invoicesIcon,
    navNameKey: 'INVOICES' as VspcLang['INVOICES'],
    navGroupId: RouteGroups.Reporting,
    navGroupTitleKey: 'REPORTING' as VspcLang['REPORTING'],
};

const ReportsAllReportsPageRoute: AppRouteItem = {
    path: 'allReports',
    exact: true,
    component: ReportsAllReportsPage,
    navNameKey: 'ALL_REPORTS' as VspcLang['ALL_REPORTS'],
};

const ReportsConfigurationPageRoute: AppRouteItem = {
    path: 'configurations',
    exact: true,
    component: ReportsConfigurationPage,
    navNameKey: 'CONFIGURATIONS' as VspcLang['CONFIGURATIONS'],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isLocationUser() || routeHiddenContext.portalUser?.isServiceProviderUser()) {
            return true;
        }

        return false;
    },
};

export const ReportsPageRoute: AppRouteItem = {
    path: 'reports',
    component: TabBarLayout,
    navIconSrc: reportsIcon,
    navNameKey: 'REPORTS' as VspcLang['REPORTS'],
    navGroupId: RouteGroups.Reporting,
    navGroupTitleKey: 'REPORTING' as VspcLang['REPORTING'],
    items: [
        ReportsAllReportsPageRoute,
        ReportsConfigurationPageRoute,
    ],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'console') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'agents', 'vbr') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'publicCloud') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'virtualInfrastructure') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbo') &&
            !routeHiddenContext.portalPreferences?.isViewVisible('infrastructure', 'vbr');
    },
};

export const allHomeRoutes: { [key in RouteIds]?: AppRouteItem; } = {
    // Home

    // Monitoring
    [RouteIds.Overview]: OverviewRoute,
    [RouteIds.ActiveAlarms]: ActiveAlarmsPageRoute,
    [RouteIds.ProtectedDataSummary]: ProtectedDataSummaryPageRoute,
    [RouteIds.BillingSummary]: ResourcesAndBillingPageRoute,
    // Analytics
    [RouteIds.JobsSummary]: JobsSummaryPageRoute,
    [RouteIds.Tops]: TopsPageRoute,
    [RouteIds.Trends]: TrendsPageRoute,
    // Management
    [RouteIds.BackupJobs]: BackupJobsPageRoute,
    [RouteIds.FailoverPlans]: FailoverPlansPageRoute,
    [RouteIds.ProtectedData]: ProtectedDataPageRoute,
    // Clients
    [RouteIds.Companies]: CompaniesPageRoute,
    [RouteIds.Resellers]: ResellersPageRoute,
    [RouteIds.Discovery]: DiscoveryPageRoute,
    // Clients
    [RouteIds.ManagedComputers]: ManagedComputersPage,
    [RouteIds.Rules]: DiscoveryRulesPageRoute,
    // Reporting
    [RouteIds.Invoices]: InvoicesPageRoute,
    [RouteIds.CompanyInvoices]: CompanyAllInvoicesPageRoute,
    [RouteIds.Reports]: ReportsPageRoute,
};
