/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    FormLayout,
    useGlobalLang,
    Text,
    StackView,
    TEXT_WEIGHT,
    NoLabelGroup,
    DataList,
    colors,
    STACK_DIRECTION,
    FieldLayout,
    CONTROL_SIZE,
    Button,
    STACK_GAP,
    useModal,
    SELECTION_MODE,
    RadiobuttonKit,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { BackupServerBackupJobGuestFSIndexingMode } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

import { SpecifyFolderModal } from '../SpecifyFolderModal/SpecifyFolderModal';

import type { SettingsWindowProps } from '../interfaces/settings-window-props';

export const WindowsSettings: React.FC<SettingsWindowProps> = observer(({
    active,
    data,
    onChange,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();

    const defaults = [
        {
            label: '%windir%',
            value: '%windir%',
        },
        {
            label: '%ProgramFiles%',
            value: '%ProgramFiles%',
        },
        {
            label: '%ProgramFiles(x86)%',
            value: '%ProgramFiles(x86)%',
        },
        {
            label: '%ProgramW6432%',
            value: '%ProgramW6432%',
        },
        {
            label: '%Temp%',
            value: '%Temp%',
        },
    ];

    const [selected, setSelected] = useState<string>();

    const [indexAllValues, setIndexAllValues] = useState<Record<string, string>[]>(
        () => {
            if (
                data.windowsIndexing?.indexingList?.length &&
                data.windowsIndexing?.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept
            ) {
                return data.windowsIndexing.indexingList.map(x => ({ label: x, value: x }));
            }
            return defaults;
        });

    const [indexOnlyValues, setIndexOnlyValues] = useState<Record<string, string>[]>(() => {
        if (
            data.windowsIndexing?.indexingList?.length &&
            data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly
        ) {
            return data.windowsIndexing.indexingList.map(x => ({ label: x, value: x }));
        }
        return [];
    });

    const [folderModal, folderModalActions] = useModal({
        render: ({ deactivate }) => (
            <SpecifyFolderModal
                onConfirm={(newData) => {
                    if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept) {
                        if (indexAllValues.find(x => x.value === newData)) {
                            notificationService.warning(lang.INDEXING_SETTINGS, lang.SPECIFIED_FOLDER_ALREADY_EXISTS);
                            return;
                        }

                        setIndexAllValues([...indexAllValues, { label: newData, value: newData }]);
                    }
                    if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly) {
                        if (indexOnlyValues.find(x => x.value === newData)) {
                            notificationService.warning(lang.INDEXING_SETTINGS, lang.SPECIFIED_FOLDER_ALREADY_EXISTS);
                            return;
                        }

                        setIndexOnlyValues([...indexOnlyValues, { label: newData, value: newData }]);
                    }

                    deactivate();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const handleRemove = (value: string) => {
        if (!value) {
            return;
        }
        if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept) {
            setIndexAllValues(indexAllValues.filter(x => x.value !== value));
        }
        if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly) {
            setIndexOnlyValues(indexOnlyValues.filter(x => x.value !== value));
        }
    };

    const handleChange = value => onChange({ ...data, windowsIndexing: { ...data.windowsIndexing, guestFSIndexingMode: value } });

    useEffect(() => {
        let indexingList = [];

        if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept) {
            indexingList = indexAllValues.map(({ value }) => value);
        }

        if (data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly) {
            indexingList = indexOnlyValues.map(({ value }) => value);
        }

        onChange({
            ...data,
            windowsIndexing: {
                ...data.windowsIndexing,
                indexingList,
            },
        });
    }, [indexAllValues, indexOnlyValues, data.windowsIndexing.guestFSIndexingMode]);

    if (!active) {
        return null;
    }

    return (
        <FormLayout>
            <Text weight={TEXT_WEIGHT.bold}>
                {`${lang.GUEST_FILE_SYSTEM_INDEXING_MODE}:`}
            </Text>

            <NoLabelGroup
                content={(
                    <RadiobuttonKit
                        value={BackupServerBackupJobGuestFSIndexingMode.Disable}
                        onChange={handleChange}
                        checked={data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.Disable}
                    >
                        {lang.DISABLE_INDEXING}
                    </RadiobuttonKit>
                )}
            />

            <NoLabelGroup
                content={(
                    <RadiobuttonKit
                        value={BackupServerBackupJobGuestFSIndexingMode.IndexAll}
                        onChange={handleChange}
                        checked={data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAll}
                    >
                        {lang.INDEX_EVERYTHING}
                    </RadiobuttonKit>
                )}
            />

            <NoLabelGroup
                content={(
                    <RadiobuttonKit
                        value={BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                        onChange={handleChange}
                        checked={data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                    >
                        {`${lang.INDEX_EVERYTHING_EXCEPT}:`}
                    </RadiobuttonKit>
                )}
                subContent={(
                    <FormLayout
                        direction={STACK_DIRECTION.column}
                    >
                        <StackView
                            direction={STACK_DIRECTION.row}
                            gap={STACK_GAP.l}
                        >
                            <FieldLayout
                                size={CONTROL_SIZE.l}
                                style={{
                                    height: '120px',
                                    border: `1px solid ${
                                        data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept
                                            ? colors.G500
                                            : colors.B500
                                    }`,
                                }}
                            >
                                <DataList
                                    selectionMode={SELECTION_MODE.single}
                                    data={
                                        data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept
                                            ? indexAllValues
                                            : []
                                    }
                                    valueGetter={item => item ? item.value : null}
                                    textGetter={item => item ? item.label : null}
                                    selected={[selected]}
                                    onChange={value => setSelected(value[0])}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                                    heightMode={120}
                                />
                            </FieldLayout>

                            <StackView
                                direction={STACK_DIRECTION.column}
                                gap={STACK_GAP.s}
                            >
                                <Button
                                    onClick={folderModalActions.activate}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                                >
                                    {`${lang.ADD}...`}
                                </Button>

                                <Button
                                    onClick={() => handleRemove(selected)}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                                >
                                    {lang.REMOVE}
                                </Button>

                                <Button
                                    onClick={() => setIndexAllValues([...defaults])}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexAllExcept}
                                >
                                    {lang.DEFAULT}
                                </Button>
                            </StackView>
                        </StackView>
                    </FormLayout>
                )}
            />

            <NoLabelGroup
                content={(
                    <RadiobuttonKit
                        value={BackupServerBackupJobGuestFSIndexingMode.IndexOnly}
                        checked={data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly}
                        onChange={handleChange}
                    >
                        {`${lang.INDEX_ONLY_FOLLOWING_FOLDERS}:`}
                    </RadiobuttonKit>
                )}
                subContent={(
                    <FormLayout
                        direction={STACK_DIRECTION.column}
                    >
                        <StackView
                            direction={STACK_DIRECTION.row}
                            gap={STACK_GAP.l}
                        >
                            <FieldLayout
                                size={CONTROL_SIZE.l}
                                style={{
                                    height: '120px',
                                    border: `1px solid ${
                                        data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexOnly
                                            ? colors.G500
                                            : colors.B500
                                    }`,
                                }}
                            >
                                <DataList
                                    selectionMode={SELECTION_MODE.single}
                                    data={
                                        data.windowsIndexing.guestFSIndexingMode === BackupServerBackupJobGuestFSIndexingMode.IndexOnly
                                            ? indexOnlyValues
                                            : []
                                    }
                                    valueGetter={item => item ? item.value : null}
                                    textGetter={item => item ? item.label : null}
                                    selected={[selected]}
                                    onChange={value => setSelected(value[0])}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexOnly}
                                    heightMode={120}
                                />
                            </FieldLayout>

                            <StackView
                                direction={STACK_DIRECTION.column}
                                gap={STACK_GAP.s}
                            >
                                <Button
                                    onClick={folderModalActions.activate}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexOnly}
                                >
                                    {`${lang.ADD}...`}
                                </Button>

                                <Button
                                    onClick={() => handleRemove(selected)}
                                    disabled={data.windowsIndexing.guestFSIndexingMode !== BackupServerBackupJobGuestFSIndexingMode.IndexOnly}
                                >
                                    {lang.REMOVE}
                                </Button>
                            </StackView>
                        </StackView>
                    </FormLayout>
                )}
            />

            {folderModal}
        </FormLayout>
    );
});
