/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalServices, useModal } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { BackupServerObjectStorageBackupJobModel, BackupServerObjectStorageBackupJobParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';

import { DataBackupObjectStorageActionToolbar, DataBackupObjectStorageFilterToolbar } from './components';
import { ConfigSectionIds } from 'core/enums';
import { useColumns } from './hooks';
import { AssignCompanyDialog } from 'views/components/AssignCompanyDialog/AssignCompanyDialog';

const GRID_DATA_METHOD = 'GetObjectStorageBackupJobs';
const GRID_DATA_URL = `/BackupServerJob/${GRID_DATA_METHOD}`;

export const DataBackupObjectStorage: React.FC<{}> = () => {
    const { pathname } = useLocation();
    const { transportService } = useGlobalServices();
    const gridStateId = `${pathname}.ObjectStorage`;
    const columns = useColumns(gridStateId);

    const gridApi = useRef<GridStore<BackupServerObjectStorageBackupJobModel, unknown, BackupServerObjectStorageBackupJobParam>>();

    const [assignCompanyDialog, assignCompanyDialogActions] = useModal({
        render: ({ deactivate }) => (
            <AssignCompanyDialog
                method='/backupserverjob/assignobjectstoragejobtocompany'
                gridApi={gridApi.current}
                deactivate={deactivate}
            />
        ),
    });

    return (
        <>
            <ConfiguredGrid
                api={gridApi}
                stateId={gridStateId}
                sectionId={ConfigSectionIds.BackupJobsDataBackupObjectStorage}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        key: 'status',
                        direction: GridSortDirections.Asc,
                    },
                }}
                columns={columns}
                data={(requestParams: RequestParams<BackupServerObjectStorageBackupJobParam>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<BackupServerObjectStorageBackupJobModel[]>>(
                        `${GRID_DATA_URL}`,
                        { ...requestParams }
                    )}
                isRowInactive={rowData => !rowData.scheduleEnabled}
                toggleable={true}
                selection={{
                    field: 'instanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <DataBackupObjectStorageFilterToolbar />,
                    () => (
                        <DataBackupObjectStorageActionToolbar
                            idProperty={'instanceUid'}
                            method={GRID_DATA_METHOD}
                            selectedOnly
                            assignCompanyDialogActions={assignCompanyDialogActions}
                        />
                    ),
                ]}
            />

            {assignCompanyDialog}
        </>
    );
};
