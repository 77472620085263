/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    FormLayout,
    HelpText,
    INDENT,
    Label,
    LinkButton,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    TextInput,
    useGlobalLang,
    useModal,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { FormFieldNames } from '../../enums';
import { TimeField } from 'components/controls';
import { useConfigurationStore } from '../../stores';
import { AdvancedSettingsDialog } from './components';
import { getNotificationLevelFlags } from '../../helpers';
import { Suffix } from '../Suffix';

export const AlarmsSettingsGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formStore: { defaultInputSize, defaultTimeInputSize, formData: { level } } } = useConfigurationStore();
    const { noEmails, summaryEmails } = getNotificationLevelFlags(level);

    const [advancedSettingsDialog, advancedSettingsDialogActions] = useModal({
        render: ({ deactivate }) => <AdvancedSettingsDialog deactivateDialog={deactivate} />,
    });

    return (
        <>
            {advancedSettingsDialog}

            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <StackView direction={STACK_DIRECTION.column} indentBottom={INDENT.s}>
                    <Label>{lang.ALARMS}</Label>
                    <HelpText>{`${lang.SPECIFY_EMAIL_SETTINGS_TO_USE}:`}</HelpText>
                </StackView>

                <FormLayout inlineLabel disabled={noEmails}>
                    <TextInput
                        label={lang.FROM}
                        maxLength={512}
                        name={FormFieldNames.AlarmsFrom}
                        size={defaultInputSize}
                    />

                    <TextInput
                        label={lang.TO}
                        maxLength={512}
                        name={FormFieldNames.AlarmsTo}
                        size={defaultInputSize}
                    />

                    <TextInput
                        disabled={summaryEmails}
                        label={lang.SUBJECT}
                        maxLength={512}
                        name={FormFieldNames.AlarmsDailySubject}
                        size={defaultInputSize}
                        suffix={(<Suffix variables={'%company%, %location%, %alarmName%, %alarmStatus%, %objectname%, %hostname%.'} />)}
                    />
                </FormLayout>

                <StackView distribution={STACK_DISTRIBUTION.between}>
                    <TimeField
                        disabled={!summaryEmails}
                        inlineLabel
                        label={lang.SEND_NOTIFICATION_DAILY_AT_THIS_TIME}
                        name={FormFieldNames.AlarmsDailyTime}
                        shortFormat
                        size={defaultTimeInputSize}
                        utc
                    />

                    <LinkButton
                        disabled={!summaryEmails}
                        onClick={advancedSettingsDialogActions.activate}
                    >
                        {`${lang.ADVANCED_SETTINGS}...`}
                    </LinkButton>
                </StackView>
            </StackView>
        </>
    );
});
