/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { GoogleCloudObjectType } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedGcpWorkloadsContext } from './contexts/ProtectedGcpWorkloadsContext';
import { ProtectedGcpWorkloadsService } from './services/protected-gcp-workloads.service';
import { ProtectedGcpWorkloadsStore } from './stores/ProtectedGcpWorkloadsStore';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useProtectedGcpWorkloadsStore } from './hooks/use-protected-gcp-workloads-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const protectedGcpWorkloadsStore = useProtectedGcpWorkloadsStore();
    const protectedGcpWorkloadsService = useMemo(() =>
        new ProtectedGcpWorkloadsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_GCP_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedGcpWorkloadsStore.setGoogleCloudObjectType}
                stateId={protectedGcpWorkloadsStore.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedGcpWorkloadsStore.googleCloudObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: GoogleCloudObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: GoogleCloudObjectType.Database, text: capitalize(lang.CLOUD_SQL_DATABASES) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedGcpWorkloadsStore.googleCloudObjectType}
            params={params}
            service={protectedGcpWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedGcpWorkloads: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ProtectedGcpWorkloads.GoogleCloudObjectType`;
    const initialType = useLocalStorageValue<GoogleCloudObjectType>(stateId, GoogleCloudObjectType.Vm);

    const protectedGcpWorkloadsStore = useMemo(() => new ProtectedGcpWorkloadsStore(initialType, stateId), [initialType, stateId]);

    return (
        <ProtectedGcpWorkloadsContext.Provider value={protectedGcpWorkloadsStore}>
            <ChartLayout />
        </ProtectedGcpWorkloadsContext.Provider>
    );
};
