/**
 * Copyright © Veeam Software Group GmbH.
 */

import { DayOfWeek } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

export const getDaysOfWeek = (lang: VspcLang) => (
    [
        { id: DayOfWeek.Monday, name: lang.MONDAY },
        { id: DayOfWeek.Tuesday, name: lang.TUESDAY },
        { id: DayOfWeek.Wednesday, name: lang.WEDNESDAY },
        { id: DayOfWeek.Thursday, name: lang.THURSDAY },
        { id: DayOfWeek.Friday, name: lang.FRIDAY },
        { id: DayOfWeek.Saturday, name: lang.SATURDAY },
        { id: DayOfWeek.Sunday, name: lang.SUNDAY },
    ]
);
