/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Text } from '@veeam-vspc/shared/components';

import type { PropsWithChildren } from 'react';

import { NoteBarStyled } from './NoteBarStyled';

export const NoteBar: React.FC<PropsWithChildren> = props => (
    <NoteBarStyled>
        <Text>
            {props.children}
        </Text>
    </NoteBarStyled>
);
