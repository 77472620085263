/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import {
    SIZE_S,
    SPACE_FILL,
    StackView,
    STACK_ALIGN,
    STACK_DISTRIBUTION,
    STACK_GAP,
    Text,
    TEXT_ALIGN,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

const ProgressBarBackground = styled.div<{ progress: number; }>`
    background-color: ${({ theme }) => theme.colorProgressbarTrack};
    position: relative;
    overflow: hidden;
    height: ${SIZE_S};
    flex: 1;
    margin-top: 2px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${({ progress }) => progress}%;
        height: ${SIZE_S};
        display: block;
        background-color: ${({ theme }) => theme.colorProgressbarProgress};
    }
`;

interface ProgressBarProps {
    progress: number;
    maxProgress?: number;
    needLabel?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress = 0, maxProgress = 1, needLabel = true }) => {
    const lang = useGlobalLang<VspcLang>();
    const progressInPercents = Math.round((progress / maxProgress) * 100);

    return (
        <StackView
            spaceFill={SPACE_FILL.all}
            gap={STACK_GAP.s}
            distribution={STACK_DISTRIBUTION.center}
            align={STACK_ALIGN.center}
        >
            {needLabel && <Text>{`${lang.PROGRESS}:`}</Text>}
            <ProgressBarBackground progress={progressInPercents} />

            {
                progress === null
                    ? <Text align={TEXT_ALIGN.left} style={{ width: '34px' }}>{lang.DASH_SYMBOL}</Text>
                    : <Text align={TEXT_ALIGN.left} style={{ width: '34px' }}>{`${progressInPercents}%`}</Text>
            }
        </StackView>
    );
};
