/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { FieldLayout, Label, useGlobalLang } from '@veeam-vspc/shared/components';
import { NotificationAlarmsSettingsDailySorting } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

import { useAdvancedSettingsStore } from '../../stores';
import { SortingRadioButtons } from '../SortingRadioButtons';

export const SortingGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { dailySorting } = useAdvancedSettingsStore();
    const sortingEnum = NotificationAlarmsSettingsDailySorting;

    return (
        <>
            <FieldLayout>
                <Label>{`${lang.SELECT_THE_SORTING_OPTION}:`}</Label>
            </FieldLayout>

            <SortingRadioButtons
                items={[
                    {
                        checked: dailySorting === sortingEnum.ByTime,
                        label: lang.TIME,
                        value: sortingEnum.ByTime,
                    },
                    {
                        checked: dailySorting === sortingEnum.ByStatus,
                        label: lang.SEVERITY,
                        value: sortingEnum.ByStatus,
                    },
                ]}
            />
        </>
    );
});
