/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    ConfiguredGrid,
    RequestGridSortDirections,
    SortingMode,
    useGlobalAddons,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { BackupServerApplicationSettingsVSS, type BackupServerBackupJobApplicationSettings } from '@veeam-vspc/models/rest';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type {
    GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { ActionsToolbar } from './components/ActionToolbar/ActionsToolbar';
import { useJobWizardStore } from '../../../../stores';

interface ApplicationProcessingOptionsProps {
    hidePanel: () => void;
}

export const ApplicationProcessingOptions: React.FC<ApplicationProcessingOptionsProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const api = useRef<GridStore<BackupServerBackupJobApplicationSettings, unknown, unknown>>();
    const wizardStore = useJobWizardStore();
    const { portalEnums } = useGlobalAddons();

    const fetchData = (
        { page, limit, sort, filter }: RequestParams<BackupServerBackupJobApplicationSettings & { name: string; }>,
    ): Promise<RequestSuccessResponse<BackupServerBackupJobApplicationSettings[]>> => {
        let items = wizardStore.appAwareProcessingSettings.slice();

        if (sort && sort.length) {
            items.sort((a, b) => {
                const { direction, property } = sort[0];

                let aValue = String(a[property]);
                let bValue = String(b[property]);

                if (property === 'name') {
                    aValue = a.vmObject.name;
                    bValue = b.vmObject.name;
                }

                if (direction === RequestGridSortDirections.Asc) {
                    return aValue
                        .localeCompare(
                            bValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                if (direction === RequestGridSortDirections.Desc) {
                    return bValue
                        .localeCompare(
                            aValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                return 0;
            });
        }

        items = items
            .filter(x => x.vmObject.name
                .toLowerCase()
                .includes((filter.name || '').trim().toLowerCase()),
            );

        const chunk = items.slice((page * limit - limit), page * limit);

        return Promise.resolve({
            data: chunk.map(x => ({ ...x, objectId: x.vmObject.objectId })),
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as RequestSuccessResponse<BackupServerBackupJobApplicationSettings[]>);
    };

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.APPLICATION_AWARE_PROCESSING_OPTIONS}
            description={`${lang.SPECIFY_APPLICATION_AWARE_PROCESSING_SETTINGS_FOR_INDIVIDUAL_OBJECTS}.`}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        const data = wizardStore.data;
                        data.guestProcessing.appAwareProcessing.appSettings = Array.from(wizardStore.appAwareProcessingSettings);

                        wizardStore.updateJobData(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick() {
                        wizardStore.handleUpdateAppAwareProcessingSettings(wizardStore.data.guestProcessing?.appAwareProcessing?.appSettings ?? []);

                        hidePanel();
                    },
                },
            ]}
        >
            <ConfiguredGrid
                defaultColumnMinWidth={100}
                stateId='backupJobs.VMs.virtualInfrastructure.jobWizard.guestProcessing.ApplicationsProcessingOptions'
                columns={[
                    {
                        id: 'name',
                        title: lang.OBJECT,
                        cell: rowData => rowData.vmObject.name,
                    },
                    {
                        id: 'vss',
                        title: lang.VSS,
                        cell: rowData => portalEnums.getEnumDescription('backupServerApplicationSettingsVSS', rowData.vss),
                    },
                    {
                        id: 'transactionLogs',
                        title: capitalize(lang.TRANSACTIONS_LOGS),
                        cell: (rowData) => {
                            if (rowData.vss === BackupServerApplicationSettingsVSS.Disabled) {
                                return ColumnTexts.DashSymbol;
                            }

                            return portalEnums.getEnumDescription('BackupServerTransactionLogsSettings', rowData.transactionLogs);
                        },
                    },
                    {
                        id: 'usePersistentGuestAgent',
                        title: capitalize(lang.PERSISTENT_GUEST_AGENT),
                        cell: rowData => rowData.usePersistentGuestAgent ? lang.YES : lang.NO,
                    },
                ]}
                data={fetchData}
                toggleable={false}
                disableAutoUpdate={true}
                selection={{
                    field: 'objectId',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => <ActionsToolbar />,
                ]}
                api={api}
                sortingMode={SortingMode.Remote}
            />
        </SidePanelForm>
    );
});
