/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormsContext, Slider } from '@veeam-vspc/shared/components';
import hasIn from 'lodash/has';

import type { SliderProps } from '@veeam-vspc/shared/components';

export interface FormSliderProps extends SliderProps {
    convertValue?: boolean;
    name: string;
    convertValueForFormCb?: (sliderValue: SliderProps['value']) => any;
    convertValueForSliderCb?: (enumValue: any) => SliderProps['value'];
}

export const FormSlider: React.FC<FormSliderProps> = (props) => {
    const {
        convertValue = false,
        convertValueForFormCb,
        convertValueForSliderCb,
        name,
        ...restProps
    } = props;

    const formsState = useContext(FormsContext);

    const getValueForSlider = useMemo(() => (
        convertValue
            ? () => convertValueForSliderCb(formsState.getValue(name))
            : () => formsState.getValue(name)
    ), [convertValue, formsState, convertValueForSliderCb, name]);

    const [value, setValue] = useState(getValueForSlider());
    const [touched, setTouched] = useState(false);

    const setFormValue = useMemo(() => (
        convertValue
            ? newValue => formsState.setValue(name, convertValueForFormCb(newValue))
            : newValue => formsState.setValue(name, newValue)
    ), [convertValue, formsState, convertValueForFormCb, name]);

    useEffect(() => {
        const updateValueFunction = () => {
            if (hasIn(formsState.value, name)) {
                setValue(getValueForSlider());
            }
        };

        formsState.onChange(updateValueFunction);

        return () => formsState.unsubscribeOnChange(updateValueFunction);
    }, [formsState, name, touched, getValueForSlider]);

    return (
        <Slider
            {...restProps}
            renderTooltip={null}
            value={value}
            onBlur={() => setTouched(true)}
            onChange={(newValue) => {
                setFormValue(newValue);
                restProps.onChange && restProps.onChange(newValue);
            }}
        />
    );
};
