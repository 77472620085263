/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { NotificationSettings } from '@veeam-vspc/models/rest';

export const customizeDataForSaving = (initValue, formValue): NotificationSettings => {
    // for nullable fields like 'alarms.from', we want to make them equal to null, because server won't accept ''
    if (formValue === '') {
        return null;
    }

    // for 'dailyStatusFilter' property, because it's an Array, 'merge' function don't replace the old array with the new one, so we do it here
    if (Array.isArray(initValue)) {
        return formValue;
    }
};
