/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, makeObservable, observable } from 'mobx';
import { deepCopy, deepEqual } from '@veeam-vspc/shared/core';
import { generate, observe } from 'fast-json-patch';
import {
    BackupServerBackupJobConfiguration,
    BackupServerVmwareInventoryType,
    BackupServerBackupJobSchedule,
    BackupServerApplicationSettingsVSS,
    BackupServerTransactionLogsSettings,
    BackupServerBackupJobGuestFSIndexingMode,
} from '@veeam-vspc/models/rest';
import { CompanyVirtualInfrastructureRepresentation, BackupServerJobRepository } from '@veeam-vspc/models/web-controllers';

import type { PortalUser } from '@veeam-vspc/shared/stores';
import type { Operation } from 'fast-json-patch';
import type {
    BackupServerBackupJobVmwareObjectSize,
    VirtualServerVirtualMachine,
    BackupServerBackupJobApplicationSettings,
    BackupServerBackupJobIndexingSettings,
    VirtualServerTag,
    BackupServerCredentialsLinuxInput,
    BackupServerCredentialsLinux,
    BackupServerCredentialsStandardInput,
    BackupServerCredentialsStandard,
} from '@veeam-vspc/models/rest';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { BackupOptionsTarget } from '../enums';
import { getDefaultSchedule } from '../helpers';

export class JobWizardStore {
    transportService: TransportService<RequestErrorResponse | Error>;
    private initialData: BackupServerBackupJobConfiguration;

    companies: CompanyVirtualInfrastructureRepresentation[] = [];

    @observable data: BackupServerBackupJobConfiguration;
    @observable.shallow repositories: BackupServerJobRepository[] = [];

    @observable serverUid: string;
    @observable mappedOrganizationUid: string;

    @observable.shallow includedItems: BackupServerBackupJobVmwareObjectSize[] = [];
    @observable.shallow excludedItems: BackupServerBackupJobVmwareObjectSize[] = [];
    @observable.shallow appAwareProcessingSettings: BackupServerBackupJobApplicationSettings[] = [];
    @observable.shallow guestFSIndexingSettings: BackupServerBackupJobIndexingSettings[] = [];


    constructor(
        data: BackupServerBackupJobConfiguration,
        companies: CompanyVirtualInfrastructureRepresentation[],
        repositories: BackupServerJobRepository[],
        transportService: TransportService<RequestErrorResponse | Error>,
        isEdit: boolean,
        private portalUser: PortalUser,
    ) {
        makeObservable(this);

        this.transportService = transportService;
        this.companies = companies;
        this.repositories = repositories;

        this.mappedOrganizationUid = data.mappedOrganizationUid;

        this.initialData = deepCopy(data);

        this.updateJobData(data);

        this.includedItems = [...(data.virtualMachines?.includes ?? [])];
        this.excludedItems = [...(data.virtualMachines?.excludes?.vms ?? [])];

        this.appAwareProcessingSettings = [...(data.guestProcessing?.appAwareProcessing?.appSettings ?? [])];
        this.guestFSIndexingSettings = [...(data.guestProcessing?.guestFSIndexing?.indexingSettings ?? [])];

        if (this.selectedCompany) {
            this.handleSelectCompany(data.mappedOrganizationUid, false);
        }
    }

    @action.bound
    updateJobData(data: BackupServerBackupJobConfiguration): void {
        this.data = data;
    }

    @action.bound
    async handleSelectCompany(companyUid: CompanyVirtualInfrastructureRepresentation['companyUid'], shouldResetValues = true): Promise<void> {
        const company = this.companies.find(x => x.companyUid === companyUid);

        this.mappedOrganizationUid = company.companyUid;
        this.serverUid = company.hostedResources[0].backupServerUid;

        this.repositories = await this.getRepositories(
            company.hostedResources[0].backupServerUid,
            company.companyUid,
        );

        // reset values on company change
        if (shouldResetValues) {
            this.resetValues();
        }
    }

    @action.bound
    private resetValues() {
        this.includedItems = [];
        this.excludedItems = [];
        this.updateSelectedItems();
        this.updateExcludedItems();
        this.data.storage.backupRepositoryId = undefined;
        this.data.guestProcessing.guestCredentials = undefined;
        this.data.schedule = getDefaultSchedule();
    }

    @action.bound
    handleSelectItems(
        target: BackupOptionsTarget,
        items: unknown[],
        type: BackupServerVmwareInventoryType
    ) {
        if (type === BackupServerVmwareInventoryType.Tag) {
            items.forEach((item: VirtualServerTag) => {
                if (this[target].findIndex(x => x.inventoryObject.objectId === item.urn) !== -1) {
                    return;
                }

                this[target].push(
                    {
                        inventoryObject: {
                            hostName: this.selectedCompanyVC.hostName,
                            name: item.name,
                            objectId: item.urn,
                            type,
                        },
                    }
                );
            });
        }

        if (type === BackupServerVmwareInventoryType.VirtualMachine) {
            items.forEach((item: VirtualServerVirtualMachine) => {
                if (this[target].findIndex(x => x.inventoryObject.objectId === item.urn) !== -1) {
                    return;
                }

                this[target].push(
                    {
                        inventoryObject: {
                            hostName: this.selectedCompanyVC.hostName,
                            name: item.name,
                            objectId: item.urn,
                            type,
                        },
                    },
                );
            });
        }
    }

    @action.bound
    handleDeleteItems(target: BackupOptionsTarget, items: BackupServerBackupJobVmwareObjectSize[]) {
        const ids = items.map(x => x.inventoryObject.objectId);

        this[target] = this[target].filter(x => !ids.includes(x.inventoryObject.objectId));
    }

    @action.bound
    updateAppAwareProcessingItems() {
        this.data.guestProcessing.appAwareProcessing.appSettings = this.includedItems
            .map((item) => {
                const alreadyExist = this.data.guestProcessing.appAwareProcessing.appSettings
                    .find(x => x.vmObject.objectId === item.inventoryObject.objectId);

                if (alreadyExist) {
                    return alreadyExist;
                }

                return {
                    vmObject: {
                        hostName: this.selectedCompanyVC.hostName,
                        name: item.inventoryObject.name,
                        type: item.inventoryObject.type,
                        objectId: item.inventoryObject.objectId,
                    },
                    vss: BackupServerApplicationSettingsVSS.RequireSuccess,
                    usePersistentGuestAgent: false,
                    transactionLogs: BackupServerTransactionLogsSettings.Process,
                    sql: null,
                    oracle: null,
                    exclusions: null,
                    scripts: null,
                };
            });

        this.appAwareProcessingSettings = [...(this.data.guestProcessing?.appAwareProcessing?.appSettings ?? [])];
    }

    @action.bound
    updateGuestFSIndexingItems() {
        this.data.guestProcessing.guestFSIndexing.indexingSettings = this.includedItems
            .map((item) => {
                const alreadyExist = this.data.guestProcessing.guestFSIndexing.indexingSettings
                    .find(x => x.vmObject.objectId === item.inventoryObject.objectId);

                if (alreadyExist) {
                    return alreadyExist;
                }

                const newItem: BackupServerBackupJobIndexingSettings = {
                    vmObject: {
                        hostName: this.selectedCompanyVC.hostName,
                        name: item.inventoryObject.name,
                        type: item.inventoryObject.type,
                        objectId: item.inventoryObject.objectId,
                    },
                    windowsIndexing: {
                        guestFSIndexingMode: BackupServerBackupJobGuestFSIndexingMode.Disable,
                        indexingList: [],
                    },
                    linuxIndexing: {
                        guestFSIndexingMode: BackupServerBackupJobGuestFSIndexingMode.Disable,
                        indexingList: [],
                    },
                };

                return newItem;
            });

        this.guestFSIndexingSettings = [...(this.data.guestProcessing?.guestFSIndexing?.indexingSettings ?? [])];
    }

    isItemSelectedForScope(id: string): boolean {
        const foundInSelected = this.isItemSelectedGeneric(
            id,
            (item: BackupServerBackupJobVmwareObjectSize) => item.inventoryObject.objectId,
            this.includedItems
        );

        const foundInExcluded = this.isItemSelectedGeneric(
            id,
            (item: BackupServerBackupJobVmwareObjectSize) => item.inventoryObject.objectId,
            this.excludedItems
        );

        return foundInExcluded || foundInSelected;
    }

    isItemSelectedGeneric(
        id: string,
        idGetter: (item: unknown) => string,
        collection: unknown[]
    ): boolean {
        return collection.find(item => idGetter(item) === id) !== undefined;
    }

    @action.bound
    updateSelectedItems(): void {
        this.data.virtualMachines.includes = this.includedItems.slice();
    }

    @action.bound
    cancelSelectedItems(): void {
        this.includedItems = this.data.virtualMachines?.includes
            ? [...this.data.virtualMachines.includes]
            : [];
    }

    @action.bound
    updateExcludedItems(): void {
        this.data.virtualMachines.excludes.vms = this.excludedItems.slice();
    }

    @action.bound
    cancelExcludedItems(): void {
        this.excludedItems = this.data.virtualMachines?.excludes?.vms
            ? [...this.data.virtualMachines.excludes.vms]
            : [];
    }

    @action.bound
    handleUpdateAppAwareProcessingSettings(items: BackupServerBackupJobApplicationSettings[]) {
        this.appAwareProcessingSettings = items;
    }

    @action.bound
    handleUpdateGuestFSIndexingSettings(items: BackupServerBackupJobIndexingSettings[]) {
        this.guestFSIndexingSettings = items;
    }

    @action.bound
    updateSchedule(schedule: BackupServerBackupJobSchedule): void {
        this.data.schedule = { ...schedule };
    }

    @computed
    get selectedCompany(): CompanyVirtualInfrastructureRepresentation {
        return this.companies.find(x => x.companyUid === this.mappedOrganizationUid);
    }

    @computed
    get selectedCompanyVC() {
        return this.selectedCompany.hostedResources[0].assignedVirtualCenters[0];
    }

    @computed
    get selectedRepository(): BackupServerJobRepository {
        return this.repositories.find(x => x.instanceUid === this.data.storage.backupRepositoryId);
    }

    @computed
    get isScheduleHidden() {
        if (this.portalUser.isAdminPortal()) {
            return false;
        }

        return !this.selectedCompany.hostedResources[0].schedulingEnabled;
    }

    @computed
    get areProcessingOptionsEnabled() {
        return this.data.guestProcessing.appAwareProcessing.isEnabled || this.data.guestProcessing.guestFSIndexing.isEnabled;
    }

    getRepositories(backupServerUid: string, companyUid: string) {
        return this.transportService
            .request<any, RequestSuccessResponse<BackupServerJobRepository[]>>(
                '/backupserverjob/getbackuprepositories',
                {
                    backupServerUid,
                    companyUid,
                    jobUid: this.data.instanceUid,
                }
            )
            .then((response: RequestSuccessResponse<BackupServerJobRepository[]>) => response.data);
    }

    createJob(): Promise<BackupServerBackupJobConfiguration> {
        const localData: BackupServerBackupJobConfiguration = deepCopy(this.data);

        if (!this.areProcessingOptionsEnabled) {
            localData.guestProcessing.guestCredentials = null;
        }

        return this.transportService.post<BackupServerBackupJobConfiguration, RequestSuccessResponse<BackupServerBackupJobConfiguration>>(
            `/infrastructure/backupServers/${this.serverUid}/jobs/backupVmJobs/vSphere/sync?mappedOrganizationUid=${this.mappedOrganizationUid}`,
            localData
        ).then((resp: RequestSuccessResponse<BackupServerBackupJobConfiguration>) => resp.data);
    }

    getExtendedDiff() {
        const initialDataLocal = deepCopy(this.initialData);
        const deletionsObserver = observe<BackupServerBackupJobConfiguration>(initialDataLocal);

        if (!deepEqual(initialDataLocal.virtualMachines.includes, this.data.virtualMachines.includes)) {
            initialDataLocal.virtualMachines.includes = [];
        }

        if (!deepEqual(initialDataLocal.virtualMachines.excludes.vms, this.data.virtualMachines.excludes.vms)) {
            initialDataLocal.virtualMachines.excludes.vms = [];
        }

        if (
            !deepEqual(initialDataLocal.guestProcessing.guestFSIndexing.indexingSettings,
                this.data.guestProcessing.guestFSIndexing.indexingSettings)
        ) {
            initialDataLocal.guestProcessing.guestFSIndexing.indexingSettings = [];
        }

        if (!deepEqual(initialDataLocal.guestProcessing.appAwareProcessing.appSettings, this.data.guestProcessing.appAwareProcessing.appSettings)) {
            initialDataLocal.guestProcessing.appAwareProcessing.appSettings = [];
        }

        const localData: BackupServerBackupJobConfiguration = deepCopy(this.data);

        if (!this.areProcessingOptionsEnabled) {
            localData.guestProcessing.guestCredentials = null;
        }

        const deletions = generate(deletionsObserver);
        deletionsObserver.unobserve();

        if (!localData.guestProcessing.appAwareProcessing.isEnabled) {
            localData.guestProcessing.appAwareProcessing.appSettings = initialDataLocal.guestProcessing.appAwareProcessing.appSettings;
        }

        if (!localData.guestProcessing.guestFSIndexing.isEnabled) {
            localData.guestProcessing.guestFSIndexing.indexingSettings = initialDataLocal.guestProcessing.guestFSIndexing.indexingSettings;
        }

        const updatesObserver = observe<BackupServerBackupJobConfiguration>(initialDataLocal);

        for (const k in localData) {
            const key = k as keyof BackupServerBackupJobConfiguration;

            (initialDataLocal[key] as unknown) = localData[key];
        }

        const updates = generate(updatesObserver);
        updatesObserver.unobserve();

        return [...deletions, ...updates];
    }

    patchJob(): Promise<BackupServerBackupJobConfiguration> {
        const data = this.getExtendedDiff();

        if (data.length === 0) {
            return Promise.resolve(null);
        }

        return this.transportService.patch<Operation[], RequestSuccessResponse<BackupServerBackupJobConfiguration>>(
            `/infrastructure/backupServers/jobs/backupVmJobs/vSphere/${this.data.instanceUid}/configuration/sync`,
            data,
            { notUseJsonPatch: true }
        ).then((resp: RequestSuccessResponse<BackupServerBackupJobConfiguration>) => resp.data);
    }

    createLinuxCredentials(input: BackupServerCredentialsLinuxInput) {
        return this.transportService.post<BackupServerCredentialsLinuxInput, RequestSuccessResponse<BackupServerCredentialsLinux>>(
            `/infrastructure/backupServers/${this.serverUid}/credentials/linux`,
            input,
        ).then((resp: RequestSuccessResponse<BackupServerCredentialsLinux>) => resp.data);
    }

    createWindowsCredentials(input: BackupServerCredentialsStandardInput) {
        return this.transportService.post<BackupServerCredentialsStandardInput, RequestSuccessResponse<BackupServerCredentialsStandard>>(
            `/infrastructure/backupServers/${this.serverUid}/credentials/standard`,
            input,
        ).then((resp: RequestSuccessResponse<BackupServerCredentialsStandard>) => resp.data);
    }

    deleteCredentials(credentialsUid: string) {
        return this.transportService.delete(`/infrastructure/backupServers/${this.serverUid}/credentials/${credentialsUid}`);
    }
}
