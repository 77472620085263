/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    FieldLayout,
    Form,
    FormLayout,
    FormTitle,
    LabelGroup,
    LinkButton,
    NoLabelGroup,
    usePageWizardStore,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { Vb365BackupJobBackupType } from '@veeam-vspc/models/rest';

import type { Vb365BackupJob } from '@veeam-vspc/models/rest';
import type { NotificationService } from '@veeam-vspc/shared/services';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { ToggleField } from 'components/controls/ToggleField';
import { ConfigureBackupObjects, ConfigureExcludeObjects } from './components';
import { useJobWizardStore } from '../../stores';
import protectedObjectsIconSrc from 'images/actions/protected-objects.png';
import excludedObjectsIconSrc from 'images/actions/exluded-objects.png';
import { backupModeService } from './services';

import type { JobWizardStore } from '../../stores';

const isBackupItemsValid = (data: Vb365BackupJob) => {
    if (data.backupType === Vb365BackupJobBackupType.SelectedItems) {
        return data.selectedItems.length > 0;
    }

    return true;
};

const isExclusionItemsValid = (data: Vb365BackupJob, exclusionsEnabled: boolean) => {
    if (exclusionsEnabled) {
        return data.excludedItems.length > 0;
    }

    return true;
};

export const getBackupModeStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<Vb365BackupJob> => ({
    title: lang.BACKUP_MODE,
    render: data => <BackupModeStep {...data} />,
    validate: () => {
        const backupItemsValid = isBackupItemsValid(store.data);
        const exclusionItemsValid = isExclusionItemsValid(store.data, store.exclusionsEnabled);
        const validationResult = backupItemsValid && exclusionItemsValid;

        if (backupItemsValid === false) {
            notificationService.warning(lang.BACKUP_MODE, lang.SELECT_AT_LEAST_ONE_OBJECT_TO_BACK_UP);
        }

        if (exclusionItemsValid === false) {
            notificationService.warning(lang.BACKUP_MODE, lang.OBJECTS_TO_EXCLUDE_ARE_NOT_SELECTED);
        }

        return validationResult;
    },
});

export const BackupModeStep = observer((wizardStepData: WizardStepData<Vb365BackupJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore();
    const wizardStore = useJobWizardStore();
    const { transportService, notificationService } = useGlobalServices();

    backupModeService.init(transportService, notificationService);

    const data = wizardStore.data;

    const handleDataChange = (data: Vb365BackupJob) => {
        wizardStore.updateJobData(data);
    };

    const handleBackupModeChange = (backupType: Vb365BackupJobBackupType) => {
        if (backupType === Vb365BackupJobBackupType.EntireOrganization) {
            wizardStore.updateJobData({
                ...data,
                backupType,
                selectedItems: [],
            });
        } else {
            wizardStore.updateJobData({ ...data, backupType });
        }
    };

    return (
        <StepLayout
            title={lang.BACKUP_MODE}
            description={lang.SPECIFY_OBJECTS_TO_INCLUDE_IN_THE_BACKUP}
        >
            <Form
                value={data}
                onChange={handleDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.BACKUP_SCOPE}</FormTitle>

                    <NoLabelGroup
                        content={(
                            <RadioGroup
                                value={data.backupType}
                                onChange={handleBackupModeChange}
                                buttons={[
                                    {
                                        label: lang.ENTIRE_ORGANIZATION,
                                        value: Vb365BackupJobBackupType.EntireOrganization,
                                    },
                                    {
                                        label: lang.BACK_UP_THE_SPECIFIED_OBJECTS,
                                        value: Vb365BackupJobBackupType.SelectedItems,
                                    },
                                ]}
                            />
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <FieldLayout
                                disabled={data.backupType !== Vb365BackupJobBackupType.SelectedItems}
                            >
                                <LinkButton
                                    onClick={() => wizardContext.openPanel(hide => <ConfigureBackupObjects hidePanel={hide} />)}
                                    error={!wizardStepData.validationState.isCommon() && !isBackupItemsValid(data)}
                                    iconBefore={protectedObjectsIconSrc}
                                >
                                    {lang.CONFIGURE}
                                </LinkButton>
                            </FieldLayout>
                        )}
                    />

                    <FormTitle>{lang.EXCLUSIONS}</FormTitle>

                    <LabelGroup label={`${lang.SPECIFY_OBJECTS_TO_EXCLUDE_FROM_THE_BACKUP}:`}>
                        <ToggleField
                            value={wizardStore.exclusionsEnabled}
                            onChange={wizardStore.setExclusionsState}
                            showSuffix
                        />
                    </LabelGroup>

                    <NoLabelGroup
                        content={(
                            <FieldLayout
                                disabled={!wizardStore.exclusionsEnabled}
                            >
                                <LinkButton
                                    onClick={() => wizardContext.openPanel(hide => <ConfigureExcludeObjects hidePanel={hide} />)}
                                    error={!wizardStepData.validationState.isCommon() && !isExclusionItemsValid(data, wizardStore.exclusionsEnabled)}
                                    iconBefore={excludedObjectsIconSrc}
                                >
                                    {lang.CONFIGURE}
                                </LinkButton>
                            </FieldLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
