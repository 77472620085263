/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, useGlobalLang } from '@veeam-vspc/shared/components';
import { DiscoveryRuleStateRepresentation } from '@veeam-vspc/models/web-controllers';

import type { DiscoveryRuleGridDataModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import successIconSrc from 'images/statuses/success.svg';
import createdIconSrc from 'images/statuses/created.png';
import stoppedIconSrc from 'images/statuses/stopped.svg';
import errorIconSrc from 'images/statuses/error.svg';
import runningIconSrc from 'images/statuses/running.svg';

export const RuleStatusColumn = ({ rowData }: { rowData: DiscoveryRuleGridDataModel; }) => {
    const { errors, state, stateStr } = rowData;
    const lang = useGlobalLang<VspcLang>();
    const iconStatusesSrc = {
        [DiscoveryRuleStateRepresentation.Success]: successIconSrc,
        [DiscoveryRuleStateRepresentation.Created]: createdIconSrc,
        [DiscoveryRuleStateRepresentation.Failed]: errorIconSrc,
        [DiscoveryRuleStateRepresentation.Running]: runningIconSrc,
        [DiscoveryRuleStateRepresentation.Canceled]: stoppedIconSrc,
        [DiscoveryRuleStateRepresentation.CancellationRequested]: stoppedIconSrc,
        [DiscoveryRuleStateRepresentation.CancellationRequestFailed]: errorIconSrc,
    };

    return (
        <ActionLinkColumn
            contentIconBefore={iconStatusesSrc[state]}
            renderContent={() => stateStr}
            isLink={!!errors}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                    iconSrc={errorIconSrc}
                >
                    <ParsedText textOrConfig={errors} />
                </MessageBox>
            )}
        />
    );
};
