/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, colors, FormsContext } from '@veeam-vspc/shared/components';

import type { NotificationSettingsLevel } from '@veeam-vspc/models/rest';
import type { ButtonProps } from '@veeam-vspc/shared/components';

const SliderBtn = styled(Button)`
    padding-left: 8px;
    padding-right: 8px;
    border-color: ${colors.G000};

    :active, :focus, :hover {
        color: ${({ theme }) => theme.colorTextMain};
        border-color: ${({ theme }) => theme.colorControlBorderAccent};
        background-color: ${({ theme }) => theme.colors.backgrounds.none};
    }
`;

const SliderBtnBordered = styled(SliderBtn)`
    border-color: ${({ theme }) => theme.colorControlBorderAccent};
`;

export interface FormSliderBtnProps extends ButtonProps {
    formValue: NotificationSettingsLevel;
    hasBorder: boolean;
    label: string;
    name: string;
}

export const FormSliderBtn: React.FC<FormSliderBtnProps> = (props) => {
    const { formValue, hasBorder, label, name, ...restProps } = props;
    const formsState = useContext(FormsContext);

    const sliderBtnProps: ButtonProps = {
        ...restProps,
        onClick: (e) => {
            formsState.setValue(name, formValue);
            restProps.onClick && restProps.onClick(e);
        },
    };

    return hasBorder
        ? <SliderBtnBordered {...sliderBtnProps}>{label}</SliderBtnBordered>
        : <SliderBtn {...sliderBtnProps}>{label}</SliderBtn>
    ;
};
