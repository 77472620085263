/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { AgentBackupsService } from './services/agent-backups.service';
import { BaseTrendChart } from '../../../BaseTrendChart';

export const AgentBackups: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    const params = {
        colors: [palette.B400, palette.Grn200],
        yTitle: lang.BACKUPS,
    };

    return (
        <BaseTrendChart
            params={params}
            service={new AgentBackupsService(transportService, lang)}
            title={{ text: capitalize(lang.COMPUTER_BACKUPS) }}
        />
    );
};
