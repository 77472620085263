/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { constants } from '@veeam-vspc/shared/components';

import type { TitleProps } from '../../interfaces';

const Flex = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`;

const HeaderStyled = styled.div`
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    height: ${constants.SIZE_XXL};
    padding: ${constants.SPACING_S} 0;
`;

const TitleStyled = styled.div`
    align-items: center;
    display: flex;
    font-weight: 700;
    padding: 0 8px;
    text-align: center;
`;

interface HeaderProps {
    title: TitleProps;
}

export const Header = ({ title }: HeaderProps) => (
    <HeaderStyled>
        <Flex />
        <TitleStyled>{title.text}</TitleStyled>
        <Flex>{title.tools}</Flex>
    </HeaderStyled>
);
