/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { TopCompaniesParam } from '@veeam-vspc/models/web-controllers';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import type { PrevConfigData, UseTopsAdvanceGridComponentCommon } from '../interfaces';

const PANEL_HEIGHT = 48;
const ROW_HEIGHT_WITH_BORDER = 32;

export const useCommonSettings = <T,>({
    maxNumberOfRows,
    getDataRequestUrl,
    shouldRenderPeriodSelect,
    period,
}: {
    maxNumberOfRows: number;
    getDataRequestUrl: string;
    shouldRenderPeriodSelect?: boolean;
    period?: number;
}): UseTopsAdvanceGridComponentCommon<T> => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [countData, setCountData] = useState<number>(0);
    const [totalData, setTotalData] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const api = useRef<GridStore<T, {}, TopCompaniesParam>>();

    const prevConfigRef = useRef<PrevConfigData>({
        maxNumberOfRows,
    });

    const height = 2 + PANEL_HEIGHT + ROW_HEIGHT_WITH_BORDER +
        (countData < maxNumberOfRows ? countData || 1 : maxNumberOfRows) * ROW_HEIGHT_WITH_BORDER +
        (countData < totalData ? PANEL_HEIGHT : 0);

    const getGridData = useCallback(
        (params: RequestParams<{}>) => transportService.request<TopCompaniesParam, RequestSuccessResponse<T[]>>(getDataRequestUrl, {
            ...params,
            limit: maxNumberOfRows,
            ...(shouldRenderPeriodSelect ? { filter: { period } } : {}),
        })
            .then((response: RequestSuccessResponse<T[]>) => {
                setCountData(response.meta.pagingInfo.count);
                setTotalData(response.meta.pagingInfo.total);
                return response;
            })
            .finally(() => setIsLoading(false)),
        [maxNumberOfRows, period]
    );

    useEffect(() => {
        if (!api.current) return;

        if (prevConfigRef.current.maxNumberOfRows !== maxNumberOfRows) {
            setIsLoading(true);
            prevConfigRef.current.maxNumberOfRows = maxNumberOfRows;
            api.current.setPagination({ limit: maxNumberOfRows });
        }

    }, [maxNumberOfRows]);

    useEffect(() => {
        if (!api.current) return;

        api.current.applyFilters({ filter: { period } });
    }, [period]);

    return {
        api,
        getGridData,
        height,
        isLoading,
        lang,
        shouldRenderPeriodSelect,
        period,
    };
};
