/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConnectionStatus {
    Unknown = 0,
    Connect = 1,
    Connecting = 2,
    Warning = 3,
    Disconnected = 4,
    Failed = 5,
    NoConnection = 6,
    NotApplicable = 7,
}