/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { Vb365BackupJobSchedulePolicyPeriodicallyEvery } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const getPeriodicallyReadableValues = (lang: VspcLang) => ({
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Minutes5]: `5 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Minutes10]: `10 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Minutes15]: `15 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Minutes30]: `30 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours1]: `1 ${lang.HOUR}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours2]: `2 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours4]: `4 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Hours8]: `8 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicyPeriodicallyEvery.Unknown]: ColumnTexts.DashSymbol,
});
