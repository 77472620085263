/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { htmlDecode } from '@veeam-vspc/shared/helpers';

import { markdownToHtml, sanitize } from '../../../core/utils';

export interface SanitizedInnerHtmlProps {
    html: string;
    isPreSanitized?: boolean;
    withMarkdown?: boolean;
}

export const SanitizedInnerHtml: React.FC<SanitizedInnerHtmlProps> = ({ html, isPreSanitized = false, withMarkdown = false }) => {
    const sanitized = isPreSanitized ? html : htmlDecode(sanitize(html));

    return (
        // eslint-disable-next-line @typescript-eslint/naming-convention
        <div dangerouslySetInnerHTML={{ __html: withMarkdown ? markdownToHtml(sanitized) : sanitized }} />
    );
};
