/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGlobalLang } from '@veeam-vspc/shared/components';
import { PublicCloudPlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { AdvancedFilterValue, FilterGroup } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import amazonWebServicesPlatformIconSrc from 'images/filters/cloud-platforms/aws.svg';
import microsoftAzurePlatformIconSrc from 'images/filters/cloud-platforms/azure.png';
import googlePlatformIconSrc from 'images/filters/cloud-platforms/google.svg';

export interface UseCloudPlatformTypesAdvancedFilterProps {
    propName?: string;
}

export const useCloudPlatformTypesAdvancedFilter = (
    { propName = 'platform' }: UseCloudPlatformTypesAdvancedFilterProps = {}
): FilterGroup<AdvancedFilterValue> => {
    const lang = useGlobalLang<VspcLang>();

    return {
        title: lang.PLATFORM,
        items: [
            {
                iconSrc: amazonWebServicesPlatformIconSrc,
                label: lang.AMAZON_WEB_SERVICES,
                value: {
                    id: PublicCloudPlatformTypeRepresentation.AmazonEc2,
                    name: propName,
                },
            },
            {
                iconSrc: microsoftAzurePlatformIconSrc,
                label: lang.MICROSOFT_AZURE,
                value: {
                    id: PublicCloudPlatformTypeRepresentation.AzureCompute,
                    name: propName,
                },
            },
            {
                iconSrc: googlePlatformIconSrc,
                label: lang.GOOGLE_CLOUD,
                value: {
                    id: PublicCloudPlatformTypeRepresentation.Gcp,
                    name: propName,
                },
            },
        ],
    };
};
