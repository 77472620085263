/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface ProtectedDataSummaryFile {
    ProtectedDataSummary: ComponentType<any>;
}

export const ProtectedDataSummaryPage = () => (
    <LazyLoadedComponent<ProtectedDataSummaryFile>
        importPromise={() => (import('./components/ProtectedDataSummary') as Promise<ProtectedDataSummaryFile>)}
        pageName={'ProtectedDataSummary'}
    />
);
