/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TabBarBodyLayout,
    TabBarBodyLayoutModes,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import React, { useMemo, useState } from 'react';
import { useNavigationBlocker } from '@veeam-vspc/shared/hooks';
import { formatStr } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import {
    ColorScheme,
    Favicon,
    LoginLogo,
    Portal,
    PortalGraphics,
    PortalName,
    PortalWebAddress,
    Report,
} from './components';
import { FormNames } from './enums';

export const PortalBranding: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const [formsIsDirty, setFormsIsDirty] = useState<Record<FormNames, boolean>>({
        [FormNames.ColorScheme]: false,
        [FormNames.PortalName]: false,
        [FormNames.PortalWebAddress]: false,
    });
    const isDirty = useMemo(() => Object.values(formsIsDirty).some(Boolean), [formsIsDirty]);

    useNavigationBlocker({
        isDirty,
        prompt: {
            title: lang.PORTAL_BRANDING,
            message: formatStr(lang.LEAVE_CONFIRM_MSG, lang.PORTAL_BRANDING_SETTINGS),
        },
    });

    const onIsDirtyChange = (formIsDirty: Partial<Record<FormNames, boolean>>) => {
        setFormsIsDirty(prevState => ({ ...prevState, ...formIsDirty }));
    };

    return (
        <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xl}>
                <ColorScheme onIsDirtyChange={onIsDirtyChange} />
                <PortalWebAddress onIsDirtyChange={onIsDirtyChange} />
                <PortalName onIsDirtyChange={onIsDirtyChange} />
                <LoginLogo />
                <PortalGraphics />
                <Portal />
                <Report />
                <Favicon />
            </StackView>
        </TabBarBodyLayout>
    );
};
