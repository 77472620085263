/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors } from '@veeam-vspc/shared/components';

export const ValueCellStyled = styled.div`
    color: ${colors.B400};
    font-size: 20px;
    padding-right: 16px;
`;
