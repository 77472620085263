/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import type { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';

import { QueryKeys, ReturnUrlProducts } from 'core/enums';

export interface SmtpOAuthQuery {
    [QueryKeys.ProductReturnUrl]: string;
    kind: OAuth2ClientSettingsKind;
    user: UserRoleRepresentation;
}

export const isSmtpOAuthRedirect = (queryParams: URLSearchParams): boolean => {
    const stateQuery = queryParams.get(QueryKeys.SmtpOAuthState) || '';

    if (!stateQuery.includes(QueryKeys.SmtpOAuthSeparator)) {
        return false;
    }

    const oAuthQueryValue = getOAuthQueryValue(stateQuery);

    return oAuthQueryValue[QueryKeys.ProductReturnUrl] === ReturnUrlProducts.SmtpOAuth;
};

export const getOAuthQueryValue = (stateQuery: string): SmtpOAuthQuery => {
    const splitQuery = stateQuery.split(QueryKeys.SmtpOAuthSeparator);
    const lastIdx = splitQuery.length - 1;
    const decodedQuery = decodeURIComponent(splitQuery[lastIdx]);

    return JSON.parse(decodedQuery);
};
