/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ToolbarWithExport, useGlobalLang } from '@veeam-vspc/shared/components';

import type { ProtectedVmBackupRestorePointExportParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import type { GridExportProps } from 'views/hooks';

export interface RestorePointDialogToolbarProps extends GridExportProps {
    method?: string;
    extraParams: ProtectedVmBackupRestorePointExportParam;
}

export const RestorePointDialogToolbar: React.FC<RestorePointDialogToolbarProps> = ({
    method = 'GetProtectedVmBackupRestorePoints',
    ...restProps
}) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
        />
    );
};
