/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ConfiguredToolbar,
    Link,
    Text,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    toolbarItemDecorators,
    useGlobalLang,
    useGlobalServices,
    useGridApi,
} from '@veeam-vspc/shared/components';
import { EActionResultStatus, ReportTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { capitalize, formatStr, plural } from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { useNavigate } from 'react-router-dom';

import type {
    MultiActionResult,
    ReportConfigurationModel,
    ReportEditModel,
    ReportGetReportDataParam,
    ReportSettingsParam,
} from '@veeam-vspc/models/web-controllers';
import type { UseFullPageActions } from '@veeam-vspc/shared/hooks';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import newSrc from 'images/actions/add.svg';
import vmBackupSrc from 'images/filters/reports/vm.png';
import dataBackupSrc from 'images/filters/reports/file-shares.png';
import computersSrc from 'images/filters/reports/computers.png';
import databasesSrc from 'images/filters/reports/databases.png';
import vb365Src from 'images/filters/reports/vb365-sla-complience.png';
import editSrc from 'images/actions/edit.svg';
import removeSrc from 'images/actions/remove.svg';
import runSrc from 'images/actions/run.svg';
import { useAsyncAction } from 'views/hooks';
import { insertComponent } from 'core/utils';

import type { WizardModel } from '../../Configuration';

export const ConfigurationActionToolbar: React.FC<{ reportWizardAction: UseFullPageActions<WizardModel>; }> = ({ reportWizardAction }) => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const newReportGroupId = 'newReportGroupId';
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const { transportService, notificationService } = useGlobalServices();
    const gridApi = useGridApi<ReportConfigurationModel, unknown, ReportSettingsParam['filter']>();

    const remove = () => {
        const ids = gridApi.selected.map(({ id }) => id);
        const count = ids.length;
        const msg = formatStr(
            lang.THIS_WILL_DELETE_REPORT,
            plural(count, lang.CONFIGURATION, lang.CONFIGURATIONS, false),
            plural(count, lang.THIS, lang.THESE, false),
            plural(count, lang.REPORT, lang.REPORTS, false),
        );

        notificationService
            .confirm(capitalize(lang.DELETE_REPORT_CONFIGURATION), msg)
            .then((key) => {
                if (key === NotificationDialogsTextKeys.Yes) {
                    doAsyncAction('/Report/Delete', lang.REMOVE, { ids });
                }
            });
    };

    const run = () => {
        const ids = gridApi.selected.map(({ id }) => id);

        doAsyncAction('/Report/GenerateReport', lang.RUN_REPORT, { ids })
            .then(({ data }: RequestSuccessResponse<MultiActionResult>) => {
                if (data.status === EActionResultStatus.Success) {
                    notificationService.info(
                        lang.RUN_REPORT,
                        <Text>
                            {insertComponent(
                                lang.NAVIGATE_TO_ALL_REPORT,
                                <Link onClick={() => {
                                    navigate('home/reports/allReports');
                                    notificationService.hide();
                                }}
                                >
                                    {lang.ALL_REPORTS}
                                </Link>
                            )}
                        </Text>
                    );
                }
            });
    };

    const actions = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.VIRTUAL_MACHINES,
            iconSrc: vmBackupSrc,
            groupId: newReportGroupId,
            onClick: () => reportWizardAction.activate({ reportType: ReportTypeRepresentation.ProtectedVms }),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.DATA_BACKUP,
            iconSrc: dataBackupSrc,
            groupId: newReportGroupId,
            onClick: () => reportWizardAction.activate({ reportType: ReportTypeRepresentation.ProtectedFiles }),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.COMPUTERS,
            iconSrc: computersSrc,
            groupId: newReportGroupId,
            onClick: () => reportWizardAction.activate({ reportType: ReportTypeRepresentation.ProtectedComputers }),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.DATABASES,
            iconSrc: databasesSrc,
            groupId: newReportGroupId,
            onClick: () => reportWizardAction.activate({ reportType: ReportTypeRepresentation.ProtectedDatabases }),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.MICROSOFT_365_OBJECTS,
            iconSrc: vb365Src,
            groupId: newReportGroupId,
            onClick: () => reportWizardAction.activate({ reportType: ReportTypeRepresentation.ProtectedVboObjectsSla }),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.EDIT,
            iconSrc: editSrc,
            onClick: () => transportService
                .request<ReportGetReportDataParam, RequestSuccessResponse<ReportEditModel>>('/Report/GetReportData', {
                    reportId: gridApi.selected[0].id,
                })
                .then(({ data }: RequestSuccessResponse<ReportEditModel>) =>
                    reportWizardAction.activate({ isEdit: true, reportType: data.type, ...data })),
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.REMOVE,
            iconSrc: removeSrc,
            onClick: remove,
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.separator,
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.RUN,
            iconSrc: runSrc,
            onClick: run,
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
    ];

    return (
        <>
            <ConfiguredToolbar
                sectionId={ConfigSectionIds.ReportsConfiguration}
                groups={[
                    {
                        iconSrc: newSrc,
                        id: newReportGroupId,
                        title: lang.NEW,
                    },
                ]}
                view={TOOLBAR_VIEW_TYPE.default}
                items={actions}
            />

            {asyncActionLoader}
        </>
    );
};
