/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { SmtpSettings } from '@veeam-vspc/models/rest';

import { UrlProtocols } from '../../../enums';

import type { ServerSettingsDialogFormData } from '../../../interfaces';

export const getNewSmtpSettings = (formData: ServerSettingsDialogFormData): SmtpSettings => {
    const { host, port, useAuth, smtp: { passwordCredential, timeout, tlsMode } } = formData;

    const smtpSettings: SmtpSettings = {
        tlsMode,
        timeout,
        oAuth2Credential: null,
        serverAddress: `${UrlProtocols.Smtp}${host}:${port}`,
    };

    if (useAuth) {
        smtpSettings.passwordCredential = {
            userName: passwordCredential.userName,
            password: passwordCredential.password,
        };
    }

    return smtpSettings;
};
