/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ReportStatistics } from '@veeam-vspc/models/web-controllers';

import { LicenseDisplayName } from '../../../enums';
import { parseDate } from 'core/utils/date-helpers';

import type { ComboboxItem } from 'core/interfaces';
import type { UsageReportsData } from '../interfaces';

export const transformData = (
    data: ReportStatistics[],
): Promise<UsageReportsData> => {
    const alreadyIn = [];
    const categories = [];
    const series = {};
    const workloads: ComboboxItem[] = [];
    let empty = true;

    data.forEach((item) => {
        const { date, workloadUsages } = item;
        const { shortMonth, year } = parseDate(date);

        if (workloadUsages.length === 0) return;

        categories.push(`${shortMonth} ${year}`);

        workloadUsages.forEach((usage) => {
            const { identifier, newCount, reportedCount } = usage;
            const { name } = identifier;

            if (!alreadyIn[name]) {
                alreadyIn[name] = true;
                workloads.push({ id: name, text: name });
            }

            if (!series[name]) {
                series[name] = [
                    {
                        data: new Array(categories.length - 1).fill(null),
                        name: LicenseDisplayName.Licensed,
                    }, {
                        data: new Array(categories.length - 1).fill(null),
                        name: LicenseDisplayName.New,
                    },
                ];
            }

            series[name][0].data.push(reportedCount);
            series[name][1].data.push(newCount);

            if (newCount > 0 || reportedCount > 0) {
                empty = false;
            }
        });

        Object.keys(series).forEach((seriesName) => {
            if (series[seriesName][0].data.length < categories.length) {
                series[seriesName][0].data.push(null);
            }
            if (series[seriesName][1].data.length < categories.length) {
                series[seriesName][1].data.push(null);
            }
        });
    });

    workloads.sort((w1, w2) => w1.text > w2.text ? 1 : -1);

    return Promise.resolve({
        categories,
        empty,
        series,
        workloads,
    });
};
