/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChangePasswordForm, changePasswordFormInitialState, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { ChangePasswordFormState } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';
import { resetFormState } from '../../utils';

import type { ChangePasswordData, ChangePasswordFactor } from '../../interfaces';

export const ChangePassword = observer(() => {
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const loginStore = useLoginStore();
    const { code } = loginStore.currentFactor as ChangePasswordFactor;

    return (
        <BaseFactor width={'491px'}>
            <ChangePasswordForm
                title={lang.PLEASE_ENTER_A_NEW_PASSWORD}
                error={loginStore.error}
                loading={loginStore.isLoading}
                logIn={({ newPassword }: ChangePasswordFormState) => {
                    loginStore.setIsLoading(true);

                    transportService.request<ChangePasswordData, {}>(
                        '/User/CompleteResetPasswordRequest',
                        { newPassword, code },
                        { noAuth: true, notShowDefaultError: true }
                    )
                        .then(() => {
                            loginStore.onFinishFactorHandler({
                                goToLoginStart: true,
                                info: lang.THE_NEW_PASSWORD_HAS_BEEN_SET,
                            });
                            resetFormState(changePasswordFormInitialState);
                        })
                        .catch((resp: RequestErrorResponse) => {
                            loginStore.setIsLoading(false);
                            loginStore.setError(resp.errors[0]?.message);
                        });
                }}
                onChange={() => loginStore.resetMessages()}
                texts={{
                    labelNewPassword: lang.NEW_PASSWORD,
                    labelRepeatPassword: lang.CONFIRM_PASSWORD,
                    actionLogIn: lang.SAVE,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    errorMatchingPasswords: lang.PASSWORDS_DO_NOT_MATCH,
                }}
            />
        </BaseFactor>
    );
});
