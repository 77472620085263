/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    ToolbarWithExport,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getSearchFieldPlaceholder } from '../../utils';

export interface PublicCloudsDialogToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    backupType: PublicCloudVmRestoreType;
    method?: string;
}

export const PublicCloudsDialogToolbar: React.FC<PublicCloudsDialogToolbarProps> = (props) => {
    const { backupType, method = 'GetPublicCloudVmBackup', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={getSearchFieldPlaceholder(backupType, lang)}
                        />
                    ),
                },
            ]}
        />
    );
};
