/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable, observable } from 'mobx';

export enum GridName {
    TopCompaniesByTraffic = 'Top Companies by Traffic',
    TopCompaniesByDataTransferOut = 'Top Companies by Data Transfer Out',
    TopCompaniesByTransferredData = 'Top Companies by Transferred Data',
}

export class PeriodsStore {

    @observable periodTopCompaniesByTraffic = 7;
    @observable periodTopCompaniesByDataTransferOut = 7;
    @observable periodTopCompaniesByTransferredData = 7;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setPeriod(period: number, gridName: GridName): void {
        switch (gridName) {
            case GridName.TopCompaniesByTraffic:
                this.periodTopCompaniesByTraffic = period;
                break;
            case GridName.TopCompaniesByDataTransferOut:
                this.periodTopCompaniesByDataTransferOut = period;
                break;
            case GridName.TopCompaniesByTransferredData:
                this.periodTopCompaniesByTransferredData = period;
                break;
        }
    }
}

export const periodsStore = new PeriodsStore();
