/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { VmBackupsService } from './services/vm-backups.service';

export const VmBackups: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    const params = {
        colors: [palette.B400],
        yTitle: lang.BACKUPS,
    };

    return (
        <BaseTrendChart
            params={params}
            service={new VmBackupsService(transportService, lang)}
            title={{ text: capitalize(lang.VM_BACKUPS) }}
        />
    );
};
