/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    SchedulingMatrix,
    HoursLegendHeader,
    SCHEDULING_MATRIX_SELECT_MOD,
    createSchedulingMatrixSelectModeFooter,
    FormLayout,
    FieldLayout,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    Form,
    NumberInput,
    FormValidator,
    SIZE_L,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import { unflatScheduleMatrix } from 'core/utils';

import type { ConfigureScheduleProps } from '../../interfaces';

export const ConfigureSchedule: FC<ConfigureScheduleProps> = ({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const [selectMode, setMode] = useState(SCHEDULING_MATRIX_SELECT_MOD.select);
    const [data, setData] = useState<boolean[][]>(
        () => unflatScheduleMatrix(wizardStore.data.schedulePolicy.periodicallyWindowSettings.backupWindow)
    );
    const [periodicallyOffsetMinutes, setPeriodicallyOffsetMinutes] = useState(wizardStore.data.schedulePolicy.periodicallyOffsetMinutes || 0);

    const handleApply = () => {
        wizardStore.updateSchedule({
            ...wizardStore.data.schedulePolicy,
            periodicallyWindowSettings: { backupWindow: data.flat() },
            periodicallyOffsetMinutes,
        });

        hidePanel();
    };

    const handleCancel = () => {
        hidePanel();
    };

    const footer = createSchedulingMatrixSelectModeFooter({
        getValue: () => selectMode,
        onChange: setMode,
    });

    const validateOffset = (data: { periodicallyOffsetMinutes: number; }) => {
        const validator = new FormValidator(data);

        validator
            .validate('periodicallyOffsetMinutes')
            .number()
            .max(59)
            .min(0);

        return validator.result();
    };

    return (
        <SidePanelForm
            title={lang.SCHEDULE}
            description={lang.CHOOSE_WHEN_JOBS_WITHIN_THE_BACKUP_WINDOW}
            onRequestClose={hidePanel}
            actions={[
                { text: lang.APPLY, onClick: handleApply, disabled: Object.keys(validateOffset({ periodicallyOffsetMinutes })).length > 0 },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >

            <SchedulingMatrix
                legendHeader={{
                    // sideOffset: 8,
                    render: () => <HoursLegendHeader />,
                }}
                columns={SchedulingMatrix.columnPresets.timeRange}
                rows={[
                    { title: lang.SUNDAY },
                    { title: lang.MONDAY },
                    { title: lang.TUESDAY },
                    { title: lang.WEDNESDAY },
                    { title: lang.THURSDAY },
                    { title: lang.FRIDAY },
                    { title: lang.SATURDAY },
                ]}
                mode={selectMode}
                data={data}
                onSelect={(items) => {
                    items.forEach(({ rowIndex, columnIndex }) => {
                        data[rowIndex][columnIndex] = selectMode === SchedulingMatrix.modes.select;
                    });
                    setData([...data]);
                }}
                footer={{
                    ...footer,
                    render() {
                        return (
                            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} style={{ height: SIZE_L }}>
                                {footer.render()}

                                <Form
                                    value={{ periodicallyOffsetMinutes }}
                                    onChange={data => setPeriodicallyOffsetMinutes(data.periodicallyOffsetMinutes)}
                                    validate={validateOffset}
                                >
                                    <FormLayout inlineLabel>
                                        <FieldLayout label={`${lang.START_TIME_WITHIN_AN_HOUR}:`}>
                                            <NumberInput
                                                name='periodicallyOffsetMinutes'
                                                value={periodicallyOffsetMinutes}
                                                minValue={0}
                                                maxValue={59}
                                                suffix={lang.MINUTES.toLowerCase()}
                                            />
                                        </FieldLayout>
                                    </FormLayout>
                                </Form>
                            </StackView>
                        );
                    },
                }}
            />
        </SidePanelForm>
    );
};
