/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { PieChartModel, PieChartModelItem } from '@veeam-vspc/models/web-controllers';

import type { PieData } from 'views/components/HighchartsWrapper/interfaces';

export const transformDonutData = ({ items }: PieChartModel, isEmptyFn?: (items: PieChartModelItem[]) => boolean): PieData => {
    const series = { data: [], currency: '' };
    let unlimited = false;

    const empty = isEmptyFn ? isEmptyFn(items) : items.every(i => !i.value);

    items.forEach((item) => {
        const { capacity, value } = item;
        let v1: number;
        let v2: number;

        if (capacity === -1) {
            unlimited = true;
            v1 = value;
            v2 = 0;
        } else if (capacity === 0) {
            v1 = value;
            v2 = 0;
        } else {
            v1 = value;
            v2 = capacity - value;
        }

        series.data = [
            ['value', v1],
            ['left', v2],
        ];

        series.currency = item.currency;
    });

    return {
        empty,
        series: [series],
        unlimited,
    };
};
