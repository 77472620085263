/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FileSizeUnitsInBytes, FileSizeUnitNames } from '@veeam-vspc/shared/core';
import { TimeUnit } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { matchDataLengthWithCategories } from './match-data-length-with-categories';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { ReportingPeriodInfo, SeriesName, TrendItem } from '../interfaces';

export const transformBaseChartData = (
    lang: VspcLang,
    data: TrendItem[],
    reportingPeriodInfo: ReportingPeriodInfo,
    seriesNames: SeriesName[],
    formatDimension = false,
): Promise<ChartDataWithCategories> => {
    const categories = [];
    const series = [];
    const unitName: string = FileSizeUnitNames.B;
    const unitPrefixes: string[] = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const unitSize: number = FileSizeUnitsInBytes.KB;
    let empty = true;
    let max = 0;
    let unitIndex = 0;

    const monthNames = [
        lang.JANUARY,
        lang.FEBRUARY,
        lang.MARCH,
        lang.APRIL,
        lang.MAY,
        lang.JUNE,
        lang.JULY,
        lang.AUGUST,
        lang.SEPTEMBER,
        lang.OCTOBER,
        lang.NOVEMBER,
        lang.DECEMBER,
    ];

    seriesNames.forEach((sName) => {
        series.push({ name: sName.label, data: new Array(12).fill(null, 0, reportingPeriodInfo.interval.count) });
    });

    data.forEach((item, i) => {
        const d = new Date(item.date.slice(0, -6));

        categories.push(reportingPeriodInfo.aggregation.unit === TimeUnit.Months
            ? `${monthNames[d.getMonth()].slice(0, 3)}`
            : `${d.getDate()} ${monthNames[d.getMonth()].slice(0, 3)}`
        );

        item.counters.forEach((c) => {
            series.find(s => s.name === seriesNames.find(sName => sName.type === c.type).label).data[i] = c.value;

            if (c.value !== null) {
                empty = false;
            }
        });
    });

    if (formatDimension) {
        series.forEach(s => s.data.forEach((v) => {
            max = v > max ? max = v : max;
        }));

        for (let i = 1; i < unitPrefixes.length; i += 1) {
            if (max < unitSize) {
                break;
            }

            max /= unitSize;
            unitIndex += 1;
        }

        series.forEach(s => s.data.forEach((v, i) => {
            s.data[i] = v === null ? v : Math.round(v / Math.pow(2, 10 * unitIndex) * 100) / 100;
        }));
    }

    matchDataLengthWithCategories({ series, categories });

    return Promise.resolve({
        categories,
        dimension: formatDimension ? `${unitPrefixes[unitIndex]}${unitName}` : null,
        empty,
        series,
    });
};
