/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { CloudConnect } from './components';

export interface TrendsCloudConnectPageProps {
    currentPath?: string;
}

export const TrendsCloudConnectPage: React.FC<TrendsCloudConnectPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <CloudConnect stateId={currentPath} />
    </TabBarBodyLayout>
);
