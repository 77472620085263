/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { JobTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { ObjectStorageDialog } from './components';

export interface FileServerColumnProps {
    value: any;
    jobType: JobTypeRepresentation;
    name: string;
    instanceUid: string;
    stateId: string;
}

export const ObjectStorageColumn: React.FC<FileServerColumnProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const {
        value,
        jobType,
        name,
        instanceUid,
        stateId,
    } = props;

    const content = (value && ![JobTypeRepresentation.FileCopy, JobTypeRepresentation.FileTapeBackup].includes(jobType)) ? value : null;

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {content}
                </TextColumn>
            )}
            isLink={Boolean(content)}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <ObjectStorageDialog
                    deactivate={deactivate}
                    title={`${name} - ${capitalize(lang.OBJECT_STORAGE_BACKUP_JOB)}`}
                    jobUid={instanceUid}
                    stateId={stateId}
                />
            )}
        />
    );
};
