/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, ActionLinkColumn, useGlobalLang, TextColumn } from '@veeam-vspc/shared/components';

import type { TextColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { getColumnIconAndText } from './utils';

import type { VawTemplateStatuses } from 'core/enums';

export interface BackupPolicyColumnProps {
    value?: string | null;
    commonVawTemplateStatus: VawTemplateStatuses;
    multipleJobs: boolean;
    onClick: () => void;
    defaultValue?: TextColumnProps['defaultValue'];
    isLink?: boolean;
}

export const BaseBackupPolicyColumn: React.FC<BackupPolicyColumnProps> = ({
    value,
    commonVawTemplateStatus,
    multipleJobs,
    onClick,
    defaultValue,
    isLink = true,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { icon, text } = getColumnIconAndText(lang, value, commonVawTemplateStatus, multipleJobs);

    if (isLink) {
        return (
            <TextColumn defaultValue={defaultValue}>
                {value !== null && value !== undefined
                    ? <LinkButton onClick={onClick} iconBefore={icon}>{text}</LinkButton>
                    : null}
            </TextColumn>
        );
    } else {
        return (
            <ActionLinkColumn
                isLink={false}
                contentIconBefore={icon}
                renderContent={() => text || defaultValue}
            />
        );
    }

};
