/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { INDENT, StackView, Text } from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { isEmpty } from 'core/utils';

export interface JobStatisticsValueTextProps {
    text: React.ReactNode;
}

export const JobStatisticsValueText: React.FC<JobStatisticsValueTextProps> = ({ text }) => (
    <StackView spaceLeft={INDENT.xl} spaceRight={INDENT.xl}>
        <Text>{!isEmpty(text) ? text : ColumnTexts.DashSymbol}</Text>
    </StackView>
);
