/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ComboboxKit,
    DatePeriod,
    Search,
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    useGridApi,
    useGlobalLang,
    ToolbarWithExport,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import React from 'react';
import { ActivityKind } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { AuditLogParam, AuditLogModel, CompanyShortData } from '@veeam-vspc/models/web-controllers';
import type { DatePeriodValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import internalSrc from 'images/filters/activity-log/internal.png';
import externalSrc from 'images/filters/activity-log/external.png';
import { getDatePeriod } from 'core/utils/date-helpers';

import type { ToggleFilterStatusItem } from 'views/components/filters/StatusToggleFilter/interfaces';

interface ActivityLogFilterToolbarProps {
    companiesList: CompanyShortData[];
    getCompaniesList: () => void;
}

export const ActivityLogFilterToolbar: React.FC<ActivityLogFilterToolbarProps> = ({ companiesList, getCompaniesList }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<AuditLogModel, unknown, AuditLogParam['filter']>();
    const { dateFrom, dateTo, companyId } = gridApi.filters;
    const dateValue: DatePeriodValue = dateFrom || dateTo ? [new Date(gridApi.filters.dateFrom), new Date(gridApi.filters.dateTo)] : [];
    const { formats } = useGlobalAppData();

    const types: ToggleFilterStatusItem[] = [
        { title: lang.INTERNAL, value: ActivityKind.Internal, iconSrc: internalSrc },
        { title: lang.EXTERNAL, value: ActivityKind.External, iconSrc: externalSrc },
    ];

    return (
        <ToolbarWithExport
            view={TOOLBAR_VIEW_TYPE.spotted}
            lang={lang}
            method={'GetAccessLogs'}
            disableGlobalFilters={true}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                            <Text>{`${lang.COMPANY}:`}</Text>

                            <ComboboxKit
                                value={companyId || -1}
                                data={[
                                    { name: lang.ALL_COMPANIES, id: -1 },
                                    ...companiesList,
                                ]}
                                textGetter={data => data.name}
                                valueGetter={data => data.id}
                                onChange={(companyId: number) => {
                                    gridApi.applyFilters({ companyId });
                                    getCompaniesList();
                                }}
                            />
                        </StackView>
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='userName'
                            placeholder={lang.USERNAME}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.TYPE}
                            statuses={types}
                            propName={'activityKind'}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <DatePeriod
                            format={formats.netShortDate}
                            value={dateValue}
                            onChange={(value) => {
                                if (value.length === 0) {
                                    const dateTo = new Date();
                                    dateTo.setUTCHours(21, 59, 59, 999);
                                    const dateFrom = new Date(dateTo.getTime() - (7 * 24 * 60 * 60 * 1000));
                                    dateFrom.setUTCHours(22, 0, 0, 0);
                                    gridApi.applyFilters({
                                        dateFrom: dateFrom.toISOString(),
                                        dateTo: dateTo.toISOString(),
                                    });
                                } else {
                                    gridApi.applyFilters(getDatePeriod(value));
                                }
                            }}
                            texts={{
                                allTimeLabel: capitalize(lang.LAST_7_DAYS),
                                allTimeValueText: capitalize(lang.LAST_7_DAYS),
                            }}
                        />
                    ),
                },
            ].filter(Boolean)}
        />
    );
};
