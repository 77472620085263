/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { GeoJSON } from 'highcharts';

import { extractCountryId } from './extract-country-id';

export const getMapByMapId = async(mapId: string) => {
    const countryId = extractCountryId(mapId);

    return await fetch(`react-app/build/static/js/maps/${countryId}/${mapId}-all.geo.json`)
        .then(res => res.json() as Promise<GeoJSON>);
};
