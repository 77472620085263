/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VspcLang } from 'configs/languages';

// Generate id 32 as last day of any month
export const getDaysNumber = (lang: VspcLang) => Array(32).fill(0)
    .map(
        (val, i) => ({
            id: i + 1,
            name: i < 31 ? (i + 1).toString() : lang.LAST,
        }),
    );
