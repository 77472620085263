/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAddons,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps, GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';
import type { BaseLocationData } from '@veeam-vspc/models/web-controllers';


import { VBObjectsFilterToolbar, VBObjectsActionToolbar, ProtectedDataTypeColumn, RestorePointsColumn } from './components';
import { vbObjectsService } from './services';
import { MAX_INTEGER_32 } from 'core/const';

import type { ProtectedVBObjectsFilters } from './interfaces';

export const VBObjects: React.FC = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { portalFilters } = useGlobalAddons();
    const { portalUser } = useGlobalAppData();
    const gridApi = useRef<GridStore<unknown, unknown, unknown>>();

    vbObjectsService.init(transportService);
    const columns: GridColumnProps<Vb365ProtectedObject>[] = [
        {
            id: 'name',
            title: lang.NAME,
            cell: rowData => rowData.name,
        },
        {
            id: 'protectedDataType',
            title: lang.TYPE,
            cell: rowData => <ProtectedDataTypeColumn rowData={rowData} />,
            width: 100,
        },
        {
            id: 'vb365OrganizationName',
            title: lang.ORGANIZATION,
            cell: rowData => rowData.vb365OrganizationName,
            isSortable: false,
        },
        {
            id: 'latestRestorePointDate',
            title: lang.LATEST_RESTORE_POINT,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.latestRestorePointDate}
                />
            ),
        },
        {
            id: 'archiveRestorePointsCount',
            title: lang.BACKUP_COPIES,
            width: 130,

            cell: rowData => (
                <RestorePointsColumn
                    name={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    stateId={'VBObjects.Dialogs.archiveRestorePointsCount'}
                    value={rowData.archiveRestorePointsCount}
                    vb365BackupRepositoryUid={rowData.archiveRepositoryUid}
                    vb365ProtectedObjectId={rowData.id}
                    vb365ServerUid={rowData.vb365ServerUid}
                />
            ),
        },
        {
            id: 'restorePointsCount',
            title: lang.RESTORE_POINTS,
            width: 140,

            cell: rowData => (
                <RestorePointsColumn
                    name={`${lang.RESTORE_POINTS} - ${rowData.name}`}
                    stateId={'VBObjects.Dialogs.restorePointsCount'}
                    value={rowData.restorePointsCount}
                    vb365BackupRepositoryUid={rowData.repositoryUid}
                    vb365ProtectedObjectId={rowData.id}
                    vb365ServerUid={rowData.vb365ServerUid}
                />
            ),
        },
        {
            id: 'organizationName',
            title: lang.COMPANY,
            cell: rowData => rowData.organizationName,
            isSortable: false,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            isSortable: false,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            isSortable: false,
            hidden: true,
        },
        {
            id: 'vb365ServerName',
            title: lang.SERVER,
            cell: rowData => rowData.vb365ServerName,
            isSortable: false,
            width: 100,
        },
        {
            id: 'consumesLicense',
            title: lang.LICENSED,
            cell: (rowData) => {
                if (rowData.consumesLicense === null) {
                    return null;
                }

                return rowData.consumesLicense ? lang.YES : lang.NO;
            },
            width: 100,
        },
    ];

    return (
        <ConfiguredGrid
            api={gridApi}
            stateId='ProtectedDataPage.VBObjects'
            columns={columns}
            data={(requestParams: RequestParams<ProtectedVBObjectsFilters>) => transportService.request('/Location/GetLocations', {
                companyIds: portalFilters.isOrganizationsFilterVisible ? (
                    portalFilters.organizationFilter
                        ? [portalFilters.organizationFilter.id]
                        : []
                ) : [portalUser.companyId],
                limit: MAX_INTEGER_32,
                page: 1,
                start: 0,
                rcopAutoUpdate: requestParams.rcopAutoUpdate,
            })
                .then((resp: RequestSuccessResponse<BaseLocationData[]>) => {
                    const location = resp.data.find(x => x.id === portalFilters.locationFilter);
                    return vbObjectsService.getProtectedVBObjects(requestParams, location);
                })}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VBObjectsFilterToolbar />,
                () => <VBObjectsActionToolbar />,
            ]}
        />
    );
});
