/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { SmtpSettings } from '@veeam-vspc/models/rest';

import { Protocols } from '../enums';

export const getServerHostName = (serverAddress: SmtpSettings['serverAddress']): URL['hostname'] => {
    // with SMTP protocol, instance of the 'new URL' class works incorrectly, that's why we need to replace the protocol
    const url = new URL(serverAddress.replace(Protocols.SMTP, Protocols.HTTPS));

    return url.hostname;
};
