/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { ACTION_VIEW, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { MAX_INTEGER_32 } from 'core/const';


import type { LocationRequest } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/location-request';
import type { LocationsPanelProps } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/locations-panel-props';
import type { Location } from '../../../../interfaces';

export const LocationsPanel = ({ hidePanel, data, onDataChange }: LocationsPanelProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const api = useRef<GridStore<Location, any, {}>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.locations?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.LOCATIONS}
            description={lang.SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.locations = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <ConfiguredGrid
                initialState={{
                    selected: data.locations,
                }}
                defaultColumnMinWidth={100}
                columns={[
                    {
                        id: 'name',
                        title: lang.LOCATION,
                        cell: rowData => rowData.name,
                    },
                    {
                        id: 'admins',
                        title: lang.ADMINS,
                        cell: rowData => rowData.admins,
                    },
                    {
                        id: 'users',
                        title: lang.USERS,
                        cell: rowData => rowData.users,
                    },
                ]}
                data={(requestParams: RequestParams<{}>) => transportService
                    .request<LocationRequest, RequestSuccessResponse<Location[]>>('/Location/GetLocationData', {
                        ...requestParams,
                        companyId: data.companyID,
                    })}
                toggleable={false}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                api={api}
            />
        </SidePanelForm>
    );
};
