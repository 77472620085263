/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { BaseForm, LOGIN_FORM_WIDTH, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

export interface BaseFactorProps {
    children: React.ReactElement | React.ReactElement[];
    alternativeButton?: React.ReactElement;
    width?: string;
}

export const BaseFactor = observer((props: BaseFactorProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { children, alternativeButton, width = `${LOGIN_FORM_WIDTH}px` } = props;

    return (
        <BaseForm
            width={width}
            alternativeButton={alternativeButton}
            beforeBtnText={lang.OR}
        >
            {children}
        </BaseForm>
    );
});
