/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    TextColumn,
    ACTION_VIEW,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalAppData,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { PulseLicenseInstallationStatus } from '../../../../enums';
import { Toolbar } from './components/Toolbar';
import { normalizeDateWithTime } from 'core/utils/date-helpers';


import type { VbrDeploySettingsWizardModel } from '../../../../entries';
import type {
    VbrLicenseRequestParams,
    VbrLicenseKey,
    PulseLicenseKey,
    PulseLicenseRequestParams,
} from '../../../../interfaces';

export const LicenseGrid: React.FC<WizardStepData<VbrDeploySettingsWizardModel>> = ({ data, onDataChange, stepData }) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const { notificationService, transportService } = useGlobalServices();

    return (
        <ConfiguredGrid
            stateId={'VbrWizard.PulseLicense.LicenseGrid'}
            columns={[
                {
                    id: 'productName',
                    title: lang.PRODUCT,
                    cell: rowData => rowData.productName,
                },
                {
                    id: 'points',
                    title: lang.POINTS,
                    cell: rowData => rowData.points,
                },
                {
                    id: 'expirationDate',
                    title: lang.EXPIRATION_DATE,
                    cell: rowData => (
                        <TextColumn>
                            {normalizeDateWithTime(new Date(Date.parse(rowData.expirationDate)), formats)}
                        </TextColumn>
                    ),
                },
                {
                    id: 'serverName',
                    title: lang.HOSTNAME,
                    cell: rowData => rowData.serverName,
                    hidden: true,
                },
                {
                    id: 'licenseUsageType',
                    title: lang.LICENSE_TYPE,
                    cell: rowData =>
                        portalEnums.getEnumDescription('PulseLicenseUsageType', rowData.licenseUsageType),
                    hidden: true,
                },
                {
                    id: 'licenseId',
                    title: lang.LICENSE_ID,
                    cell: rowData => rowData.licenseId,
                    hidden: true,
                },
            ]}
            initialState={{
                filters: {
                    productUids: data.productUids,
                    installationStatus: [PulseLicenseInstallationStatus.Installed],
                },
                selected: data.licenseId ? [{ licenseUid: data.licenseId } as VbrLicenseKey] : undefined,
            }}
            data={(requestParams: VbrLicenseRequestParams) => transportService
                .request<PulseLicenseRequestParams, RequestSuccessResponse<PulseLicenseKey[]>>('/Pulse/GetOrganizationLicenseKeys', {
                    productUids: requestParams.filter.productUids || [],
                    organizationUid: data.organizationUid,
                })}
            selection={{
                field: 'licenseUid',
                multiple: false,
                checkbox: false,
            }}
            onSelectionChange={(selected) => {
                const selectedLicense = selected[0];

                data.licenseId = selectedLicense?.licenseUid;

                if (selectedLicense.installationStatus) {
                    if (selectedLicense.installationStatus === PulseLicenseInstallationStatus.Installed) {
                        let isLicenseAlreadyInstalledPromise = null;

                        stepData.isLicenseAlreadyInstalled = () => {
                            if (!isLicenseAlreadyInstalledPromise) {
                                isLicenseAlreadyInstalledPromise = new Promise(resolve =>
                                    notificationService.info(
                                        capitalize(lang.INSTALL_LICENSE),
                                        lang.IT_IS_NOT_RECOMMENDED_TO_SHARE,
                                        {
                                            buttons: [
                                                {
                                                    type: NotificationDialogsTextKeys.Ok,
                                                    text: lang.COPY,
                                                },
                                                {
                                                    type: NotificationDialogsTextKeys.Cancel,
                                                    text: lang.CANCEL,
                                                    view: ACTION_VIEW.secondary,
                                                },
                                            ],
                                        }
                                    ).then((btn) => {
                                        if (btn !== NotificationDialogsTextKeys.Ok) {
                                            isLicenseAlreadyInstalledPromise = null;
                                        }
                                        resolve(btn === NotificationDialogsTextKeys.Ok);
                                    })
                                );
                            }

                            return isLicenseAlreadyInstalledPromise;
                        };
                    } else {
                        delete stepData.isLicenseAlreadyInstalledPromise;
                        delete stepData.isLicenseAlreadyInstalled;
                    }
                }

                onDataChange(data);
            }}
            disablePagination
            toolbars={[
                () => <Toolbar data={data} onDataChange={onDataChange} />,
            ]}
        />
    );
};

