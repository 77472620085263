/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface PluginLibraryFile {
    PluginLibrary: ComponentType<any>;
}

export const PluginLibraryPage = () => (
    <LazyLoadedComponent<PluginLibraryFile>
        importPromise={() => (import('./components/PluginLibrary') as Promise<PluginLibraryFile>)}
        pageName={'PluginLibrary'}
    />
);
