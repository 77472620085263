/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TimeUnitsInSeconds } from '@veeam-vspc/shared/core';

export function calculateDuration(seconds: number | string) {
    const value = parseInt(seconds as string);
    const secondsInMinute = TimeUnitsInSeconds.Minute;
    const secondsInHour = TimeUnitsInSeconds.Hour;

    function parseTime(seconds) {
        if (seconds >= secondsInHour) {
            const hours = Math.floor(seconds / secondsInHour);
            return [{ unit: 'hour', value: hours }].concat(parseTime(seconds - (hours * secondsInHour)));
        }
        if (seconds >= secondsInMinute) {
            const minutes = Math.floor(seconds / secondsInMinute);
            return [{
                unit: 'minute',
                value: minutes,
            }].concat(parseTime(seconds - (minutes * secondsInMinute)));
        }
        return [{ unit: 'second', value: seconds }];
    }

    function pluralize(value: number, singular: string) {
        return value === 1 ? `${value} ${singular}` : `${value} ${singular}s`;
    }

    if (value === 0) {
        return '';
    }

    const items = parseTime(value);
    let text = '';
    let precision = 2;

    for (let i = 0; i < items.length && precision > 0; i++) {
        const item = items[i];
        if (item.value !== 0) {
            text += `${pluralize(item.value, item.unit)} `;
            precision -= 1;
        }
    }

    return text;
}
