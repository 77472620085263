/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { VerifyCodeForm, useGlobalLang, useGlobalServices, verifyCodeFormInitialState } from '@veeam-vspc/shared/components';

import type { TokenManagerResponse, TokenManagerErrorResponse } from '@veeam-vspc/shared/services';
import type { VerifyCodeFormState } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';
import { resetFormState } from '../../utils';

import type { VerifyCodeFactor, GoToResetPasswordResponse, GoToSsoResponse } from '../../interfaces';

export interface VerifyCodeProps {
    totalAttempts?: number;
}

export const VerifyCode = observer(({ totalAttempts = 2 }: VerifyCodeProps) => {
    const { tokenManagerService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const loginStore = useLoginStore();
    const [disabled, setDisabled] = useState(false);
    const factor = loginStore.currentFactor as VerifyCodeFactor;
    const { mfaToken, login } = factor;

    return (
        <BaseFactor>
            <VerifyCodeForm
                title={lang.VERIFICATION}
                onReturnButtonClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
                error={loginStore.error}
                loading={loginStore.isLoading}
                disabled={disabled}
                onChange={() => loginStore.resetMessages()}
                logIn={({ code }: VerifyCodeFormState) => {
                    loginStore.setIsLoading(true);

                    tokenManagerService.mfa(mfaToken, code)
                        .then((resp) => {
                            loginStore.onFinishFactorHandler({
                                ...resp,
                                login,
                            } as (TokenManagerResponse | GoToResetPasswordResponse | GoToSsoResponse) & { login?: string; });
                            resetFormState(verifyCodeFormInitialState);
                        })
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.setIsLoading(false);

                            if (factor.attempts >= totalAttempts) {
                                setDisabled(true);
                                loginStore.setError(lang.FAILED_TO_CONFIRM_THE_VERIFICATION);
                                return;
                            }

                            factor.attempts += 1;
                            loginStore.setError(errorDescription || lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT);
                        });
                }}
                texts={{
                    placeholderMFACode: lang.CODE,
                    actionLogIn: lang.LOG_IN,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    warningMFA: lang.YOUR_ACCOUNT_USES_TWO_FACTOR_AUTHENTICATION,
                    returnButtonText: login,
                    matchErrorMessage: lang.VERIFICATION_CODE_FORMAT_INVALID,
                }}
            />
        </BaseFactor>
    );
});
