/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    FIELD_LAYOUT_HELP_TEXT_VIEW,
    FormLayout,
    NoteBar,
    NOTEBAR_STATUS,
    useGlobalAppData,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { ExternalFormApi } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { DistributionType } from '../../../../enums';
import { RadioGroup } from 'components/controls/RadioGroup';

import type { VbrDeploySettingsWizardModel } from '../../../../entries';

export interface NoAutoLkRadioGroupProps {
    distributionType: VbrDeploySettingsWizardModel['distributionType'];
    formApi: ExternalFormApi<VbrDeploySettingsWizardModel>;
}

export const NoAutoLkRadioGroup: React.FC<NoAutoLkRadioGroupProps> = ({ formApi, distributionType }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();

    return (
        <FormLayout>
            <RadioGroup
                value={distributionType}
                onChange={(value: DistributionType) => {
                    formApi.setValue('distributionType', value); // otherwise we don't see errors
                }}
                buttons={[
                    {
                        disabled: true,
                        label: `${lang.DOWNLOAD_THE_BACKUP_SERVER_SETUP_FILE_FROM} (${lang.RECOMMENDED})`,
                        value: DistributionType.DownloadFromVeeamServers,
                        helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.normal,
                        helpText: (
                            <NoteBar status={NOTEBAR_STATUS.warning}>
                                {portalUser.isAdminPortal() ? lang.ENABLE_PRODUCT_UPDATES : lang.CONTACT_YOUR_SERVICE_PROVIDER}
                            </NoteBar>
                        ),
                    },
                    {
                        label: lang.USE_THE_BACKUP_SERVER_SETUP_FILE,
                        value: DistributionType.DownloadFromFileShare,
                        helpText: `${lang.SPECIFY_PATH_TO_THE_BACKUP_SERVER}:`,
                        helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.labelDescription,
                    },
                ]}
            />
        </FormLayout>
    );
};
