/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    FormLayout,
    FormTitle,
    useGlobalLang,
    Text,
    CheckboxKit,
} from '@veeam-vspc/shared/components';
import { deepCopy } from '@veeam-vspc/shared/core';
import {
    BackupServerApplicationSettingsVSS,
    BackupServerTransactionLogsSettings,
} from '@veeam-vspc/models/rest';

import type { BackupServerBackupJobApplicationSettings } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { RadioGroup } from 'components/controls';

interface Props {
    deactivate: () => void;
    onConfirm: (data: BackupServerBackupJobApplicationSettings) => void;
    data: BackupServerBackupJobApplicationSettings;
}

export const ProcessingSettingsDialog = observer((props: Props) => {
    const lang = useGlobalLang<VspcLang>();

    const [data, setData] = useState(deepCopy(props.data));

    return (
        <Dialog
            header={lang.PROCESSING_SETTINGS}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => props.onConfirm(data),
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <FormLayout>
                <FormTitle>{lang.APPLICATIONS}</FormTitle>
                <Text>{lang.APPLICATION_AWARE_PROCESSING_DETECTS_AND_PREPARES_APPLICATIONS}</Text>

                <RadioGroup
                    value={data.vss}
                    onChange={value => setData({ ...data, vss: value })}
                    buttons={[
                        {
                            label: lang.REQUIRE_SUCCESSFUL_PROCESSING,
                            value: BackupServerApplicationSettingsVSS.RequireSuccess,
                        },
                        {
                            label: lang.TRY_APPLICATION_PROCESSING,
                            value: BackupServerApplicationSettingsVSS.IgnoreFailures,
                        },
                        {
                            label: lang.DISABLE_APPLICATION_PROCESSING,
                            value: BackupServerApplicationSettingsVSS.Disabled,
                        },
                    ]}
                />

                <FormTitle>{lang.VSS_SETTINGS}</FormTitle>
                <Text>{lang.CHOOSE_WHETHER_THIS_JOB_SHOULD_PROCESS_TRANSACTION_LOGS_UPON_SUCCESSFUL_BACKUP}</Text>

                <RadioGroup
                    value={data.transactionLogs}
                    onChange={value => setData({ ...data, transactionLogs: value })}
                    buttons={[
                        {
                            label: lang.PROCESS_TRANSACTION_LOGS_WITH_THIS_JOB_RECOMMENDED,
                            value: BackupServerTransactionLogsSettings.Process,
                            disabled: data.vss === BackupServerApplicationSettingsVSS.Disabled,
                        },
                        {
                            label: lang.PERFORM_COPY_ONLY_LETS,
                            value: BackupServerTransactionLogsSettings.CopyOnly,
                            disabled: data.vss === BackupServerApplicationSettingsVSS.Disabled,
                        },
                    ]}
                />

                <FormTitle>{lang.PERSISTENT_GUEST_AGENT}</FormTitle>
                <Text>{lang.BY_DEFAULT_APPLICATION_AWARE_PROCESSING_ON_WINDOWS_IS_PERFORMED_BY}</Text>

                <CheckboxKit
                    checked={data.usePersistentGuestAgent}
                    onChange={value => setData({ ...data, usePersistentGuestAgent: value })}
                >
                    {lang.USE_PERSISTENT_GUEST_AGENT}
                </CheckboxKit>

            </FormLayout>
        </Dialog>
    );
});
