/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VspcLang } from 'configs/languages';

export const extjsValidate = (value: string, validator: (v: string) => string | true): string => {
    if (value) {
        const result = validator(value);
        if (result !== true) {
            return result;
        }
    }
    return '';
};

export const extjsRegExpValidate = (value: string, regExp: RegExp, errorMsg: string): string => {
    if (value) {
        return regExp.test(value) ? '' : errorMsg;
    }
    return '';
};

// eslint-disable-next-line max-len
const ip4NotNull = '(?!0{1,3}\\.0{1,3}\\.0{1,3}\\.0{0,3}$)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])';

const getIp4NotNullRegex = (exact = false): RegExp =>
    exact ? new RegExp(`^${ip4NotNull}$`) : new RegExp(ip4NotNull, 'g');

const notLocalhost = (value: string, lang: VspcLang): string | void => {
    if (!value) {
        return;
    }

    const illegal = ['localhost', '127.0.0.1', '::1'];
    const vLower = value.trim().toLowerCase();
    const isIllegal = illegal.includes(vLower);
    if (isIllegal) {
        return lang.UNSUPPORTED_FORMAT_SPECIFY_DNS;
    }

    return;
};

const domainName = (value: string, lang: VspcLang): string | void => {
    // https://support.microsoft.com/help/909264
    const disallowedChars = '\\/:*?"<>|';
    const re = new RegExp(`[${disallowedChars}]`);
    const msg = lang.INVALID_CHARACTER;

    if (re.test(value)) {
        return msg;
    }

    const period = '.';
    if (value.length > 1 &&
        (value[0] === period || value[value.length - 1] === period)) {
        return msg;
    }

    return;
};

const checkUserName = (value: string, lang: VspcLang): string | void => {
    // Active Directory Domain Services
    // The pre-Windows 2000 logon name qqwe\q contains one or more of the following illegal characters: / \ [ ] : ; | = , + * ? < > @ "
    const disallowedChars = '/\\\\[\\]:;|=,+*?<>@"';
    const re = new RegExp(`[${disallowedChars}]`);
    const msg = lang.INVALID_CHARACTER;

    if (re.test(value)) {
        return msg;
    }

    return;
};

const domainUserName = (
    value: string,
    lang: VspcLang,
    notLocalhostMsg: string | undefined = undefined,
    noIpMsg: string | undefined = undefined
): string | void => {
    value = value.trim();

    if (!value) {
        return;
    }

    const msg = lang.TYPE_IN_THE_USERNAME;
    const parts = value.split('\\');
    if (parts.length !== 2) {
        return msg;
    }

    const domain = parts[0];
    const userName = parts[1];
    if (!domain || !userName) {
        return msg;
    }

    if (noIpMsg) {
        const ipRegex = getIp4NotNullRegex(true);
        if (ipRegex.test(domain)) {
            return noIpMsg;
        }
    }

    if (notLocalhostMsg) {
        const result = notLocalhost(domain, lang);
        if (result) {
            return notLocalhostMsg;
        }
    }

    const resultDomain = domainName(domain, lang);
    if (resultDomain) {
        return resultDomain;
    }

    const resultUserName = checkUserName(userName, lang);
    if (resultUserName) {
        return resultUserName;
    }

    return;
};

export const tenantName = (value, lang: VspcLang, fieldLabel = '') => {
    const allowedChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!#$%&()-.^_{}~\' `';
    let i;

    for (i = 0; i < value.length; i++) {
        if (allowedChars.indexOf(value[i]) === -1) {
            return lang.INVALID_CHARACTER;
        }
    }
    if (value.charAt(value.length - 1) === '.') {
        const fName = fieldLabel || 'Username';
        return `${fName} can't end on "."`;
    }
    return true;
};

export const domainUserNameNotLocalhost = (value: string, lang: VspcLang): string | void => domainUserName(value, lang, lang.USE_DOMAIN_USER);
