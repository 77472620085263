/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { VspcLang } from 'configs/languages';

import { JobKinds } from '../../../../../../enums';
import { JobObjectsDialog } from './components';

import type { ValScheduleTypeRepresentations } from '../../../../../../enums';

export interface ObjectsColumnProps {
    stateId?: string;
    successfulPercentage?: number;
    successfulJobsCount?: number;
    totalJobsCount?: number;
    scheduleState: keyof typeof ValScheduleTypeRepresentations;
    jobKind: JobKinds;
    title: string;
    instanceUid: string;
}

export const ObjectsColumn: React.FC<ObjectsColumnProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const {
        stateId,
        successfulPercentage,
        successfulJobsCount,
        totalJobsCount,
        scheduleState,
        jobKind,
        title,
        instanceUid,
    } = props;

    const jobsCountAvailable = Number.isFinite(successfulJobsCount) && Number.isFinite(totalJobsCount) && Number.isFinite(successfulPercentage);
    let linkText: string = ColumnTexts.DashSymbol;


    if (jobsCountAvailable) {
        linkText = scheduleState === 'Continuously'
            ? String(totalJobsCount)
            : `${successfulJobsCount} ${lang.OF} ${totalJobsCount} (${successfulPercentage}%)`;
    }

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>{linkText}</TextColumn>
            )}
            isLink={jobsCountAvailable}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <JobObjectsDialog
                    deactivate={deactivate}
                    title={title}
                    url={
                        jobKind === JobKinds.BackupJob
                            ? '/BackupServerAgentManagement/GetBackupAgentJobs'
                            : '/BackupServerAgentManagement/GetVbrAgentCopyJobObjects'
                    }
                    stateId={stateId}
                    exportMethod={jobKind === JobKinds.BackupJob ? 'GetVBRAgentJobs' : 'GetVBRAgentCopyJobs'}
                    extraParams={jobKind === JobKinds.BackupJob ? { jobContainerInstanceUid: instanceUid } : { copyJobInstanceUid: instanceUid }}
                    isExtendedColumns={jobKind === JobKinds.BackupJob}
                />
            )}
        />
    );
};
