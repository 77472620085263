/**
 * Copyright © Veeam Software Group GmbH.
 */

import { htmlEncode } from '@veeam-vspc/shared/helpers';
import { deepCopy, isErrorThatNeedToBeShown } from '@veeam-vspc/shared/core';

import type { FastJsonPatch, TransportService, TransportRequestOptions, TransportPatchData } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PortalEnumsAddon } from '@veeam-vspc/shared/addons';

import { webControllersEnumsMap } from 'swagger/webControllers/webControllersEnumsMap';

import type { EnumMap } from 'swagger/webControllers/interfaces/enum-map';

export class ExtTransportService {

    protected transportService: TransportService<RequestErrorResponse | Error>;
    protected portalEnums: PortalEnumsAddon;

    constructor(transportService: TransportService<RequestErrorResponse | Error>, portalEnums: PortalEnumsAddon) {
        this.transportService = transportService;
        this.portalEnums = portalEnums;
    }

    get<T, R>(url: string, data?: T, options?: TransportRequestOptions): Promise<RequestResponse<R>> {
        return this.transportService.get(url, data, options);
    }

    post<T, R>(url: string, data?: T, options?: TransportRequestOptions): Promise<RequestResponse<R>> {
        return this.transportService.post(url, data, options);
    }

    patch<T, R>(
        url: string,
        data?: FastJsonPatch.Operation[] | TransportPatchData<T>,
        options?: TransportRequestOptions,
    ): Promise<RequestResponse<R>> {
        return this.transportService.patch(url, data, options);
    }

    put<T, R>(url: string, data?: T, options?: TransportRequestOptions): Promise<RequestResponse<R>> {
        return this.transportService.put(url, data, options);
    }

    delete<T, R>(url: string, data?: T, options?: TransportRequestOptions): Promise<RequestResponse<R>> {
        return this.transportService.delete(url, data, options);
    }

    request<T, R>(url: string, data?: T, options?: TransportRequestOptions): Promise<RequestResponse<R>> {
        return this.transportService.request(url, data, options);
    }

    requestSync<T, R>(
        url: string,
        callback: (resp: RequestResponse<R>) => void,
        data?: T,
        options?: TransportRequestOptions,
    ): void {
        this.transportService.requestSync(url, callback, data, options);
    }

    requestWithReplaceEnums(url: string, data: Object, options?: TransportRequestOptions) {
        // XSS sanitizer
        const sanitize = htmlEncode;

        function sanitizeObject(obj) {
            Object.keys(obj).forEach((key) => {
                const val = obj[key];
                if (val && typeof val === 'object') {
                    sanitizeObject(val);
                } else if (typeof val === 'string') {
                    obj[key] = sanitize(val);
                }
            });
        }

        const enumDescriptors = this.portalEnums.getEnumDescriptors();
        let clearUrl = url.replace(/\?.*$/, '').toLowerCase();
        if (clearUrl[0] !== '/') {
            clearUrl = `/${clearUrl}`;
        }

        const dataClone = deepCopy(data);

        const newRequestCallback = (resp: RequestResponse<any>) => {
            if ('data' in resp && webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].response) {
                const enumRespMaps = webControllersEnumsMap[clearUrl].response;
                const response = resp as RequestSuccessResponse<any>;
                const handleDataItem = (dataItem: Object, enumMap: EnumMap, stepI = 0) => {
                    const isLastStep = stepI === enumMap.path.length - 1;
                    if (isLastStep) {
                        const pathValue = enumMap.path[stepI];

                        const isArrayStep = pathValue === '$i';
                        if (isArrayStep) {
                            const array = dataItem ? dataItem as Array<Object> : null;
                            if (array) {
                                array.forEach((item, index, arr) => {
                                    const strItem = item ? item as string : null;
                                    if (strItem) {
                                        const intItem = enumDescriptors[enumMap.enumName]
                                            .find(enumItem => enumItem.literalValue === strItem).intValue;
                                        if (typeof intItem === 'number') {
                                            arr[index] = intItem;
                                        }
                                    }
                                });
                            }
                        } else {
                            const enumValue = dataItem ? dataItem[pathValue] as string : null;
                            if (enumValue) {
                                if (typeof enumValue !== 'string') {
                                    throw Error(`Value of "${pathValue}" must be string from "${enumMap.enumName}" enum, ` +
                                        `but it is ${typeof enumValue}. Request "${url}"`);
                                }

                                const extractEnumFromDescriptor = (enumName) => {
                                    const enumsWithWrongDescriptionCapitalisation = ['triggerTypeRepresentation'];
                                    const isExceptionalEnum = enumsWithWrongDescriptionCapitalisation.includes(enumName);

                                    const enumDescriptor = enumDescriptors[enumName]
                                        .find((enumDescriptorItem) => {
                                            const loweredCheckResult = enumDescriptorItem.literalValue.toLowerCase() === enumValue.toLowerCase();

                                            if (loweredCheckResult && enumDescriptorItem.literalValue !== enumValue) {
                                                const isExceptionValue = enumDescriptorItem.literalValue.toLowerCase() === 'mbps';

                                                if (!isExceptionValue && !isExceptionalEnum) {
                                                    console.error(
                                                        `[VSPC] Capitalize enum problem. Request ${url}, property ${pathValue} has value`
                                                        + ` "${enumValue}" but must be a "${enumDescriptorItem.literalValue}"`
                                                    );
                                                }

                                            }

                                            if (isExceptionalEnum) {
                                                return loweredCheckResult;
                                            }

                                            return enumDescriptorItem.literalValue === enumValue;
                                        });

                                    if (enumDescriptor) {
                                        dataItem[pathValue] = enumDescriptor.intValue;
                                        dataItem[`${pathValue}_literalValue`] = enumDescriptor.literalValue;
                                        dataItem[`${pathValue}_description`] = enumDescriptor.description;
                                    }
                                };

                                if (enumDescriptors[enumMap.enumName]) {
                                    extractEnumFromDescriptor(enumMap.enumName);
                                } else {
                                    if (enumMap.enumName.indexOf('nullable') === 0) {
                                        const enumName = enumMap.enumName.replace(/^nullable([\d\D])([\d\D]+$)/, (a, b, c) => b.toLowerCase() + c);
                                        extractEnumFromDescriptor(enumName);
                                        console.warn(`(Handled) No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                                    } else {
                                        console.error(`No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                                    }
                                }
                            }
                        }
                    } else {
                        // TEST
                        if (enumMap.path[stepI] === 'userFolders') {
                            // debugger;
                        }

                        const isArrayStep = enumMap.path[stepI] === '$i';
                        if (isArrayStep) {
                            const dataItemArray = dataItem as Array<Object>;
                            if (dataItemArray) {
                                dataItemArray.forEach(dataSubItem => handleDataItem(dataSubItem, enumMap, stepI + 1));
                            }
                        } else {
                            if (dataItem) {
                                handleDataItem(dataItem[enumMap.path[stepI]], enumMap, stepI + 1);
                            }
                        }
                    }
                };

                enumRespMaps.forEach((property) => {
                    if (Array.isArray(response.data)) {
                        const dataAsArray = response.data as Array<Object>;
                        dataAsArray.forEach(dataItem => handleDataItem(dataItem, property));
                    } else {
                        handleDataItem(response.data, property);
                    }
                });
            }

            return resp;
        };

        try {
            if (webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].request) {
                const enumRequestMaps = webControllersEnumsMap[clearUrl].request;
                enumRequestMaps.forEach(property => this.replaceEnumsInRequestParams(dataClone, property, 0, url));
            }
        } catch (e) {
            console.error(e);
            // debugger;
        }

        const requestParams = dataClone || {};

        return this.transportService
            .request(url, requestParams, options)
            .then((resp: RequestResponse<any>) => {
                try {
                    sanitizeObject(resp);
                    return newRequestCallback(resp);
                } catch (e) {
                    console.error(e);
                    //debugger;
                }
            });
    }

    requestSyncWithReplaceEnums<T>(
        url: string,
        callback: (resp: RequestResponse<T>) => void,
        data: Object,
        options?: TransportRequestOptions,
    ): void {
        this.requestWithReplaceEnums(url, data, options)
            .then(resp => callback(resp))
            .catch((resp) => {
                if (resp && resp instanceof Error) {
                    isErrorThatNeedToBeShown(resp);
                } else {
                    return callback(resp);
                }
            });
    }

    protected replaceEnumsInRequestParams = (params: Object, enumMap: EnumMap, stepI = 0, url: string) => {
        const enumDescriptors = this.portalEnums.getEnumDescriptors();
        const isLastStep = stepI === enumMap.path.length - 1;
        const handleEnumValue = (obj: Object, propName: string|number) => {
            const enumValue = obj[propName] as number;
            if (typeof enumValue === 'number') {
                if (!enumDescriptors[enumMap.enumName]) {
                    console.error(`No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                } else {
                    const enumDescriptor = enumDescriptors[enumMap.enumName]
                        .find(enumDescriptorItem => enumDescriptorItem.intValue === enumValue);

                    if (enumDescriptor) {
                        obj[propName] = enumDescriptor.literalValue;
                    }
                }
            }
        };

        if (!params) {
            return;
        }

        if (isLastStep) {
            const pathValue = enumMap.path[stepI];
            if (pathValue === '$i') {
                (params as Array<string>).forEach((param, i) => handleEnumValue(params, i));
            } else {
                handleEnumValue(params, pathValue);
            }
        } else {
            const isArrayStep = enumMap.path[stepI] === '$i';
            if (isArrayStep) {
                const dataItemArray = params as Array<Object>;
                if (dataItemArray) {
                    dataItemArray.forEach(dataSubItem => this.replaceEnumsInRequestParams(dataSubItem, enumMap, stepI + 1, url));
                }
            } else {
                if (params) {
                    this.replaceEnumsInRequestParams(params[enumMap.path[stepI]], enumMap, stepI + 1, url);
                }
            }
        }
    };

}
