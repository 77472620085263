/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { RoutePaths, RouteIds } from '../../enums';
import { ConfigActionIds, ConfigSectionIds } from 'core/enums';
import { allConfigsRoutes, allCustomRoutes, allHomeRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

import type { VspcLang } from '../../languages';

export const cloudConnectAdministratorConfig: UserConfigData<typeof UserRoleRepresentation.CloudConnectAdministrator, ConfigSectionIds> = {
    role: UserRoleRepresentation.CloudConnectAdministrator,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.Overview],
                        allHomeRoutes[RouteIds.JobsSummary],
                        allHomeRoutes[RouteIds.ActiveAlarms],
                        allHomeRoutes[RouteIds.ProtectedDataSummary],
                        allHomeRoutes[RouteIds.Tops],
                        allHomeRoutes[RouteIds.BackupJobs],
                        allHomeRoutes[RouteIds.FailoverPlans],
                        allHomeRoutes[RouteIds.ProtectedData],
                        allHomeRoutes[RouteIds.Companies],
                        {
                            ...allHomeRoutes[RouteIds.Discovery],
                            navNameKey: 'DISCOVERY' as VspcLang['DISCOVERY'],
                        },
                        allHomeRoutes[RouteIds.BillingSummary],
                        allHomeRoutes[RouteIds.Invoices],
                        allHomeRoutes[RouteIds.Reports],
                    ],
                },
                {
                    path: RoutePaths.Configs,
                    component: AppPortalBodyLayout,
                    items: [
                        allConfigsRoutes[RouteIds.EventLog],
                        {
                            ...allConfigsRoutes[RouteIds.Security],
                            navNameKey: 'SECURITY_CERTIFICATES' as VspcLang['SECURITY_CERTIFICATES'],
                        },
                        allConfigsRoutes[RouteIds.CloudConnectServers],
                        allConfigsRoutes[RouteIds.RolesUsers],
                        allConfigsRoutes[RouteIds.BackupPolicies],
                        allConfigsRoutes[RouteIds.SubscriptionPlans],
                        allConfigsRoutes[RouteIds.Licensing],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.BackupServerTerminal],
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.EditUserProfileWizard,
            items: {
                loginInfoStep: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersComputers,
            actions: {
                [ConfigActionIds.ActionToolbarChangeSettings]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.SubscriptionPlansActionToolbar,
            actions: {
                [ConfigActionIds.New]: ConfigStates.Hidden,
                [ConfigActionIds.Copy]: ConfigStates.Hidden,
                [ConfigActionIds.Separator]: ConfigStates.Hidden,
                [ConfigActionIds.Remove]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsDataBackupObjectStorage,
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
    ],
};
