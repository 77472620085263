/**
 * Copyright © Veeam Software Group GmbH.
 */

/* eslint-disable @typescript-eslint/no-duplicate-imports */

import React, { useState } from 'react';
import {
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { GetVbRestoreLinkParams, PublicCloudProtectedVmModel, PublicCloudProtectedVmParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import restoreTypeIconSrc from 'images/actions/restore.svg';
import instanceRestoreTypeIconSrc from 'images/actions/instance-restore.svg';
import volumeRestoreTypeIconSrc from 'images/actions/volume-restore.svg';
import fileLevelRecoveryTypeIconSrc from 'images/actions/file-level-recovery.svg';

export interface PublicCloudsActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const PublicCloudsActionToolbar: React.FC<PublicCloudsActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudVm', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();
    const isReadOnlyUser = portalUser?.isReadOnlyRole() ?? true;
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSingleSelection(),
    ];
    const gridApi = useGridApi<PublicCloudProtectedVmModel, unknown, PublicCloudProtectedVmParam['filter']>();
    const vmRestoreGroupId = 'vmRestoreGroupId';
    const getLink = (restoreType: EPublicCloudRestoreType) => {
        const { selected } = gridApi;

        setLoading(true);

        transportService.request<GetVbRestoreLinkParams, RequestSuccessResponse<string>>('/VbPlugin/GetRestoreLink', {
            uid: selected[0].instanceUid,
            vbServerId: selected[0].vbServerId,
            restoreType,
            backUrl: window.location.href,
        })
            .then((resp: RequestSuccessResponse<string>) => {
                setLoading(false);
                window.open(resp.data as string, '_self');
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                lang={lang}
                method={method}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.group,
                        iconSrc: restoreTypeIconSrc,
                        text: lang.RESTORE,
                        hidden: isReadOnlyUser,
                        items: [
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: instanceRestoreTypeIconSrc,
                                text: lang.INSTANCE_RESTORE,
                                onClick: () => getLink(EPublicCloudRestoreType.RestoreVmInstance),
                                groupId: vmRestoreGroupId,
                                decorators: defaultDisabledDecorators,
                            },
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: volumeRestoreTypeIconSrc,
                                text: lang.VOLUME_RESTORE,
                                onClick: () => getLink(EPublicCloudRestoreType.RestoreVmVolume),
                                groupId: vmRestoreGroupId,
                                decorators: defaultDisabledDecorators,
                            },
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: fileLevelRecoveryTypeIconSrc,
                                text: lang.FILE_LEVEL_RECOVERY,
                                onClick: () => getLink(EPublicCloudRestoreType.RestoreVmFileLevel),
                                groupId: vmRestoreGroupId,
                                decorators: defaultDisabledDecorators,
                            },
                        ],
                    },
                ]}
            />

            {loading && <PortalSpinner />}
        </>
    );
};
