/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationSettingsLevel } from '@veeam-vspc/models/rest';

import type { NotificationLevelFlags } from '../interfaces';

export const getNotificationLevelFlags = (notificationLevel: NotificationSettingsLevel): NotificationLevelFlags => {
    switch (notificationLevel) {
        case NotificationSettingsLevel.All:
            return {
                noEmails: false,
                summaryEmails: false,
                everyEventEmails: true,
            };

        case NotificationSettingsLevel.Summary:
            return {
                noEmails: false,
                summaryEmails: true,
                everyEventEmails: false,
            };

        case NotificationSettingsLevel.Disabled:
        default:
            return {
                noEmails: true,
                summaryEmails: false,
                everyEventEmails: false,
            };
    }
};
