/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, ToolbarWithExport, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, useGlobalLang } from '@veeam-vspc/shared/components';
import { JobSessionTaskStatusRepresentation } from '@veeam-vspc/models/web-controllers';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';

export interface FileServersDialogToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method: string;
}

export const ObjectStorageDialogToolbar: React.FC<FileServersDialogToolbarProps> = (props) => {
    const { method, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: JobSessionTaskStatusRepresentation.Success },
        { title: lang.WARNING, value: JobSessionTaskStatusRepresentation.Warning },
        { title: lang.FAILED, value: JobSessionTaskStatusRepresentation.Failed },
        { title: lang.RUNNING, value: JobSessionTaskStatusRepresentation.InProgress },
        {
            title: lang.INFORMATION,
            value: [
                JobSessionTaskStatusRepresentation.Unknown,
                JobSessionTaskStatusRepresentation.Pending,
            ],
        },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.SERVER}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter statuses={statuses} />
                    ),
                },
            ]}
        />
    );
};
