/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { PieChartModel } from '@veeam-vspc/models/web-controllers';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { transformPieData } from 'views/components/ChartWidget/helpers';

import type { PieData } from '../interfaces';

export class LicenseStatusService {
    static fetchData(transportService: TransportService<RequestErrorResponse | Error>, { productType }): Promise<PieData> {
        return transportService
            .request('/License/GetLicenseStatistics', { productType })
            .then((resp: RequestSuccessResponse<PieChartModel>) => transformPieData(resp.data));
    }
}
