/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum QueryKeys {
    ReturnUrl = 'returnUrl',
    ProductReturnUrl = 'stateFrom',
    CloudProductType = 'productType',
    From = 'from',
    VbId = 'vbId',
    SessionId = 'sessionId',
    Name = 'name',
    PolicyId = 'policyId',
    HierarchyRef = 'instanceId',
    RepositoryId = 'repositoryId',
    ResetPasswordFlag = 'resetCode',

    SsoAuthCode = 'authorizationCode',
    SsoBackendError = 'error',
    SsoEmailCode = 'code',
    SsoLoginState = 'state',

    SmtpOAuthState = 'state',
    SmtpOAuthSeparator = '|', // agreement with backend to use this symbol as a separator between 'their' and 'ours' information in the 'state' query

    GridFilters = 'gridFilters',
    CompanyId = 'companyId',
    ValidPeriod = 'ValidPeriod',

    VspcButlerCode = 'vspcButlerCode', // auth code from https://vspc-login.vac.butler.cis.local/authorize
}
