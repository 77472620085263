/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    FormLayout,
    NoLabelGroup,
    NumberInput,
    PasswordInput,
    Separator,
    TextInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { FormFieldNames } from '../../../../enums';
import { getAuthTypes, getTlsModeTypes } from '../../helpers';
import { useServerSettingsDialogFormStore } from '../../../../stores';

import type { AuthTypes } from '../../../../enums';

export const BasicSettingsFields = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formData: { useAuth }, onAuthTypeChange, onPasswordFocus, passwordInputHasEye } = useServerSettingsDialogFormStore();

    return (
        <FormLayout inlineLabel>
            <Combobox
                data={getAuthTypes(lang)}
                size={CONTROL_SIZE.m}
                name={FormFieldNames.AuthType}
                label={lang.AUTHENTICATION_TYPE}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                onChange={(newAuthType: AuthTypes) => onAuthTypeChange(newAuthType)}
            />

            <TextInput
                size={CONTROL_SIZE.m}
                label={lang.SMTP_SERVER}
                name={FormFieldNames.Host}
                placeholder={lang.ENTER_NAME_OR_ADDRESS}
            />

            <NumberInput
                minValue={1}
                maxValue={65535}
                label={lang.PORT}
                name={FormFieldNames.Port}
            />

            <Combobox
                data={getTlsModeTypes(lang)}
                size={CONTROL_SIZE.m}
                name={FormFieldNames.TlsMode}
                label={lang.ENCRYPTION_PROTOCOL}
                valueGetter={item => item.id}
                textGetter={item => item.name}
            />

            <Separator />

            <NoLabelGroup
                content={(
                    <Checkbox
                        name={FormFieldNames.UseAuth}
                        checked={useAuth}
                    >
                        {lang.REQUIRES_AUTHENTICATION}
                    </Checkbox>
                )}
                subContent={(
                    <FormLayout inlineLabel disabled={!useAuth}>
                        <TextInput
                            label={lang.USERNAME}
                            size={CONTROL_SIZE.l}
                            name={FormFieldNames.UserName}
                            placeholder={lang.ENTER_USERNAME}
                        />

                        <PasswordInput
                            label={lang.PASSWORD}
                            size={CONTROL_SIZE.l}
                            hasEye={passwordInputHasEye}
                            name={FormFieldNames.Password}
                            placeholder={lang.ENTER_PASSWORD}
                            onFocus={onPasswordFocus}
                        />
                    </FormLayout>
                )}
            />
        </FormLayout>
    );
});
