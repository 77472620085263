/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { PortalUser } from '@veeam-vspc/shared/stores';

export const getClientDocUrl = (portalUser: PortalUser): string => {
    if (portalUser.isAdminPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(643);
    }

    if (portalUser.isResellerPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(645);
    }

    if (portalUser.isClientPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(644);
    }

    throw new Error('You are in an unknown portal');
};
