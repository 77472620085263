/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

interface SaveUserParams<T> {
    props: {
        isEdit: boolean;
        onFinish: () => void;
    };
    requestParams: T;
    areAdminRoles?: boolean;
}

export const saveUser = <T>(
    transportService: TransportService<RequestErrorResponse | Error>,
    { props, requestParams, areAdminRoles }: SaveUserParams<T>,
) => {
    if (areAdminRoles) {
        return transportService.request('/AccountManagement/Save', requestParams)
            .then(() => props.onFinish());
    } else {
        return transportService.request(props.isEdit ? '/User/UpdateUser' : '/User/CreateUser', requestParams)
            .then(() => props.onFinish());
    }
};
