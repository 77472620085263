/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileTagAttributes, XmlFileTagAttributesValues, XmlFileTags } from '../../../enums';

export type TagAttrValue = string | null;

export interface ConfigEntry {
    name: TagAttrValue;
    value: TagAttrValue;
    isHidden: boolean;
}

export const getPropertyTags = (xml: XMLDocument): Element[] => Array.from(xml.getElementsByTagName(XmlFileTags.Property));

export const isHiddenTag = (xmlTag: Element): boolean => (
    (xmlTag.getAttribute(XmlFileTagAttributes.Hidden) as TagAttrValue) === XmlFileTagAttributesValues.Accept
);

export const getXmlEntries = (xml: XMLDocument): ConfigEntry[] => (
    getPropertyTags(xml)
        .map(xmlTag => ({
            name: xmlTag.getAttribute(XmlFileTagAttributes.Name),
            value: xmlTag.getAttribute(XmlFileTagAttributes.Value),
            isHidden: isHiddenTag(xmlTag),
        }))
);
