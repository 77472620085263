/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { STACK_DIRECTION, StackView, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { VspcLang } from 'configs/languages';

import { ChartWidget } from 'views/components/ChartWidget';
import { Legend } from './components/Legend';
import { LicenseStatusChart } from './components';
import { Separator } from 'views/components/ChartWidget/components/Separator';
import { ConfigItemIds, ConfigSectionIds } from 'core/enums';

export const LicenseStatus = () => {
    const lang = useGlobalLang<VspcLang>();
    const { configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.LicensingOverview);

    const cloudConnectChartIsHidden = sectionConfig
        ? sectionConfig.getItemState(ConfigItemIds.CloudConnectServerChart) === ConfigStates.Hidden
        : false;

    return (
        <ChartWidget
            style={{ height: 384 }}
            title={{ text: capitalize(lang.LICENSE_STATUS) }}
        >
            <StackView direction={STACK_DIRECTION.row} style={{ flex: 1 }}>
                {!cloudConnectChartIsHidden && (
                    <>
                        <LicenseStatusChart
                            productType={LicenseProductType.CloudConnectServer}
                            title={lang.VEEAM_CLOUD_CONNECT}
                        />

                        <Separator />
                    </>
                )}

                <LicenseStatusChart
                    productType={LicenseProductType.BackupServer}
                    title={lang.VEEAM_BACKUP_AND_REPLICATION}
                />

                <Separator />

                <LicenseStatusChart
                    productType={LicenseProductType.VOne}
                    title={lang.VEEAM_ONE}
                />

                <Separator />

                <LicenseStatusChart
                    productType={LicenseProductType.Vbo}
                    title={lang.VEEAM_BACKUP_FOR_MICROSOFT}
                />
            </StackView>

            <Legend />
        </ChartWidget>
    );
};
