/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Toolbar } from '@veeam/components';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    SelectLink,
    STACK_DIRECTION,
    StackView,
    TOOLBAR_ITEM_TYPE,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize, formatStr } from '@veeam-vspc/shared/helpers';
import {
    CommonLicenseStateRepresentation,
    LicenseTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { VacLicenseInfo } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { LicenseInfo } from './components/LicenseInfo';
import { LicenseKeyToolbar } from '../../../LicenseKeyToolbar';
import { shortDate } from 'core/utils/date-helpers';
import error from 'images/statuses/error.svg';
import success from 'images/statuses/success.svg';
import warning from 'images/statuses/warning.svg';


export const StatusBar = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { transportService } = useGlobalServices();
    const [hideUpdate, setHideUpdate] = useState(false);
    const [licenseInfo, setLicenseInfo] = useState({} as VacLicenseInfo);
    const [statusIcon, setStatusIcon] = useState(null);
    const [statusText, setStatusText] = useState(lang.LOADING);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);

    const updateLicenseInfo = () => {
        transportService.request('/License/GetLicenseInfo')
            .then((r: RequestSuccessResponse<VacLicenseInfo>) => {
                const { expirationDate, state, status, licenseType } = r.data;

                setHideUpdate(
                    licenseType === LicenseTypeRepresentation.Nfr
                    || licenseType === LicenseTypeRepresentation.Trial
                );

                setLicenseInfo(r.data);
                setDataIsLoaded(true);
                setStatusText(state === CommonLicenseStateRepresentation.Unknown
                    ? formatStr(lang.INSTALLED_EXPIRATION_DATE, shortDate(expirationDate || new Date(), formats))
                    : status);

                switch (state) {
                    case CommonLicenseStateRepresentation.Valid:
                    case CommonLicenseStateRepresentation.Unlicensed:
                        setStatusIcon(success);
                        break;
                    case CommonLicenseStateRepresentation.Warning:
                        setStatusIcon(warning);
                        break;
                    case CommonLicenseStateRepresentation.Error:
                    case CommonLicenseStateRepresentation.Unknown:
                        setStatusIcon(error);
                        break;
                    default:
                        setStatusIcon(null);
                }
            });
    };

    useEffect(() => {
        if (formats) {
            updateLicenseInfo();
        }
    }, [formats]);

    return (
        <Toolbar items={[
            {
                text: `${capitalize(lang.LICENSE_STATUS)}:`,
                type: TOOLBAR_ITEM_TYPE.text,
            }, {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => (
                    <SelectLink
                        contentIconBefore={statusIcon}
                        disabled={!dataIsLoaded}
                        renderContent={() => statusText}
                        renderModal={({ deactivate }) => (
                            <Dialog
                                actions={[
                                    {
                                        text: lang.CLOSE,
                                        view: ACTION_VIEW.primary,
                                        onClick: () => {
                                            deactivate();
                                        },
                                    },
                                ]}
                                hasTopPadding={false}
                                header={capitalize(lang.LICENSE_KEY)}
                                onRequestClose={deactivate}
                                size={DIALOG_SIZE.auto}

                            >
                                <StackView direction={STACK_DIRECTION.column}>
                                    <LicenseKeyToolbar
                                        updateLicenseInfo={setLicenseInfo}
                                        confirmFn={updateLicenseInfo}
                                        hideUpdate={hideUpdate}
                                    />

                                    <LicenseInfo info={licenseInfo} />
                                </StackView>
                            </Dialog>
                        )}
                    />
                ),
            },
        ]}
        />
    );
});
