/**
 * Copyright © Veeam Software Group GmbH.
 */

const HOURS = 24;
const DAYS = 7;

export function unflatScheduleMatrix(data: boolean[]): boolean[][] {
    const result: boolean[][] = [];

    for (let i = 0; i < DAYS; i++) {
        const window = i * HOURS;

        result.push(data.slice(window, window + HOURS));
    }

    return result;
}
