/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum RouteIds {
    // Home
    Overview = 'Overview',
    JobsSummary = 'JobsSummary',
    ActiveAlarms = 'ActiveAlarms',
    Tops = 'Tops',
    ProtectedDataSummary = 'ProtectedDataSummary',
    Trends = 'Trends',
    BackupJobs = 'BackupJobs',
    FailoverPlans = 'FailoverPlans',
    ProtectedData = 'ProtectedData',
    Companies = 'Companies',
    Resellers = 'Resellers',
    Rules = 'Rules',
    Discovery = 'Discovery',
    ManagedComputers = 'ManagedComputers',
    BillingSummary = 'BillingSummary',
    Invoices = 'Invoices',
    CompanyInvoices = 'CompanyInvoices',
    Reports = 'Reports',

    // Configs
    Welcome = 'Welcome',
    EventLog = 'EventLog',
    Security = 'Security',
    CloudConnectServers = 'CloudConnectServers',
    RolesUsers = 'RolesUsers',
    RolesUsersReseller = 'RolesUsersReseller',
    RolesUsersClient = 'RolesUsersClient',
    Notifications = 'Notifications',
    BackupPolicies = 'BackupPolicies',
    CompanyInfo = 'CompanyInfo',
    SubscriptionPlans = 'SubscriptionPlans',
    Settings = 'Settings',
    Licensing = 'Licensing',
    LicensingWithRenamedTabs = 'LicensingWithRenamedTabs',
    ProductUpdates = 'ProductUpdates',
    Support = 'Support',
    Locations = 'Locations',
    Alarms = 'Alarms',
    Plugins = 'Plugins',
    LicenseKeys = 'LicenseKeys',
    ConfigsCompanies = 'ConfigsCompanies',

    // Custom Pages
    Login = 'Login',
    AdjustReports = 'AdjustReports',
    BackupServerTerminal = 'BackupServerTerminal',
    Api = 'Api',
}
