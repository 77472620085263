/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { HttpResponseCodes } from '@veeam-vspc/shared/core';
import {
    LoginKeyIcon,
    AlternativeButton,
    LoginStartForm,
    immediatelyValidateFlag,
    useGlobalLang,
    useGlobalServices,
    loginStartFormInitialState,
} from '@veeam-vspc/shared/components';

import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { TokenManagerErrorResponse, TokenManagerResponse } from '@veeam-vspc/shared/services';
import type { LoginStartFormState } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';
import { resetFormState } from '../../utils';

import type { LoginStartFactor } from '../../interfaces';

export const LoginStart = observer(() => {
    const { tokenManagerService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const loginStore = useLoginStore();
    const { identityProviders } = loginStore.currentFactor as LoginStartFactor;
    const goToSsoButton = () => (
        <AlternativeButton
            iconBefore={<LoginKeyIcon />}
            disabled={loginStore.isLoading}
            // onClick={() => loginStore.onFinishFactorHandler({ goToSso: true })} // Uncomment for full SSO
            onClick={() => loginStore.onSsoClick()}
        >
            {lang.LOG_IN_WITH_SSO}
        </AlternativeButton>
    );

    return (
        <BaseFactor alternativeButton={(identityProviders && identityProviders.length > 0) && goToSsoButton()}>
            <LoginStartForm
                title={lang.PLEASE_LOG_IN}
                error={loginStore.error}
                warning={loginStore.warning}
                info={loginStore.info}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={({ login, password }: LoginStartFormState) => {
                    const trimmedLogin = login.trim();
                    loginStore.setIsLoading(true);

                    tokenManagerService.login(trimmedLogin, password)
                        .then((resp: TokenManagerResponse) => {
                            loginStore.onFinishFactorHandler({ ...resp, login: trimmedLogin });
                            resetFormState(loginStartFormInitialState);
                        })
                        .catch((err: TokenManagerErrorResponse | RequestErrorResponse) => {
                            const { errors } = err as RequestErrorResponse;
                            const throttlingError = errors?.[0]?.code === HttpResponseCodes.TooManyRequests
                                ? lang.CANNOT_COMPLETE_THE_LOGIN_OPERATION
                                : null;
                            const { error, errorDescription } = err as TokenManagerErrorResponse;
                            const isInvalidRequest = error === 'invalid_request';

                            loginStore.setIsLoading(false);
                            loginStore.setError(isInvalidRequest
                                ? lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT
                                : errorDescription || throttlingError || lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT);
                        });
                }}
                texts={{
                    placeholderLogin: lang.USERNAME_TEXTFIELD_PLACEHOLDER,
                    placeholderPassword: lang.ENTER_PASSWORD,
                    actionLogIn: lang.LOG_IN,
                    actionForgotPassword: lang.FORGOT_PASSWORD,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    warningCapsLockOn: lang.CAPS_LOCK_IS_ON,
                }}
                onForgotPasswordClick={() => loginStore.onFinishFactorHandler({ goToResetPassword: true })}
                customLoginValidation={(value: string) => {
                    if (value.indexOf('@') !== -1) {
                        return `${lang.SPECIFIED_USERNAME_FORMAT_IS_NOT_SUPPORTED}${immediatelyValidateFlag}`;
                    }
                }}
            />
        </BaseFactor>
    );
});
