/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { IdentityProviderRoleMappingRuleRole } from '@veeam-vspc/models/rest';
import { usePageWizardStore, LinkButton, Text, useGlobalLang } from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { ManagedCompaniesMode } from '../../../SPSSORule/enums';
import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';

import type { SSORuleModel } from 'views/pages/RolesAndUsersPage/components/Base/interfaces/sso-rule-model';


const Companies = (wizardStepData: WizardStepData<SSORuleModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore<SSORuleModel>();
    const { data, onDataChange, validationState } = wizardStepData;
    const companiesCount = data.model.managedCompaniesUids.length;

    const showPanel = () => {
        wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide} />);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.model.managedCompaniesUids.length === 0) {
                showPanel();
            }
        }
    });

    return (
        <StepLayout
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: lang.ALL_COMPANIES_INCLUDING_NEWLY_ADDED,
                        value: ManagedCompaniesMode.AllCompanies,
                    },
                    {
                        render: () => (
                            <>
                                <Text>
                                    {`${lang.SELECTED_COMPANIES_ONLY} ${companiesCount > 0 ? `(${companiesCount})` : ''}`}
                                </Text>

                                <LinkButton
                                    onClick={showPanel}
                                    disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                >
                                    {`(${lang.CONFIGURE})`}
                                </LinkButton>
                            </>
                        ),
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: wizardStepData => <Companies {...wizardStepData} />,
    isHidden: ({ data }) => {
        const rolesEnum = IdentityProviderRoleMappingRuleRole;
        return !([rolesEnum.ResellerOperator, rolesEnum.ResellerUser].includes(data.model.role));
    },
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.model.managedCompaniesUids.length === 0) {
            return false;
        }

        return true;
    },
    id: 'companies',
});


