/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { colors } from '@veeam-vspc/shared/components';
import { merge } from 'lodash';
import { renderToString } from 'react-dom/server';

import type * as Highcharts from 'highcharts';

import { Row, Tooltip } from '../components/Tooltip';
import { getBaseOptions } from './get-base-options';

import type { CustomParams } from '../interfaces';

export const getLineOptions = ({
    spacingBottom = false,
    widgetBgColor = colors.G000,
    ...rest
}: CustomParams): Highcharts.Options => {
    const baseOptions = getBaseOptions(rest);

    return merge(baseOptions, {
        chart: {
            spacing: [8, 8, spacingBottom ? 36 : 0, 8], // 36 = 32 (legend height) + 4 (legend margin)
            type: 'line',
        },

        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    fillColor: widgetBgColor,
                    lineColor: undefined,
                    lineWidth: 2,
                    radius: 3,
                    symbol: 'circle',

                    states: {
                        hover: {
                            lineWidthPlus: 2,
                            radiusPlus: -1,
                        },
                    },
                },

                point: {
                    events: {
                        mouseOver: function() {
                            this.series['halo'].attr({
                                stroke: this.series['color'],
                            });
                        },
                    },
                },

                states: {
                    hover: {
                        lineWidthPlus: 0,

                        halo: {
                            size: 6,
                            opacity: 1,

                            attributes: {
                                fill: widgetBgColor,
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                'stroke-width': 1,
                            },
                        },
                    },
                },
            },
        },

        tooltip: {
            formatter: function() {
                return renderToString(
                    <Tooltip title={this.x}>
                        {this.points.map((point, i) => (
                            <Row
                                color={point.color as string}
                                key={i}
                                name={point.series.name}
                                symbol={'ring'}
                                value={point.y}
                            />
                        ))}
                    </Tooltip>
                );
            },
        },

        xAxis: {
            crosshair: {
                color: colors.G400,
                width: 1,
            },
        },
    });
};
