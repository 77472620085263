/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

class ExtRouteProviderStore {

    @observable pageName: string;
    @observable pageProps: unknown;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    update(pageName: string, pageProps: unknown) {
        this.pageName = pageName;
        this.pageProps = pageProps;
    }

}

export type ExtRouteProviderStoreType = typeof extRouteProviderStore;

export const extRouteProviderStore = new ExtRouteProviderStore();
