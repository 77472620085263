/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Combobox } from '@veeam/components';
import {
    ACTION_VIEW,
    DIALOG_SIZE,
    Icon,
    LinkButton,
    SelectLink,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    ConfiguredGrid,
    useModal,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { Vb365JobItemComposedItemType, Vb365OrganizationBaseProtectedServices } from '@veeam-vspc/models/rest';

import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { Vb365JobItemGroup, Vb365JobItemSite, Vb365JobItemTeam, Vb365JobItemUser, Vb365JobItemComposed } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import { resourceTypes, resourceValueGetter, sortResources } from '../../helpers';
import excludedObjectsIconSrc from 'images/actions/exluded-objects.png';
import { UsersModal } from '../UsersModal/UsersModal';
import { ObjectCell } from '../ObjectCell/ObjectCell';
import { OptionsCell } from '../OptionsCell/OptionsCell';
import { GroupsModal } from '../GroupsModal/GroupsModal';
import { SitesModal } from '../SitesModal/SitesModal';
import { TeamsModal } from '../TeamsModal/TeamsModal';
import { ProcessingOptions } from '../ProcessingOptions/ProcessingOptions';
import { BackupOptionsToolbar } from '../BackupOptionsToolbar/BackupOptionsToolbar';
import { BackupOptionsTarget } from '../../../../enums';
import { ObjectTypeCell } from '../ObjectTypeCell/ObjectTypeCell';

import type { ConfigurableOption, ConfigureExcludeObjectsProps } from '../../interfaces';

export const ConfigureExcludeObjects: FC<ConfigureExcludeObjectsProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemComposed, unknown, unknown>>();
    const [itemType, setItemType] = useState<Vb365JobItemComposedItemType>(Vb365JobItemComposedItemType.User);

    const [optionsModal, { activate: activateOptionsModal }] = useModal<Vb365JobItemComposed[]>({
        render: ({ deactivate, data }) => {
            if (!data) {
                return;
            }

            let tree: Partial<Record<ConfigurableOption, ConfigurableOption[]>>;
            switch (data[0].itemType) {
                case Vb365JobItemComposedItemType.Group:
                    tree = {
                        backupMembers: ['backupMemberMailbox', 'backupMemberArchiveMailbox', 'backupMemberOneDrive', 'backupMemberSite'],
                    };
                    break;
                case Vb365JobItemComposedItemType.PartialOrganization:
                    tree = {
                        backupTeams: ['backupTeamsChats'],
                    };
                    break;
                default:
                    tree = {};
            }

            const heights = {
                [Vb365JobItemComposedItemType.Group]: DIALOG_SIZE.l,
                [Vb365JobItemComposedItemType.User]: DIALOG_SIZE.s,
                [Vb365JobItemComposedItemType.PartialOrganization]: DIALOG_SIZE.m,
            };

            return (
                <ProcessingOptions
                    onClose={deactivate}
                    onSave={saveOptions}
                    resources={data}
                    store={wizardStore}
                    branches={tree}
                    height={heights[data[0].itemType]}
                />
            );
        },
    });

    const saveOptions = (resources: Vb365JobItemComposed[]) => {
        wizardStore.updateResources(resources, BackupOptionsTarget.ExcludedItems);
    };

    const handleSelectItems = (
        selected?: Array<Vb365JobItemGroup | Vb365JobItemTeam | Vb365JobItemSite | Vb365JobItemUser>,
        deactivate?: () => void,
    ) => {
        switch (itemType) {
            case Vb365JobItemComposedItemType.User:
                wizardStore
                    .addUsersToResources(selected as Vb365JobItemUser[], BackupOptionsTarget.ExcludedItems);
                break;
            case Vb365JobItemComposedItemType.Group:
                wizardStore
                    .addGroupsToResources(selected as Vb365JobItemGroup[], BackupOptionsTarget.ExcludedItems);
                break;
            case Vb365JobItemComposedItemType.Team:
                wizardStore
                    .addTeamsToResources(selected as Vb365JobItemTeam[], BackupOptionsTarget.ExcludedItems);
                break;
            case Vb365JobItemComposedItemType.Site:
                wizardStore
                    .addSitesToResources(selected as Vb365JobItemSite[], BackupOptionsTarget.ExcludedItems);
                break;
            case Vb365JobItemComposedItemType.PartialOrganization:
                wizardStore
                    .addCurrentOrganizationToResources(BackupOptionsTarget.ExcludedItems);
                break;
            case Vb365JobItemComposedItemType.PersonalSites:
                wizardStore
                    .addPersonalSiteToResources(BackupOptionsTarget.ExcludedItems);
                break;
        }

        deactivate && deactivate();
        api.current?.fetchData();
    };

    const handleDeleteItems = (items: Vb365JobItemComposed[]) => {
        wizardStore.removeResourcesFromExcludedResources(items);
        api.current?.reloadGrid();
    };

    const showOptionsModal = (resources: Vb365JobItemComposed[]) => {
        if (!resources || !resources.length) {
            return;
        }

        const observableResources = resources.map(r => wizardStore.excludedItems.find(x => r.id === x.id));

        activateOptionsModal(observableResources);
    };

    const getItemName = (x: Vb365JobItemComposed) => {
        if (x.itemType === Vb365JobItemComposedItemType.PersonalSites) {
            return lang.PERSONAL_SITES;
        }

        if (x.itemType === Vb365JobItemComposedItemType.PartialOrganization) {
            return wizardStore.selectedOrganization.name;
        }

        return resourceValueGetter(x, 'name', wizardStore);
    };

    const fetchData = (
        { page, limit, sort, filter }: RequestParams<Vb365JobItemComposed & { name: string; }>,
    ): Promise<RequestSuccessResponse<Vb365JobItemComposed[]>> => {
        let items = wizardStore.excludedItems.map((x) => {
            if (x.itemType === Vb365JobItemComposedItemType.PersonalSites) {
                return { ...x, name: lang.PERSONAL_SITES };
            }

            if (x.itemType === Vb365JobItemComposedItemType.PartialOrganization) {
                return { ...x, name: wizardStore.selectedOrganization.name };
            }

            return x;
        });

        if (sort && sort.length) {
            sortResources(items, sort, wizardStore);
        }

        items = items
            .filter(x => getItemName(x)
                .toLowerCase()
                .includes((filter.name || '').trim().toLowerCase()),
            );

        const chunk = items.slice((page * limit - limit), page * limit);

        return Promise.resolve({
            data: chunk,
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as RequestSuccessResponse<Vb365JobItemComposed[]>);
    };

    const handleApply = () => {
        wizardStore.updateExcludedItems();
        hidePanel();
    };

    const handleCancel = () => {
        wizardStore.cancelExcludedItems();
        hidePanel();
    };

    const couldBeSitesSelected = () => wizardStore.selectedOrganization.protectedServices
        .includes(Vb365OrganizationBaseProtectedServices.SharePointOnlineAndOneDriveForBusiness)
        || wizardStore.selectedOrganization.protectedServices
            .includes(Vb365OrganizationBaseProtectedServices.MicrosoftSharePointServer);

    const isAddButtonDisabledForPersonalSites = itemType === Vb365JobItemComposedItemType.PersonalSites &&
        wizardStore[BackupOptionsTarget.ExcludedItems].findIndex(e => e.itemType === Vb365JobItemComposedItemType.PersonalSites) !== -1;

    return (
        <SidePanelForm
            title={lang.EXCLUSIONS}
            description={lang.SPECIFY_OBJECTS_TO_EXCLUDE_FROM_THE_BACKUP}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxxl} spaceFill={SPACE_FILL.all}>
                <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xl}>
                    <Combobox
                        value={itemType}
                        onChange={setItemType}
                        data={resourceTypes(lang).filter((x) => {
                            if (x.value === Vb365JobItemComposedItemType.PartialOrganization) {
                                return false;
                            }

                            if (
                                x.value === Vb365JobItemComposedItemType.PersonalSites &&
                                wizardStore.selectedItems.find(x => x.itemType === Vb365JobItemComposedItemType.PersonalSites)
                            ) {
                                return false;
                            }

                            if (
                                x.value === Vb365JobItemComposedItemType.Team &&
                                !wizardStore.selectedOrganization.protectedServices
                                    .includes(Vb365OrganizationBaseProtectedServices.MicrosoftTeams)
                            ) {
                                return false;
                            }

                            if (x.value === Vb365JobItemComposedItemType.Site || x.value === Vb365JobItemComposedItemType.PersonalSites) {
                                return couldBeSitesSelected();
                            }

                            return true;
                        })}
                        valueGetter={x => x.value}
                        textGetter={x => x.text}
                    />

                    {
                        itemType === Vb365JobItemComposedItemType.PersonalSites
                            ? (
                                <LinkButton
                                    onClick={() => handleSelectItems()}
                                    disabled={isAddButtonDisabledForPersonalSites}
                                >
                                    {lang.ADD}
                                </LinkButton>
                            )
                            : (
                                <SelectLink
                                    renderModal={({ deactivate }) => {
                                        if (itemType === Vb365JobItemComposedItemType.User) {
                                            return (
                                                <UsersModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposedItemType.Group) {
                                            return (
                                                <GroupsModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposedItemType.Site) {
                                            return (
                                                <SitesModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                    target={BackupOptionsTarget.ExcludedItems}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposedItemType.Team) {
                                            return (
                                                <TeamsModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }
                                    }}
                                    renderContent={() => lang.BROWSE}
                                />
                            )
                    }
                </StackView>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} spaceFill={SPACE_FILL.all}>
                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                        <Icon src={excludedObjectsIconSrc} />
                        <Text>{`${lang.EXCLUDED_OBJECTS} (${wizardStore.excludedItems.length})`}</Text>
                    </StackView>

                    <ConfiguredGrid
                        api={api}
                        columns={[
                            { id: 'name', title: lang.OBJECT, cell: rowData => <ObjectCell rowData={rowData} wizardStore={wizardStore} /> },
                            { id: 'itemType', title: lang.TYPE, cell: rowData => <ObjectTypeCell value={rowData} /> },
                            {
                                id: 'options',
                                title: lang.PROCESSING_OPTIONS,
                                cell: rowData => (
                                    <OptionsCell
                                        rowData={rowData}
                                        store={wizardStore}
                                        collection={BackupOptionsTarget.ExcludedItems}
                                        onClick={() => showOptionsModal([rowData])}
                                    />
                                ),
                            },
                        ]}
                        data={fetchData}
                        disableAutoUpdate
                        selection={{
                            field: 'id',
                            checkbox: true,
                            multiple: true,
                        }}
                        toolbars={[
                            () => (
                                <BackupOptionsToolbar
                                    onEdit={showOptionsModal}
                                    onRemove={handleDeleteItems}
                                    viewType={BackupOptionsTarget.ExcludedItems}
                                />
                            ),
                        ]}
                    />
                </StackView>
            </StackView>

            {optionsModal}
        </SidePanelForm>
    );
});
