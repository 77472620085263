/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ProtectedVirtualMachineRestoreType } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

const {
    Backup,
    Copy,
    Replica,
    CdpReplica,
    Tape,
} = ProtectedVirtualMachineRestoreType;

const getDialogTitle = (type: ProtectedVirtualMachineRestoreType, lang: VspcLang): string => {
    let title = '';

    switch (type) {
        case (Backup):
        case (Copy):
            title = lang.BACKUPS;
            break;
        case (Replica):
            title = lang.REPLICAS;
            break;
        case (CdpReplica):
            title = lang.CDP_REPLICAS;
            break;
        case (Tape):
            title = lang.BACKUPS_ON_TAPE;
            break;
        default:
            title = 'Wrong restore type';
            break;
    }

    return title;
};

const getDestinationColumnHeader = (type: ProtectedVirtualMachineRestoreType, lang: VspcLang): string => {
    let title = '';

    switch (type) {
        case (Backup):
        case (Copy):
            title = lang.REPOSITORY;
            break;
        case (Replica):
            title = lang.TARGET_HOST;
            break;
        case (CdpReplica):
            title = lang.TARGET;
            break;
        case (Tape):
            title = lang.MEDIA_POOL;
            break;
        default:
            title = 'Wrong restore type';
            break;
    }

    return title;
};

const getJobNameColumnHeader = (type: ProtectedVirtualMachineRestoreType, lang: VspcLang): string => {
    let title = '';

    switch (type) {
        case (CdpReplica):
            title = capitalize(lang.POLICY_NAME);
            break;
        default:
            title = lang.JOB;
            break;
    }

    return title;
};

const getSearchFieldPlaceholder = (type: ProtectedVirtualMachineRestoreType, lang: VspcLang): string => {
    let title = '';

    switch (type) {
        case (CdpReplica):
            title = lang.POLICY;
            break;
        default:
            title = lang.JOB;
            break;
    }

    return title;
};

export {
    getDialogTitle,
    getDestinationColumnHeader,
    getJobNameColumnHeader,
    getSearchFieldPlaceholder,
};
