/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants, Text, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

const HelpStyled = styled.div<{ isHelpVisible: boolean; }>`
  background-color: ${colors.Y50};
  width: 600px;
  display: ${props => props.isHelpVisible ? 'block' : 'none'};
  margin-bottom: ${constants.SPACING_S};
  padding: ${constants.SPACING_S};
`;

export const Help = ({ install, isHelpVisible }: { install: boolean; isHelpVisible: boolean; }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <HelpStyled isHelpVisible={isHelpVisible}>
            <Text>{`${lang.CONFIGURATION_FILE_CONTAINS}:`}</Text>
            <br />
            <b>{lang.ACCEPT_EULA}</b>
            {` - ${lang.SPECIFY_1_TO_ACCEPT_VEEAM_LICENSE_AGREEMENT}.`}
            <br />
            <b>{lang.ACCEPT_LICENSING_POLICY}</b>
            {` - ${lang.SPECIFY_1_TO_ACCEPT_THE_LICENSING_POLICY}.`}
            <br />
            <b>{lang.ACCEPT_THIRDPARTY_LICENSES}</b>
            {` - ${lang.SPECIFY_1_TO_ACCEPT_THE_LICENSE_AGREEMENT_FOR_3RD}.`}
            <br />
            <b>{lang.ACCEPT_REQUIRED_SOFTWARE}</b>
            {` - ${lang.SPECIFY_1_TO_ACCEPT_ALL_REQUIRED_SOFTWARE_LICENSE}.`}
            <br />
            <b>{lang.VBR_LICENSE_FILE}</b>
            {` - ${lang.PATH_TO_THE_LICENSE_FILE}.`}
            <br />
            <b>{lang.VBR_LICENSE_AUTOUPDATE}</b>
            {` - ${lang.SPECIFY_1_TO_ENABLE_AUTOMATIC_LICENSE_UPDATE}.`}

            {
                install && (
                    <>
                        <br />
                        <b>{lang.VBR_SERVICE_USER}</b>
                        {` - ${lang.USER_ACCOUNT_UNDER_WHICH_THE_VEEAM}.`}
                    </>
                )
            }

            <br />
            <b>{lang.VBR_SERVICE_PASSWORD}</b>
            {` - ${lang.PASSWORD_FOR_THE_ACCOUNT_UNDER}.`}

            {
                install && (
                    <>
                        <br />
                        <b>{lang.VBR_SQLSERVER_INSTALL}</b>
                        {` - ${lang.SPECIFY_0_TO_USE_AN_EXISTING_SQL}.`}
                        <br />
                        <b>{lang.VBR_SQLSERVER_ENGINE}</b>
                        {` - ${lang.SPECIFY_0_TO_USE_POSTGRESQL_ENGINE}.`}
                        <br />
                        <b>{lang.VBR_SQLSERVER_SERVER}</b>
                        {` - ${lang.SQL_SERVER_AND_INSTANCE_FOR_MICROSOFT_SQL}.`}
                        <br />
                        <b>{lang.VBR_SQLSERVER_DATABASE}</b>
                        {` - ${lang.CONFIGURATION_DATABASE_NAME}.`}
                        <br />
                        <b>{lang.VBR_SQLSERVER_AUTHENTICATION}</b>
                        {` - ${lang.SPECIFIES_AUTHENTICATION_MODE_TO_CONNECT}.`}
                        <br />
                        <b>{lang.VBR_SQLSERVER_USERNAME}</b>
                        {` - ${lang.LOGINID_TO_CONNECT_TO_THE_MICROSOFT_SQL}.`}
                    </>
                )
            }

            <br />
            <b>{lang.VBR_SQLSERVER_PASSWORD}</b>
            {` - ${lang.PASSWORD_TO_CONNECT_TO_THE_MICROSOFT_SQL}.`}

            {
                !install && (
                    <>
                        <br />
                        <b>{lang.VBR_AUTO_UPGRADE}</b>
                        {` - ${lang.SPECIFY_1_TO_ENABLE_AUTOMATIC_LICENSE_UPGRADE}.`}
                    </>
                )
            }

            {
                install && (
                    <>
                        <br />
                        <b>{lang.VBRC_SERVICE_PORT}</b>
                        {` - ${lang.TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_GUEST_CATALOG_SERVICE}.`}
                        <br />
                        <b>{lang.VBR_SERVICE_PORT}</b>
                        {` - ${lang.TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_BACKUP_SERVICE}.`}
                        <br />
                        <b>{lang.VBR_SECURE_CONNECTIONS_PORT}</b>
                        {` - ${lang.PORT_USED_FOR_COMMUNICATION_BETWEEN_THE_MOUNT_SERVER}.`}
                        <br />
                        <b>{lang.VBR_RESTSERVICE_PORT}</b>
                        {` - ${lang.PORT_USED_FOR_COMMUNICATION_WITH_REST}.`}
                        <br />
                        <b>{lang.INSTALLDIR}</b>
                        {` - ${lang.PATH_TO_THE_DIRECTORY_WHERE}.`}
                        <br />
                        <b>{lang.VM_CATALOGPATH}</b>
                        {` - ${lang.PATH_TO_THE_CATALOG_FOLDER}.`}
                        <br />
                        <b>{lang.VBR_IRCACHE}</b>
                        {` - ${lang.PATH_TO_THE_FOLDER_WHERE}.`}
                        <br />
                        <b>{lang.VBR_CHECK_UPDATES}</b>
                        {` - ${lang.SPECIFY_1_TO_AUTOMATICALLY_CHECK_FOR_NEW_PRODUCT}.`}
                        <br />
                        <b>{lang.AHV_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_NUTANIX_AHV}.`}
                        <br />
                        <b>{lang.RHV_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_NUTANIX_RHV}.`}
                        <br />
                        <b>{lang.AWS_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_AWS_PLUGIN}.`}
                        <br />
                        <b>{lang.AZURE_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_MICROSOFT_AZURE}.`}
                        <br />
                        <b>{lang.GCP_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_GOOGLE_CLOUD}.`}
                        <br />
                        <b>{lang.KASTEN_INSTALL}</b>
                        {` - ${lang.SPECIFY_1_TO_INSTALL_KASTEN}.`}
                    </>
                )
            }
        </HelpStyled>
    );
};


