/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum SizeUnits {
    B,
    KB,
    MB,
    GB,
    TB,
    PB,
    EB,
}
