/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { JobStateRepresentation, JobTargetKindInteg, VirtualPlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { ToolbarProps, ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import backupJobSTypeIconSrc from 'images/filters/job-stypes/backup-servers.svg';
import cloudConnectJobSTypeIconSrc from 'images/filters/job-stypes/cloud-connect.svg';
import vSpherePlatformTypeIconSrc from 'images/filters/virtual-platform-types/vsphere.png';
import hyperVPlatformTypeIconSrc from 'images/filters/os/windows.svg';
import nutanixPlatformTypeIconSrc from 'images/filters/virtual-platform-types/nutanix.png';
import otherPlatformTypeIconSrc from 'images/view_help.png';
import localTargetTypeIconSrc from 'images/filters/fplan-types/local.png';
import cloudTargetTypeIconSrc from 'images/filters/fplan-types/cloud.png';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useJobTypesAdvancedFilter } from 'views/components/filters/JobTypesFilters';
import { ConfigAdvancedFilterItemsIds, ConfigSectionIds } from 'core/enums';

export interface VirtualInfrastructureFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const VirtualInfrastructureFilterToolbar: React.FC<VirtualInfrastructureFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: JobStateRepresentation.Success },
        { title: lang.WARNING, value: JobStateRepresentation.Warning },
        { title: lang.FAILED, value: JobStateRepresentation.Failed },
        { title: lang.RUNNING, value: JobStateRepresentation.Running },
        {
            title: lang.INFORMATION,
            value: [
                JobStateRepresentation.None,
                JobStateRepresentation.Idle,
                JobStateRepresentation.Starting,
                JobStateRepresentation.Stopping,
                JobStateRepresentation.Enabling,
                JobStateRepresentation.Disabling,
                JobStateRepresentation.WaitingTape,
                JobStateRepresentation.WaitingRepository,
            ],
        },
    ];
    const jobTypesAdvancedFilter = useJobTypesAdvancedFilter();

    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_JOBS_BY}:`,
        cols: [
            {
                groups: [
                    jobTypesAdvancedFilter,
                ],
            },
            {
                groups: [
                    {
                        id: ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructureBackupServerType,
                        title: lang.BACKUP_SERVER_TYPE,
                        items: [
                            {
                                iconSrc: backupJobSTypeIconSrc,
                                label: lang.VEEAM_BACKUP_AND_REPLICATION,
                                value: {
                                    id: 1,
                                    name: 'serverTypes',
                                },
                            },
                            {
                                iconSrc: cloudConnectJobSTypeIconSrc,
                                label: lang.VEEAM_CLOUD_CONNECT,
                                value: {
                                    id: 2,
                                    name: 'serverTypes',
                                },
                            },
                        ],
                    },
                    {
                        id: ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructurePlatformType,
                        title: lang.PLATFORM_TYPE,
                        items: [
                            {
                                iconSrc: vSpherePlatformTypeIconSrc,
                                label: lang.VSPHERE,
                                value: {
                                    id: VirtualPlatformTypeRepresentation.VSphere,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                            {
                                iconSrc: hyperVPlatformTypeIconSrc,
                                label: lang.HYPER_V,
                                value: {
                                    id: VirtualPlatformTypeRepresentation.HyperV,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                            {
                                iconSrc: nutanixPlatformTypeIconSrc,
                                label: lang.AHV,
                                value: {
                                    id: VirtualPlatformTypeRepresentation.NutanixAHV,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                            {
                                iconSrc: otherPlatformTypeIconSrc,
                                label: lang.OTHER,
                                value: {
                                    id: VirtualPlatformTypeRepresentation.Unknown,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                        ],
                    },
                    {
                        title: lang.BACKUP_TARGET,
                        items: [
                            {
                                iconSrc: localTargetTypeIconSrc,
                                label: lang.LOCAL,
                                value: {
                                    id: JobTargetKindInteg.Local,
                                    name: 'targetKind',
                                },
                            },
                            {
                                iconSrc: cloudTargetTypeIconSrc,
                                label: lang.OFFSITE,
                                value: {
                                    id: JobTargetKindInteg.Cloud,
                                    name: 'targetKind',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='serverName'
                            placeholder={lang.SERVER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                            sectionId={ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructureAdvancedFilter}
                        />
                    ),
                },
            ]}
        />
    );
};
