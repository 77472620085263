/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    LinkButton,
    StackView,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    Text,
    TEXT_WHITE_SPACE,
    Tooltip,
    usePageWizardStore,
    useGlobalLang,
    NoteBar,
    SPACING_L,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { Vb365BackupJob } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { useJobWizardStore } from '../../stores';
import { AvailableRepositories } from './components/AvailableRepositories/AvailableRepositories';
import { usedSpaceMessage } from '../../../../helpers';

import type { JobWizardStore } from '../../stores';

export const getBackupRepositoryStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<Vb365BackupJob> => ({
    title: capitalize(lang.BACKUP_REPOSITORY),
    validate: () => {
        const validationResult = Boolean(store.selectedBackupRepository);

        if (validationResult === false) {
            notificationService.warning(capitalize(lang.BACKUP_REPOSITORY), lang.SELECT_A_BACKUP_REPOSITORY_TO_PROCEED);
        }

        return validationResult;
    },
    isHidden: () => store.backupRepositories.length === 1,
    render: data => <BackupRepository {...data} />,
});

const BackupRepository = observer((wizardStepData: WizardStepData<Vb365BackupJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const wizardContext = usePageWizardStore();

    return (
        <StepLayout
            title={capitalize(lang.BACKUP_REPOSITORY)}
            description={lang.SELECT_BACKUP_REPOSITORY}
        >
            <StackView
                direction={STACK_DIRECTION.row}
                align={STACK_ALIGN.center}
                gap={STACK_GAP.m}
            >
                <Text whitespace={TEXT_WHITE_SPACE.nowrap}>{`${lang.BACKUP_REPOSITORY}:`}</Text>

                <Tooltip<HTMLDivElement>
                    delayTimeout={500}
                    render={props => (
                        <LinkButton
                            onClick={() => wizardContext
                                .openPanel(hide => <AvailableRepositories hidePanel={hide} />)}
                            error={!wizardStepData.validationState.isCommon() && !wizardStore.selectedOrganization}
                            style={{ flexShrink: 1 }}
                        >
                            {wizardStore.selectedBackupRepository?.name || lang.SELECT}
                        </LinkButton>
                    )}
                    text={wizardStore.selectedBackupRepository?.name || lang.SELECT}
                />
            </StackView>

            <Text>{`${capitalize(lang.USED_SPACE, true)}: ${usedSpaceMessage(lang, wizardStore.selectedBackupRepository)}`}</Text>

            {
                wizardStepData.isEdit && (
                    <NoteBar style={{ marginTop: SPACING_L }}>
                        <Text>{lang.IF_THE_BACKUP_JOB_HAS}</Text>
                    </NoteBar>
                )
            }
        </StepLayout>
    );
});
