/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    PortalSpinner,
    TextColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { Limits } from '@veeam-vspc/shared/core';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type {
    CloudConnectServerWithDeployInfo,
    GetVspcSitesParam,
    PublicCloudAvailableVbJson,
    PublicCloudAvailableVbModel,
    SiteModel,
} from '@veeam-vspc/models/web-controllers';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useTransportRequest } from 'views/hooks';
import { SelectVbFilterToolbar } from './components';


export interface SelectVbDialogProps {
    onClose: () => void;
    onSelect: (vbItemId: PublicCloudAvailableVbModel) => void;
}

export const SelectVbDialog: React.FC<SelectVbDialogProps> = ({ onSelect, onClose }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const {
        loading,
        data: cloudConnectServers,
    } = useTransportRequest<GetVspcSitesParam, SiteModel[]>('/VbPlugin/GetSites', {
        start: 0,
        limit: Limits.MaxInteger32,
        sort: [{ property: 'siteName', direction: GridSortDirections.Asc }],
    });
    const [selectedVb, setSelectedVb] = useState<PublicCloudAvailableVbModel>(null);
    const api = useRef<GridStore<PublicCloudAvailableVbModel, unknown, PublicCloudAvailableVbJson['filter']>>();
    const [selectedAgentGuid, setSelectedAgentGuid] = useState<string>(null);

    useEffect(() => {
        const agentGuid = selectedAgentGuid ?? null;

        setSelectedAgentGuid(agentGuid);
        api.current?.applyFilters({
            agentGuid,
        });
    }, [selectedAgentGuid]);

    return (
        <Dialog
            header={lang.CREATE_POLICY}
            title={lang.SELECT_A_CLOUD_APPLIANCE_TO_CREATE}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xxl,
            }}
            onRequestClose={onClose}
            actions={[
                {
                    text: lang.CREATE,
                    onClick: () => onSelect(selectedVb),
                    disabled: !selectedVb,
                },
                { text: lang.CLOSE, onClick: onClose },
            ]}
        >
            {loading && <PortalSpinner whiteBg />}

            {!loading && (
                <ConfiguredGrid
                    initialState={{
                        sort: {
                            key: 'hostName',
                            direction: GridSortDirections.Asc,
                        },
                    }}
                    columns={[
                        {
                            id: 'hostName',
                            title: lang.HOSTNAME,
                            cell: rowData => rowData.hostName,
                        },
                        {
                            id: 'companyName',
                            title: lang.COMPANY,
                            cell: rowData => rowData.companyName,
                        },
                        {
                            id: 'dnsName',
                            title: lang.ADDRESS,
                            cell: rowData => rowData.dnsName,
                        },
                        {
                            id: 'platform',
                            title: lang.PLATFORM,
                            cell: rowData => (
                                <TextColumn>
                                    {portalEnums.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platform)}
                                </TextColumn>
                            ),
                        },
                    ]}
                    data={(requestParams: PublicCloudAvailableVbJson) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<PublicCloudAvailableVbModel[]>>(
                            '/VbPlugin/GetAvailableVb',
                            requestParams,
                        )}
                    toggleable={false}
                    api={api}
                    selection={{
                        field: 'serverTag',
                        checkbox: false,
                        multiple: false,
                    }}
                    onSelectionChange={(selected: PublicCloudAvailableVbModel[]) => {
                        setSelectedVb(selected[0]);
                    }}
                    toolbars={[
                        () => (
                            <SelectVbFilterToolbar
                                cloudConnectServers={cloudConnectServers as CloudConnectServerWithDeployInfo[] ?? []}
                                selectedAgentGuid={selectedAgentGuid}
                                onChangeAgentGuid={(newAgentGuid: string) => {
                                    setSelectedAgentGuid(newAgentGuid);
                                }}
                            />
                        ),
                    ]}
                />
            )}
        </Dialog>
    );
};
