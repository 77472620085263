/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationSettingsLevel } from '@veeam-vspc/models/rest';

import { NotificationLevel } from '../enums';

export const getLevelForForm = (formValue: NotificationLevel): NotificationSettingsLevel => {
    switch (formValue) {
        case NotificationLevel.EveryEvent:
            return NotificationSettingsLevel.All;

        case NotificationLevel.Summary:
            return NotificationSettingsLevel.Summary;

        case NotificationLevel.NoEmails:
        default:
            return NotificationSettingsLevel.Disabled;
    }
};
