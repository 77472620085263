/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { constants, Spinner, STACK_DIRECTION, StackView, useGlobalLang } from '@veeam-vspc/shared/components';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { CloudWorkload } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { BillingWidget } from '../../../../BillingWidget';
import { EmptyPlate, SpinnerPlateStyled } from 'views/components/ChartWidget/components';
import { RowStyled, ValueCellStyled } from './components';
import { transformData } from './helpers';
import { useIntervalRequest } from 'views/hooks';

import type { TableData } from './interfaces';

export const StoredWorkloads = observer(() => {
    const abortController = useRef(new AbortController());
    const didMount = useRef(false);
    const lang = useGlobalLang<VspcLang>();

    const request = useIntervalRequest<{}, CloudWorkload[]>(
        '/Dashboard/GetCloudStoredWorkloads',
        {},
        {
            requestInit: {
                signal: abortController.current.signal,
            },
        },
        5 * TimeUnitsInMs.Minute,
    );

    const { data, empty } = request.data
        ? transformData(request.data as CloudWorkload[])
        : {} as TableData;

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();
            abortController.current = new AbortController();

            return request.forceRequest();
        }

        didMount.current = true;
    }, []);

    return (
        <BillingWidget title={{ text: lang.STORED_WORKLOADS }}>
            <StackView
                direction={STACK_DIRECTION.column}
                style={{ flex: 1 }}
            >
                {empty && <EmptyPlate emptyText={lang.NO_DATA_AVAILABLE} emptyDesc={lang.PLEASE_START_USING_VCC_BACKUP_JOBS} />}

                {request.loading && (
                    <SpinnerPlateStyled>
                        <Spinner size={48} style={{ marginTop: `-${constants.SPACING_XXL}` }} />
                    </SpinnerPlateStyled>
                )}

                {!empty && !request.loading && data?.map(({ name, value }, i) => (
                    <RowStyled key={i}>
                        <span>{`${name}:`}</span>
                        <ValueCellStyled>{value}</ValueCellStyled>
                    </RowStyled>
                ))}
            </StackView>
        </BillingWidget>
    );
});

