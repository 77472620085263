/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    DatePeriod,
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { CompanyTypeRepresentation, InvoicePaidState } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { DatePeriodValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import paidSrc from 'images/statuses/paid.png';
import unpaidSrc from 'images/statuses/unpaid.svg';
import overdueSrc from 'images/statuses/overdue.svg';
import myPlansSrc from 'images/filters/subscription-plans/my_plans.png';
import resellerItemSrc from 'images/reseller_item.png';
import { getDatePeriod } from 'core/utils/date-helpers';
import { ConfigActionIds, ConfigSectionIds } from 'core/enums';

export const AllInvoicesFilterToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { configuratorService } = useGlobalServices();
    const gridApi = useGridApi<unknown, unknown, { dateFrom: Date; dateTo: Date; }>();
    const { dateFrom, dateTo } = gridApi.filters;
    const dateValue: DatePeriodValue = dateFrom || dateTo ? [new Date(gridApi.filters.dateFrom), new Date(gridApi.filters.dateTo)] : [];
    const { formats } = useGlobalAppData();

    const statuses = [
        { title: lang.PAID, value: InvoicePaidState.Paid, iconSrc: paidSrc },
        { title: lang.UNPAID, value: InvoicePaidState.Unpaid, iconSrc: unpaidSrc },
        { title: lang.OVERDUE, value: InvoicePaidState.Overdue, iconSrc: overdueSrc },
        { title: lang.INFORMATION, value: InvoicePaidState.Unknown },
    ];
    const types = [
        { title: lang.MY, value: [CompanyTypeRepresentation.Provider, CompanyTypeRepresentation.Tenant], iconSrc: myPlansSrc },
        { title: lang.RESELLER, value: CompanyTypeRepresentation.Reseller, iconSrc: resellerItemSrc },
    ];

    const allInvoicesSectionId = ConfigSectionIds.InvoicesAllInvoices;
    const companySearchFieldId = ConfigActionIds.FilterToolbarCompanySearch;
    const sectionConfig = configuratorService?.getSectionConfig(allInvoicesSectionId);

    const companySearchIsHidden = sectionConfig
        ? sectionConfig.getActionState(companySearchFieldId) === ConfigStates.Hidden
        : false;

    return (
        <ConfiguredToolbar
            sectionId={allInvoicesSectionId}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    id: companySearchFieldId,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='companyName'
                            placeholder={lang.COMPANY}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarStatus,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            style={companySearchIsHidden ? { paddingLeft: 0 } : {}}
                            label={lang.STATUS}
                            statuses={statuses}
                            propName={'statuses'}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarType,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.TYPE}
                            statuses={types}
                            propName={'type'}
                        />
                    ),
                },
                {
                    id: ConfigActionIds.FilterToolbarDatePeriod,
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <DatePeriod
                            format={formats.netShortDate}
                            value={dateValue}
                            onChange={value => gridApi.applyFilters(getDatePeriod(value))}
                        />
                    ),
                },
            ].filter(item => item)}
        />
    );
};
