/**
 * Copyright © Veeam Software Group GmbH.
 */


import { createContext } from 'react';

import type { PeriodsStore } from '../stores';

export const PeriodsContext = createContext<PeriodsStore>({} as PeriodsStore);
