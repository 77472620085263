/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/shared/components';

export const PlateDescriptionStyled = styled.div`
    border-top: 1px solid ${colors.G300};
    padding-top: ${constants.SPACING_M};
`;
