/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    TextInput,
    useExternalFormApi,
    useWizardStore,
} from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { UserData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { useEditUserProfileStore } from '../../stores';
import { extjsRegExpValidate } from 'core/utils/validators';
import { REG_EXP } from 'core/const';
import { EditUserProfileStepIds } from '../../enums';

interface UserInfoStepProps {
    lang: VspcLang;
    stepModel: WizardStepData<UserData>;
}

const formValidation = (data: UserData, lang: VspcLang) => {
    const validator = new FormValidator(data);

    const defaultNameLightValidator = (value: string): string =>
        extjsRegExpValidate(value, REG_EXP.defaultNameLight, lang.INVALID_CHARACTER);

    validator.validate('firstName')
        .string()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('lastName')
        .string()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('email')
        .string()
        .maxLength(128)
        .check(v => extjsRegExpValidate(v, REG_EXP.email, lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS));

    return validator.result();
};

const stepValidation = (data: UserData, lang: VspcLang) => isValid(value => formValidation(value, lang), data);

const UserInfoStep: React.FC<UserInfoStepProps> = observer(({ lang, stepModel }) => {
    const { data, validationState } = stepModel;
    const formApi = useExternalFormApi<UserData>();
    const editUserProfileStore = useEditUserProfileStore();
    const defaultFieldWidth = CONTROL_SIZE.m;
    const store = useWizardStore();

    editUserProfileStore.shouldConfigureMfa && store.setStepId(EditUserProfileStepIds.MfaStep);

    return (
        <Form
            value={data}
            externalFormApi={formApi}
            validate={data => formValidation(data, lang)}
            validationState={validationState}
        >
            <FormLayout inlineLabel>
                <Combobox
                    data={editUserProfileStore.userTitles}
                    textGetter={title => title.name}
                    valueGetter={title => title.id}
                    name={'userTitle'}
                    label={lang.TITLE}
                    size={defaultFieldWidth}
                />

                <TextInput
                    name={'firstName'}
                    label={lang.FIRST_NAME}
                    size={defaultFieldWidth}
                />

                <TextInput
                    name={'lastName'}
                    label={lang.LAST_NAME}
                    size={defaultFieldWidth}
                />

                <TextInput
                    name={'email'}
                    label={lang.EMAIL_ADDRESS}
                    size={defaultFieldWidth}
                />
            </FormLayout>
        </Form>
    );
});

export const getUserInfoStep = (lang: VspcLang): WizardStep<UserData> => ({
    title: lang.USER_INFO,
    description: lang.ENTER_USERNAME_AND_CONTACT_INFORMATION,
    validate: ({ data }) => stepValidation(data, lang),
    render: stepModel => <UserInfoStep stepModel={stepModel} lang={lang} />,
});
