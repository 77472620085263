/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum RoutePaths {
    Login = 'login',

    Home = 'home',

    Configs = 'config',

    Plugins = 'plugins',

    AdjustReports = 'config/adjustReports',
    BackupServerTerminal = 'backupServer/terminal',

    NotFound = '/errors/404.html',

    Api = 'api',
}
