/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalServices } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { ProtectedObjectStorage, ProtectedObjectStoragesFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams } from '@veeam-vspc/shared/components';

import { DataBackupObjectStorageFilterToolbar } from './components';
import { ConfigSectionIds } from 'core/enums';
import { useColumns } from './hooks';

const GRID_DATA_METHOD = 'GetObjectStorages';
const GRID_DATA_URL = `/ProtectedWorkloads/${GRID_DATA_METHOD}`;

export const DataBackupObjectStorage: React.FC<{}> = () => {
    const { transportService } = useGlobalServices();
    const { pathname } = useLocation();
    const gridStateId = `${pathname}.ObjectStorage`;
    const columns = useColumns(gridStateId);

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.ProtectedDataDataBackupObjectStorage}
            stateId={gridStateId}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: 'jobName',
                },
            }}
            defaultColumnMinWidth={100}
            columns={columns}
            data={(requestParams: RequestParams<ProtectedObjectStoragesFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<ProtectedObjectStorage[]>>(
                    `${GRID_DATA_URL}`,
                    { ...requestParams }
                )}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => (
                    <DataBackupObjectStorageFilterToolbar
                        idProperty={'instanceUid'}
                        method={GRID_DATA_METHOD}
                        selectedOnly
                    />
                ),
            ]}
        />
    );
};
