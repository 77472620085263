/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { EEfsBackupType } from '@veeam-vspc/models/web-controllers';

import type { ConfiguredToolbarProps, ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { EJobStates } from 'core/enums';
import remoteSnapshotJobTypeIconSrc from 'images/filters/job-types/remote-snapshot.png';
import snapshotJobTypeIconSrc from 'images/filters/job-types/snapshot.png';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useCloudPlatformTypesAdvancedFilter } from 'views/components/filters/CloudPlatformTypesFilters';

export interface FileSharesPublicCloudsFilterToolbarProps extends Omit<ConfiguredToolbarProps, 'items'> {
}

export const FileSharesPublicCloudsFilterToolbar: React.FC<FileSharesPublicCloudsFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        { title: lang.RUNNING, value: EJobStates.Running },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Starting,
                EJobStates.Stopping,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];
    const cloudPlatformTypesAdvancedFilter = useCloudPlatformTypesAdvancedFilter();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_POLICIES_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: snapshotJobTypeIconSrc,
                                label: lang.SNAPSHOT,
                                value: {
                                    id: EEfsBackupType.Backup,
                                    name: 'jobType',
                                },
                            },
                            {
                                iconSrc: remoteSnapshotJobTypeIconSrc,
                                label: lang.REPLICA_SNAPSHOT,
                                value: {
                                    id: EEfsBackupType.BackupCopy,
                                    name: 'jobType',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    cloudPlatformTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='vmName'
                            placeholder={lang.FILE_SHARE}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='policyName'
                            placeholder={lang.POLICY}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
