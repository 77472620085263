/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { Vb365JobJobType } from '@veeam-vspc/models/rest';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { Vb365Job } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

export const JobTypeColumn = ({ rowData }: { rowData: Vb365Job; }) => {
    const lang = useGlobalLang<VspcLang>();
    const { jobType } = rowData;

    const typeMapper = {
        [Vb365JobJobType.BackupJob]: lang.BACKUP,
        [Vb365JobJobType.CopyJob]: capitalize(lang.BACKUP_COPY),
        [Vb365JobJobType.Unknown]: ColumnTexts.DashSymbol,
    };

    return (
        <TextColumn>
            {typeMapper[jobType]}
        </TextColumn>
    );
};

