/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Vb365BackupJobBackupType } from '@veeam-vspc/models/rest';
import { useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { Vb365BackupProxy, Vb365BackupRepository, Vb365OrganizationBase, Vb365BackupJob } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { PageWizard } from 'components/wizards/PageWizard';
import { getJobInfoStep } from './components/JobInfo/JobInfo';
import { getOrganizationStep } from './components/Organization/Organization';
import { JobWizardStore, JobWizardStoreContext } from './stores';
import { getBackupModeStep } from './components/BackupMode/BackupMode';
import { getBackupRepositoryStep } from './components/BackupRepository/BackupRepository';
import { getScheduleStep } from './components/Schedule/Schedule';
import { getSummaryStep } from './components/Summary/Summary';
import { isPeriodicScheduleApplicable } from '../../helpers';

export interface JobWizardProps {
    organizations: Vb365OrganizationBase[];
    backupRepositories?: Vb365BackupRepository[];
    backupProxies?: Vb365BackupProxy[];
    isEdit?: boolean;
    data?: Vb365BackupJob;
    onFinish: () => void;
    onClose: () => void;
}

export const JobWizard: FC<JobWizardProps> = observer(({
    isEdit,
    data,
    organizations,
    backupRepositories,
    backupProxies,
    onFinish,
    onClose,
}) => {
    const model: Vb365BackupJob = isEdit ? {
        ...data,
        selectedItems: data.selectedItems || [],
        excludedItems: data.excludedItems || [],
    } : {
        repositoryUid: '',
        name: '',
        isEnabled: true,
        backupType: Vb365BackupJobBackupType.EntireOrganization,
        selectedItems: [],
        excludedItems: [],
    };

    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();
    const wizardStore = useMemo(() => new JobWizardStore(
        model,
        portalUser,
        organizations,
        backupRepositories,
        backupProxies,
        isEdit,
    ), []);

    const title = isEdit ? `${lang.EDIT_BACKUP_JOB} - ${data.name}` : lang.NEW_BACKUP_JOB;

    const handleFinish = () => {
        if (!wizardStore.isScheduleHidden && !isPeriodicScheduleApplicable(wizardStore.data.schedulePolicy)) {
            wizardStore.data.schedulePolicy.periodicallyOffsetMinutes = null;
        }

        if (isEdit) {
            return wizardStore.patchJob().then(onFinish);
        } else {
            return wizardStore.createJob().then(onFinish);
        }
    };

    return (
        <JobWizardStoreContext.Provider value={wizardStore}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                deactivate={onClose}
                steps={[
                    getJobInfoStep(lang, wizardStore),
                    getOrganizationStep(lang, wizardStore, notificationService),
                    getBackupModeStep(lang, wizardStore, notificationService),
                    getBackupRepositoryStep(lang, wizardStore, notificationService),
                    getScheduleStep(lang, wizardStore),
                    getSummaryStep(lang),
                ]}
                data={model}
                onFinish={handleFinish}
                onClose={onClose}
            />
        </JobWizardStoreContext.Provider>
    );
});
