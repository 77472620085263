/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Toggle, NoLabelGroup, HelpText, ToggleKit, FieldLayout } from '@veeam-vspc/shared/components';

import type { ToggleProps } from '@veeam-vspc/shared/components';

interface Props extends Omit<ToggleProps, 'name'> {
    helpText?: string;
    name?: string;
}

export const ToggleField = (props: Props) => {
    const { label } = props;
    const labelSuffix = props.labelSuffix === undefined ? ':' : props.labelSuffix;
    const labelText = label ? label + labelSuffix : label;

    return (
        <>
            {props.name
                ? <Toggle {...props} name={props.name ?? ''} helpText={null} />
                : (
                    <FieldLayout
                        label={labelText}
                    >
                        <ToggleKit {...props} />
                    </FieldLayout>
                )}

            {props.helpText && (
                <NoLabelGroup
                    content={(
                        <HelpText>{props.helpText}</HelpText>
                    )}
                />
            )}
        </>
    );
};
