/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NumberInput,
    useExternalFormApi,
    useGlobalLang,
    useGlobalServices,
    ValidationState,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

interface Props {
    deactivate;
    setRatio;
}

export const AllocationRatioDlg = ({ deactivate, setRatio }: Props) => {
    const formApi = useExternalFormApi<{ ratio: number; }>();
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [formData, setFormData] = useState<{ ratio: number; }>({ ratio: null });
    const [loading, setLoading] = useState(false);
    const [validationState] = useState(() => new ValidationState());

    useEffect(() => {
        setLoading(true);

        transportService
            .request('/Billing/GetAllocationRatio')
            .then(({ data }: RequestSuccessResponse<number>) => {
                setLoading(false);
                formApi.setValue({ ratio: data });
            });
    }, [formApi]);

    const formValidate = (data) => {
        const validator = new FormValidator(data);

        validator.validate('ratio')
            .number()
            .required()
            .max(9999)
            .min(1);

        return validator.result();
    };

    return (
        <Dialog
            actions={[
                {
                    onClick: () => {
                        transportService
                            .request('/Billing/SetAllocationRatio', formData)
                            .then(() => {
                                setRatio(formData.ratio);
                            });

                        deactivate();
                    },
                    text: lang.SAVE,
                    view: ACTION_VIEW.primary,
                }, {
                    onClick: deactivate,
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                },
            ]}
            header={lang.ALLOCATION_RATIO}
            loading={loading}
            movable={false}
            onRequestClose={deactivate}
            size={DIALOG_SIZE.auto}
        >
            <Form
                externalFormApi={formApi}
                onChange={setFormData}
                validate={formValidate}
                validationState={validationState}
                value={formData}
            >
                <FormLayout inlineLabel>
                    <NumberInput
                        label={lang.MAXIMUM_VCPU_PER_CORE_RATIO}
                        name='ratio'
                        minValue={1}
                        maxValue={9999}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
