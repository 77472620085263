/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { EmptyLayout } from '@veeam-vspc/shared/components';

import { Login } from './components';

export interface LoginPageProps {
    currentPath: string;
}

export const LoginPage: React.FC<LoginPageProps> = ({ currentPath }) => (
    <EmptyLayout currentPath={currentPath}>
        <Login />
    </EmptyLayout>
);
