
/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import {
    BackupServerBackupGFSPolicySettingsWeeklyDesiredTime,
    BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime,
    BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime,
    BackupServerBackupJobRetentionPolicyType,
} from '@veeam-vspc/models/rest';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { BackupServerJobRepository, CompanyVirtualInfrastructureRepresentation } from '@veeam-vspc/models/web-controllers';
import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { PageWizard } from 'components/wizards/PageWizard';
import { JobWizardStore, JobWizardStoreContext } from './stores';
import { getJobSettingsStep } from './components/JobSettings/JobSettings';
import { getSummaryStep } from './components/Summary/Summary';
import { getScopeStep } from './components/Scopes/Scopes';
import { getStorageStep } from './components/Storage/Storage';
import { getGuestProcessingStep } from './components/GuestProcessing/GuestProcessing';
import { getJobScheduleStep } from './components/JobSchedule/JobSchedule';
import { scopesResourcesService } from './components/Scopes/services/scopes-resources-service';
import { guestProcessingResourcesService } from './components/GuestProcessing/services';
import { getDefaultSchedule } from './helpers';

export interface JobWizardProps {
    isEdit?: boolean;
    companies: CompanyVirtualInfrastructureRepresentation[];
    repositories: BackupServerJobRepository[];
    data?: BackupServerBackupJobConfiguration;
    onFinish: () => void;
    onClose: () => void;
}

export const JobWizard: FC<JobWizardProps> = observer(({
    isEdit,
    data,
    companies,
    repositories,
    onFinish,
    onClose,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, notificationService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();

    scopesResourcesService.init(transportService, notificationService);
    guestProcessingResourcesService.init(transportService, notificationService);

    const model: BackupServerBackupJobConfiguration = isEdit
        ? {
            ...data,
        }
        : {
            name: '',
            description: '',
            isHighPriority: false,
            mappedOrganizationUid: companies[0].companyUid,
            virtualMachines: {
                includes: [],
                excludes: {
                    vms: [],
                    disks: [],
                    templates: null,
                },
            },
            storage: {
                backupRepositoryId: null,
                backupProxies: {
                    autoSelection: true,
                    proxyIds: null,
                },
                retentionPolicy: {
                    type: BackupServerBackupJobRetentionPolicyType.Days,
                    quantity: 7,
                },
                gfsPolicy: {
                    isEnabled: false,
                    weekly: {
                        isEnabled: false,
                        keepForNumberOfWeeks: 1,
                        desiredTime: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Monday,
                    },
                    monthly: {
                        isEnabled: false,
                        keepForNumberOfMonths: 1,
                        desiredTime: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.First,
                    },
                    yearly: {
                        isEnabled: false,
                        keepForNumberOfYears: 1,
                        desiredTime: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Jan,
                    },
                },
                advancedSettings: null,
            },
            guestProcessing: {
                appAwareProcessing: {
                    isEnabled: false,
                    appSettings: [],
                },
                guestFSIndexing: {
                    isEnabled: false,
                    indexingSettings: [],
                },
                guestInteractionProxies: null,
                guestCredentials: null,
            },
            schedule: getDefaultSchedule(),
        };

    const wizardStore = useMemo(() => new JobWizardStore(
        model,
        companies,
        repositories,
        transportService,
        isEdit,
        portalUser
    ), []);

    const title = isEdit
        ? `${lang.EDIT_VSPHERE_BACKUP_JOB} - ${data.name}`
        : capitalize(lang.NEW_VSPHERE_BACKUP_JOB);

    const handleFinish = () => {
        if (isEdit) {
            return wizardStore.patchJob().then(onFinish);
        } else {
            return wizardStore.createJob().then(onFinish);
        }
    };

    return (
        <JobWizardStoreContext.Provider value={wizardStore}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                deactivate={onClose}
                steps={[
                    getJobSettingsStep(lang, wizardStore, portalUser, transportService, notificationService),
                    getScopeStep(lang, wizardStore, notificationService),
                    getStorageStep(lang, wizardStore, notificationService),
                    getGuestProcessingStep(lang, wizardStore, notificationService),
                    getJobScheduleStep(lang, wizardStore),
                    getSummaryStep(lang),
                ]}
                data={model}
                onFinish={handleFinish}
                onClose={onClose}
            />
        </JobWizardStoreContext.Provider>
    );
});
