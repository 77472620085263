/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Form,
    FormLayout,
    useExternalFormApi,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    CommonLicenseStateRepresentation,
    LicenseTypeRepresentation,
    LicenseUpdateState,
    VacLicenseInfoUsageLoggingMode,
} from '@veeam-vspc/models/web-controllers';

import type { VacLicenseInfo } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { CustomDisplayField } from './components/CustomDisplayField';
import { normalizeDateWithTime, shortDate } from 'core/utils/date-helpers';
import success from 'images/statuses/success.svg';
import warning from 'images/statuses/warning.svg';
import error from 'images/statuses/error.svg';

interface LicenseInfoProps {
    info: VacLicenseInfo;
}

export const LicenseInfo = ({ info }: LicenseInfoProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const [disableAutoUpdate, setDisableAutoUpdate] = useState(false);
    const [hideAutoUpdate, setHideAutoUpdate] = useState(false);
    const [hideCCInfo, setHideCCInfo] = useState(false);
    const [hideEditionInfo, setHideEditionInfo] = useState(false);
    const [instances, setInstances] = useState('');
    const [lastUpdateStatusIcon, setLastUpdateStatusIcon] = useState(null);
    const [statusIcon, setStatusIcon] = useState(null);
    const formApi = useExternalFormApi<VacLicenseInfo>();

    const onDataChange = (data) => {
        transportService
            .request('/License/SetLicenseAutoUpdate', { value: data.autoUpdate })
            .then();
    };

    useEffect(() => {
        const { cloudConnectProviderString, instances, licenseType, newUnitsVAW, lastUpdateStatus, state, usageLoggingMode, usedUnitsVAW } = info;
        const newUnits = `${(newUnitsVAW) ? (` + ${newUnitsVAW} ${lang.NEW.toLowerCase()}`) : ''}`;

        setDisableAutoUpdate(usageLoggingMode === VacLicenseInfoUsageLoggingMode.Enabled);

        setHideAutoUpdate(usageLoggingMode === VacLicenseInfoUsageLoggingMode.Disabled);

        setHideCCInfo(cloudConnectProviderString === lang.NO);

        setHideEditionInfo(licenseType !== LicenseTypeRepresentation.Rental);

        switch (state) {
            case CommonLicenseStateRepresentation.Valid:
            case CommonLicenseStateRepresentation.Unlicensed:
                setStatusIcon(success);
                break;
            case CommonLicenseStateRepresentation.Warning:
                setStatusIcon(warning);
                break;
            case CommonLicenseStateRepresentation.Error:
            case CommonLicenseStateRepresentation.Unknown:
                setStatusIcon(error);
                break;
            default:
                setStatusIcon(null);
        }

        switch (lastUpdateStatus) {
            case LicenseUpdateState.Ok:
            case LicenseUpdateState.UpdateIsNotRequired:
                setLastUpdateStatusIcon(success);
                break;
            case LicenseUpdateState.ConnectionError:
            case LicenseUpdateState.RemoteError:
            case LicenseUpdateState.UpdateIsNotAvailable:
                setLastUpdateStatusIcon(error);
                break;
            default:
                setLastUpdateStatusIcon(null);
        }

        setInstances(`${instances} (${usedUnitsVAW || 0} used${newUnits})`);

        formApi.setValue(info);
    }, [info]);

    return (
        <Form
            onChange={onDataChange}
            value={info}
            externalFormApi={formApi}
        >
            {!hideAutoUpdate && (
                <Checkbox
                    disabled={disableAutoUpdate}
                    name='autoUpdate'
                >
                    {lang.UPDATE_LICENSE_AUTOMATICALLY}
                </Checkbox>
            )}

            <FormLayout inlineLabel style={{ paddingTop: '8px' }}>
                <CustomDisplayField
                    icon={statusIcon}
                    label={lang.STATUS}
                    value={info.status}
                />

                {!hideCCInfo && (
                    <CustomDisplayField
                        label={lang.CLOUD_CONNECT}
                        value={info.cloudConnectProviderString}
                    />
                )}

                {!hideEditionInfo && (
                    <CustomDisplayField
                        label={lang.EDITION}
                        value={info.edition}
                    />
                )}

                {info.expirationDate && (
                    <CustomDisplayField
                        label={lang.LICENSE_EXPIRES}
                        value={shortDate(info.expirationDate, formats)}
                    />
                )}

                <CustomDisplayField
                    label={info.licenseType === LicenseTypeRepresentation.Rental ? lang.POINTS : lang.INSTANCES}
                    value={instances}
                />

                <CustomDisplayField
                    label={lang.COMPANY}
                    value={info.licenseeCompany}
                />

                <CustomDisplayField
                    label={lang.EMAIL}
                    value={info.licenseeEmail}
                />

                <CustomDisplayField
                    label={lang.LICENSE_TYPE}
                    value={portalEnums.getEnumDescription('LicenseTypeRepresentation', info.licenseType)}
                />

                {info.licenseId && (
                    <CustomDisplayField
                        label={lang.LICENSE_ID}
                        value={info.licenseId}
                    />
                )}

                {info.supportId && (
                    <CustomDisplayField
                        label={lang.SUPPORT_ID}
                        value={info.supportId}
                    />
                )}

                <CustomDisplayField
                    tooltipText={lastUpdateStatusIcon === error ? lang.CANNOT_UPDATE_THE_LICENSE_KEY : undefined}
                    icon={lastUpdateStatusIcon}
                    label={lang.LAST_UPDATE_DATE}
                    value={normalizeDateWithTime(new Date(Date.parse(info.lastUpdateDate)), formats) || lang.DASH_SYMBOL}
                />

                <CustomDisplayField
                    label={lang.PACKAGE}
                    value={capitalize(info.licensePackage)}
                />
            </FormLayout>
        </Form>
    );
};
