/**
 * Copyright © Veeam Software Group GmbH.
 */

export const kbToGb = (value?: number | string | null) => {
    if (typeof value === 'string') {
        value = Number(value);
    }

    if (Number.isFinite(value)) {
        return `${(value / 1024 / 1024 / 1024).toFixed(1)} ${'GB'}`;
    }

    return null;
};

