/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { ACTION_VIEW, Dialog, DIALOG_SIZE, FormLayout, FormTitle, useGlobalLang } from '@veeam-vspc/shared/components';
import { deepCopy } from '@veeam-vspc/shared/core';

import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../../../stores';
import { SortingGroup } from '../SortingGroup';
import { StatusFilterGroup } from '../StatusFilterGroup';
import { AlarmsAdvancedSettingsStore, AdvancedSettingsStoreContext } from '../../stores';

export interface AdvancedSettingsDialogProps {
    deactivateDialog: UseModalActionsProps['deactivate'];
}

export const AdvancedSettingsDialog: React.FC<AdvancedSettingsDialogProps> = observer((props) => {
    const lang = useGlobalLang<VspcLang>();
    const { deactivateDialog } = props;
    const { formStore: { formData, applyAlarmsAdvancedSettings } } = useConfigurationStore();
    const advancedSettingsStore = useMemo(() => new AlarmsAdvancedSettingsStore(deepCopy(formData.alarms)), []);
    const { dailySorting, dailyStatusFilter } = advancedSettingsStore;

    return (
        <AdvancedSettingsStoreContext.Provider value={advancedSettingsStore}>
            <Dialog
                header={capitalize(lang.ADVANCED_SETTINGS)}
                onRequestClose={deactivateDialog}
                size={{ width: DIALOG_SIZE.auto, height: DIALOG_SIZE.auto }}
                actions={[
                    {
                        text: lang.APPLY,
                        onClick: (): void => {
                            applyAlarmsAdvancedSettings(dailySorting, dailyStatusFilter);
                            deactivateDialog();
                        },
                    },
                    {
                        text: lang.CANCEL,
                        onClick: deactivateDialog,
                        view: ACTION_VIEW.secondary,
                    },
                ]}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.FILTER_ALARMS_BY}</FormTitle>
                    <StatusFilterGroup />
                    <FormTitle>{lang.SORT_ALARMS_BY}</FormTitle>
                    <SortingGroup />
                </FormLayout>
            </Dialog>
        </AdvancedSettingsStoreContext.Provider>
    );
});
