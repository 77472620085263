/**
 * Copyright © Veeam Software Group GmbH.
 */

export function parseDate(dateStr: string) {
    const date = new Date(dateStr);

    return {
        year: date.getUTCFullYear(),
        fullMonth: date.toLocaleString('en-us', { month: 'long', timeZone: 'UTC' }),
        shortMonth: date.toLocaleString('en-us', { month: 'short', timeZone: 'UTC' }),
    };
}
