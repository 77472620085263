/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { GeoJSON } from 'highcharts';

import { checkMapLayer, MapLayer } from './check-map-layer';

export const compareLocations = (m1, m2) =>
    (m1.name < m2.name
        ? -1 : m1.name > m2.name
            ? 1 : 0);

export const getLocationsBy = (
    location: GeoJSON | null,
    hasCountry?: boolean
): { id: string; name: string; }[] => {
    const locations = location
        ? location.features
            .map((feature) => {
                if (!feature.properties.name) return null;

                return {
                    id: feature.properties['hc-key'],
                    name:
                        hasCountry &&
                        feature.properties['hc-group'] === 'admin1'
                            ? `${location.title}, ${feature.properties.name}`
                            : feature.properties.name,
                };
            })
            .filter(Boolean)
            .sort(compareLocations)
        : [];

    if (!hasCountry || locations.length === 0) return locations;

    const firstLocation = locations[0];
    const mapLayer = checkMapLayer(firstLocation.id);

    if (mapLayer === MapLayer.Country) return locations;

    const id = firstLocation.id.split('-')[0];
    const name = location.title.split(',')[0];

    return [{ id, name }, ...locations];
};
