/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

export class BaseTrendChartStore {
    service;

    constructor(service) {
        makeObservable(this);

        this.service = service;
    }

    @observable categories: string[];
    @observable dimension: string;
    @observable empty: boolean;
    @observable loading: boolean;
    @observable series: [];

    @action
    fetchData(params): void {
        this.setLoading(true);

        this.service
            .fetchData(params)
            .then((data) => {
                this.setCategories(data.categories);
                this.setDimension(data.dimension);
                this.setEmpty(data.empty);
                this.setSeries(data.series);
                this.setLoading(false);
            })
            .catch(() => {
                this.setLoading(false);
            });
    }

    @action.bound
    setCategories(categories: string[]): void {
        this.categories = categories;
    }

    @action.bound
    setDimension(dimension: string): void {
        this.dimension = dimension;
    }

    @action.bound
    setEmpty(empty: boolean): void {
        this.empty = empty;
    }

    @action.bound
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action.bound
    setSeries(series): void {
        this.series = series;
    }

}
