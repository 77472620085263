/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    FormLayout, FormTitle,
    FormValidator,
    LabelGroup,
    MultipleChoice,
    NumberInput,
    Radiobutton,
    SignPost,
    STACK_DIRECTION,
    TextInput,
    useFormApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize, formatStr } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { VboObjectTypeRepresentation, SlaPeriodRepresentation } from '../../../enums';
import { DatetimeFormatted } from 'components/controls/DatetimeFormatted';
import { useReportStore } from '../../../hooks';

import type { ReportModel, Timezone } from '../../../interfaces';

export const vb360ParametersValidate = (data: ReportModel, errorMessage: string) => {
    const validator = new FormValidator(data);
    let tmpDateValidator;

    if (data.parameters.slaSettings.isCustomPeriod) {
        tmpDateValidator = validator
            .validate('parameters.slaSettings.fromAsDate')
            .date();
        if (data.parameters.slaSettings?.toAsDate) {
            tmpDateValidator
                .max(data.parameters.slaSettings?.toAsDate, errorMessage);
        }
        tmpDateValidator.required();

        tmpDateValidator = validator
            .validate('parameters.slaSettings.toAsDate')
            .date();

        if (data.parameters.slaSettings?.fromAsDate) {
            tmpDateValidator
                .min(data.parameters.slaSettings?.fromAsDate, errorMessage);
        }
        tmpDateValidator.required();
    }

    validator
        .validate('parameters.slaSettings.sla')
        .number()
        .required()
        .min(0)
        .max(100);

    validator
        .validate('parameters.excludeMask')
        .string()
        .maxLength(500);

    validator
        .validate('parameters.vboObjectTypes')
        .string()
        .check((val) => {
            // val is array here
            if (val.length === 0) {
                return 'error';
            }
        });

    return validator.result();
};

interface Props {
    data: ReportModel;
    onDataChange: (data: any) => void;
}

export const Vb360Parameters = ({ data, onDataChange }: Props) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useFormApi();
    const allPeriods = [{
        id: SlaPeriodRepresentation.Day,
        name: capitalize(lang.DAY),
    }, {
        id: SlaPeriodRepresentation.Week,
        name: capitalize(lang.WEEK),
    }, {
        id: SlaPeriodRepresentation.Month,
        name: capitalize(lang.MONTH),
    }, {
        id: SlaPeriodRepresentation.Quater,
        name: capitalize(lang.QUARTER),
    }];

    const objectTypes = [ {
        id: VboObjectTypeRepresentation.User,
        name: lang.USERS,
    }, {
        id: VboObjectTypeRepresentation.Group,
        name: lang.GROUPS,
    }, {
        id: VboObjectTypeRepresentation.Site,
        name: lang.SITES,
    }, {
        id: VboObjectTypeRepresentation.Team,
        name: lang.TEAMS,
    }];

    useEffect(() => {
        if (data.parameters.vboObjectTypes.length === 0) {
            formApi.setValue('parameters.vboObjectTypes', objectTypes.map(objectType => objectType.id));
            onDataChange(data);
        }
    }, []);

    const reportStore = useReportStore();
    const [timezones, setTimezones] = useState<Timezone[]>([]);

    useEffect(() => {
        reportStore.timezoneListCache.load()
            .then(items => setTimezones(items));
    }, []);

    return (
        <>
            <FormTitle>{lang.TIME_PERIOD}</FormTitle>

            <LabelGroup
                label={(
                    <Radiobutton
                        name={'parameters.slaSettings.isCustomPeriod'}
                        value={false}
                    >
                        {`${lang.LAST}:`}
                    </Radiobutton>
                )}
            >
                <FormLayout
                    direction={STACK_DIRECTION.row}
                    disabled={data.parameters.slaSettings.isCustomPeriod}
                >
                    <Combobox
                        name='parameters.slaSettings.periodType'
                        data={allPeriods}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        size={CONTROL_SIZE.xs}
                    />
                </FormLayout>
            </LabelGroup>

            <LabelGroup
                hasTopOffset
                label={(
                    <Radiobutton
                        name={'parameters.slaSettings.isCustomPeriod'}
                        value={true}
                    >
                        {`${lang.CUSTOM_PERIOD}:`}
                    </Radiobutton>
                )}
            >
                <FormLayout
                    direction={STACK_DIRECTION.row}
                    disabled={!data.parameters.slaSettings.isCustomPeriod}
                >
                    <DatetimeFormatted
                        name='parameters.slaSettings.fromAsDate'
                        label={lang.FROM}
                    />

                    <DatetimeFormatted
                        name='parameters.slaSettings.toAsDate'
                        label={lang.TO}
                    />
                </FormLayout>
            </LabelGroup>

            <Combobox
                name={'parameters.slaSettings.timeZoneId'}
                label={lang.TIME_ZONE}
                data={timezones}
                valueGetter={item => item.id}
                textGetter={item => item.description}
                disabled={!data.parameters.slaSettings.isCustomPeriod}
                size={'616px'}
            />

            <FormTitle>{lang.REPORT_PARAMETERS}</FormTitle>

            <NumberInput
                name='parameters.slaSettings.sla'
                label={lang.SLA}
                minValue={0}
                maxValue={100}
                suffix='%'
                size={CONTROL_SIZE.xxs}
            />

            <LabelGroup
                label={`${lang.OBJECT_TYPES}:`}
            >
                <MultipleChoice
                    error={data.parameters.vboObjectTypes.length === 0}
                    titleFormatter={(values) => {
                        if (values.length === objectTypes.length) {
                            return `${lang.ALL} ${lang.OBJECT_TYPES.toLowerCase()}`;
                        }
                        if (values.length > 0) {
                            return `${formatStr(lang.N_OF_OBJECT_TYPES, values.length, objectTypes.length)}`;
                        }
                        return `${lang.NOT_SELECTED}...`;
                    }}
                    data={objectTypes}
                    value={data.parameters.vboObjectTypes}
                    onChange={(value) => {
                        formApi.setValue('parameters.vboObjectTypes', value.map(jobTypeId => jobTypeId));
                        onDataChange(data);
                    }}
                />
            </LabelGroup>

            <TextInput
                name='parameters.excludeMask'
                label={lang.OBJECT_EXCLUSIONS_RULE}
                suffix={(
                    <SignPost
                        text={lang.TYPE_IN_THE_OBJECT_NAME}
                    />
                )}
            />
        </>
    );
};
