/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface FailoverPlansFile {
    FailoverPlans: ComponentType<any>;
}

export const FailoverPlansPage: React.FC<{}> = () => (
    <LazyLoadedComponent<FailoverPlansFile>
        importPromise={() => (import('./components/FailoverPlans') as Promise<FailoverPlansFile>)}
        pageName={'FailoverPlans'}
    />
);
