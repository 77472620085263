/**
 * Copyright © Veeam Software Group GmbH.
 */

import '@veeam-vspc/shared/styles';
import 'rc-tree/assets/index.css';

import React from 'react';
import { MainRoot } from '@veeam-vspc/shared/components';
import { createRoot } from 'react-dom/client';

import type { MainRootProps } from '@veeam-vspc/shared/components';

import { renderDevPanel } from 'components/DevPanel';
import { coreModuleConfig } from './configs/core-module-config';

if (process.env.NODE_ENV === 'development') {
    renderDevPanel(coreModuleConfig.companyPrefix);
}

const props: MainRootProps = {
    coreModuleConfig,
};

function init() {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<MainRoot {...props} />);
}

window.addEventListener('DOMContentLoaded', init);
