/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ConfiguredGrid,
    useGlobalLang,
    SortingMode,
} from '@veeam-vspc/shared/components';

import type { BackupServerFileJobSourceItemModel } from '@veeam-vspc/models/web-controllers';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { MAX_INTEGER_32 } from 'core/const';

export interface SourceItemsDialogProps {
    stateId: string;
    name: string;
    sourceItems: BackupServerFileJobSourceItemModel[];
    deactivate: () => void;
}

const getResponseByItems = items => ({
    data: items,
    meta: {
        pagingInfo: {
            total: items.length,
        },
    },
} as any);

export const SourceItemsDialog = ({ stateId, name, sourceItems, deactivate }: SourceItemsDialogProps) => {
    const lang = useGlobalLang<VspcLang>();
    const columns: GridColumnProps<BackupServerFileJobSourceItemModel>[] = [
        {
            id: 'path',
            title: lang.BACKED_UP_ITEMS,
            cell: rowData => rowData.path,
        },
        {
            id: 'fileMasks',
            title: lang.APPLIED_MASK,
            cell: rowData => rowData.fileMasks,
        },
    ];

    return (
        <Dialog
            header={`${name} - ${lang.SOURCE_ITEMS}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns}
                    data={() => Promise.resolve(getResponseByItems(sourceItems))}
                    toggleable={false}
                    selection={{
                        field: 'path',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    sortingMode={SortingMode.Local}
                />
            </StackView>
        </Dialog>
    );
};
