/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BasicFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, ConfiguredToolbar, useGlobalLang } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { Vb365JobJobType, Vb365JobLastStatus } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import backupJobIcon from 'images/filters/vb365-job-types/backup.svg';
import backupCopyJobIcon from 'images/filters/vb365-job-types/backup-copy.svg';

export const VBObjectsFilterToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();

    const statuses = [
        { title: lang.SUCCESS, value: Vb365JobLastStatus.Success },
        { title: lang.WARNING, value: Vb365JobLastStatus.Warning },
        { title: lang.FAILED, value: Vb365JobLastStatus.Failed },
        { title: lang.RUNNING, value: Vb365JobLastStatus.Running },
        {
            title: lang.INFORMATION,
            value: [
                Vb365JobLastStatus.Unknown,
                Vb365JobLastStatus.NotConfigured,
                Vb365JobLastStatus.Disconnected,
                Vb365JobLastStatus.Stopped,
                Vb365JobLastStatus.Queued,
            ],
        },
    ];

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.JOB}
                            maxLength={128}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='vb365ServerName'
                            placeholder={lang.SERVER}
                            maxLength={255}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            propName='lastStatus'
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <BasicFilter
                            label={lang.TYPE}
                            hasAllButton
                            name='jobType'
                            buttons={[
                                {
                                    icon: backupJobIcon,
                                    value: Vb365JobJobType.BackupJob,
                                    title: lang.BACKUP,
                                },
                                {
                                    icon: backupCopyJobIcon,
                                    value: Vb365JobJobType.CopyJob,
                                    title: capitalize(lang.BACKUP_COPY),
                                },
                            ]}
                        />
                    ),
                },
            ]}
        />
    );
};

