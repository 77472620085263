
/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    FormLayout,
    useGlobalLang,
    CONTROL_SIZE,
    FieldLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
    Form,
    TextInput,
    PasswordInput,
    Textarea,
} from '@veeam-vspc/shared/components';

import type { BackupServerCredentialsStandardInput } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';

interface Props {
    deactivate: () => void;
    onConfirm: (data: BackupServerCredentialsStandardInput) => Promise<void>;
}

const formValidate = (data: BackupServerCredentialsStandardInput) => {
    const validator = new FormValidator(data);

    validator
        .validate('username')
        .string()
        .maxLength(256)
        .required();

    validator
        .validate('password')
        .string()
        .required();

    validator
        .validate('description')
        .string()
        .maxLength(256);

    return validator.result();
};

export const MicrosoftWindowsCredentialsDialog = observer((props: Props) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();

    const formApi = useExternalFormApi<BackupServerCredentialsStandardInput>();
    const [validationState] = useState(new ValidationState());

    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            header={lang.MICROSOFT_WINDOWS_ACCOUNT}
            actions={[
                {
                    text: lang.OK,
                    disabled: !isFormValid,
                    onClick: async() => {
                        setLoading(true);
                        const { username, password, description } = formApi.value;

                        try {
                            await props.onConfirm({
                                username,
                                password: password || null, // avoid empty string
                                mappedOrganizationUid: wizardStore.mappedOrganizationUid,
                                description,
                            });
                        } finally {
                            setLoading(false);
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form
                value={{
                    username: '',
                    password: '',
                    description: '',
                }}
                validationState={validationState}
                validate={formValidate}
                externalFormApi={formApi}
                onChange={() => {
                    setIsFormValid(formApi.isValid());
                }}
            >
                <FormLayout>
                    <FormLayout inlineLabel>
                        <FieldLayout
                            label={lang.USERNAME}
                            size={CONTROL_SIZE.full}
                        >
                            <TextInput
                                name='username'
                                size={CONTROL_SIZE.full}
                            />
                        </FieldLayout>

                        <FieldLayout
                            label={lang.PASSWORD}
                            size={CONTROL_SIZE.full}
                        >
                            <PasswordInput
                                name='password'
                                size={CONTROL_SIZE.full}
                            />
                        </FieldLayout>

                        <FieldLayout
                            label={lang.DESCRIPTION}
                            size={CONTROL_SIZE.full}
                        >
                            <Textarea
                                name='description'
                                size={CONTROL_SIZE.full}
                            />
                        </FieldLayout>

                    </FormLayout>
                </FormLayout>
            </Form>
        </Dialog>
    );
});
