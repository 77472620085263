/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { Icon } from '@veeam-vspc/shared/components';

import type { CompanyTree } from '@veeam-vspc/models/web-controllers';
import type { DataNode } from 'rc-tree/lib/interface';

import companyIcon from 'images/icons/global-filters/company_item.png';
import resellerIcon from 'images/icons/global-filters/reseller_item.png';
import folderIcon from 'images/icons/global-filters/folder.svg';
import { GlobalFiltersDefaults } from '../../../enums';

const icons = {
    [CompanyTypeRepresentation.Tenant]: companyIcon,
    [CompanyTypeRepresentation.Provider]: companyIcon,
    [CompanyTypeRepresentation.Reseller]: resellerIcon,
    [CompanyTypeRepresentation.ProviderContainer]: companyIcon,
    [CompanyTypeRepresentation.ResellerContainer]: resellerIcon,
    [CompanyTypeRepresentation.CustomContainer]: folderIcon,
    [CompanyTypeRepresentation.Unknown]: folderIcon,
};

export const prepareCompaniesTree = (node: CompanyTree): DataNode => {
    const result: DataNode = {
        key: node.id ?? GlobalFiltersDefaults.OrganizationDefaultId,
        title: node.name,
        icon: <Icon src={icons[node.type]} />,
        switcherIcon: (
            <svg
                viewBox='0 0 6.409 10'
                width='6.409px'
                height='10px'
            >
                <polygon
                    fill='#444444'
                    points='1.414 0 0 1.412 3.594 5 0 8.588 1.414 10 6.409 5.002 1.414 0'
                />
            </svg>
        ),
    };

    if (node.children) {
        result.children = node.children.map(el => prepareCompaniesTree(el));
    } else {
        result.children = [];
    }

    return result;
};
