/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { LinkButton, Text, usePageWizardStore, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { formatStr } from '@veeam-vspc/shared/helpers';
import { EPrincipalRoleTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { ManagedCompaniesMode } from '../../enums';
import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';

import type { AdminUserModel } from '../../interfaces';

export const Companies = (wizardStepData: WizardStepData<AdminUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const { data, onDataChange, validationState } = wizardStepData;
    const selectedCompaniesCount = data.selectedCompanies?.length ?? 0;
    const wizardContext = usePageWizardStore<AdminUserModel>();
    const showPanel = () => wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide} />);

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies.length === 0) {
                showPanel();
            }

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection &&
                data.selectedCompanies?.some(company => company.managedByReseller)
            ) {
                const companyNames = data.selectedCompanies
                    .filter(company => company.managedByReseller)
                    .map(company => company.name)
                    .join(', ');

                notificationService.error(lang.ERROR, [formatStr(lang.CANNOT_CREATE_A_NEW_USER, companyNames)]);
            }
        }
    });

    return (
        <StepLayout
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: lang.ALL_COMPANIES_INCLUDING_NEWLY_ADDED,
                        value: ManagedCompaniesMode.AllCompanies,
                    },
                    {
                        render: () => (
                            <>
                                <Text>
                                    {`${lang.SELECTED_COMPANIES_ONLY} ${selectedCompaniesCount > 0 ? `(${selectedCompaniesCount})` : ''}`}
                                </Text>

                                <LinkButton
                                    onClick={showPanel}
                                    disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                >
                                    {`(${lang.CONFIGURE_COMPANIES}...)`}
                                </LinkButton>
                            </>
                        ),
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies.length === 0) {
            return false;
        }

        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies?.some(company => company.managedByReseller)) {
            return false;
        }

        return true;
    },
    isHidden: ({ data }) => data.model.roleType === EPrincipalRoleTypeRepresentation.Administrator,
    id: 'companies',
    render: data => <Companies {...data} />,
});
