/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { renderToString } from 'react-dom/server';
import { BASE_FONT_NORMAL_WEIGHT, colors, constants, FONT_SIZE_S } from '@veeam-vspc/shared/components';
import { merge } from 'lodash';

import type * as Highcharts from 'highcharts';

import { Row, Tooltip } from '../components/Tooltip';
import { getBaseOptions } from './get-base-options';
import { LicenseDisplayName, LicenseName } from 'views/pages/LicensingPage/components/Overview/enums';
import {
    addPercentageToMax,
    getMaxSumOfSeries,
} from 'views/pages/LicensingPage/components/Overview/components/UsageReports/helpers';

import type { CustomParams } from '../interfaces';

function onDrawEvent() {
    if (this.customPlotLines) {
        this.customPlotLines.destroy();
        this.customPlotLines = undefined;
    }

    if (this.customLabels) {
        this.customLabels.destroy();
        this.customLabels = undefined;
    }

    this.customPlotLines = this.renderer.g('customPlotLines')
        .attr({ zIndex: 1 })
        .add();
    this.customLabels = this.renderer.g('customLabels')
        .attr({ zIndex: 2 })
        .add();

    for (const itemAxis of this.yAxis) {
        itemAxis.series.forEach((itemSeries) => {
            if (itemSeries.name === LicenseDisplayName.Quota && itemSeries.yData[0] > 0 && itemSeries.visible) {
                itemSeries.points.forEach((point) => {
                    this.renderer
                        .rect(
                            this.plotLeft + point.shapeArgs.x,
                            this.plotTop + point.shapeArgs.y,
                            point.shapeArgs.width, 3)
                        .attr({
                            fill: colors.R800,
                        })
                        .add(this.customPlotLines);

                    this.renderer
                        .label(itemSeries.yData[0],
                            this.plotLeft + point.shapeArgs.x + point.pointWidth,
                            this.plotTop + point.shapeArgs.y,
                            '')
                        .attr({})
                        .css({
                            color: colors.R800,
                            fontWeight: constants.BASE_FONT_SEMI_BOLD_WEIGHT,
                        })
                        .add(
                            this.customLabels);
                });
            }

        }, 0);
    }

}

export const getColumnOptions = ({
    multi,
    navigate,
    redirectUrl,
    spacingBottom = false,
    stacked,
    stackedStyled,
    yMax,
    yMin,
    yTitle = '',
    shouldCalculateYMax = false,
    shouldCallOnDrawEvent = false,
    ...rest
}: CustomParams): Highcharts.Options => {
    const baseOptions = getBaseOptions(rest);
    const { categories } = rest;

    return merge(baseOptions, {
        chart: {
            spacing: [24, 8, spacingBottom ? 36 : 0, 8], // 36 = 32 (legend height) + 4 (legend margin)
            type: 'column',
            events: {
                redraw: function() {
                    shouldCallOnDrawEvent && onDrawEvent.call(this);
                },
                render: function() {
                    shouldCallOnDrawEvent && onDrawEvent.call(this);
                },
            },
        },

        plotOptions: {
            column: {
                borderWidth: stackedStyled ? 1 : 0,
                cursor: redirectUrl ? 'pointer' : 'default',
                pointWidth: stackedStyled ? 64 : 8,
                stacking: stacked ? 'normal' : undefined,
                grouping: false,

                dataLabels: {
                    align: 'center',
                    crop: false,
                    enabled: true,
                    overflow: 'allow',
                    padding: 4,

                    formatter: function() {
                        return (
                            this.series.name !== LicenseDisplayName.Quota
                            && (!stacked || this.point.shapeArgs.height > 16)
                            && this.y > 0
                        )
                            ? this.y
                            : '';
                    },

                    style: {
                        color: colors.G600,
                        fontSize: FONT_SIZE_S,
                        fontWeight: BASE_FONT_NORMAL_WEIGHT,
                        textOutline: 'transparent',
                    },
                },

                events: {
                    click() {
                        redirectUrl && navigate(redirectUrl);
                    },
                },

                minPointLength: function() {
                    return this.y > 0 ? 5 : 0;
                },
            },

            series: {
                events: {
                    legendItemClick: function() {
                        if (!shouldCalculateYMax) return;

                        const visibleSeriesData = [];

                        this.chart.series.forEach(function(series) {
                            if (series !== this && series.visible) {
                                visibleSeriesData.push({ data: series.data.map(point => point.y) });
                            }
                        }, this);

                        if (!this.visible) {
                            visibleSeriesData.push({ data: this.data.map(point => point.y) });
                        }

                        this.yAxis.update({ max: addPercentageToMax(getMaxSumOfSeries(visibleSeriesData)) });
                    },
                },
            },
        },

        tooltip: {
            formatter: function() {
                return renderToString(
                    <Tooltip title={this.x.replace(/<br \/>/g, ' ')}>
                        {this.points.map((point, i) => (
                            <Row
                                color={point.color as string}
                                key={i}
                                name={point.series.name}
                                symbol={'circle'}
                                value={point.y}
                            />
                        ))}
                    </Tooltip>
                );
            },
        },

        yAxis: multi
            ? categories?.map(() => ({
                gridLineWidth: 0,
                labels: { enabled: false },
                reversedStacks: false,
                title: { text: '' },
                min: 0,
                endOnTick: false,
                maxPadding: 0.1,

                stackLabels: {
                    enabled: true,
                    y: 4,

                    formatter: function() {
                        if (this['stack'] === LicenseName.Quota) return;

                        this.options.style.color = this['stack'] === LicenseName.Quota
                            ? colors.R800
                            : colors.G600;

                        return this.cumulative > 0 ? `${this.total}` : ''; // because this.total is always positive, we use this.cumulative
                    },

                    style: {
                        fontSize: FONT_SIZE_S,
                        fontWeight: BASE_FONT_NORMAL_WEIGHT,
                        textOutline: 'transparent',
                    },
                },
            }))
            : {
                allowDecimals: false,
                lineColor: colors.G350,
                max: yMax,
                min: yMin,
                reversedStacks: false,
                softMin: 0,
                tickAmount: 5,
                title: { text: yTitle },

                labels: {
                    format: '{value}',

                    style: {
                        color: colors.G600,
                        fontSize: FONT_SIZE_S,
                    },
                },

                stackLabels: {
                    enabled: true,
                    y: 4,

                    style: {
                        fontSize: FONT_SIZE_S,
                        fontWeight: BASE_FONT_NORMAL_WEIGHT,
                        textOutline: 'transparent',
                    },
                },
            },
    });
};
