/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, TextColumn, useGlobalAppData } from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { JobStateRepresentation } from '@veeam-vspc/models/web-controllers';

import { getStatusIconSrc } from 'core/utils';
import { FailureMessageDialog } from 'views/components/FailureMessageDialog';

export interface BackupFileJobStatusColumnProps {
    status: JobStateRepresentation;
    statusName: string;
    lastSessionEndTime: string;
    lastSessionUid?: string;
}

export const BackupFileJobStatusColumn: React.FC<BackupFileJobStatusColumnProps> = (props) => {
    const { formats } = useGlobalAppData();
    const { netShortDate, netShortTime } = formats;
    const { status, statusName, lastSessionUid, lastSessionEndTime } = props;
    const iconSrc = getStatusIconSrc(statusName);

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {statusName || ColumnTexts.DashSymbol}
                </TextColumn>
            )}
            isLink={statusName ? [JobStateRepresentation.Failed, JobStateRepresentation.Warning].includes(status) : false}
            contentIconBefore={statusName ? iconSrc : ''}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <FailureMessageDialog
                    url={'/BackupServerJob/GetJobSessionTasksFailureMessages'}
                    deactivate={deactivate}
                    iconSrc={iconSrc}
                    paramsForFailureMessageRequest={{ sessionGuid: lastSessionUid }}
                    paramsForFailureMessage={{
                        shortDateFormat: netShortDate,
                        shortTimeFormat: netShortTime,
                        lastSessionTime: lastSessionEndTime,
                        statusName,
                    }}
                />
            )}
        />
    );
};
