/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { MonitoredObjectType } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { MonitoredObjectsContext } from './contexts/MonitoredObjectsContext';
import { MonitoredObjectsStore } from './stores/MonitoredObjectsStore';
import { MonitoredObjectsService } from './services/monitored-objects.service';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useMonitoredObjectsStore } from './hooks/use-monitored-objects-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const monitoredObjectsStore = useMonitoredObjectsStore();
    const monitoredObjectsService = useMemo(() => new MonitoredObjectsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400],
        yTitle: lang.OBJECTS,
    };

    const title = {
        text: capitalize(lang.MONITORED_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={monitoredObjectsStore.setMonitoredObjectType}
                stateId={monitoredObjectsStore.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={monitoredObjectsStore.monitoredObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: MonitoredObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: MonitoredObjectType.Agent, text: capitalize(lang.AGENTS) },
                    { id: MonitoredObjectType.Application, text: capitalize(lang.APPLICATION) },
                    { id: MonitoredObjectType.FileShare, text: capitalize(lang.FILE_SHARES) },
                    { id: MonitoredObjectType.CloudVm, text: capitalize(lang.CLOUD_VMS) },
                    { id: MonitoredObjectType.CloudDatabase, text: capitalize(lang.CLOUD_DATABASES) },
                    { id: MonitoredObjectType.CloudFileShare, text: capitalize(lang.CLOUD_FILE_SHARES) },
                    { id: MonitoredObjectType.Microsoft365User, text: capitalize(lang.MICROSOFT_365_10_USERS) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={monitoredObjectsStore.monitoredObjectType}
            params={params}
            service={monitoredObjectsService}
            title={title}
        />
    );
});

export const MonitoredObjects: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.MonitoredObjects.MonitoredObjectType`;
    const initialObjectType = useLocalStorageValue<MonitoredObjectType>(stateId, MonitoredObjectType.Vm);

    const monitoredObjectsStore = useMemo(() => new MonitoredObjectsStore(initialObjectType, stateId), [initialObjectType, stateId]);

    return (
        <MonitoredObjectsContext.Provider value={monitoredObjectsStore}>
            <ChartLayout />
        </MonitoredObjectsContext.Provider>
    );
};
