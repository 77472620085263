/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ConfiguredGrid,
    GridSortDirections,
    TextColumn,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import {
    CompanyStatusRepresentation,
    CompanyTypeRepresentation,
    UserRoleRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { InvoiceConfigurationModel, InvoiceSettingsParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { InvoiceConfigurationStatusColumn } from './components/InvoiceConfigurationStatusColumn';
import { InvoiceConfigurationFilterToolbar } from './components/InvoiceConfigurationFilterToolbar';
import { InvoiceConfigurationActionToolbar } from './components/InvoiceConfigurationActionToolbar';
import { ConfigSectionIds } from 'core/enums';


interface InvoiceConfigurationProps {
    stateId: string;
}

export const InvoiceConfiguration: React.FC<InvoiceConfigurationProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const gridStateId = `${stateId}.InvoiceConfiguration`;
    const { transportService } = useGlobalServices();

    const isTypeFilterVisible = portalUser.isPassedRoleContained([
        UserRoleRepresentation.VacAdministrator,
        UserRoleRepresentation.CloudConnectAdministrator,
    ]);

    const columns: GridColumnProps<InvoiceConfigurationModel>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'scheduleType',
            title: lang.SCHEDULE,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('InvoiceScheduleTypeRepresentation', rowData.scheduleType)}
                </TextColumn>
            ),
        },
        {
            id: 'lastReport',
            title: lang.LAST_INVOICE,
            cell: rowData => (
                <GridDateColumn value={rowData.lastReport} />
            ),
        },
        {
            id: 'nextReport',
            title: lang.NEXT_INVOICE,
            cell: rowData => (
                rowData.enabled ? <GridDateColumn value={rowData.nextReport} /> : lang.DASH_SYMBOL
            ),
        },
        {
            id: 'companyStatus',
            title: lang.COMPANY_STATE,
            cell: rowData => rowData.companyStatus,
        },
        {
            id: 'stateId',
            title: lang.STATUS,
            cell: rowData => (
                <InvoiceConfigurationStatusColumn status={rowData.stateId} exceptions={rowData.exceptions} />
            ),
        },
        {
            id: 'subscriptionPlanName',
            title: lang.SUBSCRIPTION_PLAN,
            cell: rowData => (
                rowData.subscriptionPlanName || lang.NOT_SET
            ),
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.InvoicesConfiguration}
            columns={columns}
            initialState={{
                filters: { type: isTypeFilterVisible ? [CompanyTypeRepresentation.Provider, CompanyTypeRepresentation.Tenant] : [] },
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            defaultColumnMinWidth={80}
            data={(requestParams: InvoiceSettingsParam) => transportService
                .request<typeof requestParams, RequestSuccessResponse<InvoiceConfigurationModel[]>>('/Invoice/GetInvoiceSettings', {
                    ...requestParams,
                })}
            isRowInactive={rowData => (
                [
                    CompanyStatusRepresentation.Expired,
                    CompanyStatusRepresentation.Phantom,
                    CompanyStatusRepresentation.Disabled,
                ].includes(rowData.companyStatusId)
            )}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <InvoiceConfigurationFilterToolbar />,
                () => <InvoiceConfigurationActionToolbar />,
            ]}
        />
    );
};
