/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { PortalSpinner, useGlobalServices, useGridApi } from '@veeam-vspc/shared/components';

import type { RequestResponse } from '@veeam-vspc/shared/interfaces';
import type { NotificationParams } from '@veeam-vspc/shared/services';

import type { UseAsyncActionData } from './interfaces';

export const useAsyncAction = (params: NotificationParams = {}): UseAsyncActionData => {
    const [loading, setLoading] = useState(false);
    const { transportService, notificationService } = useGlobalServices();
    const gridApi = useGridApi();
    const asyncActionLoader = loading && <PortalSpinner />;
    const doAsyncAction = (url: string, title: string, data: Record<string, any> = {}): Promise<RequestResponse<any>> => {
        setLoading(true);

        const callback = (resp) => {
            setLoading(false);

            return notificationService.multi(title, resp, params)
                .then(() => {
                    gridApi?.reloadGrid();
                    return resp;
                });
        };

        return transportService.request(url, data)
            .then(resp => callback(resp))
            .catch(resp => callback(resp));
    };

    return [asyncActionLoader, doAsyncAction];
};
