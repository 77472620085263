/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ParsedText, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

import { ImageUpload } from '../ImageUpload';

export const Favicon: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ImageUpload
            fileExt={'.ico'}
            imageTitle={lang.BRANDING_FAVICON_TITLE}
            imageDescription={<ParsedText textOrConfig={lang.BRANDING_FAVICON_DESCRIPTION} />}
            imageInfoUrl={'/Branding/GetPortalFaviconInfo'}
            getImageUrl={'/Branding/GetPortalFavicon'}
            uploadImageUrl={'/Branding/UploadPortalFavicon'}
            resetImageUrl={'/Branding/ResetPortalFavicon'}
            promptTitle={lang.FAVICON}
            imageSize={{
                height: 16,
                width: 16,
            }}
            withThemeBgColor={false}
            withSizeNote={false}
        />
    );
};
