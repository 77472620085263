/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { AppRouteItem } from '@veeam-vspc/shared/stores';

import { RouteIds, RoutePaths } from '../../enums';
import { BackupServerTerminalPage } from 'views/pages/BackupServerTerminalPage';
import { AdjustReportsPage } from 'views/pages/AdjustReportsPage';
import { LoginPage } from 'views/pages/LoginPage';
import { ApiPage } from 'views/pages/ApiPage';

const loginPageRoute = {
    path: RoutePaths.Login,
    exact: true,
    component: LoginPage,
};

const adjustReportsPageRoute = {
    path: RoutePaths.AdjustReports,
    exact: true,
    component: AdjustReportsPage,
};

const backupServerTerminalPageRoute = {
    path: RoutePaths.BackupServerTerminal,
    exact: true,
    component: BackupServerTerminalPage,
};

const apiPageRoute = {
    path: RoutePaths.Api,
    exact: true,
    component: ApiPage,
};

export const allCustomRoutes: { [key in RouteIds]?: AppRouteItem; } = {
    [RouteIds.Login]: loginPageRoute,
    [RouteIds.AdjustReports]: adjustReportsPageRoute,
    [RouteIds.BackupServerTerminal]: backupServerTerminalPageRoute,
    [RouteIds.Api]: apiPageRoute,
};
