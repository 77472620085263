/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Spinner } from '@veeam-vspc/shared/components';
import React from 'react';

import { SpinnerLayout } from '../../styles';
export const SpinnerWithLayout: React.FC<{ isLoading: boolean; }> = ({ isLoading }) => isLoading ? (
    <SpinnerLayout>
        <Spinner />
    </SpinnerLayout>
) : null;
