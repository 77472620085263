/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BaseLocationData } from '@veeam-vspc/models/web-controllers';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestParams } from '@veeam-vspc/shared/components';
import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';

import { alternateTransformRequestParams } from 'core/utils';

import type { ProtectedVBObjectsFilters } from '../interfaces';

class VBObjectsService {
    transportService: TransportService<RequestErrorResponse | Error> = {
        get() {
            throw new Error('TransportService is undefined');
        },
    } as unknown as TransportService<RequestErrorResponse | Error>;

    init(transportService: TransportService<RequestErrorResponse | Error>) {
        this.transportService = transportService;
    }

    async getProtectedVBObjects(params: RequestParams<ProtectedVBObjectsFilters>, location: BaseLocationData) {
        return this.transportService
            .get<{ locationFilter?: string; }, RequestSuccessResponse<Vb365ProtectedObject[]>>(
                '/protectedWorkloads/vb365ProtectedObjects',
                {
                    ...alternateTransformRequestParams(params),
                    ...(
                        location
                            ? { locationFilter: location.instanceUid }
                            : {}
                    ),
                },
            );
    }
}

export const vbObjectsService = new VBObjectsService();
