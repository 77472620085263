/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { ToolbarProps, ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { EJobStates } from 'core/enums';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useJobFileTypesAdvancedFilter } from 'views/components/filters/JobTypesFilters';

export interface FileSharesLocalFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const FileSharesLocalFilterToolbar: React.FC<FileSharesLocalFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        { title: lang.RUNNING, value: EJobStates.Running },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Starting,
                EJobStates.Stopping,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];
    const jobFileTypesAdvancedFilter = useJobFileTypesAdvancedFilter();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_JOBS_BY}:`,
        cols: [
            {
                groups: [
                    jobFileTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='serverName'
                            placeholder={lang.SERVER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
