/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    FormLayout,
    useGlobalLang,
    FieldLayout,
    TextInputKit,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

export const SpecifyFolderModal: React.FC<{
    deactivate: () => void;
    onConfirm: (data: string) => void;
}> = ({ deactivate, onConfirm }) => {
    const lang = useGlobalLang<VspcLang>();
    const [value, setValue] = useState('');

    return (
        <Dialog
            header={capitalize(lang.ADD_FOLDER)}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => onConfirm(value),
                    disabled: !value,
                },
                {
                    text: lang.CANCEL,
                    onClick: () => deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
        >
            <FormLayout>
                <FieldLayout
                    inlineLabel
                    label={`${lang.FOLDER}:`}
                >
                    <TextInputKit value={value} onChange={e => setValue(e)} />
                </FieldLayout>

            </FormLayout>
        </Dialog>
    );
};
