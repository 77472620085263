/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { TabBarBodyLayout, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { BeforeLeave } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { ExtComponent } from '../../components/Ext/ExtComponent';
import { DiscoveryBackupStore } from './stores';
import { useNotificationBar } from '../../components/NotificationBar/hooks/use-notification-bar';

export const DiscoveryBackupAgentsPage: React.FC<{}> = () => {
    const xtype = 'rcop.epAgent.grid';
    const { fileTransportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const notificationBar = useNotificationBar();
    const discoveryBackupStore = useMemo(() => (
        new DiscoveryBackupStore(lang, fileTransportService, BeforeLeave.getInstance(), notificationBar)
    ), [lang, fileTransportService, notificationBar]);

    return (
        <TabBarBodyLayout>
            <ExtComponent
                xtype={xtype}
                extProps={{
                    discoveryBackupStore,
                }}
            />
        </TabBarBodyLayout>
    );
};
