/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { LicenseKeyToolbar } from '../LicenseKeyToolbar';

export const LicenseKeyDialog = ({ onClose }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <Dialog
            actions={[
                {
                    onClick: onClose,
                    text: lang.CANCEL,
                    view: ACTION_VIEW.primary,
                },
            ]}
            header={capitalize(lang.LICENSE_KEY)}
            onRequestClose={onClose}
            size={DIALOG_SIZE.auto}
        >
            <div style={{ whiteSpace: 'pre-line' }}>
                {lang.YOUR_LICENSE_KEY_HAS_EXPIRED}
            </div>

            <LicenseKeyToolbar
                confirmFn={() => {
                    window.location.reload();
                }}
                hideUpdate={false}
            />
        </Dialog>
    );
};
