/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Combobox, createLinkControl } from '@veeam/components';
import {
    colors,
    CONTROL_SIZE,
    FieldLayout,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useGlobalAppStorage,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { ChartWidget } from 'views/components/ChartWidget';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { UsageReportsContext } from './contexts';
import { UsageReportsStore } from './stores';
import { ProductCombobox } from '../ProductCombobox';
import { ProductContext } from '../../contexts';
import { ProductStore } from '../../stores';
import { RoutePaths } from 'configs/enums';
import { getColumnOptions } from 'views/components/HighchartsWrapper/helpers';
import { useProductStore } from '../../hooks';
import { useUsageReportsStore } from './hooks';
import { useLocalStorageValue } from 'views/hooks';
import { addPercentageToMax, getMaxSumOfSeries } from './helpers';

import type { ComboboxItem } from 'core/interfaces';

const UsageReportsLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const appStorage = useGlobalAppStorage();
    const store = useUsageReportsStore();
    const { type } = useProductStore();
    const { categories, empty, loading, series, workload, workloadStateId, workloads } = store;
    const [options, setOptions] = useState({});

    useEffect(() => {
        store.fetchData({ productType: type });
    }, [store, type]);

    useEffect(() => {
        if (workloads?.length > 0) {
            const localStorageWorkload = appStorage.getItem(workloadStateId);
            const lsWorkloadIsPartOfWorkloads = workloads.find(workload => workload.id === localStorageWorkload);

            if (lsWorkloadIsPartOfWorkloads) {
                store.setWorkload(localStorageWorkload);
            } else {
                store.setWorkload(workloads[0].id);
            }
        }
    }, [store, workloads]);

    useEffect(() => {
        store.setSeries();
    }, [store, workload]);

    useEffect(() => {
        const params = {
            colors: [colors.Grn400, colors.B250],
            navigate,
            redirectUrl: `/${RoutePaths.Configs}/licensing/licenseReport`,
            stacked: true,
            stackedStyled: true,
        };

        let yMax;

        if (series) {
            yMax = addPercentageToMax(getMaxSumOfSeries(series));
        }

        setOptions(getColumnOptions({
            categories,
            height: 254,
            series,
            yMax,
            shouldCalculateYMax: true,
            ...params,
        }));
    }, [categories, navigate, series, workload]);

    return (
        <ChartWidget
            style={{ height: 350 }}
            title={{ text: lang.LICENSE_USAGE_REPORTS }}
        >
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.m}>
                <ProductCombobox />

                {workloads?.length > 0 && (
                    <FieldLayout inlineLabel label={`${lang.MANAGED_WORKLOADS}:`}>
                        <Combobox
                            controlRenderer={createLinkControl()}
                            data={workloads}
                            onChange={store.setWorkload}
                            size={CONTROL_SIZE.full}
                            textGetter={(p: ComboboxItem) => p.text}
                            value={workload}
                            valueGetter={(p: ComboboxItem) => p.id}
                        />
                    </FieldLayout>
                )}
            </StackView>

            <HighchartsWrapper
                empty={empty}
                loading={loading}
                options={options}
            />
        </ChartWidget>
    );
});

export const UsageReports = () => {
    const appStorage = useGlobalAppStorage();
    const { pathname } = useLocation();
    const { transportService } = useGlobalServices();
    const workloadStateId = `${pathname}.UsageReports.ManagedWorkloads`;
    const productStateId = `${pathname}.UsageReports.Product`;
    const initialProductType = useLocalStorageValue<LicenseProductType>(productStateId, LicenseProductType.VspcEndpoint);

    const filter = useMemo(() => new ProductStore(initialProductType, productStateId), [initialProductType, productStateId]);
    const store = useMemo(() => new UsageReportsStore(transportService, appStorage, workloadStateId), []);

    return (
        <ProductContext.Provider value={filter}>
            <UsageReportsContext.Provider value={store}>
                <UsageReportsLayout />
            </UsageReportsContext.Provider>
        </ProductContext.Provider>
    );
};
