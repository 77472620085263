/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TabBarBodyLayout } from '@veeam-vspc/shared/components';
import React from 'react';

import { HighchartsStyles } from 'views/components/HighchartsWrapper';
import { Invoices } from './components/Invoices';

export interface ResourcesAndBillingInvoicesPageProps {
    currentPath: string;
}

export const ResourcesAndBillingInvoicesPage: React.FC<ResourcesAndBillingInvoicesPageProps> = () => (
    <TabBarBodyLayout>
        <HighchartsStyles />
        <Invoices />
    </TabBarBodyLayout>
);
