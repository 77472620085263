/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredAdvancedFilter,
    useGlobalLang,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    MalwareState,
    ProtectedVirtualMachineRestoreType,
    ProtectedVmCloudCopyStatus,
    ProtectedVmSimilarJobTypes,
    ProtectedVmVirtualPlatformType,
} from '@veeam-vspc/models/web-controllers';

import type { ConfiguredAdvancedFilterOptions, AdvancedFilterValue, ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import backupJobTypeIconSrc from 'images/filters/job-types/backup.png';
import replicationJobTypeIconSrc from 'images/filters/job-types/replication.png';
import cdpReplicationJobTypeIconSrc from 'images/filters/job-types/cdp.png';
import backupCopyJobTypeIconSrc from 'images/filters/job-types/backup-copy.png';
import tapeJobTypeIconSrc from 'images/filters/job-types/backup-to-tape.png';
import singleJobTypeIconSrc from 'images/pages/protected-data/filters/similar-job-type/single-job.png';
import multipleJobTypeIconSrc from 'images/pages/protected-data/filters/similar-job-type/multiple-jobs.png';
import vmWarePlatformTypeIconSrc from 'images/filters/virtual-platform-types/vsphere.png';
import hyperVPlatformTypeIconSrc from 'images/filters/os/windows.svg';
import nutanixPlatformTypeIconSrc from 'images/filters/virtual-platform-types/nutanix.png';
import yesCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/yes.png';
import noCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/no.png';
import cleanMalwareStateIconSrc from 'images/filters/malware-state/clean.svg';
import infectedMalwareStateIconSrc from 'images/filters/malware-state/infected.svg';
import suspiciousMalwareStateIconSrc from 'images/filters/malware-state/suspicious.svg';
import unknownMalwareStateIconSrc from 'images/filters/malware-state/unknown.svg';

export interface VirtualInfrastructureToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const VirtualInfrastructureToolbar: React.FC<VirtualInfrastructureToolbarProps> = (props) => {
    const { method = 'GetVirtualMachines', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: backupJobTypeIconSrc,
                                label: lang.BACKUP,
                                value: {
                                    id: ProtectedVirtualMachineRestoreType.Backup,
                                    name: 'jobTypes',
                                },
                            },
                            {
                                iconSrc: replicationJobTypeIconSrc,
                                label: lang.REPLICATION,
                                value: {
                                    id: ProtectedVirtualMachineRestoreType.Replica,
                                    name: 'jobTypes',
                                },
                            },
                            {
                                iconSrc: cdpReplicationJobTypeIconSrc,
                                label: lang.CDP_REPLICATION,
                                value: {
                                    id: ProtectedVirtualMachineRestoreType.CdpReplica,
                                    name: 'jobTypes',
                                },
                            },
                            {
                                iconSrc: backupCopyJobTypeIconSrc,
                                label: lang.BACKUP_COPY,
                                value: {
                                    id: ProtectedVirtualMachineRestoreType.Copy,
                                    name: 'jobTypes',
                                },
                            },
                            {
                                iconSrc: tapeJobTypeIconSrc,
                                label: lang.BACKUP_TO_TAPE,
                                value: {
                                    id: ProtectedVirtualMachineRestoreType.Tape,
                                    name: 'jobTypes',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        title: lang.MALWARE_STATE,
                        items: [
                            {
                                iconSrc: cleanMalwareStateIconSrc,
                                label: lang.CLEAN,
                                value: {
                                    id: MalwareState.Clean,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: infectedMalwareStateIconSrc,
                                label: lang.INFECTED,
                                value: {
                                    id: MalwareState.Infected,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: suspiciousMalwareStateIconSrc,
                                label: lang.SUSPICIOUS,
                                value: {
                                    id: MalwareState.Suspicious,
                                    name: 'malwareStates',
                                },
                            },
                            {
                                iconSrc: unknownMalwareStateIconSrc,
                                label: lang.UNKNOWN,
                                value: {
                                    id: MalwareState.Unknown,
                                    name: 'malwareStates',
                                },
                            },
                        ],
                    },
                    {
                        title: lang.N_OF_JOB_TYPES,
                        items: [
                            {
                                iconSrc: singleJobTypeIconSrc,
                                label: lang.SINGLE,
                                value: {
                                    id: ProtectedVmSimilarJobTypes.Single,
                                    name: 'similarJobTypes',
                                },
                            },
                            {
                                iconSrc: multipleJobTypeIconSrc,
                                label: lang.MULTIPLE,
                                value: {
                                    id: ProtectedVmSimilarJobTypes.Multiple,
                                    name: 'similarJobTypes',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        title: lang.PLATFORM,
                        items: [
                            {
                                iconSrc: vmWarePlatformTypeIconSrc,
                                label: lang.VSPHERE,
                                value: {
                                    id: ProtectedVmVirtualPlatformType.VMware,
                                    name: 'platformTypes',
                                },
                            },
                            {
                                iconSrc: hyperVPlatformTypeIconSrc,
                                label: lang.HYPER_V,
                                value: {
                                    id: ProtectedVmVirtualPlatformType.HyperV,
                                    name: 'platformTypes',
                                },
                            },
                            {
                                iconSrc: nutanixPlatformTypeIconSrc,
                                label: lang.AHV,
                                value: {
                                    id: ProtectedVmVirtualPlatformType.Nutanix,
                                    name: 'platformTypes',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.CLOUD_COPY, true),
                        items: [
                            {
                                iconSrc: yesCloudCopyTypeIconSrc,
                                label: lang.YES,
                                value: {
                                    id: ProtectedVmCloudCopyStatus.CloudCopy,
                                    name: 'cloudCopyStatus',
                                },
                            },
                            {
                                iconSrc: noCloudCopyTypeIconSrc,
                                label: lang.NO,
                                value: {
                                    id: ProtectedVmCloudCopyStatus.NoCopy,
                                    name: 'cloudCopyStatus',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='vmName'
                            placeholder={lang.VM}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
