/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    Search,
    ACTION_VIEW,
    ConfiguredGrid,
    SortingMode,
    ConfiguredToolbar,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { Vb365JobItemTeam } from '@veeam-vspc/models/rest';
import type { GridStore } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';
import { backupModeService } from '../../services';


import type { TeamsModalProps } from '../../interfaces';

export const TeamsModal: FC<TeamsModalProps> = ({ onCancel, onApply }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemTeam, unknown, unknown>>();

    const apply = () => {
        const selected = api.current.selected;

        onApply(selected);
    };

    const { vb365ServerUid, instanceUid } = wizardStore.selectedOrganization;

    return (
        <Dialog
            header={lang.ADD_TEAMS}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                api={api}
                columns={[
                    { id: 'displayName', title: lang.NAME, cell: rowData => rowData.displayName },
                    { id: 'description', title: lang.DESCRIPTION, cell: rowData => rowData.description },
                ]}
                data={backupModeService.getTeams(vb365ServerUid, instanceUid, api.current)}
                disableAutoUpdate
                sortingMode={SortingMode.Local}
                useCompactPaginationWithCurrentPage
                toolbars={[
                    () => (
                        <ConfiguredToolbar
                            view={TOOLBAR_VIEW_TYPE.spotted}
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='teamDisplayNameFilter'
                                            placeholder={lang.TEAM}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                isRowDisabled={rowData => wizardStore.isItemSelected(rowData.id, Vb365JobItemComposedItemType.Team)}
            />
        </Dialog>
    );
};
