/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileParams, XmlFileTagAttributes } from '../enums';
import { readAsText } from 'core/utils/file-helpers';

export type TagSelectorResult = Element | null;

export const getXmlFromFile = async(file: File): Promise<XMLDocument> => {
    const xmlString = await readAsText(file);
    return getXmlFromString(xmlString);
};

export const getXmlFromString = (xmlString: string): XMLDocument => {
    const parser = new DOMParser();
    return parser.parseFromString(xmlString, XmlFileParams.ParsingType);
};

export const getXmlTagByName = (xml: XMLDocument, tagName: string): Element[] => Array.from(xml.getElementsByTagName(tagName));

export const getXmlTagAttrByName = (xmlTag: Element, attrName: string): string | null => xmlTag.getAttribute(attrName);

export const getXmlTagByQuery = (xml: XMLDocument, qSelector: string): TagSelectorResult => xml.querySelector(qSelector);

export const getXmlTagsByQuery = (xml: XMLDocument, qSelectors: string[]): TagSelectorResult[] => (
    qSelectors.map(selector => getXmlTagByQuery(xml, selector))
);

export const getTagValueAttr = (xmlTag: TagSelectorResult): string | null => xmlTag.getAttribute(XmlFileTagAttributes.Value);
