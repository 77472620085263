/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/shared/core';
import { action, observable, makeObservable } from 'mobx';
import { ValidationState } from '@veeam-vspc/shared/components';
import { SmtpSettingsTlsMode, OAuth2ClientSettingsKind, SmtpSettings } from '@veeam-vspc/models/rest';

import type { ExternalFormApi } from '@veeam-vspc/shared/components';

import { DEFAULT_PASSWORD } from 'core/const';
import { AuthTypes, FormFieldNames } from '../enums';
import { getHostAndPortFromURL, shouldResetHostField, trimFormData } from '../helpers';
import { ServerSettingsDialogFormData } from '../interfaces';

export class ServerSettingsDialogFormStore {

    @observable formData: ServerSettingsDialogFormData;
    @observable hasOAuth: boolean;
    @observable isLoading = false;
    // by default, 'passwordInputHasEye' is 'false', because we put 'defaultPasswordValue' to the password input,
    // and we shouldn't show 'eye' in the input for the default value
    @observable passwordInputHasEye = false;

    readonly defaultPasswordValue = DEFAULT_PASSWORD;
    readonly redirectUrl = `${window.location.origin}/config/notifications/configuration`;
    readonly validationState = new ValidationState();

    constructor(
        smtpSettings: SmtpSettings,
        readonly formApi: ExternalFormApi<ServerSettingsDialogFormData>,
    ) {
        makeObservable(this);

        this.hasOAuth = Boolean(smtpSettings?.oAuth2Credential);

        this.initiateFormData(deepCopy(smtpSettings));
    }

    @action.bound
    private initiateFormData(smtpSettings: SmtpSettings): void {
        const { host, port } = getHostAndPortFromURL(smtpSettings?.serverAddress);

        this.setFormData({
            [FormFieldNames.Host]: host,
            [FormFieldNames.Port]: port,
            [FormFieldNames.UseAuth]: Boolean(smtpSettings?.passwordCredential),
            [FormFieldNames.AuthType]: Boolean(smtpSettings?.oAuth2Credential) ? AuthTypes.Modern : AuthTypes.Basic,
            smtp: {
                tlsMode: smtpSettings?.tlsMode || SmtpSettingsTlsMode.Auto,
                timeout: smtpSettings?.timeout || '00:00:30',
                serverAddress: smtpSettings?.serverAddress || '',
                passwordCredential: {
                    userName: smtpSettings?.passwordCredential?.userName || '',
                    password: smtpSettings?.passwordCredential?.userName ? this.defaultPasswordValue : '',
                },
                oAuth2Credential: {
                    clientSettings: {
                        clientId: smtpSettings?.oAuth2Credential?.clientSettings?.clientId || '',
                        tenantId: smtpSettings?.oAuth2Credential?.clientSettings?.tenantId || '',
                        clientSecret: smtpSettings?.oAuth2Credential?.clientSettings?.clientSecret || '',
                        kind: smtpSettings?.oAuth2Credential?.clientSettings?.kind || OAuth2ClientSettingsKind.Google,
                    },
                },
            },
        });
    }

    @action.bound
    readonly onPasswordFocus = async(): Promise<void> => {
            const password = this.formData.smtp.passwordCredential.password;

            // clear password input value from default value
            if (password === this.defaultPasswordValue) {
                await this.formApi.setValue(FormFieldNames.Password, '');
            }

            // should be set after clearing of the default password value, otherwise we see blinking of the 'eye'
            this.setPasswordInputHasEye(true);
        };

    @action.bound
    readonly onFormChange = async(newFormData: ServerSettingsDialogFormData): Promise<void> => {
            this.setFormData(trimFormData(newFormData));
            const { useAuth, smtp } = this.formData;

            const isDirty = this.formApi.isDataChanged();
            const password = smtp.passwordCredential.password;
            const userName = smtp.passwordCredential.userName;

            // clear password input value from default value on form change if 'useAuth' checkbox is checked
            if (isDirty && useAuth && (password === this.defaultPasswordValue)) {
                await this.formApi.setValue(FormFieldNames.Password, '');
                this.setPasswordInputHasEye(true);
            }

            // clear username and password inputs on 'useAuth' checkbox uncheck if there were some values
            if (!useAuth && userName) {
                await this.formApi.setValue(FormFieldNames.UserName, '');
                await this.formApi.setValue(FormFieldNames.Password, '');
                this.setPasswordInputHasEye(true);
            }
        };

    @action.bound
    readonly onAuthTypeChange = (newAuthType: AuthTypes): void => {
            const isModernType = newAuthType === AuthTypes.Modern;
            this.setHasOAuth(isModernType);

            // if it is basic auth type and current host value is equal to default SMTP hosts of OAuth2 servers, then we should reset host value (Bug #429689)
            if (!isModernType && shouldResetHostField(this.formData.host)) {
                this.formApi.setValue(FormFieldNames.Host, '');
            }
        };

    @action.bound
    setFormData(value: ServerSettingsDialogFormData): void {
        this.formData = value;
    }

    @action.bound
    setHasOAuth(value: boolean): void {
        this.hasOAuth = value;
    }

    @action.bound
    setIsLoading(value: boolean): void {
        this.isLoading = value;
    }

    @action.bound
    setPasswordInputHasEye(value: boolean): void {
        this.passwordInputHasEye = value;
    }

}
