/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SPACING_M } from '@veeam-vspc/shared/components';

export const WidgetRowsStyled = styled.div`
    align-content: flex-start;
    display: grid;
    gap: ${SPACING_M};
`;
