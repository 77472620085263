/**
 * Copyright © Veeam Software Group GmbH.
 */

import { MonthsRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

export const getMonth = (lang: VspcLang) => (
    [
        {
            id: MonthsRepresentation.January,
            name: lang.JANUARY,
        }, {
            id: MonthsRepresentation.February,
            name: lang.FEBRUARY,
        }, {
            id: MonthsRepresentation.March,
            name: lang.MARCH,
        }, {
            id: MonthsRepresentation.April,
            name: lang.APRIL,
        }, {
            id: MonthsRepresentation.May,
            name: lang.MAY,
        }, {
            id: MonthsRepresentation.June,
            name: lang.JUNE,
        }, {
            id: MonthsRepresentation.July,
            name: lang.JULY,
        }, {
            id: MonthsRepresentation.August,
            name: lang.AUGUST,
        }, {
            id: MonthsRepresentation.September,
            name: lang.SEPTEMBER,
        }, {
            id: MonthsRepresentation.October,
            name: lang.OCTOBER,
        }, {
            id: MonthsRepresentation.November,
            name: lang.NOVEMBER,
        }, {
            id: MonthsRepresentation.December,
            name: lang.DECEMBER,
        },
    ]
);
