/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { colors, TabBarLayout } from '@veeam-vspc/shared/components';

import type { CSSProperties } from 'react';
import type { AppRouteItem } from '@veeam-vspc/shared/stores';

export interface AppTabBarLayoutProps {
    currentPath: string;
    items: AppRouteItem[];
    bgStyles?: CSSProperties;
    showSingleTab?: boolean;
}

export const AppTabBarLayout: React.FC<AppTabBarLayoutProps> = ({ currentPath, items, bgStyles, showSingleTab = false }) => (
    <TabBarLayout
        currentPath={currentPath}
        items={items}
        backgroundStyles={bgStyles}
        showSingleTab={showSingleTab}
    />
);

export const AppTabBarLayoutGreyBg: React.FC<AppTabBarLayoutProps> = props => (
    <AppTabBarLayout
        {...props}
        bgStyles={{
            backgroundColor: colors.G200,
        }}
    />
);

export const AppTabBarLayoutVisibleSingleTab: React.FC<AppTabBarLayoutProps> = props => (
    <AppTabBarLayout
        {...props}
        showSingleTab
    />
);
