/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { AllInvoices } from './components';

export interface AllInvoicesPageProps {
    currentPath?: string;
}

export const AllInvoicesPage: React.FC<AllInvoicesPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <AllInvoices stateId={currentPath} />
    </TabBarBodyLayout>
);
