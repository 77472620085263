/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { colors, INDENT, STACK_DIRECTION, StackView } from '@veeam-vspc/shared/components';

import { Header } from './components/Header';

import type { ChartWidgetProps } from './interfaces';

export const ChartWidget = ({ children, style, title }: ChartWidgetProps) => (
    <StackView
        direction={STACK_DIRECTION.column}
        spaceBottom={INDENT.m}
        spaceHorizontal={INDENT.m}
        style={Object.assign({ backgroundColor: colors.G100 }, style)}
    >
        <Header title={title} />
        {children}
    </StackView>
);
