/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Text, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

import { ImageUpload } from '../ImageUpload';

export const LoginLogo: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ImageUpload
            fileExt={'.png'}
            imageTitle={lang.LOGIN_PAGE_LOGO}
            imageDescription={<Text>{lang.THIS_IMAGE_WILL_BE_SHOWN}</Text>}
            imageInfoUrl={'/Branding/GetPortalLogoBranding'}
            getImageUrl={'/Branding/GetPortalLogoBrandImage'}
            uploadImageUrl={'/Branding/UploadPortalLogoBrandImage'}
            resetImageUrl={'/Branding/ResetPortalLogoBranding'}
            imageSize={{
                width: 48,
                height: 48,
            }}
        />
    );
};
