/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ActionLinkColumn,
    MESSAGE_BOX_ICON_VIEW,
    MessageBox,
    ParsedText,
    useGlobalAddons,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { EVacAgentStatusInteg } from '@veeam-vspc/models/web-controllers';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import rejectedIconSrc from 'images/filters/discovered-computer-connection-status/rejected.png';
import successIconSrc from 'images/statuses/success.svg';
import infoIconSrc from 'images/statuses/info.svg';
import warningIconSrc from 'images/statuses/warning.svg';
import questionIcon from 'images/question.svg';
import errorIconSrc from 'images/statuses/error.svg';
import pendingIconSrc from 'images/statuses/pending.svg';

const ICON_STATUSES_SRC = {
    [EVacAgentStatusInteg.Healthy]: successIconSrc,
    [EVacAgentStatusInteg.Warning]: warningIconSrc,
    [EVacAgentStatusInteg.NotInstalled]: infoIconSrc,
    [EVacAgentStatusInteg.NotManaged]: infoIconSrc,
    [EVacAgentStatusInteg.NotSet]: questionIcon,
    [EVacAgentStatusInteg.Updating]: pendingIconSrc,
    [EVacAgentStatusInteg.Restarting]: pendingIconSrc,
    [EVacAgentStatusInteg.Installation]: pendingIconSrc,
    [EVacAgentStatusInteg.Applying]: pendingIconSrc,
    [EVacAgentStatusInteg.Rejected]: rejectedIconSrc,
    [EVacAgentStatusInteg.Inacessible]: errorIconSrc,
    [EVacAgentStatusInteg.Error]: errorIconSrc,
    [EVacAgentStatusInteg.FailedToUpdate]: errorIconSrc,
};

const ICON_MESSAGE_BOX = {
    [EVacAgentStatusInteg.Healthy]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Warning]: MESSAGE_BOX_ICON_VIEW.warning,
    [EVacAgentStatusInteg.NotInstalled]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.NotManaged]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.NotSet]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Updating]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Restarting]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Installation]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Applying]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Rejected]: MESSAGE_BOX_ICON_VIEW.info,
    [EVacAgentStatusInteg.Inacessible]: MESSAGE_BOX_ICON_VIEW.error,
    [EVacAgentStatusInteg.Error]: MESSAGE_BOX_ICON_VIEW.error,
    [EVacAgentStatusInteg.FailedToUpdate]: MESSAGE_BOX_ICON_VIEW.error,
};

export const AgentStatusColumn = ({ rowData }: { rowData: DiscoveredComputerModel; }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { agentStatus, agentStatusNote } = rowData;
    const statusText = portalEnums.getEnumDescription('EVacAgentStatusInteg', agentStatus);

    return (
        <ActionLinkColumn
            contentIconBefore={ICON_STATUSES_SRC[agentStatus]}
            renderContent={() => statusText}
            isLink={!!agentStatusNote}
            renderModal={({ deactivate }) => (
                <MessageBox
                    iconView={ICON_MESSAGE_BOX[agentStatus]}
                    header={lang.COMPUTER_CONNECTION_STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                >
                    <ParsedText textOrConfig={agentStatusNote} />
                </MessageBox>
            )}
        />
    );
};
