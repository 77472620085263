/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ToggleKit } from '@veeam-vspc/shared/components';

import type { ToggleProps } from '@veeam/components/lib/Toggle/types';

export const FixToggle: React.FC<ToggleProps> = (props) => {
    const { titles } = props;
    const widthStyle = titles ? {} : { width: '67px' };

    return (
        <ToggleKit
            style={widthStyle}
            {...props}
        />
    );
};
