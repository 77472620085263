/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { Location } from 'react-router-dom';

import { RoutePaths, RouteSectionType } from 'configs/enums';

export const whichSection = (location: Location): RouteSectionType => {
    const section = location.pathname.split('/')[1];

    if (section === RoutePaths.Home) {
        return RouteSectionType.Home;
    }

    if (section === RoutePaths.Configs) {
        return RouteSectionType.Config;
    }
};
