/**
 * Copyright © Veeam Software Group GmbH.
 */

export const toIsoStringWithIgnoreTime = (date: Date, setEndOfDay?: boolean) => {
    function pad(number) {
        if (number < 10) {
            return `0${number}`;
        }
        return number;
    }

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());

    const resultTime = setEndOfDay ? 'T23:59:59Z' : 'T00:00:00Z';

    return `${year}-${month}-${day}${resultTime}`;
};
