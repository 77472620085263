/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, CONTROL_SIZE, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { ManagedAgentMode } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ManagedBackupAgentsContext } from './contexts/ManagedBackupAgentsContext';
import { ManagedBackupAgentsService } from './services/managed-backup-agents.service';
import { ManagedBackupAgentsStore } from './stores/ManagedBackupAgentsStore';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useManagedBackupAgentsStore } from './hooks/use-managed-backup-agents-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const managedAgentsStore = useManagedBackupAgentsStore();
    const managedBackupAgentsService = useMemo(() => new ManagedBackupAgentsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400, palette.Grn200],
        yTitle: lang.AGENTS,
    };

    const title = {
        text: capitalize(lang.MANAGED_BACKUP_AGENTS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={managedAgentsStore.setManagedBackupAgentType}
                stateId={managedAgentsStore.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={managedAgentsStore.managedBackupAgentType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: ManagedAgentMode.All, text: lang.ALL },
                    { id: ManagedAgentMode.ByVSPC, text: lang.MANAGED_BY_CONSOLE },
                    { id: ManagedAgentMode.ByVBR, text: lang.MANAGED_BY_BACKUP_SERVER },
                ]}
                size={CONTROL_SIZE.m}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={managedAgentsStore.managedBackupAgentType}
            params={params}
            service={managedBackupAgentsService}
            title={title}
        />
    );
});

export const ManagedBackupAgents: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ManagedBackupAgents.ManagedBackupAgentType`;
    const initialType = useLocalStorageValue<ManagedAgentMode>(stateId, ManagedAgentMode.All);

    const managedBackupAgentsStore = useMemo(() => new ManagedBackupAgentsStore(initialType, stateId), [stateId, initialType]);

    return (
        <ManagedBackupAgentsContext.Provider value={managedBackupAgentsStore}>
            <ChartLayout />
        </ManagedBackupAgentsContext.Provider>
    );
};
