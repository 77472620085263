/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Link, useGlobalLang } from '@veeam-vspc/shared/components';
import React from 'react';

import type { VspcLang } from 'configs/languages';

export const SelectUserLink = ({ openPanel }: { openPanel: () => void; }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <Link onClick={openPanel}>
            {lang.SELECT}
        </Link>
    );
};
