/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposedItemType, Vb365JobItemUserUserType } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const resourceUserTypesTitles = (
    type: Vb365JobItemUserUserType,
    lang: VspcLang
) => ({
    [Vb365JobItemUserUserType.Public]: lang.PUBLIC_MAILBOX,
    [Vb365JobItemUserUserType.Shared]: lang.SHARED_MAILBOX,
    [Vb365JobItemUserUserType.User]: lang.USER,
}[type]);

export const resourceItemTypesTitles = (
    type: Vb365JobItemComposedItemType,
    lang: VspcLang
) => ({
    [Vb365JobItemComposedItemType.Group]: lang.GROUP,
    [Vb365JobItemComposedItemType.User]: lang.USER,
    [Vb365JobItemComposedItemType.Site]: lang.SITE,
    [Vb365JobItemComposedItemType.PartialOrganization]: lang.ORGANIZATION,
    [Vb365JobItemComposedItemType.PersonalSites]: lang.SITE,
    [Vb365JobItemComposedItemType.OneDriveFolders]: lang.ONEDRIVE_FOLDER,
    [Vb365JobItemComposedItemType.Team]: lang.TEAMS,
}[type]);
