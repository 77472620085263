/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { WizardSummary, useGlobalLang, useGlobalAppData } from '@veeam-vspc/shared/components';
import { capitalize, formatStr } from '@veeam-vspc/shared/helpers';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { getXmlEntries } from './helpers';
import { getXmlFromString } from '../../helpers';
import { readAsText } from 'core/utils/file-helpers';
import { StepLayout } from 'components/layouts/StepLayout';
import { normalizeDateWithTime } from 'core/utils/date-helpers';
import { DeploymentScheduleType, DistributionType, VbrDeployType } from '../../enums';

import type { VbrDeploySettingsWizardModel } from '../../entries';

export const Summary = ({ data }: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const [xml, setXml] = useState<XMLDocument>();
    const isVbrDeploy = data.vbrDeployType === VbrDeployType.Install;
    const isInfrastructureAgent = data.isInfrastructureAgent;
    const isScheduledImmediately = data.deploymentSchedule === DeploymentScheduleType.UpgradeNow;
    const isLicenseFromLicFile = data.licenseSource === VbrDeploymentLicenseSourceType.LicenseFile;
    const isLicenseFromAnswerFile = data.licenseSource === VbrDeploymentLicenseSourceType.AnswerFile;
    const licenseInfoText = isLicenseFromAnswerFile ? lang.GET_FROM_THE_CONFIGURATION_FILE : lang.ASSIGN_FROM_THE_VCSP_PULSE_PORTAL;

    useEffect(() => {
        if (data.installationParametersFile) {
            readAsText(data.installationParametersFile[0])
                .then(xmlString => setXml(getXmlFromString(xmlString)));
        }
    }, [data.installationParametersFile]);

    if (xml) {
        return (
            <StepLayout
                title={lang.SUMMARY}
                description={`${lang.REVIEW_SETTINGS_AND_CLICK_FINISH}.`}
            >
                <WizardSummary
                    fieldsets={[
                        {
                            title: lang.SOURCE,
                            fields: [
                                {
                                    label: `${lang.SOURCE}:`,
                                    value: data.distributionType === DistributionType.DownloadFromFileShare ?
                                        data.distribution.location : lang.FROM_VEEAM_WEB_SERVER,
                                },
                            ],
                        },
                        {
                            title: lang.ACCOUNT,
                            fields: [
                                {
                                    label: `${capitalize(lang.SERVICE_ACCOUNT, true)}:`,
                                    value: data.adminUserName,
                                },
                            ],
                        },
                        {
                            title: lang.SETUP_OPTIONS,
                            fields: getXmlEntries(xml)
                                .map(({ name, value, isHidden }) => ({
                                    label: `${name}:`,
                                    value: value || lang.DASH_SYMBOL,
                                    hidden: isHidden,
                                })),
                        },
                        isVbrDeploy ? {
                            title: lang.LICENSE,
                            fields: [
                                {
                                    label: `${lang.LICENSE}:`,
                                    value: isLicenseFromLicFile ? data.licenseFile[0].name : licenseInfoText,
                                },
                            ],
                        } : {},
                        isVbrDeploy && !isInfrastructureAgent ? {
                            title: lang.COMPANY_INFO,
                            fields: [
                                {
                                    label: `${lang.USERNAME}:`,
                                    value: data.tenantName,
                                },
                            ],
                        } : {},
                        {
                            title: lang.SCHEDULE,
                            fields: [
                                {
                                    label: isVbrDeploy ?
                                        `${formatStr(lang.SCHEDULED_0, lang.DEPLOYMENT.toLowerCase())}:` :
                                        `${formatStr(lang.SCHEDULED_0, lang.UPGRADE.toLowerCase())}:`,
                                    value: isScheduledImmediately
                                        ? isVbrDeploy
                                            ? lang.DEPLOY_NOW
                                            : lang.UPGRADE_NOW
                                        : normalizeDateWithTime(data.scheduleDate, formats),
                                },
                                {
                                    label: `${lang.TERMINATE_ALL_ACTIVITIES_AUTOMATICALLY}:`,
                                    value: data.stopAllActivities ? lang.YES : lang.NO,
                                    hidden: isVbrDeploy,
                                },
                                {
                                    label: `${lang.REBOOT_AUTOMATICALLY_IF_REQUIRED}:`,
                                    value: data.autoRebootAllowed ? lang.YES : lang.NO,
                                },
                            ],
                        },
                    ]}
                />
            </StepLayout>
        );
    }

    return null;
};

export const getSummaryStep = (title: string): WizardStep<VbrDeploySettingsWizardModel> => ({
    title,
    render: data => <Summary {...data} />,
});
