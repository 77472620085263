/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum JobType {
    Backup = 0,
    Archive = 2,
    Snapshot = 100,
    RemoteSnapshot = 101,
}
