/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, makeObservable, observable } from 'mobx';
import {
    ServerEventType,
    AsyncActionInfo,
    AsyncActionStatusUpdatedEvent,
    PermanentNotification,
    ServerEventNotification,
} from '@veeam-vspc/models/rest';
import { TimeUnitsInMs, isErrorThatNeedToBeShown } from '@veeam-vspc/shared/core';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/shared/helpers';

import type { TransportService, SocketTransportService } from '@veeam-vspc/shared/core';
import type {
    AsyncActionStatus,
    PermanentNotificationAddedEvent,
    PermanentNotificationsDismissedEvent,
    PermanentNotificationUpdatedEvent,
    UpdateServerEventsListenSettings,
    AsyncActionStatusUpdatedEventStatus,
} from '@veeam-vspc/models/rest';
import type { FormatsJson } from '@veeam-vspc/models/web-controllers';
import type { RequestErrorResponse, RequestResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { BellMessageTypes, ConnectionStatuses } from '../enums';
import { WebSocketConnections } from 'core/enums';
import { getStatusIconSrc } from 'core/utils';
import { getActionNameHelper } from '../utils';

import type { BellMessage } from '../interfaces';

export class AppBellStore {

    @observable messages: BellMessage[] = [];

    connectionStatus: ConnectionStatuses = ConnectionStatuses.None;

    protected dismissEventPromise: Promise<RequestResponse<any>> | null = null;
    protected intervalId: number | null = null;

    constructor(
        protected transportService: TransportService<RequestErrorResponse | Error>,
        protected socketTransportService: SocketTransportService,
        protected formats: FormatsJson,
        protected lang: VspcLang,
    ) {
        makeObservable(this);
    }

    connect() {
        return this.getInitialStateWithRespectDismiss()
            .then(() => this.connectToEvents());
    }

    disconnect() {
        return this.disconnectToEvents();
    }

    @computed
    get unread(): boolean {
        return this.messages?.length > 0;
    }

    setRefreshInterval() {
        return this.intervalId = window.setInterval(() => this.getInitialStateWithRespectDismiss(), 5 * TimeUnitsInMs.Minute);
    }

    clearRefreshInterval() {
        if (this.intervalId) {
            window.clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    getInitialStateWithRespectDismiss() {
        if (this.dismissEventPromise) {
            return this.dismissEventPromise
                .then(() => {
                    this.dismissEventPromise = null;

                    return this.getInitialState();
                })
                .catch(() => {
                    this.dismissEventPromise = null;

                    return this.getInitialState();
                });
        } else {
            return this.getInitialState();
        }
    }

    @action.bound
    getInitialState(): Promise<void> {
        return Promise.all([
            this.transportService.get<{ rcopAutoUpdate: true; }, RequestSuccessResponse<PermanentNotification[]>>('/PermanentNotifications', {
                rcopAutoUpdate: true,
            }),
            this.transportService.get<{ rcopAutoUpdate: true; }, RequestSuccessResponse<AsyncActionInfo[]>>('/AsyncActions', {
                rcopAutoUpdate: true,
            }),
        ])
            .then(([notificationsResp = {}, asyncActionsResp = {}]) => {
                // const { data: asyncActionItems = [] } = asyncActionsResp as RequestSuccessResponse<AsyncActionInfo[]>;
                const { data: notificationItems = [] } = notificationsResp as RequestSuccessResponse<PermanentNotification[]>;
                // const modifiedAsyncActionMessages = asyncActionItems
                //     .map(item => this.mapAsyncAction(item))
                //     .filter(item => item);
                const modifiedPermanentNotificationMessages = notificationItems.map(item => this.mapPermanentNotification(item));

                // this.messages = modifiedAsyncActionMessages.concat(modifiedPermanentNotificationMessages);
                this.setMessages(modifiedPermanentNotificationMessages);
            })
            .catch((err) => {
                if (isErrorThatNeedToBeShown(err)) {
                    console.error(err);
                }
            });
    }

    @action.bound
    connectToEvents(): Promise<void> {
        if (this.connectionStatus === ConnectionStatuses.Success) {
            return;
        }

        return this.socketTransportService.connectToWs(WebSocketConnections.NotificationBell, '/ServerEvents/Subscribe', {
            skipBaseUrl: true,
            onWebSocketClose: () => {
                this.connectionStatus = ConnectionStatuses.Closed;

                if (this.intervalId) {
                    // Reconnect every 30 seconds
                    window.setTimeout(() => this.connect(), 30 * TimeUnitsInMs.Second);
                }
            },
            onWebSocketError: () => {
                this.connectionStatus = ConnectionStatuses.Failed;
            },
        })
            .then(() => {
                this.connectionStatus = ConnectionStatuses.Success;

                const eventsToListen = {
                    eventsToListen: [
                        ServerEventType.PermanentNotificationsDismissedEvent,
                        ServerEventType.PermanentNotificationUpdatedEvent,
                        ServerEventType.PermanentNotificationAddedEvent,
                        // ServerEventType.AsyncActionAddedEvent,
                        // ServerEventType.AsyncActionStatusUpdatedEvent,
                        // ServerEventType.AsyncActionRemovedEvent,
                    ],
                } as UpdateServerEventsListenSettings;

                return this.socketTransportService.sendToWs(WebSocketConnections.NotificationBell, JSON.stringify(eventsToListen));
            })
            .catch(() => {
                // transport service block any connections (in case of auto logout)
            });
    }

    @action.bound
    disconnectToEvents(): void {
        if (this.connectionStatus === ConnectionStatuses.Success) {
            this.socketTransportService.disconnectWs(WebSocketConnections.NotificationBell);
        }
        this.connectionStatus = ConnectionStatuses.None;
        this.setMessages([]);
    }

    subscribeToEvents() {
        return this.socketTransportService.subscribeToWs(WebSocketConnections.NotificationBell, (message: string) => {
            try {
                this.onNotificationBellData(JSON.parse(message));
            } catch (e) {
                console.error('Failed to parse web socket message', e);
            }
        });
    }

    @action.bound
    onNotificationBellData(serverEvent: ServerEventNotification) {
        const { type, content } = serverEvent;

        switch (type) {
            case (ServerEventType.PermanentNotificationAddedEvent):
                return this.addNewPermanentEvent((content as PermanentNotificationAddedEvent).notificationContent);
            case (ServerEventType.PermanentNotificationsDismissedEvent):
                return this.removeNotification((content as PermanentNotificationsDismissedEvent).notificationUid);
            case (ServerEventType.PermanentNotificationUpdatedEvent):
                return this.updatePermanentNotificationEvent((content as PermanentNotificationUpdatedEvent).notificationContent);
            // case (ServerEventType.AsyncActionAddedEvent):
            //     return this.addNewAsyncAction((content as AsyncActionAddedEvent).asyncActionInfo);
            // case (ServerEventType.AsyncActionRemovedEvent):
            //     return this.removeNotification((content as AsyncActionRemovedEvent).asyncActionId);
            // case (ServerEventType.AsyncActionStatusUpdatedEvent):
            //     return this.updateAsyncActionStatus(content);
            default: return;
        }
    }

    dismissEvent(type: BellMessageTypes, eventId: string): Promise<RequestResponse<{}>> {
        this.setMessages(this.messages.filter(({ id }) => id !== eventId));

        switch (type) {
            case (BellMessageTypes.AsyncAction): return (this.dismissEventPromise = this.transportService.delete(`/AsyncActions/${eventId}`));
            case (BellMessageTypes.Permanent): return (this.dismissEventPromise = this.transportService.delete(`/PermanentNotifications/${eventId}`));
        }
    }

    protected mapPermanentNotification(item: PermanentNotification): BellMessage {
        return {
            id: item.instanceUid,
            title: item.title,
            titleIcon: getStatusIconSrc(item.severity),
            text: item.content,
            creationTime: renderShortDateAndTimeForColumn(item.updateTimeStamp, this.formats.netShortDate, this.formats.netShortTime),
            type: BellMessageTypes.Permanent,
            permanentNotificationPayload: item,
            onCloseClick: () => this.dismissEvent(BellMessageTypes.Permanent, item.instanceUid),
        };
    }

    protected mapAsyncAction(item: AsyncActionInfo): BellMessage {
        const { id, actionName, status } = item;
        const title: string | null = getActionNameHelper(actionName, status, this.lang);

        if (!title) {
            return null;
        }

        return {
            id,
            title,
            titleIcon: getStatusIconSrc(status),
            type: BellMessageTypes.AsyncAction,
            asyncActionPayload: item,
            onCloseClick: () => this.dismissEvent(BellMessageTypes.AsyncAction, item.id),
        };
    }

    protected updateStatusInAsyncAction(item: BellMessage, newStatus: AsyncActionStatus | AsyncActionStatusUpdatedEventStatus): BellMessage {
        const title: string | null = getActionNameHelper(item.asyncActionPayload?.actionName, newStatus, this.lang);

        return {
            ...item,
            title: title || item.title,
            titleIcon: getStatusIconSrc(newStatus),
            asyncActionPayload: {
                ...item.asyncActionPayload,
                status: newStatus as any,
            },
        };
    }

    @action.bound
    protected addNewPermanentEvent(content: PermanentNotification): void {
        const { instanceUid } = content;

        if (!this.messages.find(({ id }) => id === instanceUid)) {
            this.messages.unshift(this.mapPermanentNotification(content));
        }
    }

    @action.bound
    protected addNewAsyncAction(content: AsyncActionInfo): void {
        const { id: asyncActionId } = content;

        if (!this.messages.find(({ id }) => id === asyncActionId)) {
            const asyncActionItem: BellMessage | null = this.mapAsyncAction(content);

            // show only selected async actions
            asyncActionItem && this.messages.unshift(asyncActionItem);
        }
    }

    @action.bound
    protected updatePermanentNotificationEvent(content: PermanentNotification): void {
        const { instanceUid } = content;
        this.setMessages(this.messages.map(item => item.id === instanceUid ? this.mapPermanentNotification(content) : item));
    }

    @action.bound
    protected removeNotification(eventId: string): void {
        this.setMessages(this.messages.filter(({ id }) => id !== eventId));
    }

    @action.bound
    protected updateAsyncActionStatus({ id, status }: AsyncActionStatusUpdatedEvent): void {
        this.setMessages(this.messages.map(item => item.id === id ? this.updateStatusInAsyncAction(item, status) : item));
    }

    @action.bound
    protected setMessages(messages: BellMessage[]): void {
        this.messages = messages;
    }

}
