/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { LicenseUsageWithType } from '@veeam-vspc/models/web-controllers';

import { transformData } from '../helpers';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { CurrentUsageSeries } from '../helpers';

export class CurrentUsageService {
    static fetchData(
        transportService: TransportService<RequestErrorResponse | Error>,
        { productType, portalUser },
    ): Promise<ChartDataWithCategories<CurrentUsageSeries>> {
        return transportService
            .request('/License/GetLicenseUsage', { productType })
            .then((resp: RequestSuccessResponse<LicenseUsageWithType[]>) => transformData(resp.data, productType, portalUser));
    }
}
