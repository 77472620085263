/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { Bell } from './components';
import { AppBellContext, AppBellStore } from './stores';

export const AppBell: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService, socketTransportService } = useGlobalServices();
    const { formats } = useGlobalAppData();
    const store = useMemo(() => new AppBellStore(transportService, socketTransportService, formats, lang), []);

    return (
        <AppBellContext.Provider value={store}>
            <Bell />
        </AppBellContext.Provider>
    );
};
