/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, ConfiguredGrid, RequestGridSortDirections, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BackupServerJobRepository } from '@veeam-vspc/models/web-controllers';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import { kbToGb } from 'core/utils/size-helpers';

interface ConfigureBackupObjectsProps {
    hidePanel: () => void;
}

export const BackupRepositoryPanel: FC<ConfigureBackupObjectsProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<BackupServerJobRepository, unknown, unknown>>();
    const [selected, setSelected] = useState<BackupServerJobRepository>(() => api.current?.selected[0]);
    const { notificationService } = useGlobalServices();

    const handleApply = () => {
        if (!selected) {
            notificationService.warning(
                lang.VEEAM_BACKUP_AND_REPLICATION_REPOSITORIES,
                lang.SELECT_REPOSITORY_FROM_THE_LIST
            );

            return;
        }

        wizardStore.updateJobData({
            ...wizardStore.data,
            storage: {
                ...wizardStore.data.storage,
                backupRepositoryId: selected.instanceUid,
            },
        });
        hidePanel();
    };

    const handleCancel = () => {
        hidePanel();
    };

    const fetchData = (
        { page, limit, sort, filter }: RequestParams<BackupServerJobRepository>,
    ): Promise<RequestSuccessResponse<BackupServerJobRepository[]>> => {
        let items = wizardStore.repositories.slice();

        if (sort && sort.length) {
            items.sort((a, b) => {
                const { direction, property } = sort[0];

                const aValue = String(a[property]);
                const bValue = String(b[property]);

                if (direction === RequestGridSortDirections.Asc) {
                    return aValue
                        .localeCompare(
                            bValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                if (direction === RequestGridSortDirections.Desc) {
                    return bValue
                        .localeCompare(
                            aValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                return 0;
            });
        }

        items = items
            .filter(x => x.name
                .toLowerCase()
                .includes((filter.name || '').trim().toLowerCase()),
            );

        const chunk = items.slice((page * limit - limit), page * limit);

        return Promise.resolve({
            data: chunk,
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as RequestSuccessResponse<BackupServerJobRepository[]>);
    };

    return (
        <SidePanelForm
            title={lang.VEEAM_BACKUP_AND_REPLICATION_REPOSITORIES}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                api={api}
                stateId='backupJobs.VMs.virtualInfrastructure.jobWizard.storage.backupRepositories'
                columns={[
                    {
                        id: 'name',
                        title: capitalize(lang.BACKUP_REPOSITORY),
                        cell: rowData => rowData.name,
                    },
                    {
                        id: 'totalSpace',
                        title: lang.CAPACITY,
                        cell: rowData => kbToGb(rowData.totalSpace),
                    },
                    {
                        id: 'usedSpace',
                        title: lang.USED_SPACE,
                        cell: rowData => kbToGb(rowData.usedSpace),
                    },
                    {
                        id: 'availableSpace',
                        title: lang.FREE_SPACE,
                        cell: rowData => kbToGb(rowData.availableSpace),
                    },
                    {
                        id: 'backupServerName',
                        title: capitalize(lang.BACKUP_SERVER),
                        cell: rowData => rowData.backupServerName,
                    },
                ]}
                data={fetchData}
                disableAutoUpdate
                initialState={{
                    selected: wizardStore.selectedRepository ? [wizardStore.selectedRepository] : undefined,
                }}
                defaultColumnMinWidth={80}
                onSelectionChange={x => setSelected(x[0])}
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
            />
        </SidePanelForm>
    );
});
