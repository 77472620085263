/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalServices,
    ToolbarWithExport,
    PortalSpinner,
} from '@veeam-vspc/shared/components';
import {
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    formatStr,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { useFullPage } from '@veeam-vspc/shared/hooks';
import { FeatureNames } from '@veeam-vspc/shared/addons';
import { JobTypeRepresentation, VirtualPlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { ToolbarWithExportProps, UseModalActionsProps } from '@veeam-vspc/shared/components';
import type {
    BackupServerJobFilter,
    BackupServerJobModel,
    BackupServerJobRepository,
    CompanyVirtualInfrastructureRepresentation,
} from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import retryActionIconSrc from 'images/actions/retry.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import downloadVbrActionIconSrc from 'images/actions/download-vbr.png';
import powershellActionIconSrc from 'images/actions/powershell.png';
import deleteIcon from 'images/actions/remove.svg';
import assignToCompanyIcon from 'images/actions/assign-to-company.svg';
import newVSphereBackupJobIcon from 'images/actions/new-vsphere-backup-job.svg';
import editIcon from 'images/actions/edit.svg';
import settingsIcon from 'images/actions/settings.svg';
import { ConfigSectionIds, EJobStates, JobTypes } from 'core/enums';
import { useAsyncAction } from 'views/hooks';
import { JobWizard } from '../JobWizard/JobWizard';
import { waitFor } from 'core/utils';
import { Errors } from './enums';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';

export interface VirtualInfrastructureActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
    existsVbrShell: boolean;
    assignCompanyDialogActions: UseModalActionsProps;
}

export const VirtualInfrastructureActionToolbar: React.FC<VirtualInfrastructureActionToolbarProps> = (props) => {
    const { method = 'GetJobs', existsVbrShell, assignCompanyDialogActions, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { portalFeatures } = useGlobalAddons();
    const { fileTransportService, notificationService, transportService } = useGlobalServices();
    const gridApi = useGridApi<BackupServerJobModel, unknown, BackupServerJobFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    const [loading, setLoading] = useState<boolean>(false);

    const [
        jobWizardWrapper,
        { activate: activateJobWizardWrapper },
    ] = useFullPage<{
        companies: CompanyVirtualInfrastructureRepresentation[];
        repositories: BackupServerJobRepository[];
        data?: BackupServerBackupJobConfiguration;
        edit?: boolean;
    }>({
        render: ({ onClose, data }) => (
            <JobWizard
                data={data?.data}
                companies={data?.companies}
                repositories={data?.repositories}
                isEdit={data?.edit}
                onClose={onClose}
                onFinish={() => {
                    onClose();
                    gridApi.reloadGrid();
                }}
            />
        ),
    });
    return (
        <>
            <ToolbarWithExport
                {...restProps}
                lang={lang}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupServerJob/StartJob', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.CdpReplica,
                                    JobTypes.PostgreSqlLogBackup,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Running,
                                    EJobStates.Starting,
                                    EJobStates.Stopping,
                                ]),
                                disableForSelectionWithPropValueCallback<BackupServerJobModel>('jobScheduleState', (value, item) => (
                                    value === lang.DISABLED && item.jobType === JobTypes.BackupCopy
                                )),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => {
                            const { selected } = gridApi;
                            const doAction = (gracefully: boolean) => doAsyncAction('/BackupServerJob/StopJob', lang.STOP, {
                                ...gridApi.requestParamsForActions,
                                gracefully,
                            });
                            const skipGracefullyFor = [
                                JobTypes.SureBackup,
                                JobTypes.FileTapeBackup,
                                JobTypes.VmTapeBackup,
                            ];

                            if (selected.every(item => skipGracefullyFor.includes(item.jobType))) {
                                return doAction(false);
                            }

                            return notificationService.info(lang.STOP_JOB, lang.WE_CAN_STOP_THIS_JOB_GRACEFULLY, {
                                buttons: [
                                    {
                                        type: NotificationDialogsTextKeys.Ok,
                                        text: lang.GRACEFULLY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Yes,
                                        text: lang.IMMEDIATELY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Cancel,
                                        text: lang.CANCEL,
                                        view: ACTION_VIEW.secondary,
                                    },
                                ],
                            })
                                .then((btn) => {
                                    if (btn === NotificationDialogsTextKeys.Ok || btn === NotificationDialogsTextKeys.Yes) {
                                        return doAction(btn === NotificationDialogsTextKeys.Ok);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.CdpReplica,
                                    JobTypes.PostgreSqlLogBackup,
                                ]),
                                disableForSelectionWithPropValueCallback('lastSessionStateId', value => value !== EJobStates.Running),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: retryActionIconSrc,
                        text: lang.RETRY,
                        onClick: () => doAsyncAction('/BackupServerJob/RetryJob', lang.RETRY, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.BackupCopy,
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.SureBackup,
                                    JobTypes.VmTapeBackup,
                                    JobTypes.VmCopy,
                                    JobTypes.PostgreSqlLogBackup,
                                ]),
                                disableForSelectionWithPropValueCallback('lastSessionStateId', value => value !== EJobStates.Failed),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableJob', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.PostgreSqlLogBackup,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Enabling,
                                    EJobStates.Disabling,
                                ]),
                                disableForSelectionWithPropValueCallback('jobScheduleState', value => value !== lang.DISABLED),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableJob', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.PostgreSqlLogBackup,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Enabling,
                                    EJobStates.Disabling,
                                ]),
                                disableForSelectionWithPropValueCallback('jobScheduleState', value => (
                                    [
                                        lang.DISABLED.toLowerCase(),
                                        lang.NOT_SCHEDULED.toLowerCase(),
                                    ].includes((value as string)?.toLowerCase())
                                )),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                            setLoading(true);

                            promise
                                .then(() => {
                                    setLoading(false);

                                    Ext.create('RCOP.utils.DownloadLogsManager', {
                                        title: lang.DOWNLOAD_LOGS,
                                        url: '/BackupServerJob/DownloadJobLogs',
                                        params: gridApi.requestParamsForActions,
                                        showDialog: true,
                                    }).start();
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        },
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        id: 'separatorBeforeDownloadRemoteConsole',
                        type: TOOLBAR_ITEM_TYPE.separator,
                        hidden: !existsVbrShell,
                    },
                    {
                        id: 'downloadRemoteConsole',
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadVbrActionIconSrc,
                        text: lang.DOWNLOAD_REMOTE_BACKUP_CONSOLE,
                        onClick: () => fileTransportService.downloadFile('/BackupServer/DownloadVbrShell'),
                        hidden: !existsVbrShell,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: powershellActionIconSrc,
                        text: lang.POWERSHELL_SESSION,
                        onClick: () => {
                            const { selected } = gridApi;
                            const backupServerUid = selected[0].backupServerUid;
                            const backupServerName = selected[0].serverName;
                            const queryParams = [
                                `backupServerUid=${backupServerUid}`,
                                `backupServerName=${backupServerName}`,
                            ];
                            const isFeatureEnabled = portalFeatures.isFeatureEnabled(FeatureNames.RemoteComputerTerminalSessions);

                            if (isFeatureEnabled) {
                                return window.open(
                                    `${window.location.origin}/backupServer/terminal?${queryParams.join('&')}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                );
                            }

                            const infoMessage = portalUser.isPortalAdministrator()
                                ? lang.CANNOT_START_THE_REMOTE_POWERSHELL_SESSION
                                : lang.CANNOT_START_THE_REMOTE_POWERSHELL_SESSION_CONTACT;

                            return notificationService.info(lang.POWERSHELL_SESSION, infoMessage);
                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSingleSelection(),
                        ],
                    },
                    {
                        id: 'assignToCompanySeparator',
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.group,
                        id: 'createJobGroupId',
                        text: lang.JOB,
                        iconSrc: settingsIcon,
                        items: [
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: newVSphereBackupJobIcon,
                                id: 'newJobGroup',
                                text: lang.NEW_VSPHERE_BACKUP_JOB,
                                onClick: async() => {
                                    setLoading(true);

                                    try {
                                        const companies = await transportService
                                            .request<any, RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>>
                                            ('/HostedBackupServer/GetCompaniesVirtualInfrastructure')
                                        // eslint-disable-next-line arrow-body-style
                                            .then((response: RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>) => {
                                                return response.data;
                                            });

                                        if (!companies || companies.length === 0) {
                                            throw new ReferenceError(Errors.NoCompanies);
                                        }

                                        activateJobWizardWrapper({
                                            companies,
                                            repositories: [],
                                        });
                                    } catch (e) {
                                        if (e instanceof ReferenceError && e.message === Errors.NoCompanies) {
                                            if (portalUser.isAdminPortal()) {
                                                notificationService.info(
                                                    lang.NEW_VSPHERE_BACKUP_JOB,
                                                    lang.TO_CREATE_A_BACKUP_JOB_ASSIGN_HOSTED_BACKUP
                                                );
                                            } else {
                                                notificationService.info(
                                                    lang.NEW_VSPHERE_BACKUP_JOB,
                                                    lang.TO_ENABLE_THIS_FUNCTIONALITY
                                                );
                                            }
                                        }
                                    } finally {
                                        setLoading(false);
                                    }

                                },
                            },
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: editIcon,
                                text: lang.EDIT,
                                onClick: async() => {
                                    const job = gridApi.selected[0];

                                    if (
                                        job.jobType !== JobTypeRepresentation.Backup
                                        || job.virtualPlatformType !== VirtualPlatformTypeRepresentation.VSphere
                                    ) {
                                        return notificationService.error(lang.EDIT, lang.CANNOT_EDIT_SELECTED_JOB);
                                    }

                                    setLoading(true);
                                    let companyWithoutHostedResourcesName = '';

                                    try {
                                        const companies = await transportService
                                            .request<any, RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>>
                                            ('/HostedBackupServer/GetCompaniesVirtualInfrastructure')
                                            .then((response: RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>) => response.data);

                                        if (!companies || companies.length === 0) {
                                            throw new ReferenceError(Errors.NoCompanies);
                                        }

                                        const backupJob = await transportService
                                            .get<void, RequestSuccessResponse<BackupServerBackupJobConfiguration>>(
                                                `/infrastructure/backupServers/jobs/backupVmJobs/vSphere/${job.jobInstanceUid}/configuration`
                                            )
                                            .then((response: RequestSuccessResponse<BackupServerBackupJobConfiguration>) => response.data);

                                        // companies array contains only companies with hosted resources - that's how it works on backend
                                        const companyHasHostedResources = companies.find(company => (
                                            company.companyUid === backupJob.mappedOrganizationUid
                                        ));

                                        if (!companyHasHostedResources) {
                                            companyWithoutHostedResourcesName = backupJob.mappedOrganizationName;
                                            throw new ReferenceError(Errors.NoResources);
                                        }

                                        const repositories = await transportService
                                            .request<any, RequestSuccessResponse<BackupServerJobRepository[]>>(
                                                '/backupserverjob/getbackuprepositories',
                                                {
                                                    backupServerUid: companyHasHostedResources.hostedResources[0].backupServerUid,
                                                    companyUid: companyHasHostedResources.companyUid,
                                                    jobUid: backupJob.instanceUid,
                                                }
                                            )
                                            .then((response: RequestSuccessResponse<BackupServerJobRepository[]>) => response.data);

                                        activateJobWizardWrapper({
                                            edit: true,
                                            data: backupJob,
                                            companies,
                                            repositories,
                                        });
                                    } catch (e) {
                                        if (e instanceof ReferenceError) {
                                            switch (e.message) {
                                                case Errors.NoCompanies:
                                                    if (portalUser.isAdminPortal()) {
                                                        return notificationService.info(
                                                            lang.EDIT,
                                                            lang.TO_CREATE_A_BACKUP_JOB_ASSIGN_HOSTED_BACKUP
                                                        );
                                                    } else {
                                                        return notificationService.info(lang.EDIT, lang.TO_ENABLE_THIS_FUNCTIONALITY);
                                                    }

                                                case Errors.NoResources:
                                                    return notificationService.error(
                                                        lang.EDIT,
                                                        formatStr(lang.UNABLE_TO_EDIT_SELECTED_JOB, companyWithoutHostedResourcesName)
                                                    );

                                                default:
                                                    return notificationService.info(lang.EDIT, lang.TO_ENABLE_THIS_FUNCTIONALITY);
                                            }
                                        } else {
                                            throw e;
                                        }
                                    } finally {
                                        setLoading(false);
                                    }
                                },
                                decorators: [
                                    toolbarItemDecorators.disallowWithoutSingleSelection(),
                                    disableForSelectionWithPropValueCallback('canBeEdit', value => value !== true),
                                ],
                            },
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                iconSrc: deleteIcon,
                                text: lang.DELETE,
                                onClick: async() => {
                                    try {
                                        const companies = await transportService
                                            .request<any, RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>>
                                            ('/HostedBackupServer/GetCompaniesVirtualInfrastructure')
                                            .then((response: RequestSuccessResponse<CompanyVirtualInfrastructureRepresentation[]>) => response.data);

                                        if ((!companies || companies.length === 0) && !portalUser.isAdminPortal()) {
                                            throw new ReferenceError(Errors.NoCompanies);
                                        }

                                        notificationService.confirm(lang.REMOVE_JOB, lang.THIS_ACTION_WILL_REMOVE_ALL_SELECTED_JOBS)
                                            .then((btnKey: NotificationResponse) => {
                                                if (btnKey === NotificationDialogsTextKeys.Yes) {
                                                    doAsyncAction(
                                                        '/BackupServerJob/DeleteJob',
                                                        lang.REMOVE_JOB,
                                                        gridApi.requestParamsForActions,
                                                    );
                                                }
                                            });
                                    } catch (e) {
                                        if (e instanceof ReferenceError && e.message === Errors.NoCompanies) {
                                            return notificationService.info(lang.EDIT, lang.TO_ENABLE_THIS_FUNCTIONALITY);
                                        }
                                    }
                                },
                                decorators: [
                                    toolbarItemDecorators.disallowWithoutSelection(),
                                    disableForSelectionWithPropValueCallback<BackupServerJobModel>('canBeEdit', (value, item) => {
                                        if (portalUser.isPortalAdministrator()) {
                                            return false;
                                        }

                                        return value !== true;
                                    }),
                                ],
                            },
                        ],
                    },
                    {
                        id: 'assignToCompany',
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: assignToCompanyIcon,
                        text: lang.ASSIGN_TO_COMPANY,
                        onClick() {
                            assignCompanyDialogActions.activate();
                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSelection(),
                        ],
                    },
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
            {jobWizardWrapper}
            {asyncActionLoader}
        </>
    );
};
