/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { MAX_INTEGER_32 } from 'core/const';

import type { PublicCloudRdsBackupModel, CloudDatabasesDialogRequestParams, CloudDatabasesDialogExtraParams } from '../../interfaces';

export interface CloudDatabasesDialogProps {
    stateId: string;
    name: string;
    extraParams: CloudDatabasesDialogExtraParams;
    deactivate: () => void;
}

export const CloudDatabasesDialog: React.FC<CloudDatabasesDialogProps> = ({ stateId, name, extraParams, deactivate }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const columns: GridColumnProps<PublicCloudRdsBackupModel>[] = [
        {
            id: 'created',
            title: lang.DATE,
            cell: rowData => <GridDateColumn value={rowData.created} />,
        },
        {
            id: 'type',
            title: lang.TYPE,
            cell: rowData => rowData.type,
        },
        {
            id: 'region',
            title: lang.RESTORE_POINT_REGION,
            cell: rowData => rowData.region,
        },
    ];

    return (
        <Dialog
            header={name}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <ConfiguredGrid
                stateId={stateId}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: columns?.[0]?.id,
                    },
                }}
                columns={columns}
                data={(requestParams: CloudDatabasesDialogRequestParams<{}>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<PublicCloudRdsBackupModel[]>>(
                        '/PublicCloudRds/GetProtectedRdsBackupRestorePoints',
                        {
                            ...requestParams,
                            ...extraParams,
                        },
                    )}
                toggleable={false}
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <ToolbarWithExport
                            lang={lang}
                            idProperty={'uid'}
                            method={'GetPublicCloudRdsRestorePoints'}
                            extraParams={extraParams}
                        />
                    ),
                ]}
            />
        </Dialog>
    );
};
