/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGlobalAppData } from '@veeam-vspc/shared/components';

export const useDateFormats = () => {
    const { formats } = useGlobalAppData();

    return {
        is12HoursFormat: formats.netShortTime.indexOf('h') !== -1,
    };
};
