/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    colors,
    constants,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    Text,
} from '@veeam-vspc/shared/components';

import { Marker } from 'views/components/HighchartsWrapper/components/Tooltip';

const Status = ({ color, text }) => (
    <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center}>
        <Marker color={color} />
        <Text>{text}</Text>
    </StackView>
);

export const Legend = () => (
    <StackView
        align={STACK_ALIGN.end}
        direction={STACK_DIRECTION.row}
        distribution={STACK_DISTRIBUTION.center}
        gap={STACK_GAP.xl}
        style={{ height: constants.SIZE_L }}
    >
        {Object.entries({ Valid: colors.Grn400, Warning: colors.Y450, Error: colors.R800 }).map((item, i) => (
            <Status color={item[1]} key={i} text={item[0]} />
        ))}
    </StackView>
);
