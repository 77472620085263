/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { AmazonObjectType } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedAwsWorkloadsContext } from './contexts/ProtectedAwsWorkloadsContext';
import { ProtectedAwsWorkloadsStore } from './stores/ProtectedAwsWorkloadsStore';
import { ProtectedAwsWorkloadsService } from './services/protected-aws-workloads.service';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useProtectedAwsWorkloadsStore } from './hooks/use-protected-aws-workloads-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const protectedAwsWorkloadsStore = useProtectedAwsWorkloadsStore();
    const protectedAwsWorkloadsService = useMemo(() =>
        new ProtectedAwsWorkloadsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_AWS_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedAwsWorkloadsStore.setAmazonObjectType}
                stateId={protectedAwsWorkloadsStore.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedAwsWorkloadsStore.amazonObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: AmazonObjectType.EC2, text: lang.EC2 },
                    { id: AmazonObjectType.Rds, text: lang.RDS },
                    { id: AmazonObjectType.Efs, text: lang.EFS },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedAwsWorkloadsStore.amazonObjectType}
            params={params}
            service={protectedAwsWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedAwsWorkloads: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ProtectedAwsWorkloads.AmazonObjectType`;
    const initialType = useLocalStorageValue<AmazonObjectType>(stateId, AmazonObjectType.EC2);

    const protectedAwsWorkloadsStore = useMemo(() => new ProtectedAwsWorkloadsStore(initialType, stateId), [initialType, stateId]);

    return (
        <ProtectedAwsWorkloadsContext.Provider value={protectedAwsWorkloadsStore}>
            <ChartLayout />
        </ProtectedAwsWorkloadsContext.Provider>
    );
};
