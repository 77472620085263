/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    isValid,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    TextInput,
    useExternalFormApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { UserRoles } from 'core/enums';
import { RadioGroup } from 'components/controls/RadioGroup';
import { StepLayout } from 'components/layouts/StepLayout';
import { LocationMode } from './location-mode';

import type { SSORuleModel } from 'views/pages/RolesAndUsersPage/components/Base/interfaces/sso-rule-model';

const stepValidate = (data: SSORuleModel) => {
    const validator = new FormValidator(data);

    if (data.locationMode === LocationMode.UseLocationClaim) {
        validator.validate('model.locationsMappingSourceClaimType').string()
            .required();
    }

    return validator.result();
};

const Locations = (wizardStepData: WizardStepData<SSORuleModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
            key='managedLocations'
        >
            <StepLayout
                title={lang.LOCATIONS}
                description={lang.SPECIFY_LOCATIONS_THAT_WILL_BE_ACCESSIBLE}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <RadioGroup
                        value={data.locationMode}
                        onChange={(value: LocationMode) => {
                            formApi.setValue('locationMode', value);
                        }}
                        buttons={[
                            {
                                label: lang.USE_DEFAULT_LOCATION,
                                value: LocationMode.UseDefaultLocation,
                            },
                            {
                                label: lang.RECEIVE_LOCATIONS_LIST,
                                value: LocationMode.UseLocationClaim,
                            },
                        ]}
                    />

                    <TextInput
                        name='model.locationsMappingSourceClaimType'
                        label={lang.SPECIFY_CLAIM}
                        disabled={data.locationMode === LocationMode.UseDefaultLocation}
                        inlineLabel
                    />
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getLocationsStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: wizardStepData => <Locations {...wizardStepData} />,
    validate({ data }) {
        return isValid(stepValidate, data);
    },
    isHidden: ({ data }) => {
        if (data.model.role === UserRoles.CompanyLocationUser || data.model.role === UserRoles.CompanyLocationAdministrator) {
            return false;
        }

        return true;
    },
});
