/**
 * Copyright © Veeam Software Group GmbH.
 */

export function calculateDate(dateString: string) {
    if (!dateString) {
        return '-';
    }

    const date = new Date(Date.parse(dateString));

    if (date <= new Date(0)) {
        return '-';
    }

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}
