/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { ConfigItemIds, ConfigSectionIds } from 'core/enums';
import { allConfigsRoutes, allCustomRoutes, allHomeRoutes } from '../routes';
import { RouteIds, RoutePaths } from '../../enums';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

export const vacAdministratorConfig: UserConfigData<typeof UserRoleRepresentation.VacAdministrator, ConfigSectionIds> = {
    role: UserRoleRepresentation.VacAdministrator,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.Overview],
                        allHomeRoutes[RouteIds.JobsSummary],
                        allHomeRoutes[RouteIds.ActiveAlarms],
                        allHomeRoutes[RouteIds.ProtectedDataSummary],
                        allHomeRoutes[RouteIds.BillingSummary],
                        allHomeRoutes[RouteIds.Tops],
                        allHomeRoutes[RouteIds.Trends],
                        allHomeRoutes[RouteIds.BackupJobs],
                        allHomeRoutes[RouteIds.FailoverPlans],
                        allHomeRoutes[RouteIds.ProtectedData],
                        allHomeRoutes[RouteIds.Companies],
                        allHomeRoutes[RouteIds.Resellers],
                        allHomeRoutes[RouteIds.Discovery],
                        allHomeRoutes[RouteIds.Invoices],
                        allHomeRoutes[RouteIds.Reports],
                    ],
                },
                {
                    path: RoutePaths.Configs,
                    component: AppPortalBodyLayout,
                    items: [
                        allConfigsRoutes[RouteIds.Welcome],
                        allConfigsRoutes[RouteIds.EventLog],
                        allConfigsRoutes[RouteIds.Security],
                        allConfigsRoutes[RouteIds.CloudConnectServers],
                        allConfigsRoutes[RouteIds.RolesUsers],
                        allConfigsRoutes[RouteIds.Notifications],
                        allConfigsRoutes[RouteIds.BackupPolicies],
                        allConfigsRoutes[RouteIds.CompanyInfo],
                        allConfigsRoutes[RouteIds.SubscriptionPlans],
                        allConfigsRoutes[RouteIds.Settings],
                        allConfigsRoutes[RouteIds.Licensing],
                        allConfigsRoutes[RouteIds.Support],
                        allConfigsRoutes[RouteIds.ProductUpdates],
                        allConfigsRoutes[RouteIds.Plugins],
                    ],
                },
                {
                    path: `${RoutePaths.Plugins}/*`,
                    redirectTo: `/${RoutePaths.Configs}/plugins`,
                },
            ],
        },
        allCustomRoutes[RouteIds.AdjustReports],
        allCustomRoutes[RouteIds.BackupServerTerminal],
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.EditUserProfileWizard,
            items: {
                loginInfoStep: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.CompanyInfoProfile,
            items: {
                profileAliasField: ConfigStates.Hidden,
                emailTooltipIcon: ConfigStates.Visible,
                proPartnerIdField: ConfigStates.Hidden,
                instanceUidField: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BillingSummaryCloudConnectResourcesCloudConnectReplica,
            items: {
                [ConfigItemIds.CloudHostCpuRatio]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.MonthlyUsageReportDialog,
            items: {
                [ConfigItemIds.MonthlyUsageReport]: ConfigStates.Visible,
            },
        },
    ],
};
