/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    CONTROL_SIZE,
    FieldLayout,
    FormLayout,
    LinkButton,
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
    ToolbarSeparator,
    useGlobalLang,
    useModal,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../stores';
import { FormFieldNames } from '../../enums';
import { HostIcon, LabelBold, NotificationTestDialog } from './components';
import { ServerSettingsDialogWrapper } from '../ServerSettingsDialogWrapper';
import { getSmtpHostContent } from '../../helpers';

import type { SmtpHostContent } from '../../interfaces';

export const SmtpGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { formStore: { formIsDirty }, smtpStore: { certVerificationStatus, hasHost, settings }, onResetClick } = useConfigurationStore();

    const hostContent: SmtpHostContent = getSmtpHostContent(settings, certVerificationStatus, lang);

    const [notificationTestDialog, notificationTestDialogActions] = useModal({
        render: ({ deactivate }) => <NotificationTestDialog deactivateDialog={deactivate} />,
    });

    const [emailServerSettingsDialog, emailServerSettingsDialogActions] = useModal({
        render: ({ deactivate }) => <ServerSettingsDialogWrapper deactivateDialog={deactivate} />,
    });

    return (
        <>
            {emailServerSettingsDialog}
            {notificationTestDialog}

            <FormLayout inlineLabel>
                <FieldLayout label={<LabelBold labelText={lang.SMTP_SERVER} />}>
                    <StackView align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                        <LinkButton
                            iconBefore={<HostIcon iconType={hostContent.iconType} />}
                            onClick={emailServerSettingsDialogActions.activate}
                        >
                            {hostContent.mainText}
                        </LinkButton>

                        <Text>{hostContent.additionalText}</Text>
                    </StackView>
                </FieldLayout>

                <FieldLayout label={<LabelBold labelText={lang.CONFIGURATION} />}>
                    <StackView align={STACK_ALIGN.center}>
                        <LinkButton
                            disabled={!hasHost || formIsDirty}
                            onClick={notificationTestDialogActions.activate}
                        >
                            {lang.TEST_EMAIL_NOTIFICATION}
                        </LinkButton>

                        <ToolbarSeparator />

                        <LinkButton
                            disabled={!hasHost || formIsDirty}
                            onClick={onResetClick}
                        >
                            {lang.RESET_EMAIL_SERVER_SETTINGS}
                        </LinkButton>
                    </StackView>
                </FieldLayout>

                <TextInput
                    label={<LabelBold labelText={lang.DEFAULT_SENDER} />}
                    maxLength={512}
                    name={FormFieldNames.DefaultFrom}
                    placeholder={lang.SET_THE_DEFAULT_SENDER}
                    size={CONTROL_SIZE.full}
                />
            </FormLayout>
        </>
    );
});
