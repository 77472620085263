/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { INDENT, Text, TEXT_SIZE, TEXT_VIEW, TEXT_WHITE_SPACE } from '@veeam-vspc/shared/components';

interface FormNoteProps {
    children: React.ReactNode;
    whiteSpace?: TEXT_WHITE_SPACE;
}

export const FormNote: React.FC<FormNoteProps> = ({ children, whiteSpace }) => (
    <Text
        leader={INDENT.s}
        size={TEXT_SIZE.s}
        view={TEXT_VIEW.secondary}
        whitespace={whiteSpace || TEXT_WHITE_SPACE.normal}
    >
        {children}
    </Text>
);
