/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    LinkButton,
    usePageWizardStore,
    useGlobalLang,
    Text,
    TEXT_VIEW,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
} from '@veeam-vspc/shared/components';
import { capitalize } from 'lodash';
import { plural } from '@veeam-vspc/shared/helpers';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { BackupServerBackupJobConfiguration } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ConfigureBackupObjects, ConfigureExcludeObjects } from './components';
import protectedObjectsIconSrc from 'images/actions/protected-objects.png';
import excludedObjectsIconSrc from 'images/actions/exluded-objects.png';
import { useJobWizardStore } from '../../stores';

import type { JobWizardStore } from '../../stores';

export const getScopeStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: lang.SCOPE,
    validate: () => {
        const validationResult = store.includedItems.length > 0;

        if (validationResult === false) {
            notificationService.warning(capitalize(lang.SCOPE), lang.SELECT_OBJECTS_TO_INCLUDE);
        }

        return validationResult;
    },
    render: data => <ScopeStep {...data} />,
});

export const ScopeStep = observer((wizardStepData: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore();
    const wizardStore = useJobWizardStore();
    const protectedObjectsLength = wizardStore.data.virtualMachines.includes.length;
    const excludedObjectsLength = wizardStore.data.virtualMachines.excludes?.vms?.length;

    return (
        <StepLayout
            title={lang.SCOPE}
            description={lang.SELECT_INSTANCES_TO_PROTECT_OR_EXCLUDE}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <LinkButton
                        onClick={() => wizardContext.openPanel(hide => <ConfigureBackupObjects hidePanel={hide} />)}
                        iconBefore={protectedObjectsIconSrc}
                        error={!wizardStepData.validationState.isCommon() && wizardStore.includedItems.length === 0}
                    >
                        {
                            protectedObjectsLength
                                ? plural(protectedObjectsLength, lang.OBJECT_INCLUDED, lang.OBJECTS_INCLUDED)
                                : lang.OBJECTS_TO_INCLUDE
                        }
                    </LinkButton>

                    <Text view={TEXT_VIEW.secondary}>{lang.SELECT_OBJECTS_TO_BE_INCLUDED_IN_THE_BACKUP}</Text>
                </StackView>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <LinkButton
                        onClick={() => wizardContext.openPanel(hide => <ConfigureExcludeObjects hidePanel={hide} />)}
                        iconBefore={excludedObjectsIconSrc}
                    >
                        {
                            excludedObjectsLength
                                ? plural(excludedObjectsLength, lang.OBJECT_EXCLUDED, lang.OBJECTS_EXCLUDED)
                                : lang.OBJECTS_TO_EXCLUDE
                        }
                    </LinkButton>

                    <Text view={TEXT_VIEW.secondary}>{lang.SELECT_OBJECTS_TO_BE_EXCLUDED_FROM_THE_BACKUP}</Text>
                </StackView>
            </StackView>
        </StepLayout>
    );
});
