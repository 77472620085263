/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { Dialog, FieldLayout, SPACING_XL, FormLayout, Text, DIALOG_SIZE, useGlobalLang, useGlobalAppData } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';
import type { UseModalActionsProps } from '@veeam-vspc/shared/components';

import { normalizeDateWithTime } from 'core/utils/date-helpers';
import { useTestedSmtpSettings } from '../../../context';

const CustomFieldLayout = styled(FieldLayout)`
    .vm-FieldLabel {
        label {
            max-height: 18px;
        }
    }

    .vm-FormFieldControl {
        vertical-align: middle;
        padding-left: ${SPACING_XL};
    }
`;

const TextWrap = styled(Text)`
    white-space: normal;
`;

const DialogWrap = styled(Dialog)`
    max-width: 720px;
`;

export interface CertificateInfoDialogProps {
    deactivateDialog: UseModalActionsProps['deactivate'];
}

export const CertInfoDialog: React.FC<CertificateInfoDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { deactivateDialog } = props;
    const { serverCertificate } = useTestedSmtpSettings();
    const { subjectName, issuerName, hash, serialNumber, notBefore, notAfter, signatureAlgorithm } = serverCertificate;

    return (
        <DialogWrap
            onRequestClose={deactivateDialog}
            header={capitalize(lang.SECURITY_CERTIFICATE)}
            size={{
                width: DIALOG_SIZE.auto,
                height: DIALOG_SIZE.auto,
            }}
            actions={[
                {
                    text: lang.CLOSE,
                    onClick: deactivateDialog,
                },
            ]}
        >
            <FormLayout inlineLabel>
                <CustomFieldLayout label={lang.SUBJECT}>
                    <TextWrap>{subjectName}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.ISSUER}>
                    <TextWrap>{issuerName}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.THUMBPRINT}>
                    <TextWrap>{hash}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.SERIAL_NUMBER}>
                    <TextWrap>{serialNumber}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.VALID_FROM}>
                    <TextWrap>{normalizeDateWithTime(new Date(Date.parse(notBefore)), formats) || lang.DASH_SYMBOL}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.VALID_TO}>
                    <TextWrap>{normalizeDateWithTime(new Date(Date.parse(notAfter)), formats) || lang.DASH_SYMBOL}</TextWrap>
                </CustomFieldLayout>

                <CustomFieldLayout label={lang.SIGNATURE_ALGORITHM}>
                    <TextWrap>{signatureAlgorithm}</TextWrap>
                </CustomFieldLayout>
            </FormLayout>
        </DialogWrap>
    );
};
