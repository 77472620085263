/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface SupportCasesFile {
    SupportCases: ComponentType<any>;
}

export const SupportPage = () => (
    <LazyLoadedComponent<SupportCasesFile>
        importPromise={() => (import('./components/SupportCases') as Promise<SupportCasesFile>)}
        pageName={'SupportCases'}
    />
);
