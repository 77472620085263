/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConfigAdvancedFilterItemsIds {
    AlarmProductVspc = 'Alarm.Product.Vspc',
    AlarmProductCloudConnect = 'Alarm.Product.CloudConnect',
    AlarmScope = 'Alarm.Scope',
    AlarmScopeInternal = 'Alarm.Scope.Internal',
    AlarmArea = 'Alarm.Area',
    BackupJobsVMsVirtualInfrastructureBackupServerType = 'BackupJobs.VMs.VirtualInfrastructure.BackupServerType',
    BackupJobsVMsVirtualInfrastructurePlatformType = 'BackupJobs.VMs.VirtualInfrastructure.PlatformType',
    ReportsManagingCompany = 'Reports.ManagingCompany',
    ReportsReportConfiguration = 'Reports.ReportConfiguration',
}
