/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ConfigurationRequestsStoreContext } from './configuration-requests-store-context';

import type { ConfigurationRequestsStore } from '../configuration-requests-store';

export const useConfigurationRequestsStore = (): ConfigurationRequestsStore => useContext(ConfigurationRequestsStoreContext);
