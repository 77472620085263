/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, Spinner } from '@veeam-vspc/shared/components';

import { HostIconTypeEnum } from '../../../../enums';
import errorIcon from 'images/statuses/error.svg';
import warningIcon from 'images/statuses/warning.svg';
import successIcon from 'images/statuses/success.svg';

export interface HostIconProps {
    iconType: HostIconTypeEnum;
}

export const HostIcon: React.FC<HostIconProps> = (props) => {
    const { iconType } = props;

    switch (iconType) {
        case HostIconTypeEnum.Error:
            return <Icon src={errorIcon} />;

        case HostIconTypeEnum.Loading:
            return <Spinner size={16} />;

        case HostIconTypeEnum.Warning:
            return <Icon src={warningIcon} />;

        case HostIconTypeEnum.Success:
        default:
            return <Icon src={successIcon} />;
    }
};
