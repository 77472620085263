/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    BackupServerMbaLicenseRepresentation,
    JobMode,
    JobStateRepresentation,
    SystemTypeRepresentation,
    VbrBackupAgentJobKind,
} from '@veeam-vspc/models/web-controllers';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { ToolbarProps, AdvancedFilterValue, ConfiguredAdvancedFilterOptions } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import managedByBackupServerModeIconSrc from 'images/filters/vbr-job-modes/job.svg';
import managedByAgentModeIconSrc from 'images/filters/vbr-job-modes/policy.svg';
import backupJobKindIconSrc from 'images/filters/job-types/backup.png';
import backupCopyJobKindIconSrc from 'images/filters/job-types/backup-copy.png';
import serverAgentLicenseTypeIconSrc from 'images/filters/agent-job-types/server.png';
import sureBackupJobTypeIconSrc from 'images/filters/job-types/sure-backup.png';
import workstationAgentLicenseTypeIconSrc from 'images/filters/agent-job-types/workstation.png';
import otherOsTypeIconSrc from 'images/view_help.png';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useOsTypesAdvancedFilter } from 'views/components/filters/OsTypesFilters';
import { ConfigSectionIds } from 'core/enums';

export interface ManagedByBackupServerFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const ManagedByBackupServerFilterToolbar: React.FC<ManagedByBackupServerFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { None, Success, Warning, Failed, Running } = JobStateRepresentation;
    const statuses = [
        { title: lang.SUCCESS, value: Success },
        { title: lang.WARNING, value: Warning },
        { title: lang.ERROR, value: Failed },
        { title: lang.RUNNING, value: Running },
        { title: lang.INFO, value: None },
    ];

    const osTypesFilterPropName = 'osTypes';
    const osTypesAdvancedFilter = useOsTypesAdvancedFilter({ propName: osTypesFilterPropName });

    osTypesAdvancedFilter.items.push(
        {
            iconSrc: otherOsTypeIconSrc,
            label: lang.OTHER,
            value: {
                id: SystemTypeRepresentation.Unknown,
                name: osTypesFilterPropName,
            },
        }
    );

    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_BACKUP_AGENTS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.JOB_MODE,
                        items: [
                            {
                                iconSrc: managedByBackupServerModeIconSrc,
                                label: lang.MANAGED_BY_BACKUP_SERVER_JOB,
                                value: {
                                    id: JobMode.ManagedByBackupServer,
                                    name: 'modes',
                                },
                            },
                            {
                                iconSrc: managedByAgentModeIconSrc,
                                label: lang.MANAGED_BY_AGENT,
                                value: {
                                    id: JobMode.ManagedByAgent,
                                    name: 'modes',
                                },
                            },
                        ],
                    },
                    osTypesAdvancedFilter,
                ],
            },
            {
                groups: [
                    {
                        title: capitalize(lang.JOB_TYPE, true),
                        items: [
                            {
                                iconSrc: backupJobKindIconSrc,
                                label: lang.BACKUP,
                                value: {
                                    id: VbrBackupAgentJobKind.BackupJob,
                                    name: 'jobKind',
                                },
                            },
                            {
                                iconSrc: backupCopyJobKindIconSrc,
                                label: lang.BACKUP_COPY,
                                value: {
                                    id: VbrBackupAgentJobKind.CopyJob,
                                    name: 'jobKind',
                                },
                            },
                            {
                                iconSrc: sureBackupJobTypeIconSrc,
                                label: lang.SUREBACKUP,
                                value: {
                                    id: VbrBackupAgentJobKind.SureBackupJob,
                                    name: 'jobKind',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.OPERATION_MODE, true),
                        items: [
                            {
                                iconSrc: serverAgentLicenseTypeIconSrc,
                                label: lang.SERVER,
                                value: {
                                    id: BackupServerMbaLicenseRepresentation.Server,
                                    name: 'licenseTypes',
                                },
                            },
                            {
                                iconSrc: workstationAgentLicenseTypeIconSrc,
                                label: lang.WORKSTATION,
                                value: {
                                    id: BackupServerMbaLicenseRepresentation.Workstation,
                                    name: 'licenseTypes',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='computerName'
                            placeholder={lang.COMPUTER}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.POLICY_OR_JOB}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='backupServerName'
                            placeholder={capitalize(lang.BACKUP_SERVER)}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                            propName={'statuses'}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            sectionId={ConfigSectionIds.BackupJobsComputersManagedByBackupServer}
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
