
/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormTitle,
    useGlobalLang,
    TextInput,
    CONTROL_SIZE,
    NoLabelGroup,
    Checkbox,
    useExternalFormApi,
    NumberInput,
    PasswordInput,
    FormValidator,
    ValidationState,
    Textarea,
} from '@veeam-vspc/shared/components';
import { FieldLayout, FileInput } from '@veeam/components';

import type { BackupServerCredentialsLinuxInput } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../../../stores';
import { getCleanedFormData } from './helpers';

import type { LinuxCredsFormData } from './interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: BackupServerCredentialsLinuxInput) => Promise<void>;
}

const formValidate = (data: LinuxCredsFormData) => {
    const validator = new FormValidator(data);

    validator
        .validate('username')
        .string()
        .maxLength(256)
        .required();

    validator
        .validate('sshPort')
        .number()
        .min(0)
        .max(65535)
        .required();

    if (data.usePrivateKey) {
        validator
            .validate('privateKey')
            .string()
            .required();
    }

    if (data.autoElevated && (data.useSu || data.addToSudoers)) {
        validator
            .validate('rootPassword')
            .string()
            .required();
    }

    return validator.result();
};

export const LinuxCredentialsDialog = observer((props: Props) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useExternalFormApi<LinuxCredsFormData>();
    const [validationState] = useState(new ValidationState());
    const wizardStore = useJobWizardStore();

    const [privateKeyRequired, setPrivateKeyRequired] = useState(false);
    const [addAccountToTheSudoers, setAddAccountToTheSudoers] = useState(false);
    const [useSu, setUseSu] = useState(false);
    const [autoElevated, setAutoElevated] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            header={lang.LINUX_ACCOUNT}
            actions={[
                {
                    text: lang.OK,
                    disabled: !isFormValid,
                    onClick: async() => {
                        setLoading(true);

                        try {
                            await props.onConfirm(getCleanedFormData(formApi.value));
                        } finally {
                            setLoading(false);
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form<LinuxCredsFormData>
                externalFormApi={formApi}
                validationState={validationState}
                validate={formValidate}
                value={{
                    username: '',
                    password: '',
                    mappedOrganizationUid: wizardStore.selectedCompany.companyUid,
                    description: '',
                    sshPort: 22,
                    autoElevated: false,
                    addToSudoers: false,
                    useSu: false,
                    usePrivateKey: privateKeyRequired,
                    privateKey: '',
                    passphrase: '',
                    rootPassword: '',
                }}
                onChange={() => {
                    setIsFormValid(formApi.isValid());
                }}
            >
                <FormLayout>
                    <FormTitle>{lang.ACCOUNT_CREDENTIALS}</FormTitle>

                    <FormLayout inlineLabel>
                        <TextInput
                            label={lang.USERNAME}
                            name={'username'}
                            size={CONTROL_SIZE.full}
                        />

                        <PasswordInput
                            label={lang.PASSWORD}
                            name={'password'}
                            size={CONTROL_SIZE.full}
                        />
                    </FormLayout>

                    <NoLabelGroup
                        content={(
                            <Checkbox
                                name={'usePrivateKey'}
                                checked={privateKeyRequired}
                                onChange={value => setPrivateKeyRequired(value)}
                            >
                                {lang.PRIVATE_KEY_IS_REQUIRED_FOR_THIS_CONNECTION}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout inlineLabel disabled={!privateKeyRequired}>
                                <FieldLayout
                                    inlineLabel
                                    label={`${lang.PRIVATE_KEY}:`}
                                >
                                    <FileInput
                                        placeholder={`[${lang.SET_PRIVATE_KEY_FROM_FILE}]`}
                                        buttonText={lang.BROWSE}
                                        onChange={async(newFiles) => {
                                            const content = await newFiles[0].text();
                                            formApi.setValue('privateKey', content);
                                        }}
                                        size={CONTROL_SIZE.full}
                                    />
                                </FieldLayout>

                                <PasswordInput
                                    label={lang.PASSPHRASE}
                                    name={'passphrase'}
                                    size={CONTROL_SIZE.full}
                                />

                                <NumberInput
                                    label={lang.SSH_PORT}
                                    name='sshPort'
                                    minValue={0}
                                    maxValue={65535}
                                />

                            </FormLayout>
                        )}
                    />

                    <FormTitle>{lang.NON_ROOT_ACCOUNT}</FormTitle>

                    <NoLabelGroup
                        content={(
                            <Checkbox
                                name={'autoElevated'}
                                onChange={value => setAutoElevated(value)}
                            >
                                {lang.ELEVATE_ACCOUNT_PRIVILEGES_AUTOMATICALLY}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout disabled={!autoElevated}>
                                <Checkbox
                                    name={'addToSudoers'}
                                    onChange={value => setAddAccountToTheSudoers(value)}
                                    disabled={!autoElevated}
                                >
                                    {lang.ADD_ACCOUNT_TO_THE_SUDOERS_FILE}
                                </Checkbox>

                                <Checkbox
                                    name={'useSu'}
                                    onChange={value => setUseSu(value)}
                                    disabled={!autoElevated}
                                >
                                    {lang.USE_SU_IF_SUDO_FAILS}
                                </Checkbox>

                                <PasswordInput
                                    inlineLabel
                                    label={lang.ROOT_PASSWORD}
                                    name={'rootPassword'}
                                    disabled={!autoElevated || (!addAccountToTheSudoers && !useSu)}
                                />
                            </FormLayout>
                        )}
                    />

                    <FieldLayout
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.full}
                    >
                        <Textarea
                            maxLength={256}
                            name='description'
                            size={CONTROL_SIZE.full}
                            rows={4}
                        />
                    </FieldLayout>


                </FormLayout>
            </Form>
        </Dialog>
    );
});
