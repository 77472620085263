/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { STACK_DIRECTION, STACK_GAP, StackView, useGlobalServices } from '@veeam-vspc/shared/components';
import { ConfigStates } from '@veeam-vspc/shared/core';

import { LicenseStatus } from './components/LicenseStatus';
import { UsageReports } from './components/UsageReports';
import { ManagedWorkloads } from './components/ManagedWorkloads';
import { StatusBar } from './components';
import { ConfigItemIds, ConfigSectionIds } from 'core/enums';

export const Overview = () => {
    const { configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.LicensingOverview);

    const licenseStatusToolbarIsHidden = sectionConfig
        ? sectionConfig.getItemState(ConfigItemIds.LicenseStatusToolbar) === ConfigStates.Hidden
        : false;

    return (
        <StackView direction={STACK_DIRECTION.column} style={{ height: '100%', overflow: 'hidden' }}>
            {!licenseStatusToolbarIsHidden && <StatusBar />}

            <StackView
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.m}
                style={{ flex: 1, overflow: 'auto' }}
            >
                <ManagedWorkloads />
                <LicenseStatus />
                <UsageReports />
            </StackView>
        </StackView>
    );
};
