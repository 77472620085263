/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

export const resourceTypes = (lang: VspcLang): Array<{ text: string; value: Vb365JobItemComposedItemType; }> => [
    {
        text: lang.USER,
        value: Vb365JobItemComposedItemType.User,
    },
    {
        text: lang.GROUP,
        value: Vb365JobItemComposedItemType.Group,
    },
    {
        text: lang.SITE,
        value: Vb365JobItemComposedItemType.Site,
    },
    {
        text: lang.TEAMS,
        value: Vb365JobItemComposedItemType.Team,
    },
    {
        text: lang.PERSONAL_SITES,
        value: Vb365JobItemComposedItemType.PersonalSites,
    },
    {
        text: lang.CURRENT_ORGANIZATION,
        value: Vb365JobItemComposedItemType.PartialOrganization,
    },
];
