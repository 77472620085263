/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ComboboxItem } from 'core/interfaces';

export const transformData = (data, lang): ComboboxItem[] => {
    const list = [{
        id: '',
        text: lang.ALL_HARDWARE_PLANS,
    }];

    data.forEach(plan => list.push({
        id: plan.instanceUid,
        text: `${plan.siteName ? (`${plan.siteName}\\`) : ''}${plan.name}`,
    }));

    return list;
};
