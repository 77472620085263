/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { transformData } from '../helpers';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { UsageTrendData } from '../interfaces';

export class UsageTrendService {
    static fetchData(transportService: TransportService<RequestErrorResponse | Error>, { productType }): Promise<ChartDataWithCategories> {
        return transportService
            .request('/License/GetLicenseUsageTrend', { productType })
            .then((resp: RequestSuccessResponse<UsageTrendData>) => {
                resp.data.dailyTrends.forEach(trend => trend.counters.forEach((c) => {
                    c.count = c.count === null ? null : Math.round(c.count);
                }));

                return transformData(resp.data.dailyTrends);
            });
    }
}
