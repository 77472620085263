/**
 * Copyright © Veeam Software Group GmbH.
 */

import { WeekInMonthRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

export const getWeekInMonth = (lang: VspcLang) => (
    [
        {
            id: WeekInMonthRepresentation.First,
            text: lang.FIRST,
        }, {
            id: WeekInMonthRepresentation.Second,
            text: lang.SECOND,
        }, {
            id: WeekInMonthRepresentation.Third,
            text: lang.THIRD,
        }, {
            id: WeekInMonthRepresentation.Fourth,
            text: lang.FOURTH,
        }, {
            id: WeekInMonthRepresentation.Last,
            text: lang.LAST,
        }, {
            id: WeekInMonthRepresentation.Day,
            text: lang.THIS_DAY,
        },
    ]
);
