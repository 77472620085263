/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, useGlobalLang } from '@veeam-vspc/shared/components';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import successIconSrc from 'images/statuses/success.svg';
import infoIconSrc from 'images/statuses/info.svg';
import warningIconSrc from 'images/statuses/warning.svg';
import updatingIcon from 'images/statuses/updating.svg';
import actionPatchIcon from 'images/statuses/action-patch.png';
import errorIconSrc from 'images/statuses/error.svg';

export const AgentVersionColumn = ({ rowData }: { rowData: DiscoveredComputerModel; }) => {
    const lang = useGlobalLang<VspcLang>();
    let iconSrc = successIconSrc;
    const versionStatus = rowData.agentVersion;
    const versionStatusLower = versionStatus?.toLowerCase() || '';

    if (versionStatusLower.includes('unknown') || versionStatusLower.includes('warning') || versionStatusLower.includes('out-of-date')) {
        iconSrc = warningIconSrc;
    }

    if (versionStatusLower.includes('updating')) {
        iconSrc = updatingIcon;
    }

    if (versionStatusLower.includes('failed to update')) {
        iconSrc = errorIconSrc;
    }

    if (versionStatusLower.includes('patch available') || rowData.patchInProgress) {
        iconSrc = actionPatchIcon;
    }

    if (versionStatusLower.includes('update required')) {
        iconSrc = infoIconSrc;
    }

    if (versionStatusLower.includes('n/a') || versionStatusLower.includes('not installed')) {
        iconSrc = null;
    }

    return (
        <ActionLinkColumn
            contentIconBefore={iconSrc}
            renderContent={() => iconSrc ? versionStatus : lang.DASH_SYMBOL}
            isLink={false}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.NO_INFO}
                    onRequestClose={deactivate}
                    actions={[
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                >
                    <ParsedText textOrConfig={lang.NO_INFO} />
                </MessageBox>
            )}
        />
    );
};
