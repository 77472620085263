/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/shared/components';
import { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';

import { AuthTypes, FormFieldNames } from '../../../enums';

import type { ServerSettingsDialogFormData } from '../../../interfaces';

export const formValidate = (formData: ServerSettingsDialogFormData) => {
    const useAuth = formData.useAuth;
    const validator = new FormValidator(formData);
    const isBasicType = formData.authType === AuthTypes.Basic;
    const isAzureModernType = formData.smtp.oAuth2Credential.clientSettings.kind === OAuth2ClientSettingsKind.Azure;

    if (isBasicType) {
        validator
            .validate(FormFieldNames.Host)
            .string()
            .required();

        validator
            .validate(FormFieldNames.Port)
            .number()
            .min(1)
            .max(65535)
            .required();

        validator
            .validate(FormFieldNames.TlsMode)
            .string()
            .required();

        if (useAuth) {
            validator
                .validate(FormFieldNames.UserName)
                .string()
                .required();

            validator
                .validate(FormFieldNames.Password)
                .string()
                .required();
        }
    } else {
        if (isAzureModernType) {
            validator
                .validate(FormFieldNames.TenantId)
                .string()
                .required();
        }

        validator
            .validate(FormFieldNames.ClientId)
            .string()
            .required();

        validator
            .validate(FormFieldNames.ClientSecret)
            .string()
            .required();
    }

    return validator.result();
};
