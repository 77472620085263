/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { BackupTasksRatioService } from './services/backup-tasks-ratio.service';
import { BaseTrendChart } from '../../../BaseTrendChart';

export const BackupTasksRatio: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    const params = {
        colors: [palette.B400],
        type: 'column',
        yMax: 100,
        yMin: 0,
        yTitle: `${lang.RATIO}, %`,
    };

    return (
        <BaseTrendChart
            params={params}
            service={new BackupTasksRatioService(transportService, lang)}
            title={{ text: `${capitalize(lang.SUCCESSFUL_BACKUP_TASKS_RATIO)} (%)` }}
        />
    );
};
