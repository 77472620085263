/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum LicenseName {
    Licensed = 'licensed',
    New = 'new',
    Quota = 'quota',
    Rental = 'rental',
    Unlicensed = 'unlicensed',
}
