/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Combobox, FieldLayout } from '@veeam/components';
import { useGlobalAppStorage } from '@veeam-vspc/shared/components';

import type { ComboboxProps } from '@veeam-vspc/shared/components';

interface ComboboxWithStateProps<OptionT, ValueT> extends Omit<ComboboxProps<OptionT, ValueT>, 'name'> {
    label?: string;
    stateId: string;
    name?: string;
    value: ValueT;
}

export const StatefulCombobox = <T, K>(props: ComboboxWithStateProps<T, K>) => {
    const appStorage = useGlobalAppStorage();
    const { label = null, onChange, stateId, value, ...restProps } = props;
    const localStorageValue = appStorage.getItem(stateId);

    if (!localStorageValue || (localStorageValue !== value)) {
        appStorage.setItem(stateId, value as string);
    }

    return (
        <FieldLayout
            inlineLabel
            label={label}
        >
            <Combobox
                {...restProps}
                value={value}
                onChange={(v, i) => {
                    appStorage.setItem(stateId, v as string);
                    onChange && onChange(v, i);
                }}
            />
        </FieldLayout>
    );
};
