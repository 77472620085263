/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum StatusTypes {
    Acknowledged = 'acknowledge',
    Canceled = 'canceled',
    Created = 'created',
    Enabled = 'enabled',
    Error = 'error',
    Info = 'info',
    Installed = 'backup-agent-type-installed',
    NotInstalled = 'backup-agent-type-not-installed',
    Pending = 'pending',
    Processing = 'processing',
    Running = 'running',
    Starting = 'starting',
    Stopped = 'stopped',
    Stopping = 'stopping',
    Success = 'success',
    Syncing = 'syncing',
    Unlicensed = 'unlicensed-backup-server',
    Updating = 'updating',
    Warning = 'warning',
}
