/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    DurationColumn,
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
    useModal,
} from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { useLocation } from 'react-router-dom';

import type {
    RequestParams,
    GridStore,
    GridColumnProps } from '@veeam-vspc/shared/components';
import type { BackupServerJobFilter, BackupServerJobModel } from '@veeam-vspc/models/web-controllers';
import type { BaseRequestData, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds, EJobStates, JobTypes } from 'core/enums';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { BackupStatusColumn } from '../../../BackupStatusColumn';
import {
    JobStatisticsColumn,
    ProcessedVmsColumn,
    VirtualInfrastructureActionToolbar,
    VirtualInfrastructureFilterToolbar,
} from './components';
import { CdpErrorsColumn } from './components/CdpErrorsColumn';
import { AssignCompanyDialog } from 'views/components/AssignCompanyDialog/AssignCompanyDialog';


export const VirtualInfrastructure: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { pathname } = useLocation();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const [existsVbrShell, setExistsVbrShell] = useState(false);

    const gridApi = useRef<GridStore<BackupServerJobModel, unknown, BackupServerJobFilter>>();

    const [assignCompanyDialog, assignCompanyDialogActions] = useModal({
        render: ({ deactivate }) => (
            <AssignCompanyDialog
                method='/BackupServerJob/AssignJobToCompany'
                gridApi={gridApi.current}
                deactivate={deactivate}
            />
        ),
    });

    const gridStateId = `${pathname}.VirtualInfrastructure`;
    const columns: GridColumnProps<BackupServerJobModel>[] = [
        {
            id: 'jobName',
            title: lang.JOB,
            cell: rowData => rowData.jobName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'serverName',
            title: lang.SERVER,
            cell: rowData => rowData.serverName,
        },
        {
            id: 'lastSessionState',
            title: lang.JOB_STATUS,
            cell: rowData => (
                <BackupStatusColumn
                    statusName={rowData.lastSessionState as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            id: 'jobTypeName',
            title: lang.TYPE,
            cell: rowData => rowData.jobTypeName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'virtualPlatformType',
            title: lang.PLATFORM,
            cell: rowData =>
                portalEnums.getEnumDescription('virtualPlatformTypeRepresentation', rowData.virtualPlatformType),
            hidden: true,
        },
        {
            id: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastSessionStartTime}
                />
            ),
        },
        {
            id: 'lastSessionEndTime',
            title: lang.END_TIME,
            cell: rowData => (
                <GridDateColumn
                    value={rowData.lastSessionEndTime}
                />
            ),
            hidden: true,
        },
        {
            id: 'lastSessionDuration',
            title: lang.DURATION,
            cell: rowData => (
                <DurationColumn
                    value={rowData.lastSessionDuration}
                />
            ),
        },
        {
            id: 'avgDuration',
            title: lang.AVG_DURATION,
            cell: rowData => (
                <DurationColumn
                    value={rowData.avgDuration}
                />
            ),
            hidden: true,
        },
        {
            id: 'destination',
            title: lang.TARGET,
            cell: rowData => (
                <TextColumn>
                    {rowData.jobType === JobTypes.SureBackup ? ColumnTexts.DashSymbol : rowData.destination}
                </TextColumn>
            ),
            hidden: true,
        },
        {
            id: 'jobStatistics',
            title: lang.JOB_STATISTICS,
            cell: (rowData) => {
                if (rowData.jobType === JobTypes.SureBackup) {
                    return (
                        <TextColumn>
                            { ColumnTexts.DashSymbol }
                        </TextColumn>
                    );
                }

                return (
                    <JobStatisticsColumn
                        jobType={rowData.jobType}
                        jobUid={rowData.jobInstanceUid}
                        jobName={rowData.jobName}
                    />
                );
            },
            isSortable: false,
        },
        {
            id: 'cdpSessionErrorsCounter',
            title: lang.CDP_SESSION_ERRORS,
            cell: rowData => (
                <CdpErrorsColumn
                    errorsCount={rowData.CdpSessionErrorsCounter}
                    stateId={gridStateId}
                    jobName={rowData.jobName}
                    jobUid={rowData.jobInstanceUid}
                />
            ),
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'processedVmCounter.total',
            title: lang.PROCESSED_OBJECTS,
            width: 200,
            cell: rowData => (
                <ProcessedVmsColumn
                    value={rowData.processedVmCounter}
                    stateId={gridStateId}
                    jobType={rowData.jobType}
                    jobUid={rowData.jobInstanceUid}
                    jobName={rowData.jobName}
                />
            ),
        },
        {
            id: 'processingRate',
            title: lang.PROCESSING_RATE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.jobType === JobTypes.SureBackup ? null : rowData.processingRate}
                    unitName={'B/s'}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            id: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.jobType === JobTypes.SureBackup ? null : rowData.transferred}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'bottleneck',
            title: lang.BOTTLENECK,
            cell: rowData => (
                rowData.jobType === JobTypes.SureBackup ? null : rowData.bottleneck
            ),
            hidden: true,
        },
        {
            id: 'jobScheduleState',
            title: lang.SCHEDULE,
            cell: rowData => (
                <TextColumn defaultValue={lang.NO_INFO}>
                    {rowData.lastSessionStateId === EJobStates.Idle ? null : rowData.jobScheduleState}
                </TextColumn>
            ),
            hidden: true,
        },
    ];

    return (
        <>
            <ConfiguredGrid
                stateId={gridStateId}
                api={gridApi}
                sectionId={ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        key: 'lastSessionStateId',
                        direction: GridSortDirections.Asc,
                    },
                }}
                columns={columns}
                data={(requestParams: RequestParams<BackupServerJobFilter>) => (
                    Promise.all([
                        transportService.request<typeof requestParams, RequestSuccessResponse<BackupServerJobModel[]>>('/BackupServerJob/GetJobs', {
                            ...requestParams,
                        }),
                        transportService.request<BaseRequestData, RequestSuccessResponse<boolean>>('/BackupServer/ExistsVbrShell', {
                            rcopAutoUpdate: true,
                        }),
                    ])
                        .then(([resp, existsVbrShellResp]: [RequestSuccessResponse<BackupServerJobModel[]>, RequestSuccessResponse<boolean>]) => {
                            setExistsVbrShell(existsVbrShellResp.data as boolean);

                            return resp;
                        })
                )}
                isRowInactive={rowData => !rowData.scheduleEnabled}
                toggleable={true}
                selection={{
                    field: 'jobInstanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <VirtualInfrastructureFilterToolbar />,
                    () => (
                        <VirtualInfrastructureActionToolbar
                            idProperty={'jobInstanceUid'}
                            existsVbrShell={existsVbrShell}
                            selectedOnly
                            assignCompanyDialogActions={assignCompanyDialogActions}
                            excludeFromExport={[
                                'jobStatistics',
                            ]}
                        />
                    ),
                ]}
            />

            {assignCompanyDialog}
        </>
    );
};
