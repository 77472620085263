/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import {
    Link,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    usePageWizardStore,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { LocationsPanel } from './components/LocationsPanel';

import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

const Locations = (wizardStepData: WizardStepData<CompanyUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { data, validationState } = wizardStepData;
    const wizardContext = usePageWizardStore<CompanyUserModel>();
    const locationsCount = wizardStepData.data.locations.length;

    const openPanel = () => {
        wizardContext.openPanel(hide => <LocationsPanel {...wizardStepData} hidePanel={hide} />);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.locations.length === 0) {
                openPanel();
            }
        }
    });

    return (
        <StepLayout
            title={lang.LOCATIONS}
            description={lang.SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <Link onClick={openPanel}>
                    {`${locationsCount} ${RCOP.utils.String.plural(locationsCount, lang.LOCATION.toLowerCase())} ${lang.SELECTED.toLowerCase()}...`}
                </Link>
            </StackView>
        </StepLayout>
    );
};

export const getLocationsStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ data }) => {
        const roles = [
            UserRoleRepresentation.CompanyLocationUser,
            UserRoleRepresentation.CompanyLocationAdministrator,
            UserRoleRepresentation.Subtenant,
        ];

        return !roles.includes(data.userRole);
    },
    validate({ data }) {
        return data.locations.length !== 0;
    },
    render: data => <Locations {...data} />,
});


