/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Rules } from './components/DiscoveryComputers/components/Rules';

export interface DiscoveryRulesPageProps {
    currentPath: string;
}

export const DiscoveryRulesPage: React.FC<DiscoveryRulesPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <Rules stateId={currentPath} />
    </TabBarBodyLayout>
);
