/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { constants } from '@veeam-vspc/shared/components';

const Header = styled.div`
    font-weight: bold;
    padding: 8px 8px 0;
`;

export const Marker = styled.div<{ color: string; symbol?: string; }>`
    background-color: ${props => props.symbol === 'ring' ? 'transparent' : props.color};
    border-color: ${props => props.color};
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    height: ${constants.SIZE_XS};
    margin-right: 4px;
    width: ${constants.SIZE_XS};
`;

const Name = styled.div`
    display: inline-block;
`;

const TooltipStyled = styled.div`
    table {
        border-spacing: ${constants.SPACING_S};

        td {
            padding: 0;
        }
    }
`;

interface RowProps {
    color: string;
    name: string;
    symbol: string;
    value: number | string;
}

interface TooltipProps {
    children: React.ReactElement | React.ReactElement[];
    title: number | string;
}

export const Row = ({ color, name, symbol, value }: RowProps): React.ReactElement => (
    <tr>
        <td>
            <Marker color={color} symbol={symbol} />

            <Name>
                {`${name}:`}
            </Name>
        </td>

        <td>{value}</td>
    </tr>
);

export const Tooltip = ({ children, title }: TooltipProps): React.ReactElement => (
    <TooltipStyled>
        {title && (
            <Header>
                {title}
            </Header>
        )}

        <table>
            {children}
        </table>
    </TooltipStyled>
);
