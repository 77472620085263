/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SmtpSettingsTlsMode } from '@veeam-vspc/models/rest';

import type { SmtpSettings } from '@veeam-vspc/models/rest';

import { SMTPCertStatus } from '../enums';

export const getOriginalCertStatus = (
    tlsMode: SmtpSettingsTlsMode,
    hash: SmtpSettings['exclusivelyAcceptedCertificateHash']
): SMTPCertStatus => {
    const { NoCert, Trusted, UntrustedAccepted } = SMTPCertStatus;

    if (tlsMode === SmtpSettingsTlsMode.None) {
        return NoCert;
    } else if (hash === null) {
        return Trusted;
    }

    return UntrustedAccepted;
};
