/**
 * Copyright © Veeam Software Group GmbH.
 */

import { plural } from '@veeam-vspc/shared/helpers';
import { JobStateRepresentation } from '@veeam-vspc/models/web-controllers';

import type { IconType } from '@veeam-vspc/shared/components';
import type { BackupServerJobProcessedVmCounterModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import infoStatusIconSrc from 'images/statuses/info.svg';
import errorStatusIconSrc from 'images/statuses/error.svg';
import warningStatusIconSrc from 'images/statuses/warning.svg';
import successStatusIconSrc from 'images/statuses/success.svg';

export const getIconSrc = (status: JobStateRepresentation): IconType => {
    switch (status) {
        case JobStateRepresentation.Failed: return errorStatusIconSrc;
        case JobStateRepresentation.Warning: return warningStatusIconSrc;
        case JobStateRepresentation.Success: return successStatusIconSrc;

        default: return infoStatusIconSrc;
    }
};

export const getColumnText = (
    { total: totalCount = 0, warning: warningCount = 0, failed: failedCount = 0 }: BackupServerJobProcessedVmCounterModel,
    lang: VspcLang,
): string => {
    let columnText = plural(totalCount, lang.OBJECT.toLowerCase());
    const failedMsg = `${failedCount} ${lang.FAILED.toLowerCase()}`;
    const warningMsg = plural(warningCount, lang.WARNING.toLowerCase());

    if (failedCount && warningCount) {
        columnText += ` (${failedMsg}, ${warningMsg})`;
    } else if (failedCount) {
        columnText += ` (${failedMsg})`;
    } else if (warningCount) {
        columnText += ` (${warningMsg})`;
    }

    return columnText;
};
