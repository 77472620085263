/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { Text, View, VIEW_BG_VIEW, VIEW_BORDER, SELECTION_MODE, useGlobalLang } from '@veeam-vspc/shared/components';
import { Dropdown, List, TEXT_WEIGHT } from '@veeam/components';
import { GRID_HEIGHT_MODE } from '@veeam/components/lib/Grid/types';
import styled from 'styled-components';

import type { CellRendererProps } from '@veeam/components/lib/Grid/types';
import type { BaseLocationData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { DropdownControl } from '../DropdownControl/DropdownControl';
import { GlobalFiltersDefaults } from '../../enums';

interface LocationsFilterProps {
    data: BaseLocationData[];
    selected: number;
    disabled?: boolean;
    onSelectionChange: (id: number) => void;
}

const dropdownSizing = {
    width: '350px',
};

const ListContainer = styled.div`
    width: ${dropdownSizing.width};
    max-height: 400px;
    overflow: auto;
`;

export const LocationsFilter: React.FC<LocationsFilterProps> = ({
    data,
    selected,
    disabled,
    onSelectionChange,
}) => {
    const [query, setQuery] = useState<string>(getSelectedItemName());
    const [filteredData, setFilteredData] = useState<BaseLocationData[]>(data);
    const lang = useGlobalLang<VspcLang>();

    useEffect(() => {
        setQuery(getSelectedItemName());
    }, [selected]);

    useEffect(() => {
        setFilteredData(filterListByQuery(query, data));
    }, [data]);

    const handleSelect = (ids: number[]) => {
        const node = data.find(x => x.id === ids[0]);

        setQuery(node.name);
        onSelectionChange(node.id);
    };

    function getSelectedItemName(): string {
        const node = data.find(x => x.id === selected);

        return node.name;
    }

    const filterListByQuery = (value: string, data: BaseLocationData[]) => data.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));

    const handleQueryChange = (value: string, activateFn: () => void) => {
        const list = filterListByQuery(value, data);
        setFilteredData(list);
        setQuery(value);

        if (value) {
            activateFn();
        }
    };

    const isDirty = () => query !== getSelectedItemName();

    const nodeRenderer = ({ cellData: value }: CellRendererProps<BaseLocationData>) => {
        if (isDirty() && query.toLowerCase() !== value.toLowerCase()) {
            const parts = value
                .split(new RegExp(`(${query
                    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                    .toLowerCase()})`, 'gi'));

            return (
                <Text>
                    {
                        parts.map((part, i) => (
                            <Text
                                key={i}
                                weight={part.toLowerCase() === query.toLowerCase() ? TEXT_WEIGHT.bold : TEXT_WEIGHT.normal}
                                style={{ display: 'inline' }}
                            >
                                {part}
                            </Text>
                        ))
                    }
                </Text>
            );
        }

        return <Text>{value}</Text>;
    };

    const handleHide = () => {
        setQuery(getSelectedItemName());
    };

    const controlTitle = disabled ? lang.FILTERING_DATA_BY_LOCATIONS : lang.SELECT_A_LOCATION_TO_FILTER;

    const hasSelection: boolean = Number.isFinite(selected) && selected > 0;

    return (
        <div title={controlTitle}>
            <Dropdown
                onDidHide={handleHide}
                renderControl={({ button, actions, isActive }) => (
                    <DropdownControl
                        disabled={disabled}
                        button={button}
                        actions={actions}
                        query={query}
                        onQueryChange={handleQueryChange}
                        active={isActive}
                        selected={hasSelection}
                    />
                )}
                renderPopover={({ popover, content }) => (
                    <View
                        {...popover}
                        backgroundView={VIEW_BG_VIEW.normal}
                        borderAll={VIEW_BORDER.brand}
                        size={dropdownSizing}
                    >
                        {content}
                    </View>
                )}
                renderContent={({ actions }) => (
                    <ListContainer>
                        <List
                            selectionMode={SELECTION_MODE.single}
                            data={(isDirty() ? filteredData : data) as Record<string, unknown>[]}
                            selected={[selected]}
                            valueGetter={(loc: BaseLocationData) => loc?.id ?? GlobalFiltersDefaults.LocationDefaultId}
                            onChange={(ids) => {
                                handleSelect(ids);
                                actions.hide();
                            }}
                            textGetter={(loc: BaseLocationData) => loc?.name ?? query}
                            cellRenderer={nodeRenderer}
                            heightMode={GRID_HEIGHT_MODE.native}
                        />
                    </ListContainer>
                )}
            />
        </div>
    );
};

