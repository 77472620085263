/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { AuditLogModel, AuditLogParam, CompanyParam, CompanyShortData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ActivityLogFilterToolbar } from './components';
import { useTransportRequest } from 'views/hooks';


interface ActivityLogProps {
    stateId: string;
}

export const ActivityLog: React.FC <ActivityLogProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.ActivityLog`;
    const { transportService } = useGlobalServices();
    const {
        loading,
        data: companiesList,
        forceRequest: getCompaniesList,
    } = useTransportRequest<CompanyParam, CompanyShortData[]>('/Company/GetCompanyList', {
        filter: {
            excludeResellers: false,
            excludeSystem: false,
        },
        sort: [{ property: 'name', direction: GridSortDirections.Asc }],
    });

    const columns: GridColumnProps<AuditLogModel>[] = [
        {
            id: 'userName',
            title: lang.USERNAME,
            cell: rowData => rowData.userName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'date',
            title: lang.TIME,
            cell: rowData => <GridDateColumn value={rowData.date} />,
        },
        {
            id: 'description',
            title: lang.DESCRIPTION,
            cell: rowData => rowData.description,
            defaultValue: lang.NO_INFO,
        },
    ];

    return !loading
        ? (
            <ConfiguredGrid
                stateId={gridStateId}
                columns={columns}
                initialState={{
                    filters: {
                        companyId: -1,
                    },
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: columns?.[2]?.id,
                    },
                }}
                defaultColumnMinWidth={80}
                data={(requestParams: AuditLogParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<AuditLogModel[]>>('/Configuration/GetAccessLogs', { ...requestParams })}
                toggleable={true}
                toolbars={[
                    () => <ActivityLogFilterToolbar companiesList={companiesList as CompanyShortData[] ?? []} getCompaniesList={getCompaniesList} />,
                ]}
                selection={{
                    field: 'id',
                    checkbox: false,
                    multiple: false,
                }}
            />
        ) : null;
};
