/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    Search,
    TextColumn,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { ProtectedVirtualMachineRestoreType } from '@veeam-vspc/models/web-controllers';

import type { ProtectedVirtualMachineBackup, ProtectedVmBackupParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PageDialogProps, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointColumn } from 'views/pages/ProtectedDataPage/components/RestorePointColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { getDestinationColumnHeader, getDialogTitle, getJobNameColumnHeader, getSearchFieldPlaceholder } from '../../utils/title-helpers';
import { VirtualInfrastructureRestorePointColumn } from '../../../VirtualInfrastructureRestorePointColumn';
import { RestorePointColumnsFields } from 'views/pages/ProtectedDataPage/components/RestorePointColumn/components/RestorePointDialog';

export interface VirtualInfrastructureDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    name: string;
    extraParams: Pick<ProtectedVmBackupParam, 'vmInstanceUid' | 'backupServerUid' | 'protectionType'>;
    vmInstanceUid?: string;
}

export const VirtualInfrastructureDialog: React.FC<VirtualInfrastructureDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { portalEnums } = useGlobalAddons();
    const idProperty = 'backupUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        name,
        extraParams,
    } = props;
    const { protectionType } = extraParams;
    const columns: GridColumnProps<ProtectedVirtualMachineBackup>[] = [
        {
            id: 'jobName',
            title: getJobNameColumnHeader(protectionType, lang),
            cell: rowData => rowData.jobName,
        },
        {
            id: 'lastRun',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastRun}
                />
            ),
        },
        protectionType !== ProtectedVirtualMachineRestoreType.CdpReplica && {
            id: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.sourceSize} />,
        },
        {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
        },
        {
            id: 'restorePoints',
            title: lang.RESTORE_POINTS,
            cell: (rowData) => {
                const dialogStateId = `${stateId}.${ProtectedVirtualMachineRestoreType[protectionType]}`;
                const isReplicaType = protectionType === ProtectedVirtualMachineRestoreType.Replica ||
                    protectionType === ProtectedVirtualMachineRestoreType.CdpReplica;

                if (isReplicaType) {
                    return (
                        <RestorePointColumn
                            noteBarIsHidden
                            value={rowData.restorePoints}
                            stateId={dialogStateId}
                            name={rowData.jobName}
                            extraParams={{
                                vmInstanceUid: rowData.vmInstanceUid,
                                backupUid: rowData.backupUid,
                            }}
                            hiddenColumns={[
                                RestorePointColumnsFields.SourceSize,
                                RestorePointColumnsFields.BackedUpData,
                                RestorePointColumnsFields.RestorePointSize,
                            ]}
                        />
                    );
                }

                // @TODO transform to universal RestorePointColumn
                return (
                    <VirtualInfrastructureRestorePointColumn
                        value={rowData.restorePoints}
                        stateId={dialogStateId}
                        name={rowData.jobName}
                        protectionType={protectionType}
                        isOneBackupPerVmEnabled={rowData.isOneBackupPerVmEnabled}
                        extraParams={{
                            backupUid: rowData.backupUid,
                            vmInstanceUid: rowData.vmInstanceUid,
                        }}
                    />
                );
            },
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        ![ProtectedVirtualMachineRestoreType.Replica, ProtectedVirtualMachineRestoreType.CdpReplica].includes(protectionType) && {
            id: 'backupSize',
            title: lang.BACKUP_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.backupSize} />,
        },
        {
            id: 'destination',
            title: getDestinationColumnHeader(protectionType, lang),
            cell: rowData => rowData.destination,
        },
        {
            id: 'backupServer',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServer,
        },
    ];

    return (
        <PageDialog
            title={`${getDialogTitle(protectionType, lang)} - ${name}`}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: ProtectedVmBackupParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<ProtectedVirtualMachineBackup[]>>(
                            '/ProtectedWorkloads/GetVmsRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={false}
                    selection={{
                        field: idProperty,
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                lang={lang}
                                idProperty={idProperty}
                                extraParams={extraParams}
                                view={TOOLBAR_VIEW_TYPE.spotted}
                                method={'GetProtectedVmRestorePoints'}
                                items={[
                                    {
                                        type: TOOLBAR_ITEM_TYPE.customControl,
                                        render: () => (
                                            <Search
                                                name='jobName'
                                                placeholder={getSearchFieldPlaceholder(protectionType, lang)}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
