/**
 * Copyright © Veeam Software Group GmbH.
 */

export const removeEmptyProperties = <T,>(obj: T): T => {
    const newObj: T = (Array.isArray(obj) ? [] : {}) as T;

    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmptyProperties(v);
        } else if (v !== null && v !== undefined) {
            newObj[k] = obj[k];
        }
    });

    return newObj;
};
