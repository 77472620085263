/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import type { SmtpSettings } from '@veeam-vspc/models/rest';

import { TestedSmtpSettingsContext } from './tested-smtp-settings-context';

export const useTestedSmtpSettings = (): SmtpSettings => useContext(TestedSmtpSettingsContext);
