/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { INDENT, Text, TEXT_SIZE, TEXT_VIEW } from '@veeam-vspc/shared/components';

interface LabelHelpTextProps {
    label: string;
    helpText: string;
}

export const LabelWithHelpText: React.FC<LabelHelpTextProps> = props => (
    <>
        <Text>{`${props.label}:`}</Text>

        <Text
            leader={INDENT.xs}
            size={TEXT_SIZE.s}
            trailer={INDENT.xs}
            view={TEXT_VIEW.secondary}
        >
            {props.helpText}
        </Text>
    </>
);
