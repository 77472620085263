/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    GridSortDirections,
    Search,
    AdvancedFilter,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    ConfiguredToolbar,
} from '@veeam-vspc/shared/components';
import {
    BackupAgentPlatformRepresentation,
    EConnectionStatus,
    ESupportOsStatusRepresentation,
    EVacAgentStatusInteg,
    EVacVersionStatus,
    SystemTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { AdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { DiscoveryRuleGridDataModel, DiscoveryRuleParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { MAX_INTEGER_32 } from 'core/const';
import { FilteringCombobox } from 'components/controls/FilteringCombobox';
import onlineIconSrc from 'images/filters/discovered-computer-connection-status/online.png';
import offlineIconSrc from 'images/filters/discovered-computer-connection-status/offline.png';
import rejectedIconSrc from 'images/filters/discovered-computer-connection-status/rejected.png';
import unverifiedIconSrc from 'images/filters/discovered-computer-connection-status/unverified.png';
import notInstalledIconSrc from 'images/filters/backup-agent-type/not-installed.png';
import rejectedBaIconSrc from 'images/filters/backup-agent-type/rejected.png';
import supportedIconSrc from 'images/filters/backup-agent-type/supported.png';
import notSupportedIconSrc from 'images/filters/backup-agent-type/not-supported.png';
import cloudIconSrc from 'images/filters/backup-agent-platform-type/cloud.png';
import physicalIconSrc from 'images/filters/backup-agent-platform-type/physical.png';
import virtualIconSrc from 'images/filters/backup-agent-platform-type/virtual.png';
import naIconSrc from 'images/view_help.png';
import windowsIconSrc from 'images/filters/os/windows.svg';
import linuxIconSrc from 'images/filters/os/linux.svg';
import macIconSrc from 'images/filters/os/mac.svg';
import actionPatchStatusIconSrc from 'images/statuses/action-patch.png';
import { getStatusIconSrc } from 'core/utils';
import { StatusTypes } from 'core/utils/status-helpers/enums';

interface SimplifiedRule {
    id?: number;
    name?: string;
}

export const ComputersFilterToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi();
    const { transportService } = useGlobalServices();
    const [rulesList, setRulesList] = useState<SimplifiedRule[]>([]);
    const allRulesId = -2;
    const externalDiscoveryId = -1;

    useEffect(() => {
        transportService.request<DiscoveryRuleParam, RequestSuccessResponse<DiscoveryRuleGridDataModel[]>>('/DiscoveryRule/Get', {
            rcopAutoUpdate: true,
            limit: MAX_INTEGER_32,
            start: 0,
            sort: [{
                direction: GridSortDirections.Asc,
                property: 'name',
            }],
        }).then((response: RequestSuccessResponse<DiscoveryRuleGridDataModel[]>) => {
            const rulesItems: SimplifiedRule[] = [
                {
                    id: allRulesId,
                    name: lang.ALL_RULES,
                }, {
                    id: externalDiscoveryId,
                    name: lang.EXTERNAL_DISCOVERY,
                },
                ...response.data,
            ];
            setRulesList(rulesItems);
        });
    }, []);

    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_DISCOVERY_COMPUTERS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.CONNECTION_STATUS,
                        items: [
                            {
                                iconSrc: onlineIconSrc,
                                label: lang.ONLINE,
                                value: {
                                    id: EConnectionStatus.Online,
                                    name: 'agentStatus',
                                },
                            }, {
                                iconSrc: offlineIconSrc,
                                label: lang.INACCESSIBLE,
                                value: {
                                    id: EConnectionStatus.Offline,
                                    name: 'agentStatus',
                                },
                            }, {
                                iconSrc: rejectedIconSrc,
                                label: lang.REJECTED,
                                value: {
                                    id: EConnectionStatus.Rejected,
                                    name: 'agentStatus',
                                },
                            }, {
                                iconSrc: unverifiedIconSrc,
                                label: RCOP.Lang.UNVERIFIED,
                                value: {
                                    id: EConnectionStatus.Unverified,
                                    name: 'agentStatus',
                                },
                            },
                        ],
                    }, {
                        title: lang.MANAGEMENT_AGENT_STATUS,
                        items: [
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Success),
                                label: lang.HEALTHY,
                                value: {
                                    id: EVacAgentStatusInteg.Healthy,
                                    name: 'vacAgentStatus',
                                },
                            }, {
                                iconSrc: getStatusIconSrc(StatusTypes.Warning),
                                label: lang.WARNING,
                                value: {
                                    id: EVacAgentStatusInteg.Warning,
                                    name: 'vacAgentStatus',
                                },
                            }, {
                                iconSrc: getStatusIconSrc(StatusTypes.Error),
                                label: lang.ERROR,
                                value: {
                                    id: EVacAgentStatusInteg.Error,
                                    name: 'vacAgentStatus',
                                },
                            }, {
                                iconSrc: notInstalledIconSrc,
                                label: lang.NOT_INSTALLED,
                                value: {
                                    id: EVacAgentStatusInteg.NotInstalled,
                                    name: 'vacAgentStatus',
                                },
                            }, {
                                iconSrc: rejectedBaIconSrc,
                                label: lang.REJECTED,
                                value: {
                                    id: EVacAgentStatusInteg.Rejected,
                                    name: 'vacAgentStatus',
                                },
                            },
                        ],
                    },
                ],
            }, {
                groups: [
                    {
                        title: lang.PLATFORM,
                        items: [
                            {
                                iconSrc: physicalIconSrc,
                                label: lang.PHYSICAL,
                                value: {
                                    id: BackupAgentPlatformRepresentation.Physical,
                                    name: 'machineType',
                                },
                            }, {
                                iconSrc: virtualIconSrc,
                                label: lang.VIRTUAL,
                                value: {
                                    id: BackupAgentPlatformRepresentation.Virtual,
                                    name: 'machineType',
                                },
                            }, {
                                iconSrc: cloudIconSrc,
                                label: lang.CLOUD,
                                value: {
                                    id: BackupAgentPlatformRepresentation.Cloud,
                                    name: 'machineType',
                                },
                            }, {
                                iconSrc: naIconSrc,
                                label: lang.UNDEFINED,
                                value: {
                                    id: BackupAgentPlatformRepresentation.Unknown,
                                    name: 'machineType',
                                },
                            },
                        ],
                    }, {
                        title: capitalize(lang.OS_TYPE, true),
                        items: [
                            {
                                iconSrc: supportedIconSrc,
                                label: lang.SUPPORTED,
                                value: {
                                    id: ESupportOsStatusRepresentation.Supported,
                                    name: 'vawSupportStatus',
                                },
                            }, {
                                iconSrc: notSupportedIconSrc,
                                label: lang.UNSUPPORTED,
                                value: {
                                    id: ESupportOsStatusRepresentation.Unsupported,
                                    name: 'vawSupportStatus',
                                },
                            }, {
                                iconSrc: naIconSrc,
                                label: lang.UNDEFINED,
                                value: {
                                    id: ESupportOsStatusRepresentation.Unknown,
                                    name: 'vawSupportStatus',
                                },
                            },
                        ],
                    },
                ],
            }, {
                groups: [
                    {
                        title: lang.MANAGEMENT_AGENT_VERSION,
                        items: [
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Success),
                                label: lang.UP_TO_DATE,
                                value: {
                                    id: EVacVersionStatus.UpToDate,
                                    name: 'vacVersion',
                                },
                            }, {
                                iconSrc: getStatusIconSrc(StatusTypes.Warning),
                                label: lang.OUT_OF_DATE_HYPHENATE,
                                value: {
                                    id: EVacVersionStatus.Outdated,
                                    name: 'vacVersion',
                                },
                            }, {
                                iconSrc: actionPatchStatusIconSrc,
                                label: lang.PATCH_AVAILABLE,
                                value: {
                                    id: EVacVersionStatus.PatchAvailable,
                                    name: 'vacVersion',
                                },
                            }, {
                                iconSrc: naIconSrc,
                                label: lang.N_A,
                                value: {
                                    id: EVacVersionStatus.Na,
                                    name: 'vacVersion',
                                },
                            },
                        ],
                    }, {
                        title: lang.GUEST_OS,
                        items: [
                            {
                                iconSrc: windowsIconSrc,
                                label: RCOP.Lang.WINDOWS,
                                value: {
                                    id: SystemTypeRepresentation.Windows,
                                    name: 'osTypes',
                                },
                            }, {
                                iconSrc: linuxIconSrc,
                                label: RCOP.Lang.LINUX,
                                value: {
                                    id: SystemTypeRepresentation.Linux,
                                    name: 'osTypes',
                                },
                            }, {
                                iconSrc: macIconSrc,
                                label: RCOP.Lang.MAC_OS,
                                value: {
                                    id: SystemTypeRepresentation.Mac,
                                    name: 'osTypes',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };


    const [toolbarKey, setToolbarKey] = useState(0);

    useEffect(() => {
        if (rulesList.length) {
            if (!rulesList.find(rule => rule.id === gridApi.filters['ruleId'])) {
                gridApi.applyFilters({ ruleId: null });
                setToolbarKey(1);
            }
        }
    }, [rulesList]);

    return (
        <ConfiguredToolbar
            key={toolbarKey}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <FilteringCombobox
                            name={'ruleId'}
                            label={lang.RULE}
                            data={rulesList}
                            valueGetter={item => item.id}
                            textGetter={item => item.name}
                            defaultValue={allRulesId}
                        />
                    ),
                }, {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='hostname'
                            placeholder={lang.HOSTNAME}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
