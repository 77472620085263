/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    GridSortDirections,
    TextColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { useFullPage } from '@veeam-vspc/shared/hooks';

import type {
    ReportEditModel,
    ReportTypeRepresentation,
    ReportConfigurationModel,
    ReportSettingsParam,
} from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type {
    GridColumnProps,
    GridStore,
} from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { ConfigSectionIds } from 'core/enums';
import { ReportsFilterToolbar } from '../ReportsFilterToolbar';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ConfigurationActionToolbar } from './components/ConfigurationActionToolbar';
import { ConfigurationStatusColumn } from './components/ConfigurationStatusColumn';
import { ReportWizard } from '../wizards/ReportWizard';
import { RolesAndUsersWizardType } from '../../../RolesAndUsersPage/enums';


interface ConfigurationProps {
    stateId: string;
}

export type WizardModel = ReportEditModel & { isEdit?: boolean; reportType: ReportTypeRepresentation; };

export const Configuration: React.FC<ConfigurationProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const gridStateId = `${stateId}.Configuration`;
    const hiddenCompanyNameAndStatus = portalUser.isLocationAdminOrCompanyOwner() || portalUser.isResellerPortal() || portalUser.isSubtenant();
    const gridApi = useRef<GridStore<ReportConfigurationModel, unknown, ReportSettingsParam['filter']>>();

    const [reportWizard, reportWizardAction] = useFullPage<WizardModel>({
        render: ({ onClose, data: { isEdit = false, reportType, ...model } }) => (
            <ReportWizard
                companyIdOfCurrentUser={portalUser.companyId}
                reportType={reportType}
                isEdit={isEdit}
                model={model}
                wizard={RolesAndUsersWizardType.AdminUser}
                onFinish={() => {
                    onClose();
                    gridApi.current?.reloadGrid();
                }}
            />
        ),
    });

    const columns: GridColumnProps<ReportConfigurationModel>[] = [
        {
            id: 'name',
            title: lang.REPORT,
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
            hidden: hiddenCompanyNameAndStatus,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'locations',
            title: lang.LOCATIONS,
            cell: rowData => rowData.locations,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'typeName',
            title: lang.REPORT_TYPE,
            cell: rowData => rowData.typeName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'scheduleType',
            title: lang.SCHEDULE,
            cell: rowData => (
                <TextColumn defaultValue={lang.NO_INFO}>
                    {portalEnums.getEnumDescription('ScheduleTypeRepresentation', rowData.scheduleType)}
                </TextColumn>
            ),
        },
        {
            id: 'lastReport',
            title: lang.GENERATED_ON,
            cell: rowData => (
                <GridDateColumn value={rowData.lastReport} />
            ),
        },
        {
            id: 'nextReport',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn value={rowData.nextReport} />
            ),
        },
        {
            id: 'companyStatus',
            title: lang.COMPANY_STATE,
            cell: rowData => rowData.companyStatus,
            hidden: hiddenCompanyNameAndStatus,
        },
        {
            id: 'state',
            title: lang.STATE,
            cell: rowData => (
                <ConfigurationStatusColumn status={rowData.stateId} message={rowData.exceptions} />
            ),
        },
    ];

    return (
        <>
            <ConfiguredGrid
                api={gridApi}
                sectionId={ConfigSectionIds.ReportsConfiguration}
                stateId={gridStateId}
                defaultColumnMinWidth={80}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: columns?.[0]?.id,
                    },
                }}
                columns={columns}
                data={(requestParams: ReportSettingsParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<ReportConfigurationModel[]>>('/Report/GetReports', { ...requestParams })}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <ReportsFilterToolbar />,
                    () => <ConfigurationActionToolbar reportWizardAction={reportWizardAction} />,
                ]}
            />

            {reportWizard}
        </>
    );
};
