/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    PortalSpinner,
    TextInput,
    useExternalFormApi,
    useGlobalLang,
    useModal,
    ValidationState,
} from '@veeam-vspc/shared/components';

import type { TestEmailOptions } from '@veeam-vspc/models/rest';
import type { UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useConfigurationStore } from '../../../../stores';
import { notificationTestFormValidate } from '../../helpers';
import { useNotificationTestDialogController } from '../../hooks';

export interface NotificationTestDialogProps {
    deactivateDialog: UseModalActionsProps['deactivate'];
}

export const NotificationTestDialog: React.FC<NotificationTestDialogProps> = observer((props) => {
    const lang = useGlobalLang<VspcLang>();
    const { deactivateDialog } = props;
    const [validationState] = useState(new ValidationState());
    const formApi = useExternalFormApi<TestEmailOptions>();
    const { formStore: { formData: { defaultFrom } } } = useConfigurationStore();
    const { isLoading, sendTestNotification } = useNotificationTestDialogController(deactivateDialog);

    const onSendClick = () => {
        if (formApi.isValid()) {
            sendTestNotification(formApi.value);
        } else {
            validationState.markAsForce();
        }
    };

    // we need to use 'useModal' hook for the spinner in order to overlap rendered dialog by the spinner
    const [modalSpinner] = useModal({ initialActiveState: true, render: () => <PortalSpinner /> });

    return (
        <Dialog
            header={lang.EMAIL_NOTIFICATION}
            onRequestClose={deactivateDialog}
            size={{ width: DIALOG_SIZE.auto, height: DIALOG_SIZE.auto }}
            actions={[
                {
                    text: lang.SEND,
                    onClick: onSendClick,
                },
                {
                    text: lang.CANCEL,
                    onClick: deactivateDialog,
                    view: ACTION_VIEW.secondary,
                },
            ]}
        >
            {isLoading && modalSpinner}

            <Form
                externalFormApi={formApi}
                value={{ from: defaultFrom, to: '' }}
                validationState={validationState}
                validate={formData => (
                    notificationTestFormValidate(formData, {
                        emailFormat: lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS,
                        emailMultipleValues: lang.ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED,
                    })
                )}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        label={lang.FROM}
                        maxLength={128}
                        name={'from'}
                        size={CONTROL_SIZE.l}
                    />

                    <TextInput
                        label={lang.TO}
                        maxLength={128}
                        name={'to'}
                        size={CONTROL_SIZE.l}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
