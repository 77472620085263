/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { getMarkedList, getStatusIconSrc } from 'core/utils';
import { JobSessionTaskStatusRepresentation } from 'views/pages/BackupJobsPage/enums';

export interface FileServerStatusColumnProps {
    status: JobSessionTaskStatusRepresentation;
    statusName: string;
    messages: string[];
}

export const FileServerStatusColumn: React.FC<FileServerStatusColumnProps> = (props) => {
    const {
        status,
        statusName,
        messages,
    } = props;
    const lang = useGlobalLang<VspcLang>();
    const isLink = [
        JobSessionTaskStatusRepresentation.Failed,
        JobSessionTaskStatusRepresentation.Warning,
    ]
        .includes(status);
    const iconSrc = getStatusIconSrc(statusName);
    const msg = getMarkedList(messages || []);

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {statusName}
                </TextColumn>
            )}
            contentIconBefore={iconSrc}
            isLink={isLink}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        { text: lang.OK, onClick: deactivate },
                    ]}
                    iconSrc={iconSrc}
                >
                    {<ParsedText textOrConfig={msg} />}
                </MessageBox>
            )}
        />
    );
};
