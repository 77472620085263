/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalAddons, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { PublicCloudRdsJobModel, PublicCloudRdsJobParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { CloudDatabasesJobActionToolbar, CloudDatabasesJobFilterToolbar } from './components';
import { StatusAgoColumn } from '../StatusAgoColumn';
import { ConfigSectionIds } from 'core/enums';


export interface CloudDatabasesProps {
    stateId: string;
}

export const CloudDatabases: React.FC<CloudDatabasesProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const gridStateId = `${stateId}.CloudDatabases`;

    const columns: GridColumnProps<PublicCloudRdsJobModel>[] = [
        {
            id: 'vmName',
            title: lang.INSTANCE,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
            width: 150,
        },
        {
            id: 'company',
            title: lang.COMPANY,
            cell: rowData => rowData.company,
        },
        {
            id: 'site',
            title: lang.SITE,
            cell: rowData => rowData.site,
            hidden: true,
        },
        {
            id: 'location',
            title: lang.LOCATION,
            cell: rowData => rowData.location,
            hidden: true,
        },
        {
            id: 'displayUid',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.displayUid,
        },
        {
            id: 'platformType',
            title: lang.PLATFORM,
            cell: rowData => portalEnums.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platformType),
        },
        {
            id: 'jobName',
            title: lang.POLICY,
            cell: rowData => rowData.jobName,
        },
        {
            id: 'displayPolicyState',
            title: lang.POLICY_STATE,
            cell: rowData => (
                portalEnums.getEnumDescription('EDisplayPublicCloudPolicyState', rowData.displayPolicyState)
            ),
        },
        {
            id: 'lastSnapshot.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastSnapshot}
                />
            ),
        },
        {
            id: 'lastRemoteSnapshot.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetRemoteSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastRemoteSnapshot}
                />
            ),
            width: 200,
        },
        {
            id: 'lastBackup.sessionStateName',
            title: lang.LAST_BACKUP,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            id: 'lastArchive.sessionStateName',
            title: lang.LAST_ARCHIVE,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetArchiveTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastArchive}
                />
            ),
        },
        {
            id: 'nextRun',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn value={rowData.nextRun} />
            ),
            hidden: true,
        },
        {
            id: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: rowData => rowData.backupServerName,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.BackupJobsCloudDatabases}
            stateId={gridStateId}
            defaultColumnMinWidth={100}
            initialState={{
                sort: {
                    key: 'status',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: PublicCloudRdsJobParam) => transportService
                .request<typeof requestParams, RequestSuccessResponse<PublicCloudRdsJobModel[]>>('/PublicCloudRdsJob/GetRdsJob', {
                    ...requestParams,
                })}
            isRowInactive={rowData => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <CloudDatabasesJobFilterToolbar />,
                () => <CloudDatabasesJobActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
