/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    TextInput,
    CONTROL_SIZE,
    Combobox,
    FieldLayout,
    MultipleChoice,
    useFormApi,
    FormValidator,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import {
    EGroupBy,
    VawReportOperationModes,
    ReportRmmProviderModes,
    ReportAggregationModeRepresentation,
} from '../../../enums';
import { useReportStore } from '../../../hooks';
import { RpoInterval } from './RpoInterval';

import type { ReportModel } from '../../../interfaces';

export const computersParametersValidate = (data: ReportModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('parameters.rpoInterval.number')
        .number()
        .required()
        .min(1)
        .max(365);

    validator
        .validate('parameters.excludeMask')
        .string()
        .maxLength(500);

    validator
        .validate('parameters.guestOsFilter')
        .string()
        .check((val) => {
            // val is array here
            if (val.length === 0) {
                return 'error';
            }
        });

    return validator.result();
};

interface Props {
    data: ReportModel;
    onDataChange: (data: any) => void;
}

export const ComputersParameters = ({ data, onDataChange }: Props) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useFormApi();
    const reportStore = useReportStore();

    const isAggregated = data.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany;

    return (
        <>
            <RpoInterval />

            <Combobox
                name='parameters.operationModeFilter[0]'
                label={capitalize(lang.OPERATION_MODE, true)}
                data={[
                    {
                        id: VawReportOperationModes.All,
                        text: lang.ALL,
                    },
                    {
                        id: VawReportOperationModes.Server,
                        text: lang.SERVER,
                    },
                    {
                        id: VawReportOperationModes.Workstation,
                        text: lang.WORKSTATION,
                    },
                ]}
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
            />

            <Combobox
                name='parameters.managementTypeFilter[0]'
                label={capitalize(lang.MANAGEMENT_MODE, true)}
                data={[
                    {
                        id: ReportRmmProviderModes.Unknown,
                        text: lang.ALL,
                    },
                    {
                        id: ReportRmmProviderModes.ManagedByVAC,
                        text: capitalize(lang.MANAGED_BY_CONSOLE, true),
                    },
                    {
                        id: ReportRmmProviderModes.ManagedByVBR,
                        text: capitalize(lang.MANAGED_BY_BACKUP_SERVER, true),
                    },
                ]}
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
            />

            <TextInput
                name='parameters.excludeMask'
                label={lang.EXCLUSION_MASK_NAME}
            />

            {!isAggregated && (
                <Combobox
                    name='parameters.groupBy'
                    label={lang.GROUP_BY}
                    data={[
                        {
                            id: EGroupBy.Agent,
                            text: capitalize(lang.BACKUP_AGENT),
                        },
                        {
                            id: EGroupBy.BackupPolicy,
                            text: lang.BACKUP_POLICY,
                        },
                    ]}
                    valueGetter={item => item.id}
                    textGetter={item => item.text}
                    size={CONTROL_SIZE.m}
                />
            )}

            <FieldLayout
                label={`${lang.GUEST_OS}:`}
            >
                <MultipleChoice
                    size={CONTROL_SIZE.full}
                    error={data.parameters.guestOsFilter.length === 0}
                    data={
                        reportStore.systemTypeRepresentationSet.map(
                            item => ({
                                id: item.id.toString(),
                                name: item.name,
                            }),
                        )
                    }
                    value={data.parameters.guestOsFilter.map(item => item.toString())}
                    onChange={(value) => {
                        formApi.setValue('parameters.guestOsFilter', value.map(jobTypeId => parseInt(jobTypeId)));
                        onDataChange(data);
                    }}
                />
            </FieldLayout>
        </>
    );
};
