/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum RouteGroups {
    Monitoring = 'Monitoring',
    Analytics = 'Analytics',
    Management = 'Management',
    Clients = 'Clients',
    Discovery = 'Discovery',
    Reporting = 'Reporting',
    Audit = 'Audit',
    PortalCFG = 'Portal Configuration',
    MyCompany = 'My Company',
    ServerSettings = 'Server Settings',
}
