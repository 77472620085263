/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Tasks } from './components';

export interface EventLogTasksPageProps {
    currentPath?: string;
}

export const EventLogTasksPage: React.FC<EventLogTasksPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <Tasks stateId={currentPath} />
    </TabBarBodyLayout>
);
