/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    SchedulingMatrix,
    HoursLegendHeader,
    SCHEDULING_MATRIX_SELECT_MOD,
    createSchedulingMatrixSelectModeFooter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { unflatScheduleMatrix } from 'core/utils';

import type { ConfigureScheduleProps } from '../../interfaces';

export const ConfigureBackupWindow: FC<ConfigureScheduleProps> = ({ hidePanel, data: jobData }) => {
    const lang = useGlobalLang<VspcLang>();
    const [selectMode, setMode] = useState(SCHEDULING_MATRIX_SELECT_MOD.select);
    const [data, setData] = useState<boolean[][]>(() => unflatScheduleMatrix(jobData.schedulePolicy.backupWindowSettings.backupWindow));

    const handleApply = () => {
        jobData.schedulePolicy.backupWindowSettings.backupWindow = data.flat();

        hidePanel();
    };

    const handleCancel = () => {
        hidePanel();
    };

    const footer = createSchedulingMatrixSelectModeFooter({
        getValue: () => selectMode,
        onChange: setMode,
    });

    return (
        <SidePanelForm
            title={lang.SCHEDULE}
            description={lang.CHOOSE_WHEN_JOBS_WITHIN_THE_BACKUP_WINDOW}
            onRequestClose={hidePanel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >

            <SchedulingMatrix
                legendHeader={{
                    // sideOffset: 8,
                    render: () => <HoursLegendHeader />,
                }}
                columns={SchedulingMatrix.columnPresets.timeRange}
                rows={[
                    { title: lang.SUNDAY },
                    { title: lang.MONDAY },
                    { title: lang.TUESDAY },
                    { title: lang.WEDNESDAY },
                    { title: lang.THURSDAY },
                    { title: lang.FRIDAY },
                    { title: lang.SATURDAY },
                ]}
                mode={selectMode}
                data={data}
                onSelect={(items) => {
                    items.forEach(({ rowIndex, columnIndex }) => {
                        data[rowIndex][columnIndex] = selectMode === SchedulingMatrix.modes.select;
                    });
                    setData([...data]);
                }}
                footer={footer}
            />
        </SidePanelForm>
    );
};
