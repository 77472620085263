/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { Profile } from './components';

export const CompanyInfoProfilePage: React.FC<{}> = () => (
    <Profile />
);
