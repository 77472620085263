/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum LicenseDisplayName {
    Licensed = 'Licensed',
    New = 'New',
    Quota = 'Quota',
    Rental = 'Rental',
    Unlicensed = 'License Exceeded',
}
