/**
 * Copyright © Veeam Software Group GmbH.
 */

const getSearchParamValueFromUrl = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(paramName);
};

const deleteSearchParamFromUrl = (paramName) => {
    const url = new URL(window.location.href);

    url.searchParams.delete(paramName);

    window.history.replaceState({}, window.document.title, url.toString());
};

const extractSearchParamFromUrl = (paramName) => {
    const paramValue = getSearchParamValueFromUrl(paramName);

    deleteSearchParamFromUrl(paramName);

    return paramValue;
};

export {
    getSearchParamValueFromUrl,
    deleteSearchParamFromUrl,
    extractSearchParamFromUrl,
};
