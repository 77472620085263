/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Checkbox,
    Form,
    FormLayout,
    NoLabelGroup,
    NumberInput,
    STACK_DIRECTION,
    useGlobalLang,
    Combobox,
    CONTROL_SIZE,
    useExternalFormApi,
    FormValidator,
} from '@veeam-vspc/shared/components';
import {
    BackupServerBackupGFSPolicySettingsWeeklyDesiredTime,
    BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime,
    BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime,
} from '@veeam-vspc/models/rest';
import { deepCopy } from '@veeam-vspc/shared/core';

import type { BackupServerBackupJobGFSPolicySettings } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';

interface ConfigureBackupObjectsProps {
    hidePanel: () => void;
}

export const ConfigureGFS: FC<ConfigureBackupObjectsProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const formApi = useExternalFormApi<BackupServerBackupJobGFSPolicySettings>();

    const [gfsPolicy, setGfsPolicy] = useState(deepCopy(wizardStore.data.storage.gfsPolicy));
    const [isWeeklyEnabled, setIsWeeklyEnabled] = useState(gfsPolicy.weekly.isEnabled);
    const [isMonthlyEnabled, setIsMonthlyEnabled] = useState(gfsPolicy.monthly.isEnabled);
    const [isYearlyEnabled, setIsYearlyEnabled] = useState(gfsPolicy.yearly.isEnabled);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const handleApply = () => {
        const data = wizardStore.data;

        wizardStore.updateJobData({
            ...data,
            storage: {
                ...data.storage,
                gfsPolicy,
            },
        });
        hidePanel();
    };

    const handleCancel = () => {
        hidePanel();
    };

    const formValidate = (data: BackupServerBackupJobGFSPolicySettings) => {
        const validator = new FormValidator(data);

        validator
            .validate('weekly.keepForNumberOfWeeks')
            .number()
            .required()
            .max(9999)
            .min(1);

        validator
            .validate('monthly.keepForNumberOfMonths')
            .number()
            .required()
            .max(999)
            .min(1);

        validator
            .validate('yearly.keepForNumberOfYears')
            .number()
            .required()
            .max(999)
            .min(1);

        const result = validator.result();

        setIsSaveDisabled(Object.keys(result).length > 0);

        return result;
    };

    const weeklyDesiredTime = [
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Monday,
            name: lang.MONDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Tuesday,
            name: lang.TUESDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Wednesday,
            name: lang.WEDNESDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Thursday,
            name: lang.THURSDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Friday,
            name: lang.FRIDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Saturday,
            name: lang.SATURDAY,
        },
        {
            id: BackupServerBackupGFSPolicySettingsWeeklyDesiredTime.Sunday,
            name: lang.SUNDAY,
        },
    ];

    const monthlyDesiredTime = [
        {
            id: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.First,
            name: lang.FIRST,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.Second,
            name: lang.SECOND,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.Third,
            name: lang.THIRD,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.Fourth,
            name: lang.FOURTH,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsMonthlyDesiredTime.Last,
            name: lang.LAST,
        },
    ];

    const yearlyDesiredTime = [
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Jan,
            name: lang.JANUARY,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Feb,
            name: lang.FEBRUARY,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Mar,
            name: lang.MARCH,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Apr,
            name: lang.APRIL,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.May,
            name: lang.MAY,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Jun,
            name: lang.JUNE,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Jul,
            name: lang.JULY,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Aug,
            name: lang.AUGUST,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Sep,
            name: lang.SEPTEMBER,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Oct,
            name: lang.OCTOBER,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Nov,
            name: lang.NOVEMBER,
        },
        {
            id: BackupServerBackupJobGFSPolicySettingsYearlyDesiredTime.Dec,
            name: lang.DECEMBER,
        },
    ];


    return (
        <SidePanelForm
            title={lang.GFS_RETENTION_SETTINGS}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply, disabled: isSaveDisabled },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <Form
                value={gfsPolicy}
                externalFormApi={formApi}
                onChange={data => setGfsPolicy(data)}
                validate={formValidate}
            >
                <FormLayout inlineLabel>
                    <NoLabelGroup
                        content={(
                            <FormLayout direction={STACK_DIRECTION.row}>
                                <Checkbox
                                    name={'weekly.isEnabled'}
                                    onChange={state => setIsWeeklyEnabled(state)}
                                >
                                    {`${lang.KEEP_WEEKLY_FULL_BACKUP_FOR}: `}
                                </Checkbox>

                                <NumberInput
                                    inlineLabel
                                    name={'weekly.keepForNumberOfWeeks'}
                                    suffix={lang.WEEKS.toLowerCase()}
                                    minValue={1}
                                    maxValue={9999}
                                    disabled={!isWeeklyEnabled}
                                />
                            </FormLayout>
                        )}
                        subContent={(
                            <Combobox
                                label={lang.IF_MULTIPLE_FULL_BACKUPS_EXIST}
                                name='weekly.desiredTime'
                                data={weeklyDesiredTime}
                                valueGetter={item => item.id}
                                textGetter={item => item.name}
                                size={CONTROL_SIZE.xs}
                                inlineLabel
                                disabled={!isWeeklyEnabled}
                            />
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <FormLayout direction={STACK_DIRECTION.row}>
                                <Checkbox
                                    name={'monthly.isEnabled'}
                                    onChange={state => setIsMonthlyEnabled(state)}
                                >
                                    {`${lang.KEEP_MONTHLY_FULL_BACKUPS_FOR}: `}
                                </Checkbox>

                                <NumberInput
                                    inlineLabel
                                    name={'monthly.keepForNumberOfMonths'}
                                    suffix={lang.MONTHS.toLowerCase()}
                                    minValue={1}
                                    maxValue={999}
                                    disabled={!isMonthlyEnabled}
                                />
                            </FormLayout>
                        )}
                        subContent={(
                            <Combobox
                                label={lang.USE_WEEKLY_FULL_BACKUP_FROM_THE_FOLLOWING_WEEK_OF_THE_MONTH}
                                name='monthly.desiredTime'
                                data={monthlyDesiredTime}
                                valueGetter={item => item.id}
                                textGetter={item => item.name}
                                size={CONTROL_SIZE.xs}
                                inlineLabel
                                disabled={!isMonthlyEnabled}
                            />
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <FormLayout direction={STACK_DIRECTION.row}>
                                <Checkbox
                                    name={'yearly.isEnabled'}
                                    onChange={state => setIsYearlyEnabled(state)}
                                >
                                    {`${lang.KEEP_YEARLY_FULL_BACKUPS_FOR}: `}
                                </Checkbox>

                                <NumberInput
                                    inlineLabel
                                    name={'yearly.keepForNumberOfYears'}
                                    suffix={lang.YEARS.toLowerCase()}
                                    minValue={1}
                                    maxValue={999}
                                    disabled={!isYearlyEnabled}
                                />
                            </FormLayout>
                        )}
                        subContent={(
                            <Combobox
                                label={lang.USE_MONTHLY_FULL_BACKUP_FROM_THE_FOLLOWING_MONTH}
                                name='yearly.desiredTime'
                                data={yearlyDesiredTime}
                                valueGetter={item => item.id}
                                textGetter={item => item.name}
                                size={CONTROL_SIZE.xs}
                                inlineLabel
                                disabled={!isYearlyEnabled}
                            />
                        )}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
});
