/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Computers } from './components/DiscoveryComputers/components/Computers';

export interface DiscoveryComputersPageProps {
    currentPath: string;
}

export const DiscoveryComputersPage: React.FC<DiscoveryComputersPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <Computers stateId={currentPath} />
    </TabBarBodyLayout>
);
