/**
 * Copyright © Veeam Software Group GmbH.
 */

import { isValid } from '@veeam-vspc/shared/components';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { VspcLang } from 'configs/languages';

import { formValidate } from './formValidate';
import { checkInstallLicense, getXmlFromFile } from '../../helpers';

import type { VbrDeploySettingsWizardModel } from '../../entries';

export const stepValidate = async(lang: VspcLang, notificationService: NotificationService, data: VbrDeploySettingsWizardModel) => {
    if (data.licenseSource === VbrDeploymentLicenseSourceType.AnswerFile && data.installationParametersFile) {
        return getXmlFromFile(data.installationParametersFile[0])
            .then((xml) => {
                const licenseIsValid = checkInstallLicense(xml);

                if (!licenseIsValid) {
                    notificationService.warning(lang.CONFIGURATION_FILE, lang.THE_CONFIGURATION_FILE_INCLUDES);
                }

                return licenseIsValid;
            })
            .catch(() => {
                data.installationParametersFile = undefined;
                notificationService.error(lang.CONFIGURATION_FILE, lang.CANNOT_DETECT_THE_CONFIGURATION_FILE);

                return false;
            });
    }

    return isValid(formValidate(lang), data);
};
