/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/shared/helpers';
import { useGlobalLang } from '@veeam-vspc/shared/components';
import { JobTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { AdvancedFilterValue, FilterGroup } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import backupJobTypeIconSrc from 'images/filters/job-types/backup.png';
import replicaJobTypeIconSrc from 'images/filters/job-types/replication.png';
import vcdReplicaJobTypeIconSrc from 'images/filters/job-types/vcd.png';
import sureBackupJobTypeIconSrc from 'images/filters/job-types/sure-backup.png';
import backupCopyJobTypeIconSrc from 'images/filters/job-types/backup-copy.png';
import vmTapeBackupJobTypeIconSrc from 'images/filters/job-types/backup-to-tape.png';
import vmCopyJobTypeIconSrc from 'images/filters/job-types/copy.png';
import sqlLogBackupJobTypeIconSrc from 'images/filters/job-types/sql-log-backup.png';
import oracleLogBackupJobTypeIconSrc from 'images/filters/job-types/oracle-log-backup.png';
import nutanixSnapshotJobTypeIconSrc from 'images/filters/job-types/nutanix.png';
import fileShareBackupJobTypeIconSrc from 'images/filters/protected-files/nas-backup.png';
import fileShareBackupCopyJobTypeIconSrc from 'images/filters/protected-files/nas-backup-copy.png';
import fileCopyJobTypeIconSrc from 'images/filters/protected-files/copy.png';
import fileTapeBackupJobTypeIconSrc from 'images/filters/protected-files/file-to-tape.png';
import cdpJobTypeIconSrc from 'images/filters/job-types/cdp.png';
import postgreSqlLogJobTypeIconSrc from 'images/postgreSqlLogJobType.png';

export interface UseJobTypesAdvancedFilterProps {
    propName?: string;
}

export const useJobTypesAdvancedFilter = ({ propName = 'types' }: UseJobTypesAdvancedFilterProps = {}): FilterGroup<AdvancedFilterValue> => {
    const lang = useGlobalLang<VspcLang>();

    return {
        title: lang.TYPE,
        items: [
            {
                iconSrc: backupJobTypeIconSrc,
                label: lang.BACKUP,
                value: {
                    id: JobTypeRepresentation.Backup,
                    name: propName,
                },
            },
            {
                iconSrc: replicaJobTypeIconSrc,
                label: lang.REPLICATION,
                value: {
                    id: JobTypeRepresentation.Replica,
                    name: propName,
                },
            },
            {
                iconSrc: vcdReplicaJobTypeIconSrc,
                label: lang.VMWARE_CLOUD_DIRECTOR_REPLICATION,
                value: {
                    id: JobTypeRepresentation.VcdReplica,
                    name: propName,
                },
            },
            {
                iconSrc: sureBackupJobTypeIconSrc,
                label: lang.SURE_BACKUP,
                value: {
                    id: JobTypeRepresentation.SureBackup,
                    name: propName,
                },
            },
            {
                iconSrc: backupCopyJobTypeIconSrc,
                label: lang.BACKUP_COPY,
                value: {
                    id: JobTypeRepresentation.BackupCopy,
                    name: propName,
                },
            },
            {
                iconSrc: vmTapeBackupJobTypeIconSrc,
                label: lang.BACKUP_TO_TAPE,
                value: {
                    id: JobTypeRepresentation.VmTapeBackup,
                    name: propName,
                },
            },
            {
                iconSrc: vmCopyJobTypeIconSrc,
                label: lang.VM_COPY,
                value: {
                    id: JobTypeRepresentation.VmCopy,
                    name: propName,
                },
            },
            {
                iconSrc: sqlLogBackupJobTypeIconSrc,
                label: lang.SQL_DATABASE_LOG_BACKUP_JOB,
                value: {
                    id: JobTypeRepresentation.SqlLogBackup,
                    name: propName,
                },
            },
            {
                iconSrc: oracleLogBackupJobTypeIconSrc,
                label: lang.ORACLE_DATABASE_LOG_BACKUP_JOB,
                value: {
                    id: JobTypeRepresentation.OracleLogBackup,
                    name: propName,
                },
            },
            {
                iconSrc: postgreSqlLogJobTypeIconSrc,
                label: lang.POSTGRESQL_DATABASE_LOG,
                value: {
                    id: JobTypeRepresentation.PostgreSqlLogBackup,
                    name: propName,
                },
            },
            {
                iconSrc: nutanixSnapshotJobTypeIconSrc,
                label: lang.STORAGE_SNAPSHOT,
                value: {
                    id: JobTypeRepresentation.SnapshotJob,
                    name: propName,
                },
            },
            {
                iconSrc: cdpJobTypeIconSrc,
                label: lang.CDP_POLICY,
                value: {
                    id: JobTypeRepresentation.CdpReplica,
                    name: propName,
                },
            },
        ],
    };
};

export const useJobFileTypesAdvancedFilter = ({ propName = 'types' }: UseJobTypesAdvancedFilterProps = {}): FilterGroup<AdvancedFilterValue> => {
    const lang = useGlobalLang<VspcLang>();

    return {
        title: lang.TYPE,
        items: [
            {
                iconSrc: fileShareBackupJobTypeIconSrc,
                label: capitalize(lang.FILE_SHARE_BACKUP, true),
                value: {
                    id: JobTypeRepresentation.Nas,
                    name: propName,
                },
            },
            {
                iconSrc: fileShareBackupCopyJobTypeIconSrc,
                label: lang.FILE_SHARE_BACKUP_COPY,
                value: {
                    id: JobTypeRepresentation.NasBackupCopy,
                    name: propName,
                },
            },
            {
                iconSrc: fileCopyJobTypeIconSrc,
                label: lang.FILE_COPY,
                value: {
                    id: JobTypeRepresentation.FileCopy,
                    name: propName,
                },
            },
            {
                iconSrc: fileTapeBackupJobTypeIconSrc,
                label: lang.FILE_TO_TAPE,
                value: {
                    id: JobTypeRepresentation.FileTapeBackup,
                    name: propName,
                },
            },
        ],
    };
};
