/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
    ConfiguredGrid,
    ToolbarWithExport,
    useGlobalLang,
    useGlobalServices,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { BackupAgentRestorePointModel } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { MAX_INTEGER_32 } from 'core/const';

import type { OldRestorePointRequestParams, OldRestorePointExtraParams } from '../../interfaces';

export interface OldRestorePointDialogProps {
    stateId?: string;
    name: string;
    extraParams: OldRestorePointExtraParams;
    deactivate: () => void;
    isExtendedColumns?: boolean;
    hideMalwareState?: boolean;
}

export const OldRestorePointDialog: React.FC<OldRestorePointDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { portalEnums } = useGlobalAddons();
    const {
        stateId,
        name,
        extraParams,
        deactivate,
        isExtendedColumns = false,
        hideMalwareState = false,
    } = props;

    const draftColumns: GridColumnProps<BackupAgentRestorePointModel>[] = [
        isExtendedColumns && {
            id: 'backupedItems',
            title: lang.BACKED_UP_ITEMS,
            cell: rowData => rowData.backupedItems,
            width: 145,
        },
        {
            id: 'creationDateUtc',
            title: lang.DATE,
            cell: rowData => <GridDateColumn value={rowData.creationDateUtc} />,
        },
        {
            id: 'usedSourceSize',
            title: lang.SOURCE_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.usedSourceSize} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        !hideMalwareState && {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
            width: 165,
        },
        {
            id: 'incrementDataSize',
            title: lang.BACKED_UP_DATA,
            cell: rowData => <RestorePointSizeColumn value={rowData.incrementDataSize} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            width: 150,
        },
        {
            id: 'size',
            title: lang.RESTORE_POINT_SIZE,
            cell: rowData => <RestorePointSizeColumn value={rowData.size} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            width: 150,
        },
    ];

    const columns = draftColumns.filter(col => col);

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    {...(stateId ? { stateId } : {})}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: 'creationDateUtc',
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns}
                    data={(requestParams: OldRestorePointRequestParams<{}>) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<BackupAgentRestorePointModel[]>>('/BackupAgent/GetRestorePoints', {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={false}
                    selection={{
                        field: 'creationDateUtc',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                lang={lang}
                                extraParams={extraParams}
                                idProperty={'creationDateUtc'}
                                method={'GetComputersRestorePoints'}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
