/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CompanyProtectedCloudWorkloads } from '@veeam-vspc/models/web-controllers';

import { TopDataUrls } from '../../../../enums';
import { useCommonSettings } from '../../../../hooks';
import { TopsGrid } from '../../../TopsGrid';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const ProtectedCloudWorkloads: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings =
        useCommonSettings<CompanyProtectedCloudWorkloads>({
            maxNumberOfRows,
            getDataRequestUrl: TopDataUrls.ProtectedDataWorkloads,
        });
    const { lang } = settings;

    const columns: GridColumnProps<CompanyProtectedCloudWorkloads>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'vmsCount',
            title: lang.VMS,
            cell: rowData => rowData.vmsCount,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'databasesCount',
            title: lang.DATABASES,
            cell: rowData => rowData.databasesCount,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'sharesCount',
            title: capitalize(lang.FILE_SHARES),
            cell: rowData => rowData.sharesCount,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'totalCount',
            title: capitalize(lang.TOTAL),
            cell: rowData => rowData.totalCount,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_COMPANIES_BY_PROTECTED_CLOUD_WORKLOADS}
            columns={columns}
            sectionId={ConfigSectionIds.TopsCompanies}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
