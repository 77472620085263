/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Button,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_WEIGHT,
    TextInputKit,
    Tooltip,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { useAsyncAction } from 'views/hooks';
import { REG_EXP } from 'core/const';
import { FormNames } from '../../enums';

interface PortalWebAddressProps {
    onIsDirtyChange: (formIsDirty: Partial<Record<FormNames, boolean>>) => void;
}

export const PortalWebAddress: React.FC<PortalWebAddressProps> = ({ onIsDirtyChange }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [url, setUrl] = useState<string>();
    const [error, setError] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();

    useEffect(() => {
        transportService.request('/Branding/GetPortalWebAddress')
            .then(({ data }: RequestSuccessResponse<string>) => setUrl(data));
    }, []);

    useEffect(() => {
        onIsDirtyChange({ [FormNames.PortalWebAddress]: isDirty });
    }, [isDirty]);

    const onApply = () => {
        doAsyncAction('/Branding/SetPortalWebAddress', lang.ERROR, { url })
            .finally(() => setIsDirty(false));
    };

    return (
        <>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <Text weight={TEXT_WEIGHT.bold}>{lang.BRANDING_WEB_ADDRESS_TITLE}</Text>
                <Text>{lang.THIS_ADDRESS_WILL_BE_USED_IN_THE_WELCOME_EMAIL}</Text>

                <Tooltip
                    isActive={!!error}
                    text={error}
                    render={(control: any) => (
                        <TextInputKit
                            {...control}
                            value={url}
                            error={!!error}
                            onChange={(v) => {
                                const errorMsg = REG_EXP.portalUrl.test(url) ? '' : lang.THIS_FIELD_SHOULD_BE_IN_THE_URL;
                                setError(errorMsg);
                                setIsDirty(!errorMsg);
                                setUrl(v);
                            }}
                        />
                    )}
                />

                <Button disabled={!isDirty} onClick={onApply} style={{ width: 'max-content' }}>{lang.APPLY}</Button>
            </StackView>

            {asyncActionLoader}
        </>
    );
};
