/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { Configuration } from './components';

export interface ReportsConfigurationPageProps {
    currentPath?: string;
}

export const ReportsConfigurationPage: React.FC<ReportsConfigurationPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <Configuration stateId={currentPath} />
    </TabBarBodyLayout>
);
