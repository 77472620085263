/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { RouteIds, RoutePaths } from '../../enums';
import { ConfigSectionIds, ConfigActionIds } from 'core/enums';
import { allCustomRoutes, allHomeRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

export const financeConfig: UserConfigData<typeof UserRoleRepresentation.Finance, ConfigSectionIds> = {
    role: UserRoleRepresentation.Finance,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.CompanyInvoices],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesConfiguration,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesAllInvoices,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ConfigActionIds.FilterToolbarStatus]: ConfigStates.Visible,
                [ConfigActionIds.FilterToolbarDatePeriod]: ConfigStates.Visible,
            },
            columns: {
                companyName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.EditUserProfileWizardSummary,
            items: {
                sendInvoice: ConfigStates.Visible,
            },
        },
    ],
};
