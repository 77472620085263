/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TimeAgoColumn, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { Vb365JobLastStatus } from '@veeam-vspc/models/rest';

import type { Vb365Job } from '@veeam-vspc/models/rest';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { JobStatusColumn, JobTypeColumn, VBObjectsActionToolbar, VBObjectsFilterToolbar } from './components';
import { vbObjectsService } from './services';


export const VBObjects: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    vbObjectsService.init(transportService);

    const columns: GridColumnProps<Vb365Job>[] = [
        {
            id: 'name',
            title: lang.JOB,
            cell: rowData => rowData.name,
        },
        {
            id: 'vspcOrganizationName',
            title: lang.COMPANY,
            cell: rowData => rowData.vspcOrganizationName,
        },
        {
            id: 'vb365ServerName',
            title: lang.SERVER,
            cell: rowData => rowData.vb365ServerName,
        },
        {
            id: 'jobType',
            title: lang.JOB_TYPE,
            cell: rowData => <JobTypeColumn rowData={rowData} />,
        },
        {
            id: 'lastRun',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastRun}
                />
            ),
        },
        {
            id: 'lastStatus',
            title: lang.JOB_STATUS,
            cell: rowData => <JobStatusColumn rowData={rowData} />,
        },
        {
            id: 'repositoryName',
            title: lang.REPOSITORY,
            cell: rowData => rowData.repositoryName,
        },
        {
            id: 'nextRun',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn
                    value={rowData.nextRun}
                />
            ),
        },
    ];

    return (
        <ConfiguredGrid
            columns={columns}
            stateId='BackupJobsPage.VBObjects'
            data={props => vbObjectsService.getJobs(props)}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VBObjectsFilterToolbar />,
                () => <VBObjectsActionToolbar />,
            ]}
            defaultColumnMinWidth={150}
            isRowInactive={rowData => !rowData.isEnabled || rowData.lastStatus === Vb365JobLastStatus.Deleting}
        />
    );
};
