/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { CompanyFileBackup } from '@veeam-vspc/models/web-controllers';

import { TopsGrid } from '../../../TopsGrid';
import { useCommonSettings } from '../../../../hooks';
import { TopDataUrls } from '../../../../enums';
import { SizeColumnRender } from '../../../columns';
import { ConfigSectionIds } from 'core/enums';


import type { TopsGridComponentWrapper } from '../../../../interfaces';

export const FileBackups: React.FC<TopsGridComponentWrapper> = ({ maxNumberOfRows }) => {
    const settings = useCommonSettings<CompanyFileBackup>({
        maxNumberOfRows,
        getDataRequestUrl: TopDataUrls.FileBackups,
    });
    const { lang } = settings;

    const columns: GridColumnProps<CompanyFileBackup>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
        },
        {
            id: 'nasShortTermUsedSpace',
            title: lang.BACKUP_SIZE,
            cell: rowData => <SizeColumnRender value={rowData.nasShortTermUsedSpace} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'nasLongTermUsedSpace',
            title: lang.ARCHIVE_SIZE,
            cell: rowData => <SizeColumnRender value={rowData.nasLongTermUsedSpace} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
        },
        {
            id: 'nasSourceAmountOfDataSize',
            title: lang.SOURCE_DATA_SIZE,
            cell: rowData => <SizeColumnRender value={rowData.nasSourceAmountOfDataSize} />,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 160,
        },
        {
            id: 'protectedFileServers',
            title: capitalize(lang.UNSTRUCTURED_DATA),
            cell: rowData => rowData.protectedFileServers,
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            width: 160,
        },
    ];

    return (
        <TopsGrid
            title={lang.TOP_COMPANIES_BY_PROTECTED_UNSTRUCTURED_DATA}
            columns={columns}
            sectionId={ConfigSectionIds.TopsCompanies}
            maxNumberOfRows={maxNumberOfRows}
            {...settings}
        />
    );
};
