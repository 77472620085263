/**
 * Copyright © Veeam Software Group GmbH.
 */

import { LicenseProductType, UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

export const shouldDisableRedirectLink = (productType: LicenseProductType, userRole: UserRoleRepresentation): boolean => {
    const rolesWithDisabledCCLink = [
        UserRoleRepresentation.ResellerOwner,
        UserRoleRepresentation.ResellerAdministrator,
    ];

    return (productType === LicenseProductType.CloudConnectServer) && rolesWithDisabledCCLink.includes(userRole);
};
