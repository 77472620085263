/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import { coreModuleConfig } from './core-module-config';
import { unknownConfig, vacAdministratorConfig } from '../user-roles';
import { enLocale } from '../languages';

export const fakeCoreModuleConfig = {
    ...coreModuleConfig,
    configuratorConfig: {
        allRolesPaths: {
            [UserRoleRepresentation.Unknown]: () => Promise.resolve({
                role: UserRoleRepresentation.Unknown,
                routes: unknownConfig.routes,
                sections: unknownConfig.sections,
            }),
            [UserRoleRepresentation.VacAdministrator]: () => Promise.resolve({
                role: UserRoleRepresentation.VacAdministrator,
                routes: vacAdministratorConfig.routes,
                sections: vacAdministratorConfig.sections,
            }),
        },
    },
    langConfig: {
        allLangPaths: {
            en: (): Promise<typeof enLocale> => Promise.resolve(enLocale),
        },
    },
    appEventHandlers: {},
};
