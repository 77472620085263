/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { colors, constants, Icon, ICON_SIZES, SIZE_XL, SPACING_S } from '@veeam-vspc/shared/components';

import { Entity } from '../../enums';
import { UsedValueStyled } from './UsedValue';

const Currency = styled.i`
    font-size: 13px;
    font-style: normal;
    position: absolute;
    top: 3px;

    &:first-child {
        left: 0;
        transform: translateX(-100%);
    }
`;

const InfoWrap = styled.div`
    color: ${colors.G400};
    font-size: ${constants.FONT_SIZE_M};
    text-align: center;
`;

const LeftValue = styled.div`
    font-size: ${constants.FONT_SIZE_XL};
    display: inline-block;
    position: relative;
`;

export const DonutInfo = ({ currency, entity, lang, status, total, unit, used }) => {
    const left = total - used;
    let digits, title;

    switch (entity) {
        case Entity.Quota:
            digits = 1;
            title = `${lang.QUOTA}, ${unit}`;
            break;
        case Entity.Ratio:
            digits = 2;
            title = lang.RATIO;
            break;
        case Entity.Paid:
            digits = 0;
            title = `${lang.PAID}:`;
            break;
    }

    return (
        <InfoWrap>
            <div>{title}</div>

            <UsedValueStyled error={entity !== Entity.Paid && left < 0}>
                {used}
            </UsedValueStyled>

            {
                currency?.length === 3
                    ? <div>{`${lang.OF} ${total} ${currency || ''}`}</div>
                    : <div>{`${lang.OF} ${currency || ''}${total}`}</div>
            }

            <div style={{ height: SIZE_XL, padding: `${SPACING_S} 0` }}>
                {status.icon && <Icon size={ICON_SIZES.m} src={status.icon} />}
            </div>

            <div>{`${status.text}:`}</div>

            {
                currency?.length === 3
                    ? (
                        <LeftValue>
                            <span>{Math.abs(left).toFixed(digits)}</span>
                            <Currency>{currency}</Currency>
                        </LeftValue>
                    )
                    : (

                        <LeftValue>
                            <Currency>{currency}</Currency>
                            <span>{Math.abs(left).toFixed(digits)}</span>
                        </LeftValue>
                    )
            }
        </InfoWrap>
    );
};
