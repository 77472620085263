/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam/components';
import {
    DatePeriod,
    Search,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGridApi,
    useGlobalLang,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { CompanyTypeRepresentation, ReportAggregationModeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { DatePeriodValue } from '@veeam/components';
import type { ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import vmBackupSrc from 'images/filters/reports/vm.png';
import dataBackupSrc from 'images/filters/reports/file-shares.png';
import computersSrc from 'images/filters/reports/computers.png';
import databasesSrc from 'images/filters/reports/databases.png';
import vb365Src from 'images/filters/reports/vb365-sla-complience.png';
import singleSrc from 'images/filters/reports/individual.png';
import multiSrc from 'images/filters/reports/agregated.png';
import providerSrc from 'images/filters/subscription-plans/my_plans.png';
import resellerSrc from 'images/reseller_item.png';
import { ReportType } from '../wizards/ReportWizard/enums';
import { getDatePeriod } from 'core/utils/date-helpers';
import { ConfigAdvancedFilterItemsIds, ConfigSectionIds } from 'core/enums';

interface ReportsFilterToolbarProps {
    withDateFilter?: boolean;
}

export const ReportsFilterToolbar: React.FC<ReportsFilterToolbarProps> = ({ withDateFilter = false }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<unknown, unknown, { dateFrom: Date; dateTo: Date; }>();
    const { dateFrom, dateTo } = gridApi.filters;
    const { formats } = useGlobalAppData();
    const dateValue: DatePeriodValue = dateFrom || dateTo ? [new Date(gridApi.filters.dateFrom), new Date(gridApi.filters.dateTo)] : [];
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: lang.FILTER_REPORTS_BY,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: vmBackupSrc,
                                label: lang.PROTECTED_VMS_RPO_SLA,
                                value: {
                                    id: ReportType.ProtectedVms,
                                    name: 'types',
                                },
                            },
                            {
                                iconSrc: dataBackupSrc,
                                label: lang.PROTECTED_DATA_BACKUP_RPO,
                                value: {
                                    id: ReportType.ProtectedFiles,
                                    name: 'types',
                                },
                            },
                            {
                                iconSrc: computersSrc,
                                label: lang.PROTECTED_COMPUTERS_RPO,
                                value: {
                                    id: ReportType.ProtectedComputers,
                                    name: 'types',
                                },
                            },
                            {
                                iconSrc: databasesSrc,
                                label: lang.PROTECTED_DATABASES_RPO,
                                value: {
                                    id: ReportType.ProtectedDatabases,
                                    name: 'types',
                                },
                            },
                            {
                                iconSrc: vb365Src,
                                label: lang.MICROSOFT_365_OBJECT_BACKUP_SLA,
                                value: {
                                    id: ReportType.ProtectedVboObjectsSla,
                                    name: 'types',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        id: ConfigAdvancedFilterItemsIds.ReportsManagingCompany,
                        title: lang.MANAGED_BY,
                        items: [
                            {
                                iconSrc: providerSrc,
                                label: capitalize(lang.MY_COMPANY, true),
                                value: {
                                    id: CompanyTypeRepresentation.Provider,
                                    name: 'organizationTypes',
                                },
                            },
                            {
                                iconSrc: resellerSrc,
                                label: lang.RESELLER,
                                value: {
                                    id: CompanyTypeRepresentation.Reseller,
                                    name: 'organizationTypes',
                                },
                            },
                        ],
                    },
                    {
                        id: ConfigAdvancedFilterItemsIds.ReportsReportConfiguration,
                        title: lang.REPORT_CONFIGURATION,
                        items: [
                            {
                                iconSrc: singleSrc,
                                label: lang.INDIVIDUAL_REPORT,
                                value: {
                                    id: ReportAggregationModeRepresentation.SingleCompany,
                                    name: 'aggregationModes',
                                },
                            },
                            {
                                iconSrc: multiSrc,
                                label: lang.SUMMARY_REPORT,
                                value: {
                                    id: ReportAggregationModeRepresentation.MultiCompany,
                                    name: 'aggregationModes',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            placeholder={lang.REPORT}
                            name={'name'}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            sectionId={ConfigSectionIds.ReportsFilterToolbarAdvancedFilter}
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
                withDateFilter && {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <DatePeriod
                            format={formats.netShortDate}
                            value={dateValue}
                            onChange={value => gridApi.applyFilters(getDatePeriod(value))}
                        />
                    ),
                },
            ].filter(Boolean)}
        />
    );
};
