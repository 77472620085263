/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { WizardSummary, useGlobalLang } from '@veeam-vspc/shared/components';
import { EPrincipalRoleTypeRepresentation, EPrincipalTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData, WizardSummaryProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ManagedCompaniesMode } from '../../enums';

import type { AdminUserModel } from '../../interfaces';

export const Summary = ({ data }: WizardStepData<AdminUserModel>) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${lang.ACCOUNT}:`,
                        fields: [
                            {
                                label: `${lang.ACCOUNT}:`,
                                value: data.model.name,
                            },
                            {
                                label: `${lang.ACCOUNT_TYPE}:`,
                                value: data.model.type === EPrincipalTypeRepresentation.WinNTUser ? lang.USER : lang.GROUP,
                            },
                        ],
                    },
                    {
                        title: `${lang.ROLE}:`,
                        fields: [
                            {
                                label: `${lang.ROLE}:`,
                                value: data.model.roleType,
                                transform: (value: EPrincipalRoleTypeRepresentation) => {
                                    const roleMap = {
                                        [EPrincipalRoleTypeRepresentation.Readonly]: lang.PORTAL_READONLY_OPERATOR,
                                        [EPrincipalRoleTypeRepresentation.Operator]: lang.PORTAL_OPERATOR,
                                        [EPrincipalRoleTypeRepresentation.Administrator]: lang.PORTAL_ADMINISTRATOR,
                                    };

                                    return roleMap[value];
                                },
                            },
                        ],
                    },
                    {
                        title: `${lang.COMPANIES}:`,
                        fields: [
                            {
                                label: `${lang.COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.AllCompanies
                                || data.model.roleType === EPrincipalRoleTypeRepresentation.Administrator
                                    ? lang.ALL
                                    : data.selectedCompanies.map(company => company.name).join(', '),
                            },
                        ],
                    },
                ] as WizardSummaryProps<string | EPrincipalRoleTypeRepresentation>['fieldsets']}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    render: data => <Summary {...data} />,
});
