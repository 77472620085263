/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { VBObjects } from './components';

export const BackupJobsVBObjectsPage: React.FC<{}> = () => (
    <TabBarBodyLayout>
        <VBObjects />
    </TabBarBodyLayout>
);
