/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { Provider } from '../components/IdentityProvider/identity-provider-model';

export const getInitialProviderName = (providers: Provider[]): string => {
    if (!providers) return;

    const firstActiveProvider = providers.find(provider => provider.enabled);

    if (firstActiveProvider) {
        return firstActiveProvider.value;
    }

    return providers[0].value; // if there are no active providers return the first one in the list
};
