/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CONTROL_SIZE,
    FieldLayout,
    isValid,
    PasswordInput,
    Textarea,
    TextInput,
    TextInputKit,
    useGlobalLang,
    usePageWizardStore,
} from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { CommonService } from 'core/services/common-service';
import { stepValidate } from './helpers/stepValidate';
import { LoginInfoForm } from './components/LoginInfoForm';
import { SelectUserLink } from './components/SelectUserLink';
import { SelectUser } from './components/SelectUser';
import { VacInternalItemType } from 'core/enums/vac-internal-item-type';

import type { CompanyUserModel } from '../../../CompanyUser/interfaces';

export const LoginInfo: React.FC<WizardStepData<CompanyUserModel>> = (wizardStepData) => {
    const { validationState } = wizardStepData;
    const lang = useGlobalLang<VspcLang>();
    const { data, isEdit } = wizardStepData;
    const isCompanyOwner = data.userRole === UserRoleRepresentation.CompanyOwner;
    const isSubtenant = data.userRole === UserRoleRepresentation.Subtenant;
    const wizardContext = usePageWizardStore<CompanyUserModel>();
    const openPanel = () => wizardContext.openPanel(hide => <SelectUser {...wizardStepData} hidePanel={hide} />);

    if (data.isVcdCompany && isCompanyOwner) {
        return (
            <LoginInfoForm {...wizardStepData}>
                <FieldLayout
                    label={`${lang.LOGIN}:`}
                    disabled={true}
                >
                    <TextInput
                        name='login'
                    />
                </FieldLayout>

                <FieldLayout
                    label={`${lang.NEW_PASSWORD}:`}
                    disabled={true}
                >
                    <PasswordInput
                        name='password'
                        autocomplete='new-password'
                    />
                </FieldLayout>

                <FieldLayout
                    label={`${lang.CONFIRM_PASSWORD}:`}
                    disabled={true}
                >
                    <PasswordInput
                        name='confirmPassword'
                        autocomplete='new-password'
                    />
                </FieldLayout>
            </LoginInfoForm>
        );
    }

    if (data.isVcdCompany && isSubtenant) {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (!isEdit && !data.vcdUserId) {
                openPanel();
            }
        }

        return (
            <LoginInfoForm {...wizardStepData}>
                <FieldLayout
                    label={`${lang.LOGIN}:`}
                    disabled={true}
                    suffix={!isEdit && <SelectUserLink openPanel={openPanel} />}
                >
                    <TextInputKit
                        value={isEdit ? data.login : data.vcdUserName}
                        size={CONTROL_SIZE.l}
                    />
                </FieldLayout>

                <Textarea
                    name='description'
                    label={lang.DESCRIPTION}
                    rows={8}
                    size={CONTROL_SIZE.l}
                />
            </LoginInfoForm>
        );
    }

    return (
        <LoginInfoForm {...wizardStepData}>
            <FieldLayout label={`${lang.LOGIN}:`} disabled={isEdit}>
                <TextInput
                    name='login'
                />
            </FieldLayout>

            {data.selfEdit && (
                <FieldLayout label={`${lang.CURRENT_PASSWORD}:`}>
                    <PasswordInput
                        name='oldPassword'
                        autocomplete='new-password'
                    />
                </FieldLayout>
            )}

            <FieldLayout label={isEdit ? `${lang.NEW_PASSWORD}:` : `${lang.PASSWORD}:`}>
                <PasswordInput
                    name='password'
                    autocomplete='new-password'
                />
            </FieldLayout>

            <FieldLayout label={`${lang.CONFIRM_PASSWORD}:`}>
                <PasswordInput
                    name='confirmPassword'
                    autocomplete='new-password'
                />
            </FieldLayout>

            {isSubtenant && (
                <Textarea
                    name='description'
                    label={lang.DESCRIPTION}
                    rows={8}
                    size={CONTROL_SIZE.m}
                />
            )}
        </LoginInfoForm>
    );
};

export const getLoginInfoStep = (
    lang: VspcLang,
    title: string,
    transportService: TransportService<RequestErrorResponse | Error>,
): WizardStep<CompanyUserModel> => ({
    title,
    validate: ({ data, isEdit, stepData }) => new Promise(async(resolve) => {
        let valid = isValid(data => stepValidate(lang, data, isEdit), data);

        if (valid) {
            const item = {
                additionalId: data.companyID || null,
                id: isEdit ? data.id : null,
                type: VacInternalItemType.User,
                name: data.login,
            };

            const exist = await CommonService.isItemExist(transportService, item);

            if (exist) {
                stepData.isLoginExist = true;

                valid = false;
            }
        }

        resolve(valid);
    }),
    render: data => <LoginInfo {...data} />,
});


