/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable, observable, runInAction } from 'mobx';
import { deepCopy } from '@veeam-vspc/shared/core';
import {
    UserCreationSourceRepresentation,
    UserMfaPolicyAggregateStatusRepresentation,
    UserRoleRepresentation,
    AddTotpLoginParam,
    TotpSecret,
} from '@veeam-vspc/models/web-controllers';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { EnumRepresentation, GenerateTotpSecretResponse, UserData, UserLogin } from '@veeam-vspc/models/web-controllers';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PortalEnumsAddon } from '@veeam-vspc/shared/addons';

import type { UserTitleModel } from 'core/interfaces/user-title-model';

export class EditUserProfileStore {
    readonly transportService: TransportService<RequestErrorResponse | Error>;
    readonly portalEnums: PortalEnumsAddon;
    readonly data: UserData;
    readonly initialData: UserData;
    readonly isMfaStrictPolicy: boolean;
    readonly enabledQuota: boolean;
    readonly isVcd: boolean;
    readonly disabledPassword: boolean;
    readonly disabledNewPassword: boolean;
    @observable loading = false;
    @observable userTitles: UserTitleModel[] = [];
    @observable mfaEnabled: boolean;
    @observable shouldConfigureMfa: boolean;
    @observable totpSecret: TotpSecret;
    @observable isSecretCodeGenerated: boolean;

    constructor(
        transportService: TransportService<RequestErrorResponse | Error>,
        portalEnums: PortalEnumsAddon,
        data: UserData,
        isMfaStrictPolicy: boolean
    ) {
        makeObservable(this);
        this.transportService = transportService;
        this.portalEnums = portalEnums;
        this.data = data;
        this.initialData = deepCopy(data);
        this.isMfaStrictPolicy = isMfaStrictPolicy;
        this.enabledQuota = data.quota !== null;
        this.isVcd = this.initialData.creationSource === UserCreationSourceRepresentation.Vcd;
        this.disabledPassword = this.isVcd && ![
            UserRoleRepresentation.CompanyLocationAdministrator,
            UserRoleRepresentation.CompanyLocationUser,
            UserRoleRepresentation.Finance,
            UserRoleRepresentation.CompanyAdministrator,
        ].includes(this.initialData.userRole);
        this.disabledNewPassword = this.isVcd && UserRoleRepresentation.CompanyOwner === this.initialData.userRole;

        runInAction(() => {
            this.isSecretCodeGenerated = this.initialData.mfaPolicyStatus === UserMfaPolicyAggregateStatusRepresentation.Enabled;
            this.mfaEnabled = this.initialData.mfaPolicyStatus !== UserMfaPolicyAggregateStatusRepresentation.Disabled;
            this.shouldConfigureMfa = this.initialData.mfaPolicyStatus === UserMfaPolicyAggregateStatusRepresentation.NotConfigured;
        });
    }

    @action.bound
    setMfaStatus(value: boolean) {
        this.mfaEnabled = value;
    }

    @action.bound
    loadUserTitles() {
        return this.transportService.request<void, RequestSuccessResponse<EnumRepresentation[]>>('/User/GetUserTitles')
            .then(({ data: titles }: RequestSuccessResponse<EnumRepresentation[]>) =>
                this.userTitles = titles
                    .filter(title => title.name)
                    .map(title =>
                        ({ ...title, id: this.portalEnums.getEnumStringValue('UserTitleRepresentation', title.id) })
                    ) as UserTitleModel[]
            );
    }

    @action.bound
    generateTotpSecret() {
        this.loading = true;
        return this.transportService.request<void, RequestSuccessResponse<GenerateTotpSecretResponse>>('/User/GenerateTotpSecret')
            .then(({ data: { data: totpSecret } }: RequestSuccessResponse<GenerateTotpSecretResponse>) => {
                this.totpSecret = totpSecret;
            })
            .finally(() => this.loading = false);
    }

    @action.bound
    addTotp(data: AddTotpLoginParam) {
        this.loading = true;
        return this.transportService.request<AddTotpLoginParam, RequestSuccessResponse<UserLogin>>('/User/AddTotpLogin', data)
            .then((resp: RequestSuccessResponse<UserLogin>) => resp.data)
            .then(() => {
                this.isSecretCodeGenerated = true;
                this.shouldConfigureMfa = false;
            })
            .finally(() => this.loading = false);
    }
}
