/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import {
    Form,
    PortalSpinner,
    STACK_GAP,
    STACK_DIRECTION,
    StackView,
    useExternalFormApi,
    useGlobalLang,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { useNavigationBlocker } from '@veeam-vspc/shared/hooks';

import type { VspcLang } from 'configs/languages';

import { formValidate } from './helpers';
import { isSmtpOAuthRedirect } from 'core/utils';
import { useSmtpRedirectController } from './hooks';
import { useConfigurationRequestsStore, useConfigurationStore } from './stores';
import { AlarmsSettingsGroup, BillingSettingsGroup, DiscoveryRulesSettingsGroup, FormSliderGroup, SaveBtnGroup, SmtpGroup } from './components';

import type { ConfigurationFormData } from './interfaces';

const FormFieldsContainer = styled(StackView)`
    width: 520px;
`;

export const Configuration = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const [searchParams] = useSearchParams();
    const configurationStore = useConfigurationStore();
    const requestsStore = useConfigurationRequestsStore();
    const { portalUser } = useGlobalAppData();
    const { userRole } = portalUser ?? {};
    const formApi = useExternalFormApi<ConfigurationFormData>();
    const { handleOAuthRedirect } = useSmtpRedirectController();

    const { formStore: { formData, onFormChange, validationState, formIsDirty } } = configurationStore;

    useNavigationBlocker({
        isDirty: formIsDirty,
        prompt: {
            title: lang.CONFIGURATION,
            message: lang.EMAIL_SETTINGS_HAVENT_BEEN_SAVED,
        },
    });

    useEffect(() => {
        isSmtpOAuthRedirect(searchParams)
            ? handleOAuthRedirect(searchParams, userRole)
            : configurationStore.initiateStoreData();
    }, []);

    return (
        <>
            {requestsStore.isLoading && <PortalSpinner />}

            {!requestsStore.isLoading && (
                <Form
                    externalFormApi={formApi}
                    onChange={newFormData => onFormChange(newFormData)}
                    value={formData}
                    validate={formData => (
                        formValidate(formData, {
                            emailFormat: lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS,
                            emailGroupFormat: lang.ONE_OR_MORE_ADDRESSES_IS,
                            emailMultipleValues: lang.ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED,
                        })
                    )}
                    validationState={validationState}
                >
                    <FormFieldsContainer direction={STACK_DIRECTION.column} gap={STACK_GAP.l}>
                        <SaveBtnGroup />
                        <SmtpGroup />
                        <FormSliderGroup />
                        <AlarmsSettingsGroup />
                        <DiscoveryRulesSettingsGroup />
                        <BillingSettingsGroup />
                    </FormFieldsContainer>
                </Form>
            )}
        </>
    );
});
