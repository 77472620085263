/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PublicCloudEfsBackupRestorePointParam, PublicCloudProtectedVmBackupRestorePointModel } from '@veeam-vspc/models/web-controllers';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { MAX_INTEGER_32 } from 'core/const';

export interface FsPublicCloudsBackupDialogProps {
    stateId: string;
    name: string;
    extraParams: Pick<PublicCloudEfsBackupRestorePointParam, 'backupType' | 'backupUid' | 'backupServerUid' | 'vmUid' | 'platformType' | 'policyUid'>;
    deactivate: () => void;
}

export const FsPublicCloudsBackupDialog: React.FC<FsPublicCloudsBackupDialogProps> = ({ stateId, name, extraParams, deactivate }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const columns: GridColumnProps<PublicCloudProtectedVmBackupRestorePointModel>[] = [
        {
            id: 'created',
            title: lang.DATE,
            cell: rowData => (
                <GridDateColumn value={rowData.created} />
            ),
        },
        {
            id: 'vmSize',
            title: lang.SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.vmSize} />
            ),
        },
    ];

    return (
        <Dialog
            header={name}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <ConfiguredGrid
                stateId={stateId}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: columns?.[0]?.id,
                    },
                }}
                columns={columns.filter(item => item)}
                data={(requestParams: PublicCloudEfsBackupRestorePointParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<PublicCloudProtectedVmBackupRestorePointModel[]>>(
                        '/PublicCloudEfs/GetProtectedEfsBackupRestorePoints',
                        {
                            ...requestParams,
                            ...extraParams,
                        },
                    )}
                toggleable={false}
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <ToolbarWithExport
                            lang={lang}
                            idProperty={'instanceUid'}
                            extraParams={extraParams}
                            method={'GetPublicCloudEfsRestorePoints'}
                        />
                    ),
                ]}
            />
        </Dialog>
    );
};
