/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    LinkButton,
    usePageWizardStore,
    useGlobalLang,
    Text,
    STACK_DIRECTION,
    FormLayout,
    FormTitle,
    TEXT_VIEW,
    NoLabelGroup,
    StackView,
    STACK_GAP,
    SPACING_L,
    STACK_ALIGN,
    CheckboxKit,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { BackupServerBackupJobConfiguration, BackupServerBackupJobGuestProcessing } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ApplicationProcessingOptions } from './components/ApplicationProcessingOptions/ApplicationProcessingOptions';
import { FileSystemIndexingOptions } from './components/FileSystemIndexingOptions/FileSystemIndexingOptions';
import { useJobWizardStore } from '../../stores';
import { CredentialsPanel } from './components/CredentialsPanel/CredentialsPanel';

import type { JobWizardStore } from '../../stores';

const isValid = (store: JobWizardStore) => {
    if (
        store.data.guestProcessing.guestFSIndexing.isEnabled ||
        store.data.guestProcessing.appAwareProcessing.isEnabled
    ) {
        return Boolean(
            store.data.guestProcessing.guestCredentials &&
            store.data.guestProcessing.guestCredentials.credentialsId
        );
    }

    return true;
};

export const getGuestProcessingStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<BackupServerBackupJobConfiguration> => ({
    title: lang.GUEST_PROCESSING,
    validate: () => {
        const validationResult = isValid(store);

        if (!validationResult) {
            notificationService.warning(lang.GUEST_PROCESSING, lang.SELECT_GUEST_OS_CREDENTIALS_FOR_RUNNING_VMS);
        }

        return validationResult;
    },
    render: data => <GuestProcessingStep {...data} />,
});

export const GuestProcessingStep = observer((wizardStepData: WizardStepData<BackupServerBackupJobConfiguration>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardContext = usePageWizardStore();
    const wizardStore = useJobWizardStore();

    const handleDataChange = (data: BackupServerBackupJobGuestProcessing) => {
        wizardStore.updateJobData({
            ...wizardStore.data,
            guestProcessing: {
                ...wizardStore.data.guestProcessing,
                ...data,
            },
        });
    };

    return (
        <StepLayout
            title={lang.GUEST_PROCESSING}
        >
            <FormLayout inlineLabel>
                <FormTitle>{capitalize(lang.PROCESSING_OPTIONS, true)}</FormTitle>
                <Text view={TEXT_VIEW.light}>{lang.CHOOSE_GUEST_OS_PROCESSING_OPTIONS_FOR_RUNNING_VMS}</Text>

                <NoLabelGroup
                    content={(
                        <CheckboxKit
                            checked={wizardStore.data.guestProcessing.appAwareProcessing.isEnabled}
                            onChange={(value) => {
                                handleDataChange({
                                    appAwareProcessing: { ...wizardStore.data.guestProcessing.appAwareProcessing, isEnabled: value },
                                });

                                wizardStore.updateAppAwareProcessingItems();
                            }}
                        >
                            {`${lang.ENABLE_APPLICATION_AWARE_PROCESSING}: `}
                        </CheckboxKit>
                    )}
                    subContent={(
                        <>
                            <Text view={TEXT_VIEW.light}>
                                {lang.CUSTOMIZE_APPLICATION_HANDLING_OPTIONS_FOR_INDIVIDUAL_MACHINES_AND_APPLICATIONS}
                            </Text>

                            <LinkButton
                                onClick={() => wizardContext.openPanel(hide => <ApplicationProcessingOptions hidePanel={hide} />)}
                                style={{ flexShrink: 1 }}
                                disabled={!wizardStore.data.guestProcessing.appAwareProcessing.isEnabled}
                            >
                                {lang.CUSTOMIZE_APPLICATION}
                            </LinkButton>
                        </>
                    )}
                />

                <NoLabelGroup
                    content={(
                        <CheckboxKit
                            checked={wizardStore.data.guestProcessing.guestFSIndexing.isEnabled}
                            onChange={(value) => {
                                handleDataChange({
                                    guestFSIndexing: { ...wizardStore.data.guestProcessing.guestFSIndexing, isEnabled: value },
                                });

                                wizardStore.updateGuestFSIndexingItems();
                            }}
                        >
                            {`${lang.ENABLE_GUEST_FILE_SYSTEM_INDEXING}: `}
                        </CheckboxKit>
                    )}
                    subContent={(
                        <>
                            <Text view={TEXT_VIEW.light}>
                                {lang.CUSTOMIZE_ADVANCED_GUEST_FILE_SYSTEM_INDEXING_OPTIONS_FOR_INDIVIDUAL_MACHINES}
                            </Text>

                            <LinkButton
                                onClick={() => wizardContext.openPanel(hide => <FileSystemIndexingOptions hidePanel={hide} />)}
                                style={{ flexShrink: 1 }}
                                disabled={!wizardStore.data.guestProcessing.guestFSIndexing.isEnabled}
                            >
                                {lang.CUSTOMIZE_INDEXING}
                            </LinkButton>
                        </>
                    )}
                />

                <FormTitle>{lang.GUEST_OS_CREDENTIALS}</FormTitle>
                <Text view={TEXT_VIEW.light}>{lang.SELECT_GUEST_OS_CREDENTIALS_FOR_RUNNING_VMS}</Text>

                <StackView direction={STACK_DIRECTION.column}>
                    <StackView
                        direction={STACK_DIRECTION.row}
                        align={STACK_ALIGN.center}
                        style={{ paddingTop: SPACING_L }}
                        gap={STACK_GAP.s}
                    >
                        <Text>
                            {`${lang.CREDENTIALS}: `}
                        </Text>

                        <LinkButton
                            onClick={() => wizardContext.openPanel(hide => <CredentialsPanel hidePanel={hide} />)}
                            error={!wizardStepData.validationState.isCommon() && !isValid(wizardStore)}
                            disabled={!wizardStore.areProcessingOptionsEnabled}
                        >
                            {
                                wizardStore.data.guestProcessing.guestCredentials?.credentialsId
                                    ? wizardStore.data.guestProcessing.guestCredentials.credentialsType
                                    : lang.SELECT
                            }
                        </LinkButton>
                    </StackView>
                </StackView>
            </FormLayout>

        </StepLayout>
    );
});
