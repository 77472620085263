/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useFormApi, useGlobalLang } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { VspcLang } from 'configs/languages';

import { Platform, ProtectedFileRetentionType } from '../../../enums';

import type { FormFieldNames } from '../../../enums';

interface ComboboxItem<T> {
    id: T;
    text: string;
}

type ComboboxItems<T> = ComboboxItem<T>[];
type PlatformItems = ComboboxItems<Platform>;
type RetentionTypeItems = ComboboxItems<ProtectedFileRetentionType>;

export const useParametersConfig = () => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useFormApi();

    const platforms: PlatformItems = [
        {
            id: Platform.All,
            text: lang.ALL,
        },
        {
            id: Platform.Local,
            text: lang.ON_PREMISES,
        },
        {
            id: Platform.PublicCloud,
            text: capitalize(lang.PUBLIC_CLOUD, true),
        },
        {
            id: Platform.ObjectStorage,
            text: capitalize(lang.OBJECT_STORAGE, true),
        },
    ];

    const localTypes: RetentionTypeItems = [
        {
            id: ProtectedFileRetentionType.Default,
            text: lang.ALL,
        },
        {
            id: ProtectedFileRetentionType.Backup,
            text: capitalize(lang.PRIMARY_BACKUP, true),
        },
        {
            id: ProtectedFileRetentionType.Archive,
            text: lang.ARCHIVE,
        },
    ];

    const publicCloudTypes: RetentionTypeItems = [
        {
            id: ProtectedFileRetentionType.Snapshot,
            text: lang.SNAPSHOT,
        },
    ];

    const objectStorageTypes: RetentionTypeItems = [
        {
            id: ProtectedFileRetentionType.Default,
            text: lang.ALL,
        },
        {
            id: ProtectedFileRetentionType.Backup,
            text: capitalize(lang.PRIMARY_BACKUP, true),
        },
        {
            id: ProtectedFileRetentionType.Archive,
            text: lang.ARCHIVE,
        },
    ];

    const mergedTypes: RetentionTypeItems = [].concat(localTypes, publicCloudTypes, objectStorageTypes);

    const getDeduplicatedTypes = (typesArr: RetentionTypeItems): RetentionTypeItems => {
        const typesSet = new Set<string>();

        typesArr.forEach(type => typesSet.add(JSON.stringify(type)));

        return [...typesSet].map(stringifiedType => JSON.parse(stringifiedType));
    };

    const getPlatformTypes = (platform: Platform): RetentionTypeItems => {
        switch (platform) {
            case Platform.Local:
                return localTypes;

            case Platform.PublicCloud:
                return publicCloudTypes;

            case Platform.ObjectStorage:
                return objectStorageTypes;

            case Platform.All:
            default:
                return getDeduplicatedTypes(mergedTypes);
        }
    };

    const getFirstPlatformTypeValue = (platform: Platform): ProtectedFileRetentionType => getPlatformTypes(platform)[0].id;

    const setFormTypeValueToPlatformType = (platform: Platform, fieldName: FormFieldNames): void => {
        formApi.setValue(fieldName, getFirstPlatformTypeValue(platform));
    };

    const onPlatformChange = (newValue: Platform, fieldName: FormFieldNames): void => {
        setFormTypeValueToPlatformType(newValue, fieldName);
    };

    return {
        getPlatformTypes,
        onPlatformChange,
        platforms,
    };
};
