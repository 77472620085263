/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { AllReports } from './components';

export interface ReportsAllReportsPageProps {
    currentPath?: string;
}

export const ReportsAllReportsPage: React.FC<ReportsAllReportsPageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <AllReports stateId={currentPath} />
    </TabBarBodyLayout>
);
