/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ComponentType } from 'react';

import { LazyLoadedComponent } from '../../components/LazyLoadedComponent';

interface ResellersFile {
    Resellers: ComponentType<any>;
}

export interface ResellersPageProps {
    currentPath?: string;
}

export const ResellersPage: React.FC<ResellersPageProps> = ({ currentPath }) => (
    <LazyLoadedComponent<ResellersFile>
        importPromise={() => (import('./components/Resellers') as Promise<ResellersFile>)}
        pageName={'Resellers'}
        pageProps={{
            parentStateId: currentPath,
        }}
    />
);
