/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    GoBackButton,
    PortalBodyLayout,
    useGlobalAppStorage,
    useGlobalAppData,
    useGlobalLang,
    useGlobalRoutes,
    useGlobalServices,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { VspcLang } from 'configs/languages';
import type { AppRouteItem } from '@veeam-vspc/shared/stores';

import { ExtRouteProvider } from 'views/providers/ExtRouteProvider';
import { NavigationHistory, RoutePaths, RouteSectionType } from 'configs/enums';
import { whichSection } from 'core/utils';
import { GlobalFilters } from 'components/controls/GlobalFilters';
import { NotificationBar } from '../../NotificationBar';

export interface AppPortalContentLayoutProps {
    currentPath?: string;
    items: AppRouteItem[];
}

export const AppPortalBodyLayout: React.FC<AppPortalContentLayoutProps> = observer(({ currentPath, items }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const routes = useGlobalRoutes();
    const globalAppData = useGlobalAppData();
    const { notificationService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const appStorage = useGlobalAppStorage();
    const { portalFilters } = useGlobalAddons();
    const section = whichSection(location);
    const mainRoutes = routes.filter(({ path }) => path === '')[0];
    const isHomeRoutesExists = mainRoutes.items.filter(({ path }) => path === RoutePaths.Home).length > 0;

    const onGoBackClick = () => {
        const lastHomeRoute = appStorage.getItem(NavigationHistory.LastHomeRoute) || `/${RoutePaths.Home}`;

        if (globalAppData.navigationBlockerPrompt) {
            return notificationService.confirm(globalAppData.navigationBlockerPrompt.title, globalAppData.navigationBlockerPrompt.message)
                .then((btn) => {
                    if (btn === NotificationDialogsTextKeys.Yes) {
                        globalAppData.navigationBlockerPrompt = null;
                        navigate(`${lastHomeRoute}${window.location.search}`);
                    }
                });
        }

        navigate(`${lastHomeRoute}${window.location.search}`);
    };

    useEffect(() => {
        const currentConfigRoute = location.pathname;

        if (section === RouteSectionType.Config) {
            appStorage.setItem(NavigationHistory.LastConfigRoute, currentConfigRoute);
        }

        if (section === RouteSectionType.Home) {
            appStorage.setItem(NavigationHistory.LastHomeRoute, currentConfigRoute);
        }
    }, [location]);

    const renderHeader = section === RouteSectionType.Config && isHomeRoutesExists ?
        () => <GoBackButton onClick={onGoBackClick} text={lang.EXIT_CONFIGURATION} /> :
        () => portalFilters ? <GlobalFilters /> : null;

    return (
        <>
            <PortalBodyLayout
                currentPath={currentPath}
                items={items}
                portalSideMenuProps={{
                    renderHeader,
                    hideBorder: true,
                }}
            >
                <NotificationBar>
                    <Outlet key={currentPath} />
                </NotificationBar>
            </PortalBodyLayout>

            <ExtRouteProvider />
        </>
    );
});
