/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
    colors,
    STACK_DIRECTION,
    StackView,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { ChartTitleStyled } from 'views/components/ChartWidget/components/ChartTitle';
import { HighchartsWrapper } from 'views/components/HighchartsWrapper';
import { LicenseStatusContext } from '../../contexts';
import { LicenseStatusStore } from '../../stores';
import { RoutePaths } from 'configs/enums';
import { getPieOptions } from 'views/components/HighchartsWrapper/helpers';
import { useLicenseStatusStore } from '../../hooks';

interface StatusPie {
    productType: LicenseProductType;
    title: string;
}

const LicenseStatusPie = observer(({ productType, title }: StatusPie) => {
    const lang = useGlobalLang<VspcLang>();
    const navigate = useNavigate();
    const store = useLicenseStatusStore();
    const { empty, loading, series } = store;
    const { portalEnums } = useGlobalAddons();

    const pageRoutes = {
        [LicenseProductType.BackupServer]: 'servers',
        [LicenseProductType.CloudConnectServer]: 'cloudConnect',
        [LicenseProductType.Vbo]: 'vbo',
        [LicenseProductType.VOne]: 'veeamOne',
        [LicenseProductType.VspcEndpoint]: 'agents',
    };

    const params = {
        colors: [colors.Grn400, colors.Y450, colors.R800],
        navigate,
        portalEnums,
        redirectUrl: `/${RoutePaths.Configs}/licensing/${pageRoutes[productType]}`,
    };

    const options = getPieOptions({
        lang,
        categories: [],
        height: 254,
        legend: false,
        series,
        size: 148,
        ...params,
    });

    useEffect(() => {
        store.fetchData({ productType });
    }, [productType, store]);

    return (
        <StackView direction={STACK_DIRECTION.column} style={{ flex: 1 }}>
            <ChartTitleStyled>{title}</ChartTitleStyled>

            <HighchartsWrapper
                empty={empty}
                loading={loading}
                options={options}
            />
        </StackView>
    );
});

export const LicenseStatusChart = ({ productType, title }) => {
    const { transportService } = useGlobalServices();
    const store = useMemo(() => new LicenseStatusStore(transportService), []);

    return (
        <LicenseStatusContext.Provider value={store}>
            <LicenseStatusPie productType={productType} title={title} />
        </LicenseStatusContext.Provider>
    );
};
