/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Icon,
    LinkButton,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextColumn,
    useModal,
    useGlobalLang,
    useGlobalAppData,
    transformTimeToAgoString,
} from '@veeam-vspc/shared/components';
import { JobStateRepresentation } from '@veeam-vspc/models/web-controllers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { PublicCloudJobSessionStateModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getStatusIconSrc } from 'core/utils';
import { FailureMessageDialog } from 'views/components/FailureMessageDialog';

import type { PublicCloudPlatformTypes } from 'core/enums';

export interface StatusAgoColumnProps {
    url: string;
    backupServerUid: string;
    vmUid: string;
    platform: PublicCloudPlatformTypes;
    session: PublicCloudJobSessionStateModel | null;
}

export const StatusAgoColumn: React.FC<StatusAgoColumnProps> = ({ url, backupServerUid, vmUid, platform, session }) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { sessionState, sessionStateName, sessionStartTime, sessionEndTime, sessionUid } = session || { sessionState: JobStateRepresentation.None };
    const content = sessionState === JobStateRepresentation.None ? null : sessionStateName;
    const iconSrc = content ? getStatusIconSrc(sessionState) : '';
    const isLink = Boolean(content) && [JobStateRepresentation.Failed, JobStateRepresentation.Warning].includes(sessionState);
    const [modal, actions] = useModal({
        render: ({ deactivate }) => (
            <FailureMessageDialog
                deactivate={deactivate}
                iconSrc={iconSrc}
                url={url}
                paramsForFailureMessageRequest={{
                    backupServerUid,
                    vmUid,
                    platform,
                    sessionUid,
                }}
                paramsForFailureMessage={{
                    shortDateFormat: formats.netShortDate,
                    shortTimeFormat: formats.netShortTime,
                    lastSessionTime: sessionEndTime,
                    statusName: sessionStateName,
                }}
            />
        ),
    });

    const timeAgoString = content && ` (${transformTimeToAgoString(sessionStartTime, lang)})`;

    return (
        <TextColumn>
            <StackView
                align={STACK_ALIGN.center}
                direction={STACK_DIRECTION.row}
                gap={STACK_GAP.s}
                size={{ width: '100%' }}
            >
                {
                    isLink ?
                        (
                            <Text truncate>
                                <LinkButton style={{ verticalAlign: 'middle' }} onClick={actions.activate} iconBefore={iconSrc}>
                                    {content}
                                </LinkButton>

                                <span style={{ verticalAlign: 'middle' }}>
                                    {timeAgoString}
                                </span>
                            </Text>
                        ) :
                        (
                            <>
                                { iconSrc && <Icon src={iconSrc} /> }

                                <Text truncate style={{ color: 'inherit' }}>
                                    <span>{content || ColumnTexts.DashSymbol}</span>
                                    {timeAgoString}
                                </Text>
                            </>
                        )
                }
            </StackView>

            {modal}
        </TextColumn>
    );
};
