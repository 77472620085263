/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { merge } from 'lodash';
import { renderToString } from 'react-dom/server';
import { colors } from '@veeam-vspc/shared/components';

import type * as Highcharts from 'highcharts';

import { DonutInfo } from '../components/DonutInfo';
import { Entity } from '../enums';
import { calcScale } from 'core/utils/size-helpers';
import { getPieOptions } from './get-pie-options';
import error from 'images/statuses/error.svg';
import warning from 'images/statuses/warning.svg';

import type { CustomParams } from '../interfaces';

export const getDonutOptions = ({ entity = Entity.Quota, lang, ...rest }: CustomParams): Highcharts.Options => {
    const pieOptions = getPieOptions({
        ...rest,
        lang,
    });

    return merge(pieOptions, {
        chart: {
            spacing: 0,

            events: {
                load: function() {
                    let icon;
                    let left = 0;
                    let text = lang.LEFT;
                    let total = 0;
                    let used = 0;

                    this.series && this.series[0]?.data.forEach((s) => {
                        if (s.name === 'value') {
                            used = s.y;
                        } else {
                            left = s.y;
                        }

                        total += s.y;
                    });

                    const part = used / total;

                    if (entity !== Entity.Paid && part >= 0.95) {
                        icon = part > 1 ? error : warning;
                        text = lang.REACHING_QUOTA;

                        if (part >= 1) {
                            text = part > 1 ? lang.OVER_QUOTA : lang.QUOTA_REACHED;
                        }

                        this.update({ colors: [part > 1 ? colors.R800 : colors.Y450, colors.G250] });
                    }

                    if (entity === Entity.Paid && part > 1) {
                        text = lang.OVERPAYMENT;
                    }

                    const scale = calcScale(Math.max(used, left, total));

                    const scaleValue = (value) => {
                        if (value > 0 && entity !== Entity.Paid) {
                            value = parseFloat((value / Math.pow(1024, scale.exp)).toFixed(entity === Entity.Quota ? 1 : 2));
                        }

                        return value;
                    };

                    const titleEl = renderToString(
                        <DonutInfo
                            currency={this.series[0]?.userOptions.currency}
                            entity={entity}
                            lang={lang}
                            status={{ icon, text }}
                            total={scaleValue(total)}
                            unit={scale.unit}
                            used={scaleValue(used)}
                        />
                    );

                    this.setTitle({ text: titleEl });
                },
            },
        },

        donut: true,
        legend: { enabled: false },

        plotOptions: {
            pie: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                },

                endAngle: 135,
                innerSize: 160,
                startAngle: -135,
            },

            series: {
                states: {
                    hover: {
                        brightness: 0,
                    },

                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },

        title: {
            align: 'center',
            verticalAlign: 'middle',
            useHTML: true,
            y: 48,
        },

        tooltip: {
            enabled: false,
        },
    });
};
