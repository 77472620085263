/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/shared/helpers';

import type { PieChartModel } from '@veeam-vspc/models/web-controllers';

import type { PieData, PieSeries } from 'views/components/HighchartsWrapper/interfaces';

export const transformPieData = ({ items }: PieChartModel): PieData => {
    const series: PieSeries = { data: [] };
    let empty = true;

    items?.forEach(({ currency, type, value }) => {

        if (value > 0) {
            empty = false;
        }

        series.data.push({
            currency,
            name: capitalize(type),
            y: value || null,
        });
    });

    return {
        empty,
        series: [series],
    };
};
