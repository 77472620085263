/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { Form, FormLayout, useExternalFormApi, useGlobalLang } from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { stepValidate } from '../../helpers/stepValidate';

import type { CompanyUserModel } from '../../../../../CompanyUser/interfaces';

interface LoginInfoFormProps extends WizardStepData<CompanyUserModel> {
    children: React.ReactElement[];
}

export const LoginInfoForm = ({ data, validationState, onDataChange, isEdit, stepData, children }: LoginInfoFormProps) => {
    const lang = useGlobalLang<VspcLang>();
    const formApi = useExternalFormApi<CompanyUserModel>();
    const isVcdSubtenant = data.isVcdCompany && data.userRole === UserRoleRepresentation.Subtenant;
    const loginInfoDescription = isVcdSubtenant ? `${lang.SPECIFY_LOGIN_INFORMATION}.` : lang.SPECIFY_LOGIN_AND_PASSWORD;

    useEffect(() => {
        if (stepData.isLoginExist) {
            formApi.setExternalErrors({
                login: lang.THIS_LOGIN_IS_ALREADY_USED,
            });

            delete stepData.isLoginExist;
        }
    });

    return (
        <Form
            value={data}
            validate={data => stepValidate(lang, data, isEdit)}
            validationState={validationState}
            onChange={onDataChange}
            key='loginInfo'
            externalFormApi={formApi}
        >
            <StepLayout
                title={lang.LOGIN_INFO}
                description={loginInfoDescription}
            >
                <FormLayout inlineLabel>
                    {children}
                </FormLayout>
            </StepLayout>
        </Form>
    );
};
