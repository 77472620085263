/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { Search, Toolbar, TOOLBAR_ITEM_TYPE, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { WinPrincipalModel } from '@veeam-vspc/models/web-controllers';
import type { GridStore, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { ToolbarFirst } from 'views/pages/RolesAndUsersPage/components/AdminUser/components/Account/components/AccountGrid/components/ToolbarFirst';
import { MAX_INTEGER_32 } from 'core/const';


import type {
    AdminUserModel,
    AccountFilterModel,
    DiscoverySamAccountRequest,
} from 'views/pages/RolesAndUsersPage/components/AdminUser/interfaces';

export const AccountGrid = ({ data, onDataChange }: WizardStepData<AdminUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const api = useRef<GridStore<WinPrincipalModel, any, AccountFilterModel>>();

    return (
        <ConfiguredGrid
            columns={[
                {
                    id: 'displayName',
                    title: lang.ACCOUNT,
                    cell: rowData => rowData.displayName,
                },
                {
                    id: 'description',
                    title: lang.DESCRIPTION,
                    cell: rowData => rowData.description,
                },
            ]}
            initialState={{
                filters: {
                    userType: data.model.type,
                    samAccountName: '',
                },
                selected: data.model.sId ? [ data.model ] as WinPrincipalModel[] : undefined,
            }}
            data={requestParams => transportService
                .request<DiscoverySamAccountRequest, RequestSuccessResponse<WinPrincipalModel[]>>('/AccountManagement/DiscoverySamAccount', {
                    ...requestParams,
                    discoveryModel: {
                        domain: data.domain,
                        password: data.password,
                        userName: data.userName,
                        samAccountName: requestParams.filter.samAccountName,
                        type: requestParams.filter.userType,
                    },
                })}
            toggleable={false}
            selection={{
                field: 'sId',
                multiple: false,
                checkbox: false,
            }}
            paginationLimit={MAX_INTEGER_32}
            onSelectionChange={(selected) => {
                const selectedAccount = selected[0];

                data.model = {
                    sId: selectedAccount?.sId,
                    type: api.current.filters.userType,
                    contextType: selectedAccount?.contextType,
                    name: selectedAccount?.name,
                    description: selectedAccount?.description,
                    roleType: data.model.roleType,
                } as WinPrincipalModel;

                onDataChange(data);
            }}
            toolbars={[
                () => <ToolbarFirst />,
                () => (
                    <Toolbar
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='samAccountName'
                                        placeholder={lang.ACCOUNT}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
            api={api}
        />
    );
};

