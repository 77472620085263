/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { CheckboxKit, WizardSummary, useGlobalLang, useGlobalAppData } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { capitalize, normalizeOldDateFormat, plural } from '@veeam-vspc/shared/helpers';
import {
    Vb365BackupJobSchedulePolicySchedulePolicyType,
    Vb365JobItemComposedItemType,
    Vb365ServerOwnership,
} from '@veeam-vspc/models/rest';
import { ColumnTexts } from '@veeam-vspc/shared/enums';


import type { Vb365BackupJob } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { useJobWizardStore } from '../../stores';
import { StepLayout } from 'components/layouts/StepLayout';
import { getPeriodicallyReadableValues } from '../../../../helpers';
import { resourceValueGetter } from '../BackupMode/helpers';
import { formatStringTime } from 'core/utils/date-helpers';

export const getSummaryStep = (lang: VspcLang): WizardStep<Vb365BackupJob> => ({
    title: lang.SUMMARY,
    render: data => <SummaryStep {...data} />,
});

const SummaryStep = observer(({ isEdit }: WizardStepData<Vb365BackupJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const { portalUser, formats } = useGlobalAppData();
    const shortTimeFormat = normalizeOldDateFormat(formats.netShortTime);

    const jobData = wizardStore.data;

    const selectedProxy = () => wizardStore.backupProxies.find(x => x.instanceUid === wizardStore.selectedBackupRepository?.proxyUid)?.hostName;
    const hideProxyInfo = (portalUser.isCompanyOwner() || portalUser.isResellerPortal()) &&
        wizardStore.selectedOrganization._embedded.vb365Server.ownership === Vb365ServerOwnership.Hosted;

    const policyScheduling = () => {
        const { schedulePolicy } = jobData;

        if (!schedulePolicy || !schedulePolicy.scheduleEnabled) {
            return ColumnTexts.DashSymbol;
        }

        if (schedulePolicy.schedulePolicyType === Vb365BackupJobSchedulePolicySchedulePolicyType.Daily && schedulePolicy.dailyTime) {
            return `${schedulePolicy.dailyType} ${lang.AT.toLowerCase()} ${formatStringTime(schedulePolicy.dailyTime, shortTimeFormat, true)}`;
        }

        if (
            schedulePolicy.schedulePolicyType === Vb365BackupJobSchedulePolicySchedulePolicyType.Periodically &&
            schedulePolicy.periodicallyEvery
        ) {
            return `${lang.EVERY} ${getPeriodicallyReadableValues(lang)[schedulePolicy.periodicallyEvery]}`;
        }

        return ColumnTexts.DashSymbol;
    };

    const selectedItems = (collection: keyof Pick<Vb365BackupJob, 'excludedItems' | 'selectedItems'>) => jobData[collection]?.map((item) => {
        if (item.itemType === Vb365JobItemComposedItemType.PersonalSites) {
            return lang.PERSONAL_SITES;
        }

        if (item.itemType === Vb365JobItemComposedItemType.PartialOrganization && wizardStore.selectedOrganization) {
            return wizardStore.selectedOrganization.name;
        }

        return resourceValueGetter(item, 'name', wizardStore);
    }).join(', ');

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: lang.GENERAL,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: jobData.name || ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.DESCRIPTION}:`,
                                value: jobData.description || ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.BACKUP_MODE, true),
                        fields: [
                            {
                                label: capitalize(lang.BACKUP_MODE, true),
                                value: jobData.backupType === 'EntireOrganization'
                                    ? lang.ENTIRE_ORGANIZATION
                                    : lang.BACK_UP_THE_SPECIFIED_OBJECTS,
                            },
                            {
                                label: `${lang.INCLUDED_OBJECTS}:`,
                                value: selectedItems('selectedItems') || ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.EXCLUDED_OBJECTS}:`,
                                value: selectedItems('excludedItems') || ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: lang.BACKUP_REPOSITORY,
                        fields: [
                            {
                                label: `${lang.BACKUP_PROXY}:`,
                                value: selectedProxy() || ColumnTexts.DashSymbol,
                                hidden: hideProxyInfo,
                            },
                            {
                                label: `${lang.BACKUP_REPOSITORY}:`,
                                value: wizardStore.selectedBackupRepository?.name || ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                    {
                        title: lang.SCHEDULE,
                        fields: [
                            {
                                label: `${lang.JOB_SCHEDULING}:`,
                                value: policyScheduling(),
                            },
                            {
                                label: `${lang.RETRY_FAILED_OBJECTS}:`,
                                value: jobData.schedulePolicy && jobData.schedulePolicy.retryEnabled && jobData.schedulePolicy.retryNumber
                                    ? plural(jobData.schedulePolicy.retryNumber, lang.TIME.toLowerCase())
                                    : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.WAIT_BEFORE_RETRY}:`,
                                value: jobData.schedulePolicy && jobData.schedulePolicy.retryEnabled && jobData.schedulePolicy.retryWaitInterval
                                    ? plural(jobData.schedulePolicy.retryWaitInterval, lang.MINUTE, lang.MINUTES.toLowerCase())
                                    : ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.BACKUP_WINDOW}:`,
                                value: jobData.schedulePolicy && jobData.schedulePolicy.backupWindowEnabled
                                    ? lang.TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW
                                    : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                ]}
            />

            {!isEdit && (
                <CheckboxKit
                    checked={wizardStore.startJobImmediately}
                    onChange={value => wizardStore.startJobImmediately = value}
                    style={{ width: 'fit-content' }}
                >
                    {lang.START_THE_JOB_WHEN_I_CLICK_FINISH}
                </CheckboxKit>
            )}
        </StepLayout>
    );
});
