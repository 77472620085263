/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VspcLang } from 'configs/languages';

import { AuthTypes } from '../../../enums';

export const getAuthTypes = (lang: VspcLang) => [
    { id: AuthTypes.Basic, name: lang.BASIC },
    { id: AuthTypes.Modern, name: lang.MODERN },
];
