/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    toolbarItemDecorators,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useState } from 'react';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { GeneratedReportModel, ReportParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import viewComputersSrc from 'images/actions/view-computers.png';
import sendEmailSrc from 'images/actions/resend-email.png';
import removeSrc from 'images/actions/remove.svg';
import { useAsyncAction } from 'views/hooks';
import { ConfigActionIds, ConfigSectionIds } from 'core/enums';

export const AllReportsActionToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const [loading, setLoading] = useState(false);
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const { notificationService, fileTransportService } = useGlobalServices();
    const gridApi = useGridApi<GeneratedReportModel, unknown, ReportParam['filter']>();

    const viewComputers = () => {
        const { selected } = gridApi;

        setLoading(true);

        fileTransportService.downloadFile(`/Report/GetGeneratedReportPdf?uid=${selected[0].statementID}`)
            .finally(() => setLoading(false));
    };

    const send = () => {
        const { selected } = gridApi;
        const ids = selected.map(({ id }) => id);

        doAsyncAction('/Report/SendGeneratedReport', lang.SEND_REPORT, { ids });
    };

    const remove = () => {
        const { selected } = gridApi;
        const ids = selected.map(({ id }) => id);

        notificationService
            .confirm(lang.DELETE_REPORT, lang.THIS_ACTION_WILL_DELETE_REPORTS)
            .then((key) => {
                if (key === NotificationDialogsTextKeys.Yes) {
                    doAsyncAction('/Report/DeleteGeneratedReport', lang.REMOVE, { ids });
                }
            });
    };

    const actions = [{
        id: ConfigActionIds.ActionToolbarViewReports,
        type: TOOLBAR_ITEM_TYPE.button,
        text: lang.VIEW,
        iconSrc: viewComputersSrc,
        onClick: viewComputers,
        decorators: [
            toolbarItemDecorators.disallowWithoutSingleSelection(),
        ],
    }, {
        type: TOOLBAR_ITEM_TYPE.separator,
    }, {
        type: TOOLBAR_ITEM_TYPE.button,
        text: lang.SEND,
        iconSrc: sendEmailSrc,
        onClick: send,
        decorators: [
            toolbarItemDecorators.disallowWithoutSelection(),
        ],
    }, {
        type: TOOLBAR_ITEM_TYPE.separator,
    }, {
        type: TOOLBAR_ITEM_TYPE.button,
        text: lang.REMOVE,
        iconSrc: removeSrc,
        onClick: remove,
        decorators: [
            toolbarItemDecorators.disallowWithoutSelection(),
        ],
    }];

    return (
        <>
            <ConfiguredToolbar
                sectionId={ConfigSectionIds.ReportsAllReports}
                view={TOOLBAR_VIEW_TYPE.default}
                items={actions}
            />

            {loading && <PortalSpinner delayTime={300} />}
            {asyncActionLoader}
        </>
    );
};
