/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { addParamsToQuery } from '@veeam-vspc/shared/core';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors } from '@veeam-vspc/shared/components';
import { merge } from 'lodash';
import { renderToString } from 'react-dom/server';

import type * as Highcharts from 'highcharts';

import { QueryKeys } from 'core/enums';
import { Row, Tooltip } from '../components/Tooltip';
import { getBaseOptions } from './get-base-options';
import { objectToQueryUrl } from 'core/utils';
import { sizeToString } from 'core/utils/size-helpers';

import type { CustomParams } from '../interfaces';

export const getPieOptions = ({
    lang,
    navigate,
    portalEnums,
    redirectUrl,
    size,
    sizeFormat = null,
    spacingBottom = false,
    ...rest
}: CustomParams): Highcharts.Options => {
    const baseOptions = getBaseOptions(rest);

    return merge(baseOptions, {
        chart: {
            spacing: [8, 8, spacingBottom ? 36 : 0, 8], // 36 = 32 (legend height) + 4 (legend margin)
            type: 'pie',

            events: {
                load: function() {
                    let index;

                    const oneSlice = this.series && this.series[0]?.data.filter((s, i) => {
                        if (s.y > 0) {
                            index = i;
                        }

                        return s.y > 0;
                    }).length === 1;

                    this.update({
                        plotOptions: {
                            pie: { borderColor: oneSlice ? baseOptions.colors[index] : colors.G000 },
                            series: { states: { hover: { brightness: oneSlice ? 0 : 0.1 } } },
                        },
                    });
                },
            },
        },

        plotOptions: {
            pie: {
                center: ['50%', '50%'],
                cursor: redirectUrl ? 'pointer' : 'default',
                showInLegend: true,
                size, // todo: try to calculate dynamically

                dataLabels: {
                    enabled: true,
                    formatter: function() {
                        if (!this.point) {
                            return;
                        }

                        const { y, name } = this.point;
                        let formattedValue = y;

                        if (sizeFormat) {
                            formattedValue = sizeToString({ lang, pointFixed: sizeFormat.digits, value: y });
                        }

                        return `${name}: ${this.series.data[this.series.index].currency || ''}${formattedValue.toLocaleString()}`;
                    },
                    style: {
                        textOverflow: 'none',
                    },
                },

                events: {
                    click(e) {
                        if (redirectUrl) {
                            const stateInt = portalEnums.getEnumIntValue('CommonLicenseStateRepresentation', e.point.name.toLowerCase());

                            const filtersQuery = objectToQueryUrl(QueryKeys.GridFilters, {
                                licenseStatus: [stateInt],
                            });

                            navigate(addParamsToQuery(redirectUrl, filtersQuery));
                        }
                    },
                },
            },
        },

        tooltip: {
            formatter: function() {
                if (!this.point) {
                    return;
                }

                const { y } = this.point;
                let formattedValue = y;

                if (sizeFormat) {
                    formattedValue = sizeToString({ lang, pointFixed: sizeFormat.digits, value: y });
                }

                return renderToString(
                    <Tooltip title={this.x}>
                        <Row
                            color={this.color as string}
                            name={capitalize(this.key)}
                            symbol={'circle'}
                            value={`${this.series.data[this.series.index].currency || ''}${formattedValue.toLocaleString()}`}
                        />
                    </Tooltip>
                );
            },
        },
    });
};
