/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getXmlTagAttrByName, getXmlTagByName } from '../../../helpers';
import { XmlFileTagAttributes, XmlFileTagAttributesValues, XmlFileTags } from '../../../enums';

export const checkXmlFileValidity = (xml: XMLDocument, isVbrUpgrade: boolean): boolean => {
    const mainTag = getXmlTagByName(xml, XmlFileTags.Main)[0];

    if (!mainTag) {
        return false;
    }

    const xmlBundleAttributeValue = getXmlTagAttrByName(mainTag, XmlFileTagAttributes.Bundle)?.toLowerCase();
    const xmlModeAttributeValue = getXmlTagAttrByName(mainTag, XmlFileTagAttributes.Mode)?.toLowerCase();
    const validModeValue = isVbrUpgrade ? XmlFileTagAttributesValues.UpgradeMode : XmlFileTagAttributesValues.InstallMode;

    const isValidBundleValue = xmlBundleAttributeValue === XmlFileTagAttributesValues.Bundle;
    const isValidModeValue = xmlModeAttributeValue === validModeValue;

    return isValidBundleValue && isValidModeValue;
};
