/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';
import {
    Combobox,
    CONTROL_SIZE,
    CopyInput,
    FIELD_L,
    FieldLayout,
    INDENT,
    PasswordInput,
    Text,
    TEXT_VIEW,
    TextInput,
    TEXT_SIZE,
    useGlobalLang,
    NOTEBAR_STATUS,
    TEXT_WHITE_SPACE,
    NoteBar,
    NoLabelGroup,
    FormLayout,
    STACK_DIRECTION,
    StackView,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { FormFieldNames } from '../../../../enums';
import { getAuthTypes, getMailServicesTypes } from '../../helpers';
import { useServerSettingsDialogFormStore } from '../../../../stores';

import type { AuthTypes } from '../../../../enums';

export const ModernSettingsFields = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { onAuthTypeChange, redirectUrl, formData: { smtp } } = useServerSettingsDialogFormStore();
    const oAuth2Kind = smtp.oAuth2Credential.clientSettings.kind;
    const isAzureOAth2Kind = oAuth2Kind === OAuth2ClientSettingsKind.Azure;

    return (
        <FormLayout inlineLabel>
            <Combobox
                data={getAuthTypes(lang)}
                size={CONTROL_SIZE.m}
                name={FormFieldNames.AuthType}
                label={lang.AUTHENTICATION_TYPE}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                onChange={(newAuthType: AuthTypes) => onAuthTypeChange(newAuthType)}
            />

            <Combobox
                size={CONTROL_SIZE.m}
                data={getMailServicesTypes(lang)}
                label={lang.MAIL_SERVICE}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                name={FormFieldNames.MailServiceKind}
            />

            <TextInput
                size={CONTROL_SIZE.l}
                name={FormFieldNames.ClientId}
                label={lang.APPLICATION_CLIENT_ID}
                placeholder={lang.ENTER_APPLICATION_CLIENT_ID}
                helpText={(
                    <Text
                        size={TEXT_SIZE.s}
                        view={TEXT_VIEW.light}
                        style={{ width: FIELD_L }}
                    >
                        {lang.REGISTER_YOUR_APPLICATION}
                    </Text>
                )}
                helpTextWrap
            />

            <PasswordInput
                size={CONTROL_SIZE.l}
                label={lang.CLIENT_SECRET}
                name={FormFieldNames.ClientSecret}
                placeholder={lang.ENTER_CLIENT_SECRET}
            />

            {isAzureOAth2Kind && (
                <TextInput
                    label={lang.TENANT_ID}
                    size={CONTROL_SIZE.l}
                    name={FormFieldNames.TenantId}
                    placeholder={lang.ENTER_TENANT_ID}
                />
            )}

            <FieldLayout label={`${lang.REDIRECT_URL}:`}>
                <CopyInput value={redirectUrl} size={CONTROL_SIZE.l} />
            </FieldLayout>

            <NoLabelGroup
                content={(
                    <StackView indentTop={INDENT.s} direction={STACK_DIRECTION.column}>
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text whitespace={TEXT_WHITE_SPACE.normal}>
                                {lang.TO_AUTHORIZE_THE_APPLICATION}
                            </Text>
                        </NoteBar>
                    </StackView>
                )}
            />
        </FormLayout>
    );
});
