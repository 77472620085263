/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { ManagedVmType } from '@veeam-vspc/models/web-controllers';

export class ManagedVmsStore {

    @observable managedVmType: ManagedVmType;

    constructor(type: ManagedVmType, readonly lsStateId: string) {
        makeObservable(this);
        this.managedVmType = type;
    }

    @action.bound
    setManagedVmType(v: ManagedVmType): void {
        this.managedVmType = v;
    }
}
