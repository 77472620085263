/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { AccountGrid } from 'views/pages/RolesAndUsersPage/components/AdminUser/components/Account/components/AccountGrid/AccountGrid';

import type { AdminUserModel } from '../../interfaces';

const Account = (stepData: WizardStepData<AdminUserModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();

    if (stepData.validationState.isForce()) {
        stepData.validationState.markAsHandledForce();

        if (!stepData.data.model.sId) {
            notificationService.error(lang.ERROR, [lang.ACCOUNT_IS_NOT_SET]);
        }
    }

    return (
        <StepLayout
            title={lang.ACCOUNT}
            description={lang.SPECIFY_ACCOUNT_TYPE}
        >
            <AccountGrid {...stepData} />
        </StepLayout>
    );
};

export const getAccountStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    isHidden: ({ isEdit }) => isEdit,
    validate: ({ data }) => !!data.model.sId,
    render: data => <Account {...data} />,
});
