/**
 * Copyright © Veeam Software Group GmbH.
 */

export const objectToQueryUrl = <T extends {}>(queryField: string, obj: T): string => {
    const searchParams = new URLSearchParams();
    searchParams.set(queryField, JSON.stringify(obj));

    return searchParams.toString();
};
