/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/shared/components';
import { isPositiveNumber } from '@veeam-vspc/shared/helpers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { RestorePointsDialog } from './components/RestorePointsDialog/RestorePointsDialog';

import type { RestorePointsColumnProps } from './interfaces';

export const RestorePointsColumn: React.FC<RestorePointsColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <RestorePointsDialog
                deactivate={deactivate}
                {...restProps}
            />
        )}
    />
);


