/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    FormValidator,
    isValid,
    NoteBar,
    NOTEBAR_STATUS,
    PasswordInput,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { TransportService } from '@veeam-vspc/shared/core';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse, RequestErrorResponse, AppServices } from '@veeam-vspc/shared/interfaces';
import type { ValidationResult, VbrDeployTenantValidationParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { DataCache } from 'core/utils/data-cache';
import { VbrDeployType } from '../../enums';

import type { VbrDeploySettingsWizardModel } from '../../entries';

const formValidate = (data: VbrDeploySettingsWizardModel) => {
    const validator = new FormValidator(data);

    validator.validate('tenantPassword')
        .string()
        .required();

    return validator.result();
};

const checkTenantCredentials = (transportService: TransportService<RequestErrorResponse | Error>) =>
    new DataCache<ValidationResult, VbrDeployTenantValidationParam>(
        data => transportService
            .request<VbrDeployTenantValidationParam, RequestSuccessResponse<ValidationResult>>('/BackupServer/CheckTenantCredentials', {
                organizationUid: data.organizationUid,
                tenantName: data.tenantName,
                password: data.password,
            })
            .then((resp: RequestSuccessResponse<ValidationResult>) => resp.data)
    );

const tenantInformationStepValidate = (
    data: VbrDeploySettingsWizardModel,
    lang: VspcLang,
    appServices: AppServices,
): boolean | Promise<boolean> => {
    const { notificationService, transportService } = appServices;

    if (isValid(value => formValidate(value), data)) {
        return checkTenantCredentials(transportService)
            .load({
                organizationUid: data.organizationUid,
                tenantName: data.tenantName,
                password: data.tenantPassword,
            })
            .then((data: ValidationResult) => {
                if (!data.validationPass) {
                    notificationService.error(lang.COMPANY_INFO, data.reasonText);
                }

                return data.validationPass;
            })
            .catch((err: RequestErrorResponse) => {
                err && console.error(err);

                return false;
            });
    }

    return false;
};

const TenantInformation = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const { data, onDataChange, validationState } = wizardStepData;
    const lang = useGlobalLang<VspcLang>();

    return (
        <Form
            value={data}
            validate={formValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.COMPANY_INFO}
                description={`${lang.SPECIFY_THE_COMPANY_USER_INFO}.`}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <FormLayout inlineLabel>
                        <TextInput
                            disabled={!data.isVcdTenant}
                            name='tenantName'
                            label={lang.USERNAME}
                        />

                        <PasswordInput
                            name='tenantPassword'
                            label={lang.PASSWORD}
                        />
                    </FormLayout>

                    {data.isVcdTenant && (
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text>{lang.SPECIFY_ADMIN_ACCOUNT_FROM_VMWARE_CLOUD}</Text>
                        </NoteBar>
                    )}
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getTenantInformationStep = (
    title: string,
    lang: VspcLang,
    appServices: AppServices
): WizardStep<VbrDeploySettingsWizardModel> => ({
    title,
    isHidden: ({ data }) => data.vbrDeployType === VbrDeployType.Upgrade || data.isInfrastructureAgent === true,
    validate: ({ data }) => tenantInformationStepValidate(data, lang, appServices),
    render: data => <TenantInformation {...data} />,
});
