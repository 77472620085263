/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Form, FormLayout, isValid, PasswordInput, TextInput, useExternalFormApi, useGlobalLang } from '@veeam-vspc/shared/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { stepValidate } from './helpers';

import type { VbrDeploySettingsWizardModel } from '../../entries';

const Account = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi<VbrDeploySettingsWizardModel>();

    return (
        <StepLayout
            title={lang.SERVICE_ACCOUNT}
            description={lang.SELECT_AN_ACCOUNT}
        >
            <Form
                value={data}
                validate={stepValidate(lang)}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='adminUserName'
                        label={lang.USERNAME}
                    />

                    <PasswordInput
                        name='adminPassword'
                        label={lang.PASSWORD}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};

export const getAccountStep = (lang: VspcLang): WizardStep<VbrDeploySettingsWizardModel> => ({
    title: lang.ACCOUNT,
    validate: ({ data }) => isValid(stepValidate(lang), data),
    render: data => <Account {...data} />,
});
