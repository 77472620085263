/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants, Text } from '@veeam-vspc/shared/components';
import React from 'react';


const HelpStyled = styled.div<{ isHelpVisible: boolean; }>`
  background-color: ${colors.Y50};
  width: 600px;
  display: ${props => props.isHelpVisible ? 'block' : 'none'};
  margin-bottom: ${constants.SPACING_S};
  padding: ${constants.SPACING_S};
`;

export const Help = ({ isHelpVisible }: { isHelpVisible: boolean; }) => (
    <HelpStyled isHelpVisible={isHelpVisible}>
        <Text>
                Use the following variables to configure the rules:
            <br />
            <b>%CompanyNameAlias%</b>
            {' '}
- company name concatenated with Alias. This value is unique for each company.
            <br />
            <b>%LocationName%</b>
            {' '}
- the name of the location.
            <br />
        </Text>

        <br />

        <Text>
            <b>Example.</b>
            <br />
                Company Administrator role should be assigned to the users with security_attribute = “adm”.
                The rule should work only for the companies “Company1”, “Company2”, “Company3”.
            <br />
            <br />

            <table>
                <tr>
                    <td>Claim:</td>
                    <td>Security_attribute</td>
                </tr>

                <tr>
                    <td>Expression:</td>
                    <td>Equals</td>
                </tr>

                <tr>
                    <td>Value:</td>
                    <td>adm</td>
                </tr>
            </table>

            <br />
        </Text>
    </HelpStyled>
);


