/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    TextColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import {
    JobMode,
    VbrBackupAgentJobKind,
    TargetTypeRepresentation,
    SystemTypeRepresentation,
    EndpointSourceTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PageDialogProps, GridColumnProps } from '@veeam-vspc/shared/components';
import type { ComputerProtectingJobModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { BackupJobsDialogToolbar } from '../BackupJobsDialogToolbar/BackupJobsDialogToolbar';
import { OldRestorePointColumn } from 'views/components/columns/OldRestorePointColumn';


import type { BackupJobsDialogExtraParams, BackupJobsDialogFilterModel, BackupJobsDialogRequestParams } from '../../interfaces';

export interface BackupJobsDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    title: string;
    instanceUid: string;
    systemType: SystemTypeRepresentation;
    jobKind: VbrBackupAgentJobKind;
    hideMalwareState?: boolean;
}

export const BackupJobsDialog: React.FC<BackupJobsDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { portalEnums } = useGlobalAddons();
    const idProperty = 'instanceUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        title,
        instanceUid,
        systemType,
        jobKind,
        hideMalwareState = false,
    } = props;
    const extraParams: BackupJobsDialogExtraParams = {
        computersUids: [instanceUid],
    };

    const columns: GridColumnProps<ComputerProtectingJobModel>[] = [
        {
            id: 'name',
            title: capitalize(lang.JOB_NAME),
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        jobKind === VbrBackupAgentJobKind.BackupJob && {
            id: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: rowData => rowData.operationModeRendered,
            defaultValue: lang.NO_INFO,
        },
        jobKind === VbrBackupAgentJobKind.BackupJob && {
            id: 'backupModeRendered',
            title: capitalize(lang.BACKUP_TYPE),
            cell: rowData => rowData.backupModeRendered,
            defaultValue: lang.NO_INFO,
        },
        !hideMalwareState && {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
        },
        {
            id: 'restorePoints',
            title: lang.RESTORE_POINTS,
            cell: (rowData) => {
                const isVspcJob = rowData.sourceType === EndpointSourceTypeRepresentation.Vac;
                const isMbaJob = rowData.jobManagementMode === JobMode.ManagedByAgent;
                const isLocalTarget = [
                    TargetTypeRepresentation.SharedFolder,
                    TargetTypeRepresentation.ComputerFolder,
                ].includes(rowData.destinationType);
                const isLink = (!isVspcJob && isMbaJob) ? !isLocalTarget : true;

                return (
                    <OldRestorePointColumn
                        stateId={`${stateId}.Dialog.RestorePoints`}
                        value={rowData.restorePoints}
                        name={rowData.name}
                        isLink={isLink}
                        extraParams={{
                            agentId: rowData.agentId,
                            configId: rowData.configUid,
                            endpointId: rowData.endpointUid,
                        }}
                        hideMalwareState={hideMalwareState}
                    />
                );
            },
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: rowData => (
                <GridDateColumn value={rowData.lastRestorePoint} />
            ),
        },
        {
            id: 'backupSize',
            title: lang.BACKUP_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.backupSize} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'destination',
            title: lang.DESTINATION,
            cell: rowData => rowData.destination,
        },
        systemType === SystemTypeRepresentation.Windows && {
            id: 'nextRun',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn value={rowData.nextRun} />
            ),
            hidden: true,
        },
    ];

    return (
        <PageDialog
            title={title}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <ConfiguredGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.id,
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: BackupJobsDialogRequestParams<BackupJobsDialogFilterModel>) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<ComputerProtectingJobModel[]>>(
                            jobKind === VbrBackupAgentJobKind.BackupJob
                                ? '/ProtectedWorkloads/GetComputersProtectingJobs'
                                : '/ProtectedWorkloads/GetComputersProtectingCopyJobs',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={true}
                    selection={{
                        field: idProperty,
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <BackupJobsDialogToolbar
                                jobKind={jobKind}
                                idProperty={idProperty}
                                extraParams={extraParams}
                                method={jobKind === VbrBackupAgentJobKind.BackupJob ? 'GetComputersProtectingJobs' : 'GetComputersProtectingCopyJobs'}
                                selectedOnly={true}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
