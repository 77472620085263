/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getMapByMapId } from './get-map-by-map-id';

export const getFriendlyName = async({
    locationAdmin0Code,
    locationAdmin1Code,
}) => {
    const map = await getMapByMapId(locationAdmin0Code);
    return map.features
        .find(region => region.properties['hc-key'] === locationAdmin1Code)?.properties.name || '';
};
