/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { HardwarePlanShortInfo } from '@veeam-vspc/models/web-controllers';
import type { TransportService } from '@veeam-vspc/shared/core';

import { transformData } from '../helpers';

import type { ComboboxItem } from 'core/interfaces';

export class HwPlansService {
    static fetchData(transportService: TransportService<RequestErrorResponse | Error>, lang): Promise<ComboboxItem[]> {
        return transportService
            .request('/Company/GetHwPlans')
            .then((resp: RequestSuccessResponse<HardwarePlanShortInfo[]>) => transformData(resp.data, lang));
    }
}
