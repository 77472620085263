/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Combobox, createLinkControl, FieldLayout } from '@veeam/components';
import React from 'react';
import { useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { MAX_NUMBER_OF_ROWS_OPTIONS } from './configs';

import type { ComboboxItem } from './interfaces';

export const MaxNumberOfRows: React.FC<{ maxNumberOfRows: number; handleChangeMaxNumberOfRows: (maxNumberValue: number) => void; }> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { maxNumberOfRows, handleChangeMaxNumberOfRows } = props;

    return (
        <FieldLayout
            inlineLabel
            label={`${lang.MAX_NUMBER_OF_ROWS}:`}
        >
            <Combobox
                controlRenderer={createLinkControl()}
                value={maxNumberOfRows}
                onChange={handleChangeMaxNumberOfRows}
                textGetter={(p: ComboboxItem) => p.text}
                valueGetter={(p: ComboboxItem) => p.id}
                data={MAX_NUMBER_OF_ROWS_OPTIONS}
            />
        </FieldLayout>
    );
};
