/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ValueTypes } from 'core/utils/sanitize-complex-object/enums/value-types';

export const calcValueType = <T>(value: T) => {
    if (typeof value === 'string') {
        return ValueTypes.String;
    }

    if (Array.isArray(value)) {
        return ValueTypes.Array;
    }

    if (value === null) {
        return ValueTypes.Null;
    }

    if (typeof value === 'object') {
        return ValueTypes.Object;
    }

    return ValueTypes.Unknown;
};
