/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';
import { DownloaderState } from '@veeam-vspc/models/web-controllers';
import { LinkButton, Button, useModal, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse, BaseRequestData } from '@veeam-vspc/shared/interfaces';
import type { IconType } from '@veeam-vspc/shared/components';
import type { GetLogsProgressJson } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { DownloadLogsDialog } from './components/DownloadLogsDialog';

interface DownloadLogsButtonProps {
    dialogHeader?: string;
    buttonText?: string;
    progressStartedMessage?: string;
    isLinkButton?: boolean;
    showSelectPeriodDialog?: boolean;
    downloadInitUrl?: string;
    downloadProgressUrl?: string;
    intervalTimeInMs?: number;
    requestParams?: Record<string, any>;
    iconBefore?: IconType;
    disabled?: boolean;
}

export const DownloadLogsButton: React.FC<DownloadLogsButtonProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const {
        buttonText = lang.DOWNLOAD_LOGS,
        progressStartedMessage = lang.DOWNLOADING_SUPPORT_LOGS,
        isLinkButton = false,
        showSelectPeriodDialog = true,
        downloadInitUrl = '/Log/DownloadVacLogsInit',
        downloadProgressUrl = '/Log/DownloadLogsGetProgress',
        intervalTimeInMs = 1 * TimeUnitsInMs.Second,
        requestParams,
        iconBefore,
        disabled,
    } = props;
    const { transportService, notificationService, fileTransportService } = useGlobalServices();
    const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
    let timerId: number | null = null;
    const progressDownloadLogs = (rcopAutoUpdate = false) => {
        transportService.request<BaseRequestData, RequestSuccessResponse<GetLogsProgressJson>>(downloadProgressUrl, { rcopAutoUpdate })
            .then((resp: RequestSuccessResponse<GetLogsProgressJson>) => {
                const { data } = resp;
                const { state, fileUrl } = data ?? {};

                if (state === DownloaderState.Success) {
                    setIsDownloadInProgress(false);
                    fileTransportService.downloadFile(fileUrl);
                } else if (state === DownloaderState.Gathering || state === DownloaderState.Downloading) {
                    timerId = window.setTimeout(() => progressDownloadLogs(true), intervalTimeInMs);
                } else if (state === DownloaderState.Error || (resp as any).message || (data as GetLogsProgressJson).message) {
                    const errorMessage = (resp as any).message || (data as GetLogsProgressJson).message || 'Some error happened';

                    setIsDownloadInProgress(false);
                    notificationService.error(buttonText, errorMessage);
                }
            })
            .catch((resp) => {
                resp && console.error(resp);
                setIsDownloadInProgress(false);
            });
    };
    const sendInitialRequest = (additionalParams: Record<string, any> = {}) => {
        setIsDownloadInProgress(true);
        transportService.request(downloadInitUrl, {
            ...requestParams,
            ...additionalParams,
        })
            .then(() => {
                notificationService.info(buttonText, progressStartedMessage);
                progressDownloadLogs();
            })
            .catch((resp) => {
                resp && console.error(resp);
                setIsDownloadInProgress(false);
            });
    };

    const [downloadLogsModal, downloadLogsModalAction] = useModal({
        render: ({ deactivate }) => (
            <DownloadLogsDialog
                header={props.dialogHeader}
                deactivate={deactivate}
                onConfirm={(daysCount) => {
                    const todayTime = (new Date()).getTime();

                    sendInitialRequest({
                        startDate: new Date(todayTime - daysCount * TimeUnitsInMs.Day).toISOString(),
                        endDate: new Date().toISOString(),
                    });
                }}
            />
        ),
    });

    useEffect(() => () => window.clearTimeout(timerId as number), [timerId]); // clear possible timer on unmount

    return (
        <>
            {isLinkButton && (
                <LinkButton
                    data-tid={'download-logs-button'}
                    disabled={disabled || isDownloadInProgress}
                    onClick={() => showSelectPeriodDialog ? downloadLogsModalAction.activate() : sendInitialRequest()}
                    iconBefore={iconBefore}
                >
                    {buttonText}
                </LinkButton>
            )}

            {!isLinkButton && (
                <Button
                    data-tid={'download-logs-button'}
                    disabled={disabled || isDownloadInProgress}
                    onClick={() => showSelectPeriodDialog ? downloadLogsModalAction.activate() : sendInitialRequest()}
                >
                    {buttonText}
                </Button>
            )}

            {downloadLogsModal}
        </>
    );
};
