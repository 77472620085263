/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, makeObservable, observable } from 'mobx';

import { Bar } from '../interfaces/bar';

export class NotificationBarStore {
    @observable bars: Bar[] = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    hide(barToRemove: Bar) {
        this.bars = this.bars.filter(bar => bar.id !== barToRemove.id);
    }

    @action.bound
    hideAll() {
        this.bars = [];
    }

    @action.bound
    hideAllAndFireCancelActions() {
        this.bars.forEach(bar => bar.cancelAction?.());

        this.hideAll();
    }

    @action.bound
    show(barToShow: Bar) {
        const existedBar = this.bars.find(bar => bar.id === barToShow.id);

        if (existedBar) {
            existedBar.text = barToShow.text;
            existedBar.icon = barToShow.icon;
            existedBar.onClose = barToShow.onClose;
        } else {
            this.bars.push(observable(barToShow));
        }
    }
}