/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BodyLayout } from '@veeam-vspc/shared/components';

import { AllInvoices } from './components';

export interface CompanyAllInvoicesPageProps {
    currentPath?: string;
}

export const CompanyAllInvoicesPage: React.FC<CompanyAllInvoicesPageProps> = ({ currentPath }) => (
    <BodyLayout>
        <AllInvoices stateId={currentPath} />
    </BodyLayout>
);
