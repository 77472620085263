/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { constants, Spinner, STACK_DIRECTION, StackView, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { DonutCircleStyled, EmptyPlate, SpinnerPlateStyled, UnlimitedPlate } from '../ChartWidget/components';

import type { HighchartsWrapperProps } from './interfaces';

const ChartWrapper = ({ lang, options, unlimited }) => {
    const { donut } = options;

    const extendedOptions: Highcharts.Options = {
        exporting: {
            enabled: false,
            ...(options.exporting || {}),
        },
        ...options,
    };

    return (
        <>
            {unlimited && (
                <UnlimitedPlate lang={lang} options={options} />
            )}

            {!unlimited && donut && (
                <DonutCircleStyled>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={extendedOptions}
                    />
                </DonutCircleStyled>
            )}

            {!unlimited && !donut && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={extendedOptions}
                />
            )}
        </>
    );
};

export const HighchartsWrapper = ({ empty, emptyDesc, loading, options, unlimited }: HighchartsWrapperProps) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            style={{ flex: 1 }}
        >
            {empty && (
                <EmptyPlate
                    emptyDesc={emptyDesc}
                    emptyText={lang.NO_DATA_AVAILABLE}
                />
            )}

            {loading && (
                <SpinnerPlateStyled>
                    <Spinner size={48} style={{ marginTop: `-${constants.SPACING_XXL}` }} />
                </SpinnerPlateStyled>
            )}

            {!empty && !loading && (
                <ChartWrapper lang={lang} options={options} unlimited={unlimited} />
            )}
        </StackView>
    );
};
