/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FilterCollation, SortDirection, transformRequestParams } from '@veeam-vspc/shared/components';

import type { RequestParams } from '@veeam-vspc/shared/components';
import type {
    Vb365BackupJob,
    Vb365BackupProxy,
    Vb365BackupRepository,
    Vb365CopyJob,
    Vb365Job,
    Vb365OrganizationBase,
} from '@veeam-vspc/models/rest';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';

import { VboExpandParams } from '../enums';

class VBObjectsService {
    transportService?: TransportService<RequestErrorResponse | Error> = {
        get() {
            throw new Error('TransportService is undefined');
        },
    } as unknown as TransportService<RequestErrorResponse | Error>;

    init(transportService: TransportService<RequestErrorResponse | Error>) {
        this.transportService = transportService;
    }

    getJobs(params: RequestParams<unknown>) {
        return this.transportService
            .get<{}, RequestSuccessResponse<Vb365Job[]>>(
                '/infrastructure/vb365Servers/organizations/jobs',
                transformRequestParams(params)
            );
    }

    loadOrganizations() {
        const minServerVersion = 7;

        return this.transportService.get<any, RequestSuccessResponse<Vb365OrganizationBase[]>>(
            '/infrastructure/vb365Servers/organizations',
            {
                expand: [VboExpandParams.Vb365Server],
                sort: JSON.stringify([{
                    property: 'name',
                    direction: SortDirection.Asc,
                    collation: FilterCollation.Ignorecase,
                }]),
                filter: JSON.stringify([{
                    property: '_embedded.vb365Server.majorVersion',
                    operation: 'greaterThanOrEqual',
                    value: minServerVersion,
                }]),
            }
        ).then((response: RequestSuccessResponse<Vb365OrganizationBase[]>) => response.data);
    }

    loadAvailableBackupRepositories(vb365ServerUid: string, vb365BackupJobUid: string) {
        return this.transportService.get<any, RequestSuccessResponse<Vb365BackupRepository[]>>(
            `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/jobs/backup/${vb365BackupJobUid}/availableBackupRepositories`,
            { sort: JSON.stringify([{ property: 'name', direction: SortDirection.Asc, collation: FilterCollation.Ignorecase }]) }
        )
            .then((response: RequestSuccessResponse<Vb365BackupRepository[]>) => response.data);
    }

    loadBackupRepositories(vb365ServerUid: string) {
        return this.transportService.get<any, RequestSuccessResponse<Vb365BackupRepository[]>>(
            `/infrastructure/vb365Servers/${vb365ServerUid}/backupRepositories`,
            { sort: JSON.stringify([{ property: 'name', direction: SortDirection.Asc, collation: FilterCollation.Ignorecase }]) }
        )
            .then((response: RequestSuccessResponse<Vb365BackupRepository[]>) => response.data);
    }

    loadProxies(vb365ServerUid: string) {
        return this.transportService
            .get<void, RequestSuccessResponse<Vb365BackupProxy[]>>(`/infrastructure/vb365Servers/${vb365ServerUid}/backupProxies`)
            .then((response: RequestSuccessResponse<Vb365BackupProxy[]>) => response.data);
    }

    loadBackupJobData(job: Vb365Job) {
        return this.transportService
            .get<void, RequestSuccessResponse<Vb365BackupJob>>(
                `/infrastructure/vb365Servers/${job.vb365ServerUid}/organizations/jobs/backup/${job.instanceUid}`
            )
            .then((response: RequestSuccessResponse<Vb365BackupJob>) => response.data);
    }

    loadCopyJobData(job: Vb365Job) {
        return this.transportService
            .get<void, RequestSuccessResponse<Vb365CopyJob>>(
                `/infrastructure/vb365Servers/${job.vb365ServerUid}/organizations/jobs/copy/${job.instanceUid}`
            )
            .then((response: RequestSuccessResponse<Vb365CopyJob>) => response.data);
    }
}

export const vbObjectsService = new VBObjectsService();
