/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NestedTabBarLayout, TabBarLayout } from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { AppRouteItem } from '@veeam-vspc/shared/stores';

import { RouteGroups, RouteIds } from '../../enums';
import gettingStartedIcon from 'images/icons/left-menu/getting-started.svg';
import { GettingStartedPage } from 'views/pages/GettingStartedPage';
import eventLogIcon from 'images/icons/left-menu/access-log.svg';
import { EventLogActivityLogPage, EventLogTasksPage } from 'views/pages/EventLogPage';
import securityIcon from 'images/icons/left-menu/security.svg';
import { SecurityApiKeysPage, SecurityCertificatePage, SecurityMfaPage, SecuritySsoPage } from 'views/pages/SecurityPage';
import cloudConnectServersIcon from 'images/icons/left-menu/cloud-connect-servers.svg';
import usersAndRolesIcon from 'images/icons/left-menu/users.svg';
import {
    ClientRolesUsersPortalRulesPage,
    ClientRolesUsersPortalUsersPage,
    ResellerRolesUsersClientRulesPage,
    ResellerRolesUsersClientUsersPage,
    ResellerRolesUsersPortalRulesPage,
    ResellerRolesUsersPortalUsersPage,
    RolesUsersClientRulesPage,
    RolesUsersClientUsersPage,
    RolesUsersPortalRulesPage,
    RolesUsersPortalUsersPage,
} from 'views/pages/RolesAndUsersPage';
import notificationsIcon from 'images/icons/left-menu/notifications.svg';
import { NotificationsAlarmsManagementPage, NotificationsConfigurationPage } from 'views/pages/NotificationsPage';
import backupPoliciesIcon from 'images/icons/left-menu/backup-policies.svg';
import { BackupPoliciesPage } from 'views/pages/BackupPoliciesPage';
import companyInfoIcon from 'images/icons/left-menu/company-info.svg';
import { CompanyInfoPortalBrandingPage, CompanyInfoProfilePage } from 'views/pages/CompanyInfoPage';
import subscriptionPlansIcon from 'images/icons/left-menu/subscription-plans.svg';
import { SubscriptionPlansPage } from 'views/pages/SubscriptionPlansPage';
import settingsIcon from 'images/icons/left-menu/settings.svg';
import { SettingsDbSettingsPage, SettingsMaintenanceModePage, SettingsRetentionPolicyPage } from 'views/pages/SettingsPage';
import licensingIcon from 'images/icons/left-menu/licensing.svg';
import {
    LicensingAgentsPage,
    LicensingBackupAndReplicationPage,
    LicensingCloudConnectPage,
    LicensingOverviewPage,
    LicensingUsageReportsPage,
    LicensingVeeamBackupForMicrosoftPage,
    LicensingVeeamOnePage,
} from 'views/pages/LicensingPage';
import aboutIcon from 'images/icons/left-menu/about.svg';
import { ProductUpdatesPage } from 'views/pages/ProductUpdatesPage';
import pluginLibraryIcon from 'images/icons/left-menu/plugin-library.svg';
import { PluginLibraryPage } from 'views/pages/PluginLibraryPage';
import locationsIcon from 'images/icons/left-menu/locations.svg';
import { LocationsPage } from 'views/pages/LocationsPage';
import alarmsManagementIcon from 'images/icons/left-menu/alarms-management.svg';
import supportIcon from 'images/icons/left-menu/support.svg';
import { AlarmsManagementPage } from 'views/pages/AlarmsManagementPage';
import { LicenseKeysPage } from 'views/pages/LicenseKeysPage';
import { CompaniesPageRoute } from './all-home-routes';
import { CloudConnectServersPage } from 'views/pages/CloudConnectServersPage';
import { AppTabBarLayoutVisibleSingleTab } from 'views/components/layouts/AppTabBarLayout';
import { SupportPage } from 'views/pages/SupportPage/SupportPage';

import type { VspcLang } from '../../languages';

export const GettingStartedPageRoute: AppRouteItem = {
    path: 'welcome',
    exact: true,
    component: GettingStartedPage,
    navIconSrc: gettingStartedIcon,
    navNameKey: 'GETTING_STARTED' as VspcLang['GETTING_STARTED'],
};

const EventLogTasksPageRoute: AppRouteItem = {
    path: 'tasks',
    exact: true,
    component: EventLogTasksPage,
    navNameKey: 'TASKS' as VspcLang['TASKS'],
};

const EventLogActivityLogPageRoute: AppRouteItem = {
    path: 'activityLog',
    exact: true,
    component: EventLogActivityLogPage,
    navNameKey: 'ACTIVITY_LOG' as VspcLang['ACTIVITY_LOG'],
};

export const EventLogPageRoute: AppRouteItem = {
    path: 'eventLog',
    component: TabBarLayout,
    navIconSrc: eventLogIcon,
    navNameKey: 'EVENT_LOG' as VspcLang['EVENT_LOG'],
    navGroupId: RouteGroups.Audit,
    navGroupTitleKey: 'AUDIT' as VspcLang['AUDIT'],
    items: [
        EventLogTasksPageRoute,
        EventLogActivityLogPageRoute,
    ],
};

const SecurityMfaPageRoute: AppRouteItem = {
    path: 'mfa',
    exact: true,
    component: SecurityMfaPage,
    navNameKey: 'MULTI_FACTOR_AUTHENTICATION' as VspcLang['MULTI_FACTOR_AUTHENTICATION'],
    isRouteHidden({ portalUser }) {
        return [
            portalUser?.isSiteAdministrator(),
            portalUser?.isCompanyOwner(),
            portalUser?.isCompanyAdministrator(),
        ].includes(true);
    },
};

const SecurityCertificatePageRoute: AppRouteItem = {
    path: 'certificate',
    exact: true,
    component: SecurityCertificatePage,
    navNameKey: 'SECURITY_CERTIFICATES' as VspcLang['SECURITY_CERTIFICATES'],
    isRouteHidden({ portalUser }) {
        return [
            portalUser?.isServiceProviderGlobalAdministrator(),
            portalUser?.isServiceProviderAdministrator(),
            portalUser?.isCompanyOwner(),
            portalUser?.isCompanyAdministrator(),
        ].includes(true);
    },
};

const SecurityApiKeysPageRoute: AppRouteItem = {
    path: 'keys',
    exact: true,
    component: SecurityApiKeysPage,
    navNameKey: 'API_KEYS' as VspcLang['API_KEYS'],
    isRouteHidden({ portalUser }) {
        return portalUser?.isSiteAdministrator();
    },
};

const SecuritySsoPageRoute: AppRouteItem = {
    path: 'sso',
    exact: true,
    component: SecuritySsoPage,
    navNameKey: 'SINGLE_SIGN_ON' as VspcLang['SINGLE_SIGN_ON'],
    isRouteHidden({ portalUser }) {
        return portalUser?.isSiteAdministrator();
    },
};

export const SecurityPageRoute: AppRouteItem = {
    path: 'security',
    component: TabBarLayout,
    navIconSrc: securityIcon,
    navNameKey: 'SECURITY' as VspcLang['SECURITY'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    items: [
        SecurityMfaPageRoute,
        SecurityCertificatePageRoute,
        SecurityApiKeysPageRoute,
        SecuritySsoPageRoute,
    ],
};

export const CloudConnectServersPageRoute: AppRouteItem = {
    path: 'cloudConnectServers',
    component: CloudConnectServersPage,
    navIconSrc: cloudConnectServersIcon,
    navNameKey: 'CLOUD_CONNECT_SERVERS' as VspcLang['CLOUD_CONNECT_SERVERS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
};

const RolesUsersPortalUsersPageRoute: AppRouteItem = {
    path: 'portalUsers',
    exact: true,
    component: RolesUsersPortalUsersPage,
    navNameKey: 'WINDOWS_USERS' as VspcLang['WINDOWS_USERS'],
};

const RolesUsersPortalRulesPageRoute: AppRouteItem = {
    path: 'portalRules',
    exact: true,
    component: RolesUsersPortalRulesPage,
    navNameKey: 'SSO_RULES' as VspcLang['SSO_RULES'],
};

const RolesUsersMyPortalPageRoute: AppRouteItem = {
    path: 'myPortal',
    component: NestedTabBarLayout,
    navNameKey: 'MY_COMPANY' as VspcLang['MY_COMPANY'],
    items: [
        RolesUsersPortalUsersPageRoute,
        RolesUsersPortalRulesPageRoute,
    ],
    isRouteHidden({ portalUser }) {
        return [
            portalUser?.isSiteAdministrator(),
            portalUser?.isPortalOperator(),
            portalUser?.isPortalReadonlyOperator(),
        ].includes(true);
    },
};

const RolesUsersClientUsersPageRoute: AppRouteItem = {
    path: 'clientUsers',
    exact: true,
    component: RolesUsersClientUsersPage,
    navNameKey: 'LOCAL_USERS' as VspcLang['LOCAL_USERS'],
};

const RolesUsersClientRulesPageRoute: AppRouteItem = {
    path: 'clientRules',
    exact: true,
    component: RolesUsersClientRulesPage,
    navNameKey: 'SSO_RULES' as VspcLang['SSO_RULES'],
    isRouteHidden({ portalUser }) {
        return [
            portalUser?.isSiteAdministrator(),
            portalUser?.isPortalOperator(),
            portalUser?.isPortalReadonlyOperator(),
        ].includes(true);
    },
};

const RolesUsersManagedCompaniesPageRoute: AppRouteItem = {
    path: 'clients',
    component: NestedTabBarLayout,
    navNameKey: 'MANAGED_COMPANIES' as VspcLang['MANAGED_COMPANIES'],
    items: [
        RolesUsersClientUsersPageRoute,
        RolesUsersClientRulesPageRoute,
    ],
};

export const RolesUsersPageRoute: AppRouteItem = {
    path: 'rolesUsers',
    component: TabBarLayout,
    navIconSrc: usersAndRolesIcon,
    navNameKey: 'ROLES_AND_USERS' as VspcLang['ROLES_AND_USERS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    items: [
        RolesUsersMyPortalPageRoute,
        RolesUsersManagedCompaniesPageRoute,
    ],
};

const ResellerRolesUsersPortalUsersPageRoute: AppRouteItem = {
    path: 'portalUsers',
    exact: true,
    component: ResellerRolesUsersPortalUsersPage,
    navNameKey: 'LOCAL_USERS' as VspcLang['LOCAL_USERS'],
};

const ResellerRolesUsersPortalRulesPageRoute: AppRouteItem = {
    path: 'portalRules',
    exact: true,
    component: ResellerRolesUsersPortalRulesPage,
    navNameKey: 'SSO_RULES' as VspcLang['SSO_RULES'],
};

const ResellerRolesUsersMyPortalPageRoute: AppRouteItem = {
    path: 'myPortal',
    component: NestedTabBarLayout,
    navNameKey: 'MY_COMPANY' as VspcLang['MY_COMPANY'],
    items: [
        ResellerRolesUsersPortalUsersPageRoute,
        ResellerRolesUsersPortalRulesPageRoute,
    ],
    isRouteHidden({ portalUser }) {
        return portalUser?.isServiceProviderUser() || portalUser?.isServiceProviderOperator();
    },
};

const ResellerRolesUsersClientUsersPageRoute: AppRouteItem = {
    path: 'clientUsers',
    exact: true,
    component: ResellerRolesUsersClientUsersPage,
    navNameKey: 'LOCAL_USERS' as VspcLang['LOCAL_USERS'],
};

const ResellerRolesUsersClientRulesPageRoute: AppRouteItem = {
    path: 'clientRules',
    exact: true,
    component: ResellerRolesUsersClientRulesPage,
    navNameKey: 'SSO_RULES' as VspcLang['SSO_RULES'],
    isRouteHidden({ portalUser }) {
        return portalUser?.isServiceProviderUser() || portalUser?.isServiceProviderOperator();
    },
};

const ResellerRolesUsersManagedCompaniesPageRoute: AppRouteItem = {
    path: 'clients',
    component: NestedTabBarLayout,
    navNameKey: 'MANAGED_COMPANIES' as VspcLang['MANAGED_COMPANIES'],
    items: [
        ResellerRolesUsersClientUsersPageRoute,
        ResellerRolesUsersClientRulesPageRoute,
    ],
};

export const ResellerRolesUsersPageRoute: AppRouteItem = {
    path: 'rolesUsers',
    component: TabBarLayout,
    navIconSrc: usersAndRolesIcon,
    navNameKey: 'ROLES_AND_USERS' as VspcLang['ROLES_AND_USERS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    items: [
        ResellerRolesUsersMyPortalPageRoute,
        ResellerRolesUsersManagedCompaniesPageRoute,
    ],
};

const ClientRolesUsersPortalUsersPageRoute: AppRouteItem = {
    path: 'users',
    exact: true,
    component: ClientRolesUsersPortalUsersPage,
    navNameKey: 'LOCAL_USERS' as VspcLang['LOCAL_USERS'],
};

const ClientRolesUsersPortalRulesPageRoute: AppRouteItem = {
    path: 'rules',
    exact: true,
    component: ClientRolesUsersPortalRulesPage,
    navNameKey: 'SSO_RULES' as VspcLang['SSO_RULES'],
    isRouteHidden(routeHiddenContext) {
        return routeHiddenContext.portalUser?.isLocationAdministrator();
    },
};

export const ClientRolesUsersPageRoute: AppRouteItem = {
    path: 'rolesUsers',
    component: TabBarLayout,
    navIconSrc: usersAndRolesIcon,
    navNameKey: 'ROLES_AND_USERS' as VspcLang['ROLES_AND_USERS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    items: [
        ClientRolesUsersPortalUsersPageRoute,
        ClientRolesUsersPortalRulesPageRoute,
    ],
};

const NotificationsConfigurationPageRoute: AppRouteItem = {
    path: 'configuration',
    exact: true,
    component: NotificationsConfigurationPage,
    navNameKey: 'CONFIGURATION' as VspcLang['CONFIGURATION'],
};

const NotificationsAlarmsManagementPageRoute: AppRouteItem = {
    path: 'alarmsManagement',
    exact: true,
    component: NotificationsAlarmsManagementPage,
    navNameKey: 'ALARMS_MANAGEMENT' as VspcLang['ALARMS_MANAGEMENT'],
};

export const NotificationsPageRoute: AppRouteItem = {
    path: 'notifications',
    component: TabBarLayout,
    navIconSrc: notificationsIcon,
    navNameKey: 'NOTIFICATIONS' as VspcLang['NOTIFICATIONS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    items: [
        NotificationsConfigurationPageRoute,
        NotificationsAlarmsManagementPageRoute,
    ],
};

export const BackupPoliciesPageRoute: AppRouteItem = {
    path: 'backupPolicies',
    component: BackupPoliciesPage,
    navIconSrc: backupPoliciesIcon,
    navNameKey: 'BACKUP_POLICIES' as VspcLang['BACKUP_POLICIES'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
};

export const PluginLibraryPageRoute: AppRouteItem = {
    path: 'plugins',
    component: PluginLibraryPage,
    navIconSrc: pluginLibraryIcon,
    navNameKey: 'PLUGIN_LIBRARY' as VspcLang['PLUGIN_LIBRARY'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
};

export const CompanyInfoProfilePageRoute: AppRouteItem = {
    path: 'profile',
    exact: true,
    component: CompanyInfoProfilePage,
    navNameKey: 'PROFILE' as VspcLang['PROFILE'],
};

const CompanyInfoPortalBrandingPageRoute: AppRouteItem = {
    path: 'branding',
    exact: true,
    component: CompanyInfoPortalBrandingPage,
    navNameKey: 'PORTAL_BRANDING' as VspcLang['PORTAL_BRANDING'],
};

export const CompanyInfoPageRoute: AppRouteItem = {
    path: 'companyInfo',
    component: AppTabBarLayoutVisibleSingleTab,
    navIconSrc: companyInfoIcon,
    navNameKey: 'COMPANY_INFO' as VspcLang['COMPANY_INFO'],
    navGroupId: RouteGroups.MyCompany,
    navGroupTitleKey: 'MY_COMPANY' as VspcLang['MY_COMPANY'],
    items: [
        CompanyInfoProfilePageRoute,
        CompanyInfoPortalBrandingPageRoute,
    ],
};

export const SubscriptionPlansPageRoute: AppRouteItem = {
    path: 'plans',
    component: SubscriptionPlansPage,
    navIconSrc: subscriptionPlansIcon,
    navNameKey: 'SUBSCRIPTION_PLANS' as VspcLang['SUBSCRIPTION_PLANS'],
    navGroupId: RouteGroups.MyCompany,
    navGroupTitleKey: 'MY_COMPANY' as VspcLang['MY_COMPANY'],
};

const SettingsRetentionPolicyPageRoute: AppRouteItem = {
    path: 'retention',
    exact: true,
    component: SettingsRetentionPolicyPage,
    navNameKey: 'RETENTION_POLICY' as VspcLang['RETENTION_POLICY'],
    isRouteHidden(routeHiddenContext) {
        return routeHiddenContext.portalUser?.isPassedRoleContained([UserRoleRepresentation.Unknown]);
    },
};

const SettingsDbSettingsPageRoute: AppRouteItem = {
    path: 'dbSettings',
    exact: true,
    component: SettingsDbSettingsPage,
    navNameKey: 'SQL_SERVER_CONNECTION' as VspcLang['SQL_SERVER_CONNECTION'],
};

const SettingsMaintenanceModePageRoute: AppRouteItem = {
    path: 'maintenanceMode',
    exact: true,
    component: SettingsMaintenanceModePage,
    navNameKey: 'MAINTENANCE_MODE' as VspcLang['MAINTENANCE_MODE'],
};

export const SettingsPageRoute: AppRouteItem = {
    path: 'settings',
    component: TabBarLayout,
    navIconSrc: settingsIcon,
    navNameKey: 'SETTINGS' as VspcLang['SETTINGS'],
    navGroupId: RouteGroups.ServerSettings,
    navGroupTitleKey: 'SERVER_SETTINGS' as VspcLang['SERVER_SETTINGS'],
    items: [
        SettingsRetentionPolicyPageRoute,
        SettingsDbSettingsPageRoute,
        SettingsMaintenanceModePageRoute,
    ],
};

const LicensingOverviewPageRoute: AppRouteItem = {
    path: 'overview',
    exact: true,
    component: LicensingOverviewPage,
    navNameKey: 'OVERVIEW' as VspcLang['OVERVIEW'],
    isRouteHidden(routeHiddenContext) {
        return routeHiddenContext.portalUser?.isSiteAdministrator();
    },
};

const LicensingUsageReportsPageRoute: AppRouteItem = {
    path: 'licenseReport',
    exact: true,
    component: LicensingUsageReportsPage,
    navNameKey: 'USAGE_REPORTS' as VspcLang['USAGE_REPORTS'],
    isRouteHidden(routeHiddenContext) {
        return routeHiddenContext.portalUser?.isSiteAdministrator();
    },
};

const LicensingCloudConnectPageRoute: AppRouteItem = {
    path: 'cloudConnect',
    exact: true,
    component: LicensingCloudConnectPage,
    navNameKey: 'VEEAM_CLOUD_CONNECT' as VspcLang['VEEAM_CLOUD_CONNECT'],
    isRouteHidden(routeHiddenContext) {
        return [
            routeHiddenContext.portalUser?.isResellerPortal(),
            routeHiddenContext.portalUser?.isCompanyAdministrator(),
        ].includes(true);
    },
};

const LicensingBackupAndReplicationPageRoute: AppRouteItem = {
    path: 'servers',
    exact: true,
    component: LicensingBackupAndReplicationPage,
    navNameKey: 'VEEAM_BACKUP_AND_REPLICATION' as VspcLang['VEEAM_BACKUP_AND_REPLICATION'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisibleWithoutPortalFilter('infrastructure', 'vbr');
    },
};

const LicensingAgentsPageRoute: AppRouteItem = {
    path: 'agents',
    exact: true,
    component: LicensingAgentsPage,
    navNameKey: 'VEEAM_AGENT' as VspcLang['VEEAM_AGENT'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisibleWithoutPortalFilter('infrastructure', 'agents', 'console');
    },
};

const LicensingVeeamOnePageRoute: AppRouteItem = {
    path: 'veeamOne',
    exact: true,
    component: LicensingVeeamOnePage,
    navNameKey: 'VEEAM_ONE' as VspcLang['VEEAM_ONE'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisibleWithoutPortalFilter('infrastructure', 'vOne');
    },
};

const LicensingVeeamBackupForMicrosoftPageRoute: AppRouteItem = {
    path: 'vbo',
    exact: true,
    component: LicensingVeeamBackupForMicrosoftPage,
    navNameKey: 'VEEAM_BACKUP_FOR_MICROSOFT' as VspcLang['VEEAM_BACKUP_FOR_MICROSOFT'],
    isRouteHidden(routeHiddenContext) {
        return !routeHiddenContext.portalPreferences?.isViewVisibleWithoutPortalFilter('infrastructure', 'vbo');
    },
};

export const LicensingPageRoute: AppRouteItem = {
    path: 'licensing',
    component: TabBarLayout,
    navIconSrc: licensingIcon,
    navNameKey: 'LICENSING' as VspcLang['LICENSING'],
    navGroupId: RouteGroups.ServerSettings,
    navGroupTitleKey: 'SERVER_SETTINGS' as VspcLang['SERVER_SETTINGS'],
    items: [
        LicensingOverviewPageRoute,
        LicensingUsageReportsPageRoute,
        LicensingCloudConnectPageRoute,
        LicensingBackupAndReplicationPageRoute,
        LicensingAgentsPageRoute,
        LicensingVeeamOnePageRoute,
        LicensingVeeamBackupForMicrosoftPageRoute,
    ],
};

export const LicensingPageRouteWithRenamedTabs: AppRouteItem = {
    path: 'licensing',
    component: TabBarLayout,
    navIconSrc: licensingIcon,
    navNameKey: 'QUOTA_AND_LICENSE_USAGE' as VspcLang['QUOTA_AND_LICENSE_USAGE'],
    navGroupId: RouteGroups.MyCompany,
    navGroupTitleKey: 'MY_COMPANY' as VspcLang['MY_COMPANY'],
    items: [
        {
            ...LicensingOverviewPageRoute,
            navNameKey: 'LICENSE_QUOTA_USAGE' as VspcLang['LICENSE_QUOTA_USAGE'],
        },
        LicensingUsageReportsPageRoute,
        LicensingCloudConnectPageRoute,
        LicensingBackupAndReplicationPageRoute,
        LicensingAgentsPageRoute,
        LicensingVeeamOnePageRoute,
        LicensingVeeamBackupForMicrosoftPageRoute,
    ],
};

export const ProductUpdatesPageRoute: AppRouteItem = {
    path: 'productUpdates',
    component: ProductUpdatesPage,
    navIconSrc: aboutIcon,
    navNameKey: 'PRODUCT_UPDATES' as VspcLang['PRODUCT_UPDATES'],
    navGroupId: RouteGroups.ServerSettings,
    navGroupTitleKey: 'SERVER_SETTINGS' as VspcLang['SERVER_SETTINGS'],
};

export const SupportPageRoute: AppRouteItem = {
    path: 'support',
    component: SupportPage,
    navIconSrc: supportIcon,
    navNameKey: 'TECHNICAL_SUPPORT' as VspcLang['TECHNICAL_SUPPORT'],
    navGroupId: RouteGroups.ServerSettings,
    navGroupTitleKey: 'SERVER_SETTINGS' as VspcLang['SERVER_SETTINGS'],
};

export const LocationsPageRoute: AppRouteItem = {
    path: 'locations',
    component: LocationsPage,
    navIconSrc: locationsIcon,
    navNameKey: 'LOCATIONS' as VspcLang['LOCATIONS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
};

export const AlarmsManagementPageRoute: AppRouteItem = {
    path: 'alarmsManagement',
    component: AlarmsManagementPage,
    navIconSrc: alarmsManagementIcon,
    navNameKey: 'ALARMS_MANAGEMENT' as VspcLang['ALARMS_MANAGEMENT'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
};

export const LicenseKeysPageRoute: AppRouteItem = {
    path: 'licenseKeys',
    component: LicenseKeysPage,
    navIconSrc: licensingIcon,
    navNameKey: 'LICENSE_KEYS' as VspcLang['LICENSE_KEYS'],
    navGroupId: RouteGroups.PortalCFG,
    navGroupTitleKey: 'PORTAL_CONFIGURATION' as VspcLang['PORTAL_CONFIGURATION'],
    isRouteHidden(routeHiddenContext) {
        if (routeHiddenContext.portalUser?.isAuditor()) {
            return false;
        }

        if (routeHiddenContext.portalUser?.isServiceProviderUser()) {
            return true;
        }

        return !routeHiddenContext.portalPreferences?.isViewVisibleWithoutPortalFilter('infrastructure', 'pulse', 'licenseKeys');
    },
};

export const allConfigsRoutes: { [key in RouteIds]?: AppRouteItem; } = {
    // Configs

    [RouteIds.Welcome]: GettingStartedPageRoute,
    // Audit
    [RouteIds.EventLog]: EventLogPageRoute,
    // PortalCFG
    [RouteIds.Security]: SecurityPageRoute,
    [RouteIds.CloudConnectServers]: CloudConnectServersPageRoute,
    [RouteIds.RolesUsers]: RolesUsersPageRoute,
    [RouteIds.RolesUsersReseller]: ResellerRolesUsersPageRoute,
    [RouteIds.RolesUsersClient]: ClientRolesUsersPageRoute,
    [RouteIds.Notifications]: NotificationsPageRoute,
    [RouteIds.BackupPolicies]: BackupPoliciesPageRoute,
    [RouteIds.Plugins]: PluginLibraryPageRoute,

    [RouteIds.Locations]: LocationsPageRoute,
    [RouteIds.Alarms]: AlarmsManagementPageRoute,
    [RouteIds.LicenseKeys]: LicenseKeysPageRoute,
    // My Company
    [RouteIds.CompanyInfo]: CompanyInfoPageRoute,
    [RouteIds.SubscriptionPlans]: SubscriptionPlansPageRoute,
    [RouteIds.LicensingWithRenamedTabs]: LicensingPageRouteWithRenamedTabs,
    // Server settings
    [RouteIds.Settings]: SettingsPageRoute,
    [RouteIds.Licensing]: LicensingPageRoute,
    [RouteIds.Support]: SupportPageRoute,
    [RouteIds.ProductUpdates]: ProductUpdatesPageRoute,

    // Clients
    [RouteIds.ConfigsCompanies]: CompaniesPageRoute,
};
