/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { StackView } from '@veeam-vspc/shared/components';

import { InvoiceStatus } from './components/InvoiceStatus';
import { MonthlyRevenueState } from './components/MonthlyRevenueState';
import { RevenueByServiceType } from './components/RevenueByServiceType';

export const Invoices = () => (
    <StackView style={{ height: '100%', overflow: 'hidden' }}>
        <div style={{ overflow: 'auto' }}>
            <MonthlyRevenueState />
            <InvoiceStatus />
            <RevenueByServiceType />
        </div>
    </StackView>
);
