/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ColorSchemes {
    Blue = 'Blue',
    Green = 'Green',
    Red = 'Red',
    Turquoise = 'Turquoise',
    Yellow = 'Yellow',
    Teal = 'Teal',
}
