/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { constants, Icon, ICON_SIZES, STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/shared/components';
import { Link } from 'react-router-dom';

import { EmptyPlateStyled } from './EmptyPlate.styled';
import { PlateDescriptionStyled, PlateTextStyled } from '../PlateText';
import infoSrc from 'images/info-light-gray.svg';

import type { EmptyDescWithLink } from '../../../HighchartsWrapper/interfaces';

const EmptyDesc = ({ data }) => {
    const { href, linkText, text } = data;
    const textArr = (text || data).split(linkText);

    return (
        <PlateDescriptionStyled>
            {textArr && textArr[0]}

            {
                href
                    ? (<Link to={href}>{linkText}</Link>)
                    : linkText
            }

            {textArr && textArr[1]}
        </PlateDescriptionStyled>
    );
};

export const EmptyPlate = ({ emptyText, emptyDesc }: { emptyText: string; emptyDesc?: string | EmptyDescWithLink; }) => (
    <EmptyPlateStyled>
        <StackView
            direction={STACK_DIRECTION.column}
            gap={STACK_GAP.m}
            style={{
                height: '100%',
                padding: `0 ${constants.SPACING_XL}`,
                textAlign: 'center',
                width: '100%',
            }}
        >
            <PlateTextStyled withoutDesc={!emptyDesc}>
                {!emptyDesc && <Icon size={ICON_SIZES.xl} src={infoSrc} style={{ marginBottom: constants.SPACING_S, width: '100%' }} />}
                <div>{emptyText}</div>
            </PlateTextStyled>

            {emptyDesc && <EmptyDesc data={emptyDesc} />}
        </StackView>
    </EmptyPlateStyled>
);
