/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Combobox,
    FieldLayout,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    Text,
    CONTROL_SIZE,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { Vb365CopyJob } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { useCopyJobWizardStore } from '../../stores';
import { NoteBar } from 'components/layouts/NoteBar';
import { usedSpaceMessage } from '../../../../helpers';

export const stepValidate = (data: Vb365CopyJob): FormErrors<Vb365CopyJob> => {
    const validator = new FormValidator(data);

    validator
        .validate('repositoryUid')
        .string()
        .required();

    return validator.result();
};

export const getBackupRepositoryStep = (lang: VspcLang): WizardStep<Vb365CopyJob> => ({
    title: lang.TARGET_REPOSITORY,
    validate: ({ data }) => {
        const validationResult = isValid(stepValidate, data);

        return Boolean(validationResult);
    },
    render: data => <BackupRepository {...data} />,
});

const BackupRepository = observer(({ data, onDataChange, validationState }: WizardStepData<Vb365CopyJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useCopyJobWizardStore();

    return (
        <StepLayout
            title={lang.TARGET_REPOSITORY}
            description={lang.SPECIFY_A_REPOSITORY_FOR_THE_BACKUP_COPY_JOB}
        >
            <Form
                value={data}
                onChange={onDataChange}
                validate={stepValidate}
                validationState={validationState}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <FormLayout>
                        <FieldLayout
                            label={`${lang.BACKUP_REPOSITORY}:`}
                        >
                            <Combobox
                                name='repositoryUid'
                                data={wizardStore.backupRepositories}
                                valueGetter={item => item.instanceUid}
                                textGetter={item => item.name}
                                size={CONTROL_SIZE.m}
                            />
                        </FieldLayout>

                        <Text>{`${capitalize(lang.USED_SPACE, true)}: ${usedSpaceMessage(lang, wizardStore.selectedBackupRepository(data))}`}</Text>
                    </FormLayout>

                    <NoteBar>
                        <Text>{lang.YOU_CAN_SELECT_ONLY_A_REPOSITORY_THAT_IS_CONNECTED_TO_THE_SAME_BACKUP_PROXY}</Text>
                    </NoteBar>
                </StackView>
            </Form>
        </StepLayout>
    );
});
