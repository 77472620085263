/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { lazy, Suspense } from 'react';
import { useGlobalServices, PortalSpinner, useGlobalLang, useGlobalAppData } from '@veeam-vspc/shared/components';
import { formatStr } from '@veeam-vspc/shared/helpers';

import type { ComponentType } from 'react';
import type { VspcLang } from 'configs/languages';

export interface LazyLoadedComponentProps<T> {
    importPromise: () => Promise<T>;
    pageName: keyof T;
    pageProps?: Record<string, any>;
}

export const LazyLoadedComponent = <T,>({ importPromise, pageName, pageProps }: LazyLoadedComponentProps<T>) => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const { webHostName } = useGlobalAppData();
    const LazyComponent = lazy(() => (
        importPromise()
            .then(module => ({ default: module[pageName] as ComponentType<any> }))
            .catch((err) => {
                if (process.env.NODE_ENV === 'development') {
                    err && console.error(err);
                }

                notificationService.error(
                    lang.UNTRUSTED_SECURITY_CERTIFICATE,
                    formatStr(lang.FAILED_TO_LOAD_THE_PAGE, webHostName),
                    { canClose: false },
                )
                    .then(() => window.location.reload());

                return { default: () => <div /> };
            })
    ));

    return (
        <Suspense fallback={<PortalSpinner delayTime={1000} />}>
            <LazyComponent {...pageProps} />
        </Suspense>
    );
};
