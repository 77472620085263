/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

interface GridWrapperProps {
    height: number;

}
export const GridWrapper = styled.div<GridWrapperProps>`
  height: ${({ height }) => height}px;
  position: relative;
  overflow: hidden;
`;
