/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { STACK_DIRECTION, STACK_GAP, StackView, useGlobalLang } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { ChartWidget } from 'views/components/ChartWidget';
import { CurrentUsage } from './components/CurrentUsage';
import { ProductCombobox } from '../ProductCombobox';
import { ProductContext } from '../../contexts';
import { ProductStore } from '../../stores';
import { Separator } from 'views/components/ChartWidget/components/Separator';
import { UsageTrend } from './components';
import { useLocalStorageValue } from 'views/hooks';

const ManagedWorkloadsLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ChartWidget
            style={{ height: 460 }}
            title={{ text: lang.MANAGED_WORKLOADS }}
        >
            <ProductCombobox />

            <StackView
                direction={STACK_DIRECTION.row}
                gap={STACK_GAP.s}
                style={{ flex: 1 }}
            >
                <CurrentUsage />
                <Separator />
                <UsageTrend />
            </StackView>
        </ChartWidget>
    );
});

export const ManagedWorkloads: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ManagedWorkloads.Product`;
    const initialType = useLocalStorageValue<LicenseProductType>(stateId, LicenseProductType.VspcEndpoint);

    const filter = useMemo(() => new ProductStore(initialType, stateId), [initialType, stateId]);

    return (
        <ProductContext.Provider value={filter}>
            <ManagedWorkloadsLayout />
        </ProductContext.Provider>
    );
};
