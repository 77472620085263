/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { BackupServicesType, TimeUnit } from '@veeam-vspc/models/web-controllers';
import { useGlobalLang } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BackupTasksRatio } from './components/BackupTasksRatio';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { ConsumedPoints } from './components/ConsumedPoints';
import { FilterContext } from '../../contexts';
import { FilterStore } from '../../stores';
import { ManagedCompanies } from './components/ManagedCompanies';
import { TriggeredAlarms } from './components/TriggeredAlarms';
import { WidgetColumnsStyled, WidgetViewStyled, WidgetRowsStyled } from 'components/layouts/WidgetView';
import { useFilterStore } from '../../hooks';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

interface InfrastructureLayoutProps {
    periodStateId: string;
}

const InfrastructureLayout: React.FC<InfrastructureLayoutProps> = observer((props) => {
    const { periodStateId } = props;
    const filterStore = useFilterStore();
    const lang = useGlobalLang<VspcLang>();

    return (
        <WidgetViewStyled>
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                label={`${lang.REPORTING_PERIOD}:`}
                onChange={filterStore.setReportingPeriod}
                stateId={periodStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={filterStore.reportingPeriod}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: TimeUnit.Weeks, text: lang.WEEKLY },
                    { id: TimeUnit.Months, text: lang.MONTHLY },
                ]}
            />

            <WidgetColumnsStyled>
                <WidgetRowsStyled>
                    <TriggeredAlarms />
                    <ManagedCompanies />
                </WidgetRowsStyled>

                <WidgetRowsStyled>
                    <BackupTasksRatio />
                    <ConsumedPoints />
                </WidgetRowsStyled>
            </WidgetColumnsStyled>
        </WidgetViewStyled>
    );
});

export interface InfrastructureProps {
    stateId: string;
}

export const Infrastructure: React.FC<InfrastructureProps> = () => {
    const { pathname } = useLocation();
    const periodStateId = `${pathname}.ReportingPeriod`;
    const period = useLocalStorageValue<TimeUnit>(periodStateId, TimeUnit.Months);

    const filterStore = useMemo(() => new FilterStore(period, BackupServicesType.All), [period]);

    return (
        <FilterContext.Provider value={filterStore}>
            <InfrastructureLayout periodStateId={periodStateId} />
        </FilterContext.Provider>
    );
};
