/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum EditUserProfileStepIds {
    UserInfoStep = 'UserInfoStep',
    LoginInfoStep = 'LoginInfoStep',
    MfaStep = 'MfaStep',
    SummaryStep = 'SummaryStep',
}
