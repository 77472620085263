/**
 * Copyright © Veeam Software Group GmbH.
 */

export const closeExtDialogsOnLogout = () => {
    if (RCOP.OPENED_DIALOGS?.length > 0) {
        let dialog;
        let counter = 0; // add to prevent infinite loops because of ext.js dialogs can be not removed from dialogs array

        while ((dialog = RCOP.OPENED_DIALOGS[RCOP.OPENED_DIALOGS.length - 1]) && counter < 10) {
            if (typeof dialog.closeWithoutConfirm === 'function') {
                dialog.closeWithoutConfirm();
            } else if (typeof dialog.hide === 'function') {
                dialog.hide();
            } else if (typeof dialog.close === 'function') {
                dialog.close();
            }

            counter += 1;
        }
    }
};
