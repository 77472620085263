/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    transformRequestParams,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
    GridSortDirections,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { Vb365RestorePoint } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';

import type { RestorePointsDialogProps } from '../../interfaces';

export const RestorePointsDialog: React.FC<RestorePointsDialogProps> = ({
    stateId,
    name,
    vb365ProtectedObjectId,
    vb365ServerUid,
    vb365BackupRepositoryUid,
    deactivate,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const columns: GridColumnProps<Vb365RestorePoint>[] = [
        {
            id: 'backupTime',
            title: lang.DATE,
            cell: rowData => <GridDateColumn value={rowData.backupTime} />,
        },
        {
            id: 'processedObjectTypesStr',
            title: lang.PROCESSING_OPTIONS,
            cell: rowData => (
                rowData.processedObjectTypesStr
            ),
        },
        {
            id: 'repositoryName',
            title: lang.TARGET_REPOSITORY,
            cell: rowData => rowData.repositoryName,
        },
    ];

    return (
        <Dialog
            header={name}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <ConfiguredGrid
                stateId={stateId}
                defaultColumnMinWidth={100}
                columns={columns}
                data={
                    params => transportService.get<any, RequestSuccessResponse<Vb365RestorePoint[]>>(
                        `/protectedWorkloads/vb365ProtectedObjects/${vb365ProtectedObjectId}/restorePoints`,
                        {
                            ...transformRequestParams(params),
                            vb365ServerUid,
                            vb365BackupRepositoryUid,
                        }
                    )
                }
                initialState={{
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: columns?.[0]?.id,
                    },
                }}
                toggleable={false}
                selection={{
                    field: 'id',
                    checkbox: false,
                    multiple: false,
                }}
                paginationLimit={500}
                toolbars={[
                    () => (
                        <ToolbarWithExport
                            lang={lang}
                            idProperty='id'
                            RESTMethod={`protectedWorkloads/vb365ProtectedObjects/${vb365ProtectedObjectId}/restorePoints/`}
                            selectedOnly
                            extraParams={{ vb365ServerUid, vb365BackupRepositoryUid }}
                        />
                    ),
                ]}
            />
        </Dialog>
    );
};

