/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { StackView, useGlobalServices } from '@veeam-vspc/shared/components';
import { ConfigStates } from '@veeam-vspc/shared/core';

import { DataTransferOut } from './components/DataTransferOut';
import { StorageUsage } from './components/StorageUsage';
import { StoredWorkloads } from './components/StoredWorkloads';
import { UsedStorageQuota } from './components/UsedStorageQuota';
import { ConfigItemIds, ConfigSectionIds } from 'core/enums';

export const Backup = () => {
    const { configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.BillingSummaryCloudConnectResourcesCloudConnectBackup);

    const storageUsageWidgetIsHidden = sectionConfig
        ? sectionConfig.getItemState(ConfigItemIds.StorageUsageWidget) === ConfigStates.Hidden
        : false;

    const storedWorkloadsWidgetIsHidden = sectionConfig
        ? sectionConfig.getItemState(ConfigItemIds.StoredWorkloadsWidget) === ConfigStates.Hidden
        : false;

    const dataTransferWidgetIsHidden = sectionConfig
        ? sectionConfig.getItemState(ConfigItemIds.DataTransferWidget) === ConfigStates.Hidden
        : false;

    return (
        <StackView style={{ height: '100%', overflow: 'hidden' }}>
            <div style={{ overflow: 'auto' }}>
                <UsedStorageQuota />
                {!storageUsageWidgetIsHidden && <StorageUsage />}
                {!storedWorkloadsWidgetIsHidden && <StoredWorkloads />}
                {!dataTransferWidgetIsHidden && <DataTransferOut />}
            </div>
        </StackView>
    );
};
