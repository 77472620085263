/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';
import type { TrendItem } from '../interfaces';

interface TrendSeries {
    data: number[];
    name: string;
}

export const transformData = (
    data: TrendItem[],
): Promise<ChartDataWithCategories> => {
    const categories = [];
    const series: TrendSeries[] = [];
    let empty = true;

    const points = data.length;

    data.forEach((item, i) => {
        const d = new Date(item.date.slice(0, -6));

        categories.push(`${d.getDate()} ${d.toLocaleString('en-us', { month: 'short', timeZone: 'UTC' })}`);

        item.counters.forEach((c) => {
            if (c.identifier) {
                const { name } = c.identifier;
                const index = series.findIndex(s => s.name === name);

                if (index > -1) {
                    series[index].data[i] = c.count;
                } else {
                    const s: TrendSeries = { name: name, data: new Array(points).fill(null) };

                    s.data[i] = c.count;
                    series.push(s);
                }
            }

            if (c.count !== null) {
                empty = false;
            }
        });
    });

    return Promise.resolve({
        categories,
        empty,
        series,
    });
};
