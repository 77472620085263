/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { EmptyLayout } from '@veeam-vspc/shared/components';

import { ExtComponent } from '../../components/Ext/ExtComponent';

export interface AdjustReportsPageProps {
    currentPath: string;
}

export const AdjustReportsPage: React.FC<AdjustReportsPageProps> = ({ currentPath }) => (
    <EmptyLayout currentPath={currentPath}>
        <ExtComponent
            isCustomViewport
        />
    </EmptyLayout>
);
