/**
 * Copyright © Veeam Software Group GmbH.
 */

import type * as themes from '@veeam/components/lib/themes';

const getExtProductTheme = (portalThemeName: keyof typeof themes): keyof typeof themes => {
    switch (portalThemeName) {
        case ('teal'):
            return 'turquoise' as keyof typeof themes;
        default:
            return portalThemeName;
    }
};

const getReactProductTheme = (portalThemeName: keyof typeof themes): keyof typeof themes => {
    switch (portalThemeName) {
        case ('turquoise' as keyof typeof themes):
            return 'teal';
        default:
            return portalThemeName;
    }
};

export {
    getExtProductTheme,
    getReactProductTheme,
};
