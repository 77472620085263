/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Search,
    toolbarItemDecorators,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import {
    disableForAnyOfSelectionWithPropValueCallback,
    processDecoratorsForDisabledByPriority,
    disableForAllPageSelection,
} from '@veeam-vspc/shared/helpers';
import { Vb365JobItemComposedItemType, Vb365JobItemGroupGroupType } from '@veeam-vspc/models/rest';

import type { Vb365JobItemComposed } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import editIcon from 'images/actions/edit.svg';
import removeIcon from 'images/actions/remove.svg';
import { isItemEditable } from '../OptionsCell/OptionsCell';
import { useJobWizardStore } from '../../../../stores';

import type { BackupOptionsTarget } from '../../../../enums';

interface BackupOptionsToolbarProps {
    onEdit: (items: Vb365JobItemComposed[]) => void;
    onRemove: (items: Vb365JobItemComposed[]) => void;
    viewType: BackupOptionsTarget;
}

export const BackupOptionsToolbar: React.FC<BackupOptionsToolbarProps> = ({
    onEdit,
    onRemove,
    viewType,
}) => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<Vb365JobItemComposed, unknown, unknown>();
    const wizardStore = useJobWizardStore();

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.default}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            maxLength={100}
                            placeholder={lang.OBJECT}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: editIcon,
                    text: lang.EDIT_PROCESSING_OPTIONS,
                    onClick: () => {
                        const selected = gridApi.selected;
                        onEdit(selected);
                    },
                    decorators: [
                        processDecoratorsForDisabledByPriority([
                            toolbarItemDecorators.disallowWithoutSelection(),
                            disableForAllPageSelection(),
                            disableForAnyOfSelectionWithPropValueCallback('itemType', value => value !== gridApi.selected[0].itemType),
                            disableForAnyOfSelectionWithPropValueCallback<Vb365JobItemComposed>('group', (value, item) => {
                                const firstSelItem = gridApi.selected[0];
                                if (firstSelItem.itemType === Vb365JobItemComposedItemType.Group
                                    && item.itemType === Vb365JobItemComposedItemType.Group) {
                                    if (firstSelItem.group.groupType === Vb365JobItemGroupGroupType.Office365) {
                                        return item.group.groupType !== Vb365JobItemGroupGroupType.Office365;
                                    } else {
                                        return item.group.groupType === Vb365JobItemGroupGroupType.Office365;
                                    }
                                }
                                return false;
                            }),
                            disableForAnyOfSelectionWithPropValueCallback(
                                'itemType',
                                (value, item) => wizardStore.getProcessingOptions(item).length === 0
                            ),
                            disableForAnyOfSelectionWithPropValueCallback<Vb365JobItemComposed>(
                                'user',
                                (value, item) => !isItemEditable(item, viewType)
                            ),
                        ]),
                    ],
                },
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: removeIcon,
                    text: lang.REMOVE,
                    onClick: () => {
                        const { exclude, onlyThese } = gridApi.requestParamsForActions;
                        let itemsToDelete;

                        if (onlyThese.length) {
                            itemsToDelete = wizardStore[viewType].filter(({ id }) => (onlyThese as string[]).includes(id));
                        }

                        if (exclude.length) {
                            itemsToDelete = wizardStore[viewType].filter(({ id }) => !(exclude as string[]).includes(id));
                        }

                        if (onlyThese.length === 0 && exclude.length === 0) {
                            itemsToDelete = wizardStore[viewType];
                        }

                        onRemove(itemsToDelete);
                    },
                    decorators: [
                        processDecoratorsForDisabledByPriority([
                            toolbarItemDecorators.disallowWithoutSelection(),
                        ]),
                    ],
                },
            ]}
        />
    );
};
