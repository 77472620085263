/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ServerSettingsDialogFormStoreContext } from './server-settings-dialog-form-store-context';

import type { ServerSettingsDialogFormStore } from '../server-settings-dialog-form-store';

export const useServerSettingsDialogFormStore = (): ServerSettingsDialogFormStore => useContext(ServerSettingsDialogFormStoreContext);
