/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    LinkButton,
    StackView,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    Text,
    Tooltip,
    usePageWizardStore,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { NotificationService } from '@veeam-vspc/shared/services';
import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { Vb365BackupJob } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { useJobWizardStore } from '../../stores';
import { AvailableOrganizations } from './components/AvailableOrganizations/AvailableOrganizations';

import type { JobWizardStore } from '../../stores';

export const getOrganizationStep = (
    lang: VspcLang,
    store: JobWizardStore,
    notificationService: NotificationService,
): WizardStep<Vb365BackupJob> => ({
    title: lang.ORGANIZATION,
    validate: () => {
        const validationResult = Boolean(store.selectedOrganization);

        if (validationResult === false) {
            notificationService.warning(lang.ORGANIZATION, lang.SELECT_ORGANIZATION_TO_CONTINUE);
        }

        return validationResult;
    },
    render: data => <OrganizationsStep {...data} />,
});

const OrganizationsStep = observer((wizardStepData: WizardStepData<Vb365BackupJob>) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const wizardContext = usePageWizardStore();

    return (
        <StepLayout
            title={lang.ORGANIZATION}
            description={lang.SPECIFY_ORGANIZATION_TO_BACKUP}
        >
            <StackView
                direction={STACK_DIRECTION.row}
                align={STACK_ALIGN.center}
                gap={STACK_GAP.m}
            >
                <Text>{`${lang.ORGANIZATION}:`}</Text>

                <Tooltip<HTMLDivElement>
                    delayTimeout={500}
                    render={props => (
                        <LinkButton
                            onClick={() => wizardContext
                                .openPanel(hide => <AvailableOrganizations hidePanel={hide} />)}
                            error={!wizardStepData.validationState.isCommon() && !wizardStore.selectedOrganization}
                            disabled={wizardStepData.isEdit}
                            style={{ flexShrink: 1 }}
                        >
                            {wizardStore.selectedOrganization?.name || lang.SELECT}
                        </LinkButton>
                    )}
                    text={wizardStore.selectedOrganization?.name || lang.SELECT}
                />

            </StackView>
        </StepLayout>
    );
});
