/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { useGlobalAppData, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { PageWizard } from 'components/wizards/PageWizard';
import { saveUser } from 'views/pages/RolesAndUsersPage/components/Base/helpers/saveUser';
import { getLoginInfoStep } from '../Base/components/LoginInfo';
import { getUserInfoStep } from '../Base/components/UserInfo';
import { getMultiFactorAuthStep } from '../Base/components/MultiFactorAuthentication';
import { getLocationsStep } from './components/Locations';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';
import { getQuotaStep } from './components/Quota';
import { getCompanyStep } from './components/Company';
import { CompanyUserStore, CompanyWizardStoreContext } from './stores';

import type { CompanyUserModel, CompanyUserProps } from './interfaces';

export const CompanyUser = (props: CompanyUserProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser } = useGlobalAppData();
    const { transportService, notificationService } = useGlobalServices();
    const additionalWizardStore = useMemo(() => new CompanyUserStore(), []);

    const data: CompanyUserModel = {
        userRole: portalUser.isLocationAdministrator() ? UserRoleRepresentation.CompanyLocationUser : UserRoleRepresentation.CompanyAdministrator,
        repositories: props.repositories,
        isUnlimited: false,
        repositoryFriendlyName: '',
        enableQuota: false,
        mfaPolicyStatus: props.mfaPolicyStatus,
        isAllowedToSwitchMfaPolicyStatus: true,
        sendInvoice: false,
        tenantRepositoryId: undefined,
        userTitle: undefined,
        locations: [],
        repositoryQuota: 1,
        ...props.model,
        password: '',
        confirmPassword: '',
        oldPassword: props.selfEdit ? '' : undefined,
        selfEdit: props.selfEdit,
        isCompanyAdminPortal: props.isCompanyAdminPortal || undefined,
        companyFilterInitValue: props.companyFilterInitValue,
    };
    const title = props.isEdit ? lang.EDIT_USER : lang.NEW_USER;

    return (
        <CompanyWizardStoreContext.Provider value={additionalWizardStore}>
            <PageWizard
                title={title}
                deactivate={() => {}}
                isEdit={props.isEdit}
                data={data}
                steps={[
                    getCompanyStep(lang.COMPANY),
                    getRoleStep(lang.ROLE),
                    getUserInfoStep(lang, lang.USER_INFO),
                    getLoginInfoStep(lang, lang.LOGIN_INFO, transportService),
                    getLocationsStep(lang.LOCATIONS),
                    getQuotaStep(lang.QUOTA, additionalWizardStore, lang, notificationService),
                    getMultiFactorAuthStep(lang.MULTI_FACTOR_AUTHENTICATION),
                    getSummaryStep(lang.SUMMARY),
                ] as WizardStep<CompanyUserModel>[]}
                onFinish={async({ data }) => {
                    const enableQuota = data.userRole === UserRoleRepresentation.Subtenant;

                    const requestParams: CompanyUserModel = {
                        ...data,
                        enableQuota,
                        quota: enableQuota ? {
                            isUnlimited: data.isUnlimited,
                            repositoryFriendlyName: data.repositoryFriendlyName,
                            repositoryQuota: data.isUnlimited ? undefined : data.repositoryQuota,
                            tenantRepositoryId: data.tenantRepositoryId,
                        } : null,
                        userLocationIds: data.locations.map(location => location.id),
                    };

                    return saveUser(transportService, { props, requestParams });
                }}
            />
        </CompanyWizardStoreContext.Provider>
    );
};
