/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { TabBarBodyLayout, useGlobalLang } from '@veeam-vspc/shared/components';
import { useNavigationBlocker } from '@veeam-vspc/shared/hooks';

import type { VspcLang } from 'configs/languages';

import { ExtComponent } from '../../components/Ext/ExtComponent';

export const SettingsRetentionPolicyPage: React.FC<{}> = () => {
    const xtype = 'rcop.configuration.maintenanceMode.Retention';
    const lang = useGlobalLang<VspcLang>();
    const [isDirty, setIsDirty] = useState(false);

    useNavigationBlocker({
        isDirty,
        prompt: {
            title: lang.RETENTION_POLICY,
            message: lang.SETTINGS_HAVE_NOT_BEEN_SAVED,
        },
    });

    return (
        <TabBarBodyLayout>
            <ExtComponent
                xtype={xtype}
                extProps={{
                    setIsDirty: (isDirty: boolean) => setIsDirty(isDirty),
                }}
            />
        </TabBarBodyLayout>
    );
};
