/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ValueTypes {
    Unknown,
    String,
    Object,
    Array,
    Null,
}
