/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ReportRmmProviderModes {
    Unknown = -1,
    UnManaged = 0,
    ManagedByVBR = 1,
    ManagedByVAC = 100,
}
