/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    DurationColumn,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { BackupServerFileJobSourceModel, FileJobSourceParam } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { FileServersDialogToolbar } from '../FileServersDialogToolbar/FileServersDialogToolbar';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { FileServerStatusColumn } from '../FileServerStatusColumn/FileServerStatusColumn';
import { MAX_INTEGER_32 } from 'core/const';


export interface FileServersDialogProps {
    deactivate: () => void;
    title: string;
    jobUid: string;
    stateId: string;
}

export const FileServersDialog: React.FC<FileServersDialogProps> = ({ deactivate, title, jobUid, stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const columns: GridColumnProps<BackupServerFileJobSourceModel>[] = [
        {
            id: 'statusName',
            title: lang.STATUS,
            cell: rowData => (
                <FileServerStatusColumn
                    status={rowData.status}
                    statusName={rowData.statusName}
                    messages={rowData.messages}
                />
            ),
        },
        {
            id: 'name',
            title: lang.SERVER,
            cell: rowData => rowData.name,
        },
        {
            id: 'sourceItemsCount',
            title: lang.FILES_AND_FOLDERS,
            cell: rowData => (
                <SourceItemsColumn
                    value={rowData.sourceItemsCount}
                    stateId={`${stateId}.Dialogs.SourceItems`}
                    name={rowData.name}
                    sourceItems={rowData.sourceItems}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'sourceFiles',
            title: lang.N_OF_FILES,
            cell: rowData => (
                <TextColumn defaultValue={lang.N_A}>
                    {rowData.sourceFiles}
                </TextColumn>
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'changedFiles',
            title: lang.CHANGED_FILES,
            cell: rowData => rowData.changedFiles,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backedUpFilesCount',
            title: lang.TRANSFERRED_FILES,
            width: 145,
            cell: rowData => (
                rowData.backedUpFiles
            ),
        },
        {
            id: 'transferredData',
            title: lang.TRANSFERRED_DATA,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.transferredData}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'duration',
            title: lang.DURATION,
            cell: rowData => (
                <DurationColumn
                    value={rowData.duration}
                />
            ),
        },
    ];

    return (
        <Dialog
            header={title}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.OK, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <ConfiguredGrid
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'status',
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={columns}
                    data={(requestParams: FileJobSourceParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<BackupServerFileJobSourceModel[]>>(
                            '/BackupServerJob/GetFileJobSources',
                            {
                                ...requestParams,
                                jobUid,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => <FileServersDialogToolbar idProperty={'instanceUid'} extraParams={{ jobUid }} />,
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
