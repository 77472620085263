/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    DurationColumn,
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    Text,
    TextColumn,
    ConfiguredGrid,
    useGlobalAddons,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize, DurationFormatTypes } from '@veeam-vspc/shared/helpers';
import { JobSessionTaskStatusRepresentation } from '@veeam-vspc/models/web-controllers';

import type { CdpReplicationProcessedVmParam, CdpReplicationJobProcessedVmDataModel } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { PageDialogProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StatusColumn } from 'views/components/columns/StatusColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { getJobSessionCdpTaskStatusRepresentationText } from '../../../../../../../../enums';
import { ProcessedVmsDialogToolbar } from '../ProcessedVmsDialogToolbar';
import { getFailureMessage } from 'views/components/FailureMessageDialog';
import { getMarkedList } from 'core/utils';


export interface ProcessedVmsCdpJobsDialogProps extends Omit<PageDialogProps, 'content'> {
    jobUid: string;
    stateId: string;
}

export const ProcessedVmsCdpJobsDialog: React.FC<ProcessedVmsCdpJobsDialogProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { formats } = useGlobalAppData();
    const { portalEnums } = useGlobalAddons();
    const { netShortDate, netShortTime } = formats;
    const { transportService } = useGlobalServices();
    const { jobUid, stateId, title, description, disabledBack, hasBack, footer, onClose } = props;

    return (
        <PageDialog
            title={title}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <ConfiguredGrid
                    stateId={`${stateId}.Dialogs.ProcessedVms.Cdp`}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'name',
                        },
                    }}
                    defaultColumnMinWidth={100}
                    columns={[
                        {
                            id: 'name',
                            title: lang.OBJECT,
                            cell: rowData => rowData.name,
                        },
                        {
                            id: 'status',
                            title: lang.STATUS,
                            cell: (rowData) => {
                                const statusName = rowData.status;
                                const linkStatuses = [
                                    JobSessionTaskStatusRepresentation.Failed,
                                    JobSessionTaskStatusRepresentation.Warning,
                                ];

                                let message;

                                if (rowData.failureMessage) {
                                    if (linkStatuses.includes(statusName)) {
                                        message = getFailureMessage(lang, {
                                            shortDateFormat: netShortDate,
                                            shortTimeFormat: netShortTime,
                                            failureMessage: rowData.failureMessage,
                                            lastSessionTime: rowData.lastSessionEndTime,
                                            statusName: rowData.status,
                                        });
                                    } else {
                                        message = getMarkedList(rowData.failureMessage);
                                    }
                                }

                                return (
                                    <StatusColumn
                                        status={getJobSessionCdpTaskStatusRepresentationText(rowData.status)}
                                        messageBoxTitle={lang.LAST_OBSERVED_ERRORS}
                                        message={message}
                                    />
                                );
                            },
                        },
                        {
                            id: 'sla',
                            title: lang.SLA,
                            cell: rowData => <Text>{`${rowData.sla}%`}</Text>,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'bottleneck',
                            title: lang.BOTTLENECK,
                            cell: rowData => (
                                <TextColumn>
                                    {portalEnums.getEnumDescription('cdpBottleneckRepresentation', rowData.bottleneck)}
                                </TextColumn>
                            ),
                            hidden: true,
                        },
                        {
                            id: 'maxDelaySec',
                            title: capitalize(lang.MAX_DELAY),
                            cell: rowData => (
                                <DurationColumn value={rowData.maxDelaySec} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'avgDurationSec',
                            title: lang.AVG_DURATION,
                            cell: rowData => (
                                <DurationColumn value={rowData.avgDurationSec} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'maxDurationSec',
                            title: lang.MAX_DURATION,
                            cell: rowData => (
                                <DurationColumn value={rowData.maxDurationSec} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'intervalSec',
                            title: capitalize(lang.SYNC_INTERVAL),
                            cell: rowData => (
                                <DurationColumn value={rowData.intervalSec} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'successfulSessionsCount',
                            title: lang.SUCCESSFUL_SESSIONS,
                            cell: rowData => rowData.successfulSessionsCount,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'failedSessionsCount',
                            title: lang.FAILED_SESSIONS,
                            cell: rowData => rowData.failedSessionsCount,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'warningsCount',
                            title: lang.WARNINGS,
                            cell: rowData => rowData.warningsCount,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            id: 'avgTransferredDataKb',
                            title: lang.AVG_DATA,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.avgTransferredDataKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                            hidden: true,
                        },
                        {
                            id: 'maxTransferredDataKb',
                            title: lang.MAXIMUM_DATA,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.maxTransferredDataKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                            hidden: true,
                        },
                        {
                            id: 'totalTransferredDataKb',
                            title: lang.TOTAL_DATA,
                            cell: rowData => (
                                <RestorePointSizeColumn value={rowData.totalTransferredDataKb} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ]}
                    data={(requestParams: CdpReplicationProcessedVmParam) => transportService
                        .request<typeof requestParams, RequestSuccessResponse<CdpReplicationJobProcessedVmDataModel[]>>(
                            '/BackupServerJob/GetCdpProcessedVms',
                            { jobUid, ...requestParams },
                        )}
                    toggleable={true}
                    selection={{
                        field: 'vmUid',
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <ProcessedVmsDialogToolbar
                                method={'CdpProcessedVms'}
                                idProperty={'vmUid'}
                                extraParams={{ jobUid }}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
