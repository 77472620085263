/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGlobalAppStorage } from '@veeam-vspc/shared/components';

export const useLocalStorageValue = <ValueT,>(itemStateId: string, defaultV: ValueT): ValueT => {
    const appStorage = useGlobalAppStorage();
    const currentLocalStorageValue = appStorage.getItem(itemStateId) as unknown as ValueT;

    if (!currentLocalStorageValue) {
        appStorage.setItem(itemStateId, defaultV as string);
    }

    return appStorage.getItem(itemStateId) as unknown as ValueT;
};
