/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum VbWizardTypes {
    RepositoryWizard = 'repositoryWizard',

    // AWS
    PolicyEc2Wizard = 'policyEc2Wizard',
    PolicyRdsWizard = 'policyRdsWizard',
    PolicyEfsWizard = 'policyEfsWizard',
    FlrEc2Wizard = 'flrEc2Wizard',
    FlrEfsWizard = 'flrEfsWizard',

    // Azure
    PolicyVmWizard = 'policyVmWizard',
    PolicySqlWizard = 'policySqlWizard',
    PolicyAzureFilesWizard = 'policyAzureFilesWizard',
    FlrVmWizard = 'flrVmWizard',
    FlrAzureFilesWizard = 'flrAzureFilesWizard',
}
