/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ToolbarWithExport, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import type { GridExportProps } from 'views/hooks';

export interface RestorePointDialogToolbarProps extends GridExportProps {
    method?: string;
}

export const RestorePointDialogToolbar: React.FC<RestorePointDialogToolbarProps> = ({ method = 'GetRestorePoints', ...restProps }) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
        />
    );
};
