/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import {
    Search,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    ConfiguredAdvancedFilter,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { EJobStatusInteg } from '@veeam-vspc/models/web-controllers';

import type { ToolbarProps, ConfiguredAdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import physicalPlatformTypeIconSrc from 'images/filters/vaw-platform-types/physical.png';
import virtualPlatformTypeIconSrc from 'images/filters/vaw-platform-types/virtual.png';
import cloudPlatformTypeIconSrc from 'images/filters/vaw-platform-types/cloud.png';
import serverModeIconSrc from 'images/filters/agent-job-types/server.png';
import workstationModeIconSrc from 'images/filters/agent-job-types/workstation.png';
import readOnlyGuiModeIconSrc from 'images/filters/gui-modes/readonly.png';
import fullGuiModeIconSrc from 'images/filters/gui-modes/admin.png';
import entireComputerTypeIconSrc from 'images/filters/protected-computer/backup-os-level.png';
import volumeLevelTypeIconSrc from 'images/filters/protected-computer/backup-volume-level.png';
import fileLevelTypeIconSrc from 'images/filters/protected-computer/backup-file-level.png';
import { EndpointBackupModes, VawOperationModes } from 'core/enums';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useOsTypesAdvancedFilter } from 'views/components/filters/OsTypesFilters';
import { EVawGuiModes, VawPlatformTypes } from '../../../../../../enums';

export interface ManagedByConsoleFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const ManagedByConsoleFilterToolbar: React.FC<ManagedByConsoleFilterToolbarProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { Unknown, None, Success, Warning, Failed, Running } = EJobStatusInteg;
    const statuses = [
        { title: lang.SUCCESS, value: Success },
        { title: lang.WARNING, value: Warning },
        { title: lang.ERROR, value: Failed },
        { title: lang.RUNNING, value: Running },
        { title: lang.INFO, value: [Unknown, None] },
    ];
    const osTypesAdvancedFilter = useOsTypesAdvancedFilter();
    const advancedFilterOptions: ConfiguredAdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_BACKUP_AGENTS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.PLATFORM,
                        items: [
                            {
                                iconSrc: physicalPlatformTypeIconSrc,
                                label: lang.PHYSICAL,
                                value: {
                                    id: VawPlatformTypes.Physical,
                                    name: 'vawPlatformFilter',
                                },
                            },
                            {
                                iconSrc: virtualPlatformTypeIconSrc,
                                label: lang.VIRTUAL,
                                value: {
                                    id: VawPlatformTypes.Virtual,
                                    name: 'vawPlatformFilter',
                                },
                            },
                            {
                                iconSrc: cloudPlatformTypeIconSrc,
                                label: lang.CLOUD,
                                value: {
                                    id: VawPlatformTypes.Cloud,
                                    name: 'vawPlatformFilter',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.OPERATION_MODE, true),
                        items: [
                            {
                                iconSrc: serverModeIconSrc,
                                label: lang.SERVER,
                                value: {
                                    id: VawOperationModes.Server,
                                    name: 'guestOsFilter',
                                },
                            },
                            {
                                iconSrc: workstationModeIconSrc,
                                label: lang.WORKSTATION,
                                value: {
                                    id: VawOperationModes.Workstation,
                                    name: 'guestOsFilter',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        title: lang.UI_MODE,
                        items: [
                            {
                                iconSrc: readOnlyGuiModeIconSrc,
                                label: lang.READ_ONLY,
                                value: {
                                    id: EVawGuiModes.ReadOnly,
                                    name: 'guiModeFilter',
                                },
                            },
                            {
                                iconSrc: fullGuiModeIconSrc,
                                label: lang.FULL,
                                value: {
                                    id: EVawGuiModes.Full,
                                    name: 'guiModeFilter',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.BACKUP_MODE, true),
                        items: [
                            {
                                iconSrc: entireComputerTypeIconSrc,
                                label: lang.ENTIRE_COMPUTER,
                                value: {
                                    id: EndpointBackupModes.EntireComputer,
                                    name: 'backupModeFilter',
                                },
                            },
                            {
                                iconSrc: volumeLevelTypeIconSrc,
                                label: lang.VOLUME_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Volume,
                                    name: 'backupModeFilter',
                                },
                            },
                            {
                                iconSrc: fileLevelTypeIconSrc,
                                label: lang.FILE_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Folder,
                                    name: 'backupModeFilter',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    osTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <ConfiguredToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='AgentName'
                            placeholder={lang.COMPUTER}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            label={lang.STATUS}
                            statuses={statuses}
                            propName={'jobStatus'}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <ConfiguredAdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
