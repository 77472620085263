/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { CONTROL_SIZE, useGlobalLang } from '@veeam-vspc/shared/components';
import { ReportIntervalRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useIntervalStore } from './hooks';

export const IntervalCombobox = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const intervalStore = useIntervalStore();

    return (
        <StatefulCombobox
            controlRenderer={createLinkControl()}
            data={[
                { id: ReportIntervalRepresentation.CurrentMonth, text: lang.CURRENT_MONTH },
                { id: ReportIntervalRepresentation.LastMonth, text: lang.PAST_MONTH },
                { id: ReportIntervalRepresentation.LasttQuarter, text: lang.PAST_QUARTER },
                { id: ReportIntervalRepresentation.LastYear, text: lang.PAST_YEAR },
            ]}
            onChange={intervalStore.setInterval}
            size={CONTROL_SIZE.m}
            stateId={intervalStore.localStorageStateId}
            textGetter={p => p.text}
            value={intervalStore.interval}
            valueGetter={p => p.id}
        />
    );
});
