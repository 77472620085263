/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useCallback } from 'react';
import { STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';

import { UsedCloudRepositories, UsedHardwarePlansByCompanies, CloudConnectServersByPoints, CloudConnectServersByTenants } from './components';
import { MaxNumberOfRows } from '../MaxNumberOfRows';
import { useRowsStore } from '../../hooks';
import { SelectOnPage } from '../../stores';

export const CloudConnect: React.FC<{}> = observer(() => {
    const rowsStore = useRowsStore();
    const { maxNumberRowsCloudConnect: maxNumberOfRows } = rowsStore;

    const handleChangeMaxNumberOfRows = useCallback((maxNumberValue: number): void => {
        rowsStore.setMaxNumberRows(maxNumberValue, SelectOnPage.CloudConnect);
    }, []);

    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} style={{ width: '100%', overflow: 'hidden' }}>
            <StackView direction={STACK_DIRECTION.row}>
                <MaxNumberOfRows
                    maxNumberOfRows={maxNumberOfRows}
                    handleChangeMaxNumberOfRows={handleChangeMaxNumberOfRows}
                />
            </StackView>

            <StackView
                direction={STACK_DIRECTION.row}
                gap={STACK_GAP.m}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                }}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxl}>
                    <UsedCloudRepositories maxNumberOfRows={maxNumberOfRows} />
                    <UsedHardwarePlansByCompanies maxNumberOfRows={maxNumberOfRows} />
                </StackView>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxl}>
                    <CloudConnectServersByTenants maxNumberOfRows={maxNumberOfRows} />
                    <CloudConnectServersByPoints maxNumberOfRows={maxNumberOfRows} />
                </StackView>
            </StackView>
        </StackView>
    );
});
