/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    TextColumn,
    transformTimeToAgoString,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { ProtectedComputerManagedByVBR, ProtectedComputersManagedByVbrFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { JobKinds } from 'views/pages/BackupJobsPage/enums';
import { BackupJobsColumn } from '../BackupJobsColumn';
import { ComputersFilterToolbar } from './components';
import { ConfigSectionIds } from 'core/enums';


export interface ManagedByBackupServerProps {
    stateId: string;
}

export const ManagedByBackupServer: React.FC<ManagedByBackupServerProps> = ({ stateId }) => {
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const gridStateId = `${stateId}.ManagedByBackupServer`;
    const columns: GridColumnProps<ProtectedComputerManagedByVBR>[] = [
        {
            id: 'name',
            title: lang.NAME,
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'serverName',
            title: lang.SERVER_NAME,
            cell: rowData => rowData.serverName,
        },
        {
            id: 'malwareState',
            title: capitalize(lang.MALWARE_STATE),
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('MalwareState', rowData.malwareState)}
                </TextColumn>
            ),
        },
        {
            id: 'totalJobsCount',
            title: lang.BACKUPS,
            cell: rowData => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupJobs`}
                    value={rowData.totalJobsCount}
                    title={`${lang.BACKUPS} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.BackupJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'totalCopyJobsCount',
            title: lang.BACKUP_COPIES,
            cell: rowData => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupCopyJobs`}
                    value={rowData.totalCopyJobsCount}
                    title={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.CopyJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'guestOs',
            title: lang.GUEST_OS,
            cell: rowData => rowData.guestOs,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: rowData => transformTimeToAgoString(rowData.lastRestorePoint, lang),
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'lastCloudRestorePoint',
            title: lang.CLOUD_COPY,
            cell: (rowData) => {
                if (!rowData.lastCloudRestorePoint) {
                    return lang.NO;
                }

                return `${lang.YES} (${transformTimeToAgoString(rowData.lastCloudRestorePoint, lang)})`;
            },
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: rowData => rowData.operationModeRendered,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            stateId={gridStateId}
            defaultColumnMinWidth={80}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.id,
                },
            }}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<ProtectedComputersManagedByVbrFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<ProtectedComputerManagedByVBR[]>>(
                    '/ProtectedWorkloads/GetComputersManagedByVBR',
                    { ...requestParams }
                )}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ComputersFilterToolbar />,
            ]}
        />
    );
};
