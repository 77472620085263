/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    ActionLinkColumn,
    ConfiguredGrid,
    MessageBox,
    ParsedText,
    TextColumn,
    TimeAgoColumn,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import {
    EOsTypeInteg,
    SystemTypeRepresentation,
    DeployAgentStatusRepresentation,
    EVacAgentStatusInteg,
    EConnectionStatus,
} from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { useFullPage } from '@veeam-vspc/shared/hooks';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridStore } from '@veeam-vspc/shared/components';
import type { DiscoveredComputerModel, DiscoveredComputerFilter, VbrDeployWizardParametersModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ComputersFilterToolbar } from './components/ComputersFilterToolbar';
import { CustomNoteColumn } from 'views/components/columns/CustomNoteColumn';
import { ConnectionStatusColumn } from 'views/components/columns/ConnectionStatusColumn';
import { DeploymentStatusColumn } from './components/DeploymentStatusColumn';
import { ScheduledUpdatesColumn } from 'views/components/columns/ScheduledUpdatesColumn';
import { AgentStatusColumn } from 'views/components/columns/AgentStatusColumn';
import { AgentVersionColumn } from 'views/components/columns/AgentVersionColumn';
import { ProgressBar } from 'views/components/ProgressBar';
import { BackupPolicyColumn } from './components/BackupPolicyColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ComputersActionToolbar } from './components/ComputersActionToolbar';
import { ConfigSectionIds } from 'core/enums';
import { InstallAndUpdateVBRWizard } from '../../../InstallAndUpdateVBRWizard';
import { VbrDeployType } from '../../../InstallAndUpdateVBRWizard/enums';


import type { Product } from '../../../InstallAndUpdateVBRWizard/interfaces';

export interface ComputersProps {
    stateId?: string;
}

export const Computers: React.FC<ComputersProps> = ({ stateId }) => {
    const { transportService } = useGlobalServices();
    const gridStateId = `${stateId}.Computers`;
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useRef<GridStore<DiscoveredComputerModel, unknown, DiscoveredComputerFilter>>();

    const [installVbrWizard, installVbrWizardAction] = useFullPage<{
        vbrParams: VbrDeployWizardParametersModel;
        hostName: string;
        products: Product[];
        onFinish: () => void;
            }>({
                render: ({ onClose, data }) => (
                    <InstallAndUpdateVBRWizard
                        distributiveSize={data.vbrParams.distributiveSize}
                        autoLkConfigured={data.vbrParams.autoLkConfigured}
                        distributiveFileName={data.vbrParams.distributiveFileName}
                        pulseConfigured={data.vbrParams.pulseConfigured}
                        tenantName={data.vbrParams.tenantName}
                        isInfrastructureAgent={data.vbrParams.isInfrastructureAgent}
                        organizationUid={data.vbrParams.organizationUid}
                        isVcdTenant={data.vbrParams.isVcdTenant}
                        vbrDeployType={VbrDeployType.Install}
                        hostName={data.hostName}
                        products={data.products}
                        vbrLicenseParams={{
                            onlyThese: [...gridApi.current.requestParamsForActions.onlyThese as string[]],
                        }}
                        onFinish={data.onFinish}
                        deactivate={onClose}
                    />
                ),
            });

    const [initialConfig] = useState(() => {
        if (RCOP.discoveryComputersRuleId !== undefined) {
            const ruleId = RCOP.discoveryComputersRuleId;
            delete RCOP.discoveryComputersRuleId;

            return {
                filters: {
                    ruleId,
                },
                isForce: true,
            };
        }

        return undefined;
    });

    return (
        <>
            <ConfiguredGrid
                api={gridApi}
                sectionId={ConfigSectionIds.DiscoveryComputersComputers}
                stateId={gridStateId}
                defaultColumnMinWidth={80}
                isRowInactive={rowData =>
                    rowData.agentStatus === EVacAgentStatusInteg.Inacessible ||
                    rowData.agentStatus === EVacAgentStatusInteg.Rejected ||
                    rowData.status === EConnectionStatus.Offline}
                columns={[
                    {
                        id: 'companyName',
                        title: lang.COMPANY,
                        cell: rowData => rowData.companyName,
                    }, {
                        id: 'siteName',
                        title: lang.SITE,
                        hidden: true,
                        cell: rowData => rowData.siteName,
                    }, {
                        id: 'locationName',
                        title: lang.LOCATION,
                        hidden: true,
                        cell: rowData => rowData.locationName,
                    }, {
                        id: 'ruleName',
                        title: lang.RULE,
                        cell: rowData => rowData.ruleName,
                    }, {
                        id: 'machineTypeStr',
                        title: lang.PLATFORM,
                        hidden: true,
                        cell: rowData => rowData.machineTypeStr,
                    }, {
                        id: 'hostName',
                        title: lang.HOSTNAME,
                        cell: rowData => rowData.hostName,
                    }, {
                        id: 'agentCustomNote',
                        title: lang.TAG,
                        cell: rowData => (
                            <CustomNoteColumn
                                value={rowData.agentCustomNote}
                                agentUid={rowData.vacAgentGuid}
                                itemParams={
                                    {
                                        agentStatus: rowData.agentStatus,
                                        connectionStatus: rowData.status,
                                        isVacInstalled: rowData.isVacInstalled,
                                    }
                                }
                            />
                        ),
                        width: 85,
                    }, {
                        id: 'osType',
                        title: lang.TYPE,
                        cell: (rowData) => {
                            const textResult = (text: string) => text;
                            const value = rowData.osType;

                            if (value === EOsTypeInteg.Unknown) {
                                return textResult(lang.DASH_SYMBOL);
                            }

                            if (rowData.systemType === SystemTypeRepresentation.Windows) {
                                if (value === EOsTypeInteg.Workstation) {
                                    return textResult(lang.WINDOWS_WORKSTATION);
                                }
                                return textResult(lang.WINDOWS_SERVER);
                            } else if (rowData.systemType === SystemTypeRepresentation.Linux) {
                                return textResult(lang.LINUX);
                            }
                            return textResult(lang.MAC);
                        },
                        hidden: true,
                    }, {
                        id: 'os',
                        title: lang.GUEST_OS,
                        cell: rowData => rowData.os,
                    }, {
                        id: 'applicationsStr',
                        title: lang.APPLICATION,
                        cell: (rowData) => {
                            const appsItems = rowData.applications && rowData.applications.length > 1
                                ? rowData.applications.map(app => ({
                                    text: app,
                                    listItem: true,
                                })) : [];
                            return (
                                <ActionLinkColumn
                                    isLink={rowData.applications && rowData.applications.length > 1}
                                    content={rowData.applicationsStr}
                                    renderModal={({ deactivate }) => (
                                        <MessageBox
                                            header={lang.APPLICATION}
                                            onRequestClose={deactivate}
                                            actions={[
                                                { text: lang.OK, onClick: deactivate },
                                            ]}
                                        >
                                            <ParsedText
                                                textOrConfig={[
                                                    {
                                                        text: lang.DISCOVERED_APPLICATIONS,
                                                    }, ...appsItems,
                                                ]}
                                            />
                                        </MessageBox>
                                    )}
                                />
                            );
                        },
                        hidden: true,
                    }, {
                        id: 'status',
                        title: capitalize(lang.CONNECTION_STATUS),
                        cell: rowData => <ConnectionStatusColumn rowData={rowData} />,
                        width: 155,
                    }, {
                        id: 'deployStatus',
                        title: lang.DEPLOYMENT_STATUS,
                        cell: rowData => <DeploymentStatusColumn rowData={rowData} />,
                        width: 160,
                    }, {
                        id: 'scheduledVbrTask',
                        title: lang.SCHEDULED_DEPLOYMENT,
                        cell: rowData => <ScheduledUpdatesColumn rowData={rowData} />,
                        width: 185,
                    }, {
                        id: 'progress',
                        title: lang.DEPLOYMENT_PROGRESS,
                        cell: (rowData) => {
                            if (
                                rowData.deployStatus === DeployAgentStatusRepresentation.Failed
                                || rowData.deployStatus === DeployAgentStatusRepresentation.Unknown
                            ) {
                                return lang.DASH_SYMBOL;
                            }

                            return (
                                <ProgressBar
                                    progress={rowData.progress}
                                    needLabel={false}
                                />
                            );
                        },
                        width: 175,
                    }, {
                        id: 'agentStatus',
                        title: capitalize(lang.MANAGEMENT_AGENT_STATUS),
                        cell: rowData => <AgentStatusColumn rowData={rowData} />,
                        width: 205,
                    }, {
                        id: 'agentVersion',
                        title: capitalize(lang.MANAGEMENT_AGENT_VERSION),
                        cell: rowData => <AgentVersionColumn rowData={rowData} />,
                        width: 220,
                    }, {
                        id: 'backupPolicyStr',
                        title: lang.BACKUP_POLICY,
                        cell: rowData => (
                            <BackupPolicyColumn
                                model={rowData}
                            />
                        ),
                        hidden: true,
                    }, {
                        id: 'rejectReason',
                        title: lang.REJECT_REASON,
                        cell: rowData => rowData.rejectReason,
                        hidden: true,
                    }, {
                        id: 'registrationTime',
                        title: lang.REGISTRATION_TIME,
                        cell: rowData => (
                            <GridDateColumn value={rowData.registrationTime} />
                        ),
                        hidden: true,
                    }, {
                        id: 'ipAddresses',
                        title: capitalize(lang.IP_ADDRESS),
                        cell: rowData => (
                            <TextColumn defaultValue={lang.DASH_SYMBOL}>
                                {Array.isArray(rowData.ipAddresses) ? rowData.ipAddresses.join(', ') : rowData.ipAddresses}
                            </TextColumn>
                        ),
                        hidden: true,
                    }, {
                        id: 'macAddress',
                        title: lang.MAC_ADDRESS,
                        cell: rowData => rowData.macAddress,
                        hidden: true,
                    }, {
                        id: 'discoveryTime',
                        title: lang.DISCOVERY_TIME,
                        cell: rowData => (
                            <GridDateColumn value={rowData.discoveryTime} />
                        ),
                        hidden: true,
                    }, {
                        id: 'agentRuleRole',
                        title: lang.AGENT_ROLE,
                        cell: rowData => rowData.agentRuleRole,
                        hidden: true,
                    }, {
                        id: 'lastContactTime',
                        title: lang.LAST_HEARTBEAT,
                        cell: rowData => (
                            <TimeAgoColumn
                                lang={lang}
                                value={rowData.lastContactTime}
                            />
                        ),
                        hidden: true,
                    }, {
                        id: 'rebootRequired',
                        title: lang.REBOOT_REQUIRED,
                        cell: rowData => rowData.rebootRequired ? lang.YES : lang.NO,
                        hidden: true,
                    },
                ]}
                selection={{
                    field: 'uniqueId',
                    checkbox: true,
                    multiple: true,
                }}
                data={(requestParams: RequestParams<DiscoveredComputerFilter>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<DiscoveredComputerModel[]>>('/DiscoveryRule/GetComputers', {
                        ...requestParams,
                    })}
                toolbars={[
                    () => <ComputersFilterToolbar />,
                    () => (
                        <ComputersActionToolbar
                            installVbrWizardAction={installVbrWizardAction}
                        />
                    ),
                ]}
                initialState={
                    initialConfig
                }
            />

            {installVbrWizard}
        </>
    );
};
