/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { CopyJobWizardStoreContext } from './copy-job-wizard-store-context';

import type { CopyJobWizardStore } from './copy-job-wizard-store';

export const useCopyJobWizardStore = (): CopyJobWizardStore => useContext(CopyJobWizardStoreContext);
