/**
 * Copyright © Veeam Software Group GmbH.
 */

import { colors, Icon, SPACING_L, SPACING_M, STACK_ALIGN, STACK_GAP, StackView, WindowClose } from '@veeam-vspc/shared/components';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { getStatusIconSrc } from 'core/utils';
import { StatusTypes } from 'core/utils/status-helpers/enums';

export interface NotificationBarProps {
    text?: string;
    icon?: StatusTypes;
    onClose?: () => void;
}

export const NotificationBarStyles = createGlobalStyle`
    .notification-bar .notification-bar__close-icon svg {
      color: ${colors.G600}
    }
`;

export const NotificationBarItem: React.FC<NotificationBarProps> = ({ text, icon, onClose }) => (
    <StackView
        align={STACK_ALIGN.center}
        gap={STACK_GAP.m}
        style={{
            padding: `${SPACING_L} ${SPACING_M}`,
            backgroundColor: colors.Y50,
            position: 'relative',
        }}
        className={'notification-bar'}
    >
        <Icon src={getStatusIconSrc(icon ?? StatusTypes.Info)} />
        <span>{text ?? ''}</span>

        {onClose && (
            <WindowClose
                isInteractable
                onClick={onClose}
                className={'notification-bar__close-icon'}
            />
        )}

        <NotificationBarStyles />
    </StackView>
);
