/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

export const SpinnerLayout = styled.div`
  position: absolute;
  top: 38px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
`;
