/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { VirtualInfrastructure } from './components';

export interface ProtectedDataVirtualInfrastructurePageProps {
    currentPath?: string;
}

export const ProtectedDataVirtualInfrastructurePage: React.FC<ProtectedDataVirtualInfrastructurePageProps> = ({ currentPath }) => (
    <TabBarBodyLayout>
        <VirtualInfrastructure stateId={currentPath} />
    </TabBarBodyLayout>
);
