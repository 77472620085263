/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout } from '@veeam-vspc/shared/components';

import { DataBackupObjectStorage } from './components';

export const BackupJobsDataBackupObjectStoragePage: React.FC<{}> = () => (
    <TabBarBodyLayout>
        <DataBackupObjectStorage />
    </TabBarBodyLayout>
);
