/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { colors as palette, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { observer } from 'mobx-react-lite';
import { AzureObjectType } from '@veeam-vspc/models/web-controllers';
import { useLocation } from 'react-router-dom';

import type { VspcLang } from 'configs/languages';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedAzureWorkloadsContext } from './contexts/ProtectedAzureWorkloadsContext';
import { ProtectedAzureWorkloadsService } from './services/protected-azure-workloads.service';
import { ProtectedAzureWorkloadsStore } from './stores/ProtectedAzureWorkloadsStore';
import { StatefulCombobox } from 'views/components/StatefulCombobox';
import { useProtectedAzureWorkloadsStore } from './hooks/use-protected-azure-workloads-store';
import { useLocalStorageValue } from 'views/hooks';

import type { ComboboxItem } from 'core/interfaces';

export const ChartLayout = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const protectedAzureWorkloadsStore = useProtectedAzureWorkloadsStore();
    const protectedAzureWorkloadsService = useMemo(() => new ProtectedAzureWorkloadsService(transportService, lang), [transportService, lang]);

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_AZURE_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedAzureWorkloadsStore.setAzureObjectType}
                stateId={protectedAzureWorkloadsStore.lsStateId}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedAzureWorkloadsStore.azureObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: AzureObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: AzureObjectType.Database, text: capitalize(lang.AZURE_SQL_DATABASES) },
                    { id: AzureObjectType.Files, text: capitalize(lang.AZURE_FILES) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedAzureWorkloadsStore.azureObjectType}
            params={params}
            service={protectedAzureWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedAzureWorkloads: React.FC = () => {
    const { pathname } = useLocation();
    const stateId = `${pathname}.ProtectedAzureWorkloads.AzureObjectType`;
    const initialType = useLocalStorageValue<AzureObjectType>(stateId, AzureObjectType.Vm);

    const protectedAzureWorkloadsStore = useMemo(() => new ProtectedAzureWorkloadsStore(initialType, stateId), [initialType, stateId]);

    return (
        <ProtectedAzureWorkloadsContext.Provider value={protectedAzureWorkloadsStore}>
            <ChartLayout />
        </ProtectedAzureWorkloadsContext.Provider>
    );
};
