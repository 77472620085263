/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode, ConfiguredGrid, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { Vb365BackupRepository } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import { AvailableRepositoriesToolbar } from '../AvailableRepositoriesToolbar/AvailableRepositoriesToolbar';

interface AvailableRepositoriesProps {
    hidePanel: () => void;
}

export const AvailableRepositories: FC<AvailableRepositoriesProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365BackupRepository, unknown, unknown>>();
    const { notificationService } = useGlobalServices();

    const fetchData = ({ page, limit, filter }:
        RequestParams<Vb365BackupRepository>): Promise<RequestSuccessResponse<Vb365BackupRepository[]>> => {
        const items = wizardStore.backupRepositories
            .filter(x => x.name.toLowerCase().includes((filter.name || '').toLowerCase().trim()));

        const chunk = items.filter((x, index) => index >= (page * limit) - limit && index < page * limit);

        return Promise.resolve({
            data: chunk,
            meta: {
                pagingInfo: {
                    total: items.length,
                    count: items.length,
                    offset: 0,
                },
            },
        });
    };

    const handleApply = () => {
        const repository = api.current?.selected[0];

        if (!repository) {
            return;
        }

        if (!wizardStore.selectedBackupRepository) {
            wizardStore.selectBackupRepository(repository.instanceUid);

            hidePanel();

            return;
        }

        if (repository.instanceUid === wizardStore.selectedBackupRepository.instanceUid) {
            hidePanel();

            return;
        }

        if (wizardStore.needToShowBackupRepositoryChangeWarning) {
            notificationService.warning(
                lang.BACKUP_REPOSITORY,
                lang.IT_IS_RECOMMENDED_TO_PERFORM_A_FULL
            ).then(() => wizardStore.handleBackupRepositoryChangeWarningShown());
        }

        wizardStore.selectBackupRepository(repository.instanceUid);

        hidePanel();
    };

    return (
        <SidePanelForm
            title={lang.AVAILABLE_REPOSITORIES}
            description={lang.SELECT_BACKUP_REPOSITORY_FROM_THE_LIST}
            onRequestClose={hidePanel}
            actions={[
                { text: lang.APPLY, onClick: handleApply, disabled: api.current?.selected.length === 0 },
                { text: lang.CANCEL, onClick: hidePanel, view: ACTION_VIEW.secondary },
            ]}
        >
            <ConfiguredGrid
                api={api}
                columns={[
                    {
                        id: 'name',
                        title: lang.REPOSITORY,
                        cell: rowData => rowData.name,
                    },
                    {
                        id: 'isObjectStorageRepository',
                        title: lang.TYPE,
                        cell: rowData => rowData.isObjectStorageRepository
                            ? lang.OBJECT_STORAGE
                            : lang.EXTENSIBLE_STORAGE_ENGINE,
                    },
                ]}
                data={fetchData}
                initialState={{
                    selected: wizardStore.selectedBackupRepository ? [wizardStore.selectedBackupRepository] : [],
                }}
                disableAutoUpdate
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
                sortingMode={SortingMode.Local}
                toolbars={[
                    () => <AvailableRepositoriesToolbar />,
                ]}
                toggleable={false}
            />
        </SidePanelForm>
    );
});
