/**
 * Copyright © Veeam Software Group GmbH.
 */

import { HttpMethods } from '@veeam-vspc/shared/core';
import { useGlobalServices } from '@veeam-vspc/shared/components';

import type { BlobFileData } from '@veeam-vspc/shared/services';

export const useLoadImageDataUrl = () => {
    const { fileTransportService } = useGlobalServices();

    return (url: string, noAuth = false) => fileTransportService.downloadFileAjax(url, null, {
        requestInit: {
            method: HttpMethods.Get,
        },
        noAutoDownload: true,
        noAuth,
    })
        .then(resp => window.URL.createObjectURL((resp as BlobFileData).blob))
        .catch(() => url);
};
