/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
    colors,
    constants,
    FONT_SIZE_XL,
    Icon,
    ICON_SIZES,
    SPACING_S,
    SPACING_XXL,
    Spinner,
    STACK_DIRECTION,
    StackView,
    Text,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';
import styled from 'styled-components';

import type { PieChartModelVmItem } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { BillingWidget } from '../../../../BillingWidget';
import { EmptyPlate, SpinnerPlateStyled, Separator } from 'views/components/ChartWidget/components';
import { useHwPlansStore } from '../../hooks';
import { useIntervalRequest } from 'views/hooks';
import vCpuIcon from 'images/vcpu.svg';
import vmsIcon from 'images/vms.svg';

export const UsedCpuResources = observer(() => {
    const abortController = useRef(new AbortController());
    const didMount = useRef(false);
    const lang = useGlobalLang<VspcLang>();
    const { hwpId } = useHwPlansStore();

    const Counter = styled(Text)`
        color: ${colors.B400};
        font-size: ${FONT_SIZE_XL};
    `;

    const IconCounter = styled(StackView)`
        align-items: center;
        flex: 1;
        justify-content: center;
    `;

    const Type = styled(Text)`
        padding: ${SPACING_S} 0;
    `;

    const request = useIntervalRequest<{}, PieChartModelVmItem>(
        '/Billing/GetClientBillingSummaryUsedCPUResources',
        { hwpId },
        {
            requestInit: {
                signal: abortController.current.signal,
            },
        },
        5 * TimeUnitsInMs.Minute,
    );

    const { vmcount, vcpucount } = request.data
        ? request.data[0] as PieChartModelVmItem
        : {} as PieChartModelVmItem;

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();
            abortController.current = new AbortController();

            return request.forceRequest();
        }

        didMount.current = true;
    }, [hwpId]);

    return (
        <BillingWidget title={{ text: lang.CLOUD_HOST_CPU_RATIO }}>
            <StackView style={{ flex: 1 }}>
                {request.loading && (
                    <SpinnerPlateStyled>
                        <Spinner size={48} style={{ marginTop: `-${constants.SPACING_XXL}` }} />
                    </SpinnerPlateStyled>
                )}

                {vmcount + vcpucount === 0 && <EmptyPlate emptyText={lang.NO_DATA_AVAILABLE} emptyDesc={lang.PLEASE_START_USING_VCC_BACKUP_JOBS} />}

                {vmcount + vcpucount > 0 && !request.loading && (
                    <StackView
                        direction={STACK_DIRECTION.row}
                        style={{
                            alignSelf: 'center',
                            flex: 1,
                            height: '128px',
                            paddingBottom: constants.SPACING_XL,
                        }}
                    >
                        <IconCounter direction={STACK_DIRECTION.column} style={{ paddingLeft: SPACING_XXL }}>
                            <Icon size={ICON_SIZES.xl} src={vmsIcon} />
                            <Type>{lang.VMS}</Type>
                            <Counter>{vmcount}</Counter>
                        </IconCounter>

                        <Separator />

                        <IconCounter direction={STACK_DIRECTION.column} style={{ paddingRight: SPACING_XXL }}>
                            <Icon size={ICON_SIZES.xl} src={vCpuIcon} />
                            <Type>{lang.V_CPUS}</Type>
                            <Counter>{vcpucount}</Counter>
                        </IconCounter>
                    </StackView>
                )}
            </StackView>
        </BillingWidget>
    );
});
