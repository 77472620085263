/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Checkbox,
    Form,
    NoLabelGroup,
    useExternalFormApi,
    useGlobalServices,
    WizardSummary,
} from '@veeam-vspc/shared/components';
import { capitalize, dataSizeToString } from '@veeam-vspc/shared/helpers';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { WizardStep } from '@veeam-vspc/shared/components';
import type { UserData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { useEditUserProfileStore } from '../../stores';
import { ConfigSectionIds } from 'core/enums';

interface SummaryStepProps {
    lang: VspcLang;
}

const SummaryStep: React.FC<SummaryStepProps> = ({ lang }) => {
    const editUserProfileStore = useEditUserProfileStore();
    const { configuratorService } = useGlobalServices();
    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.EditUserProfileWizardSummary);
    const isSendInvoiceAvailable = sectionConfig
        ? sectionConfig.getItemState('sendInvoice') === ConfigStates.Visible
        : false;
    const { data, isSecretCodeGenerated } = editUserProfileStore;
    const formApi = useExternalFormApi();

    return (
        <Form value={data} externalFormApi={formApi}>
            <WizardSummary
                fieldsets={[
                    {
                        title: lang.USER_INFO,
                        fields: [
                            {
                                label: `${lang.FIRST_NAME}:`,
                                value: data.firstName,
                            },
                            {
                                label: `${lang.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${capitalize(lang.EMAIL_ADDRESS)}:`,
                                value: data.email,
                            },
                            {
                                label: `${lang.USERNAME}:`,
                                value: data.login,
                            },
                            {
                                label: `${lang.ROLE}:`,
                                value: data.userRoleName,
                            },
                        ],
                    },
                    editUserProfileStore.enabledQuota && {
                        title: lang.QUOTA,
                        fields: [
                            {
                                label: lang.CLOUD_REPOSITORY_NAME,
                                value: data.quota.repositoryFriendlyName,
                            },
                            {
                                label: lang.USER_QUOTA,
                                value: data.quota.repositoryQuota.toString(),
                                transform(value) {
                                    if (!data.quota.isUnlimited) {
                                        const quota = Number(value) || 0;
                                        return quota === -1 ? lang.UNLIMITED : dataSizeToString(quota, 0, 'G');
                                    }

                                    return lang.UNLIMITED;
                                },
                            },
                        ],
                    },
                    {
                        title: lang.MULTI_FACTOR_AUTHENTICATION,
                        fields: [
                            {
                                label: `${lang.MFA_STATUS}:`,
                                value: isSecretCodeGenerated ? lang.ENABLED : lang.DISABLED,
                            },
                        ],
                    },
                ].filter(Boolean)}
            />

            {isSendInvoiceAvailable && (
                <NoLabelGroup
                    content={(
                        <Checkbox
                            name={'sendInvoice'}
                        >
                            {lang.ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES}
                        </Checkbox>
                    )}
                />
            )}
        </Form>
    );
};

export const getSummaryStep = (lang: VspcLang): WizardStep<UserData> => ({
    title: lang.SUMMARY,
    description: lang.REVIEW_AND_COPY_MSG,
    render: () => <SummaryStep lang={lang} />,
});
