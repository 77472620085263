/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import {
    TabBarBodyLayout,
    TabBarBodyLayoutModes,
    useGlobalAppData,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { Configuration } from './components';
import {
    ConfigurationRequestsStore,
    ConfigurationRequestsStoreContext,
    ConfigurationStore,
    ConfigurationStoreContext,
} from './components/Configuration/stores';

export const NotificationsConfigurationPage: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { notificationService, transportService } = useGlobalServices();
    const { portalUser } = useGlobalAppData();
    const { organizationUid } = portalUser ?? {};

    const requestsStore = useMemo(() => (
        new ConfigurationRequestsStore(transportService, organizationUid, lang.EMAIL_SERVER_SETTINGS)
    ), []);

    const configurationStore = useMemo(() => (
        new ConfigurationStore(notificationService, requestsStore, lang)
    ), []);

    return (
        <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
            <ConfigurationRequestsStoreContext.Provider value={requestsStore}>
                <ConfigurationStoreContext.Provider value={configurationStore}>
                    <Configuration />
                </ConfigurationStoreContext.Provider>
            </ConfigurationRequestsStoreContext.Provider>
        </TabBarBodyLayout>
    );
};
