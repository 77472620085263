/**
 * Copyright © Veeam Software Group GmbH.
 */

import { MonthsRepresentation } from '@veeam-vspc/models/web-controllers';

export const monthsInfo = {
    [MonthsRepresentation.January]: { days: 31 },
    [MonthsRepresentation.February]: { days: 29 },
    [MonthsRepresentation.March]: { days: 31 },
    [MonthsRepresentation.April]: { days: 30 },
    [MonthsRepresentation.May]: { days: 31 },
    [MonthsRepresentation.June]: { days: 30 },
    [MonthsRepresentation.July]: { days: 31 },
    [MonthsRepresentation.August]: { days: 31 },
    [MonthsRepresentation.September]: { days: 30 },
    [MonthsRepresentation.October]: { days: 31 },
    [MonthsRepresentation.November]: { days: 30 },
    [MonthsRepresentation.December]: { days: 31 },
};

export const isValidDayInMonth = (day: number, months: MonthsRepresentation[] = []): boolean => (
    months.some(month => monthsInfo[month].days >= day)
);
