/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ProtectedVmReportJobType {
    Backup = 0,
    Replication = 1,
    Archive = 2,
    VmTapeBackup = 28,
    BackupCopyAdvanced = 51,
    Snapshot = 100,
    RemoteSnapshot = 101,
}
