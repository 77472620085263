/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { AsyncActionStatusUpdatedEventStatus, AsyncActionStatus } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { AsyncActionNames } from '../enums';

export const getActionNameHelper = (
    actionName: string,
    status: AsyncActionStatus | AsyncActionStatusUpdatedEventStatus,
    lang: VspcLang
): string | null => {
    if (!actionName || !status) {
        return null;
    }

    const {
        RemoveVb365Organizations,
        DeleteVb365Server,
        ForceCollectVb365Server,
        RemoveVb365Organization,
        CreateVb365Microsoft365Organization,
        PatchVb365Microsoft365Organization,
        RemoveVb365Jobs,
        StartVb365Jobs,
        StopVb365Jobs,
        DisableVb365Jobs,
        EnableVb365Jobs,
        CreateVb365BackupJob,
        PatchVb365BackupJob,
        CreateVb365CopyJob,
        PatchVb365CopyJob,
    } = AsyncActionNames;

    switch (actionName) {
        case (RemoveVb365Organizations):
            switch (status) {
                default: return '';
            }

        case (DeleteVb365Server):
            switch (status) {
                default: return '';
            }

        case (ForceCollectVb365Server):
            switch (status) {
                default: return '';
            }

        case (RemoveVb365Organization):
            switch (status) {
                default: return '';
            }

        case (CreateVb365Microsoft365Organization):
            switch (status) {
                default: return '';
            }

        case (PatchVb365Microsoft365Organization):
            switch (status) {
                default: return '';
            }

        case (RemoveVb365Jobs):
            switch (status) {
                default: return '';
            }

        case (StartVb365Jobs):
            switch (status) {
                default: return '';
            }

        case (StopVb365Jobs):
            switch (status) {
                default: return '';
            }

        case (DisableVb365Jobs):
            switch (status) {
                default: return '';
            }

        case (EnableVb365Jobs):
            switch (status) {
                default: return '';
            }

        case (CreateVb365BackupJob):
            switch (status) {
                default: return '';
            }

        case (PatchVb365BackupJob):
            switch (status) {
                default: return '';
            }

        case (CreateVb365CopyJob):
            switch (status) {
                default: return '';
            }

        case (PatchVb365CopyJob):
            switch (status) {
                default: return '';
            }

        default: return null;
    }
};
