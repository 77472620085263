/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CloudWorkload } from '@veeam-vspc/models/web-controllers';

import type { TableData } from '../interfaces';

export const transformData = (data: CloudWorkload[]): TableData => ({
    data,
    empty: data.length === 0,
});
