/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Icon,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    ConfiguredGrid,
    useGlobalLang,
    RequestGridSortDirections,
    LinkButton,
    useModal, useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { BackupServerVmwareInventoryType } from '@veeam-vspc/models/rest';

import type { VirtualServerVirtualMachine, BackupServerBackupJobVmwareObjectSize } from '@veeam-vspc/models/rest';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { FC } from 'react';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useJobWizardStore } from '../../../../stores';
import protectedObjectsIconSrc from 'images/actions/protected-objects.png';
import searchIconSrc from 'images/icons/search.svg';
import { ItemsModal } from '../ItemsModal/ItemsModal';
import { BackupOptionsToolbar } from '../BackupOptionsToolbar/BackupOptionsToolbar';
import { BackupOptionsTarget } from '../../../../enums';

interface ConfigureExcludeObjectsProps {
    hidePanel: () => void;
}

export const ConfigureExcludeObjects: FC<ConfigureExcludeObjectsProps> = observer(({ hidePanel }) => {
    const lang = useGlobalLang<VspcLang>();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<BackupServerBackupJobVmwareObjectSize, unknown, unknown>>();
    const { portalEnums } = useGlobalAddons();

    const handleSelectItems = (
        vms: VirtualServerVirtualMachine[],
        deactivate?: () => void,
    ) => {
        wizardStore.handleSelectItems(
            BackupOptionsTarget.ExcludedItems,
            vms,
            BackupServerVmwareInventoryType.VirtualMachine
        );

        deactivate && deactivate();

        api.current?.fetchData();
    };

    const handleDeleteItems = (items: BackupServerBackupJobVmwareObjectSize[]) => {
        wizardStore.handleDeleteItems(BackupOptionsTarget.ExcludedItems, items);

        api.current?.reloadGrid();
    };

    const fetchData = (
        { page, limit, sort, filter }: RequestParams<BackupServerBackupJobVmwareObjectSize & { name: string; }>,
    ): Promise<RequestSuccessResponse<BackupServerBackupJobVmwareObjectSize[]>> => {
        let items = wizardStore.excludedItems.slice();

        if (sort && sort.length) {
            items.sort((a, b) => {
                const { direction, property } = sort[0];

                const aValue = a.inventoryObject[property];
                const bValue = b.inventoryObject[property];

                if (direction === RequestGridSortDirections.Asc) {
                    return aValue
                        .localeCompare(
                            bValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                if (direction === RequestGridSortDirections.Desc) {
                    return bValue
                        .localeCompare(
                            aValue,
                            'kn',
                            { sensitivity: 'base', numeric: true }
                        );
                }

                return 0;
            });
        }

        items = items
            .filter(x => x.inventoryObject.name
                .toLowerCase()
                .includes((filter.name || '').trim().toLowerCase()),
            );

        const chunk = items.slice((page * limit - limit), page * limit);

        return Promise.resolve({
            data: chunk.map(x => ({ ...x, objectId: x.inventoryObject.objectId })),
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as RequestSuccessResponse<BackupServerBackupJobVmwareObjectSize[]>);
    };

    const handleApply = () => {
        wizardStore.updateExcludedItems();

        hidePanel();
    };

    const handleCancel = () => {
        wizardStore.cancelExcludedItems();

        hidePanel();
    };

    const [itemsModal, itemsModalActions] = useModal({
        render: ({ deactivate }) => (
            <ItemsModal
                availablePages={['vms']}
                onCancel={deactivate}
                onApply={(containers, vms) => handleSelectItems(vms, deactivate)}
            />
        ),
    });


    return (
        <SidePanelForm
            title={lang.EXCLUSIONS}
            description={lang.SPECIFY_OBJECTS_TO_EXCLUDE_FROM_THE_BACKUP}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.l} spaceFill={SPACE_FILL.all}>
                <LinkButton
                    iconBefore={searchIconSrc}
                    onClick={() => itemsModalActions.activate()}
                >
                    {`${lang.BROWSE_TO_SELECT_OBJECTS}...`}
                </LinkButton>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} spaceFill={SPACE_FILL.all}>
                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                        <Icon src={protectedObjectsIconSrc} />
                        <Text>{`${lang.EXCLUDED_OBJECTS} (${wizardStore.excludedItems.length})`}</Text>
                    </StackView>

                    <ConfiguredGrid
                        api={api}
                        columns={[
                            {
                                id: 'name',
                                title: lang.NAME,
                                cell: rowData => rowData.inventoryObject.name,
                            },
                            {
                                id: 'type',
                                title: lang.TYPE,
                                cell: rowData => portalEnums.getEnumDescription('BackupServerVmwareInventoryType', rowData.inventoryObject.type),
                            },
                        ]}
                        data={fetchData}
                        disableAutoUpdate
                        selection={{
                            field: 'objectId',
                            checkbox: true,
                            multiple: true,
                        }}
                        toolbars={[
                            () => (
                                <BackupOptionsToolbar
                                    onRemove={handleDeleteItems}
                                    viewType={BackupOptionsTarget.ExcludedItems}
                                />
                            ),
                        ]}
                    />
                </StackView>
            </StackView>

            {itemsModal}
        </SidePanelForm>
    );
});
