/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { Vb365JobItemComposed } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import { groupTypeTitles, resourceItemTypesTitles, resourceUserTypesTitles } from '../../helpers';

export const ObjectTypeCell = ({ value }: { value: Vb365JobItemComposed; }) => {
    const lang = useGlobalLang<VspcLang>();

    if (value.itemType === Vb365JobItemComposedItemType.User) {
        return <TextColumn>{resourceUserTypesTitles(value.user.userType, lang)}</TextColumn>;
    }

    if (value.itemType === Vb365JobItemComposedItemType.Group) {
        return <TextColumn>{groupTypeTitles(value.group.groupType, lang)}</TextColumn>;
    }

    return <TextColumn>{resourceItemTypesTitles(value.itemType, lang)}</TextColumn>;
};
