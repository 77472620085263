/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import {
    DurationColumn,
    GridColumnJustify,
    GridSortDirections,
    TimeAgoColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useModal,
} from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { useLocation } from 'react-router-dom';

import type { BackupServerFileJobFilter, BackupServerFileJobModel } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type {
    RequestParams,
    GridColumnProps,
    GridStore } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import {
    BackupFileJobStatusColumn,
    FileServerColumn,
    FileSharesLocalActionToolbar,
    FileSharesLocalFilterToolbar,
} from './components';
import { ConfigSectionIds } from 'core/enums';
import { AssignCompanyDialog } from 'views/components/AssignCompanyDialog/AssignCompanyDialog';


export const FileSharesLocal: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { pathname } = useLocation();
    const { transportService } = useGlobalServices();
    const gridApi = useRef<GridStore<BackupServerFileJobModel, unknown, BackupServerFileJobFilter>>();

    const [assignCompanyDialog, assignCompanyDialogActions] = useModal({
        render: ({ deactivate }) => (
            <AssignCompanyDialog
                method='/backupserverjob/assignfilejobtocompany'
                gridApi={gridApi.current}
                deactivate={deactivate}
            />
        ),
    });

    const gridStateId = `${pathname}.FileSharesLocal`;

    const columns: GridColumnProps<BackupServerFileJobModel>[] = [
        {
            id: 'statusName',
            title: lang.JOB_STATUS,
            cell: rowData => (
                <BackupFileJobStatusColumn
                    status={rowData.status}
                    statusName={rowData.statusName}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            id: 'name',
            title: lang.JOB,
            cell: rowData => rowData.name,
        },
        {
            id: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: rowData => rowData.backupServerName,
        },
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            hidden: true,
        },
        {
            id: 'locationName',
            title: lang.LOCATION,
            cell: rowData => rowData.locationName,
            hidden: true,
        },
        {
            id: 'fileServers',
            title: lang.SERVER,
            cell: rowData => (
                <FileServerColumn
                    value={rowData.fileServers}
                    jobType={rowData.jobType}
                    name={rowData.name}
                    instanceUid={rowData.instanceUid}
                    stateId={`${pathname}.Dialogs.FileServers`}
                />
            ),
        },
        {
            id: 'jobTypeName',
            title: lang.JOB_TYPE,
            cell: rowData => rowData.jobTypeName,
        },
        {
            id: 'sourceAmountOfData',
            title: lang.SOURCE_SIZE,
            cell: rowData => (
                <RestorePointSizeColumn value={rowData.sourceAmountOfData} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'backedUpFilesCount',
            title: lang.BACKED_UP_FILES,
            cell: rowData => (
                <FileServerColumn
                    value={rowData.backedUpFiles}
                    jobType={rowData.jobType}
                    name={rowData.name}
                    instanceUid={rowData.instanceUid}
                    stateId={`${pathname}.Dialogs.BackupUpFiles`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'destination',
            title: lang.DESTINATION,
            cell: rowData => rowData.destination,
        },
        {
            id: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: rowData => (
                <TimeAgoColumn
                    lang={lang}
                    value={rowData.lastSessionStartTime}
                />
            ),
        },
        {
            id: 'longTermDestination',
            title: lang.ARCHIVE_REPOSITORY,
            cell: rowData => rowData.longTermDestination,
            hidden: true,
        },
        {
            id: 'lastSessionDuration',
            title: lang.DURATION,
            cell: rowData => (
                <DurationColumn
                    value={rowData.lastSessionDuration}
                />
            ),
            hidden: true,
        },
        {
            id: 'avgDuration',
            title: lang.AVG_DURATION,
            cell: rowData => (
                <DurationColumn
                    value={rowData.avgDuration}
                />
            ),
            hidden: true,
        },
        {
            id: 'processingRate',
            title: lang.PROCESSING_RATE,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.processingRate}
                    unitName={'B/s'}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: rowData => (
                <RestorePointSizeColumn
                    value={rowData.transferred}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            id: 'scheduleState',
            title: lang.SCHEDULE,
            cell: rowData => rowData.scheduleState,
            hidden: true,
        },
        {
            id: 'bottleneck',
            title: lang.BOTTLENECK,
            cell: rowData => rowData.bottleneck,
            hidden: true,
        },
    ];

    return (
        <>
            <ConfiguredGrid
                api={gridApi}
                stateId={gridStateId}
                sectionId={ConfigSectionIds.BackupJobsFileSharesLocal}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        key: 'status',
                        direction: GridSortDirections.Asc,
                    },
                }}
                columns={columns}
                data={(requestParams: RequestParams<BackupServerFileJobFilter>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<BackupServerFileJobModel[]>>('/BackupServerJob/GetFileJobs', {
                        ...requestParams,
                    })}
                isRowInactive={rowData => !rowData.scheduleEnabled}
                toggleable={true}
                selection={{
                    field: 'instanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <FileSharesLocalFilterToolbar />,
                    () => (
                        <FileSharesLocalActionToolbar
                            assignCompanyDialogActions={assignCompanyDialogActions}
                            idProperty={'instanceUid'}
                            selectedOnly
                        />
                    ),
                ]}
            />

            {assignCompanyDialog}
        </>
    );
};
