/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { EActionResultStatus, VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';
import { useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { PageWizard } from 'components/wizards/PageWizard';
import {
    getAccountStep,
    getDeploymentScheduleStep,
    getDistributionStep,
    getInstallationParametersStep,
    getLicenseStep,
    getPulseLicenseStep,
    getSummaryStep,
    getTenantInformationStep,
} from './components';
import { VbrDeploySettingsWizardModel } from './entries';
import { VbrDeployType } from './enums';

import type { VbrWizardProps } from './interfaces';

export const InstallAndUpdateVBRWizard: React.FC<VbrWizardProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const appServices = useGlobalServices();
    const { notificationService, transportService } = appServices;
    const model = new VbrDeploySettingsWizardModel(props);
    const isVbrDeploy = props.vbrDeployType === VbrDeployType.Install;
    const isLicenseFromPulse = model.licenseSource === VbrDeploymentLicenseSourceType.Pulse;
    const deploymentStepTitle = lang.SCHEDULE;

    const isMultiSelectMode = props.vbrLicenseParams.onlyThese.length > 1;
    const wizardTitle = isVbrDeploy ? lang.INSTALL_BACKUP_SERVER : lang.UPGRADE_BACKUP_SERVER;
    const wizardFullTitle = isMultiSelectMode ? wizardTitle : `${wizardTitle} - ${props.hostName}`;

    if (!model.isPulseConfigured && isLicenseFromPulse) {
        model.licenseSource = VbrDeploymentLicenseSourceType.LicenseFile;
    }

    return (
        <PageWizard
            title={wizardFullTitle}
            deactivate={() => props?.deactivate()}
            isEdit={false}
            data={model}
            steps={[
                getDistributionStep(lang),
                getAccountStep(lang),
                getInstallationParametersStep(lang, notificationService),
                getLicenseStep(lang, notificationService),
                getPulseLicenseStep(lang.VCSP_PULSE),
                getTenantInformationStep(lang.COMPANY_INFO, lang, appServices),
                getDeploymentScheduleStep(lang, deploymentStepTitle),
                getSummaryStep(lang.SUMMARY),
            ]}
            onFinish={async({ data }) => {
                const url = isVbrDeploy ? '/DiscoveryRule/DeployBackupServer' : '/BackupServer/Upgrade';

                return transportService.request(url, {
                    ...props.vbrLicenseParams,
                    deploySettings: await data.serialize(),
                })
                    .then(resp => notificationService.multi(wizardTitle, resp as RequestSuccessResponse<any>)
                        .then((res) => {
                            if (res.status !== EActionResultStatus.Error || !isVbrDeploy) {
                                props.onFinish();
                            } else {
                                return Promise.reject();
                            }
                        })
                    );
            }}
        />
    );
};
