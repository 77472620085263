/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BackupServerCredentialsLinuxInput } from '@veeam-vspc/models/rest';

import type { LinuxCredsFormData } from '../interfaces';

export const getCleanedFormData = (formData: LinuxCredsFormData): BackupServerCredentialsLinuxInput => {
    const clonedData = { ...formData };

    delete clonedData.usePrivateKey;

    if (!clonedData.autoElevated) {
        clonedData.addToSudoers = false;
        clonedData.useSu = false;
        clonedData.rootPassword = '';
    }

    return clonedData as BackupServerCredentialsLinuxInput;
};
