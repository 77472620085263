/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { WizardSummary, CheckboxKit, useGlobalLang } from '@veeam-vspc/shared/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import { ManagedCompaniesMode } from '../../enums';
import { UserMfaStatuses } from '../../../Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { ROLES } from 'core/const';

import type { SPUserModel } from '../../interfaces';

export const Summary = ({ data, onDataChange }: WizardStepData<SPUserModel>) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${lang.USER_INFO}:`,
                        fields: [
                            {
                                label: `${lang.FIRST_NAME}:`,
                                value: data.firstName,
                            },
                            {
                                label: `${lang.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${lang.EMAIL_ADDRESS}:`,
                                value: data.email,
                            },
                            {
                                label: `${lang.USERNAME}:`,
                                value: data.login,
                            },
                        ],
                    },
                    {
                        title: `${lang.ROLE}:`,
                        fields: [
                            {
                                label: `${lang.ROLE}:`,
                                value: data.userRole,
                                transform: (value: UserRoleRepresentation) => ROLES[value],
                            },
                        ],
                    },
                    (data.userRole === UserRoleRepresentation.ResellerUser || data.userRole === UserRoleRepresentation.ResellerOperator) && {
                        title: `${lang.COMPANIES}:`,
                        fields: [
                            {
                                label: `${lang.MANAGED_COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                                    ? data.companies.map(company => company.name).join(', ')
                                    : lang.ALL,
                            },
                        ],
                    },
                    {
                        title: `${lang.MULTI_FACTOR_AUTHENTICATION}:`,
                        fields: [
                            {
                                label: `${lang.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus,
                                transform: (value: UserMfaStatuses) => value === UserMfaStatuses.Disabled ? lang.NOT_ENFORCED : lang.ENFORCED,
                            },
                        ],
                    },
                    {
                        renderBody: data.userRole === UserRoleRepresentation.ResellerFinance && (
                            <CheckboxKit
                                checked={data.sendInvoice}
                                onChange={(isChecked) => {
                                    data.sendInvoice = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {lang.ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES}
                            </CheckboxKit>
                        ),
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    render: data => <Summary {...data} />,
});
