/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    toolbarItemDecorators,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
    useModal,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { capitalize, processDecoratorsForDisabledByPriority } from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';

import type { NotificationResponse } from '@veeam-vspc/shared/services';
import type { BackupServerCredentialsLinuxInput, BackupServerCredentialsRecord, BackupServerCredentialsStandardInput } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import removeIcon from 'images/actions/remove.svg';
import addIcon from 'images/actions/add.svg';
import { useJobWizardStore } from '../../../../../../stores';
import { MicrosoftWindowsCredentialsDialog } from '../../../MicrosoftWindowsCredentialsDialog/MicrosoftWindowsCredentialsDialog';
import { LinuxCredentialsDialog } from '../../../LinuxCredentialsDialog/LinuxCredentialsDialog';

export const CredentialsPanelToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<BackupServerCredentialsRecord, unknown, unknown>();
    const wizardStore = useJobWizardStore();
    const { notificationService } = useGlobalServices();

    const [windowsCredentialsModal, windowsCredentialsModalActions] = useModal<BackupServerCredentialsStandardInput>({
        render: ({ deactivate }) => (
            <MicrosoftWindowsCredentialsDialog
                onConfirm={newData => wizardStore
                    .createWindowsCredentials(newData)
                    .then(() => {
                        gridApi.reloadGrid();
                        deactivate();
                    })}
                deactivate={deactivate}
            />
        ),
    });

    const [linuxCredentialsModal, linuxCredentialsModalActions] = useModal<BackupServerCredentialsLinuxInput>({
        render: ({ deactivate }) => (
            <LinuxCredentialsDialog
                onConfirm={newData => wizardStore
                    .createLinuxCredentials(newData)
                    .then(() => {
                        gridApi.reloadGrid();
                        deactivate();
                    })}
                deactivate={deactivate}
            />
        ),
    });

    return (
        <>
            <ConfiguredToolbar
                view={TOOLBAR_VIEW_TYPE.default}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.group,
                        id: 'createCredentialsId',
                        text: lang.ADD,
                        iconSrc: addIcon,
                        items: [
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                text: lang.MICROSOFT_WINDOWS,
                                onClick: () => {
                                    windowsCredentialsModalActions.activate();
                                },
                            },
                            {
                                type: TOOLBAR_ITEM_TYPE.button,
                                text: lang.LINUX,
                                onClick: () => {
                                    linuxCredentialsModalActions.activate();
                                },
                            },
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: removeIcon,
                        text: lang.REMOVE,
                        onClick: () => {
                            const { onlyThese } = gridApi.requestParamsForActions;
                            const notificationTitle = capitalize(lang.REMOVE_CREDENTIALS);

                            if (onlyThese[0] === wizardStore.data.guestProcessing.guestCredentials?.credentialsId) {
                                notificationService.error(notificationTitle, lang.CANNOT_REMOVE_CREDENTIALS);

                                return;
                            }

                            notificationService.confirm(notificationTitle, lang.THIS_ACTION_WILL_REMOVE_SELECTED_CREDENTIALS)
                                .then((btnKey: NotificationResponse) => {
                                    if (btnKey === NotificationDialogsTextKeys.Yes) {
                                        wizardStore
                                            .deleteCredentials((onlyThese as string[])[0])
                                            .then(() => gridApi.reloadGrid());
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                toolbarItemDecorators.disallowWithoutSelection(),
                            ]),
                        ],
                    },
                ]}
            />

            {windowsCredentialsModal}
            {linuxCredentialsModal}
        </>
    );
};
