/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

interface AmountCurrencyColumnProps {
    amount: number | null;
    currency: string;
}

export const AmountCurrencyColumn: React.FC<AmountCurrencyColumnProps> = ({ currency, amount }) => (
    <TextColumn>{amount ? `${(amount / 100).toFixed(2)} ${currency}` : ColumnTexts.DashSymbol}</TextColumn>
);
