/**
 * Copyright © Veeam Software Group GmbH.
 */

import { parse } from 'markdown';

import { sanitize } from '../string-helpers';

const markdownToHtml = (v: string) => sanitize(parse(v));

export {
    markdownToHtml,
};
