/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { NotificationBarStoreContext } from '../store';

import type { NotificationBarStore } from '../store';

export const useNotificationBar = (): NotificationBarStore => useContext(NotificationBarStoreContext);