/**
 * Copyright © Veeam Software Group GmbH.
 */

import { BackupServerMbaLicenseRepresentation, JobMode } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { SystemTypeRepresentations } from 'core/enums';

export const getOsName = (lang: VspcLang, value: SystemTypeRepresentations): string => {
    const osNames = {
        [SystemTypeRepresentations.Windows]: lang.WINDOWS,
        [SystemTypeRepresentations.Linux]: lang.LINUX,
        [SystemTypeRepresentations.Mac]: lang.MAC_OS,
    };

    return osNames[value];
};

export const getJobModeNames = (lang: VspcLang, value: JobMode): string => {
    const jobModeNames = {
        [JobMode.ManagedByBackupServer]: lang.MANAGED_BY_BACKUP_SERVER_JOB,
        [JobMode.ManagedByAgent]: lang.MANAGED_BY_AGENT,
    };

    return jobModeNames[value];
};

export const getLicenceTypeNames = (lang: VspcLang, value: BackupServerMbaLicenseRepresentation): string => {
    const licenceTypeNames = {
        [BackupServerMbaLicenseRepresentation.Unknown]: lang.NO_INFO,
        [BackupServerMbaLicenseRepresentation.Server]: lang.SERVER,
        [BackupServerMbaLicenseRepresentation.Workstation]: lang.WORKSTATION,
        [BackupServerMbaLicenseRepresentation.Limited]: lang.LIMITED,
    };

    return licenceTypeNames[value];
};
