/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { GridSortDirections } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { CompanyTypeRepresentation, UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { PortalUser } from '@veeam-vspc/shared/stores';
import type { RequestErrorResponse, RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { VspcLang } from 'configs/languages';

import { DataCache } from 'core/utils/data-cache';
import { MAX_INTEGER_32 } from 'core/const';
import {
    DefaultEmailOptions,
    JobType,
    ProtectedVmReportJobType,
    SystemTypeReportRepresentations,
    VirtualReportPlatformType,
    VmsPlatform,
} from '../enums';

import type { CompanyModel, Timezone } from '../interfaces';

interface EmailDefaultAndHint {
    roles: UserRoleRepresentation[];
    hintForIndividual: string[];
    hintForAggregated: string[];
    defaultIndividual: string;
    defaultAggregated: string;
}

export class ReportStore {

    @observable companies: number[] = [];

    @observable companyFilter = '';
    @observable locationFilter = '';

    @action.bound
    setCompanies(companies: number[]) {
        this.companies = companies;
    }

    systemTypeRepresentationSet: { id: SystemTypeReportRepresentations; name: string; }[];
    virtualReportPlatformTypeSet: { id: VirtualReportPlatformType; name: string; }[];
    protectedVmReportJobTypeSet: { id: ProtectedVmReportJobType; name: string; platform?: VmsPlatform[]; }[];
    jobTypeSet: { id: JobType; name: string; }[];

    isDefaultEmailOptions = true;
    emailDefaultAndHint: EmailDefaultAndHint[];

    constructor(
        private lang: VspcLang,
        private portalUser: PortalUser,
        private transportService: TransportService<RequestErrorResponse | Error>,
    ) {
        makeObservable(this);

        this.systemTypeRepresentationSet = [
            {
                id: SystemTypeReportRepresentations.Windows,
                name: lang.WINDOWS,
            }, {
                id: SystemTypeReportRepresentations.Linux,
                name: lang.LINUX,
            }, {
                id: SystemTypeReportRepresentations.Mac,
                name: lang.MAC_OS,
            },
        ];

        this.protectedVmReportJobTypeSet = [
            {
                id: ProtectedVmReportJobType.Backup,
                name: lang.BACKUP,
            }, {
                id: ProtectedVmReportJobType.Replication,
                name: lang.REPLICATION,
                platform: [VmsPlatform.VirtualInfrastructure],
            }, {
                id: ProtectedVmReportJobType.Archive,
                name: lang.ARCHIVE,
                platform: [VmsPlatform.PublicCloud],
            }, {
                id: ProtectedVmReportJobType.Snapshot,
                name: lang.SNAPSHOT,
                platform: [VmsPlatform.PublicCloud],
            }, {
                id: ProtectedVmReportJobType.RemoteSnapshot,
                name: lang.REPLICA_SNAPSHOT,
                platform: [VmsPlatform.PublicCloud],
            },
        ];

        this.virtualReportPlatformTypeSet = [
            {
                id: VirtualReportPlatformType.VSphere,
                name: lang.VSPHERE,
            }, {
                id: VirtualReportPlatformType.HyperV,
                name: lang.HYPER_V,
            }, {
                id: VirtualReportPlatformType.NutanixAHV,
                name: lang.AHV,
            },
        ];

        this.jobTypeSet = [
            {
                id: JobType.Backup,
                name: lang.BACKUP,
            }, {
                id: JobType.Archive,
                name: lang.ARCHIVE,
            }, {
                id: JobType.Snapshot,
                name: lang.SNAPSHOT,
            }, {
                id: JobType.RemoteSnapshot,
                name: capitalize(lang.REPLICA_SNAPSHOT),
            },
        ];

        this.emailDefaultAndHint = [{
            roles: [UserRoleRepresentation.VacAdministrator],
            hintForIndividual: [
                lang.THE_FOLLOWING_VARIABLES_FOR_PORTAL_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_OPERATORS,
                lang.THE_FOLLOWING_VARIABLES_FOR_READONLY_USERS,
                lang.TO_SEND_A_NOTIFICATION_TO_EMAIL_ADDRESS_SPECIFIED,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_EMAIL,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_USERS,
            ],
            hintForAggregated: [
                lang.THE_FOLLOWING_VARIABLES_FOR_PORTAL_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_OPERATORS,
                lang.THE_FOLLOWING_VARIABLES_FOR_READONLY_USERS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_EMAIL,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
            ],
            defaultIndividual: DefaultEmailOptions.CompanyOwner,
            defaultAggregated: DefaultEmailOptions.PortalAdmins,
        }, {
            roles: [
                UserRoleRepresentation.VacOperator,
                UserRoleRepresentation.CloudConnectAdministrator,
            ],
            hintForIndividual: [
                lang.TO_SEND_A_NOTIFICATION_TO_EMAIL_ADDRESS_SPECIFIED,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
            ],
            hintForAggregated: [],
            defaultIndividual: DefaultEmailOptions.CompanyOwner,
            defaultAggregated: DefaultEmailOptions.Empty,
        }, {
            roles: [
                UserRoleRepresentation.CompanyOwner,
                UserRoleRepresentation.CompanyAdministrator,
            ],
            hintForIndividual: [
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
            ],
            hintForAggregated: [],
            defaultIndividual: DefaultEmailOptions.CompanyOwner,
            defaultAggregated: DefaultEmailOptions.Empty,
        }, {
            roles: [UserRoleRepresentation.CompanyLocationAdministrator],
            hintForIndividual: [
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
            ],
            hintForAggregated: [],
            defaultIndividual: DefaultEmailOptions.LocationAdmins,
            defaultAggregated: DefaultEmailOptions.Empty,
        }, {
            roles: [
                UserRoleRepresentation.ResellerOwner,
                UserRoleRepresentation.ResellerAdministrator,
            ],
            hintForIndividual: [
                lang.TO_SEND_A_NOTIFICATION_TO_EMAIL_ADDRESS_SPECIFIED,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_EMAIL,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_USERS,
            ],
            hintForAggregated: [
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
            ],
            defaultIndividual: DefaultEmailOptions.CompanyOwner,
            defaultAggregated: DefaultEmailOptions.Reseller,
        }, {
            roles: [UserRoleRepresentation.ResellerOperator],
            hintForIndividual: [
                lang.TO_SEND_A_NOTIFICATION_TO_EMAIL_ADDRESS_SPECIFIED,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_OWNER,
                lang.THE_FOLLOWING_VARIABLES_FOR_COMPANY_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_LOCATION_ADMINS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLERS_USERS,
            ],
            hintForAggregated: [
                lang.THE_FOLLOWING_VARIABLES_FOR_RESELLER_OPERATORS,
            ],
            defaultIndividual: DefaultEmailOptions.CompanyOwner,
            defaultAggregated: DefaultEmailOptions.ResellerOperators,
        }];
    }

    readonly companiesListCache = new DataCache<CompanyModel[], void>(
        () => this.transportService.request('/Company/GetCompanyList', {
            filter: { excludeSystem: false },
            sort: [{
                direction: GridSortDirections.Asc,
                property: 'name',
            }],
            limit: MAX_INTEGER_32,
            page: 1,
            start: 0,
        }).then((resp: RequestSuccessResponse<CompanyModel[]>) => resp.data)
    );
    readonly timezoneListCache = new DataCache<Timezone[], void>(
        () => this.transportService.request('/Report/GetTimeZones', {
            limit: MAX_INTEGER_32,
            page: 1,
            start: 0,
        }).then((resp: RequestSuccessResponse<Timezone[]>) => resp.data)
    );

    getVmReportJobTypesByPlatform(platform: VmsPlatform): { id: ProtectedVmReportJobType; name: string; }[] {
        if (platform === VmsPlatform.All) {
            return this.protectedVmReportJobTypeSet;
        }

        return this.protectedVmReportJobTypeSet
            .filter(jobType => !jobType.platform || jobType.platform.includes(platform));
    }

    getScheduleHint(isAggregated: boolean): string {
        let result = '';

        this.emailDefaultAndHint.forEach((emailInfo) => {
            if (result) {
                return;
            }

            if (emailInfo.roles.some(role => role === this.portalUser.userRole)) {
                if (isAggregated) {
                    result = emailInfo.hintForAggregated.join('');
                } else {
                    result = emailInfo.hintForIndividual.join('');
                }
            }
        });

        if (!result) {
            return null;
        }

        return this.lang.USE_THE_FOLLOWING_VARIABLES + result;
    }

    getDefaultEmail(isAggregated: boolean): string {
        let result: string = DefaultEmailOptions.Empty;

        this.emailDefaultAndHint.forEach((emailInfo) => {
            if (result) {
                return;
            }

            if (emailInfo.roles.some(role => role === this.portalUser.userRole)) {
                if (isAggregated) {
                    result = emailInfo.defaultAggregated;
                } else {
                    if (this.companies && this.companies.length && this.companiesListCache.value) {
                        const chosenCompanies = this.companiesListCache.value.filter(company => this.companies.includes(company.id));

                        if (chosenCompanies.every(company => company.type === CompanyTypeRepresentation.Provider)) {
                            result = DefaultEmailOptions.PortalAdmins;
                        } else if (chosenCompanies.every(company => company.type === CompanyTypeRepresentation.Reseller)) {
                            result = DefaultEmailOptions.ResellerAdmins;
                        } else if (chosenCompanies.every(company => company.type === CompanyTypeRepresentation.Tenant)) {
                            result = emailInfo.defaultIndividual;
                        } else {
                            result = DefaultEmailOptions.Empty;
                        }
                    } else {
                        result = emailInfo.defaultIndividual;
                    }
                }
            }
        });

        return result;
    }
}
