/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
    toolbarItemDecorators,
    ConfiguredToolbar,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React from 'react';
import { InvoicePaidState } from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { disableForSelectionWithPropValues, processDecoratorsForDisabledByPriority } from '@veeam-vspc/shared/helpers';

import type { GeneratedInvoiceModel, InvoiceParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { useAsyncAction } from 'views/hooks';
import markAsPaidSrc from 'images/actions/mark-as-paid.svg';
import markAsUnpaidSrc from 'images/actions/mark-as-unpaid.svg';
import sendEmailSrc from 'images/actions/resend-email.png';
import removeSrc from 'images/actions/remove.svg';
import { ConfigSectionIds } from 'core/enums';

export const AllInvoicesActionToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const { notificationService } = useGlobalServices();
    const gridApi = useGridApi<GeneratedInvoiceModel, unknown, InvoiceParam['filter']>();

    const markAs = (status: InvoicePaidState) => {
        const { selected } = gridApi;
        const ids = selected.map(({ id }) => id);

        doAsyncAction('/Invoice/SetInvoiceStatus', lang.INVOICE, { ids, status });
    };

    const resend = () => {
        const { selected } = gridApi;
        const ids = selected.map(({ id }) => id);

        doAsyncAction('/Invoice/ResendInvoice', lang.SEND_INVOICE, { ids });
    };

    const remove = () => {
        const { selected } = gridApi;
        const ids = selected.map(({ id }) => id);

        notificationService
            .confirm(lang.DELETE_INVOICE, lang.THIS_ACTION_WILL_DELETE_INVOICES)
            .then((key) => {
                if (key === NotificationDialogsTextKeys.Yes) {
                    doAsyncAction('/Invoice/DeleteInvoice', lang.REMOVE, { ids });
                }
            });
    };

    const actions = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.MARK_AS_PAID,
            iconSrc: markAsPaidSrc,
            onClick: () => markAs(InvoicePaidState.Paid),
            decorators: [
                processDecoratorsForDisabledByPriority([
                    disableForSelectionWithPropValues('statusId', [
                        InvoicePaidState.Unknown,
                        InvoicePaidState.Paid,
                        InvoicePaidState.NotInvoice,
                    ]),
                    toolbarItemDecorators.disallowWithoutSelection(),
                ]),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.MARK_AS_UNPAID,
            iconSrc: markAsUnpaidSrc,
            onClick: () => markAs(InvoicePaidState.Unpaid),
            decorators: [
                processDecoratorsForDisabledByPriority([
                    disableForSelectionWithPropValues('statusId', [
                        InvoicePaidState.Unknown,
                        InvoicePaidState.Unpaid,
                        InvoicePaidState.NotInvoice,
                        InvoicePaidState.Overdue,
                    ]),
                    toolbarItemDecorators.disallowWithoutSelection(),
                ]),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.separator,
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.SEND,
            iconSrc: sendEmailSrc,
            onClick: resend,
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.separator,
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            text: lang.REMOVE,
            iconSrc: removeSrc,
            onClick: remove,
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
    ];

    return (
        <>
            <ConfiguredToolbar
                sectionId={ConfigSectionIds.InvoicesAllInvoices}
                view={TOOLBAR_VIEW_TYPE.default}
                items={actions}
            />

            {asyncActionLoader}
        </>
    );
};
