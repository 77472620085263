/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/shared/components';
import { isPositiveNumber } from '@veeam-vspc/shared/helpers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { PublicCloudsDialog } from './components';

import type { PublicCloudsDialogProps } from './components';

export interface PublicCloudsColumnProps extends Omit<PublicCloudsDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const PublicCloudsColumn: React.FC<PublicCloudsColumnProps> = ({ value, onClose, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderPageDialog={({ onClose: onClosePageDialog }) => (
            <PublicCloudsDialog
                {...restProps}
                onClose={(...args) => {
                    onClosePageDialog(...args);
                    onClose?.(...args);
                }}
            />
        )}
    />
);

