/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useRef, useState } from 'react';
import { useGlobalAddons, useGlobalContext } from '@veeam-vspc/shared/components';
import { reaction } from 'mobx';
import { canCancelInterval } from '@veeam-vspc/shared/core-modules';

import type { TransportRequestOptions } from '@veeam-vspc/shared/core';

import { useTransportRequest } from '../use-transport-requests';

import type { UseRequestsData } from '../use-transport-requests';

export const useIntervalRequest = <T, R>(
    url: string,
    data?: T,
    options?: Partial<TransportRequestOptions>,
    timeout = 60000,
    useGlobalFilters = true,
): UseRequestsData<R> => {
    const { appState } = useGlobalContext();
    const abortController = useRef(new AbortController());
    const [isAutoUpdate, setAutoUpdate] = useState(false);
    const request = useTransportRequest<T, R>(url, isAutoUpdate ? { ...data, rcopAutoUpdate: true } : data, { ...options, requestInit: {
        signal: abortController.current.signal,
    } });
    const { portalFilters } = useGlobalAddons();
    const intervalIdRef = useRef<number | null>(null);
    const stopIntervalTimer = () => {
        if (intervalIdRef.current !== null) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    useEffect(() => {
        intervalIdRef.current = window.setInterval(() => {
            setAutoUpdate(true);

            request.forceRequest();
        }, timeout);

        return stopIntervalTimer;
    }, []);

    useEffect(() => {
        if (canCancelInterval(appState)) {
            stopIntervalTimer();
        }
    }, [appState]);

    useEffect(() => {
        if (portalFilters) {
            return reaction(
                () => [portalFilters.locationFilter, portalFilters.siteFilter.join(','), portalFilters.organizationFilter],
                () => {
                    if (useGlobalFilters) {
                        abortController.current?.abort();
                        abortController.current = new AbortController();

                        setAutoUpdate(false);

                        request.forceRequest();
                    }
                },
            );
        }
    }, [portalFilters, useGlobalFilters]);

    return request;
};
