/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
    PortalSpinner,
} from '@veeam-vspc/shared/components';
import {
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/shared/helpers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { JobStateRepresentation, JobTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { BackupServerFileJobFilter, BackupServerFileJobModel } from '@veeam-vspc/models/web-controllers';
import type { ToolbarWithExportProps, UseModalActionsProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import retryActionIconSrc from 'images/actions/retry.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import { ConfigSectionIds } from 'core/enums';
import { useAsyncAction } from 'views/hooks';
import assignToCompanyIcon from 'images/actions/assign-to-company.svg';
import { waitFor } from 'core/utils';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';

export interface FileSharesLocalActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
    assignCompanyDialogActions: UseModalActionsProps;
}

export const FileSharesLocalActionToolbar: React.FC<FileSharesLocalActionToolbarProps> = (props) => {
    const { method = 'GetFileJobs', assignCompanyDialogActions, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const gridApi = useGridApi<BackupServerFileJobModel, unknown, BackupServerFileJobFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [loading, setLoading] = useState<boolean>(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                lang={lang}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsFileSharesLocal}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupServerJob/StartFileJob', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Idle,
                                    JobStateRepresentation.Running,
                                    JobStateRepresentation.Starting,
                                    JobStateRepresentation.Stopping,
                                    JobStateRepresentation.Deleting,
                                    JobStateRepresentation.Enabling,
                                    JobStateRepresentation.Disabling,
                                    JobStateRepresentation.WaitingTape,
                                    JobStateRepresentation.WaitingRepository,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => {
                            const { selected } = gridApi;
                            const doAction = (gracefully: boolean) => doAsyncAction('/BackupServerJob/StopFileJob', lang.STOP, {
                                ...gridApi.requestParamsForActions,
                                gracefully,
                            });
                            const skipGracefullyFor = [
                                JobTypeRepresentation.SureBackup,
                                JobTypeRepresentation.FileTapeBackup,
                                JobTypeRepresentation.VmTapeBackup,
                            ];

                            if (selected.every(item => skipGracefullyFor.includes(item.jobType))) {
                                return doAction(false);
                            }

                            return notificationService.info(lang.STOP_JOB, lang.WE_CAN_STOP_THIS_JOB_GRACEFULLY, {
                                buttons: [
                                    {
                                        type: NotificationDialogsTextKeys.Ok,
                                        text: lang.GRACEFULLY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Yes,
                                        text: lang.IMMEDIATELY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Cancel,
                                        text: lang.CANCEL,
                                        view: ACTION_VIEW.secondary,
                                    },
                                ],
                            })
                                .then((btn) => {
                                    if (btn === NotificationDialogsTextKeys.Ok || btn === NotificationDialogsTextKeys.Yes) {
                                        return doAction(btn === NotificationDialogsTextKeys.Ok);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('status', value => value !== JobStateRepresentation.Running),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: retryActionIconSrc,
                        text: lang.RETRY,
                        onClick: () => doAsyncAction('/BackupServerJob/RetryFileJob', lang.RETRY, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypeRepresentation.FileCopy,
                                    JobTypeRepresentation.FileTapeBackup,
                                ]),
                                disableForSelectionWithPropValueCallback('status', value => value !== JobStateRepresentation.Failed),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableFileJob', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleState', value => value !== lang.DISABLED),
                            ]),
                            disableForSelectionWithPropValues('status', [
                                JobStateRepresentation.Enabling,
                                JobStateRepresentation.Disabling,
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableFileJob', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleState', value => (
                                    [lang.DISABLED, lang.NOT_SCHEDULED].includes(value as string)
                                )),
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Enabling,
                                    JobStateRepresentation.Disabling,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                            setLoading(true);

                            promise
                                .then(() => {
                                    setLoading(false);

                                    Ext.create('RCOP.utils.DownloadLogsManager', {
                                        title: lang.DOWNLOAD_LOGS,
                                        url: '/BackupServerJob/DownloadFileJobLogs',
                                        params: gridApi.requestParamsForActions,
                                        showDialog: true,
                                    }).start();
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        },
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                        id: 'assignToCompanySeparator',
                    },
                    {
                        id: 'assignToCompany',
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: assignToCompanyIcon,
                        text: lang.ASSIGN_TO_COMPANY,
                        onClick() {
                            assignCompanyDialogActions.activate();
                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSelection(),
                        ],
                    },
                ]}
            />

            {asyncActionLoader}
            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
};
