/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    toolbarItemDecorators,
    TOOLBAR_ITEM_TYPE,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';

import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

import flrActionIconSrc from 'images/actions/flr.png';
import { alternateTransformRequestParams } from 'core/utils';

export const VBObjectsActionToolbar: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const gridApi = useGridApi<Vb365ProtectedObject, any, any>();
    const { notificationService } = useGlobalServices();

    return (
        <ToolbarWithExport
            lang={lang}
            RESTMethod='protectedWorkloads/vb365ProtectedObjects/'
            extraParams={alternateTransformRequestParams(gridApi.requestParams)}
            selectedOnly
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: flrActionIconSrc,
                    text: `${lang.RESTORE_PORTAL}...`,
                    onClick: () => {
                        const { fileRestorePortalUrl, isFileRestorePortalEnabled } = gridApi.selected[0];

                        if (!isFileRestorePortalEnabled || !fileRestorePortalUrl) {
                            return notificationService.warning(lang.WARNING, `${lang.THE_RESTORE_PORTAL_IS_NOT_CONFIGURED}.`);
                        }

                        window.open(fileRestorePortalUrl, '_blank', 'noopener,noreferrer');
                    },
                    decorators: [
                        toolbarItemDecorators.disallowWithoutSingleSelection(),
                    ],
                },
            ]}
        />
    );
};
