/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';

import type { AppStorage } from '@veeam-vspc/shared/helpers';
import type { TransportService } from '@veeam-vspc/shared/core';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import { UsageReportsService } from '../services';
import { UsageReportsData } from '../interfaces';

import type { ComboboxItem } from 'core/interfaces';

export class UsageReportsStore {

    @observable categories: string[];
    @observable empty: boolean;
    @observable loading: boolean;
    @observable series: [];
    @observable seriesByProduct: [];
    @observable workload: string;
    @observable workloads: ComboboxItem[];

    data: UsageReportsData;

    constructor(
        protected transportService: TransportService<RequestErrorResponse | Error>,
        private appStorage: AppStorage,
        readonly workloadStateId: string,
    ) {
        makeObservable(this);
    }

    @action
    fetchData(params): void {
        this.setLoading(true);

        UsageReportsService
            .fetchData(this.transportService, params)
            .then((data) => {
                this.setCategories(data.categories);
                this.setData(data);
                this.setEmpty(data.empty);
                this.setLoading(false);
                this.setSeries(data.series);
                this.setWorkloads(data.workloads);
            })
            .catch(() => {
                this.setLoading(false);
            });
    }

    @action.bound
    setCategories(categories: string[]): void {
        this.categories = categories;
    }

    @action.bound
    setData(data): void {
        this.data = data;
    }

    @action.bound
    setEmpty(empty: boolean): void {
        this.empty = empty;
    }

    @action.bound
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action.bound
    setSeries(series?): void {
        this.series = series ? series[this.workload] : this.data?.series[this.workload];
    }

    @action.bound
    setWorkload(w): void {
        this.workload = w;
        this.appStorage.setItem(this.workloadStateId, w);
    }

    @action.bound
    setWorkloads(w): void {
        this.workloads = w;
    }

}
