/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { FieldLayout, Label, useGlobalLang } from '@veeam-vspc/shared/components';
import { NotificationAlarmsSettingsDailyStatusFilter } from '@veeam-vspc/models/rest';

import type { VspcLang } from 'configs/languages';

import { StatusCheckboxes } from '../StatusCheckboxes';
import { useAdvancedSettingsStore } from '../../stores';
import successIcon from 'images/statuses/success.svg';
import infoIcon from 'images/statuses/info.svg';
import warningIcon from 'images/statuses/warning.svg';
import errorIcon from 'images/statuses/error.svg';
import acknowledgeIcon from 'images/statuses/acknowledge.svg';

export const StatusFilterGroup = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const { dailyStatusFilter } = useAdvancedSettingsStore();
    const statusFilterEnum = NotificationAlarmsSettingsDailyStatusFilter;

    return (
        <>
            <FieldLayout>
                <Label>{`${lang.SELECT_ALARM_SEVERITY}:`}</Label>
            </FieldLayout>

            <StatusCheckboxes
                items={[
                    {
                        checked: dailyStatusFilter.has(statusFilterEnum.Resolved),
                        enumValue: statusFilterEnum.Resolved,
                        icon: successIcon,
                        label: lang.RESOLVED,
                    },
                    {
                        checked: dailyStatusFilter.has(statusFilterEnum.Info),
                        enumValue: statusFilterEnum.Info,
                        icon: infoIcon,
                        label: lang.INFO,
                    },
                    {
                        checked: dailyStatusFilter.has(statusFilterEnum.Warning),
                        enumValue: statusFilterEnum.Warning,
                        icon: warningIcon,
                        label: lang.WARNING,
                    },
                    {
                        checked: dailyStatusFilter.has(statusFilterEnum.Error),
                        enumValue: statusFilterEnum.Error,
                        icon: errorIcon,
                        label: lang.ERROR,
                    },
                    {
                        checked: dailyStatusFilter.has(statusFilterEnum.Acknowledged),
                        enumValue: statusFilterEnum.Acknowledged,
                        icon: acknowledgeIcon,
                        label: lang.ACKNOWLEDGED,
                    },
                ]}
            />
        </>
    );
});
