/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    AppBar,
    Link,
    LINK_TARGET,
    SIZE_M,
    Spinner,
    Text,
    TitleH1,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useCallback, useEffect, useState } from 'react';
import { formatStr } from '@veeam-vspc/shared/helpers';

import type { GetRestApiVersionResult } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import logoVeeam from 'images/logo.png';
import { ConfigIcon, Container, LinkItem, Links, OpenApiIcon, Separator, SwaggerIcon, UserGuideIcon, VeeamForumsIcon } from './styles';
import { HREFS } from './enums';

export const ApiPage: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();

    const [actualRestVersion, setActualRestVersion] = useState('');
    const [helpCenterLinkVersionIdentifier, setHelpCenterLinkVersionIdentifier] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getRestApiVersion = useCallback(() => {
        setIsLoading(true);
        transportService.request('/common/restapiversion', {}, { noAuth: true })
            .then((data: { data: GetRestApiVersionResult; }) => {
                const responseData = data?.data ?? {};
                const { actualRestVersion, helpCenterLinkVersionIdentifier } = responseData;
                setActualRestVersion(actualRestVersion);
                setHelpCenterLinkVersionIdentifier(helpCenterLinkVersionIdentifier);
            })
            .finally(() => setIsLoading(false));

    }, []);

    useEffect(() => {
        getRestApiVersion();
    }, [getRestApiVersion]);

    return (
        <>
            <AppBar logo={logoVeeam} />

            <Container isLoading={isLoading}>
                { isLoading ? <Spinner /> : (
                    <>
                        <ConfigIcon />
                        <TitleH1>{formatStr(lang.WELCOME_TO_REST_API, actualRestVersion || '')}</TitleH1>
                        <Text>{lang.YOU_HAVE_REACHED_THE_VEEAM_SERVICE_PROVIDER_CONSOLE_APIS}</Text>
                        <Separator />
                        <Text style={{ marginBottom: SIZE_M }}>{lang.WHERE_TO_GO_FROM_HERE}</Text>

                        <Links>
                            <LinkItem>
                                <SwaggerIcon />
                                <Link href={HREFS.Swagger}>{lang.SWAGGER_UI}</Link>
                            </LinkItem>

                            <LinkItem>
                                <OpenApiIcon />

                                <Link
                                    disabled={!actualRestVersion}
                                    href={formatStr(HREFS.OpenApiSpecification, actualRestVersion)}
                                >
                                    {lang.OPEN_API_SPECIFICATION}
                                </Link>
                            </LinkItem>

                            <LinkItem>
                                <UserGuideIcon />

                                <Link
                                    target={LINK_TARGET.blank}
                                    disabled={!helpCenterLinkVersionIdentifier}
                                    href={formatStr(HREFS.RestApiUserGuide, helpCenterLinkVersionIdentifier)}
                                >
                                    {lang.REST_API_USER_GUIDE}
                                </Link>
                            </LinkItem>

                            <LinkItem>
                                <VeeamForumsIcon />
                                <Link target={LINK_TARGET.blank} href={HREFS.RestApiVeeamForums}>{lang.REST_API_VEEAM_FORUMS}</Link>
                            </LinkItem>

                        </Links>
                    </>
                )}
            </Container>
        </>
    );
};
