/**
 * Copyright © Veeam Software Group GmbH.
 */

interface Props {
    formats?;
    lang;
    pointFixed: number;
    startUnit?: string;
    unitName?: string;
    value: number;
}

export const sizeToString = ({ formats, lang, pointFixed, startUnit, unitName, value }: Props) => {
    formats = formats || [];

    let startUnitIndex;

    const defFormats = [
        {
            value: null,
            text: lang.DASH_SYMBOL,
        }, {
            value: -1,
            text: lang.UNLIMITED,
        },
    ];

    defFormats.forEach((defFormatItem) => {
        const item = formats.find(formatItem => defFormatItem.value === formatItem.value);

        if (item == null) {
            formats.push(defFormatItem);
        }
    });

    const formatItem = formats.find(f => f.value === value);

    if (formatItem != null) {
        return formatItem.text;
    }


    if (value === 0) {
        return value;
    }

    // K - 2^10, M - 2^20, G - 2^30, T - 2^40, P - 2^50, E - 2^60, Z - 2^70, Y - 2^80
    const unitPrefixes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const unitSize = 1024;

    if (pointFixed === undefined) {
        pointFixed = 0;
    }

    if (startUnit === undefined) {
        startUnitIndex = 0;
    } else if (Ext.isNumber(startUnit)) {
        startUnitIndex = startUnit;
    } else {
        startUnitIndex = unitPrefixes.indexOf(startUnit.toUpperCase());
    }

    if (unitName === undefined) {
        unitName = 'B';
    }

    let i;

    for (i = startUnitIndex; i < unitPrefixes.length; i++) {
        if (value < unitSize) {
            break;
        }

        value /= unitSize;
    }

    return `${parseFloat(value.toFixed(pointFixed))} ${unitPrefixes[i]}${unitName}`;
};
