/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { deepCopy } from '@veeam-vspc/shared/core';
import { GridSortDirections, ConfiguredGrid, useGlobalAddons, useGlobalLang, useGlobalServices, PortalSpinner } from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { BackupAgentJobContainerFilter, BackupAgentJobContainerModel, BackupAgentParam } from '@veeam-vspc/models/web-controllers';
import type { GridColumnProps, GridStore } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { CustomNoteColumn } from 'views/components/columns/CustomNoteColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { UpdatesColumn } from 'views/components/columns/UpdatesColumn';
import {
    ManagedByConsoleActionToolbar,
    ManagedByConsoleFilterToolbar,
    RunningJobsColumn,
    SuccessfulJobsColumn,
} from './components';
import { ConfigSectionIds } from 'core/enums';
import { BaseBackupPolicyColumn } from 'views/components/columns/BaseBackupPolicyColumn';
import { waitFor } from 'core/utils';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';

export const ManagedByConsole: React.FC<{}> = () => {
    const { pathname } = useLocation();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const lang = useGlobalLang<VspcLang>();
    const api = useRef<GridStore<BackupAgentJobContainerModel, unknown, BackupAgentJobContainerFilter>>();
    const gridStateId = `${pathname}.ManagedByConsole`;
    const [loading, setLoading] = useState<boolean>(false);
    const columns: GridColumnProps<BackupAgentJobContainerModel>[] = [
        {
            id: 'company',
            title: lang.COMPANY,
            cell: rowData => rowData.company,
            defaultValue: lang.NO_INFO,
            getAdditionalSorters: currentSort => [{ ...currentSort, property: 'name' }],
        },
        {
            id: 'siteName',
            title: lang.SITE,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'location',
            title: lang.LOCATION,
            cell: rowData => rowData.location,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'name',
            title: lang.COMPUTER,
            cell: rowData => rowData.name,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'agentCustomNote',
            title: lang.TAG,
            cell: rowData => (
                <CustomNoteColumn
                    value={rowData.agentCustomNote}
                    agentUid={rowData.agentId}
                />
            ),
        },
        {
            id: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: rowData => rowData.operationModeRendered,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'guestOs',
            title: lang.GUEST_OS,
            cell: rowData => rowData.guestOs,
            defaultValue: lang.NO_INFO,
            hidden: true,
        },
        {
            id: 'jobsStatus',
            title: lang.SUCCESSFUL_JOBS,
            cell: rowData => (
                <SuccessfulJobsColumn
                    successJobsCount={rowData.successJobsCount}
                    totalJobsCount={rowData.totalJobsCount}
                    jobsStatus={rowData.jobsStatus}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepCopy(rowData);
                        portalEnums.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);
                        const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                        setLoading(true);

                        promise
                            .then(() => {
                                setLoading(false);
                                const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                                    record: {
                                        ...rowDataForExt,
                                        get: prop => rowDataForExt[prop],
                                    },
                                    extProps: {
                                        gridReload() {
                                            api.current?.reloadGrid();
                                        },
                                    },
                                });

                                dialog.open(() => {}, () => {
                                    api.current?.reloadGrid();
                                    (document.activeElement as HTMLElement)?.blur();
                                });
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    }}
                />
            ),
        },
        {
            id: 'runningJobsCount',
            title: lang.RUNNING_JOBS,
            cell: rowData => (
                <RunningJobsColumn
                    runningJobsCount={rowData.runningJobsCount}
                    totalJobsCount={rowData.totalJobsCount}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepCopy(rowData);
                        portalEnums.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);
                        const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                        setLoading(true);

                        promise
                            .then(() => {
                                setLoading(false);
                                const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                                    record: {
                                        ...rowDataForExt,
                                        get: prop => rowDataForExt[prop],
                                    },
                                    extProps: {
                                        gridReload() {
                                            api.current?.reloadGrid();
                                        },
                                    },
                                });

                                dialog.open(() => {}, () => {
                                    api.current?.reloadGrid();
                                    (document.activeElement as HTMLElement)?.blur();
                                });
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    }}
                />
            ),
        },
        {
            id: 'backupPolicyStr',
            title: lang.BACKUP_POLICY,
            cell: rowData => (
                <BaseBackupPolicyColumn
                    value={rowData.backupPolicyStr}
                    commonVawTemplateStatus={rowData.commonVawTemplateStatus}
                    multipleJobs={rowData.multipleJobs}
                    defaultValue={lang.NO_INFO}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepCopy(rowData);
                        portalEnums.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);
                        const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                        setLoading(true);

                        promise
                            .then(() => {
                                setLoading(false);
                                const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                                    record: {
                                        ...rowDataForExt,
                                        get: prop => rowDataForExt[prop],
                                    },
                                    extProps: {
                                        gridReload() {
                                            api.current?.reloadGrid();
                                        },
                                    },
                                });

                                dialog.open(() => {}, () => {
                                    api.current?.reloadGrid();
                                    (document.activeElement as HTMLElement)?.blur();
                                });
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    }}
                />
            ),
        },
        {
            id: 'isReadOnlyMode',
            title: capitalize(lang.UI_MODE),
            cell: rowData => capitalize(rowData.isReadOnlyMode ? lang.READ_ONLY : lang.FULL_ACCESS),
        },
        {
            id: 'vawPlatformRendered',
            title: lang.PLATFORM,
            cell: rowData => rowData.vawPlatformRendered || lang.N_A,
            hidden: true,
        },
        {
            id: 'disabled',
            title: lang.SCHEDULE,
            cell: rowData => rowData.disabled ? lang.DISABLED : lang.ENABLED,
            hidden: true,
        },
        {
            id: 'lastModified',
            title: lang.LAST_MODIFIED,
            cell: rowData => (
                <GridDateColumn value={rowData.lastModified} />
            ),
            hidden: true,
        },
        {
            id: 'lastActivity',
            title: lang.LAST_ACTIVITY,
            cell: rowData => (
                <GridDateColumn value={rowData.lastActivity} />
            ),
        },
        {
            id: 'displayVersion',
            title: lang.BACKUP_AGENT_VERSION,
            cell: rowData => rowData.displayVersion,
            defaultValue: lang.NO_INFO,
            hidden: true,
        }, {
            id: 'vawUpgradeStatus',
            title: lang.AVAILABLE_UPDATES,
            hidden: true,
            cell: rowData => (
                <UpdatesColumn
                    upgradeStatus={rowData.vawUpgradeStatus}
                    upgradeVersion={rowData.vawUpgradeVersion}
                />
            ),
        },
    ];

    return (
        <>
            <ConfiguredGrid
                stateId={gridStateId}
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByConsole}
                defaultColumnMinWidth={100}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: columns?.[0]?.id,
                    },
                }}
                api={api}
                columns={columns}
                data={(requestParams: BackupAgentParam) => transportService
                    .request<BackupAgentParam, RequestSuccessResponse<BackupAgentJobContainerModel[]>>('/BackupAgent/GetBackupAgents', {
                        ...requestParams,
                    })}
                isRowInactive={rowData => rowData.disabled}
                toggleable={true}
                selection={{
                    field: 'agentId',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <ManagedByConsoleFilterToolbar />,
                    () => <ManagedByConsoleActionToolbar idProperty={'agentId'} selectedOnly />,
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
};
