/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PortalSpinner, useExternalFormApi, useModal } from '@veeam-vspc/shared/components';

import type { UseModalActionsProps } from '@veeam-vspc/shared/components';

import { useConfigurationStore } from '../../stores';
import { ServerSettingsDialog } from './components';
import { ServerSettingsDialogFormStore, ServerSettingsDialogFormStoreContext } from './stores';

import type { ServerSettingsDialogFormData } from './interfaces';

export interface ServerSettingsDialogProps {
    deactivateDialog: UseModalActionsProps['deactivate'];
}

export const ServerSettingsDialogWrapper: React.FC<ServerSettingsDialogProps> = observer((props) => {
    const { deactivateDialog } = props;
    const { smtpStore: { settings } } = useConfigurationStore();
    const formApi = useExternalFormApi<ServerSettingsDialogFormData>();

    const dialogFormStore = useMemo(() => (new ServerSettingsDialogFormStore(settings, formApi)), []);

    // we need to use 'useModal' hook for the spinner in order to overlap rendered dialog by the spinner
    const [modalSpinner] = useModal({ initialActiveState: true, render: () => <PortalSpinner /> });

    return (
        <ServerSettingsDialogFormStoreContext.Provider value={dialogFormStore}>
            {dialogFormStore.isLoading && modalSpinner}
            <ServerSettingsDialog deactivateDialogCb={deactivateDialog} />
        </ServerSettingsDialogFormStoreContext.Provider>
    );
});
