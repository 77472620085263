/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/shared/components';
import { emailValidator } from '@veeam-vspc/shared/helpers';

import type { TestEmailOptions } from '@veeam-vspc/models/rest';
import type { FormErrors } from '@veeam-vspc/shared/components';

export interface ErrorTexts {
    emailFormat: string;
    emailMultipleValues: string;
}

export const notificationTestFormValidate = (formData: TestEmailOptions, errorTexts: ErrorTexts): FormErrors<TestEmailOptions> => {
    const validator = new FormValidator(formData);
    const validateEmail = (email: string) => (
        emailValidator(email, {
            multipleEmailsError: errorTexts.emailMultipleValues,
            wrongEmailFormatError: errorTexts.emailFormat,
        })
    );

    validator
        .validate('from')
        .string()
        .required()
        .check(v => validateEmail(v));

    validator
        .validate('to')
        .string()
        .required()
        .check(v => validateEmail(v));

    return validator.result();
};
