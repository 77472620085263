/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ValidationType } from '../enums';
import { checkReboot } from './check-reboot';
import { checkPolitics } from './check-politics';
import { checkUpgradeLicense } from '../../../helpers';
import { checkXmlFileValidity } from './check-xml-file-validity';

import type { ValidationResult } from '../interfaces';

export const getValidationResult = (xml: XMLDocument, isVbrUpgrade: boolean): ValidationResult => {
    const validationResults: ValidationResult[] = [
        {
            type: ValidationType.MainTag,
            isValid: checkXmlFileValidity(xml, isVbrUpgrade),
        },
        {
            type: ValidationType.Politics,
            isValid: checkPolitics(xml),
        },
        {
            type: ValidationType.RebootTag,
            isValid: checkReboot(xml),
        },
        {
            type: ValidationType.LicenseTag,
            isValid: isVbrUpgrade ? checkUpgradeLicense(xml) : true,
        },
    ];

    return validationResults.find(result => result.isValid === false) || { type: ValidationType.AllChecksPassed, isValid: true };
};
