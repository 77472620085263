/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SoftwareStatusRepresentation, UserRoleRepresentation, EConnectionStatus } from '@veeam-vspc/models/web-controllers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { EAgentCommonStatuses, EpRmmProviderModes } from 'core/enums';

import type { AssignTagAllowed, AssignTagItemParams } from '../interfaces';

const isAssignTagAllowed = (value: string, userRole: UserRoleRepresentation, itemParams: AssignTagItemParams): AssignTagAllowed => {
    const { isVacInstalled, vacAgentInstalled, agentStatus, connectionStatus, managementMode } = itemParams;
    const canEdit = !(
        userRole === UserRoleRepresentation.CompanyLocationUser
        || userRole === UserRoleRepresentation.VacReadonlyOperator
        || userRole === UserRoleRepresentation.ResellerUser
        || userRole === UserRoleRepresentation.Subtenant
    );

    const forceAllowSetTagForAgentStatus = agentStatus !== undefined && [
        EAgentCommonStatuses.NotSet,
    ].includes(agentStatus);
    const forceAllowSetTagForConnectionStatus = connectionStatus !== undefined && [
        EConnectionStatus.Unverified,
    ].includes(connectionStatus);
    const forceAllowSetTag = forceAllowSetTagForAgentStatus || forceAllowSetTagForConnectionStatus;

    if (
        (managementMode !== undefined && managementMode === EpRmmProviderModes.ManagedByVBR)
        || (isVacInstalled !== undefined && isVacInstalled !== SoftwareStatusRepresentation.Installed && !forceAllowSetTag)
        || (vacAgentInstalled !== undefined && !vacAgentInstalled)
    ) {
        return {
            canEdit: false,
            value: ColumnTexts.DashSymbol,
        };
    }

    return {
        canEdit,
        value,
    };
};

export {
    isAssignTagAllowed,
};
