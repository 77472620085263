/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TabBarBodyLayout, TabBarBodyLayoutModes } from '@veeam-vspc/shared/components';

import { BackupInfrastructure } from './components';
import { rowsStore } from './stores';
import { RowsContext } from './contexts';

export interface TopsBackupInfrastructurePageProps {
    currentPath?: string;
}

export const TopsBackupInfrastructurePage: React.FC<TopsBackupInfrastructurePageProps> = () => (
    <TabBarBodyLayout mode={TabBarBodyLayoutModes.Scrollable}>
        <RowsContext.Provider value={rowsStore}>
            <BackupInfrastructure />
        </RowsContext.Provider>
    </TabBarBodyLayout>
);
