/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { deepCopy } from '@veeam-vspc/shared/core';
import { SmtpSettings } from '@veeam-vspc/models/rest';

import { CertVerificationStatus, SMTPCertStatus } from '../enums';
import { getOriginalCertStatus, getValidatedCertStatus } from '../helpers';

import type { ConfigurationRequestsStore } from './configuration-requests-store';

export class SmtpStore {

    @observable certVerificationStatus: CertVerificationStatus;
    @observable hasHost = false;
    @observable settings: SmtpSettings = null;

    constructor(private readonly requestsStore: ConfigurationRequestsStore) {
        makeObservable(this);
    }

    @action.bound
        initiateStoreData = (smtpSettings: SmtpSettings): void => {
            this.settings = deepCopy(smtpSettings);
            this.hasHost = Boolean(smtpSettings?.serverAddress);
            const hasOAuth = Boolean(smtpSettings?.oAuth2Credential);

            if (this.settings && !hasOAuth) {
                this.validateCert(this.settings);
            }
        };

    @action.bound
    private readonly validateCert = (originalSmtpSettings: SmtpSettings): void => {
        // to avoid race condition on new smtp settings saving
            this.requestsStore.testSmtpAbortController.abort();
            this.requestsStore.testSmtpAbortController = new AbortController();

            const { GettingInfo, NotVerified, NotVerifiedTrusted, Verified } = CertVerificationStatus;

            const smtpSettingsForTest = deepCopy(originalSmtpSettings);
            smtpSettingsForTest.passwordCredential = null;
            this.certVerificationStatus = GettingInfo;

            this.requestsStore.testSmtpSettings(smtpSettingsForTest)
                .then((testedSmtpSettings: SmtpSettings): CertVerificationStatus => {
                    const { Trusted, Untrusted, UntrustedAccepted } = SMTPCertStatus;
                    const { serverCertificate: testedServerCertificate } = testedSmtpSettings;
                    const { tlsMode, exclusivelyAcceptedCertificateHash: originalHash } = originalSmtpSettings;

                    const originalCertStatus = getOriginalCertStatus(tlsMode, originalHash);
                    const validatedCertStatus = getValidatedCertStatus(testedServerCertificate, originalHash);

                    if (
                    // can define result cert status only when compare this two values
                        (originalCertStatus === validatedCertStatus && validatedCertStatus !== UntrustedAccepted) || (validatedCertStatus === Trusted)
                    ) {
                        return this.certVerificationStatus = Verified;
                    } else if (validatedCertStatus === Untrusted) {
                        return this.certVerificationStatus = NotVerified;
                    }

                    return this.certVerificationStatus = NotVerifiedTrusted;
                })
                .catch((error) => {
                    if (error.code !== DOMException.ABORT_ERR) {
                        this.certVerificationStatus = NotVerified;
                    }
                });
        };
}
