/**
 * Copyright © Veeam Software Group GmbH.
 */

import { LicenseProductType } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/shared/helpers';

import type { LicenseUsageWithType } from '@veeam-vspc/models/web-controllers';

import { LicenseDisplayName, LicenseName } from 'views/pages/LicensingPage/components/Overview/enums';

import type { ChartDataWithCategories } from 'views/components/HighchartsWrapper/interfaces';

export interface CurrentUsageSeries {
    color?: string;
    colorIndex?: number;
    data: number[][];
    enableMouseTracking?: boolean;
    id?: string;
    linkedTo?: string;
    name: string;
    pointWidth?: number;
    stack: string;
    yAxis?: number;
    visible?: boolean;
    showInLegend?: boolean;
    pointPadding?: number;
    borderWidth?: number;
    borderColor?: string;
    zIndex?: number;
}

export const transformData = (
    data: LicenseUsageWithType[],
    productType: LicenseProductType,
    portalUser,
): Promise<ChartDataWithCategories<CurrentUsageSeries>> => {
    const categories = [];
    const keys = [LicenseName.Licensed, LicenseName.Unlicensed, LicenseName.New];
    const series: CurrentUsageSeries[] = [];
    let empty = true;

    if (productType === LicenseProductType.CloudConnectServer) {
        keys.push(LicenseName.Rental);
    }

    if (portalUser.isServiceProviderGlobalOrUsualAdmin()) {
        keys.push(LicenseName.Quota);
    }

    data.forEach((item, i) => {
        const { identifier } = item;

        categories.push(identifier.name.replace(/ /g, '<br />'));

        keys.forEach((k, j) => {
            const s: CurrentUsageSeries = {
                data: [[i, item[k]]],
                name: LicenseDisplayName[capitalize(k, true)],
                stack: k === LicenseName.Quota ? LicenseName.Quota : 'main',
            };

            if (i === 0) {
                s.id = k;
            } else {
                s.linkedTo = k;
                s.yAxis = i;
            }

            if (k === LicenseName.Quota) {
                s.color = 'rgba(232, 89, 90, 0.3)';
                s.borderColor = 'rgba(232, 89, 90, 1)';
                s.enableMouseTracking = false;
                s.pointPadding = 0;
                s.pointWidth = 84;
                s.borderWidth = 1;
                s.zIndex = 0;
            } else {
                s.colorIndex = j;
                s.pointPadding = 0.1;
                s.zIndex = 1;
            }

            series.push(s);

            if (item[k] !== null) {
                empty = false;
            }
        });
    });

    return Promise.resolve({
        categories,
        empty,
        series,
    });
};
