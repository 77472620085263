/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/shared/enums';
import { dataSizeToString } from '@veeam-vspc/shared/helpers';

import type { Vb365BackupRepository } from '@veeam-vspc/models/rest';
import type { VspcLang } from 'configs/languages';

export const usedSpaceMessage = (lang: VspcLang, repo?: Vb365BackupRepository) => {
    if (repo) {
        if (repo.capacityBytes) {
            const usedMsg = dataSizeToString(repo.capacityBytes - repo.freeSpaceBytes, 1);
            const totalMsg = dataSizeToString(repo.capacityBytes, 1);

            if (!repo.isObjectStorageRepository && repo.usedSpaceBytes) {
                const directoryMsg = dataSizeToString(repo.usedSpaceBytes, 1);

                return `${usedMsg} ${lang.OF} ${totalMsg} (${lang.DATABASE_SIZE} - ${directoryMsg})`;
            }

            return `${usedMsg} ${lang.OF} ${totalMsg}`;
        }

        if (Number.isInteger(repo.usedSpaceBytes)) {
            const usedMsg = dataSizeToString(repo.usedSpaceBytes, 1);

            return usedMsg;
        }
    }

    return ColumnTexts.DashSymbol;
};
