/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    FormValidator,
    LabelGroup,
    NumberInput,
    useGlobalLang,
} from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { FormFieldNames, Platform } from '../../../enums';
import { RpoInterval } from './RpoInterval';
import { LabelWithHelpText } from './LabelWithHelpText';
import { useParametersConfig } from '../hooks';

import type { ReportModel } from '../../../interfaces';

export const fileSharesParametersValidate = (data: ReportModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('parameters.rpoInterval.number')
        .number()
        .required()
        .min(1)
        .max(365);

    validator
        .validate(FormFieldNames.ProcessedPercentage)
        .number()
        .required()
        .max(100)
        .min(0);

    return validator.result();
};

interface Props {
    data: ReportModel;
}

export const FileSharesParameters = ({ data }: Props) => {
    const lang = useGlobalLang<VspcLang>();
    const retentionTypeFieldName = FormFieldNames.RetentionTypeFilter;
    const { getPlatformTypes, onPlatformChange, platforms } = useParametersConfig();
    const selectedPlatform: Platform = data.parameters.platformFilter[0] as Platform;

    return (
        <>
            <Combobox
                name={FormFieldNames.PlatformFilter}
                label={lang.PLATFORM}
                data={platforms}
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
                onChange={newValue => onPlatformChange(newValue, retentionTypeFieldName)}
            />

            <LabelGroup label={`${lang.BACKUP_TYPE}:`}>
                <Combobox
                    name={FormFieldNames.RetentionTypeFilter}
                    data={getPlatformTypes(selectedPlatform)}
                    valueGetter={item => item.id}
                    textGetter={item => item.text}
                    size={CONTROL_SIZE.m}
                />
            </LabelGroup>

            <RpoInterval />

            <LabelGroup
                disabled={selectedPlatform === Platform.PublicCloud}
                label={(
                    <LabelWithHelpText
                        helpText={lang.APPLICABLE_TO_ON_PREMISES_AND}
                        label={lang.PROCESSED_PERCENTAGE}
                    />
                )}
            >
                <NumberInput
                    name={FormFieldNames.ProcessedPercentage}
                    precision={2}
                    minValue={0}
                    maxValue={100}
                    size={CONTROL_SIZE.xs}
                    suffix={'%'}
                />
            </LabelGroup>
        </>
    );
};
