/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
    GridSortDirections,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridStore, RequestParams } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { MAX_INTEGER_32 } from 'core/const';


import type {
    ManagedCompaniesFilterModel,
    ManagedCompaniesProps,
} from 'views/pages/RolesAndUsersPage/components/AdminUser/interfaces';
import type { Company } from '../../../../interfaces';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<Company, {}, ManagedCompaniesFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.selectedCompanies?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.selectedCompanies = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <ConfiguredGrid
                initialState={{
                    selected: data.selectedCompanies,
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: 'name',
                    },
                }}
                defaultColumnMinWidth={100}
                columns={[
                    {
                        id: 'name',
                        title: lang.NAME,
                        cell: rowData => rowData.name,
                    },
                    {
                        id: 'siteName',
                        title: lang.SITE,
                        cell: rowData => rowData.siteName,
                    },
                    {
                        id: 'locations',
                        title: lang.LOCATIONS,
                        cell: rowData => rowData.locations,
                    },
                    {
                        id: 'companyStatus',
                        title: lang.STATE,
                        cell: rowData => rowData.companyStatus,
                    },
                    {
                        id: 'managerCompanyName',
                        title: lang.MANAGED_BY,
                        cell: rowData => rowData.managerCompanyName,
                    },
                ]}
                data={(requestParams: RequestParams<ManagedCompaniesFilterModel>) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<Company[]>>('/AccountManagement/GetCompanies', requestParams)}
                toggleable={false}
                paginationLimit={MAX_INTEGER_32}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='companyName'
                                            placeholder={lang.COMPANY_NAME}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
