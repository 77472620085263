/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import {
    DIALOG_SIZE,
    DialogWizard,
    useGlobalAddons,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import { FeatureNames } from '@veeam-vspc/shared/addons';
import { ConfigStates } from '@veeam-vspc/shared/core';
import { observer } from 'mobx-react-lite';

import type { UserData } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { getLoginInfoStep, getMfaStep, getSummaryStep, getUserInfoStep } from './components';
import { EditUserProfileStore, EditUserProfileStoreContext } from './stores';
import { ConfigSectionIds } from 'core/enums';

export interface EditUserProfileWizardProps {
    data: UserData;
    deactivate: () => void;
}

export const EditUserProfileWizard: React.FC<EditUserProfileWizardProps> = observer(({ data, deactivate }) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalFeatures, portalEnums } = useGlobalAddons();
    const { notificationService, transportService, configuratorService } = useGlobalServices();
    const isMfaStrictPolicy = portalFeatures.isFeatureEnabled(FeatureNames.MfaStrictPolicy);
    const editUserProfileStore = useMemo(() => new EditUserProfileStore(transportService, portalEnums, data, isMfaStrictPolicy), []);
    const configSection = configuratorService.getSectionConfig(ConfigSectionIds.EditUserProfileWizard);
    const isLoginStepHidden = configSection
        ? configSection.getItemState('loginInfoStep') === ConfigStates.Hidden
        : false;

    const closeMessageText = editUserProfileStore.shouldConfigureMfa
        ? isMfaStrictPolicy
            ? lang.YOUR_PORTAL_ADMINISTRATOR_HAS_ENFORCED_THE_MULTI
            : lang.YOU_MUST_CONFIGURE_THE_MULTI_FACTOR
        : lang.ALL_CHANGES_WILL_BE_LOST;


    useEffect(() => {
        editUserProfileStore.loadUserTitles();
    }, []);

    return (
        <EditUserProfileStoreContext.Provider value={editUserProfileStore}>
            <DialogWizard
                steps={[
                    getUserInfoStep(lang),
                    !isLoginStepHidden && getLoginInfoStep(lang, notificationService, transportService),
                    getMfaStep(lang, editUserProfileStore, notificationService),
                    getSummaryStep(lang),
                ].filter(Boolean)}
                size={{
                    height: DIALOG_SIZE.xxl,
                    width: DIALOG_SIZE.m,
                }}
                title={lang.EDIT_USER}
                isEdit
                data={data}
                onFinish={async({ data }) => {
                    const { confirmPassword, ...userData } = data as unknown as UserData & { confirmPassword: string; };

                    await transportService.request('/User/UpdateUser', userData)
                        .finally(deactivate);
                }}
                deactivate={deactivate}
                closeMessageText={closeMessageText}
                closeMessageOkButtonHide={editUserProfileStore.shouldConfigureMfa}
                closeMessageOkButtonText={editUserProfileStore.shouldConfigureMfa ? '' : lang.YES}
                closeMessageCancelButtonText={editUserProfileStore.shouldConfigureMfa ? lang.OK : lang.NO}
            />
        </EditUserProfileStoreContext.Provider>
    );
});
