/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { JobStateRepresentation, PublicCloudJobSessionStateModel } from '@veeam-vspc/models/web-controllers';

export const oneOfPropsHaveOneOfStatus = <T>(item: T, backupsProps: (keyof T)[], listOfStatuses: JobStateRepresentation[]): boolean => (
    backupsProps.some(prop => (
        item[prop]
            ? listOfStatuses.includes((item[prop] as PublicCloudJobSessionStateModel).sessionState)
            : false
    ))
);
