/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, ConfiguredGrid, useGlobalLang, useGlobalServices, useGlobalAddons } from '@veeam-vspc/shared/components';
import { capitalize } from '@veeam-vspc/shared/helpers';
import { useLocation } from 'react-router-dom';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { PublicCloudEfsJobModel, PublicCloudEfsJobParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { FileSharesPublicCloudsActionToolbar, FileSharesPublicCloudsFilterToolbar } from './components';
import { StatusAgoColumn } from '../../../StatusAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ConfigSectionIds } from 'core/enums';


export const FileSharesPublicClouds: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { pathname } = useLocation();
    const { transportService } = useGlobalServices();
    const { portalEnums } = useGlobalAddons();
    const gridStateId = `${pathname}.FileSharesPublicClouds`;
    const columns: GridColumnProps<PublicCloudEfsJobModel>[] = [
        {
            id: 'vmName',
            title: lang.NAME,
            cell: rowData => rowData.vmName,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
        },
        {
            id: 'company',
            title: lang.COMPANY,
            cell: rowData => rowData.company,
        },
        {
            id: 'site',
            title: lang.SITE,
            cell: rowData => rowData.site,
            hidden: true,
        },
        {
            id: 'location',
            title: lang.LOCATION,
            cell: rowData => rowData.location,
            hidden: true,
        },
        {
            id: 'displayUid',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.displayUid,
        },
        {
            id: 'platformTypeName',
            title: lang.PLATFORM,
            cell: rowData => rowData.platformTypeName,
        },
        {
            id: 'jobName',
            title: lang.POLICY,
            cell: rowData => rowData.jobName,
        },
        {
            id: 'displayPolicyState',
            title: lang.POLICY_STATE,
            cell: rowData => portalEnums.getEnumDescription('EDisplayPublicCloudPolicyState', rowData.displayPolicyState),
        },
        {
            id: 'lastBackup.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudEfsJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            id: 'lastBackupCopy.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudEfsJob/GetBackupCopyTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackupCopy}
                />
            ),
        },
        {
            id: 'nextRun',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn value={rowData.nextRun} />
            ),
            hidden: true,
        },
        {
            id: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: rowData => rowData.backupServerName,
            hidden: true,
        },
        {
            id: 'targetVault',
            title: capitalize(lang.BACKUP_TARGET),
            cell: rowData => rowData.targetVault,
            hidden: true,
        },
        {
            id: 'backupCopyTarget',
            title: lang.BACKUP_COPY_TARGET,
            cell: rowData => rowData.backupCopyTarget,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsFileSharesPublicClouds}
            defaultColumnMinWidth={100}
            initialState={{
                sort: {
                    key: 'status',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudEfsJobParam>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<PublicCloudEfsJobModel[]>>('/PublicCloudEfsJob/GetEfsJob', {
                    ...requestParams,
                })}
            isRowInactive={rowData => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesPublicCloudsFilterToolbar />,
                () => <FileSharesPublicCloudsActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
