/**
 * Copyright © Veeam Software Group GmbH.
 */

import { AddonNames, CompanyPrefixes, VeeamRcopProductPrefixes } from '@veeam-vspc/shared/enums';
import { AppStorage } from '@veeam-vspc/shared/helpers';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { NotificationDialogsTextKeys } from '@veeam-vspc/shared/services';
import { reaction } from 'mobx';

import type { NotificationResponse, NotificationService, SuccessAuth } from '@veeam-vspc/shared/services';
import type { ReturnUrlManagerContext } from '@veeam-vspc/shared/core';
import type { MainCoreModuleConfig } from '@veeam-vspc/shared/core-modules';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { AppDataStore } from '@veeam-vspc/shared/stores';

import { QueryKeys, ReturnUrlProducts, SsoLoginStates } from 'core/enums';
import { ReactToExtService } from 'core/services/react-to-ext';
import { webControllersEnumsMap } from 'swagger/webControllers/webControllersEnumsMap';
import { RoutePaths } from '../enums';
import {
    extractSearchParamFromUrl,
    getReactProductTheme,
    getSearchParamValueFromUrl,
    isSmtpOAuthRedirect,
    isSsoRedirect,
    returnUrlActionFromSso,
    returnUrlActionFromVb,
    waitFor,
} from 'core/utils';

import type { enLocale, VspcLang } from '../languages';

const companyPrefix = CompanyPrefixes.VeeamRcop;
const productPrefix = VeeamRcopProductPrefixes.VSPC;
const returnUrlConfigs = [
    {
        // download agent
        urlValidation: (location, queryParams: URLSearchParams): boolean => {
            if (!queryParams.has(QueryKeys.ReturnUrl)) {
                return false;
            }

            const returnURL = queryParams.get(QueryKeys.ReturnUrl);

            return returnURL.includes(QueryKeys.ValidPeriod);
        },
        action: (
            {
                fileTransportService,
                notificationService,
            }: ReturnUrlManagerContext<RequestErrorResponse | Error> & { notificationService: NotificationService; },
            lang: Partial<VspcLang>,
            location: Location,
            queryParams: URLSearchParams,
        ) => {
            const url = encodeURI(queryParams.get(QueryKeys.ReturnUrl));

            notificationService.info(
                lang.DOWNLOAD_MANAGEMENT_AGENT,
                lang.MANAGEMENT_AGENT_WILL_NOW_BE_DOWNLOADED,
            )
                .then((btnKey: NotificationResponse) => {
                    if (btnKey === NotificationDialogsTextKeys.Ok) {
                        fileTransportService.downloadFile(url, { skipBaseUrl: true });
                    }
                });
        },
        resetQuery: true,
    },
    {
        // Remove a reset password token from email for logged user
        urlValidation: (location, queryParams) => !!queryParams.get(QueryKeys.ResetPasswordFlag),
        action: () => {
        },
        resetQuery: true,
    },
    {
        urlValidation: (location, queryParams: URLSearchParams): boolean => (
            queryParams.get(QueryKeys.ProductReturnUrl) === ReturnUrlProducts.Vb
        ),
        action: returnUrlActionFromVb,
        resetQuery: true,
    },
    {
        // TODO: Add 'stateFrom=sso' query for SSO to avoid conflicts with SmtpOAuth queries
        urlValidation: (location, queryParams) => !isSmtpOAuthRedirect(queryParams) && isSsoRedirect(queryParams),
        action: returnUrlActionFromSso,
        resetQuery: true,
    },
];

export const coreModuleConfig: MainCoreModuleConfig = {
    companyPrefix,
    productPrefix,
    restPrefix: '/api/v3',
    baseUrl: '/uiapi',
    defaultAppData: {
        defaultTheme: 'blue',
        portalLogoSrc: '',
        defaultPortalName: 'Veeam Service Provider Console',
        getCorrectProductTheme: getReactProductTheme,
    },
    getLoginPath: (returnUrl?: string) => {
        if (returnUrl && returnUrl.includes(QueryKeys.ReturnUrl)) {
            const params = new URL(window.location.origin + returnUrl);
            const newReturnUrl = params.searchParams.get(QueryKeys.ReturnUrl);

            return `/${RoutePaths.Login}?returnUrl=${encodeURIComponent(newReturnUrl)}`;
        }

        if (returnUrl) {
            return `/${RoutePaths.Login}?returnUrl=${encodeURIComponent(returnUrl)}`;
        }

        return `/${RoutePaths.Login}`;
    },
    getPortalPath: () => {
        const returnUrl = new URLSearchParams(window.location.search.slice(1)).get(QueryKeys.ReturnUrl);

        if (returnUrl) {
            return returnUrl;
        }

        return '/';
    },
    configuratorConfig: {
        allRolesPaths: {
            [UserRoleRepresentation.CloudConnectAdministrator]: () => (
                import('../user-roles/configs/cloud-connect-administrator.config').then(file => file.cloudConnectAdministratorConfig)
            ),
            [UserRoleRepresentation.CompanyAdministrator]: () => (
                import('../user-roles/configs/company-administrator.config').then(file => file.companyAdministratorConfig)
            ),
            [UserRoleRepresentation.CompanyLocationAdministrator]: () => (
                import('../user-roles/configs/company-location-administrator.config').then(file => file.companyLocationAdministratorConfig)
            ),
            [UserRoleRepresentation.CompanyLocationUser]: () => (
                import('../user-roles/configs/company-location-user.config').then(file => file.companyLocationUserConfig)
            ),
            [UserRoleRepresentation.CompanyOwner]: () => (
                import('../user-roles/configs/company-owner.config').then(file => file.companyOwnerConfig)
            ),
            [UserRoleRepresentation.Finance]: () => (
                import('../user-roles/configs/finance.config').then(file => file.financeConfig)
            ),
            [UserRoleRepresentation.ResellerAdministrator]: () => (
                import('../user-roles/configs/reseller-administrator.config').then(file => file.resellerAdministratorConfig)
            ),
            [UserRoleRepresentation.ResellerFinance]: () => (
                import('../user-roles/configs/reseller-finance.config').then(file => file.resellerFinanceConfig)
            ),
            [UserRoleRepresentation.ResellerOperator]: () => (
                import('../user-roles/configs/reseller-operator.config').then(file => file.resellerOperatorConfig)
            ),
            [UserRoleRepresentation.ResellerOwner]: () => (
                import('../user-roles/configs/reseller-owner.config').then(file => file.resellerOwnerConfig)
            ),
            [UserRoleRepresentation.ResellerUser]: () => (
                import('../user-roles/configs/reseller-user.config').then(file => file.resellerUserConfig)
            ),
            [UserRoleRepresentation.Subtenant]: () => (
                import('../user-roles/configs/subtenant.config').then(file => file.subtenantConfig)
            ),
            [UserRoleRepresentation.Unknown]: () => (
                import('../user-roles/configs/unknown.config').then(file => file.unknownConfig)
            ),
            [UserRoleRepresentation.VacAdministrator]: () => (
                import('../user-roles/configs/vac-administrator.config').then(file => file.vacAdministratorConfig)
            ),
            [UserRoleRepresentation.VacOperator]: () => (
                import('../user-roles/configs/vac-operator.config').then(file => file.vacOperatorConfig)
            ),
            [UserRoleRepresentation.VacReadonlyOperator]: () => (
                import('../user-roles/configs/vac-readonly-operator.config').then(file => file.vacReadonlyOperatorConfig)
            ),
        },
    },
    langConfig: {
        allLangPaths: {
            en: (): Promise<typeof enLocale> => import('../languages/locales/en.locale').then(file => file.enLocale),
        },
    },
    returnUrlManagerConfig: {
        returnUrlConfigs,
    },
    fileTransportConfig: {
        authorizationCodeProps: {
            url: '/Login/AuthorizationCode',
            queryKey: 'x-authorization-code',
        },
    },
    tokenManagerConfig: {
        authUrl: '/token',
    },
    loggerConfig: {
        loggerUrl: '/Error/WriteLog',
    },
    portalConfig: {
        loginDataUrl: '/Login/IsLogged',
        identityProvidersUrl: '/authentication/identityProviders',
    },
    addonsConfig: [
        [
            AddonNames.PortalEnums,
            {
                webControllersEnumsMap,
            },
        ],
        AddonNames.PortalFeatures,
        [
            AddonNames.PortalPreferences,
            null,
            null,
            1,
        ],
        [
            AddonNames.PortalFilters,
            null,
            (appDataStore: AppDataStore) => [
                // UserRoleRepresentation.Unknown, // NOT allowed for that role
                UserRoleRepresentation.VacAdministrator,
                UserRoleRepresentation.CompanyLocationAdministrator,
                UserRoleRepresentation.CompanyLocationUser,
                UserRoleRepresentation.CompanyOwner,
                // UserRoleRepresentation.Finance, // NOT allowed for that role
                // UserRoleRepresentation.Subtenant, // NOT allowed for that role
                UserRoleRepresentation.VacOperator,
                UserRoleRepresentation.ResellerOwner,
                UserRoleRepresentation.ResellerOperator,
                UserRoleRepresentation.ResellerUser,
                UserRoleRepresentation.ResellerFinance,
                UserRoleRepresentation.ResellerAdministrator,
                UserRoleRepresentation.CloudConnectAdministrator,
                UserRoleRepresentation.CompanyAdministrator,
                UserRoleRepresentation.VacReadonlyOperator,
            ].includes(appDataStore.portalUser?.userRole ?? UserRoleRepresentation.Unknown),
        ],
    ],
    appEventHandlers: {
        onBeforeLoadingEnd: ({ services, appDataStore, lang, addons }) => {
            // @TODO Remove with Ext.js
            (window as any).reactToExtService = new ReactToExtService(services, appDataStore, addons);
            (window as any).RCOP = (window as any).RCOP || {};
            (window as any).RCOP.OPENED_DIALOGS = [];
            (window as any).RCOP.Storage = new AppStorage(companyPrefix, productPrefix, appDataStore.portalUser?.instanceUid);
            (window as any).RCOP.UI_PREFERENCES = (addons.portalPreferences as any)?.preferences;
            (window as any).RCOP.UI_PREFERENCES_WITHOUT_FILTERS = (addons.portalPreferences as any)?.preferencesWithoutPortalFilter;
            (window as any).RCOP.SERVICE_TIME_ZONE = appDataStore.serviceTimeZone;
            // (window as any).RCOP.CURRENT_USER = appDataStore.portalUser; // It will be done below "RCOP.Configurator.setUser(appDataStore.portalUser);"
            (window as any).RCOP.FORMAT_DATE_PATTERNS = appDataStore.formats;
            (window as any).RCOP.Lang = lang;

            window.onerror = (event: Event | string, source: string): void => {
                if (typeof event === 'string' && event.includes('Uncaught TypeError') && source.includes('app.js')) {
                    services.notificationService.error(lang.ERROR, (lang as VspcLang).AN_ERROR_OCCURRED, { canClose: false })
                        .then(() => window.location.reload())
                        .catch(() => {
                            // for double error handling
                        });
                }
            };

            reaction(
                () => [
                    (addons.portalPreferences as any)?.preferences,
                    (addons.portalPreferences as any)?.preferencesWithoutPortalFilter,
                ],
                () => {
                    // @TODO Remove with Ext.js
                    // Update Ext.js UI_PREFERENCES with Portal preferences update
                    (window as any).RCOP.UI_PREFERENCES = (addons.portalPreferences as any)?.preferences;
                    (window as any).RCOP.UI_PREFERENCES_WITHOUT_FILTERS = (addons.portalPreferences as any)?.preferencesWithoutPortalFilter;
                },
            );

            reaction(
                () => [
                    (addons.portalFilters as any)?.locationFilter,
                    (addons.portalFilters as any)?.siteFilter.join(','),
                    (addons.portalFilters as any)?.organizationFilter,
                ],
                () => {
                    // @TODO Remove with Ext.js
                    // Update Ext.js grids with Portal filters updates
                    if (
                        window.ExtApplication
                        && window.ExtApplication.viewport
                        && window.ExtApplication.extApplicationInitializationState === 'done'
                    ) {
                        window.ExtApplication.viewport.updateCurrentViewData();
                    }
                },
            );

            return waitFor<{ setColorTheme: (theme: string, version: string) => void; }>(window, 'ExtApplication').promise
                .then((app) => {
                    (window as any).RCOP.utils.Services.updateServices();
                    app.setColorTheme(appDataStore.theme, appDataStore.version);
                })
                .then(() => {
                    RCOP.Configurator.setUser(appDataStore.portalUser);
                    RCOP.Configurator.initViewControls();
                });
        },
    },
    trustedUrlsWithNoError: [
        '/LicenseReport/SaveNotificationSettings',
        '/Setting/SetSMTPSettings',
        '/CloudConnectServers/CheckAgent',
    ],
    customCheckAuthorization: (suppliers, services) => {
        const { tokenManagerService } = services;
        const authorizationCode = extractSearchParamFromUrl(QueryKeys.SsoAuthCode);
        const loginState = getSearchParamValueFromUrl(QueryKeys.SsoLoginState);

        if (authorizationCode && loginState !== SsoLoginStates.TestLogin) {
            return tokenManagerService.authorizationCode(authorizationCode)
                .then((response) => {
                    if ((response as SuccessAuth).successAuth) {
                        return Promise.resolve();
                    }

                    return Promise.reject();
                });
        }

        return tokenManagerService.checkAuthorization();
    },
};
