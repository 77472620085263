/**
 * Copyright © Veeam Software Group GmbH.
 */
import styled from 'styled-components';
import { SPACING_L, SPACING_M } from '@veeam-vspc/shared/components';

export const Links = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;

  gap: ${SPACING_L};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #c0c0c0;
    transform: translateX(-50%);
  }
`;

export const LinkItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_M};
`;
