/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, STACK_ALIGN, STACK_DIRECTION, STACK_GAP, useGlobalLang, TextColumn } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { getColumnContentParams } from './utils';

import type { EProductUpgradeStatus } from 'core/enums';

export interface UpdatesColumnProps {
    upgradeVersion: string;
    upgradeStatus: EProductUpgradeStatus;
}

export const UpdatesColumn: React.FC<UpdatesColumnProps> = (props) => {
    const lang = useGlobalLang<VspcLang>();
    const { upgradeStatus, upgradeVersion } = props;
    const { iconSrc, columnText } = getColumnContentParams(lang, upgradeStatus, upgradeVersion);

    return (
        <StackView
            gap={STACK_GAP.s}
            align={STACK_ALIGN.center}
            direction={STACK_DIRECTION.row}
            size={{ width: '100%' }}
        >
            <Icon src={iconSrc} />

            <TextColumn>
                {columnText}
            </TextColumn>
        </StackView>
    );
};
