/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';

import type { VspcLang } from 'configs/languages';

import { JobTypes } from 'core/enums';
import { FileServersDialog } from './components';

export interface FileServerColumnProps {
    value: any;
    jobType: JobTypes;
    name: string;
    instanceUid: string;
    stateId: string;
}

export const FileServerColumn: React.FC<FileServerColumnProps> = (props) => {
    const {
        value,
        jobType,
        name,
        instanceUid,
        stateId,
    } = props;
    const lang = useGlobalLang<VspcLang>();
    const content = value && ![JobTypes.FileCopy, JobTypes.FileTapeBackup].includes(jobType) ? value : null;

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {content}
                </TextColumn>
            )}
            isLink={Boolean(content)}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <FileServersDialog
                    deactivate={deactivate}
                    title={`${name} - ${lang.FILE_SHARE_BACKUP}`}
                    jobUid={instanceUid}
                    stateId={stateId}
                />
            )}
        />
    );
};
