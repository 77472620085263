/**
 * Copyright © Veeam Software Group GmbH.
 */

import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { PortalFiltersIds } from '@veeam-vspc/shared/addons';
import { ConfigStates } from '@veeam-vspc/shared/core';

import type { UserConfigData } from '@veeam-vspc/shared/core';

import { RouteIds, RoutePaths } from '../../enums';
import { ConfigActionIds, ConfigAdvancedFilterItemsIds, ConfigItemIds, ConfigSectionIds } from 'core/enums';
import { allConfigsRoutes, allCustomRoutes, allHomeRoutes } from '../routes';
import { AppPortalLayout } from 'views/components/layouts/AppPortalLayout';
import { AppPortalBodyLayout } from 'views/components/layouts/AppPortalBodyLayout';

import type { VspcLang } from '../../languages';

export const companyLocationAdministratorConfig: UserConfigData<
    typeof UserRoleRepresentation.CompanyLocationAdministrator, ConfigSectionIds | PortalFiltersIds
> = {
    role: UserRoleRepresentation.CompanyLocationAdministrator,
    routes: [
        allCustomRoutes[RouteIds.Login],
        {
            path: '',
            component: AppPortalLayout,
            items: [
                {
                    path: RoutePaths.Home,
                    component: AppPortalBodyLayout,
                    items: [
                        allHomeRoutes[RouteIds.ActiveAlarms],
                        allHomeRoutes[RouteIds.ProtectedDataSummary],
                        {
                            ...allHomeRoutes[RouteIds.BillingSummary],
                            navNameKey: 'RESOURCES' as VspcLang['RESOURCES'],
                        },
                        allHomeRoutes[RouteIds.BackupJobs],
                        allHomeRoutes[RouteIds.FailoverPlans],
                        allHomeRoutes[RouteIds.ProtectedData],
                        allHomeRoutes[RouteIds.Rules],
                        allHomeRoutes[RouteIds.ManagedComputers],
                        allHomeRoutes[RouteIds.Reports],
                    ],
                },
                {
                    path: RoutePaths.Configs,
                    component: AppPortalBodyLayout,
                    items: [
                        allConfigsRoutes[RouteIds.RolesUsersClient],
                        allConfigsRoutes[RouteIds.Locations],
                    ],
                },
            ],
        },
        allCustomRoutes[RouteIds.BackupServerTerminal],
        allCustomRoutes[RouteIds.Api],
        {
            path: '*',
            redirectTo: RoutePaths.NotFound,
        },
    ],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByConsole,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                separatorBeforeDownloadRemoteConsole: ConfigStates.Hidden,
                downloadRemoteConsole: ConfigStates.Hidden,
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
                createJobGroupId: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsDataBackupObjectStorage,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                assignToCompany: ConfigStates.Hidden,
                assignToCompanySeparator: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsSelectVbDialogFilterToolbar,
            actions: {
                [ConfigActionIds.FilterToolbarSiteSearch]: ConfigStates.Hidden,
                [ConfigActionIds.FilterToolbarCompanySearch]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataComputers,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataDataBackupObjectStorage,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesPublicClouds,
            columns: {
                company: ConfigStates.Hidden,
                site: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsCloudDatabases,
            columns: {
                company: ConfigStates.Hidden,
                site: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ReportsAllReports,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ConfigActionIds.ActionToolbarViewReports]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.InvoicesConfiguration,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.InvoicesAllInvoices,
            actions: {
                [ConfigActionIds.FilterToolbarType]: ConfigStates.Hidden,
            },
            columns: {
                companyName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarmsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.AlarmProductVspc]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmProductCloudConnect]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.AlarmScope]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersComputers,
            actions: {
                [ConfigActionIds.ActionToolbarAutoDeploy]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarChangeSettings]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarSetCompany]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarAcceptConnection]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarRejectConnection]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarAcceptConnectionSeparator]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarInstallVbr]: ConfigStates.Hidden,
                [ConfigActionIds.ActionToolbarInstallVbrSeparator]: ConfigStates.Hidden,
            },
            columns: {
                rejectReason: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.DiscoveryComputersRules,
            columns: {
                company: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: PortalFiltersIds.PortalFiltersSection,
            items: {
                [PortalFiltersIds.SitesPortalFilter]: ConfigStates.Hidden,
                [PortalFiltersIds.OrganizationsPortalFilter]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarmsFilterToolbar,
            actions: {
                [ConfigActionIds.FilterToolbarRegionSearch]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ActiveAlarmsAlarms,
            columns: {
                regionName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BillingSummaryCloudConnectResourcesCloudConnectBackup,
            items: {
                [ConfigItemIds.StorageUsageWidget]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ReportsFilterToolbarAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.ReportsManagingCompany]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.ReportsReportConfiguration]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructureAdvancedFilter,
            advancedFilters: {
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructureBackupServerType]: ConfigStates.Hidden,
                [ConfigAdvancedFilterItemsIds.BackupJobsVMsVirtualInfrastructurePlatformType]: ConfigStates.Hidden,
            },
        },
    ],
};
