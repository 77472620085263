/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BasicFilter, ConfiguredToolbar, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, useGlobalLang } from '@veeam-vspc/shared/components';
import { NetworkDiscoveryRuleOsTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import windowsOsTypeIconSrc from 'images/filters/os/windows.svg';
import linuxOsTypeIconSrc from 'images/filters/os/linux.svg';

export const RulesFilterToolbar: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <ConfiguredToolbar
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='search'
                            placeholder={lang.RULE}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <BasicFilter
                            name={'osTypes'}
                            label={lang.GUEST_OS}
                            hasAllButton={true}
                            buttons={[
                                {
                                    title: lang.WINDOWS,
                                    value: `${NetworkDiscoveryRuleOsTypeRepresentation.WinNT}`,
                                    icon: windowsOsTypeIconSrc,
                                },
                                {
                                    title: lang.LINUX,
                                    value: `${NetworkDiscoveryRuleOsTypeRepresentation.Linux}`,
                                    icon: linuxOsTypeIconSrc,
                                },
                            ]}
                        />
                    ),
                },
            ]}
        />
    );
};
