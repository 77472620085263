/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ACTION_VIEW,
    Dialog,
    Form,
    FormLayout,
    Radiobutton,
    useExternalFormApi,
    DIALOG_SIZE,
    useGlobalLang,
    useGlobalServices,
} from '@veeam-vspc/shared/components';
import React, { useEffect } from 'react';
import { InvoiceOptionsTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type {
    InvoiceOptionParam,
    InvoiceGetInvoiceOptionsParam,
    InvoiceGetInvoiceOptionsResponse,
    InvoiceOptionsModel,
} from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

interface InvoiceParametersProps {
    onApplyParameters: (data: InvoiceOptionParam) => void;
    deactivate: () => void;
    ids: number[];
}

export const InvoiceParameters: React.FC<InvoiceParametersProps> = ({ deactivate, ids, onApplyParameters }) => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const formApi = useExternalFormApi<InvoiceOptionsModel>();

    useEffect(() => {
        transportService.request<InvoiceGetInvoiceOptionsParam, RequestSuccessResponse<InvoiceGetInvoiceOptionsResponse>>(
            '/Invoice/GetInvoiceOptions',
            { ids: [ids[0]] },
        )
            .then(({ data }: RequestSuccessResponse<InvoiceGetInvoiceOptionsResponse>) => {
                formApi.setValue(data[0]);
            });
    }, []);

    return (
        <Dialog
            onRequestClose={deactivate}
            header={lang.INVOICE_PARAMETERS}
            title={lang.CHOOSE_REPORTING_OPTIONS}
            size={DIALOG_SIZE.auto}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => onApplyParameters({ ids, opts: { type: formApi.value.type } }),
                },
                {
                    text: lang.CANCEL,
                    onClick: deactivate,
                    view: ACTION_VIEW.secondary,
                },
            ]}
        >
            <Form value={{}} externalFormApi={formApi}>
                <FormLayout inlineLabel>
                    <Radiobutton
                        name={'type'}
                        value={InvoiceOptionsTypeRepresentation.Summary}
                    >
                        {lang.SUMMARY_ONLY}
                    </Radiobutton>

                    <Radiobutton
                        name={'type'}
                        value={InvoiceOptionsTypeRepresentation.PastInterval}
                    >
                        {lang.DETAILED_REPORT}
                    </Radiobutton>
                </FormLayout>
            </Form>
        </Dialog>
    );
};
