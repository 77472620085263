/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';

import type { ProtectedComputerManagedByConsole, ProtectedComputersManagedByConsoleFilter } from '@veeam-vspc/models/web-controllers';
import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import flrActionIconSrc from 'images/actions/flr.png';
import { ComputersActionIds, ConfigSectionIds } from 'core/enums';

import type { ComputersService } from '../../../../services';

export interface ComputersActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
    computersService: ComputersService;
}

export const ComputersActionToolbar: React.FC<ComputersActionToolbarProps> = (props) => {
    const { method = 'GetComputers', selectedOnly = true, computersService, ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { notificationService } = useGlobalServices();
    const gridApi = useGridApi<ProtectedComputerManagedByConsole, any, ProtectedComputersManagedByConsoleFilter>();
    const { portalUser, version } = useGlobalAppData();

    const actions = [
        {
            id: ComputersActionIds.FileLevelRestorePortalTBD,
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: flrActionIconSrc,
            text: lang.FILE_LEVEL_RESTORE_PORTAL,
            onClick: () => {
                const { agentId } = gridApi.selected.length > 0 ? gridApi.selected[0] : {} as ProtectedComputerManagedByConsole;

                computersService.openFlr(lang, {
                    agentId,
                    userId: portalUser.instanceUid,
                    vspcVersion: version,
                })
                    .then(() => gridApi.reloadGrid())
                    .catch(resp => notificationService.multi(lang.FILE_LEVEL_RESTORE_PORTAL, resp));
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            lang={lang}
            method={method}
            selectedOnly={selectedOnly}
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            items={actions}
        />
    );
};
