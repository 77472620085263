/**
 * Copyright © Veeam Software Group GmbH.
 */

import { WORLD_MAP_ID } from '../configs';

export enum MapLayer {
    World,
    Country,
    Region,
}

export const checkMapLayer = (mapId: string): MapLayer => {
    if (mapId === WORLD_MAP_ID) return MapLayer.World;

    // for example "us-ca"
    if (mapId.includes('-')) {
        return MapLayer.Region;
    }

    // for example "us"
    return MapLayer.Country;
};
