/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalAddons,
    ToolbarWithExport,
    PortalSpinner,
} from '@veeam-vspc/shared/components';
import {
    hideForFilterWithoutPropValue,
    hideForSelectionWithoutPropValue,
    processDecoratorsForDisabledByPriority,
    disableForSelectionWithPropValueCallback,
} from '@veeam-vspc/shared/helpers';
import { BackupAgentGuiModeRepresentation } from '@veeam-vspc/models/web-controllers';
import { observer } from 'mobx-react-lite';

import type { BackupAgentJobContainerFilter, BackupAgentJobContainerModel } from '@veeam-vspc/models/web-controllers';
import type { CustomToolbarPayload, ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import createJobIconSrc from 'images/actions/add.svg';
import createWindowJobIconSrc from 'images/filters/os/windows.svg';
import createLinuxJobIconSrc from 'images/filters/os/linux.svg';
import createMacJobIconSrc from 'images/filters/os/mac.svg';
import uiModeIconSrc from 'images/actions/gui-mode.png';
import readonlyUiModeIconSrc from 'images/actions/gui-mode-readonly.png';
import adminUiModeIconSrc from 'images/actions/gui-mode-admin.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import settingsActionIconSrc from 'images/actions/settings.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import { ConfigSectionIds, SystemTypeRepresentations } from 'core/enums';
import { useAsyncAction, useDependentToolbarButton } from 'views/hooks';
import { waitFor } from 'core/utils';

import type { ExtApplicationModel } from 'views/components/Ext/ExtComponent';

export interface ManagedByConsoleActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const ManagedByConsoleActionToolbar: React.FC<ManagedByConsoleActionToolbarProps> = observer((props) => {
    const createJobGroupId = 'createJobGroupId';
    const uiModeGroupId = 'uiModeGroupId';
    const { method = 'GetBackupAgents', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { portalEnums } = useGlobalAddons();
    const gridApi = useGridApi<BackupAgentJobContainerModel, unknown, BackupAgentJobContainerFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [loading, setLoading] = useState<boolean>(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];
    const [extView, setExtView] = useState(null);
    const [extController, setExtController] = useState(null);

    const getExtController = () => {
        if (extView && extController) {
            return extController;
        }

        if (extView && !extController) {
            const controller = extView.getController();

            setExtController(controller);
            return controller;
        }

        const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

        return promise
            .then(() => {
                const view = Ext.create('RCOP.view.home.backupJobs.computers.managedByConsole.ManagedByConsole');
                const controller = view.getController();

                setExtView(view);
                setExtController(controller);

                return controller;
            })
            .catch(() => {});
    };

    const { group: actionGroup, items: actionItems } = useDependentToolbarButton<
        BackupAgentJobContainerModel,
        unknown,
        BackupAgentJobContainerFilter
    >(
        {
            id: createJobGroupId,
            title: lang.CREATE_JOB,
            iconSrc: createJobIconSrc,
        },
        [
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createWindowJobIconSrc,
                text: lang.WINDOWS,
                groupId: createJobGroupId,
                onClick: async() => {
                    const windowsExtValue = portalEnums.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Windows);
                    setLoading(true);
                    const controller = await getExtController();
                    setLoading(false);
                    const view = controller.createJobHandler(windowsExtValue, gridApi.selected);

                    view.on('finish', () => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Windows),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Windows),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createLinuxJobIconSrc,
                text: lang.LINUX,
                groupId: createJobGroupId,
                onClick: async() => {
                    const linuxExtValue = portalEnums.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Linux);
                    setLoading(true);
                    const controller = await getExtController();
                    setLoading(false);
                    const view = controller.createJobHandler(linuxExtValue, gridApi.selected);

                    view.on('finish', () => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Linux),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Linux),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createMacJobIconSrc,
                text: lang.MAC,
                groupId: createJobGroupId,
                onClick: async() => {
                    const macExtValue = portalEnums.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Mac);
                    setLoading(true);
                    const controller = await getExtController();
                    setLoading(false);
                    const view = controller.createJobHandler(macExtValue, gridApi.selected);

                    view.on('finish', () => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Mac),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Mac),
                ],
            },
        ],
    );

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                lang={lang}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByConsole}
                groups={[
                    actionGroup,
                    {
                        id: uiModeGroupId,
                        title: lang.BACKUP_AGENT_UI,
                        iconSrc: uiModeIconSrc,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupAgent/StartBackupAgentJob', lang.START, gridApi.requestParamsForActions),
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => doAsyncAction('/BackupAgent/StopBackupAgentJob', lang.STOP, gridApi.requestParamsForActions),
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    ...actionItems,
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: readonlyUiModeIconSrc,
                        text: lang.SWITCH_TO_READ_ONLY_UI,
                        groupId: uiModeGroupId,
                        onClick: () => doAsyncAction('/BackupAgent/SetReadonly', lang.SWITCH_TO_READ_ONLY_UI, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('guiMode', value => value === BackupAgentGuiModeRepresentation.ReadOnly),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: adminUiModeIconSrc,
                        text: lang.SWITCH_TO_FULL_ADMIN_ACCESS,
                        groupId: uiModeGroupId,
                        onClick: () => doAsyncAction('/BackupAgent/SetFullMode', lang.SWITCH_TO_FULL_ADMIN_ACCESS, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('guiMode', value => value !== BackupAgentGuiModeRepresentation.ReadOnly),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.text,
                        text: `${lang.SCHEDULING}:`,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupAgent/SetDisabled', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            value: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('disabled', value => Boolean(value) === false),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupAgent/SetDisabled', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            value: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('disabled', value => Boolean(value) === true),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: settingsActionIconSrc,
                        text: lang.SETTINGS,
                        onClick: async() => {
                            setLoading(true);
                            const controller = await getExtController();
                            setLoading(false);

                            controller.setSettingsHandler(gridApi.selected, gridApi.requestParamsForActions)
                                .then(() => gridApi.reloadGrid());
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                (payload) => {
                                    const { filters, selectedItems, flags } = (payload as CustomToolbarPayload);
                                    const osTypesFilter = filters?.osTypes as SystemTypeRepresentations[] || null;

                                    if (
                                        (osTypesFilter?.length === 1 && osTypesFilter[0] === SystemTypeRepresentations.Windows)
                                        || flags.isAllPageMode
                                    ) {
                                        return { disabled: false };
                                    }

                                    return {
                                        disabled: selectedItems
                                            .every(item => item.systemType !== SystemTypeRepresentations.Windows || item.isSettingsChanging),
                                    };
                                },
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            const { selected } = gridApi;
                            const { promise } = waitFor<ExtApplicationModel>(window, 'ExtApplication');

                            setLoading(true);

                            promise
                                .then(() => {
                                    setLoading(false);

                                    Ext.create('RCOP.utils.DownloadAgentLogsManager', {
                                        title: lang.DOWNLOAD_LOGS,
                                        url: '/BackupAgent/DownloadLogs',
                                        params: {
                                            onlyThese: selected.map(item => item.agentId),
                                        },
                                        systemTypeData: {
                                            onlyThese: selected.map(item => item.systemType),
                                        },
                                    }).start();
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                payload => ({ disabled: payload.selectedItems.length > 10 }),
                            ]),
                        ],
                    },
                ]}
            />

            {asyncActionLoader}
            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
});
