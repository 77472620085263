/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettingsKind } from '@veeam-vspc/models/rest';

import type { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import type { OAuth2ClientSettings, InlineObject2 } from '@veeam-vspc/models/rest';

import { QueryKeys, ReturnUrlProducts } from 'core/enums';

import type { SmtpOAuthQuery } from 'core/utils';

export const getOAuthSettings = (userRole: UserRoleRepresentation, redirectUrl: string, clientSettings: OAuth2ClientSettings): InlineObject2 => {
    const isAzureModernType = clientSettings.kind === OAuth2ClientSettingsKind.Azure;

    const stateValueObj: SmtpOAuthQuery = {
        [QueryKeys.ProductReturnUrl]: ReturnUrlProducts.SmtpOAuth,
        kind: clientSettings.kind,
        user: userRole,
    };

    return {
        redirectUrl,
        state: JSON.stringify(stateValueObj),
        clientSettings: {
            kind: clientSettings.kind,
            clientId: clientSettings.clientId,
            clientSecret: clientSettings.clientSecret,
            tenantId: isAzureModernType ? clientSettings.tenantId : null,
        },
    };
};
