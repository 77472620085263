/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { TransportService } from '@veeam-vspc/shared/core';
import type { IdentityProviderRoleMappingRule } from '@veeam-vspc/models/rest';
import type { RequestErrorResponse } from '@veeam-vspc/shared/interfaces';

import type { SSORuleModel } from '../interfaces/sso-rule-model';
import type { SSORuleProps } from '../interfaces/sso-rule-props';

interface SaveRuleParams {
    title: string;
    data: SSORuleModel;
    props: SSORuleProps;
    model: IdentityProviderRoleMappingRule;
}

export const saveRule = (transportService: TransportService<RequestErrorResponse | Error>, { title, data, props, model }: SaveRuleParams) => {
    const { identityProviderName } = data;
    const { organizationUid, isEdit } = props;

    if (isEdit) {
        return transportService.patch(
            `/organizations/${organizationUid}/identityProviders/${identityProviderName}/rules/${props.model.instanceUid}`,
            { original: props.model, current: model },
            { backendErrorTitle: title },
        )
            .then(() => props.onFinish());
    } else {
        return transportService.post(
            `/organizations/${organizationUid}/identityProviders/${identityProviderName}/rules`,
            model,
            { backendErrorTitle: title },
        )
            .then(() => props.onFinish());
    }
};
