/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    ACTION_VIEW,
    DatetimeInput,
    Dialog,
    DIALOG_SIZE,
    FieldLayout,
    FormLayout,
    STACK_DIRECTION,
    useGlobalAppData,
    useGlobalLang,
} from '@veeam-vspc/shared/components';
import React, { useEffect, useState } from 'react';
import { TimeUnitsInMs } from '@veeam-vspc/shared/core';

import type { QuotaUsageOptionsModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { toIsoStringWithIgnoreTime } from 'core/utils/date-helpers';

interface QuotaUsageProps {
    onRun: (options: QuotaUsageOptionsModel) => void;
    deactivate: () => void;
}

export const QuotaUsage: React.FC<QuotaUsageProps> = ({ deactivate, onRun }) => {
    const lang = useGlobalLang<VspcLang>();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const { formats } = useGlobalAppData();
    const [fromDateErrorText, setFromDateErrorText] = useState('');
    const [toDateErrorText, setToDateErrorText] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const fromTime = Math.floor(new Date(fromDate).getTime() / (1000 * 60));
        const toTime = Math.floor(new Date(toDate).getTime() / (1000 * 60));
        const fromDateError = fromTime && toTime && fromTime > toTime;
        const toDateError = fromTime && toTime && toTime < fromTime;
        setFromDateErrorText(fromDateError ? lang.START_DATE_SHOULD_BE_LESS_THAN : '');
        setToDateErrorText(toDateError ? lang.END_DATE_SHOULD_BE_GREATER_THAN : '');
        setDisabled(!fromDate || !toDate || fromDateError || toDateError);
    }, [fromDate, toDate]);

    const onChangeFrom = (date: Date) => setFromDate(date);

    const onChangeTo = (date: Date) => setToDate(date);

    return (
        <Dialog
            onRequestClose={deactivate}
            header={lang.QUOTA_USAGE_REPORT}
            title={lang.SELECT_A_TIME_PERIOD_TO}
            size={DIALOG_SIZE.auto}
            actions={[
                {
                    text: lang.RUN,
                    disabled,
                    onClick: () => onRun({ from: toIsoStringWithIgnoreTime(fromDate), to: toIsoStringWithIgnoreTime(toDate, true) }),
                },
                {
                    text: lang.CANCEL,
                    onClick: deactivate,
                    view: ACTION_VIEW.secondary,
                },
            ]}
        >
            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                <FieldLayout error={fromDateErrorText} label={`${lang.FROM}:`}>
                    <DatetimeInput
                        value={fromDate}
                        placeholder={formats.netShortDate}
                        onChange={onChangeFrom}
                        error={!!fromDateErrorText}
                        endDatetime={new Date()}
                        format={formats.netShortDate}
                    />
                </FieldLayout>

                <FieldLayout error={toDateErrorText} label={`${lang.TO}:`}>
                    <DatetimeInput
                        value={toDate}
                        placeholder={formats.netShortDate}
                        onChange={onChangeTo}
                        error={!!toDateErrorText}
                        endDatetime={new Date(Date.now() + TimeUnitsInMs.Day * 364)}
                        format={formats.netShortDate}
                    />
                </FieldLayout>
            </FormLayout>
        </Dialog>
    );
};
