/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { BasicFilter, useGlobalLang } from '@veeam-vspc/shared/components';
import { SystemTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { FilterGroup, AdvancedFilterValue } from '@veeam-vspc/shared/components';
import type { VspcLang } from 'configs/languages';

import windowsOsTypeIconSrc from 'images/filters/os/windows.svg';
import linuxOsTypeIconSrc from 'images/filters/os/linux.svg';
import macOsTypeIconSrc from 'images/filters/os/mac.svg';

export interface OsTypesFilterProps {
    propName?: string;
}

export const OsTypesToggleFilter: React.FC<OsTypesFilterProps> = ({ propName = 'osTypes' } = {}) => {
    const lang = useGlobalLang<VspcLang>();

    return (
        <BasicFilter
            name={propName}
            label={lang.GUEST_OS}
            hasAllButton={true}
            buttons={[
                {
                    title: lang.WINDOWS,
                    value: `${SystemTypeRepresentation.Windows}`,
                    icon: windowsOsTypeIconSrc,
                },
                {
                    title: lang.LINUX,
                    value: `${SystemTypeRepresentation.Linux}`,
                    icon: linuxOsTypeIconSrc,
                },
                {
                    title: lang.MAC_OS,
                    value: `${SystemTypeRepresentation.Mac}`,
                    icon: macOsTypeIconSrc,
                },
            ]}
        />
    );
};

export interface UseOsTypesAdvancedFilterProps {
    propName?: string;
}

export const useOsTypesAdvancedFilter = ({ propName = 'osTypes' }: UseOsTypesAdvancedFilterProps = {}): FilterGroup<AdvancedFilterValue> => {
    const lang = useGlobalLang<VspcLang>();

    return {
        title: lang.GUEST_OS,
        items: [
            {
                iconSrc: windowsOsTypeIconSrc,
                label: lang.WINDOWS,
                value: {
                    id: SystemTypeRepresentation.Windows,
                    name: propName,
                },
            },
            {
                iconSrc: linuxOsTypeIconSrc,
                label: lang.LINUX,
                value: {
                    id: SystemTypeRepresentation.Linux,
                    name: propName,
                },
            },
            {
                iconSrc: macOsTypeIconSrc,
                label: lang.MAC_OS,
                value: {
                    id: SystemTypeRepresentation.Mac,
                    name: propName,
                },
            },
        ],
    };
};
