/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum VawReportOperationModes {
    All = -1,
    Unlicensed = 0,
    Server = 1,
    Workstation = 2,
}
