/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridSortDirections,
    TextColumn,
    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAddons,
} from '@veeam-vspc/shared/components';
import { useLocation } from 'react-router-dom';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { RequestParams, GridColumnProps } from '@veeam-vspc/shared/components';
import type { PublicCloudJobFilter, PublicCloudJobModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { PublicCloudsActionToolbar, PublicCloudsFilterToolbar } from './components';
import { StatusAgoColumn } from '../../../StatusAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ConfigSectionIds } from 'core/enums';


export const PublicClouds: React.FC<{}> = () => {
    const lang = useGlobalLang<VspcLang>();
    const { pathname } = useLocation();
    const { portalEnums } = useGlobalAddons();
    const { transportService } = useGlobalServices();
    const gridStateId = `${pathname}.PublicClouds`;
    const columns: GridColumnProps<PublicCloudJobModel>[] = [
        {
            id: 'vmName',
            title: lang.VM,
            cell: rowData => rowData.vmName,
            width: 75,
        },
        {
            id: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: rowData => rowData.vbServerName,
            width: 150,
        },
        {
            id: 'company',
            title: lang.COMPANY,
            cell: rowData => rowData.company,
        },
        {
            id: 'site',
            title: lang.SITE,
            cell: rowData => rowData.site,
            hidden: true,
        },
        {
            id: 'location',
            title: lang.LOCATION,
            cell: rowData => rowData.location,
            hidden: true,
        },
        {
            id: 'displayUid',
            title: lang.RESOURCE_ID,
            cell: rowData => rowData.displayUid,
        },
        {
            id: 'platformTypeName',
            title: lang.PLATFORM,
            cell: rowData => rowData.platformTypeName,
        },
        {
            id: 'jobName',
            title: lang.POLICY,
            cell: rowData => rowData.jobName,
        },
        {
            id: 'displayPolicyState',
            title: lang.POLICY_STATE,
            cell: rowData => (
                <TextColumn>
                    {portalEnums.getEnumDescription('EDisplayPublicCloudPolicyState', rowData.displayPolicyState)}
                </TextColumn>
            ),
        },
        {
            id: 'lastSnapshot.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastSnapshot}
                />
            ),
        },
        {
            id: 'lastBackup.sessionStateName',
            title: lang.LAST_BACKUP,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            id: 'lastRemoteSnapshot.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetRemoteSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastRemoteSnapshot}
                />
            ),
            width: 200,
        },
        {
            id: 'lastArchive.sessionStateName',
            title: lang.LAST_ARCHIVE,
            cell: rowData => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetArchiveTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastArchive}
                />
            ),
        },
        {
            id: 'nextRun',
            title: lang.NEXT_RUN,
            cell: rowData => (
                <GridDateColumn
                    value={rowData.nextRun}
                />
            ),
            width: 100,
        },
        {
            id: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: rowData => rowData.backupServerName,
            hidden: true,
        },
    ];

    return (
        <ConfiguredGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds}
            defaultColumnMinWidth={100}
            initialState={{
                sort: {
                    key: columns[0]?.id,
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudJobFilter>) => transportService
                .request<typeof requestParams, RequestSuccessResponse<PublicCloudJobModel[]>>('/PublicCloudVmJob/GetVmJobs', { ...requestParams })}
            isRowInactive={rowData => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <PublicCloudsFilterToolbar />,
                () => <PublicCloudsActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
