/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { WizardSummary, WORD_BREAK, useGlobalLang, useGlobalAppData, useGlobalServices } from '@veeam-vspc/shared/components';
import { capitalize, formatStr, formatDate, normalizeOldDateFormat } from '@veeam-vspc/shared/helpers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import { StepLayout } from 'components/layouts/StepLayout';
import {
    DailyKindsEnums,
    DayInMonth,
    DayOfWeek,
    DbGroupingBy,
    EGroupBy,
    Months,
    Platform,
    ProtectedFileRetentionType,
    ReportAggregationModeRepresentation,
    ReportScheduleType,
    ReportType,
    RpoPeriod,
    SlaPeriodRepresentation,
    VawReportOperationModes,
    VboObjectTypeRepresentation,
    VmsPlatform,
    ReportRmmProviderModes,
    ReportAccessMode,
} from '../../enums';
import { useReportStore } from '../../hooks';
import { MAX_INTEGER_32 } from 'core/const';
import { formatStringTime } from 'core/utils/date-helpers';

import type { JobType, ProtectedVmReportJobType } from '../../enums';
import type { CompanyModel, LocationModel, ReportModel, Timezone } from '../../interfaces';

export const getSummaryStep = (title: string): WizardStep<ReportModel> => ({
    title,
    render: data => <SummaryStep {...data} />,
});

const SummaryStep = ({ data }: WizardStepData<ReportModel>) => {
    const lang = useGlobalLang<VspcLang>();
    const { portalUser, formats } = useGlobalAppData();
    const reportStore = useReportStore();
    const { transportService } = useGlobalServices();
    const companies = reportStore.companies;
    const templates = {
        [ReportType.ProtectedComputers]: lang.PROTECTED_COMPUTERS_RPO,
        [ReportType.ProtectedVms]: lang.PROTECTED_VMS_RPO,
        [ReportType.ProtectedFiles]: lang.PROTECTED_DATA_BACKUP_RPO,
        [ReportType.ProtectedVmsCdp]: lang.VMS_WITH_CDP_SLA,
        [ReportType.ProtectedDatabases]: lang.PROTECTED_DATABASES_RPO,
        [ReportType.ProtectedVboObjectsSla]: lang.MICROSOFT_365_OBJECT_BACKUP_SLA,
    };
    const [timezones, setTimezones] = useState<Timezone[]>([]);
    const [locationsList, setLocationsList] = useState<LocationModel[]>([]);
    const [companiesList, setCompaniesList] = useState<CompanyModel[]>([]);
    const isCompanyRole = portalUser.isCompanyAdministrator() || portalUser.isLocationAdminOrCompanyOwner();
    const isAggregated = data.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany;
    const shortTimeFormat = normalizeOldDateFormat(formats.netShortTime);

    useEffect(() => {
        if (isAggregated) {
            reportStore.companiesListCache.load()
                .then(items => setCompaniesList(items));
        } else {
            transportService.request('/Location/GetLocations', {
                companyIds: companies,
                limit: MAX_INTEGER_32,
                page: 1,
                start: 0,
            })
                .then((resp: RequestSuccessResponse<LocationModel[]>) => {
                    setLocationsList(resp.data);
                });
        }
    }, []);

    const buildLocationSummary = () => {
        const val = data.parameters.locations;

        return locationsList
            .filter(item => val.indexOf(item.id) > -1)
            .map(item => (val.length === 1 ? item.name : `${item.companyName}/${item.name}`))
            .join(', ');
    };

    const buildCompaniesSummary = () => {
        const val = companies;

        return companiesList
            .filter(item => val.indexOf(item.id) > -1)
            .map(item => item.name)
            .join(', ');
    };

    const retentionTypes = {
        [ProtectedFileRetentionType.Default]: lang.ALL,
        [ProtectedFileRetentionType.Backup]: capitalize(lang.PRIMARY_BACKUP, true),
        [ProtectedFileRetentionType.Archive]: lang.ARCHIVE,
        [ProtectedFileRetentionType.Snapshot]: lang.SNAPSHOT,
    };

    const buildRPOInterval = () => {
        const intervals = {
            [RpoPeriod.Hour]: capitalize(lang.HOURS),
            [RpoPeriod.Day]: capitalize(lang.DAYS),
            [RpoPeriod.Week]: capitalize(lang.WEEKS),
            [RpoPeriod.Month]: capitalize(lang.MONTHS),
        };

        const interval = data.parameters.rpoInterval;
        const intervalName = RCOP.utils.String.singular(interval.number, intervals[interval.period]);

        return `${interval.number} ${intervalName}`;
    };

    const vmsPlatforms = {
        [VmsPlatform.All]: lang.ALL,
        [VmsPlatform.VirtualInfrastructure]: capitalize(lang.VIRTUAL_INFRASTRUCTURE, true),
        [VmsPlatform.PublicCloud]: capitalize(lang.PUBLIC_CLOUD, true),
    };

    const platforms = {
        [Platform.All]: lang.ALL,
        [Platform.Local]: lang.ON_PREMISES,
        [Platform.PublicCloud]: capitalize(lang.PUBLIC_CLOUD, true),
        [Platform.ObjectStorage]: capitalize(lang.OBJECT_STORAGE, true),
    };

    const buildVmJobType = () => {
        const jobTypes = reportStore.protectedVmReportJobTypeSet;

        const val = data.parameters.jobTypeFilter as ProtectedVmReportJobType[];

        return jobTypes
            .filter(item => val.includes(item.id))
            .map(item => item.name)
            .join(', ');
    };

    const buildJobType = () => {
        const val = data.parameters.jobTypeFilter as JobType[];

        return reportStore.jobTypeSet
            .filter(item => val.includes(item.id))
            .map(item => capitalize(item.name, true))
            .join(', ');
    };

    const buildVirtualPlatforms = () => {
        const val = data.parameters.virtualPlatformFilter;

        return reportStore.virtualReportPlatformTypeSet
            .filter(item => val.includes(item.id))
            .map(item => item.name)
            .join(', ');
    };

    const vawOperationModes = {
        [VawReportOperationModes.All]: lang.ALL,
        [VawReportOperationModes.Server]: lang.SERVER,
        [VawReportOperationModes.Workstation]: lang.WORKSTATION,
    };

    const epRmmProviderModes = {
        [ReportRmmProviderModes.Unknown]: lang.ALL,
        [ReportRmmProviderModes.ManagedByVAC]: capitalize(lang.MANAGED_BY_CONSOLE, true),
        [ReportRmmProviderModes.ManagedByVBR]: capitalize(lang.MANAGED_BY_BACKUP_SERVER, true),
    };

    const eGroupByList = {
        [EGroupBy.Agent]: capitalize(lang.BACKUP_AGENT),
        [EGroupBy.BackupPolicy]: lang.BACKUP_POLICY,
    };

    const groupByList = {
        [DbGroupingBy.Policy]: lang.POLICY,
        [DbGroupingBy.BackupServer]: lang.BACKUP_SERVER,
        [DbGroupingBy.PlatformType]: lang.PLATFORM_TYPE,
    };

    const daysOfWeek = {
        [DayOfWeek.Monday]: lang.MONDAY,
        [DayOfWeek.Tuesday]: lang.TUESDAY,
        [DayOfWeek.Wednesday]: lang.WEDNESDAY,
        [DayOfWeek.Thursday]: lang.THURSDAY,
        [DayOfWeek.Friday]: lang.FRIDAY,
        [DayOfWeek.Saturday]: lang.SATURDAY,
        [DayOfWeek.Sunday]: lang.SUNDAY,
    };

    const months = {
        [Months.January]: lang.JANUARY,
        [Months.February]: lang.FEBRUARY,
        [Months.March]: lang.MARCH,
        [Months.April]: lang.APRIL,
        [Months.May]: lang.MAY,
        [Months.June]: lang.JUNE,
        [Months.July]: lang.JULY,
        [Months.August]: lang.AUGUST,
        [Months.September]: lang.SEPTEMBER,
        [Months.October]: lang.OCTOBER,
        [Months.November]: lang.NOVEMBER,
        [Months.December]: lang.DECEMBER,
    };

    const dayOfWeek = {
        [DayInMonth.First]: lang.FIRST,
        [DayInMonth.Second]: lang.SECOND,
        [DayInMonth.Third]: lang.THIRD,
        [DayInMonth.Fourth]: lang.FOURTH,
        [DayInMonth.Last]: lang.LAST,
    };

    function ordinalSuffixOf(i) {
        if (i === 32) {
            return lang.LAST_DAY;
        }

        const j = i % 10;
        const k = i % 100;

        if (j === 1 && k !== 11) {
            return `${i}st`;
        }
        if (j === 2 && k !== 12) {
            return `${i}nd`;
        }
        if (j === 3 && k !== 13) {
            return `${i}rd`;
        }
        return `${i}th`;
    }

    const buildGuestOs = () => {
        const val = data.parameters.guestOsFilter;

        return reportStore.systemTypeRepresentationSet
            .filter(item => val.includes(item.id))
            .map(item => item.name)
            .join(', ');
    };

    useEffect(() => {
        reportStore.timezoneListCache.load()
            .then(items => setTimezones(items));
    }, []);

    const buildSchedule = () => {
        if (!data.schedule || data.schedule.type === ReportScheduleType.NotScheduled) {
            return lang.NOT_SCHEDULED;
        }

        const timezone = timezones.find(timezone => timezone.id === data.schedule.timeZoneId)?.description;

        if (data.schedule.type === ReportScheduleType.Daily) {
            const time = `${formatStringTime(data.schedule.daily.time, shortTimeFormat)} ${timezone}`;

            if (data.schedule.daily.kind === DailyKindsEnums.EveryDay) {
                return formatStr(lang.EVERYDAY_AT, time);
            }

            if (data.schedule.daily.kind === DailyKindsEnums.WeekDays) {
                return formatStr(lang.ON_WEEK_DAYS_AT, time);
            }

            const selectedDays = data.schedule.daily.days
                .map(day => daysOfWeek[day])
                .join(', ');

            return formatStr(lang.ON_THIS_DAYS_AT, selectedDays, time);

        } else if (data.schedule.type === ReportScheduleType.Monthly) {
            const time = `${formatStringTime(data.schedule.monthly.time, shortTimeFormat)} ${timezone}`;
            const selectedMonths = data.schedule.monthly.months.length === 12
                ? lang.EVERY_MONTH
                : data.schedule.monthly.months
                    .map(month => months[month])
                    .join(', ');
            let dayText;

            if (data.schedule.monthly.week === DayInMonth.Day) {
                dayText = ordinalSuffixOf(data.schedule.monthly.dayNumber);
            } else {
                dayText = `${dayOfWeek[data.schedule.monthly.week]} ${daysOfWeek[data.schedule.monthly.day]}`;
            }

            return formatStr(lang.MONTHLY_ON_THE, dayText, selectedMonths, time);
        }

    };

    const buildTimePeriod = () => {
        if (data.parameters.slaSettings.isCustomPeriod) {
            const dateFormat = normalizeOldDateFormat(formats.netShortDate);
            const from = formatDate(data.parameters.slaSettings.fromAsDate, dateFormat);
            const to = formatDate(data.parameters.slaSettings.toAsDate, dateFormat);
            const timezone = timezones.find(timezone => timezone.id === data.parameters.slaSettings.timeZoneId)?.description;
            return `${from} - ${to}, ${timezone}`;
        }

        const periods = {
            [SlaPeriodRepresentation.Day]: capitalize(lang.DAY),
            [SlaPeriodRepresentation.Week]: capitalize(lang.WEEK),
            [SlaPeriodRepresentation.Month]: capitalize(lang.MONTH),
            [SlaPeriodRepresentation.Quater]: capitalize(lang.QUARTER),
        };

        return `${lang.LAST} ${periods[data.parameters.slaSettings.periodType]}`;
    };

    const buildObjectTypes = () => {
        const objectTypes = [ {
            id: VboObjectTypeRepresentation.User,
            name: lang.USERS,
        }, {
            id: VboObjectTypeRepresentation.Group,
            name: lang.GROUPS,
        }, {
            id: VboObjectTypeRepresentation.Site,
            name: lang.SITES,
        }, {
            id: VboObjectTypeRepresentation.Team,
            name: lang.TEAMS,
        }];

        const val = data.parameters.vboObjectTypes;

        return objectTypes
            .filter(item => val.includes(item.id))
            .map(item => item.name)
            .join(', ');
    };

    const isDatabasesType = data.type === ReportType.ProtectedDatabases;


    const fields = [
        {
            label: `${lang.TEMPLATE}:`,
            value: templates[data.type],
        },
        {
            label: `${lang.NAME}:`,
            value: data.name,
        },
        {
            label: `${lang.DESCRIPTION}:`,
            value: data.description,
            hidden: !data.description,
        },
    ];

    if (!isCompanyRole) {
        fields.push({
            label: `${lang.REPORT_CONFIGURATION}:`,
            value: isAggregated ?
                lang.SUMMARY_REPORT : lang.INDIVIDUAL_REPORT,
        });
    }

    if (isAggregated) {
        fields.push({
            label: `${lang.COMPANIES}:`,
            value: buildCompaniesSummary(),
        });
    }

    if (data.parameters.locations && data.parameters.locations.length > 0) {
        fields.push({
            label: `${lang.LOCATIONS}:`,
            value: buildLocationSummary(),
        });
    }

    if (!isCompanyRole && !isAggregated) {
        fields.push({
            label: `${capitalize(lang.ACCESS_TYPE, true)}:`,
            value: data.parameters.accessMode === ReportAccessMode.Public
                ? lang.PUBLIC : lang.PRIVATE,
        });
    }

    if (data.type === ReportType.ProtectedFiles) {
        fields.push({
            label: `${lang.PLATFORM}:`,
            value: platforms[data.parameters.platformFilter[0]],
        });

        fields.push({
            label: `${lang.BACKUP_TYPE}:`,
            value: retentionTypes[data.parameters.retentionTypeFilter[0]],
        });

        fields.push({
            label: `${lang.PROCESSED_PERCENTAGE}:`,
            value: `${data.parameters.processedPercentage}%`,
        });
    }

    if (
        data.type === ReportType.ProtectedVms
        || data.type === ReportType.ProtectedComputers
        || data.type === ReportType.ProtectedFiles
        || data.type === ReportType.ProtectedDatabases
    ) {
        fields.push({
            label: `${lang.RPO}:`,
            value: buildRPOInterval(),
        });
    }

    if (data.type === ReportType.ProtectedVms) {
        fields.push({
            label: `${lang.PLATFORM}:`,
            value: vmsPlatforms[data.parameters.platformFilter[0]],
        });

        if (data.parameters.platformFilter[0] === VmsPlatform.VirtualInfrastructure) {
            fields.push({
                label: `${lang.PLATFORM_TYPE}:`,
                value: buildVirtualPlatforms(),
            });
        }

        fields.push({
            label: `${lang.JOB_TYPE}:`,
            value: buildVmJobType(),
        });
    }

    if (data.type === ReportType.ProtectedComputers) {
        fields.push({
            label: `${capitalize(lang.OPERATION_MODE, true)}:`,
            value: vawOperationModes[data.parameters.operationModeFilter[0]],
        }, {
            label: `${capitalize(lang.MANAGEMENT_MODE, true)}:`,
            value: epRmmProviderModes[data.parameters.managementTypeFilter[0]],
        });
    }

    if (data.type === ReportType.ProtectedDatabases) {
        fields.push({
            label: `${lang.JOB_TYPE}:`,
            value: buildJobType(),
        }, {
            label: `${lang.GROUP_BY}:`,
            value: groupByList[data.parameters.groupBy],
        });
    }

    if (data.type === ReportType.ProtectedVmsCdp || data.type === ReportType.ProtectedVboObjectsSla) {
        fields.push({
            label: `${lang.TIME_PERIOD}:`,
            value: buildTimePeriod(),
        }, {
            label: `${lang.SLA}:`,
            value: `${data.parameters.slaSettings.sla}%`,
        });
    }

    if (data.parameters.vboObjectTypes) {
        fields.push({
            label: `${lang.OBJECT_TYPES}:`,
            value: buildObjectTypes(),
        });
    }

    if (data.type !== ReportType.ProtectedFiles && !isDatabasesType) {
        fields.push({
            label: `${lang.EXCLUSION_MASK}:`,
            value: data.parameters.excludeMask,
        });
    }

    if (data.type === ReportType.ProtectedComputers) {
        if (!isAggregated) {
            fields.push({
                label: `${lang.GROUP_BY}:`,
                value: eGroupByList[data.parameters.groupBy],
            });
        }

        fields.push({
            label: `${lang.GUEST_OS}:`,
            value: buildGuestOs(),
        });
    }

    if (isAggregated) {
        fields.push({
            label: `${lang.INCLUDE_DETAILS}:`,
            value: data.parameters.includeCompaniesDetails ? lang.YES : lang.NO,
        });
    }

    fields.push({
        label: `${lang.SCHEDULE}:`,
        value: buildSchedule(),
    });

    if (data.parameters.emailOptions) {
        fields.push({
            label: `${lang.SEND_REPORTS_TO_THE_FOLLOWING_EMAIL}:`,
            value: data.parameters.emailOptions,
        });
    }

    return (
        <StepLayout
            title={capitalize(lang.SUMMARY)}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[{
                    fields,
                }]}
                fieldContentWordBreak={WORD_BREAK.breakWord}
            />
        </StepLayout>
    );
};
