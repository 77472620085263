/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { CONTROL_SIZE, useGlobalLang } from '@veeam-vspc/shared/components';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';

import type { VspcLang } from 'configs/languages';

import { useProductStore } from '../../hooks';
import { StatefulCombobox } from 'views/components/StatefulCombobox';

import type { ComboboxItem } from 'core/interfaces';

export const ProductCombobox: React.FC<{}> = observer(() => {
    const lang = useGlobalLang<VspcLang>();
    const productStore = useProductStore();

    return (
        <StatefulCombobox
            label={`${lang.PRODUCT}:`}
            controlRenderer={createLinkControl()}
            data={[
                { id: LicenseProductType.VspcEndpoint, text: lang.VEEAM_SERVICE_PROVIDER_CONSOLE },
                { id: LicenseProductType.CloudConnectServer, text: lang.VEEAM_CLOUD_CONNECT },
                { id: LicenseProductType.BackupServer, text: lang.VEEAM_BACKUP_AND_REPLICATION },
                { id: LicenseProductType.VOne, text: lang.VEEAM_ONE },
                { id: LicenseProductType.Vbo, text: lang.VEEAM_BACKUP_FOR_MICROSOFT },
            ]}
            onChange={productStore.setType}
            size={CONTROL_SIZE.full}
            stateId={productStore.localStorageStateId}
            textGetter={(p: ComboboxItem) => p.text}
            value={productStore.type}
            valueGetter={(p: ComboboxItem) => p.id}
        />
    );
});
