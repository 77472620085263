/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { GoogleCloudObjectType } from '@veeam-vspc/models/web-controllers';

export class ProtectedGcpWorkloadsStore {

    @observable googleCloudObjectType: GoogleCloudObjectType;

    constructor(type: GoogleCloudObjectType, readonly lsStateId: string) {
        makeObservable(this);
        this.googleCloudObjectType = type;
    }

    @action.bound
    setGoogleCloudObjectType(v: GoogleCloudObjectType): void {
        this.googleCloudObjectType = v;
    }
}
