/**
 * Copyright © Veeam Software Group GmbH.
 */

import { RequestGridSortDirections, transformRequestParams } from '@veeam-vspc/shared/components';
import { FilterExpressionOperation, SortExpressionDirection, Collation } from '@veeam-vspc/models/rest';
import { NotificationDefaultTitles, NotificationService } from '@veeam-vspc/shared/services';

import type { Filter, RequestParams, GridStore, SortRequestParams } from '@veeam-vspc/shared/components';
import type { RequestSuccessResponse, RequestErrorResponse } from '@veeam-vspc/shared/interfaces';
import type { BackupServerBackupJobVmwareObjectSize, FilterExpression, SortExpression } from '@veeam-vspc/models/rest';
import type { TransportService } from '@veeam-vspc/shared/core';

const genericEmptyResponse: RequestSuccessResponse<{}> = {
    data: [],
    meta: {
        pagingInfo: {
            total: 0,
            count: 0,
            offset: 0,
        },
    },
};

type ObjectRequestSuccessResponse = RequestSuccessResponse<BackupServerBackupJobVmwareObjectSize[]>;

class GuestProcessingResourcesService {
    protected transportService?: TransportService<RequestErrorResponse | Error> = {
        get() {
            throw new Error('TransportService is undefined');
        },
    } as unknown as TransportService<RequestErrorResponse | Error>;
    notificationService?: NotificationService;

    init(
        transportService: TransportService<RequestErrorResponse | Error>,
        notificationService: NotificationService,
    ) {
        this.transportService = transportService;
        this.notificationService = notificationService;
    }

    abortController = new AbortController();

    showError(err) {
        const message = err.errors.length > 1 ? err.errors.map(error => ({
            text: error.message,
            listItem: true,
        })) : err.errors[0].message;

        this.notificationService.error(NotificationService.texts[NotificationDefaultTitles.Error], message);
    }

    protected abortPreviousRequest() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    protected getSortQueryValue(sortParams: SortRequestParams): string {
        // TODO: change the approach, if models are changed on the backend (if nesting is removed)
        const sortDirection = sortParams[0]?.direction === RequestGridSortDirections.Asc
            ? SortExpressionDirection.Ascending
            : SortExpressionDirection.Descending;

        // BackupServerBackupJobVmwareObjectSize model
        const sortProperty = sortParams[0]?.property === 'size'
            ? sortParams[0]?.property
            : `inventoryObject.${sortParams[0]?.property}`;

        const sortConfig: SortExpression = {
            property: sortProperty,
            direction: sortDirection,
            collation: Collation.Ignorecase,
        };

        return JSON.stringify([sortConfig]);
    }

    protected getFilterQueryValue<FilterT>(filter: Filter<FilterT>): string {
        // TODO: change the approach, if models are changed on the backend (if nesting is removed)
        const filterConfig: FilterExpression = {
            property: 'inventoryObject.name', // BackupServerBackupJobVmwareObjectSize model
            operation: FilterExpressionOperation.Contains,
            value: filter['name'] || '',
        };

        return JSON.stringify([filterConfig]);
    }

    // TODO: change the approach, if models are changed on the backend (if nesting is removed)
    // for selection in a grid
    protected addSelectionFiledToExpandedObjects(response: ObjectRequestSuccessResponse): Promise<ObjectRequestSuccessResponse> {
        return Promise.resolve({
            ...response,
            data: response.data.map(x => ({ ...x, objectId: x.inventoryObject.objectId })),
        } as ObjectRequestSuccessResponse);
    }

    getTransformedExpandedObjects(
        backupServerUid: string,
        virtualCenterUid: string,
        vmWareObjectSizeArr: BackupServerBackupJobVmwareObjectSize[],
        gridApi: GridStore<BackupServerBackupJobVmwareObjectSize, unknown, unknown>
    ) {
        return (params: RequestParams<unknown>) => {
            this.abortPreviousRequest();

            const urlParams = new URLSearchParams();
            const transformedParams = transformRequestParams(params);

            for (const i in transformedParams) {
                if (transformedParams[i]) {
                    urlParams.append(i, transformedParams[i]);
                }
            }

            urlParams.set('filter', this.getFilterQueryValue(params.filter));
            urlParams.set('sort', this.getSortQueryValue(params.sort));

            return this.transportService
                .post<any, ObjectRequestSuccessResponse>(
                    `/infrastructure/backupServers/${backupServerUid}/servers/virtualCenter/${virtualCenterUid}/objects/expand` +
                    `?${urlParams.toString()}`,
                    vmWareObjectSizeArr,
                    {
                        requestInit: {
                            signal: this.abortController.signal,
                        },
                    },
                )
                .then((response: ObjectRequestSuccessResponse): Promise<ObjectRequestSuccessResponse> => (
                    this.addSelectionFiledToExpandedObjects(response))
                )
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    this.showError(err);

                    return genericEmptyResponse as ObjectRequestSuccessResponse;
                });
        };
    }
}

export const guestProcessingResourcesService = new GuestProcessingResourcesService();
