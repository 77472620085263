/**
 * Copyright © Veeam Software Group GmbH.
 */

import { StackView, STACK_DIRECTION, STACK_GAP, Dialog, ACTION_VIEW, DIALOG_SIZE, STACK_ALIGN, useGlobalLang } from '@veeam-vspc/shared/components';
import { Checkbox } from '@veeam/components';
import React, { useMemo, useState } from 'react';
import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { Vb365JobItemComposed } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { getOptionName } from '../OptionsCell/OptionsCell';

import type { ConfigurableOption, ProcessingOptionsProps } from '../../interfaces';

export const ProcessingOptions: FC<ProcessingOptionsProps> = ({
    resources,
    branches = {},
    store,
    height = DIALOG_SIZE.s,
    onClose,
    onSave,
    disabledItems,
}) => {
    const [resource] = resources;

    const processingOptions = store.getProcessingOptions(resource);

    const [selection, setSelection] = useState<{ [key in ConfigurableOption]?: boolean; }>(
        resources.length === 1
            ? Object.fromEntries(processingOptions.map(f => [f, resource[f]]))
            : Object.fromEntries(processingOptions.map(f => [f, false]))
    );

    const firstLevel = useMemo(() => {
        const allLeaves = new Set(Object.values(branches).flat());

        return processingOptions.filter(x => !allLeaves.has(x));
    }, [branches, processingOptions]);

    const lang = useGlobalLang<VspcLang>();

    const toggle = (option: ConfigurableOption, parent?: ConfigurableOption) => {
        let newSelection = { ...selection, [option]: !selection[option] };

        if (parent) {
            const selectedItems = Object.keys(newSelection)
                .filter(k => newSelection[k] === true);

            if (branches[parent].every(x => !selectedItems.includes(x))) {
                newSelection = { ...newSelection, [parent]: false };
            }
        }

        setSelection(newSelection);
    };

    const getSelectionForSave = (): Partial<Vb365JobItemComposed> => {
        const itemsOfNotSelectedSubtree = Object.entries(branches)
            .filter(([tree]) => selection[tree] === false)
            .flatMap(x => x[1]);


        const selectedItems: typeof selection = {};

        for (const key in selection) {
            const option = key as ConfigurableOption;

            if (itemsOfNotSelectedSubtree.includes(option)) {
                selectedItems[option] = false;
            } else {
                selectedItems[option] = selection[option];
            }
        }

        return selectedItems;
    };

    const handleSave = () => {
        onSave(resources.map(x => ({ ...x, ...getSelectionForSave() })));
        onClose();
    };

    const addTeamsFakeItem = resources[0].itemType === Vb365JobItemComposedItemType.Team;

    const canSave = () => {
        const everySelectedSubtreeHasAtLeastOneSelectedItem = Object.entries(branches)
            .filter(([tree]) => selection[tree] === true)
            .every(([, branch]) => branch.some(k => selection[k] === true));

        const thereAreAnySelection = Object.values(getSelectionForSave()).filter(x => Boolean(x)).length > 0;

        const microsoftTeamsIsSelected = Boolean(getSelectionForSave().backupTeams);

        return (thereAreAnySelection && everySelectedSubtreeHasAtLeastOneSelectedItem)
            || microsoftTeamsIsSelected
            || addTeamsFakeItem;
    };

    const toggleParent = (option: string) => {
        setSelection({
            ...selection,
            [option]: !selection[option],
        });
    };

    const subtreeDisabled = (parent: ConfigurableOption) => !selection[parent];

    const renderSubtree = (parent: ConfigurableOption) => (
        <StackView
            direction={STACK_DIRECTION.column}
            gap={STACK_GAP.s}
            style={{ paddingLeft: '28px' }}
            align={STACK_ALIGN.start}
        >
            {
                branches[parent]
                    .filter(x => processingOptions.includes(x))
                    .map(node => (
                        <Checkbox
                            key={node}
                            disabled={(disabledItems && disabledItems.includes(node)) || subtreeDisabled(parent)}
                            checked={Boolean(selection[node])}
                            onChange={() => toggle(node, parent)}
                        >
                            {getOptionName(node, resource.itemType, lang)}
                        </Checkbox>
                    ))
            }
        </StackView>
    );

    return (
        <Dialog
            header={lang.EDIT_PROCESSING_OPTIONS}
            description={lang.SELECT_OBJECTS_TO_PROCESS}
            size={{
                width: DIALOG_SIZE.xxs,
                height,
            }}
            autoHeight
            onRequestClose={onClose}
            actions={[
                { text: lang.SAVE, onClick: handleSave, disabled: !canSave() },
                { text: lang.CLOSE, onClick: onClose, view: ACTION_VIEW.secondary },
            ]}
        >
            <StackView
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.xxs}
                align={STACK_ALIGN.start}
            >
                {
                    firstLevel.map(node => (
                        <>
                            <Checkbox
                                key={node}
                                checked={Boolean(selection[node])}
                                onChange={() => branches[node] ? toggleParent(node) : toggle(node)}
                                disabled={disabledItems && disabledItems.includes(node)}
                            >
                                {getOptionName(node, resource.itemType, lang)}
                            </Checkbox>

                            {branches[node] && renderSubtree(node)}
                        </>
                    ))
                }

                {
                    // Add fake Teams option
                    addTeamsFakeItem && (
                        <Checkbox
                            checked={true}
                            disabled={true}
                        >
                            {lang.CHANNELS_TABS_FILES}
                        </Checkbox>
                    )
                }
            </StackView>
        </Dialog>
    );
};
