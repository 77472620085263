/**
 * Copyright © Veeam Software Group GmbH.
 */

import { BodyLayout } from '@veeam-vspc/shared/components';
import React from 'react';

import { Rules } from './components/DiscoveryComputers/components/Rules';

export interface ManagedComputersRulesPageProps {
    currentPath: string;
}

export const ManagedComputersRulesPage: React.FC<ManagedComputersRulesPageProps> = ({ currentPath }) => (
    <BodyLayout>
        <Rules stateId={currentPath} />
    </BodyLayout>
);
