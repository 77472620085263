/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ActionLinkColumn,
    GridColumnJustify,
    GridSortDirections,
    PortalSpinner,

    ConfiguredGrid,
    useGlobalLang,
    useGlobalServices,
    useGlobalAppData,
} from '@veeam-vspc/shared/components';
import { CompanyTypeRepresentation, InvoicePaidState, UserRoleRepresentation } from '@veeam-vspc/models/web-controllers';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { GridColumnProps } from '@veeam-vspc/shared/components';
import type { GeneratedInvoiceModel, InvoiceParam } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { AmountCurrencyColumn } from 'views/components/columns/AmountCurrencyColumn';
import { AllInvoicesFilterToolbar } from './components/AllInvoicesFilterToolbar';
import { AllInvoicesActionToolbar } from './components/AllInvoicesActionToolbar';
import { ConfigSectionIds } from 'core/enums';

interface AllInvoicesProps {
    stateId: string;
}

export const AllInvoices: React.FC<AllInvoicesProps> = ({ stateId }) => {
    const lang = useGlobalLang<VspcLang>();
    const gridStateId = `${stateId}.AllInvoices`;
    const { portalUser } = useGlobalAppData();
    const [loading, setLoading] = useState(false);
    const { transportService, fileTransportService } = useGlobalServices();

    const isTypeFilterVisible = portalUser.isPassedRoleContained([
        UserRoleRepresentation.VacAdministrator,
        UserRoleRepresentation.CloudConnectAdministrator,
    ]);

    const columns: GridColumnProps<GeneratedInvoiceModel>[] = [
        {
            id: 'companyName',
            title: lang.COMPANY,
            cell: rowData => rowData.companyName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'siteName',
            title: lang.SITE,
            hidden: true,
            cell: rowData => rowData.siteName,
            defaultValue: lang.NO_INFO,
        },
        {
            id: 'description',
            title: lang.INVOICE,
            cell: rowData => (
                <ActionLinkColumn
                    content={rowData.description}
                    isLink
                    onClick={() => {
                        setLoading(true);
                        fileTransportService.downloadFile(`/Invoice/GetPdf?uid=${rowData.statementID}`)
                            .finally(() => setLoading(false));
                    }}
                />
            ),
        },
        {
            id: 'created',
            title: lang.GENERATED_ON,
            cell: rowData => (
                <GridDateColumn value={rowData.created} />
            ),
        },
        {
            id: 'due',
            title: lang.DUE_DATE,
            cell: rowData => (
                <GridDateColumn value={rowData.due} />
            ),
        },
        {
            id: 'status',
            title: lang.STATUS,
            cell: rowData => rowData.status,
        },
        {
            id: 'paid',
            title: lang.PAID_DATE,
            cell: rowData => (
                <GridDateColumn value={rowData.paid} />
            ),
        },
        {
            id: 'invoiceAmount',
            justify: GridColumnJustify.Right,
            title: lang.TOTAL_SUM,
            cell: rowData => (
                <AmountCurrencyColumn amount={rowData.invoiceAmount} currency={rowData.currency} />
            ),
        },
        {
            id: 'id',
            justify: GridColumnJustify.Right,
            title: lang.INVOICE_ID,
            hidden: true,
            cell: rowData => (
                rowData.statusId === InvoicePaidState.NotInvoice ? ColumnTexts.DashSymbol : rowData.id
            ),
        },
        {
            id: 'subscriptionPlanName',
            title: lang.SUBSCRIPTION_PLAN,
            hidden: true,
            cell: rowData => (
                rowData.subscriptionPlanName || lang.NOT_SET
            ),
        },
    ];

    return (
        <>
            <ConfiguredGrid
                stateId={gridStateId}
                sectionId={ConfigSectionIds.InvoicesAllInvoices}
                columns={columns}
                initialState={{
                    filters: { type: isTypeFilterVisible ? [CompanyTypeRepresentation.Provider, CompanyTypeRepresentation.Tenant] : [] },
                    sort: {
                        direction: GridSortDirections.Desc,
                        key: 'created',
                    },
                }}
                defaultColumnMinWidth={80}
                data={(requestParams: InvoiceParam) => transportService
                    .request<typeof requestParams, RequestSuccessResponse<GeneratedInvoiceModel[]>>('/Invoice/GetInvoices', { ...requestParams })}
                toggleable={true}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                toolbars={[
                    () => <AllInvoicesFilterToolbar />,
                    () => <AllInvoicesActionToolbar />,
                ]}
            />

            {loading && <PortalSpinner delayTime={300} />}
        </>
    );
};
