/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useGlobalLang,
    useGlobalServices,
    ToolbarWithExport,
} from '@veeam-vspc/shared/components';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { ToolbarWithExportProps } from '@veeam-vspc/shared/components';
import type { GetVbRestoreLinkParams, PublicCloudRdsModel, PublicCloudRdsFilter } from '@veeam-vspc/models/web-controllers';
import type { RequestSuccessResponse } from '@veeam-vspc/shared/interfaces';
import type { VspcLang } from 'configs/languages';

import databaseRestoreIconSrc from 'images/actions/database-restore.svg';
import { ConfigSectionIds } from 'core/enums';

export interface CloudDatabasesActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items' | 'lang'> {
    method?: string;
}

export const CloudDatabasesActionToolbar: React.FC<CloudDatabasesActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudRds', ...restProps } = props;
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSingleSelection(),
    ];
    const gridApi = useGridApi<PublicCloudRdsModel, unknown, PublicCloudRdsFilter>();
    const getLink = (restoreType: EPublicCloudRestoreType) => {
        const { selected } = gridApi;

        setLoading(true);

        transportService.request<GetVbRestoreLinkParams, RequestSuccessResponse<string>>('/VbPlugin/GetRestoreLink', {
            uid: selected[0].uid,
            vbServerId: selected[0].vbServerId,
            restoreType,
            backUrl: window.location.href,
        })
            .then((resp: RequestSuccessResponse<string>) => {
                setLoading(false);
                window.open(resp.data as string, '_self');
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                lang={lang}
                sectionId={ConfigSectionIds.ProtectedDataCloudDatabases}
                method={method}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: databaseRestoreIconSrc,
                        text: lang.RESTORE,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreRds),
                        decorators: defaultDisabledDecorators,
                    },
                ]}
            />

            {loading && <PortalSpinner />}
        </>
    );
};
