/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/shared/components';
import { ColumnTexts } from '@veeam-vspc/shared/enums';

import { SourceItemsDialog } from './components/SourceItemsDialog/SourceItemsDialog';

import type { SourceItemsDialogProps } from './components/SourceItemsDialog/SourceItemsDialog';

export interface SourceItemsColumnProps extends Omit<SourceItemsDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const SourceItemsColumn: React.FC<SourceItemsColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={value || ColumnTexts.DashSymbol}
        isLink={Boolean(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <SourceItemsDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

