/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, useGridApi, Text, useGlobalLang, useGlobalAppData } from '@veeam-vspc/shared/components';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/shared/helpers';

import type { DiscoveredComputerModel } from '@veeam-vspc/models/web-controllers';
import type { VspcLang } from 'configs/languages';

import { ScheduledUpdatesDialog } from 'views/pages/DiscoveryPage/components/ScheduledUpdatesDialog';

import type { ScheduledVbrTask } from 'views/pages/DiscoveryPage/components/ScheduledUpdatesDialog/interfaces';

export const ScheduledUpdatesColumn = ({ rowData }: { rowData: DiscoveredComputerModel; }) => {
    const { scheduledVbrTask } = rowData;
    const gridApi = useGridApi();
    const lang = useGlobalLang<VspcLang>();
    const { formats: { netShortDate, netShortTime }, portalUser } = useGlobalAppData();

    if (!scheduledVbrTask) {
        return (
            <Text>
                {lang.NONE}
            </Text>
        );
    }

    const isDisabledLink = portalUser.isLocationAdministrator()
        || portalUser.isCompanyOwner()
        || portalUser.isCompanyAdministrator();

    return (
        <ActionLinkColumn
            disabled={isDisabledLink}
            renderContent={() => renderShortDateAndTimeForColumn(scheduledVbrTask?.date, netShortDate, netShortTime)}
            isLink={true}
            renderModal={({ deactivate }) => (
                <ScheduledUpdatesDialog
                    scheduledVbrTask={scheduledVbrTask as any as ScheduledVbrTask}
                    deactivate={deactivate}
                    onChange={() => gridApi.reloadGrid()}
                />
            )}
        />
    );
};
