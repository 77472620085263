/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable, makeObservable } from 'mobx';
import { NotificationAlarmsSettingsDailyStatusFilter } from '@veeam-vspc/models/rest';

import type { NotificationAlarmsSettings } from '@veeam-vspc/models/rest';

export class AlarmsAdvancedSettingsStore {

    @observable dailySorting: NotificationAlarmsSettings['dailySorting'];
    @observable dailyStatusFilter: Set<NotificationAlarmsSettingsDailyStatusFilter>;

    constructor(alarmsSettings: NotificationAlarmsSettings) {
        makeObservable(this);

        this.dailySorting = alarmsSettings.dailySorting;
        this.dailyStatusFilter = new Set(alarmsSettings.dailyStatusFilter);
    }

    @action.bound
    updateStatusFilter(value: NotificationAlarmsSettingsDailyStatusFilter, isAddAction: boolean): void {
        if (isAddAction) {
            this.dailyStatusFilter.add(value);
        } else {
            this.dailyStatusFilter.delete(value);
        }
    }
}
