/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum CertVerificationStatus {
    GettingInfo = 'gettingInfo',
    NotVerified = 'notVerified',
    NotVerifiedTrusted = 'notVerifiedTrusted',
    Verified = 'verified',
}
