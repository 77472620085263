/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, SIZE_M, SPACING_XXXL, STACK_GAP, StackView, Text } from '@veeam-vspc/shared/components';
import styled from 'styled-components';

import { FieldLayoutStyled } from './FieldLayout.styled';

import type { CustomDisplayFieldProps } from '../../interfaces';

const TooltipStyled = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CustomDisplayField: React.FC<CustomDisplayFieldProps> = ({ icon, label, value, tooltipText = '' }) => (
    <FieldLayoutStyled inlineLabel label={`${label}:`} style={{ height: SIZE_M, marginLeft: '64px' }}>
        <TooltipStyled
            title={tooltipText}
        >
            <StackView gap={STACK_GAP.s} style={{ height: SIZE_M, padding: `0 ${SPACING_XXXL}` }}>
                {icon && <Icon src={icon} />}

                <Text style={{ display: 'flex', height: SIZE_M }}>
                    {value}
                </Text>
            </StackView>
        </TooltipStyled>
    </FieldLayoutStyled>
);
