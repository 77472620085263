/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn, useGlobalLang } from '@veeam-vspc/shared/components';
import { Vb365JobItemComposedItemType } from '@veeam-vspc/models/rest';

import type { FC } from 'react';
import type { VspcLang } from 'configs/languages';

import { resourceValueGetter } from '../../helpers';

import type { ObjectCellProps } from '../../../../../../interfaces';

export const ObjectCell: FC<ObjectCellProps> = ({ rowData, wizardStore }) => {
    const lang = useGlobalLang<VspcLang>();

    if (rowData.itemType === Vb365JobItemComposedItemType.PersonalSites) {
        return <TextColumn>{lang.PERSONAL_SITES}</TextColumn>;
    }

    if (rowData.itemType === Vb365JobItemComposedItemType.PartialOrganization) {
        return <TextColumn>{wizardStore.selectedOrganization.name}</TextColumn>;
    }

    return <TextColumn>{resourceValueGetter(rowData, 'name', wizardStore)}</TextColumn>;
};
