/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { STACK_DIRECTION, StackView, FieldLayout, STACK_GAP, useGlobalLang, useGlobalServices } from '@veeam-vspc/shared/components';
import { Combobox } from '@veeam/components';
import { ConfigStates } from '@veeam-vspc/shared/core';
import { observer } from 'mobx-react-lite';

import type { VspcLang } from 'configs/languages';

import { CloudHostCpuRatio } from './components/CloudHostCpuRatio';
import { UsedCpuResources } from './components/UsedCpuResources';
import { CloudHostMemory } from './components/CloudHostMemory';
import { CloudHostStorage } from './components/CloudHostStorage';
import { ConfigSectionIds } from 'core/enums';
import { HwPlansContext } from './contexts';
import { hwPlansStore } from './stores';
import { useHwPlansStore } from './hooks';

export const ReplicationLayout = observer(() => {
    const store = useHwPlansStore();
    const lang = useGlobalLang<VspcLang>();
    const { configuratorService } = useGlobalServices();

    const sectionConfig = configuratorService?.getSectionConfig(ConfigSectionIds.BillingSummaryCloudConnectResourcesCloudConnectReplica);
    const isCloudHostCpuRatioVisible = sectionConfig?.getItemState('CloudHostCpuRatio') === ConfigStates.Visible;

    useEffect(() => {
        store.fetchData();
    }, [store]);

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            gap={STACK_GAP.m}
            style={{ height: '100%', overflow: 'hidden' }}
        >
            <FieldLayout
                inlineLabel
                label={`${lang.HARDWARE_PLAN}:`}
            >
                <Combobox
                    data={store.hwPlanList}
                    onChange={store.setHwpId}
                    textGetter={p => p.text}
                    value={store.hwpId}
                    valueGetter={p => p.id}
                />
            </FieldLayout>

            <div style={{ overflow: 'auto' }}>
                {isCloudHostCpuRatioVisible && <CloudHostCpuRatio />}
                {!isCloudHostCpuRatioVisible && <UsedCpuResources />}
                <CloudHostMemory />
                <CloudHostStorage />
            </div>
        </StackView>
    );
});

export const Replication: React.FC = () => {
    const lang = useGlobalLang<VspcLang>();
    const { transportService } = useGlobalServices();
    hwPlansStore.setInitial(transportService, lang);

    return (
        <HwPlansContext.Provider value={hwPlansStore}>
            <ReplicationLayout />
        </HwPlansContext.Provider>
    );
};
